import React, { Component } from "react";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
//components
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";
import { Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import ManageVendors from "./search";
import { BackButton } from "../../common/backbutton";

import InfoModal from "../../common/InfoModal";

import connectionsLogo from "../../../images/connections_info_logo.png";

import QuestionModalTrigger from "../../common/questionModalTrigger";

export default class ManageVendorsMain extends Component {
  componentDidUpdate(nextProps) {
    // const { match } = this.props;
    console.log("nextProps")
    console.log(nextProps);
  }
  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {

    const { match } = this.props;
    console.log("src/components/dashboard/vendors/index.js this.props", this.props)
    if (
      (getUser() && getUser().company_admin === false) ||
      getUser().company_id !== match.params.id
    ) {
      history.push("/dashboard");
    }
  }
  render() { 
    
    console.log("src/components/dashboard/vendors/index.js this.props", this.props);
    return (
      <Flex
        key={["FDMV" + `${Math.random().toString()}`]}
        flexDirection="column">
        <ManageVendors  
          key={["MVABC" + `${Math.random().toString()}`]}
          match={this.props.match } // added check in ManageVendors _fetchDependencies -- added the empty string to fix 'value' prop on 'input' should not be null.
        />
      </Flex>
    );
  }
}

export const ManageVendorsNav = ({ ...props }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
     <InfoModal
      modalName='connections_modal'  //no spaces. Once set right do not change
      modalVersion="1.00" //set it to 1.00 initially then change it when needed
      image={connectionsLogo}
      title='Get Connected'
      text='Build your Partner Network by connecting to people and companies already on KickPost. Find specific users by searching and/or scrolling then make a connection request with a single click.'
      extraTexts={['Want to see how it works? Watch our tutorial video.']}
      videoLink='https://youtu.be/bLizN1BdS7Y'
      />
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        CONNECTIONS
      </NunitoSans>
      <QuestionModalTrigger modalName='connections_modal' />
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);
