import pdfIcon from "../images/pdf-icon.png";

import excelIcon from "../images/excel-icon.png";
import docxIcon from "../images/docx-icon.png";
import ppxIcon from "../images/ppx-icon.png";
import documentIcon from "../images/document.png";
import pagesIcon from "../images/pages-icon.png";
import numbersIcon from "../images/numbers-icon.png";
import keynoteIcon from "../images/keynote-icon.png";

const mimeTypeImages = {
  "application/pdf": pdfIcon,
  "application/vnd.ms-excel": excelIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": excelIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template": excelIcon,
  "application/vnd.ms-excel.sheet.macroEnabled.12": excelIcon,
  "application/vnd.ms-excel.template.macroEnabled.12": excelIcon,
  "application/vnd.ms-excel.addin.macroEnabled.12": excelIcon,
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12": excelIcon,
  "application/msword": docxIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": docxIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template": docxIcon,
  "application/vnd.ms-word.document.macroEnabled.12": docxIcon,
  "application/vnd.ms-word.template.macroEnabled.12": docxIcon,
  "application/vnd.ms-powerpoint": ppxIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": ppxIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.template": ppxIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow": ppxIcon,
  "application/vnd.ms-powerpoint.addin.macroEnabled.12": ppxIcon,
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12": ppxIcon,
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": ppxIcon,
  "application/vnd.apple.keynote": keynoteIcon,
  "application/vnd.apple.pages": pagesIcon,
  "application/vnd.apple.numbers": numbersIcon
};

export const filenameCleaner = filenameRaw => {
  let filename = filenameRaw;
  if (filename.endsWith(".pages.zip")) {
    filename = filename.replace(".pages.zip", ".pages");
  }
  if (filename.endsWith(".numbers.zip")) {
    filename = filename.replace(".numbers.zip", ".numbers");
  }
  if (filename.endsWith(".key.zip")) {
    filename = filename.replace(".key.zip", ".key");
  }
  return filename;
};

export const mimeToImage = (mimeTypeRaw, filename) => {
  let mimeType = mimeTypeRaw;
  if (filename.endsWith(".pages.zip")) {
    mimeType = "application/vnd.apple.pages";
  }
  if (filename.endsWith(".numbers.zip")) {
    mimeType = "application/vnd.apple.numbers";
  }
  if (filename.endsWith(".key.zip")) {
    mimeType = "application/vnd.apple.keynote";
  }
  let image = mimeTypeImages[mimeType];
  if (image) {
    return image;
  } else {
    return documentIcon;
  }
};
