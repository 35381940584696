const defaultState = {
  selectedCompanies: [],
  selectedDepartments: [],
  selectedGroups: [],
  selectedRows: [],
  selectedUsers: []
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SET_SELECTED_COMPANIES":
      newState.selectedCompanies = action.payload;
      return newState;
    case "SET_SELECTED_DEPARTMENTS":
      newState.selectedDepartments = action.payload;
      return newState;
    case "SET_SELECTED_GROUPS":
      newState.selectedGroups = action.payload;
      return newState;
    case "SET_SELECTED_ROWS":
      newState.selectedRows = action.payload;
      return newState;
    case "CLEAR_SELECTED_COMPANIES":
      newState.selectedCompanies = [];
      return newState;
    case "CLEAR_SELECTED_DEPARTMENTS":
      newState.selectedDepartments = [];
      return newState;
    case "CLEAR_SELECTED_GROUPS":
      newState.selectedGroups = [];
      return newState;
    case "CLEAR_SELECTED_USERS":
      newState.selectedUsers = [];
      return newState;
    case "CLEAR_SELECTED_ROWS":
      newState.selectedRows = [];
      return newState;
    case "CLEAR_SELECTED_ROWS":
      newState.selectedRows = [];
      return newState;
    case "SET_SELECTED_USERS":
      newState.selectedUsers = action.payload;
      return newState;
    default:
      return state;
  }
};