import React from "react";
import { reduxForm } from "redux-form";

import { TwoPartFieldDashed, renderSmallField } from "../../../../common/forms";

import { AsYouType } from "libphonenumber-js";

const validate = values => {
  const errors = {};
console.log(values);
  if (!values.body) {
    errors.body = "Required";
  }

  if (!values.label) {
    errors.label = "Required";
  }

  return errors;
};

const formatter = new AsYouType("US");
const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }

  formatter.reset();
  const valueOnlyNums = value.replace(/[^\d]/g, "");
  if (
    previousValue &&
    value.length < previousValue.length &&
    value.length <= 5
  ) {
    // doing value length === 5 so we only apply this rule on (415) cases. may need to be changed for full
    //// compatibility .
    // typing backward, need to make sure we are actually deleting numbers when going backwards.
    const previousValueOnlyNums = previousValue.replace(/[^\d]/g, "");
    if (previousValueOnlyNums.length === valueOnlyNums.length) {
      let oneLessNumber = valueOnlyNums.slice(0, valueOnlyNums.length - 1);
      return formatter.input(oneLessNumber);
    }
  }
  return formatter.input(value);
};

let ProfileAttribute = props => {
  const {
    formLabel,
    labelPlaceholder,
    maxlengths,
    placeholder,
    clickHandler,
    handleSubmit,
    phone
  } = props;

  if (phone) {
    return (
      <form onSubmit={handleSubmit} id="profile-form">
        <TwoPartFieldDashed
          fieldOneProps={{
            label: formLabel,
            name: "body",
            placeholder: placeholder,
            component: renderSmallField,
            type: "text",
            maxLength: maxlengths[0],
            normalize: normalizePhone
          }}
          fieldTwoProps={{
            label: "LABEL",
            name: "label",
            placeholder: labelPlaceholder,
            component: renderSmallField,
            type: "text",
            maxLength: maxlengths[1]
          }}
          icon="checkmark"
          clickHandler={clickHandler}
        />
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit} id="profile-form">
      <TwoPartFieldDashed
        fieldOneProps={{
          label: formLabel,
          name: "body",
          placeholder: placeholder,
          component: renderSmallField,
          type: "text",
          maxLength: maxlengths[0]
        }}
        fieldTwoProps={{
          label: "LABEL",
          name: "label",
          placeholder: labelPlaceholder,
          component: renderSmallField,
          type: "text",
          maxLength: maxlengths[1]
        }}
        icon="checkmark"
        clickHandler={clickHandler}
      />
    </form>
  );
};

export default reduxForm({
  validate
})(ProfileAttribute);
