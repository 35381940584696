import styled from "styled-components";
import {
  height,
  space,
  width,
  maxWidth,
  minWidth,
  zIndex,
  // bg,
  top,
  bottom,
  left,
  right,
  fontSize,
  color,
  alignItems,
  borderRadius,
  justifyContent,
  display
} from "styled-system";


  // ${bg}
export const AbsoluteDiv = styled.div`
  ${width}
  ${height}
  ${maxWidth}
  ${minWidth}
  ${space}
  ${color}
  ${fontSize}
  ${zIndex}
  ${top}
  ${borderRadius}
  ${bottom}
  ${left}
  ${right}
  ${alignItems}
  ${justifyContent}
  ${display}
  cursor:${props => (props.cursor ? props.cursor : "inherit")}
  overflow:scroll;
  position:absolute;
`;
//overflow:${props => (props.overflow ? props.overflow : "visible")};
  