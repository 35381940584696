import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import AWS from "aws-sdk";
import { push } from "react-router-redux";
import { history } from "../../../store";
//utils
import { getUser } from "../../../utils/auth";
import { MapComponent } from "../../common/mapComponent";
//actions
import { actions } from "../../../actions/resources";
import { mainContentScrollTop } from "../../../actions/dashboardPresentation";
//components
import { NunitoSans } from "../../common/typography";
import InfoForm from "./edit/forms/info";
import Attributes from "./edit/attributes";
import { OutlineFlex, DashboardSmallCTA } from "../../common/forms";
import { Box, Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { submit } from "redux-form";
import { BackButton } from "../../common/backbutton";
import { error, success } from "../../../actions/alerts";
import {
  ImageTreatment, NewImageTreatment,
  CircleDropzoneSmall,
  CircleDropzoneLarge
} from "../../common/ui";
import ImageCrop from "../../common/imageCrop";
import { LinkedInSignInBubble } from "./linkedin";
import {defaultProfile} from "../../../utils/defaultLogos";
import axios from "axios";
import ReactTooltip from 'react-tooltip';
import { set } from "react-ga";

export class EditProfileMain extends PureComponent {
  _isMounted = false;
  state = {
    file: null,
    linkedinActivated: true,
    placeName: "San Francisco, California, 94117, US",
    address: "San Francisco",
    text: "",
    code: '',
    errorMessage: '',
    thePic: ''
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidMount(){
    this._fetchDependencies();
    this._isMounted = true;
    
      if (this._isMounted) {
        this.setState({
          placeName: "San Francisco, California, 94117, US",
          address: "San Francisco",
          text: ""
        });
      }
  
  }

  _handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: '',
    });
    axios.get(`${process.env.REACT_APP_API_URL}/liprofile?code=${data.code}&origin=${window.location.origin}`)
    .then(res => {
      //console.log(res.data)
      this.setState({
        liProfile: res.data,
      });
      this._linkedinSync(res.data)
    }).catch(err => console.log('ERROR',err));
  }

  _handleFailure = (error) => {
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    });    
  }



_fetchDependencies = async () => {
    const { fetchUser, match } = this.props;
    if (getUser() && getUser().id !== match.params.id) {
      history.push("/dashboard");
    }
    fetchUser(getUser().id, { edit: true });
  }

  _addressChange = async geolocation => {
    const { updateUser } = this.props;
    await updateUser(getUser().id, { location: geolocation });
  };

  _linkedinSync = async data => {
    const {
      createEmailAddress,
      createWebsite,
      fetchUser,
      updateUser,
      systemSettings
    } = this.props;
        
    const userId = getUser().id;
    
    let position = data.headline;
    if (data.positions && data.positions.values) {
      if (data.positions.values[0].title) {
        position = data.positions.values[0].title;
      }
    }

    // this._toDataURL(data.pictureUrl, async (data)=>{
      
    //   console.log(data)
      
      
    // })

    let imageFile = await this._urltoFile(data.pictureUrl,'test.png', 'image/png');
    
    
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });

    const s3Bucket = new AWS.S3({ 
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });
    const fileKey = `profile-photos/${moment().valueOf()}${getUser().id}`;
    let s3Data = {
      Key: fileKey,
      Body: imageFile,
      ContentType: imageFile.type,
      ACL: "public-read"
    };

    await s3Bucket.putObject(s3Data, async (err, response) => {
      // payload = {
      //   ...data,
      //   ...{ avatar: `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}` }
      // };
      // await updateUser(getUser().id, payload);
      // return redirect(`/dashboard/profile/${getUser().id}`);
    });
    
    data.pictureUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`

   

    let userPayload = {
      avatar: data.pictureUrl,
      first_name: data.firstName,
      last_name: data.lastName,
      linkedin: JSON.stringify({
        id: data.id,
        url: data.publicProfileUrl
      }),
      position: position
    };

    let emailPayload = {
      body: data.emailAddress,
      label: "LinkedIn",
      user_id: userId
    };

    let websitePayload = {
      body: data.publicProfileUrl,
      label: "LinkedIn",
      user_id: userId
    };

    await updateUser(userId, userPayload);
    await createEmailAddress(emailPayload);
    await createWebsite(websitePayload);
    fetchUser(userId, { edit: true });
    if (this._isMounted) {
      window.location.reload();
    }
  };

  _urltoFile=(url, filename, mimeType)=>{
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  
  _infoFormError  = async data => {  
    const { notifyError} = this.props;
    let errorMessage = "";
    if(data.first_name){
      errorMessage += data.first_name + ".  ";
    }
    if(data.last_name){
      errorMessage += data.last_name + ".  ";
    }
    if(data.position){
      errorMessage += data.position + ".";
    }
    notifyError(
      errorMessage
    ); 
  }

  _submitInfo = async data => {  
    const { updateUser, redirect } = this.props;
    const { file } = this.state;
    let payload = data;
    console.log(data)

    if (file) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });

      const s3Bucket = new AWS.S3({ 
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
      });
      const fileKey = `profile-photos/${moment().valueOf()}${getUser().id}`;
      let s3Data = {
        Key: fileKey,
        Body: file,
        ContentType: file.type,
        ACL: "public-read"
      };

      return s3Bucket.putObject(s3Data, async (err, response) => {
        payload = {
          ...data,
          ...{ avatar: `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}` }
        };
        await updateUser(getUser().id, payload);
        return redirect(`/dashboard/profile/${getUser().id}`);
      });
    }
    
    await updateUser(getUser().id, payload);
    return redirect(`/dashboard/profile/${getUser().id}`);
  };

  _handleBlur = async data =>  {
    this.setState({validating: true});
    //console.log(data)
  };

  onDrop = files => {
    
    const { notifySuccess, notifyError} = this.props;

    console.log("onDrop files",files);
    console.log("onDrop this.props",this.props);

    this.setState({ file: files[0], thePic: files[0].preview });
    let r = false;
    try {
     // console.log(files[0].name,files[0]);
      if(this._checkExtentsion(files[0].name)){
        r = true;
      }
    } catch(e){ /*bad good*/}

    if(r === true){
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  onCrop = file =>{
    
    const { notifySuccess, notifyError} = this.props;
    
    console.log("onCrop file",file);
    console.log("onCrop this.props",this.props);

    this.setState({ file: file, thePic: file.preview });
    let r = false;
    try {
     // console.log(files[0].name,files[0]);
      if(this._checkExtentsion(file.name)){
        r = true;
      }
    } catch(e){ /*bad good*/}

    if(r === true){
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  }

  _checkExtentsion(filename){
    let ext = filename.split('.').pop();
    ext = ext.toString().toLowerCase();
    if(ext === "png"||ext ==="jpg"||ext ==="jpeg"){
        return true;
    }
    return false;
  }

  render() {
    const { browser, user, systemSettings, updateUser, companySettings } = this.props;
    const { file, placeName, address, text, code, errorMessage, thePic } = this.state;
    
    
    if (user) {

      const emailAddresses = [
        {
          body: user.email,
          company: false,
          company_id: null,
          id: Date.now(),
          label: "Primary",
          status: "active",
          user_id: "615d214d-57b7-4d23-a6f3-09a250e89671",
          disabled: true
        },
        ...user.email_addresses
      ];
      
      return (
         <div style={{height: "89vh", overflow: "auto", paddingRight: "5px", paddingLeft: "2px", marginTop: "20px"}}>
        <Flex flexDirection="column">
          {browser.lessThan.large ? (
            <Flex
              mb="32px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <ImageTreatment
                img={(file && file.preview) || thePic || user.avatar || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
                blurRadius={"15px"}
                imageInset={0}
                borderRadius={130}
                innerBorderRadius={120}
                shadowOffset={0.1}
                padding={8}
                height={80}
                width={80}
              />
              {/* <Box ml="37px" data-tip="Click here or drag and drop to upload">
                
                <ImageCrop dropZoneType='circle_small' onCrop={this.onCrop} notifyError={this.props.notifyError} />
              </Box>
              <ReactTooltip /> */}
            </Flex>
          ) : (
                <Flex
                  mb="53px"
                  flexDirection="row"
                  justifyContent="center"
                  // alignItems="center"
                  width="100%"
                >


<NewImageTreatment
              key={[`NIT` + `${user.first_name}${user.last_name}`]}
              img={(file && file.preview) || (user && user.avatar) || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
              height={150}
              width={150}
              borderRadius="50%"
            />

                  {/* <ImageTreatment
                    img={(file && file.preview) || user.avatar || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
                    blurRadius={"15px"}
                    imageInset={0}
                    borderRadius={"130"}
                    innerBorderRadius={"120px"}
                    shadowOffset={0.1}
                    padding={10}
                    height={120}
                    width={120}
                  /> */}



                  <Box ml="62px" data-tip="Click here or drag and drop to upload">
                    {/* <CircleDropzoneLarge onDrop={this.onDrop} /> */}
                    <ImageCrop 
                      onDrop={this.onDrop}  
                      enableClick={true} 
                      dropZoneType='circle_large' 
                      onCrop={this.onCrop} 
                      notifyError={this.props.notifyError} />
                    
                  </Box>
                  <ReactTooltip />
                </Flex>
          )}
            
          {
            browser.lessThan.large
            ?
            null
            :
            <Flex
            mb="32px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >

            <DashboardSmallCTA
                  width="15%"
                  minWidth={["110px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  color="#FE4A49"
                  onClick={()=> updateUser(getUser().id, {avatar: null}).then(user => this.setState({thePic: user.payload.avatar}) )}

          >
                  DELETE PHOTO
          </DashboardSmallCTA>
          </Flex>
          }
           {!browser.lessThan.large ? (
            <Box mb={"20px"}>
          
          <LinkedInSignInBubble
            onFailure={this._handleFailure}
            onSuccess={this._handleSuccess}
          />
            </Box>
          ) : (
          <Box >
          
          <LinkedInSignInBubble
            onFailure={this._handleFailure}
            onSuccess={this._handleSuccess}
          />
          </Box>
          )}
          
          <InfoForm
            browser={browser}
            onSubmit={this._submitInfo}
            onSubmitFail={this._infoFormError}
            initialValues={{
              first_name: user.first_name,
              last_name: user.last_name,
              position: user.position,
              profile_private: user.profile_private
            }}
            privacyToolOn={systemSettings.privacy_active && companySettings.users_private }
          />
          {/* {browser.lessThan.large ? (
            <Box mb={"60px"}>
          
          <LinkedInSignInBubble
            onFailure={this._handleFailure}
            onSuccess={this._handleSuccess}
          />
            </Box>
          ) : null} */}
          <Box mb="64px">
            <Attributes
              onBlur={this._handleBlur}
              label="Phone Contacts"
              labelPlaceholder="e.g. Mobile"
              maxlengths={["20", "20"]}
              formLabel="NUMBER"
              list={user.phone_numbers}
              placeholder="e.g. 415-555-5555"
              createVar="Phone_number"
              fetchVar="Phone_numbers"
              refreshVar="fetchUser"
              resourceId={user.id}
            />
          </Box>
          <Box mb="64px">
            <Attributes
              onBlur={this._handleBlur}
              label="Websites"
              labelPlaceholder="e.g. Company"
              maxlengths={[null, "20"]}
              formLabel="URL"
              list={user.websites}
              placeholder="e.g. website.com"
              createVar="Website"
              fetchVar="Websites"
              refreshVar="fetchUser"
              resourceId={user.id}
            />
          </Box>
          <Box mb="64px">
            <Attributes
              onBlur={this._handleBlur}
              label="Email Addresses"
              labelPlaceholder="e.g. Support"
              maxlengths={[null, "20"]}
              formLabel="EMAIL"
              list={emailAddresses}
              placeholder="e.g. support@website.com"
              createVar="Email_address"
              fetchVar="Email_addresses"
              refreshVar="fetchUser"
              resourceId={user.id}
              user={user}
            />
          </Box>
          {browser.lessThan.large ? (
            <Box mt="40px" mb={66 * 5 + 100 + "px"}>
              <MapComponent
                placeName={
                  (user.location && user.location.place_name) || placeName
                }
                address={
                  user.location
                    ? `${user.location.address ? user.location.address : ""}`
                    : address
                }
                text={(user.location && user.location.text) || text}
                addressChange={this._addressChange}
              />
            </Box>
          ) : null}
          
        </Flex>
        </div>
      );
    }

    return null;
  }
}

export default (EditProfileMain = connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    createEmailAddress: bindActionCreators(
      actions.createEmail_address,
      dispatch
    ),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createWebsite: bindActionCreators(actions.createWebsite, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch)
  })
)(EditProfileMain));

let EditProfileNav = ({ dispatch }) => {
// const [hover, setHover]= useState(false);
return  (
    <Flex
      height={"100%"}
      width={"100%"}
      alignItems="flex-end"
      justifyContent="center"
    >
      <Flex
        height={["49px", "49px", "60px", "40px"]}
        width={"100%"}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <NunitoSans
          pt={["6px", "6px", "8px", "6px"]}
          fontSize={[14, 14, 18, 18]}
          fontWeight={["bold"]}
        >
          EDIT PROFILE
        </NunitoSans>
        <AbsoluteDiv
          left={["0px", "0px", "0px", "0px", "-20%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
          >
            <BackButton />
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-end"]}
          >
            {/* <OutlineFlex
              justifyContent="center"
              alignItems="center"
              height={["30px", "30px", "40px"]}
              width={["64px", "64px", "102px"]}
              onClick={() => dispatch(submit("profile-info"))}
              borderRadius="8px"
              
              color={"#2FD371"}
            >
              <NunitoSans
                pt={["2px", "4px"]}
                fontSize={["12px", "12px", "18px"]}
                fontWeight="bold"
                color="#2FD371"
              >
                SAVE
              </NunitoSans>
            </OutlineFlex> */}
            <DashboardSmallCTA
                width="15%"
                onClick={() => dispatch(submit("profile-info"))}
                minWidth={["80px","80px","122px", "122px"]}
                fontSize={["12px","12px","17px","17px"]}
                innerHeight={["30px","30px","40px","40px"]}
                // my="12px"
                // height="34px"
              >
                SAVE
              </DashboardSmallCTA>
          </Flex>
        </AbsoluteDiv>
      </Flex>
    </Flex>
  );
} 

EditProfileNav = connect(
  state => ({
    browser: state.browser,
    
  }),
  null
)(EditProfileNav);

export { EditProfileNav };



function numberParser(number){
  return new Intl.NumberFormat('en-US').format(number)
}