import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset, SubmissionError } from "redux-form";
import Modal from "react-modal";
import axios from "axios";
//utils
import { setUser } from "../../utils/auth";
import _ from "underscore";
//actions
import { actions } from "../../actions/resources";
import { success, error } from "../../actions/alerts";
//components
import RegisterForm from "./forms/register";


import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex, IconBox, Box } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";

import lockIcon from "../../images/lock_icon.svg";

// import {
//   history
// } from "store";

export class AuthRegister extends Component {

  state = {
    isModalOpen: false,
    emailPayload: {},
    companyPayload: {},
    inviteSuccess: false,
    inviteInProgress: false,
    referralCode: "",
    initialValueSet: false
  }

  constructor(props) {
    super(props);
  }

async componentDidMount() {
      const { redirect } = this.props;

      let domain, LeEmail = "";
      if(localStorage.getItem("LeEmail")&&localStorage.getItem("LeEmail")!==""){
        LeEmail = localStorage.getItem("LeEmail");
        domain = LeEmail.split("@")[1];
        document.getElementById("register-form")[4].value = LeEmail;
      }
      if(localStorage.getItem("LeCompany")&&localStorage.getItem("LeCompany")!==""){

        
        document.getElementById("register-form")[2].parentNode.style.display = "none";
        document.getElementById("register-form")[2].value = JSON.parse(localStorage.getItem("LeCompany")).name.replaceAll(/"/ig, '');
        document.getElementById("register-form")[2].disabled = true;
        document.getElementById("register-form")[2].style.color ="#777";
        //readOnly={props.readOnly}
      } else {
        // redirect(company.is_single_user_company ? "/individual" : "/a_ccount-type");

      }

  const urlParams = new URLSearchParams(window.location.search);
  let referralCode = urlParams.get("referral_code") || null;
  
  console.log("referralCode",referralCode);

  if(referralCode || !localStorage.getItem("LeEmail")){
    if(referralCode===null){
      referralCode="";
    }
    localStorage.setItem("referral_code",referralCode.toUpperCase());
    console.log("referral_code",referralCode.toUpperCase());
    redirect("/");
  }
  
  let companyPayload = await fetch(`${process.env.REACT_APP_API_URL}/companias/${domain}`);
  let company = await companyPayload.json();
   this.setState({companyPayload:company});
  // console.log("company",company);
  
  if(localStorage.getItem("referral_code")){
    referralCode = localStorage.getItem("referral_code");
  }
  // if(company.domain){    referralCode = "";    } 

  this.setState({ referralCode: referralCode })

  if (referralCode != "" && referralCode != null) {
    if(document.getElementById("register-form")){
      document.getElementById("register-form")[3].value = referralCode;
    }
  } else {     


    if(document.getElementById("register-form") && document.getElementById("register-form")[2]){
      document.getElementById("register-form")[2].disabled = false;
    }
    const invited_by = await fetch(`${process.env.REACT_APP_API_URL}/invite_lists?domain=${localStorage.getItem("LeDomain")}`)
    .then(response => response.json())
    .then(data => {
      if(data&&data[0]&&data[0].invited_by){
        return data[0].invited_by;
      } else {    
        if(document.getElementById("register-form") && document.getElementById("register-form")[3]){
          document.getElementById("register-form")[3].disabled = false;
        }    
        
        //document.getElementById("register-form")[3].parentNode.style.display = "block";
        return null;
      }
    });
    // console.log("invited_by",invited_by)
    // console.log("company.domain",company.domain);

      if(invited_by!==null){
      
        await fetch(`${process.env.REACT_APP_API_URL}/user/${invited_by}`)
        .then(response => response.json())
        .then(data => {
          // console.log("companias referralCode", data);
          // console.log("companias typeof companyPayload.id", typeof companyPayload.id);
          if(data.referral_code){
            if(company.id){
              document.getElementById("register-form")[3].value = "";          
              localStorage.setItem("referral_code","");
              document.getElementById("register-form")[2].disabled = false;
              document.getElementById("register-form")[3].disabled = true;
              document.getElementById("register-form")[3].parentNode.style.display = "none";
            } else {              
              //document.getElementById("register-form")[3].parentNode.style.display = "block";
              document.getElementById("register-form")[3].value = data.referral_code;          
              localStorage.setItem("referral_code",data.referral_code.toString().toUpperCase());
              console.log("referral_code",data.referral_code.toString().toUpperCase());
              document.getElementById("register-form")[2].disabled = false;
              document.getElementById("register-form")[3].disabled = true;
            }
  
          }
        });
      }

    setTimeout(() => {

      if(companyPayload.domain){
        document.getElementById("register-form")[3].value = "";        
      } else {
        if(localStorage.getItem("referral_code")&&localStorage.getItem("referral_code")!==""){
          // document.getElementById("register-form")[3].value = localStorage.getItem("referral_code");
        }
      }

      if(localStorage.getItem("LeEmail")&&localStorage.getItem("LeEmail")!==""){
        document.getElementById("register-form")[4].value = localStorage.getItem("LeEmail");
      }
      if(localStorage.getItem("LeCompany")&&localStorage.getItem("LeCompany")!==""){
        document.getElementById("register-form")[2].value = localStorage.getItem("LeCompany");
        document.getElementById("register-form")[2].disabled = true;
        //readOnly={props.readOnly}
      } else {
        // redirect(company.is_single_user_company ? "/individual" : "/a_ccount-type");

      }
    }, 1000)
  }

  }

  _throwError = async message => {
    throw new SubmissionError({ _error: message });
  };

  _handleSubmit = async data => {
    // console.log("handlesubmit",data);
    const { createUser, redirect, resetForm, notifySuccess, notifyError, referral_code } = this.props;
    const { companyPayload } = this.state;

    // if(!data.terms){
    //   return this._throwError('You need to accept terms and conditions.');
    // }

    let LeEmail = data.email;
    LeEmail = LeEmail.toString().toLowerCase();
    data.email = LeEmail;
    let domain = LeEmail.split("@")[1];

    if(data.company){
      localStorage.setItem("LeCompany",JSON.stringify({"name":data.company}));
    }

    let dom_referral_code = document.getElementById("register-form")[3].value;
    if (data.referral_code || dom_referral_code != "") {
      let ref = dom_referral_code;
      if (data.referral_code) {
        ref = data.referral_code.replace(/[^a-z0-9]/gi, '');
      }
      ref = ref.toUpperCase();
      if (ref && ref.length) {
        data.referral_code = ref;
      } else {
        delete data.referral_code;
      }
    }

    const response = await createUser(_.omit(data, ["terms","company"]));

    if (response) {
console.log(response)
      if (response.payload.referral_error || response.payload.error || response.error) {
        
        document.getElementById("register-form")[3].disabled = false;        
        //document.getElementById("register-form")[3].parentNode.style.display = "block";
         
         if(document.getElementById("register-form")[3].value!==""){
          return this._throwError('Referral code is not valid.');
         } else if(response.payload.invite_status){
            
          return this.setState({
            isModalOpen: true, emailPayload: {
              first_name: data.first_name,
              last_name: data.last_name,
              email: LeEmail,
              invited_by: LeEmail,
              status: 'pending',
              domain
            }
          })
         }
      } else if (response.payload.invite_status) {

        if (response.payload.invite_status === 'not_found') {
          document.getElementById("register-form")[3].disabled = false;
          //document.getElementById("register-form")[3].parentNode.style.display = "block";
          return this.setState({
            isModalOpen: true, emailPayload: {
              first_name: data.first_name,
              last_name: data.last_name,
              email: LeEmail,
              invited_by: LeEmail,
              status: 'pending',
              domain
            }
          })

        } else if (response.payload.invite_status === 'pending') {

          return this._throwError('A request already submitted. We will contact you shortly.');

        } else {
          return this._throwError('Please contact our support team at support@kickpost.io');
        }



      } else {
        await setUser(response.payload);

        if (true || data.terms) {
          let kpPolicy = {
            "message": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
            "tospp": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
            "version": process.env.REACT_APP_POLICY_VERSION
          };
          localStorage.setItem("kpPolicy", JSON.stringify(kpPolicy));
        }
        

        if (response.payload.company_id || response.payload.is_single_user_company) {
          redirect(response.payload.is_single_user_company ? "/individual" : "/company");
        } else {
          redirect("/company-type");
          // redirect("/a_ccount-type");
        }
      }



    } else {

      const company = companyPayload;
      const userPayload = await fetch(`${process.env.REACT_APP_API_URL}/user/${LeEmail}`);
      const user = await userPayload.json();

      if (!user.company_id) {
        await setUser({
          ...user,
          is_single_user_company: company.is_single_user_company
        });

        let kpPolicy = {
          "message": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
          "tospp": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
          "version": process.env.REACT_APP_POLICY_VERSION
        };
        localStorage.setItem("kpPolicy", JSON.stringify(kpPolicy));
        redirect("/company-type");
        // redirect(company.is_single_user_company ? "/individual" : "/a_ccount-type");
      }

    }


    // resetForm("register");
    return this._throwError('Your email may be in the system already. Please try signing in.');

  };

  _handleInviteRequest = async () => {
    const { notifyError } = this.props;
    const { emailPayload } = this.state;

    this.setState({ inviteInProgress: true, inviteSuccess: false })

    try {


      await axios.post(`${process.env.REACT_APP_API_URL}/invite_list`, emailPayload).then(res => res.data);

      this.setState({ inviteInProgress: false, inviteSuccess: true })

      setTimeout(() => this.setState({ isModalOpen: false, }), 1000);

    } catch (err) {
      notifyError(err.toString());
      this.setState({ isModalOpen: false, inviteInProgress: false })
    }

  }


  render() {
    const { browser } = this.props;
    const { isModalOpen, inviteInProgress, inviteSuccess, referralCode } = this.state;
    console.log("le referralCode", referralCode)

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: browser.lessThan.large ? '50%' : "443px",
        // minHeight: "312px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: '1px solid #707070',
        borderRadius: '10px'
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }

    };

    return (
      <div className="auth-form">
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          style={customStyles}
          contentLabel="Access Modal"
          ariaHideApp={false}
        >

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Flex
              width='100%'
              justifyContent="flex-end"
            >
              <IconBox
                cursor="pointer"
                fontSize={["12px"]}
                color="#C7C7C7"
                onClick={() => this.setState({ isModalOpen: false })}
              >
                thinx
              </IconBox>
            </Flex>

            {/* <IconBox
             
              fontSize={["52px"]}
              color="#2FD371"
            >
                    thinx
            </IconBox> */}

            <img src={lockIcon} width="150"></img>

            <NunitoSansBold
              color='#000000'
              fontSize={['25px', '25px', '35px', '35px']}
              textAlign='center'
              style={{ marginBottom: '17px' }}
            >
              You're Not On The List
            </NunitoSansBold>

            <NunitoSans
              color='#000000'
              fontSize={['16px', '16px', '18px', '18px']}
              lineHeight='22px'
            >
              Unfortunately you haven’t been invited to KickPost. Request a demo below and we'll reach out to you soon. If you have an industry partner on KickPost already, you can ask them for an invite.
            </NunitoSans>

            <DashboardSmallCTA
              width="200px"
              minWidth={["74px"]}
              fontSize="15px"
              innerHeight="40px"
              mt='30px'
              onClick={() => !inviteInProgress && !inviteSuccess ? this._handleInviteRequest() : null}
              color="#2FD371"

            >
              {inviteSuccess ? "REQUESTED!" : "REQUEST DEMO"}
            </DashboardSmallCTA>

            <Box height="12px" />

          </Flex>

        </Modal>
        <RegisterForm onSubmit={this._handleSubmit} referralCode={referralCode} />
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    createUser: bindActionCreators(actions.createUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
  })
)(AuthRegister);
