import { getUser } from "../utils/auth";
import _ from "underscore";
import { uniqBy } from "lodash";
import moment from "moment";
import axios from "axios";

import {
  actions
} from "../actions/resources";

const algoliasearch = require("algoliasearch");

let client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const updateAudienceTotals = payload => ({
  type: "SET_AUDIENCE_RESULTS",
  payload
});

const updateCompanySearchResults = payload => ({
  type: "SET_COMPANY_RESULTS",
  payload
});

const updateProductSearchResults = payload => ({
  type: "SET_PRODUCT_RESULTS",
  payload
});

const updateMobileSearchResults = payload => ({
  type: "SET_MOBILE_RESULTS",
  payload
});

const updateProductGroupSearchResults = payload => ({
  type: "SET_PRODUCT_GROUP_RESULTS",
  payload
});

const updateProductCategorySearchResults = payload => ({
  type: "SET_PRODUCT_CATEGORY_RESULTS",
  payload
});

const updateCompanySearchRows = payload => ({
  type: "SET_COMPANY_ROWS",
  payload
});

export const updatePostSearchResults = payload => ({
  type: "SET_POST_RESULTS",
  payload
});

export const updateAssetSearchResults = payload => ({
  type: "SET_ASSET_RESULTS",
  payload
});



// export const updateProfilePostSearchResults = payload => ({
//   type: "SET_PROFILE_POST_RESULTS",
//   payload
// });

// export const updateProfileIncentiveSearchResults = payload => ({
//   type: "SET_PROFILE_INCENTIVE_RESULTS",
//   payload
// });

// export const updateCompanyPostSearchResults = payload => ({
//   type: "SET_COMPANY_POST_RESULTS",
//   payload
// });

// export const updateCompanyIncentiveSearchResults = payload => ({
//   type: "SET_COMPANY_INCENTIVE_RESULTS",
//   payload
// });

// export const updateCompanyUserSearchResults = payload => ({
//   type: "SET_COMPANY_USER_RESULTS",
//   payload
// });

export const updateNewsFeedSearchResults = payload => ({
  type: "SET_NEWSFEED_RESULTS",
  payload
});

export const updateIncentiveSearchResults = payload => ({
  type: "SET_INCENTIVE_RESULTS",
  payload
});

const updateGroupSearchResults = payload => ({
  type: "SET_GROUP_RESULTS",
  payload
});

const updateGroupSearchRows = payload => ({
  type: "SET_GROUP_ROWS",
  payload
});

const updateDepartmentSearchResults = payload => ({
  type: "SET_DEPARTMENT_RESULTS",
  payload
});

const updateDepartmentSearchRows = payload => ({
  type: "SET_DEPARTMENT_ROWS",
  payload
});

const updateEmployeeSearchResults = payload => ({
  type: "SET_EMPLOYEE_RESULTS",
  payload
});

const clearEmployeeSearchResults = payload => ({
  type: "SET_EMPLOYEE_RESULTS"
});

const updatePersonSearchResults = payload => ({
  type: "SET_PERSON_RESULTS",
  payload
});

const clearPersonSearchResults = payload => ({
  type: "SET_PERSON_RESULTS"
});

const updateSearchCategory = payload => ({
  type: "SET_SEARCH_CATEGORY",
  payload
});

const updateSearchCompany = payload => ({
  type: "SET_SEARCH_COMPANY",
  payload
});

const clearSearchCompany = payload => ({
  type: "SET_SEARCH_COMPANY",

});

const updateUserSearchResults = payload => ({
  type: "SET_USER_RESULTS",
  payload
});

const updateUserSearchRows = payload => ({
  type: "SET_USER_ROWS",
  payload
});

const expandCompanyIds = (vendorIds = []) => {
  let string = "";
  //console.log(vendorIds)

  if (!vendorIds.length) return null;

  vendorIds.forEach((id, i) => {
    //console.log(id.company_id)
    //console.log(id)
    if (i === 0) {
      string = string + `company_id:${id}`;
      //console.log(`company_id:${id}`)

    } else {
      string = string + ` OR company_id:${id}`;
      //console.log(`company_id:${id}`)
    }
  });

  return string;
};

const expandVendorIds = (vendorIds = []) => {
  let string = "";

  if (!vendorIds.length) return null;

  vendorIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `${id}`;
    } else {
      string = string + ` OR vendor_ids:${id}`;
    }
  });

  return string;
};


const expandDepartmentIds = (departmentIds = []) => {
  let string = "";

  if (!departmentIds.length) return null;

  departmentIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `${id}`;
    } else {
      string = string + ` OR department_ids:${id}`;
    }
  });

  return string;
};

const expandDepartmentAudienceIds = (departmentIds = []) => {
  let string = "";

  if (!departmentIds.length) return null;

  departmentIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `${id}`;
    } else {
      string = string + ` OR audience_ids:${id}`;
    }
  });

  return string;
};

const expandVendorCompanyAudienceIds = (vendorIds = []) => {
  let string = "";

  if (!vendorIds.length) return null;

  vendorIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `(all_connections:no AND company_id:${id})`;
    } else {
      string = string + ` OR (all_connections:no AND company_id:${id})`;
    }
  });

  return string;
};

const expandVendorCompanyIds = (vendorIds = []) => {
  let string = "";

  if (!vendorIds.length) return null;

  vendorIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `${id}`;
    } else {
      string = string + ` OR company_id:${id}`;
    }
  });

  return string;
};

const expandIndustryCategories = (categories = []) => {
  let string = '';

  if (!categories.length) return null;

  categories.forEach((category, i) => {
    if (i === 0) {
      string = string + `"${category}"`;
    } else {
      string = string + ` OR industry_categories:"${category}"`;
    }
  });

  return string;
};

const expandGroupIds = (groupIds = []) => {
  let string = "";

  if (!groupIds.length) return null;

  groupIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `${id}`;
    } else {
      string = string + ` OR group_ids:${id}`;
    }
  });

  return string;
};



const expandUserIds = (userIds = []) => {
  let string = "";

  if (!userIds.length) return null;

  userIds.forEach((id, i) => {
    if (i === 0) {
      string = string + `id:${id}`;
    } else {
      string = string + ` OR id:${id}`;
    }
  });

  return string;
};

export const searchConnectionCompanies = (input, paid = true, rewards_active = false, theCompanyId = null, audienceCompanies = [], includeSingleUsers = false, isPartnerIncentive = false) => {
  const companiesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX
  );



  if (!getUser()) return;

  let filters = `id:${theCompanyId || getUser().company_id}`;

  if (paid) {
    filters = `vendor_ids:${theCompanyId || getUser().company_id}`;

    if (rewards_active) {
      if (isPartnerIncentive) {
        filters += ` AND can_receive_vendor_incentives:true`
      } else {

        filters += ` AND can_receive_incentives:true`
      }
    }
  }

  filters += ` AND status:active`;

  if (!includeSingleUsers) {
    filters += ` AND is_single_user_company:false`;
  }



  let search = {
    query: input,
    filters: filters,
    distinct: true,
    hitsPerPage: 1017
  };
  if (audienceCompanies.length) {
    search = {
      query: input,
      hitsPerPage: 1040,
      facetFilters: [
        [...audienceCompanies.map(id => `id:${id}`)]
      ]
    };
  }

  if (true || !paid) {
    // search = { query: input, filters: `id:${getUser().company_id}` };
  }


  return dispatch => {
    return companiesIndex.search(search, (err, content) => {
      if (!err) {
        content.hits.forEach(filterNonHits);


        // if(rewards_active){
        //   content.hits = content.hits.filter(item=>item.rewards_active)
        // }

        function filterNonHits(index, arr, item) {
          //    console.log("hits searchConnectionCompanies")
          //  console.log(item)
        }

        let companyRows = content.hits.map(company => {
          return {
            type: "company",
            company_id: company.id,
            id: company.id,
            groups: company.groups,
            row: {
              id: company.objectID,
              name: company.name,
              image: company.logo,
              domain: company.domain,
              is_single_user_company: company.is_single_user_company,
              pseudoName: company.pseudoName || '',
              people: company.total_employee_count === 1 ?
                `${company.total_employee_count} Person` : `${company.total_employee_count} People`,
              total: company.total_employee_count
            }
          };
        });

        dispatch(updateCompanySearchRows(companyRows));
        return dispatch(updateCompanySearchResults(content));

      }
    });
  };
};

export const searchConnectionDepartments = (input, paid = false, rewards_active, audienceDepartments = []) => {
  const departmentsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_DEPARTMENTS_INDEX
  );

  let filters = `company_id:${getUser().company_id}`;
  if (paid) {
    filters = `vendor_ids:${getUser().company_id}`;

    if (rewards_active) {
      filters += " AND can_receive_incentives:true"
    }
  }


  let search = {
    query: input,
    filters: filters
  };

  if (audienceDepartments.length) {
    search = {
      query: input,
      facetFilters: [
        [...audienceDepartments.map(id => `id:${id}`)]
      ]
    };
  }

  return dispatch => {
    return departmentsIndex.search(search,
      (err, content) => {
        if (!err) {
          // if(rewards_active){
          //   content.hits = content.hits.filter(department => department.company.rewards_active)
          // }
          // console.log(content.hits)
          let departmentRows = content.hits.map(department => {
            return {
              type: "department",
              company_id: department.company_id,
              id: department.id,
              row: {
                id: department.objectID,
                name: department.name,
                image: department.company.logo,
                company: department.company.name,
                people: department.total_employee_count === 1 ?
                  `${department.total_employee_count} Person` : `${department.total_employee_count} People`,
                total: department.total_employee_count
              }
            };
          });

          return dispatch(updateDepartmentSearchRows(departmentRows));
        }
      }
    );
  };
};

export const searchConnectionGroups = (input, paid = false, rewards_active) => {
  const groupsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_GROUPS_INDEX
  );

  let filters = `company_id:${getUser().company_id}`;


  return dispatch => {
    return groupsIndex.search({
      query: input,
      filters: filters
    },
      (err, content) => {
        if (!err) {
          if (rewards_active) {
            // content.hits = content.hits.filter(group=> group.company.rewards_active)
            content.hits.forEach(group => {
              group.companies = group.companies.filter(company => company.can_receive_incentives);
              group.total_company_count = group.companies.length;
              group.total_people_count = group.companies.map(company => parseInt(company.total_employee_count)).reduce((a, b) => a + b, 0);
            });

          }
          // FILTERING SMART GROUPS TO HIDE ////
          // REMOVE FILTER TO UNHIDE ///
          let groupRows = content.hits.filter(group => !group.isManaged).map(group => {
            return {
              type: "group",
              company_id: group.company_id,
              id: group.id,
              row: {
                id: group.objectID,
                name: group.name,
                image: group.company.logo,
                company: group.company.name,
                isManaged: group.isManaged,
                people: group.total_people_count === 1 ?
                  `${group.total_people_count} Person` : `${group.total_people_count} People`,
                totalPeople: group.total_people_count,
                totalCompanies: group.total_company_count,
                companies: group.total_company_count === 1 ?
                  `${group.total_company_count} Company` : `${group.total_company_count} Companies`,
                companyIds: group.companies.map(company => company.id) || []
              }
            };
          });

          return dispatch(updateGroupSearchRows(groupRows));
        }
      }
    );
  };
};

export const searchConnectionUsers = (value, params = null, paid = false, rewards_active, audienceUsers = [], departmentsSearch = false) => {
  const usersIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_USERS_INDEX
  );
  if (!getUser()) return null;
  return dispatch => {
    let companyId = getUser().company_id;
    let filters = `company_id:${companyId}`;

    if (paid) {
      filters = `vendor_ids:${companyId}`;
    }

    if (departmentsSearch) {
      filters = `company_id:${companyId}`;
    }

    //6/1/2022
    if (filters) {
      filters = `${filters} AND status:'active'`;
    } else {
      filters = ` status:'active'`;
      // console.log("params", params);
      // console.log("departmentsSearch", departmentsSearch);
      // console.log("paid", paid);
      // console.log("companyId", companyId);
    }

    if (rewards_active) {
      filters += " AND can_receive_incentives:true"
    }
    
    //April 28 2022
    // hitsPerPage: departmentsSearch ? 1000 : 50
    let search = {
      query: value,
      filters: filters,
      hitsPerPage: departmentsSearch ? 1018 : 50
    };

    if (audienceUsers.length) {
      search = {
        query: value,
        facetFilters: [
          [...audienceUsers.map(id => `id:${id}`)]
        ]
      };
    }

    return usersIndex.search(search,
      (err, content) => {
        if (!err) {
          // if(rewards_active){
          //   content.hits = content.hits.filter(user=>user.company.rewards_active)
          // }
          let userRows = content.hits.map(user => {

            return {
              type: "person",
              company_id: user.company_id,
              department_ids: user.department_ids,
              id: user.id,
              row: {
                id: user.objectID,
                name: `${user.first_name} ${user.last_name}`,
                image: user.avatar,
                company: user.company.name,
                company_domain: user.company.domain,
                is_single_user_company: user.company.is_single_user_company
                // company: user._highlightResult.company.name.value
              }
            };
          });
          dispatch(updateUserSearchRows(userRows));
          return dispatch(updateUserSearchResults(content));
        }
      }
    );
  };
};

export const searchPeople = (personId, input = "") => {
  const inviteesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_INVITEES_INDEX
  );

  //console.log("actions_searchPeople  searchPeople ");
  //console.log("personId " + personId);
  return dispatch => {
    let facetfilter = "user_id:" + personId;
    return inviteesIndex.search(input, {
      "hitsPerPage": 10,
      "page": 0,
      "analytics": false,
      "attributesToRetrieve": "*",
      "getRankingInfo": true,
      "responseFields": "*",
      "facets": "*,user_id,id,person.id,invitee_id,person.email,person.last_name,person.first_name",
      "facetFilters": facetfilter
    },
      (err, content) => {
        if (!err) {
          if (content.nbHits > 0) {
            return dispatch(
              updatePersonSearchResults(content)
            );
          } else {
            return dispatch(
              updatePersonSearchResults([])
            );
            //return [];
          }
        }
      }
    );
  };
};


export const searchEmployees = (companyId, input = "") => {
  const usersIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_USERS_INDEX
  );


  // console.log("actions_search  searchEmployees ");
  // console.log("companyId " + companyId);
  return dispatch => {
    return usersIndex.search({
      query: input,
      filters: `company_id:${companyId}`,
      hitsPerPage: 20
    },
      (err, content) => {
        if (!err) {
          if (content.nbHits > 0) {
            return dispatch(
              updateEmployeeSearchResults(_.pluck(content.hits, "id"))
            );
          } else {
            return dispatch(
              updateEmployeeSearchResults(["1"])
            );
            //return [];
          }
        }
      }
    );
  };
};

export const searchDepartments = (companyId, input = "") => {
  const departmentsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_DEPARTMENTS_INDEX
  );


  // console.log("actions_search  searchDepartments ")

  return dispatch => {
    return departmentsIndex.search({
      query: input,
      filters: `company_id:${companyId}`,
      hitsPerPage: 20
    },
      (err, content) => {
        if (!err) {

          return dispatch(
            updateDepartmentSearchResults(_.pluck(content.hits, "id"))
          );
        }
      }
    );
  };
};

export const searchGroups = (companyId, input = "") => {
  const groupsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_GROUPS_INDEX
  );


  // console.log("actions_search  searchDepartments ")

  return dispatch => {
    return groupsIndex.search({
      query: input,
      filters: `company_id:${companyId}`,
      hitsPerPage: 20
    },
      (err, content) => {
        if (!err) {
          return dispatch(
            updateGroupSearchResults(_.pluck(content.hits, "id"))
          );
        }
      }
    );
  };
};

const _getConnections1 = async (user) => {
  return actions.fetchConnections({
    company_id: user.company_id,
    post: true
  });
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function filterGeneratorPosts(user,gotuser,userId,companyId="",caller=""){
  let filters = "";
  //console.log("caller ",caller,user,gotuser,userId)

    let mgrs  = gotuser.company_id + "-managers";
    if(user.id===gotuser.id){
      //if i am looking at my own posts, then i want to see all of them
      
      if(companyId===""){
        filters = ` user_id:${userId}`;
      } else {        
        filters = ` company_id:${companyId} `;
      }
    } else if(gotuser.company_id===user.company_id && gotuser.company_admin===true && user.id!==gotuser.id){
      //if i am at the same company and i am a manager, i want to see all of their posts

      if(companyId===""){
        filters = ` user_id:${user.id} `; //target user's profile id
      } else {        
        filters = ` company_id:${companyId} `;
      }
      // filters += `  AND ( audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
    
    } else if(gotuser.company_id===user.company_id){
      //if i am a user at the same company
      filters = `  start_date_unix <= ${moment().unix()} `; //start date
      filters += ` AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `; //end date
      if(companyId===""){
        filters += ` AND user_id:${user.id} `; //target user's profile id
      } else {        
        filters += ` AND company_id:${companyId} `;
      }
      filters += ` AND  (status:active OR status:closed)  `; 
      filters += ` AND (audience_ids:all_connections_yes OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to me or shared to my groups
    } else if(gotuser.company_admin===true){
      // i am a manager at a different company
      //IF THERE ARE PARTNER POSTS, HOW DO I SEE PARTNER POSTS?
      filters = ` (start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()})  `;
      if(companyId===""){
        filters += ` AND (user_id:${user.id}) `; //target user's profile id
      } else {        
        filters += ` AND (company_id:${companyId}) `;
      }
      filters += ` AND  (status:active OR status:closed) `; 
      filters += ` AND ( audience_ids:all_connections_yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to my company or shared to me or shared to my groups
    } else {
      //i am a user at a different company
      filters = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `;
      if(companyId===""){
        filters += ` AND user_id:${user.id} `; //target user's profile id
      } else {        
        filters += ` AND company_id:${companyId} `;
      }
      filters += ` AND  (status:active OR status:closed)  `; 
      filters += ` AND ( audience_ids:all_connections_yes OR audience_ids:${gotuser.company_id} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to my company or shared to me or shared to my groups
    }
  return filters;
}




function filterGeneratorIncentives(user,ngotuser,userId,companyId="",caller="",companySettings = {},
systemSettings = {},CAN_RECEIVE_PARTNER_INCENTIVES=false,CAN_RECEIVE_SALES_INCENTIVES=false,vendor_ids=[]){
  let filters = "";
  let extraFilters = '';
  const gotuser = getUser();
  //console.log("filterGeneratorIncentives caller ",caller,user,ngotuser,userId,companyId,gotuser)

  if (CAN_RECEIVE_PARTNER_INCENTIVES===true && CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraFilters = ` is_partner_incentive:true OR is_partner_incentive:false `;
  } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
    extraFilters = ` is_partner_incentive:true `;
  } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraFilters = ` is_partner_incentive:false `;
  } else {
    //cannot receive any
    extraFilters = ` is_partner_incentive:undefined `;
  }

  if(user&&user.company){

    //console.log("filterGeneratorIncentives CAN_RECEIVE ",CAN_RECEIVE_PARTNER_INCENTIVES,CAN_RECEIVE_SALES_INCENTIVES,extraFilters);
    //console.log("filterGeneratorIncentives CAN_RECEIVE ",systemSettings.rewards_active,systemSettings.vendor_incentives_active,systemSettings.vendor_companies_active,user.company.vendor_incentive_active,companySettings.receive_vendor_incentives,systemSettings.sales_companies_active,user.company.sales_incentive_active,companySettings.receive_incentives);
  }

    let mgrs  = gotuser.company_id + "-managers";
    if(caller==="searchIncentives"){
      
      filters = `  start_date_unix <= ${moment().unix()} `; //start date
      filters += ` AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `; //end date
      filters += ` AND  (status:active OR status:closed)  `; 
      // filters += ` AND ${expandVendorCompanyAudienceIds(vendor_ids)}`;
      // filters += ` AND all_connections:yes `;
      // filters += " AND (all_connections:yes AND company_id:a40a606e-85d3-4365-b940-176e7fc2c613)"
      filters += ` AND (all_connections:yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
      filters += ` AND (company_id:${expandVendorCompanyIds(vendor_ids)}) `;

      // ${expandVendorCompanyAudienceIds(vendor_ids)} OR 
    } else if(user.id===gotuser.id){
      //if i am looking at my own incentives, then i want to see all of them
      
      if(companyId===""){
        filters = ` user_id:${userId}`;
      } else {        
        filters = ` company_id:${companyId} `;
      }
    } else if(gotuser.company_id===user.company_id && gotuser.company_admin===true){
      //if i am at the same company and i am a manager, i want to see all of their incentives

      if(companyId===""){
        filters = ` user_id:${user.id} `; //target user's profile id
      } else {        
        filters = ` company_id:${companyId} `;
      }
      // filters += `  AND ( audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
    
    } else if(gotuser.company_id===user.company_id && gotuser.sales_id!==null){
      //if i am a sales person at the same company
      filters = `  start_date_unix <= ${moment().unix()} `; //start date
      filters += ` AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `; //end date
      if(companyId===""){
        filters += ` AND user_id:${user.id} `; //target user's profile id
      } else {        
        filters += ` AND company_id:${companyId} `;
      }
      filters += ` AND  (status:active OR status:closed)  `; 
      filters += ` AND (audience_ids:all_connections_yes OR audience_ids:${gotuser.company_id} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to me or shared to my groups    
    } else if(gotuser.company_id===user.company_id){
        //if i am a user at the same company
        filters = ` false `; //target user's profile id
    } else if(gotuser.company_admin===true){
      // i am a manager at a different company
      //IF THERE ARE PARTNER POSTS, HOW DO I SEE PARTNER POSTS?
      filters = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `;
      if(companyId===""){
        filters += ` AND user_id:${user.id} `; //target user's profile id
      } else {        
        filters += ` AND company_id:${companyId} `;
      }
      filters += ` AND  (status:active OR status:closed)  `; 
      filters += ` AND ( audience_ids:all_connections_yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to my company or shared to me or shared to my groups

      //written first of two times
      if (CAN_RECEIVE_PARTNER_INCENTIVES===false && CAN_RECEIVE_SALES_INCENTIVES===false) {
        filters = ` false `; //can't see any incentives
      } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true && CAN_RECEIVE_SALES_INCENTIVES===true) {
        //let it be... can see all incentives
      } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
        filters += ` AND is_partner_incentive:true `; //can see only partner incentives
      } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
        filters += ` AND is_partner_incentive:false `;  //can see only sales incentives
      } else {
        //should not run
        filters = ` false `; //can't see any incentives
      }
       
    } else if(gotuser.sales_id!==null){
      // i am a sales person at a different company
      //IF THERE ARE PARTNER POSTS, HOW DO I SEE PARTNER POSTS?
      filters = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `;
      if(companyId===""){
        filters += ` AND user_id:${user.id} `; //target user's profile id
      } else {        
        filters += ` AND company_id:${companyId} `;
      }
      filters += ` AND  (status:active OR status:closed)  `; 
      filters += ` AND ( audience_ids:all_connections_yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;//shared with the world or shared to my company or shared to me or shared to my groups
      
      //written second of two times
      if (CAN_RECEIVE_PARTNER_INCENTIVES===false && CAN_RECEIVE_SALES_INCENTIVES===false) {
        filters = ` false `; //can't see any incentives
      } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true && CAN_RECEIVE_SALES_INCENTIVES===true) {
        //let it be... can see all incentives
      } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
        filters += ` AND is_partner_incentive:true `; //can see only partner incentives
      } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
        filters += ` AND is_partner_incentive:false `;  //can see only sales incentives
      } else {
        //should not run
        filters = ` false `; //can't see any incentives
      }
       
    } else {
      //i am a user at a different company
      filters = ` false `;   
    }
    //console.log("filterGeneratorIncentives CAN_RECEIVE extraFilters",CAN_RECEIVE_PARTNER_INCENTIVES,CAN_RECEIVE_SALES_INCENTIVES,extraFilters);
    //console.log("filterGeneratorIncentives CAN_RECEIVE filters",CAN_RECEIVE_PARTNER_INCENTIVES,CAN_RECEIVE_SALES_INCENTIVES,filters);
    // filters = filters + " AND (" + extraFilters + ") ";
  return filters;
}

export const searchNewsFeed = (vendor_ids, user, input = "", page = 0, companySettings, systemSettings) => {
  let DMG = "const searchNewsFeed";

  if (!user) return;

  const gotUser = getUser();
  if(gotUser){
    const isConnection = vendor_ids.includes(getUser().company_id);    
    if(!isConnection){
      return {}
    }
  } else {
    return {}
  }
  
  //ORIGINAL BEFORE APRIL 2023
  let CAN_RECEIVE_PARTNER_INCENTIVES = user && user.company_admin && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && companySettings.receive_vendor_incentives;

  let CAN_RECEIVE_SALES_INCENTIVES = user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.receive_incentives;
  
 //console.log("3 CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("3 CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  
  if(CAN_RECEIVE_SALES_INCENTIVES===true){
    if(user.company_admin===true || user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
      if(user.company_admin===true){
        CAN_RECEIVE_SALES_INCENTIVES = true;
      }
    } 
  }

  if(CAN_RECEIVE_PARTNER_INCENTIVES!==true){
    // console.log("I AM USER",user.company_admin);
    if(user.company_admin===true){
      CAN_RECEIVE_PARTNER_INCENTIVES = true;
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }
  if(CAN_RECEIVE_PARTNER_INCENTIVES===true){
    if(user.company_admin!==true){
      CAN_RECEIVE_PARTNER_INCENTIVES = false;
    } else {
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }

  let extraIncentiveFilters = "";
  // if(systemSettings.sales_incentives_active && companySettings.receive_incentives && (!systemSettings.vendor_incentives_active ||!companySettings.receive_vendor_incentives)){
  //   //only receiving sales incentives

  //   extraIncentiveFilters = `is_partner_incentive:false`;

  // }else if(!companySettings.receive_incentives && user.company_admin && companySettings.receive_vendor_incentives && systemSettings.vendor_incentives_active){
  //   //only receiving partner incentives
  //   extraIncentiveFilters = `is_partner_incentive:true`;
  // }else if(systemSettings.sales_incentives_active && systemSettings.vendor_incentives_active && companySettings.receive_incentives && user.company_admin && companySettings.receive_vendor_incentives){
  //   //receives both
  //   extraIncentiveFilters = `is_partner_incentive:false OR is_partner_incentive:true`;
  // }else{
  //   //do not receive any incentives. Only company's incentives are visible
  //   extraIncentiveFilters = `company_id:${user.company_id}`;

  //   if(!user.company_admin){
  //     //cannot see any partner incentives
  //     extraIncentiveFilters+= ` AND is_partner_incentive:false`
  //   }
  // } 

  /*if (CAN_RECEIVE_PARTNER_INCENTIVES===true && CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraIncentiveFilters = `is_partner_incentive:true OR is_partner_incentive:false`;
  } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
    extraIncentiveFilters = `is_partner_incentive:true`;
  } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraIncentiveFilters = `is_partner_incentive:false`;
  } else {
    //cannot receive any but their own

    // if(user.company_admin===true || user.sales_id && user.sales_id.length>4){
    if(user.company_admin===true){
      extraIncentiveFilters = ` is_partner_incentive:true `;
    } else {      
      extraIncentiveFilters = `is_partner_incentive:undefined`;
    }
  }*/


  if (CAN_RECEIVE_PARTNER_INCENTIVES === true && CAN_RECEIVE_SALES_INCENTIVES === true) {
    extraIncentiveFilters = `is_partner_incentive:true OR is_partner_incentive:false OR company_id:${user.company_id}`;
  } else if (CAN_RECEIVE_PARTNER_INCENTIVES === true) {
    extraIncentiveFilters = `is_partner_incentive:true OR company_id:${user.company_id}`;
  } else if (CAN_RECEIVE_SALES_INCENTIVES === true) {
    extraIncentiveFilters = `is_partner_incentive:false OR company_id:${user.company_id}`;
  } else {
    //cannot receive any
    extraIncentiveFilters = `is_partner_incentive:undefined`;
  }





  let filters;
/*
  filters = ` vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  filters += ` OR company_ids:${user.company_id}`; //if a post was shared to the user's company
  filters += ` OR (company_ids:${ user.company_id })`; //if a post was created by a co-worker
  filters += ` OR (company_id:${ process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID })`; //if a post was created by KickPost 
*/
 
filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
filters += ` OR (company_ids:${ user.company_id })`; //if a post was created by a co-worker
filters += ` OR (company_id:${ process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID })`; //if a post was created by KickPost 
filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
// filters += ` OR (all_connections:yes AND (company_id:${expandVendorCompanyIds(vendor_ids)})) `;
filters += ` OR user_id:${user.id}`; //if a post was created by this user...
filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`; //if a post was shared to a department the user is in 



  let filters2 = ` OR ( user_ids:${user.id} ) `; //if a post was shared to this user...
  filters2 += ` OR ( user_id:${user.id})`; //if a post was created by this user...
  filters2 += ` OR (department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}) `; //if a post was shared to a department the user is in
  // filters2 = ""

  //FILTERS1 for all connections no audience
  // const postFILTERS1 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:no AND (${filters})`;

  let postFILTERS1;
  if(user.company_admin===true){    
    filters += ` OR (company_id:${ user.company_id })`; //if a post was created by a co-worker
    postFILTERS1 = `  all_connections:no AND ${filters}  ${filters2} `;
    //postFILTERS1 = `  all_connections:no AND (company_id:${expandVendorCompanyIds(vendor_ids)})`;
  } else {
    postFILTERS1 = ` (start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()} AND all_connections:no) AND ${filters}  ${filters2} `;
  }

  //FILTERS2 for all connections yes audience
  let postFILTERS2 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()} AND all_connections:yes AND (company_id:${expandVendorCompanyIds(vendor_ids)}) AND  (status:active OR status:closed)  `; 

  // postFILTERS1 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND audience_ids:${user.company_id} `;

  // // restrictSearchableAttributes: [ 'audience_ids' ]


  // postFILTERS2 = postFILTERS1;

  let FILTERS3Test = `  start_date_unix <= ${moment().unix()} `; //start date
  FILTERS3Test += ` AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `; //end date
  FILTERS3Test += ` AND all_connections:no `; //not shared with everyone
  
  let mgrs  = user.company_id + "-managers";
  if(user.company_admin===true){    
    FILTERS3Test += ` AND ( audience_ids:${user.company_id} OR audience_ids:${mgrs} OR audience_ids:${user.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(user.departments, "id"))}) `;//shared with the world or shared to me or shared to my groups
  } else {
    FILTERS3Test += ` AND ( audience_ids:${user.company_id} OR audience_ids:${user.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(user.departments, "id"))}) `;//shared with the world or shared to me or shared to my groups
  }
  
  FILTERS3Test += ` AND  (status:active OR status:closed)  `; 


  // let FILTERS3Test = filterGeneratorPosts(user,getUser(),user.id);

//console.log("apostFILTERS3",FILTERS3Test)
postFILTERS1 = FILTERS3Test
  //console.log("apostFILTERS1",postFILTERS1);

  //console.log("apostFILTERS2",postFILTERS2);


  let incentiveFILTERS1 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:no AND (status:active OR status:closed) AND ${extraIncentiveFilters} AND (${filters})`;

  //FILTERS2 for all connections yes audience
  let incentiveFILTERS2 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:yes AND (status:active OR status:closed) AND (company_id:${expandVendorCompanyIds(vendor_ids)}) AND ${extraIncentiveFilters}`;

  // if(!user.company_admin || (!companySettings.create_vendor_incentives && !companySettings.receive_vendor_incentives)){
  //   const  partnerIncentiveFilter = ` AND is_partner_incentive:false`;
  //   incentiveFILTERS1+=partnerIncentiveFilter;
  //   incentiveFILTERS2+=partnerIncentiveFilter;
  // }

  if(extraIncentiveFilters === `is_partner_incentive:undefined`){
    //block non admins and non sales_id employees from querying incentives
    incentiveFILTERS1 =  extraIncentiveFilters;
    incentiveFILTERS2 =  extraIncentiveFilters;
  }

  
  if(user.company_admin===true){
    incentiveFILTERS2 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND (status:active OR status:closed) AND all_connections:yes AND (company_id:${expandVendorCompanyIds(vendor_ids)}) AND (${extraIncentiveFilters})`;
  }

 //console.log(DMG + "3DD CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log(DMG + "3DD CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  

  const postsQuery1 = {
    indexName: process.env.REACT_APP_ALGOLIA_POSTS_INDEX,
    query: input,
    params: {
      filters: postFILTERS1,
      // restrictSearchableAttributes: [ 'audience_ids' ],
      hitsPerPage: 26,
      page: page
    }
  };
  const postsQuery2 = {
    indexName: process.env.REACT_APP_ALGOLIA_POSTS_INDEX,
    query: input,
    params: {
      hitsPerPage: 27,
      // restrictSearchableAttributes: [ 'audience_ids' ],
      page: page,
      filters: postFILTERS2
    }

  };


  const incentivesQuery1 = {
    indexName: process.env.REACT_APP_ALGOLIA_INCENTIVES_INDEX,
    query: input,
    params: {
      distinct: true,
      filters: incentiveFILTERS1,
      hitsPerPage: 28,
      page: page
    }
  };

  const incentivesQuery2 = {
    indexName: process.env.REACT_APP_ALGOLIA_INCENTIVES_INDEX,
    query: input,
    params: {
      distinct: true,
      hitsPerPage: 29,
      page: page,
      filters: incentiveFILTERS2
    }

  };



  //console.log(DMG + " user ", user );

  //console.log(DMG + "(postsQuery1);",postsQuery1);

  //console.log(DMG + "(incentiveFILTERS1);",incentiveFILTERS1);

  //console.log(DMG + "(incentiveFILTERS2);",incentiveFILTERS2);
  const queries1 = [];
  const queries2 = [];
  queries1.push(postsQuery1);
  queries2.push(postsQuery2);

  // if(systemSettings.rewards_active && (companySettings.receive_incentives || companySettings.create_incentives)){
  //   queries1.push(incentivesQuery1);
  //   queries2.push(incentivesQuery2);
  // }

  if (
    systemSettings.rewards_active
    &&
    (
      (companySettings.receive_incentives || companySettings.create_incentives)
      ||
      (
        user.company_admin
        &&
        (companySettings.create_vendor_incentives || companySettings.receive_vendor_incentives)
      )
    )
  ) {
    queries1.push(incentivesQuery1);
    queries2.push(incentivesQuery2);
  }


  queries1.push(incentivesQuery1);
  queries2.push(incentivesQuery2);

  return async dispatch => {

    const content1 = await client.search(queries1).then(res => res.results);
    let content2 = await client.search(queries2).then(res => res.results);
// content2 = []
    let posts = [...content1[0].hits, ...content2[0].hits].filter(post => post.status === 'active');
    // posts = [...content1[0].hits].filter(post => post.status==='active')
    let incentives = [];
    let nbPages = [content1[0].nbPages, content2[0].nbPages].sort((a, b) => a - b)[1];

    if (systemSettings.rewards_active && (companySettings.receive_incentives || companySettings.create_incentives) && (companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) {
      incentives = [...content1[1].hits, ...content2[1].hits].filter(incentive => incentive.status === 'active' || incentive.status === 'closed');
      nbPages = [content1[0].nbPages, content1[1].nbPages, content2[0].nbPages, content2[1].nbPages].sort((a, b) => a - b)[3];
    }
    
    incentives = [...content1[1].hits, ...content2[1].hits].filter(incentive => incentive.status === 'active' || incentive.status === 'closed');
    nbPages = [content1[0].nbPages, content1[1].nbPages, content2[0].nbPages, content2[1].nbPages].sort((a, b) => a - b)[3];

    //console.log("truth",(systemSettings.rewards_active && (companySettings.receive_incentives || companySettings.create_incentives) && (companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) )


    //console.log("truth1",systemSettings.rewards_active )
    //console.log("truth2",companySettings.receive_incentives )
    //console.log("truth3",companySettings.create_incentives )
    //console.log("truth4",companySettings.receive_vendor_incentives )
    //console.log("truth5",companySettings.create_vendor_incentives )
    // console.log("truth6",systemSettings.rewards_active )





    let postCompanyIds = posts.map(hit => hit.company_id);
    postCompanyIds = _.uniq(postCompanyIds);
    let paidPostCompanyIds = [];
    paidPostCompanyIds.push(user.company_id);

    for (let i = 0; i < postCompanyIds.length; i++) {

      const companyId = postCompanyIds[i];

      let company = await fetch(`${process.env.REACT_APP_API_URL}/companies/${companyId}`).then(res => res.json());

      if (company && company.paid === true) {
        paidPostCompanyIds.push(companyId); // adding the user's company so they  can see their company's posts even they are not a paid company 

      }
    }

    // posts = posts.filter(post => paidPostCompanyIds.includes(post.company_id));    

    if (!companySettings.receive_incentives && companySettings.create_incentives) {
      incentives = incentives.filter(incentive => incentive.company_id === user.company_id)
    }

    const hits = uniqBy([...posts, ...incentives], 'id').filter(item => vendor_ids.includes(item.company_id)).sort((a, b) => b.updated_at - a.updated_at);





    const payload = {
      hits,
      nbPages,
      page
    };


    return dispatch(updateNewsFeedSearchResults(payload));
  }





}

export const searchProfilePosts = ({
  input = '',
  user = {},
  userId = ''
}) => {
//you can only search if you are connected

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_POSTS_INDEX);
  let gotuser = getUser();

  let filters = filterGeneratorPosts(user,gotuser,user.id,"","searchProfilePosts");
//  let filters = filterGeneratorPosts(user,gotuser,userId);
//user,gotuser,userId,companyId="",caller



  // if (user.id === userId) {
  //   filters = `user_id:${userId}`;
  // } else {
  //   filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  //   filters += ` OR all_connections:yes`;
  //   filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
  //   filters += ` OR user_id:${user.id}`; //if a post was created by this user...
  //   filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`;

  //   filters = `user_id:${userId} AND status:active AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND (${filters})`;
  // }


  

//if user is not a manager
//if a post is by your company and you are not a manager, you should not see it
//and not company-id

// console.log("filtersz user",user);
// console.log("filtersz input",input)
// console.log("filtersz",filters,user, gotuser, userId,input)

  const postsQuery = {
    query: input,
    filters,
    hitsPerPage: 22
  };
  return async dispatch => {
    const content = await index.search(postsQuery);
    return dispatch(updatePostSearchResults(content));
  }
}

export const searchCompanyPosts = ({
  input = '',
  user = {},
  companyId = ''
}) => {

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_POSTS_INDEX);
  let hitsPerPage = 20;
  // let filters;
  const gotuser = getUser();

  
  let filters = filterGeneratorPosts({"id":companyId,"company_id":companyId},gotuser,user.id,companyId,"searchCompanyPosts");


  // filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  // filters += ` OR all_connections:yes`;

 ////console.log("searchCompanyPosts user",user)
  /*if(user.company_admin!==true){
    filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
    filters += ` OR user_id:${user.id}`; //if a post was created by this user...
    filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`;
    filters = `company_id:${companyId} AND status:active AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()}  AND (${filters})`;
  
  } else {
    filters = `company_id:${companyId} AND (${filters})`;  
    hitsPerPage = 75;
  }*/

  // console.log("searchCompanyPosts filters",filters)
  // console.log("searchCompanyPosts filterssearchCompanyPosts",filterssearchCompanyPosts);
  const postsQuery = {
    query: input,
    filters,
    // restrictSearchableAttributes: [ 'audience_ids' ],
    hitsPerPage: hitsPerPage
  };



  return async dispatch => {

    const content = await index.search(postsQuery);


    return dispatch(updatePostSearchResults(content));
  }

}

export const searchCompanyAssets = ({
  input = '',
  user = {},
  companyId = ''
}) => {

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_ASSETS_INDEX);//assets-merge
  let hitsPerPage = 20;  
  let filters =  ` company_id:${companyId}  AND status:active  `; 

  console.log("searchCompanyAssets user",user)
  
  console.log("searchCompanyAssets input",input)
  
  const assetsQuery = {
    query: input,
    filters,
    hitsPerPage: hitsPerPage
  };

  return async dispatch => {
    const content = await index.search(assetsQuery);
    return dispatch(updateAssetSearchResults(content));
  }

}

export const searchProfileIncentives = ({
  input = '',
  user = {},
  userId = '',
  companySettings = {},
  systemSettings = {}
}) => {

  let DMG =" searchProfileIncentives "

  //console.log(DMG+" user", user);
  //console.log(DMG+" userId", userId);
let leUser = getUser();
//console.log(DMG+" leUser", leUser);

  if (!user || (!systemSettings.sales_incentives_active && !systemSettings.vendor_incentives_active)) return;

  let CAN_RECEIVE_PARTNER_INCENTIVES = user && user.company_admin && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && companySettings.receive_vendor_incentives;

  let CAN_RECEIVE_SALES_INCENTIVES = user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.receive_incentives;
 //console.log("4 searchProfileIncentives CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("4 searchProfileIncentives CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  
  if(CAN_RECEIVE_SALES_INCENTIVES===true){
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
      if(user.company_admin===true){
        CAN_RECEIVE_SALES_INCENTIVES = true;
      }
    } 
  } else{
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }
  if(CAN_RECEIVE_PARTNER_INCENTIVES===false){
    if(user.company_admin===true){
      CAN_RECEIVE_PARTNER_INCENTIVES = true;
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }

  if(CAN_RECEIVE_PARTNER_INCENTIVES===true){
    if(user.company_admin!==true){
      CAN_RECEIVE_PARTNER_INCENTIVES = false;
    } else {
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_INCENTIVES_INDEX);
  
  let filters;
  let extraFilters = '';
  if (userId === user.id) {
    filters = `user_id:${userId}`;
  } else {
//WHY WOULD I NOT BE MYSELF?
  if (CAN_RECEIVE_PARTNER_INCENTIVES===true && CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraFilters = `is_partner_incentive:true OR is_partner_incentive:false`;
  } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
    extraFilters = `is_partner_incentive:true`;
  } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
    extraFilters = `is_partner_incentive:false`;
  } else {
    //cannot receive any
    extraFilters = `is_partner_incentive:undefined`;
  }

  // console.log(DMG,"user",user)
  filters = ` vendor_ids:${user.company_id}`;

  // filters += ` OR all_connections:yes`;
  // filters += ` OR user_ids:${user.id}`;
  // filters += ` OR user_id:${user.id}`;
  // filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`;


  filters = `user_id:${userId}  AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()}  AND (${filters})`
  // AND (status:active OR status:closed)`;

  if (extraFilters) {
    filters += ` AND (${extraFilters})`
  }
  }
  let caller="searchProfileIncentives";
  let filtersIncentives = filterGeneratorIncentives(user,getUser(),userId,"",caller,companySettings,
  systemSettings,CAN_RECEIVE_PARTNER_INCENTIVES,CAN_RECEIVE_SALES_INCENTIVES);

  //console.log(caller+"-extraFilters",extraFilters);
  //console.log(caller+"-systemSettings",systemSettings);
  //console.log(caller+"-companySettings",companySettings);
  //console.log(caller+"-filters",filters);
  //console.log(caller+"-filtersIncentives",filtersIncentives);
  filters = filtersIncentives;

  const incentivesQuery = {
    query: input,
    filters: `${filters}`,
    hitsPerPage: 24
  };

  return async dispatch => {
    const content = await index.search(incentivesQuery);
    return dispatch(updateIncentiveSearchResults(content));
  }
}


export const searchCompanyIncentives = ({
  input = '',
  user = {},
  companyId = '',
  companySettings = {},
  systemSettings = {}
}) => {

  if (!user || (!systemSettings.sales_incentives_active && !systemSettings.vendor_incentives_active)) return;

  let CAN_RECEIVE_PARTNER_INCENTIVES = user && user.company_admin && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && (companySettings && companySettings.create_vendor_incentives || companySettings.receive_vendor_incentives);

  let CAN_RECEIVE_SALES_INCENTIVES = (user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.receive_incentives);
 //console.log("5 searchCompanyIncentives CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("5 searchCompanyIncentives CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  
  if(CAN_RECEIVE_SALES_INCENTIVES===true){
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    } else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
    } 
  } else {
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    } else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
    } 
  }

  if(CAN_RECEIVE_PARTNER_INCENTIVES!==true){
    if(user.company_admin===true){
      CAN_RECEIVE_PARTNER_INCENTIVES = true;
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }

  if(CAN_RECEIVE_PARTNER_INCENTIVES===true){
    if(user.company_admin!==true){
      CAN_RECEIVE_PARTNER_INCENTIVES = false;
    } else {
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }



  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_INCENTIVES_INDEX);
  let filters;
  let hitsPerPage = 20;
  let extraFilters = '';
 //console.log("1 searchCompanyIncentives CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("1 searchCompanyIncentives CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES);

  // if(CAN_RECEIVE_PARTNER_INCENTIVES===false){
    // if(user.company_admin===true){
    //   CAN_RECEIVE_PARTNER_INCENTIVES = true;
    // } else {
    //   CAN_RECEIVE_PARTNER_INCENTIVES = false;
    // }
    // if(user.sales_id && user.sales_id.length>4){
    //   CAN_RECEIVE_SALES_INCENTIVES = true;
    // } else {
    //   CAN_RECEIVE_SALES_INCENTIVES = false;
    // } 
  // }

  
 //console.log("0 searchCompanyIncentives CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("0 searchCompanyIncentives CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)

  if (user.company_id !== companyId) {

    if (CAN_RECEIVE_PARTNER_INCENTIVES===true&& CAN_RECEIVE_SALES_INCENTIVES===true) {
      extraFilters = ` is_partner_incentive:true OR is_partner_incentive:false `;
    } else if (CAN_RECEIVE_PARTNER_INCENTIVES===true) {
      extraFilters = ` is_partner_incentive:true `;
    } else if (CAN_RECEIVE_SALES_INCENTIVES===true) {
      extraFilters = ` is_partner_incentive:false `;
    } else {
      //cannot receive any
      extraFilters = ` is_partner_incentive:undefined `;
    }
  }

  filters = `vendor_ids:${user.company_id}`; 
  filters += ` OR all_connections:yes`;

  if(user.company_admin!==true){
    filters += ` OR user_ids:${user.id}`; 
    filters += ` OR user_id:${user.id}`; 
    filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`;
    filters = `company_id:${companyId} AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND (status:active OR status:closed) AND (${filters})`;
  
  } else {
    filters = `company_id:${companyId} `;  
    // filters = `company_id:${companyId} AND (${filters})`;  
    hitsPerPage = 75;
  }

  if (extraFilters) {
    filters += ` AND (${extraFilters})`
  }

  let caller="searchCompanyIncentives";
  let filtersIncentives = filterGeneratorIncentives({"id":companyId,"company_id":companyId},getUser(),user.id,companyId,caller,companySettings,
  systemSettings,CAN_RECEIVE_PARTNER_INCENTIVES,CAN_RECEIVE_SALES_INCENTIVES);

  //console.log(caller+"-extraFilters",extraFilters);
  //console.log(caller+"-systemSettings",systemSettings);
  //console.log(caller+"-companySettings",companySettings);
  //console.log(caller+"-filters",filters);
  //console.log(caller+"-filtersIncentives",filtersIncentives);
  filters = filtersIncentives;

  const incentivesQuery = {
    query: input,
    filters,
    hitsPerPage: hitsPerPage
  };

  return async dispatch => {
    const content = await index.search(incentivesQuery);
    return dispatch(updateIncentiveSearchResults(content));
  }
}

export const searchCompanyUsers = ({
  input,
  companyId = ''
}) => {

  const index = client.initIndex(process.env.REACT_APP_ALGOLIA_USERS_INDEX);

  const filters = `company_id:${companyId} AND status:active`;

  const usersQuery = {
    query: input,
    filters,
    hitsPerPage: 20
  };

  return async dispatch => {

    const content = await index.search(usersQuery);


    return dispatch(updateUserSearchResults(content));
  }

}

export const searchPosts = (vendor_ids, user, input = "", page = 0, pending = false, systemSettings = {}) => {
  //export const searchPosts = (user, input = "", page = 0) => {
let DMG = "export const searchPosts";
  /*
  1) this method drives News Feed page and Search page 
  2) in this method, the first search is the orginal one split up to be more legible and easier to test. the second search add the ability for a connection to see all the previous posts of a company. To accomplish thise the vendor_ids have been included as a facet filter 
  3) these queries are being done again Algolia's indices: posts-staging, posts-demo, posts-production
  4) ideally, we could use Heroku to Algolia (Server to server communication) in the future to reduce demand on the browser to resolve the two searches. 
  */
  //console.log(DMG,vendor_ids, user, input, page) 


  //console.log(DMG,"searchCompanyPosts user",user)

/*

let index = client.initIndex(process.env.REACT_APP_ALGOLIA_POSTS_INDEX);
  let hitsPerPage = 20;
  let filters;

  filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  filters += ` OR all_connections:yes`;

  if(user.company_admin!==true){
    filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
    filters += ` OR user_id:${user.id}`; //if a post was created by this user...
    filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`;
    filters = `company_id:${companyId} AND status:active AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()}  AND (${filters})`;
  
  } else {
    filters = `company_id:${companyId} AND (${filters})`;  
    hitsPerPage = 75;
  }


  const postsQuery = {
    query: input,
    filters,
    hitsPerPage: hitsPerPage
  };

*/

const gotUser = getUser();
if(gotUser){
  const isConnection = vendor_ids.includes(getUser().company_id);    
  if(!isConnection){
    return {}
  }
} else {
  return {}
}

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_POSTS_INDEX);
  let filters;
  let status = pending ? "pending" : "active";

  filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  filters += ` OR company_ids:${user.company_id}`; //if a post was shared to the user's company
  filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
  filters += ` OR user_id:${user.id}`; //if a post was created by this user...
  filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`; //if a post was shared to a department the user is in 

  // SearchPostFILTERS1 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND audience_ids:${user.company_id} `;
  // FILTERS2 = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND audience_ids:${user.company_id} `;
  // FILTERS2 += ` OR audience_ids:${user.id} `;
  // FILTERS2 += ` OR audience_ids:${expandDepartmentAudienceIds(_.pluck(user.departments, "id"))} `;
  // FILTERS2 += ` OR (audience_ids:all_connections_yes AND audience_ids:${user.company_id})`;

  
  // FILTERS2 = `  start_date_unix <= ${moment().unix()} `; //start date
  // FILTERS2 += ` AND end_date_unix >= ${moment().subtract(1, 'd').unix()}  `; //end date
  // filters += ` AND all_connections:no `; //end date
  filters = `  audience_ids:${user.company_id} OR audience_ids:${user.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(user.departments, "id"))} `;//shared with the world or shared to me or shared to my groups




  // restrictSearchableAttributes: [ 'audience_ids' ]


  //FILTERS1 for all connections no audience
  let SearchPostFILTERS1= pending ? `all_connections:no AND status:pending AND (${filters})` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:no AND (${filters})`;
  // SearchPostFILTERS1= pending ? `all_connections:no AND status:pending AND (${filters})` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:no `;

  // let FILTERS1 = pending ? `all_connections:no AND status:pending` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:no AND (${filters})`;




  //console.log(DMG,"SearchPostFILTERS1",SearchPostFILTERS1);
  
  
  
  let FILTERS_DATES = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} `;

  const gotuser = getUser();
  //console.log(DMG,"user",user);
  //console.log(DMG,"gotuser",gotuser);
  let mgrs  = gotuser.company_id + "-managers";
  let audience_limiting_filter = ``;
 
  if(user.company_admin === true){
    audience_limiting_filter = ` (all_connections:yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
  } else {
    audience_limiting_filter = ` (all_connections:yes OR audience_ids:${gotuser.company_id} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
  } 
  let FILTERS1 = FILTERS_DATES + ` AND status:active AND ( company_id:${expandVendorCompanyIds(vendor_ids)} ) AND ${audience_limiting_filter} `;  

  if (pending && systemSettings.post_multi_approval && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && !user.system_admin && user.company_admin) {
    // approval mode for only system owner company managers that are not system admins

    FILTERS1 = `NOT approved_by:${user.id} AND (assigned_approvers:systemmanagers OR assigned_approvers:${user.id}) AND ${FILTERS1}`;
  }

  //console.log(DMG,"FILTERS1",FILTERS1);
  
  

  //FILTERS2 for all connections yes audience
  // let FILTERS2= pending ? `all_connections:yes AND status:pending AND (company_id:${expandVendorCompanyIds(vendor_ids)})` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:yes AND (company_id:${expandVendorCompanyIds(vendor_ids)})`;

  let FILTERS2 = pending ? `all_connections:yes AND status:pending` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(1, 'd').unix()} AND all_connections:yes AND status:${status} AND (company_id:${expandVendorCompanyIds(vendor_ids)})`;

  if (pending && systemSettings.post_multi_approval && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && !user.system_admin && user.company_admin) {
    // approval mode for only system owner company managers that are not system admins

    SearchPostFILTERS1 = `NOT approved_by:${user.id} AND (assigned_approvers:systemmanagers OR assigned_approvers:${user.id}) AND ${SearchPostFILTERS1}`;
    FILTERS2 = `NOT approved_by:${user.id} AND (assigned_approvers:systemmanagers OR assigned_approvers:${user.id}) AND ${FILTERS2}`;


  }

  //console.log(DMG,"actions_search  searchPosts ")
  ////console.log(input)
  // if (true  || user.company.paid === true || user.company.paid === "true") {
//console.log(DMG,"SearchPostFILTERS1",SearchPostFILTERS1)
// FILTERS1 = `start_date_unix <= 1666559309 AND end_date_unix >= 1666472909 AND vendor_ids:${user.company_id}`; 
// FILTERS1 = `start_date_unix:1666460000 TO 1666559309 AND company_ids:${user.company_id}`;
// FILTERS1 = `start_date_unix:1666472909 TO 1666559309 AND end_date_unix:1666472909 TO 1666559309 AND vendor_ids:${user.company_id}`; 
// FILTERS1 = `start_date_unix <= 1666559309 AND end_date_unix >= 1666472909 AND vendor_ids:${user.company_id}`; 
//console.log(DMG,"FILTERS2",FILTERS2)

/*  
 start_date_unix <= 1666559309 AND end_date_unix >= 1666472909 AND all_connections:no AND (vendor_ids:81703432-91c2-43b9-a13d-cc48820f9431 OR user_ids:886e4c66-dd89-4b46-904f-da8ba61e3507 OR user_id:886e4c66-dd89-4b46-904f-da8ba61e3507 OR department_ids:c6a0a287-8691-4d8e-9033-7bfbbfedb0c6 OR department_ids:7621525f-4d3a-46fb-a1bb-8af94d297017 OR department_ids:3c2d78ea-fe99-4d9c-b985-03adbe928954)
*/

  return dispatch => {

    let SearchPostcontent1 = index.search({
      query: input,
      filters: FILTERS1,
      // numericFilters: [
      //   `start_date_unix <= ${moment().unix()}`,
      //   `end_date_unix >= ${moment().unix()}`
      // ],
      // restrictSearchableAttributes: [ 'audience_ids' ],
      hitsPerPage: 1010,
      page: page
    },
      (err, content) => {
        if (!err) {

          //save the results of the original query search
          SearchPostcontent1 = content;
          let content2;
          //second query for shared with all connections because Algolia doesn't have an exclusion option we are resorting to this process, we should move this to server side code

          //from the Algolia example; will return more information about the search than the one we are using
          //  //console.log(vendor_ids)

          let content3 = index.search("", {
            query: input,
            filters: FILTERS1,
            // filters: FILTERS2,
            hitsPerPage: 1020,
            page: page,
            // numericFilters: [
            //   `start_date_unix <= ${moment().unix()}`,
            //   `end_date_unix >= ${moment().unix()}`
            // ],
            // "analytics": false,
            // "attributesToRetrieve": "*",
            // "getRankingInfo": true,
            // "responseFields": "*",
            // "facets": [
            //   "all_connections",
            //   "company_sharing",
            //   "company_id",
            //   "end_date_unix",
            //   "start_date_unix",
            //   "user_id",
            //   "vendor_ids"
            // ],
            // "facetFilters": [
            //   vendor_ids,
            //   [
            //     "all_connections:yes"
            //   ]
            // ]
          },
            (err, content) => {
              if (!err) {
                ////console.log(content)
                content2 = Object.assign(content);
               ////console.log(SearchPostcontent1, content2);

                ////console.log(getUser(),user.company_id)

                function returnNotPrivate(hit) { //this function prevents posts marked as private from being visible to a company
                  // //console.log(hit.user.email)
                  // //console.log(getUser().email)
                  if (hit && getUser()) {
                    if (hit.user_id === getUser().id) { //but the user can still see their own posts
                      return hit
                    }
                    let hcs = hit.company_sharing || "false";
                    hcs = hcs.toString();
                    // //console.log(hcs)

                    if (hcs === "false") {
                      return hit;
                    }
                    let userDomain = getUser().email;
                    userDomain = userDomain.split("@")[1];
                    let posterDomain = hit.user.email;
                    posterDomain = posterDomain.split("@")[1];
                    if (userDomain !== posterDomain) {
                      return hit;
                    }
                  }


                }


                //taking the results of the two queries for posts on algolia and merging them together

                let tempArray = [];
                let paredArray = [];
                let myKeys = [];

                SearchPostcontent1.hits.forEach(adder);
                content2.hits.forEach(adder);

                function adder(v, i, a) {
                  // console.log(vendor_ids.toString().search(v.company_id),v.company_id)
                  //// console.log(v.title+v.created_at)
                  //console.log(v.company_id)
                  //console.log(vendor_ids)
                  if (vendor_ids.includes(v.company_id)) {
                    //if(vendor_ids.toString().search(v.company_id)>-1){
                    tempArray[v.title + v.created_at] = v;
                    myKeys.push(v.title + v.created_at);
                  }
                  //tempArray[v.title + v.created_at] = v;
                  //myKeys.push(v.title + v.created_at);


                }
                //console.log(myKeys)
                //get unique keys, iterate over those, and add them to a new array
                var array = [...new Set(myKeys)];
                array.forEach(adder2);
                // console.log(array)
                function adder2(v, i, a) {
                  // console.log(vendor_ids.toString().search(v.company_id),v)
                  //  console.log(tempArray[v])
                  paredArray[i] = tempArray[v];
                }

                // console.log(tempArray)
                // console.log(paredArray)



                // console.log(paredArray)

                let content4x = paredArray.filter(returnNotPrivate);
                paredArray = content4x;
                // console.log(paredArray)

                //sort the results by created_at date
                paredArray.sort(function (a, b) {
                  var c = new Date(a.created_at);
                  var d = new Date(b.created_at);
                  return d - c;
                });


                // console.log(content.hits)
                content.hits = paredArray;
                // console.log(content.hits)
                let postCompanyIds = content.hits.map(hit => hit.company_id);
                postCompanyIds = _.uniq(postCompanyIds);
                let paidPostCompanyIds = [];
                paidPostCompanyIds.push(user.company_id);


                const start = async () => {
                  await asyncForEach(postCompanyIds, async (companyId) => {
                    try {

                      let company = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`);

                      if (company && company.data && company.data.paid === true) {
                        paidPostCompanyIds.push(companyId); // adding the user's company so they  can see their company's posts even they are not a paid company 

                      }
                    } catch (err) {
                      console.log(err);
                    }
                  });

                  // if(!user.company.paid){

                  //   content.hits = content.hits.filter(post => post.company_id === user.company_id || post.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
                  // }

                  if (!pending) {

                    // content.hits = content.hits.filter(post => paidPostCompanyIds.includes(post.company_id) && post.status === status).sort((a, b) => b.updated_at - a.updated_at);
                  }



                  if ((content.hits.length === 0 || content.nbHits === 0)) {
                    //update the UI with the good post results data
                    return dispatch(updatePostSearchResults(content));
                  } else if (content.hits.length === 0) {
                    //return null;
                  } else {
                    return dispatch(updatePostSearchResults(content));
                  }
                }


                start();



                // let paidPostCompanyIds = postCompanyIds.filter( companyId => {
                //  fetchCompany(companyId).then(company => {

                //  });
                //   console.log(company.payload)
                //  if(company.payload.paid === true){
                //    return true;
                //  }
                // });








              }
              return;
            }
          );
        }
        return null;
      }
    );
    return null;
  };


};

export const searchIncentives = (vendor_ids, user, input = "", page = 0, companySettings, pending = false, systemSettings = {}) => {

  //console.log("export const searchIncentives", vendor_ids, user, input)

  if (!getUser() || (!systemSettings.sales_incentives_active && !systemSettings.vendor_incentives_active)) return;

  let CAN_RECEIVE_PARTNER_INCENTIVES = user && user.company_admin && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && companySettings.receive_vendor_incentives;

  let CAN_RECEIVE_SALES_INCENTIVES = user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.receive_incentives;
  
 //console.log("2 CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("2 CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  

  if(CAN_RECEIVE_SALES_INCENTIVES===true){
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
      if(user.company_admin===true){
        CAN_RECEIVE_SALES_INCENTIVES = true;
      }
    } 
  } else {
    if(user.sales_id && user.sales_id.length>4){
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }else {
      CAN_RECEIVE_SALES_INCENTIVES = false;
      if(user.company_admin===true){
        CAN_RECEIVE_SALES_INCENTIVES = true;
      }
    } 
  }

  if(CAN_RECEIVE_PARTNER_INCENTIVES===true){
    if(user.company_admin===false){
      CAN_RECEIVE_PARTNER_INCENTIVES = false;
    } 
  }
  
  if(CAN_RECEIVE_PARTNER_INCENTIVES===false){
   //console.log("I AM USER",user.company_admin);
    if(user.company_admin===true){
      CAN_RECEIVE_PARTNER_INCENTIVES = true;
      CAN_RECEIVE_SALES_INCENTIVES = true;
    }
  }

  let index = client.initIndex(process.env.REACT_APP_ALGOLIA_INCENTIVES_INDEX);
  let filters;
  let extraFilters;
  // if(systemSettings.sales_incentives_active && companySettings.receive_incentives && !(user.company_admin && companySettings.receive_vendor_incentives)){
  //   //only receiving sales incentives

  //   extraFilters = `is_partner_incentive:false`;

  // }else if(!companySettings.receive_incentives && user.company_admin && companySettings.receive_vendor_incentives && systemSettings.vendor_incentives_active){
  //   //only receiving partner incentives
  //   extraFilters = `is_partner_incentive:true`;
  // }else if(systemSettings.sales_incentives_active && systemSettings.vendor_incentives_active && companySettings.receive_incentives && user.company_admin && companySettings.receive_vendor_incentives){
  //   //receives both
  //   extraFilters = `is_partner_incentive:false OR is_partner_incentive:true`;
  // }else{
  //   //do not receive any incentives. Only company's incentives are visible
  //   extraFilters = `company_id:${user.company_id}`;
  //   if(!user.company_admin){
  //     //cannot see any partner incentives
  //     extraFilters+= ` AND is_partner_incentive:false`
  //   }
  // }

  ////////

 //console.log("2BB CAN_RECEIVE_PARTNER_INCENTIVES",CAN_RECEIVE_PARTNER_INCENTIVES)
 //console.log("2BB CAN_RECEIVE_SALES_INCENTIVES",CAN_RECEIVE_SALES_INCENTIVES)
  
  if (CAN_RECEIVE_PARTNER_INCENTIVES === true && CAN_RECEIVE_SALES_INCENTIVES === true) {
    extraFilters = `is_partner_incentive:true OR is_partner_incentive:false OR company_id:${user.company_id}`;
  } else if (CAN_RECEIVE_PARTNER_INCENTIVES === true) {
    extraFilters = `is_partner_incentive:true OR company_id:${user.company_id}`;
  } else if (CAN_RECEIVE_SALES_INCENTIVES === true) {
    extraFilters = `is_partner_incentive:false OR company_id:${user.company_id}`;
  } else {
    //cannot receive any
    extraFilters = `is_partner_incentive:undefined`;
  }




  filters = `vendor_ids:${user.company_id}`; //if a post was shared to the user's company
  // filters += ` OR (company_ids:${ user.company_id })`; //if a post was created by a co-worker
  // filters += ` OR (company_id:${ process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID })`; //if a post was created by KickPost 
  filters += ` OR user_ids:${user.id}`; //if a post was shared to this user...
  // filters += ` OR (all_connections:yes AND (company_id:${expandVendorCompanyIds(vendor_ids)})) `;
  filters += ` OR user_id:${user.id}`; //if a post was created by this user...
  filters += ` OR department_ids:${expandDepartmentIds(_.pluck(user.departments, "id"))}`; //if a post was shared to a department the user is in 

  //FILTERS1 for all connections no audience
  // let FILTERS1= pending ? `all_connections:no AND status:pending AND (${filters})` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:no AND (status:active OR status:closed) AND (${filters})`;
  let FILTERS1 = pending ? `all_connections:no AND status:pending` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:no AND (status:active OR status:closed) AND (${filters}) AND (${extraFilters})`;


  //FILTERS2 for all connections yes audience
  // let FILTERS2= pending ? `all_connections:yes AND status:pending AND (company_id:${expandVendorCompanyIds(vendor_ids)})` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:yes AND (status:active OR status:closed) AND (company_id:${expandVendorCompanyIds(vendor_ids)})`;
  let FILTERS2 = pending ? `all_connections:yes AND status:pending` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} AND all_connections:yes AND (status:active OR status:closed) AND (company_id:${expandVendorCompanyIds(vendor_ids)}) AND (${extraFilters})`;
  let FILTERS_DATES = ` start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} `;

  const gotuser = getUser();
  let mgrs  = gotuser.company_id + "-managers";
  let audience_limiting_filter = ``;
 
  if(user.company_admin === true){
    audience_limiting_filter = ` (all_connections:yes OR audience_ids:${gotuser.company_id} OR audience_ids:${mgrs} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
  } else if(user.sales_id !== null) {
    audience_limiting_filter = ` (all_connections:yes OR audience_ids:${gotuser.company_id} OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))}) `;
  } else {
    audience_limiting_filter = ` false `; // user is not a manager or a sales person
  }

  FILTERS1 = `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} 
  AND (company_id:${expandVendorCompanyIds(vendor_ids)})  AND (${extraFilters})`;
  // FILTERS2 = pending ? `all_connections:yes AND status:pending` : `start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'days').unix()} 
  // AND (company_id:${expandVendorCompanyIds(vendor_ids)}) AND (${extraFilters})`;
  FILTERS1 = FILTERS_DATES + ` AND status:active AND ( company_id:${expandVendorCompanyIds(vendor_ids)} ) AND ${audience_limiting_filter} AND (${extraFilters}) `;    
  FILTERS2 = FILTERS_DATES + ` AND status:active AND ( company_id:${expandVendorCompanyIds(vendor_ids)} ) AND ${audience_limiting_filter} AND (${extraFilters}) `;  //  ` ${expandVendorCompanyAudienceIds(vendor_ids)} `;

  if (pending && systemSettings.incentive_multi_approval && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && !user.system_admin && user.company_admin) {
    // approval mode for only system owner company managers that are not system admins

    FILTERS1 = `NOT approved_by:${user.id} AND (assigned_approvers:systemmanagers OR assigned_approvers:${user.id}) AND ${FILTERS1}`;
    FILTERS2 = `NOT approved_by:${user.id} AND (assigned_approvers:systemmanagers OR assigned_approvers:${user.id}) AND ${FILTERS2}`;


  }
  let DMG = "actions/search ";

 //console.log(DMG+"FILTERS1 ",FILTERS1 );
 //console.log(DMG+"FILTERS2 ",FILTERS2 );
 //console.log(DMG+"extraFilters ",extraFilters );
 //console.log(DMG+" ", );

  return async dispatch => {



    let content1 = index.search({
      query: input,
      distinct: true,
      filters: FILTERS1,
      // numericFilters: [
      //   `start_date_unix <= ${moment().unix()}`,
      //   `end_date_unix >= ${moment().subtract(60, 'days').unix()}`
      // ],
      hitsPerPage: 201,
      page: page
    },
      (err, content) => {
        if (!err) {

          //save the results of the original query search
          content1 = content;          
         //console.log(DMG+"content1 ",content1 );

          let content2;
          //second query for shared with all connections because Algolia doesn't have an exclusion option we are resorting to this process, we should move this to server side code

          //from the Algolia example; will return more information about the search than the one we are using
          //  console.log(vendor_ids)

          let content3 = index.search({
            query: input,
            filters: FILTERS2,
            distinct: true,
            hitsPerPage: 1030,
            page: page,
            // numericFilters: [
            //   `start_date_unix <= ${moment().unix()}`,
            //   `end_date_unix >= ${moment().subtract(60, 'days').unix()}`
            // ],
            // "analytics": false,
            // // "attributesToRetrieve": "*",
            // "getRankingInfo": true,
            // "responseFields": "*",
            // "facets": [
            //   "all_connections",
            //   // "company_sharing",
            //   "company_id",
            //   "status",
            //   "end_date_unix",
            //   "start_date_unix",
            //   // "user_id",
            //   "vendor_ids"
            // ],
            // "facetFilters": [
            //   "all_connections:yes",
            //   "status:active",
            //   [...vendor_ids.map(vendorId=>`company_id:${vendorId}`)]
            // ]
          },
            (err, content) => {
              if (!err) {
                //console.log(content)
                content2 = Object.assign(content);
                ////console.log(content1, content2);
                
 //console.log(DMG+"content2 ",content2 );



                //console.log(getUser(),user.company_id)

                function returnNotPrivate(hit) { //this function prevents posts marked as private from being visible to a company
                  // console.log(hit.user.email)
                  // console.log(getUser().email)
                  if (hit) {
                    if (hit.user_id === getUser().id) { //but the user can still see their own posts
                      return hit
                    }
                    let hcs = hit.company_sharing || "false";
                    hcs = hcs.toString();
                    // console.log(hcs)

                    if (hcs === "false") {
                      return hit;
                    }
                    let userDomain = getUser().email;
                    userDomain = userDomain.split("@")[1];
                    let posterDomain = hit.user.email;
                    posterDomain = posterDomain.split("@")[1];
                    if (userDomain !== posterDomain) {
                      return hit;
                    }
                  }


                }


                //taking the results of the two queries for posts on algolia and merging them together

                let tempArray = [];
                let paredArray = [];
                let myKeys = [];

                content1.hits.forEach(adder);
                content2.hits.forEach(adder);

                function adder(v, i, a) {
                  // console.log(vendor_ids.toString().search(v.company_id),v.company_id)
                  //// console.log(v.title+v.created_at)
                  //console.log(v.company_id)
                  //console.log(vendor_ids)
                  if (vendor_ids.includes(v.company_id)) {
                    //if(vendor_ids.toString().search(v.company_id)>-1){
                    tempArray[v.title + v.created_at] = v;
                    myKeys.push(v.title + v.created_at);
                  }
                  //tempArray[v.title + v.created_at] = v;
                  //myKeys.push(v.title + v.created_at);


                }
                //console.log(myKeys)
                //get unique keys, iterate over those, and add them to a new array
                var array = [...new Set(myKeys)];
                array.forEach(adder2);
                // console.log(array)
                function adder2(v, i, a) {
                  // console.log(vendor_ids.toString().search(v.company_id),v)
                  //  console.log(tempArray[v])
                  paredArray[i] = tempArray[v];
                }

                // console.log(tempArray)
                // console.log(paredArray)



                // console.log(paredArray)

                let content4x = paredArray.filter(returnNotPrivate);
                paredArray = content4x;
                // console.log(paredArray)

                //sort the results by created_at date
                paredArray.sort(function (a, b) {
                  var c = new Date(a.created_at);
                  var d = new Date(b.created_at);
                  return d - c;
                });


                // console.log(content.hits)
                content.hits = paredArray;
                // console.log(content.hits)
                // let postCompanyIds = content.hits.map(hit => hit.company_id);
                // postCompanyIds = _.uniq(postCompanyIds);
                // let paidPostCompanyIds = [];
                // paidPostCompanyIds.push(user.company_id);


                const start = async () => {
                  // await asyncForEach(postCompanyIds, async (companyId) => {
                  //   try{

                  //     let company = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`);

                  //     if(company && company.data && company.data.paid === true){
                  //       paidPostCompanyIds.push(companyId); // adding the user's company so they  can see their company's posts even they are not a paid company 

                  //     }
                  //   }catch(err){
                  //     console.log(err);
                  //   }
                  // });

                  // if(!user.company.paid){

                  //   content.hits = content.hits.filter(post => post.company_id === user.company_id || post.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
                  // }

                  // content.hits = content.hits.filter(post => paidPostCompanyIds.includes(post.company_id));


                  // if(!companySettings.receive_incentives && companySettings.create_incentives){
                  //   content.hits = content.hits.filter(hit=>hit.company_id===user.company_id)
                  // }

                  // content.hits = content.hits.filter(post => post.status===status);


                  if ((content.hits.length === 0 || content.nbHits === 0)) {
                    //update the UI with the good post results data
                    return dispatch(updateIncentiveSearchResults(content));
                  } else if (content.hits.length === 0) {
                    //return null;
                  } else {
                    return dispatch(updateIncentiveSearchResults(content));
                  }
                }


                start();



                // let paidPostCompanyIds = postCompanyIds.filter( companyId => {
                //  fetchCompany(companyId).then(company => {

                //  });
                //   console.log(company.payload)
                //  if(company.payload.paid === true){
                //    return true;
                //  }
                // });








              }
              return;
            }
          );
         //console.log(DMG+"content3 ",content3 );

        }
        return null;
      }
    );
    return null;
  };


};

export const searchUsers = (vendorIds, departmentIds, userIds) => {
  const usersIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_USERS_INDEX
  );



  return dispatch => {
    let filters = "";
    let activeFilters = [];

    if (vendorIds && vendorIds.length) {
      filters = `(${expandCompanyIds(vendorIds)})`;
      activeFilters.push(1);
    }

    if (departmentIds && departmentIds.length) {
      if (activeFilters.length) {
        filters = `${filters} OR (department_ids:${expandDepartmentIds(
          departmentIds
        )})`;
      } else {
        filters = `(department_ids:${expandDepartmentIds(departmentIds)})`;
      }
      activeFilters.push(2);
    }

    if (userIds && userIds.length) {
      if (activeFilters.length) {
        filters = `${filters} OR (${expandUserIds(userIds)})`;
      } else {
        filters = `(${expandUserIds(userIds)})`;
      }
      activeFilters.push(3);
    }

    //6/1/2022
    if (filters) {
      filters = `${filters} AND status:'active'`;
    } else {
      filters = ` status:'active'`;
     //console.log("vendorIds", vendorIds);
     //console.log("departmentIds", departmentIds);
     //console.log("userIds", userIds);
    }

    return usersIndex.search({
      filters: filters,
      //1000 is max by Algolia. When hits are more than 1000, companies may show incorrect numbers.
      hitsPerPage: 500
    }, (err, content) => {
      if (!err) {
        let users = content.nbHits;
        //        let users = content.hits.length;
        // console.log(content.hits, "HITZZZ");
        let companies = _.chain(content.hits.map(hit => hit.company))
          // .pluck("domain")
          .pluck("id")
          .uniq()
          .value().length;
        return dispatch(
          updateAudienceTotals({
            companies: companies,
            people: users
          })
        );
      }
    });
  };
};

export const searchVendors = (q = "", { company_goal = "", industry_categories = [] }) => {
  const companiesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX
  );

  let filters = '';
  if (company_goal) {
    if (company_goal === "Receive Info") {
      company_goal = "receive_info";
    }
    if (company_goal === "Share Info") {
      company_goal = "share_info";
    }
    filters += `company_goal:${company_goal}`
  }

  if (industry_categories && industry_categories.length) {
    let categoryFilter = '';
    if (industry_categories.length === 1) {
      categoryFilter = `industry_categories:"${industry_categories[0]}"`;
    } else {
      categoryFilter = `(industry_categories:${expandIndustryCategories(industry_categories)})`
    }
    if (filters.length) {

      filters += ` AND ${categoryFilter}`;
    } else {
      filters = `industry_categories:${expandIndustryCategories(industry_categories)}`;
    }
  }


  return dispatch => {
    return companiesIndex.search({
      query: q,
      distinct: true,
      hitsPerPage: 1012,
      filters
    },
      (err, content) => {
        if (!err) {
          content.hits.forEach(filterNonHits);

          function filterNonHits(index, arr, item) {
            // console.log("hits searchVendors")
            // console.log(item)
          }
          return dispatch(updateCompanySearchResults(content));
        }
      }
    );
  };
};

export const searchProducts = (q = "", partnerIds = []) => {
  const productsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX
  );


  return dispatch => {
    return productsIndex.search({
      query: q,
      hitsPerPage: 1011,
      facetFilters: [
        [...partnerIds.map(id => "partner_id:" + id)]
      ]
    },
      (err, content) => {
        if (!err) {
          // console.log(content, "ALG PRO")
          return dispatch(updateProductSearchResults(content));
        } else {
          // console.log(err, "ALG PRO")
        }
      }
    );
  };
};

export const searchMobile = (q = "", audience_ids = [], user) => {
  const mobileIndex2 = client.initIndex(
    process.env.REACT_APP_ALGOLIA_MOBILE_INDEX
  );
  let companyId = "";  
  const gotuser = getUser();
  let mgrs  = gotuser.company_id + "-managers";

  let mobileIndex = client.initIndex("mobile-merge2");
  let filters = "";
  
  filters = ` (status:active OR status:closed) `; 
  filters += ` AND start_date_unix <= ${moment().unix()} AND end_date_unix >= ${moment().subtract(60, 'd').unix()}  `;
  filters += ` AND ( contentType:people OR contentType:companies  OR audience_ids:all_connections_yes `;

  if(gotuser.company_admin===true){
    filters += ` OR audience_ids:${mgrs} `;
  } 
  filters += `  OR audience_ids:${gotuser.company_id}  OR audience_ids:${gotuser.id} OR audience_ids:${expandDepartmentAudienceIds(_.pluck(gotuser.departments, "id"))} ) `;//shared with the world or shared to my company or shared to me or shared to my groups

  if(gotuser.sales_id===null && gotuser.company_admin===false){
    filters += ` AND NOT contentType:incentives `;
  } 
  

  return dispatch => {
    return mobileIndex.search({
      query: q,
      hitsPerPage: 999,
      filters
      // ,
      // facetFilters: [
      //   [...audience_ids.map(id => "audience_ids:" + id)]
      // ]
    },
      (err, content) => {
        if (!err) {
          // console.log(content, "ALG MOB PRO")
          return dispatch(updateMobileSearchResults(content));
        } else {
          console.log(err, "ALG MOB PRO")
        }
      }
    );
  };
};

export const searchProductGroups = (q = "", partnerIds = []) => {
  const productsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_PRODUCT_GROUPS_INDEX
  );


  return dispatch => {
    return productsIndex.search({
      query: q,
      hitsPerPage: 1013,
      facetFilters: [
        [...partnerIds.map(id => "partner_id:" + id)]
      ]
    },
      (err, content) => {
        if (!err) {
          // console.log(content, "ALG PRO")
          return dispatch(updateProductGroupSearchResults(content));
        } else {
          console.log(err, "ALG PRO")
        }
      }
    );
  };
};

export const searchProductCategories = (q = "") => {
  const productsIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_PRODUCT_CATEGORIES_INDEX
  );


  return dispatch => {
    return productsIndex.search({
      query: q,
      hitsPerPage: 1014,

    },
      (err, content) => {
        if (!err) {
          // console.log(content, "ALG PRO")
          return dispatch(updateProductCategorySearchResults(content));
        } else {
          console.log(err, "ALG PROPCSR")
        }
      }
    );
  };
};

export const searchPendingCompanies = (input, { company_goal = "", industry_categories = [] }) => {
  const companiesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX
  );
  if (!getUser()) return;

  let filters = '';
  if (company_goal) {
    if (company_goal === "Receive Info") {
      company_goal = "receive_info";
    }
    if (company_goal === "Share Info") {
      company_goal = "share_info";
    }
    filters += `company_goal:${company_goal}`
  }

  if (industry_categories && industry_categories.length) {
    if (filters.length) {

      filters += ` AND (industry_categories:${expandIndustryCategories(industry_categories)})`;
    } else {
      filters = `industry_categories:${expandIndustryCategories(industry_categories)}`;
    }
  }
  let search = {
    query: input,
    distinct: true,
    hitsPerPage: 200,
    filters,
    optionalFilters: [`vendor_ids:${getUser().company_id}`]
  };

  // console.log("actions_search  searchPendingCompanies ")
  // console.log(input);

  return dispatch => {
    return companiesIndex.search(search, (err, content) => {
      if (!err) {

        //     console.log("content");
        //    console.log(content);



        let content_hits = content.hits.filter(function (value, index, arr) {

         //console.log(value.vendor_ids, "VEDNORIDS");
          //    console.log(value.vendor_ids.toString().search(getUser().company_id));
          if (value.vendor_ids.toString().search(getUser().company_id) > -1) {
            //    console.log("match")
            return null;
          } else {
            value.status = "searchPendingCompanies";
            return value;
          }
        });

        //   console.log("content_hits");
        //   console.log(content_hits);

        content.hits = content_hits;
        //       console.log("content.toString search");
        //     console.log(content.toString().toLowerCase().search("pending"));


        return dispatch(updateCompanySearchResults(content));
      }
    });
  };

  /*
  return dispatch => {
    return companiesIndex.search(
      {
        query: q
      },
      (err, content) => {
        if (!err) {
          content.hits.forEach(filterNonHits);

          function filterNonHits(index,arr,item){
            console.log("hits searchPendingCompanies")
            console.log(item)
          }

          return dispatch(updateCompanySearchResults(content));
        }
      }
    );
  };
*/


};


export const searchConnectedCompanies = (input, { company_goal = "", industry_categories = [] }) => {
  const companiesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX
  );

  let filters = `vendor_ids:${getUser().company_id}`;
  if (company_goal) {
    if (company_goal === "Receive Info") {
      company_goal = "receive_info";
    }
    if (company_goal === "Share Info") {
      company_goal = "share_info";
    }
    filters += ` AND company_goal:${company_goal}`
  }

  if (industry_categories && industry_categories.length) {

    if (industry_categories.length === 1) {
      filters += ` AND industry_categories:"${industry_categories[0]}"`;
    } else {

      filters += ` AND (industry_categories:${expandIndustryCategories(industry_categories)})`;
    }
  }

 //console.log({ filters, company_goal, industry_categories }, "CONNECTEDCOMPPPS")

  let search = {
    query: input,
    distinct: true,
    filters,
    hitsPerPage: 1015
  };
  //search = { query: input, filters: `id:${getUser().company_id}` };

  // console.log("input");
  // console.log(input);
  // console.log("actions_search  searchConnectedCompanies ")

  return dispatch => {
    return companiesIndex.search(search, (err, content) => {
      // console.log(content)
      if (!err) {
        // content.hits.forEach(filterNonHits);

        function filterNonHits(index, arr, item) {
          //  console.log("hits searchConnectedCompanies")
          //  console.log(item)
        }
        let companyRows = content.hits.map(company => {
          return {
            type: "company",
            company_id: company.id,
            id: company.id,
            row: {
              id: company.objectID,
              name: company.name,
              image: company.logo,
              people: company.total_employee_count === 1 ?
                `${company.total_employee_count} Person` : `${company.total_employee_count} People`,
              total: company.total_employee_count
            }
          };
        });
        /* NEED TO CHECK NEXT LINES */
       //console.log(companyRows)
        dispatch(updateCompanySearchRows(companyRows));
        return dispatch(updateCompanySearchResults(content));
      }
    });
  };
};


export const searchNotConnectedCompanies = (input, { company_goal = "", industry_categories = [] }) => {
  const companiesIndex = client.initIndex(
    process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX
  );

  let filters = '';
  if (company_goal) {
    if (company_goal === "Receive Info") {
      company_goal = "receive_info";
    }
    if (company_goal === "Share Info") {
      company_goal = "share_info";
    }
    filters += `company_goal:${company_goal}`
  }

  if (industry_categories && industry_categories.length) {
    let categoryFilter = "";
    if (industry_categories.length === 1) {
      categoryFilter = `industry_categories:"${industry_categories[0]}"`;
    } else {
      categoryFilter = `(industry_categories:${expandIndustryCategories(industry_categories)})`
    }
    if (filters.length) {

      filters += ` AND ${categoryFilter}`;
    } else {
      filters = `industry_categories:${expandIndustryCategories(industry_categories)}`;
    }
  }

  let search = {
    query: input,
    hitsPerPage: 1016,
    distinct: true,
    filters,
    optionalFilters: [
      `vendor_ids:${getUser().company_id}`
    ]
  };
  //search = { query: input, filters: `id:${getUser().company_id}` };

  // console.log("input");
  // console.log(input);
  // console.log("actions_search  searchNotConnectedCompanies ")

  return dispatch => {
    return companiesIndex.search(search, (err, content) => {
      if (!err) {

        // console.log("content");
        // console.log(content);



        let content_hits = content.hits.filter(function (value, index, arr) {

          // console.log(value.vendor_ids);
          // console.log(value.vendor_ids.toString().search(getUser().company_id));
          if (value.vendor_ids.toString().search(getUser().company_id) > -1) {
           //console.log("match")
            return null;
          } else {
            value.status = "searchNotConnectedCompanies";
            return value;
          }
        });

        // console.log("content_hits");
        // console.log(content_hits);

        content.hits = content_hits;
        // console.log("content.toString search");
        // console.log(content.toString().toLowerCase().search("pending"));


        return dispatch(updateCompanySearchResults(content));
      }
    });
  };
};

export const toggleCategory = (category, value) => {
  return dispatch => {
    return dispatch(
      updateSearchCategory({
        category,
        value
      })
    );
  };
};

export const toggleCompany = (company, value) => {
  return dispatch => {
    return dispatch(
      updateSearchCompany({
        company,
        value
      })
    );
  };
};

export const clearAllCompany = (company, value) => {
  return dispatch => {
    return dispatch(
      clearSearchCompany({
        company,
        value
      })
    );
  };
}; 