const defaultState = {
    group: null,
    groupMembers: null
  };
  
  export default (state = defaultState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
      case "SET_GROUP":
        newState.group = action.payload;
        return newState;
      case "SET_GROUP_MEMBERS":
        newState.groupMembers = action.payload;
        return newState;
      default:
        return state;
    }
  };
  