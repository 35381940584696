import React, { Component } from "react";
import { Box, Flex, OverflowHiddenBox } from "../common/flexbox";
// import { BlurWrapper } from "../common/ui";
import { NunitoSans } from "../common/typography";

import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import { mapboxgl_accessToken } from "../../utils/constants";
// import { AbsoluteDiv } from "../common/absolute";

import MapboxClient from "mapbox";
import { addressBottomPart } from "../../utils/helpers";

import ReactMapboxGl from "react-mapbox-gl";

mapboxgl.accessToken = mapboxgl_accessToken;
const client = new MapboxClient(mapboxgl_accessToken);

const Map = ReactMapboxGl({
  accessToken: mapboxgl_accessToken,
  scrollZoom: false,
  doubleClickZoom: false,
  dragPan: false,
  style: "mapbox://styles/mapbox/streets-v10"
});
const zoom = [8];

export class MapboxMap extends Component {
  state = {
    center: null
  };

  componentDidMount() {
    this.centerOnAddress(this.props.placeName);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.placeName !== this.props.placeName) {
      this.centerOnAddress(this.props.placeName);
    }
  }
  centerOnAddress(address) {
    client
      .geocodeForward(address)
      .then(
        function(res) {
          let data = res.entity;
          let features = data.features;
          if (features.length > 0) {
            let center = features[0].center;
            this.setState({ center: center });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("geocode error " + err);
        // handle errors
      });
  }

  addressTopPart() {
    return this.props.address + " " + this.props.text;
  }

  render() {
    const { placeName, showFullAddress } = this.props;
    const { center } = this.state;

    return (
      <Flex flexDirection="column" width="100%" height="100%">
        <OverflowHiddenBox
          position="relative"
          borderRadius="10px"
          width="100%"
          height="100%"
        >
          <Box width="100%" height="100%" id="mapbox-container">
            {center ? (
              <Map
                // eslint-disable-next-line
                style="mapbox://styles/mapbox/streets-v10"
                center={center}
                zoom={zoom}
                scrollZoom={false}
                containerStyle={{ height: "100%", width: "100%" }}
              />
            ) : null}
          </Box>
          {/* <AbsoluteDiv
            overflow="hidden"
            left="-5px"
            right="-5px"
            height="66px"
            bottom="-5px"
          >
            <BlurWrapper width="100%" height="100%" blur="7px">
              <AbsoluteDiv
                position="absolute"
                bottom="0px"
                width="100%"
                height="227px"
                id="mapbox-container-blur"
              >
                {center ? (
                  <Map
                    // eslint-disable-next-line
                    style="mapbox://styles/mapbox/streets-v10"
                    center={center}
                    zoom={zoom}
                    scrollZoom={false}
                    containerStyle={{ height: "100%", width: "100%" }}
                  />
                ) : null}
              </AbsoluteDiv>
            </BlurWrapper>
            <Box
              position="relative"
              zIndex="10"
              height="100%"
              width="100%"
              bg="white"
              style={{ opacity: 0.3 }}
            />
          </AbsoluteDiv> */}
          {/* <AbsoluteDiv
            zIndex="20"
            overflow="hidden"
            left="0px"
            right="0px"
            height="61px"
            bottom="0px"
          > */}
            
          {/* </AbsoluteDiv> */}
        </OverflowHiddenBox>
       {
         showFullAddress
         ?
          
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          // height="100%"
          mt='5px'
        >
          {/* <NunitoSans
            width="78%"
            textAlign="center"
            fontSize={["11px", "12px"]}
            fontStyle="italic"
          >
            {this.addressTopPart()}
          </NunitoSans> */}
          <NunitoSans
            width="78%"
            textAlign="center"
            fontSize={["11px", "12px"]}
            fontStyle="italic"
          >
            {addressBottomPart(placeName)}
          </NunitoSans>
        </Flex>
        :
        null
       }
      </Flex>
    );
  }
}
