import { get, post, patch, remove } from "../lib/request";
import resources from "../constants/resources";
import { makeAuthHeader } from "../utils/auth";
import { error } from "../actions/alerts";
import { capitalize } from "../utils/string";

const notifyError = error.bind(null, "api_error");

export const receiveCollectionError = (resource, error) => ({
  type: "RESOURCE_COLLECTION_ERROR",
  resource,
  error
}); 

export const receiveDetailError = (resource, error) => ({
  type: "RESOURCE_DETAIL_ERROR",
  resource,
  error
});

export const receiveCollection = (resource, payload) => ({
  type: "RECEIVE_RESOURCE_COLLECTION",
  resource,
  payload
});

export const receiveDetail = (resource, payload) => ({
  type: "RECEIVE_RESOURCE_DETAIL",
  resource,
  payload
});

export const requestCollection = resource => ({
  type: "REQUEST_RESOURCE_COLLECTION",
  resource
});

export const requestResourceCreate = resource => ({
  type: "REQUEST_RESOURCE_CREATE",
  resource
});

export const requestResourceUpdate = resource => ({
  type: "REQUEST_RESOURCE_UPDATE",
  resource
});

export const requestDetail = resource => ({
  type: "REQUEST_RESOURCE_DETAIL",
  resource
});

export const resourceDeleted = (resource, id) => ({
  type: "REQUEST_RESOURCE_DELETED",
  id,
  resource
});

export const clearCollection = resource => ({
  type: "CLEAR_RESOURCE_COLLECTION",
  resource: resource
});

export const clearDetail = resource => ({
  type: "CLEAR_RESOURCE_DETAIL",
  resource: resource
});

const addAuthHeader = headers => {
  let authHeader = makeAuthHeader();
  return Object.assign({}, headers, authHeader);
};

const _delete = (resourceType, url, params) => {
  //console.log(resourceType, url, params) 
  return (dispatch, getState) => {
    let headers = addAuthHeader({}, getState);
    return remove(url, params, headers)
      .catch(errResponse => {
        //dispatch(apiError(errResponse));
        if(errResponse && errResponse.data){
          throw new Error(errResponse.data.error);
        }         
      })
      .then(response => dispatch(resourceDeleted(resourceType, response.data)));
  };
};

let errorcounter = 0;

const _fetch = (requestAction, receiveAction, errorAction) => {
  // console.log("_fetch")
  return (resourceType, url, params) => {

    // console.log(resourceType, url, params);
    return (dispatch, getState) => {
      // console.log(dispatch, getState);
      let headers = addAuthHeader({}, getState);
      dispatch(requestAction(resourceType));
      return get(url, params, headers)
        .catch(errResponse => {

          if (errorcounter < 10) {
            errorcounter++;
            console.log("resources.js errorcounter", url, params, headers);
          }
          if (errorcounter < 10) {
            if(errResponse && errResponse.data){
              dispatch(errorAction(resourceType, errResponse.data));
              throw new Error(errResponse.data.error);
            } else {
              console.log("errResponse",errResponse);
              //throw new Error(errResponse.data.error);
              throw new Error(errResponse);      
            }
          }
        })
        .then(response => {
          if(resourceType===""){
            console.log(resourceType, response.data);
          }
         
         return dispatch(receiveAction(resourceType, response.data));
        }
        );
    };
  };
};

const _post = (resourceType, url, params) => {
  return (dispatch, getState) => {
    dispatch(requestResourceCreate(resourceType));
    const headers = addAuthHeader({}, getState);
    const { doNotUpdateState = false, ...rest } = params;
    return post(url, rest, headers)
      .catch(error => {
        dispatch(notifyError(error.response.data.message));
      })
      .then(response => {
        if (response) {
          return doNotUpdateState ? receiveDetail(resourceType, response.data) : dispatch(receiveDetail(resourceType, response.data));
        }
      });
  };
};

const _patch = (resourceType, url, params) => {
  return (dispatch, getState) => {
    //console.log(resourceType)
    dispatch(requestResourceUpdate(resourceType));
    const headers = addAuthHeader({}, getState);
    const { doNotUpdateState = false, ...rest } = params;
    
    // console.log(url, rest, headers);
    return patch(url, rest, headers)
      .catch(errResponse => {
        //dispatch(apiError(errResponse.data));
        if (errResponse && errResponse.data && errResponse.data.error) {
          throw new Error(errResponse.data.error);
          //dispatch(notifyError(errResponse.data.error));
        } else {         
          console.log("errResponse",errResponse);
          //dispatch(notifyError(_.values(errResponse.data)[0][0]));
        }
        
      })
      .then(response => !doNotUpdateState ? dispatch(receiveDetail(resourceType, response.data)) : receiveDetail(resourceType, response.data));
  };
};

const _delete2 = (resourceType, url, params) => {
  //console.log(resourceType, url, params)

  return {};
  return (dispatch, getState) => {
    //console.log(resourceType)
    dispatch(requestResourceUpdate(resourceType));
    const headers = addAuthHeader({}, getState);
    return patch(url, params, headers)
      .catch(errResponse => {
        //dispatch(apiError(errResponse.data));
        console.log(url, params, headers, errResponse);
        if (errResponse.data && errResponse.data.error) {
          //dispatch(notifyError(errResponse.data.error));
        } else {
          //dispatch(notifyError(_.values(errResponse.data)[0][0]));
        }
        throw new Error(errResponse.data.error);
      })
      .then(response => dispatch(receiveDetail(resourceType, response.data)));
  };


};

export const fetchCollection = _fetch(
  requestCollection,
  receiveCollection,
  receiveCollectionError
);
export const fetchDetail = _fetch(
  requestDetail,
  receiveDetail,
  receiveDetailError
);

export const actions = {};

Object.keys(resources).forEach(key => {
  //console.log(resources)
  let resource = resources[key];
  actions["retrieve" + capitalize(key)] = (domain, params) => {
    return fetchDetail(key, `${resource.url}/${domain}`, params);
  };
  actions["fetch" + capitalize(key)] = (id, params) => {
    return fetchDetail(key, `${resource.url}/${id}`, params);
  };
  actions["fetch" + capitalize(resource.plural)] = fetchCollection.bind(
    null,
    key,
    resource.url
  );
  actions["create" + capitalize(key)] = params => {
    return _post(key, resource.url, params);
  };
  actions["patch" + capitalize(key)] = (id, params) => {
    return _patch(key, `${resource.url}/${id}`, params);
  };
  actions["delete" + capitalize(key)] = (id, params) => {
    return _delete(key, `${resource.url}/${id}`, params);
  };
  actions["delete2" + capitalize(key)] = (id, params) => {
    return _delete2(key, `${resource.url}/${id}`, params);
  };
});
