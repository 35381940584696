import React, { Component } from "react";
import _ from "underscore";
import { H2, NunitoSansLight, NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold } from "../../common/typography";

// import styled from "styled-components";
import {MobilePrivacy} from "./mobilePrivacy";
import {MobileTermOfUse} from "./mobileTerms";
import { history } from "../../../store";



export class MobileLegalNav extends Component {
  state = {
    currentPage: 'terms'
  }

  
  render() {

      return (
        <div style={{"margin": "0 10px"}}>

          <H2 style={{"textAlign": "center","fontSize": "40px", "fontFamily": "NunitoSansLight", "marginBottom":"25px", "marginTop":"40px", "color": "#000"}}>LEGAL DISCLAIMERS</H2>
          <div style={{"marginTop": "45px"}} style={{"textAlign": "center"}}><a href="#" id="TOSBtn" onClick={()=> this.setState({currentPage: 'terms'})} style={{color:"#000",textDecoration:"underline",}}>Terms of Service</a> | <a id="PrivacyBtn" href="#" onClick={()=> this.setState({currentPage: 'privacy'})} style={{color:"#000",textDecoration:"underline",}}>Privacy Policy</a> | <a id="Dashboard" href="#" onClick={()=> history.push("/dashboard")} style={{color:"#000",textDecoration:"underline",}}>Dashboard</a></div>
  

          <div style={{"marginTop": "45px", "textAlign": "center"}} >
          {this.state.currentPage === 'terms' ? <MobileTermOfUse /> : <MobilePrivacy />}
          </div>
         
  

      </div>
    );
  }
}

export default MobileLegalNav;
