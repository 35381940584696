import React from "react";

import {
    BrandTwoPartFieldMarkupOnly,
    renderSmallField
} from "../../../common/forms";

let ExistingBrandAttribute = props => {
  const { clickHandler } = props;
  return (
    <BrandTwoPartFieldMarkupOnly
      id={props.id}
      logo={props.logo}
      name={props.name}
      fieldOneProps={{
        disabled: true,
        label: "NAME",
        name: "name",
        component: renderSmallField,
        type: "text",
        fieldValue: props.initialValues.name
      }}
      fieldTwoProps={{
        disabled: true,
        label: "URL",
        name: "url",
        component: renderSmallField,
        type: "text",
        fieldValue: props.initialValues.url
      }}
      icon="close"
      notActionable={props.initialValues.disabled}
      clickHandler={clickHandler}
    />
  );
};

export default ExistingBrandAttribute;