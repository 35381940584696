import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import queryString from "query-string";
//utils
import { setUser, getUser } from "../../utils/auth";
//actions
import { error } from "../../actions/alerts";
// import { error, success } from "actions/alerts";
import { actions, clearDetail } from "../../actions/resources";
//components
import EmailVerificationForm from "../auth/forms/email-verification";
import { SubmissionError, reset } from 'redux-form';  // ES6

export class EmailVerification extends Component {



  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {
    const { clearDetail, redirect } = this.props;
    clearDetail("company");

    let queryParams = queryString.parse(window.location.search);
    if (queryParams.token) {
      this._handleSubmit({ email_verification_code: queryParams.token });
    }else if(!getUser()){
      redirect("/");
    }

    // console.log(getUser(), "UZER2")
  }

  _handleSubmit = async data => {

    
    // console.log("src/components/auth/email-verification.js _handleSubmit")
    const {
      fetchUser,
      redirect,
      createEmailVerification,
      updateConnection,
      resetForm
    } = this.props;
    
    let email_verification_code = data.email_verification_code;
    email_verification_code = email_verification_code.toString().trim();
    data.email_verification_code = email_verification_code;

    const response = await createEmailVerification(data);
    if (response && response.payload.email_verified_at) {
      await setUser(response.payload);
      await fetchUser(response.payload.id, {
        posts: true,
        phone_numbers: true
      });
      let queryParams = queryString.parse(window.location.search);
      if (queryParams.connection_id) {
        await updateConnection(queryParams.connection_id, { status: "active" });
      }
      return redirect("/dashboard");
    }
    resetForm("email-verification");
    return this._throwError();
    
    
  };

  _throwError = async data => {
    
    
     throw new SubmissionError({ _error: 'VERIFICATION CODE IS INCORRECT. CHECK YOUR EMAIL' });

  };

  render() {
    return (
      <div>
        <EmailVerificationForm
          isMobile={this.props.browser.lessThan.large}
          back={this.props.back || "/"}
          onSubmit={this._handleSubmit}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
  }),
  dispatch => ({
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    clearDetail: bindActionCreators(clearDetail, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    createEmailVerification: bindActionCreators(
      actions.createEmail_verification,
      dispatch
    ),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch)
  })
)(EmailVerification);
