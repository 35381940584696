import {breakpoints_em} from './breakpoints';

// breakpoint values
// any array length works with styled-system
const breakpoints = breakpoints_em.map(em => {
  return em + 'em';
});

export const colors = {
  kickpostBlack:'#000',
  black:'#000',
  kickpostGreen:'#2FD371',
  kickpostPlus: '#428bf4',
  kickpostNavyBlue:'#10213A',
  lightGreen:'#74dfc4',
  whiteGreen:'#e3f9f3',
  kickpostYellow:'#edce59',
  lightYellow:'#f4e29a',
  pending:'#faf5de',
  darkText: '#000',
  error: '#FE4A49',
  gray: '#a3aa9e',
  darkGray: '#6c7168',
  darkerGray: '#979797',
  midGray: '#C3C3C3',
  placeholderGray: '#C8C8C8',
  lightGray: '#ebebe7',
  inputBackgroundGray: '#ebebe7',
  whiteGray: '#f8f8f8',
  imageBorder: '#f8f8f8',
  vLightGray: '#f9f9f9',
};

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

// typographic scale
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96, 128];

// for any scale, either array or objects will work
const lineHeights = [1, 1.125, 1.25, 1.5];

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800,
};

const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
};

// border-radius
const radii = [0, 2, 4, 8];

const borders = [0, '1px solid #979797', '2px solid', '1px solid #c3c3c3'];

const shadows = [`0 1px 2px 0 ${colors.text}`, `0 1px 4px 0 ${colors.text}`];

const theme = {
  breakpoints,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borders,
  shadows,
};

export default theme;
