import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { EMAIL_REGEX } from "../constants/app";

export const isSame = function(arrayOne, arrayTwo) {
  var a = arrayOne,
    b = arrayTwo;

  if (arrayOne.length <= arrayTwo.length) {
    a = arrayTwo;
    b = arrayOne;
  }
  return isEmpty(difference(a.sort(), b.sort()));
};

export const addressBottomPart = function(placeName) {
  // placeName is a string that partitions out locations by commas
  const location = placeName.split(', ')
  let [place, region, country] = [location[location.length - 3], location[location.length - 2], location[location.length - 1]]

  // removing zipcodes for some regions
  if (region) {
    let regionArr = region.split(' ') 
    
    if (parseInt(regionArr[regionArr.length - 1]) > 0) {
      regionArr.pop()
      region = regionArr.join(' ')
    }
  }

  // if some places/regions are undefined (ie if you only put a state/country or just country, it'll be an empty string)
  return `${place ? place + ',' : ''} ${region ? region + ',' : ''} ${country}`
}

export const filterActiveDepartments = function(departments) {
  return filter(departments, function(department) {
    return department.status === "active";
  });
};

export const filterActiveGroups = function(groups) {
  return filter(groups, function(group) {
    return group.status === "active";
  });
};

export const filterActiveEmployees = function(employees) {
  return filter(employees, function(employee) {
    return employee.status === "active";
  });
};

export const isValidEmail = email => {
  let re = new RegExp(EMAIL_REGEX, "i");

  return re.test(email);
}