import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P} from '../../common/typography';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
import ReactTooltip from 'react-tooltip';

import {    DashboardSmallCTA } from "../../common/forms";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faShareAlt, faUserPlus, faNewspaper as faNewspaperLight, faPaperPlane as faPaperPlaneLight } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';
import { faSolarSystem } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faTimesCircle } from'@fortawesome/free-regular-svg-icons';
import {   NunitoSansBold,  NunitoSans,  NunitoSansSemiBold } from "../../common/typography";

import {
 
  ImageTreatment
  
} from "../../common/ui";
import {defaultProfile, defaultCompany} from "../../../utils/defaultLogos";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Company name is required';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
 
  return errors;
};
const BlurWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 10px;
  right: 10px;
  bottom: -18px;
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
`;
const DefaultBlurrable = styled.div`
  width: 100%;
  height: 100%;
  background: #9f9f9f;
`;
const PreviewImg = styled.img`
  height: 100%;
  width: 100%;
`;
const PreviewImageBlurrable = ({src}) => <PreviewImg src={src} />;

const DropzoneKickpost = ({...props}) => (
  <Box position="relative" >
    <Flex
      borderRadius={10}
      bg="imageBorder"
      position="relative"
      zIndex={100} 
      justifyContent="center"
      p={'10px'}
      alignItems="center"
      width={[83, 110, 110, 110]}
      height={[83, 110, 110, 110]}>
      <Dropzone disableClick
        onDrop={props.dropzoneOnDrop}
        style={{
          backgroundColor: '#fff',
          backgroundImage: props.dropzonePreview
            ? `url(${props.dropzonePreview})`
            : `url(${companyLogoUploadImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          cursor: 'pointer',
          width: '100%',
          paddingTop: '100%',
        }}
        multiple={false}
      />
    </Flex>
    {props.dropzonePreview ? (
      <BlurWrapper>
        <PreviewImageBlurrable src={props.dropzonePreview} />
      </BlurWrapper>
    ) : (
      <BlurWrapper>
        <DefaultBlurrable />
      </BlurWrapper>
    )}
  </Box>
);
const CompanyNameBubble = ({...props}) => (
  <Flex
    w={[1, 1, 1, 1]}
    alignContent="center"
    justifyContent="space-between"
    flexDirection={['column', 'column', 'row', 'row']}>
    <Bubble
      bg={['white', 'white', 'inputBackgroundGray', 'inputBackgroundGray']}>
      <Flex
        flexDirection={['column', 'column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
        pt={['0px', '0px', '36px', '36px']}
        pb={['0px', '0px', '39px', '39px']}>
        <Box
          flex="1 1 auto"
          order={[1, 1, 0, 0]}
          width={[1, 1, 0.605, 0.655]}
          ml={['0px', '0px', '44px', '44px']}>
          <Field
            label="Company Name"
            name="name"
            mb={0}
            component={renderField}
            type="text"
          />
        </Box>
        <Box data-tip="Drag and Drop Logo Here"
          mb={[39, 39, 0, 0]}
          mr={[0, 0, 49, 49]}
          ml={[0, 0, 49, 49]}
          order={[0, 0, 1, 1]}
          width={[103, 130, 130, 130]}>
            <ReactTooltip />
          <DropzoneKickpost {...props} />
        </Box>
      </Flex>
    </Bubble>
  </Flex>
);

let ConnectionForm = props => {
  const {pendingConnections, sponsoredBrands, industryCategories, updateConnection,handleSubmit, dropzoneOnDrop, dropzonePreview, back, singleUserAccount, redirect, isMobile} = props;
  
  const [page, setPage] = useState(1);
  return (
    <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    GET CONNECTED
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                      Pending Connection Requests
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="center"
                  >
                    Approve your connection requests to immediately start engaging your industry partners.
                  </NunitoSansSemiBold>

                  <PendingConnections
                  isMobile={isMobile}
                  pendingConnections={props.pendingConnections}
                  updateConnection={updateConnection}
                  />

                  <DashboardSmallCTA
                    minWidth="130px"
                    width='100%'
                    innerHeight="35px"
                    fontSize="16px"
                    onClick={() => setPage()}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
  );
};


const SponsoredBrands = (props)=>(
  <Flex
  width="100%"
  flexDirection={["column", "row"]}
  justifyContent="space-between"
  alignItems="center"
  style={{marginTop: '30px', marginBottom: '30px'}}
  >

    {props.sponsoredBrands.map((brand, index)=>(
      <SponsoredBrandRow
      key={brand+index.toString()}
      brand={brand}
      createConnection={props.createConnection}
      isMobile={props.isMobile}
      />
    ))}

  </Flex>
);

const SponsoredBrandRow = ({brand, createConnection, isMobile})=>{

  const [processing, setProcessing] = useState(false);

  const _createConnection = async(vendorId)=>{

    setProcessing(true);

    await createConnection(vendorId);

    setProcessing(false);

  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      style={{padding: isMobile ? "15px 15px" : "20px 20px", border: '1px solid #C3C3C3', borderRadius: '5px', marginBottom: isMobile ? '10px' : 0}}
      >

        <img src={brand.logo || defaultCompany(brand.id, brand.name)} width={120} alt={brand.name} />

        <NunitoSansBold
        fontSize="17px"
        color="#000000"
        textAlign="center"
        style={{marginTop: '10px', marginBottom: '10px'}}
        >
          {brand.name}
        </NunitoSansBold>

        {
          processing
          ?
          <NunitoSans
          fontSize="12px"
          color="#2FD371"
          textAlign="center"
          >
            Processing...

          </NunitoSans>
          :
          !brand.connectionStatus
          ?
          <DashboardSmallCTA
          width="100%"
          minWidth={["74px"]}
          fontSize="12px"
          innerHeight="30px"
          onClick={() => _createConnection(brand.id)}
          >
            CONNECT
          </DashboardSmallCTA>
          :
          <NunitoSans
          fontSize="12px"
          color="#2FD371"
          textAlign="center"
          >
            {brand.connectionStatus==='active' ? "CONNECTED" : "PENDING"}

          </NunitoSans>
        }

      </Flex>
  );
};


const PendingConnections = (props)=>(
  <Flex
  flexDirection="column"
  width="100%"
  style={{height: props.height || '350px', overflowX: 'hidden', overflowY: 'auto', paddingRight: '10px'}}
  >
    {
      props.pendingConnections.map((connection, index)=>(
        <PendingConnectionRow
        key={connection+ index.toString()}
        updateConnection={props.updateConnection}
        connection={connection}
        index={index}
        isMobile={props.isMobile}
        />
      ))
    }

  </Flex>
);

const PendingConnectionRow = ({connection, index, updateConnection, isMobile})=>{

  const [processing, setProcessing] = useState(false);

  const _updateConnection = async(conn, status)=>{

    setProcessing(true);

    await updateConnection(conn, status);

    setProcessing(false);

  }

  return (
    <Flex
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    style={{borderTop: index!==0 ? '1px dashed #A3A99E' : 'none', paddingTop: isMobile ? '10px' : '20px', paddingBottom: isMobile ? '10px' : '20px'}}
    >


        <Flex
        alignItems="center"
        >
          <ImageTreatment
              img={connection.company.logo ||(connection.company.is_single_user_company ? defaultProfile(connection.company.id, connection.company.name) : defaultCompany(connection.company.id, connection.company.name))}
              blurRadius={"0px"}
              imageInset={0}
              borderRadius={connection.company.is_single_user_company ? 22 : 4}
              innerBorderRadius={connection.company.is_single_user_company ? 20 : 0}
              shadowOffset={0}
              padding={4}
              height={isMobile ? 30 : 40}
              width={isMobile ? 30 : 40}
              
          />

          <Flex
          flexDirection="column"
          style={{marginLeft: '15px'}}
          >
            <Flex
            alignItems="center"
            >
              <NunitoSansBold
              fontSize={["14px","14px","17px","17px"]}
              color="#000000"
              >
                {connection.company.name}
              </NunitoSansBold>

            </Flex>
            <NunitoSans
            fontSize={["10px","10px","12px","12px"]}
            color="#000000"
            >
              {connection.company.is_single_user_company ? connection.company.pseudoName : `${connection.company.total_employee_count} People`}
            </NunitoSans>
          </Flex>

        </Flex>

        {
          processing
          ?
          <NunitoSans
          fontSize={["12px","12px","15px","15px"]}
          textAlign="center"
          >
            Processing...
          </NunitoSans>
          :
          connection.status==="requested"
          ?
          <Flex>
            {
              isMobile
              ?
              <FontAwesomeIcon
              icon={faTimesCircle}
              color="#A3AA9E"
              size="2x"
              onClick={() => _updateConnection(connection, 'declined')}
              />
              :
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#FE4A49"
                onClick={() => _updateConnection(connection, 'declined')}
              >
                IGNORE
              </DashboardSmallCTA>
            }

            {
              isMobile
              ?
              <FontAwesomeIcon
              icon={faCheckCircle}
              color="#428BF4"
              size="2x"
              onClick={() => _updateConnection(connection, 'active')}
              style={{marginLeft: '15px'}}
              />
              :
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                onClick={() => _updateConnection(connection, 'active')}
              >
                ACCEPT
              </DashboardSmallCTA>
            }

          </Flex>
          :
          <NunitoSansBold
          color='#C7C7C7'
          fontSize={["12px","12px","15px","15px"]}
          textAlign="center"
          >
            CONNECTED
          </NunitoSansBold>
        }


    </Flex>
  );
};

export default reduxForm({
  form: 'company',
  validate,
})(ConnectionForm);


function numberParser(number){
  return new Intl.NumberFormat('en-US').format(number)
}