import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { getUser } from "../../utils/auth";
import { DashboardSmallCTA } from "../common/forms";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY);

function App(props) {
  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    let checkoutURL = `${process.env.REACT_APP_API_URL}/createsession/${getUser().id}`;

    if(props.annual){
      checkoutURL += `?annual=${props.annual}`
    }

    const { id:sessionId } = await fetch(checkoutURL).then(res=>res.json());
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;

    if(props.onSuccess){
      props.onSuccess(true);
    }
    const {error} = await stripe.redirectToCheckout({
      sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    
    if(error && error.message && props.onError){
        props.onError(error.message)
    }
  };
  return (

    <DashboardSmallCTA
    onClick={handleClick}
    {
        ...props
    }
    >
        {props.children}
    </DashboardSmallCTA>
    
  );
}


export default App