import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset, Field, getFormValues, reduxForm } from "redux-form";
import _ from "underscore";
import moment from "moment";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import AWS from "aws-sdk";
// import 'react-table/react-table.css';
// import ReactTable from "react-table";

import axios from "axios";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
import Modal from "react-modal";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle} from '@fortawesome/free-regular-svg-icons';



//components
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
import { ClickCursorFlex, IconBox } from "../../common/flexbox";
import { InputStyled, SwitchInput } from "../../common/forms";
import { Flex, Box } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA } from "../../common/forms";
import { BackButton } from "../../common/backbutton";
import ReactTooltip from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faPlus, faMinusCircle, faCog, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import {

  ImageTreatment,
  NavNotificationBubble
} from "../../common/ui";

import ImageCrop from "../../common/imageCrop";

import defaultCompanyImage from "../../../images/default_company.png"; //sytemsettings
import { defaultProfile, defaultCompany } from "../../../utils/defaultLogos";

import currencies from '../../../constants/currency';
import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

import { setIncentiveHovered, setIncentiveSelected, setRowSelected, setCsvDropOpen } from "../../../actions/incentives";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers
} from "../../../actions/search";


import ConfirmModal from "../../common/confirmModal";
import { renderCondensedField } from "../../common/forms";




const DATE = new Date();
let timeout = null;

const RULE_NAMES = (formName = "") => {

  const type = formName.includes('vendor') ? 'PARTNER' : 'REP';

  return {
    "rep_reward_cap": `${type} REWARD CAP`,
    "program_reward_cap": "PROGRAM REWARD CAP",
    "program_quantity_cap": "PROGRAM QUANTITY CAP",
    "reward_per_product_cap": `REWARD PER PRODUCT CAP (PER ${type})`,
    "quantity_per_product_cap": `QUANTITY PER PRODUCT CAP (PER ${type})`,
    "rep_quantity_product_cap": `${type} QUANTITY PRODUCT CAP`,
    "sales_selection": "SALES SELECTION",
    "reward_calc_param": "REWARD CALCULATION PARAMETER"
  }
}

const INCENTIVE_TYPES = ['onetoone', 'increment', 'tier', 'volume', 'quickpay'];

export class ManageSystemSettingsMain extends Component {
  state = {
    customDatesOpen: false,
    customFundingDatesOpen: false,
    customModalOpen: false,
    customModalType: null,
    search: '',
    file_primary: null,
    thePic_primary: '',
    file_secondary: null,
    thePic_secondary: '',
    inviteRequests: [],
    inviteModalOpen: false,
    salesConfigExpanded: false,
    vendorConfigExpanded: false,
    systemManagers: null,
    multiTierSelectedCompany: null,
    selectedApprovers: [],
    searchMultiTier: '',
    multiTierWarningModalType: null,

  }

componentDidMount(){
  this._fetchDependencies();
}


_fetchDependencies = async () => {
    const { systemSettings, fetchSettings } = this.props;
    if (getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || !getUser().system_admin) return history.push('/dashboard');

    if (!systemSettings) {
      fetchSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID).then(response => {

        this._initializeSettingsFormValues(response.payload);
      });
    } else {
      this._initializeSettingsFormValues(systemSettings);
    }

    this._fetchInviteRequests();


    this._search('');
  }

  componentDidUpdate(prevProps){
    if(!_.isEqual(prevProps.systemSettings, this.props.systemSettings)){
      this._initializeSettingsFormValues(this.props.systemSettings);
    }
  }

  _initializeSettingsFormValues = (settings) => {

    if (!settings) return;

    let configValues = {};
    // sets form values for sales incentive configs
    Object.keys(settings.sales_incentives_config).forEach(type => {
      Object.keys(settings.sales_incentives_config[type]).forEach(rule => configValues[`sales_incentives_config~${type}~${rule}`] = settings.sales_incentives_config[type][rule])
    });
    // sets form values for vendor incentive configs
    Object.keys(settings.vendor_incentives_config).forEach(type => {
      Object.keys(settings.vendor_incentives_config[type]).forEach(rule => configValues[`vendor_incentives_config~${type}~${rule}`] = settings.vendor_incentives_config[type][rule])
    });

    let fundingDates = {};

    if (settings.funding_dates && settings.funding_dates.may) {
      Object.keys(settings.funding_dates).forEach(month => fundingDates['funding_dates_' + month] = settings.funding_dates[month]);
    }

    let payload = { ...fundingDates, ...settings.statement_dates, ...configValues, ..._.omit(settings, ["id", "created_at", "updated_at", "company_id", "logo_primary", "logo_secondary", "sales_incentives_config", "vendor_incentives_config", "funding_dates"]) }



    this.props.initialize(payload);
  };



  _fetchInviteRequests = async () => {
    const inviteRequests = await fetch(`${process.env.REACT_APP_API_URL}/invite_lists?status=pending`).then(res => res.json());

    this.setState({ inviteRequests });
  }

  _handleRequestAction = async (id, status) => {

    const { notifyError, fetchNotificationCount } = this.props;

    try {

      const invite = await axios.patch(`${process.env.REACT_APP_API_URL}/invite_list/${id}`, { status, invited_by: getUser().email }).then(res => res.data);

      if (invite && invite.id) {
        this._fetchInviteRequests()
      }

      fetchNotificationCount({ user_id: getUser().id });

    } catch (err) {
      notifyError(err.toString())
    }

  }





  _updateSearch = input => {
    this.setState({ search: input });
    // using clearTimeout and setTimeout to not trigger a search for each keystroke
    clearTimeout(timeout);

    timeout = setTimeout(() => this._search(input), 1000);

  };

  _search = value => {
    const { searchCompanies } = this.props;
    searchCompanies(value);
  }

  _clearSearch = () => {
    this.setState({ search: '' });
    this._search('');
  }

  _resetStatementDates = date => {
    const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];



    const ThirtyDayMonths = ['april', 'june', 'september', 'november'];

    const { initialize, formValues } = this.props;

    let newDates = { ...formValues };

    MONTHS.forEach((month, index) => index !== DATE.getMonth() ? newDates[month] = date > 28 && month === 'february' ? 28 : date === 31 && ThirtyDayMonths.includes(month) ? 30 : date : null);

    // index !== DATE.getMonth() is to prevent current month change

    initialize(newDates);
  }

  _resetFundingDates = date => {
    const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];



    const ThirtyDayMonths = ['april', 'june', 'september', 'november'];

    const { initialize, formValues } = this.props;

    let newDates = { ...formValues };

    MONTHS.forEach((month, index) => index !== DATE.getMonth() ? newDates['funding_dates_' + month] = date > 28 && month === 'february' ? 28 : date === 31 && ThirtyDayMonths.includes(month) ? 30 : date : null);

    // index !== DATE.getMonth() is to prevent current month change

    initialize(newDates);
  }

  onDropPrimary = files => {
    const { notifySuccess, notifyError, change } = this.props;

    let r = false;
    try {
      console.log(files[0].name);
      if (this._checkExtentsion(files[0].name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {

      this.setState({ file_primary: files[0], thePic_primary: files[0].preview });
      change('logo_primary', files[0]);
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  onCropPrimary = file => {
    const { notifySuccess, notifyError, change } = this.props;

    let r = false;
    try {

      if (this._checkExtentsion(file.name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {

      this.setState({ file_primary: file, thePic_primary: file.preview });
      change('logo_primary', file);
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  onDropSecondary = files => {
    const { notifySuccess, notifyError, change } = this.props;

    let r = false;
    try {
      console.log(files[0].name);
      if (this._checkExtentsion(files[0].name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {

      this.setState({ file_secondary: files[0], thePic_secondary: files[0].preview });
      change('logo_secondary', files[0])
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  onCropSecondary = file => {
    const { notifySuccess, notifyError, change } = this.props;

    let r = false;
    try {

      if (this._checkExtentsion(file.name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {

      this.setState({ file_secondary: file, thePic_secondary: file.preview });
      change('logo_secondary', file);
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  _checkExtentsion(filename) {
    let ext = filename.split('.').pop();
    ext = ext.toString().toLowerCase();
    if (ext === "png" || ext === "jpg" || ext === "jpeg") {
      return true;
    }
    return false;
  }

  _handleMultiTierEdit = async (type) => {
    const { updateSettings, fetchUsers } = this.props;
    const { systemManagers } = this.state;

    if (!systemManagers) {
      fetchUsers({ company_id: process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, company_admin: true, system_admin: false, status: 'active' }).then(res => this.setState({ systemManagers: res.payload }))
    }


    this.setState({ customModalOpen: true, customModalType: type });
  }

  _handleMultiTierActivation = async (type) => {
    const { updateSettings, fetchUsers } = this.props;
    const { systemManagers } = this.state;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { [type]: true });

   

    if (!systemManagers) {
      fetchUsers({ company_id: process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, company_admin: true, system_admin: false, status: 'active' }).then(res => this.setState({ systemManagers: res.payload }))
    }

    this.setState({ customModalOpen: true, customModalType: type });
  }

  _handleMultiTierDectivation = async (type) => {
    const { updateSettings } = this.props;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { [type]: false });

  }


  _handlePostingCompaniesActivation = async () => {
    const { companyResults, change, formValues, initialize, updateSettings } = this.props;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { posting_companies_active: true });
    this._setPostingCompanies();
  }

  _setPostingCompanies = () => {
    const { companyResults, formValues, initialize } = this.props;

    this.setState({ customModalOpen: true, customModalType: 'posting_active' });
    let payload = { ...formValues };

    companyResults.forEach(company => payload[`posting_active_${company.id}`] = formValues[`posting_active_${company.id}`] || company.posting_active || false);
    initialize(payload);
  }

  _handlePostingCompaniesDeactivation = async () => {
    const { updateSettings } = this.props;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { posting_companies_active: false });

  }

  _handleIncentiveCompaniesActivation = async formName => {
    const { updateSettings } = this.props;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { [formName]: true });
    this._setIncentiveCompanies(formName);
  }

  _handleIncentiveCompaniesDeactivation = async formName => {
    const { updateSettings } = this.props;

    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, { [formName]: false });
  }

  _setIncentiveCompanies = (formName) => {
    const { companyResults, formValues, initialize } = this.props;

    let name = formName === 'sales_companies_active' ? 'sales_incentive_active' : 'vendor_incentive_active';

    this.setState({ customModalOpen: true, customModalType: name });
    let payload = { ...formValues };

    companyResults.forEach(company => payload[`${name}_${company.id}`] = formValues[`${name}_${company.id}`] || company[name] || false);
    initialize(payload);
  }

  _handleManagerRemove = userIndex => {
    const { selectedApprovers } = this.state;

    let tempApprovers = [...selectedApprovers];

    tempApprovers.splice(userIndex, 1);

    this.setState({ selectedApprovers: tempApprovers });
  }

  _handleApproverSelection = approver => {
    const { selectedApprovers } = this.state;


    let tempApprovers = [...selectedApprovers];

    tempApprovers.unshift(approver);

    this.setState({ selectedApprovers: tempApprovers })
  }

  _handleMultiTierWarningModalClose = () => {
    const { multiTierSelectedCompany, selectedApprovers, customModalType, search } = this.state;

    this.setState({ multiTierWarningModalType: 'unsaved' });
  }

  _saveMultiApprovers = async () => {
    const { multiTierSelectedCompany, selectedApprovers, customModalType, search } = this.state;
    const { formValues, notifyError, notifySuccess } = this.props;

    console.log(multiTierSelectedCompany, 'multiTierSelectedCompany')

    if (selectedApprovers.length < 1 && multiTierSelectedCompany[customModalType].approvers && multiTierSelectedCompany[customModalType].approvers.length === 0) {
      return notifyError("Please select approvers.");
    }

    const numberOfApprovers = selectedApprovers.length === 0 && multiTierSelectedCompany[customModalType].approvers && multiTierSelectedCompany[customModalType].approvers.length !== 0 ? 0 : parseInt(formValues[`${multiTierSelectedCompany.id}~${customModalType}~number_of_approvers`] || 1);

    if (parseInt(formValues[`${multiTierSelectedCompany.id}~${customModalType}~number_of_approvers`] || 1) > selectedApprovers.length) {
      return this.setState({ multiTierWarningModalType: 'notcomplete' });
    }

    let approvers = [...selectedApprovers];

    approvers = approvers.map(approver => {

      const { id, avatar, first_name, last_name, company_id } = approver;
      return {
        id,
        avatar,
        first_name,
        last_name,
        company_id,
        passive_approver: formValues[`${id}~${multiTierSelectedCompany.id}~${customModalType}~passive_approver`] || false
      }
    });

    let payload = {
      number_of_approvers: numberOfApprovers,
      approvers
    };

    try {

      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/companies/${multiTierSelectedCompany.id}`, { [customModalType]: payload }).then(res => res.data);

      if (response && response.id) {

        setTimeout(() => this._search(search), 2000)

        notifySuccess(`${customModalType === 'post_multi_approval' ? 'Post' : 'Incentive'} approvers saved for ${multiTierSelectedCompany.name}.`);

        return this.setState({ multiTierSelectedCompany: null, selectedApprovers: [], searchMultiTier: '' });
      } else {
        return notifyError("Something went wrong");
      }


    } catch (err) {
      return notifyError("Something went wrong");
    }
  }



  _initializeApprovalCompany = company => {

    const { customModalType } = this.state;
    const { formValues, initialize } = this.props;

    if (company[customModalType] && company[customModalType].approvers) {
      let newValues = { ...formValues };

      newValues[`${company.id}~${customModalType}~number_of_approvers`] = company[customModalType].number_of_approvers;

      company[customModalType].approvers.forEach(approver => newValues[`${approver.id}~${company.id}~${customModalType}~passive_approver`] = approver.passive_approver);

      initialize(newValues);

      this.setState({ selectedApprovers: company[customModalType].approvers });
    }

    this.setState({ multiTierSelectedCompany: company })
  }

  render() {
    const { customDatesOpen, customFundingDatesOpen, customModalOpen, customModalType, search, file_primary, thePic_primary, file_secondary, thePic_secondary, inviteModalOpen, inviteRequests, salesConfigExpanded, vendorConfigExpanded, multiTierSelectedCompany, selectedApprovers, systemManagers, searchMultiTier, multiTierWarningModalType } = this.state;
    const { formValues, companyResults, browser, redirect, systemSettings, notificationCounts } = this.props;




    if (!formValues) return null;



    const GENERAL = [

      {
        name: 'NETWORK PRIVACY',
        formName: 'privacy_active',
        description: 'Allow companies to set user privacy features at the company or user level.',
        inputType: 'switch'
      },
      {
        name: 'COMPANY ACCESS FEE',
        formName: 'access_fee',
        description: `Set fees to charge companies for access to your private network.`,
        inputType: 'number'
      },
      {
        name: `INVITE REQUESTS`,
        formName: 'invite_requests',
        description: `Manage invite requests to the join the system.`,
        inputType: 'button',
        buttonText: 'VIEW'
      }
    ];

    const POSTS = [
      {
        name: 'Activate Posting For The System',
        formName: 'posts_active',
        description: 'Allows companies and users on the system to share content using posts on the system.',
        inputType: 'switch'
      },
      {
        name: 'POSTING COMPANIES',
        formName: 'posting_companies_active',
        description: 'Specify what companies can post on the system.',
        inputType: 'button',
        buttonText: 'ACTIVATE'
      },
      {
        name: 'POST APPROVAL',
        formName: 'post_approval',
        description: 'Require posts to be approved by your managers.',
        inputType: 'switch'
      },
      {
        name: 'POST APPROVAL - COWORKERS',
        formName: 'post_approval_coworkers',
        description: 'Require posts added by your coworkers to be approved by your managers.',
        inputType: 'switch'
      },
      {
        name: 'POST MULTI TIER APPROVAL',
        formName: 'post_multi_approval',
        description: 'Require multiple managers to approve posts (per company).',
        inputType: 'button',
        buttonText: 'ACTIVATE'
      },
      {
        name: 'POSTS DEACTIVATION',
        formName: 'post_admin_deactivation',
        description: 'Allow system admins to deactivate posts',
        inputType: 'switch'
      },
    ];

    const REWARDS = [
      {
        name: 'Activate KickPost Rewards for the System',
        formName: 'rewards_active',
        description: 'Activating rewards allows companies on your system to opt in to incentives.',
        inputType: 'switch'
      },

      {
        name: 'CURRENCY SETTING',
        formName: 'currency',
        description: 'Choose the currency that will be used on this system.',
        inputType: 'select'
      },
      {
        name: 'BACKDATING',
        formName: 'backdate_in_months',
        description: 'Set maximum months a sales incentive can be backdated.',
        inputType: 'select'
      },

      {
        name: 'INCENTIVES DEACTIVATION',
        formName: 'incentive_admin_deactivation',
        description: 'Allow system admins to deactivate incentives.',
        inputType: 'switch'
      },
      {
        name: 'Incentive Approval Process',
        formName: 'incentive_approval',
        description: 'Set incentives to be approved by your managers.',
        inputType: 'switch'
      },
      {
        name: 'Incentive Approval Process - Coworkers',
        formName: 'incentive_approval_coworkers',
        description: 'Set incentives added by your coworkers to be approved by your managers.',
        inputType: 'switch'
      },
      {
        name: 'INCENTIVE MULTI TIER APPROVAL',
        formName: 'incentive_multi_approval',
        description: 'Require multiple managers to approve incentives (per company).',
        inputType: 'button',
        buttonText: 'ACTIVATE'
      },

      {
        name: 'Statement Dates',
        formName: 'statement_dates',
        description: 'Set a custom statement date for each month using the dropdown menu, or use the global override button to assign all months. (Note: cannot change statement date for current month)',
        inputType: 'select'
      },
      {
        name: 'Funding Dates',
        formName: 'funding_dates',
        description: 'Set a custom funding date for the incentives you run each month, or use the global override to assign all months. (Note: cannot change funding date for current month)',
        inputType: 'select'
      },
      {
        name: 'Activate Products Database',
        formName: 'product_active',
        description: 'Do you want to activate products database?',
        inputType: 'switch'
      },
      {
        name: 'Internal Part Number',
        formName: 'part_number_active',
        description: 'Do you want to have internal part numbers in products database?',
        inputType: 'switch'
      },
      {
        name: 'Internal Part Number Name',
        formName: 'part_number_name',
        description: 'What do you call the internal part number?',
        inputType: 'text'
      },
      {
        name: 'Reward type',
        formName: 'reward_type',
        description: 'Set reward types for the system to process.',
        inputType: 'select'
      },
      {
        name: 'Reward Splits',
        formName: 'reward_splits',
        description: 'Activate Reward Splits to be set by each company.',
        inputType: 'switch'
      }
    ];

    const SALES_INCENTIVES = [

      {
        name: 'Activate Sales Incentives',
        formName: 'sales_incentives_active',
        description: 'Allow companies on your system to create and/or receive sales incentive programs.',
        inputType: 'switch'
      },
      {
        name: 'Sales Incentives Companies',
        formName: 'sales_companies_active',
        description: 'Specify what companies can create/receive sales incentives.',
        inputType: 'button'
      },
      {
        name: 'Configure Sales Incentive Types and Rules',
        formName: 'sales_incentives_config',
        description: 'Choose which sales incentive types you want to activate on your system.',
        inputType: 'icon'
      },

      {
        name: 'Sales Incentive Fee',
        formName: 'incentive_fee_active',
        description: 'Activate management fee for incentives in your network.',
        inputType: 'switch'
      },

      {
        name: 'System wide incentive fee',
        formName: 'system_incentive_fee',
        description: 'Set percent (%) management fee to charge your network for sales incentives.',
        inputType: 'select'
      },

      {
        name: 'Specific company incentive fee',
        formName: 'company_incentive_fee',
        description: 'Set percent (%) management fee to charge a specific company for sales incentives.',
        inputType: 'link'
      }
    ];

    const PARTNER_INCENTIVES = [

      {
        name: 'Activate Partner Incentives',
        formName: 'vendor_incentives_active',
        description: 'Allow companies on your system to create and/or receive partner incentives.',
        inputType: 'switch'
      },
      {
        name: 'Partner Incentives Companies',
        formName: 'vendor_companies_active',
        description: 'Specify what companies can create/receive partner incentives.',
        inputType: 'button'
      },
      {
        name: 'Configure Partner Incentive Types and Rules',
        formName: 'vendor_incentives_config',
        description: 'Choose which partner incentive types you want to activate on your system.',
        inputType: 'icon'
      },

      {
        name: 'Partner Incentive Fee',
        formName: 'vendor_incentive_fee_active',
        description: 'Activate management fee for partner incentives in your network.',
        inputType: 'switch'
      },

      {
        name: 'System wide partner incentive fee',
        formName: 'system_vendor_incentive_fee',
        description: 'Set percent (%) management fee to charge your network for partner incentives.',
        inputType: 'select'
      },
      {
        name: 'Specific company partner incentive fee',
        formName: 'company_vendor_incentive_fee',
        description: 'Set percent (%) management fee to charge a specific company for partner incentives.',
        inputType: 'link'
      },

    ];

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "400px",
        height: "80vh",
        zIndex: 900008,
        overflow: "auto"
      },

      overlay: {
        zIndex: 900007,

        background: "#A3A99E88"
      }

    };

    const customMultiTierStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "500px",
        height: "80vh",
        zIndex: 999998,
        // overflow: "auto"
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88"
      }

    };

    const inviteModalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "400px",
        maxHeight: "80vh",
        zIndex: 999998,
        overflow: "auto"
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88"
      }

    };



    const currencyMask = (decimal = 2, negative = false) => createNumberMask({
      prefix: currencies[formValues.currency].symbol,
      // suffix: formValues.access_fee_period==='annual' ? ' per year' : ' per month',
      decimalPlaces: decimal,
      allowNegative: negative,
      locale: 'en-US',
    });

    const numberMask = createNumberMask({
      // prefix: '$ ',
      // suffix: ' per item',
      allowNegative: false,
      locale: 'en-US',
    });

    const approverIds = selectedApprovers.map(approver => approver.id);
    const filteredManagers = systemManagers && systemManagers.length ? systemManagers.filter(manager => (manager.first_name.toLowerCase().includes(searchMultiTier.toLowerCase()) || manager.last_name.toLowerCase().includes(searchMultiTier.toLowerCase())) && !approverIds.includes(manager.id)) : [];

    if (getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) return null;

    console.log(multiTierSelectedCompany, 'multiTierSelectedCompany')

    return <>
      <Flex
        flexDirection="column"
        style={{ height: "85vh", overflowY: "auto", overflowX: 'hidden', paddingRight: '25px', marginTop: '55px' }}
      >
        <Modal
          isOpen={inviteModalOpen}
          onRequestClose={() => this.setState({ inviteModalOpen: false })}
          style={inviteModalStyles}
          contentLabel="Invite Modal"
          ariaHideApp={false}
        >
          <Flex
            width='100%'
            justifyContent="flex-end"
          >
            <IconBox
              cursor="pointer"
              fontSize={["12px"]}
              color="#C7C7C7"
              onClick={() => this.setState({ inviteModalOpen: false })}
            >
              thinx
            </IconBox>
          </Flex>
          <NunitoSansBold
            textAlign='center'
            style={{ marginBottom: '15px', marginTop: '10px' }}
          >
            INVITE REQUESTS
          </NunitoSansBold>

          {
            inviteRequests && inviteRequests.length
              ?
              <Flex
                flexDirection='column'
                style={{ marginBottom: '15px' }}
              >
                {
                  inviteRequests.map((request, index, arr) => (
                    <Flex
                      alignItems='center'
                      justifyContent='space-between'
                      style={{ paddingBottom: '5px', borderBottom: index !== arr.length - 1 ? '0.5px solid #c3c3c3' : 'none' }}
                    >
                      <Flex
                        alignItems='center'
                      >
                        <NunitoSans
                          fontWeight={600}
                          fontSize='14px'
                          color="#000000"
                        >
                          {request.email}
                        </NunitoSans>
                        <NunitoSans
                          fontSize="10px"
                          color="#c3c3c3"
                          style={{ marginLeft: "5px" }}
                        >
                          {moment(request.created_at).fromNow()}
                        </NunitoSans>
                      </Flex>

                      <Flex
                        alignItems='center'

                      >
                        <DashboardSmallCTA

                          onClick={() => this._handleRequestAction(request.id, 'denied')}
                          minWidth={["80px"]}
                          fontSize="12px"
                          innerHeight="20px"
                          width="80px"
                          color="#FE4A49"
                        >
                          DENY
                        </DashboardSmallCTA>

                        <DashboardSmallCTA

                          onClick={() => this._handleRequestAction(request.id, 'active')}
                          minWidth={["80px"]}
                          fontSize="12px"
                          innerHeight="20px"
                          width="80px"
                          color="#428BF4"
                          style={{ marginLeft: '5px' }}
                        >
                          APPROVE
                        </DashboardSmallCTA>
                      </Flex>

                    </Flex>
                  ))
                }

              </Flex>

              :
              <NunitoSans
                fontSize="20px"
                fontWeight={500}
                textAlign='center'
                color="D3D3D3"
                style={{ marginBottom: '15px' }}
              >
                There are no requests.
              </NunitoSans>
          }

        </Modal>


        <form id="system-settings-form">
          <Flex
            style={{ borderBottom: '5px solid #D3D3D3', marginBottom: '20px' }}
          >
            <NunitoSans
              fontSize="16px"
              color="#6C7168"
              fontWeight="bolder"
            >
              KICKPOST
            </NunitoSans>
          </Flex>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "20px", width: '100%' }}
          >
            <Flex
              width="45%"
            >
              <Flex
                flexDirection="column"
              >
                <NunitoSans
                  color="#10213A"
                  fontWeight="bold"
                  fontSize="15px"
                >
                  SYSTEM PRIMARY LOGO
                </NunitoSans>
                <NunitoSans
                  color="#000000"
                  fontSize="14px"
                >
                  For display on Desktop
                </NunitoSans>

              </Flex>

              <Flex
                ml="20px"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              // width="100%"
              >
                <ImageTreatment
                  img={thePic_primary || (file_primary && file_primary.preview) || systemSettings.logo_primary || defaultCompanyImage}
                  blurRadius={"15px"}
                  imageInset={0}
                  borderRadius={10}
                  innerBorderRadius={0}
                  shadowOffset={0.1}
                  padding={10}
                  height={75}
                  width={75}
                />
                <Box ml="20px" data-tip="Click here or drag and drop to upload">
                  {/* <SquareDropzoneSmall onDrop={this.onDropPrimary} /> */}
                  <ImageCrop dropZoneType='square_small' onCrop={this.onCropPrimary} notifyError={this.props.notifyError} />
                </Box>
                <ReactTooltip />
              </Flex>
            </Flex>

            <Flex
              width="45%"
            >
              <Flex
                flexDirection="column"
              >
                <NunitoSans
                  color="#10213A"
                  fontWeight="bold"
                  fontSize="15px"
                >
                  SYSTEM SECONDARY LOGO
                </NunitoSans>
                <NunitoSans
                  color="#000000"
                  fontSize="14px"
                >
                  For display on Mobile
                </NunitoSans>

              </Flex>

              <Flex
                ml="20px"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              // width="100%"
              >
                <ImageTreatment
                  img={thePic_secondary || (file_secondary && file_secondary.preview) || systemSettings.logo_secondary || defaultCompanyImage}
                  blurRadius={"15px"}
                  imageInset={0}
                  borderRadius={10}
                  innerBorderRadius={0}
                  shadowOffset={0.1}
                  padding={10}
                  height={25}
                  width={100}
                />
                <Box ml="20px" data-tip="Click here or drag and drop to upload">
                  {/* <SquareDropzoneSmall onDrop={this.onDropSecondary} /> */}
                  <ImageCrop dropZoneType='square_small' onCrop={this.onCropSecondary} notifyError={this.props.notifyError} aspectRect={true} />
                </Box>
                <ReactTooltip />
              </Flex>
            </Flex>

          </Flex>

          {GENERAL.map((setting, index) => {





            return (
              <Flex
                alignItems='center'
                justifyContent='space-between'
                style={{ borderBottom: '1px solid #D3D3D3', borderTop: index === 0 ? '1px solid #D3D3D3' : "none" }}
              >
                <Flex
                  flexDirection="column"
                  style={{ marginTop: '13px', marginBottom: '13px' }}
                >
                  <div style={{ position: 'relative' }}>
                    <NunitoSans
                      fontSize='14px'
                      fontWeight='bold'
                      color='#10213A'
                    >
                      {setting.name.toUpperCase()}
                    </NunitoSans>
                    {
                      setting.formName === 'invite_requests' && notificationCounts.settings
                        ?
                        <NavNotificationBubble
                          top="-5px"
                          left="120px"
                        >
                          {notificationCounts.settings}
                        </NavNotificationBubble>
                        :
                        null
                    }
                  </div>
                  <NunitoSans
                    fontSize='14px'
                    color='#000000'
                  >
                    {setting.description}
                  </NunitoSans>

                </Flex>

                <Flex
                  flexDirection="column"
                  style={{ marginTop: '13px', marginBottom: '13px' }}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                  >

                    {
                      setting.inputType === 'button'
                        ?
                        <DashboardSmallCTA
                          onClick={() => this.setState({ inviteModalOpen: true })}
                          minWidth={["122px"]}
                          fontSize="14px"
                          innerHeight="30px"
                          width="150px"
                          color="#428BF4"
                        >
                          {setting.buttonText}
                        </DashboardSmallCTA>
                        :
                        setting.inputType === 'switch'
                          ?
                          <Field
                            key={setting.formName}
                            id={setting.formName}
                            name={setting.formName}
                            type="checkmark"
                            component={SwitchInput}
                            onColor='#2FD371'
                            offColor='#D3D3D3'
                          />
                          :
                          <Flex
                            justifyContent="space-between"
                          >
                            <Flex
                              flexDirection="column"
                              justifyContent='center'
                              alignItems='center'
                            >
                              <NunitoSans>
                                Monthly
                              </NunitoSans>
                              <Field
                                id={"monthly_" + setting.formName}
                                name={"monthly_" + setting.formName}
                                component='input'
                                type='text'
                                style={{ width: '200px', height: '39px', border: '1px solid #707070', color: '#000000', fontFamily: 'NunitoSans', fontSize: '14px', textAlign: 'center' }}
                                {...currencyMask()}
                              />

                            </Flex>
                            <Flex
                              flexDirection="column"
                              justifyContent='center'
                              alignItems='center'
                              style={{ marginLeft: '10px' }}
                            >
                              <NunitoSans>
                                Annual
                              </NunitoSans>
                              <Field
                                id={"annual_" + setting.formName}
                                name={"annual_" + setting.formName}
                                component='input'
                                type='text'
                                style={{ width: '200px', height: '39px', border: '1px solid #707070', color: '#000000', fontFamily: 'NunitoSans', fontSize: '14px', textAlign: 'center' }}
                                {...currencyMask()}
                              />

                            </Flex>

                          </Flex>

                    }

                  </Flex>


                </Flex>

              </Flex>
            )
          }
          )}


          <Flex
            style={{ borderBottom: '5px solid #D3D3D3', marginTop: '47px' }}
          >
            <NunitoSans
              fontSize="16px"
              color="#6C7168"
              fontWeight="bolder"
            >
              POSTS
            </NunitoSans>
          </Flex>

          {
            POSTS.map((setting, index) => {

              if (setting.formName !== 'posts_active' && !formValues.posts_active) return null;
              if ((setting.formName === 'post_approval_coworkers' || setting.formName === 'post_multi_approval') && !formValues.post_approval) return null;

              return (
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  style={{ borderBottom: '1px solid #D3D3D3', borderTop: index === 0 ? '1px solid #D3D3D3' : "none" }}
                >
                  <Flex
                    flexDirection="column"
                    style={{ marginTop: '13px', marginBottom: '13px' }}
                  >
                    <NunitoSans
                      fontSize='14px'
                      fontWeight='bold'
                      color='#10213A'
                    >
                      {setting.name.toUpperCase()}
                    </NunitoSans>
                    <NunitoSans
                      fontSize='14px'
                      color='#000000'
                    >
                      {setting.description}
                    </NunitoSans>

                  </Flex>

                  <Flex
                    flexDirection="column"
                    style={{ marginTop: '13px', marginBottom: '13px' }}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                    >

                      {
                        setting.inputType === 'button' && setting.formName === 'post_multi_approval'
                          ?
                          <Flex
                            justifyContent="space-between"
                          >
                            {
                              systemSettings.post_multi_approval
                                ?
                                <DashboardSmallCTA
                                  mr="10px"
                                  minWidth={["122px"]}
                                  fontSize="14px"
                                  innerHeight="30px"
                                  width="150px"
                                  onClick={() => this._handleMultiTierEdit('post_multi_approval')}
                                  color="#428BF4"
                                >
                                  EDIT
                                </DashboardSmallCTA>
                                :
                                null
                            }

                            <DashboardSmallCTA
                              minWidth={["122px"]}
                              fontSize="14px"
                              innerHeight="30px"
                              width="150px"
                              onClick={() => systemSettings.post_multi_approval ? this._handleMultiTierDectivation('post_multi_approval') : this._handleMultiTierActivation('post_multi_approval')}
                              color="#428BF4"
                            >
                              {systemSettings.post_multi_approval ? 'DEACTIVATE' : 'ACTIVATE'}
                            </DashboardSmallCTA>
                          </Flex>
                          :
                          setting.inputType === 'button' && setting.formName === 'posting_companies_active'
                            ?
                            <Flex
                              justifyContent="space-between"
                            >
                              {
                                systemSettings.posting_companies_active
                                  ?
                                  <DashboardSmallCTA
                                    mr="10px"
                                    minWidth={["122px"]}
                                    fontSize="14px"
                                    innerHeight="30px"
                                    width="150px"
                                    onClick={() => this._setPostingCompanies()}
                                    color="#428BF4"
                                  >
                                    EDIT
                                  </DashboardSmallCTA>
                                  :
                                  null
                              }

                              <DashboardSmallCTA
                                minWidth={["122px"]}
                                fontSize="14px"
                                innerHeight="30px"
                                width="150px"
                                onClick={() => systemSettings.posting_companies_active ? this._handlePostingCompaniesDeactivation() : this._handlePostingCompaniesActivation()}
                                color="#428BF4"
                              >
                                {systemSettings.posting_companies_active ? 'DEACTIVATE' : 'ACTIVATE'}
                              </DashboardSmallCTA>
                            </Flex>
                            :
                            setting.inputType === 'switch'
                              ?
                              <Field
                                id={setting.formName}
                                name={setting.formName}
                                type="checkmark"
                                component={SwitchInput}
                                onColor='#2FD371'
                                offColor='#D3D3D3'
                              />
                              :
                              null

                      }

                    </Flex>


                  </Flex>

                </Flex>
              );
            })
          }

          <Flex
            style={{ borderBottom: '5px solid #D3D3D3', marginTop: '47px' }}
          >
            <NunitoSans
              fontSize="16px"
              color="#6C7168"
              fontWeight="bolder"
            >
              KICKPOST REWARDS
            </NunitoSans>
          </Flex>
          {
            REWARDS.map((setting, index) => {
              if (index > 0 && (!formValues || !formValues.rewards_active)) return null;
              if ((!formValues || !formValues.incentive_fee_active) && (setting.formName === 'system_incentive_fee' || setting.formName === 'company_incentive_fee')) return null;
              if ((!formValues || !formValues.vendor_incentive_fee_active) && (setting.formName === 'system_vendor_incentive_fee' || setting.formName === 'company_vendor_incentive_fee')) return null;
              if (!formValues.sales_incentives_active && setting.formName === 'sales_companies_active') return null;
              if (!formValues.vendor_incentives_active && setting.formName === 'vendor_companies_active') return null;

              if ((setting.formName === 'incentive_approval_coworkers' || setting.formName === 'incentive_multi_approval') && !formValues.incentive_approval) return null;
              if ((setting.formName === 'part_number_active' || setting.formName === 'part_number_name') && !formValues.product_active) return null;
              if (setting.formName === "part_number_name" && !formValues.part_number_active) return null;
              if (setting.formName === "funding_dates" && !systemSettings.pre_funded) return null;
              return (
                <Flex
                  flexDirection="column"
                  style={{ borderBottom: '1px solid #D3D3D3', marginBottom: index === REWARDS.length - 1 ? '50px' : '0px' }}
                >
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                  // style={{borderBottom:'1px solid #D3D3D3', marginBottom: index===REWARDS.length-1 ? '50px' : '0px'}}
                  >
                    <Flex
                      flexDirection="column"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      <NunitoSans
                        fontSize='14px'
                        fontWeight='bold'
                        color='#10213A'
                      >
                        {setting.name.toUpperCase()}
                      </NunitoSans>
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                      >
                        {setting.description}
                      </NunitoSans>

                    </Flex>

                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      {
                        setting.inputType === 'button' && setting.formName === 'incentive_multi_approval'
                          ?
                          <Flex
                            justifyContent="space-between"
                          >
                            {
                              systemSettings.incentive_multi_approval
                                ?
                                <DashboardSmallCTA
                                  mr="10px"
                                  minWidth={["122px"]}
                                  fontSize="14px"
                                  innerHeight="30px"
                                  width="150px"
                                  onClick={() => this._handleMultiTierEdit('incentive_multi_approval')}
                                  color="#428BF4"
                                >
                                  EDIT
                                </DashboardSmallCTA>
                                :
                                null
                            }

                            <DashboardSmallCTA
                              minWidth={["122px"]}
                              fontSize="14px"
                              innerHeight="30px"
                              width="150px"
                              onClick={() => systemSettings.incentive_multi_approval ? this._handleMultiTierDectivation('incentive_multi_approval') : this._handleMultiTierActivation('incentive_multi_approval')}
                              color="#428BF4"
                            >
                              {systemSettings.incentive_multi_approval ? 'DEACTIVATE' : 'ACTIVATE'}
                            </DashboardSmallCTA>
                          </Flex>
                          :
                          setting.inputType === 'icon' && (setting.formName === 'sales_incentives_config' || setting.formName === 'vendor_incentives_config')
                            ?
                            // <DashboardSmallCTA
                            //     width="15%"
                            //     mr="10px"
                            //     minWidth={["122px"]}
                            //     fontSize="14px"
                            //     innerHeight="30px"
                            //     width="130px"
                            //     onClick={()=> {
                            //       if(setting.formName==='sales_incentives_config'){
                            //         this.setState({salesConfigExpanded: !salesConfigExpanded})
                            //       }else{
                            //         this.setState({vendorConfigExpanded: !vendorConfigExpanded})
                            //       }
                            //     }}
                            //     color="#428BF4"
                            //     >
                            //       {
                            //       (setting.formName==='sales_incentives_config' && salesConfigExpanded) || (setting.formName==='vendor_incentives_config' && vendorConfigExpanded)
                            //       ?
                            //       "MINIMIZE"
                            //       :
                            //       "EXPAND"
                            //       }
                            //     </DashboardSmallCTA>

                            <FontAwesomeIcon
                              icon={
                                (setting.formName === 'sales_incentives_config' && salesConfigExpanded) || (setting.formName === 'vendor_incentives_config' && vendorConfigExpanded)
                                  ?
                                  faCaretDown
                                  :
                                  faCaretRight
                              }
                              color="#428BF4"
                              size="lg"
                              onClick={() => {
                                if (setting.formName === 'sales_incentives_config') {
                                  this.setState({ salesConfigExpanded: !salesConfigExpanded })
                                } else {
                                  this.setState({ vendorConfigExpanded: !vendorConfigExpanded })
                                }
                              }}
                            />

                            :
                            setting.inputType === 'button' && (setting.formName === 'sales_companies_active' || setting.formName === 'vendor_companies_active')
                              ?
                              <Flex
                                justifyContent="space-between"
                              >
                                {
                                  systemSettings[setting.formName]
                                    ?
                                    <DashboardSmallCTA
                                      mr="10px"
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="150px"
                                      onClick={() => this._setIncentiveCompanies(setting.formName)}
                                      color="#428BF4"
                                    >
                                      EDIT
                                    </DashboardSmallCTA>
                                    :
                                    null
                                }

                                <DashboardSmallCTA
                                  minWidth={["122px"]}
                                  fontSize="14px"
                                  innerHeight="30px"
                                  width="150px"
                                  onClick={() => systemSettings[setting.formName] ? this._handleIncentiveCompaniesDeactivation(setting.formName) : this._handleIncentiveCompaniesActivation(setting.formName)}
                                  color="#428BF4"
                                >
                                  {systemSettings[setting.formName] ? 'DEACTIVATE' : 'ACTIVATE'}
                                </DashboardSmallCTA>
                              </Flex>
                              :
                              setting.inputType === 'switch' ?
                                <Field
                                  id={setting.formName}
                                  name={setting.formName}
                                  type="checkmark"
                                  component={SwitchInput}
                                  onColor='#428BF4'
                                  offColor='#D3D3D3'
                                />
                                :
                                setting.inputType === 'select' ?
                                  <Field
                                    id={setting.formName}
                                    name={setting.formName}
                                    onChange={field => setting.formName === 'statement_dates' ? this._resetStatementDates(parseInt(field.target.value)) : setting.formName === 'funding_dates' ? this._resetFundingDates(parseInt(field.target.value)) : null}
                                    component="select"
                                    style={{ border: '1px solid #707070', width: setting.formName === 'statement_dates' || setting.formName === 'funding_dates' ? '70px' : '200px', height: '39px', fontSize: '14px', fontFamily: "NunitoSans", color: "#000000", paddingLeft: '20px' }}
                                  >
                                    {setting.formName === 'currency' ?
                                      Object.keys(currencies).map((currency, index) => (
                                        <option key={index + setting.formName} value={currency}>{currencies[currency].name + ' - ' + currencies[currency].symbol}</option>
                                      ))
                                      :
                                      setting.formName === 'backdate_in_months' ?
                                        Array(13).fill("1").map((a, index) => (
                                          <option key={index + setting.formName} value={index}>{index + (index > 1 ? ' months' : ' month')}</option>
                                        ))
                                        :
                                        setting.formName === 'system_incentive_fee' || setting.formName === "system_vendor_incentive_fee" ?
                                          Array(21).fill("1").map((a, index) => (
                                            <option key={index + setting.formName} value={index * 5}>{(index * 5) + '%'}</option>
                                          ))
                                          :
                                          setting.formName === 'statement_dates' || setting.formName === 'funding_dates' ?
                                            Array(31).fill("1").map((a, index) => (
                                              <option key={index + setting.formName} value={index + 1}>{(index + 1)}</option>
                                            ))
                                            :
                                            setting.formName === 'reward_type' ?
                                              ['Cash', 'Prizes', 'Both'].map((type, index) => (
                                                <option key={index + setting.formName} value={type.toLowerCase()}>{type}</option>
                                              ))

                                              :

                                              null
                                    }


                                  </Field>
                                  :
                                  setting.inputType === 'text'
                                    ?
                                    <Field
                                      id={setting.formName}
                                      name={setting.formName}
                                      component='input'
                                      type='text'
                                      style={{ width: '200px', height: '39px', border: '1px solid #707070', color: '#000000', fontFamily: 'NunitoSans', fontSize: '14px', textAlign: 'center' }}

                                    />
                                    :
                                    setting.formName === 'company_incentive_fee' ?
                                      <NunitoSans
                                        fontSize='14px'
                                        color='#000000'
                                        className="settingsLink"
                                        style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                                        onClick={() => {
                                          const { companyResults, change, formValues, initialize } = this.props;
                                          this.setState({ customModalOpen: true, customModalType: 'incentive_fee' });
                                          let payload = { ...formValues };
                                          // companyResults.forEach(company=>change(`fee_${company.id}`, '10'))
                                          companyResults.forEach(company => payload[`fee_${company.id}`] = company.incentive_fee || formValues.system_incentive_fee);
                                          initialize(payload);
                                          // this._search('');
                                        }
                                        }
                                      >
                                        Set custom fees for each company
                                      </NunitoSans>
                                      :
                                      null
                      }



                    </Flex>



                  </Flex>
                  <Flex
                    justifyContent="flex-end"
                  >
                    {setting.formName === 'statement_dates' || setting.formName === 'funding_dates' ?
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                        className="settingsLink"
                        style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                        onClick={() => this.setState(setting.formName === 'statement_dates' ? { customDatesOpen: !customDatesOpen } : { customFundingDatesOpen: !customFundingDatesOpen })}
                      >
                        {(setting.formName === 'statement_dates' && customDatesOpen) || (setting.formName === 'funding_dates' && customFundingDatesOpen) ? 'CLOSE' : 'Set custom dates for each month'}
                      </NunitoSans>
                      :
                      null
                    }

                  </Flex>
                  {(setting.formName === "statement_dates" && customDatesOpen) || (setting.formName === "funding_dates" && customFundingDatesOpen) ?
                    <Flex
                      flexDirection="column"
                      // alignItems="center"
                      style={{ marginBottom: '13px' }}
                    >
                      <Flex
                        justifyContent="space-evenly"
                      >
                        {['january', 'february', 'march', 'april', 'may', 'june'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={setting.formName === "statement_dates" ? month : "funding_dates_" + month}
                              name={setting.formName === "statement_dates" ? month : "funding_dates_" + month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === index }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'february' ? 28 : month === 'april' || month === 'june' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={index + month} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex
                        justifyContent="space-evenly"
                        style={{ marginTop: '5px' }}
                      >
                        {['july', 'august', 'september', 'october', 'november', 'december'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={setting.formName === "statement_dates" ? month : "funding_dates_" + month}
                              name={setting.formName === "statement_dates" ? month : "funding_dates_" + month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === (index + 6) }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'september' || month === 'november' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={month + index} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                    :
                    null
                  }
                  {
                    (setting.formName === "sales_incentives_config" && salesConfigExpanded) || (setting.formName === "vendor_incentives_config" && vendorConfigExpanded)
                      ?
                      <Flex
                        flexDirection="column"
                        style={{ paddingLeft: '25px' }}
                      >
                        {
                          INCENTIVE_TYPES.map((type, index, arr) => (
                            <Flex
                              flexDirection="column"
                            >
                              <Flex
                                alignItems='center'
                                justifyContent='space-between'
                                style={{ borderTop: '1px solid #D3D3D3' }}
                              >
                                <Flex
                                  flexDirection="column"
                                  style={{ marginTop: '13px', marginBottom: '13px' }}
                                >
                                  <NunitoSans
                                    fontSize='12px'
                                    fontWeight='bold'
                                    color='#10213A'
                                  >
                                    {(type === 'onetoone' ? 'one to one' : type).toUpperCase()}
                                  </NunitoSans>
                                  {/* <NunitoSans
                                fontSize='12px'
                                color='#000000'
                                >
                                  {setting.description}
                                </NunitoSans> */}

                                </Flex>
                                <Field
                                  id={`${setting.formName}~${type}~isActive`}
                                  name={`${setting.formName}~${type}~isActive`}
                                  type="checkmark"
                                  component={SwitchInput}
                                  onColor='#428BF4'
                                  offColor='#D3D3D3'
                                  height={28}
                                  width={56}
                                />

                              </Flex>
                              {
                                formValues[`${setting.formName}~${type}~isActive`] && Object.keys(systemSettings[setting.formName][type]).filter(key => key !== 'isActive').map(rule => (
                                  <div style={{ paddingLeft: '15px' }}>
                                    <Flex
                                      alignItems='center'
                                      justifyContent='space-between'
                                      style={{ borderTop: '1px solid #D3D3D3' }}
                                    >
                                      <Flex
                                        flexDirection="column"
                                        style={{ marginTop: '13px', marginBottom: '13px' }}
                                      >
                                        <NunitoSans
                                          fontSize='10px'
                                          fontWeight='bold'
                                          color='#428BF4'
                                        >
                                          {setting.formName === "vendor_incentives_config" ? RULE_NAMES(setting.formName)[rule].toUpperCase().replaceAll('REP', 'PARTNER') : RULE_NAMES(setting.formName)[rule].toUpperCase()}
                                        </NunitoSans>
                                        {/* <NunitoSans
                                    fontSize='12px'
                                    color='#000000'
                                    >
                                      {setting.description}
                                    </NunitoSans> */}

                                      </Flex>
                                      <Field
                                        id={`${setting.formName}~${type}~${rule}`}
                                        name={`${setting.formName}~${type}~${rule}`}
                                        type="checkmark"
                                        component={SwitchInput}
                                        onColor='#2FD371'
                                        offColor='#D3D3D3'
                                        height={24}
                                        width={48}
                                      />

                                    </Flex>
                                  </div>
                                ))
                              }
                            </Flex>

                          ))
                        }

                      </Flex>

                      :
                      null
                  }

                </Flex>
              )
            }

            )}

          {
            formValues.rewards_active
              ?
              <Flex
                style={{ borderBottom: '5px solid #D3D3D3', marginTop: '47px' }}
              >
                <NunitoSans
                  fontSize="16px"
                  color="#6C7168"
                  fontWeight="bolder"
                >
                  SALES INCENTIVES
                </NunitoSans>
              </Flex>
              :
              null
          }


          {
            formValues.rewards_active && SALES_INCENTIVES.map((setting, index) => {
              if (index > 0 && (!formValues || !formValues.rewards_active)) return null;
              if (!formValues.sales_incentives_active && setting.formName !== 'sales_incentives_active') return null;
              if ((!formValues || !formValues.incentive_fee_active) && (setting.formName === 'system_incentive_fee' || setting.formName === 'company_incentive_fee')) return null;
              if ((!formValues || !formValues.vendor_incentive_fee_active) && (setting.formName === 'system_vendor_incentive_fee' || setting.formName === 'company_vendor_incentive_fee')) return null;
              if (!formValues.sales_incentives_active && setting.formName === 'sales_companies_active') return null;
              if (!formValues.vendor_incentives_active && setting.formName === 'vendor_companies_active') return null;

              if (setting.formName === 'incentive_approval_coworkers' && !formValues.incentive_approval) return null;
              if ((setting.formName === 'part_number_active' || setting.formName === 'part_number_name') && !formValues.product_active) return null;
              if (setting.formName === "part_number_name" && !formValues.part_number_active) return null;
              return (
                <Flex
                  flexDirection="column"
                  style={{ borderBottom: '1px solid #D3D3D3', marginBottom: index === REWARDS.length - 1 ? '50px' : '0px' }}
                >
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                  // style={{borderBottom:'1px solid #D3D3D3', marginBottom: index===REWARDS.length-1 ? '50px' : '0px'}}
                  >
                    <Flex
                      flexDirection="column"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      <NunitoSans
                        fontSize='14px'
                        fontWeight='bold'
                        color='#10213A'
                      >
                        {setting.name.toUpperCase()}
                      </NunitoSans>
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                      >
                        {setting.description}
                      </NunitoSans>

                    </Flex>

                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      {
                        setting.inputType === 'icon' && (setting.formName === 'sales_incentives_config' || setting.formName === 'vendor_incentives_config')
                          ?

                          <FontAwesomeIcon
                            icon={
                              (setting.formName === 'sales_incentives_config' && salesConfigExpanded) || (setting.formName === 'vendor_incentives_config' && vendorConfigExpanded)
                                ?
                                faCaretDown
                                :
                                faCaretRight
                            }
                            color="#428BF4"
                            size="3x"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (setting.formName === 'sales_incentives_config') {
                                this.setState({ salesConfigExpanded: !salesConfigExpanded })
                              } else {
                                this.setState({ vendorConfigExpanded: !vendorConfigExpanded })
                              }
                            }}
                          />
                          :
                          setting.inputType === 'button' && (setting.formName === 'sales_companies_active' || setting.formName === 'vendor_companies_active')
                            ?
                            <Flex
                              justifyContent="space-between"
                            >
                              {
                                systemSettings[setting.formName]
                                  ?
                                  <DashboardSmallCTA
                                    mr="10px"
                                    minWidth={["122px"]}
                                    fontSize="14px"
                                    innerHeight="30px"
                                    width="150px"
                                    onClick={() => this._setIncentiveCompanies(setting.formName)}
                                    color="#428BF4"
                                  >
                                    EDIT
                                  </DashboardSmallCTA>
                                  :
                                  null
                              }

                              <DashboardSmallCTA
                                minWidth={["122px"]}
                                fontSize="14px"
                                innerHeight="30px"
                                width="150px"
                                onClick={() => systemSettings[setting.formName] ? this._handleIncentiveCompaniesDeactivation(setting.formName) : this._handleIncentiveCompaniesActivation(setting.formName)}
                                color="#428BF4"
                              >
                                {systemSettings[setting.formName] ? 'DEACTIVATE' : 'ACTIVATE'}
                              </DashboardSmallCTA>
                            </Flex>
                            :
                            setting.inputType === 'switch' ?
                              <Field
                                id={setting.formName}
                                name={setting.formName}
                                type="checkmark"
                                component={SwitchInput}
                                onColor='#428BF4'
                                offColor='#D3D3D3'
                              />
                              :
                              setting.inputType === 'select' ?
                                <Field
                                  id={setting.formName}
                                  name={setting.formName}
                                  onChange={field => setting.formName === 'statement_dates' ? this._resetStatementDates(parseInt(field.target.value)) : null}
                                  component="select"
                                  style={{ border: '1px solid #707070', width: '100px', height: '39px', fontSize: '14px', fontFamily: "NunitoSans", color: "#000000", paddingLeft: '20px' }}
                                >
                                  {setting.formName === 'currency' ?
                                    Object.keys(currencies).map((currency, index) => (
                                      <option key={index + setting.formName} value={currency}>{currencies[currency].name + ' - ' + currencies[currency].symbol}</option>
                                    ))
                                    :
                                    setting.formName === 'backdate_in_months' ?
                                      Array(13).fill("1").map((a, index) => (
                                        <option key={index + setting.formName} value={index}>{index + (index > 1 ? ' months' : ' month')}</option>
                                      ))
                                      :
                                      setting.formName === 'system_incentive_fee' || setting.formName === "system_vendor_incentive_fee" ?
                                        Array(21).fill("1").map((a, index) => (
                                          <option key={index + setting.formName} value={index * 5}>{(index * 5) + '%'}</option>
                                        ))
                                        :
                                        setting.formName === 'statement_dates' ?
                                          Array(31).fill("1").map((a, index) => (
                                            <option key={index + setting.formName} value={index + 1}>{(index + 1)}</option>
                                          ))
                                          :
                                          setting.formName === 'reward_type' ?
                                            ['Cash', 'Prizes', 'Both'].map((type, index) => (
                                              <option key={index + setting.formName} value={type.toLowerCase()}>{type}</option>
                                            ))

                                            :

                                            null
                                  }


                                </Field>
                                :
                                setting.inputType === 'text'
                                  ?
                                  <Field
                                    id={setting.formName}
                                    name={setting.formName}
                                    component='input'
                                    type='text'
                                    style={{ width: '200px', height: '39px', border: '1px solid #707070', color: '#000000', fontFamily: 'NunitoSans', fontSize: '14px', textAlign: 'center' }}

                                  />
                                  :
                                  setting.formName === 'company_incentive_fee' ?
                                    <NunitoSans
                                      fontSize='14px'
                                      color='#000000'
                                      className="settingsLink"
                                      style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                                      onClick={() => {
                                        const { companyResults, change, formValues, initialize } = this.props;
                                        this.setState({ customModalOpen: true, customModalType: 'incentive_fee' });
                                        let payload = { ...formValues };
                                        // companyResults.forEach(company=>change(`fee_${company.id}`, '10'))
                                        companyResults.forEach(company => payload[`fee_${company.id}`] = company.incentive_fee || formValues.system_incentive_fee);
                                        initialize(payload);
                                        // this._search('');
                                      }
                                      }
                                    >
                                      Set custom fees for each company
                                    </NunitoSans>
                                    :
                                    null
                      }



                    </Flex>



                  </Flex>
                  <Flex
                    justifyContent="flex-end"
                  >
                    {setting.formName === 'statement_dates' ?
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                        className="settingsLink"
                        style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                        onClick={() => this.setState({ customDatesOpen: !customDatesOpen })}
                      >
                        {customDatesOpen ? 'CLOSE' : 'Set custom dates for each month'}
                      </NunitoSans>
                      :
                      null
                    }

                  </Flex>
                  {setting.formName === "statement_dates" && customDatesOpen ?
                    <Flex
                      flexDirection="column"
                      // alignItems="center"
                      style={{ marginBottom: '13px' }}
                    >
                      <Flex
                        justifyContent="space-evenly"
                      >
                        {['january', 'february', 'march', 'april', 'may', 'june'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={month}
                              name={month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === index }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'february' ? 28 : month === 'april' || month === 'june' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={index + month} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex
                        justifyContent="space-evenly"
                        style={{ marginTop: '5px' }}
                      >
                        {['july', 'august', 'september', 'october', 'november', 'december'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={month}
                              name={month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === (index + 6) }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'september' || month === 'november' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={month + index} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                    :
                    null
                  }
                  {
                    (setting.formName === "sales_incentives_config" && salesConfigExpanded) || (setting.formName === "vendor_incentives_config" && vendorConfigExpanded)
                      ?
                      <Flex
                        flexDirection="column"
                        style={{ paddingLeft: '25px' }}
                      >
                        {
                          INCENTIVE_TYPES.map((type, index, arr) => (
                            <Flex
                              flexDirection="column"
                            >
                              <Flex
                                alignItems='center'
                                justifyContent='space-between'
                                style={{ borderTop: '1px solid #D3D3D3' }}
                              >
                                <Flex
                                  flexDirection="column"
                                  style={{ marginTop: '13px', marginBottom: '13px' }}
                                >
                                  <NunitoSans
                                    fontSize='12px'
                                    fontWeight='bold'
                                    color='#10213A'
                                  >
                                    {(type === 'onetoone' ? 'one to one' : type).toUpperCase()}
                                  </NunitoSans>
                                  {/* <NunitoSans
                                fontSize='12px'
                                color='#000000'
                                >
                                  {setting.description}
                                </NunitoSans> */}

                                </Flex>
                                <Field
                                  id={`${setting.formName}~${type}~isActive`}
                                  name={`${setting.formName}~${type}~isActive`}
                                  type="checkmark"
                                  component={SwitchInput}
                                  onColor='#428BF4'
                                  offColor='#D3D3D3'
                                  height={28}
                                  width={56}
                                />

                              </Flex>
                              {
                                formValues[`${setting.formName}~${type}~isActive`] && Object.keys(systemSettings[setting.formName][type]).filter(key => key !== 'isActive').map(rule => (
                                  <div style={{ paddingLeft: '15px' }}>
                                    <Flex
                                      alignItems='center'
                                      justifyContent='space-between'
                                      style={{ borderTop: '1px solid #D3D3D3' }}
                                    >
                                      <Flex
                                        flexDirection="column"
                                        style={{ marginTop: '13px', marginBottom: '13px' }}
                                      >
                                        <NunitoSans
                                          fontSize='10px'
                                          fontWeight='bold'
                                          color='#428BF4'
                                        >
                                          {RULE_NAMES(setting.formName)[rule].toUpperCase()}
                                        </NunitoSans>
                                        {/* <NunitoSans
                                    fontSize='12px'
                                    color='#000000'
                                    >
                                      {setting.description}
                                    </NunitoSans> */}

                                      </Flex>
                                      <Field
                                        id={`${setting.formName}~${type}~${rule}`}
                                        name={`${setting.formName}~${type}~${rule}`}
                                        type="checkmark"
                                        component={SwitchInput}
                                        onColor='#2FD371'
                                        offColor='#D3D3D3'
                                        height={24}
                                        width={48}
                                      />

                                    </Flex>
                                  </div>
                                ))
                              }
                            </Flex>

                          ))
                        }

                      </Flex>

                      :
                      null
                  }

                </Flex>
              )
            }

            )}

          {
            formValues.rewards_active
              ?
              <Flex
                style={{ borderBottom: '5px solid #D3D3D3', marginTop: '47px' }}
              >
                <NunitoSans
                  fontSize="16px"
                  color="#6C7168"
                  fontWeight="bolder"
                >
                  PARTNER INCENTIVES
                </NunitoSans>
              </Flex>
              :
              null
          }
          {
            formValues.rewards_active && PARTNER_INCENTIVES.map((setting, index) => {
              if (index > 0 && (!formValues || !formValues.rewards_active)) return null;
              if (!formValues.vendor_incentives_active && setting.formName !== 'vendor_incentives_active') return null;
              if ((!formValues || !formValues.incentive_fee_active) && (setting.formName === 'system_incentive_fee' || setting.formName === 'company_incentive_fee')) return null;
              if ((!formValues || !formValues.vendor_incentive_fee_active) && (setting.formName === 'system_vendor_incentive_fee' || setting.formName === 'company_vendor_incentive_fee')) return null;
              if (!formValues.sales_incentives_active && setting.formName === 'sales_companies_active') return null;
              if (!formValues.vendor_incentives_active && setting.formName === 'vendor_companies_active') return null;

              if (setting.formName === 'incentive_approval_coworkers' && !formValues.incentive_approval) return null;
              if ((setting.formName === 'part_number_active' || setting.formName === 'part_number_name') && !formValues.product_active) return null;
              if (setting.formName === "part_number_name" && !formValues.part_number_active) return null;
              return (
                <Flex
                  flexDirection="column"
                  style={{ borderBottom: '1px solid #D3D3D3', marginBottom: index === REWARDS.length - 1 ? '50px' : '0px' }}
                >
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                  // style={{borderBottom:'1px solid #D3D3D3', marginBottom: index===REWARDS.length-1 ? '50px' : '0px'}}
                  >
                    <Flex
                      flexDirection="column"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      <NunitoSans
                        fontSize='14px'
                        fontWeight='bold'
                        color='#10213A'
                      >
                        {setting.name.toUpperCase()}
                      </NunitoSans>
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                      >
                        {setting.description}
                      </NunitoSans>

                    </Flex>

                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                    >
                      {
                        setting.inputType === 'icon' && (setting.formName === 'sales_incentives_config' || setting.formName === 'vendor_incentives_config')
                          ?

                          <FontAwesomeIcon
                            icon={
                              (setting.formName === 'sales_incentives_config' && salesConfigExpanded) || (setting.formName === 'vendor_incentives_config' && vendorConfigExpanded)
                                ?
                                faCaretDown
                                :
                                faCaretRight
                            }
                            color="#428BF4"
                            size="3x"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (setting.formName === 'sales_incentives_config') {
                                this.setState({ salesConfigExpanded: !salesConfigExpanded })
                              } else {
                                this.setState({ vendorConfigExpanded: !vendorConfigExpanded })
                              }
                            }}
                          />
                          :
                          setting.inputType === 'button' && (setting.formName === 'sales_companies_active' || setting.formName === 'vendor_companies_active')
                            ?
                            <Flex
                              justifyContent="space-between"
                            >
                              {
                                systemSettings[setting.formName]
                                  ?
                                  <DashboardSmallCTA
                                    mr="10px"
                                    minWidth={["122px"]}
                                    fontSize="14px"
                                    innerHeight="30px"
                                    width="150px"
                                    onClick={() => this._setIncentiveCompanies(setting.formName)}
                                    color="#428BF4"
                                  >
                                    EDIT
                                  </DashboardSmallCTA>
                                  :
                                  null
                              }

                              <DashboardSmallCTA
                                minWidth={["122px"]}
                                fontSize="14px"
                                innerHeight="30px"
                                width="150px"
                                onClick={() => systemSettings[setting.formName] ? this._handleIncentiveCompaniesDeactivation(setting.formName) : this._handleIncentiveCompaniesActivation(setting.formName)}
                                color="#428BF4"
                              >
                                {systemSettings[setting.formName] ? 'DEACTIVATE' : 'ACTIVATE'}
                              </DashboardSmallCTA>
                            </Flex>
                            :
                            setting.inputType === 'switch' ?
                              <Field
                                id={setting.formName}
                                name={setting.formName}
                                type="checkmark"
                                component={SwitchInput}
                                onColor='#428BF4'
                                offColor='#D3D3D3'
                              />
                              :
                              setting.inputType === 'select' ?
                                <Field
                                  id={setting.formName}
                                  name={setting.formName}
                                  onChange={field => setting.formName === 'statement_dates' ? this._resetStatementDates(parseInt(field.target.value)) : null}
                                  component="select"
                                  style={{ border: '1px solid #707070', width: '100px', height: '39px', fontSize: '14px', fontFamily: "NunitoSans", color: "#000000", paddingLeft: '20px' }}
                                >
                                  {setting.formName === 'currency' ?
                                    Object.keys(currencies).map((currency, index) => (
                                      <option key={index + setting.formName} value={currency}>{currencies[currency].name + ' - ' + currencies[currency].symbol}</option>
                                    ))
                                    :
                                    setting.formName === 'backdate_in_months' ?
                                      Array(13).fill("1").map((a, index) => (
                                        <option key={index + setting.formName} value={index}>{index + (index > 1 ? ' months' : ' month')}</option>
                                      ))
                                      :
                                      setting.formName === 'system_incentive_fee' || setting.formName === "system_vendor_incentive_fee" ?
                                        Array(21).fill("1").map((a, index) => (
                                          <option key={index + setting.formName} value={index * 5}>{(index * 5) + '%'}</option>
                                        ))
                                        :
                                        setting.formName === 'statement_dates' ?
                                          Array(31).fill("1").map((a, index) => (
                                            <option key={index + setting.formName} value={index + 1}>{(index + 1)}</option>
                                          ))
                                          :
                                          setting.formName === 'reward_type' ?
                                            ['Cash', 'Prizes', 'Both'].map((type, index) => (
                                              <option key={index + setting.formName} value={type.toLowerCase()}>{type}</option>
                                            ))

                                            :

                                            null
                                  }


                                </Field>
                                :
                                setting.inputType === 'text'
                                  ?
                                  <Field
                                    id={setting.formName}
                                    name={setting.formName}
                                    component='input'
                                    type='text'
                                    style={{ width: '200px', height: '39px', border: '1px solid #707070', color: '#000000', fontFamily: 'NunitoSans', fontSize: '14px', textAlign: 'center' }}

                                  />
                                  :
                                  setting.formName === 'company_vendor_incentive_fee' ?
                                    <NunitoSans
                                      fontSize='14px'
                                      color='#000000'
                                      className="settingsLink"
                                      style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                                      onClick={() => {
                                        const { companyResults, change, formValues, initialize } = this.props;
                                        this.setState({ customModalOpen: true, customModalType: 'vendor_incentive_fee' });
                                        let payload = { ...formValues };
                                        // companyResults.forEach(company=>change(`fee_${company.id}`, '10'))
                                        companyResults.forEach(company => payload[`vendor_fee_${company.id}`] = company.vendor_incentive_fee || formValues.system_vendor_incentive_fee);
                                        initialize(payload);
                                        // this._search('');
                                      }
                                      }
                                    >
                                      Set custom fees for each company
                                    </NunitoSans>
                                    :
                                    null
                      }



                    </Flex>



                  </Flex>
                  <Flex
                    justifyContent="flex-end"
                  >
                    {setting.formName === 'statement_dates' ?
                      <NunitoSans
                        fontSize='14px'
                        color='#000000'
                        className="settingsLink"
                        style={{ textDecoration: 'underline', marginTop: '12px', cursor: 'pointer' }}
                        onClick={() => this.setState({ customDatesOpen: !customDatesOpen })}
                      >
                        {customDatesOpen ? 'CLOSE' : 'Set custom dates for each month'}
                      </NunitoSans>
                      :
                      null
                    }

                  </Flex>
                  {setting.formName === "statement_dates" && customDatesOpen ?
                    <Flex
                      flexDirection="column"
                      // alignItems="center"
                      style={{ marginBottom: '13px' }}
                    >
                      <Flex
                        justifyContent="space-evenly"
                      >
                        {['january', 'february', 'march', 'april', 'may', 'june'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={month}
                              name={month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === index }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'february' ? 28 : month === 'april' || month === 'june' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={index + month} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex
                        justifyContent="space-evenly"
                        style={{ marginTop: '5px' }}
                      >
                        {['july', 'august', 'september', 'october', 'november', 'december'].map((month, index) => (
                          <Flex>
                            <span
                              // fontSize='12px'
                              style={{ fontFamily: 'monospace', fontSize: '12px' }}
                            >
                              {month.toUpperCase().slice(0, 3)}
                            </span>
                            <Field
                              id={month}
                              name={month}
                              component='select'
                              props={{ disabled: DATE.getMonth() === (index + 6) }}
                              style={{ border: '1px solid #707070', marginLeft: '5px' }}
                            >
                              {Array(month === 'september' || month === 'november' ? 30 : 31).fill('1').map((a, index) => (
                                <option key={month + index} value={index + 1}>{index + 1}</option>
                              ))}
                            </Field>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                    :
                    null
                  }
                  {
                    (setting.formName === "sales_incentives_config" && salesConfigExpanded) || (setting.formName === "vendor_incentives_config" && vendorConfigExpanded)
                      ?
                      <Flex
                        flexDirection="column"
                        style={{ paddingLeft: '25px' }}
                      >
                        {
                          INCENTIVE_TYPES.map((type, index, arr) => (
                            <Flex
                              flexDirection="column"
                            >
                              <Flex
                                alignItems='center'
                                justifyContent='space-between'
                                style={{ borderTop: '1px solid #D3D3D3' }}
                              >
                                <Flex
                                  flexDirection="column"
                                  style={{ marginTop: '13px', marginBottom: '13px' }}
                                >
                                  <NunitoSans
                                    fontSize='12px'
                                    fontWeight='bold'
                                    color='#10213A'
                                  >
                                    {(type === 'onetoone' ? 'one to one' : type).toUpperCase()}
                                  </NunitoSans>
                                  {/* <NunitoSans
                                fontSize='12px'
                                color='#000000'
                                >
                                  {setting.description}
                                </NunitoSans> */}

                                </Flex>
                                <Field
                                  id={`${setting.formName}~${type}~isActive`}
                                  name={`${setting.formName}~${type}~isActive`}
                                  type="checkmark"
                                  component={SwitchInput}
                                  onColor='#428BF4'
                                  offColor='#D3D3D3'
                                  height={28}
                                  width={56}
                                />

                              </Flex>
                              {
                                formValues[`${setting.formName}~${type}~isActive`] && Object.keys(systemSettings[setting.formName][type]).filter(key => key !== 'isActive').map(rule => (
                                  <div style={{ paddingLeft: '15px' }}>
                                    <Flex
                                      alignItems='center'
                                      justifyContent='space-between'
                                      style={{ borderTop: '1px solid #D3D3D3' }}
                                    >
                                      <Flex
                                        flexDirection="column"
                                        style={{ marginTop: '13px', marginBottom: '13px' }}
                                      >
                                        <NunitoSans
                                          fontSize='10px'
                                          fontWeight='bold'
                                          color='#428BF4'
                                        >
                                          {RULE_NAMES(setting.formName)[rule].toUpperCase()}
                                        </NunitoSans>
                                        {/* <NunitoSans
                                    fontSize='12px'
                                    color='#000000'
                                    >
                                      {setting.description}
                                    </NunitoSans> */}

                                      </Flex>
                                      <Field
                                        id={`${setting.formName}~${type}~${rule}`}
                                        name={`${setting.formName}~${type}~${rule}`}
                                        type="checkmark"
                                        component={SwitchInput}
                                        onColor='#2FD371'
                                        offColor='#D3D3D3'
                                        height={24}
                                        width={48}
                                      />

                                    </Flex>
                                  </div>
                                ))
                              }
                            </Flex>

                          ))
                        }

                      </Flex>

                      :
                      null
                  }

                </Flex>
              )
            }

            )}

          <Box height="40px" />
          <Modal
            isOpen={customModalOpen && customModalType}
            onRequestClose={() => this.setState({ customModalOpen: false, customModalType: null })}
            style={customStyles}
            contentLabel={`${customModalType} Modal`}
            ariaHideApp={false}
          >
            <Flex
              flexDirection="column"
              style={{ width: "100%", height: '100%' }}
            >
              <Flex
                position="relative"
                justifyContent="center"
                style={{ marginBottom: '25px' }}
              >
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bold"
                  color="#10213A"
                >
                  {
                    customModalType === 'incentive_fee' || customModalType === 'vendor_incentive_fee'
                      ?
                      "SET CUSTOM RATES FOR COMPANIES"
                      :
                      customModalType === 'posting_active'
                        ?
                        "SET POSTING COMPANIES"
                        :
                          customModalType === 'sales_incentive_active'
                            ?
                            "SET SALES INCENTIVE COMPANIES"
                            :
                            customModalType === 'vendor_incentive_active'
                              ?
                              "SET PARTNER INCENTIVE COMPANIES"
                              :
                              customModalType === 'post_multi_approval'
                                ?
                                "POST MULTI TIER APPROVAL"
                                :
                                customModalType === 'incentive_multi_approval'
                                  ?
                                  "INCENTIVE MULTI TIER APPROVAL"
                                  :
                                  null
                  }

                </NunitoSans>
                <AbsoluteDiv
                  display={["block"]}
                  right={["20px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["17px"]}
                >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={() => this.setState({ customModalOpen: false, customModalType: null })}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                    <IconBox
                      cursor="pointer"
                      pl={["15px"]}
                      pb={["15px"]}
                      pt={["15px"]}
                      fontSize={["20px"]}
                      color="#c3c3c3"
                    >
                      thinx
                    </IconBox>
                  </ClickCursorFlex>
                </AbsoluteDiv>
              </Flex>
              <Flex
                position="relative"
                width={["100%"]}
                style={{ marginBottom: '20px' }}
              >
                <InputStyled
                  placeholder="Search Companies"
                  onChange={e => this._updateSearch(e.target.value)}
                  value={search || ""}
                  innerRef={input => (this.searchInput = input)}
                  width="100%"
                  name="dashboard-search"
                  type="text"
                  border={0}
                  autoFocus={true}
                  bg={["lightGray"]}
                  borderRadius={"6px"}
                  pt={["3px"]}
                  px={["5px"]}
                  mt={["0px"]}
                  height={["40px"]}
                  fontWeight={400}
                  fontSize={[24]}
                  lineHeight={1.5}
                  color={"#979797"}
                  borderBottom={[0]}
                />
                <AbsoluteDiv
                  display={["block"]}
                  right={["20px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["17px"]}
                >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={this._clearSearch}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                    <IconBox
                      cursor="pointer"
                      pl={["15px"]}
                      pb={["15px"]}
                      pt={["15px"]}
                      fontSize={["17px"]}
                      color="#c3c3c3"
                    >
                      thinx
                    </IconBox>
                  </ClickCursorFlex>
                </AbsoluteDiv>


              </Flex>
              <Flex
                flexDirection="column"
                style={{ overflow: "auto" }}
              >
                {
                  companyResults && companyResults.map((company, index) => (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      className="settingsCompanyResults"
                      style={{ paddingBottom: "10px", paddingTop: '10px', borderBottom: index + 1 === companyResults.length ? 'none' : '1px dashed #D3D3D3' }}
                    >
                      <Flex
                        alignItems="center"
                      >
                        <ImageTreatment
                          img={company.logo || defaultCompany(company.id, company.name)}
                          blurRadius={"0px"}
                          imageInset={0}
                          borderRadius={4}
                          innerBorderRadius={0}
                          shadowOffset={0}
                          padding={4}
                          height={36}
                          width={36}
                        />

                        <Flex
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="flex-start"
                          style={{ marginLeft: "20px" }}
                        >
                          <NunitoSans
                            fontSize="14px"
                            fontWeight={300}
                            color="darkText"
                            pt={"3px"}
                            lineHeight={1}
                          >
                            {company.name}
                          </NunitoSans>
                          <NunitoSans
                            fontSize="8px"
                            fontWeight={500}
                            color="darkText"
                          >
                            {company.total_employee_count > 1 ? `${company.total_employee_count} PEOPLE` : `${company.total_employee_count} PERSON`}
                          </NunitoSans>
                        </Flex>

                      </Flex>
                      {
                        customModalType === 'incentive_fee'
                          ?
                          <Field
                            id={"fee_" + company.id}
                            name={"fee_" + company.id}
                            component="select"
                            style={{ border: '1px solid #428BF4', borderRadius: '8px', width: "50px", height: "25px", fontFamily: "NunitoSans", fontSize: "12px", marginRight: '10px' }}
                          >
                            {Array(21).fill('1').map((a, index) => (
                              <option key={index + "fee"} value={index * 5} > {(index * 5) + "%"} </option>
                            ))}
                          </Field>
                          :
                          customModalType === 'vendor_incentive_fee'
                            ?
                            <Field
                              id={"vendor_fee_" + company.id}
                              name={"vendor_fee_" + company.id}
                              component="select"
                              style={{ border: '1px solid #428BF4', borderRadius: '8px', width: "50px", height: "25px", fontFamily: "NunitoSans", fontSize: "12px", marginRight: '10px' }}
                            >
                              {Array(21).fill('1').map((a, index) => (
                                <option key={index + "fee"} value={index * 5} > {(index * 5) + "%"} </option>
                              ))}
                            </Field>
                            :
                            customModalType === 'posting_active' || customModalType === 'sales_incentive_active' || customModalType === 'vendor_incentive_active'
                              ?
                              <div style={{ marginRight: '5px' }}>
                                <Field
                                  id={`${customModalType}_${company.id}`}
                                  name={`${customModalType}_${company.id}`}
                                  type="checkmark"
                                  component={SwitchInput}
                                  onColor='#2FD371'
                                  offColor='#D3D3D3'
                                  height={24}
                                  width={48}
                                  onChange={() => {
                                    const { formValues } = this.props;
                                    axios.patch(`${process.env.REACT_APP_API_URL}/companies/${company.id}`, { [customModalType]: !formValues[`${customModalType}_${company.id}`] });

                                  }}
                                />
                              </div>

                              :
                              customModalType === 'post_multi_approval' || customModalType === 'incentive_multi_approval'
                                ?
                                <DashboardSmallCTA
                                  // width="15%"
                                  mr="10px"
                                  minWidth={["90px"]}
                                  fontSize="14px"
                                  innerHeight="30px"
                                  // width="130px"
                                  onClick={() => this._initializeApprovalCompany(company)}
                                  color="#428BF4"
                                >
                                  {company[customModalType] && company[customModalType].approvers && company[customModalType].approvers.length ? 'EDIT' : 'SET'}
                                </DashboardSmallCTA>
                                :
                                null
                      }

                    </Flex>
                  ))

                }

              </Flex>
            </Flex>
          </Modal>

          <ConfirmModal
            modalOpen={!!multiTierWarningModalType}
            handleCloseRequest={() => this.setState({ multiTierWarningModalType: null })}
            handleConfirm={() => {
              if (multiTierWarningModalType === "unsaved") {
                //SAVE button
                this._saveMultiApprovers();
              } else {
                // CONTINUE button
                this.setState({ multiTierWarningModalType: null })

              }
            }}
            customCancelHandle={() => this.setState({ multiTierSelectedCompany: null, selectedApprovers: [], searchMultiTier: '' })}
            message={multiTierWarningModalType === "unsaved" ? "There are unsaved changes." : "A minimum number of selected approvers has not been met."}
            message2={multiTierWarningModalType === "unsaved" ? "Are you sure you want to leave without saving?" : "Please continue and complete the selections or your changes will not be saved if you leave."}
            cancelButtonText="LEAVE"
            confirmButtonText={multiTierWarningModalType === "unsaved" ? "SAVE" : "CONTINUE"}
          />

          <Modal
            isOpen={multiTierSelectedCompany && customModalType}
            //  onRequestClose={()=>this.setState({multiTierSelectedCompany: null, selectedApprovers: [], searchMultiTier: ''})}
            onRequestClose={() => this._handleMultiTierWarningModalClose()}
            style={customMultiTierStyles}
            contentLabel={`${customModalType} Multi Tier Modal`}
            ariaHideApp={false}
          >
            <Flex
              flexDirection="column"
              style={{ width: "100%", height: '100%' }}
            >
              <Flex
                position="relative"
                justifyContent="center"
                style={{ marginBottom: '25px' }}
              >
                <AbsoluteDiv
                  display={["block"]}
                  left={["0px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["100px"]}
                >
                  <DashboardSmallCTA
                    fontSize="12px"
                    onClick={() => this._saveMultiApprovers()}
                  >
                    SAVE
                  </DashboardSmallCTA>
                </AbsoluteDiv>
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bold"
                  color="#10213A"
                >
                  {

                    customModalType === 'post_multi_approval'
                      ?
                      "POST APPROVERS"
                      :
                      customModalType === 'incentive_multi_approval'
                        ?
                        "INCENTIVE MULTI TIER APPROVAL"
                        :
                        null
                  }

                </NunitoSans>
                <AbsoluteDiv
                  display={["block"]}
                  right={["20px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["17px"]}
                >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={() => this._handleMultiTierWarningModalClose()}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                    <IconBox
                      cursor="pointer"
                      pl={["15px"]}
                      pb={["15px"]}
                      pt={["15px"]}
                      fontSize={["20px"]}
                      color="#c3c3c3"
                    >
                      thinx
                    </IconBox>
                  </ClickCursorFlex>
                </AbsoluteDiv>
              </Flex>


              <div style={{ overflow: 'auto', paddingRight: '10px' }} >
                {
                  multiTierSelectedCompany
                    ?
                    <>
                      <Flex
                        flexDirection="column"
                        // justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        mb="20px"
                      >
                        <ImageTreatment
                          img={multiTierSelectedCompany.logo || defaultCompany(multiTierSelectedCompany.id, multiTierSelectedCompany.name)}
                          blurRadius={"15px"}
                          imageInset={0}
                          borderRadius={10}
                          innerBorderRadius={0}
                          shadowOffset={0.1}
                          padding={10}
                          height={75}
                          width={75}
                        />
                        <NunitoSans
                          fontSize="22px"
                          fontWeight="bold"
                          color="#000000"
                          mt='10px'
                        >
                          {multiTierSelectedCompany.name}
                        </NunitoSans>

                      </Flex>
                      <Flex

                        justifyContent="space-evenly"
                        alignItems="center"
                        width="100%"
                        mb="20px"
                      >
                        <NunitoSans
                          fontSize="14px"
                          fontWeight="bold"
                          color="#000000"
                        >

                          {`How many managers should approve ${customModalType === 'post_multi_approval' ? 'a post' : 'an incentive'}?`}
                        </NunitoSans>

                        <Field
                          id={`${multiTierSelectedCompany.id}~${customModalType}~number_of_approvers`}
                          name={`${multiTierSelectedCompany.id}~${customModalType}~number_of_approvers`}
                          component="select"
                          style={{ border: '1px solid #428BF4', borderRadius: '8px', width: "70px", height: "30px", fontFamily: "NunitoSans", fontSize: "14px", marginRight: '10px' }}
                        >
                          {Array(systemManagers.length).fill('1').map((a, index) => (
                            <option key={index + "multi_tier" + customModalType} value={index + 1} > {index + 1} </option>
                          ))}
                        </Field>

                      </Flex>
                    </>
                    :
                    null
                }

                {
                  multiTierSelectedCompany && multiTierSelectedCompany.id
                    ?
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      style={{ borderBottom: '6px solid #D3D3D3' }}
                    >

                      <NunitoSans
                        fontSize="16px"
                        fontWeight="bolder"
                        color={selectedApprovers.length >= parseInt(formValues[multiTierSelectedCompany.id + "~" + customModalType + "~number_of_approvers"] || 1) ? "#428BF4" : "#FE4A49"}
                      >
                        {`SELECT APPROVERS ${selectedApprovers.length}/${formValues[multiTierSelectedCompany.id + "~" + customModalType + "~number_of_approvers"] || 1}`}
                      </NunitoSans>

                      {
                        selectedApprovers.length
                          ?
                          <NunitoSans
                            fontSize="14px"
                            fontWeight="bold"
                            color="#FE4A49"
                            style={{ textDecoration: 'underline', cursor: "pointer" }}
                            onClick={() => this.setState({ selectedApprovers: [] })}
                          >
                            CLEAR ALL
                          </NunitoSans>
                          :
                          null
                      }

                    </Flex>
                    :
                    null
                }


                {
                  selectedApprovers && selectedApprovers.length
                    ?
                    <Flex
                      flexDirection="column"
                      alignItems='center'
                      justifyContent='center'
                      width="100%"
                      mb="15px"
                    >
                      {
                        selectedApprovers.map((approver, index) => (
                          <Flex
                            alignItems='center'
                            justifyContent='space-between'
                            width="100%"
                            style={{ borderTop: index !== 0 ? '1px dashed #D3D3D3' : 'none', paddingTop: '5px', paddingBottom: '5px' }}
                          >
                            <Flex
                              alignItems='center'
                              justifyContent='space-between'
                            >
                              <ImageTreatment
                                img={approver.avatar || defaultProfile(approver.id, `${approver.first_name} ${approver.last_name}`)}
                                blurRadius={"0px"}
                                imageInset={0}
                                borderRadius={28}
                                innerBorderRadius={25}
                                shadowOffset={0}
                                padding={4}
                                height={50}
                                width={50}
                              />
                              <NunitoSansSemiBold
                                fontSize="18px"
                                color="#000000"
                                style={{ marginLeft: "20px" }}
                              >
                                {approver.first_name + " " + approver.last_name}
                              </NunitoSansSemiBold>
                            </Flex>
                            <Flex
                              alignItems='center'
                              justifyContent='space-between'
                            >
                              <Flex
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <NunitoSansBold
                                  fontSize="10px"
                                  color={formValues[`${approver.id}~${multiTierSelectedCompany.id}~${customModalType}~passive_approver`] ? "#428BF4" : "#A3A99E"}
                                  style={{ marginRight: '13px' }}
                                >
                                  Passive Approver
                                </NunitoSansBold>
                                <Field
                                  id={`${approver.id}~${multiTierSelectedCompany.id}~${customModalType}~passive_approver`}
                                  name={`${approver.id}~${multiTierSelectedCompany.id}~${customModalType}~passive_approver`}
                                  component={SwitchInput}
                                  onColor="#428BF4"
                                  height={20}
                                  width={40}
                                />

                              </Flex>

                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                size="lg"
                                color="#FE4A49"
                                style={{ cursor: 'pointer', marginLeft: '30px' }}
                                onClick={() => this._handleManagerRemove(index)}
                              />
                            </Flex>
                          </Flex>
                        ))
                      }

                    </Flex>
                    :
                    <NunitoSans
                      fontSize="18px"
                      fontWeight="bold"
                      fontStyle="italic"
                      color="#676767"
                      textAlign="center"
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      No Managers Selected
                    </NunitoSans>
                }

                {
                  multiTierSelectedCompany && multiTierSelectedCompany.id && selectedApprovers.length < parseInt(formValues[multiTierSelectedCompany.id + "~" + customModalType + "~number_of_approvers"] || 1)

                    ?

                    <NunitoSansSemiBold
                      fontSize="14px"
                      fontStyle="italic"
                      color="#FE4A49"
                      textAlign="center"
                    >
                      {`Please select at least (${parseInt(formValues[multiTierSelectedCompany.id + "~" + customModalType + "~number_of_approvers"] || 1) - selectedApprovers.length}) more manager(s)`}
                    </NunitoSansSemiBold>

                    :
                    null

                }

                <Flex
                  width="100%"
                  style={{ borderBottom: "6px solid #D3D3D3", marginTop: "30px", marginBottom: "25px" }}
                >
                  <NunitoSans
                    fontSize="16px"
                    fontWeight={800}
                    color="#6C7168"
                  >
                    MANAGERS
                  </NunitoSans>

                </Flex>


                <Flex
                  position="relative"
                  width={["100%"]}
                  style={{ marginBottom: '20px' }}
                >
                  <InputStyled
                    placeholder="Search Managers"
                    onChange={e => this.setState({ searchMultiTier: e.target.value })}
                    value={searchMultiTier || ""}
                    innerRef={input => (this.searchInput = input)}
                    width="100%"
                    name="multi-tier-search"
                    type="text"
                    border={0}
                    autoFocus={true}
                    bg={["lightGray"]}
                    borderRadius={"6px"}
                    pt={["3px"]}
                    px={["5px"]}
                    mt={["0px"]}
                    height={["40px"]}
                    fontWeight={400}
                    fontSize={[24]}
                    lineHeight={1.5}
                    color={"#979797"}
                    borderBottom={[0]}
                  />
                  <AbsoluteDiv
                    display={["block"]}
                    right={["20px"]}
                    bottom={"0px"}
                    top={["0px"]}
                    width={["17px"]}
                  >
                    <ClickCursorFlex
                      height={["100%", "100%", "100%"]}
                      alignItems={["center"]}
                      onClick={() => this.setState({ searchMultiTier: '' })}
                      justifyContent={[
                        "flex-start",
                        "flex-start",
                        "flex-start",
                        "center"
                      ]}
                    >
                      <IconBox
                        cursor="pointer"
                        pl={["15px"]}
                        pb={["15px"]}
                        pt={["15px"]}
                        fontSize={["17px"]}
                        color="#c3c3c3"
                      >
                        thinx
                      </IconBox>
                    </ClickCursorFlex>
                  </AbsoluteDiv>


                </Flex>

                {
                  filteredManagers.length
                    ?
                    <Flex
                      flexWrap="wrap"
                      justifyContent="space-between"

                    >

                      {
                        filteredManagers.map((manager, index) => (
                          <Flex
                            alignItems='center'
                            justifyContent='space-between'
                            width="100%"
                            style={{ borderTop: index !== 0 ? '1px dashed #D3D3D3' : 'none', paddingTop: '5px', paddingBottom: '5px' }}
                          >
                            <Flex
                              alignItems='center'
                              justifyContent='space-between'
                            >
                              <ImageTreatment
                                img={manager.avatar || defaultProfile(manager.id, `${manager.first_name} ${manager.last_name}`)}
                                blurRadius={"0px"}
                                imageInset={0}
                                borderRadius={28}
                                innerBorderRadius={25}
                                shadowOffset={0}
                                padding={4}
                                height={50}
                                width={50}
                              />
                              <NunitoSansSemiBold
                                fontSize="18px"
                                color="#000000"
                                style={{ marginLeft: "20px" }}
                              >
                                {manager.first_name + " " + manager.last_name}
                              </NunitoSansSemiBold>
                            </Flex>

                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              size="lg"
                              color="#2FD371"
                              style={{ cursor: 'pointer' }}
                              onClick={() => this._handleApproverSelection(manager)}
                            />

                          </Flex>
                        ))
                      }
                    </Flex>
                    :
                    <NunitoSans
                      fontSize="16px"
                      color="#676767"
                      fontWeight="bold"
                      fontStyle="italic"
                      textAlign="center"
                      style={{ marginTop: '10px' }}
                    >
                      No managers to select.
                    </NunitoSans>
                }
              </div>
            </Flex>
          </Modal>


        </form>
      </Flex>
    </>
  }
}

ManageSystemSettingsMain = reduxForm({
  // destroyOnUnmount: true,
  form: "system-settings-form",
  // validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ManageSystemSettingsMain);

export default connect(
  state => ({
    browser: state.browser,
    formValues: getFormValues('system-settings-form')(state),
    company: state.resources.detail.company,
    loading: state.resources.detail.loading.post,
    post: state.resources.detail.post,
    sales: state.resources.collection.sale || [],
    rewards: state.resources.collection.reward || [],
    invoices: state.resources.collection.invoice || [],
    systemSettings: state.resources.detail.systemsetting,
    selectedUsers: state.post.selectedUsers,
    companyResults: state.search.companySearchResults,
    //companyResults: state.search.companySearchResults || [],
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    user: state.resources.detail.user,
    connections: state.resources.collection.connection || [],
    incentiveHovered: state.incentives.incentiveHovered,
    incentiveSelected: state.incentives.incentiveSelected,
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createSale: bindActionCreators(actions.createSale, dispatch),
    createSalesupload: bindActionCreators(actions.createSalesupload, dispatch),
    //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    fetchSettings: bindActionCreators(actions.fetchSystemsetting, dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch),
    fetchSalesuploads: bindActionCreators(actions.fetchSalesuploads, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch),
    updateSale: bindActionCreators(actions.patchSale, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    updateSettings: bindActionCreators(actions.patchSystemsetting, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
  })
)(ManageSystemSettingsMain);

// export default ManageSettingsMain;


class ManageSystemSettingsNav extends Component {
  state = {
    verificationModalOpen: false
  }

  _handleVerification = async () => {

    const { verificationFormValues, resetForm } = this.props;

    if (!verificationFormValues || !verificationFormValues.code) return this.props.change('code', '');

    let code = verificationFormValues.code.trim();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/email_verifications`, { email_verification_code: code });

      if (response && response.data.email_verified_at) {
        this._handleSave();
        this.setState({ verificationModalOpen: false })
      }

    } catch (err) {
      return this.props.change('code', '');

    }
  }

  _handleClick = async () => {
    const { formValues, systemSettings } = this.props;

    if (formValues.rewards_active !== systemSettings.rewards_active || formValues.incentive_fee_active !== systemSettings.incentive_fee_active || formValues.reward_splits !== systemSettings.reward_splits) {

      await axios.post(`${process.env.REACT_APP_API_URL}/sessions?system_settings=true`, { email: getUser().email });
      this.setState({ verificationModalOpen: true });


    } else {
      this._handleSave();
    }
  }

  _handleSave = async () => {
    const { formValues, updateSettings, systemSettings, notifySuccess } = this.props;
    const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    let customFees = Object.entries(formValues).filter(pair => pair[0].slice(0, 4) === 'fee_' && pair[1] !== formValues.system_incentive_fee);
    let customVendorFees = Object.entries(formValues).filter(pair => pair[0].slice(0, 11) === 'vendor_fee_' && pair[1] !== formValues.system_vendor_incentive_fee);
    let newStatementDates = {};
    let fundingDates = {};
    let settingsPayload = _.omit(formValues, Object.keys(formValues).filter(key => key.slice(0, 4) === 'fee_' || key.slice(0, 11) === 'vendor_fee_' || key.slice(0, 15) === 'posting_active_' || key.slice(0, 23) === 'sales_incentive_active_' || key.slice(0, 24) === 'vendor_incentive_active_' || key === 'posting_companies_active' || key === 'sales_companies_active' || key === 'vendor_companies_active' || key.includes('number_of_approvers') || key.includes('passive_approver') || key.includes('sales_incentives_config') || key.includes('vendor_incentives_config') || key.includes('funding_dates_') || MONTHS.includes(key)));
    MONTHS.forEach(month => newStatementDates[month] = parseInt(formValues[month]));
    MONTHS.forEach(month => fundingDates[month] = parseInt(formValues["funding_dates_" + month]));
    settingsPayload.statement_dates = newStatementDates;
    settingsPayload.funding_dates = fundingDates;

    let sales_incentives_config = {};
    let vendor_incentives_config = {};

    //set each incentive type for configs

    INCENTIVE_TYPES.forEach(type => {
      sales_incentives_config[type] = {};
      vendor_incentives_config[type] = {};
    })

    // to restructure formValues for system settings payload
    Object.keys(formValues)
      .filter(key => key.includes('sales_incentives_config') || key.includes('vendor_incentives_config'))
      .forEach(key => {
        const [formName, type, rule] = key.split('~');
        console.log(formName, type, rule, 'salesKEYS')
        // [formName][type][rule] = formValues[`${formName}~${type}~${rule}`]
        if (formName === 'sales_incentives_config') {

          sales_incentives_config[type][rule] = formValues[`sales_incentives_config~${type}~${rule}`]
        } else if (formName === 'vendor_incentives_config') {
          vendor_incentives_config[type][rule] = formValues[`vendor_incentives_config~${type}~${rule}`]
        }


      });



    console.log(sales_incentives_config, 'salesConfig', vendor_incentives_config, 'vendorConfig')

    settingsPayload.sales_incentives_config = sales_incentives_config;
    settingsPayload.vendor_incentives_config = vendor_incentives_config;

    if (formValues.logo_primary) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });

      const s3Bucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
      });

      const fileKey = `company-logos/${process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID}${Date.now()}PrimaryLogo`;
      let s3Data = {
        Key: fileKey,
        Body: formValues.logo_primary,
        ContentType: formValues.logo_primary.type,
        ACL: "public-read"
      };

      await s3Bucket.putObject(s3Data, async (err, response) => {

      })
      settingsPayload.logo_primary = `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`
    }

    if (formValues.logo_secondary) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });

      const s3Bucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
      });

      const fileKey = `company-logos/${process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID}${Date.now()}SecondaryLogo`;
      let s3Data = {
        Key: fileKey,
        Body: formValues.logo_secondary,
        ContentType: formValues.logo_secondary.type,
        ACL: "public-read"
      };

      await s3Bucket.putObject(s3Data, async (err, response) => {

      });

      settingsPayload.logo_secondary = `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`

    }


    await updateSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID, settingsPayload);

    if (customFees.length) {
      for (let [key, value] of customFees) {
        await axios.patch(`${process.env.REACT_APP_API_URL}/companies/${key.slice(4)}`, { incentive_fee: parseInt(value) });
      }
    }

    if (customVendorFees.length) {
      for (let [key, value] of customVendorFees) {
        await axios.patch(`${process.env.REACT_APP_API_URL}/companies/${key.slice(11)}`, { vendor_incentive_fee: parseInt(value) });
      }
    }

    return notifySuccess('System Settings updated successfully.')

  }

  render() {
    const { notificationCounts } = this.props;

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "600px",
        height: "300px",
        zIndex: 999998,
        overflow: "auto"
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88"
      }

    };



    return <Flex
      height={"100%"}
      width={"100%"}
      alignItems="flex-end"
      justifyContent="center"
    >
      <Flex
        height={["49px", "49px", "60px", "40px"]}
        width={"100%"}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ position: 'relative' }}>
          {
            getUser().system_admin && getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID
              ?
              <select
                id="system_settings_nav_dropdown"
                style={{ padding: "10px 25px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000" }}
                onChange={e => history.push(`/dashboard/${e.target.value}`)}
              >
                <option
                  style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                  value="systemsettings"
                >
                  {`SYSTEM SETTINGS${notificationCounts.settings ? ' (' + notificationCounts.settings + ')' : ''}`}
                </option>
                <option
                  style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                  value="systemreports"
                >
                  SYSTEM REPORTS
                </option>
                <option
                  style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                  value="settings"
                >
                  ACCOUNT SETTINGS
                </option>

              </select>
              :
              <NunitoSans
                pt={["6px", "6px", "8px", "6px"]}
                fontSize={[14, 14, 18, 18]}
                fontWeight={["bold"]}
              >
                SYSTEM SETTINGS
              </NunitoSans>

          }
          {
            notificationCounts.settings
              ?
              <NavNotificationBubble
                top="-5px"
                right="-5px"
              >
                {notificationCounts.settings || 0}
              </NavNotificationBubble>
              :
              null
          }
        </div>
        <AbsoluteDiv
          left={["0px", "0px", "0px", "0px", "-20%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
          >
            <BackButton />
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv
          right={["0px", "0px", "0px", "0px", "-5%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <DashboardSmallCTA
            onClick={() => this._handleClick()}
            minWidth={["122px"]}
            fontSize="17px"
            innerHeight="40px"
            width="150px"
            color="#428BF4"
          >
            SAVE
          </DashboardSmallCTA>

        </AbsoluteDiv>
      </Flex>
      <Modal
        isOpen={this.state.verificationModalOpen}
        onRequestClose={() => this.setState({ verificationModalOpen: false })}
        style={customStyles}
        contentLabel="Verification Modal"
        ariaHideApp={false}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSans
            fontSize="22px"
            fontWeight="bold"
            style={{ marginTop: '10px', marginBottom: "15px" }}
          >
            Verification Required
          </NunitoSans>
          <NunitoSans
            fontSize="14px"
            style={{ marginBottom: "15px" }}
          >
            {`A verification code has been sent to ${getUser().email}. Please enter it below.`}
          </NunitoSans>
          <form id="settings_verification-form">
            <Field
              // label="CODE"
              name="code"
              component={renderCondensedField}
              key={"verificationtext"}
              type="text"
              mb="0px"
            />



          </form>


          <DashboardSmallCTA
            onClick={() => this._handleVerification()}
            fontSize="15px"
            width="130px"
            color="#428BF4"
          >
            SUBMIT
          </DashboardSmallCTA>

        </Flex>

      </Modal>
    </Flex>
  }
}

const validate = values => {
  const errors = {};

  if (!values.code) {
    errors.code = "CHECK YOUR VERIFICATION CODE";
  }

  return errors;
}


ManageSystemSettingsNav = reduxForm({
  destroyOnUnmount: true,
  form: "settings_verification-form",
  // initialValues: {
  //   rewardsnav: 'sales'
  // },
  validate,
  // enableReinitialize : true
})(ManageSystemSettingsNav);

ManageSystemSettingsNav = connect(
  state => ({
    verificationFormValues: getFormValues('settings_verification-form')(state),
    formValues: getFormValues('system-settings-form')(state),
    systemSettings: state.resources.detail.systemsetting,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
    updateSettings: bindActionCreators(actions.patchSystemsetting, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),

    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
  })
)(ManageSystemSettingsNav);

export { ManageSystemSettingsNav };

