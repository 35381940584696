
export const defaultCompany = (companyId="", name="")=>{

    if(!companyId || !name) return null;

    const initial = name.trim()[0];
    
    return AvatarImage(initial, companyId);
}

export const defaultProfile = (userId="", name="")=>{

    if(!userId || !name) return null;

    const initials = name.split(' ').reduce((a,b)=>a+b[0],"").slice(0,2)
    return AvatarImage(initials, userId);
}



function AvatarImage(letters, id) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext("2d");
    var size = 128;
 
    // Generate a random color every time function is called
    var color =  getBackgroundColor(id);
 
    // Set canvas with & height
    canvas.width = size;
    canvas.height = size;
 
    // Select a font family to support different language characters
    // like Arial
    context.font = `800 ${Math.round(canvas.width / 2)}px NunitoSans, Arial`
    context.textAlign = "center";
 
    // Setup background and front color
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#FFFFFF";
    context.fillText(letters.toUpperCase(), size / 2, size / 1.5);
 
    // Set image representation in default format (png)
    let dataURI = canvas.toDataURL();
 
    // Dispose canvas element
    canvas = null;
 
    return dataURI;
  }



function getBackgroundColor(id){
    const backgroundColors = [
        "#EA6A6A",
        "#F2A261",
        "#FADB95",
        "#59DC8D",
        "#8EB9F8",
        "#A991ED",
        "#F286ED",
    ];

    const uniqueNumber = Math.abs(hashCode(id));
    
    return backgroundColors[uniqueNumber%backgroundColors.length];
    
}




function hashCode (s){
    return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
}