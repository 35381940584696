import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <Flex
            justifyContent="space-evenly"
            width="100%"
            >
                <FontAwesomeIcon
                icon={faUser}
                size="8x"
                color="#428BF4"
                />

                <FontAwesomeIcon
                icon={faUsers}
                size="8x"
                color="#428BF4"
                />
            </Flex>

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    Go it alone or together with your team.
                </span>
                {` You can create a personal network with your industry partners or create a network together with your whole team.`}
            </NunitoSans>

        </Flex>
);