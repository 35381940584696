import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const OriginalLink = props => (
  <RouterLink
    className={`router-link native-link`}
    to={props.to}
    replace={props.replace}
    title={props.title}
    style={{ fontWeight: props.fontWeight ? props.fontWeight : "inherit" }}
  >
    {props.children}
  </RouterLink>
);

export const UnderlineLink = props => (
  <RouterLink
    className={`router-link underline-on-hover`}
    to={props.to}
    replace={props.replace}
    title={props.title}
    style={{ fontWeight: props.fontWeight ? props.fontWeight : "inherit" }}
  >
    {props.children}
  </RouterLink>
);

export const Link = props => {
  return (
    <RouterLink
      className="router-link"
      to={props.to}
      replace={props.replace}
      title={props.title}
    >
      {props.children}
    </RouterLink>
  );
};

export const BlockLink = props => (
  <RouterLink
    className="router-link-block"
    to={props.to}
    replace={props.replace}
    title={props.title}
  >
    {props.children}
  </RouterLink>
);
