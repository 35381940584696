import React, {Component} from "react";
import Modal from "react-modal";
import isEqual from "lodash/isEqual";

import { NunitoSansSemiBold, NunitoSansBold, NunitoSans } from "../../common/typography";
import { Flex, IconBox, Box } from "../../common/flexbox";
import { DashboardSmallCTA, InputStyled } from "../../common/forms";
import { AbsoluteDiv } from "../../common/absolute";

import { getUser } from "../../../utils/auth";
import { ConnectedCompanyList, MappedNameList } from "../../common/reactWindow";
import { ConnectedCompanyRow } from "../../common/rows";

class MapSalesModal extends Component {

    state={
        companies: null,
        filteredCompanies: null,
        mfrNames: null,
        filteredMfrNames: null,
        selectedCompany: null,
        selectedNames: [],
        connectionSearchInput: '',
        nameSearchInput: '',
        saving: false,
        errorMessage: null,
        successMessage: null,
        mapId: null
    }

    componentDidMount(){
        this._fetchConnectedCompanies();
        this._fetchSalesMfrNames();
    }

    _fetchConnectedCompanies = async() => {
        const companies = await fetch(`${process.env.REACT_APP_API_URL}/mnfmappings/${getUser().company_id}`).then(res => res.json());

        this.setState({companies, filteredCompanies: Object.values(companies)});
    }

    _fetchSalesMfrNames = async () => {
        const mfrNames = await fetch(`${process.env.REACT_APP_API_URL}/mnfnames/${getUser().company_id}`).then(res => res.json());

        this.setState({mfrNames, filteredMfrNames: mfrNames});
    }

    _handleSubmit = async () => {
      const { selectedCompany, selectedNames, mfrNames, companies } = this.state;

      this.setState({saving: true});

      const mapping = await fetch(`${process.env.REACT_APP_API_URL}/mnfmappings`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify({
            company_id: getUser().company_id,
            vendor_id: selectedCompany.id,
            mnf_names: JSON.stringify(selectedNames)
        })
      }).then(res=>res.json());

      if(mapping.id){
        this.setState({successMessage: "Sales mapping saved successfully!"});

        setTimeout(()=>{
          const updatedCompanies = {...companies};
          updatedCompanies[selectedCompany.id].mapped_names = selectedNames;
          this.setState({
            companies: updatedCompanies,
            filteredCompanies: Object.values(updatedCompanies),
            filteredMfrNames: mfrNames,
            selectedCompany: null,
            selectedNames: [],
            connectionSearchInput: '',
            nameSearchInput: '',
            errorMessage: null,
            successMessage: null
          });
        }, 2000);
        
      }else{
        this.setState({
          errorMessage: 'Something went wrong. Please try again later.'
        });
      }
      this.setState({saving: false});
    }

    _updateConnectionSearch = value => {
      this.setState({connectionSearchInput: value, filteredCompanies: Object.values(this.state.companies).filter(company => company.name.match(new RegExp(`${value}`, 'i')))});
      
    }

    _updateNameSearch = value => {
      this.setState({nameSearchInput: value, filteredMfrNames: this.state.mfrNames.filter(name => name.match(new RegExp(`${value}`, 'i')))})
    }

    _selectCompany = company => {
      this.setState({selectedCompany: company, selectedNames: company.mapped_names});
    }

    _addName = name => {
      this.setState({selectedNames: [...this.state.selectedNames, name]})
    }

    _removeName = name => {
      const names = [...this.state.selectedNames];

      const index = names.indexOf(name);

      if( index > -1){
        names.splice(index, 1);
        this.setState({selectedNames: names})
      }
    }

    _handleCloseRequest =() => {
      this.setState({
        filteredCompanies: Object.values(this.state.companies),
        filteredMfrNames: this.state.mfrNames,
        selectedCompany: null,
        selectedNames: [],
        connectionSearchInput: '',
        nameSearchInput: '',
        saving: false,
        errorMessage: null,
        successMessage: null
      });
      this.props.closeModal();
    }

    render(){
        const { companies, filteredCompanies, filteredMfrNames, selectedCompany, selectedNames, connectionSearchInput, nameSearchInput, saving, errorMessage, successMessage } = this.state;
        const {isModalOpen} = this.props;


        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)',
              width: "1000px",
              zIndex: 16777271,
              overflow: "auto",
              paddingLeft: "40px",
              paddingRight: "40px",
              border: '1px solid #707070',
              borderRadius: '10px'
            },
        
            overlay: {
              zIndex: 16777270,
              background: "#A3A99E88"
            }
            
          };

        return (
            <Modal
            isOpen={isModalOpen}
            onRequestClose={() => this._handleCloseRequest()}
            style={customStyles}
            contentLabel={`Map Sales Modal`}
            ariaHideApp={false}
            >
            <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"          
            >
              <Flex
              width='100%'
              justifyContent="flex-end"
              >
                <IconBox
                      style={{cursor: 'pointer'}}
                      fontSize={["12px"]}
                      color="#C7C7C7"
                      onClick={()=>this._handleCloseRequest()}
                    >
                      thinx
                    </IconBox>
              </Flex>
              
              <NunitoSansBold
              color='#428BF4'
              fontSize="20px"
              textAlign='center'
              style={{marginTop:'10px', marginBottom:'10px'}}
              >
                MAP SALES
              </NunitoSansBold>

              <NunitoSansBold
              color='#000000'
              fontSize="28px"
              textAlign="center"
              >
                Share sales data with your connections.
              </NunitoSansBold>

              <NunitoSansSemiBold
              color='#000000'
              fontSize="18px"
              textAlign="center"
              style={{marginTop: '15px', marginBottom: '40px', width: '75%'}}
              >
              Choose a connected company on the left and map sales values to them. Once a map has been created allow up to 48 hours for sales data stream to begin.
              </NunitoSansSemiBold>

              <Flex
              justifyContent="space-between"
              width="100%"
              style={{marginBottom: '40px'}}
              >
                  <Flex
                  flexDirection="column"
                  width="47%"
                  >
                      <NunitoSansBold
                      fontSize="18px"
                      color="#C3C3C3"
                      textAlign="center"
                      >
                          Your Network
                      </NunitoSansBold>

                      {
                        selectedCompany
                        ?
                        <>
                          <ConnectedCompanyRow
                          company={selectedCompany}
                          selected={true}
                          firstRow={true}
                          lastRow={false}
                          onSelect={() => this.setState({selectedCompany: null, selectedNames: []})}
                          />

                          <NunitoSansBold
                          fontSize="16px"
                          color="#C3C3C3"
                          textAlign="center"
                          style={{marginTop: '10px'}}
                          >
                              Mapped Values
                          </NunitoSansBold>
                          <MappedNameList
                            mappings={selectedNames}
                            onSelect={this._removeName}
                            selected={true}
                          />
                        </>
                        :
                        <>
                          <Flex position="relative" width={["100%"]} style={{marginTop: '15px', marginBottom: '15px'}}>
                            <InputStyled
                              placeholder="Search Connections"
                              onChange={e => this._updateConnectionSearch(e.target.value)}
                              value={connectionSearchInput}
                              width="100%"
                              name="connected-company-search"
                              type="text"
                              border={0}
                              autoFocus={true}
                              bg={["lightGray"]}
                              borderRadius={"6px"}
                              pt={["3px"]}
                              px={["16px"]}
                              mt={["0px"]}
                              height={["40px"]}
                              fontWeight={300}
                              fontSize={[16]}
                              lineHeight={1.3}
                              color={"#979797"}
                              borderBottom={[0]}
                            />
                            <AbsoluteDiv
                              display={["block"]}
                              right={["20px"]}
                              bottom={"0px"}
                              top={["0px"]}
                              width={["17px"]}
                            >
                              <Flex
                                height={["100%", "100%", "100%"]}
                                alignItems={["center"]}
                                onClick={() => this._updateConnectionSearch("")}
                                justifyContent={["center"]}
                                style={{cursor: 'pointer'}}
                              >
                                <IconBox
                                  cursor="pointer"
                                  pl={["15px"]}
                                  pb={["15px"]}
                                  pt={["15px"]}
                                  fontSize={["16px"]}
                                  color="#c3c3c3"
                                >
                                  thinx
                                </IconBox>
                              </Flex>
                            </AbsoluteDiv>
                          </Flex>

                          <ConnectedCompanyList
                              companies={filteredCompanies || []}
                              onSelect={this._selectCompany}
                          />
                        </>
                      }


                  </Flex>

                  <Flex
                  flexDirection="column"
                  width="47%"
                  >
                      <NunitoSansBold
                      fontSize="18px"
                      color="#C3C3C3"
                      textAlign="center"
                      >
                          From Your Sales
                      </NunitoSansBold>
                        
                      <Flex position="relative" width={["100%"]} style={{marginTop: '15px', marginBottom: '15px'}}>
                        <InputStyled
                          placeholder="Search Value"
                          onChange={e => this._updateNameSearch(e.target.value)}
                          value={nameSearchInput}
                          width="100%"
                          name="name-search"
                          type="text"
                          border={0}
                          autoFocus={true}
                          bg={["lightGray"]}
                          borderRadius={"6px"}
                          pt={["3px"]}
                          px={["16px"]}
                          mt={["0px"]}
                          height={["40px"]}
                          fontWeight={300}
                          fontSize={[16]}
                          lineHeight={1.3}
                          color={"#979797"}
                          borderBottom={[0]}
                          disabled={!selectedCompany}
                        />
                        <AbsoluteDiv
                          display={["block"]}
                          right={["20px"]}
                          bottom={"0px"}
                          top={["0px"]}
                          width={["17px"]}
                        >
                          <Flex
                            height={["100%", "100%", "100%"]}
                            alignItems={["center"]}
                            onClick={() => this._updateNameSearch("")}
                            justifyContent={["center"]}
                            style={{cursor: 'pointer'}}
                          >
                            <IconBox
                              cursor="pointer"
                              pl={["15px"]}
                              pb={["15px"]}
                              pt={["15px"]}
                              fontSize={["16px"]}
                              color="#c3c3c3"
                            >
                              thinx
                            </IconBox>
                          </Flex>
                        </AbsoluteDiv>
                      </Flex>
                      {
                        selectedCompany
                        ?
                        <MappedNameList mappings={filteredMfrNames?.filter(name => !selectedNames.includes(name)) || []} onSelect={this._addName} />
                        :
                        null
                      }

                  </Flex>
              </Flex>

              {
                successMessage
                ?
                <NunitoSans
                fontSize="24px"
                color="#2FD371"
                textAlign="center"
                >
                  {successMessage}
                </NunitoSans>
                :
                errorMessage
                ?
                <NunitoSans
                fontSize="24px"
                color="#FE4A49"
                textAlign="center"
                >
                  {errorMessage}
                </NunitoSans>
                :
                <DashboardSmallCTA
                  width="80%"
                  minWidth={["74px"]}
                  fontSize="15px"
                  innerHeight="40px"
                  onClick={() => !saving && this._handleSubmit()}
                  disabled={!selectedCompany || isEqual([...selectedNames].sort(), [...companies[selectedCompany.id].mapped_names].sort()) || saving}
                >
                  {saving ? "SAVING..." : "SAVE"}
                </DashboardSmallCTA>
              }



              
              <Box height="12px" />

            </Flex>
            
        </Modal>
        );
        
    }
}

export default MapSalesModal;