import {
  LightGrayCTA, KickPostGreenCTA
} from "../common/ui";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { gridTemplateRows, gridTemplateColumns } from "styled-system";
import { push } from "react-router-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route } from "react-router-dom";
// import FullStory from "react-fullstory";

//Intercom

import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
//utils
import { clearUser, getUser } from "../../utils/auth";
import { extraPadding } from "../../utils/constants";
import { colors } from "../../utils/theme";
import {
  phoneGutterSize,
  largePhoneGutterSize,
  tabletGutterSize
} from "../../utils/layout";
//actions
import { actions } from "../../actions/resources";
//components
//import asyncComponent from "../async";
// ^^ todo https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import { Box, Flex, IconBox } from "../common/flexbox";
import { AbsoluteDiv } from "../common/absolute";
import { GradientOverflowDownward } from "../common/ui";
import Feed, { FeedNavLaptop, FeedNavMobile } from "../dashboard/posts";
import FeedAccessory from "../dashboard/posts/accessory";
import AddPostAccessory from "../dashboard/posts/new/accessory";
// import AddPostAudienceFilterNav from "../dashboard/posts/new/audiences/audienceSearchMobileNav";
// import AddPostSelectedAudienceFiltersNav from "../dashboard/posts/new/audiences/selectedAudienceFiltersMobileNav";
// import AddPostAudienceFilterMain from "../dashboard/posts/new/audiences/audienceSearchMobileIndex";
// import AddPostSelectedAudienceFiltersMain from "../dashboard/posts/new/audiences/selectedAudienceFiltersMobileIndex";
import AddPostMain from "../dashboard/posts/new";
import AddPostNav from "../dashboard/posts/new/navigation";

import { Link } from "../common/navigation";

import {
  NotFoundMain,
  NotFoundAccessory,
  NotFoundNav
} from "../dashboard/notfound";
import EditProfileAccessory from "../dashboard/profile/edit/accessory";
import EditProfileMain, {
  EditProfileNav
} from "../dashboard/profile/edit";
import EditCompanyAccessory from "../dashboard/company/edit/accessory";
import EditCompanyMain, {
  EditCompanyNav
} from "../dashboard/company/edit";
import AssetCompanyMain, {
  AssetCompanyNav
} from "../dashboard/company/asset";
import ProfileDetailAccessory from "../dashboard/profile/detail/accessory";
import PostDetailMain, {
  PostDetailAccessory,
  PostDetailNav
} from "../dashboard/posts/detail";
import ProfileDetailMain, {
  ProfileDetailNav
} from "../dashboard/profile/detail";
import CompanyDetailAccessory from "../dashboard/company/detail/accessory";
import CompanyDetailMain, {
  CompanyDetailNav
} from "../dashboard/company/detail";
import ManageEmployeesAccessory from "../dashboard/employees/accessory";
import ManageEmployeesMain, {
  ManageEmployeesNav
} from "../dashboard/employees";
import { LegalNav } from "../dashboard/legal";
import ManageVendorsAccessory from "../dashboard/vendors/accessory";
import ManageVendorsMain, {
  ManageVendorsNav
} from "../dashboard/vendors";
import ManageSystemSettingsMain, {
  ManageSystemSettingsNav
} from "../dashboard/systemsettings";
import ManageSystemReportsMain, {
  ManageSystemReportsNav
} from "../dashboard/systemreports";
import ManageSettingsMain, {
  ManageSettingsNav
} from "../dashboard/settings";
import ManageIncentiveMain, {
  ManageIncentiveNav
} from "../dashboard/incentives/new";
import IncentiveDetail, {
  IncentiveDetailNav
} from "../dashboard/incentives/details";
import ManageRewardsMain, {
  ManageRewardsNav
} from "../dashboard/rewards";
import ManageSalesMain, {
  ManageSalesNav
} from "../dashboard/sales";
import ManageRewardsAccessory from "../dashboard/rewards/accessory";
import ManageSalesAccessory from "../dashboard/sales/accessory";
import ManageDepartmentsAccessory from "../dashboard/departments/accessory";
import ManageGroupsAccessory from "../dashboard/groups/accessory";
import ManageIncentiveAccessory, { IncentiveDetailAccessory } from "../dashboard/incentives/accessory";
import ManageInvitationsAccessory from "../dashboard/invitations/accessory";
import ManageDepartmentsMain, {
  ManageDepartmentsNav
} from "../dashboard/departments";
import ManageGroupsMain, {
  ManageGroupsNav
} from "../dashboard/groups";
import ManageInvitationsMain, {
  ManageInvitationsNav
} from "../dashboard/invitations";
import InviteSuggestionsMain, {
  InviteSuggestionsNav
} from "../dashboard/invitesuggestions";
import InviteSuggestionsAccessory from "../dashboard/invitesuggestions/accessory";
import ConnectionSuggestionsMain, {
  ConnectionSuggestionsNav
} from "../dashboard/connectionsuggestions";
import SearchNav from "../dashboard/search/nav";
import SearchMain from "../dashboard/search";
import SystemAlerts from "../common/systemAlerts";
//import { BreadcrumbSidebar } from "../dashboard/breadcrumbs";
import { HomeCrumbForSidebar } from "../dashboard/homecrumb";
import {
  H1CondensedSmallD0D0D0,
  DINCondensed,
  NunitoSans,
  NunitoSansBold,
  NunitoSansSemiBold
} from "../common/typography";
import {
  OutlineFlex,
  DashboardSmallCTA,
  renderSmallField
} from "../common/forms";
//components
import AuthStart from "./start";
import { faLacrosseStick } from "@fortawesome/pro-light-svg-icons";

const LegalStyled = styled.div`
  /*margin-top: 1em;*/
  text-align: center;
  font-size: 10px;
  color: #6c7168;
  font-family: NunitoSans;
`;


const GridStyledHeader = styled.div`
  overflow:${props => (props.overflow ? props.overflow : "visible")}
  ${gridTemplateRows} ${gridTemplateColumns}
  display:grid;
  height: 8%;
`;
const GridStyled = styled.div`
  overflow:${props => (props.overflow ? props.overflow : "visible")}
  ${gridTemplateRows} ${gridTemplateColumns}
  display:grid;
  height: 100%;
`;
const MobileNavWrapperStyled = styled(Flex)`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const MobileContentWrapperStyled = styled.div`
  /* border-style: groove; */
  grid-column-start: 1;
  grid-column-end: 3;
  /* grid-row-start: 2;*/
  grid-row-end: 1;
  overflow: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BreadcrumbSidebarColumn3 = styled.div`
/* border-style: groove; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  height: 100%;
  position: relative;
`;
const BreadcrumbSidebarColumn2 = styled.div`
/* border-style: groove; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  height: 500%;
  position: relative;
`;


export const SidebarGradient1 = styled(AbsoluteDiv)`
  /* Rectangle 4: */
  background-color: ${colors.kickpostNavyBlue};
`;

export const SidebarGradient2 = styled(AbsoluteDiv)`
  background-image: linear-gradient(
    -154deg, 
    #10213A 100%,
    #10213A 100%
  );  
`;
export const SidebarGradient3 = styled(AbsoluteDiv)`
  /* Rectangle 4: */
  background-color: ${colors.kickpostNavyBlue};
`;
export const SidebarGradient4 = styled(AbsoluteDiv)`
  background-color: ${colors.kickpostNavyBlue};  
`;

const Sidebar = ({ ...props }) => (
  <Flex alignItems="center" justifyContent="center">
    <SidebarGradient1 style={{ display: "none" }} id="sdbr1" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient2 style={{ display: "none" }} id="sdbr2" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient3 style={{ display: "none" }} id="sdbr3" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient4 style={{ display: "none" }} id="sdbr4" top="0px" left="0px" right="0px" bottom="0px" />
    <AbsoluteDiv bottom="79.75%" left="0px" right="0px">
      {/*<IconBox paddingLeft="25px" color="#fff" fontSize="70px">
      kickpostMini-1
      </IconBox>*/}
      <img src="/icon.png" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "70px", height: "70px", zIndex: "1" }}></img>

      <LegalStyled>
        <Link width="100%" to="/dashboard/legal">
          Legal Disclaimer
        </Link>
      </LegalStyled>
    </AbsoluteDiv>
    {props.children}
  </Flex>
);
const Sidebar3 = ({ ...props }) => (
  <Flex flexDirection="column" height="98%">
    <SidebarGradient1 id="sdbr1" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient2 id="sdbr2" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient3 id="sdbr3" top="0px" left="0px" right="0px" bottom="0px" />
    <SidebarGradient4 id="sdbr4" top="0px" left="0px" right="0px" bottom="0px" />

    {props.children}
    <Flex alignItems="center" justifyContent="flex-end" flexDirection="column" height="90%">



      {/* <img src="/icon.png" style={{width:"70px",height:"70px",zIndex:"1"}}></img> */}
      <img src={props.homeIcon} style={{ width: "70px", height: "70px", zIndex: "1" }}></img>

      <LegalStyled style={{ zIndex: "1" }}>
        <Link width="100%" to="/dashboard/legal">
          Legal Disclaimer
        </Link>
      </LegalStyled>


    </Flex>
  </Flex>
);

const LaptopNavWrapper = styled(Flex)`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: white;
  position: relative;
`;

const LaptopContentColumn = styled.div`
  grid-column-start: 3;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1;
  ${gridTemplateRows} ${gridTemplateColumns}
  display:grid;
  height: 100%;
`;

const LaptopContentWrapper = styled.div`
/* border-style: groove; */
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 4;
  overflow: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const LaptopAccessoryColumn = styled.div`
  grid-column-start: 7;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 1;
  position: relative;
  ${gridTemplateRows} ${gridTemplateColumns}
  display:grid;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;



let LCCDisplay = 'block';
var hideModal;

const MModal = styled.div`

`;


export class Dashboard extends Component {

  constructor(props) {
    super(props);
  }


  _fetchDependencies = async () => {
    const { fetchUser } = this.props;
    
    const gotuser = getUser();
    if(gotuser&&gotuser.id){
      fetchUser(gotuser.id);
    }
    // this.props.iKey = window.location.pathname.toString().substring(21);
  }

  componentDidMount() {
    this._fetchDependencies();
    //hide the scroll bars on the left
    // document.getElementById("LACColumn").style.overflowY = "hidden";
    // document.getElementById("LACColumn").style.overflowX = "hidden";
    this._checkAuth();

    this._systemSettings()
      .catch((e) => {
        console.log("e", e);
      });
    this._fetchSet()
      .catch((e) => {
        console.log("e", e);
      });
    this._fetchFeatureFlags()
      .catch((e) => {
        console.log("e", e);
      });
    this._fetchInvite()
      .then(() => {
        afterset(this.props);
      })
      .catch((e) => {
        console.log("e", e);
      });

    // if (getUser() && getUser().company_id && systemSettings && fetchSet && fetchInvite) {
    function afterset(myprops) {
      if (myprops.companySettings && myprops.systemSettings) {
        let invite_suggestion_active = myprops.companySettings.invite_suggestion_active;
        let connection_suggestion_active = myprops.companySettings.connection_suggestion_active;
        let company_goal = myprops.companySettings.company_goal;
        // console.log("invite_suggestion_active, connection_suggestion_active, company_goal", invite_suggestion_active, connection_suggestion_active, company_goal);
        if (invite_suggestion_active === true) {
          myprops.fetchInviteSuggestions({ company_id: getUser().company_id, user_id: getUser().id });
        }
        if (connection_suggestion_active === true) {
          myprops.fetchConnectionSuggestions({ company_id: getUser().company_id });
        }
       
      };
    }
  }



  componentDidUpdate() {

    const { user, updateUser } = this.props;
    console.log("user", user)

    const userCookie = getUser() || null;

    if (!userCookie || !userCookie.token) {
      clearUser();
      this.props.redirect("/");
    }

    if (user && userCookie && user.id === userCookie.id) {
      if (
        userCookie.company_owner !== user.company_owner ||
        (user.status !== "active" && user.status !== "pending") ||
        (user.company_admin && !userCookie.company_admin) ||
        (!user.company_admin && userCookie.company_admin) ||
        (user.system_admin && !userCookie.system_admin) ||
        (!user.system_admin && userCookie.system_admin) ||
        (userCookie.company_id !== null && user.company_id !== userCookie.company_id)
      ) {
        clearUser();
        this.props.redirect("/");
      }
    }


    if (userCookie && userCookie.hasOwnProperty("client_session_id") && user && user.id === userCookie.id && user.client_session_id !== userCookie.client_session_id) {
      clearUser();

      if (!user.client_session_id) {
        localStorage.clear();
      }

      this.props.redirect("/");
    }


    var currentPage = window.location.pathname;

    if (currentPage.search("legal") < 0) {

      this._kickpostModalNotification();

      hideModal = window.setTimeout(function () {
        try {
          document.getElementsByClassName("main-content")[0].style.display = "block";
          if (document.getElementById('myModal').style.display === "block") {
            document.getElementById('myModal').style.top = "242px";

            document.getElementById("LACColumn").style.display = "none";
            document.getElementsByClassName("main-content")[0].style.display = "none";
          } else {
            document.getElementById("LACColumn").style.display = "block";
          }
        } catch (e) {

        }


      }, 300);
    }


  }

  /*
  componentWillRec-eiveProps(nextProps) {
    // if (nextProps.user && nextProps.user.status === "deleted") {
    //   this._logout(); 
    // }
  }
*/


  _kickpostModalNotification = () => {
    const { redirect } = this.props;


    if (!getUser() || !getUser().token) {
      return redirect("/");
    }

    // Get the button that opens the modal
    var btn = document.getElementById("myBtn");
    // When the user clicks on the button, open the modal


    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
      }
    }


    // Get the modal
    var modal = document.getElementById('myModal');

    if (modal) {
      modal.style.display = "none";
    }

    document.getElementById("kickpost-root").style.background = "rgba(0,0,0,0.5)";

    var stateLCCColumn = document.getElementById("LCCColumn");
    //console.log(stateLCCColumn)
    if ((stateLCCColumn !== null) && (stateLCCColumn !== "null")) {
      document.getElementById("GODL").style.display = "none";
      document.getElementById("sdbr1").style.display = "none";
      document.getElementById("sdbr2").style.display = "none";
      document.getElementById("sdbr3").style.display = "block";
      document.getElementById("sdbr4").style.display = "block";
    } else {


      if (modal) {
        modal.style.left = "0px";
      }

      if (document.getElementById("MobileContentWrapper")) {
        document.getElementById("MobileContentWrapper").style.visibility = "hidden";
      }
    }




    let kpPolicy = JSON.parse(localStorage.getItem("kpPolicy"));

    if ((localStorage.getItem("kpPolicy") === null) || (kpPolicy && kpPolicy.version && kpPolicy.version !== process.env.REACT_APP_POLICY_VERSION)) {

      if (modal) {

        modal.style.display = "block";
      }

      if (btn) {

        btn.click();
      }
    } else if (typeof kpPolicy.tospp === "undefined" || typeof kpPolicy.tospp === undefined || kpPolicy.tospp === false || kpPolicy.tospp === "false") {

      if (modal) {

        modal.style.display = "block";
      }

      if (btn) {

        btn.click();
      }
    } else {

      //modal.style.display = "none";
      //document.getElementById('myModal').style.display = "none";
      //document.getElementById("LCCColumn").style.display = "inherit";
      //document.getElementById("LACColumn").style.display = "inherit";      


      //var stateLCCColumn = document.getElementById("LCCColumn");
      //console.log(stateLCCColumn)
      if ((stateLCCColumn !== null) && (stateLCCColumn !== "null")) {
        document.getElementById("LCCColumn").style.display = "inherit";
        document.getElementById("LACColumn").style.display = "inherit";
        document.getElementById("GODL").style.display = "block";
        document.getElementById("sdbr1").style.display = "block";
        document.getElementById("sdbr2").style.display = "block";
        document.getElementById("sdbr3").style.display = "none";
        document.getElementById("sdbr4").style.display = "none";

      } else {
        if (document.getElementById("MobileContentWrapper")) {

          document.getElementById("MobileContentWrapper").style.visibility = "visible";
        }

      }

      if (modal) {
        modal.style.display = "none";
      }
      document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";



    }




    // When the user clicks anywhere outside of the modal
    window.onclick = function (event) {
      if (event.target === modal) {
        //modal.style.display = "none";//, close it
      }
    }


  };


  _checkAuth = () => {
    const { redirect } = this.props;
    if (!getUser() || !getUser().token) {
      return redirect("/");
    }
  };


  _systemSettings = async () => {
    return this.props.fetchSystemSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
  }
  _fetchSet = async () => {
    return this.props.fetchSettings(getUser().company_id);
  }
  _fetchInvite = async () => {
    return this.props.fetchInviteSuggestions({ company_id: getUser().company_id, user_id: getUser().id });
  }
  _fetchFeatureFlags = async () => {
    const gotUser = getUser();
    if(gotUser&&gotUser.company_id){
      return this.props.fetchFeatureFlags(gotUser.company_id);
    } 
  } //updated to make sure company id is present on 10/8/23



  _processAccept = async () => {
    //window.alert("You accepted the ToS and PP");
    //console.log("You accepted the ToS and PP");
    let kpPolicy = {
      "message": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
      "tospp": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
      "version": process.env.REACT_APP_POLICY_VERSION
    };
    localStorage.setItem("kpPolicy", JSON.stringify(kpPolicy));

    document.getElementById('myModal').style.display = "none";
    document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";

    var stateLCCColumn = document.getElementById("LCCColumn");
    //console.log(stateLCCColumn)
    if ((stateLCCColumn !== null) && (stateLCCColumn !== "null")) {
      document.getElementById("LCCColumn").style.display = "inherit";
      document.getElementById("LACColumn").style.display = "inherit";
      document.getElementById("GODL").style.display = "block";
      document.getElementById("sdbr1").style.display = "block";
      document.getElementById("sdbr2").style.display = "block";
      document.getElementById("sdbr3").style.display = "none";
      document.getElementById("sdbr4").style.display = "none";
      this.props.redirect("/dashboard");

    } else {
      document.getElementById("MobileContentWrapper").style.visibility = "hidden";
      window.location.reload(true);

    }

  };

  //prevent doing anything until Notification is HANDLED properly by user (accepted)

  //see these features

  //system maintenance during these dates

  //show close button

  //  redirection to the page 

  _logout = async () => {
    //window.alert("_logout");
    //window.location.href="https://www.kickpost.io/";
    document.getElementById('myModal').style.display = "none";
    document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";
    document.getElementById("GODL").style.display = "block";
    document.getElementById("sdbr1").style.display = "block";
    document.getElementById("sdbr2").style.display = "block";
    document.getElementById("sdbr3").style.display = "none";
    document.getElementById("sdbr4").style.display = "none";


    clearUser();
    this.props.redirect("/");
  };


  render() {
    // console.log("WELCOME TO KICKPOST");
    const DMG ="src/components/dashboard/index.js";
    console.log(DMG+" this.props",this.props);
    const { redirect, post, user, location, systemSettings, companySettings, browser, featureFlags } = this.props;

    // console.log(user);

    const myBtnModalStyle = {
      "display": "none"
    }

    const modalStyling = {
      "border": "#2FD371 solid 1px",
      "zIndex": "9999999",

      /*
      "display": "block",
      "position": "absolute",
      "z-index": "200000",
      "top": "242px",
      "left": "366px",
      "background": "green",
      "color": "white",
      "margin": "23px",
      "padding": "50px",
      "border-radius": "10px"
      */
    };


    const HeaderDivH1 = styled.div`
  padding-bottom: 20px;
  padding-top: 45px;
  text-align: center;
  font-size: 34px;
  color: #000;
  font-family: NunitoSansBold;
`;

    const CloseTextDiv = styled.div`
  font-size: 16px;
  color: #000;
  font-family: NunitoSans;
  line-height: 1.25;
`;
    /* const HeaderStyleH1 = {
      "textAlign": "center",
      "fontSize": "35px",
      "color": "#000",
      "fontFamily": "NunitoSansBold"
    }; */

    if (getUser() && systemSettings && companySettings) {
      return (
        <Box height="100%">
          {/* {process.env.ENVIRONMENT === "production" ? (
            <div>
              <FullStory org={process.env.REACT_APP_FULLSTORY_APP_ID} />
            </div>
          ) : null} */}




{
  //          (user && user.id && user.company_owner && (!user.onboarding_completed) || !Boolean(localStorage.getItem("kpPolicy"))) -- with checking of kpPolicy
          (user && user.id && user.company_owner && (!user.onboarding_completed))  ?          
          <Box height="100%">
            <AuthStart                   
              isMobile={browser.lessThan.large}
            />
          </Box>
          : 
          this.props.browser.lessThan.large ? (
            <Box height="100%">


              <MModal
                style={{ modalStyling }}
              >
                <button style={{ myBtnModalStyle }} id="myBtn"></button>
                <div
                  id="myModal"
                  className="modal"
                  style={{
                    "border": "#2FD371 solid 1px",
                    "display": "block",
                    "position": "absolute",
                    "zIndex": "100000",
                    "top": "50%",
                    "left": "40%",
                    "background": "#fff",
                    "color": "black",
                    "padding": "0 45px 45px",
                    // "width":"10%",
                    // "minWidth":"250px",
                    "maxWidth": "100%",
                    "borderRadius": "30px"
                  }}
                >


                  <div className="modal-content">
                    <HeaderDivH1>Welcome to KickPost!</HeaderDivH1>
                    <CloseTextDiv>You must accept the KickPost Terms of Service and acknowledge Privacy Policy to continue.</CloseTextDiv>
                    <br />
                    View <a href="/mlegal" target="_blank" style={{ color: "#2FD371", textDecoration: "underline" }}>Terms of Service</a> and <a href="/mlegal" target="_blank" style={{ color: "#2FD371", textDecoration: "underline" }}>Privacy Policy</a>.
                    <Flex flexDirection="column" pt="45px" px="8px">
                      <Flex px="1%" flexDirection="row" justifyContent="space-between">
                        <Box width="40%" height="34px" maxWidth="145px">
                          <LightGrayCTA fontSize={17} link={{}}>
                            <button style={{ "cursor": "pointer", "fontSize": "17px", "verticalAlign": "top", "fontWeight": "bold", "color": "lightgray" }} onClick={this._logout} value>
                              DECLINE
                            </button>
                          </LightGrayCTA>
                        </Box>
                        <Box width="40%" height="34px" maxWidth="145px">
                          <KickPostGreenCTA fontSize={17} link={{}}>
                            <button style={{ "cursor": "pointer", "fontSize": "17px", "verticalAlign": "top", "fontWeight": "bold", "color": "white" }} onClick={this._processAccept}>
                              ACCEPT
                            </button>
                          </KickPostGreenCTA>
                        </Box>
                      </Flex>
                    </Flex>

                  </div>

                </div>
              </MModal>

              <GridStyledHeader id="MobileContentWrapper"
                overflow="hidden"
                gridTemplateColumns={["100fr"]}
                gridTemplateRows={["49px 100fr", "49px 100fr", "60px 100fr"]}
              >
                <MobileNavWrapperStyled
                  position="relative"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AbsoluteDiv
                    top="0px"
                    bottom="0px"
                    left={[
                      phoneGutterSize,
                      largePhoneGutterSize,
                      tabletGutterSize
                    ]}
                    right={[
                      phoneGutterSize,
                      largePhoneGutterSize,
                      tabletGutterSize
                    ]}
                  >
                    <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                      <SystemAlerts />
                    </AbsoluteDiv>
                    <TransitionGroup>
                      <CSSTransition
                        key={this.props.location.key}
                        classNames="mobile-nav"
                        timeout={100}
                      >
                        <Switch location={this.props.location}>
                          <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                            <Route
                              exact
                              path="/dashboard"
                              render={props => <FeedNavMobile
                                user={user}
                                settings={systemSettings}
                                companySettings={companySettings}
                                {...props}
                              />}
                            />
                            {/* <Route
                              exact
                              path="/dashboard/posts/edit/:id"
                              render={props => <AddPostNav {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/posts/new"
                              render={props => <AddPostNav {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/posts/new/audience"
                              render={props => (
                                <AddPostAudienceFilterNav {...props} />
                              )}
                            /> */}

                            {/* <Route
                              exact
                              path="/dashboard/posts/new/audience/selected"
                              render={props => (
                                <AddPostSelectedAudienceFiltersNav {...props} />
                              )}
                            /> */}
                            <Route
                              exact
                              path="/dashboard/profile/:id"
                              render={props => (
                                <ProfileDetailNav
                                key={`pdnvr`+props.location.pathname}
                                  redirect={redirect}
                                  user={user}
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/:id?search"
                              render={props => (
                                <ProfileDetailNav
                                key={`pdnvr`+props.location.pathname}
                                  redirect={redirect}
                                  user={user}
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/edit/:id"
                              render={() => <EditProfileNav />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/edit/:id"
                              render={() => <EditCompanyNav />}
                            />
                            <Route
                              path="/dashboard/post/:id"
                              render={() => (
                                <PostDetailNav
                                  post={post}
                                  user={user}
                                  redirect={redirect}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id"
                              render={props => (
                                <CompanyDetailNav
                                  key={["CDN" + `${Math.random().toString()}`]}
                                  redirect={redirect}
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/invitations"
                              render={props => (
                                <ManageInvitationsNav {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/invitesuggestions"
                              render={props => (
                                <InviteSuggestionsNav {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/connectionsuggestions"
                              render={props => (
                                <ConnectionSuggestionsNav {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/employees"
                              render={props => (
                                <ManageEmployeesNav {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/manage/employees"
                              render={() => <ManageEmployeesNav />}
                            />
                            {/* <Route
                              exact
                              path="/dashboard/company/:id/vendors"
                              render={() => <ManageVendorsNav />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/systemsettings"
                              render={() => <ManageSystemSettingsNav />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/settings"
                              render={props => <ManageSettingsNav {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/new"
                              render={props => <ManageIncentiveNav {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/edit/:id"
                              render={props => <ManageIncentiveNav {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/duplicate/:id"
                              render={props => <ManageIncentiveNav {...props} />}
                            /> */}
                            <Route
                              exact
                              path="/dashboard/incentive/:id"
                              render={props => (
                                <IncentiveDetailNav {...props} />
                              )}
                            />

                            {
                              (featureFlags && featureFlags.rewards === true) && (
                                <Route
                                  exact
                                  path="/dashboard/rewards"
                                  render={() => <ManageRewardsNav />}
                                />
                              )
                            }
                            {
                              (featureFlags && featureFlags.sales === true) && (
                                <Route
                                  exact
                                  path="/dashboard/sales"
                                  render={props => <ManageSalesNav  {...props}/>}
                                />
                              )
                            }
                            <Route
                              exact
                              path="/dashboard/search"
                              render={() => <SearchNav />}
                            />
                            <Route
                              exact
                              path="/dashboard/legal"
                              render={props => <LegalNav {...props} />}
                            />

                          </AbsoluteDiv>
                          <Route render={() => <NotFoundNav />} />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  </AbsoluteDiv>
                  <AbsoluteDiv
                    width="100%"
                    bottom={["-30px", "-40px", "-60px"]}
                  >
                    <GradientOverflowDownward
                      width={1}
                    // height={["15px", "20px", "25px"]}
                    />
                  </AbsoluteDiv>
                </MobileNavWrapperStyled>
              </GridStyledHeader>
              <GridStyled  id="MobileContentWrapper"
                overflow="hidden"
                 height="5px"
                gridTemplateColumns={["30fr"]}
                gridTemplateRows={["49px 10fr", "49px 10fr", "60px 10fr"]}>

                <MobileContentWrapperStyled
                  // innerRef={div => (this.mainContentMobile = div)}
                >
                  <AbsoluteDiv
                    left={[
                      phoneGutterSize,
                      largePhoneGutterSize,
                      tabletGutterSize
                    ]}
                    right={[
                      phoneGutterSize,
                      largePhoneGutterSize,
                      tabletGutterSize
                    ]}
                  >
                    {/* <Box height="30px" /> */}
                    <TransitionGroup>
                      <CSSTransition
                        key={this.props.location.key}
                        classNames="mobile-nav"
                        timeout={100}
                      >
                        <Switch location={this.props.location}>
                          <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                            <Box height={["15px", "20px", "25px"]} />
                            <Route
                              exact
                              path="/dashboard"
                              render={props => <Feed
                                user={user}
                                {...props}
                              />}
                            />
                            {/* <Route
                              exact
                              path="/dashboard/posts/edit/:id"
                              render={props => <AddPostMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/posts/new"
                              render={props => <AddPostMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/posts/new/audience"
                              render={props => (
                                <AddPostAudienceFilterMain {...props} />
                              )}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/posts/new/audience/selected"
                              render={props => (
                                <AddPostSelectedAudienceFiltersMain
                                  {...props}
                                />
                              )}
                            /> */}
                            <Route
                              exact
                              path="/dashboard/profile/:id"
                              render={props => (
                                <ProfileDetailMain 
                                key={`pdmain1`+props.location.pathname}
                                user={user}
                                systemSettings={systemSettings}
                                companySettings={companySettings}
                                {...props}
                                 />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/:id?search"
                              render={props => (
                                <ProfileDetailMain 
                                key={`pdmain2`+props.location.pathname}
                                user={user}
                                systemSettings={systemSettings}
                                companySettings={companySettings}
                                {...props}
                                 />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/edit/:id"
                              render={props => (
                                <EditProfileMain user={user} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/edit/:id"
                              render={() => <EditCompanyMain />}
                            />
                            <Route
                              exact
                              path="/dashboard/post/:id"
                              render={props => <PostDetailMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id"
                              render={props => <CompanyDetailMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/employees"
                              render={props => (
                                <ManageEmployeesMain
                                  {...props}
                                />
                              )}
                            />
                            {/* <Route
                              exact
                              path="/dashboard/company/:id/vendors"
                              render={props => <ManageVendorsMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/systemsettings"
                              render={props => <ManageSystemSettingsMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/settings"
                              render={props => <ManageSettingsMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/new"
                              render={props => <ManageIncentiveMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/edit/:id"
                              render={props => <ManageIncentiveMain {...props} />}
                            /> */}
                            {/* <Route
                              exact
                              path="/dashboard/incentives/duplicate/:id"
                              render={props => <ManageIncentiveMain {...props} />}
                            /> */}
                            {console.log(featureFlags,"1194featureFlags  src/components/dashboard/index.js")}

                            {
                              (featureFlags && featureFlags.rewards === true) && (
                                <Route
                                  exact
                                  path="/dashboard/rewards"
                                  render={props => <ManageRewardsMain {...props} />}
                                />
                              )
                            }
                            {
                              (featureFlags && featureFlags.sales === true) && (
                                <Route
                                  exact
                                  path="/dashboard/sales"
                                  render={props => <ManageSalesMain {...props} />}
                                />
                              )
                            }
                            <Route
                              exact
                              path="/dashboard/incentive/:id"
                              key={window.location.pathname.toString().substring(18)}
                              render={props => <IncentiveDetail                                 
                                key={`incd1`+props.location.pathname}
                                {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/invitations"
                              render={props => (
                                <ManageInvitationsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/invitesuggestions"
                              render={props => (
                                <InviteSuggestionsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/connectionsuggestions"
                              render={props => (
                                <ConnectionSuggestionsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/search"
                              render={() => <SearchMain />}
                            />

                            <Route exact path="/dashboard/linkedin" component={LinkedInPopUp} />
                            {/* <Route render={props => <NotFoundMain {...props} />} /> */}
                          </AbsoluteDiv>
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  </AbsoluteDiv>
                </MobileContentWrapperStyled>

                </GridStyled>  
            </Box>
          ) : (
            <GridStyled
              gridTemplateColumns={[
                "8.333% 50fr " +
                extraPadding +
                "px 50% " +
                extraPadding +
                "px 50fr " +
                extraPadding +
                "px 25% " +
                extraPadding +
                "px 8.333%",

                "8.333% 50fr " +
                extraPadding +
                "px 50% " +
                extraPadding +
                "px 50fr " +
                extraPadding +
                "px 25% " +
                extraPadding +
                "px 8.333%",

                "8.333% 50fr " +
                extraPadding +
                "px 50% " +
                extraPadding +
                "px 50fr " +
                extraPadding +
                "px 25% " +
                extraPadding +
                "px 8.333%",

                "8.333% 33fr " +
                extraPadding +
                "px 50% " +
                extraPadding +
                "px 33fr " +
                extraPadding +
                "px 25% " +
                extraPadding +
                "px 33fr",

                "8.333% 33fr " +
                extraPadding +
                "px 41.6667% " +
                extraPadding +
                "px 33fr " +
                extraPadding +
                "px 25% " +
                extraPadding +
                "px 33fr"
              ]}
              gridTemplateRows={["100fr"]}
            >
              {
                (window.location.pathname.toString().search("legal") < 0) ? (
                  <Box width={["100%", "100%", "100%"]} pb={["0px", "0px"]}>
                    <BreadcrumbSidebarColumn3>
                      <Sidebar3
                        homeIcon={systemSettings.logo_primary}
                      >
                        {/*<BreadcrumbSidebar />*/}
                        <HomeCrumbForSidebar logout={this._logout} path={location.pathname} user={user || getUser()} />
                      </Sidebar3>
                    </BreadcrumbSidebarColumn3>
                  </Box>
                ) : (
                  <Box width={["100%", "100%", "100%"]} pb={["0px", "0px"]}>
                    <BreadcrumbSidebarColumn3>
                      <Sidebar3
                        homeIcon={systemSettings.logo_primary}
                      >
                        {/*<BreadcrumbSidebar />*/}
                        <HomeCrumbForSidebar logout={this._logout} path={location.pathname} user={user || getUser()} />
                      </Sidebar3>
                    </BreadcrumbSidebarColumn3>
                  </Box>
                )
              }
              <MModal
                style={{ modalStyling }}
              >
                <button style={{ myBtnModalStyle }} id="myBtn"></button>
                <div
                  id="myModal"
                  className="modal"
                  style={{
                    "border": "#2FD371 solid 1px",
                    "display": "block",
                    "position": "absolute",
                    "zIndex": "100000",
                    "top": "50%",
                    "left": "40%",
                    "background": "#fff",
                    "color": "black",
                    "padding": "0 45px 45px",
                    "width": "18%",
                    "minWidth": "335px",
                    "borderRadius": "30px"
                  }}
                >


                  <div className="modal-content">
                    <HeaderDivH1>Welcome to KickPost!</HeaderDivH1>
                    <CloseTextDiv>You must accept the KickPost Terms of Service and acknowledge Privacy Policy to continue.</CloseTextDiv>
                    <br />
                    View <a href="/dashboard/legal" target="_blank" style={{ color: "#2FD371", textDecoration: "underline" }}>Terms of Service and Privacy Policy</a>.
                    <Flex flexDirection="column" pt="45px" px="8px">
                      <Flex px="1%" flexDirection="row" justifyContent="space-between">
                        <Box width="40%" height="34px" maxWidth="145px">
                          <LightGrayCTA fontSize={17} link={{}}>
                            <button style={{ "cursor": "pointer", "fontSize": "17px", "verticalAlign": "top", "fontWeight": "bold", "color": "lightgray" }} onClick={this._logout} value>
                              DECLINE
                            </button>
                          </LightGrayCTA>
                        </Box>
                        <Box width="40%" height="34px" maxWidth="145px">
                          <DashboardSmallCTA fontSize={17} link={{}}
                            onClick={this._processAccept}
                            modal={true}
                          >
                            ACCEPT

                          </DashboardSmallCTA>
                        </Box>
                      </Flex>
                    </Flex>

                  </div>

                </div>
              </MModal>


              <LaptopContentColumn id="LCCColumn"
                style={{ "display": LCCDisplay }}
                gridTemplateColumns={["100fr"]}
                gridTemplateRows={[
                  "60px 10x 100fr",
                  "60px 10px 100fr",
                  "60px 10px 100fr",
                  "66px 10px 100fr"
                ]}
              >
                <LaptopNavWrapper id="GODL"
                  mx={`${extraPadding}px`}
                  alignItems="flex-end"
                >
                  <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                    <SystemAlerts />
                  </AbsoluteDiv>
                  <TransitionGroup>
                    <CSSTransition
                      key={this.props.location.key}
                      classNames="laptop-nav"
                      timeout={100}
                    >
                      <Switch location={this.props.location}>
                        <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                          <Route
                            exact
                            path="/dashboard"
                            render={props => (
                              <FeedNavLaptop
                                extraPadding={extraPadding}
                                logout={this._logout}
                                user={user}
                                systemSettings={systemSettings}
                                companySettings={companySettings}
                                browser={browser}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/posts/edit/:id"
                            render={props => <AddPostNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/posts/duplicate/:id"
                            render={props => <AddPostNav {...props} />}
                          />
                          <Route
                            path="/dashboard/posts/new"
                            render={props => <AddPostNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/:id"
                            render={props => (
                              <ProfileDetailNav
                                redirect={redirect}
                                key={`pdnv`+props.location.pathname}
                                user={user}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/:id?search"
                            render={props => (
                              <ProfileDetailNav
                                redirect={redirect}
                                key={`pdnv`+props.location.pathname}
                                user={user}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/edit/:id"
                            render={() => <EditProfileNav />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/edit/:id"
                            render={() => <EditCompanyNav />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/asset/new"
                            render={() => <AssetCompanyNav />}
                          />
                          <Route
                            path="/dashboard/post/:id"
                            render={() => (
                              <PostDetailNav
                                post={post}
                                user={user}
                                redirect={redirect}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id"
                            render={props => (
                              <CompanyDetailNav
                                key={["CDN" + `${Math.random().toString()}`]}
                                redirect={redirect}
                                {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/employees"
                            render={props => <ManageEmployeesNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/vendors"
                            render={() => <ManageVendorsNav />}
                          />
                          <Route
                            exact
                            path="/dashboard/systemsettings"
                            render={() => <ManageSystemSettingsNav />}
                          />
                          <Route
                            exact
                            path="/dashboard/systemreports"
                            render={() => <ManageSystemReportsNav />}
                          />
                          <Route
                            exact
                            path="/dashboard/settings"
                            render={props => <ManageSettingsNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/incentives/new"
                            render={props => <ManageIncentiveNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/incentives/edit/:id"
                            render={props => <ManageIncentiveNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/incentives/duplicate/:id"
                            render={props => <ManageIncentiveNav {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/incentive/:id"
                            render={props => <IncentiveDetailNav 
                              key={`incd2`+props.location.pathname}
                              {...props} />}
                          />
                          {console.log(featureFlags,"1548featureFlags  src/components/dashboard/index.js featureFlags")}
                          {

                              (featureFlags && featureFlags.rewards === true) && (
                                <Route
                                  exact
                                  path="/dashboard/rewards"
                                  render={() => <ManageRewardsNav />}
                                />
                              )
                          }
                           {
                              (featureFlags && featureFlags.sales === true) && (
                                <Route
                                  exact
                                  path="/dashboard/sales"
                                  render={props => <ManageSalesNav  {...props}/>}
                                />
                              )
                          }
                          {
                              (featureFlags && (featureFlags.departments === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/departments"
                                  render={props => (
                                    <ManageDepartmentsNav {...props} />
                                  )}
                                />
                              )
                          }
                          {
                              (featureFlags && (featureFlags.groups === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/groups"
                                  render={props => (
                                    <ManageGroupsNav {...props} />
                                  )}
                                />
                              )
                          }
                          <Route
                            exact
                            path="/dashboard/company/:id/invitations"
                            render={props => (
                              <ManageInvitationsNav {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/invitesuggestions"
                            render={props => (
                              <InviteSuggestionsNav {...props} />
                            )}
                          />

                          <Route
                            exact
                            path="/dashboard/company/:id/connectionsuggestions"
                            render={props => (
                              <ConnectionSuggestionsNav {...props} />
                            )}
                          />
                          <Route
                            path="/dashboard/search"
                            render={() => (
                              <SearchNav extraPadding={extraPadding} />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/legal"
                            render={() => <LegalNav />}
                          />
                          <Route render={() => <NotFoundNav />} />
                        </AbsoluteDiv>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </LaptopNavWrapper>
                <LaptopContentWrapper
                  className="main-content"
                  innerRef={div => (this.mainContentLaptop = div)}
                  style={{ "marginTop": window.location.pathname.toString().search("search") > 0 ? "80px" : "0px" }}
                >
                  <AbsoluteDiv
                    left={`${extraPadding}px`}
                    right={`${extraPadding}px`}
                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={this.props.location.key}
                        classNames="laptop-main"
                        timeout={100}
                      >
                        <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                          {/* <Box height="30px" /> */}
                          <Switch location={this.props.location}>
                            <Route
                              exact
                              path="/"
                              render={
                                props =>
                                  <Feed
                                    user={user}
                                    {...props} />
                              }
                            />
                            <Route
                              exact
                              path="/dashboard"
                              render={
                                props =>
                                  <Feed
                                    user={user}
                                    {...props} />
                              }
                            />
                            <Route
                              exact
                              path="/dashboard/posts/edit/:id"
                              render={props => <AddPostMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/posts/duplicate/:id"
                              render={props => <AddPostMain {...props} />}
                            />
                            <Route
                              path="/dashboard/posts/new"
                              render={props => <AddPostMain
                                {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/edit/:id"
                              render={props => (
                                <EditProfileMain user={user} {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/edit/:id"
                              render={() => <EditCompanyMain />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/asset/new"
                              render={() => <AssetCompanyMain />}
                            />
                            <Route
                              exact
                              path="/dashboard/post/:id"
                              render={props => <PostDetailMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/:id"
                              render={props => (
                                <ProfileDetailMain
                                key={`pdmain3`+props.location.pathname}
                                user={user || {}} 
                                
                                systemSettings={systemSettings}
                                companySettings={companySettings}
                                {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/profile/:id?search"
                              render={props => (
                                <ProfileDetailMain 
                                key={`pdmain4`+props.location.pathname}
                                user={user || {}} 
                                
                                systemSettings={systemSettings}
                                companySettings={companySettings}
                                {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id"
                              render={props => <CompanyDetailMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/employees"
                              render={props => (
                                <ManageEmployeesMain
                                  {...props}
                                />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/vendors"
                              render={props => <ManageVendorsMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/systemsettings"
                              render={props => <ManageSystemSettingsMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/systemreports"
                              render={props => <ManageSystemReportsMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/settings"
                              render={props => <ManageSettingsMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/incentives/new"
                              render={props => <ManageIncentiveMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/incentives/edit/:id"
                              render={props => <ManageIncentiveMain {...props} />}
                            />
                            <Route
                              exact
                              path="/dashboard/incentives/duplicate/:id"
                              render={props => <ManageIncentiveMain {...props} />}
                            />                
                            {console.log(featureFlags,"1787featureFlags  src/components/dashboard/index.js")}
                            {
                              (featureFlags && featureFlags.rewards === true) && (
                                <Route
                                  exact
                                  path="/dashboard/rewards"
                                  render={props => <ManageRewardsMain {...props} />}
                                />
                              )
                            }
                            {
                              (featureFlags && featureFlags.sales === true) && (
                                <Route
                                  exact
                                  path="/dashboard/sales"
                                  render={props => <ManageSalesMain {...props} />}
                                />
                              )
                            }
                            <Route
                              exact
                              path="/dashboard/incentive/:id"
                              key={window.location.pathname.toString().substring(19)}
                              render={props => <IncentiveDetail 
                                key={`incd3`+props.location.pathname}
                                {...props} />}
                            />
                            {
                              (featureFlags && (featureFlags.departments === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/departments"
                                  render={props => (
                                    <ManageDepartmentsMain {...props} />
                                  )}
                                />
                              )
                            }
                            {
                              (featureFlags && (featureFlags.groups === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/groups"
                                  render={props => (
                                    <ManageGroupsMain {...props} />
                                  )}
                                />
                              )
                            }
                            <Route
                              exact
                              path="/dashboard/company/:id/invitations"
                              render={props => (
                                <ManageInvitationsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/invitesuggestions"
                              render={props => (
                                <InviteSuggestionsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/company/:id/connectionsuggestions"
                              render={props => (
                                <ConnectionSuggestionsMain {...props} />
                              )}
                            />
                            <Route
                              exact
                              path="/dashboard/search"
                              render={() => <SearchMain />}
                            />
                            <Route render={props => <NotFoundMain {...props} />} />

                          </Switch>
                        </AbsoluteDiv>
                      </CSSTransition>
                    </TransitionGroup>
                  </AbsoluteDiv>
                </LaptopContentWrapper>
              </LaptopContentColumn>
              <LaptopAccessoryColumn id="LACColumn">
                <AbsoluteDiv
                  left={`${extraPadding}px`}
                  right={`${extraPadding}px`}

                >
                  <Box height="30px" />
                  <TransitionGroup>
                    <CSSTransition
                      key={this.props.location.key}
                      classNames="laptop-accessory"
                      timeout={100}
                    >
                      <Switch location={this.props.location}>
                        <AbsoluteDiv left={0} right={0} bottom={0} top={0}>
                          <Route
                            exact
                            path="/dashboard"
                            render={props => <FeedAccessory {...props} />}
                          />
                          <Route
                            path="/dashboard/posts/duplicate/:id"
                            render={() => <AddPostAccessory />}
                          />
                          <Route
                            path="/dashboard/posts/new"
                            render={() => <AddPostAccessory />}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/:id"
                            render={props => (
                              <ProfileDetailAccessory {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/:id?search"
                            render={props => (
                              <ProfileDetailAccessory {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/profile/edit/:id"
                            render={() => <EditProfileAccessory user={user} />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/edit/:id"
                            render={() => <EditCompanyAccessory />}
                          />
                          <Route
                            path="/dashboard/post/:id"
                            render={() => <PostDetailAccessory
                              post={post}
                              user={user}
                              redirect={redirect}
                            />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id"
                            render={props => (
                              <CompanyDetailAccessory {...props} />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/employees"
                            render={props => (  <ManageEmployeesAccessory
                              {...props}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/vendors"
                            render={() => <ManageVendorsAccessory />}
                          />
                           {
                              (featureFlags && (featureFlags.departments === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/departments"
                                  render={() => <ManageDepartmentsAccessory />}
                                />
                              )
                            }
                           {
                              (featureFlags && (featureFlags.groups === true)) && (
                                <Route
                                  exact
                                  path="/dashboard/company/:id/groups"
                                  render={() => <ManageGroupsAccessory />}
                                />
                              )
                            }
                          
                          <Route
                            exact
                            path="/dashboard/incentives/new"
                            render={props => <ManageIncentiveAccessory {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/incentives/duplicate/:id"
                            render={props => <ManageIncentiveAccessory {...props} />}
                          />
                          {/* <Route
                            exact
                            path="/dashboard/incentives/edit/:id"
                            render={props => <ManageIncentiveAccessory {...props} />}
                          /> */}
                            {console.log(featureFlags,"1958featureFlags  src/components/dashboard/index.js")}

                            {
                              (featureFlags && featureFlags.rewards === true) && (
                                <Route
                                  exact
                                  path="/dashboard/rewards"
                                  render={() => <ManageRewardsAccessory />}
                                />
                              )
                            }
                            {
                              (featureFlags && featureFlags.sales === true) && (
                                <Route
                                  exact
                                  path="/dashboard/sales"
                                  render={() => <ManageSalesAccessory />}
                                />
                              )
                            }
                          <Route
                            exact
                            path="/dashboard/incentive/:id"
                            render={props => <IncentiveDetailAccessory 
                              key={`incd4`+props.location.pathname}
                              {...props} />}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/invitations"
                            render={(props) => <ManageInvitationsAccessory  {...props}/>}
                          />
                          <Route
                            exact
                            path="/dashboard/company/:id/invitesuggestions"
                            render={() => <InviteSuggestionsAccessory />}
                          />
                          <Route render={() => <NotFoundAccessory />} />
                        </AbsoluteDiv>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </AbsoluteDiv>
              </LaptopAccessoryColumn>
            </GridStyled>
          )}

        </Box>
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    browser: state.browser,
    dashboardPresentation: state.dashboardPresentation,
    post: state.resources.detail.post,
    user: state.resources.detail.user,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
  }),
  dispatch => ({
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    fetchSystemSettings: bindActionCreators(actions.fetchSystemsetting, dispatch),
    fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
    fetchFeatureFlags: bindActionCreators(actions.fetchFeatureflag, dispatch),
    fetchInviteSuggestions: bindActionCreators(actions.fetchInvitesuggestions, dispatch),
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
    redirect: bindActionCreators(push, dispatch)
  })
)(Dashboard);
