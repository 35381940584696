import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
//utils
import theme from "./utils/theme";
import { getUser } from "./utils/auth";
//components
import Auth from "./components/auth";
import AuthLogin from "./components/auth/login";
import AuthRegister from "./components/auth/register";
import CompanyType from "./components/auth/company-type";
import AccountType from "./components/auth/account-type";
import AuthStart from "./components/auth/start";
import Connection from "./components/auth/connection";
import Industry from "./components/auth/industry";
import Goal from "./components/auth/goal";
import Company from "./components/auth/company";
import Dashboard from "./components/dashboard";
import EmailVerification from "./components/auth/email-verification";
import LegalNav from "./components/auth/forms/legal";
import { CompanyRedirect } from "./components/dashboard/company/redirect"
import { MobileLegalNav } from "./components/dashboard/legal/mobileIndex";
import NotFound from './components/dashboard/notfound';
// import IntercomApp from "./components/dashboard/intercom";

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        React.createElement(layout, rest, React.createElement(component, rest))
      }
    />
  );
};

export const router = (props) => {
  // const { url } = useRouteMatch();
  return (
    <ThemeProvider theme={theme}>
      <div id="kickpost-root">
        <Switch>
        <RouteWithLayout
          layout={Auth}
          exact
          component={AuthLogin}
          path="/"
        />
        <RouteWithLayout
          layout={Auth}
          exact
          component={AuthRegister}
          // step={1}
          path={"/signup/?:referral_code"}
        />
        <RouteWithLayout
          layout={Auth}
          component={AuthRegister}
          // step={1}
          path="/signup/"
        />
        <RouteWithLayout
          layout={Auth}
          component={AuthRegister}
          // step={1}
          path="/signup"
        />  
        <RouteWithLayout
          layout={Auth}
          component={AuthStart}
          // step={1}
          path="/start/"
        />
        <RouteWithLayout
          layout={Auth}
          component={AuthStart}
          // step={1}
          path="/start"
        />  
        <RouteWithLayout
          layout={Auth}
          component={Goal}
          
          // step={1}
          path="/goal/"
          back="/company"
        />
        <RouteWithLayout
          layout={Auth}
          component={Goal}
          // step={1}
          path="/goal"
          back="/company"
        />   
        <RouteWithLayout
          layout={Auth}
          component={CompanyType}
          // step={1}
          path="/company-type/"
          back="/signup"
        />
        <RouteWithLayout
          layout={Auth}
          component={CompanyType}
          // step={1}
          path="/company-type"
          back="/signup"
        />     
        <RouteWithLayout
          layout={Auth}
          component={Industry}
          // step={1}
          path="/industry/"
          back="/company-type"
        />
        <RouteWithLayout
          layout={Auth}
          component={Industry}
          // step={1}
          path="/industry"
          back="/company-type"
        />   
               
        <RouteWithLayout
          layout={Auth}
          component={Connection}
          // step={1}
          path="/connection/"
          back="/industry"
        />
        <RouteWithLayout
          layout={Auth}
          component={Connection}
          // step={1}
          path="/connection"
          back="/industry"
        />
        <RouteWithLayout
          layout={Auth}          
          component={AccountType}
          step={1}
          path="/account-type"
          back="/signup"
        />
        <RouteWithLayout
          layout={Auth}
          
          component={Company}
          step={getUser() && (getUser().company_id || getUser().is_single_user_company) ? 1 : 2}
          path="/individual"
          back="/account-type"

        />
        <RouteWithLayout
          layout={Auth}
          
          component={Company}
          step={getUser() && (getUser().company_id || getUser().is_single_user_company) ? 1 : 2}
          path="/company"
          back="/company-type"

        />
        <RouteWithLayout
          layout={Auth}
          
          component={Company}
          step={getUser() && (getUser().company_id || getUser().is_single_user_company) ? 1 : 2}
          path="/company/"
          back="/company-type"

        />
        <RouteWithLayout
          step={getUser() && (getUser().company_id || getUser().is_single_user_company) ? 2 : 3}
          layout={Auth}
          
          component={EmailVerification}
          path="/email-verification"
          back="/company"
        />
        <Route
          render={({ location }) => <Dashboard location={location} />}
          path={"/dashboard/profile/:user_id?search"}
        />
        <Route
          path="/dashboard/incentive"
          render={({ location }) => <Dashboard location={location} />}
        />
        <Route
          path="/dashboard"
          render={({ location }) => <Dashboard location={location} />}
        />
        <Route
          path="/dashboard/"
          render={({ location }) => <Dashboard location={location} />}
        />
        <Route
          path="/legal"
          // component={LegalNav}
          render={() => <LegalNav />}
        />
        <Route
          path="/privacy"
          // component={LegalNav}
          render={() => <LegalNav />}
        />
        <Route
          path="/mlegal"
          // component={MobileLegalNav}
          render={() => <MobileLegalNav />}

        />
        <RouteWithLayout
          layout={Auth}         
          component={EmailVerification}
          path="/login-verification"
        />
        <RouteWithLayout
          layout={Auth}         
          component={EmailVerification}
          path="/login-verification/?:token"
        />

        <Route
          path="/c"
          render={() => <CompanyRedirect />}
        />

        <Route 
          render={() => <NotFound />}
        />
        </Switch>

        {/* <Route
        path='/login'
        >
          <Redirect to="/" />
        </Route> */}

        {/* {
          process.env.REACT_APP_NODE_ENV === "production"
          ?
          <IntercomApp
          appUser={getUser() || {}}
          ></IntercomApp>
          :
          null
        }
      */}


      </div>
    </ThemeProvider>
  );
}

export default router;
