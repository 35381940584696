import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit, reset } from "redux-form";
import _ from "underscore";
import moment from "moment";

import AWS from "aws-sdk";
import axios from "axios";
//utils
// import { getUser } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
import { error } from "../../../actions/alerts";
//components
import { Eyebrow } from "../../common/forms";
import { Box } from "../../common/flexbox";
import ExistingAttribute from "./forms/brands-existing-attribute";
import Attribute from "./forms/brands-attribute";

export class BrandAttributes extends Component {

    state = {
        file: null,
        thePic: ''
    }

    

    _onSubmit = async data => {
        const {
            createBrand,
            fetchCompany,
            extraParams,
            notifyError,
            resetForm,
            companyId,
            list
        } = this.props;

        const { file } = this.state;
        
        if(!data.name || !data.url) return;

        data.name = data.name.trim();
        data.url = data.url.trim();

        if(list.some(item=> item.name.toLowerCase()==data.name.toLowerCase())){
            return notifyError("This name already exists.");
        }else if(list.some(item=> item.url.toLowerCase()==data.url.toLowerCase())){
            return notifyError("This url already exists.");
        }

        let payload = {
            ...data,
            ...extraParams
        };

        if (file) {
            
            AWS.config.update({
              accessKeyId: process.env.REACT_APP_AWS_KEY,
              secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
              region: "us-east-1"
            });
      
      
            const s3Bucket = new AWS.S3({
              params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
            });

            let data_name = data.name;
            data_name = data_name.replace(/\s/g, '');
            data_name = data_name.replace(',', '');
            data_name = data_name.replace('.', '');
            const fileKey = `brand-logos/${moment().valueOf()}${data_name}`;
            let s3Data = {
              Key: fileKey,
              Body: file,
              ContentType: file.type,
              ACL: "public-read"
            };
      
            await s3Bucket.putObject(s3Data).promise();

            payload.logo = `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`
          }

        await createBrand(payload);
        resetForm('brand-form');
        resetForm(`profile-existing-attribute-Brands`);

        this.setState({file: null, thePic: ''});
        fetchCompany(companyId, {
            edit: true
        });
    };

    onCrop = file => {

        const { notifyError } = this.props;

        this.setState({ file: file, thePic: file.preview });

        if (!this._checkExtentsion(file.name)) {
            //window.alert("bad file");
            notifyError(
                "This file doesn't work. It must be a png or a jpg."
            );
        }
    }

    _checkExtentsion(filename) {
        let ext = filename.split('.').pop();
        ext = ext.toString().toLowerCase();
        if (ext === "png" || ext === "jpg" || ext === "jpeg") {
            return true;
        }
        return false;
    }

    _removeAttribute = async id => {
        const { updateBrand, fetchCompany, companyId } = this.props;
        await updateBrand(id, { status: "inactive" });
        fetchCompany(companyId, { edit: true });
    };

    render() {
        const {
            dispatch,
            list,
            maxlengths,
            notifyError
        } = this.props;

        if (list && list.length) {
            let rows = list.map(
                (brandAttribute, i) => {
                    return (
                        <Box mb="34px" key={i}>
                            <ExistingAttribute
                                id={brandAttribute.id}
                                logo={brandAttribute.logo}
                                name={brandAttribute.name}
                                form={`profile-existing-attribute-Brands-${i}`}
                                initialValues={brandAttribute}
                                clickHandler={() => this._removeAttribute(brandAttribute.id)}
                            />
                        </Box>
                    );
                }
            );

            return (
                <div>
                    <Eyebrow ml={["5px", "5px"]}>BRANDS</Eyebrow>
                    {rows}
                    <Attribute
                        file={this.state.file}
                        thePic={this.state.thePic}
                        notifyError={notifyError}
                        onCrop={this.onCrop}
                        form={`profile-existing-attribute-Brands`}
                        maxlengths={maxlengths}
                        onSubmit={this._onSubmit}
                        clickHandler={() =>
                            dispatch(submit(`profile-existing-attribute-Brands`))
                        }
                    />
                </div>
            );
        }

        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Eyebrow ml={["5px", "5px"]}>BRANDS</Eyebrow>
                    
                </div>
                <Attribute
                    file={this.state.file}
                    thePic={this.state.thePic}
                    notifyError={notifyError}
                    onCrop={this.onCrop}
                    form={`profile-existing-attribute-Brands`}
                    maxlengths={maxlengths}
                    onSubmit={this._onSubmit}
                    clickHandler={() =>
                        dispatch(submit(`profile-existing-attribute-Brands`))
                    }
                />
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        dispatch,
        actions: bindActionCreators(actions, dispatch),
        createBrand: bindActionCreators(actions.createBrand, dispatch),
        updateBrand: bindActionCreators(actions.patchBrand, dispatch),
        fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
        notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
        resetForm: bindActionCreators(reset, dispatch)
    })
)(BrandAttributes);
