const defaultState = {
  audienceSearchResults: null,
  companySearchResults: [],
  companySearchRows: [],
  companySearchResultsTotal: 0,
  mobileSearchResults: [],
  mobileSearchResultsTotal: 0,
  productSearchResults: [],
  productSearchResultsTotal: 0,
  productCategorySearchResults: [],
  productCategorySearchResultsTotal: 0,
  personSearchResults: [],
  employeeSearchResults: [],
  departmentSearchResults: [],
  departmentSearchRows: [],
  groupSearchResults: [],
  groupSearchRows: [],
  newsFeedSearchResults: [],
  newsFeedSearchResultsCurrentPage: 0,
  newsFeedSearchResultsTotalPages: null,
  postSearchResults: [],
  postSearchResultsCurrentPage: 0,
  postSearchResultsTotalPages: null,
  assetSearchResults: [],
  assetSearchResultsCurrentPage: 0,
  assetSearchResultsTotalPages: null,
  incentiveSearchResults: [],
  incentiveSearchResultsCurrentPage: 0,
  incentiveSearchResultsTotalPages: null,
  searchCompanies: true,
  searchConnectedCompanies: true,
  searchNotConnectedCompanies: true,
  searchPendingCompanies: true,
  searchPosts: true,
  searchUsers: true,
  searchIncentives: true,
  selectedRows: [],
  userSearchResults: [],
  userSearchRows: [],
  userSearchResultsTotal: 0
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "CLEAR_AUDIENCE_RESULTS":
      newState.audienceSearchResults = [];
      return newState;
    case "CLEAR_COMPANY_RESULTS":
      newState.companySearchResults = [];
      return newState;
    case "CLEAR_TAG_RESULTS":
      newState.tagSearchResults = [];
      return newState;
    case "CLEAR_USER_RESULTS":
      newState.userSearchResults = [];
      return newState;
    case "SET_AUDIENCE_RESULTS":
      newState.audienceSearchResults = action.payload;
      return newState;
    case "SET_COMPANY_RESULTS":
      newState.companySearchResultsTotal = action.payload.nbHits;
      newState.companySearchResults = action.payload.hits;
      return newState;
    case "SET_MOBILE_RESULTS":
      newState.mobileSearchResultsTotal = action.payloadnbHits;
      newState.mobileSearchResults = action.payload.hits;
      return newState;
    case "SET_PRODUCT_RESULTS":
      newState.productSearchResultsTotal = action.payloadnbHits;
      newState.productSearchResults = action.payload.hits;
      return newState;
    case "SET_PRODUCT_GROUP_RESULTS":
      newState.productGroupSearchResultsTotal = action.payload.nbHits;
      newState.productGroupSearchResults = action.payload.hits;
      return newState;
    case "SET_PRODUCT_CATEGORY_RESULTS":
      newState.productCategorySearchResultsTotal = action.payload.nbHits;
      newState.productCategorySearchResults = action.payload.hits;
      return newState;
    case "SET_COMPANY_ROWS":
      newState.companySearchRows = action.payload;
      return newState;
    case "SET_DEPARTMENT_RESULTS":
      newState.departmentSearchResults = action.payload;
      return newState;
    case "SET_DEPARTMENT_ROWS":
      newState.departmentSearchRows = action.payload;
      return newState;
    case "SET_GROUP_RESULTS":
      newState.groupSearchResults = action.payload;
      return newState;
    case "SET_GROUP_ROWS":
      newState.groupSearchRows = action.payload;
      return newState;
    case "SET_PERSON_RESULTS":
      newState.personSearchResults = action.payload;
      return newState;
    case "SET_EMPLOYEE_RESULTS":
      newState.employeeSearchResults = action.payload;
      return newState;
    case "SET_POST_RESULTS":
      newState.postSearchResultsTotalPages = action.payload.nbPages;
      newState.postSearchResultsCurrentPage = action.payload.page;
      newState.postSearchResults = action.payload.hits;
      return newState;
    case "SET_ASSET_RESULTS":
      newState.assetSearchResultsTotalPages = action.payload.nbPages;
      newState.assetSearchResultsCurrentPage = action.payload.page;
      newState.assetSearchResults = action.payload.hits;
      return newState;          
    case "SET_NEWSFEED_RESULTS":
      newState.newsFeedSearchResultsTotalPages = action.payload.nbPages;
      newState.newsFeedSearchResultsCurrentPage = action.payload.page;
      newState.newsFeedSearchResults = action.payload.hits;
      return newState;
    case "SET_INCENTIVE_RESULTS":
      newState.incentiveSearchResultsTotalPages = action.payload.nbPages;
      newState.incentiveSearchResultsCurrentPage = action.payload.page;
      newState.incentiveSearchResults = action.payload.hits;
      return newState;
    case "SET_SEARCH_COMPANY":
      newState[action.payload.company] = action.payload.value;
      return newState;
    case "SET_SEARCH_CATEGORY":
      newState[action.payload.category] = action.payload.value;
      return newState;
    case "SET_SEARCH_COMPANY":
      newState[action.payload.company] = action.payload.company;
      return newState;
    case "SET_SEARCH_TAGS":
      newState.tagSearchResults = action.payload;
      return newState;
    case "SET_SELECTED_SEARCH_TAGS":
      newState.selectedTags = action.payload;
      return newState;
    case "SET_TAG_RESULTS":
      newState.tagSearchResults = action.payload;
      return newState;
    case "SET_USER_RESULTS":
      newState.userSearchResults = action.payload.hits;
      newState.userSearchResultsTotal = action.payload.nbHits;
      

      return newState;
    case "SET_USER_ROWS":
      newState.userSearchRows = action.payload;
      return newState;
    default:
      return state;
  }
};