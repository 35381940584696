import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <FontAwesomeIcon
            icon={faBadgeCheck}
            size="10x"
            color="#428BF4"
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    Make sure your industry partners can find you.
                </span>
                {` Let everyone know what company you’re with by confirming or adding your company name now.`}
            </NunitoSans>

        </Flex>
);