import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NunitoSansBold, NunitoSansSemiBold } from "../common/typography";
import { Flex } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";
import { actions } from "../../actions/resources";
import { success } from "../../actions/alerts";
import { setUpgradeModalOpen } from "../../actions/rule";
import { faRocketLaunch } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-thin-svg-icons";
import { getUser } from "../../utils/auth";
import StripeCheckoutButton from "../common/stripeCheckoutButton";

export class UpgradeModal extends Component {
  _handleCloseRequest = () => {
    this.props.setModalOpen(false);
  };

  _handleUpgradeRequest = async () => {
    await this.props.updateCompany(getUser().company_id, { paid: true });
    await this.props.updateSettings(getUser().company_id, {
      plan_type: "premium",
    });
    await this.props.fetchFeatureFlags(getUser().company_id);
    this.props.notifySuccess("Your account has been upgraded to Premium.");
    this._handleCloseRequest();
  };

  render() {
    const { open, companySettings, browser } = this.props;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: browser.lessThan.large ? "50%" : "800px",
        minHeight: "342px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: "1px solid #707070",
        borderRadius: "10px",
        background: "#F8F8F8",
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88",
      },
    };

    const isVendor = companySettings.company_type === "Vendor";

    const vendorUpgradeItems = [
      "Unlimited Posting",
      "Create Sales Incentives",
      "Create Partner Incentives",
      "Access Sales Reporting",
      "Automated Reward Management",
      "KickPost University",
    ];

    const resellerUpgradeItems = [
      "Unlimited Posting",
      "Receive Sales Incentives",
      "Receive Partner Incentives",
      "Share Sales Reporting",
      "Automated Reward Management",
      "KickPost University",
    ];

    const upgradeItems = isVendor ? vendorUpgradeItems : resellerUpgradeItems;

    return (
      <Modal
        isOpen={open}
        onRequestClose={() => this._handleCloseRequest()}
        style={customStyles}
        contentLabel={`Upgrade Modal`}
        ariaHideApp={false}
      >
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSansSemiBold
            fontSize="14px"
            color="#428BF4"
            textAlign="center"
            style={{ marginTop: "20px", marginBottom: "25px" }}
          >
            Ready to upgrade your account?
          </NunitoSansSemiBold>

          <Flex
            width="500px"
            flexDirection="column"
            justifyContent="center"
            style={{
              padding: "40px",
              border: "1px solid #979797",
              background: "white",
              marginBottom: "20px",
            }}
          >
            <Flex justifyContent="center" alignItems="center">
              <FontAwesomeIcon
                icon={faRocketLaunch}
                color="#428BF4"
                size="lg"
              />

              <NunitoSansSemiBold
                fontSize="24px"
                color="#428BF4"
                textAlign="center"
                style={{ marginLeft: "20px" }}
              >
                PREMIUM
              </NunitoSansSemiBold>
            </Flex>

            <NunitoSansSemiBold
              fontSize="18px"
              color="#000000"
              textAlign="center"
              style={{ marginTop: "20px", marginBottom: "30px" }}
            >
             {
              isVendor
              ?
              "Unlock the full potential of KickPost. Choose between a monthly or yearly subscription now:"
              :
              "Make More Sales Together!"
             }
            </NunitoSansSemiBold>

            <Flex flexDirection="column">
              <NunitoSansSemiBold
                fontSize="18px"
                color="#000000"
                style={{ marginBottom: "10px" }}
              >
                Basic, plus:
              </NunitoSansSemiBold>

              {upgradeItems.map((item, i) => (
                <Flex alignItems="center" mb="20px" key={i}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    color="#428BF4"
                    size="lg"
                  />

                  <NunitoSansSemiBold
                    fontSize="18px"
                    color="#000000"
                    textAlign="center"
                    style={{ marginLeft: "20px" }}
                  >
                    {item}
                  </NunitoSansSemiBold>
                </Flex>
              ))}
              <div
                style={{
                  borderBottom: "1px solid #979797",
                }}
              />
              {isVendor ? (
                <Flex
                  style={{
                    marginTop: "30px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  justifyContent="space-between"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                  >
                    <NunitoSansBold fontSize="24px" color="#428BF4">
                      Monthly
                    </NunitoSansBold>

                    <StripeCheckoutButton
                      mt="20px"
                      fontSize="16px"
                      width="80%"
                      color="#428BF4"
                    >
                      Get Premium
                    </StripeCheckoutButton>
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                    style={{ borderLeft: "1px solid #979797" }}
                  >
                    <NunitoSansBold fontSize="24px" color="#428BF4">
                      Yearly
                    </NunitoSansBold>

                    <StripeCheckoutButton
                      mt="20px"
                      fontSize="16px"
                      width="80%"
                      color="#428BF4"
                      annual={true}
                    >
                      Get Premium
                    </StripeCheckoutButton>
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Flex
                    style={{ marginTop: "30px", marginBottom: "10px" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NunitoSansBold fontSize="48px" color="#428BF4">
                      $0
                    </NunitoSansBold>

                    <NunitoSansSemiBold
                      fontSize="21px"
                      color="#428BF4"
                      style={{ marginLeft: "5px" }}
                    >
                      /mo
                    </NunitoSansSemiBold>
                  </Flex>

                  <NunitoSansSemiBold
                    fontSize="28px"
                    color="#428BF4"
                    textAlign="center"
                  >
                    Yup, It’s still free!
                  </NunitoSansSemiBold>
                  <DashboardSmallCTA
                    width="100%"
                    fontSize="16px"
                    color="#428BF4"
                    mt="30px"
                    onClick={() => this._handleUpgradeRequest()}
                  >
                    Get Premium
                  </DashboardSmallCTA>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    browser: state.browser,
    companySettings: state.resources.detail.companysetting,
  }),
  (dispatch) => ({
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
    fetchFeatureFlags: bindActionCreators(actions.fetchFeatureflag, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
  })
)(UpgradeModal);
