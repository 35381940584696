import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import moment from "moment";
import AWS from "aws-sdk";
// import ReactTooltip from 'react-tooltip';
// import Dropzone from "react-dropzone";
// import Modal from "react-modal";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { setInvitationId } from "../../../actions/invitations";
//components
import { Flex, Box } from "../../common/flexbox";
import { InviteVendorCTA, RecommendedContentWrapper, CreateDepartmentCTA, EditDepartmentCTA } from "../../common/ui";
// import { NunitoSans,  H1CondensedSmallD0D0D0 } from "../../common/typography";
// import { DashboardSmallCTA, renderSmallField } from "../../common/forms";

import { EMAIL_REGEX, NON_BIZ_REGEX } from "../../../constants/app";

import CsvDropzoneModal from "../../common/csvDropzoneModal";
// import { CSVLink, CSVDownload } from "react-csv";


// import LoadingSpinner from "../../common/loading";


export class InviteSuggestionsAccessory extends Component {
  //constructor(props) {
  //  super(props);
//    this.state = { search: null };
   // this._search = _.debounce(this._search.bind(this), 400);
  //}
  state = {
    uploading : false,
    csvDropOpen: false,
    uploadHistory: []
  } 

componentDidMount(){
  this._fetchDependencies();
}


_fetchDependencies = async () => {
    const { fetchCsvuploads, fetchCompany, company } = this.props;

    
    fetchCompany(getUser().company_id)
    

    fetchCsvuploads({company_id: getUser().company_id, type:'vendor_invite'}).then(res=>this.setState({uploadHistory: res.payload.filter((a, index)=>index<6)}));

  }



  UNSAFE_componentWillReceiveProps(nextProps) {
    
    /*
    const resultsDiff = _.difference(
      nextProps.companyResults,
      this.props.companyResults
    );

    if (resultsDiff > 0) {
      return this._fetchConnections();
    }
    */
  }

  _fetchConnections = () => {
    /*
    const { companyResults, fetchConnections } = this.props;
    fetchConnections({
      company_id: getUser().company_id,
      ids: _.pluck(companyResults, "id")
    });
    */
  };

  _fetchDependencies = async () => {
    //const { searchVendors } = this.props;
    //searchVendors();
    //this._fetchConnections();
  };


  /*_createDepartment = async data => {
    const { createDepartment, fetchDepartments, resetForm } = this.props;
    await createDepartment({
      ...data,
      ...{ company_id: getUser().company_id, user_id: getUser().id }
    });
    resetForm("create-department");
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active"
    });
  };

  _updateDepartment = async data => {
    const {
      departments,
      departmentId,
      fetchDepartments,
      resetForm,
      setInvitationId,
      updateDepartment
    } = this.props;

    await updateDepartment(_.findWhere(departments, { id: departmentId }).id, {
      name: data.name
    });
    setInvitationId(null);
    resetForm("edit-department");
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active"
    });
  };*/

  
  _onSubmit = async data => {
    const {
      people,
      searchedPeople, 
      fetchPersons,
      createConnection,
      createUser,
      resetForm,
      notifyError,
      notifySuccess,
      company,
      companySettings
    } = this.props;

    let inviteeEmails = people.map(item=> item.person.email);
    

    let LeEmail = data.email;
    LeEmail = LeEmail.toString().trim().toLowerCase();
    data.email = LeEmail;
 
    let userPayload = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      status: "invited_company",
      inviter: {
        ...getUser(),
        company_name: company.name
      }
    };

    let getUserEmail = getUser().email.split("@")[1];
    let userPayloadEmail = userPayload.email.split("@")[1];

   
    if(inviteeEmails.includes(userPayload.email.toString().toLowerCase()) ){
      notifyError(
        "You have already invited this person, to remind them click the RESEND button below."
      );
      resetForm("create-vendor");
    } else if (
      (!companySettings.is_single_user_company && userPayloadEmail != undefined )
      ||
      (companySettings.is_single_user_company && getUser().email !== userPayload.email && userPayloadEmail != undefined )
    ) {
      //check if the email is already associated with another user
      const activeEmail = await fetch(`${process.env.REACT_APP_API_URL}/emailcheck/${data.email}`).then(res=>res.json());

      if(activeEmail && activeEmail.id){
        return notifyError("This email is already associated with another user in the system.")
      }

      const user = await createUser(userPayload);

      if (user && user.payload) {
       // console.log(user);
        let result = await createConnection({
          vendor_id: getUser().company_id,//maye hard-code this to KickPost
          company_id: getUser().company_id,//getUser().company_id,
          user_id: getUser().id,
          invitee_id: user.payload.invited_company_user.id,
          status: "invited",
          inviteMessage: data.message
        });
        
        if(typeof result !== "undefined" && result.payload.status==="invited"){
          notifySuccess(
            "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
          );
          fetchPersons({"id": getUser().id});
        } else {
          notifyError(
            "We could not invite this person. Please try again later."
          );
        }
      }

      
    }


    resetForm("create-vendor");
  };

  
  _uploadVendorCsv = (files, inviteMessage='') => {
    const { createBatchInvite, notifySuccess, notifyError, company } = this.props;

    this.setState({uploading: true});

    let file = files[0];

    if(typeof file === "undefined"){
      this.setState({uploading: false});

      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    if(!inviteMessage || inviteMessage.trim().length===0){
      this.setState({uploading: false});
      return notifyError('INVITE MESSAGE REQUIRED.');
    }else if(inviteMessage.trim().length>300){
      this.setState({uploading: false});
      return notifyError('Invite message cannot be more than 300 characters.');
    }

    

   AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: "us-east-1"
  });

    const s3Bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });
    const fileKey = `csv-vendor-uploads/${moment().valueOf()}${
      getUser().company_id
    }`;
    let s3Data = {
      Key: fileKey,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    };

    return s3Bucket.putObject(s3Data, async (err, response) => {
      const bi = await createBatchInvite({
        user_id: getUser().id,
        company_id: getUser().company_id,
        profile_pic: `<img  width="30%" height="30%" src=${getUser().avatar}>`,
        url: fileKey,
        invite_type: "vendor",
        status: "created",
        inviter: {
          ...getUser(),
          company_name: company.name
        },
        inviteMessage
      });

      if (bi.payload.id) {
        this.setState({uploading: false});

        notifySuccess(
          "We will email you when we have finished processing your upload."
        );
      }
    });
  };

  validate = values => {
    const errors = {};
    if (!values.company_name) {
    //  errors.company_name = "Company name required";
    }

    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    let re = new RegExp(EMAIL_REGEX, "i");

    if (!re.test(values["email"])) {
      errors["email"] = "Not a valid email";
    }

    let re2 = new RegExp(NON_BIZ_REGEX, "i");

    if (re2.test(values["email"])) {
      errors["email"] = "Only Work Emails";
    }

    if (!values.message || !values.message.trim()) {
      errors.message = "Message required";
    }else if(values.message.trim().length>300){
      errors.message = "Message cannot be more than 300 characters.";
    }

    return errors;
  };

  _handleCloseModal = ()=>this.setState({csvDropOpen: false, uploading: false})

  _handleCsvUpload = () => { this.setState({ csvDropOpen: true}) };

  render() {
    const { departments, 
      departmentId, 
      setInvitationId,     
      people,
      searchedPeople,
      company
  } = this.props;

  const {uploading, csvDropOpen, uploadHistory} = this.state;

  console.log(company)

  
  const headers = [
    {label: 'WORK EMAIL', key:'work_email'},
    {label: 'FIRST NAME', key:'first_name'},
    {label: 'LAST NAME', key:'last_name'}
  ]

    /*if (departmentId) {
      return (
        <Flex flexDirection="column">
          <Box height="103px" />
          <EditDepartmentCTA
            initialValues={{
              name: _.findWhere(departments, { id: departmentId }).name
            }}
            setInvitationId={() => setInvitationId(null)}
            onSubmit={this._updateDepartment}
          />
        </Flex>
      );
    }*/
//below is for the inner page after clicking 'manage invites' in connections page//
    return (      
      <Flex flexDirection="column">
        <Box height="76px" width={["100%"]} />
        <InviteVendorCTA onSubmit={this._onSubmit} validate={this.validate} handleCsvUpload={this._handleCsvUpload} initialValues={{message: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales."}} />
        <Box height="40px"  />
        {/* <Box height="78px" width={["100%"]} data-tip="Drag and Drop File Here">
          <NonDottedDropzone
          uploading={this.state.uploading}         
            text="Drag and Drop CSV file here"
            secondText="(work email, first name, last name)"
            thirdText="Maximum 5,000 invites per upload."
            type="csv"
            onDrop={this._uploadVendorCsv}
          />
        </Box>
        {
          this.state.uploading ? null : <ReactTooltip />
        }
        
        <Box height="40px" />
        <Box height="78px" width={["100%"]}>
          <NonDottedDownloadVendorTemplate 
            text="Download CSV Template"
          />
        </Box> */}
        {/* <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="14px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Uploads</H1CondensedSmallD0D0D0>
            </Box>
            </Flex>
            
          <Flex pt="19px" flexDirection="column" px="1%">
          
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="50%"
                onClick={() => {
                  this.setState({ csvDropOpen: true})
                }}
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                UPLOAD INVITES
              </DashboardSmallCTA>
              
              
            </Flex>
          </Flex>
        </Flex>
        
      </RecommendedContentWrapper> */}

      <CsvDropzoneModal
        modalOpen={csvDropOpen}
        handleCloseRequest={this._handleCloseModal}
        handleCsvUpload={uploading ? ()=>{} : this._uploadVendorCsv}
        csvName={"kickpost invite"}
        uploading={uploading}
        csvErrors={[]}
        csvHeaders={headers}
        uploadHistory={uploadHistory}
        />
        <Box height="40px" />
      
      </Flex>
    );
  }
}

export default connect(
  state => ({
    departments: state.resources.collection.department,
    departmentId: state.departments.departmentId,
    people: state.resources.collection.person || [],
    searchedPeople: state.search.personSearchResults || [],
    companySettings: state.resources.detail.companysetting,
    company: state.resources.detail.company,
  }),
  dispatch => ({
    setInvitationId: bindActionCreators(setInvitationId, dispatch),
    createDepartment: bindActionCreators(actions.createDepartment, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    fetchCsvuploads: bindActionCreators(actions.fetchCsvuploads, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createBatchInvite: bindActionCreators(actions.createBatch_invite, dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchPersons: bindActionCreators(actions.fetchPersons, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)//,
    //updateDepartment: bindActionCreators(actions.patchDepartment, dispatch)
  })
)(InviteSuggestionsAccessory);
