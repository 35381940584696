import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import _ from "underscore";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
import {
  searchConnectionCompanies,
  searchPosts,
  searchIncentives,
  searchConnectionUsers,
  toggleCategory
} from "../../../actions/search";
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";

//components
import { InputStyled, SwitchInput, DashboardSmallToggleCTA } from "../../common/forms";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { BackButton } from "../../common/backbutton";
import {
  SearchFilterFlag,
  GradientOverflowDownwardLaptop,
  NavNotificationBubble
} from "../../common/ui";
import QuestionModalTrigger from "../../common/questionModalTrigger";
import InfoModal from "../../common/InfoModal";
import connectionsLogo from "../../../images/connections_info_logo.png";

class SearchNav extends Component {
  state = {
    _isMounted: false,
    searchValue: sessionStorage.getItem('searchValue') ? sessionStorage.getItem('searchValue') : (decodeURI(window.location.href.split("?")[1]) !== "undefined") ? decodeURI(window.location.href.split("?")[1]) : "",
    vendor_ids: [],
    posts: {},
    browser: {},
    connections: {},
    searchCompanies: {},
    searchPosts: {},
    searchUsers: {},
    user: {},
    pendingChecked: false,
    selectedCategory: 'all',
    count: 0
  }

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.state._isMounted = false;
    sessionStorage.setItem('searchValue', this.state.searchValue);
    this._toggleCategory('all');
    this.props.change('search_switch', false);
  }

  componentDidUpdate(nextProps) {
    console.log(this.props, nextProps, "componentDidUpdate");
    if (nextProps.connections.length) {
      // document.getElementById("searchInput").focus(); 
      // document.getElementById("searchInput").select();
    }
  }

  //REVIEW THIS DEBOUNCE - APPLY AUGUSRT 22
  componentDidMount() {
    this._search = _.debounce(this._search, 300);
    this._fetchDependencies();
    this.state._isMounted = true;
    if (this.state._isMounted) {
      // 5 secs delay added to approval switch to prevent immediate action on it which was causing wrong posts to be rendered
      setTimeout(() => this.setState({ approvalSwitchEnabled: true }), 5000);
    }
    document.getElementById("searchInput").focus(); 
    document.getElementById("searchInput").select();
  }

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }

  _fetchDependencies = async () => {
    const { fetchUser, fetchConnections, companySettings } = this.props;
    await fetchUser(getUser().id, { edit: true });

    let connections;
    this._connections(fetchConnections)
      .then(() => {
//        console.log("_fetchDependencies this.props", this.props);
        connections = this.props.connections;
//        console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table

        let Vendor_ids = [];
        if (connections) {
          connections.forEach(reorder);
          // connections.payload.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              Vendor_ids.push(v.vendor_id);
            } else {
              Vendor_ids.push(v.company_id);
            }
          }
          this.setState({ vendor_ids: Vendor_ids });
        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });
      this._search("");
  };

  _clearSearch = () => {
    const { toggleCategory } = this.props;

    this.setState({ searchValue: "" });

    // const filters = [
    //   "searchCompanies",
    //   "searchPosts",
    //   "searchIncentives", 
    //   "searchUsers"
    // ];

    // filters.forEach((filter, i) => {
    //   if (!this.props[filter]) {
    //     toggleCategory(filter, true);
    //   }
    // });

    this._search("");
  };

  _search = value => {
    if (value.length < 2) {
      if (value.length != 0) {
        return;
      }
    }
    const { searchC, searchP, searchU, searchI, user, fetchCompany, incentives, posts, companySettings, systemSettings } = this.props;
    const { vendor_ids, pendingChecked } = this.state;

    const filters = ["searchCompanies", "searchPosts", "searchUsers", "searchIncentives"];
    const searchFunctions = {
      searchCompanies: searchC,
      searchPosts: searchP,
      searchUsers: searchU,
      searchIncentives: (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) ? searchI : () => { }
    };

    filters.forEach(filter => {
      if (this.props[filter]) {
        if (filter === "searchCompanies") {
          return searchFunctions[filter](value, true);
          //return searchFunctions[filter](value, user.company.paid);
        }

        if (filter === "searchPosts") {
          if (!vendor_ids.length) return;
          return searchFunctions[filter](vendor_ids, user, value, 0, pendingChecked, systemSettings);
        }

        if (filter === "searchIncentives") {
          if (!vendor_ids.length || !systemSettings.rewards_active || (!companySettings.receive_incentives && !companySettings.receive_vendor_incentives && !companySettings.create_incentives && !companySettings.create_vendor_incentives)) return;
          return searchFunctions[filter](vendor_ids, user, value, 0, companySettings, pendingChecked, systemSettings);
        }

        if (filter === "searchUsers") {
          return searchFunctions[filter](value, null, true);
          //return searchFunctions[filter](value, null, user.company.paid);
        }

        // if (filter === "searchIncentives") {
        //   return searchFunctions[filter](value, null, true);
        //   //return searchFunctions[filter](value, null, user.company.paid);
        // }
        searchFunctions[filter](value);
      }
    });
  };

  _toggleCategory = category => {

    const { toggleCategory, searchUsers, searchCompanies, searchPosts, searchIncentives } = this.props;
    const { searchValue, count } = this.state;

    const filters = [
      "searchCompanies",
      "searchPosts",
      "searchIncentives",
      "searchUsers"
    ];

    // this.setState({selectedCategory: category});

    filters.forEach(filter => {
      toggleCategory(filter, category === 'all' ? true : filter === category);
    })

    if (category !== 'all') {
      this._search(searchValue);
    }


    // if(count===0 && searchCompanies && searchPosts && searchUsers && searchIncentives){
    //   this.setState({count: 1});

    //   const filters = [
    //     "searchCompanies",
    //     "searchPosts",
    //     "searchIncentives",
    //     "searchUsers"
    //   ];

    //   filters.forEach(filter => {
    //     if(category !== filter){
    //       toggleCategory(filter, !this.props[filter]);
    //     }
    //   })
    // }else{

    //   toggleCategory(category, !this.props[category]);
    //   if (!this.props[category] === true) {
    //     this._search(searchValue);
    //   }
    // }


  };

  _updateSearchInput = value => {
    this.setState({ searchValue: value });
    this._search(value);
  };

  _pendingSwitchOnChange = () => {

    const { pendingChecked } = this.state;
    const { toggleCategory, systemSettings } = this.props;

    const filters = [
      "searchCompanies",
      "searchPosts",
      "searchTags",
      "searchIncentives",
      "searchUsers"
    ];

    if (pendingChecked) {
      // pending approvals being turned off
      this._clearSearch();

    } else {
      // pending approvals being turned on

      filters.forEach((filter, i) => {
        toggleCategory(filter, (filter === 'searchPosts' && systemSettings.post_approval) || (filter === 'searchIncentives' && systemSettings.incentive_approval));
        this._search('');
      });

    }

    this.setState({ pendingChecked: !pendingChecked });

  }

  render() {
    const {
      browser,
      extraPadding,
      searchCompanies,
      searchPosts,
      searchUsers,
      searchIncentives,
      user,
      posts,
      systemSettings,
      companySettings,
      notificationCounts
    } = this.props;
    const { searchValue, vendor_ids, pendingChecked, approvalSwitchEnabled, selectedCategory } = this.state;

    const allSelected = searchPosts && searchIncentives && searchCompanies && searchUsers;

    return (

      <Flex
        flexDirection="column"
      >



        <Flex
          height={"100%"}
          width={"100%"}
          alignItems="flex-end"
          justifyContent="center"
          mt="12px"
        >

          <AbsoluteDiv
            zIndex={10000}
            left={["0px", "0px", "0px", "-30px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
            mt="20px"
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
            >
              <BackButton />
            </Flex>
          </AbsoluteDiv>
          <Flex
            mx={["33px", "0px"]}
            height={["49px", "49px", "60px", "60px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >

            <Flex position="relative" width={["100%", "83%", "83%", "100%"]} alignItems="center">
              <AbsoluteDiv
                display={["block"]}
                top={["6px", "6px"]}
                width={["40px"]}
              >
                <IconBox
                  fontSize={["18px", "18px"]}
                  color="#c3c3c3"
                >
                  search
                </IconBox>

              </AbsoluteDiv>
              <InputStyled
                key={"searchInput"}
                id="searchInput"
                placeholder="Search Your KickPost Network"
                innerRef={input => (this.searchInput = input)}
                style={{ paddingLeft: '45px' }}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray", "lightGray"]}
                borderRadius={browser.lessThan.small ? "11px" : "11px"}
                // pt={["3px", "3px", 0]}
                px={["5px", "5px"]}
                mt={["3px", "0px"]}
                height={[31, 40, 40, 40]}
                // fontWeight={browser.lessThan.small ? 400 : 400}
                fontSize={[14, 20, 20, 20]}
                lineHeight={1.5}
                color={browser.lessThan.small ? "#000000" : "#000000"}
                borderBottom={[0, 0]}
                onChange={e => this._updateSearchInput(e.target.value)}
                value={searchValue || ""}
                vendor_ids={vendor_ids || []}
              />
              <QuestionModalTrigger modalName='search_modal' />
              <InfoModal
                modalName='search_modal'  //no spaces. Once set right do not change
                modalVersion="1.00" //set it to 1.00 initially then change it when needed
                image={connectionsLogo}
                title='Search Your Network'
                text="Search lets you find Posts, Incentives, People, or Companies that are part of your network. Looking for more? Managers and account owners can grow the Network on the Connections page!"
                extraTexts={["Need help? Watch our tutorial video. "]}
                videoLink='https://youtu.be/_XKwC8fhZ78'
              />
              {
                searchValue && searchValue.length
                  ?
                  <AbsoluteDiv
                    display={["block"]}
                    right={["35px"]}

                    top={["6px", "6px"]}
                    width={["40px"]}
                  >
                    <ClickCursorFlex
                      height={["100%", "100%", "100%"]}
                      alignItems={["center"]}
                      onClick={this._clearSearch}
                      justifyContent={[
                        "flex-start",
                        "flex-start",
                        "flex-start",
                        "center"
                      ]}
                    >
                      <IconBox
                        // pl={["15px"]}
                        // pb={["15px"]}
                        // pt={["15px"]}
                        fontSize={["17px", "17px"]}
                        color="#c3c3c3"
                      >
                        thinx
                      </IconBox>
                    </ClickCursorFlex>
                  </AbsoluteDiv>
                  :
                  null
              }

            </Flex>
            {/* <AbsoluteDiv
            zIndex={1000}
            bottom={["-55px", "-90px"]}
            left={`-${extraPadding ? extraPadding : 200}px`}
            right={`-${extraPadding ? extraPadding : 200}px`}
            height={["55px", "90px"]}
          >
            <GradientOverflowDownwardLaptop height="100%" width="100%" />
          </AbsoluteDiv> */}
            {/* <AbsoluteDiv
            zIndex={1000}
            bottom={["-35px", "-70px"]}
            left={`-${extraPadding ? extraPadding : 200}px`}
            right={`-${extraPadding ? extraPadding : 200}px`}
            height={["35px", "70px"]}
          >
            <GradientOverflowDownwardLaptop height="100%" width="100%" />
          </AbsoluteDiv> */}


            {/* <AbsoluteDiv
            zIndex={10000}
            bottom={["-25px", "-35px", "-52px", "-52px"]}
            left={["5px", "8.5%", "8.5%", "0px"]}
            right={["5px", "8.5%", "8.5%", "0px"]}
            height={["25px", "35px", "40px", "45px"]}
          >
            <Flex
              height={["25px", "35px", "35px", "45px"]}
              width={"100%"}
              position="relative"
              alignItems="flex-end"
              justifyContent={["center", "center", "flex-start"]}
              >
                <DashboardSmallToggleCTA
                >
                  COMPANIES
                </DashboardSmallToggleCTA>
              {(user && user.status === "pending") || (pendingChecked && !systemSettings.post_approval) ? null : (
                <Box
                  mr="26px"
                  onClick={() => this._toggleCategory("searchPosts")}
                >
                  <SearchFilterFlag
                    mobile={browser.lessThan.large}
                    active={searchPosts}
                  >
                    POSTS
                  </SearchFilterFlag>
                </Box>
              )}
              {(user && user.status === "pending") || pendingChecked ? null : (
                <Box
                  mr="26px"
                  onClick={() => this._toggleCategory("searchCompanies")}
                >
                  <SearchFilterFlag
                    mobile={browser.lessThan.large}
                    active={searchCompanies}
                  >
                    COMPANIES
                  </SearchFilterFlag>
                </Box>
              )}
              {(user && user.status === "pending") || pendingChecked ? null : (
                <Box
                mr="26px"
                onClick={() => this._toggleCategory("searchUsers")}>
                  <SearchFilterFlag
                    mobile={browser.lessThan.large}
                    active={searchUsers}
                  >
                    PEOPLE
                  </SearchFilterFlag>
                </Box>
              )}
              {(user && user.status === "pending") || !systemSettings.rewards_active || (!companySettings.create_incentives && !companySettings.receive_vendor_incentives && !companySettings.receive_incentives && !companySettings.create_vendor_incentives) || (pendingChecked && !systemSettings.incentive_approval) ? null : (
                <Box onClick={() => this._toggleCategory("searchIncentives")}>
                  <SearchFilterFlag
                    mobile={browser.lessThan.large}
                    active={searchIncentives}
                  >
                    INCENTIVES
                  </SearchFilterFlag>
                </Box>
              )}
            </Flex>
          </AbsoluteDiv> */}

            {/* {
            (systemSettings.post_approval || systemSettings.incentive_approval) && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin
            ?
            <AbsoluteDiv
            zIndex={10000}
            bottom={["-25px", "-35px", "-62px", "-62px"]}
            
            right={["5px", "8.5%", "8.5%", "0px"]}
            height={["25px", "35px", "40px", "45px"]}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              
              >

              <form id='pending-switch'>
              <Field
              name="search_switch"
              type="checkmark"
              component={SwitchInput}
              onColor={"#428BF4"}
              offColor='#D3D3D3'
              height={25}
              width={48}
              onChange={()=>this._pendingSwitchOnChange()}
              disabled={!approvalSwitchEnabled}
              />
              </form>

              
              <Flex
              position="relative"
              flexDirection="column"
              style={{marginLeft:"5px", marginBottom: '5px'}}
              >
               
              <NunitoSans
              fontSize="12px"
              fontWeight="bold"
              >
                Approvals
              </NunitoSans>

              {
                notificationCounts.search
                ?
                <NavNotificationBubble
                top="-5px"
                left="55px"
                >
                  {notificationCounts.search}
                </NavNotificationBubble>
                :
                null
              }

              </Flex>
              
              </Flex>
          </AbsoluteDiv>
            :
            null
          } */}
          </Flex>
        </Flex>
        {
          user && user.status === "pending"
            ?
            null
            :
            <Flex
              style={{ marginTop: '15px' }}
            >
              <Flex
                flexWrap="wrap"
              >
                <Flex
                // style={{paddingRight:'15px', borderRight: '1px solid #A3A99E'}}
                // style={{paddingRight:'15px'}}
                >
                  <DashboardSmallToggleCTA
                    selected={allSelected}
                    onClick={() => this._toggleCategory("all")}
                    fontSize={["12px", "12px", "17px", "17px"]}
                  >
                    ALL
                  </DashboardSmallToggleCTA>
                  <div style={{ marginLeft: '15px', borderRight: '1px solid #A3A99E', maxHeight: '32px' }} />
                </Flex>
                {
                  pendingChecked && !systemSettings.post_approval
                    ?
                    null
                    :

                    <DashboardSmallToggleCTA
                      style={{ marginLeft: '15px', marginRight: '15px', marginBottom: '5px' }}
                      selected={searchPosts && !allSelected}
                      onClick={() => this._toggleCategory("searchPosts")}
                      fontSize={["12px", "12px", "17px", "17px"]}
                    >
                      POSTS
                    </DashboardSmallToggleCTA>

                }

                {
                  !systemSettings.rewards_active || (!companySettings.create_incentives && !companySettings.receive_vendor_incentives && !companySettings.receive_incentives && !companySettings.create_vendor_incentives) || (pendingChecked && !systemSettings.incentive_approval)
                    ?
                    null
                    :

                    <DashboardSmallToggleCTA
                      style={{ marginRight: '15px', marginBottom: '5px' }}
                      selected={searchIncentives && !allSelected}
                      onClick={() => this._toggleCategory("searchIncentives")}
                      fontSize={["12px", "12px", "17px", "17px"]}
                    >
                      INCENTIVES
                    </DashboardSmallToggleCTA>
                }

                {
                  pendingChecked
                    ?
                    null
                    :
                    <DashboardSmallToggleCTA
                      style={{ marginRight: '15px', marginBottom: '5px' }}
                      selected={searchUsers && !allSelected}
                      onClick={() => this._toggleCategory("searchUsers")}
                      fontSize={["12px", "12px", "17px", "17px"]}
                    >
                      PEOPLE
                    </DashboardSmallToggleCTA>
                }

                {
                  pendingChecked
                    ?
                    null
                    :
                    <DashboardSmallToggleCTA
                      style={{ marginRight: '15px', marginBottom: '5px' }}
                      selected={searchCompanies && !allSelected}
                      onClick={() => this._toggleCategory("searchCompanies")}
                      fontSize={["12px", "12px", "17px", "17px"]}
                    >
                      COMPANIES
                    </DashboardSmallToggleCTA>
                }



              </Flex>

              {
                (systemSettings.post_approval || systemSettings.incentive_approval) && getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin && !browser.lessThan.small
                  ?
                  <Flex

                    // alignItems="center"

                    ml='auto'
                  >

                    <form id='pending-switch'>
                      <Field
                        key={"search_switch"}
                        name="search_switch"
                        type="checkmark"
                        component={SwitchInput}
                        onColor={"#428BF4"}
                        offColor='#D3D3D3'
                        height={25}
                        width={48}
                        onChange={() => this._pendingSwitchOnChange()}
                        disabled={!approvalSwitchEnabled}
                      />
                    </form>


                    <Flex
                      position="relative"
                      flexDirection="column"

                      style={{ marginLeft: "5px", marginBottom: '5px' }}
                    >

                      <NunitoSans
                        fontSize="12px"
                        fontWeight="bold"
                      >
                        Approvals
                      </NunitoSans>

                      {
                        notificationCounts.search
                          ?
                          <NavNotificationBubble
                            top="-5px"
                            left="55px"
                          >
                            {notificationCounts.search}
                          </NavNotificationBubble>
                          :
                          null
                      }

                    </Flex>

                  </Flex>
                  :
                  null
              }

            </Flex>

        }
      </Flex>

    );
  }
}

SearchNav = reduxForm({
  form: "pending-switch"
})(SearchNav);

export default connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    connections: state.resources.collection.connection || [],
    searchCompanies: state.search.searchCompanies,
    searchPosts: state.search.searchPosts,
    searchUsers: state.search.searchUsers,
    searchIncentives: state.search.searchIncentives,
    user: state.resources.detail.user,
    posts: state.search.postSearchResults,
    incentives: state.search.incentiveSearchResults,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    searchC: bindActionCreators(searchConnectionCompanies, dispatch),
    searchP: bindActionCreators(searchPosts, dispatch),
    searchI: bindActionCreators(searchIncentives, dispatch),
    searchU: bindActionCreators(searchConnectionUsers, dispatch),
    toggleCategory: bindActionCreators(toggleCategory, dispatch)
  })
)(SearchNav);
