import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import _ from "underscore";
import moment from "moment";
import { success, error } from "../../../actions/alerts";
import { Box, Flex } from "../../common/flexbox";
import { getAccessory, getUser, setAccessory } from "../../../utils/auth";
import {
  ProfileCompletion,
  RecommendedContentCTA,
  SystemNotifications,
  ReferralsAccessory,
  TeamRewardsAccessory,
  IncentivesAccessory,
  SalesAccessory,
  InvoicesAccessory,
  BillingCTA,
  RewardsCTA
} from "../../common/ui";

import { actions } from "../../../actions/resources";
import { history } from "../../../store";

export class FeedAccessory extends Component {
  state = {
    dismissAccessory: false,
    dismissProfileAccessory: false,
    dismissSystemNoticationsAccessory: true,
    dismissRewardsAccessory: false,
    notificationsResults: [],
    bonusTransactions: {
      referrals: [],
      others: []
    },
    rewardAccessory: {
      company: {
        pendingTotal: 0,
        lastMonthPaidTotal: 0,
        paidTotalYtd: 0
      },
      personal: {
        pendingTotal: 0,
        lastMonthPaidTotal: 0,
        paidTotalYtd: 0,
        stripeAccountNeeded: false
      }
    }
  };

  componentDidMount(){
    this._fetchDependencies();
  }

  
  _fetchDependencies = async () => {
    const { fetchPosts, fetchRewards, systemSettings, companySettings} = this.props;
    this._checkStripeConnect();
    if(getAccessory()){
      if(getAccessory().post){
        this.setState({dismissAccessory: getAccessory().post});
      }

      if(getAccessory().rewards){
        this.setState({dismissRewardsAccessory: getAccessory().rewards});
      }

      if(getAccessory().profile){
        this.setState({dismissProfileAccessory: getAccessory().profile});
      }
    }

    let notifications = await fetch(`${process.env.REACT_APP_API_URL}/newnotifications/${getUser().id}`).then(res=>res.json());

    if(!getUser().company_admin){
      notifications = notifications.filter(not => not.action!=="new_company");
    }
    
    // const theCompany = await fetchCompany(getUser().company_id).then(res=>res.payload);

    if(getUser().company_admin && systemSettings.rewards_active && companySettings.receive_incentives){
      // insert upload sales notification

      const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

      const TODAY = new Date();

      let deadline=new Date();

      if(TODAY.getDate() < systemSettings.statement_dates[MONTHS[TODAY.getMonth()]]){
            
        deadline.setDate(systemSettings.statement_dates[MONTHS[TODAY.getMonth()]]-1)
              
      }else{
              
        deadline.setMonth(TODAY.getMonth()+1);
            
        deadline.setDate(systemSettings.statement_dates[MONTHS[deadline.getMonth()]]-1);
              
        }

      const newNotification = {
        action: 'upcoming_sale_deadline',
        name: deadline.getDate()===new Date().getDate() ? "TODAY" : `in ${moment(deadline).diff(moment(), 'days')} days`,
        created_at: Date.now()
      };

      notifications.unshift(newNotification);
    }
              {/* 
              company/d3e67319-27db-4449-bb2b-7dbadb9a8880?companies
              company/${user.company.id}/vendors
              */}
    notifications.forEach(notification => {
      if(notification.action === "connection_request_accept" || notification.action === "new_connection_request" || notification.action === "connection_request_decline" || notification.action === "connection_removed"){
        notification.url = `dashboard/company/${getUser().company_id}/vendors?${notification.name.split('at')[0].trim()}`;
      }else if(notification.action === "new_company"){
        notification.url = `dashboard/company/${notification.company_id}?companies`;
      }else if(notification.action === "new_user"){
        notification.url = `dashboard/profile/${notification.user_id}`;
      }else if (notification.action ==="user_pending"){
        notification.url = `dashboard/company/${getUser().company_id}/employees?${notification.variable}`;
      }

    });

    if(companySettings.company_type !== "Vendor"){

      const bonusTransactionsRaw = await fetch(`${process.env.REACT_APP_API_URL}/bonusTransactions/${getUser().id}`).then(res=>res.json());
  
      const bonusTransactions = {
        referrals: [],
        others: []
      };
  
      bonusTransactionsRaw.forEach(bonus => bonusTransactions[bonus.referred_company_id ? "referrals" : "others"].push(bonus));

      this.setState({bonusTransactions});
    }


    this.setState({notificationsResults: notifications});

    await fetchPosts({ user_id: getUser().id, status: "active" });

    const rewards = await fetch(`${process.env.REACT_APP_API_URL}/rewards?user_id=${getUser().id}&rewardsAccessory=true`).then(res=>res.json());
    this.setState({rewardAccessory: {
      company: {
        pendingTotal: (rewards && rewards.company && rewards.company.pendingTotal) || 0,
        lastMonthPaidTotal: (rewards && rewards.company && rewards.company.lastMonthPaidTotal) || 0,
        paidTotalYtd: (rewards && rewards.company && rewards.company.paidTotalYtd) || 0,
      },
      personal: {
        pendingTotal: (rewards && rewards.personal && rewards.personal.pendingTotal) || 0,
        lastMonthPaidTotal: (rewards && rewards.personal && rewards.personal.lastMonthPaidTotal) || 0,
        paidTotalYtd: (rewards && rewards.personal && rewards.personal.paidTotalYtd) || 0,
        stripeAccountNeeded: rewards && rewards.personal && rewards.personal.stripeAccountNeeded
      }
    }})
  };

  _calculateCompletion = user => {
    let activeNumbers = []
if(user.phone_numbers.length){
   activeNumbers = user.phone_numbers.filter(number => number.status === 'active')

}
    let a = [
      !user.avatar || user.avatar==='https://kickpost-files.s3.amazonaws.com/profile-photos/default_profile.jpg' ? true : false,
      activeNumbers.length && user.phone_numbers.length? false : true,
      user.position ? false : true
    ];

    let b = _.filter(a, Boolean);

    let multiplier = 33;

    return (a.length - b.length) * multiplier;
  };

  _checkStripeConnect = async () => {
    const { notifyError, notifySuccess, updateUser, fetchUser} = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const stateStripe = urlParams.get('state');
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    if (stateStripe && (code || error)) {
      history.replace(window.location.pathname);
    }

    if (stateStripe === getUser().id && code && !error) {
      const response = await updateUser(getUser().id, { stripe_connect_code: code });
      fetchUser(getUser().id)
      if (response.payload.stripe_connect_id) {
        notifySuccess(`You can now get paid!`)
      }
      if (response.payload.stripeStatus) {
        notifyError(response.payload.stripeStatus.message)
      }
    } else if (error) {
      notifyError(`${error} There was a problem with Stripe Connect. Please try again.`)
    }
  }


  render() {
    const { redirect, user, posts, rewards, loading, incentives, systemSettings, companySettings } = this.props;
    const { dismissSystemNoticationsAccessory, notificationsResults, bonusTransactions, rewardAccessory } = this.state;


    if (user && user.phone_numbers && posts && !loading) {
      
     
      let activeNumbers=[];
      if(user.phone_numbers.length){
activeNumbers= user.phone_numbers.filter(number => number.status === 'active');
      }

      // const LEVEL_NAMES = {
      //   newbie: 'Newbie',
      //   prospect: 'Prospect',
      //   rookie: 'Rookie',
      //   starter: 'Starter',
      //   allstar: 'Allstar',
      //   franchise: 'Franchise',
      //   goat: 'G.O.A.T.'
      // };

      return (
        <Flex flexDirection="column"  mt="20px" style={{height: "95vh", overflowY: "auto", overflowX: "hidden", paddingLeft: "5px", paddingRight: "25px", paddingTop: "5px"}}>
            <div>

              {systemSettings.rewards_active && companySettings && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && (getUser().sales_id || getUser().company_admin)?
             
                <TeamRewardsAccessory
                user={user}
                redirect={redirect}
                rewards={rewardAccessory}
                systemSettings={systemSettings}
                companySettings={companySettings}
                />
              
              :
              null}

              {companySettings.company_type !== "Vendor" && (<Box pb="36px">
                <ReferralsAccessory
                  referrals={bonusTransactions.referrals}
                  otherBonuses={bonusTransactions.others}
                  user={getUser()}
                  stripeConnectId={user.stripe_connect_id}
                />
              </Box>)}
              
              <Box pb="36px">
                <SystemNotifications
                  closeHandler={() => {
                    //setAccessory({ post: false });
                    this.setState({ dismissSystemNoticationsAccessory: dismissSystemNoticationsAccessory ? false : true });
                  }}
                  show={dismissSystemNoticationsAccessory}
                  message={notificationsResults}
                  companyLogoActive={!user.company.logo}
                  linkedInActive={!user.linkedin}
                  percentComplete={this._calculateCompletion(user)}
                  profilePictureActive={!user.avatar}
                  profilePhoneNumberActive={user.phone_numbers.length ? !user.phone_numbers.filter(number => number.status === 'active').length : !user.phone_numbers.length}
                  isCompanyAdmin={user.company_admin}
                  user={user}
                  redirect={redirect}
                />
              </Box>
             
              {systemSettings.rewards_active && companySettings && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && incentives.length ?
              <Box pb="36px">
                <IncentivesAccessory
                  incentives={incentives}
                  redirect={redirect}
                  currency={systemSettings.currency}
                />
              </Box>
              :
              null
              }
              {/* {systemSettings.rewards_active && companySettings && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && sales.length && (getUser().sales_id || getUser().company_admin)?
              <>
              <Box pb="36px">
                <SalesAccessory
                  sales={sales}
                  redirect={redirect}
                  currency={systemSettings.currency}
                />
              </Box>
              </>
              :
              null
              } */}
              {/* {systemSettings.rewards_active && companySettings && (companySettings.create_incentives || companySettings.create_vendor_incentives) && getUser().company_admin && invoices.length ?
              <>
              <Box pb="36px">
                <InvoicesAccessory
                  invoices={invoices}
                  redirect={redirect}
                  currency={systemSettings.currency}
                />
              </Box>
              </>
              :
              null} */}
              
            </div>
          {user ? (
            <div>
              <Box height="10px" />
              {user.position &&
              activeNumbers.length &&
              (user.avatar && user.avatar!=='https://kickpost-files.s3.amazonaws.com/profile-photos/default_profile.jpg') ? null : (
                <Box pb="36px">
                  <ProfileCompletion
                  dismissHandler={()=>{
                    let payload={};
                    if(getAccessory()){
                      payload = getAccessory();
                    }
                    payload.profile = true;
                    setAccessory(payload);
                    this.setState({dismissProfileAccessory: true})
                    console.log(getAccessory())
                  }}
                    companyLogoActive={!user.company.logo}
                    linkedInActive={!user.linkedin}
                    percentComplete={this._calculateCompletion(user)}
                    profilePictureActive={!user.avatar || user.avatar==='https://kickpost-files.s3.amazonaws.com/profile-photos/default_profile.jpg'}
                    profilePosition={!user.position}
                    profilePhoneNumberActive={user.phone_numbers.length ? !user.phone_numbers.filter(number => number.status === 'active').length : !user.phone_numbers.length}
                    isCompanyAdmin={user.company_admin}
                    user={user}
                    redirect={redirect}
                  />
                </Box>
              )}
              
            </div>
          ) : null}
        </Flex>
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    notifications: state.resources.collection.notification || [],
    posts: state.search.postSearchResults || [],
    incentives: state.search.incentiveSearchResults || [],
    userPosts: state.resources.collection.post || [],
    sales: state.resources.collection.sale || [],
    rewards: state.resources.collection.reward || [],
    invoices: state.resources.collection.invoice || [],
    user: state.resources.detail.user,
    loading: state.resources.detail.loading.user
  }),
  dispatch => ({
    fetchNotifications: bindActionCreators(actions.fetchNotifications, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchPosts: bindActionCreators(actions.fetchPosts, dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch),
    fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch)
  })
)(FeedAccessory);
