import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../store";
// import moment from "moment";


//utils
import { getUser, dismissSuggestionDuringSession } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
//actions
import {
  searchCompanyPosts,
  searchCompanyAssets,
  searchCompanyIncentives,
  searchCompanyUsers
} from "../../../actions/search";
//components
import { Flex, ClickCursorFlex, ClickCursorFlexHover } from "../../common/flexbox";
import {
  OutlineFlex,
  DashboardSmallCTA,
  renderSmallField
} from "../../common/forms";
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";
import { AbsoluteDiv } from "../../common/absolute";
import { BackButton } from "../../common/backbutton";
import CompanyDetail from "../../dashboard/company/detail/index";
//images
import { defaultCompany } from "../../../utils/defaultLogos";

import QuestionModalTrigger from "../../common/questionModalTrigger";


import InfoModal from "../../common/InfoModal";

import connectionsLogo from "../../../images/connections_info_logo.png";


export class CompanyDetailMain extends Component {
  state = {
    pendingConnections:[],
    filteredIncentives: [],
    filteredPosts: [],
    isConnection: false,
    isRequestPending: false,
    connections: [],
    connectionsPending: [],
    companies: []
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }
  
  _connectionsPending = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'requested' });
  }


  _fetchDependencies = async () => {
    const { fetchCompany, fetchPosts, fetchIncentives, match, companySettings,fetchConnections, systemSettings, user } = this.props;

    if (match.params.id === getUser().company_id && companySettings.is_single_user_company) {
      return history.push("/dashboard");
    }

    let connections;
    // this._connections(fetchConnections)
    // await fetchConnections({ company_id: getUser().company_id, status: 'active' })
    await fetch(`${process.env.REACT_APP_API_URL}/connections?status=active&company_id=${getUser().company_id}`)
    .then((res) => res.json())
    .then((json) => {
        connections = json;
        
        // connections = this.props.connections;
        // console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table
        if(connections.length===0){
          connections = this.props.connections;
        }
        
        let Vendor_ids = [];
        // let isConnection = false;
        if (connections.length!==0) {
          connections.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              Vendor_ids.push(v.vendor_id);
            } else {
              Vendor_ids.push(v.company_id);
            }
          }

          // console.log("_fetchDependencies Vendor_ids", Vendor_ids);
          // this.setState({ vendor_ids: Vendor_ids });
          const isConnection = Vendor_ids.includes(getUser().company_id);
          // isConnection = Vendor_ids.includes(user.company_id);
          // console.log("_fetchDependencies isConnection", isConnection);
          //const isConnection = vendor_ids.includes(match.params.id);

          // console.log("src/components/dashboard/company/detail.js render isConnection", isConnection)

          if (!isConnection) {
            //check if a connection request pending

            const pendingConnections =  fetch(`${process.env.REACT_APP_API_URL}/connections?status=requested&company_id=${getUser().company_id}`)
            .then((res) => res.json())
            .then((json) => {
                // console.log(json)
                let pendingIds = [];
                let pendingConnections = json;
                
              // console.log("_fetchDependencies pendingConnections src/components/dashboard/company/detail/detail.js pendingConnections", pendingConnections);

                pendingConnections.forEach(item => {
                  if (item.company_id === getUser().company_id) {
                    pendingIds.push(item.vendor_id);
                  } else {
                    pendingIds.push(item.company_id);
                  }
                });
    
                this.setState({ isRequestPending: pendingIds.includes(match.params.id) })
    
    
              
              return json;
            });


          }

          // console.log("_fetchDependencies isConnection src/components/dashboard/company/detail/detail.js", isConnection);
    
          this.setState({ isConnection });

          fetchCompany(match.params.id, { detail: true });

          // if(isConnection){
          //   fetchPosts({ company_id: match.params.id, status: "active" });

          //   // who can receive incentives
          //   fetchIncentives({company_id: match.params.id})

          // }

          if (isConnection) {
            this._handleSearch('');
          }


        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });

      this._connectionsPending(fetchConnections)
      .then(() => {
        console.log("this.props",this.props)
      })
      .catch((e)=>{
        console.log("_fetchDependencies e", e);
      })




  };





  _createConnection = async () => {
    const { createConnection, match, fetchConnectionSuggestions } = this.props;

    // console.log("src/components/dashboard/company/detail.js _createConnection createConnection, match, fetchConnectionSuggestions", createConnection, match, fetchConnectionSuggestions);
    dismissSuggestionDuringSession(match.params.id);

    await createConnection({
      vendor_id: match.params.id,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchConnectionSuggestions({
      company_id: getUser().company_id
    });

    this._fetchDependencies();
  }


  _getCategory = (input,selectedCategory) => {
      console.log(input, selectedCategory, "_getCategory input company/detail.js")
    }


  _handleSearch = (input,selectedCategory2) => {
    const { searchCompanyPosts, searchCompanyAssets, searchCompanyIncentives, searchCompanyUsers, match, user, companySettings, systemSettings } = this.props;
    let { selectedCategory } = this.state;
    console.log(input , selectedCategory2, selectedCategory,"_handleSearch input company/detail.js")
    let contentx,selectedCategory3 = '';
    (typeof input === "string" ) ?  contentx = input : input = input.content;

    if(selectedCategory2 === undefined && input && input.category){
      selectedCategory3 = input.category;
    } else {
      selectedCategory3 = selectedCategory2;
    }


    if((selectedCategory3 !== "People")&&(selectedCategory3 !== "Incentives")&&(selectedCategory3 !== "Assets")&&(selectedCategory3 !== "Posts")){
      console.log(input, "input")
      console.log(selectedCategory3, "selectedCategory3")
    

        searchCompanyPosts({
          input,
          user,
          companyId: match.params.id
        });
        
        searchCompanyAssets({
          input,
          user,
          companyId: match.params.id
        });

        searchCompanyIncentives({
          input,
          user,
          companyId: match.params.id,
          companySettings,
          systemSettings
        });
        
        searchCompanyUsers({
          input,
          companyId: match.params.id
        });
    } else {
    
      if (selectedCategory3 === "Posts" ) {
        console.log(input, "input")
        console.log(selectedCategory3, "selectedCategory3")
            searchCompanyPosts({
              input,
            user,
            companyId: match.params.id
          });
        } else if (selectedCategory3 === "Incentives" ) {
          console.log(input, "input")
          console.log(selectedCategory3, "selectedCategory3")
              searchCompanyIncentives({
            input,
            user,
            companyId: match.params.id,
            companySettings,
            systemSettings
          });
        } else if (selectedCategory3 === "People" ) {
          console.log(input, "input")
          console.log(selectedCategory3, "selectedCategory3")
              searchCompanyUsers({
                input,
            companyId: match.params.id
          });
        } else if (selectedCategory3 === "Assets" ) {
          console.log(input, "input")
          console.log(selectedCategory3, "selectedCategory3")
              searchCompanyAssets({
            input,
            user,
            companyId: match.params.id
          });

        } 

    }

      
    


   
  }


  render() {

    const { company, assets, posts, incentives, users, match, user } = this.props;

    const { isConnection, isRequestPending } = this.state;
    // const { filteredIncentives, filteredPosts, isConnection, isRequestPending } = this.state;


    if (company && (company.id === match.params.id) && (user.id === getUser().id)) {
      localStorage.setItem("LeCompany",JSON.stringify(company)); 
      return (
        <CompanyDetail
          key={company.id}
          company={company}
          name={company.name}
          companyImage={company.logo || defaultCompany(company.id, company.name)}
          domain={company.domain}
          departments={company.departments}
          posts={posts}
          assets={assets}
          incentives={incentives}
          users={users}
          referral_code={company.referral_code||""}
          emails={company.email_addresses}
          websites={company.websites}
          phoneNumbers={company.phone_numbers}
          address={(company.location && company.location.address) || ""}
          placeName={(company.location && company.location.place_name) || null}
          text={(company.location && company.location.text) || ""}
          description={company.description}
          isConnection={isConnection}
          isRequestPending={isRequestPending}
          createConnection={this._createConnection}
          handleSearch={this._handleSearch}
          getCategory={this._getCategory}
        />
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    company: state.resources.detail.company,
    companySettings: state.resources.detail.companysetting,
    systemSettings: state.resources.detail.systemsetting,
    // posts: state.resources.collection.post || [],
    posts: state.search.postSearchResults,
    assets: state.search.assetSearchResults,
    incentives: state.search.incentiveSearchResults,
    users: state.search.userSearchResults,
    // incentives: state.resources.collection.incentive || [],
    connections: state.resources.collection.connection || [],
    user: state.resources.detail.user || [],
  }),
  dispatch => ({
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchPosts: bindActionCreators(actions.fetchPosts, dispatch),
    fetchIncentives: bindActionCreators(actions.fetchIncentives, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    searchCompanyAssets: bindActionCreators(searchCompanyAssets, dispatch),
    searchCompanyPosts: bindActionCreators(searchCompanyPosts, dispatch),
    searchCompanyIncentives: bindActionCreators(searchCompanyIncentives, dispatch),
    searchCompanyUsers: bindActionCreators(searchCompanyUsers, dispatch),
  })
)(CompanyDetailMain);

export const CompanyDetailNav = ({ ...props }) => (

  //key={`"divkey"+${props.match.params.id}`}

  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      {
        props.match.params.id === getUser().company_id ?
          <>
          <InfoModal                  
            key={0}
            modalName='my_company_modal'  //no spaces. Once set right do not change
            modalVersion="1.00" //set it to 1.00 initially then change it when needed
            image={connectionsLogo}
            title='Get Noticed'
            text='Complete your profile to make it easier for your Partner Network to recognize you! You can also sync your LinkedIn profile to quickly fill in details.'
            extraTexts={["Need help? Watch our tutorial video."]}
            videoLink='https://youtu.be/b7HOcaxPCTc'
          />
          <NunitoSans
            key={1}
            pt={["6px", "6px", "8px", "6px"]}
            fontSize={[14, 14, 18, 18]}
            fontWeight={["bold"]}
            justifyContent="center"
          >
            My Company
          </NunitoSans>
          <QuestionModalTrigger modalName='my_company_modal' />
        </>
          :
          null
      }
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton company={true} />
        </Flex>
      </AbsoluteDiv>
      {getUser().company_admin &&
        props.match.params.id === getUser().company_id ? (
        <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-end"]}
          >
            {/* <ClickCursorFlexHover 
                  justifyContent="center"
                  alignItems="center"
                  height={["30px", "30px", "40px"]}
                  width={["84px", "84px", "122px"]}
                  onClick={() =>
                    props.redirect(
                      `/dashboard/company/edit/${getUser().company_id}`
                    )
                  }
                  borderRadius="9px"
                  bg="whiteGray"
                  color="gray">
                  <NunitoSansExtraBold
                    pt={["2px", "4px"]}
                    fontSize={["17px", "17px", "17px"]}
                    fontWeight="normal"
                  >
                    EDIT
                  </NunitoSansExtraBold>
                </ClickCursorFlexHover> */}
            {/* <DashboardSmallCTA
                width="15%"
                onClick={() => props.redirect(
                  `/dashboard/company/${getUser().company_id}/asset/new`
                )}
                minWidth={["80px", "80px", "122px", "122px"]}
                fontSize={["12px", "12px", "17px", "17px"]}
                innerHeight={["30px", "30px", "40px", "40px"]}
                mr="2px"  // You can adjust the margin to space it out appropriately.
            >
                ADD ASSET
            </DashboardSmallCTA> */}
            <DashboardSmallCTA
                width="15%"
                onClick={() => props.redirect(
                  `/dashboard/company/${getUser().company_id}/asset/new`
                )}
                minWidth={["80px", "80px", "122px", "122px"]}
                fontSize={["12px", "12px", "17px", "17px"]}
                innerHeight={["30px", "30px", "40px", "40px"]}
                mr="2px"  // You can adjust the margin to space it out appropriately.
            >
                ADD ASSET
            </DashboardSmallCTA>
            <DashboardSmallCTA
              width="15%"
              onClick={() =>
                props.redirect(
                  `/dashboard/company/edit/${getUser().company_id}`
                )}
              minWidth={["80px", "80px", "122px", "122px"]}
              fontSize={["12px", "12px", "17px", "17px"]}
              innerHeight={["30px", "30px", "40px", "40px"]}
              ml="2px"


            // my="12px"
            // height="34px"
            >
              EDIT
            </DashboardSmallCTA>
            
          </Flex>
        </AbsoluteDiv>
      ) : null}
    </Flex>
  </Flex>
);
