import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {Field, reduxForm} from 'redux-form';
import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P} from '../../common/typography';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
import ReactTooltip from 'react-tooltip';
import {  NunitoSansBold,  NunitoSans,  NunitoSansSemiBold } from "../../common/typography";
import {  DashboardSmallCTA } from "../../common/forms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faShareAlt, faUserPlus, faNewspaper as faNewspaperLight, faPaperPlane as faPaperPlaneLight } from '@fortawesome/pro-light-svg-icons';
import _ from "lodash";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../actions/resources";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Company name is required';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
 
  return errors;
};
const BlurWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 10px;
  right: 10px;
  bottom: -18px;
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
`;
const DefaultBlurrable = styled.div`
  width: 100%;
  height: 100%;
  background: #9f9f9f;
`;
const PreviewImg = styled.img`
  height: 100%;
  width: 100%;
`;
const PreviewImageBlurrable = ({src}) => <PreviewImg src={src} />;

const DropzoneKickpost = ({...props}) => (
  <Box position="relative" >
    <Flex
      borderRadius={10}
      bg="imageBorder"
      position="relative"
      zIndex={100} 
      justifyContent="center"
      p={'10px'}
      alignItems="center"
      width={[83, 110, 110, 110]}
      height={[83, 110, 110, 110]}>
      <Dropzone disableClick
        onDrop={props.dropzoneOnDrop}
        style={{
          backgroundColor: '#fff',
          backgroundImage: props.dropzonePreview
            ? `url(${props.dropzonePreview})`
            : `url(${companyLogoUploadImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          cursor: 'pointer',
          width: '100%',
          paddingTop: '100%',
        }}
        multiple={false}
      />
    </Flex>
    {props.dropzonePreview ? (
      <BlurWrapper>
        <PreviewImageBlurrable src={props.dropzonePreview} />
      </BlurWrapper>
    ) : (
      <BlurWrapper>
        <DefaultBlurrable />
      </BlurWrapper>
    )}
  </Box>
);
const CompanyNameBubble = ({...props}) => (
  <Flex
    w={[1, 1, 1, 1]}
    alignContent="center"
    justifyContent="space-between"
    flexDirection={['column', 'column', 'row', 'row']}>
    <Bubble
      bg={['white', 'white', 'inputBackgroundGray', 'inputBackgroundGray']}>
      <Flex
        flexDirection={['column', 'column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
        pt={['0px', '0px', '36px', '36px']}
        pb={['0px', '0px', '39px', '39px']}>
        <Box
          flex="1 1 auto"
          order={[1, 1, 0, 0]}
          width={[1, 1, 0.605, 0.655]}
          ml={['0px', '0px', '44px', '44px']}>
          <Field
            label="Company Name"
            name="name"
            mb={0}
            component={renderField}
            type="text"
          />
        </Box>
        <Box data-tip="Drag and Drop Logo Here"
          mb={[39, 39, 0, 0]}
          mr={[0, 0, 49, 49]}
          ml={[0, 0, 49, 49]}
          order={[0, 0, 1, 1]}
          width={[103, 130, 130, 130]}>
            <ReactTooltip />
          <DropzoneKickpost {...props} />
        </Box>
      </Flex>
    </Bubble>
  </Flex>
);

let IndustryForm = props => {
  
  const [errorMessage, setErrorMessage] = useState('');
  const {user,isMobile,updateSettings,handleSubmit, dropzoneOnDrop, dropzonePreview, back, singleUserAccount, redirect, company} = props;
  const [industryCategories, setIndustryCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(user&&user.company&&user.company.settings&&user.company.settings.industry_categories || []);
  
  const [page ] = useState(1);
  
  const handleCategory = async(company, user,props)=>{
    if(selectedCategories && selectedCategories.length){
      // await updateSettings(company.id,{
      //   "company_type": JSON.parse(localStorage.getItem("selectedCategories"))[0].name,
      //   "company_goal": (JSON.parse(localStorage.getItem("selectedCategories"))[0].name==="Vendor"?"share_info":"receive_info"),        
      //   "industry_categories": localStorage.getItem("industry")
      // });

      if(company && company.id){

        await updateSettings(company.id,{
          "company_type": JSON.parse(localStorage.getItem("selectedCategories"))[0].name
        });
        
        await updateSettings(company.id,{
          "company_goal": (JSON.parse(localStorage.getItem("selectedCategories"))[0].name==="Reseller"?"receive_info":"share_info")        
        });
        
        await updateSettings(company.id,{
          "industry_categories": localStorage.getItem("industry")
        });
        
        redirect("/email-verification");
 
      } else {
        console.log("industry.js could not update company");
        console.log("industry.js handleCategory props companyGoalcompanyGoalcompanyGoalcompanyGoal pendingConnectionspendingConnectionspendingConnectionspendingConnections",props);
        console.log("LeEmail: "+localStorage.getItem("LeEmail"));
      }
    }
  }

  const toggleCategory = (category)=>{
    setErrorMessage('');
    
    let newSelection = [...selectedCategories];

   const isSelected = _.findIndex(newSelection, function(o) { return o.id === category.id; }) > -1;
   
   if(isSelected){
     // remove category
      setSelectedCategories(newSelection.filter(cat=>cat.id!==category.id));
   }else{
     if(newSelection.length === 3){
      setErrorMessage("You can choose up to 3 industry categories.");
       return setTimeout(()=>setErrorMessage(''), 5000);
     }
    //add category
    newSelection.push(category);
    setSelectedCategories(newSelection);
    localStorage.setItem("industry",JSON.stringify(newSelection));
   }

  };



  const fetchIndustryCategories = async () => {

    const categories = await fetch(`${process.env.REACT_APP_API_URL}/industrycategories`).then(res => res.json());

    setIndustryCategories(categories);
  }
  
  useEffect(() => {
    if (!industryCategories.length) {
      fetchIndustryCategories();
    }
  }, [industryCategories]);
  
  return (
    <Flex
                justifyContent="left"
                alignItems="left"
                width="80%"
                maxWidth="567px"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"                    
                  >                    
                  </NunitoSansBold>
                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"left"}}                    
                  >
                        Industry Settings.
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '10px' : '70px'}}
                  >
                     Choose the industries that your company works in.
                  </NunitoSansSemiBold>
                  {/* <NunitoSansBold
                    fontSize={["12px","12px","14px","14px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '10px' : '70px'}}
                  >
                     CHOOSE INDUSTRY (Up to 3)
                  </NunitoSansBold> */}

                  <IndustryCategories
                  isMobile={isMobile}
                  industryCategories={industryCategories}
                  selectedCategories={selectedCategories}
                  toggleCategory={toggleCategory}
                  />

                    {
                      errorMessage
                      ?
                      <p
                      style={{
                        color: '#FE4A49',
                        fontSize:'14px'
                      }}
                      >
                        {errorMessage}
                      </p>
                      :
                      null
                    }
                  <DashboardSmallCTA
                    width="100%"
                    minWidth="130px"
                    innerHeight="35px"
                    fontSize="16px"
                    disabled={selectedCategories.length<1}
                    onClick={() => handleCategory(company, user,props)}
                    style={{marginTop: errorMessage ? '21px' : '70px'}}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
  );
};


const IndustryCategories = ({industryCategories=[], selectedCategories, toggleCategory, isMobile})=>{

  const theFirstHalf = Math.ceil(industryCategories.length/2);

  const firstColumn = industryCategories.slice(0, theFirstHalf);
  const secondColumn = industryCategories.slice(theFirstHalf);

  return (
    <Flex
    width="100%"
    justifyContent="space-between"
    flexDirection={isMobile ? "column" : "row"}
    >
      <Flex
      flexDirection="column"
      >
        {
          firstColumn.map((category, index)=><CategoryBox
            key={category+index.toString()}
            isMobile={isMobile}
            category={category}
            selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
            isFirst={index===0}
            toggleCategory={toggleCategory}
            />
          )
        }

      </Flex>

      <Flex
      flexDirection="column"
      >
        {
          secondColumn.map((category, index)=><CategoryBox
            key={theFirstHalf + index.toString()}
            isMobile={isMobile}
            category={category}
            selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
            isFirst={index===0}
            toggleCategory={toggleCategory}
            />
          )
        }

      </Flex>

    </Flex>
  );
}


const CategoryBox = (props) => (
  <Flex
  alignItems="center"
  width="250px"
  style={{
    borderRadius:`6px`,
    border: `1px solid ${props.selected ? "#428BF4" : "#C3C3C3"}`, 
    backgroundColor: props.selected ? "#428BF4" : "transparent", 
    marginTop: props.isMobile ? '15px' : !props.isFirst ? '35px' : 'none',
    paddingBottom:  props.isMobile ? '5px' : '10px',
    paddingTop: props.isMobile ? '5px' : '10px',
    cursor: 'pointer'
  }}
  onClick={()=>props.toggleCategory(props.category)}
  >

    <FontAwesomeIcon
    icon={props.selected ? faMinus : faPlus}
    color={props.selected ? "#FFFFFF" : "#C3C3C3"}
    style={{marginLeft: '20px', fontSize: props.isMobile ? '18px' : '22px'}}
    />

    <NunitoSans
    color={props.selected ? "#FFFFFF" : "#C3C3C3"}
    fontSize={["18px","18px","22px","22px"]}
    style={{marginLeft: '20px'}}
    >
      {props.category.name}
    </NunitoSans>

  </Flex>
);

IndustryForm = connect(
  state => ({
    user: state.resources.detail.user,    
    company: state.resources.detail.company
  }),
  dispatch => ({
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),  
    redirect: bindActionCreators(push, dispatch)
    
  })
)(IndustryForm);

export default reduxForm({
  form: 'industry',
  validate,
})(IndustryForm);
