import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MapComponent } from "../../../common/mapComponent";
import { Box, Flex } from "../../../common/flexbox";
//actions
import { actions } from "../../../../actions/resources";

export class EditCompanyAccessory extends Component {
  state = {
    placeName: "San Francisco, California, 94117, US",
    address: "San Francisco",
    text: ""
  };
  
  constructor(props) {
    super(props);
  }


  _addressChange = geolocation => {
    const { updateCompany, company } = this.props;
    console.log("_addressChange this.props",this.props)
    console.log("_addressChange geolocation",geolocation)
    updateCompany(company.id, { location: geolocation });
  };

  render() {
    const { company } = this.props;
    const { placeName, address, text } = this.state;

    if (company) {
      return (
        <Flex flexDirection="column">
          <Box mt="91px" />
          <MapComponent
            placeName={
              (company.location && company.location.place_name) || placeName
            }
            address={
              company.location
                ? `${company.location.address ? company.location.address : ""}`
                : address
            }
            text={(company.location && company.location.text) || text}
            addressChange={this._addressChange}
          />
          <Box mt={66 * 5 + 600 + "px"} />
        </Flex>
      );
    }
    return null;
  } 
}

export default connect(
  state => ({
    company: state.resources.detail.company
  }),
  dispatch => ({
    updateCompany: bindActionCreators(actions.patchCompany, dispatch)
  })
)(EditCompanyAccessory);

function numberParser(number){
  return new Intl.NumberFormat('en-US').format(number)
}
