import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import _ from "underscore";
import parse from "url-parse";
import moment from "moment";
import axios from "axios";
import { push } from "react-router-redux";
import { history } from "../../../store";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
//components
import Form from "./new/form";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers,
  searchUsers
} from "../../../actions/search";

import RouteLeavingGuard from "../../common/routeGuardModal";

import InfoModal from "../../common/InfoModal";

import postLogo from "../../../images/post_info_logo.png";

export class PostNew extends Component {
  state = {
    posting: false,
    audienceSearchInput: null,
    clicked: false,
    errors: {},
    files: [],
    videos: [],
    startDate: moment(),
    startDateError: null,
    endDate: moment().add(7, "days"),
    endDateError: null,
    postBody: null,
    videoAdded: false,
    editMode: window.location.pathname.includes('edit'),
    brands: [],
    selectedBrand: null,
    blockNavigation : true,
    submitted: false
  };

  // blockNavigation = true;

  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {
    const { editMode,blockNavigation } = this.state;
    const { fetchBrands, fetchPost, fetchUser, match, user, systemSettings, redirect } = this.props;

    if(!getUser().company_admin) {
      redirect('/dashboard');
    } 

    if (systemSettings && !systemSettings.posts_active && !editMode) {
      this.setState({blockNavigation: false});
      // blockNavigation = false;
      return redirect('/dashboard');
    }
 
    // if(window.location.pathname.includes('edit')){
    //   this.setState({editMode: true})
    // }


    if (!user) {
      fetchUser(getUser().id).then(res => {
        if (systemSettings.posting_companies_active && res.payload && res.payload.company && !res.payload.company.posting_active && !editMode) {

          return redirect('/dashboard');
        }
      });
    } else {
      if (systemSettings.posting_companies_active && user.company && !user.company.posting_active && !editMode) {

        return redirect('/dashboard');
      }
    }

    await fetchBrands({ company_id: getUser().company_id, status: 'active' });

    if (match.params.id) {
      // window.alert(match.params.id)
      fetchPost(match.params.id).then(response => {
        let files = response.payload.files.map(file => {
          return {
            url: file.url,
            name: file.filename,
            type: file.filetype
          };
        });
        let selectedBrand = null;
        if (response.payload.brand) {
          selectedBrand = {
            value: response.payload.brand.id,
            label: response.payload.brand.name,
            logo: response.payload.brand.logo
          };
        }
        this.setState({
          endDate: moment(response.payload.end_date).add(1, "days"),
          postBody: response.payload.content,
          startDate: moment(response.payload.start_date).add(1, "days"),
          title: response.payload.title,
          videos: response.payload.videos,
          files: files,
          selectedBrand
        });
      });
    }


    //await fetchCompany(match.params.id);

    //this._fetchDependencies();

  }





  onDrop = async acceptedFiles => {
    this.setState({
      files: _.union(this.state.files, acceptedFiles)
    });
  };

  clickToPrivate = async () => {
    const { clicked } = this.state;
    return this.setState({
      clicked: !clicked
    });
  };

  addVideo = async () => {
    const { notifyError } = this.props;
    const { videoUrl, videos } = this.state;
    const parsedUrl = parse(videoUrl);


    let videoMeta = null;
    if (parsedUrl.hostname === "vimeo.com") {
      const response = await fetch(
        `${process.env.REACT_APP_VIMEO_URL}${parsedUrl.pathname}?access_token=${process.env.REACT_APP_VIMEO_ACCESS_TOKEN
        }`,
        { mode: 'no-cors' }
      ).then(response => response.json());
      if (response && response.name && response.pictures) {
        videoMeta = {
          title: response.name,
          image: response.pictures.sizes[3].link,
          duration: parseInt(response.duration / 60, 10),
          url: videoUrl
        };
      }
    }
    //    https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&key=AIzaSyDNyDP1f_mQUTkQWOd1VEtYuGRNC6_mIOc&id=
    if (parsedUrl.hostname === "youtu.be") {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&key=${process.env.REACT_APP_YOUTUBE_DATA_KEY}&id=${parsedUrl.pathname.replace(
          //`${process.env.REACT_APP_YOUTUBE_URL}${parsedUrl.pathname.replace(
          /^\/+/g,
          ""
          , { mode: 'no-cors' }
        )}`
      ).then(response => response.json());


      //youtube-api-for-kp-uploads

      if (response.items) {
        const item = response.items[0];

        const duration = moment
          .duration(item.contentDetails.duration)
          .asMinutes();

        if (item && item.snippet.thumbnails && item.snippet.title) {
          const selectedThumbnail = item.snippet.thumbnails.standard || item.snippet.thumbnails.high || item.snippet.thumbnails.medium || item.snippet.thumbnails.default;
          videoMeta = {
            title: item.snippet.title,
            image: selectedThumbnail.url,
            duration: parseInt(duration, 10),
            url: videoUrl
          };
        }
      }


    }

    if (videoMeta) {

      if (videos.map(video => video.url).includes(videoMeta.url)) {
        return notifyError("This video has been already attached.");
      }
      this.setState({ videoAdded: true })
      return this.setState({
        videos: _.union(this.state.videos, [videoMeta]),
        videoAdded: false
      });
    }

    return notifyError("That is an invalid url.");
  };

  updateVideoInput = e => {

    this.setState({
      videoUrl: e.target.value
    });

  };

  removeFile = file => {
    let fileIndex = this.state.files.indexOf(file);
    let fileArrayClone = this.state.files.slice(0);
    fileArrayClone.splice(fileIndex, 1);
    window.URL.revokeObjectURL(file.preview);
    this.setState({ files: fileArrayClone });
  };

  removeVideo = video => {
    const { videos } = this.state;
    let videoIndex = videos.indexOf(video);
    let videoArrayClone = videos.slice(0);
    videoArrayClone.splice(videoIndex, 1);
    this.setState({ videos: videoArrayClone });
  };

  _updatePost = async data => {
    const { redirect, updatePost, post, user, systemSettings } = this.props;
    const { files, videos, startDate, endDate, postBody, selectedBrand } = this.state;

    const errors = this._validate();

    this.setState({ posting: true, submitted: true })

    if (!_.isEmpty(errors)) {
      this.setState({ posting: false, submitted: false })
      return this.setState({ errors: errors });
    }

    let promises = files.map(async file => {
      if (file.url) {
        return {
          url: file.url,
          filename: file.name,
          filetype: file.type
        };
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/s3/signed-url`,
        {
          params: {
            content_type: true,
            filename: `posts/${user.company_id}/${file.name}`,
            filetype: file.type
          }
        }
      );

      let options = { headers: { "Content-Type": file.type } };
      const s3Response = await axios.put(
        response.data.signed_url,
        file,
        options
      );

      let payload = {
        url: s3Response.config.url,
        filename: file.name,
        filetype: file.type
      };

      return payload;
    });

    let newFiles = await Promise.all(promises);

    let payload = {
      company_id: getUser().company_id,
      content: JSON.stringify(postBody),
      end_date: endDate.format("YYYY-MM-DD"),
      files: JSON.stringify(newFiles),
      start_date: startDate.format("YYYY-MM-DD"),
      title: data.title,
      status: systemSettings.post_approval && (getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || (getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && systemSettings.post_approval_coworkers)) ? 'pending' : 'active',
      videos: JSON.stringify(videos)
    };

    if (selectedBrand && selectedBrand.value) {
      payload.brand_id = selectedBrand.value;
    }

    const postResponse = await updatePost(post.id, payload);
    if (postResponse && postResponse.payload.id) {

      setTimeout(() => {
        this.setState({
          files: [],
          videos: [],
          startDate: moment(),
          startDateError: null,
          endDate: moment().add(7, "days"),
          endDateError: null,
          postBody: null,
          post: null,
          blockNavigation: false,
          posting: false
        });

        redirect(`/dashboard/post/${postResponse.payload.id}`);
      }, 5000)
    }
  };

  /*  _fetchConnections = () => {
      const { companyResults, fetchConnections } = this.props;
      fetchConnections({
        company_id: getUser().company_id,
        ids: _.pluck(companyResults, "id")
      });
    };*/



  _onSubmit = async data => {
    const {
      // connections,
      createPost,
      redirect,
      selectedUsers,
      selectedDepartments,
      selectedCompanies,
      selectedGroups,
      user,
      connections,
      searchCompanies,
      searchDepartments,
      searchConnectionUsers,
      post,
      systemSettings
    } = this.props;
    this.setState({ posting: true, submitted: true })
    const { clicked, files, videos, startDate, endDate, postBody, selectedBrand } = this.state;


    const errors = this._validate();


    if (!_.isEmpty(errors)) {
      this.setState({ posting: false, submitted: false })
      return this.setState({ errors: errors });
    }

    console.log("new.js",user, user.company,user.company.posting_active,systemSettings.posting_companies_active)
    if(user === undefined || user.company === undefined || user.company.posting_active !== true || systemSettings.posting_companies_active === true){
      this.setState({ posting: false, submitted: false })
      let refreshedErrors = {};
      refreshedErrors["postBody"] = "Account posting is restricted. Please contact an administrator for an update.";
      console.log(refreshedErrors["postBody"]);
      //return this.setState({ errors: refreshedErrors });
    } else {
      this.setState({blockNavigation: false});
    }



    let promises = files.map(async file => {
      if (file.url) {
        return {
          url: file.url,
          filename: file.name,
          filetype: file.type
        };
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/s3/signed-url`,
        {
          params: {
            content_type: true,
            filename: `posts/${user.company_id}/${file.name}`,
            filetype: file.type
          }
        }
      );

      let options = { headers: { "Content-Type": file.type } };
      const s3Response = await axios.put(
        response.data.signed_url,
        file,
        options
      );

      let payload = {
        url: s3Response.config.url,
        filename: file.name,
        filetype: file.type
      };

      return payload;
    });

    let newFiles = await Promise.all(promises);
    //console.log(selectedCompanies)
    let goodCompanies = "[]";
    let userObj = { "vendor_id": user.company_id }
    connections.push(userObj);
    console.log(connections);
    console.log(selectedGroups);
    if (typeof selectedCompanies === "undefined" || selectedCompanies === null || typeof selectedCompanies === null) {
      //rejoice, we do nothing
    } else {

      goodCompanies = JSON.stringify(_.uniq(_.pluck(selectedCompanies, "id").concat(_.pluck(selectedGroups, "company_id"))));
    }

    console.log(goodCompanies)

    let connected = "no";

    // if(clicked === false||clicked ==="false"){
    connected = ((selectedCompanies.length + selectedUsers.length + selectedDepartments.length + selectedGroups.length) === 0) ? "yes" : "no";
    // }


    let payload = {
      all_connections: connected,
      company_sharing: (clicked),//update sharing rule
      company_ids: goodCompanies,
      department_ids: JSON.stringify(_.pluck(selectedDepartments, "id")),
      user_ids: JSON.stringify(_.pluck(selectedUsers, "id")),
      company_id: getUser().company_id,
      //      company_id: (clicked) ? "[]" : (getUser().company_id),//use clicked of this.state here to remove poster's company from receiving this
      content: JSON.stringify(postBody),
      end_date: endDate
        ? endDate.format("YYYY-MM-DD")
        : moment()
          .add(7, "days")
          .format("YYYY-MM-DD"),
      files: JSON.stringify(newFiles),
      start_date: startDate
        ? startDate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      title: data.title,
      user_id: getUser().id,
      videos: JSON.stringify(videos),
      status: systemSettings.post_approval && (getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || (getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && systemSettings.post_approval_coworkers)) ? 'pending' : 'active'
    };

    if (selectedBrand && selectedBrand.value) {
      payload.brand_id = selectedBrand.value;
    }

    const postResponse = await createPost(payload);
    if (postResponse && postResponse.payload.id) {

      setTimeout(() => {
        this.setState({
          files: [],
          videos: [],
          startDate: moment(),
          startDateError: null,
          endDate: moment().add(7, "days"),
          endDateError: null,
          postBody: null,
          post: null,
          blockNavigation: false
        });


        this.setState({ posting: false });
        this.setState({blockNavigation: false});
        redirect('/dashboard') // `/dashboard/post/${postResponse.payload.id}` will bring you to page of what you posted
      }, 5000)
    }
  };

  _updatePostBody = content => {
    this.setState({ postBody: content });
  };

  _onDateChange = (name, date) => {
    this.setState({ [name]: date });
  };

  _validate = () => {
    const { startDate, endDate, postBody } = this.state;

    let refreshedErrors = {};



    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        refreshedErrors["startDate"] = "Start date cannot be after end date.";
      }


      if (endDate.diff(startDate, 'days') > 365) {
        refreshedErrors["endDate"] = "End date cannot exceed 1 year.";
      }
    }

    if (!postBody || !postBody.blocks || !postBody.blocks.length || !postBody.blocks.map(block => block.text || '').join('').trim().length) {
      refreshedErrors["postBody"] = "Your post must have a kick.";
    }



    return refreshedErrors;
  };

  _handleBrandSelection = selectedBrand => {
    // const {brands} = this.state;

    // if(!brandId) return this.setState({selectedBrand: null});

    // let selectedBrand = brands && brands.find(brand => brand.id === brandId);

    this.setState({ selectedBrand });
  }

  render() {
    const { loading, match, post, user, systemSettings, companySettings, brands } = this.props;
    const {
      posting,
      clicked,
      postBody,
      errors,
      files,
      startDate,
      startDateError,
      endDate,
      endDateError,
      videoUrl,
      videos,
      audienceSearchInput,
      videoAdded,
      editMode,
      selectedBrand,
      submitted
    } = this.state;



    if (match.params.id && !post && loading) return null;
    if (!user) return null;


    const brandOptions = brands.map(brand => {
      return {
        value: brand.id,
        label: brand.name,
        logo: brand.logo
      }
    });

    // add current company to Brand Options
    brandOptions.unshift({ value: null, label: user.company.name, logo: user.company.logo });


    return (
      <>
        {/* <Prompt
      when={this.blockNavigation}
      message={"Leaving this page will discard unsaved changes. Click OK to leave."}
      /> */
         
      }
        <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          // whener={this.state.blockNavigation && !(systemSettings.posting_companies_active && user.company && !user.company.posting_active)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && (submitted || !getUser().company_admin)) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            }
            return true
          }}
        />
        <InfoModal
          modalName='add_post'  //no spaces. Once set right do not change
          modalVersion="1.00" //set it to 1.00 initially then change it when needed
          image={postLogo}
          title='Share your Info'
          text='Posts let you deliver information directly to your partner network to help them better support their customers. For tips on how to create a successful post, watch our tutorial video: '
          videoLink='https://youtu.be/xFYRTlTuTHA'
        />
        <Form
          systemSettings={systemSettings}
          companySettings={companySettings}
          brandOptions={brandOptions}
          handleBrandSelection={this._handleBrandSelection}
          selectedBrand={selectedBrand || brandOptions[0] || {
            value: "b7d35765-3f59-4e65-927a-ac4f58c9a0ca",
            label: "SIIG, Inc.",
            logo: "https://files.kickpost.io/brand-logos/1643740387561SIIGInc"
          }}
          posting={posting}
          clicked={clicked}
          clickToPrivate={this.clickToPrivate}
          addVideo={this.addVideo}
          editMode={editMode}
          endDate={endDate}
          endDateError={endDateError}
          errors={errors}
          files={files}
          initialValues={
            match.params.id
              ? {
                title: post && post.title
              }
              : null
          }
          postBody={match.params.id ? (post && post.content) : null}
          videos={videos}
          onDateChange={this._onDateChange}
          onDrop={this.onDrop}
          updateVideoInput={this.updateVideoInput}
          onSubmit={editMode ? this._updatePost : this._onSubmit}
          removeFile={this.removeFile}
          removeVideo={this.removeVideo}
          startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
          startDateError={startDateError}
          updateContent={this._updatePostBody}
          audienceSearchInput={audienceSearchInput}
          videoUrl={videoUrl}
          videoAdded={videoAdded}
          user={user}
        />
      </>
    );
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    loading: state.resources.detail.loading.post,
    post: state.resources.detail.post,
    selectedUsers: state.post.selectedUsers,
    searchCompanies: state.search.searchCompanies,
    brands: state.resources.collection.brand || [],
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    user: state.resources.detail.user,
    connections: state.resources.collection.connection || []
  }),
  dispatch => ({
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createPost: bindActionCreators(actions.createPost, dispatch),
    //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    fetchPost: bindActionCreators(actions.fetchPost, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchBrands: bindActionCreators(actions.fetchBrands, dispatch),
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updatePost: bindActionCreators(actions.patchPost, dispatch)
  })
)(PostNew);
