import _ from "underscore";

export const setLocation = payload => ({
  type: "SET_LOCATION",
  payload
});

export const setLocationRuleType = payload => ({
  type: "SET_LOCATION_RULE_TYPE",
  payload
});

export const setRadius = payload => ({
  type: "SET_RADIUS",
  payload
});

export const updateDepartments = payload => ({
  type: "SET_DEPARTMENTS",
  payload
});

export const updateGroups = payload => ({
  type: "SET_GROUPS",
  payload
});

export const updateExclusions = payload => ({
  type: "SET_EXCLUSIONS",
  payload
});

export const setInfoModalOpen = payload => ({
  type: "SET_INFO_MODAL",
  payload
});

export const setUpgradeModalOpen = payload => ({
  type: "SET_UPGRADE_MODAL",
  payload
});

export const calculatePeople = (connections, departments) => {
  let companies = _.pluck(connections, "company");
  companies = companies.map(company => {
    let total = calculateCompanyPeople(company, departments);
    return total;
  });
  let totalReach = _.reduce(companies, (m, n) => m + n);
  return totalReach;
};

export const calculateCompanyPeople = (company, departments) => {
  let processedDepartments = _.filter(
    company.departments,
    department => !departments.includes(department.id)
  );
  let totalCount = _.reduce(
    processedDepartments,
    (total, department) => {
      return department.employee_count + total;
    },
    0
  );
  totalCount = totalCount * (company.total_employee_count / totalCount);

  if (!company.departments.length) {
    return company.total_employee_count;
  }

  return totalCount || 0;
};

export const calculateCompanies = (connections, departments) => {
  let companies = _.pluck(connections, "company");
  companies = companies.filter(company => {
    let total = calculateCompanyPeople(company, departments);
    return total > 0;
  });
  return companies.length;
};

export const calculateTotalReach = (connections, departments) => {
  let companyCounts = _
    .chain(connections)
    .pluck("company")
    .pluck("total_employee_count")
    .value();
  return _.reduce(companyCounts, (m, n) => m + n);
};
