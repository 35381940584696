import React from "react";
import Modal from "react-modal";


import TermOfUse from "./terms";
import Privacy from "./privacy";


const LegalModalController = props => {

    const {termsOpen, privacyOpen, setPrivacyOpen, setTermsOpen} = props;

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          width: "50vw",
          maxHeight: "70vh",
          zIndex: 16777271,
          overflow: "auto"
        },
    
        overlay: {
          zIndex: 16777270
        }
        
      };

    return (
        <>
        <Modal
        isOpen={termsOpen}
        onRequestClose={()=>setTermsOpen(false)}
        style={customStyles}
        contentLabel="Terms Modal"
        ariaHideApp={false}
        >
            <TermOfUse setTermsOpen={setTermsOpen}/>
        </Modal>
        <Modal
        isOpen={privacyOpen}
        onRequestClose={()=>setPrivacyOpen(false)}
        style={customStyles}
        contentLabel="Privacy Modal"
        ariaHideApp={false}
        >
            <Privacy setPrivacyOpen={setPrivacyOpen} />
        </Modal>
        </>
    )

}

export default LegalModalController;