import React, {useState, Component, useEffect} from "react";
import { connect } from "react-redux";
//components
import IndustryForm from "./forms/industry";
//utils
import {
  getUser
} from "../../utils/auth";

export class Industry extends Component {
  state = {
    company: {"company":JSON.parse(localStorage.getItem("LeCompany")).name,"name":JSON.parse(localStorage.getItem("LeCompany")).name},
    user:null
  };

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    let DMG = "_fetchDependencies";

    let gotUser = getUser();
    console.log(DMG,"gotUser",gotUser);
  }
  render() {
    let { company } = this.state;
    console.log("industry.js company",company)
    const {user} = this.props;
    console.log("industry.js this.props",this.props)
    return (
      <div className="App">
        <IndustryForm 
        user={user}
        company={this.props.company} />
      </div>
    );
  }
}
export default connect(
  state => ({
    company: state.resources.detail.company
  })
)(Industry); 