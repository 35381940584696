export const setIncentiveHovered = payload => ({
    type: "SET_INCENTIVE_HOVERED",
    payload
  });

export const setIncentiveSelected = payload => ({
    type: "SET_INCENTIVE_SELECTED",
    payload
  });

export const setIncentiveDetailType = payload => ({
    type: "SET_INCENTIVE_TYPE",
    payload
  });

export const setRowSelected = payload => ({
  type: "SET_ROW_SELECTED",
  payload
});

export const setCsvDropOpen = payload => ({
  type: "SET_CSV_DROP",
  payload
});
  