
//import { render } from "react-dom";
// const container = document.getElementById('root');
// render(<App tab="home" />, container);
// After

import { createRoot } from 'react-dom/client';
import React from 'react';
//import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hotjar } from 'react-hotjar';
import store, { history } from "./store";
import axios from "axios";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import DisabledCookies from "./components/disabledCookies";
import UnderMaintenance from "./components/maintenance";
import TrialAlert from "./components/common/trialAlert";
import Routes from "./routes";

import { ReflexProvider } from "reflexbox";


import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { checkVersion } from "./utils/auth";
import "./App.css";



const container = document.getElementById('root');
const space = [0, 6, 12, 18, 24];
const breakpoints = [32, 48, 64, 80];



// Disables console logs 
if (window.location.origin === "https://app.kickpost.io") {

  console.log = function () { };
}
localStorage.setItem("env",process.env.REACT_APP_NODE_ENV);
if (process.env.REACT_APP_NODE_ENV === "production") {
  const hjid = 1403243;
  const hjsv = 6;
  hotjar.initialize(hjid, hjsv);
}

axios.get(`${process.env.REACT_APP_API_URL}/maintenance_mode`).then(respond => {

  // const root = ReactDOM.createRoot(document.getElementById('root'));
  const root = createRoot(container);


  if (false || respond.data.maintenance_mode === 'on') {

    root.render(
      <UnderMaintenance />
    );

  } else if ( !areCookiesEnabled()) {
    root.render(

      <DisabledCookies />
    );
  } else {
    checkVersion();

    if(false){

      root.render(
        <App />
      );
    } else {
      root.render(
        <>
          <ReflexProvider space={space} breakpoints={breakpoints}>
            <Provider store={store}>
              <>
              <TrialAlert history={history}/>
              <Router history={history}>
                <Routes
    
                />
              </Router>
              </>
            </Provider>
          </ReflexProvider>
        </>
      );
    }



  }

}
)

function areCookiesEnabled() {
  try {
    document.cookie = 'cookietest=1';
    var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return cookiesEnabled;
  } catch (e) {
    return false;
  }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
