import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {Field, reduxForm} from 'redux-form';
import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P} from '../../common/typography';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
import ReactTooltip from 'react-tooltip';
import {  NunitoSansBold,  NunitoSans,  NunitoSansSemiBold } from "../../common/typography";
import {  DashboardSmallCTA } from "../../common/forms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faShareAlt, faUserPlus, faNewspaper as faNewspaperLight, faPaperPlane as faPaperPlaneLight } from '@fortawesome/pro-light-svg-icons';
import _ from "lodash";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../actions/resources";

const validate = values => {
  return {};
};

let CompanyTypeForm = props => {
  
  const [errorMessage, setErrorMessage] = useState('');
  const {user,isMobile,updateSettings,handleSubmit, dropzoneOnDrop, dropzonePreview, back, singleUserAccount, redirect, company} = props;

const [companytypeCategories, setCompanyTypeCategories] = useState([]);


  const [selectedCategories, setSelectedCategories] = useState(user&&user.company&&user.company.settings&&user.company.settings.companytype_categories || []);
  
  const [page, setPage] = useState(1);
  
  const handleCategory = async(company, user,props)=>{
    console.log("handleCategory company, user,props",props,company,user);    
    if(selectedCategories && selectedCategories.length){
      localStorage.setItem("selectedCategories",JSON.stringify(selectedCategories));
      redirect("/industry");
    }
  }

  const toggleCategory = (category)=>{
    console.log("selectedCategories",selectedCategories)

    if(true || category.id!=="0c894c00-9eea-409c-9ce1-02ee84cbaacf"){

      setErrorMessage('');
      
      let newSelection = [...selectedCategories];
      console.log("newSelection.filter(cat=>cat.id!==category.id)",newSelection.filter(cat=>cat.id!==category.id))
      const isSelected = _.findIndex(newSelection, function(o) { return o.id === category.id; }) > -1;
   

      if(isSelected){
        // deselect the current category
         setSelectedCategories(newSelection.filter(cat=>cat.id!==category.id));
      }else{
        if(newSelection.length === 1){
          newSelection.pop();//remove the one category 
        //  setErrorMessage("You can choose either Vendor or Reseller.");
        //   return setTimeout(()=>setErrorMessage(''), 5000);
        }
       //add category
       newSelection.push(category);
       setSelectedCategories(newSelection);
      }
    }


  };



  const fetchCompanyTypeCategories = async () => {
    // const categories = await fetch(`${process.env.REACT_APP_API_URL}/companytypecategories`).then(res => res.json());
    // console.log("categoriesCompanyType",categories);
    // setCompanyTypeCategories(categories);
    setCompanyTypeCategories([{"id":"e9a2049b-75d6-40a5-be5d-cf0f805ab5b2","name":"Vendor"},{"id":"355f814a-36fe-4f38-b3a9-703412375974","name":"Reseller"}]);    
    //
  }
  
  useEffect(() => {
    if (!companytypeCategories.length) {
      fetchCompanyTypeCategories();
    }
  }, [companytypeCategories]);
  
  return (
    <Flex
                justifyContent="left"
                alignItems="left"
                flexDirection="column"                
                maxWidth="567px"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"                    
                  >                    
                  </NunitoSansBold>
                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"left"}}                    
                  >
                        Company Type.
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '10px' : '45px'}}
                  >
                     Choose the type of company you're setting up.
                  </NunitoSansSemiBold>
                  <NunitoSansBold
                    fontSize={["14px","14px","16px","16px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '10px' : '30px'}}
                  >
                     CHOOSE COMPANY TYPE
                  </NunitoSansBold>

                  <CompanyTypeCategories
                    width="100%"
                    minWidth="130px"
                    isMobile={true}
                    companytypeCategories={companytypeCategories}
                    selectedCategories={selectedCategories}
                    toggleCategory={toggleCategory}
                    style={{width:'100%',minWidth:'130px'}}
                  />

                    {
                      errorMessage
                      ?
                      <p
                      style={{
                        color: '#FE4A49',
                        fontSize:'14px'
                      }}
                      >
                        {errorMessage}
                      </p>
                      :
                      null
                    }
                  <DashboardSmallCTA
                    width="100%"
                    minWidth="130px"
                    innerHeight="35px"
                    fontSize="16px"
                    
                    my={['2vh', '2vh', '9vh', '9vh']}
                    disabled={selectedCategories.length<1}
                    onClick={() => handleCategory(company, user,props)}
                    style={{marginTop: errorMessage ? '21px' : '70px'}}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
  );
};


const CompanyTypeCategories = ({companytypeCategories=[], selectedCategories, toggleCategory, isMobile})=>{

  const theFirstHalf = Math.ceil(companytypeCategories.length/2);

  const firstColumn = companytypeCategories.slice(0, theFirstHalf);
  const secondColumn = companytypeCategories.slice(theFirstHalf);

  return (
    <Flex
    width="100%"
    justifyContent="space-between"
    flexDirection={isMobile ? "column" : "row"}
    >
      <Flex
      flexDirection="column"
      >
        {
          firstColumn.map((category, index)=><CategoryBox
            key={category+index.toString()}
            isMobile={isMobile}
            category={category}
            selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
            isFirst={index===0}
            toggleCategory={toggleCategory}
            />
          )
        }

      </Flex>

      <Flex
      flexDirection="column"
      >
        {
          secondColumn.map((category, index)=><CategoryBox
            key={theFirstHalf + index.toString()}
            isMobile={isMobile}
            category={category}
            selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
            isFirst={index===0}
            toggleCategory={toggleCategory}
            />
          )
        }

      </Flex>

    </Flex>
  );
}


const CategoryBox = (props) => (
  <Flex
  alignItems="center"
  
  
  width="100%"
  minWidth="130px"
  style={{
    borderRadius:`6px`,
    border: `1px solid ${props.selected ? "#428BF4" : "#C3C3C3"}`, 
    backgroundColor: (false && props.category.id==="0c894c00-9eea-409c-9ce1-02ee84cbaacf") ? "#428BF4" : props.selected ? "#428BF4" : "transparent", 
    marginTop: props.isMobile ? '15px' : !props.isFirst ? '35px' : 'none',
    paddingBottom:  props.isMobile ? '5px' : '10px',
    paddingTop: props.isMobile ? '5px' : '10px',
    cursor: 'pointer'
  }}
  onClick={()=>props.toggleCategory(props.category)}
  >

    <FontAwesomeIcon
    icon={props.selected ? faMinus : faPlus}
    color={(false && props.category.id==="0c894c00-9eea-409c-9ce1-02ee84cbaacf") ? "#FFFFFF" : props.selected ? "#FFFFFF" : "428BF4"}
    style={{marginLeft: '20px', fontSize: props.isMobile ? '18px' : '22px'}}
    />

    <NunitoSans
    color={(false && props.category.id==="0c894c00-9eea-409c-9ce1-02ee84cbaacf") ? "#FFFFFF" : props.selected ? "#FFFFFF" : "428BF4"}
    fontSize={["18px","18px","22px","22px"]}
    style={{marginLeft: '20px'}}
    >
      {props.category.name}
    </NunitoSans>

  </Flex>
);

CompanyTypeForm = connect(
  state => ({
    user: state.resources.detail.user,    
    company: state.resources.detail.company
  }),
  dispatch => ({
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),  
    redirect: bindActionCreators(push, dispatch)
    
  })
)(CompanyTypeForm);

export default reduxForm({
  form: 'companytype',
  validate,
})(CompanyTypeForm);
