import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {Field, reduxForm} from 'redux-form';
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P,
NunitoSansBold,
NunitoSansSemiBold
} from "../../common/typography";
import {
  DashboardSmallCTA
} from "../../common/forms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { faPaperPlane, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

// import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
// import ReactTooltip from 'react-tooltip';

// const [page, setPage] = useState(1);



const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Company name is required';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
 
  return errors;
};

const handleGoal = async(selectedGoal,company, user,props)=>{

  const { updateSettings, redirect } = props;

  if(selectedGoal && selectedGoal.length){
    await updateSettings(company.id,{
      company_goal: selectedGoal
    });
    
    redirect("/industry");
    // redirect("/email-verification");
  } 

  // setPage(!(selectedCategories && selectedCategories.length) ? 3 : pendingConnections.filter(conn=>conn.status==='requested').length ? 4 : sponsoredBrands && sponsoredBrands.length && companyGoal==="receive_info" ? 5 : 6);
}

const BlurWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 10px;
  right: 10px;
  bottom: -18px;
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
`;
const DefaultBlurrable = styled.div`
  width: 100%;
  height: 100%;
  background: #9f9f9f;
`;
const PreviewImg = styled.img`
  height: 100%;
  width: 100%;
`;
const PreviewImageBlurrable = ({src}) => <PreviewImg src={src} />;

let GoalForm = props => {
  const {handleSubmit, dropzoneOnDrop, dropzonePreview, back, singleUserAccount, redirect} = props;
  
  const {user, isSingleUser, isMobile, pendingConnections, sponsoredBrands, industryCategories, updateConnection, createConnection, finalAction,  notifyError,  updateSettings, updateUser, companyGoal,company } = props;

  
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedGoal, setSelectedGoal] = useState(user&&user.company&&user.company.settings&&user.company.settings.company_goal || '');

  return (
    <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    YOUR GOAL
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize="28px"
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                        Choose Your Objective
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize="18px"
                    color="#000000"
                    textAlign="center"
                    style={{marginBottom: isMobile ? '30px' : '70px'}}
                  >
                     Select your primary goal for joining KickPost.
                  </NunitoSansSemiBold>

                  <Flex
                  width="100%"
                  justifyContent='space-between'
                  flexDirection={isMobile ? "column" : "row"}
                  >
                     <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    style={{
                      border: `1px solid #428BF4`,
                      backgroundColor: selectedGoal==='receive_info' ? '#428BF4': 'transparent',
                      cursor: 'pointer'
                    }}
                    onClick={()=>setSelectedGoal('receive_info')}
                    >

                      <FontAwesomeIcon
                      icon={faNewspaper}
                      color={selectedGoal==='receive_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginTop: isMobile ? '20px' : '50px', marginBottom: isMobile ? '15px' : '27px', fontSize: isMobile ? '35px' : '45px'}}
                      />

                      <NunitoSansBold
                      
                      color={selectedGoal==='receive_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginBottom: isMobile ? '20px' :'30px', textAlign: 'center', fontSize:isMobile ? '18px' : "22px", width: '150px'}}
                      >
                        Receive Info from my Network
                      </NunitoSansBold>

                    </Flex>

                    <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    style={{
                      border: `1px solid #428BF4`,
                      backgroundColor: selectedGoal==='share_info' ? '#428BF4': 'transparent',
                      cursor: 'pointer',
                      marginTop: isMobile ? '20px' : 0
                    }}
                    onClick={()=>setSelectedGoal('share_info')}
                    >

                      <FontAwesomeIcon
                      icon={faPaperPlane}
                      color={selectedGoal==='share_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginTop: isMobile ? '20px' : '50px', marginBottom: isMobile ? '15px' : '27px', fontSize: isMobile ? '35px' : '45px'}}
                      />

                      <NunitoSansBold
                      
                      color={selectedGoal==='share_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginBottom: isMobile ? '20px' :'30px', textAlign: 'center', fontSize:isMobile ? '18px' : "22px", width: '150px'}}
                      >
                        Share Info with my Network
                      </NunitoSansBold>

                    </Flex>

                  </Flex>

                  

                  <DashboardSmallCTA
                    width="100%"
                    minWidth="130px"
                    innerHeight="35px"
                    fontSize="16px"
                    disabled={!selectedGoal}
                    onClick={() => handleGoal(selectedGoal,company, user,props)}
                    style={{marginTop: '45px'}}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
  );
};

GoalForm = connect(
  state => ({
    user: state.resources.detail.user,    
    company: state.resources.detail.company
  }),
  dispatch => ({
    fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch)
    
  })
)(GoalForm);

export default reduxForm({
  form: 'goal',
  validate,
})(GoalForm);
