import React, { Component } from "react";
import { VideoAttachmentBubble } from "../../../common/post";
import { Box, Flex } from "../../../common/flexbox";
import { Eyebrow, VideoInputBubble } from "../../../common/forms";
const laptopBubbleWidth = 47.3;

export default class VideoAttachments extends Component {
  render() {
    const {
      addVideo,
      removeVideo,
      updateVideoInput,
      videos,
      videoUrl,
      videoAdded
    } = this.props;
    return (
      <Flex flexDirection="column">
        <Eyebrow ml={"8px"}>VIDEO ATTACHMENT(S)</Eyebrow>
        <Flex
          position="relative"
          justifyContent="space-between"
          flexWrap="wrap"
          pb={"22px"}
          width={[1,1,1]}
        >
          {videos.map((video, i) => {
            return (
              <Box key={i} width={["100%", laptopBubbleWidth + "%"]} mb="20px">
                <VideoAttachmentBubble
                  clickHandler={() => removeVideo(video)}
                  title={video.title}
                  minutes={video.duration}
                  image={video.image}
                  key={i}
                  icon="close"
                  iconMarginLeft="0px" 
                  width={[1,1,1]}
                />
              </Box>
            );
          })}
          <VideoInputBubble
            addVideo={addVideo}
            inputChangeHandler={updateVideoInput}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
          />
        </Flex>
      </Flex>
    );
  }
}
