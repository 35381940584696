import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OnboardingCTA } from '../common/forms';
//utils
import {  getUser } from "../../utils/auth";
import _ from "underscore";
//actions
import { actions } from "../../actions/resources";
import { H1, H4, H2 } from '../common/typography';
import { NunitoSans, NunitoSansBold,  NunitoSansSemiBold } from "../common/typography";
import { Flex } from "../common/flexbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass,  faNewspaper, faGears } from '@fortawesome/free-solid-svg-icons'
import { faRocketLaunch, faCommentSmile, faSackDollar, faCircleNodes, faFileLock } from '@fortawesome/pro-regular-svg-icons';
library.add(faRocketLaunch,faCommentSmile,faMagnifyingGlass, faSackDollar, faCircleNodes, faNewspaper, faGears, faFileLock)

export class AuthStart extends Component {
  state = {  }

  constructor(props) {
    super(props);
  }
  

  _handleSubmit = async (gotUser) => {  
    const { updateUser } = this.props;   
    await updateUser(gotUser.id, {onboarding_completed: true});    
  };
  
  render() {
    const { isMobile} = this.props;
    document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";
  
    return (
        <div style={{height:'100%',display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div id="leftRocket" style={{height:'100%',display: 'flex', flexDirection: 'column'}}>
            <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            alignItems="center"        
            backgroundColor="#10213A"
            >
            <Flex            
            marginTop='10%'
            justifyContent="space-evenly"
            width="100%"
            >
              <span style={{fontSize:"15em",color:"#2FD371"}}> <FontAwesomeIcon icon={faRocketLaunch} /></span>
              
                      
            </Flex>

            <NunitoSans
                fontSize="24px"
                color="#FFFFFF"
                style={{marginTop: "50px",marginBottom: window.innerHeight*.47,textAlign:"center",width:"66%",overflow:"none"}}
                >
                    <span 
                    style={{fontWeight:900}}
                    >
                        Welcome to KickPost!
                    </span><br />
                    {`Thanks for joining, we're excited to have you as a new member on the platform.`}
                </NunitoSans>
              </Flex>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft:'10%', marginTop:'3%', marginBottom:'3%'}}>
             
        <Flex 
            width={[1, 1, 1]} 
            alignItems="left" 
            flexDirection="column">
        <H1 alignSelf={'flex-start'}>Get Started!</H1>
        {/* <NunitoSansSemiBold
          fontSize={["16px","16px","18px","18px"]}
          color="#000000"
          textAlign="left"
          alignItems="left"
          style={{textAlign:'left', marginBottom: isMobile ? '5px' : '25px'}}
        >
            Explore the benefits of your account.
        </NunitoSansSemiBold> */}
  
                    
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginBottom:'1px', marginTop: '2em', textAlign:"left"}}                    
                    >
                          
                      <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faCircleNodes} /></span>CONNECT WITH YOUR PARTNERS
                          
                      <NunitoSansSemiBold
                        fontSize={["16px","16px","18px","18px"]}
                        color="#000000"
                        textAlign="left"
                        style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                      >
                        Join all your industry partners in a single place.
                      </NunitoSansSemiBold>
                    </NunitoSansBold>
  
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          
                <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faNewspaper} /></span>STAY UP TO DATE
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       Browse the latest info in your newsfeed.
                    </NunitoSansSemiBold>
  
  
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          
              <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>ACCESS INFO INSTANTLY
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       Search info from all your partners in one place.
                    </NunitoSansSemiBold>
  
  
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faSackDollar} /></span>EARN REWARDS
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       Earn points and prizes engaging with your partners.
                    </NunitoSansSemiBold>

                    
  
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:"0.65em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faGears} /></span>AUTOMATE INCENTIVES
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       Create or receive incentives with your partners.
                    </NunitoSansSemiBold>

                    
  
                    <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faFileLock} /></span>MANAGE SALES DATA
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       Send or receive automated sales data with your partners.
                    </NunitoSansSemiBold>


  
  
                    {/* <NunitoSansBold
                      fontSize={["22px","22px","20px","20px"]}
                      color="#000000"
                      style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                    >
                          
              <span style={{fontSize:"2em",verticalAlign:"top",padding:"0",paddingRight:".85em",textAlign:"center",marginTop:"auto",color:"#2FD371"}}> <FontAwesomeIcon icon={faCommentSmile} /></span>JUST GETTING STARTED
                    </NunitoSansBold>
                    <NunitoSansSemiBold
                      fontSize={["16px","16px","18px","18px"]}
                      color="#000000"
                      textAlign="left"
                      style={{marginLeft:"4.2em",marginTop: "-1.5em",marginBottom: "1em"}}
                    >
                       
                    </NunitoSansSemiBold> */}
                    {/* <H2 style={{fontSize:"28px", marginTop: "1em", marginBottom:"0"}} alignSelf={'flex-start'}>We're adding new features all the time.</H2> */}
                    
                    <Flex
                      justifyContent={["center", "flex-start"]}
                      alignItems="center"
                      width={[1, 1, 1, 1]}
                    >
                      <OnboardingCTA 
                        my={["2vh", "2vh", "4vh", "4vh"]}
                        width={[0.75, 0.75, 0.675, 0.675]}
                        onClick={() => this._handleSubmit(getUser())}                
                        type="submit">
                          START KICKPOSTING!
                      </OnboardingCTA>
                    </Flex>
      </Flex>
            </div>
          </div>
        </div>
       );

  }
}

export default connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    updateUser: bindActionCreators(actions.patchUser, dispatch)
  })
)(AuthStart);
