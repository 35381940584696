import React from 'react';
import Select, {
  components
} from 'react-select';

import {
    ImageTreatment
  } from "../../../common/ui";

  import {defaultCompany} from "../../../../utils/defaultLogos";
  import { getUser } from "../../../../utils/auth";

const Control = ({ children, ...props }) => {
  const { value } = props.selectProps;
  console.log(props, "CONTROLPROPS")
  return (
    <components.Control {...props}>
      <ImageTreatment
        img={value.logo || defaultCompany((value.value || getUser().company_id), value.label)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={4}
        shadowOffset={0}
        padding={4}
        height={36}
        width={36}
      />
      {children}
    </components.Control>
  );
};

const CustomSelectProps = (props) => {

  let {brandOptions, selectedBrand, handleBrandSelection} = props;
  if(selectedBrand && typeof selectedBrand.value !== "string"){
    selectedBrand = {
      "value": selectedBrand.label, 
      "label": selectedBrand.label, 
      "logo": selectedBrand.logo
    };
  }
  
  

  const styles = {
    control: (css) => ({ ...css, paddingLeft: '1rem', paddingBottom: '5px', paddingTop: '5px', minWidth: '300px'}),
    menu: (css) => ({ ...css, zIndex: 99999}),
  };

  const handleChange = value =>{
    handleBrandSelection(value || brandOptions[0]);
  }
  
  return (
    <Select
      {...props}
      components={{ Control }}
      isSearchable
      name="branddropdown"
      options={brandOptions}
      styles={styles}
      value={selectedBrand}
      onChange={handleChange}
      noOptionsMessage={()=> "No Brands"}
    />
  );
  return (
    <Select
      {...props}
      components={{ Control }}
      isSearchable
      name="branddropdown"
      options={brandOptions}
      styles={styles}
      value={selectedBrand || ""}
      onChange={handleChange}
      noOptionsMessage={()=> "No Brands"}
    />
  );
};

export default CustomSelectProps;