import React, { Component } from "react";
import styled from "styled-components";
import { width, lineHeight, space, color, fontSize } from "styled-system";
import { Box, Flex, OverflowHiddenBox } from "../common/flexbox";
import { AbsoluteDiv } from "../common/absolute";
import {
  H1Condensed,
  H3Condensed,
  NunitoSans,
  NunitoSansSemiBold,
  H5,
  H6,
} from "../common/typography";
// import DanteComponent from "../common/dante";
import DraftComponent from "../common/reactDraft";
import axios from "axios";
import {
  BGImgContain,
  BGImgCover,
  BlurWrapper,
  OneSixDash,
  LightGrayCTA,
  GradientUnderlayRightToLeft,
  AccessoryButton,
  FlexBubble,
  ImageTreatment,
} from "../common/ui";

import ReactPlayer from "react-player";
import { VideoEmbedMobile, VideoEmbed } from "../common/ui";
import { UnderlineLink } from "../common/navigation";

const StretchedImg = styled.img`
  height: 100%;
  width: 100%;
`;

const ImageBlur = ({ src }) => <StretchedImg src={src} />;

export const SquareImageTreatmentSmall = ({ ...props }) => (
  <Box position="relative">
    <Flex
      borderRadius={props.borderRadius}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={`${props.borderRadius}px`}
      alignItems="center"
      justifyContent="center"
      width={props.width}
      height={props.width}
    >
      <AbsoluteDiv
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={5}
        m={`${props.borderRadius}px`}
        top={0}
        bottom={0}
        right={0}
        left={0}
        bg="white"
      >
        {props.imageInset ? (
          <BGImgContain
            height={`calc(100% - ${props.imageInset * 2}px)`}
            width={`calc(100% - ${props.imageInset * 2}px)`}
            image={props.img}
          />
        ) : (
          <BGImgContain height={"100%"} width={"100%"} image={props.img} />
        )}
      </AbsoluteDiv>
    </Flex>
    <BlurWrapper
      opacity={0.3}
      blur="10px"
      top={props.borderRadius + props.width * 0.15}
      left={props.borderRadius}
      right={props.borderRadius}
      bottom={-1 * (props.width * 0.15)}
    >
      <ImageBlur src={props.img} />
    </BlurWrapper>
  </Box>
);

export const SquareImageTreatmentLarge = ({ ...props }) => (
  <Box position="relative">
    <Flex
      borderRadius={props.borderRadius}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={`${props.borderRadius}px`}
      alignItems="center"
      justifyContent="center"
      width={props.size}
      height={props.size}
    >
      <AbsoluteDiv
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={5}
        m={`${props.borderRadius}px`}
        top={0}
        bottom={0}
        right={0}
        left={0}
        bg="white"
      >
        {props.imageInset ? (
          <BGImgContain
            height={`calc(100% - ${props.imageInset * 2}px)`}
            width={`calc(100% - ${props.imageInset * 2}px)`}
            image={props.img}
          />
        ) : (
          <BGImgContain height={"100%"} width={"100%"} image={props.img} />
        )}
      </AbsoluteDiv>
    </Flex>
    <BlurWrapper
      opacity={0.3}
      blur="15px"
      top={props.borderRadius + props.size * 0.1}
      left={(props.borderRadius * 3) / 4}
      right={(props.borderRadius * 3) / 4}
      bottom={-1 * (props.size * 0.1 - props.borderRadius / 2)}
    >
      <ImageBlur src={props.img} />
    </BlurWrapper>
  </Box>
);

export const VideoImageTreatmentSmall = ({ ...props }) => (
  <Box position="relative">
    <Flex
      borderRadius={props.borderRadius}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={`${props.borderRadius}px`}
      alignItems="center"
      justifyContent="center"
      width={props.width}
      height={props.height}
    >
      <AbsoluteDiv
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={5}
        m="5px"
        top={0}
        bottom={0}
        right={0}
        left={0}
        bg="white"
      >
        {props.imageInset ? (
          <BGImgCover
            height={`calc(100% - ${props.imageInset * 2}px)`}
            width={`calc(100% - ${props.imageInset * 2}px)`}
            image={props.img}
          />
        ) : (
          <BGImgCover height={"100%"} width={"100%"} image={props.img} />
        )}
      </AbsoluteDiv>
    </Flex>
    <BlurWrapper
      opacity={0.3}
      blur="10px"
      top={props.borderRadius + props.height * 0.15}
      left={props.borderRadius}
      right={props.borderRadius}
      bottom={-1 * (props.height * 0.15)}
    >
      <ImageBlur src={props.img} />
    </BlurWrapper>
  </Box>
);

export const CompressedBody = styled.div`
  ${width}
  ${space}
  ${fontSize}
  ${color}
  ${lineHeight}
  font-display: swap;
  font-family: NunitoSans;
  font-weight: normal;
  position: relative;
`;

export const GradientOverflowUpward = styled(AbsoluteDiv)`
  background-image: linear-gradient(
    -179deg,
    rgba(255, 255, 255, 0) 3%,
    #ffffff 78%
  );
  z-index: 1000;
  position: absolute;
`;

export const FileAttachmentRow = ({ ...props }) => (
  <Flex
    position="relative"
    alignItems="center"
    justifyContent="center"
    width={1}
    height={50}
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <Flex alignItems="center" justifyContent="spaceBetween" width={1}>
      <Box mr={16}>
        <SquareImageTreatmentSmall
          imageInset={5}
          borderRadius={5}
          width={40}
          img={props.image}
        />
      </Box>
      <OverflowHiddenBox height="18px">
        <H3Condensed>{props.filename}</H3Condensed>
      </OverflowHiddenBox>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="44px"
        gradientWidth="43px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="3px" bottom="1px">
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <AccessoryButton
          clickHandler={props.clickHandler}
          color="#c3c3c3"
          iconSize={13}
          iconName={props.icon}
        />
      </Flex>
    </AbsoluteDiv>
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </Flex>
);

export const FileAttachmentBubble = ({ ...props }) => (
  <FlexBubble
    bg="white"
    borderRadius="8px"
    position="relative"
    alignItems="center"
    justifyContent="center"
    width={1}
    height={50}
    cursor="pointer"
  >
    <Flex alignItems="center" justifyContent="spaceBetween" width={1}>
      <Box mx={0}>
        <SquareImageTreatmentSmall
          imageInset={5}
          borderRadius={5}
          width={40}
          img={props.image}
        />
      </Box>
      <OverflowHiddenBox height="18px">
        <H3Condensed>{props.filename}</H3Condensed>
      </OverflowHiddenBox>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="49px"
        gradientWidth="43px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="12px" bottom="1px">
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <AccessoryButton
          clickHandler={props.clickHandler}
          color="#c3c3c3"
          iconSize={13}
          iconName={props.icon}
        />
      </Flex>
    </AbsoluteDiv>
  </FlexBubble>
);

export const VideoAttachmentRow = ({ ...props }) => (
  <Flex
    position="relative"
    alignItems="center"
    justifyContent="center"
    width={1}
    height={50}
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <Flex alignItems="center" justifyContent="spaceBetween" width={1}>
      <Box mr={16}>
        <VideoImageTreatmentSmall
          borderRadius={5}
          width={50}
          height={38}
          img={props.image}
        />
      </Box>
      <Flex flexDirection="column">
        <OverflowHiddenBox height="18px">
          <H3Condensed>{props.title}</H3Condensed>
        </OverflowHiddenBox>
        <Box mt={"6px"}>
          <H6>{props.minutes} Minutes</H6>
        </Box>
      </Flex>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="44px"
        gradientWidth="43px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="3px" bottom="1px">
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <AccessoryButton
          clickHandler={props.clickHandler}
          iconMarginLeft={props.iconMarginLeft}
          color="#c3c3c3"
          iconSize={12}
          iconName={props.icon}
        />
      </Flex>
    </AbsoluteDiv>
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </Flex>
);

export const VideoAttachmentBubble = ({ ...props }) => (
  <FlexBubble
    bg="white"
    borderRadius="8px"
    position="relative"
    alignItems="center"
    justifyContent="center"
    width={[1, 1, 1]}
    height={50}
    cursor="pointer"
  >
    <Flex
      alignItems="center"
      justifyContent="spaceBetween"
      width={[0.8, 0.8, 0.8]}
    >
      <Box mx={16}>
        <VideoImageTreatmentSmall
          borderRadius={5}
          width={50}
          height={38}
          img={props.image}
        />
      </Box>
      <Flex flexDirection="column">
        <OverflowHiddenBox height="18px">
          <H3Condensed>{props.title}</H3Condensed>
        </OverflowHiddenBox>
        <Box mt={"6px"}>
          <H6>{props.minutes} Minutes</H6>
        </Box>
      </Flex>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="49px"
        gradientWidth="43px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="12px" bottom="1px">
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <AccessoryButton
          clickHandler={props.clickHandler}
          iconMarginLeft={props.iconMarginLeft}
          color="#c3c3c3"
          iconSize={12}
          iconName={props.icon}
        />
      </Flex>
    </AbsoluteDiv>
  </FlexBubble>
);

// export class FeedPostMobile extends Component {
//   state = {
//     showVideo: null
//   };

//   _playVideo = async video => {
//     this.setState({
//       showVideo: video
//     });
//   };

//   _processFileDownload = async file => {
//     const { companyId } = this.props;
//     let win = window.open(window.location, "_blank");
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_URL}/s3/signed-url`,
//       {
//         params: {
//           filename: `posts/${companyId}/${file.filename}`,
//           filetype: file.filetype
//         }
//       }
//     );

//     if (response.data.signed_url) {
//       win.location.href = response.data.signed_url;
//       win.focus();
//     }
//   };

//   render() {
//     const { showVideo } = this.state;
//     return (
//       <UnderlineLink to={`/dashboard/post/${this.props.postId}`}>
//       <Flex flexDirection="column" py={24}>
//         <Flex
//           justifyContent="space-between"
//           flexDirection="row"
//           position="relative"
//           width={[1]}
//         >
//           <Box position="relative" mt="5px" width={2 / 3}>
//             <H1Condensed>{this.props.title}</H1Condensed>
//             <H5 pt="5px" color="darkerGray">
//                 {this.props.companyName}
//               </H5>
//             {this.props.expiresSoon ? (
//               <AbsoluteDiv right="0px" bottom="2px">
//                 <NunitoSans fontSize="7px" fontWeight={400} color="#F84F00">
//                   EXPIRES {this.props.endDate}
//                 </NunitoSans>
//               </AbsoluteDiv>
//             ) : (
//               <AbsoluteDiv right="0px" bottom="2px">
//                 <NunitoSans fontSize="7px" fontWeight={400} color="#F84F00">
//                   EXPIRES {this.props.endDate}
//                 </NunitoSans>
//               </AbsoluteDiv>
//             )}
//           </Box>

//             <Box>
//               <SquareImageTreatmentSmall
//                 borderRadius={5}
//                 width={44}
//                 img={this.props.companyLogo}
//               />
//             </Box>

//         </Flex>
//         <Flex position="relative" flexDirection="column">
//           <OverflowHiddenBox position="relative" maxHeight="66px" mt={23}>
//             <CompressedBody fontSize={12}>
//               <DanteComponent
//                 readOnly={true}
//                 style={{ "z-index": "3" }}
//                 content={this.props.content}
//               />
//             </CompressedBody>
//             <GradientOverflowUpward bottom={0} left={0} right={0} height={50} />
//           </OverflowHiddenBox>
//           {this.props.fileAttachments&&this.props.fileAttachments.map((file, i) => {
//             return (
//               <FileAttachmentRow
//                 clickHandler={() => this._processFileDownload(file)}
//                 icon="rightarrow"
//                 link={file.url}
//                 filename={file.title}
//                 image={file.image}
//                 key={i}
//               />
//             );
//           })}
//           {this.props.videoAttachments&&this.props.videoAttachments.map((video, i) => {
//             return (
//               <VideoAttachmentRow
//                 clickHandler={() => this._playVideo(video)}
//                 iconMarginLeft="3px"
//                 icon="play"
//                 link={video.link}
//                 title={video.title}
//                 image={video.image}
//                 minutes={video.duration}
//                 key={i}
//               />
//             );
//           })}
//           {showVideo ? (
//             <VideoEmbedMobile
//               clickHandler={() => {
//                 this.setState({ showVideo: false });
//               }}
//             >
//               <ReactPlayer
//                 className="react-player-mobile"
//                 width="100%"
//                 height="100%"
//                 url={showVideo.url}
//               />
//             </VideoEmbedMobile>
//           ) : null}
//           <Flex
//             height="52px"
//             width="100%"
//             justifyContent="center"
//             flexDirection="row"
//             alignItems="center"
//           >
//             <Flex width="49%" flexDirection="column">
//               <H6 color="midGray" textAlign="center">
//                 {this.props.timeago}
//               </H6>
//               <H6 color="midGray" textAlign="center" pt="4px">
//                 by &nbsp;

//                   {this.props.postedBy}

//               </H6>
//             </Flex>
//             {/* <OneSixDash mt="3px" width="1px" height="34px" />
//             <Flex width="49%" justifyContent="center">
//               <Box width="80%" height="34px" maxWidth="145px">
//                 <LightGrayCTA
//                   link={`/dashboard/post/${this.props.postId}`}
//                   fontSize={17}
//                 >
//                   VIEW POST
//                 </LightGrayCTA>
//               </Box>
//             </Flex> */}
//           </Flex>
//           <OneSixDash
//             position="absolute"
//             bottom="-1px"
//             left="0px"
//             right="0px"
//             height="1px"
//           />
//         </Flex>
//       </Flex>
//       </UnderlineLink>
//     );
//   }
// }

export class FeedPostMobile extends Component {
  state = {
    showVideo: null,
    viewMore: false,
  };

  _playVideo = async (video) => {
    this.setState({
      showVideo: video,
    });
  };

  _processFileDownload = async (file) => {
    const { companyId } = this.props;
    let win = window.open(window.location, "_blank");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/s3/signed-url`,
      {
        params: {
          filename: `posts/${companyId}/${file.filename}`,
          filetype: file.filetype,
        },
      }
    );

    if (response.data.signed_url) {
      win.location.href = response.data.signed_url;
      win.focus();
    }
  };

  render() {
    const { showVideo, viewMore } = this.state;
    //     let fullText = "";
    // console.log(this.props.content.blocks)
    //     if(this.props.content.blocks.length > 1){
    //        fullText = this.props.content.blocks.map(item => <CompressedBody color="#000" pb="5px" lineHeight={"20px"}  fontSize={14}>

    //       {item.text}
    //     </CompressedBody>)

    //     }

    let content = { ...this.props.content };

    let firstContent = { ...content.blocks[0] };

    firstContent.text =
      firstContent.text.trim().length > 100
        ? firstContent.text.trim().substring(0, 100) + "..."
        : firstContent.text.trim();

    content.blocks = [firstContent];

    return (
      <UnderlineLink to={`/dashboard/post/${this.props.postId}`}>
        <Flex
          flexDirection="column"
          py={20}
          px={20}
          style={{
            borderStyle: "solid",
            borderWidth: "0.5px",
            marginBottom: "14px",
            color: "#D3D3D3",
          }}
          className="post-row"
        >
          <Flex
            justifyContent="space-between"
            flexDirection="row"
            position="relative"
            width={[1]}
          >
            {/* <ImageTreatment
            img={this.props.postedByImg}
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={50}
            innerBorderRadius={40}
            shadowOffset={0}
            padding={3}
            height={70}
            width={70}
          /> */}

            <Box position="relative " mt="10px" width={0.72}>
              {/* <UnderlineLink className='post-text' to={`/dashboard/post/${this.props.postId}`}> */}

              <NunitoSans
                fontSize={["16px", "16px", "21px", "26px"]}
                fontWeight="bold"
                color="#000000"
                className="post-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "35vw",
                  whiteSpace: "nowrap",
                }}
              >
                {this.props.title}
              </NunitoSans>
              {/* </UnderlineLink> */}
              <Flex justifyContent="space-between">
                <NunitoSans fontSize="15px" pt="12px" color="#A3A99E">
                  By&nbsp;
                  {this.props.postedBy}
                  &nbsp;at&nbsp;
                  {this.props.companyName}
                </NunitoSans>

                {/* <AbsoluteDiv right="2px" bottom="0px"> */}
                {/* <NunitoSans  pt="12px" fontSize="15px" fontWeight={400} color="#A3A99E">
                Expires  {this.props.endDate}
                </NunitoSans> */}
                {/* </AbsoluteDiv> */}
              </Flex>
            </Box>

            <Box mr="-5px">
              <SquareImageTreatmentLarge
                borderRadius={10}
                size={55}
                img={this.props.companyLogo}
              />
            </Box>
          </Flex>
          <Flex position="relative" flexDirection="column">
            <OverflowHiddenBox
              position="relative"
              // maxHeight="135px"
              mt={16}
            >
              <CompressedBody
                color="#000"
                pb="5px"
                lineHeight={"20px"}
                fontSize={14}
              >
                <DraftComponent
                  id="DraftComponentContent"
                  readOnly={true}
                  initialContent={content}
                />
              </CompressedBody>

              {/* <GradientOverflowUpward bottom={0} left={0} right={0} height={50} /> */}
            </OverflowHiddenBox>
            {/* {fullText ? 
          <Flex
            position="relative"
            justifyContent="center"
          >
          <NunitoSans
                fontSize={15}
                fontWeight="normal"
                color="gray"
                textAlign="center"
                onClick={()=> this.setState({viewMore: !viewMore})}
          >
                <a>{viewMore ? 'View Less' : 'View More'}</a>
          </NunitoSans>
          </Flex>
          : 
          null
          } */}
            <Flex
              position="relative"
              justifyContent="flex-start"
              flexWrap="wrap"
              // pb={"10px"}
            >
              {this.props.fileAttachments !== null &&
              this.props.fileAttachments.length > 0
                ? this.props.fileAttachments.map((file, i) => {
                    return (
                      <Box
                        key={i}
                        width="33%"
                        mt="10px"
                        onClick={() => this._processFileDownload(file)}
                      >
                        <FileAttachmentBubble
                          // clickHandler={() => this._processFileDownload(file)}
                          icon="rightarrow"
                          link={file.link}
                          filename={file.title}
                          image={file.image}
                          key={i}
                        />
                      </Box>
                    );
                  })
                : null}
              {this.props.videoAttachments.map((video, i) => {
                return (
                  <Box
                    key={i}
                    width="33%"
                    mt="10px"
                    onClick={() => this._playVideo(video)}
                  >
                    <VideoAttachmentBubble
                      // clickHandler={() => this._playVideo(video)}
                      iconMarginLeft="3px"
                      icon="play"
                      link={video.url}
                      title={video.title}
                      image={video.image}
                      minutes={video.duration}
                      key={i}
                    />
                  </Box>
                );
              })}
              {showVideo ? (
                <VideoEmbed
                  clickHandler={() => {
                    this.setState({ showVideo: false });
                  }}
                >
                  <ReactPlayer url={showVideo.url} />
                </VideoEmbed>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </UnderlineLink>
    );
  }
}

export class FeedPostLaptop extends Component {
  state = {
    showVideo: null,
    viewMore: false,
  };

  _playVideo = async (video) => {
    this.setState({
      showVideo: video,
    });
  };

  _processFileDownload = async (file) => {
    const { companyId } = this.props;
    let win = window.open(window.location, "_blank");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/s3/signed-url`,
      {
        params: {
          filename: `posts/${companyId}/${file.filename}`,
          filetype: file.filetype,
        },
      }
    );

    if (response.data.signed_url) {
      win.location.href = response.data.signed_url;
      win.focus();
    }
  };

  render() {
    const { showVideo, viewMore } = this.state;
    //     let fullText = "";
    // console.log(this.props.content.blocks)
    //     if(this.props.content.blocks.length > 1){
    //        fullText = this.props.content.blocks.map(item => <CompressedBody color="#000" pb="5px" lineHeight={"20px"}  fontSize={14}>

    //       <DraftComponent readOnly={true} initialContent={company.description}/>
    //     </CompressedBody>)

    //     }

    let content = { ...this.props.content };

    let firstContent = { ...content.blocks[0] };

    firstContent.text =
      firstContent.text.trim().length > 100
        ? firstContent.text.trim().substring(0, 100) + "..."
        : firstContent.text.trim();

    content.blocks = [firstContent];

    return (
      <Flex
        flexDirection="column"
        py={20}
        px={20}
        style={{
          borderStyle: "solid",
          borderWidth: "0.5px",
          marginBottom: "14px",
          color: "#D3D3D3",
        }}
        className="post-row"
      >
        {this.props.brandName ? (
          <NunitoSansSemiBold
            fontSize="10px"
            color="#000000"
            style={{
              backgroundColor: "#ACEDC6",
              padding: "1px 5px 0 5px",
              width: "fit-content",
            }}
          >
            {`On Behalf Of: ${this.props.brandName}`}
          </NunitoSansSemiBold>
        ) : null}
        <Flex
          justifyContent="space-between"
          flexDirection="row"
          position="relative"
          width={[1]}
        >
          {/* <ImageTreatment
            img={this.props.postedByImg}
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={50}
            innerBorderRadius={40}
            shadowOffset={0}
            padding={3}
            height={70}
            width={70}
          /> */}

          <Box position="relative " mt="10px" width={0.72}>
            <UnderlineLink
              className="post-text"
              to={`/dashboard/post/${this.props.postId}`}
            >
              {/* <H1Condensed>{this.props.title}</H1Condensed> */}
              <NunitoSans
                fontSize={["16px", "16px", "21px", "26px"]}
                fontWeight="bold"
                color="#000000"
                className="post-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "35vw",
                  whiteSpace: "nowrap",
                }}
              >
                {this.props.title}
              </NunitoSans>
            </UnderlineLink>
            <Flex justifyContent="space-between">
              <NunitoSans fontSize="15px" pt="12px" color="#A3A99E">
                By&nbsp;
                <UnderlineLink
                  fontWeight={400}
                  to={`/dashboard/profile/${this.props.userId}`}
                >
                  {this.props.postedBy}
                </UnderlineLink>
                &nbsp;at&nbsp;
                {this.props.isSingleUserCompany ? (
                  this.props.companyName
                ) : (
                  <UnderlineLink
                    to={`/dashboard/company/${this.props.companyId}`}
                  >
                    {this.props.companyName}
                  </UnderlineLink>
                )}
              </NunitoSans>

              {/* <AbsoluteDiv right="2px" bottom="0px"> */}
              <NunitoSans
                pt="12px"
                fontSize="15px"
                fontWeight={400}
                color="#A3A99E"
              >
                Expires {this.props.endDate}
              </NunitoSans>
              {/* </AbsoluteDiv> */}
            </Flex>
          </Box>
          <UnderlineLink
            to={
              this.props.isSingleUserCompany
                ? `/dashboard/profile/${this.props.userId}`
                : `/dashboard/company/${this.props.companyId}`
            }
          >
            <Box mr="-5px">
              <SquareImageTreatmentLarge
                borderRadius={10}
                size={55}
                img={this.props.companyLogo}
              />
            </Box>
          </UnderlineLink>
        </Flex>
        <Flex position="relative" flexDirection="column">
          <OverflowHiddenBox
            position="relative"
            // maxHeight="135px"
            mt={16}
          >
            <CompressedBody
              color="#000"
              pb="5px"
              lineHeight={"20px"}
              fontSize={14}
            >
              {/* <DanteComponent
                readOnly={true}
                style={{ "z-index": "3" }}
                content={this.props.content}
              /> */}
              <DraftComponent
                id="DraftComponentContent"
                readOnly={true}
                initialContent={content}
              />
            </CompressedBody>
            <UnderlineLink
              className="post-text"
              to={`/dashboard/post/${this.props.postId}`}
            >
              <NunitoSans
                fontSize={15}
                fontWeight="normal"
                color="gray"
                textAlign="center"
                onClick={() => this.setState({ viewMore: !viewMore })}
              >
                View Post
                {/* <a>View Post</a> */}
              </NunitoSans>
            </UnderlineLink>

            {/* <GradientOverflowUpward bottom={0} left={0} right={0} height={50} /> */}
          </OverflowHiddenBox>
          {/* {fullText ? 
          <Flex
            position="relative"
            justifyContent="center"
          >
          <NunitoSans
                fontSize={15}
                fontWeight="normal"
                color="gray"
                textAlign="center"
                onClick={()=> this.setState({viewMore: !viewMore})}
          >
                <a>{viewMore ? 'View Less' : 'View More'}</a>
          </NunitoSans>
          </Flex>
          : 
          null
          } */}
          <Flex position="relative" justifyContent="flex-start" flexWrap="wrap">
            {this.props?.fileAttachments?.map((file, i) => {
              return (
                <Box
                  key={i}
                  width="33%"
                  mt="10px"
                  onClick={() => this._processFileDownload(file)}
                >
                  <FileAttachmentBubble
                    // clickHandler={() => this._processFileDownload(file)}
                    icon="rightarrow"
                    link={file.link}
                    filename={file.title}
                    image={file.image}
                    key={i}
                  />
                </Box>
              );
            })}
            {this.props?.videoAttachments?.map((video, i) => {
              return (
                <Box
                  key={i}
                  width="33%"
                  mt="10px"
                  onClick={() => this._playVideo(video)}
                >
                  <VideoAttachmentBubble
                    // clickHandler={() => this._playVideo(video)}
                    iconMarginLeft="3px"
                    icon="play"
                    link={video.url}
                    title={video.title}
                    image={video.image}
                    minutes={video.duration}
                    key={i}
                  />
                </Box>
              );
            })}
            {showVideo ? (
              <VideoEmbed
                clickHandler={() => {
                  this.setState({ showVideo: false });
                }}
              >
                <ReactPlayer url={showVideo.url} />
              </VideoEmbed>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export const FeedIncentiveMobile = (props) => (
  <UnderlineLink to={`/dashboard/incentive/${props.incentiveId}`}>
    <Flex
      flexDirection="column"
      py={20}
      px={20}
      style={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        marginBottom: "14px",
        color: "#D3D3D3",
        borderLeft: `6px solid ${
          props.isPartnerIncentive ? "#F7C34F" : "#428BF4"
        }`,
      }}
      className={
        props.isPartnerIncentive ? "partner-incentive-row" : "incentive-row"
      }
    >
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        position="relative"
        width={[1]}
      >
        {/* <UnderlineLink to={`/dashboard/profile/${props.userId}`}>

    <ImageTreatment
      img={props.postedByImg}
      blurRadius={"0px"}
      imageInset={0}
      borderRadius={50}
      innerBorderRadius={40}
      shadowOffset={0}
      padding={3}
      height={70}
      width={70}
    />
    </UnderlineLink> */}
        <Box position="relative " mt="10px" width={0.72}>
          <NunitoSans
            fontSize="13px"
            fontWeight="bold"
            color={props.isPartnerIncentive ? "#D5A800" : "#428BF4"}
            className={
              props.isPartnerIncentive
                ? "partner-incentive-text"
                : "incentive-text"
            }
          >
            {`${props.isPartnerIncentive ? "PARTNER " : ""}INCENTIVE - ${
              props.type === "onetoone"
                ? "ONE TO ONE"
                : props.type === "increment"
                ? "INCREMENT"
                : props.type === "volume"
                ? "VOLUME"
                : props.type === "tier"
                ? "TIER"
                : "QUICK PAY"
            }`}
          </NunitoSans>
          {/* <UnderlineLink className='incentive-text' to={`/dashboard/incentive/${props.incentiveId}`}> */}
          <NunitoSans
            fontSize={["16px", "16px", "21px", "26px"]}
            fontWeight="bold"
            color="#000000"
            className={
              props.isPartnerIncentive
                ? "partner-incentive-text"
                : "incentive-text"
            }
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "30vw",
              whiteSpace: "nowrap",
            }}
          >
            {props.title}
          </NunitoSans>
          {/* </UnderlineLink> */}
          <NunitoSans fontSize="15px" pt="12px" color="#A3A99E">
            By&nbsp;
            {props.postedBy}
            &nbsp;at&nbsp;
            {props.companyName}
          </NunitoSans>

          {props.type !== "quickpay" ? (
            <NunitoSans
              fontSize="15px"
              fontWeight={400}
              color="#A3A99E"
              style={{ marginTop: "5px" }}
            >
              {`${props.startDate} - ${props.endDate} ${
                props.status !== "active"
                  ? "(" + capitalizeFirstLetter(props.status) + ")"
                  : ""
              }`}
            </NunitoSans>
          ) : null}
        </Box>

        <Box mr="-5px">
          <SquareImageTreatmentLarge
            borderRadius={10}
            size={55}
            img={props.companyLogo}
          />
        </Box>
      </Flex>
    </Flex>
  </UnderlineLink>
);

export const FeedIncentiveLaptop = (props) => (
  <Flex
    flexDirection="column"
    py={20}
    px={20}
    style={{
      borderStyle: "solid",
      borderWidth: "0.5px",
      marginBottom: "14px",
      color: "#D3D3D3",
      borderLeft: `6px solid ${
        props.isPartnerIncentive ? "#F7C34F" : "#428BF4"
      }`,
    }}
    className={
      props.isPartnerIncentive ? "partner-incentive-row" : "incentive-row"
    }
  >
    <Flex
      justifyContent="space-between"
      flexDirection="row"
      position="relative"
      width={[1]}
    >
      <UnderlineLink to={`/dashboard/profile/${props.userId}`}>
        <ImageTreatment
          img={props.postedByImg}
          blurRadius={"0px"}
          imageInset={0}
          borderRadius={50}
          innerBorderRadius={40}
          shadowOffset={0}
          padding={3}
          height={70}
          width={70}
        />
      </UnderlineLink>
      <Box position="relative " mt="10px" width={0.72}>
        <NunitoSans
          fontSize="13px"
          fontWeight="bold"
          color={props.isPartnerIncentive ? "#D5A800" : "#428BF4"}
          className={
            props.isPartnerIncentive
              ? "partner-incentive-text"
              : "incentive-text"
          }
        >
          {`${props.isPartnerIncentive ? "PARTNER " : ""}INCENTIVE - ${
            props.type === "onetoone"
              ? "ONE TO ONE"
              : props.type === "increment"
              ? "INCREMENT"
              : props.type === "volume"
              ? "VOLUME"
              : props.type === "tier"
              ? "TIER"
              : "QUICK PAY"
          }`}
        </NunitoSans>
        <UnderlineLink
          className={
            props.isPartnerIncentive
              ? "partner-incentive-text"
              : "incentive-text"
          }
          to={`/dashboard/incentive/${props.incentiveId}`}
        >
          <NunitoSans
            fontSize={["16px", "16px", "21px", "26px"]}
            fontWeight="bold"
            color="#000000"
            className={
              props.isPartnerIncentive
                ? "partner-incentive-text"
                : "incentive-text"
            }
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "30vw",
              whiteSpace: "nowrap",
            }}
          >
            {props.title}
          </NunitoSans>
        </UnderlineLink>
        <NunitoSans fontSize="15px" pt="12px" color="#A3A99E">
          By&nbsp;
          <UnderlineLink
            fontWeight={400}
            to={`/dashboard/profile/${props.userId}`}
          >
            {props.postedBy}
          </UnderlineLink>
          &nbsp;at&nbsp;
          <UnderlineLink to={`/dashboard/company/${props.companyId}`}>
            {props.companyName}
          </UnderlineLink>
        </NunitoSans>

        {props.type !== "quickpay" ? (
          <NunitoSans
            fontSize="15px"
            fontWeight={400}
            color="#A3A99E"
            style={{ marginTop: "5px" }}
          >
            {`${props.startDate} - ${props.endDate} ${
              props.status !== "active"
                ? "(" + capitalizeFirstLetter(props.status) + ")"
                : ""
            }`}
          </NunitoSans>
        ) : null}
      </Box>
      <UnderlineLink to={`/dashboard/company/${props.companyId}`}>
        <Box mr="-5px">
          <SquareImageTreatmentLarge
            borderRadius={10}
            size={55}
            img={props.companyLogo}
          />
        </Box>
      </UnderlineLink>
    </Flex>
  </Flex>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function numberParser(number) {
  return new Intl.NumberFormat("en-US").format(number);
}
