import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { actions } from "actions/resources";
// import { getUser } from "../../utils/auth";
import Switch from "react-switch";
import styled from "styled-components";
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import {
  space,
  width,
  fontSize,
  color,
  height,
  colorStyle,
  borders,
  fontWeight,
  lineHeight,
  borderRadius,
  alignSelf,
  padding,
  paddingLeft,
  borderColor,
  display
} from "styled-system";
import {
  Box,
  Flex,
  IconBox,
  OverflowHiddenBox
} from "../common/flexbox";
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold, NunitoSansLight, NunitoSansExtraBold } from "../common/typography";
import {
  FourFourDottedWith8pxCornerRadius,
  BlurWrapper,
  ThickPixelDash,
  AccessoryButton,
  SearchFilterFlag,
  ImageTreatment,
  UnderlayShadow,
  OverlayShadow
} from "../common/ui";
import ImageCrop from "../common/imageCrop";
import { AbsoluteDiv } from "../common/absolute";
import * as moment from "moment";
import { SingleDatePicker } from "react-dates";
// import DanteComponent from "../common/dante";
import DraftComponent from "../common/reactDraft";

import { defaultCompany } from "../../utils/defaultLogos";

import oneSixSquareImage from "../../images/one-six-square.png";
import oneSixSquareImageBottomRight from "../../images/one-six-square-bottom-right.png";

import { VERTICAL_ORIENTATION, ANCHOR_LEFT } from "react-dates/constants";
import isInclusivelyAfterDay from "react-dates/src/utils/isInclusivelyAfterDay";
//import { SingleDatePickerPhrases } from "react-dates/src/defaultPhrases";

import { debounce } from "lodash";

import { colors } from "../../utils/theme";
import omit from 'lodash/omit';
import SingleDatePickerShape from 'react-dates/src/shapes/SingleDatePickerShape';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';


const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,

  ...omit(SingleDatePickerShape, [
    'date',
    'onDateChange',
    'focused',
    'onFocusChange',
  ]),
};

export const InputStyled = styled.input`
  ${borderRadius}
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  placeholder:${props => props.placeholder ? props.placeholder : ""};
  font-display: swap; font-family:NunitoSans;
`;

export const TextAreaStyled = styled.textarea`
  ${borderRadius}
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  placeholder:${props => props.placeholder ? props.placeholder : ""};
  font-display: swap; font-family:NunitoSansLight;
  resize: vertical;
`;

export const SwitchInput = (field) => (
  <Switch
    checked={(field.input.value)}
    onChange={field.input.onChange}
    onColor={field.onColor}
    offColor={field.offColor}
    height={field.height || 32}
    width={field.width || 64}
    disabled={field.disabled}
  />
)

export const SelectStyled = styled.select`
  ${borderRadius}
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  font-display: swap; font-family:NunitoSansLight;
`;

export const Input = props => (
  <InputStyled
    {...props}
    autoComplete={props.autoComplete}
    border={0}
    bg={props.colorStyle || "inputBackgroundGray"}
    height={[37, 49, 49, 49]}
    fontWeight={"light"}
    fontSize={[24, 36, 36, 36]}
    lineHeight={1.5}
    color={"darkText"}
    borderBottom={[1]}
  />
);

export const Select = props => (
  <SelectStyled
    {...props}
    autoComplete={props.autoComplete}
    border={0}
    bg="inputBackgroundGray"
    height={[37, 49, 49, 49]}
    fontWeight={"light"}
    fontSize={[24, 36, 36, 36]}
    lineHeight={1.5}
    color={"darkText"}
    borderBottom={[1]}
  />
);

export const SmallInput = ({ ...props }) => (
  <InputStyled
    {...props}
    border={0}
    fontWeight={"light"}
    // fontSize={[28, 28, 28, 28]}
    height={[30]}
    mt={"8px"}
    maxLength={props.maxLength}
    lineHeight={[1.3]}
    color={"darkText"}
    borderBottom={[1]}
  />
);

export const SmallTextArea = ({ ...props }) => (
  <TextAreaStyled
    {...props}
    // border={0}
    fontWeight={"light"}
    fontSize={[12, 12, 18, 18]}
    pl={'5px'}
    height={[120]}
    mt={"8px"}
    maxLength={props.maxLength}
    lineHeight={[1.3]}
    color={"darkText"}
    border={[1]}
  />
);

export const SmallSelect = ({ ...props }) => (
  <SelectStyled
    {...props}
    border={0}
    fontWeight={"light"}
    // fontSize={[28, 28, 28, 28]}
    height={[30]}
    mt={"8px"}
    maxLength={props.maxLength}
    lineHeight={[1.3]}
    color={"darkText"}
    borderBottom={[1]}
  />
);

const CondensedInputStyled = styled.input`
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  font-display: swap; font-family:NunitoSansSemiBold;
  padding-left: 12px;
  line-height: 1.5;
  &::placeholder {
    color:#DFDFDF;
  }
`;

const CondensedSelectStyled = styled.select`
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  font-display: swap; font-family:NunitoSansSemiBold;
  padding-left: 12px;
  line-height: 1.5;
  &::placeholder {
    color:#DFDFDF;
  }
`;

const CondensedInput = ({ ...props }) => (
  <Flex position="relative" flexDirection="column">
    <CondensedInputStyled
      {...props}
      border={0}
      bg="inputBackgroundGray"
      height={props.height ? props.height : [37, 49, 49, 49]}
      fontWeight={"bold"}
      fontSize={[18, 18, 22, 22]}
      color={"darkText"}
    />
    <ThickPixelDash />
  </Flex>
);



const CondensedSelect = ({ ...props }) => (
  <Flex position="relative" flexDirection="column">
    <CondensedSelectStyled
      {...props}
      border={0}
      bg="inputBackgroundGray"
      height={[37, 49, 49, 49]}
      fontWeight={"bold"}
      fontSize={[18, 18, 22, 22]}
      color={"darkText"}
    />
    <ThickPixelDash />
  </Flex>
);

const MegaInputStyled = styled.input`
  ${space}
  ${width}
  ${height}
  ${color}
  ${colorStyle}
  ${borders}
  ${fontSize}
  ${fontWeight}
  font-display: swap; font-family:NunitoSans;
  text-align:center;
  line-height: 1.5;
`;

const MegaInput = ({ ...props }) => (
  <MegaInputStyled
    {...props}
    border={0}
    bg="inputBackgroundGray"
    height={[91, 152]}
    fontWeight={"light"}
    fontSize={[42, 94]}
    color={"darkText"}
    borderBottom={[1]}
  />
);

export const renderMegaField = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  meta: { touched, error, warning }
}) => (
  <Flex
    width={1}
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    className="renderMegaField-input-wrapper"
  >
    <Eyebrow htmlFor={name}>{label}</Eyebrow>
    <MegaInput
      width={1}
      {...input}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      type={type}
      required={required}
      placeholder={placeholder}
    />
    {touched && error ? (
      <ErrorUnderbrow mt={["13px", "16px", "16px", "16px"]}>
        {error}
      </ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow mt={["13px", "16px", "16px", "16px"]}>
        &nbsp;
      </ErrorUnderbrow>
    )}
  </Flex>
);

const EyebrowStyled = styled.label`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  font-display: swap; font-family:NunitoSans;
  text-transform:${props => props.textTransform || "uppercase"};
`;

export const Eyebrow = ({ ...props }) => (
  <EyebrowStyled
    ml={["1px", "3px"]}
    mb={["1px", "3px"]}
    fontWeight={"700"}
    fontSize={[10, 14, 14, 14]}
    color={"black"}
    {...props}
  >
    {props.children}
  </EyebrowStyled>
);

export const SmallEyebrow = ({ ...props }) => (
  <EyebrowStyled
    {...props}
    fontWeight={props.fontWeight || "normal"}
    fontSize={props.fontSize || [12, 12, 12, 12]}
    color={"black"}
  >
    {props.children}
  </EyebrowStyled>
);

const ErrorUnderbrowStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  font-display: swap; font-family:NunitoSans;
  font-style:italic;
  text-transform:uppercase;
`;

export const ErrorUnderbrow = ({ ...props }) => (
  <ErrorUnderbrowStyled
    {...props}
    mt={["2px", "5px", "5px", "5px"]}
    fontWeight={"normal"}
    fontSize={["12px", "16px", "16px", "16px"]}
    color={"error"}
    height={[18, 23, 23, 23]}
  >
    {props.children}
  </ErrorUnderbrowStyled>
);

export const SmallErrorUnderbrow = ({ ...props }) => (
  <ErrorUnderbrowStyled
    {...props}
    mt={["2px", "2px", "2px", "2px"]}
    fontWeight={props.fontWeight || "normal"}
    fontSize={props.fontSize || ["9px", "9px", "9px", "9px"]}
    color={"error"}
    height={[18, 18, 18, 18]}
  >
    {props.children}
  </ErrorUnderbrowStyled>
);

export const renderFieldCheckBox = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  mb,
  meta: { touched, error, warning }
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
  >

    <Input
      {...input}
      pl="6px"
      name={name}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      type={type}
      required={required}
      placeholder={placeholder}
      style={{ textAlign: "center" }}
    />
    <Eyebrow style={{ textAlign: "center" }} htmlFor={name}>{label}</Eyebrow>
    {touched && error ? (
      <ErrorUnderbrow style={{ textAlign: "center" }} >{error}</ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow style={{ textAlign: "center" }} >&nbsp;</ErrorUnderbrow>
    )}
  </Flex>
);


const _checkPlanType = () => {
  console.log(this)
  //  const { updateCompany, company } = this.props;
  //  updateCompany(company.id, { location: geolocation });

};

export const renderFieldPlan = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  mb,
  meta: { touched, error, warning },
  ...props
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
  >

    <Eyebrow htmlFor={name}>{label}</Eyebrow>
    <br />
    <select {...input} style={{ background: "#EBEBE7", height: "30px", fontSize: "16px", color: "#000000", fontFamily: "NunitoSans" }}>

      {props.children}
    </select>

    {touched && error ? (
      <ErrorUnderbrow >{error}</ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow  >&nbsp;</ErrorUnderbrow>
    )}
  </Flex>
);



export const renderField = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  company,
  inputMode,
  disabled,
  mb,
  meta: { touched, error, warning },
  colorStyle
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
  >
    <Eyebrow htmlFor={name}>{label}</Eyebrow>

    <Input
      {...input}
      pl="2px"
      name={company}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      type={type}
      required={required}
      placeholder={placeholder}
      bg={colorStyle}
      disabled={disabled}
      inputMode={inputMode}
    />
    {touched && error ? (
      <ErrorUnderbrow>{error}</ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow>&nbsp;</ErrorUnderbrow>
    )}
  </Flex>
);

export const renderSmallField = ({
  autoComplete,
  autoCorrect,
  spellCheck,
  input,
  label,
  width,
  fontSize,
  labelFontSize,
  labelFontWeight,
  labelTextTransform,
  type,
  maxLength,
  name,
  required,
  placeholder,
  disabled,
  mb,
  meta: { touched, error, warning }
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
  >
    <SmallEyebrow textTransform={labelTextTransform} fontWeight={labelFontWeight} fontSize={labelFontSize} htmlFor={name}>{label}</SmallEyebrow>
    <SmallInput
      {...input}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      fontSize={fontSize || "28px"}
      width={width || "100%"}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      spellCheck={spellCheck}
      maxLength={maxLength}
      type={type}

      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
    {touched && error ? (
      <SmallErrorUnderbrow fontSize="12px" fontWeight="bold">{error}</SmallErrorUnderbrow>
    ) : (
      <SmallErrorUnderbrow>&nbsp;</SmallErrorUnderbrow>
    )}
  </Flex>
);

export const renderSmallTextAreaField = ({
  autoComplete,
  autoCorrect,
  spellCheck,
  input,
  label,
  width,
  fontSize,
  labelFontSize,
  labelFontWeight,
  labelTextTransform,
  type,
  maxLength,
  name,
  required,
  placeholder,
  disabled,
  mb,
  meta: { touched, error, warning }
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "10px"}
    className="renderField-input-wrapper"
  >
    <SmallEyebrow textTransform={labelTextTransform} fontWeight={labelFontWeight} fontSize={labelFontSize} htmlFor={name}>{label}</SmallEyebrow>
    <SmallTextArea
      {...input}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      fontSize={fontSize || "28px"}
      width={width || "100%"}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      spellCheck={spellCheck}
      maxLength={maxLength}
      type={type}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
    {touched && error ? (
      <SmallErrorUnderbrow>{error}</SmallErrorUnderbrow>
    ) : (
      <SmallErrorUnderbrow>&nbsp;</SmallErrorUnderbrow>
    )}
  </Flex>
);

export const renderSmallFieldSelect = ({
  autoComplete,
  autoCorrect,
  spellCheck,
  input,
  label,
  type,
  fontSize,
  maxLength,
  name,
  required,
  placeholder,
  disabled,
  mb,
  meta: { touched, error, warning },
  ...props
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
  >
    <SmallEyebrow htmlFor={name}>{label}</SmallEyebrow>
    <SmallSelect
      {...input}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      spellCheck={spellCheck}
      maxLength={maxLength}
      type={type}
      fontSize={fontSize || "28px"}
      width="100%"
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    >
      {props.children}
    </SmallSelect>
    {touched && error ? (
      <SmallErrorUnderbrow>{error}</SmallErrorUnderbrow>
    ) : (
      <SmallErrorUnderbrow>&nbsp;</SmallErrorUnderbrow>
    )}
  </Flex>
);

export const SmallInputComponent = ({ ...props }) => (
  <Flex
    flexDirection="column"
    mb={props.mb !== undefined ? props.mb : "8px"}
    className="renderField-input-wrapper"
  >
    <SmallEyebrow htmlFor={props.name}>{props.label}</SmallEyebrow>
    <SmallInput
      {...props.input}
      className={`${props.touched && props.error ? "invalid" : ""}`}
      label={props.label}
      type={props.type}
      fontSize={fontSize || "28px"}
      width="100%"
      required={props.required}
      placeholder={props.placeholder}
    />
    {props.touched && props.error ? (
      <SmallErrorUnderbrow>{props.error}</SmallErrorUnderbrow>
    ) : (
      <SmallErrorUnderbrow>&nbsp;</SmallErrorUnderbrow>
    )}
  </Flex>
);

export const renderCondensedField = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  mb,
  width,
  height,
  meta: { touched, error, warning }
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
    width={width ? width : ''}
  >
    <Eyebrow htmlFor={name}>{label}</Eyebrow>
    <CondensedInput
      {...input}
      height={height || ''}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      type={type}
      required={required}
      placeholder={placeholder}

    />
    {touched && error ? (
      <ErrorUnderbrow>{error}</ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow>&nbsp;</ErrorUnderbrow>
    )}
  </Flex>
);

export const renderCondensedFieldSelect = ({
  input,
  label,
  type,
  name,
  required,
  placeholder,
  mb,
  width,
  meta: { touched, error, warning },
  ...props
}) => (
  <Flex
    flexDirection="column"
    mb={mb !== undefined ? mb : "30px"}
    className="renderField-input-wrapper"
    width={width ? width : ''}
  >
    <Eyebrow htmlFor={name}>{label}</Eyebrow>
    <CondensedSelect
      {...input}
      className={`${touched && error ? "invalid" : ""}`}
      label={label}
      type={type}
      required={required}
      placeholder={placeholder}

    >
      {props.children}
    </CondensedSelect>
    {touched && error ? (
      <ErrorUnderbrow>{error}</ErrorUnderbrow>
    ) : (
      <ErrorUnderbrow>&nbsp;</ErrorUnderbrow>
    )}
  </Flex>
);

const ButtonStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borderColor}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  ${display}
  font-display: swap; font-family:NunitoSans;
  background-size:150%;
  border:2px solid;
  background-image: linear-gradient(-90deg, #white 0%,#white 26%, #white 57%, #white 100%);
 
  border-radius: 100px;
  text-transform:uppercase;
  cursor:pointer;
  background-position:left;
  &:hover {
    background-image: linear-gradient(-72deg,#2FD371 0%,#2FD371 100%);
    color: #fff;
  }
`;

export const OnboardingCTA = ({ ...props }) => (
  <ButtonStyled
    fontSize={["20px", "24px", "24px", "24px"]}
    fontWeight={"bold"}
    color={props.color || "#2FD371"}
    borderColor={props.color || "#2FD371"}
    height={props.height ? props.height : ["47px", "64px", "64px", "64px"]}
    {...props}
  >
    {props.children}
  </ButtonStyled>
);

const BackButtonStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  border:2px solid#2FD371;
  border-radius: 100px;
  background:white;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackButton = props => (
  <Link to={props.link}>
    <BackButtonStyled
      fontSize={"24px"}
      color={"#2FD371"}
      height={"64px"}
      width={"64px"}
      className="icon"
      {...props}
    >
      leftarrow
    </BackButtonStyled>
  </Link>
);

const MobileBackButtonStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  border-radius: 100px;
  background:#f9f9f9;
  cursor:pointer;
`;

export const MobileBackButton = ({ ...props }) => (
  <Link to={props.link}>
    <MobileBackButtonStyled
      fontSize={"15px"}
      color={"#C3C3C3"}
      height={"47px"}
      width={"47px"}
      className="icon"
      {...props}
    >
      leftarrow
    </MobileBackButtonStyled>
  </Link>
);

const WhiteCoverBG = styled(AbsoluteDiv)`
  background-color: white;
`;

const DottedBGLeftTop = styled(AbsoluteDiv)`
  background-image: url(${oneSixSquareImage});
`;

const DottedBGBottomRight = styled(AbsoluteDiv)`
  background-image: url(${oneSixSquareImageBottomRight});
  background-position: bottom right;
`;

export const renderDante = ({
  config,
  content,
  errors,
  label,
  mb,
  name,
  updateContent,
  placeholder,
  pageType
}) => {
  return (
    <Flex
      flexDirection="column"
      mb={mb !== undefined ? mb : "30px"}
      className="renderField-input-wrapper"
    >
      <Eyebrow htmlFor={name}>{label}</Eyebrow>
      <Flex justifyContent="center" position="relative">
        <Box zIndex="3" position="relative" width={["calc(100% - 10px)"]}>
          {/* <DanteComponent
            style={{ "z-index": "3" }}
            config={config}
            content={content}
            placeholder={placeholder}
            updateContent={e => updateContent(e)}
          /> */}
          <DraftComponent
            style={{ zIndex: "3" }}
            initialContent={content}
            placeholder={placeholder}
            pageType={pageType}
            updateContent={e => updateContent(e)}
          />
        </Box>
        <DottedBGLeftTop
          zIndex="1"
          top="-1px"
          bottom="0px"
          left="-1px"
          right="0px"
        />
        <DottedBGBottomRight
          zIndex="1"
          top="0px"
          bottom="-1px"
          left="0px"
          right="-1px"
        />
        <WhiteCoverBG
          zIndex="2"
          top="0px"
          bottom="0px"
          left="0px"
          right="0px"
        />
      </Flex>
      {errors && errors["postBody"] ? (
        <ErrorUnderbrow>{errors["postBody"]}</ErrorUnderbrow>
      ) : (
        <ErrorUnderbrow>&nbsp;</ErrorUnderbrow>
      )}
    </Flex>
  );
};

export const PrivatePostInput = ({ ...props }) => (
  <Flex
    w={[1, 1, 1, 1]}
    alignContent="center"
    justifyContent="space-between"
    flexDirection={["column", "column", "row", "row"]}
  >
    <Flex bg="inputBackgroundGray" width="100%" borderRadius="10px">
      <Flex
        width="100%"
        flexDirection="row"
        justifyContent="ANCHOR_LEFT"
        alignItems="left"
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
      >
        <Box mb="3px" ml="auto" mr="auto">
          <SearchFilterFlag
            clickHandler={props.clickToPrivate}
            mobile={false}
            active={props.clicked}
          >
            CLICK TO MAKE POST PRIVATE
          </SearchFilterFlag>
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

//w={[1, 1, 1]}
    
export const VideoInputBubble = ({ ...props }) => (
  <Flex
    minWidth={"530px"} 
    width={[1,1,1]}
    alignContent="center"
    justifyContent="space-between"
    flexDirection={["column", "column", "row", "row"]}
  >
    <Flex bg="inputBackgroundGray" 
      minWidth={"530px"} 
      width={[1,1,1]}
      borderRadius="10px">
    <Flex 
        minWidth={"530px"} 
        width={[1,1,1]}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
      >
        <Box width="100%">
          <Field
            minWidth={"530px"} 
            width={[1,1,1]}
            label="ADD YOUR YOUTUBE SHARE LINK"
            name="video_url"
            format={(input) => {
              if (!input) return;
              if (props.videoAdded) {
                return '';

              }
              return;
            }}
            placeholder="https://youtu.be/example"
            onChange={props.inputChangeHandler}
            onBlur={props.inputChangeHandler}
            mb={0}
            component={renderSmallField}
            type="text"
          />
        </Box>
        <Box mb="3px" ml="22px" mr="10px">
          <AccessoryButton
            size={29}
            clickHandler={props.addVideo}
            color="#c3c3c3"
            iconSize={12}
            iconMarginTop={"2px"}
            iconName={"checkmark"}
            hoverColor="#2FD371"
          />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

export const DateBubble = ({ ...props }) => (
  <Flex bg="inputBackgroundGray" borderRadius="10px">
    <Flex
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
      px={["12px"]}
      pt={["16px"]}
      pb={["16px"]}
    >
      <Box width="100%">{props.children}</Box>

      {/* <IconBox
        pl={"20px"}
        pr={"10px"}
        fontSize="24px"
        py="8px"
        color="darkerGray"
      >
        calendar
      </IconBox> */}
    </Flex>
  </Flex>
);



export class SingleDatePickerWrapper extends Component {
  state = {
    focused: false,
    lerenderMonth: PropTypes.func
  }



  constructor(props) {
    super(props);
    this.setFocusThrottled = debounce(this.setFocusThrottled.bind(this), 500, {
      leading: true,
      trailing: false
    });
  }
  setFocusThrottled({ focused }) {
    this.setState({ focused });
  }
  onFocusChange = ({ focused }) => {
    this.props.onFocusChange(focused);
    this.setFocusThrottled({ focused });
  };
  _onDateChange = date => {
    this.props.onDateChange(this.props.name, date);
  };

  

  componentDidMount(){
    // document.querySelector("         SingleDatePicker_picker__directionLeft_2").style.width = "3em";
    document.querySelector("#endDate").style.width = "250px";
    document.querySelector("#startDate").style.width = "250px";
    document.querySelector(".dashboard-button-wrapper").style.zIndex = 1;
    // document.querySelector(".SingleDatePicker_picker").style.zIndex = 2;
  }
  render() {
    /*
    const {
      input,
      label,
      type,
      name,
      required,
      placeholder,
      mb,
      meta: { touched, error, warning }
    } = this.props;

    */

    const { name, date, error, pastMonths, afterStartDate, lerenderMonth, width } = this.props;
    const defaultProps = {
      // example props for the demo
      // input related props
      id: name,
      placeholder: "Date",
      disabled: false,
      required: true,
      screenReaderInputMessage: "",
      showClearDate: false,
      showDefaultInputIcon: false,
      customInputIcon: null,
      block: false,
      small: false,
      regular: false,
      verticalSpacing: undefined,
      keepFocusOnInput: false,

      // calendar presentation and interaction related props
      //RenderMonthProps: lerenderMonth,
      orientation: VERTICAL_ORIENTATION,
      anchorDirection: ANCHOR_LEFT,
      horizontalMargin: 0,
      withPortal: false,
      withFullScreenPortal: false,
      initialVisibleMonth: null,
      numberOfMonths: 2,
      keepOpenOnDateSelect: false,
      reopenPickerOnClearDate: false,
      isRTL: false,

      // navigation related props
      navPrev: null,
      navNext: null,
      onPrevMonthClick() { },
      onNextMonthClick() { },
      onClose() { },

      // day presentation and interaction related props
      renderCalendarDay: undefined,
      renderDayContents: null,
      enableOutsideDays: false,
      isDayBlocked: () => false,
      isOutsideRange: day => !isInclusivelyAfterDay(day, pastMonths ? moment().subtract(pastMonths, 'months') : afterStartDate ? afterStartDate : moment()),
      isDayHighlighted: () => { },

      // internationalization props
      displayFormat: "D MMMM YYYY",
      monthFormat: "MMMM YYYY"
    };
    
    return (
      <Flex flexDirection="column" >
        <SmallEyebrow>{this.props.label}</SmallEyebrow>    
        <div style={{zIndex:"2",width:"100%"}}>
        <SingleDatePicker
          openDirection={this.props.openDirection || "down"}
          date={date} // momentPropTypes.momentObj or null
          onDateChange={this._onDateChange} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bo
          onFocusChange={this.onFocusChange} // PropTypes.func.isRequired
          {...defaultProps}
        />
        </div>   
        
        {error ? <SmallErrorUnderbrow>{error}</SmallErrorUnderbrow> : null}
      </Flex>
    );
  }
}

const DashboardButtonStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  background-image: linear-gradient(-123deg, #fff 0%, #fff 52%,#fff 100%);
  background-size:200%;
  background-position:40% 50%;
  border-radius: 100px;
  text-transform:uppercase;
  color:${props => props.color ? props.color : "#2FD371"};
  border: ${props => props.color ? props.color : "#2FD371"};
  border-style: solid;
  border-width: inherit;
  cursor:pointer;
  position:relative;
  z-index:1;
  &:hover {
    background-image: ${props => props.color ? "linear-gradient(-72deg, " + props.color + " 0%, " + props.color + " 100%)" : "linear-gradient(-72deg,#2FD371 0%,#2FD371 100%)"} ;
    color: #fff;
  }
`;
//(true)
//used to be (props.loading)
export const DashboardCTA = ({ ...props }) => (  
  console.log("DashboardCTA","JSON.parse(props.loading).loading",typeof JSON.parse(props.loading).loading),
  JSON.parse(props.loading).loading ? 
<Flex
className={`dashboard-cta loading `}
position="relative"
flexDirection="column"
justifyContent="center"
alignItems="center"
{...props}
>
<BlurWrapper
  className="blur-wrapper"
  width="100%"
  height="100%"
  blur="8px"
  top="5px"
  left="0px"
  opacity={0.2}
>
</BlurWrapper>
<OverflowHiddenBox
  className="dashboard-cta-overflow"
  position="relative"
  borderRadius="100px"
  width="100%"
  height="100%"
>
  <DashboardButtonStyled
    className="dashboard-button-wrapper"
    fontSize={["20px", "24px", "24px", "24px"]}
    fontWeight={"bold"}
    color={props.color ? props.color : "white"}
    height={["47px", "64px", "64px", "64px"]}
    width="100%"
    type="submit"
  >
    {JSON.parse(props.loading).loading ? "LOADING" : props.ctaText ? props.ctaText : "POST"}
  </DashboardButtonStyled>
</OverflowHiddenBox>
</Flex>

: 


<Flex
className={`dashboard-cta }`}
position="relative"
flexDirection="column"
justifyContent="center"
alignItems="center"
{...props}
>
<BlurWrapper
  className="blur-wrapper"
  width="100%"
  height="100%"
  blur="8px"
  top="5px"
  left="0px"
  opacity={0.2}
>
</BlurWrapper>
<OverflowHiddenBox
  className="dashboard-cta-overflow"
  position="relative"
  borderRadius="100px"
  width="100%"
  height="100%"
>
  <DashboardButtonStyled
    className="dashboard-button-wrapper"
    fontSize={["20px", "24px", "24px", "24px"]}
    fontWeight={"bold"}
    color={props.color ? props.color : "white"}
    height={["47px", "64px", "64px", "64px"]}
    width="100%"
    type="submit"
  >
    { JSON.parse(props.loading).loading ? "LOADING" : props.ctaText ? props.ctaText : "POST"}
  </DashboardButtonStyled>
</OverflowHiddenBox>
</Flex>


);
//{true ? "LOADING" : props.ctaText ? props.ctaText : "POST"}
//used to be {props.loading ? "LOADING" : props.ctaText ? props.ctaText : "POST"}


const DashboardSmallButtonStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  /* Rectangle: */
  background-image: ${props => props.reversed && !props.disabled ? "linear-gradient(-72deg, " + (props.color || "#2FD371") + " 0%, " + (props.color || "#2FD371") + " 100%)" : "linear-gradient(-72deg, #fff 0%, #fff 100%)"};
  background-size:300%;
  background-position:60% 50%;
  border-radius: 6px;
  font-family: NunitoSans;
  font-display: swap;
  cursor:${props => props.disabled ? "default" : "pointer"};
  position:relative;
  z-index:1;
  color:${props => props.disabled ? "#979797" : props.reversed ? "#fff" : props.color ? props.color : "#2FD371"};
  border:${props => props.borderColor ? props.borderColor : props.disabled ? "#979797" : props.color ? props.color : "#2FD371"} ;
  border-style: solid;
  border-width: inherit;
  &:hover {
    background-image:${props => props.disabled ? "#fff" : props.hoverColor ? "linear-gradient(-72deg, " + props.hoverColor + " 0%, " + props.hoverColor + " 100%)" : props.reversed ? "linear-gradient(-72deg, #fff 0%, #fff 100%)" : props.color && !props.disabled ? "linear-gradient(-72deg, " + props.color + " 0%, " + props.color + " 100%)" : !props.disabled ? "linear-gradient(-72deg,#2FD371 0%,#2FD371 100%)" : ""} ;
    color: ${props => props.disabled ? "#979797" : props.hoverColor ? "#fff" : props.reversed ? (props.color || "#2FD371") : "#fff"} ;
    border-color: ${props => props.disabled ? "#979797" : (props.hoverColor || props.color)}
  }
`;

const DashboardSmallToggleButtonStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  /* Rectangle: */
  min-width: 75px;
  padding-right: 15px;
  padding-left: 15px;
  background-image: ${props => props.reversed ? "linear-gradient(-72deg, " + (props.color || "#2FD371") + " 0%, " + (props.color || "#2FD371") + " 100%)" : "linear-gradient(-72deg, #fff 0%, #fff 100%)"};
  background-size:300%;
  background-position:60% 50%;
  border-radius: ${props => props.borderRadius || '23px'};
  font-family: NunitoSans;
  font-display: swap;
  cursor:${props => props.disabled || props.selected ? "default" : "pointer"};
  position:relative;
  z-index:1;
  color:${props => props.disabled ? "#979797" : props.reversed ? "#fff" : props.color ? props.color : "#2FD371"};
  border:${props => props.borderColor ? props.borderColor : props.disabled ? "#979797" : props.color ? props.color : "#2FD371"} ;
  border-style: solid;
  border-width: 1px;
  &:hover {
    background-image:${props => props.selected ? "" : "#fff"} ;
    color: ${props => props.selected ? '' : '#2FD371'} ;
    border-color: ${props => props.selected ? '' : '#2FD371'} ;
  }
`;

export const DashboardSmallToggleCTA = ({ ...props }) => (
  <Flex
    className="dashboard-mini-cta"
    position="relative"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <BlurWrapper
      className="blur-wrapper"
      width="100%"
      height="100%"
      blur="8px"
      top="5px"
      left="0px"
      opacity={0.2}
    >

    </BlurWrapper>
    <OverflowHiddenBox
      className="dashboard-mini-cta-overflow"
      position="relative"
      borderRadius="6px"
      width="100%"
      height="100%"
    >
      <DashboardSmallToggleButtonStyled
        className="dashboard-mini-button-wrapper"
        fontSize={props.fontSize ? props.fontSize : "17px"}
        fontWeight={"bold"}
        color={props.selected ? (props.color || colors.kickpostGreen) : '#A3A99E'}
        border={props.selected ? (props.color || colors.kickpostGreen) : '#A3A99E'}
        border-style="solid"
        border-width="inherit"
        height={props.innerHeight ? props.innerHeight : "32px"}
        // width="100%"
        type="submit"
        disabled={props.disabled}
        reversed={props.selected}
        selected={props.selected}
        borderColor={props.borderColor}
      >
        {props.children}
      </DashboardSmallToggleButtonStyled>
    </OverflowHiddenBox>
  </Flex>
);




let DashboardSmallCTA = ({ ...props }) => {
  // {console.log(props)}
  return (
    <Flex
      className="dashboard-mini-cta"
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <BlurWrapper
        className="blur-wrapper"
        width="100%"
        height="100%"
        blur="8px"
        top="5px"
        left="0px"
        opacity={0.2}
      >

      </BlurWrapper>
      <OverflowHiddenBox
        className="dashboard-mini-cta-overflow"
        position="relative"
        borderRadius="6px"
        width="100%"
        display="block"
        margin="0 auto"
        height="100%"
      >
        <DashboardSmallButtonStyled
          className="dashboard-mini-button-wrapper"
          fontSize={props.fontSize ? props.fontSize : "14px"}
          fontWeight={"bold"}
          color={props.color ? props.color : colors.kickpostGreen}
          border={props.color ? props.color : colors.kickpostGreen}
          border-style="solid"
          border-width="inherit"
          height={props.innerHeight ? props.innerHeight : "32px"}
          width="100%"
          padding="0.3em"
          type="submit"
          disabled={props.disabled}
          reversed={props.reversed}
          borderColor={props.borderColor}
          hoverColor={props.hoverColor}
        >
          {props.children}
        </DashboardSmallButtonStyled>
      </OverflowHiddenBox>
    </Flex>
  );
}

export { DashboardSmallCTA };


const VendorRequestStyled = styled.button`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  /* Rectangle: */
 /* background-image: linear-gradient(-72deg, #f8f8f8 0%, #f8f8f8 100%);*/
  background-size:300%;
  background-position:60% 50%;
  border-radius: 9px;
  text-transform:uppercase;
  cursor:pointer;
  color:#2FD371;
  border:#2FD371;
  border-style: solid;
  border-width: inherit;
  position:relative;
  z-index:1;
  font-display: swap; font-family:NunitoSansExtraBold;
  &:hover {
    background-image: linear-gradient(-72deg,#2FD371 0%,#2FD371 100%);
    color: #fff;
  }
`;

export const VendorRequest = ({ ...props }) => (
  <Flex
    className="dashboard-mini-cta"
    position="relative"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <BlurWrapper
      className="blur-wrapper"
      width="100%"
      height="100%"
      blur="8px"
      top="5px"
      left="0px"
      opacity={0.2}
    >
    </BlurWrapper>
    <OverflowHiddenBox
      className="dashboard-mini-cta-overflow"
      position="relative"
      borderRadius="9px"
      width="100%"
      height="100%"
    >
      <VendorRequestStyled
        className="dashboard-mini-button-wrapper"
        fontSize={["14px"]}
        fontWeight={"normal"}
        color={"gray"}
        height={props.innerHeight ? props.innerHeight : "33px"}
        width="100%"
        type="submit"
      >
        {props.children}
      </VendorRequestStyled>
    </OverflowHiddenBox>
  </Flex>
);

export const TwoPartField = ({ ...props }) => (
  <Flex flexDirection="column">
    <Flex bg="inputBackgroundGray" borderRadius="10px">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px={["12px"]}
          pt={["16px"]}
          pb={["6px"]}
        >
          <Box width={["100%", "44.3%"]}>
            <Field {...props.fieldOneProps} mb="0px" />
          </Box>
          <Box width={["100%", "44.3%"]}>
            <Field {...props.fieldTwoProps} mb="0px" />
          </Box>
        </Flex>
        <Box mb="3px" mx="22px">
          <AccessoryButton
            size={29}
            clickHandler={props.clickHandler}
            color="#c3c3c3"
            iconSize={12}
            iconName={props.icon}
          />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

export const SmallFieldNotEditable = ({ ...props }) => (
  <Flex
    flexDirection="column"
    mb={props.mb !== undefined ? props.mb : "30px"}
    className="renderField-input-wrapper markuponly"
  >
    <SmallEyebrow htmlFor={props.name}>{props.label}</SmallEyebrow>
    <OverflowHiddenBox
      className="two-part-field"
      width="100%"
      border={0}
      borderBottom={[1]}
      mt={"8px"}
    >
      <NunitoSans
        fontWeight={"light"}
        fontSize={[24, 24, 24, 24]}
        height={[30]}
        lineHeight={[1.3]}
        color={"darkText"}
      >
        {props.fieldValue}
      </NunitoSans>
    </OverflowHiddenBox>
    <SmallErrorUnderbrow>&nbsp;</SmallErrorUnderbrow>
  </Flex>
);

export const TwoPartFieldMarkupOnly = ({ ...props }) => (
  <Flex flexDirection="column">
    <Flex bg="inputBackgroundGray" borderRadius="10px">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px={["12px"]}
          pt={["16px"]}
          pb={["6px"]}
          overflow="hidden"
        >
          <Box width={["100%", "44.3%"]}>
            <SmallFieldNotEditable {...props.fieldOneProps} mb="0px" />
          </Box>
          <Box width={["100%", "44.3%"]}>
            <SmallFieldNotEditable {...props.fieldTwoProps} mb="0px" />
          </Box>
        </Flex>

        {
          props.notActionable
            ?
            null
            :

            <Box mb="3px" mx="22px">
              <AccessoryButton
                size={29}
                clickHandler={props.clickHandler}
                color="#c3c3c3"
                iconSize={12}
                iconName={props.icon}
              />
            </Box>
        }
      </Flex>
    </Flex>
  </Flex>
);

export const TwoPartFieldDashed = ({ ...props }) => (
  <Flex flexDirection="column">
    <FourFourDottedWith8pxCornerRadius>
      <Flex bg="white" borderRadius="10px">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Flex
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            px={["12px"]}
            pt={["16px"]}
            pb={["6px"]}
          >
            <Box width={["100%", "44.3%"]}>
              <Field {...props.fieldOneProps} onBlur={props.clickHandler} mb="0px" />
            </Box>
            <Box width={["100%", "44.3%"]}>
              <Field {...props.fieldTwoProps} onBlur={props.clickHandler} mb="0px" />
            </Box>
          </Flex>
          <Box mb="3px" mx="22px">
            <AccessoryButton
              size={29}
              clickHandler={props.clickHandler}
              color="#c3c3c3"
              iconSize={12}
              iconName={props.icon}
            />
            {/* <FontAwesomeIcon
            icon={props.icon === "checkmark" ? faPlusCircle : faMinusCircle}
            color="#2FD371"
            size="2x"
            /> */}
          </Box>
        </Flex>
      </Flex>
    </FourFourDottedWith8pxCornerRadius>
  </Flex>
);

export const BrandTwoPartFieldMarkupOnly = ({ ...props }) => (
  <Flex flexDirection="column">
    <Flex bg="inputBackgroundGray" borderRadius="10px">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >

        <ImageTreatment
          img={props.logo || defaultCompany(props.id, props.name)}
          blurRadius={"5px"}
          imageInset={0}
          borderRadius={10}
          innerBorderRadius={0}
          shadowOffset={0.1}
          padding={5}
          height={65}
          width={65}
        />
        <Flex
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px={["12px"]}
          pt={["16px"]}
          pb={["6px"]}
          overflow="hidden"
        >
          <Box width={["100%", "44.3%"]}>
            <SmallFieldNotEditable {...props.fieldOneProps} mb="0px" />
          </Box>
          <Box width={["100%", "44.3%"]}>
            <SmallFieldNotEditable {...props.fieldTwoProps} mb="0px" />
          </Box>
        </Flex>

        {
          props.notActionable
            ?
            null
            :

            <Box mb="3px" mx="22px">
              <AccessoryButton
                size={29}
                clickHandler={props.clickHandler}
                color="#c3c3c3"
                iconSize={12}
                iconName={props.icon}
              />
            </Box>
        }
      </Flex>
    </Flex>
  </Flex>
);

export const BrandTwoPartFieldDashed = ({ ...props }) => (
  <Flex flexDirection="column">
    <FourFourDottedWith8pxCornerRadius>
      <Flex bg="white" borderRadius="10px">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          style={{ paddingLeft: '5px' }}
        >
          <ImageCrop file={props.file} thePic={props.thePic} enableClick={true} dropZoneType='square_extra_small' onCrop={props.onCrop} notifyError={props.notifyError} page="brand" />
          <Flex
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            px={["12px"]}
            pt={["16px"]}
            pb={["6px"]}
          >
            <Box width={["100%", "44.3%"]}>
              <Field {...props.fieldOneProps} onBlur={props.clickHandler} mb="0px" />
            </Box>
            <Box width={["100%", "44.3%"]}>
              <Field {...props.fieldTwoProps} onBlur={props.clickHandler} mb="0px" />
            </Box>
          </Flex>
          <Box mb="3px" mx="22px">
            <AccessoryButton
              size={29}
              clickHandler={props.clickHandler}
              color="#c3c3c3"
              iconSize={12}
              iconName={props.icon}
            />
          </Box>
        </Flex>
      </Flex>
    </FourFourDottedWith8pxCornerRadius>
  </Flex>
);

export const GradientFlex = styled(Flex)`
  background-image: ${props => (props.gradient ? props.gradient : "red")};
`;

export const OutlineFlex = styled(Flex)`
  border: ${props => (props.borderThickness ? props.borderThickness : "2px")}
    solid ${props => props.color};
  cursor: pointer;
`;
