import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset, Field, getFormValues, reduxForm } from "redux-form";
import _ from "underscore";
import moment from "moment";

import axios from "axios";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser, clearUser } from "../../../utils/auth";
import "react-table/react-table.css";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

//components
import {
  NunitoSans,
  NunitoSansBold,
  NunitoSansSemiBold,
} from "../../common/typography";
import { SwitchInput } from "../../common/forms";
import { Flex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA } from "../../common/forms";
import { BackButton } from "../../common/backbutton";
import StripeCheckoutButton from "../../common/stripeCheckoutButton";

import {
  SettingsBilling,
  SettingsPaymentEdit,
  SettingsRewards,
  BillingModal,
  IncentivesActivationModal,
  NavNotificationBubble,
} from "../../common/ui";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
import {
  setIncentiveHovered,
  setIncentiveSelected,
  setRowSelected,
  setCsvDropOpen,
} from "../../../actions/incentives";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers,
} from "../../../actions/search";
import { renderCondensedField } from "../../common/forms";
import { LockedFeature } from "../../common/lockedfeature";
import { onboardStripeConnectAccount } from "../../../utils/stripe";
import UpgradeModal from "../../common/upgradeModal";
import { CSVLink } from "react-csv";

export class ManageSettingsMain extends Component {
  state = {
    industryDesignationModalOpen: false,
    customDatesOpen: false,
    customFeeModalOpen: false,
    customFeesInitialized: false,
    search: "",
    file_primary: null,
    thePic_primary: "",
    file_secondary: null,
    thePic_secondary: "",
    stripeModalOpen: false,
    billingInfoModalOpen: false,
    warningModalOpen: false,
    incentiveActivationModalOpen: false,
    incentiveModalType: "",
    warningModalType: "",
    initialCreateIncentives: false,
    transferModalOpen: false,
    companyAdmins: [],
    industryCategories: [],
    selectedCategories: [],
    industryError: "",
    createIncentivesExpanded: false,
    receiveIncentivesExpanded: false,
    stripeBillingModalOpen: false,
    upgradePremiumModalOpen: false,
    userReportData: null,
    DMG: "src/components/dashboard/settings/index.js",
  };

  _users = async (fetchUsers) => {
    const { DMG } = this.state;

    let resp = await fetchUsers({
      company_id: getUser().company_id,
      company_admin: true,
      status: "active",
    });
    console.log(DMG, "_users", resp);
  };

  _fetchDependencies = async () => {
    const {
      companySettings,
      fetchSettings,
      updateSettings,
      company,
      fetchCompany,
      fetchUser,
      user,
      notifyError,
      notifySuccess,
      fetchUsers,
    } = this.props;
    const { DMG } = this.state;

    // console.log(DMG,"_fetchDependencies this.props", this.props);
    // console.log(DMG,"_fetchDependencies this.state", this.state);

    if (!getUser().company_admin) return history.push("/dashboard");
    if (!company) {
      fetchCompany(getUser().company_id, { detail: true });
    }

    if (!user || getUser().id !== user.id) {
      fetchUser(getUser().id);
    }

    /*await fetchUsers({ company_id: getUser().company_id, company_admin: true, status: 'active' })
    .then(users => {     
      if(!users){
        this._users(fetchUsers);
      }
      if(true){//(company)
        let companyAdmins = [];


        console.log(DMG,"_fetchDependencies users", users); 
        console.log(DMG,"_fetchDependencies this.props", this.props); 
        console.log(DMG,"_fetchDependencies company", company); 
        let company_users = [];
        if(!users && company &&company.users&&company.users.length!==0){
          company_users = company.users;
        } else if(this.props.users && this.props.users.length!==0){
          company_users = this.props.users;
        } else if(users&&users.length!==0){
          company_users = users;
        }


        company_users.forEach(v => {
          if(v.company_admin === true){
            companyAdmins.push(v); 
          }
        });
        console.log(DMG,"_fetchDependencies companyAdmins", companyAdmins); 
        this.setState({ "companyAdmins": companyAdmins})
      }
      
    });
    */

    this._fetchIndustryCategories();

    //stripe connect token check
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const error = urlParams.get("error");

    // if (urlParams.get('upgrade')) {
    //   // history.replace(window.location.pathname);
    //   this.setState({ stripeBillingModalOpen: true });
    // }

    if (state && (code || error)) {
      history.replace(window.location.pathname);
    }

    if (state && state.split("~")[0] === getUser().id && code && !error) {
      let payload = {
        stripe_connect_code: code,
      };

      if (state.split("~")[1] === "partner") {
        payload.receive_vendor_incentives = true;
      } else {
        payload.receive_incentives = true;
      }

      const response = await updateSettings(getUser().company_id, payload);

      if (response.payload.stripe_connect_id) {
        notifySuccess(`Stripe Connect Account created successfully.`);
      }
      if (response.payload.stripeStatus) {
        notifyError(response.payload.stripeStatus.message);
      }
    } else if (error) {
      notifyError(
        `${error} There was a problem with Stripe Connect. Please try again.`
      );
    }

    if (!companySettings || code) {
      fetchSettings(getUser().company_id).then((response) => {
        let payload = {
          ..._.omit(response.payload, [
            "id",
            "created_at",
            "updated_at",
            "company_id",
            "stripe_connect_id",
            "stripe_id",
          ]),
        };
        this.setState({
          selectedCategories: response.payload.industry_categories || [],
        });
        this.props.initialize(payload);
      });
    } else {
      let settings = {
        ..._.omit(companySettings, [
          "id",
          "created_at",
          "updated_at",
          "company_id",
          "stripe_connect_id",
          "stripe_id",
        ]),
      };
      this.setState({
        selectedCategories: companySettings.industry_categories || [],
      });
      this.props.initialize(settings);
    }

    const categories = await fetch(
      `${process.env.REACT_APP_API_URL}/industrycategories`
    ).then((res) => res.json());

    this.setState({ industryCategories: categories });
  };

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchIndustryCategories = async () => {
    const categories = await fetch(
      `${process.env.REACT_APP_API_URL}/industrycategories`
    ).then((res) => res.json());

    this.setState({ industryCategories: categories });
  };

  _fetchCompanyInvoices = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/nonreward_invoices`, {
        params: {
          billed_company_id: getUser().company_id,
        },
      })
      .then((res) => this.setState({ companyInvoices: res.data }));
  };

  _closeIncentiveActivationModal = () =>
    this.setState({ incentiveActivationModalOpen: false });

  _confirmIncentiveActivationModal = async (actionType) => {
    const { updateSettings, notifySuccess, notifyError } = this.props;

    let payload = {};
    payload[actionType] = true;

    try {
      await updateSettings(getUser().company_id, payload);

      notifySuccess(
        `${actionType.includes("create") ? "Create" : "Receive"} ${
          actionType.includes("vendor") ? "partner" : "sales"
        } incentives activated.`
      );
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }

    this.setState({ incentiveActivationModalOpen: false });
  };

  _handleSwitchChange = async (name) => {
    const { formValues, updateSettings, notifySuccess, notifyError } =
      this.props;

    let payload = {};
    payload[name] = !formValues[name];

    console.log("payload[name]", payload[name]);

    try {
      await updateSettings(getUser().company_id, payload);

      if (name.includes("suggestion")) {
        notifySuccess(`Suggestion settings updated.`);
      } else if (name.includes("private")) {
        notifySuccess(`Privacy settings updated.`);
      } else {
        notifySuccess(`Settings updated.`);
      }
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }
  };

  _handleSplitsChange = async () => {
    const { companySettings, updateSettings, notifySuccess, notifyError } =
      this.props;

    let payload = {
      splits_active: !companySettings.splits_active,
    };

    try {
      await updateSettings(getUser().company_id, payload);

      notifySuccess(
        payload.splits_active
          ? `Reward splits activated.`
          : `Reward splits deactivated.`
      );
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }
  };

  _handleVerificationChange = async () => {
    const { companySettings, updateSettings, notifySuccess, notifyError } =
      this.props;
    const { DMG } = this.state;

    let payload = {
      sale_verification_active: !companySettings.sale_verification_active,
    };

    console.log(
      DMG,
      payload,
      companySettings.sale_verification_active,
      "SALE VERIFY"
    );

    try {
      await updateSettings(getUser().company_id, payload);

      notifySuccess(
        payload.sale_verification_active
          ? `Sale verification activated.`
          : `Sale verification deactivated.`
      );
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }
  };

  _submitStripe = async (data, plan) => {
    const {
      fetchUser,
      fetchCompany,
      notifySuccess,
      updateCompany,
      companySettings,
      fetchSettings,
    } = this.props;
    let payload = {};

    if (data.id) {
      payload = {
        stripe: JSON.stringify({
          token: data.id,
          card: data.card,
          interval: plan,
        }),
        status: "active",
      };
    } else {
      payload = {
        stripe: JSON.stringify({
          type: "invoice",
          interval: plan,
        }),
        billing_address: data.billing_address,
        billing_contact: data.billing_contact,
      };
    }

    await updateCompany(getUser().company_id, payload);
    const response = await fetchSettings(getUser().company_id);

    if (response.payload.stripe_id) {
      fetchCompany(getUser().company_id, { edit: true });
      return notifySuccess(
        "Your account has been successfully upgraded to share Posts."
      );
    }
  };

  _rewardsActivation = async () => {
    const { updateCompany, fetchCompany, company, notifySuccess } = this.props;

    let successMessage =
      company && company.rewards_active ? (
        "Billing Info saved successfully."
      ) : (
        <span>
          You’ve successfully activated KickPost Rewards.
          <br />
          Now choose if you’d like to create and/or receive incentives.
        </span>
      );

    let payload = {
      // billing_address: {
      //   address1: data.address1,
      //   address2: data.address2,
      //   city: data.city,
      //   state: data.state,
      //   zipcode: data.zipcode
      // },
      // billing_contact: data.billing_contact,
      rewards_active: true,
    };

    await updateCompany(company.id, payload);
    // return redirect(`/dashboard/company/edit/${company.id}`);
    fetchCompany(getUser().company_id, { edit: true });

    return notifySuccess(successMessage);
  };

  _handleBillingInfo = async (data) => {
    const {
      updateCompany,
      fetchCompany,
      company,
      notifySuccess,
      updateSettings,
    } = this.props;
    const { initialCreateIncentives } = this.state;

    let payload = {
      billing_address: {
        ..._.omit(data, ["billing_contact"]),
      },
      billing_contact: data.billing_contact,
    };

    if (initialCreateIncentives) {
      await updateSettings(getUser().company_id, { create_incentives: true });
    }

    await updateCompany(company.id, payload);
    // return redirect(`/dashboard/company/edit/${company.id}`);
    fetchCompany(getUser().company_id, { edit: true });

    notifySuccess(
      initialCreateIncentives
        ? "Create incentives activated."
        : "Billing Info saved successfully."
    );

    this.setState({
      billingInfoModalOpen: false,
      initialCreateIncentives: false,
    });
  };

  _handleOwnershipTransfer = async (user_index) => {
    const { redirect, notifyError } = this.props;
    const { companyAdmins, DMG } = this.state;

    // console.log(DMG,user_index, companyAdmins[user_index], "USERRRRRRR")

    const payload = {
      ownership_to: companyAdmins[user_index].id,
      company_id: getUser().company_id,
    };

    const user = await axios
      .patch(`${process.env.REACT_APP_API_URL}/users/${getUser().id}`, payload)
      .then((res) => res.data);

    if (user && user.id) {
      clearUser();
      redirect("/");
    } else {
      notifyError("There was an error");
    }
  };

  _deactivateRewards = async () => {
    const { updateCompany, fetchCompany, company, notifySuccess } = this.props;

    let payload = {
      rewards_active: false,
    };

    await updateCompany(company.id, payload);
    fetchCompany(getUser().company_id, { edit: true });
    return notifySuccess("KickPost Rewards deactivated successfully.");
  };

  _deletionRequest = async () => {
    const { notifySuccess, notifyError } = this.props;

    const respond = await fetch(
      `${process.env.REACT_APP_API_URL}/deletionrequest/${getUser().id}`
    ).then((res) => res.json());

    if (respond.success) {
      return notifySuccess("Deletion Request has been sent.");
    } else {
      return notifyError(respond.error || "An error occured.");
    }
  };

  _handleStripeLogin = async () => {
    const { companySettings, notifyError } = this.props;

    const stripe = await fetch(
      `${process.env.REACT_APP_API_URL}/stripelogin/${companySettings.stripe_connect_id}`
    ).then((res) => res.json());

    if (stripe && stripe.url) {
      window.open(stripe.url);
    } else {
      notifyError("There has been a problem with Stripe");
    }
  };

  _startCustomerSession = async () => {
    const { companySettings, notifyError } = this.props;

    const stripe = await fetch(
      `${process.env.REACT_APP_API_URL}/customersession/${companySettings.stripe_id}`
    ).then((res) => res.json());
    if (stripe && stripe.url) {
      window.open(stripe.url);
    } else {
      notifyError("There has been a problem with Stripe");
    }
  };

  _handleBillingModalCloseRequest = () => {
    const { company, change } = this.props;
    if (!company.billing_address) {
      change("create_incentives", false);
    }
    this.setState({ billingInfoModalOpen: false });
  };

  _handleVendorIncentiveChange = async (type, payload) => {
    const { updateSettings, notifySuccess, notifyError } = this.props;

    try {
      await updateSettings(getUser().company_id, payload);

      notifySuccess(
        `${
          type === "create_vendor_incentives" ? "Create" : "Receive"
        } partner incentives ${payload[type] ? "activated" : "deactivated"}.`
      );
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }
  };

  _handleIncentiveDeactivation = async () => {
    const { updateSettings, notifySuccess, notifyError } = this.props;
    const { warningModalType } = this.state;

    let payload = {};
    payload[warningModalType] = false;

    try {
      await updateSettings(getUser().company_id, payload);

      notifySuccess(
        `${warningModalType.includes("create") ? "Create" : "Receive"} ${
          warningModalType.includes("vendor") ? "partner" : "sales"
        } incentives deactivated.`
      );
    } catch (err) {
      notifyError("There has been an error. Please try again later.");
    }

    this.setState({ warningModalOpen: false });
  };

  _handleSalesRepFeeChange = async (value = 0) => {
    const { updateSettings, notifySuccess, notifyError } = this.props;
    try {
      await updateSettings(getUser().company_id, { rep_fee: value });

      return notifySuccess("Sales rep fee updated.");
    } catch {
      return notifyError("There has been an error. Please try again later.");
    }
  };

  _handlePartnerFeeChange = async (value = 0) => {
    const { updateSettings, notifySuccess, notifyError } = this.props;

    try {
      await updateSettings(getUser().company_id, { partner_fee: value });

      return notifySuccess("Partner fee updated.");
    } catch {
      return notifyError("There has been an error. Please try again later.");
    }
  };
  _handleIndustryChange = async () => {
    const { updateSettings, notifySuccess, notifyError } = this.props;

    try {
      await updateSettings(getUser().company_id, {
        industry_categories: JSON.stringify(this.state.selectedCategories),
      });
      this.setState({ industryDesignationModalOpen: false });
      return notifySuccess("Industry designation updated.");
    } catch {
      return notifyError("There has been an error. Please try again later.");
    }
  };

  _handleCompanyGoalChange = async (value) => {
    const { updateSettings, notifySuccess, notifyError } = this.props;

    try {
      await updateSettings(getUser().company_id, { company_goal: value });
      return notifySuccess("Goal designation updated.");
    } catch {
      return notifyError("There has been an error. Please try again later.");
    }
  };

  _handleAnnualInvoice = async (data) => {
    const {
      updateCompany,
      fetchCompany,
      company,
      notifySuccess,
      notifyError,
      updateSettings,
    } = this.props;
    const { DMG } = this.state;

    try {
      // console.log(DMG,payload, 'Invoice data')

      let payload = {
        billing_address: {
          ..._.omit(data, ["billing_contact"]),
        },
        billing_contact: data.billing_contact,
      };

      await this._submitStripe(payload, "annual");
    } catch (err) {
      return notifyError(
        "There has been an error while upgrading your account."
      );
    }
  };

  _toggleCategory = (category) => {
    this.setState({ industryError: "" });

    let newSelection = [...this.state.selectedCategories];

    const isSelected =
      _.findIndex(newSelection, function (o) {
        return o.id === category.id;
      }) > -1;

    if (isSelected) {
      // remove category
      this.setState({
        selectedCategories: newSelection.filter(
          (cat) => cat.id !== category.id
        ),
      });
    } else {
      if (newSelection.length === 3) {
        this.setState({
          industryError: "You can choose up to 3 industry categories.",
        });
        return setTimeout(() => this.setState({ industryError: "" }), 5000);
      }
      //add category
      newSelection.push(category);
      this.setState({ selectedCategories: newSelection });
    }
  };

  _fetchUserReport = async () => {
    this.setState({ userReportData: "fetching" });

    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/connected_users_report/${this.props.company.id}`
      ).then((res) => res.json());
      this.setState({ userReportData: data });
    } catch (error) {
      this.setState({ userReportData: null });
    }
  };

  render() {
    const {
      stripeModalOpen,
      billingInfoModalOpen,
      warningModalOpen,
      warningModalType,
      incentiveModalType,
      incentiveActivationModalOpen,
      initialCreateIncentives,
      transferModalOpen,
      companyAdmins,
      industryCategories,
      selectedCategories,
      industryDesignationModalOpen,
      industryError,
      createIncentivesExpanded,
      receiveIncentivesExpanded,
      stripeBillingModalOpen,
      userReportData,
    } = this.state;
    const {
      company,
      user,
      systemSettings,
      companySettings,
      formValues,
      featureFlags,
    } = this.props;

    if (
      !company ||
      company.id !== getUser().company_id ||
      !user ||
      user.id !== getUser().id
    )
      return null;

    const ALLSETTINGS = [
      {
        name: "Upgrades",
        items: [
          {
            name: "UPGRADE TO PREMIUM",
            formName: "upgrade_premium",
            description:
              "Activate the ability to share posts with your network.",
            inputType: "button",
            show: companySettings.plan_type === "standard",
          },
          {
            name: "CREATE INCENTIVES",
            formName: "create_incentives_main",
            description: "",
            inputType: "icon",
            show:
              (featureFlags.create_sales_incentives !== false &&
                systemSettings.rewards_active &&
                !companySettings.is_single_user_company &&
                systemSettings.sales_incentives_active &&
                (!systemSettings.sales_companies_active ||
                  (systemSettings.sales_companies_active &&
                    company.sales_incentive_active))) ||
              (featureFlags.create_partner_incentives !== false &&
                systemSettings.rewards_active &&
                !companySettings.is_single_user_company &&
                systemSettings.vendor_incentives_active &&
                (!systemSettings.vendor_companies_active ||
                  (systemSettings.vendor_companies_active &&
                    company.vendor_incentive_active))),
            children: [
              {
                name: "CREATE SALES INCENTIVES",
                formName: "create_incentives",
                description:
                  "Activate to create sales incentives for your network.",
                inputType: "button",
                show:
                  featureFlags.create_sales_incentives !== false &&
                  systemSettings.rewards_active &&
                  !companySettings.is_single_user_company &&
                  systemSettings.sales_incentives_active &&
                  (!systemSettings.sales_companies_active ||
                    (systemSettings.sales_companies_active &&
                      company.sales_incentive_active)),
              },
              {
                name: "CREATE PARTNER INCENTIVES",
                formName: "create_vendor_incentives",
                description:
                  "Activate to create partner incentives for your network.",
                inputType: "button",
                show:
                  featureFlags.create_partner_incentives !== false &&
                  systemSettings.rewards_active &&
                  !companySettings.is_single_user_company &&
                  systemSettings.vendor_incentives_active &&
                  (!systemSettings.vendor_companies_active ||
                    (systemSettings.vendor_companies_active &&
                      company.vendor_incentive_active)),
              },
            ],
          },
          {
            name: "RECEIVE INCENTIVES",
            formName: "receive_incentives_main",
            description: "",
            inputType: "icon",
            show:
              systemSettings.rewards_active &&
              ((systemSettings.sales_incentives_active &&
                ((featureFlags.receive_sales_incentives !== false &&
                  !systemSettings.sales_companies_active) ||
                  (systemSettings.sales_companies_active &&
                    company.sales_incentive_active))) ||
                (featureFlags.receive_partner_incentives !== false &&
                  !companySettings.is_single_user_company &&
                  systemSettings.vendor_incentives_active &&
                  (!systemSettings.vendor_companies_active ||
                    (systemSettings.vendor_companies_active &&
                      company.vendor_incentive_active)))),
            children: [
              {
                name: "RECEIVE SALES INCENTIVES",
                formName: "receive_incentives",
                description:
                  "Activate to receive sales incentives from your network.",
                inputType: "button",
                show:
                  featureFlags.receive_sales_incentives !== false &&
                  systemSettings.rewards_active &&
                  systemSettings.sales_incentives_active &&
                  (!systemSettings.sales_companies_active ||
                    (systemSettings.sales_companies_active &&
                      company.sales_incentive_active)),
              },
              {
                name: "RECEIVE PARTNER INCENTIVES",
                formName: "receive_vendor_incentives",
                description:
                  "Activate to receive partner incentives from your network.",
                inputType: "button",
                show:
                  featureFlags.receive_partner_incentives !== false &&
                  systemSettings.rewards_active &&
                  !companySettings.is_single_user_company &&
                  systemSettings.vendor_incentives_active &&
                  (!systemSettings.vendor_companies_active ||
                    (systemSettings.vendor_companies_active &&
                      company.vendor_incentive_active)),
              },
            ],
          },
        ],
      },
      {
        name: "Reports",
        items: [
          {
            name: "CONNECTED USERS REPORT",
            formName: "connected_users_report",
            description: "Create a report for connected users",
            inputType: "button",
            buttonText: "get",
            csvHeaders: [
              {
                label: "Company Name",
                key: "companyName",
              },
              {
                label: "First Name",
                key: "firstName",
              },
              {
                label: "Last Name",
                key: "lastName",
              },
              {
                label: "Position",
                key: "position",
              },
              {
                label: "City",
                key: "city",
              },
              {
                label: "State",
                key: "state",
              },
              {
                label: "Email",
                key: "email",
              },
              {
                label: "Phone",
                key: "phone",
              },
            ],
            show:
              companySettings.company_type === "Vendor" &&
              companySettings.plan_type === "premium",
          },
        ],
      },
      {
        name: "General",
        items: [
          {
            name: "INDUSTRY DESIGNATION",
            formName: "industry_designation",
            description: "Change your industry designation.",
            inputType: "button",
            buttonText: "change",
            show: true,
          },
          {
            name: "GOAL DESIGNATION",
            formName: "company_goal",
            description: "Current goal designation.",
            inputType: "select",
            buttonText: "manage",
            show: true,
          },
          {
            name: "ALL USERS PRIVACY",
            formName: "company_private",
            description:
              "Hide all your coworkers contact information from being displayed to any users outside of your company.",
            inputType: "switch",
            show:
              !companySettings.is_single_user_company &&
              systemSettings.privacy_active &&
              formValues &&
              !formValues.users_private,
          },
          {
            name: "USERS PRIVACY",
            formName: "users_private",
            description:
              "Allow your coworkers to hide their contact information from being displayed to any users outside of your company.",
            inputType: "switch",
            show:
              !companySettings.is_single_user_company &&
              systemSettings.privacy_active &&
              formValues &&
              !formValues.company_private,
          },
        ],
      },
      {
        name: "Your Account",
        items: [
          {
            name: "CONNECTION SUGGESTIONS",
            formName: "connection_suggestion_active",
            description: "Allow connection suggestions to be displayed.",
            inputType: "switch",
            show: true,
          },
          {
            name: "CONNECTION AUTO APPROVAL",
            formName: "auto_approve",
            description: "Allow connection requests to be auto-approved.",
            inputType: "switch",
            show: featureFlags && featureFlags.auto_approve === true,
          },
          /*{
            name: 'INVITE SUGGESTIONS',
            formName: 'invite_suggestion_active',
            description: 'Allow invite suggestions to be displayed.',
            inputType: 'switch',
            show: true
          },*/
          {
            name: "MANAGE BILLING",
            formName: "edit_payment",
            description: "Manage your payment methods for KickPost.",
            inputType: "button",
            buttonText: "manage",
            show:
              company.id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID &&
              company.paid &&
              getUser().company_admin &&
              companySettings.stripe_id,
          },
          {
            name: "TRANSFER OF OWNERSHIP",
            formName: "ownership_transfer",
            description:
              "Transfer the ownership rights for this account to another manager.",
            inputType: "button",
            buttonText: "transfer",
            show:
              !companySettings.is_single_user_company &&
              companySettings.company_id === getUser().company_id &&
              getUser().company_owner,
          },
        ],
      },
      {
        name: "Rewards",
        items: [
          {
            name: "Incentive Billing Contact",
            formName: "edit_address",
            description: "Edit billing contact information for incentives.",
            inputType: "button",
            show:
              systemSettings.rewards_active &&
              !companySettings.is_single_user_company &&
              company &&
              company.billing_address,
          },
          {
            name: "SALES VERIFICATION",
            formName: "sale_verification_active",
            description:
              "Activate Sales Verification for Incentives your company runs.",
            inputType: "button",
            show:
              systemSettings.rewards_active &&
              !companySettings.is_single_user_company &&
              ((systemSettings.sales_incentives_active &&
                (!systemSettings.sales_companies_active ||
                  (systemSettings.sales_companies_active &&
                    company.sales_incentive_active &&
                    companySettings.create_incentives))) ||
                (systemSettings.vendor_incentives_active &&
                  (!systemSettings.vendor_companies_active ||
                    (systemSettings.vendor_companies_active &&
                      company.vendor_incentive_active &&
                      companySettings.create_vendor_incentives)))),
          },
        ],
      },
      {
        name: "Incentives",
        items: [
          {
            name: "Sales Rep Fee",
            formName: "rep_fee",
            description:
              "Set the percent (%) your company will receive from your sales reps' incentive rewards.",
            inputType: "select",
            show:
              systemSettings.rewards_active &&
              companySettings &&
              !companySettings.is_single_user_company &&
              companySettings.receive_incentives,
          },
          {
            name: "Partner Fee",
            formName: "partner_fee",
            description:
              "Set the percent (%) your company will receive from your partner.",
            inputType: "select",
            show:
              systemSettings.rewards_active &&
              companySettings &&
              !companySettings.is_single_user_company &&
              companySettings.receive_incentives,
          },
          {
            name: "REWARD SPLITS",
            formName: "splits_active",
            description:
              "Activate Reward Splits for your coworkers (Assign in Team).",
            inputType: "button",
            show:
              systemSettings.rewards_active &&
              companySettings &&
              !companySettings.is_single_user_company &&
              companySettings.receive_incentives &&
              systemSettings &&
              systemSettings.reward_splits,
          },
          {
            name: companySettings.is_single_user_company
              ? "EARNINGS ACCOUNT"
              : "COMPANY INCENTIVE EARNINGS ACCOUNT",
            formName: "stripe_login",
            description: companySettings.is_single_user_company
              ? "Manage your rewards account."
              : "Manage your company rewards account.",
            inputType: "button",
            show: companySettings && companySettings.receive_incentives,
          },
        ],
      },
      {
        name: "Delete",
        show: company.id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID,
        items: [
          {
            name: "DELETE ACCOUNT REQUEST",
            formName: "delete_request",
            description:
              "Send a request to delete this account from the system.",
            inputType: "button",
            buttonText: "request",
            show: true,
          },
        ],
      },
    ];

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        // height: "600px",
        zIndex: 999998,
        overflow: "auto",
        padding: "40px",
        marginTop: "20px",
        marginBottom: "20px",
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88",
      },
    };

    const warningModalStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        // height: "600px",
        zIndex: 999998,
        overflow: "auto",
        padding: "40px",
        marginTop: "20px",
        marginBottom: "20px",
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88",
      },
    };

    const transferModalStyle = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        // height: "600px",
        zIndex: 999998,
        overflow: "auto",
        padding: "40px",
        marginTop: "10px",
        marginBottom: "20px",
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88",
      },
    };

    return (
      <>
        <IncentivesActivationModal
          modalIsOpen={incentiveActivationModalOpen}
          modalType={incentiveModalType}
          hasNextPage={
            incentiveModalType === "receive_incentives" ||
            incentiveModalType === "receive_vendor_incentives"
              ? !companySettings.stripe_connect_id
              : !company.billing_address
          }
          handleCloseRequest={this._closeIncentiveActivationModal}
          handleConfirmRequest={this._confirmIncentiveActivationModal}
          goNext={
            incentiveModalType === "receive_incentives" ||
            incentiveModalType === "receive_vendor_incentives"
              ? () =>
                  this.setState({
                    incentiveActivationModalOpen: false,
                    stripeModalOpen: true,
                  })
              : () =>
                  this.setState({
                    incentiveActivationModalOpen: false,
                    billingInfoModalOpen: true,
                    initialCreateIncentives: true,
                  })
          }
          processingFee={
            incentiveModalType.includes("vendor")
              ? systemSettings.system_vendor_incentive_fee
              : systemSettings.system_incentive_fee
          }
          isSingleUser={companySettings.is_single_user_company}
        />
        <BillingModal
          company={company}
          dispatch={this.props.dispatch}
          updateBillingInfo={this._handleBillingInfo}
          modalIsOpen={billingInfoModalOpen}
          handleCloseRequest={this._handleBillingModalCloseRequest}
          initialCreateIncentives={initialCreateIncentives}
        />

        <IndustryDesignationModal
          industryCategories={industryCategories}
          selectedCategories={selectedCategories}
          toggleCategory={this._toggleCategory}
          modalOpen={industryDesignationModalOpen}
          handleCloseRequest={() =>
            this.setState({ industryDesignationModalOpen: false })
          }
          saveChanges={this._handleIndustryChange}
          errorMessage={industryError}
        />

        <StripeBillingModal
          modalOpen={stripeBillingModalOpen}
          handleCloseRequest={() =>
            this.setState({ stripeBillingModalOpen: false })
          }
          submitStripe={this._submitStripe}
          company={company}
          handleInvoiceInfo={this._handleAnnualInvoice}
          isSingleUser={companySettings.is_single_user_company}
          dispatch={this.props.dispatch}
        />

        <UpgradeModal
          open={this.state.upgradePremiumModalOpen}
          setModalOpen={(value) =>
            this.setState({ upgradePremiumModalOpen: value })
          }
        />

        <Modal
          isOpen={transferModalOpen}
          onRequestClose={() => this.setState({ transferModalOpen: false })}
          style={transferModalStyle}
          contentLabel="Transfer Modal"
          ariaHideApp={false}
        >
          <Flex flexDirection="column" alignItems="center">
            <NunitoSansBold fontSize="24px" color="#000000">
              TRANSFER OF OWNERSHIP
            </NunitoSansBold>
            <NunitoSans style={{ marginTop: "15px", marginBottom: "15px" }}>
              {companyAdmins && companyAdmins.length > 0
                ? "Please choose the company manager to transfer ownership below"
                : "There are no company managers to transfer ownership. Make sure you have at least one company manager other than yourself."}
            </NunitoSans>
            {companyAdmins && companyAdmins.length > 0 ? (
              <select
                id="ownership_dropdown"
                style={{
                  padding: "10px 10px",
                  border: "1px solid #428BF4",
                  borderRadius: "9px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: "NunitoSans",
                  color: "#000000",
                  marginLeft: "10px",
                }}
                fontSize={["12px", "12px", "16px", "16px"]}
                defaultValue={0}
              >
                {companyAdmins.map((admin, index) => (
                  <option
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontWeight: "bold",
                      fontFamily: "NunitoSans",
                      color: "#A3A99E",
                    }}
                    value={index}
                    key={index}
                  >
                    {admin.first_name + " " + admin.last_name}
                  </option>
                ))}
              </select>
            ) : null}
            <Flex
              width="60%"
              justifyContent={
                companyAdmins && companyAdmins.length > 0
                  ? "space-between"
                  : "center"
              }
              alignItems="center"
            >
              <DashboardSmallCTA
                onClick={() => this.setState({ transferModalOpen: false })}
                minWidth={["122px"]}
                fontSize="14px"
                innerHeight="35px"
                width="150px"
                mt="25px"
                color="#FE4A49"
              >
                CANCEL
              </DashboardSmallCTA>
              {companyAdmins && companyAdmins.length > 0 ? (
                <DashboardSmallCTA
                  onClick={() =>
                    this._handleOwnershipTransfer(
                      document.getElementById("ownership_dropdown") &&
                        document.getElementById("ownership_dropdown").value
                    )
                  }
                  minWidth={["122px"]}
                  fontSize="14px"
                  innerHeight="35px"
                  width="150px"
                  mt="25px"
                  color="#428BF4"
                >
                  CONFIRM
                </DashboardSmallCTA>
              ) : null}
            </Flex>
          </Flex>
        </Modal>

        <Modal
          isOpen={warningModalOpen}
          onRequestClose={() => this.setState({ warningModalOpen: false })}
          style={warningModalStyles}
          contentLabel="Warning Modal"
          ariaHideApp={false}
        >
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <NunitoSansBold fontSize="24px" color="#000000">
              WARNING
            </NunitoSansBold>
            <NunitoSans>
              {`Warning: turning off this feature will hide all ${
                warningModalType.includes("vendor") ? "partner" : "sales"
              } incentives, ${
                warningModalType.includes("vendor") ? "partner" : "sales"
              } incentive rewards and other ${
                warningModalType.includes("vendor") ? "partner" : "sales"
              } related features from all of your users.`}
            </NunitoSans>
            <Flex
              width="60%"
              justifyContent="space-between"
              alignItems="center"
            >
              <DashboardSmallCTA
                onClick={() => this.setState({ warningModalOpen: false })}
                minWidth={["122px"]}
                fontSize="14px"
                innerHeight="35px"
                width="150px"
                mt="25px"
                color="#FE4A49"
              >
                CANCEL
              </DashboardSmallCTA>
              <DashboardSmallCTA
                onClick={() => this._handleIncentiveDeactivation()}
                minWidth={["122px"]}
                fontSize="14px"
                innerHeight="35px"
                width="150px"
                mt="25px"
                color="#428BF4"
              >
                CONFIRM
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Modal>
        <Flex
          flexDirection="column"
          style={{
            height: "85vh",
            overflowY: "auto",
            overflowX: "hidden",
            overflowX: "hidden",
            paddingRight: "25px",
            marginTop: "55px",
          }}
        >
          {/* {
            false && !company.paid && getUser().company_admin
            ?
            <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{border: '2px solid #428BF4', borderRadius:'10px', padding: '20px 10px', marginBottom: '20px'}}
            >
              <Flex
              flexDirection="column"
              >
                <NunitoSansBold
                color="#000000"
                >
                  UPGRADE TO KICKPOST PLUS!
                </NunitoSansBold>
                <NunitoSans
                fontSize='14px'
                color="#000000"
                >
                  {
                    companySettings.is_single_user_company
                    ?
                    "With a Plus account you can share posts with your network."
                    :
                    "With a Plus account you and your team can share posts and incentives with your network."
                  }
                  
                </NunitoSans>

              </Flex>

              <SettingsBilling
              user={getUser()}
              submitStripe={this._submitStripe}
              company={company}
              handleInvoiceInfo={this._handleAnnualInvoice}
              isSingleUser={companySettings.is_single_user_company}
              dispatch={this.props.dispatch}
              />

            </Flex>
            :
            null
          } */}

          <form id="settings-form">
            {getUser().company_admin
              ? ALLSETTINGS.filter((settings) =>
                  settings.items.some((item) => item.show)
                ).map((settings, mainIndex) => (
                  <div
                    style={{ marginBottom: "20px" }}
                    key={mainIndex.toString()}
                  >
                    <Flex
                      style={{
                        borderBottom: "5px solid #D3D3D3",
                        marginBottom: "20px",
                      }}
                      key={`keytwo` + mainIndex}
                    >
                      <NunitoSans
                        fontSize="16px"
                        color="#6C7168"
                        fontWeight="bolder"
                        key={settings.name}
                      >
                        {settings.name.toUpperCase()}
                      </NunitoSans>
                    </Flex>

                    {settings.items
                      .filter((item) => item.show)
                      .map((setting, index) => {
                        return (
                          <Flex
                            flexDirection="column"
                            style={{
                              borderTop:
                                index !== 0 ? "1px solid #D3D3D3" : "none",
                            }}
                            key={index}
                            // style={{borderBottom:'1px solid #D3D3D3', marginBottom: index===REWARDS.length-1 ? '50px' : '0px'}}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                              // style={{ borderTop: index!==0 ? '1px solid #D3D3D3' : "none"}}
                              key={`keythree` + index}
                            >
                              <Flex
                                flexDirection="column"
                                style={{
                                  marginTop: "13px",
                                  marginBottom: "13px",
                                }}
                                key={`keyfour` + index}
                              >
                                <NunitoSans
                                  fontSize="14px"
                                  fontWeight="bold"
                                  color="#10213A"
                                  key={`keyfive` + index}
                                >
                                  {setting.name.toUpperCase()}
                                </NunitoSans>
                                <NunitoSans
                                  fontSize="14px"
                                  color="#000000"
                                  key={`keysix` + index}
                                >
                                  {setting.description}
                                </NunitoSans>
                              </Flex>

                              <Flex
                                flexDirection="column"
                                style={{
                                  marginTop: "13px",
                                  marginBottom: "13px",
                                }}
                                key={`keyseven` + index}
                              >
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  key={`keyone` + index}
                                >
                                  {setting.inputType === "icon" &&
                                  (setting.formName ===
                                    "create_incentives_main" ||
                                    setting.formName ===
                                      "receive_incentives_main") ? (
                                    <FontAwesomeIcon
                                      icon={
                                        (setting.formName ===
                                          "create_incentives_main" &&
                                          createIncentivesExpanded) ||
                                        (setting.formName ===
                                          "receive_incentives_main" &&
                                          receiveIncentivesExpanded)
                                          ? faCaretDown
                                          : faCaretRight
                                      }
                                      color="#428BF4"
                                      size="3x"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (
                                          setting.formName ===
                                          "create_incentives_main"
                                        ) {
                                          this.setState({
                                            createIncentivesExpanded:
                                              !createIncentivesExpanded,
                                          });
                                        } else {
                                          this.setState({
                                            receiveIncentivesExpanded:
                                              !receiveIncentivesExpanded,
                                          });
                                        }
                                      }}
                                    />
                                  ) : setting.formName === "upgrade_premium" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        this.setState({
                                          upgradePremiumModalOpen: true,
                                        })
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                      key={`keyeight` + index}
                                    >
                                      UPGRADE
                                    </DashboardSmallCTA>
                                  ) : setting.formName ===
                                    "kickpost_rewards" ? (
                                    <SettingsRewards
                                      company={company}
                                      dispatch={this.props.dispatch}
                                      deactivateRewards={
                                        this._deactivateRewards
                                      }
                                      rewardsActivation={
                                        this._rewardsActivation
                                      }
                                      key={`keynine` + index}
                                    />
                                  ) : setting.formName === "edit_payment" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        this._startCustomerSession()
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                      key={`keyten` + index}
                                    >
                                      {setting.buttonText.toUpperCase()}
                                    </DashboardSmallCTA>
                                  ) : setting.formName ===
                                    "connected_users_report" ? (
                                    <>
                                      {!userReportData ||
                                      userReportData === "fetching" ? (
                                        <DashboardSmallCTA
                                          onClick={() =>
                                            userReportData === "fetching"
                                              ? ""
                                              : this._fetchUserReport()
                                          }
                                          minWidth={["122px"]}
                                          fontSize="14px"
                                          innerHeight="30px"
                                          width="130px"
                                          color="#428BF4"
                                          key={`keytwelve` + index}
                                          disabled={
                                            userReportData === "fetching"
                                          }
                                        >
                                          {userReportData === "fetching"
                                            ? "FETCHING..."
                                            : setting.buttonText.toUpperCase()}
                                        </DashboardSmallCTA>
                                      ) : (
                                        <DashboardSmallCTA
                                          minWidth={["122px"]}
                                          fontSize="14px"
                                          innerHeight="30px"
                                          width="130px"
                                          color="#428BF4"
                                          key={`keytwelve` + index}
                                        >
                                          <CSVLink
                                            id={setting.formName}
                                            filename={`${setting.formName}.csv`}
                                            data={userReportData}
                                            headers={setting.csvHeaders}
                                          >
                                            DOWNLOAD
                                          </CSVLink>
                                        </DashboardSmallCTA>
                                      )}
                                    </>
                                  ) : setting.formName === "delete_request" ? (
                                    <DashboardSmallCTA
                                      onClick={() => this._deletionRequest()}
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#FE4A49"
                                      key={`keytwelve` + index}
                                    >
                                      {setting.buttonText.toUpperCase()}
                                    </DashboardSmallCTA>
                                  ) : setting.formName ===
                                    "ownership_transfer" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        this.setState({
                                          transferModalOpen: true,
                                        })
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                      key={`keyeleven` + index}
                                    >
                                      {setting.buttonText.toUpperCase()}
                                    </DashboardSmallCTA>
                                  ) : setting.formName ===
                                    "industry_designation" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        this.setState({
                                          industryDesignationModalOpen: true,
                                        })
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                      key={`keythirteen` + index}
                                    >
                                      {setting.buttonText.toUpperCase()}
                                    </DashboardSmallCTA>
                                  ) : setting.formName === "company_goal" &&
                                    !companySettings.company_goal ? (
                                    <Field
                                      id={setting.formName}
                                      name={setting.formName}
                                      component={setting.inputType}
                                      type="text"
                                      key={`keyfield` + index}
                                      disabled={companySettings.company_goal}
                                      style={{
                                        width: "130px",
                                        height: "39px",
                                        border: "1px solid #707070",
                                        color: "#000000",
                                        fontFamily: "NunitoSans",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        paddingLeft: "5px",
                                      }}
                                      onChange={(e) =>
                                        this._handleCompanyGoalChange(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option style={{ display: "none" }}>
                                        Select Goal
                                      </option>
                                      <option value="receive_info">
                                        RECEIVE INFO
                                      </option>
                                      <option value="share_info">
                                        SHARE INFO
                                      </option>
                                    </Field>
                                  ) : setting.formName === "company_goal" &&
                                    companySettings.company_goal ? (
                                    <NunitoSansSemiBold
                                      fontSize="14px"
                                      color="#000000"
                                    >
                                      {companySettings.company_goal ===
                                      "share_info"
                                        ? "SHARE INFO"
                                        : "RECEIVE INFO"}
                                    </NunitoSansSemiBold>
                                  ) : setting.inputType === "switch" ? (
                                    <Field
                                      key={`keyfieldtwo` + index}
                                      id={setting.formName}
                                      name={setting.formName}
                                      // type="checkmark"
                                      type="text"
                                      component={SwitchInput}
                                      onColor="#2FD371"
                                      offColor="#D3D3D3"
                                      onChange={() =>
                                        this._handleSwitchChange(
                                          setting.formName
                                        )
                                      }
                                    />
                                  ) : setting.inputType === "button" &&
                                    (setting.formName ===
                                      "receive_incentives" ||
                                      setting.formName ===
                                        "create_incentives" ||
                                      setting.formName ===
                                        "receive_vendor_incentives" ||
                                      setting.formName ===
                                        "create_vendor_incentives" ||
                                      setting.formName === "splits_active" ||
                                      setting.formName ===
                                        "sale_verification_active") ? (
                                    <DashboardSmallCTA
                                      borderColor={
                                        !company.paid &&
                                        setting.formName ===
                                          "create_vendor_incentives"
                                          ? "transparent"
                                          : undefined
                                      }
                                      disabled={
                                        !company.paid &&
                                        setting.formName ===
                                          "create_vendor_incentives"
                                      }
                                      onClick={() => {
                                        if (
                                          setting.formName ===
                                          "sale_verification_active"
                                        ) {
                                          this._handleVerificationChange();
                                        } else if (
                                          setting.formName === "splits_active"
                                        ) {
                                          this._handleSplitsChange();
                                        } else if (
                                          (setting.formName ===
                                            "receive_incentives" &&
                                            !companySettings.receive_incentives) ||
                                          (setting.formName ===
                                            "receive_vendor_incentives" &&
                                            !companySettings.receive_vendor_incentives)
                                        ) {
                                          // initial activation of receive incentives when there is not company stripe account set
                                          // this.setState({stripeModalOpen: true})
                                          this.setState({
                                            incentiveActivationModalOpen: true,
                                            incentiveModalType:
                                              setting.formName,
                                          });
                                        } else if (
                                          (setting.formName ===
                                            "receive_incentives" &&
                                            companySettings.receive_incentives) ||
                                          (setting.formName ===
                                            "receive_vendor_incentives" &&
                                            companySettings.receive_vendor_incentives)
                                        ) {
                                          //turning receive incentives OFF
                                          this.setState({
                                            warningModalOpen: true,
                                            warningModalType: setting.formName,
                                          });
                                        } else if (
                                          (setting.formName ===
                                            "create_incentives" &&
                                            !companySettings.create_incentives) ||
                                          (setting.formName ===
                                            "create_vendor_incentives" &&
                                            !companySettings.create_vendor_incentives)
                                        ) {
                                          //turning create incentives ON

                                          this.setState({
                                            incentiveActivationModalOpen: true,
                                            incentiveModalType:
                                              setting.formName,
                                          });
                                        } else if (
                                          (setting.formName ===
                                            "create_incentives" &&
                                            companySettings.create_incentives) ||
                                          (setting.formName ===
                                            "create_vendor_incentives" &&
                                            companySettings.create_vendor_incentives)
                                        ) {
                                          //turning create incentives OFF
                                          this.setState({
                                            warningModalOpen: true,
                                            warningModalType: setting.formName,
                                          });
                                        } else if (
                                          setting.formName ===
                                            "create_vendor_incentives" &&
                                          !companySettings.create_vendor_incentives
                                        ) {
                                          //turning create vendor incentives ON

                                          this._handleVendorIncentiveChange(
                                            "create_vendor_incentives",
                                            { create_vendor_incentives: true }
                                          );
                                        } else if (
                                          setting.formName ===
                                            "create_vendor_incentives" &&
                                          companySettings.create_vendor_incentives
                                        ) {
                                          //turning create vendor incentives OFF
                                          this._handleVendorIncentiveChange(
                                            "create_vendor_incentives",
                                            { create_vendor_incentives: false }
                                          );
                                        } else if (
                                          setting.formName ===
                                            "receive_vendor_incentives" &&
                                          !companySettings.receive_vendor_incentives
                                        ) {
                                          //turning receive vendor incentives ON

                                          this._handleVendorIncentiveChange(
                                            "receive_vendor_incentives",
                                            { receive_vendor_incentives: true }
                                          );
                                        } else if (
                                          setting.formName ===
                                            "receive_vendor_incentives" &&
                                          companySettings.receive_vendor_incentives
                                        ) {
                                          //turning create vendor incentives OFF
                                          this._handleVendorIncentiveChange(
                                            "receive_vendor_incentives",
                                            { receive_vendor_incentives: false }
                                          );
                                        }
                                      }}
                                      minWidth={["130px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      // width="130px"
                                      color="#428BF4"
                                    >
                                      {!company.paid &&
                                      setting.formName ===
                                        "create_vendor_incentives"
                                        ? "PLUS UPGRADE REQUIRED"
                                        : (setting.formName ===
                                            "receive_incentives" &&
                                            companySettings.receive_incentives) ||
                                          (setting.formName ===
                                            "receive_vendor_incentives" &&
                                            companySettings.receive_vendor_incentives) ||
                                          (setting.formName ===
                                            "create_vendor_incentives" &&
                                            companySettings.create_vendor_incentives) ||
                                          (setting.formName ===
                                            "create_incentives" &&
                                            companySettings.create_incentives) ||
                                          (setting.formName ===
                                            "splits_active" &&
                                            companySettings.splits_active) ||
                                          (setting.formName ===
                                            "sale_verification_active" &&
                                            companySettings.sale_verification_active)
                                        ? "DEACTIVATE"
                                        : "ACTIVATE"}
                                    </DashboardSmallCTA>
                                  ) : setting.formName === "rep_fee" ? (
                                    <Field
                                      id={setting.formName}
                                      name={setting.formName}
                                      component={setting.inputType}
                                      type="text"
                                      style={{
                                        width: "100px",
                                        height: "39px",
                                        border: "1px solid #707070",
                                        color: "#000000",
                                        fontFamily: "NunitoSans",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        paddingLeft: "5px",
                                      }}
                                      props={{
                                        disabled:
                                          !companySettings.stripe_connect_id,
                                      }}
                                      onChange={(e) =>
                                        this._handleSalesRepFeeChange(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {Array(11)
                                        .fill("1")
                                        .map((v, i) => (
                                          <option key={i} value={i}>
                                            {`${i} %`}
                                          </option>
                                        ))}
                                      {Array(8)
                                        .fill("1")
                                        .map((v, i) => (
                                          <option
                                            key={i + 100}
                                            value={(i + 3) * 5}
                                          >
                                            {`${(i + 3) * 5} %`}
                                          </option>
                                        ))}
                                    </Field>
                                  ) : setting.formName === "partner_fee" ? (
                                    <Field
                                      id={setting.formName}
                                      name={setting.formName}
                                      component={setting.inputType}
                                      type="text"
                                      style={{
                                        width: "100px",
                                        height: "39px",
                                        border: "1px solid #707070",
                                        color: "#000000",
                                        fontFamily: "NunitoSans",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        paddingLeft: "5px",
                                      }}
                                      props={{
                                        disabled:
                                          !companySettings.stripe_connect_id,
                                      }}
                                      onChange={(e) =>
                                        this._handlePartnerFeeChange(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {Array(11)
                                        .fill("1")
                                        .map((v, i) => (
                                          <option key={i + 1000} value={i}>
                                            {`${i} %`}
                                          </option>
                                        ))}
                                      {Array(8)
                                        .fill("1")
                                        .map((v, i) => (
                                          <option
                                            key={i + 10000}
                                            value={(i + 3) * 5}
                                          >
                                            {`${(i + 3) * 5} %`}
                                          </option>
                                        ))}
                                    </Field>
                                  ) : setting.formName === "stripe_login" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        companySettings.stripe_connect_id
                                          ? this._handleStripeLogin()
                                          : this.setState({
                                              stripeModalOpen: true,
                                            })
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                    >
                                      {companySettings.stripe_connect_id
                                        ? "LOGIN"
                                        : "ACTIVATE"}
                                    </DashboardSmallCTA>
                                  ) : setting.formName === "edit_address" ? (
                                    <DashboardSmallCTA
                                      onClick={() =>
                                        this.setState({
                                          billingInfoModalOpen: true,
                                        })
                                      }
                                      minWidth={["122px"]}
                                      fontSize="14px"
                                      innerHeight="30px"
                                      width="130px"
                                      color="#428BF4"
                                    >
                                      EDIT
                                    </DashboardSmallCTA>
                                  ) : null}
                                </Flex>
                              </Flex>
                            </Flex>
                            {(setting.formName === "create_incentives_main" &&
                              createIncentivesExpanded) ||
                            (setting.formName === "receive_incentives_main" &&
                              receiveIncentivesExpanded) ? (
                              <Flex
                                flexDirection="column"
                                style={{ paddingLeft: "25px" }}
                              >
                                {setting.children
                                  .filter((item) => item.show)
                                  .map((childSetting, childIndex) => (
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                      style={{
                                        borderTop:
                                          childIndex !== 0
                                            ? "1px solid #D3D3D3"
                                            : "none",
                                      }}
                                    >
                                      <Flex
                                        flexDirection="column"
                                        style={{
                                          marginTop: "13px",
                                          marginBottom: "13px",
                                        }}
                                      >
                                        <NunitoSans
                                          fontSize="14px"
                                          fontWeight="bold"
                                          color="#10213A"
                                        >
                                          {childSetting.name.toUpperCase()}
                                        </NunitoSans>
                                        <NunitoSans
                                          fontSize="14px"
                                          color="#000000"
                                        >
                                          {childSetting.description}
                                        </NunitoSans>
                                      </Flex>

                                      <Flex
                                        flexDirection="column"
                                        style={{
                                          marginTop: "13px",
                                          marginBottom: "13px",
                                        }}
                                      >
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          {childSetting.inputType ===
                                            "button" &&
                                          ((childSetting.formName ===
                                            "create_vendor_incentives" &&
                                            featureFlags.create_partner_incentives ===
                                              "locked") ||
                                            (childSetting.formName ===
                                              "create_incentives" &&
                                              featureFlags.create_sales_incentives ===
                                                "locked") ||
                                            (childSetting.formName ===
                                              "receive_vendor_incentives" &&
                                              featureFlags.receive_partner_incentives ===
                                                "locked") ||
                                            (childSetting.formName ===
                                              "receive_incentives" &&
                                              featureFlags.receive_sales_incentives ===
                                                "locked")) ? (
                                            <LockedFeature
                                              text={`Upgrade to add ${
                                                childSetting.formName.includes(
                                                  "vendor"
                                                )
                                                  ? "Partner"
                                                  : "Sales"
                                              } Incentives`}
                                              fontSize="14px"
                                              width="300px"
                                              padding="5px"
                                            />
                                          ) : childSetting.inputType ===
                                              "button" &&
                                            (childSetting.formName ===
                                              "receive_incentives" ||
                                              childSetting.formName ===
                                                "create_incentives" ||
                                              childSetting.formName ===
                                                "receive_vendor_incentives" ||
                                              childSetting.formName ===
                                                "create_vendor_incentives") ? (
                                            <DashboardSmallCTA
                                              onClick={() => {
                                                if (
                                                  (childSetting.formName ===
                                                    "receive_incentives" &&
                                                    !companySettings.receive_incentives) ||
                                                  (childSetting.formName ===
                                                    "receive_vendor_incentives" &&
                                                    !companySettings.receive_vendor_incentives)
                                                ) {
                                                  // initial activation of receive incentives when there is not company stripe account set
                                                  // this.setState({stripeModalOpen: true})
                                                  this.setState({
                                                    incentiveActivationModalOpen: true,
                                                    incentiveModalType:
                                                      childSetting.formName,
                                                  });
                                                } else if (
                                                  (childSetting.formName ===
                                                    "receive_incentives" &&
                                                    companySettings.receive_incentives) ||
                                                  (childSetting.formName ===
                                                    "receive_vendor_incentives" &&
                                                    companySettings.receive_vendor_incentives)
                                                ) {
                                                  //turning receive incentives OFF
                                                  this.setState({
                                                    warningModalOpen: true,
                                                    warningModalType:
                                                      childSetting.formName,
                                                  });
                                                } else if (
                                                  (childSetting.formName ===
                                                    "create_incentives" &&
                                                    !companySettings.create_incentives) ||
                                                  (childSetting.formName ===
                                                    "create_vendor_incentives" &&
                                                    !companySettings.create_vendor_incentives)
                                                ) {
                                                  //turning create incentives ON

                                                  this.setState({
                                                    incentiveActivationModalOpen: true,
                                                    incentiveModalType:
                                                      childSetting.formName,
                                                  });
                                                } else if (
                                                  (childSetting.formName ===
                                                    "create_incentives" &&
                                                    companySettings.create_incentives) ||
                                                  (childSetting.formName ===
                                                    "create_vendor_incentives" &&
                                                    companySettings.create_vendor_incentives)
                                                ) {
                                                  //turning create incentives OFF
                                                  this.setState({
                                                    warningModalOpen: true,
                                                    warningModalType:
                                                      childSetting.formName,
                                                  });
                                                } else if (
                                                  childSetting.formName ===
                                                    "create_vendor_incentives" &&
                                                  !companySettings.create_vendor_incentives
                                                ) {
                                                  //turning create vendor incentives ON

                                                  this._handleVendorIncentiveChange(
                                                    "create_vendor_incentives",
                                                    {
                                                      create_vendor_incentives: true,
                                                    }
                                                  );
                                                } else if (
                                                  childSetting.formName ===
                                                    "create_vendor_incentives" &&
                                                  companySettings.create_vendor_incentives
                                                ) {
                                                  //turning create vendor incentives OFF
                                                  this._handleVendorIncentiveChange(
                                                    "create_vendor_incentives",
                                                    {
                                                      create_vendor_incentives: false,
                                                    }
                                                  );
                                                } else if (
                                                  childSetting.formName ===
                                                    "receive_vendor_incentives" &&
                                                  !companySettings.receive_vendor_incentives
                                                ) {
                                                  //turning receive vendor incentives ON

                                                  this._handleVendorIncentiveChange(
                                                    "receive_vendor_incentives",
                                                    {
                                                      receive_vendor_incentives: true,
                                                    }
                                                  );
                                                } else if (
                                                  childSetting.formName ===
                                                    "receive_vendor_incentives" &&
                                                  companySettings.receive_vendor_incentives
                                                ) {
                                                  //turning create vendor incentives OFF
                                                  this._handleVendorIncentiveChange(
                                                    "receive_vendor_incentives",
                                                    {
                                                      receive_vendor_incentives: false,
                                                    }
                                                  );
                                                }
                                              }}
                                              minWidth={["130px"]}
                                              fontSize="14px"
                                              innerHeight="30px"
                                              // width="130px"
                                              color="#428BF4"
                                            >
                                              {(childSetting.formName ===
                                                "receive_incentives" &&
                                                companySettings.receive_incentives) ||
                                              (childSetting.formName ===
                                                "receive_vendor_incentives" &&
                                                companySettings.receive_vendor_incentives) ||
                                              (childSetting.formName ===
                                                "create_vendor_incentives" &&
                                                companySettings.create_vendor_incentives) ||
                                              (childSetting.formName ===
                                                "create_incentives" &&
                                                companySettings.create_incentives)
                                                ? "DEACTIVATE"
                                                : "ACTIVATE"}
                                            </DashboardSmallCTA>
                                          ) : null}
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  ))}
                              </Flex>
                            ) : null}
                          </Flex>
                        );
                      })}
                  </div>
                ))
              : null}
          </form>
        </Flex>
        <Modal
          isOpen={stripeModalOpen}
          onRequestClose={() => {
            this.props.change(
              incentiveModalType.includes("vendor")
                ? "receive_vendor_incentives"
                : "receive_incentives",
              false
            );
            this.setState({ stripeModalOpen: false });
          }}
          style={customStyles}
          contentLabel="Stripe Modal"
          ariaHideApp={false}
        >
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
          >
            <NunitoSansBold alignSelf="center" fontsSize="18px">
              {`RECIEVING ${
                incentiveModalType.includes("vendor") ? "PARTNER" : "SALES"
              } INCENTIVES`}
            </NunitoSansBold>
            <br />

            <NunitoSans>
              {companySettings.is_single_user_company
                ? "We’ve made it easy for you to receive rewards from all your partners. Its a simple three-step process:"
                : `We’ve made it easy for ${
                    incentiveModalType.includes("vendor")
                      ? "your company"
                      : "you and your sales reps"
                  } to receive rewards from all your partners. Its a simple three-step process:`}
            </NunitoSans>
            <br />
            <NunitoSansBold>Step 1:</NunitoSansBold>
            <NunitoSans>
              {companySettings.is_single_user_company
                ? "You earn rewards by selling qualified products."
                : `Your ${
                    incentiveModalType.includes("vendor")
                      ? "company"
                      : "sales reps"
                  } earns rewards by selling qualified products.`}
            </NunitoSans>
            <br />
            <NunitoSansBold>Step 2:</NunitoSansBold>
            <NunitoSans>
              You upload sales to the system and we’ll calculate the results.
            </NunitoSans>
            <br />
            <NunitoSansBold>Step 3:</NunitoSansBold>
            <NunitoSans>Your vendors pay KickPost and we pay you.</NunitoSans>
            <br />
            <NunitoSans>
              {companySettings.is_single_user_company
                ? "To receive your rewards you need to set-up your Stripe account. This account is secure and simple to use."
                : `${
                    incentiveModalType.includes("vendor")
                      ? "To receive your partner incentives"
                      : "To receive your sales rep fee and receive incentives not associated to active users"
                  }, you’re required to activate a company Stripe account. This account is secure and simple to use.`}
            </NunitoSans>
            <br />
            <NunitoSans>It's that simple!</NunitoSans>
            <br />
            <DashboardSmallCTA
              onClick={() => onboardStripeConnectAccount(true)}
              minWidth={["122px"]}
              fontSize="14px"
              innerHeight="35px"
              width="225px"
              color="#428BF4"
              alignSelf="center"
            >
              CREATE STRIPE ACCOUNT
            </DashboardSmallCTA>
          </Flex>
        </Modal>
      </>
    );
  }
}

ManageSettingsMain = reduxForm({
  // destroyOnUnmount: true,
  form: "settings-form",
  // validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ManageSettingsMain);

export default connect(
  (state) => ({
    browser: state.browser,
    formValues: getFormValues("settings-form")(state),
    company: state.resources.detail.company,
    loading: state.resources.detail.loading.post,
    post: state.resources.detail.post,
    sales: state.resources.collection.sale || [],
    rewards: state.resources.collection.reward || [],
    invoices: state.resources.collection.invoice || [],
    employees: state.resources.collection.user || [],
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    selectedUsers: state.post.selectedUsers,
    companyResults: state.search.companySearchResults,
    //companyResults: state.search.companySearchResults || [],
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    user: state.resources.detail.user,
    userz: state.resources.detail.users,
    users: state.resources.collection.users,
    connections: state.resources.collection.connection || [],
    incentiveHovered: state.incentives.incentiveHovered,
    incentiveSelected: state.incentives.incentiveSelected,
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen,
  }),
  (dispatch) => ({
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createSale: bindActionCreators(actions.createSale, dispatch),
    createSalesupload: bindActionCreators(actions.createSalesupload, dispatch),
    //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch),
    fetchSalesuploads: bindActionCreators(actions.fetchSalesuploads, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch),
    updateSale: bindActionCreators(actions.patchSale, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
  })
)(ManageSettingsMain);

// export default ManageSettingsMain;

class ManageSettingsNav extends Component {
  state = {
    verificationModalOpen: false,
  };

  _handleVerification = async () => {
    const { verificationFormValues, resetForm } = this.props;

    if (!verificationFormValues || !verificationFormValues.code)
      return this.props.change("code", "");

    let code = verificationFormValues.code.trim();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/email_verifications`,
        { email_verification_code: code }
      );

      if (response && response.data.email_verified_at) {
        this._handleSave();
        this.setState({ verificationModalOpen: false });
      }
    } catch (err) {
      return this.props.change("code", "");
    }
  };

  _handleClick = async () => {
    const { formValues, systemSettings } = this.props;

    this._handleSave();
  };

  _handleSave = async () => {
    const { formValues, updateSettings, companySettings, notifySuccess } =
      this.props;

    const payload = {
      create_incentives: formValues.create_incentives,
      receive_incentives: formValues.receive_incentives,
      rep_fee: formValues.rep_fee,
      partner_fee: formValues.partner_fee,
    };

    await updateSettings(getUser().company_id, payload);

    return notifySuccess("Company Settings updated successfully.");
  };

  render() {
    const { notificationCounts } = this.props;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        height: "300px",
        zIndex: 999998,
        overflow: "auto",
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88",
      },
    };

    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {getUser().system_admin &&
          getUser().company_id ===
            process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID ? (
            <div style={{ position: "relative" }}>
              <select
                id="company_settings_nav_dropdown"
                style={{
                  padding: "10px 25px",
                  border: "1px solid #428BF4",
                  borderRadius: "9px",
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "NunitoSans",
                  color: "#000000",
                }}
                onChange={(e) => history.push(`/dashboard/${e.target.value}`)}
              >
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="settings"
                >
                  ACCOUNT SETTINGS
                </option>
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="systemsettings"
                >
                  {`SYSTEM SETTINGS${
                    notificationCounts.settings
                      ? " (" + notificationCounts.settings + ")"
                      : ""
                  }`}
                </option>
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="systemreports"
                >
                  SYSTEM REPORTS
                </option>
              </select>
              {notificationCounts.settings ? (
                <NavNotificationBubble top="-5px" right="-5px">
                  {notificationCounts.settings || 0}
                </NavNotificationBubble>
              ) : null}
            </div>
          ) : (
            <NunitoSans
              pt={["6px", "6px", "8px", "6px"]}
              fontSize={[14, 14, 18, 18]}
              fontWeight={["bold"]}
            >
              ACCOUNT SETTINGS
            </NunitoSans>
          )}
          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center",
              ]}
            >
              <BackButton />
            </Flex>
          </AbsoluteDiv>
          {/* <AbsoluteDiv
            right={["0px", "0px", "0px", "0px", "-5%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
        <DashboardSmallCTA
        width="15%"
        onClick={()=>this._handleClick()}
        minWidth={["122px"]}
        fontSize="17px"
        innerHeight="40px"
        width="130px"
        color="#428BF4"
        >
          SAVE
        </DashboardSmallCTA>

          </AbsoluteDiv> */}
        </Flex>
        <Modal
          isOpen={this.state.verificationModalOpen}
          onRequestClose={() => this.setState({ verificationModalOpen: false })}
          style={customStyles}
          contentLabel="Verification Modal"
          ariaHideApp={false}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <NunitoSans
              fontSize="22px"
              fontWeight="bold"
              style={{ marginTop: "10px", marginBottom: "15px" }}
            >
              Verification Required
            </NunitoSans>
            <NunitoSans fontSize="14px" style={{ marginBottom: "15px" }}>
              {`A verification code has been sent to ${
                getUser().email
              }. Please enter it below.`}
            </NunitoSans>
            <form id="settings_verification-form">
              <Field
                // label="CODE"
                name="code"
                component={renderCondensedField}
                type="text"
                mb="0px"
                key={"0px"}
              />
            </form>

            <DashboardSmallCTA
              onClick={() => this._handleVerification()}
              fontSize="15px"
              width="130px"
              color="#428BF4"
            >
              SUBMIT
            </DashboardSmallCTA>
          </Flex>
        </Modal>
      </Flex>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "CHECK YOUR VERIFICATION CODE";
  }

  return errors;
};

ManageSettingsNav = reduxForm({
  destroyOnUnmount: true,
  form: "settings_verification-form",
  // initialValues: {
  //   rewardsnav: 'sales'
  // },
  validate,
  // enableReinitialize : true
})(ManageSettingsNav);

ManageSettingsNav = connect(
  (state) => ({
    verificationFormValues: getFormValues("settings_verification-form")(state),
    formValues: getFormValues("settings-form")(state),
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  (dispatch) => ({
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
  })
)(ManageSettingsNav);

export { ManageSettingsNav };

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const StripeBillingModal = ({
  modalOpen = false,
  handleCloseRequest,
  isSingleUser = false,
  company,
  dispatch,
  handleAnnualInvoice,
  submitStripe,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "650px",
      // height: "550px",
      zIndex: 16777271,
      overflow: "auto",
      padding: "80px 60px",
      border: "1px solid #707070",
      borderRadius: "44px",
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88",
    },
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => handleCloseRequest()}
      style={customStyles}
      contentLabel={`Stripe Billing Modal`}
      ariaHideApp={false}
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <FontAwesomeIcon icon={faPaperPlane} color="#428BF4" size="4x" />

        <NunitoSansBold
          fontSize="16px"
          color="#428BF4"
          style={{ marginBottom: "15px", marginTop: "20px" }}
        >
          {isSingleUser ? "INDIVIDUAL" : "COMPANY"}
        </NunitoSansBold>

        <NunitoSansBold
          fontSize="28px"
          color="#000000"
          alignText="center"
          style={{ marginBottom: "15px" }}
        >
          Start Sharing Posts
        </NunitoSansBold>

        <NunitoSansSemiBold fontSize="18px" color="#000000" textAlign="center">
          {isSingleUser
            ? "Take your KickPost account to the next level by adding the ability to share unlimited posts with your network."
            : "Upgrade your company account to allow your team to share unlimited posts with your partners. You have the option to pay annually."}
        </NunitoSansSemiBold>

        <NunitoSans
          fontSize="14px"
          color="#000000"
          style={
            isSingleUser
              ? { marginBottom: "35px", marginTop: "25px" }
              : { marginBottom: "35px" }
          }
        >
          <li>Credit Card Required</li>
        </NunitoSans>

        <SettingsBilling
          user={getUser()}
          submitStripe={submitStripe}
          company={company}
          handleInvoiceInfo={handleAnnualInvoice}
          customButtonText="COMPLETE"
          customWidth="100%"
          isSingleUser={isSingleUser}
          dispatch={dispatch}
        />
      </Flex>
    </Modal>
  );
};

const IndustryDesignationModal = ({
  modalOpen = false,
  industryCategories,
  selectedCategories,
  toggleCategory,
  handleCloseRequest,
  saveChanges,
  errorMessage = "",
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "575px",
      height: "550px",
      zIndex: 16777271,
      overflow: "auto",
      padding: "50px 60px",
      border: "1px solid #707070",
      borderRadius: "44px",
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88",
    },
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => handleCloseRequest()}
      style={customStyles}
      contentLabel={`Industry Designation Modal`}
      ariaHideApp={false}
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <NunitoSansBold fontSize="16px" color="#428BF4">
          YOUR INDUSTRY
        </NunitoSansBold>

        <NunitoSansBold
          fontSize="28px"
          color="#000000"
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Choose Your Industry
        </NunitoSansBold>

        <NunitoSansSemiBold
          fontSize="18px"
          color="#000000"
          textAlign="center"
          style={{ marginBottom: "70px" }}
        >
          Select at least one option from the list below.
        </NunitoSansSemiBold>

        <IndustryCategories
          industryCategories={industryCategories}
          selectedCategories={selectedCategories}
          toggleCategory={toggleCategory}
        />

        {errorMessage ? (
          <p
            style={{
              color: "#FE4A49",
              fontSize: "14px",
            }}
          >
            {errorMessage}
          </p>
        ) : null}

        <DashboardSmallCTA
          width="100%"
          minWidth="130px"
          innerHeight="35px"
          fontSize="16px"
          disabled={selectedCategories.length < 1}
          onClick={() => saveChanges()}
          style={{ marginTop: errorMessage ? "21px" : "70px" }}
        >
          SAVE
        </DashboardSmallCTA>
      </Flex>
    </Modal>
  );
};

const IndustryCategories = ({
  industryCategories = [],
  selectedCategories,
  toggleCategory,
}) => {
  const theFirstHalf = Math.ceil(industryCategories.length / 2);

  const firstColumn = industryCategories.slice(0, theFirstHalf);
  const secondColumn = industryCategories.slice(theFirstHalf);

  return (
    <Flex width="100%" justifyContent="space-between">
      <Flex flexDirection="column">
        {firstColumn.map((category, index) => (
          <CategoryBox
            key={index}
            category={category}
            selected={selectedCategories
              .map((cat) => cat.id)
              .includes(category.id)}
            isFirst={index === 0}
            toggleCategory={toggleCategory}
          />
        ))}
      </Flex>

      <Flex flexDirection="column">
        {secondColumn.map((category, index) => (
          <CategoryBox
            key={theFirstHalf + index}
            category={category}
            selected={selectedCategories
              .map((cat) => cat.id)
              .includes(category.id)}
            isFirst={index === 0}
            toggleCategory={toggleCategory}
          />
        ))}
      </Flex>
    </Flex>
  );
};

const CategoryBox = (props) => (
  <Flex
    alignItems="center"
    width="250px"
    style={{
      border: `1px solid ${props.selected ? "#428BF4" : "#C3C3C3"}`,
      backgroundColor: props.selected ? "#428BF4" : "transparent",
      marginTop: !props.isFirst ? "35px" : "none",
      paddingBottom: "10px",
      paddingTop: "10px",
      cursor: "pointer",
    }}
    onClick={() => props.toggleCategory(props.category)}
  >
    <FontAwesomeIcon
      icon={props.selected ? faMinus : faPlus}
      color={props.selected ? "#FFFFFF" : "#C3C3C3"}
      style={{ marginLeft: "20px", fontSize: "22px" }}
    />

    <NunitoSans
      color={props.selected ? "#FFFFFF" : "#C3C3C3"}
      fontSize="22px"
      style={{ marginLeft: "20px" }}
    >
      {props.category.name}
    </NunitoSans>
  </Flex>
);
