import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Flex } from "../common/flexbox";
import { NunitoSansSemiBold } from "../common/typography";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
    setUpgradeModalOpen
  } from "../../actions/rule";

let LockedFeature = (props) => {

    const { text, width="100%", padding="20px", fontSize="16px", noBorder = false, iconSize = "xl", marginBottom = 0 } = props;

    const boxStyle = {
        paddingTop: padding,
        paddingBottom: padding,
        cursor: "pointer",
        marginBottom
    };

    if(!noBorder){
        boxStyle.border = "1px dashed #979797";
        boxStyle.borderRadius = "8px";
    }

    return (
        <Flex
        justifyContent="center"
        alignItems="center"
        width = {width}
        onClick={() => props.setUpgradeModalOpen(true)}
        style={boxStyle}
        >
            <Flex
            alignItems="center"
            >

                <FontAwesomeIcon
                icon={faCircleArrowUp}
                color="#979797"
                size={iconSize}
                />

                <NunitoSansSemiBold
                color="#979797"
                fontSize={fontSize}
                style={{
                    marginLeft: "10px"
                }}
                >
                    {text}
                </NunitoSansSemiBold>
            </Flex>
        </Flex>
    )
};

LockedFeature = connect(
    state => ({
      browser: state.browser,
     
    }),
    dispatch => ({
        setUpgradeModalOpen: bindActionCreators(setUpgradeModalOpen, dispatch),
    })
  )(LockedFeature);



export  {LockedFeature};