import React, {
  Component,
  useState,
  useEffect
} from "react";
import {
  connect
} from "react-redux";
import {
  bindActionCreators
} from "redux";
import titleize from "underscore.string/titleize";
import moment from "moment";
import {
  push
} from "react-router-redux";

import InfiniteScroll from "react-infinite-scroller";
import {
  history
} from "../../../store";
import _ from "underscore";

import { isEqual } from "lodash";

//import ScrollArea from 'react-scrollbar';

//actions
//  ,updatePostSearchResults
import {
  searchPosts,
  searchIncentives,
  searchNewsFeed
} from "../../../actions/search";
import {
  actions
} from "../../../actions/resources";
import {
  success
} from "../../../actions/alerts";
//utils
import {
  getAccessory,
  getUser,
  setAccessory
} from "../../../utils/auth";
import {
  mimeToImage
} from "../../../utils/mimetypes";
//components
import {
  NunitoSansBlack,
  NunitoSansBlackItalic,
  NunitoSansBold,
  NunitoSansBoldItalic,
  NunitoSansExtraBold,
  NunitoSansExtraBoldItalic,
  NunitoSansExtraLight,
  NunitoSansExtraLightItalic,
  NunitoSansLight,
  NunitoSansLightItalic,
  NunitoSansMedium,
  NunitoSans,
  NunitoSansRegularItalic,
  NunitoSansSemiBold,
  NunitoSansSemiBoldItalic
} from "../../common/typography";
import {
  InputStyled,
  DashboardSmallCTA
} from "../../common/forms";
import {
  FeedPostMobile,
  FeedPostLaptop,
  FeedIncentiveLaptop,
  FeedIncentiveMobile
} from "../../common/post";
import styled from "styled-components";
import {
  Box,
  Flex,
  FlexGrow,
  IconBox
} from "../../common/flexbox";
import {
  AbsoluteDiv
} from "../../common/absolute";
import {
  OneSixDash979797,
  NavButton,
  GradientOverflowDownwardLaptop,
  ProfileCompletion,
  SystemNotifications,
  RecommendedContentCTA,
  BillingCTA,
  GettingStartedProcess
} from "../../common/ui";
import {
  Link
} from "../../common/navigation";
import {
  MobileMenu
} from "../mobileMenu";
import {
  mainContentScrollTop
} from "../../../actions/dashboardPresentation";
//images

import { defaultProfile, defaultCompany } from "../../../utils/defaultLogos";


import { InitialLoginModal, PendingConnectionsModal } from "./initialLoginModal";
import { LockedFeature } from "../../common/lockedfeature";


export class Feed extends Component {

  componentWillUnmount() {
    const {
      searchPosts,
      searchIncentives,
      user,
      companySettings,
      systemSettings

    } = this.props;
    const {
      currentUser,
      vendorIds,
      loadedPosts
    } = this.state;

    // this._isMounted = false;
    this.state._isMounted = false;
    this.state.allrows = [];
    this.setState({
      loadedPosts: [],
      allrows: [],
      unmounting: true
    })
    // window.alert("this.state.unmounting: true")
    if (vendorIds && !vendorIds.includes(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)) {
      vendorIds.push(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
    }
    searchNewsFeed(vendorIds, user, "", 0, companySettings, systemSettings);

  }


  constructor(props) {
    super(props);
    this.state = {
      companySetupDismissed: false,
      receiveIncentivesDismissed: false,
      salesRepDismissed: false,
      vendorIds: [],
      loadedPosts: [],
      allrows: [],
      shouldRender: false,
      unmounting: false,
      _isMounted: false,
      currentUser: {},
      dismissAccessory: {},
      getStartedCompanyCheck: {}
    }
  }

  // _isMounted = false;


  componentDidMount() {
    this._checkStripe();
    // console.log("Feed componentDidMount");
    setTimeout(() => this.setState({ shouldRender: true }), 3000);
    const {
      posts,
      newsFeed,
    } = this.props;
    // this._isMounted = true;
    this.setState({
      _isMounted: true
    });
    sessionStorage.removeItem('searchValue');

    if (this.state._isMounted === true) {
      this.setState({
        showBubble: false,
        posts: {},
        loadedPosts: [],
        dismissAccessory: false,
        allrows: [],
        unmounting: false
      });
    }
    this.props.adjustScrollTop(0);
    
    this._fetchDependencies();

  }

  componentDidUpdate(prevProps) {
    // console.log("componentdidupdate")

    if (!isEqual(prevProps.newsFeed, this.props.newsFeed)) {
      this._handleNewItems();
    }

    if(!isEqual(prevProps.companySettings, this.props.companySettings)){
      this.setState({allrows: []});
      this._handleNewItems()
    }

  }

  _fetchDependencies = async () => {
    let DMG = "_fetchDependencies";
    // console.log(DMG);
    const userCompanyID = getUser().company_id;

    const {
      fetchConnections,
      fetchUser,
      fetchSettings,
      postsCurrentPage,
      searchPosts,
      searchNewsFeed,
      newsFeed,
      searchIncentives,
      fetchNotifications,
      posts,
      // user,
      companySettings,
      systemSettings
    } = this.props;

    // console.log(DMG,"newsFeed",newsFeed)

    if (newsFeed && newsFeed.length) {
      this._handleNewItems();
    }

    // 

    if (getAccessory()) {
      if (getAccessory().companySetup) {
        this.setState({ companySetupDismissed: getAccessory().companySetup });
      }

      if (getAccessory().receiveIncentives) {
        this.setState({ receiveIncentivesDismissed: getAccessory().receiveIncentives });
      }

      if (getAccessory().salesRep) {
        this.setState({ salesRepDismissed: getAccessory().salesRep });
      }
    }

    const notifications = await fetchNotifications({
      company_id: userCompanyID,
      id: getUser().id
    });
    //console.log(DMG,notifications.payload);//this is the raw notifications returned from querying the PostgreSQL database notifications table
    if (notifications && notifications.payload) {
      this.setState({
        notificationsResults: notifications.payload
      });
    }

    // const user2 = await fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${getUser().company_id}&status=active`).then(res => res.json());
    // console.log(DMG,"index user2", user2);

    // const user = await fetch(`${process.env.REACT_APP_API_URL}/users/${getUser().id}&edit=true`).then(res => res.json());

    let user = await fetchUser(getUser().id);
    if (!user) {
      fetchUser(getUser().id);
    }

    // user = this.props.user;

    // console.log(DMG,"this.props.user", this.props.user);
    // console.log(DMG,"user", user);


    //   .then(res => res.json());

    // const user = await fetchUser(getUser().id, {
    //   edit: true
    // })
    //   .then(res => res.json());


    if (user) {

      // console.log(DMG,"lala user", user);
    }




    // const connections = await fetchConnections({
    //   company_id: getUser().company_id,
    //   status: 'active'
    // });

    const connections = await fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${userCompanyID}&status=active`).then(res => res.json());

    const getStartedCompanyCheck = await fetch(`${process.env.REACT_APP_API_URL}/checkgettingstarted?company_id=${userCompanyID}&user_id=${getUser().id}`)
      .then(res => res.json());

    // console.log(DMG,"getStartedCompanyCheck", getStartedCompanyCheck);
    if (getStartedCompanyCheck) {
      this.setState({ getStartedCompanyCheck });
    }

    // console.log(DMG,connections);//this is the raw connections returned from querying the PostgreSQL database connections table
    let vendor_ids = [];
    // connections.payload.filter(conn => conn.status === 'active').forEach(reorder);
    connections.filter(conn => conn.status === 'active').forEach(reorder);

    function reorder(v, i, a) { //the results returned from the table are correct but not aligned properly
      if (v.company_id === userCompanyID) { //this will extract the company_ids that a company is connected with
        vendor_ids.push(v.vendor_id);
      } else {
        vendor_ids.push(v.company_id);
      }
    }

    this.setState({ vendorIds: vendor_ids }, () => {
      // console.log(DMG,this.state.vendorIds, 'vendorIds');
    }); 


    if (user && user.payload && connections || this.props.user && this.props.user.first_name) {
      // console.log(DMG,"user.payload",user.payload)

      if (vendor_ids && !vendor_ids.includes(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)) {
        vendor_ids.push(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
      }

      const newuser = { "payload": this.props.user };

      if(vendor_ids.length>0){
        searchNewsFeed(vendor_ids, newuser.payload, "", 0, companySettings, systemSettings);
      }

      if (systemSettings.rewards_active && (systemSettings.sales_incentives_active || systemSettings.vendor_incentives_active) && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) {
        if( vendor_ids && vendor_ids.length>0){
          searchIncentives(vendor_ids, newuser.payload, "", 0, companySettings, false, systemSettings);
        }
      }
      if( vendor_ids.length>0){
        searchPosts(vendor_ids, newuser.payload, "", 0);
      }
    } else {

      // console.log(DMG,"nope.user.payload")
    }




  };

  _checkStripe = async () => {
    console.log("_checkStripe")
    const { updateCompany, fetchUser, notifySuccess, updateSettings, fetchFeatureFlags } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      // history.replace(window.location.pathname);

      const session = await fetch(`${process.env.REACT_APP_API_URL}/sessionretrieve?sessionId=${sessionId}`).then(res => res.json());

      if (session && session.id && session.payment_status === 'paid' && session.client_reference_id === getUser().id) {
        await updateSettings(getUser().company_id, {plan_type: "premium"});
        await fetchFeatureFlags(getUser().company_id);
        await updateCompany(getUser().company_id, { paid: true });
        fetchUser(getUser().id, {
          edit: true
        });

        localStorage.setItem(`_kp_initial_login_${getUser().id}`, 'complete');

        return notifySuccess("Your account successfully upgraded to KickPost Premium.")
      }


    }


  }

  _calculateCompletion = user => {
    let a = [
      user.avatar ? false : true,
      user.phone_numbers.length ? false : true,
      user.company_admin && user.company.logo ? false : true
    ];

    let b = _.filter(a, Boolean);

    let multiplier = user.company_admin ? 33 : 50;

    return (a.length - b.length) * multiplier;

  };

  _loadMore = incomingData => {

    const { user, newsFeedCurrentPage, newsFeedTotalPages, searchNewsFeed, companySettings, systemSettings } = this.props;
    // console.log("this.props.newsFeedCurrentPage",this.props.newsFeedCurrentPage);
    const { vendorIds } = this.state;



    let vendor_ids = [...vendorIds];

    let nextPage = newsFeedCurrentPage + 1;

    if (vendor_ids.length > 0) {
      if (nextPage <= newsFeedTotalPages) {
        if (vendor_ids && !vendor_ids.includes(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)) {
          vendor_ids.push(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
        }
        searchNewsFeed(vendor_ids, user, "", nextPage, companySettings, systemSettings);
      } else {
        console.log("src\components\dashboard\posts\index.js line 279");
      }
    }
  };

  _handleNewItems = () => {

    const { newsFeed, browser } = this.props;

    const { allrows } = this.state;

    const isMobile = browser.lessThan.small;

    let loadedIds = allrows.map(row => row.props.id);



    let rows =
      newsFeed &&
      newsFeed.filter(post => !loadedIds.includes(post.id)).map((post, i) => {


        return (

          <div key={post.id} id={post.id} >
            {isMobile ? (
              <FeedItemMobile hit={post} props={this.props} key={i} />
            ) : (
              <FeedItemLaptop hit={post} props={this.props} key={i} />
            )
            }
          </div>


        );

      });




    let newRows = [...allrows, ...rows];

    newRows = newRows.sort((a, b) => {
      a = a.props.children.props.hit;
      b = b.props.children.props.hit;

      return b.updated_at - a.updated_at
    });



    this.setState({ allrows: [...newRows] })

  }

  _dismissGettingStarted = name => {
    let payload = {};
    if (getAccessory()) {
      payload = getAccessory();
    }
    payload[name] = true;
    setAccessory(payload);
    this.setState({ [name + "Dismissed"]: true })

  }




  render() {
    /*
      

    */
    const {
      searchPosts,
      postsCurrentPage,
      postsTotalPages,
      posts,
      newsFeed,
      newsFeedCurrentPage,
      newsFeedTotalPages,
      incentives,
      browser,
      redirect,
      user,
      systemSettings,
      companySettings,
      sales
    } = this.props;

    //
    const {
      vendorIds,
      currentUser,
      dismissAccessory,
      allrows,
      loadedPosts,
      companySetupDismissed,
      receiveIncentivesDismissed,
      salesRepDismissed,
      getStartedCompanyCheck,
      shouldRender
    } = this.state;

    // !this.state.unmounting
    if (user && !this.state.unmounting) {

      let getStartedType = null;

      if (getUser().id === user.id) {

        if ((user.company_owner || user.company_admin) && !companySetupDismissed) {
          getStartedType = "companySetup";
        } else if (systemSettings.rewards_active && companySettings.receive_incentives) {
          if (user.company_admin && !receiveIncentivesDismissed) {
            getStartedType = "receiveIncentives"
          }
        }
      }

      if ( allrows && allrows.length > 0) {
        // console.log(" src\components\dashboard\posts\index.js allrows.length", allrows.length);
        return (<div
          style={{ height: "85vh", overflow: "auto", paddingRight: "20px", marginTop: "32px" }}
        >
          {
            getStartedType
              ?
              <GettingStartedProcess
                key={user.id}
                user={user}
                type={getStartedType}
                redirect={redirect}
                receiveIncentives={systemSettings.rewards_active && companySettings.receive_incentives}
                invitesCheck={getStartedCompanyCheck}
                handleDismiss={this._dismissGettingStarted}
                salesExist={sales && sales.length > 0}
                userSales={sales && sales.length && sales.some(sale => sale.user_id === getUser().id)}
                isSingleUser={companySettings.is_single_user_company}
                hasConnections={vendorIds.filter(id => id !== user.company_id && id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID).length > 0}
              />
              :
              null
          }

          <InfiniteScroll
            pageStart={0}
            loadMore={this._loadMore}

            hasMore={this.props.newsFeedCurrentPage < this.props.newsFeedTotalPages}
            // hasMore={true}
            loader={<div className="loader" key={0} ></div>}
            useWindow={false}
            threshold={150}
          >
            {allrows}            
          </InfiniteScroll>
          
        </div>);

      } else if (shouldRender) {
        console.log("src\components\dashboard\posts\index.js shouldRender");
        return (<div
          style={{ height: "85vh", overflow: "auto", paddingRight: "20px", marginTop: "35px" }}
        >
          {
            getStartedType
              ?
              <GettingStartedProcess
                key={user.id}
                user={user}
                type={getStartedType}
                redirect={redirect}
                receiveIncentives={systemSettings.rewards_active && companySettings.receive_incentives}
                invitesCheck={getStartedCompanyCheck}
                handleDismiss={this._dismissGettingStarted}
                salesExist={sales && sales.length > 0}
                userSales={sales && sales.length && sales.some(sale => sale.user_id === getUser().id)}
                isSingleUser={companySettings.is_single_user_company}
                hasConnections={vendorIds.filter(id => id !== user.company_id && id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID).length > 0}
              />

              :
              null
          }

          <NunitoSansBold
            fontSize="14px"
            color='#C3C3C3'
            textAlign="center"
          >
            Your Newsfeed is Empty
          </NunitoSansBold>
          <NunitoSansSemiBold
            fontSize="12px"
            color='#C3C3C3'
            textAlign="center"
          >
            Build your network to start seeing more posts.
          </NunitoSansSemiBold>
        </div>);
      } else {
        // console.log(" src\components\dashboard\posts\index.js allrows null");
        return null;
      }

    } else {
      // console.log(this.state.unmounting, 'this.state.unmounting - null')
      return null;
    }


  }
}






export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    connections: state.resources.collection.connection || [],
    loading: state.resources.detail.loading.user,
    browser: state.browser,
    incentives: state.search.incentiveSearchResults || [],
    posts: state.search.postSearchResults,
    postsCurrentPage: state.search.postSearchResultsCurrentPage,
    postsTotalPages: state.search.postSearchResultsTotalPages,
    newsFeed: state.search.newsFeedSearchResults || [],
    newsFeedCurrentPage: state.search.newsFeedSearchResultsCurrentPage,
    newsFeedTotalPages: state.search.newsFeedSearchResultsTotalPages,
    user: state.resources.detail.user,
    sales: state.resources.collection.sale || []
  }),
  dispatch => ({
    fetchNotifications: bindActionCreators(actions.fetchNotifications, dispatch),
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    redirect: bindActionCreators(push, dispatch),
    searchPosts: bindActionCreators(searchPosts, dispatch),
    searchNewsFeed: bindActionCreators(searchNewsFeed, dispatch),
    searchIncentives: bindActionCreators(searchIncentives, dispatch),
    // updatePostSearchResults: bindActionCreators(
    //   updatePostSearchResults,
    //   dispatch
    // ),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
    fetchFeatureFlags: bindActionCreators(actions.fetchFeatureflag, dispatch),
  })
)(Feed);


const SearchBubbleNav = ({ ...props }) => {
  const [searchValue, setValue] = useState('');

  //   return (
  //    <FlexGrow
  //      flexGrow="100"
  //      bg="lightGray"
  //      height="40px"
  //      borderRadius="11px"
  //      alignItems="center"
  //    >
  //      <IconBox style={{paddingLeft: '15px', paddingRight:'20px'}} color="#c3c3c3" fontSize="18px">
  //        search
  //      </IconBox>
  //      {/* <form 
  //      style={{height: "100%", width: "100%"}}
  //      autoComplete="off"
  //      onSubmit={(event)=>{
  //        event.preventDefault();
  //        setValue('');
  //        history.push(`/dashboard/search?${searchValue}`)
  //      }}>

  //      <input type="text" name="mainsearch" value={searchValue} onChange={(event)=> setValue(event.target.value)} style={{height: "100%", width: "100%", fontSize: "20px", fontFamily: "NunitoSans"}} placeholder="Search KickPost" />
  //      </form> */}
  //      <NunitoSans
  //      color="#c3c3c3"
  //      fontSize="20px"
  //      >
  //        Search KickPost
  //      </NunitoSans>
  //    </FlexGrow>
  //  );

  return (
    <Flex position="relative" width={["100%", "83%", "83%", "100%"]}>
      <AbsoluteDiv
        display={["block"]}
        // left={["10px"]}
        // bottom={"0px"}
        top={["6px", "6px"]}
        width={["40px"]}
      >
        <IconBox
          // pl={["15px"]}
          // pb={["15px"]}
          // pt={["15px"]}
          fontSize={["18px", "18px"]}
          color="#c3c3c3"
        >
          search
        </IconBox>

      </AbsoluteDiv>
      <InputStyled
        key={"searchInput"}
        placeholder="Search Your KickPost Network"
        style={{ paddingLeft: '45px', cursor: 'pointer' }}
        width="100%"
        name="dashboard-search"
        type="text"
        border={0}
        autoFocus={true}
        bg={["lightGray", "lightGray"]}
        borderRadius={"11px"}
        defaultValue={""}

        px={["5px", "5px"]}
        mt={["3px", "0px"]}
        height={[31, 40, 40, 40]}

        fontSize={[14, 20, 20, 20]}
        lineHeight={1.5}
        color={"#000000"}
        borderBottom={[0, 0]}
        
      />

    </Flex>
  );
}

// background-image: linear-gradient(rgba(247,247,247,0.98) 0%, rgba(247,247,247,0.95) 100%);
const PopoverBubble = styled(AbsoluteDiv)`
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
  background-size: 100%;
  background: rgba(247, 247, 247, 0.95);
  border-radius: 10px;
  z-index: 10000;
`;

const DefaultCursorFlex = styled(Flex)`
  cursor: default;
`;

const FeedItemLaptop = ({ hit, props }) => {
  let fileAttachments = hit.files && hit.files.map(file => {
    return {
      filetype: file.filetype,
      filename: file.filename,
      title: file.filename,
      link: file.url,
      image: mimeToImage(file.filetype, file.filename)
    };
  });

  return (
    hit.type ?
      <FeedIncentiveLaptop
        clickHandler={props.redirect}
        timeago={moment(hit.updated_at)
          .utc()
          .fromNow()}
        expiresSoon={moment(hit.end_date)
          .utc()
          .isBefore(moment().add(5, "days"))}

        startDate={
          moment(hit.start_date).utc().format("L")
        }
        endDate={moment(hit.end_date).utc()
          .format("L")}
        postedBy={
          getUser().id === hit.user_id
            ? `You`
            : titleize(`${hit.user.first_name} ${hit.user.last_name}`)
        }
        key={hit.id}
        incentiveId={hit.id}
        title={hit.title}
        type={hit.type}
        companyId={hit.company.id}
        companyLogo={hit.company.logo || defaultCompany(hit.company.id, hit.company.name)}
        postedByImg={hit.user.avatar || defaultProfile(hit.user.id, `${hit.user.first_name} ${hit.user.last_name}`)}
        companyName={hit.company.name}
        status={hit.status}
        userId={hit.user.id}
        isPartnerIncentive={hit.is_partner_incentive}
      />
      :
      <FeedPostLaptop
        clickHandler={props.redirect}
        timeago={moment(hit.updated_at)
          .utc()
          .fromNow()}
        expiresSoon={moment(hit.end_date)
          .utc()
          .isBefore(moment().add(5, "days"))}
        endDate={moment(hit.end_date).utc()
          .format("MMMM Do YYYY")}
        postedBy={
          getUser().id === hit.user_id
            ? `You`
            : titleize(`${hit.user.first_name} ${hit.user.last_name}`)
        }
        key={hit.id}
        postId={hit.id}
        title={hit.title}
        companyId={hit.company.id}
        companyLogo={
          hit.brand && hit.brand.name
            ?
            (hit.brand.logo || defaultCompany(hit.brand.id, hit.brand.name))
            :
            hit.company.is_single_user_company
              ?
              defaultCompany(hit.company.id, hit.company.name)
              :
              (hit.company.logo || defaultCompany(hit.company.id, hit.company.name))
        }
        brandName={hit.brand && hit.brand.name}
        companyName={hit.company.name}
        content={hit.content}
        fileAttachments={fileAttachments}
        videoAttachments={hit.videos}
        userId={hit.user.id}
        postedByImg={hit.user.avatar || defaultProfile(hit.user.id, `${hit.user.first_name} ${hit.user.last_name}`)}
        isSingleUserCompany={!!hit.company.is_single_user_company}
      />
  );

};

const FeedItemMobile = ({ hit, props }) => {
  let fileAttachments = hit.files.map(file => {
    return {
      filetype: file.filetype,
      filename: file.filename,
      title: file.filename,
      link: file.url,
      image: mimeToImage(file.filetype, file.filename)
    };
  });

  return (
    <div>
      {
        hit.type
          ?
          <FeedIncentiveMobile
            clickHandler={props.redirect}
            timeago={moment(hit.updated_at)
              .utc()
              .fromNow()}
            expiresSoon={moment(hit.end_date)
              .utc()
              .isBefore(moment().add(5, "days"))}

            startDate={
              moment(hit.start_date).utc().format("L")
            }
            endDate={moment(hit.end_date).utc()
              .format("L")}
            postedBy={
              getUser().id === hit.user_id
                ? `You`
                : titleize(`${hit.user.first_name} ${hit.user.last_name}`)
            }
            key={hit.id}
            incentiveId={hit.id}
            title={hit.title}
            type={hit.type}
            companyId={hit.company.id}
            companyLogo={hit.company.logo || defaultCompany(hit.company.id, hit.company.name)}
            postedByImg={hit.user.avatar || defaultProfile(hit.user.id, `${hit.user.first_name} ${hit.user.last_name}`)}
            companyName={hit.company.name}
            status={hit.status}
            userId={hit.user.id}
            isPartnerIncentive={hit.is_partner_incentive}
          />
          :

          <FeedPostMobile
            clickHandler={props.redirect}
            timeago={moment(hit.updated_at)
              .utc()
              .fromNow()}
            expiresSoon={moment(hit.end_date)
              .utc()
              .isBefore(moment().add(5, "days"))}
            endDate={moment(hit.end_date).utc()
              .format("MMMM Do YYYY")}
            postedBy={
              getUser().id === hit.user_id
                ? `You`
                : titleize(`${hit.user.first_name} ${hit.user.last_name}`)
            }
            key={hit.id}
            postId={hit.id}
            title={hit.title}
            companyId={hit.company.id}
            companyLogo={
              hit.brand && hit.brand.name
                ?
                (hit.brand.logo || defaultCompany(hit.brand.id, hit.brand.name))
                :
                hit.company.is_single_user_company
                  ?
                  defaultCompany(hit.company.id, hit.company.name)
                  :
                  (hit.company.logo || defaultCompany(hit.company.id, hit.company.name))
            }
            brandName={hit.brand && hit.brand.name}
            companyName={hit.company.name}
            content={hit.content}
            fileAttachments={fileAttachments}
            videoAttachments={hit.videos}
            userId={hit.user.id}
            postedByImg={hit.user.avatar || defaultProfile(hit.user.id, `${hit.user.first_name} ${hit.user.last_name}`)}
          />
      }
    </div>
  );
};

export class NavBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBubble: false
    }
  }
  componentDidMount() {
    console.log("NavBubble componentDidMount");
  }


  onMouseLeave = () => {
    this.setState({ showBubble: false });
  };
  onMouseOver = () => {
    this.setState({ showBubble: true });
  };
  render() {
    return (
      <DefaultCursorFlex
        onMouseOver={this.onMouseOver.bind(this)}
        position="relative"
        borderRadius={
          this.props.borderRadius ? this.props.borderRadius : "20px"
        }
        height={40}
        width={40}
        bg="lightGray"
      >
        <DefaultCursorFlex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IconBox ml="1px" color="darkerGray" className="icon" fontSize={20}>
            {this.props.icon}
          </IconBox>
        </DefaultCursorFlex>
        {this.state.showBubble ? (
          <PopoverBubble
            onMouseLeave={this.onMouseLeave.bind(this)}
            onMouseOver={this.onMouseOver.bind(this)}
            top={["-10px"]}
            left="-83px"
            right="-83px"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                justifyContent="center"
                alignItems="flex-end"
                id="menu-spacer"
                height="51px"
                width="100%"
              >
                <IconBox
                  ml="1px"
                  mb="6px"
                  onMouseOver={this.onMouseOver.bind(this)}
                  color="darkerGray"
                  className="icon"
                  fontSize={20}
                >
                  {this.props.icon}
                </IconBox>
              </Flex>
              {this.props.children}
            </Flex>
          </PopoverBubble>
        ) : null}
      </DefaultCursorFlex>
    );
  }
}

const NavBubbleRow = ({ ...props }) => (
  <Link to={props.link}>
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      height="62px"
      width="100%"
    >
      {props.first ? null : (
        <AbsoluteDiv position="absolute" top="0px" left="0px" right="0px">
          <OneSixDash979797 width="100%" height="1px" />
        </AbsoluteDiv>
      )}
      <NunitoSans
        className="navbubblerow"
        textAlign="center"
        fontSize="14px"
        fontWeight="bold"
        color={props.color || "darkerGray"}
      >
        {props.children}
      </NunitoSans>
      {props.last ? null : (
        <AbsoluteDiv position="absolute" bottom="-1px" left="0px" right="0px">
          <OneSixDash979797 width="100%" height="1px" />
        </AbsoluteDiv>
      )}
    </Flex>
  </Link>
);

// Had to add extra PX of padding on each side of the feed.
// Overflow-y scroll forces overflow-x to be hidden, cuts off the shadows.

let FeedNavMobile = ({ ...props }) => {

  const { user, systemSettings, companySettings, browser, redirect } = props;

  const [pendingConnections, setPendingConnections] = useState([]);
  const [sponsoredBrands, setSponsoredBrands] = useState([]);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [initialPendingCount, setInitialPendingCount] = useState(-1);
  const [industryCategories, setIndustryCategories] = useState([]);

  const fetchPendingConnections = async () => {
    const pendingConnections = await fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${getUser().company_id}&from=pendingCompanies`).then(res => res.json());

    let pendings = [];
    pendingConnections.forEach(connection => {
      if (connection.status === 'active') {
        pendings.unshift(connection);
      } else {
        pendings.push(connection);
      }
    })

    if (initialPendingCount < 0) {
      setInitialPendingCount(pendings.filter(conn => conn.status === 'requested').length);
    }

    setPendingConnections(pendings);
  }

  const fetchSponsoredBrands = async (company_id) => {

    const brands = await fetch(`${process.env.REACT_APP_API_URL}/sponsoredbrands/${company_id}`).then(res => res.json());

    setSponsoredBrands(brands);
  }

  const _createConnection = async (vendorId) => {
    const { createConnection } = props;

    await createConnection({
      vendor_id: vendorId,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchSponsoredBrands(getUser().company_id);
  }


  const _updateConnection = async (connection, status) => {

    const { updateConnection, fetchNotificationCount, fetchConnections } = props;

    await updateConnection(connection.id, { status: status, index: "companyIndex", vendor_id: getUser().company_id === connection.company_id ? connection.vendor_id : connection.company_id, company_id: getUser().company_id });
    await fetchPendingConnections();
    fetchConnections({ company_id: getUser().company_id });
    fetchNotificationCount();

    if (status === 'active' && !refreshNeeded) {
      setRefreshNeeded(true);
    }
    return 'done';
  }

  const modalFinalAction = () => {
    if (refreshNeeded) {
      return redirect('/dashboard');
    }
  }

  const fetchIndustryCategories = async () => {

    const categories = await fetch(`${process.env.REACT_APP_API_URL}/industrycategories`).then(res => res.json());

    setIndustryCategories(categories);
  }

  useEffect(() => {
    if (user && getUser() && user.id === getUser().id && user.company_admin && !pendingConnections.length) {
      fetchPendingConnections();
    }

    if (user && getUser() && user.id === getUser().id && user.company_admin && !sponsoredBrands.length) {
      fetchSponsoredBrands(user.company_id);
    }


  }, [user]);

  useEffect(() => {
    if (!industryCategories.length) {
      fetchIndustryCategories();
    }
  }, [industryCategories]);

  return (
    <>
      <InitialLoginModal
        user={user}
        isSingleUser={companySettings.is_single_user_company}
        companyGoal={companySettings.company_goal}
        isMobile={true}
        pendingConnections={pendingConnections}
        sponsoredBrands={sponsoredBrands}
        industryCategories={industryCategories}
        updateConnection={_updateConnection}
        createConnection={_createConnection}
        finalAction={modalFinalAction}
      />
      <MobileMenu
        leftIcon="add"
        leftLink="modalnav"
        rightIcon="hamburger"
        rightLink="/dashboard/posts/new"
        {...props}
      />
    </>
  );
};

FeedNavMobile = connect(
  state => ({
    company: state.resources.detail.company,
    connections: state.resources.collection.connection || [],
  }),
  dispatch => ({

    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    redirect: bindActionCreators(push, dispatch),
    // notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),

  })
)(FeedNavMobile);

export { FeedNavMobile };

let FeedNavLaptop = props => {
  const { user, systemSettings, companySettings, browser, redirect, featureFlags } = props;


  const [pendingConnections, setPendingConnections] = useState([]);
  const [sponsoredBrands, setSponsoredBrands] = useState([]);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [initialPendingCount, setInitialPendingCount] = useState(-1);
  const [industryCategories, setIndustryCategories] = useState([]);

  const fetchPendingConnections = async () => {
    const pendingConnections = await fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${getUser().company_id}&from=pendingCompanies`).then(res => res.json());

    let pendings = [];
    pendingConnections.forEach(connection => {
      if (connection.status === 'active') {
        pendings.unshift(connection);
      } else {
        pendings.push(connection);
      }
    })

    if (initialPendingCount < 0) {
      setInitialPendingCount(pendings.filter(conn => conn.status === 'requested').length);
    }

    setPendingConnections(pendings);
  }

  const fetchSponsoredBrands = async (company_id) => {

    const brands = await fetch(`${process.env.REACT_APP_API_URL}/sponsoredbrands/${company_id}`).then(res => res.json());

    setSponsoredBrands(brands);
  }

  const _createConnection = async (vendorId) => {
    const { createConnection } = props;

    await createConnection({
      vendor_id: vendorId,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchSponsoredBrands(getUser().company_id);
  }

  const _updateConnection = async (connection, status) => {

    const { updateConnection, fetchNotificationCount, fetchConnections } = props;

    await updateConnection(connection.id, { status: status, index: "companyIndex", vendor_id: getUser().company_id === connection.company_id ? connection.vendor_id : connection.company_id, company_id: getUser().company_id });
    await fetchPendingConnections();
    fetchConnections({ company_id: getUser().company_id });
    fetchNotificationCount();

    if (status === 'active' && !refreshNeeded) {
      setRefreshNeeded(true);
    }
    return 'done';
  }

  const modalFinalAction = () => {
    if (refreshNeeded) {
      return redirect('/dashboard');
    }
  }

  const fetchIndustryCategories = async () => {

    const categories = await fetch(`${process.env.REACT_APP_API_URL}/industrycategories`).then(res => res.json());

    setIndustryCategories(categories);
  }

  useEffect(() => {
    if (user && getUser() && user.id === getUser().id && user.company_admin && !pendingConnections.length) {
      fetchPendingConnections();
    }

    if (user && getUser() && user.id === getUser().id && user.company_admin && !sponsoredBrands.length) {
      fetchSponsoredBrands(user.company_id);
    }


  }, [user]);

  useEffect(() => {
    if (!industryCategories.length) {
      fetchIndustryCategories();
    }
  }, [industryCategories]);



  if (user && user.id === getUser().id) {

    return (
      <Flex mt="20px" width="100%" >
        <InitialLoginModal
          user={user}
          isSingleUser={companySettings.is_single_user_company}
          companyGoal={companySettings.company_goal}
          isMobile={browser.lessThan.large}
          pendingConnections={pendingConnections}
          sponsoredBrands={sponsoredBrands}
          industryCategories={industryCategories}
          updateConnection={_updateConnection}
          createConnection={_createConnection}
          finalAction={modalFinalAction}
        />

        <PendingConnectionsModal
          user={user}
          isSingleUser={companySettings.is_single_user_company}
          isMobile={browser.lessThan.large}
          pendingConnections={pendingConnections.filter(conn => conn.status === 'requested')}
          initialPendingCount={initialPendingCount}
          updateConnection={_updateConnection}
          finalAction={modalFinalAction}
        />
        <Box width="100%">
          <Link to="/dashboard/search">
            <SearchBubbleNav />
          </Link>
        </Box>
        <Box ml="38px">

          {(user.status === "pending") && (!user.company) || !user.company_admin ||
            (
              !(systemSettings.posts_active && (!systemSettings.posting_companies_active || (systemSettings.posting_companies_active && user.company.posting_active)))
              &&
              !(user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.create_incentives)
              &&
              !(user && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_vendor_incentives)
            )
            ? null : (
              <NavBubble icon="add">
                <Box width="90%">
                  {
                    systemSettings.posts_active && (!systemSettings.posting_companies_active || (systemSettings.posting_companies_active && user.company.posting_active))
                      ?
                      (featureFlags&&(featureFlags.add_posts === true)) ?
                      <NavBubbleRow
                        link={`/dashboard/posts/new`}
                        first={true}
                        last={
                          !(user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_incentives &&  (featureFlags && (featureFlags.create_sales_incentives === true)))
                          &&
                          !(user && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_vendor_incentives && (featureFlags&&(featureFlags.create_partner_incentives === true)))
                        }
                      >
                        Add a Post
                      </NavBubbleRow>
                      :
                      <LockedFeature
                        text="Upgrade to Add Posts"
                        iconSize="lg"
                        padding="10px"
                        fontSize="12px"
                        noBorder={true}
                      />
                      :
                      null
                  }
                  {user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_incentives && (featureFlags&&(featureFlags.create_sales_incentives === true)) ?
                    <NavBubbleRow
                      link={`/dashboard/incentives/new?type=sales`}
                      // color="#428BF4"
                      last={!(user && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_vendor_incentives )}
                      first={
                        !(systemSettings.posts_active && (!systemSettings.posting_companies_active || (systemSettings.posting_companies_active && user.company.posting_active)))
                      }

                    >
                      Add a Sales Incentive
                    </NavBubbleRow>
                    :
                    null}
                  {user && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && !companySettings.is_single_user_company && companySettings.create_vendor_incentives && (featureFlags&&(featureFlags.create_partner_incentives === true)) ?
                    <NavBubbleRow
                      link={`/dashboard/incentives/new?type=partner`}
                      // color="#428BF4"
                      last={true}
                      first={
                        !(systemSettings.posts_active && (!systemSettings.posting_companies_active || (systemSettings.posting_companies_active && user.company.posting_active)))
                        &&
                        !(user && systemSettings.rewards_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.create_incentives)
                      }

                    >
                      Add a Partner Incentive
                    </NavBubbleRow>
                    :
                    null}
                </Box>
              </NavBubble>
            )}

        </Box>
        {/* {user.company_admin ? (
          <Box ml="38px">
            <NavBubble borderRadius="8px" icon="company">
              <Box width="90%">
                <NavBubbleRow
                  link={`/dashboard/company/${user.company_id}/employees`}
                  first={true}
                >
                  Co-workers
                </NavBubbleRow>
                <NavBubbleRow
                  link={`/dashboard/company/${user.company_id}/vendors`}
                  last={true}
                >
                  Connections
                </NavBubbleRow>
              </Box>
            </NavBubble>
          </Box>
        ) : null} */}
        {/* {user.status === "pending" ? null : (
          <Box ml="38px">
            <NavButton
              link="/dashboard/posts/new"
              icon="add"
              title="Add Post"
            />
          </Box>
        )} */}
        <AbsoluteDiv
          bottom={["-90px"]}
          left={`-${props.extraPadding}px`}
          right={`-${props.extraPadding}px`}
          height="90px"
        >
          <GradientOverflowDownwardLaptop id="GODL" height="55%" width="100%" />
        </AbsoluteDiv>
      </Flex>
    );
  }
  return null;
};

FeedNavLaptop = connect(
  state => ({
    company: state.resources.detail.company,
    connections: state.resources.collection.connection || [],
    featureFlags: state.resources.detail.featureflag,
  }),
  dispatch => ({
    searchNewsFeed: bindActionCreators(searchNewsFeed, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    redirect: bindActionCreators(push, dispatch),
    // notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),

  })
)(FeedNavLaptop);

export { FeedNavLaptop };