import React, { Component } from "react";
import { VideoAttachmentBubble } from "../../../common/post";
import { Box, Flex } from "../../../common/flexbox";
import { Eyebrow, VideoInputBubble } from "../../../common/forms";
const laptopBubbleWidth = 47.3;

export default class VideoAttachments extends Component {
  render() {
    const {
      addVideo,
      removeVideo,
      updateVideoInput,
      videos,
      videoUrl,
      videoAdded
    } = this.props

    return (
      <Flex flexDirection="column">
        <Flex flexDirection='row'>
          <Eyebrow ml={"8px"}>VIDEO ATTACHMENT</Eyebrow><Eyebrow ml={"8px"} textTransform='lowercase'>(1 FILE OR VIDEO MAX PER ASSET)</Eyebrow>
        </Flex>
        <Flex
          position="relative"
          justifyContent="space-between"
          flexWrap="wrap"
          pb={"22px"}
          width={[1,1,1]}
        >
          {videos.map((video, i) => {
            return (
              <Box key={i} width={["100%", laptopBubbleWidth + "%"]} mb="20px">
                <VideoAttachmentBubble
                  clickHandler={() => removeVideo(video)}
                  title={video.title}
                  minutes={video.duration}
                  image={video.image}
                  key={i}
                  icon="close"
                  iconMarginLeft="0px" 
                  width={[1,1,1]}
                />
              </Box>
            )
          })}
          {
            videos.length < 1 ? ( // limit is 1 video per asset
              <VideoInputBubble
                addVideo={addVideo}
                inputChangeHandler={updateVideoInput}
                videoUrl={videoUrl}
                videoAdded={videoAdded}
              />
            ) : (
              null
            )
          }
        </Flex>
      </Flex>
    )
  }
}
