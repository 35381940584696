import React, { Component } from "react";
// import { connect } from "react-redux";
import { NunitoSans } from "../common/typography";
import { Flex, Box } from "../common/flexbox";
import {
  renderField,
  OnboardingCTA,
  BackButton
} from "../common/forms";

import image404 from "../../images/404_KP.png";
import { history } from "../../store";



export class NotFoundMain extends Component {


  // render(){  return PageNotFound;}



  render() {


    return (
      <Flex
        flexDirection="column"
        //  maxWidth="248px"
        alignItems="center"
        justifyContent="flex-end"
      >


        <Flex
          flexDirection="column"
          maxWidth="248px"
          alignItems="center"
        >

          <img src={image404} width="248px" height="198px"></img>

          <NunitoSans
            fontSize="35px"
            fontWeight={700}
            textAlign='center'
          >
            We're sorry. The Page could not be found.
          </NunitoSans>

          <NunitoSans
            fontSize="14px"
            textAlign='center'
          >
            The page you are looking for doesn't exist or has been moved.
          </NunitoSans>
          <OnboardingCTA
            my="3vh"

            width="220px"
            height="43px"
            onClick={() => history.push("/dashboard")}
          >
            <NunitoSans
              fontSize="14px"
              textAlign='center'
            >
              BACK TO HOME
            </NunitoSans>

          </OnboardingCTA>
        </Flex>


      </Flex>
    );
  }

}


// const PageNotFound= () =>{
//   <div>
//       <h1>404 Error</h1>
//       <h1>Page Not Found</h1>
//   </div>
// }

export const PageNotFound = () => {
  const { isFetchingData } = false;
  // <div>
  //     <h1>404 Error</h1>
  //     <h1>Page Not Found</h1>
  // </div>
}

export const NotFoundAccessory = ({ ...props }) => <NunitoSans />;

export const NotFoundNav = ({ ...props }) => <NunitoSans />;


export default NotFoundMain;