

const currencies = {
   USD: {
        name: 'US Dollar',
        symbol: '$'
    },
    CAD: {
        name: 'Canadian Dollar',
        symbol: 'CA$'
    },
    EUR: {
        name: 'Euro',
        symbol: '€'
    },
    GBP: {
        name: 'Pound Sterling',
        symbol: '£'
    },
    JPY: {
        name: 'Japanese Yen',
        symbol: '￥'
    }
};

export default currencies;