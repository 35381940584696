import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset, SubmissionError } from "redux-form";
import Modal from "react-modal";
import axios from "axios";
//utils
import { setUser } from "../../utils/auth";
import _ from "underscore";
//actions
import { actions } from "../../actions/resources";
import { success, error } from "../../actions/alerts";
//components
import ConnectionForm from "./forms/connection";

import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex, IconBox, Box } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";

import lockIcon from "../../images/lock_icon.svg";

// import {
//   history
// } from "store";

export class Connection extends Component {

  state = {
    isModalOpen: false,
    emailPayload: {},
    inviteSuccess: false,
    inviteInProgress: false,
    referralCode: "",
    initialValueSet: false
  }

  constructor(props) {
    super(props);
  }

async componentDidMount() {
  const urlParams = new URLSearchParams(window.location.search);
 

  }

  _throwError = async message => {
    throw new SubmissionError({ _error: message });
  };

  _handleSubmit = async data => {
    console.log("handlesubmit",data);
    const { createUser, redirect, resetForm, notifySuccess, notifyError, referral_code } = this.props;

    // if(!data.terms){
    //   return this._throwError('You need to accept terms and conditions.');
    // }

    let LeEmail = data.email;
    LeEmail = LeEmail.toString().toLowerCase();
    data.email = LeEmail;
    let domain = LeEmail.split("@")[1];
    if(data.company){
      localStorage.setItem("LeCompany",JSON.stringify({"name":data.company}));
    }

    let dom_referral_code = document.getElementById("register-form")[3].value;
    if (data.referral_code || dom_referral_code != "") {
      let ref = dom_referral_code;
      if (data.referral_code) {
        ref = data.referral_code.replace(/[^a-z0-9]/gi, '');
      }
      ref = ref.toUpperCase();
      if (ref && ref.length) {
        data.referral_code = ref;
      } else {
        delete data.referral_code;
      }
    }

    const response = await createUser(_.omit(data, ["terms","company"]));

    if (response) {

      if (response.payload.referral_error) {
        return this._throwError('Referral code is not valid.');
      } else if (response.payload.invite_status) {

        if (response.payload.invite_status === 'not_found') {

          return this.setState({
            isModalOpen: true, emailPayload: {
              first_name: data.first_name,
              last_name: data.last_name,
              email: LeEmail,
              invited_by: LeEmail,
              status: 'pending',
              domain
            }
          })

        } else if (response.payload.invite_status === 'pending') {

          return this._throwError('A request already submitted. We will contact you shortly.');

        } else {
          return this._throwError('Please contact our support team at support@kickpost.io');
        }



      } else {
        const userSuccess = setUser(response.payload);

        if(userSuccess===true){

          if (true || data.terms) {
            let kpPolicy = {
              "message": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
              "tospp": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
              "version": process.env.REACT_APP_POLICY_VERSION
            };
            localStorage.setItem("kpPolicy", JSON.stringify(kpPolicy));
          }
          

          if (response.payload.company_id || response.payload.is_single_user_company) {
            redirect(response.payload.is_single_user_company ? "/individual" : "/company");
          } else {
            redirect("/copmany-type");
            // redirect("/a_ccount-type");
          }
        }
      }



    } else {
      //  fetch(`${process.env.REACT_APP_API_URL}/companias/${domain}`).then(payload => payload.json()).then(company => {
      //   if(!company.id){

      //     fetch(`${process.env.REACT_APP_API_URL}/user/${LeEmail}`).then(data => data.json()).then(user => {
      //       setUser(user);
      //       redirect("/company");

      //     })

      //   }
      //  } )

      const companyPayload = await fetch(`${process.env.REACT_APP_API_URL}/companias/${domain}`);
      const company = await companyPayload.json();
      const userPayload = await fetch(`${process.env.REACT_APP_API_URL}/user/${LeEmail}`);
      const user = await userPayload.json();

      if (!user.company_id) {

        const userSuccess = setUser({
          ...user,
          is_single_user_company: company.is_single_user_company
        });

        if(userSuccess===true){

          let kpPolicy = {
            "message": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
            "tospp": `You accepted the Terms of Service and Privacy Policy (Version ${process.env.REACT_APP_POLICY_VERSION})`,
            "version": process.env.REACT_APP_POLICY_VERSION
          };
          localStorage.setItem("kpPolicy", JSON.stringify(kpPolicy));
          
          //new way
          redirect("/company-type");
          //old way
          // redirect(company.is_single_user_company ? "/individual" : "/accousdnt-type");
  
        }


      }

    }


    // resetForm("register");
    return this._throwError('Your email may be in the system already. Please try signing in.');

  };

  _handleInviteRequest = async () => {
    const { notifyError } = this.props;
    const { emailPayload } = this.state;

    this.setState({ inviteInProgress: true, inviteSuccess: false })

    try {


      await axios.post(`${process.env.REACT_APP_API_URL}/invite_list`, emailPayload).then(res => res.data);

      this.setState({ inviteInProgress: false, inviteSuccess: true })

      setTimeout(() => this.setState({ isModalOpen: false, }), 1000);

    } catch (err) {
      notifyError(err.toString());
      this.setState({ isModalOpen: false, inviteInProgress: false })
    }

  }


  render() {
    const { browser } = this.props;
    const { isModalOpen, inviteInProgress, inviteSuccess, referralCode } = this.state;

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: browser.lessThan.large ? '50%' : "443px",
        // minHeight: "312px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: '1px solid #707070',
        borderRadius: '10px'
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }

    };

    return (
      <div className="auth-form">
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          style={customStyles}
          contentLabel="Access Modal"
          ariaHideApp={false}
        >

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Flex
              width='100%'
              justifyContent="flex-end"
            >
              <IconBox
                cursor="pointer"
                fontSize={["12px"]}
                color="#C7C7C7"
                onClick={() => this.setState({ isModalOpen: false })}
              >
                thinx
              </IconBox>
            </Flex>

            {/* <IconBox
             
              fontSize={["52px"]}
              color="#2FD371"
            >
                    thinx
            </IconBox> */}

            <img src={lockIcon} width="150"></img>

            <NunitoSansBold
              color='#000000'
              fontSize={['25px', '25px', '35px', '35px']}
              textAlign='center'
              style={{ marginBottom: '17px' }}
            >
              You're Not On The List
            </NunitoSansBold>

            <NunitoSans
              color='#000000'
              fontSize={['16px', '16px', '18px', '18px']}
              lineHeight='22px'
            >
              Unfortunately you haven’t been invited to KickPost. Request a demo below and we'll reach out to you soon. If you have an industry partner on KickPost already, you can ask them for an invite.
            </NunitoSans>

            <DashboardSmallCTA
              width="200px"
              minWidth={["74px"]}
              fontSize="15px"
              innerHeight="40px"
              mt='30px'
              onClick={() => !inviteInProgress && !inviteSuccess ? this._handleInviteRequest() : null}
              color="#2FD371"

            >
              {inviteSuccess ? "REQUESTED!" : "REQUEST DEMO"}
            </DashboardSmallCTA>

            <Box height="12px" />

          </Flex>

        </Modal>
        <ConnectionForm 
        onSubmit={this._handleSubmit} 
        back={this.props.back || "/"}
        referralCode={referralCode} 
        company={this.props.company} />
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    createUser: bindActionCreators(actions.createUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
    // ,referral_code: bindActionCreators({"referral_code":document.getElementById("register-form")[3].value}, dispatch)
  })
)(Connection);
