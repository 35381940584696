import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { clearUser } from "../../utils/auth";
import { NunitoSans } from "../common/typography";
import { Link } from "../common/navigation";
import { OneSixDash } from "../common/ui";
import { AbsoluteDiv } from "../common/absolute";
import { Box, Flex, IconBox } from "../common/flexbox";
import { SidebarGradient1, SidebarGradient2 } from "./index";
import { extraPaddingMobile } from "../../utils/constants";
import { getUser } from "../../utils/auth";
// import { history } from "store";
import _ from "underscore";

// import MobileLogo from "images/kickpost_logo_mobile.png";

export class MobileMenu extends Component {
  state = {
    popoverVisible: false,
    companyId: ""
  };

  _logout = async () => {
    const {redirect} = this.props;
    clearUser();
    if(_.isFunction(redirect)){
      redirect("/");
    }
  }; 

  render() {
    const { settings, user, companySettings } = this.props;
    const { popoverVisible } = this.state;
    const isAdmin = getUser().company_admin;
    const companyID = getUser().company_id;
    const userID = getUser().id;
    return (
      <Flex
        position="relative"
        justifyContent="space-between"
        alignItems="center"
        width={[1]}
        mt={"20px"}
        height={"100%"}
      >
        <AbsoluteDiv
          display={popoverVisible ? "block" : "none"}
          zIndex={999999999999999999}
          top="0px"
          left={`-${extraPaddingMobile}px`}
        >
          <IconBox
            cursor="pointer"
            onClick={() => {
              this.setState({ popoverVisible: false });
            }}
            px="22px"
            py="18px"
            color="#979797"
            fontSize="17px"
            width="17px"
            height="17px"
          >
            thinx
          </IconBox>
        </AbsoluteDiv>
        <AbsoluteDiv
          display={popoverVisible ? "block" : "none"}
          zIndex={200000}
          top="0px"
          left={[
            extraPaddingMobile * -1 + "px",
            extraPaddingMobile * -2 + "px",
            extraPaddingMobile * -5 + "px"
          ]}
          right={[
            extraPaddingMobile * -1 + "px",
            extraPaddingMobile * -2 + "px",
            extraPaddingMobile * -5 + "px"
          ]}
          height="100vh"
        >
          <Flex
            overflow="scroll"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            bg="rgba(255,255,255,0.97)"
            height="100%"
            width="100%"
          >
            <SidebarGradient1 top="0px" left="0px" right="0px" bottom="0px" />
            <SidebarGradient2 top="0px" left="0px" right="0px" bottom="0px" />
            <Flex
              position="relative"
              zIndex={20}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="568px"
              height="100%"
              width={"80%"}
              px="20px"
            >
              <Flex
                justifyContent="center"
                // alignItems="center"
                width="100%"
                height="35px"
              >
                
                <Link to={`/dashboard/search`}>
                  <Flex>
                  <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                search-nav
                </IconBox>
                <NunitoSans
                    pt="2px"
                    textAlign="center"
                    fontWeight={700}
                    fontSize="14px"
                    color="darkerGray"
                  >
                    Search
                  </NunitoSans>
                  </Flex>
                  
                </Link>
              </Flex>
              <OneSixDash height="1px" width="100%" />
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="42px"
              >
                <Link to={`/dashboard/profile/${userID}`}>
                  <Flex>
                    <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                    my-profile
                    </IconBox>
                    <NunitoSans
                      pt="2px"
                      textAlign="center"
                      fontWeight={700}
                      fontSize="14px"
                      color="darkerGray"
                    >
                      My Profile
                    </NunitoSans>
                  </Flex>
                  
                </Link>
              </Flex>
              {
                companySettings.is_single_user_company
                ?
                null
                :
                <>
                  <OneSixDash height="1px" width="100%" />
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    <Link to={`/dashboard/company/${companyID}`}>
                    <Flex>
                        <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        my-company
                        </IconBox>
                      <NunitoSans fontWeight={700} fontSize="14px" color="darkerGray">
                        My Company
                      </NunitoSans>
                    </Flex>
                    </Link>
                  </Flex>
                </>
              }
              {/* <OneSixDash height="1px" width="100%" /> */}
              
              <Box height="43px" />
              {isAdmin && !companySettings.is_single_user_company ? (
                <Flex width="100%" flexDirection="column">
                  {
                   settings.rewards_active &&user&&user.status !== "pending" && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && (isAdmin || getUser().sales_id)
                   ?
                   <>
                    
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    <Link to={`/dashboard/rewards`}>
                    <Flex>
                        <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        rewards-icon
                        </IconBox>
                        <NunitoSans
                        fontWeight={700}
                        fontSize="14px"
                        color="darkerGray"
                      >
                        Rewards
                      </NunitoSans>
                    </Flex>
                      
                    </Link>
                  </Flex>
                  <OneSixDash height="1px" width="100%" />
                   </>
                   :
                   null
                 }
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    <Link to={`/dashboard/company/${companyID}/employees`}>
                    <Flex>
                        <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        coworkers
                        </IconBox>
                        <NunitoSans
                        pt="2px"
                        textAlign="center"
                        fontWeight={700}
                        fontSize="14px"
                        color="darkerGray"
                        >
                          Team
                        </NunitoSans>
                    </Flex>
                      
                    </Link>
                  </Flex>
                  <OneSixDash height="1px" width="100%" />
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    
                   <Link to={`/dashboard/company/${companyID}/invitations`}>
                   <Flex>
                        <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        connections
                        </IconBox>
                        <NunitoSans
                        pt="2px"
                        textAlign="center"
                        fontWeight={700}
                        fontSize="14px"
                        color="darkerGray"
                      >
                        Invite Companies
                      </NunitoSans>
                    </Flex>
                      
                    </Link>
                  </Flex>
                 
                  <Box height="43px" />
                </Flex>
              ) : 
                settings.rewards_active &&user&&user.status !== "pending" && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && (isAdmin || getUser().sales_id)
                   ?
                   <>
                    
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    <Link to={`/dashboard/rewards`}>
                    <Flex>
                        <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        rewards-icon
                        </IconBox>
                        <NunitoSans
                        fontWeight={700}
                        fontSize="14px"
                        color="darkerGray"
                      >
                        Rewards
                      </NunitoSans>
                    </Flex>
                      
                    </Link>
                  </Flex>
                  <OneSixDash height="1px" width="100%" />
                  <Box height="43px" />
                   </>
                   :
                   null
                 
              }
                <Flex width="100%" flexDirection="column">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="42px"
                  >
                    <Link to={`/mlegal`}>
                      <NunitoSans
                        pt="2px"
                        textAlign="center"
                        fontWeight={500}
                        fontSize="14px"
                        color="darkerGray"
                      >
                        View Terms of Service and Privacy Policy
                      </NunitoSans>
                    </Link>
                  </Flex>
                  <OneSixDash height="1px" width="100%" />
                 
                  <Box height="43px" />

                  <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="42px"
                  >
                    <a role="button" href="#" onClick={this._logout}>
                      <Flex>
                    <IconBox  className="icon" color="darkerGray" fontSize={14} mr='5px'>
                        signout
                        </IconBox>
                      <NunitoSans fontWeight={700} fontSize="14px" color="darkerGray">
                        Sign Out
                      </NunitoSans>
                      </Flex>
                    </a>
                    
                  </Flex>
                  <OneSixDash height="1px" width="100%" />
                </Flex>
            </Flex>
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv
          onClick={() => {
            this.setState({ popoverVisible: !this.state.popoverVisible });
          }}
          cursor="pointer"
          width={0.2}
          right={0}
          top={0}
          bottom={0}
        />
        {/* <AbsoluteDiv
          onClick={() => history.push(this.props.rightLink)}
          cursor="pointer"
          width={0.2}
          right={0}
          top={0}
          bottom={0}
        /> */}
        <IconBox color="#606060" className="icon" fontSize={"20px"}>
          
        </IconBox>
       
        {/* <IconBox color="#2FD371" fontSize="20px"> */}
          <a href="#posts">
            <img src={settings.logo_secondary} height="25px" />
            </a>        
        {/* </IconBox> */}
       
        <IconBox color="#606060" className="icon" fontSize={"20px"}>
          {this.props.rightIcon}
        </IconBox>
      </Flex>
    );
  }
}

export default connect(
  null
  ,
  dispatch => ({
    redirect: bindActionCreators(push, dispatch),
    // fetchUser: bindActionCreators(actions.fetchUser, dispatch),
  })
)(MobileMenu);
