import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Flex, IconBox, OverflowHiddenBox } from "../common/flexbox";
import { AbsoluteDiv } from "../common/absolute";
import { NunitoSans } from "../common/typography";

import {
  FourFourRedDashed8pxCornerRadius,
  FourFourYellowDashed8pxCornerRadius,
  FourFourGreenDashed8pxCornerRadius,
  FourFourDottedTransparentWith8pxCornerRadius,
  BlurWrapper
} from "../common/ui";

import { dismiss, dismissAll } from "../../actions/alerts";

const WarningNotification = ({ ...props }) => (
  <Flex
    mb={["25px"]}
    position="relative"
    key={props.key}
    className={props.classes}
  >
    <Flex minHeight={["48px", "60px"]} width="100%" zIndex={16777272}>
      <FourFourYellowDashed8pxCornerRadius width="100%">
        <Flex
          py={["14px", "17px"]}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSans
            pt={["2px", "4px"]}
            px={["17px", "23px"]}
            fontSize={["12px", "14px"]}
            fontStyle="italic"
            fontWeight={400}
            color="#979797"
          >
            {props.message}
          </NunitoSans>
        </Flex>
        {props.dissmiss || true ? (
          <AbsoluteDiv zIndex={999998} top="10px" right="10px">
            <Flex
              onClick={() => {
                props.dismiss();
              }}
              justifyContent="center"
              alignItems="center"
              flexDirection="flex-start"
              width="100%"
              height="100%"
              style={{cursor: 'pointer'}}
            >
              <IconBox fontSize={["13px", "17px"]} color="#c3c3c3">
                thinx
              </IconBox>
            </Flex>
          </AbsoluteDiv>
        ) : null}
      </FourFourYellowDashed8pxCornerRadius>
    </Flex>
    <AbsoluteDiv zIndex={999998} top="0px" left="0px" right="0px" bottom="0px">
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999998}
        width="100%"
        height="100%"
        blur="7px"
        top="6px"
        left="0px"
        opacity={0.075}
      >
        <OverflowHiddenBox
          borderRadius="8px"
          zIndex="999997"
          bg="#000000"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
    <AbsoluteDiv
      zIndex={999997}
      top="-10px"
      left="-10px"
      right="-10px"
      bottom="-10px"
    >
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999997}
        width="100%"
        height="100%"
        blur="7px"
        top="0px"
        left="0px"
        opacity={1}
      >
        <OverflowHiddenBox
          borderRadius="10px"
          zIndex="999997"
          bg="#ffffff"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
  </Flex>
);

const SuccessNotification = ({ ...props }) => (
  <Flex
    mb={["25px"]}
    position="relative"
    key={props.key}
    className={props.classes}
  >
    <Flex minHeight={["48px", "60px"]} width="100%" zIndex={16777272}>
      <FourFourGreenDashed8pxCornerRadius width="100%">
        <Flex
          py={["14px", "17px"]}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSans
            pt={["2px", "4px"]}
            px={["17px", "23px"]}
            fontSize={["12px", "14px"]}
            fontWeight={500}
            color="#979797"
          >
            {props.message}
          </NunitoSans>
        </Flex>
        {props.dissmiss || true ? (
          <AbsoluteDiv zIndex={999998} top="10px" right="10px">
            <Flex
              onClick={() => {
                props.dismiss();
              }}
              justifyContent="center"
              alignItems="center"
              flexDirection="flex-start"
              width="100%"
              height="100%"
              style={{cursor: 'pointer'}}
            >
              <IconBox fontSize={["13px", "13px"]} color="#c3c3c3">
                thinx
              </IconBox>
            </Flex>
          </AbsoluteDiv>
        ) : null}
      </FourFourGreenDashed8pxCornerRadius>
    </Flex>
    <AbsoluteDiv zIndex={999998} top="0px" left="0px" right="0px" bottom="0px">
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999998}
        width="100%"
        height="100%"
        blur="7px"
        top="6px"
        left="0px"
        opacity={0.075}
      >
        <OverflowHiddenBox
          borderRadius="8px"
          zIndex="999997"
          bg="#000000"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
    <AbsoluteDiv
      zIndex={999997}
      top="-10px"
      left="-10px"
      right="-10px"
      bottom="-10px"
    >
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999997}
        width="100%"
        height="100%"
        blur="7px"
        top="0px"
        left="0px"
        opacity={1}
      >
        <OverflowHiddenBox
          borderRadius="10px"
          zIndex="999997"
          bg="#ffffff"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
  </Flex>
);

const ErrorNotification = ({ ...props }) => (
  <Flex
    mb={["25px"]}
    position="relative"
    key={props.key}
    className={props.classes}
  >
    <Flex minHeight={["48px", "60px"]} width="100%" zIndex={16777272}>
      <FourFourRedDashed8pxCornerRadius width="100%">
        <Flex
          py={["14px", "17px"]}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSans
            pt={["2px", "4px"]}
            px={["17px", "23px"]}
            fontSize={["12px", "14px"]}
            fontStyle="italic"
            fontWeight={400}
            color="#FE4A49"
          >
            {props.message}
          </NunitoSans>
        </Flex>
        {props.dissmiss || true ? (
          <AbsoluteDiv zIndex={999998} top="10px" right="10px">
            <Flex
              onClick={() => {
                props.dismiss();
              }}
              justifyContent="center"
              alignItems="center"
              flexDirection="flex-start"
              width="100%"
              height="100%"
              style={{cursor: 'pointer'}}
            >
              <IconBox fontSize={["13px", "17px"]} color="#c3c3c3">
                thinx
              </IconBox>
            </Flex>
          </AbsoluteDiv>
        ) : null}
      </FourFourRedDashed8pxCornerRadius>
    </Flex>
    <AbsoluteDiv zIndex={999998} top="0px" left="0px" right="0px" bottom="0px">
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999998}
        width="100%"
        height="100%"
        blur="7px"
        top="6px"
        left="0px"
        opacity={0.075}
      >
        <OverflowHiddenBox
          borderRadius="8px"
          zIndex="999997"
          bg="#000000"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
    <AbsoluteDiv
      zIndex={999997}
      top="-10px"
      left="-10px"
      right="-10px"
      bottom="-10px"
    >
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999997}
        width="100%"
        height="100%"
        blur="7px"
        top="0px"
        left="0px"
        opacity={1}
      >
        <OverflowHiddenBox
          borderRadius="10px"
          zIndex="999997"
          bg="#ffffff"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
  </Flex>
);

const InfoNotification = ({ ...props }) => (
  <Flex
    mb={["25px"]}
    position="relative"
    key={props.key}
    className={props.classes}
  >
    <Flex minHeight={["48px", "60px"]} width="100%" zIndex={16777272}>
      <FourFourDottedTransparentWith8pxCornerRadius width="100%">
        <Flex
          py={["14px", "17px"]}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSans
            pt={["2px", "4px"]}
            px={["17px", "23px"]}
            fontSize={["12px", "14px"]}
            fontWeight={500}
            color="#979797"
          >
            {props.message}
          </NunitoSans>
        </Flex>
        {props.dissmiss || true ? (
          <AbsoluteDiv zIndex={999998} top="10px" right="10px">
            <Flex
              onClick={() => {
                props.dismiss();
              }}
              justifyContent="center"
              alignItems="center"
              flexDirection="flex-start"
              width="100%"
              height="100%"
              style={{cursor: 'pointer'}}
            >
              <IconBox fontSize={["13px", "17px"]} color="#c3c3c3">
                thinx
              </IconBox>
            </Flex>
          </AbsoluteDiv>
        ) : null}
      </FourFourDottedTransparentWith8pxCornerRadius>
    </Flex>
    <AbsoluteDiv zIndex={999998} top="0px" left="0px" right="0px" bottom="0px">
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999998}
        width="100%"
        height="100%"
        blur="7px"
        top="6px"
        left="0px"
        opacity={0.075}
      >
        <OverflowHiddenBox
          borderRadius="8px"
          zIndex="999997"
          bg="#000000"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
    <AbsoluteDiv
      zIndex={999997}
      top="-10px"
      left="-10px"
      right="-10px"
      bottom="-10px"
    >
      <BlurWrapper
        className="blur-wrapper"
        zIndex={999997}
        width="100%"
        height="100%"
        blur="7px"
        top="0px"
        left="0px"
        opacity={1}
      >
        <OverflowHiddenBox
          borderRadius="10px"
          zIndex="999997"
          bg="#ffffff"
          width="100%"
          height="100%"
        />
      </BlurWrapper>
    </AbsoluteDiv>
  </Flex>
);
export class SystemAlerts extends Component {
  componentWillUnmount() {
    this.props.dismissAll();
  }
  _onDismiss(key) {
    this.props.dismiss(key);
  }
  _renderNotification(alert, i) {
    /*
    let classes = cx({
      alert: true,
      'alert-info': alert.type === 'info',
      'alert-success': alert.type === 'success',
      'alert-warning': alert.type === 'warning',
      'alert-error': alert.type === 'error',
    });
    */

    let dismiss = alert.dismissable
      ? null
      : this._onDismiss.bind(this, alert.key);

    /*
    let message =
      typeof alert.message !== 'string' ? (
        React.createElement(alert.message)
      ) : (
        <div className="stack">{alert.message}</div>
      );
      */

    if (alert.type === "info") {
      return (
        <InfoNotification key={i} dismiss={dismiss} message={alert.message} />
      );
    } else if (alert.type === "success") {
      return (
        <SuccessNotification
          key={i}
          dismiss={dismiss}
          message={alert.message}
        />
      );
    } else if (alert.type === "warning") {
      return (
        <WarningNotification
          key={i}
          dismiss={dismiss}
          message={alert.message}
        />
      );
    } else if (alert.type === "error") {
      return (
        <ErrorNotification key={i} dismiss={dismiss} message={alert.message} />
      );
    }
  }
  render() {
    return (
      <Flex
        mt={["60px", "60px", "60px", "103px", "103px"]}
        flexDirection="column"
        width="100%"
      >
        {this.props.alerts.map(this._renderNotification.bind(this))}
      </Flex>
    );
  }
}

export default connect(
  state => ({
    alerts: Object.keys(state.alerts).map(key => {
      return state.alerts[key];
    })
  }),
  dispatch => ({
    dismiss: bindActionCreators(dismiss, dispatch),
    dismissAll: bindActionCreators(dismissAll, dispatch)
  })
)(SystemAlerts);
