import { makeAuthHeader, getUser } from "./auth"

export const onboardStripeConnectAccount = async(isCompanyAccount = false) => {
    const stripeLink = await fetch(`${process.env.REACT_APP_API_URL}/stripeconnect`,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            userId: getUser().id,
            isCompanyAccount
        })
    }).then(res=>res.json()).catch(err => console.log(err));

    if(stripeLink.url){
      window.open(stripeLink.url, "_blank")
    }
}