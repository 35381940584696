const defaultState = {
    incentiveHovered: null,
    incentiveSelected: null,
    incentiveType: null,
    rowSelected: {},
    csvDropOpen: false
  };
  
  export default (state = defaultState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
      case "SET_INCENTIVE_HOVERED":
        newState.incentiveHovered = action.payload;
        return newState;
      case "SET_INCENTIVE_SELECTED":
        newState.incentiveSelected = action.payload;
        return newState;
      case "SET_INCENTIVE_TYPE":
        newState.incentiveType = action.payload;
        return newState;
      case "SET_ROW_SELECTED":
        newState.rowSelected = action.payload;
        return newState;
      case "SET_CSV_DROP":
        newState.csvDropOpen = action.payload;
        return newState;
        
      default:
        return state;
    }
  };
  