import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset } from "redux-form";
import _ from "underscore";
import moment from "moment";
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

//utils
import { getUser, dismissInviteSuggestion, getDismissedInviteSuggestions, dismissConnectionSuggestion, getDismissedConnectionSuggestions, dismissSuggestionDuringSession, getDismissSuggestionDuringSession } from "../../../utils/auth";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { searchVendors, searchPendingCompanies, searchConnectedCompanies, searchNotConnectedCompanies, toggleCompany } from "../../../actions/search";//connected to ClickCursorFlex line 264
//components
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { SearchFilterFlag,
  GradientOverflowDownwardLaptop, 
  InviteVendorCTA, ImageTreatment, NavNotificationBubble } from "../../common/ui";
import { InputStyled, DashboardSmallCTA, DashboardSmallToggleCTA } from "../../common/forms";
import { filterActiveGroups } from "../../../utils/helpers";
import {
  //VendorInvitedRowGroup,
  VendorRowGroup,
  RowGroupHeader
} from "../../common/rows";

import {VendorList} from "../../common/reactWindow";

import LoadingSpinner from "../../common/loading";
import {defaultCompany} from "../../../utils/defaultLogos";
import {InviteSuggestionBox, ConnectionSuggestionBox} from "../../common/suggestions";

let timeout =null;

export class ManageVendors extends React.PureComponent {
  state = { 
    search: null, 
    loading: true, 
    sortByValue: 'name',
    searchValue: '',
    partnerModalOpen: false,
    partnerVendor: null,
    partnerIds: [],
    existingPartnerIds: [],
    partnerIdSearchValue: '',
    theLabels: {},
    pendingNumber: 0,
    readyToConnectIndividuals: 0,
    inviteSearchSuggestions: null,
    dismissedInvites: [],
    dismissedConnections: [],
    expandedFilterTab: "",
    selectedStatus: "",
    selectedGoal: "",
    selectedCompanyType:"",
    selectedIndustry: []
   }
  
  constructor(props) {
    super(props);    
  }

  componentDidMount(){
    this._fetchDependencies();
    this._search = _.debounce(this._search.bind(this), 400);
    this.setState({selectedGoal: 'All', selectedIndustry: [], selectedStatus: 'All'});
    this._toggleCompany("all");
    this._search(this.state.searchValue, {});

try {
  if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
    setTimeout(()=>{
      if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
        document.getElementById("VendorRowGroupId").children[1].style.overflowX = "hidden";
      }}, 4000);
  } else {
    setTimeout(()=>{
      if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
        document.getElementById("VendorRowGroupId").children[1].style.overflowX = "hidden";
      }}, 4000);
  }
} catch(e){/*fail silently */}

  }

  _fetchDependencies = async () => {
    const { fetchCompany, match, fetchGroups, systemSettings, fetchInviteSuggestions, redirect, companySettings } = this.props;
    if(!(getUser().company_admin && (!systemSettings.many_to_one || (systemSettings.many_to_one && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)))){
      return redirect('/dashboard');
    }

    if(companySettings && companySettings.company_goal){
      this.setState({selectedGoal : companySettings.company_goal==="share_info" ? "Receive Info" : "Share Info"});
    }

    if(companySettings && companySettings.company_type){
      this.setState({selectedCompanyType : companySettings.company_type});
    }

    if(companySettings && companySettings.industry_categories && companySettings.industry_categories.length){
      const names = companySettings.industry_categories.map(categ=>categ.name);
      this.setState({selectedIndustry : names});
    }
    let matcher = "";
    if(match && match.params && match.params.id){
      matcher = match.params.id;
    }
    await fetchCompany(matcher);

    this._fetchDependencies();
    this._fetchLabelValues();
    fetchGroups({ company_id: getUser().company_id });
    let query = decodeURI(window.location.href.split("?")[1]);

    if(query !== undefined && query !== "undefined"){
      this._updateSearch(query, true);
    }

    if(companySettings.invite_suggestion_active){

      fetchInviteSuggestions({company_id: getUser().company_id, user_id: getUser().id});
      this.setState({
        dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()],
        dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
      });
    }
    // console.log("4000");
    setTimeout(()=>{this.setState({loading: false})}, 2000);
    // setTimeout(()=>{document.getElementById("VendorRowGroupId").children[1].style.overflowX = "hidden";console.log("4000")}, 4000);
  }

  _fetchLabelValues = async ()=>{
    const labels = await fetch(`${process.env.REACT_APP_API_URL}/partnerlabels`).then(res=>res.json());
      
    let theLabels = {};

    labels && labels.forEach(lb=> theLabels[lb.partner_id] = lb.label);
    this.setState({theLabels});
    
  }

  componentWillUnmount(){
    this._toggleCompany('all')
    this._clearSearch();
  }

 componentDidUpdate(prevProps, prevState, nextProps){
   if(nextProps && nextProps.companyResults){ 
    const resultsDiff = _.difference(
    nextProps.companyResults,
    this.props.companyResults
  );

  if (resultsDiff > 0) {
    
    return this._fetchConnections();
  }


  }


  console.log(prevState.inviteSearchSuggestions, this.state.inviteSearchSuggestions, this.state.searchValue);
  if(
    !this.state.loading  && 
    this.props.companyResults.length===0 && 
    this.state.searchValue && 
    this.state.searchValue.length>2 && 
    (
      (prevState.inviteSearchSuggestions === null && this.state.inviteSearchSuggestions===null)
      ||
      !_.isEqual(prevState.inviteSearchSuggestions, this.state.inviteSearchSuggestions)
      ||
      prevState.searchValue !== this.state.searchValue
    )
    ){
    this._fetchInvitesBySearch(this.state.searchValue.trim().toLowerCase());
  }

  const {searchConnectedCompanies, searchNotConnectedCompanies, searchPendingCompanies, companyResults} = this.props

   if(this.props.companyResults.length===0 && this.state.loading){

    setTimeout(()=>this.setState({loading:false}), 2000)

   }

   if((searchConnectedCompanies && searchNotConnectedCompanies && searchPendingCompanies) || (!searchConnectedCompanies && !searchNotConnectedCompanies && searchPendingCompanies)){
     let pendingNumber = companyResults.filter(company=>company.connectionStatus==='requested' || company.connectionStatus==='actionRequired').length;
     if(pendingNumber!==prevState.pendingNumber){
       this.setState({pendingNumber});
     }
   }


   if(
    !searchConnectedCompanies && !searchPendingCompanies && searchNotConnectedCompanies && 
    this.state.searchValue.length>2 &&
    companyResults.length>1 && companyResults.every(company=>company.domain===companyResults[0].domain)
    ){
      this.setState({readyToConnectIndividuals: companyResults.length})
   }else{
    this.setState({readyToConnectIndividuals: 0})
   }

 }



  _fetchInvitesBySearch = async(value='')=>{
    
    const suggestions = await fetch(`${process.env.REACT_APP_API_URL}/searchinvitesuggestions?user_id=${getUser().id}&search=${value}`).then(res=>res.json()).catch(err=>console.log(err));

    this.setState({inviteSearchSuggestions: suggestions})
  }

  _fetchConnections = () => {
    const { companyResults, fetchConnections } = this.props;
    if(!getUser()) return;
    fetchConnections({
      company_id: getUser().company_id,
    });
  };

  _fetchDependencies = async () => {
    const { searchVendors, searchCC, systemSettings, companySettings } = this.props;
    let extraFilters = {};
    if(companySettings.company_goal){
      extraFilters.company_goal = companySettings.company_goal === "Resellers" ? 'share_info' : 'receive_info';
    }

    if(companySettings.industry_categories && companySettings.industry_categories.length){
      extraFilters.industry_categories = companySettings.industry_categories.map(ct=>ct.name);
    }
    if(systemSettings.many_to_one){
      searchCC("", extraFilters);
    }else{

      searchVendors("", extraFilters);
    }
    
    this._fetchConnections();
    
    const existingPartnerIds = await fetch(`${process.env.REACT_APP_API_URL}/partnerids`).then(res=>res.json());
    this.setState({existingPartnerIds});
  };

  

  _clearSearch = () => {
    const extraFilters = {};
    if(this.state.selectedGoal && this.state.selectedGoal!=='All' ){
      
      extraFilters.company_goal = this.state.selectedGoal;
    }

    if(this.state.selectedIndustry && this.state.selectedIndustry.length){
      
      extraFilters.industry_categories = this.state.selectedIndustry;
    }

    this.setState({ searchValue: "", search: ""});
    this._search("", extraFilters);

  };

  _sortBy = (a, b) =>{
    const {sortByValue} = this.state;
    if(sortByValue==='newest') return b.created_at - a.created_at;
    if(sortByValue==='oldest') return a.created_at - b.created_at;
    a.name=a.name.trim();
    b.name=b.name.trim();
    if(sortByValue==='name') return (a.name<b.name ? -1 : a.name > b.name ? 1 : 0);
    if(sortByValue==='reverse-name') return (a.name>b.name ? -1 : a.name < b.name ? 1 : 0);
  }

  /*
    original search
    _search = async input => {
      const { searchVendors } = this.props;
      searchVendors(input);
    };
  */
  _search = (value, extraFilters={}) => {

  const { searchCC, searchPC, searchNCC, searchVendors, searchNone, searchConnectedCompanies, searchNotConnectedCompanies, searchPendingCompanies, systemSettings} = this.props;

  const filters = [
    "searchNotConnectedCompanies",
    "searchConnectedCompanies",
    "searchPendingCompanies",
    "searchNone"
  ];
  const searchFunctions = {
    searchConnectedCompanies: searchCC,
    searchPendingCompanies: searchPC,
    searchNotConnectedCompanies: searchNCC,
    searchVendors: searchVendors,
    searchNone: searchNone
  };

  if(systemSettings.many_to_one){
    // if it is many to one , we only search for connected companies
    searchCC(value, extraFilters);

  }else if(searchConnectedCompanies && searchNotConnectedCompanies && searchPendingCompanies){
    searchVendors(value, extraFilters);
  }else{
    
    filters.forEach(filter => {
        if (this.props[filter]) {
          searchFunctions[filter](value, extraFilters);
        }
      });

      this.setState({loading: true})
  }


  };

  _updateSearch = (input, incomingQuery=false) => {
    this.setState({ search: input, searchValue: input });
    clearTimeout(timeout);
    if(incomingQuery){
     return this._resetFilters();
    }
    const extraFilters = {};
    if(this.state.selectedGoal!=='All'){
      extraFilters.company_goal = this.state.selectedGoal !== "Resellers" ? 'share_info' : 'receive_info';
    }

    if(this.state.selectedIndustry && this.state.selectedIndustry.length){
      extraFilters.industry_categories = [...this.state.selectedIndustry];
    }
    timeout = setTimeout(()=>this._search(input, extraFilters), 1000);
  };

  _toggleCompany = category => {
    const { toggleCompany } = this.props;
    const { searchValue, selectedGoal, selectedIndustry } = this.state;

    const filters = [
      "searchNotConnectedCompanies",
      "searchConnectedCompanies",
      "searchPendingCompanies"
    ];

    filters.forEach(filter=>{
      toggleCompany(filter, category==='all' ? true : filter===category);
    });
    let extraFilters = {};
    if(selectedGoal){
      extraFilters.company_goal = selectedGoal !== "Resellers" ? 'share_info' : 'receive_info';
    }

    if(selectedIndustry && selectedIndustry.length){
      extraFilters.industry_categories = [...selectedIndustry];
    }
    this._search(searchValue, extraFilters);
    
  };

  _onSubmit = async data => {
    console.log("THIS CODE WAS SUPPOSED TO BE REFACTORED OUT")
    const DMG ="src/components/dashboard/vendors/search.js";

    const {
      createCompany,
      createConnection,
      createUser,
      resetForm
    } = this.props;

    let userPayload = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      status: "invited_company"
    };

    const user = await createUser(userPayload);
    console.log(DMG+" this.props",this.props);
    console.log(DMG+" this.state",this.state);

    if (user && user.payload) {
      let companyPayload = {
        domain: data.email.split("@")[1],
        name: data.company_name,
        status: "invited",
        user_id: user.payload.id
      };

      const company = await createCompany(companyPayload);

      if (company && company.payload) {
        await createConnection({
          vendor_id: company.payload.id,
          company_id: getUser().company_id,
          user_id: getUser().id,
          invitee_id: user.payload.id,
          status: "invited"
        });

        if (user.payload && company.payload) {
          this._fetchConnections();
        }
      } else {
        //failure, does company already exist?
        console.log("could not create company")
        console.log(company)
        console.log(user)
      }
    }

    resetForm("create-vendor");
  };

  _connectAll = async()=>{
    const {companyResults, createConnection, fetchConnections} = this.props;

   for(let i=0; i<companyResults.length; i++){

    const vendor = companyResults[i];

    await createConnection({
      vendor_id: vendor.id,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    })

   }

   await fetchConnections({
    company_id: getUser().company_id,
  });
  
  }

  _handlePartnerModal = vendor=>{
    this.setState({partnerModalOpen: true, partnerVendor: vendor, partnerIds: vendor.partner_ids})
  }

  _savePartners = async ()=>{
    const {partnerIds, partnerVendor, searchValue} = this.state;
    const {updateCompany, notifySuccess, notifyError} = this.props;

    const vendor = await updateCompany(partnerVendor.id, {
      partner_ids: JSON.stringify(partnerIds)
    } ).catch(err=>console.log(err));
    
    this.setState({partnerModalOpen: false, partnerVendor: null, partnerIds: []});
    if(vendor && vendor.payload && vendor.payload.id){
      setTimeout(()=>{
        this._search(searchValue);
        notifySuccess("Partner IDs saved successfully.")

      },1000)
    }else{
      notifyError("There was a problem. Please try again.")
    }

  }

  validate = values => {
    const errors = {};
    if (!values.company_name) {
     // errors.company_name = "Company name required";
    }

    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    return errors;
  };

  _connectionStatus = (vendor) => {
    const { company, connections } = this.props;

    let incomingRequest = _.findWhere(connections, {
      vendor_id: getUser().company_id,
      company_id: vendor.id
    });
    let outgoingRequest = _.findWhere(connections, {
      company_id: getUser().company_id,
      vendor_id: vendor.id
    });

    
    if (outgoingRequest && outgoingRequest.vendor_id === vendor.id) {
      return outgoingRequest.status;
    }

    const incomingStatuses = {
      requested: "actionRequired",
      active: "active",
      removed: "removed",
      declined: "declined"
    };

    if (incomingRequest && incomingRequest.company_id === vendor.id) {
      return incomingStatuses[incomingRequest.status];
    }

    return null;
  };

  _handleInvite = async({id, first_name, last_name, email})=>{
    const {company, notifyError, notifySuccess, createUser, createConnection, fetchInviteSuggestions} = this.props;

    this._dismissForSession(id);

    let userPayload = {
      email,
      first_name,
      last_name,
      status: "invited_company",
      inviter: {
        ...getUser(),
        company_name: company.name
      }
    };


    const user = await createUser(userPayload);

    if (user && user.payload) {
      // console.log(user);
       let result = await createConnection({
         vendor_id: getUser().company_id,//maye hard-code this to KickPost
         company_id: getUser().company_id,//getUser().company_id,
         user_id: getUser().id,
         invitee_id: user.payload.invited_company_user.id,
         status: "invited",
         inviteMessage: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales."
       });
       
       if(typeof result !== "undefined" && result.payload.status==="invited"){
         notifySuccess(
           "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
         );
         this._fetchInvitesBySearch(this.state.searchValue.trim().toLowerCase());
         
       } else {
         notifyError(
           "We could not invite this person. Please try again later."
         );
       }
     }else {
      notifyError(
        "We could not invite this person. Please try again later."
      );
    }

  };

  _handleDismiss = id =>{

    

    dismissInviteSuggestion(id);

    const dismissedInvites = [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()];
    this.setState({
      dismissedInvites
      
    });
  }

  _handleSuggestionConnect = async(id)=>{
    const { createConnection, fetchConnections, fetchNotificationCount, fetchConnectionSuggestions} = this.props;

    this._dismissForSession(id);
    
    await createConnection({
      vendor_id: id,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });
   
    fetchConnectionSuggestions({
      company_id: getUser().company_id
    });

    await fetchConnections({ company_id: getUser().company_id });
    fetchNotificationCount();
  };

  _dismissForSession = id =>{
    dismissSuggestionDuringSession(id);
    this.setState({
      dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
    });
  }

  _handleConnectionSuggestionDismiss = id =>{

    dismissConnectionSuggestion(id);
      this.setState({
        dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
      });
  }

  _toggleFilterTab = (tab)=>{
    this.setState({expandedFilterTab: tab === this.state.expandedFilterTab ? "" : tab});
  }

  _toggleFilterOption = (tab, option)=>{

    const {selectedGoal, selectedIndustry, searchValue} = this.state;

    let extraFilters = {};
    if(tab==="Industry"){

      if(selectedGoal && selectedGoal !== 'All'){
        extraFilters.company_goal = selectedGoal !== "Resellers" ? 'share_info' : 'receive_info';
      }

      if(option==="All"){
        this.setState({selectedIndustry: []});
        return this._search(searchValue, extraFilters);
      }


      let current = [...this.state.selectedIndustry];

      if(current.includes(option)){
        const theNewArr = current.filter(item=>item!==option);
        this.setState({selectedIndustry: theNewArr});
        extraFilters.industry_categories = [...theNewArr];
        return this._search(searchValue, extraFilters);
      }

      if(current.length >= 3){
        let [item1, item2] = current;
        const theNewArr = [item1, item2, option];
        this.setState({selectedIndustry: theNewArr});
        extraFilters.industry_categories = [...theNewArr];
        return this._search(searchValue, extraFilters);
      }else{
        current.push(option);
        this.setState({selectedIndustry: current});
        extraFilters.industry_categories = [...current];
        return this._search(searchValue, extraFilters);
      }

    }else if(tab==="Type"){

      if(selectedIndustry && selectedIndustry.length){
        extraFilters.industry_categories = [...selectedIndustry];
      }

      if(option !== "All"){
        extraFilters.company_goal = option!=="Resellers" ? "share_info" : "receive_info";
        // extraFilters.company_type = this.company_type;
      }

      this.setState({selectedGoal: option});

      this._search(searchValue, extraFilters);

    }else if(tab==="Status"){
     
      this.setState({selectedStatus: option});
      this._toggleCompany(option==="All" ? "all" : option === "Connected" ? "searchConnectedCompanies" : option === "Pending" ? "searchPendingCompanies" : "searchNotConnectedCompanies");
    }
  }

  _resetFilters = ()=>{
    this.setState({selectedGoal: 'All', selectedIndustry: [], selectedStatus: 'All'});
    this._toggleCompany("all");
    this._search(this.state.searchValue, {});
  }

  render() {
    const {
      browser,
      company,
      companyResults,
      connections,
      groups,
      searchConnectedCompanies,
      searchNotConnectedCompanies,
      searchPendingCompanies,
      createConnection,
      updateConnection,
      updateCompany,
      fetchConnections,
      fetchConnectionSuggestions,
      redirect,
      user,
      systemSettings,
      companySettings,
      notifySuccess,
    } = this.props;
    
    const { search, loading, sortByValue, pendingNumber, partnerModalOpen, partnerVendor, partnerIds, existingPartnerIds, partnerIdSearchValue, theLabels, readyToConnectIndividuals, inviteSearchSuggestions, dismissedInvites, dismissedConnections, expandedFilterTab, selectedGoal, selectedIndustry, selectedStatus } = this.state;
    const isMobile = browser.lessThan.small;

    
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: "670px",
        height: "80vh",
        zIndex: 999998,
        overflowX: "hidden",
        overflowY: "auto"
      },
  
      overlay: {
        zIndex: 999997,        
        background: "#A3A99E88"
      }
      
    };

    const filters = {
        Status: ["All", "Not Connected", "Connected", "Pending"],
        Type: ["All", "Vendors and Distributors", "Resellers"],
        Industry: ["All", "Technology", "Audio/Video", "Office Products", "SAAS", "Telecomm", "Other"]
    };

    const filterCounts = {
        Status: 0,
        //Status: !selectedStatus || selectedStatus === 'All' ? 0 : 1,
        Goal: 0,
        //Goal: selectedGoal === 'All' ? 0 : 1,
        Industry: selectedIndustry.length
    }

    const allSelected = searchConnectedCompanies && searchNotConnectedCompanies && searchPendingCompanies;

    // const filteredInviteSuggestions = inviteSearchSuggestions ? inviteSearchSuggestions.filter(invite=>!dismissedInvites.includes(invite.id)): null;
    const filteredInviteSuggestions = null;
    // const filteredConnectionSuggestions = connectionSuggestions ? connectionSuggestions.filter(suggestion=>!dismissedConnections.includes(suggestion.id)): null;
    const filteredConnectionSuggestions = null;

    const showConnectionSuggestions = false && companySettings.connection_suggestion_active && !(search && search.length) && filteredConnectionSuggestions && filteredConnectionSuggestions.length;

    if (company && connections && companyResults) {
      return (
        <Flex flexDirection="column">
          <Modal
                   isOpen={partnerModalOpen}
                   onRequestClose={()=>{
                     this.setState({partnerModalOpen: false, partnerVendor: null, partnerIds: [], partnerIdSearchValue:''});
                     
                    }}
                   style={customStyles}
                   contentLabel="Partner ID Modal"
                   ariaHideApp={false}
                  >
                    <Flex
                    flexDirection="column"
                    style={{width:"100%", height:'100%'}}
                    >
                    <Flex
                    position="relative"
                    justifyContent="center"
                    // alignItems="center"
                    style={{marginBottom:'25px'}}
                    >
                      <AbsoluteDiv
                      display={["block"]}
                      left={["0px"]}
                      bottom={"0px"}
                      top={["0px"]}
                      width={["100px"]}
                      >
                        <DashboardSmallCTA
                        fontSize="12px"
                        onClick={()=>this._savePartners()}
                        >
                          SAVE
                        </DashboardSmallCTA>
                      </AbsoluteDiv>
                      <NunitoSansBold
                      // fontSize="14px"
                      // fontWeight="bold"
                      pt={["6px", "6px", "8px", "6px"]}
                      fontSize={[14, 14, 16, 16]}
                      fontWeight={["bold"]}
                      justifyContent="center"
                      color="#10213A"
                      >
                      Set Partner IDs
                      </NunitoSansBold>
                      <AbsoluteDiv
                      display={["block"]}
                      right={["20px"]}
                      bottom={"0px"}
                      top={["0px"]}
                      width={["17px"]}
                      >
                        <ClickCursorFlex
                          height={["100%", "100%", "100%"]}
                          alignItems={["center"]}
                          onClick={()=>{
                            this.setState({partnerModalOpen: false, partnerVendor: null, partnerIds: [], partnerIdSearchValue:''});
                            
                           }}
                          justifyContent={[
                            "flex-start",
                            "flex-start",
                            "flex-start",
                            "center"
                          ]}
                        >
                          <IconBox
                            cursor="pointer"
                            pl={["15px"]}
                            pb={["15px"]}
                            pt={["15px"]}
                            fontSize={["14px, 14px, 18px, 18px"]}
                            color="#c3c3c3"
                          >
                            thinx
                          </IconBox>
                          </ClickCursorFlex>
                      </AbsoluteDiv>
                    </Flex>

                    {
                        partnerVendor
                        ?
                        <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        mb="20px"
                        >
                          <ImageTreatment
                              img={partnerVendor.logo || defaultCompany(partnerVendor.id, partnerVendor.name)}
                              blurRadius={"0px"}
                              imageInset={0}
                              borderRadius={52}
                              innerBorderRadius={50}
                              shadowOffset={0}
                              padding={4}
                              height={75}
                              width={75}
                          />
                          
                          <NunitoSansBold
                          fontSize="18px, 18px, 20px, 20px"
                          fontWeight="bold"
                          color="#000000"
                          style={{marginLeft:"20px"}}
                          >
                            {partnerVendor.name}
                          </NunitoSansBold>
                          
                        </Flex>
                        :
                        null
                      }

                    <Flex
                    flexDirection="column"
                    alignItems="center"                    
                    >
                      <NunitoSans
                      fontSize="12px"
                      // color= {colors.buttonCustom}
                      fontWeight="bold"
                      width="85%"
                      style={{borderBottom:'1px solid #D3D3D3', marginTop: '10px', paddingBottom: '10px'}}
                      >
                       Assigned Partner IDs
                      </NunitoSans>

                      {
                        partnerIds.length ? 
                        partnerIds.map((id, index)=>(
                          <Flex
                          // justifyContent="center"
                          // alignItems="center"
                          width="85%"
                          style={{borderBottom:'1px solid #D3D3D3', marginTop: '10px', paddingBottom: '10px'}}
                        
                          >

                            <NunitoSans
                            fontSize="12px"
                            color="#00000"
                            fontWeight="bold"
                            width="85%"
                            
                            >
                              {`${id} (${theLabels[id] || "No Label"})`}
                            </NunitoSans>

                            <FontAwesomeIcon
                            icon={faMinusCircle}
                            color="#FE4A49"
                            size="sm"
                            style={{cursor:'pointer', marginLeft: "30px"}}
                            onClick={()=>{
                              let currentIds = [...partnerIds];
                              currentIds.splice(index,1);
                              this.setState({partnerIds: currentIds})
                            }}
                            />
                          </Flex>
                        ))
                        :
                        <NunitoSansBold
                        style={{marginTop:'10px'}}
                        >
                          No Assigned Partner Ids
                        </NunitoSansBold> 
                      }

                        <input
                        id='partner_id_input'
                        type="text"                        
                        placeholder="Search by Partner ID or Label..."                        
                        style={{backgroundColor:"#EBEBE7",width:"85%", color:"#979797", paddingTop:'10px', paddingBottom: '10px', paddingLeft: '5px', fontSize:'12px', fontFamily:'NunitoSans', marginTop:'10px'}}
                        onChange={e=>this.setState({partnerIdSearchValue: e.target.value.trim()})}
                        value={partnerIdSearchValue}
                          
                        // style={{border: '1px solid black', width: '50%', paddingLeft:'10px', marginRight: "10px", color:'#000000'}}
                        />
                     
                      <NunitoSans
                      fontSize="12px"
                      // color= {colors.buttonCustom}
                      fontWeight="bold"
                      width="85%"
                      style={{borderBottom:'1px solid #D3D3D3', marginTop: '10px', paddingBottom: '10px'}}
                      >
                        Partner IDs
                      </NunitoSans>
                      {
                        existingPartnerIds.length ? 
                        existingPartnerIds.filter(id=>(id.toLowerCase().includes(partnerIdSearchValue.toLowerCase()) && !partnerIds.includes(id)) || (theLabels[id]|| '').toLowerCase().includes(partnerIdSearchValue.toLowerCase())).map((id, index)=>(
                          <Flex
                          // justifyContent="center"
                          // alignItems="center"
                          width="85%"
                          style={{backgroundColor: index % 2===0 ?  '#FAFAFA': 'white', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px'}}
                        
                          >

                            <NunitoSans
                            fontSize="12px"
                            color="#00000"
                            fontWeight="bold"
                            width="85%"
                            
                            >
                              {`${id} (${theLabels[id] || "No Label"})`}
                            </NunitoSans>

                            <FontAwesomeIcon
                            icon={faPlusCircle}
                            // color={colors.kickpostGreen}
                            size="sm"
                            style={{cursor:'pointer', marginLeft: "30px"}}
                            onClick={()=>{
                              let currentIds = [...partnerIds];
                              currentIds.push(id);
                              this.setState({partnerIds: currentIds})
                            }}
                            />
                          </Flex>
                        ))
                        :
                        <NunitoSansBold
                        style={{marginTop:'10px'}}
                        >
                          No Partner Ids
                        </NunitoSansBold> 
                      }
                    </Flex>
                    
                    </Flex>
                    
              </Modal>
        <Flex
        // mb="7px"
        fontSize="small"
        ml="12px"
        color="green"
        >
        </Flex>
          <Flex
            mt="1px"
            mb="15px"
            mx={["0px"]}
            // height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <AbsoluteDiv
                display={["block"]}
                // left={["10px"]}
                // bottom={"0px"}
                top={["6px", "6px"]}
                width={["40px"]}
              >
                  <IconBox
                    // pl={["15px"]}
                    // pb={["15px"]}
                    // pt={["15px"]}
                    fontSize={["18px", "18px"]}
                    color="#c3c3c3"
                  >
                    search
                  </IconBox>
              
              </AbsoluteDiv>
              <InputStyled
                placeholder="Search Companies & People"
                onChange={e => this._updateSearch(e.target.value)}
                value={search || "" }
                innerRef={input => (this.searchInput = input)}
                style={{paddingLeft:'45px'}}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                // pt={["3px"]}
                px={["5px"]}
                mt={["0px"]}
                mb={["5px"]}
                height={[31, 40, 40, 40]}
                // fontWeight={400}
                fontSize={[14, 20, 20, 20]}
                lineHeight={1.5}
                color={"#000000"}
                borderBottom={[0]}
              />
              {
                search && search.length
                ?
                <AbsoluteDiv
                display={["block"]}
                right={["10px"]}
                top={["-8px"]}
                width={["40px"]}
              >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={this._clearSearch}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
                :
                null
              }

        

            </Flex>
          
          </Flex>
  <Flex flexDirection="column" alignItems="flex-end" style={{ marginBottom: "15px"}}>
    <Flex alignItems="center">
        <Flex flexDirection="column" alignItems="center"
            style={{paddingRight: '10px', borderRight: '1px solid #C3C3C3'}}>

            <NunitoSans fontSize='12px' color="#C3C3C3">
                Filters
            </NunitoSans>

            <NunitoSans fontSize='10px' color="#2FD371" style={{cursor: 'pointer'}} onClick={()=>this._resetFilters()}
                >
                Clear All
            </NunitoSans>
        </Flex>
        {
        Object.keys(filters).map((tab, index)=>(
        <Flex
            key={[`FLEXIS` + `${Math.random().toString()}`]}
            style={{padding: '0 10px', border: '1px solid #2FD371', borderRadius: '12px', marginLeft: '10px', cursor: "pointer"}}
            onClick={()=>this._toggleFilterTab(tab)}
            >
            <NunitoSansSemiBold fontSize="12px" color="#2FD371" style={{marginRight: '10px'}}>
                {`${tab}${filterCounts[tab] > 0 ? " ("+filterCounts[tab]+")" :''}`}
            </NunitoSansSemiBold>

            <FontAwesomeIcon icon={expandedFilterTab===tab ? faAngleDown : faAngleRight}
                style={{fontSize: '12px', marginTop: '3px'}} color="#2FD371" />

        </Flex>
        ))
        }
  </Flex>
    {
    expandedFilterTab
    ?
    <Flex alignItems="center" style={{marginTop: '10px'}}>
        {
        filters[expandedFilterTab].map((option, index)=>{

        const current = expandedFilterTab==="Status" ? selectedStatus : expandedFilterTab==="Type" ? selectedGoal :
        expandedFilterTab==="Industry" ? selectedIndustry : "";

        const isSelected = expandedFilterTab==="Industry" ? (option==="All" && current.length===0) ||
        current.includes(option) : (option==="All" && current.length===0) || current === option;

        return (
        <NunitoSansSemiBold 
            key={[`NSSBIS` + `${Math.random().toString()}`]}
            fontSize="12px" color={isSelected ? "#FFFFFF" : "#C3C3C3" }
            style={{padding: '0 10px', border: `1px solid ${isSelected ? "#2FD371" : "#C3C3C3"}`, borderRadius: '12px', marginLeft: '10px', cursor: "pointer", backgroundColor: isSelected ? "#2FD371" : "transparent"}}
            onClick={()=>this._toggleFilterOption(expandedFilterTab, option)}
            >
            {option}
        </NunitoSansSemiBold>
        )
        })
        }
    </Flex>
    :
    null
    }

</Flex>
 
       
          
          {companyResults.length ? (
            <div>
              <div id="VendorRowGroupId" style={{ overflowY: "auto", overflowX: "hidden"}}>
              
              <NunitoSansBold
              style={{marginBottom: '15px'}}
              >
                CONNECTIONS
              </NunitoSansBold>
              <VendorList 
              id="VendorListID"
              currentHeight={showConnectionSuggestions ? 460 : 660}
               createConnection={createConnection}
               updateConnection={updateConnection}
               updateCompany={updateCompany}
               connections={connections}
               handlePartnerModal={this._handlePartnerModal}
               company={company}
               fetchConnections={fetchConnections}
               fetchConnectionSuggestions={fetchConnectionSuggestions}
              //  firstRow={i === 0}
              //  lastRow={i === companyResults.length - 1}
               hideFirstLastDash={true}
              fetchNotificationCount={()=>this.props.fetchNotificationCount({user_id:getUser().id})}
              vendors={companyResults.sort(this._sortBy)}
               mobile={isMobile}
               redirect={redirect}
               user={getUser()}
               settings={systemSettings}
               notifySuccess={notifySuccess}
              />

              
              </div>
              

              {
                !searchConnectedCompanies &&
                !searchPendingCompanies &&
                searchNotConnectedCompanies &&
                readyToConnectIndividuals>0 
                ?
                <Flex
                style={{
                  position:"relative",
                  top:"-30px",
                  backgroundColor: 'white',
                  padding: '10px 0px'
                }}
                >
                  <DashboardSmallCTA
                width="100%"
                color="#2FD371"
                innerHeight="40px"
                fontSize="16px"
                reversed={true}
                onClick={()=>this._connectAll()}
                
                >
                  {`CONNECT WITH ALL ${readyToConnectIndividuals} INDIVIDUALS @${companyResults[0].domain}`}
                </DashboardSmallCTA>
                </Flex>
                
                
                :
                null
              }

              
            </div>
          ) :
          loading
          ?
           (
            <>
            <br/>
            <br />
            <br />
            <br />
          <LoadingSpinner
          textSize="20px"
          spinnerSize="6x"
          />
          </>
          )
        :
        filteredInviteSuggestions && filteredInviteSuggestions.length
        ?
        <Flex
        flexDirection="column"
        >
          <Flex
          flexDirection="column"
          mt="70px"
          mb='30px'
          style={{paddingBottom:'10px', borderBottom: '1px solid #707070'}}
          >
            <NunitoSans
            fontSize="18px"
            textAlign="center"
            color="#A3A99E"
            >
              No Results Found
            </NunitoSans>

          </Flex>

                  <Flex
                  flexDirection="column"
                  >
                    <Flex
                    mb='20px'
                    >
                      <NunitoSansBold
                      fontSize="14px"
                      color="#000000"
                      style={{marginRight: '20px'}}
                      >
                        RECOMMENDED INVITES
                      </NunitoSansBold>

                      <NunitoSansBold
                      fontSize="14px"
                      color="#2FD371"
                      style={{textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={()=>redirect(`/dashboard/company/${getUser().company_id}/invitesuggestions`)}
                      >
                        VIEW ALL
                      </NunitoSansBold>
                    </Flex>

                    <Flex
                    className="suggestion-box-wrapper singleRow"
                   
                    >
                      {filteredInviteSuggestions.slice(0,6).map((suggestion, index)=><InviteSuggestionBox key={index} suggestion={suggestion} sendInvite={this._handleInvite} handleDismiss={this._handleDismiss} index={index} />)}
                    </Flex>

                  </Flex>

        </Flex>
        :
        <NunitoSansSemiBold
        fontSize='20px'
        color="#c3c3c3"
        textAlign='center'
        style={{marginTop:'60px'}}
        >
          NO RESULTS FOUND
        </NunitoSansSemiBold>
        }
          {browser.lessThan.large ? (
            <Flex pt="100px" pb="40px" flexDirection="column">
              <InviteVendorCTA
                validate={this.validate}
                onSubmit={this._onSubmit}
              />
            </Flex>
          ) : null}
        </Flex>
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    browser: state.browser,
    searchNotConnectedCompanies: state.search.searchNotConnectedCompanies,
    searchConnectedCompanies: state.search.searchConnectedCompanies,
    searchPendingCompanies: state.search.searchPendingCompanies,
    company: state.resources.detail.company,
    // companyResults: state.search.companySearchResults || [],
    companyResults: processCompanyResults(state.search.companySearchResults, state.resources.collection.connection) || [],
    connections: state.resources.collection.connection || [],
    groups: state.resources.collection.group || [],
    notificationCounts: state.resources.collection.notificationcount || {},
    connectionSuggestions: state.resources.collection.connectionsuggestion || [],
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchGroups: bindActionCreators(actions.fetchGroups, dispatch),
    searchVendors: bindActionCreators(searchVendors, dispatch),
    searchNone: function(){},
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    searchNCC: bindActionCreators(searchNotConnectedCompanies, dispatch),
    searchCC: bindActionCreators(searchConnectedCompanies, dispatch),
    searchPC: bindActionCreators(searchPendingCompanies, dispatch),
    toggleCompany: bindActionCreators(toggleCompany, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    fetchInviteSuggestions: bindActionCreators(actions.fetchInvitesuggestions, dispatch),
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
  })
)(ManageVendors);

function processCompanyResults(rawResults=[], connections=[]){

  

  let processedCompanyResults = [...rawResults];
    processedCompanyResults.forEach(vendor=>{
      const {status=null, declinedFor=null}=connectionStatus(vendor, connections);

      vendor.connectionStatus = status;

      if(declinedFor && declinedFor <= 90){
        vendor.declinedFor = declinedFor;
      }
    });
    processedCompanyResults = processedCompanyResults.filter(vendor=>{
      if(typeof vendor.status ==="undefined" || (vendor.status==='searchNotConnectedCompanies' && vendor.connectionStatus===null) || (vendor.status==='searchPendingCompanies' && vendor.connectionStatus!==null )) return true;

      return false;
    });

    return processedCompanyResults;
  
}

function connectionStatus (vendor, connections){

  let incomingRequest = _.findWhere(connections, {
    vendor_id: getUser().company_id,
    company_id: vendor.id
  });
  let outgoingRequest = _.findWhere(connections, {
    company_id: getUser().company_id,
    vendor_id: vendor.id
  });

  
  if (outgoingRequest && outgoingRequest.status!=='declined' && outgoingRequest.vendor_id === vendor.id) {
    return {
      status: outgoingRequest.status
    };
  }

  const incomingStatuses = {
    requested: "actionRequired",
    active: "active",
    removed: "removed",
    declined: "declined"
  };

  if (incomingRequest && incomingRequest.company_id === vendor.id) {
    let result = {};
    result.status = incomingStatuses[incomingRequest.status];
    if(incomingRequest.status==='declined'){
      result.declinedFor = 90 - moment().diff(incomingRequest.updated_at, 'days');

      if(result.declinedFor <= 0){
        result.status = null;
      }
    }
    return result;
  }

  return {};
};