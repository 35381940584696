import React, { Component } from "react";
import { ClickCursorFlex, IconBox } from "../common/flexbox";
import { history } from "../../store";
import { HashLink as Link } from 'react-router-hash-link';

export class BackButton extends Component {
  goBack= ()=> {
    let query = decodeURI(window.location.href.split("?")[1]);
    if((this.props.profile || this.props.company) && query !=='search' && query !=='coworkers' && query !=='companies' ){
      history.push("/dashboard");
    }else if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/dashboard");
    }
  }

  

  render() {
    let query = decodeURI(window.location.href.split("?")[1]);
    return this.props.postId && query !== 'search' ? (
      <ClickCursorFlex>
        <Link to={`/dashboard#${this.props.postId}`}>

        <IconBox
          color="#606060"
          mt={["2px"]}
          fontSize={["20px", "20px", "24px"]}
        >
          leftcarret
        </IconBox>
        </Link>
      </ClickCursorFlex>
      
    ): (
<ClickCursorFlex onClick={this.goBack}>
        <IconBox
          color="#606060"
          mt={["2px"]}
          fontSize={["20px", "20px", "24px"]}
        >
          leftcarret
        </IconBox>
      </ClickCursorFlex>
    );
  }
}
