import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { OnboardingCTA, BackButton } from "../common/forms";
import { Flex, Box, IconBox } from "../common/flexbox";
import { H1, P,  NunitoSans, NunitoSansBold, NunitoSansExtraBold } from "../common/typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
//utils
import { getUser } from "../../utils/auth";

let AccountType = props => {
  const { handleSubmit,submit, error, pristine, reset, submitting, back, redirect, browser } = props;

  const [accountType, setAccountType] = useState('individual');
  const [accountHelpOpen, setAccountHelpOpen] = useState(false);

  useEffect(() => {
    if(!getUser() || getUser().is_single_user_company || getUser().company_id) return redirect('/signup');
  });

  const handleNext = (type)=>{
      if (!type) return;

      return redirect('/'+type)
  }

  return (
    <Flex width={[1, 1, 1]} alignItems="center" flexDirection="column">
      <H1 alignSelf={"flex-start"}>You're in!</H1>
      <P alignSelf={"flex-start"}>
        Looks like you’re the first one from your company domain! Which account
        type would you like to set up?
      </P>

      <Flex
      alignItems='center'
      alignSelf="flex-start"
      >
        <NunitoSansBold
        fontSize='16px'
        >
        Need help deciding?
        </NunitoSansBold>
        <FontAwesomeIcon
        icon={accountHelpOpen ? faArrowAltCircleDown : faArrowAltCircleRight}
        // size='lg'
        color='#2FD371'
        style={{marginLeft:'10px', fontSize:'16px', cursor: 'pointer'}}
        onClick={()=>setAccountHelpOpen(!accountHelpOpen)}
        />
        
      </Flex>

      {
        accountHelpOpen &&
        <Flex
        flexDirection="column"
        alignSelf='flex-start'
        mt="10px"
        mb="10px"
        >
          
          <NunitoSansBold
          fontSize="14px"
          >
            Personal Includes:
            <li>View Posts</li>
            <li>Advanced Search</li>
            <li>Unlimited Connections</li>
            <li>Personal Profile</li>
          </NunitoSansBold>
          <br/>
          <NunitoSansBold
          fontSize="14px"
          >
            Company Includes:
            <li>All personal features, plus:</li>
            <li>Company Profile</li>
            <li>Manage Team</li>
            <li>Create Departments</li>
          </NunitoSansBold>

          
          

        </Flex>
      }

      <Flex
        w={1}
        mt={["7px", "28px", "28px", "28px"]}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          w={[1, 1, 1, 1]}
          alignContent="center"
          justifyContent="space-between"
          flexDirection={["column", "column", "row", "row"]}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["auto", "auto", "40%", "40%"]}
            style={{
              border: `1px solid ${
                accountType === "individual" ? "#2FD371" : "#A3A99E"
              }`,
              borderRadius: "15px",
              cursor: "pointer",
              padding: browser.lessThan.large ? "16px 16px" : "33px 33px",
            }}
            onClick={() => setAccountType("individual")}
          >
            <IconBox
              className="icon"
              fontSize={[30, 30, 54, 54]}
              color={accountType === "individual" ? "#2FD371" : "#A3A99E"}
            >
              my-profile
            </IconBox>

            <NunitoSansExtraBold
              fontSize={["20px", "20px", "25px", "25px"]}
              color={accountType === "individual" ? "#2FD371" : "#A3A99E"}
              style={{ margintTop: "18px" }}
            >
              PERSONAL
            </NunitoSansExtraBold>

            <NunitoSans
              fontSize={["16px", "16px", "20px", "20px"]}
              color="#000000"
              style={{ margintTop: "31px" }}
              textAlign="center"
            >
              For people who want to build a personal partner network.
            </NunitoSans>
          </Flex>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["auto", "auto", "40%", "40%"]}
            mt={["10px", "10px", 0, 0]}
            style={{
              border: `1px solid ${
                accountType === "company" ? "#2FD371" : "#A3A99E"
              }`,
              borderRadius: "15px",
              cursor: "pointer",
              padding: browser.lessThan.large ? "16px 16px" : "33px 33px",
            }}
            onClick={() => setAccountType("company")}
          >
            <IconBox
              className="icon"
              fontSize={[30, 30, 54, 54]}
              color={accountType === "company" ? "#2FD371" : "#A3A99E"}
            >
              my-company
            </IconBox>

            <NunitoSansExtraBold
              fontSize={["20px", "20px", "25px", "25px"]}
              color={accountType === "company" ? "#2FD371" : "#A3A99E"}
              style={{ margintTop: "18px" }}
            >
              COMPANY
            </NunitoSansExtraBold>

            <NunitoSans
              fontSize={["16px", "16px", "20px", "20px"]}
              color="#000000"
              style={{ margintTop: "31px" }}
              textAlign="center"
            >
              For teams who want to build and establish their company’s partner
              network.
            </NunitoSans>
          </Flex>
        </Flex>
      </Flex>
      <Flex 
        justifyContent={["center", "flex-start"]} 
        alignItems="center" 
        width={[1, 1, 1, 1]}>
        <Box display={["none", "block"]} width={[1, 1, 1, 1]}>
          <BackButton link={back} />
        </Box>
        <OnboardingCTA
          my={["2vh", "2vh", "9vh", "9vh"]}
          width={[0.75, 0.75, 0.375, 0.375]}
          onClick={() => handleNext(accountType)}
        >
          NEXT
        </OnboardingCTA>
      </Flex>
      <OnboardingCTA
        display={["block", "none"]}
        my={["1vh", "1vh", "9vh", "9vh"]}
        width={[0.75, 0.75, 0.375, 0.375]}
        color="#A3A99E"
        onClick={() => redirect(back)}
      >
        BACK
      </OnboardingCTA>
    </Flex>
  );
};

AccountType = connect(
    state => ({
      browser: state.browser,
    }),
    dispatch => ({
     
      redirect: bindActionCreators(push, dispatch)
    })
  )(AccountType);

export default AccountType;