import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartMixed, faUserCheck, faCommentSmile, faFaceSmilingHands, faUserPen } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>{

    const [tabIndex, setTabIndex] = useState(0);

    const tabs = ["What is KickPost?", "Why Sign Up?", "How it works."];

    return (
        <Flex
        flexDirection="column"
        width="100%"
        style={{textAlign:"center"}}
        >
            <WhatIsKickPost/>
            {/* <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{marginBottom: "70px"}}
            >
                {tabs.map((tab, index)=>(
                    <NunitoSans
                    fontSize="16px"
                    color={tabIndex===index ? "#428BF4" : "#FFFFFF"}
                    onClick={()=>setTabIndex(index)}
                    key={index+tab}
                    style={{cursor: "pointer"}}
                    >
                        {tab}
                    </NunitoSans>
                ))}


            </Flex> */}
            {/* {
                tabIndex===0
                ?
                <WhatIsKickPost/>
                :
                tabIndex===1
                ?
                <WhySignup/>
                :
                tabIndex===2
                ?
                <HowItWorks/>
                :
                null
            } */}
        </Flex>
    );
};

function WhatIsKickPost(){
    return (
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <FontAwesomeIcon
            icon={faUserCheck}
            size="10x"
            color="#428BF4"
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    Your Profile
                </span><br />
                {` Looks like we need a few details from you.`}
            </NunitoSans>

        </Flex>
    );
}

function WhySignup(){
    return (
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <Flex
            justifyContent="space-between"
            width="100%"
            >
                <FontAwesomeIcon
                icon={faUserCheck}
                size="7x"
                color="#428BF4"
                />

                <FontAwesomeIcon
                icon={faCommentSmile}
                size="7x"
                color="#428BF4"
                />

                <FontAwesomeIcon
                icon={faChartMixed}
                size="7x"
                color="#428BF4"
                />
            </Flex>

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    We made it really, really simple for you to connect, engage & grow with your industry partners.
                </span>
                {` Brands can deliver info to their partners. Partners can access info when they need it. Brands and partners collaborate on incentives to drive sales, all in one place.`}
            </NunitoSans>

        </Flex>
    );
}

function HowItWorks(){
    return (
        <Flex
        flexDirection="column"
        width="100%"
        // alignItems="center"
        >
           <Flex
           style={{marginBottom:"30px"}}
           >
                <FontAwesomeIcon
                icon={faUserPen}
                size="2x"
                color="#428BF4"
                style={{marginRight: '10px'}}
                />
               <Flex
               flexDirection="column"
               >
                   

                   <NunitoSans
                   fontSize="24px"
                   color="#FFFFFF"
                   style={{marginBottom: "10px"}}
                   >
                        <span 
                        style={{fontWeight:900}}
                        >
                            Sign up
                        </span>
                        {` and set up your profiles.`}

                   </NunitoSans>

                   <NunitoSans
                   fontSize="21px"
                   color="#FFFFFF"
                   >
                       Get your personal and company profiles set-up in minutes and invite your team to join.
                   </NunitoSans>
               </Flex>

           </Flex>

           <Flex
           style={{marginBottom:"30px"}}
           >
                <FontAwesomeIcon
                icon={faUserCheck}
                size="2x"
                color="#428BF4"
                style={{marginRight: '10px'}}
                />
               <Flex
               flexDirection="column"
               >
                   

                   <NunitoSans
                   fontSize="24px"
                   color="#FFFFFF"
                   style={{marginBottom: "10px"}}
                   >
                        <span 
                        style={{fontWeight:900}}
                        >
                            Connect
                        </span>
                        {` to your industry partners.`}

                   </NunitoSans>

                   <NunitoSans
                   fontSize="21px"
                   color="#FFFFFF"
                   >
                       Build your network by connecting to members on the platform and invite those that aren’t.
                   </NunitoSans>
               </Flex>

           </Flex>

           <Flex>
                <FontAwesomeIcon
                icon={faFaceSmilingHands}
                size="2x"
                color="#428BF4"
                style={{marginRight: '10px'}}
                />
               <Flex
               flexDirection="column"
               >
                   

                   <NunitoSans
                   fontSize="24px"
                   color="#FFFFFF"
                   style={{marginBottom: "10px"}}
                   >
                        <span 
                        style={{fontWeight:900}}
                        >
                            Engage
                        </span>
                        {` with your network.`}

                   </NunitoSans>

                   <NunitoSans
                   fontSize="21px"
                   color="#FFFFFF"
                   >
                       Share posts and incentives with your network or receive info in a searchable newsfeed.
                   </NunitoSans>
               </Flex>

           </Flex>

        </Flex>
    );
}