import React from "react";
import { Field, reduxForm } from "redux-form";
import { Eyebrow, renderFieldPlan, renderField, renderSmallFieldSelect, renderSmallField, renderCondensedField } from "../../../common/forms";
// import currencies from "../currencies";
import { Flex } from "reflexbox";
import { AsYouType } from "libphonenumber-js";

const validate = values => {
  const errors = {};
  console.log(values)

  if (!values.address1) {
    errors.address1 = "Address required";
  }

  if (!values.city) {
    errors.city = "City required";
  }

  if (!values.state) {
    errors.state = "State required";
  }

  if (!values.zipcode) {
    errors.zipcode = "ZIP Code required";
  }

  if (!values.phone_number) {
    errors.phone_number = "Phone number required";
  }

  if (!values.billing_contact) {
    errors.billing_contact = "Billing contact required";
  }

  return errors;
};


let BillingInfo = props => {
  const { company, handleSubmit, description, updateDescription } = props;
  // console.log(company)

const formatter = new AsYouType("US");
const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }

  formatter.reset();
  const valueOnlyNums = value.replace(/[^\d]/g, "");
  if (
    previousValue &&
    value.length < previousValue.length &&
    value.length <= 5
  ) {
    // doing value length === 5 so we only apply this rule on (415) cases. may need to be changed for full
    //// compatibility .
    // typing backward, need to make sure we are actually deleting numbers when going backwards.
    const previousValueOnlyNums = previousValue.replace(/[^\d]/g, "");
    if (previousValueOnlyNums.length === valueOnlyNums.length) {
      let oneLessNumber = valueOnlyNums.slice(0, valueOnlyNums.length - 1);
      return formatter.input(oneLessNumber);
    }
  }
  return formatter.input(value);
};

return (<form
  key={"020-rewards-form"}
  onSubmit={handleSubmit} 
  id="rewards-form">
<Field
        key={[`billing_contact_name` ]}
        label="BILLING CONTACT"
        name="billing_contact"
        company={company.billing_contact}
        placeholder="e.g. Jason Smith"
        component={renderSmallFieldSelect}
        fontSize="18px"
        // component="select"
        // type="text"
      >
        {props.company.users.filter(user => user.company_admin).map(user=> 
        <option 
        value={`${user.first_name} ${user.last_name}`}     
        key={[`${user.first_name} ${user.last_name}`]}
        >
        {`${user.first_name} ${user.last_name}`}
        </option>)}
      </Field>
      <Field
        key={["billing_address1"]}
        label="Address Line 1"
        name="address1"
        company={company.address1}        
        placeholder="Address line 1"
        component={renderSmallField}
        type="text"
        fontSize="18px"
      />
      <Field
        key={["billing_address2"]}
        label="Address Line 2"
        name="address2"
        company={company.address2}        
        placeholder="Address line 2"
        component={renderSmallField}
        type="text"
        fontSize="18px"
      />
      <Field
        key={["billing_city"]}
        label="City"
        name="city"
        company={company.city}        
        placeholder="City"
        component={renderSmallField}
        type="text"
        fontSize="18px"
      />
      <Flex
      // justifyContent="space-between"
      // style={{width: "100%"}}
      >
      <Field
        key={["billing_state"]}
        label="State"
        name="state"
        company={company.state}        
        placeholder="State"
        component={renderSmallField}
        type="text"
        width="90%"
        fontSize="18px"
        />
{/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      */}
      <Field
        key={["billing_zipcode"]}
        label="Zip Code"
        name="zipcode"
        company={company.zipcode}        
        placeholder="ZIP Code"
        component={renderSmallField}
        type="text"
        width="90%"
        fontSize="18px"
      />
     
      </Flex>
      <Flex
        key={["nofieldkey"]}
        // justifyContent="space-between"
        // style={{width: "100%"}}
      >
      <Field
        key={["billing_phone_number"]}
        label="Phone Number"
        name="phone_number"
        company={company.phone_number}        
        placeholder="(444) 444-4444"
        component={renderSmallField}
        type="text"
        width="90%"
        fontSize="18px"
        normalize={normalizePhone}
        />

     {
       props.showPO
       ?
       <Field
         key={["billing_po_number"]}
         label="PO Number"
         name="po_number"
         company={company.po_number}        
         placeholder="Optional"
         component={renderSmallField}
         type="text"
         width="90%"
         fontSize="18px"
       />
       :
       null
     }
     
      </Flex>
      
     
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  form: "billing-info",
  validate
})(BillingInfo);
