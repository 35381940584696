import React from "react";
import { reduxForm} from 'redux-form';
// import {Field, reduxForm} from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import { OnboardingCTA, BackButton} from '../../common/forms';
import { Flex, Box } from "../../common/flexbox";
import { Bubble } from "../../common/ui";
import { H1, H2, H4, P, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
// import { H1, H2, H4, P, NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
import { SquareImageTreatment, ImageTreatment } from "../../common/ui";
import companyLogoUploadImage from "../../../images/company-logo-upload.jpg";

import {defaultCompany} from "../../../utils/defaultLogos.js";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const validate = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }

  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }

  return errors;
};

const CompanyNameBubble = props => {
  let { company } = props;  
  if((company.domain==="" || company.domain===undefined || company.domain===null)&&(localStorage.getItem("LeDomain")!=="")){
    company.domain = localStorage.getItem("LeDomain");
  }

  return (
    <Flex
      w={[1, 1, 1, 1]}
      alignContent="center"
      justifyContent="space-between"
      flexDirection={["column", "column", "row", "row"]}
    >
      <Bubble bg={"inputBackgroundGray"}>
        <Flex
          flexDirection={["column", "column", "row", "row"]}
          justifyContent="flex-start"
          alignItems="center"
          pt={["24px", "24px", "36px", "36px"]}
          pb={["24px", "24px", "39px", "39px"]}
        >
          <Box
            mb={[39, 39, 0, 0]}
            ml={[0, 0, 49, 49]}
            width={[103, 130, 130, 130]}
          >
            <SquareImageTreatment
              img={company.logo || companyLogoUploadImage}
              widthSmall={83}
              widthLarge={110}
            />
            
          </Box>
          <Flex
            alignItems={["center", "center", "flex-start", "flex-start"]}
            justifyContent={["center", "center", "flex-start", "flex-start"]}
            flexDirection="column"
            flex="1 1 auto"
            width={[1, 1, 0.605, 0.655]}
            mx={["0px", "0px", "50px", "50px"]}
          >
            <H2>{company.name}</H2>
            <H4 color="darkText"><a rel="noopener noreferrer" href={`http://${company.domain}`} target='_blank'>{company.domain}</a></H4>
          </Flex>
        </Flex>
      </Bubble>
    </Flex>
  );
};

let ConfirmCompany = props => {
  let { company, redirect, handleSubmit, singleUserAccount } = props;
  if((company.name==="" || company.name===undefined || company.name===null)&&(localStorage.getItem("LeCompany")!=="")){
    company.name = JSON.parse(localStorage.getItem("LeCompany")).name ;
  }

  return (
    <Flex width={[1, 1, 1]} alignItems="center" flexDirection="column">
      <H1 alignSelf={"flex-start"}>Your Company</H1>
      <P alignSelf={"flex-start"}>
        {singleUserAccount
          ? "You already have coworkers on KickPost, so you’ll be creating a personal account."
          : "Awesome, looks like your company already has members!"}
      </P>
      <form
        onSubmit={handleSubmit}
        id="companyConfirm"
        style={{ width: "100%" }}
      >
        <Flex
          width={[1, 1, 1, 1]}
          mt={["7px", "7px", "28px", "28px"]}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex
            w={[1, 1, 1, 1]}
            alignContent="center"
            // justifyContent="space-between"
            flexDirection="column"
          >
            {/* <Box w={0.9}>
            <Field
              label={singleUserAccount ? "Company Display Name" : "Company Name"}
              name="name"
              component={renderField}
              type="text"
              bg='white'
              props={{ disabled: true }}
            />

            
          </Box> */}
            {singleUserAccount ? (
              <Flex 
                width={[1, 1, 1, 1]}
                flexDirection="column"
              >
                <NunitoSansBold
                  fontSize="17px"
                  color="#000000"
                  // style={{ borderBottom: "1px solid #000000" }}
                  style={{textDecoration: 'underline'}}
                >
                  COMPANY NAME
                </NunitoSansBold>

                <Flex
                alignItems="center"
                >
                  <NunitoSansSemiBold
                    fontSize="39px"
                    color="#000000"
                    style={{ marginTop: "10px" }}
                  >
                    {company.name}
                  </NunitoSansSemiBold>

                  {
                  singleUserAccount
                  ? 
                  <FontAwesomeIcon
                  icon={faCircleCheck}
                  size="lg"
                  color="#2FD371"
                  style={{marginLeft: '10px'}}
                  />
                  :
                  null
                  }
                </Flex>
              </Flex>
            ) : (
              <Flex alignItems="center">
                <ImageTreatment
                  img={company.logo || defaultCompany(company.id, company.name)}
                  blurRadius={"0px"}
                  imageInset={0}
                  borderRadius={10}
                  innerBorderRadius={0}
                  // shadowOffset={0.1175}
                  padding={9}
                  height={97}
                  width={97}
                  blurOpacity={0.4}
                />
                <NunitoSansSemiBold
                  fontSize="39px"
                  color="#000000"
                  style={{ marginLeft: "30px" }}
                >
                  {company.name}
                </NunitoSansSemiBold>
              </Flex>
            )}

            {/* {
            singleUserAccount
            ?
            <Box>
            <NunitoSans
            color="#000000"
            fontSize="14px"
            mt='-20px'
            >
              Contact Customer Support if this Company Display Name is Incorrect, or if you would like to change it.
            </NunitoSans>
            </Box>
            :
            null
          } */}

            {/* <Box >
          <ImageTreatment
              img={company.logo || defaultCompany}
              blurRadius={"12px"}
              imageInset={0}
              borderRadius={10}
              innerBorderRadius={0}
              shadowOffset={0.1175}
              padding={5}
              height={60}
              width={60}
              blurOpacity={0.4}
            />
            </Box> */}
          </Flex>
          {/* <Flex
            w={[1, 1, 1, 1]}
            alignContent="center"
            justifyContent="space-between"
            flexDirection={["column", "column", "row", "row"]}
          >
            <Box w={[1, 1, 0.4823, 0.4823]}>
              <Field
                label="First name"
                name="first_name"
                component={renderField}
                type="text"
              />
            </Box>
            <Box w={[1, 1, 0.4823, 0.4823]}>
              <Field
                label="Last name"
                name="last_name"
                component={renderField}
                type="text"
              />
            </Box>
          </Flex> */}
          <Flex
            justifyContent={["center", "flex-start"]}
            alignItems="center"
            width={[1, 1, 1, 1]}            
          >
            <Box display={["none", "block"]} width={0.333}>
              <BackButton link={"/signup"} />
            </Box>
            <OnboardingCTA
              my={["2vh", "2vh", "9vh", "9vh"]}
              width={[0.66, 0.66, 0.375, 0.375]}
              type="submit"
            >
              CONTINUE
            </OnboardingCTA>
          </Flex>
          <OnboardingCTA
            display={["block", "none"]}
            my={["1vh", "1vh", "9vh", "9vh"]}
            width={[0.75, 0.75, 0.375, 0.375]}
            color="#A3A99E"
            onClick={() => redirect("/signup")}
          >
            BACK
          </OnboardingCTA>
        </Flex>
      </form>
    </Flex>
  );
};

export default reduxForm({
  form: "companyConfirm",
  validate,
  enableReinitialize : true,
  keepDirtyOnReinitialize : true
})(ConfirmCompany);
