import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faCloudCheck} from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <Flex
            justifyContent="space-evenly"
            width="100%"
            >
                <FontAwesomeIcon
                icon={faCloudCheck}
                size="8x"
                color="#428BF4"
                />
            </Flex>

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px",textAlign:"center"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    Your Company Profile
                </span><br />
                {` Make sure your industry partners can find you.`}
            </NunitoSans>

        </Flex>
);