import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, reset, getFormValues } from "redux-form";
import _ from "underscore";
// import parse from "url-parse";
import moment from "moment";
import axios from "axios";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { CSVLink, CSVDownload } from "react-csv";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { uniq } from "lodash";

//components
import {
  NunitoSans,
  NunitoSansExtraBold,
  H1Condensed,
  NunitoSansBold,
} from "../../common/typography";
import { ClickCursorFlex, IconBox } from "../../common/flexbox";
// import DanteComponent from "../../common/dante";
import DraftComponent from "../../common/reactDraft";
import { AudienceMultiRowGroup } from "../../common/rows";
import { Flex, Box } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA } from "../../common/forms";
import ManageVendors from "../vendors/search";
import { BackButton } from "../../common/backbutton";
import { UnderlineLink } from "../../common/navigation";
import QuestionTooltip from "../../common/questionTooltip";
import { CompressedBody, SquareImageTreatmentLarge } from "../../common/post";
import { OneSixDash, HashImage, ImageTreatment } from "../../common/ui";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
import currencies from "../../../constants/currency";

import {
  setIncentiveHovered,
  setIncentiveSelected,
  setIncentiveDetailType,
} from "../../../actions/incentives";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers,
  searchUsers,
} from "../../../actions/search";
import { set } from "react-ga";

import { defaultProfile, defaultCompany } from "../../../utils/defaultLogos";

// incentive forms
import QuickPay from "./types/quickpay";
import OneToOne from "./types/onetoone";
import Tier from "./types/tier";
import Increment from "./types/increment";
import Volume from "./types/volume";
import { get } from "styled-system";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

let viewTimer = null;

export class IncentiveDetail extends Component {
  state = {
    detailsSelected: true,
    posting: false,
    audienceSearchInput: null,
    clicked: false,
    errors: {},
    files: [],
    videos: [],
    startDate: moment(),
    startDateError: null,
    endDate: moment().add(7, "days"),
    endDateError: null,
    postBody: null,
    videoAdded: false,
    tiers: null,
    rules: null,
    tableData: null,
    type: null,
    theUser: null,
    theCompany: null,
    sell_goal: null,
    earn_amount: null,
    title: null,
    content: null,
    rewards: null,
    sales: null,
    sell_unit: null,
    units_in: null,
    all_connections: null,
    filteredAudience: null,
    modalOpen: false,
    incentiveId: null,
    status: null,
    key: 0,
    hidePartNumbers: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._fetchDependencies();

    viewTimer = setTimeout(this.sendViewRecord, 5000);
  }

  _incentive = async (fetchIncentive, id) => {
    return await fetchIncentive(id);
  };

  _fetchDependencies = async () => {
    const {
      fetchCompany,
      fetchIncentive,
      fetchRewards,
      fetchConnections,
      fetchSales,
      fetchUser,
      match,
      user,
      searchCompanies,
      searchDepartments,
      searchConnectionUsers,
      companySettings,
    } = this.props;

    fetchCompany(getUser().company_id, {
      edit: true,
    });

    if (!user) {
      fetchUser(getUser().id);
    }

    if (
      !(
        companySettings.receive_incentives ||
        companySettings.create_incentives ||
        companySettings.receive_vendor_incentives ||
        companySettings.create_vendor_incentives
      )
    ) {
      return history.push("/dashboard");
    }

    // console.log("src/components/dashboard/incentives/details.js (match.params.id) ", match.params);

    // console.log("src/components/dashboard/incentives/details.js this.props", this.props);
    if (match.params.id) {
      // window.alert(match.params.id)
      await fetchIncentive(match.params.id)
        .then((response) => {
          let { incentive } = this.props;
          // console.log("src/components/dashboard/incentives/details.js incentive", incentive);
          // console.log("src/components/dashboard/incentives/details.js this.props", this.props);
          // console.log("src/components/dashboard/incentives/details.js response", response);

          if (
            incentive === undefined ||
            incentive === null ||
            (incentive && incentive.id) !==
              (match && match.params && match.params.id)
          ) {
            fetchIncentive(match.params.id).then((response) => {
              let { incentive } = this.props;
              // console.log("src/components/dashboard/incentives/details.jsx incentive", incentive);
              // console.log("src/components/dashboard/incentives/details.jsx this.props", this.props);
              setIncentiveValues(incentive, this.props, this);
            });
          }

          if (
            (incentive && incentive.id) !==
            (match && match.params && match.params.id)
          ) {
            return null;
          } else {
            if (incentive) {
              setIncentiveValues(incentive, this.props, this);
            }
          }

          function setIncentiveValues(incentive, props, localthis) {
            if (
              incentive &&
              !incentive.is_partner_incentive &&
              companySettings &&
              companySettings.rep_fee &&
              parseFloat(companySettings.rep_fee) > 0
            ) {
              incentive.rules.sales_rep_fee = {
                name: "SALES REP FEE",
                inputBox: true,
                lastRule: false,
                selected: true,
                inputType: "number",
                inputValue: companySettings.rep_fee,
                placeholder: "Enter Qty.",
                tooltip: true,
              };
            }

            props.setIncentiveDetailType(incentive.type);
            localthis.setState({
              endDate: moment(incentive.end_date),
              postBody: incentive.content,
              startDate: moment(incentive.start_date),
              title: incentive.title,
              rules: incentive.rules,
              tiers: incentive.tiers,
              tableData: incentive.tableData,
              type: incentive.type,
              theUser: incentive.user,
              theCompany: incentive.company,
              sell_goal: incentive.sell_goal,
              earn_amount: incentive.earn_amount,
              content: incentive.content,
              sell_unit: incentive.sell_unit,
              units_in: incentive.units_in,
              all_connections: incentive.all_connections,
              incentiveId: incentive.incentive_id,
              key: incentive.incentive_id,
              status: incentive.status,
              is_partner_incentive: incentive.is_partner_incentive,
              hidePartNumbers:
                incentive.rules &&
                incentive.rules.sales_selection &&
                incentive.rules.sales_selection.selected &&
                incentive.rules.sales_selection.inputValue === "all_sales",
            });

            if (
              incentive.all_connections === "yes" &&
              (getUser().company_id === incentive.company.id ||
                getUser().company_id ===
                  process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) &&
              getUser().company_admin
            ) {
              // fetchConnections({ company_id: incentive.company.id, status: 'active', from:"newPost" });
              searchCompanies("", true, true, incentive.company.id, [], true);
            }

            if (
              incentive.all_connections === "no" &&
              (getUser().company_id === incentive.company.id ||
                getUser().company_id ===
                  process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) &&
              getUser().company_admin
            ) {
              // fetchConnections({ company_id: incentive.company.id, status: 'active', from:"newPost" });

              if (incentive.company_ids.length) {
                searchCompanies(
                  "",
                  true,
                  true,
                  incentive.company.id,
                  incentive.company_ids
                );
              }

              if (incentive.department_ids.length) {
                searchDepartments("", true, true, incentive.department_ids);
              }

              if (incentive.user_ids.length) {
                searchConnectionUsers(
                  "",
                  true,
                  true,
                  incentive.company.id,
                  incentive.user_ids
                );
              }
            }

            //GET USER SPECIFIED SALES DATA
            fetchSales({
              incentive_id: incentive.id,
              user_id: getUser().id,
            }).then((res) => {
              let { sales } = props;

              // console.log("src/components/dashboard/incentives/details.js resres", res);
              // console.log("src/components/dashboard/incentives/details.js sales", sales);
              localthis.setState({
                sales: sales,
              });

              // console.log("src/components/dashboard/incentives/details.js sales", sales);
            });

            // GET USER SPECIFIED REWARDS DATA

            let rewardsPayload =
              getUser().company_admin &&
              (getUser().company_id ===
                process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID ||
                getUser().company_id === incentive.company_id)
                ? {
                    sales_incentive_id: match.params.id,
                  }
                : getUser().company_admin
                ? {
                    sales_incentive_id: match.params.id,
                    vendor_company_id: getUser().company_id,
                  }
                : {
                    sales_incentive_id: match.params.id,
                    user_id: getUser().id,
                  };

            fetchRewards(rewardsPayload).then((res) => {
              // let rewards = [...res.payload];
              let { rewards } = props;
              // console.log("src/components/dashboard/incentives/details.js rewards", rewards);

              rewards.forEach((reward) => {
                reward.value_type =
                  reward.partner_fee > 0
                    ? "Partner Fee"
                    : reward.sales_rep_fee > 0
                    ? "Sales Rep Fee"
                    : "Reward";
              });
              localthis.setState({
                rewards,
              });

              // console.log("src/components/dashboard/incentives/details.js rewards", rewards);
            });

            // console.log("src/components/dashboard/incentives/details.js this.props", props);

            if (
              getUser().company_id ===
                process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID &&
              getUser().company_admin
            ) {
              fetchRewards({ sales_incentive_id: match.params.id }).then(
                (res) => {
                  // console.log("res",res)
                  //console.log("this.props",this.props)
                  if (res) {
                    localthis.setState({ rewards: res });
                  }
                }
              );
            } else if (
              getUser().company_id === incentive.company_id &&
              getUser().company_admin
            ) {
              fetchRewards({ sales_incentive_id: match.params.id }).then(
                (res) => {
                  console.log("res", res);
                  //console.log("this.props",this.props)
                  if (res) {
                    localthis.setState({ rewards: res });
                  }
                }
              );
            } else if (getUser().company_admin) {
              fetchRewards({
                sales_incentive_id: match.params.id,
                vendor_company_id: getUser().company_id,
              }).then((res) => {
                console.log("res", res);
                //console.log("this.props",this.props)
                if (res) {
                  localthis.setState({ rewards: res });
                }
              });
            } else {
              fetchRewards({
                sales_incentive_id: match.params.id,
                user_id: getUser().id,
              }).then((res) => {
                console.log("res", res);
                //console.log("this.props",this.props)
                if (res) {
                  localthis.setState({ rewards: res });
                }
              });
            }
          }
        })
        .catch((e) => {
          console.log("src/components/dashboard/incentives/details.js", e);
        });
    }

    //await fetchCompany(match.params.id);

    //this._fetchDependencies();
  };

  sendViewRecord = async () => {
    const viewPayload = {
      user_id: getUser().id,
      company_id: getUser().company_id,
      content_type: "incentive",
      content_id: this.props.match.params.id,
    };

    await axios.post(
      `${process.env.REACT_APP_API_URL}/viewrecord`,
      viewPayload
    );
  };

  // componentDidMount(){

  //   // this._fetchDependencies();

  // }

  componentWillUnmount() {
    this.props.setIncentiveSelected("");
    clearTimeout(viewTimer);
  }

  currencyParser = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this.props.systemSettings.currency,
    }).format(number);
  };

  _getTierText = (type, unitsIn, tier, isLastTier) => {
    const shouldUseDollarSign = type === "tier" && unitsIn !== "QTY";
    let text = "SELL ";

    if (shouldUseDollarSign) {
      text += "$";
    }

    text += numberParser(parseFloat(tier.units_from));

    if (isLastTier) {
      text += "+";
    } else {
      text += " - ";
      if (shouldUseDollarSign) {
        text += "$";
      }
      text += numberParser(parseFloat(tier.units_to));
    }

    if (!shouldUseDollarSign) {
      text += " UNITS";
    }

    return text;
    // type === 'tier' ? `SELL ${unitsIn !== "QTY" ? "$":""}${numberParser(parseFloat(tier.units_from))} - ${unitsIn !== "QTY" ? "$":""}${numberParser(parseFloat(tier.units_to))}${unitsIn === "QTY" ? " UNITS":""}` : `SELL ${numberParser(parseFloat(tier.units_from))} - ${numberParser(parseFloat(tier.units_to))} UNITS`
  };

  render() {
    const {
      company,
      loading,
      match,
      post,
      user,
      setIncentiveHovered,
      setIncentiveSelected,
      incentiveHovered,
      incentiveSelected,
      formValues,
      companies,
      companyRows,
      departmentRows,
      userRows,
      rewards,
      sales,
      systemSettings,
      companySettings,
      browser,
    } = this.props;
    // console.log(userRows, companyRows, departmentRows)
    const {
      detailsSelected,
      posting,
      clicked,
      postBody,
      errors,
      files,
      startDate,
      startDateError,
      endDate,
      endDateError,
      videoUrl,
      videos,
      audienceSearchInput,
      videoAdded,
      tiers,
      rules,
      tableData,
      type,
      title,
      theUser,
      theCompany,
      sell_goal,
      sell_unit,
      units_in,
      earn_amount,
      content,
      // rewards,
      // sales,
      all_connections,
      modalOpen,
      incentiveId,
      status,
      is_partner_incentive,
      hidePartNumbers,
    } = this.state;

    const customStyles = {
      content: {
        top: "50%",
        left: "40%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "677px",
        maxHeight: "80vh",
        zIndex: 16777271,
        overflow: "auto",
        borderRadius: "59px",
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88",
      },
    };

    // console.log(companies, "CONNN")

    if (!user) return null;
    if (
      !(
        companySettings.receive_incentives ||
        companySettings.create_incentives ||
        companySettings.receive_vendor_incentives ||
        companySettings.create_vendor_incentives
      )
    )
      return null;

    const rewardCalcVar =
      rules &&
      rules.reward_calc_param &&
      rules.reward_calc_param.selected &&
      rules.reward_calc_param.inputValue === "cost_per_unit"
        ? "cost_per_unit"
        : "unit_sales_out";

    sales &&
      sales.length &&
      sales.forEach(
        (data) =>
          (data.total_value =
            parseFloat(data.cost_per_unit) * parseFloat(data.quantity))
      );

    const tableColumns = [
      {
        Header: "MFG PART #",
        accessor: "mnfg_part_number",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        filterable: true,
        show: type !== "quickpay",
        Cell: (props) => props.value || "No product description",
      },

      {
        Header: `${currencies[systemSettings.currency].symbol} CAP PER PRODUCT`,
        accessor: "cap_per_product",
        // Cell: this.renderEditable,
        show:
          type === "onetoone" &&
          rules &&
          rules.reward_per_product_cap &&
          rules.reward_per_product_cap.selected,
      },
      {
        Header: "QTY. PER PRODUCT CAP",
        accessor: "qty_per_product",
        // Cell: this.renderEditable,
        show:
          type === "onetoone" &&
          type !== "increment" &&
          rules &&
          rules.quantity_per_product_cap &&
          rules.quantity_per_product_cap.selected,
      },
      {
        Header: `REWARD VALUE (${currencies[systemSettings.currency].symbol})`,
        accessor: "reward_value",
        Cell: (props) => this.currencyParser(props.value),
        show: type === "onetoone",
      },
    ];

    const quickpayColumns = [
      {
        Header: "REP NAME",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "COMPANY",
        accessor: "company",
        filterable: true,
      },
      {
        Header: `AND EARN (${currencies[systemSettings.currency].symbol})`,
        accessor: "reward",
      },
    ];

    const detailColumns = [
      {
        Header: "NAME",
        accessor: "rep_name",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "COMPANY",
        accessor: "vendor_company_name",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "INVOICE",
        accessor: "invoice_number",
        filterable: true,
        show: type === "onetoone",
      },
      {
        Header: "PART #",
        accessor: "part_number",
        filterable: true,
        show: type === "onetoone",
      },
      {
        Header:
          type === "increment"
            ? "UNITS"
            : rewardCalcVar === "cost_per_unit"
            ? "COGS"
            : "SALES OUT",
        accessor: "sales_out",
        filterable: true,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: (props) =>
          type === "increment" ||
          (type === "volume" && sell_unit === "units") ||
          (type === "tiers" && units_in === "QTY")
            ? numberParser(parseFloat(props.value))
            : this.currencyParser(parseFloat(props.value)),
        show: type !== "onetoone",
      },
      {
        Header: "REWARD TIER",
        accessor: "reward_tier",
        filterable: true,
        Cell: (props) =>
          props.value.sell_goal ||
          props.value.units_from ||
          props.value.earn_amount
            ? `SELL ${
                type === "volume"
                  ? sell_unit === "units"
                    ? numberParser(props.value.sell_goal)
                    : this.currencyParser(props.value.sell_goal)
                  : units_in === "$"
                  ? this.currencyParser(props.value.units_from) +
                    " - " +
                    this.currencyParser(props.value.units_to)
                  : numberParser(props.value.units_from) +
                    " - " +
                    numberParser(props.value.units_to)
              } EARN ${this.currencyParser(
                type === "volume" ? props.value.earn_amount : props.value.reward
              )} ${type === "increment" ? "PER UNIT" : ""}`
            : "NOT QUALIFIED",
        show: type !== "onetoone",
      },
      {
        Header: "QTY",
        accessor: "quantity",
        filterable: true,
        // Cell:props=> numberParser(parseFloat(props.quantity)),
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        show: type === "onetoone",
      },
      {
        Header: "TYPE",
        accessor: "reward_type",
        show: false,
      },
      {
        Header: `REWARD VALUE (${currencies[systemSettings.currency].symbol})`,
        accessor: "value",
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: (props) => (
          <span
            {...(props.original.capped
              ? {
                  "data-tip": "Reward Cap Reached, See Incentive Details",
                }
              : {})}
            style={
              props.original.capped
                ? {
                    color: "#428BF4",
                  }
                : {}
            }
          >
            {" "}
            {this.currencyParser(parseFloat(props.value))}{" "}
          </span>
        ),
      },
      // {
      //   Header: 'PROJECTED SPLIT',
      //   accessor: 'projected_split',
      //   // Cell: this.renderEditable
      // }
    ];

    const salesColumns = [
      {
        Header: "INV. DATE",
        accessor: "invoice_date",
        Cell: (row) =>
          moment(row.value, moment.ISO_8601)
            .add(1, "days")
            .format("MM/DD/YYYY"),
        filterable: true,
        sortMethod: (a, b) => Date.parse(b) - Date.parse(a),
        filterMethod: (filter, row) =>
          row[filter.id] ===
          moment(filter.value).subtract(7, "hours").toISOString(),
      },
      {
        Header: "INVOICE",
        accessor: "invoice_number",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "COMPANY",
        accessor: "company_name",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "NAME",
        accessor: "rep_name",
        filterable: true,
        // Cell: this.renderEditable
      },
      {
        Header: "PART #",
        accessor: "mnfg_pn",
        filterable: true,
        // Cell: this.renderEditable
      },
      //   {
      //     Header: 'CUSTOMER NAME',
      //     accessor: 'customer_name',
      //     filterable: true
      //     // Cell: this.renderEditable
      // },
      {
        Header: "QTY",
        accessor: "quantity",
        width: 50,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: (props) => numberParser(parseFloat(props.value)),
      },
      {
        Header: "COST PER UNIT",
        accessor: "cost_per_unit",
        width: 75,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: (props) => this.currencyParser(parseFloat(props.value)),
      },
      {
        Header: "TOTAL VALUE",
        accessor: "total_value",
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: (props) => this.currencyParser(parseFloat(props.value)),
      },
    ];

    const total_reward =
      rewards && type !== "quickpay"
        ? rewards
            .map((reward) => reward.value)
            .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)
        : 0;
    const total_value =
      sales && type !== "quickpay"
        ? sales
            .map((sale) => sale.total_value)
            .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)
        : 0;
    const total_qty =
      sales && type !== "quickpay"
        ? sales
            .map((sale) => sale.quantity)
            .reduce((a, c) => parseFloat(a) + parseFloat(c), 0)
        : 0;
    const reward_sale_percent =
      total_value > 0 ? (total_reward / total_value) * 100 : 0;
    const total_rep_qty =
      rewards && type !== "quickpay"
        ? uniq(rewards.map((reward) => reward.rep_name)).length
        : 0;

    const summary = [
      {
        name: "Total Rewards Earned",
        value: this.currencyParser(total_reward),
      },
      {
        name: "Total Sales",
        value: this.currencyParser(total_value),
      },
      {
        name: `Qualifying ${is_partner_incentive ? "Partners" : "Sales Reps"}`,
        value: numberParser(total_rep_qty),
      },
      {
        name: "Reward/Sales %",
        value: numberParser(reward_sale_percent) + " %",
      },
    ];

    if (title) {
      if (
        formValues &&
        formValues.incentivenav &&
        formValues.incentivenav === "RESULTS"
      ) {
        return (
          <Flex flexDirection="column" mt="40px" mb="20px">
            <Flex width="100%" justifyContent="space-between">
              {browser.lessThan.large ? null : (
                <UnderlineLink to={`/dashboard/profile/${theUser.id}`}>
                  <ImageTreatment
                    img={
                      theUser.avatar ||
                      defaultProfile(
                        theUser.id,
                        `${theUser.first_name} ${theUser.last_name}`
                      )
                    }
                    blurRadius={"0px"}
                    imageInset={0}
                    borderRadius={50}
                    innerBorderRadius={40}
                    shadowOffset={0}
                    padding={3}
                    height={70}
                    width={70}
                  />{" "}
                </UnderlineLink>
              )}{" "}
              <Flex flexDirection="column" style={{ width: "70%" }}>
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bolder"
                  color={is_partner_incentive ? "#D5A800" : "#428BF4"}
                >
                  {`${
                    type === "onetoone" ? "ONE TO ONE" : type.toUpperCase()
                  } ${
                    is_partner_incentive ? "PARTNER" : "SALES"
                  } INCENTIVE`}{" "}
                </NunitoSans>

                <H1Condensed fontSize="34px" fontWeight="bold" color="#000000">
                  {title}{" "}
                </H1Condensed>

                <Flex justifyContent="space-between" mt="15px">
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {/* {`By ${theUser.first_name} ${theUser.last_name} at ${theCompany.name}`} */}
                    By &nbsp;{" "}
                    <UnderlineLink
                      fontWeight={400}
                      to={`/dashboard/profile/${theUser.id}`}
                    >
                      {`${theUser.first_name} ${theUser.last_name}`}{" "}
                    </UnderlineLink>{" "}
                    &nbsp; at &nbsp;
                    <UnderlineLink to={`/dashboard/company/${theCompany.id}`}>
                      {" "}
                      {theCompany.name}{" "}
                    </UnderlineLink>{" "}
                  </NunitoSans>

                  <NunitoSans fontSize="15px" fontWeight="bold" color="#000000">
                    {`${moment(startDate).utc().format("L")} - ${moment(endDate)
                      .utc()
                      .format("L")}`}{" "}
                  </NunitoSans>
                </Flex>

                <Flex justifyContent="space-between" mt="15px">
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {`ID: ${incentiveId}`}{" "}
                  </NunitoSans>{" "}
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {`STATUS: ${capitalizeFirstLetter(status)}`}{" "}
                  </NunitoSans>{" "}
                </Flex>
              </Flex>{" "}
              <UnderlineLink to={`/dashboard/company/${theCompany.id}`}>
                <Box mr="0px">
                  <SquareImageTreatmentLarge
                    borderRadius={10}
                    size={65}
                    img={
                      theCompany.logo ||
                      defaultCompany(theCompany.id, theCompany.name)
                    }
                  />{" "}
                </Box>{" "}
              </UnderlineLink>{" "}
            </Flex>
            <NunitoSans
              fontSize="16px"
              fontWeight="bolder"
              color="#428BF4"
              width="100%"
              style={{
                borderBottom: "#D3D3D3 solid 1px",
                marginTop: "31px",
              }}
            >
              SUMMARY{" "}
            </NunitoSans>
            {summary.map((item, index) => (
              <Flex
                key={[`summary` + `${Math.random().toString()}`]}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                style={{
                  backgroundColor: index % 2 === 0 ? "#FAFAFA" : "white",
                  paddingTop: "11px",
                  paddingBottom: "11px",
                }}
              >
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bold"
                  color="#A3A99E"
                  style={{
                    paddingLeft: "35px",
                  }}
                >
                  {item.name}{" "}
                </NunitoSans>{" "}
                <Flex width="30%" justifyContent="space-between">
                  <NunitoSans
                    fontSize="16px"
                    fontWeight="bold"
                    color="#000000"
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    {item.value}{" "}
                  </NunitoSans>{" "}
                  {/* <NunitoSans
                                          fontSize="16px"
                                          fontWeight="bold"
                                          color="#000000"
                                          style={{paddingRight: "15px"}}
                                          >
                                              20%
                                          </NunitoSans> */}{" "}
                </Flex>{" "}
              </Flex>
            ))}
            <Flex
              // justifyContent="center"
              alignItems="center"
              width="100%"
              style={{
                marginTop: "40px",
                marginBottom: "15px",
              }}
            >
              <Flex
                cursor="pointer"
                width="60%"
                justifyContent="center"
                style={{
                  backgroundColor: detailsSelected ? "#428BF4" : "#EBEBE7",
                  borderRadius: "15px 15px 0px 0px",
                }}
                onClick={() =>
                  this.setState({
                    detailsSelected: true,
                  })
                }
              >
                <NunitoSans
                  fontSize="18px"
                  fontWeight="bold"
                  color={detailsSelected ? "#FFFFFF" : "#000000"}
                  style={{
                    marginTop: "12px",
                    marginBottom: "10px",
                  }}
                >
                  DETAILS{" "}
                </NunitoSans>{" "}
              </Flex>{" "}
              <Flex
                cursor="pointer"
                justifyContent="center"
                width="60%"
                style={{
                  backgroundColor: !detailsSelected ? "#428BF4" : "#EBEBE7",
                  borderRadius: "15px 15px 0px 0px",
                }}
                onClick={() =>
                  this.setState({
                    detailsSelected: false,
                  })
                }
              >
                <NunitoSans
                  fontSize="18px"
                  fontWeight="bold"
                  color={!detailsSelected ? "#FFFFFF" : "#000000"}
                  style={{
                    marginTop: "12px",
                    marginBottom: "10px",
                  }}
                >
                  SALES
                </NunitoSans>
              </Flex>
            </Flex>
            <ReactTooltip place="left" type="info" effect="solid" />
            <ReactTable
              resizable={true}
              className="-striped -highlight"
              columns={detailsSelected ? detailColumns : salesColumns}
              data={detailsSelected ? rewards : sales}
              showPagination={true}
              defaultFilterMethod={(filter, row) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              minRows={0}
              onPageChange={(pageIndex) => ReactTooltip.rebuild()}
              onPageSizeChange={(pageSize, pageIndex) => ReactTooltip.rebuild()}
              onSortedChange={(newSorted, column, shiftKey) =>
                ReactTooltip.rebuild()
              }
              onExpandedChange={(newExpanded, index, event) =>
                ReactTooltip.rebuild()
              }
              onFilteredChange={(column, value) => ReactTooltip.rebuild()}
              onResizedChange={(newResized, event) => ReactTooltip.rebuild()}
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "NunitoSans",
                color: "#6C7168",
                width: "100%",
                border: "none",
              }}
            />{" "}
            {browser.lessThan.large ? null : (
              <Flex justifyContent="center">
                <DashboardSmallCTA
                  color="#428BF4"
                  fontSize="15px"
                  style={{
                    marginTop: "15px",
                    width: "33%",
                  }}
                >
                  <CSVLink
                    filename={`${incentiveId}_${
                      detailsSelected ? "rewards" : "sales"
                    }_${new Date().toJSON().slice(0, 10)}.csv`}
                    data={detailsSelected ? rewards : sales}
                  >
                    Export Data as CSV{" "}
                  </CSVLink>{" "}
                </DashboardSmallCTA>{" "}
              </Flex>
            )}
          </Flex>
        );
      } else {
        return (
          <Flex flexDirection="column" mt="40px" mb="20px">
            <Modal
              isOpen={modalOpen}
              onRequestClose={() =>
                this.setState({
                  modalOpen: false,
                })
              }
              style={customStyles}
              contentLabel="Incentive Audience Modal"
              ariaHideApp={false}
            >
              <AudienceMultiRowGroup
                audiencesMulti={
                  all_connections === "yes"
                    ? companyRows
                    : [].concat(companyRows, departmentRows, userRows)
                }
                // selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                groupName="AUDIENCE"
                headerFontSize="35px"
                headerJustifyContent="center"
                mobile={true}
                hideFirstLastDash={true}
                // onSelect={this._selectRow}
                noSelect={true}
                onlyPeople={incentiveSelected === "quickpay"}
              />
            </Modal>
            <Flex width="100%" justifyContent="space-between">
              {browser.lessThan.large ? null : (
                <UnderlineLink to={`/dashboard/profile/${theUser.id}`}>
                  <ImageTreatment
                    img={
                      theUser.avatar ||
                      defaultProfile(
                        theUser.id,
                        `${theUser.first_name} ${theUser.last_name}`
                      )
                    }
                    blurRadius={"0px"}
                    imageInset={0}
                    borderRadius={50}
                    innerBorderRadius={40}
                    shadowOffset={0}
                    padding={3}
                    height={70}
                    width={70}
                  />
                </UnderlineLink>
              )}{" "}
              <Flex
                flexDirection="column"
                style={{
                  width: "70%",
                }}
              >
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bolder"
                  color={is_partner_incentive ? "#D5A800" : "#428BF4"}
                >
                  {`${
                    type === "onetoone" ? "ONE TO ONE" : type.toUpperCase()
                  } ${
                    is_partner_incentive ? "PARTNER" : "SALES"
                  } INCENTIVE`}{" "}
                </NunitoSans>

                <H1Condensed fontSize="34px" fontWeight="bold" color="#000000">
                  {title}{" "}
                </H1Condensed>

                <Flex justifyContent="space-between" mt="15px">
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {/* {`By ${theUser.first_name} ${theUser.last_name} at ${theCompany.name}`} */}
                    By &nbsp;{" "}
                    <UnderlineLink
                      fontWeight={400}
                      to={`/dashboard/profile/${theUser.id}`}
                    >
                      {`${theUser.first_name} ${theUser.last_name}`}{" "}
                    </UnderlineLink>{" "}
                    &nbsp; at &nbsp;
                    <UnderlineLink to={`/dashboard/company/${theCompany.id}`}>
                      {" "}
                      {theCompany.name}{" "}
                    </UnderlineLink>{" "}
                  </NunitoSans>

                  {type !== "quickpay" ? (
                    <NunitoSans
                      fontSize="15px"
                      fontWeight="bold"
                      color="#000000"
                    >
                      {`${moment(startDate).utc().format("L")} - ${moment(
                        endDate
                      )
                        .utc()
                        .format("L")}`}{" "}
                    </NunitoSans>
                  ) : null}
                </Flex>

                <Flex justifyContent="space-between" mt="15px">
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {`ID: ${incentiveId}`}{" "}
                  </NunitoSans>{" "}
                  <NunitoSans fontSize="15px" color="#A3AA9E">
                    {`STATUS: ${capitalizeFirstLetter(status)}`}{" "}
                  </NunitoSans>{" "}
                </Flex>
              </Flex>{" "}
              <UnderlineLink to={`/dashboard/company/${theCompany.id}`}>
                <Box mr="0px">
                  <SquareImageTreatmentLarge
                    borderRadius={10}
                    size={65}
                    img={
                      theCompany.logo ||
                      defaultCompany(theCompany.id, theCompany.name)
                    }
                  />{" "}
                </Box>{" "}
              </UnderlineLink>{" "}
            </Flex>
            {type !== "quickpay" &&
            Object.keys(rules).filter((rule) => rules[rule].selected).length >
              0 ? (
              <>
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bolder"
                  color="#428BF4"
                  width="100%"
                  style={{
                    borderBottom: "#D3D3D3 solid 1px",
                    marginTop: "31px",
                  }}
                >
                  RULES
                </NunitoSans>
                {Object.keys(rules)
                  .filter((rule) => rules[rule].selected)
                  .map((rule, index) => {
                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        style={{
                          backgroundColor:
                            index % 2 === 1 ? "#FAFAFA" : "transparent",
                          paddingTop: "11px",
                          paddingBottom: "11px",
                        }}
                      >
                        <Flex>
                          <NunitoSans
                            fontSize="16px"
                            fontWeight="bold"
                            color="#000000"
                            style={{
                              paddingLeft: "35px",
                            }}
                          >
                            {is_partner_incentive
                              ? rules[rule].name.replaceAll("REP", "PARTNER")
                              : rules[rule].name}{" "}
                          </NunitoSans>{" "}
                          {rules[rule].tooltip ? (
                            <QuestionTooltip tooltipText="This is a percentage based fee that goes to your company for the management of your rewards program." />
                          ) : null}{" "}
                        </Flex>{" "}
                        <NunitoSans
                          fontSize="16px"
                          fontWeight="bold"
                          color="#000000"
                          style={{
                            paddingRight: "15px",
                          }}
                        >
                          {rules[rule].inputBox
                            ? rules[rule].inputType === "number"
                              ? numberParser(
                                  parseFloat(rules[rule].inputValue),
                                  rule === "sales_rep_fee"
                                )
                              : rules[rule].inputType === "select" &&
                                rules[rule].options &&
                                rules[rule].options.length &&
                                rules[rule].options.find(
                                  (rle) => rle.value === rules[rule].inputValue
                                )
                              ? rules[rule].options.find(
                                  (rle) => rle.value === rules[rule].inputValue
                                ).label
                              : this.currencyParser(
                                  parseFloat(rules[rule].inputValue)
                                )
                            : "YES"}{" "}
                        </NunitoSans>{" "}
                      </Flex>
                    );
                  })}{" "}
              </>
            ) : type === "quickpay" ? (
              <>
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bolder"
                  color="#428BF4"
                  width="100%"
                  style={{
                    borderBottom: "#D3D3D3 solid 1px",
                    marginTop: "31px",
                  }}
                >
                  DESCRIPTION
                </NunitoSans>
                <NunitoSans
                  fontSize="16px"
                  color="#000000"
                  style={{
                    marginTop: "15px",
                  }}
                >
                  {/* <DanteComponent config={{ read_only: true }} content={content} /> */}
                  <DraftComponent readOnly={true} initialContent={content} />{" "}
                </NunitoSans>{" "}
              </>
            ) : null}
            {!browser.lessThan.large &&
            (getUser().company_id === theCompany.id ||
              getUser().company_id ===
                process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) &&
            getUser().company_admin ? (
              <>
                <NunitoSans
                  fontSize="16px"
                  fontWeight="bolder"
                  color="#428BF4"
                  width="100%"
                  style={{
                    borderBottom: "#D3D3D3 solid 1px",
                    marginTop: "31px",
                  }}
                >
                  AUDIENCE{" "}
                </NunitoSans>

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  style={{
                    paddingTop: "11px",
                    paddingBottom: "11px",
                  }}
                >
                  <NunitoSans
                    fontSize="16px"
                    fontWeight="bold"
                    color="#000000"
                    style={{
                      paddingLeft: "35px",
                    }}
                  >
                    {all_connections === "yes" ? "ALL" : "FILTERED"}{" "}
                  </NunitoSans>{" "}
                  <Flex alignItems="center">
                    <NunitoSans
                      className="audienceDetails"
                      fontSize="12px"
                      fontWeight="bold"
                      color="#A3AA9E"
                      style={{
                        marginRight: "25px",
                      }}
                      onClick={() =>
                        this.setState({
                          modalOpen: true,
                        })
                      }
                    >
                      View Details{" "}
                    </NunitoSans>

                    <NunitoSans
                      fontSize="16px"
                      fontWeight="bold"
                      color="#000000"
                      style={{
                        paddingRight: "15px",
                      }}
                    >
                      {all_connections === "yes"
                        ? `${
                            is_partner_incentive
                              ? companies.length
                              : companies.reduce(
                                  (a, b) => a + b.total_employee_count,
                                  0
                                )
                          } ${is_partner_incentive ? "PARTNERS" : "REPS"}`
                        : `${
                            is_partner_incentive
                              ? companyRows.length
                              : userRows.length +
                                companyRows.reduce(
                                  (a, b) => a + b.row.total,
                                  0
                                ) +
                                departmentRows.reduce(
                                  (a, b) => a + b.row.total,
                                  0
                                )
                          } ${is_partner_incentive ? "PARTNERS" : "REPS"}`}
                    </NunitoSans>
                  </Flex>
                </Flex>
              </>
            ) : null}
            {/* <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        style={{backgroundColor: "#428BF4", marginTop: "40px", }}
                        > */}{" "}
            <NunitoSans
              fontSize="16px"
              fontWeight="bolder"
              color="#428BF4"
              width="100%"
              style={{
                borderBottom: "#D3D3D3 solid 1px",
                marginTop: "31px",
              }}
            >
              INCENTIVE DETAIL{" "}
            </NunitoSans>{" "}
            {/* <NunitoSans
                            fontSize="18px"
                            fontWeight="bold"
                            color="#FFFFFF"
                            style={{marginTop: "12px", marginBottom: "10px"}}
                            >
                            INCENTIVE DETAIL
                            </NunitoSans> */}{" "}
            {/* </Flex> */}{" "}
            {type === "onetoone" ? (
              <ReactTable
                className="-striped -highlight"
                columns={tableColumns}
                defaultFilterMethod={(filter, row) =>
                  row[filter.id]
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                }
                data={tableData}
                showPagination={true}
                minRows={0}
                resizable={true}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "NunitoSans",
                  color: "#6C7168",
                  width: "100%",
                  border: "none",
                }}
              />
            ) : type === "tier" || type === "increment" ? (
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                style={{
                  marginTop: "35px",
                }}
              >
                <Flex
                  flexDirection="column"
                  style={{
                    width: "100%",
                  }}
                >
                  {tiers.map((tier, index) => {
                    const tierCount = index + 1;

                    return (
                      <Flex key={index + tier.units_to} flexDirection="column">
                        <NunitoSans
                          fontSize="12px"
                          fontWeight="bold"
                          color="#428BF4"
                          style={{
                            textAlign: "center",
                            width: "100%",
                            borderBottom: "#428BF4 solid 1px",
                          }}
                        >
                          {"TIER " + tierCount}
                        </NunitoSans>
                        <Flex
                          width="100%"
                          style={{
                            marginTop: "15px",
                            marginBottom: "30px",
                          }}
                        >
                          <NunitoSans
                            fontSize="12px"
                            fontWeight="bold"
                            color="#000000"
                            style={{
                              textAlign: "center",
                              width: "50%",
                            }}
                          >
                            {this._getTierText(
                              type,
                              units_in,
                              tier,
                              tierCount === tiers.length
                            )}
                          </NunitoSans>
                          <NunitoSans
                            fontSize="12px"
                            fontWeight="bold"
                            color="#000000"
                            style={{
                              textAlign: "center",
                              width: "50%",
                            }}
                          >
                            {type === "tier"
                              ? `EARN $${tier.reward}`
                              : `EARN $${tier.reward} PER UNIT`}
                          </NunitoSans>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
                {hidePartNumbers ? (
                  <Flex flexDirection="column" width="100%" mb="20px">
                    <NunitoSans
                      fontWeight="bolder"
                      color="#428BF4"
                      width="100%"
                      style={{
                        borderBottom: "#D3D3D3 solid 1px",
                        marginTop: "31px",
                      }}
                    >
                      ELIGIBLE SALES
                    </NunitoSans>

                    <NunitoSansBold
                      fontSize="16px"
                      style={{
                        alignSelf: "flex-end",
                        marginTop: "5px",
                      }}
                    >
                      {`All qualifying '${theCompany.name}' sales`}
                    </NunitoSansBold>
                  </Flex>
                ) : (
                  <ReactTable
                    className="-striped -highlight"
                    columns={tableColumns}
                    data={tableData}
                    defaultFilterMethod={(filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                    }
                    resizable={true}
                    showPagination={true}
                    minRows={0}
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "NunitoSans",
                      color: "#6C7168",
                      width: "100%",
                      border: "none",
                    }}
                  />
                )}
              </Flex>
            ) : type === "volume" ? (
              <>
                <Flex
                  style={{
                    width: "100%",
                    marginTop: "35px",
                  }}
                >
                  <Flex
                    flexDirection="column"
                    style={{
                      width: "50%",
                    }}
                  >
                    <NunitoSans
                      fontSize="12px"
                      fontWeight="bold"
                      color="#428BF4"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        borderBottom: "#428BF4 solid 1px",
                        marginBottom: "15px",
                      }}
                    >
                      SELL GOAL{" "}
                    </NunitoSans>

                    <NunitoSans
                      fontSize="12px"
                      fontWeight="bold"
                      color="#000000"
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {`SELL ${
                        sell_unit === "units"
                          ? sell_goal + " UNITS"
                          : this.currencyParser(parseFloat(sell_goal))
                      }`}{" "}
                    </NunitoSans>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    style={{
                      width: "50%",
                    }}
                  >
                    <NunitoSans
                      fontSize="12px"
                      fontWeight="bold"
                      color="#428BF4"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        borderBottom: "#428BF4 solid 1px",
                        marginBottom: "15px",
                      }}
                    >
                      AND EARN
                    </NunitoSans>

                    <NunitoSans
                      fontSize="12px"
                      fontWeight="bold"
                      color="#000000"
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {`EARN $${earn_amount}`}{" "}
                    </NunitoSans>
                  </Flex>
                </Flex>
                {hidePartNumbers ? (
                  <Flex flexDirection="column" width="100%" mb="20px">
                    <NunitoSans
                      fontWeight="bolder"
                      color="#428BF4"
                      width="100%"
                      style={{
                        borderBottom: "#D3D3D3 solid 1px",
                        marginTop: "31px",
                      }}
                    >
                      ELIGIBLE SALES
                    </NunitoSans>

                    <NunitoSansBold
                      fontSize="16px"
                      style={{
                        alignSelf: "flex-end",
                      }}
                    >
                      {`All qualifying '${theCompany.name}' sales`}
                    </NunitoSansBold>
                  </Flex>
                ) : (
                  <ReactTable
                    className="-striped -highlight"
                    columns={tableColumns}
                    data={tableData}
                    defaultFilterMethod={(filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                    }
                    showPagination={true}
                    minRows={0}
                    resizable={true}
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "NunitoSans",
                      color: "#6C7168",
                      width: "100%",
                      border: "none",
                      marginTop: "35px",
                    }}
                  />
                )}
              </>
            ) : type === "quickpay" ? (
              <>
                <ReactTable
                  className="-striped -highlight"
                  columns={quickpayColumns}
                  data={tableData}
                  defaultFilterMethod={(filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  showPagination={true}
                  minRows={0}
                  resizable={true}
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#6C7168",
                    width: "100%",
                    border: "none",
                    marginTop: "35px",
                  }}
                />
              </>
            ) : null}
            {browser.lessThan.large || hidePartNumbers ? null : (
              <Flex justifyContent="center">
                <DashboardSmallCTA
                  color="#428BF4"
                  fontSize="15px"
                  style={{
                    marginTop: "15px",
                    width: "33%",
                  }}
                >
                  <CSVLink
                    filename={`${incentiveId}_details_${new Date()
                      .toJSON()
                      .slice(0, 10)}.csv`}
                    data={tableData.map((item) =>
                      _.omit(item, ["status", "formName", "delete_btn"])
                    )}
                    headers={[
                      {
                        label: "MFG #",
                        key: "mnfg_part_number",
                      },
                      {
                        label: "Description",
                        key: "description",
                      },
                      {
                        label: "Reward",
                        key: "reward_value",
                      },
                      {
                        label: "Cap Per Product",
                        key: "cap_per_product",
                      },
                      {
                        label: "Qty Per Product",
                        key: "qty_per_product",
                      },
                    ]}
                  >
                    Export Data as CSV{" "}
                  </CSVLink>{" "}
                </DashboardSmallCTA>{" "}
              </Flex>
            )}
          </Flex>
        );
      }
    }
    return null;
  }
}

export default connect(
  (state) => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    formValues: getFormValues("incentiveNav-form")(state),
    company: state.resources.detail.company,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows || [],
    departmentRows: state.search.departmentSearchRows || [],
    userRows: state.search.userSearchRows || [],
    connections: state.resources.collection.connection || [],
    loading: state.resources.detail.loading.post,
    post: state.resources.detail.post,
    selectedUsers: state.post.selectedUsers,
    searchCompanies: state.search.searchCompanies,
    //companyResults: state.search.companySearchResults || [],
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    user: state.resources.detail.user,
    connections: state.resources.collection.connection || [],
    sales: state.resources.collection.sale || [],
    rewards: state.resources.collection.reward || [],
    incentive: state.resources.detail.incentive,
    incentiveHovered: state.incentives.incentiveHovered,
    incentiveSelected: state.incentives.incentiveSelected,
  }),
  (dispatch) => ({
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    setIncentiveDetailType: bindActionCreators(
      setIncentiveDetailType,
      dispatch
    ),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createIncentive: bindActionCreators(actions.createIncentive, dispatch),
    //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    fetchIncentive: bindActionCreators(actions.fetchIncentive, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch),
  })
)(IncentiveDetail);

class IncentiveDetailNav extends Component {
  render() {
    const { incentive, incentiveType } = this.props;
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {/* <NunitoSans pt={["6px", "6px", "8px", "6px"]} fontSize={[14, 14, 18, 18]} fontWeight={["bold"]} > INCENTIVE </NunitoSans> */}
          {/* {console.log("incentive, incentiveType")}
          {console.log(incentive)}
          {console.log(incentiveType)} */}

          {incentiveType === "quickpay" ? (
            <form id="incentiveNav-form">
              <Field
                key={["formkey_incentiveNav"]}
                name="incentivenav"
                className="dropdown"
                component="select"
                style={{
                  padding: "10px 25px",
                  border: "1px solid #428BF4",
                  borderRadius: "9px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "NunitoSans",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="INCENTIVE"
                >
                  DETAIL
                </option>
              </Field>
            </form>
          ) : (
            <form id="incentiveNav-form">
              <Field
                key={["formkey_incentiveNav"]}
                name="incentivenav"
                className="dropdown"
                component="select"
                style={{
                  padding: "10px 25px",
                  border: "1px solid #428BF4",
                  borderRadius: "9px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "NunitoSans",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="INCENTIVE"
                >
                  DETAIL
                </option>{" "}
                <option
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "NunitoSans",
                    color: "#A3A99E",
                  }}
                  value="RESULTS"
                >
                  RESULTS
                </option>
              </Field>
            </form>
          )}

          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center",
              ]}
            >
              <BackButton postId={incentive && incentive.id} />
            </Flex>
          </AbsoluteDiv>
        </Flex>
      </Flex>
    );
  }
}

IncentiveDetailNav = reduxForm({
  // destroyOnUnmount: true,
  form: "incentiveNav-form",
  // validate,
  initialValues: {
    reward_value: "",
    mnfg_part_number: "",
    description: "",
    cap_per_product: "",
    qty_per_product: "",
    incentive: "",
  },
  defaultValues: {
    reward_value: "",
    mnfg_part_number: "",
    description: "",
    cap_per_product: "",
    qty_per_product: "",
    incentive: "",
  },
  // enableReinitialize : true
})(IncentiveDetailNav);

IncentiveDetailNav = connect(
  (state) => ({
    formValues: getFormValues("incentiveNav-form")(state),
    incentiveHovered: state.incentives.incentiveHovered,
    incentiveSelected: state.incentives.incentiveSelected,
    incentiveType: state.incentives.incentiveType,
    incentive: state.resources.detail.incentive,
  }),
  (dispatch) => ({
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    //   createGroup: bindActionCreators(actions.createGroup, dispatch),
    //   fetchGroups: bindActionCreators(actions.fetchGroups, dispatch),
    //   notifySuccess: bindActionCreators(
    //     success.bind(null, "api_success"),
    //     dispatch
    //   ),
    //   notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    //   resetForm: bindActionCreators(reset, dispatch),
    //   updateGroup: bindActionCreators(actions.patchGroup, dispatch)
  })
)(IncentiveDetailNav);

export { IncentiveDetailNav };

function numberParser(number, percent_sign = false) {
  if (String(number) === "NaN") {
    return 0;
  }
  return (
    new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
    }).format(number) + (percent_sign ? " %" : "")
  );
}
