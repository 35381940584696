import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import _ from "underscore";
import {sortBy} from "lodash";
import { push } from "react-router-redux";
//utils
import { getUser } from "../../../utils/auth";
import { success, error } from "../../../actions/alerts";
//actions
import { actions } from "../../../actions/resources";
import { setDepartment, setDepartmentMembers } from "../../../actions/departments";
//components
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { CreateDepartmentCTA, EditDepartmentCTA } from "../../common/ui";

import { companyTotal, peopleTotal, selectRow, clearRows, preSelectRows } from "../../../actions/post";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionGroups,
  searchConnectionUsers,
  searchUsers
} from "../../../actions/search";
//components
import { H2, NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold,H1CondensedSmallD0D0D0 } from "../../common/typography";
import CompanyInfographic from "./audiences/companyInfographic";
import PeopleInfographic from "./audiences/peopleInfographic";

import { MegaFlexBubble, BillingCTA, OneSixDash, FourFourDottedWith8pxCornerRadius,RecommendedContentWrapper } from "../../common/ui";
import { AudienceRow } from "../../common/rows";
import { AudienceMultiRowGroup } from "../../common/rows";
import {AudienceList} from "../../common/reactWindow";
import { InputStyled, DashboardSmallCTA } from "../../common/forms";
import { AbsoluteDiv } from "../../common/absolute";


export class ManageDepartmentsAccessory extends Component {
  state = {
    audienceSearchInput: null,
    searchModeActive: true,
    coworkers: null
  }

  componentDidMount() {
    
    this._fetchDependencies();
  }

  componentWillUnmount(){
    this._removeAudience();
  }



  componentDidUpdate(prevProps, prevState) {
    const { audienceSearchInput, searchModeActive, coworkers } = this.state;
    
    if(prevProps.theDepartment !== this.props.theDepartment){
      this._removeAudience();
      this.props.setDepartmentMembers(null);
      this.setState({coworkers: null});
      
    }


    if(!prevState.coworkers && coworkers && this.props.theDepartment){
      
      //that's how we preselect members of the department
      let departmentMembers = coworkers.filter(coworker=> coworker.department_ids.includes(this.props.theDepartment.id))
     
      this.props.preSelectRows(departmentMembers, "person")
      this.props.setDepartmentMembers(departmentMembers); 
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      searchUsers,
      selectedCompanies,
      selectedDepartments,
      selectedUsers,
      selectedGroups
    } = this.props;

    if (
      selectedCompanies !== nextProps.selectedCompanies ||
      selectedDepartments !== nextProps.selectedDepartments ||
      selectedUsers !== nextProps.selectedUsers ||
      selectedGroups !== nextProps.selectedGroups
    ) {
      console.log(nextProps.selectedCompanies, nextProps.selectedGroups)
      searchUsers(
        _.pluck(nextProps.selectedCompanies, "id").concat(_.pluck(nextProps.selectedGroups, "company_id")),
        _.pluck(nextProps.selectedDepartments, "id"),
        _.pluck(nextProps.selectedUsers, "id")
      );
    }
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user, incentive, fetchIncentive, match } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }

    this._search('')

   
    fetchConnections({ company_id: getUser().company_id, status: 'active', from:"newPost" });
  };

  _search = async input => {
    const {
      searchCompanies,
      searchDepartments,
      searchGroups,
      searchConnectionUsers,
      user
    } = this.props;
    
    searchConnectionUsers(input, null, true, false, [], true);
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows, groupRows, selectedRows, theDepartment } = this.props;
   
    let rows = userRows;
    if(!this.state.audienceSearchInput && userRows && !this.state.coworkers){
      // console.log(userRows.map(item=>({'name': item.row.name, 'departments': item.department_ids})),  "COWORKERS SET");
      if(theDepartment){
        let departmentMembers = userRows.filter(coworker=> coworker.department_ids.includes(theDepartment.id));
        if(parseInt(theDepartment.total_employee_count) === departmentMembers.length){
          this.setState({coworkers: userRows})
        }else{
          this._search('')
        }
      }
      
    }
    // we sort to push selected rows to the bottom
    let rowsSelected = [];
    let rowsNotSelected = [];

   sortBy(rows, [function(item){return item.row.name}]).forEach(row=>{
      if(selectedRows.map(slrow=>slrow.id).includes(row.id)){
        rowsSelected.push(row);
      }else{
        rowsNotSelected.push(row)
      }
    })

    return [...rowsNotSelected, ...rowsSelected];
    // return rows.sort(row=>{
    //   if(selectedRows.map(slrow=>slrow.id).includes(row.id)) return 1;
    //   return -1;
    // });
  };
//selectedAudienceMultiIds

	
_removeAudience = () => {
    
  const {
    selectedRows,
    clearRows
  } = this.props;
  
  //console.log(selectedRows);
  clearRows();
  this._updateSearch("");
}

_updateSearch = input => {

   
  //console.log(AudienceMultiRowGroup);


    this.setState({ audienceSearchInput: input });
    //HELLO
    if (input.length < 2){
      if(input.length != 0){
        return;
      }    
    }
    this._search(input);
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectedGroups,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;
    
    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers,
      group: selectedGroups
    };
    console.log(itemId, itemType, companyId)
    
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };


  _createDepartment = async data => {
    const { createDepartment, fetchDepartments, resetForm, notifyError, notifySuccess } = this.props;
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active"
    }).then(departments => {
      let departmentNames = departments.payload.map(item => item.name);
      if(departmentNames.includes(data.name)){

        notifyError("A department with this name already exists.");

      }else if(!data.name){
        notifyError("Department name can not be blank.");
      }else{

      createDepartment({
          ...data,
          ...{ company_id: getUser().company_id, user_id: getUser().id }
        }).then((result)=>{
          notifySuccess("Department has been created.");

          fetchDepartments({
            company_id: getUser().company_id,
            status: "active"
          });
        });
      }
    });
    
    resetForm("create-department");
  };

  _updateDepartment = async data => {
    const {
      departments,
      theDepartment,
      fetchDepartments,
      resetForm,
      setDepartmentId,
      updateDepartment
    } = this.props;

    await updateDepartment(_.findWhere(departments, { id: theDepartment.id }).id, {
      name: data.name
    });
    setDepartmentId(null);
    resetForm("edit-department");
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active"
    });
  };
// below is accessory side when editing a department in the DEPARTMENTS page //
  render() {
    

    const {
      departments,
      theDepartment,
      setDepartmentId,
      allConnections,
      audienceSearchResults,
      companies,
      connections, 
      selectedRows,
      selectedCompanies, 
      selectedDepartments, 
      selectedGroups, 
      selectedUsers,
      company,
      user,
      incentiveHovered,
    incentiveSelected } = this.props;
    const { audienceSearchInput, searchModeActive } = this.state;
    console.log(selectedRows);
    if (!theDepartment) return null;
    return (
      <Flex justifyContent="center" flexDirection="column" width="100%">
   

        <Box mt="35px" width="100%">
          <NunitoSans textAlign="center" fontWeight={700} fontSize="18px">
            TEAM
          </NunitoSans>
        </Box>
        <Flex mt="26px" mb="40px" width="100%" flexDirection="column">
          {/* <MegaFlexBubble
            bg="white"
            borderRadius="10px"
            pl={["5px", "12px", "15px"]}
            pt={["5px", "12px", "15px"]}
            pr={["5px", "12px", "15px"]}
            pb={"0px"}
            flexDirection="column"
          > */}
            
            
             
              <Box position="relative" mt="23px">
                
                {(selectedRows.length) ? (
                  <Flex
                  maxHeight="30vh"
                  flexDirection="column"
                  // justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  style={{overflow:"auto"}}
                >
               
                  
                                          
                    <AudienceMultiRowGroup
                      audiencesMulti={selectedRows}
                      selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                      mobile={true}
                      hideHeader={true}
                      hideFirstLastDash={true}
                      onSelect={this._selectRow}
                    />
                  </Flex>
                ) : 
                null
                }
              </Box> 
            
          {/* </MegaFlexBubble> */}
        </Flex>
        
        <div>
        
        
        
          <Flex
            mt="1px"
            mb="25px"
            mx={["0px"]}
            height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <InputStyled
                placeholder="Search Coworkers"
                onChange={e => this._updateSearch(e.target.value)}
                innerRef={input => (this.searchInput = input)}
                value={audienceSearchInput || ""}
                width="100%"
                name="post-new-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                pt={["3px"]}
                px={["16px"]}
                mt={["0px"]}
                height={["46px"]}
                fontWeight={300}
                fontSize={[18]}
                lineHeight={1.3}
                color={"#979797"}
                borderBottom={[0]}
              />
              <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                <ClickCursorFlex
                  height={["100%", "100%", "100%"]}
                  alignItems={["center"]}
                  onClick={() => this._updateSearch("")}
                  justifyContent={["center"]}
                >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
            </Flex>
          </Flex>
        
          <Box 
          mb="5px"
          // style={{height:"45vh", overflow:"auto"}}
          >
            {/* <AudienceMultiRowGroup
              audiencesMulti={this._searchAudienceFilters()}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              onlyPeople={true}
            /> */}

             <AudienceList
              audiencesMulti={this._searchAudienceFilters()}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              onlyPeople={true}
              />
          </Box>
          
        
        </div>
        
      </Flex>
    );
    /*
    if (departmentId) {
      return (
        <Flex flexDirection="column">
          <Box height="76px" />
          <EditDepartmentCTA
            initialValues={{
              name: _.findWhere(departments, { id: departmentId }).name
            }}
            setDepartmentId={() => setDepartmentId(null)}
            onSubmit={this._updateDepartment}
          />
        </Flex>
      );
    }
// below is the accessory side of the DEPARTMENTS page //
    return (
      <Flex flexDirection="column">
        <Box height="76px" />
        <CreateDepartmentCTA onSubmit={this._createDepartment} />
      </Flex>
    );
    */
  }
}

export default connect(
  state => ({
    departments: state.resources.collection.department,
    theDepartment: state.departments.department,
    systemSettings: state.resources.detail.systemsetting,
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length &&
      !state.post.selectedGroups.length,
    audienceSearchResults: state.search.audienceSearchResults,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    connections: state.resources.collection.connection || [],
    departmentRows: state.search.departmentSearchRows,
    groupRows: state.search.groupSearchRows,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    user: state.resources.detail.user,
    userRows: state.search.userSearchRows,
    incentive: state.resources.detail.incentive,
    incentiveHovered: state.incentives.incentiveHovered ,
    incentiveSelected: state.incentives.incentiveSelected
  }),
  dispatch => ({
    setDepartment: bindActionCreators(setDepartment, dispatch),
    setDepartmentMembers: bindActionCreators(setDepartmentMembers, dispatch),
    createDepartment: bindActionCreators(actions.createDepartment, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updateDepartment: bindActionCreators(actions.patchDepartment, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    // setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    // setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchGroups: bindActionCreators(
      searchConnectionGroups,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    preSelectRows: bindActionCreators(preSelectRows, dispatch),
    clearRows: bindActionCreators(clearRows, dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch)
  })
)(ManageDepartmentsAccessory);
