import React, { PureComponent } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop  
} from 'react-image-crop'
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-modal";
import {
    CircleDropzoneSmall,
    CircleDropzoneLarge,
    SquareDropzoneExtraSmall,
    SquareDropzoneSmall,
    SquareDropzoneLarge
  } from "../common/ui";
import { DashboardSmallCTA } from "../common/forms";
import { Flex } from "../common/flexbox";
import { NunitoSans } from "../common/typography";

class ImageCrop extends PureComponent {

  constructor() {
    super()
    this.state = { files: [] }
  }

  // onDrop(files) {
  //   this.setState({
  //     files
  //   });
  // }

  state = {
    aspect: 8/8, 
    setAspect: 8/8,
    modalIsOpen: false,
    src: null,
    crop: {
      unit: "%",
      // aspect: this.props.aspectRect ? 8 / 2 : 8 / 8,
      aspect: 8 / 8,
      x: 0,
      y: 0,
      width: 40,
      height: 40
    }
  };

  onSelectFile = files => {
    this.setState({
      files
    });
    const SIZE_LIMIT = 20000000; // 8 MB
    if (files && files[0] && files[0].size <= SIZE_LIMIT) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        try {
          this.setState({ src: reader.result, modalIsOpen: true });
        } catch(e){
          console.log(e)
        }
      }
        // this.setState({ src: reader.result, modalIsOpen: true })}
      );
      reader.readAsDataURL(files[0]);
    }else if(files && files[0] && files[0].size > SIZE_LIMIT){
        this.props.notifyError(`Image file can not be larger than ${SIZE_LIMIT/1000000} MB.`);
    }
    else{
        this.props.notifyError('Please use an image file png or jpeg.');
    }
  };

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
centerAspectCrop = (
  mediaWidth=0,
  mediaHeight=0,
  aspect=0,
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    if(!image){
      return;
    }
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {      
    if (this.imageRef && crop.width && crop.height) {
      // console.log(this.imageRef)
      const croppedImageUrl = await this.getCroppedImg(
        document.getElementById("croppedImage"),
        crop,
        "newFile.jpeg"
      );      
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    canvas.borderRadius = "4em";
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        blob.preview = this.fileUrl;
        resolve(blob);
      }, "image/png", 1);
    });
  }

  dropzones ={
      circle_small: CircleDropzoneSmall,
      circle_large: CircleDropzoneLarge,
      square_extra_small: SquareDropzoneExtraSmall,
      square_small: SquareDropzoneSmall,
      square_large: SquareDropzoneLarge,
      default:CircleDropzoneLarge
  }

  render() {
    const {onCrop, crop, dropZoneType, enableClick, page, file, thePic} = this.props;
    const {  croppedImageUrl, src, modalIsOpen } = this.state;
    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          width: "40vw",
          maxHeight: "98vh",
          zIndex: 16777271,
          overflow: "auto"
        },
    
        overlay: {
          zIndex: 16777270,
          background: "#A3A99E88"
        }
        
      };

    const DropZone = this.dropzones[dropZoneType || 'default'] || CircleDropzoneLarge;

    return (
      <div >
        <div>
          {/* <input type="file" accept="image/*" onChange={this.onSelectFile} /> */}
          <DropZone enableClick={enableClick} onDrop={this.onSelectFile} file={file} thePic={thePic} />
        </div>


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={()=>this.setState({modalIsOpen: false})}
          style={customStyles}
          contentLabel="Image Crop Modal"
          ariaHideApp={false}
        >
          
         
            {Boolean(src) && (
            <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            >
          {/* <img src={src} width="100px" height="100px"/> */}

        <ReactCrop
          crop={this.state.crop || 
            centerCrop(
              makeAspectCrop(
                {
                  unit: '%',
                  width: 40,
                },
                8/8,
                40,
                40
              ),
              40,
              40
            )
         }
          onComplete={this.onCropComplete}
          onChange={this.onChange}
          minHeight={50}
          aspect={8/8}
          ruleOfThirds
        >
          <img
            id="croppedImage"
            alt="Crop me"
            src={src}
            style={{ transform: `scale(${1}) rotate(${0}deg)`, width: '100%', height: '100%' }}            
            onLoad={this.onImageLoaded}
          />
        </ReactCrop>
            {
              page==="company_profile"
              ?
              <NunitoSans
              textAlign="center"
              style={{marginTop:'20px'}}
              >
                Try using the same logo your company uses on its LinkedIn company page.
              </NunitoSans>
              :
              null
            }

            <DashboardSmallCTA
            onClick={()=>{
              if(croppedImageUrl!==undefined){
                onCrop(croppedImageUrl);
                this.setState({modalIsOpen: false})
              } else {
                this.props.notifyError(`Crop the image`);
              }
            }}
            style={{marginTop:'20px', width: '40%'}}
            >
                CROP
            </DashboardSmallCTA>
            </Flex>
        )}
        </Modal>
      </div>
    );
  }
}

export default ImageCrop;