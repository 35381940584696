import React from "react"
import { reduxForm } from "redux-form"
import { Flex } from "../../common/flexbox"
import { H1, P } from "../../common/typography"
import kickpostLogo from '../../../images/kickpost-logo-color.svg'


let MobileForm = props => {
  return (
    <>
      <Flex flexDirection="column" alignItems='center'>
          <div style={{
            width: '175px',
            margin: 'auto',
            paddingBottom: '40px',
            textAlign: 'center',
            position: "relative",
          }}>
            <img
              src={kickpostLogo}
              style={{ width: '175px' }}
            />
          </div>
      </Flex>
      <Flex
        alignItems="center"
        flexDirection="column"
        mt={4}
      >
        <H1 alignSelf='center'>
          Let's KickPost
        </H1>
        <P alignSelf='center'>
          Download our app below.
        </P>
        <a href="https://apps.apple.com/us/app/kickpost/id6445927282" target="_blank" className="link-block-2 w-inline-block"><img src="https://assets-global.website-files.com/64fb27fb1d7bf8f01a23b1b5/650d5249e3d3581267a2ab72_ap.svg" width='200px' loading="lazy" alt="apple icon"/></a>
        <Flex pt='10px'></Flex>
        <a href="https://play.google.com/store/apps/details?id=io.kickpost.android&amp;pcampaignid=web_share" target='_blank' className="link-block-2 w-inline-block"><img src="https://assets-global.website-files.com/64fb27fb1d7bf8f01a23b1b5/650d52499684044d55159829_gp.svg" width='200px' loading="lazy" alt="android icon"/></a>
      </Flex>
    </>
  )
}


export default reduxForm({
  form: "login"
})(MobileForm)
