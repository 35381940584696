import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { responsiveStoreEnhancer } from "redux-responsive";
import createHistory from "history/createBrowserHistory";
import logger from "redux-logger";
import ReactGA from "react-ga";
import rootReducer from "./reducers";

export const history = createHistory();

const initialState = {};
const enhancers = [responsiveStoreEnhancer];

let middleware = [thunk, routerMiddleware(history)];

if (process.env.REACT_APP_NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

if (process.env.REACT_APP_NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
  middleware.push(logger);
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

export default createStore(rootReducer, initialState, composedEnhancers);
