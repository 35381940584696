import React, { Component, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg'; // Documentation at https://jpuri.github.io/react-draft-wysiwyg/#/docs
import AWS from "aws-sdk";
import imageCompression from 'browser-image-compression';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import { getUser } from "../../utils/auth";


const content= {
    "blocks": [
      {
        "key": "di73m",
        "text": "Shopping List",
        "type": "header-two",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      }
    ],
    "entityMap": {}
  };




async function imageCallback(image){
    if(!image) return "No Image";

const errorElement = document.getElementById("editorImageError");

if(errorElement){
  errorElement.remove();
}

const imageTypes= ['image/gif','image/jpeg','image/jpg','image/png','image/svg'];
const bytesInMB = 1048576;
const sizeLimit = 10; // in MB
const sizeLimitInBytes = sizeLimit * bytesInMB;

const error = !imageTypes.includes(image.type) ? "Not an image file. Acceptable image files are gif, jpeg, jpg, png and svg" : image.size>sizeLimitInBytes ? `File size cannot be larger than ${sizeLimit} MB` : null;

if(error){
  const parent = document.querySelector(".rdw-image-modal-upload-option");

  return parent.insertAdjacentHTML('afterend', `<p id="editorImageError" class="editor-image-error">${error}</p>`);
};

  let theImage = image;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: image.type
    };
    
    if(theImage.type!=='image/gif'){

      theImage = await imageCompression(image, options);
    }
    

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });

    const s3Bucket = new AWS.S3({ 
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });

    const fileKey = `post-images/${getUser().company_id}${Math.floor(Date.now()/1000)}${getUser().id}`;

    let s3Data = {
      Key: fileKey,
      Body: theImage,
      ContentType: theImage.type,
      ACL: "public-read"
    };

    try{
      const s3Response = await s3Bucket.upload(s3Data).promise();

      if(s3Response){
        const addButton=document.querySelectorAll(".rdw-image-modal-btn")[0];

        setTimeout(()=>addButton.click(), 10);
        return {
          data:{
              link:`${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`
          }
        };
      }

    }catch(e){
      console.log(e)
    }

}


const EditorComponent = (props) => {

  const toolbar= {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'image', 'link', 'emoji', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
    fontSize: {
      options: [14, 16, 18, 20, 22],
      
    },
    link: { inDropdown: true, defaultTargetOption:"_blank" },
    list: {
      inDropdown: true,
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      inDropdown: true,
      options: ['left', 'center', 'right'],
    },
    emoji: {
      emojis: [
        "😀","😁","😂","😉","😍","😇","😗",
        "😋","😛","😜","🤗","😷","😎","🤓",
        "🤔","😣","😫","😌","😴","😈","👻",
        "😺","😸","😹","😻","😼","🙀","🖐",
        "👌","🖖","✌","🤞","🤘","👈","👉","👆",
        "👇","✊","👍","👎","👊","👏","🙌","🙏",
        "💪","👀","🍉","🎂","🍰","🍷","🍾","🍺",
        "📅","🌍","🚑","⏰","⭐","🌟","⚽","🏈",
        "🏀","💯","🎉","🎊","⛄","🔥","🎁","🔔",
        "🎈","🔇","🔈","💰","🎵","✅","❎"
      ]
     
    },
    image: {
        urlEnabled: false,
        uploadEnabled: true,
        uploadCallback: imageCallback
    }
}

if(props.pageType==="company"){
  toolbar.options = ['inline', 'list', 'link', 'history']
}

  

    const onContentStateChange = (contentState)=>{
        
        props.updateContent(contentState);
    }

    let styleEditor = {fontSize:'14px', fontFamily: "NunitoSans", paddingLeft:'5px', paddingRight:'5px', marginTop:'0px', ...props.extraStyles};

    if(!props.readOnly){
      styleEditor.minHeight = '100px';
    }

    return <Editor
    initialContentState={props.initialContent || ""}
    editorClassName="draft-editor"
    placeholder={props.placeholder || "Type something..."}
    readOnly={props.readOnly}
    toolbarHidden={props.readOnly}
    onContentStateChange={onContentStateChange}
    editorStyle={styleEditor}
    toolbar={toolbar}
    />
}

export default EditorComponent;


/*
DEFAULT TOOLBAR

{
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    bold: { icon: bold, className: undefined },
    italic: { icon: italic, className: undefined },
    underline: { icon: underline, className: undefined },
    strikethrough: { icon: strikethrough, className: undefined },
    monospace: { icon: monospace, className: undefined },
    superscript: { icon: superscript, className: undefined },
    subscript: { icon: subscript, className: undefined },
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: fontSize,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { icon: unordered, className: undefined },
    ordered: { icon: ordered, className: undefined },
    indent: { icon: indent, className: undefined },
    outdent: { icon: outdent, className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: left, className: undefined },
    center: { icon: center, className: undefined },
    right: { icon: right, className: undefined },
    justify: { icon: justify, className: undefined },
  },
  colorPicker: {
    icon: color,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    link: { icon: link, className: undefined },
    unlink: { icon: unlink, className: undefined },
    linkCallback: undefined
  },
  emoji: {
    icon: emoji,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
  embedded: {
    icon: embedded,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  image: {
    icon: image,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  remove: { icon: eraser, className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { icon: undo, className: undefined },
    redo: { icon: redo, className: undefined },
  },
}
*/