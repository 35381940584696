// import {
//   BillingCTA, PaymentEdit
// } from "../../common/ui";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit, reset } from "redux-form";
import AWS from "aws-sdk";
import moment from "moment";
import { push } from "react-router-redux";
import { history } from "../../../store";
// import Modal from "react-modal";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
import { mainContentScrollTop } from "../../../actions/dashboardPresentation";
import { error, success } from "../../../actions/alerts";
//components
import Attributes from "../profile/edit/attributes";
import BrandAttributes from "./brandattributes";
import InfoForm from "./forms/info";
// import BillingForm from "./forms/billing";

import { NunitoSans, NunitoSansBold } from "../../common/typography";
import { DashboardSmallCTA, OutlineFlex } from "../../common/forms";
import { Box, Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { LockedFeature } from "../../common/lockedfeature";
import ImageCrop from "../../common/imageCrop";
import {
  ImageTreatment,
  SquareDropzoneSmall,
  SquareDropzoneLarge,
  VendorLogoImage,
  EmployeeLogoImage,
  DepartmentLogoImage
} from "../../common/ui";
import { MapComponent } from "../../common/mapComponent";
import { BackButton } from "../../common/backbutton";
//images
import { defaultCompany } from "../../../utils/defaultLogos";
//import axios from 'axios';
import ReactTooltip from 'react-tooltip';

// import RouteLeavingGuard from "../../common/routeGuardModal";



export class EditCompanyMain extends PureComponent {
  state = {
    description: [],
    file: null,
    linkedinActivated: true,
    placeName: "San Francisco, California, 94117, US",
    address: "San Francisco",
    text: "",
    thePic: '',
    modalIsOpen: false,
    modalPage: 1
  };

  constructor(props) {
    super(props);
  }



  //signal = axios.CancelToken.source();

  componentWillUnmount() {
    //  this.signal.cancel('Api is being canceled');

  }

  componentDidMount() {
    if (window.location.href.split("?")[1] === "rewards") {
      this.setState({ modalIsOpen: true });
    }
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { fetchCompany, companySettings } = this.props;
    if ((getUser() && !getUser().company_admin) || companySettings.is_single_user_company) {
      history.goBack();
    }
    if (getUser().company_id) {
      await fetchCompany(getUser().company_id, { edit: true })
        .then(() => {
          this.setState({description: this.props.user.company.description })});
      }
  };

  onDrop = files => {
    const { notifySuccess, notifyError } = this.props;
    console.log(files)
    this.setState({ file: files[0], thePic: files[0].preview });
    let r = false;
    try {
      console.log(files[0].name);
      if (this._checkExtentsion(files[0].name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  };

  onCrop = file => {

    const { notifySuccess, notifyError } = this.props;

    this.setState({ file: file, thePic: file.preview });

    let r = false;
    try {
      // console.log(files[0].name,files[0]);
      if (this._checkExtentsion(file.name)) {
        r = true;
      }
    } catch (e) { /*bad good*/ }

    if (r === true) {
      //window.alert("good file");
      /*notifySuccess(
        "good file."
      );*/
    } else {
      //window.alert("bad file");
      notifyError(
        "This file doesn't work. It must be a png or a jpg."
      );
    }
  }

  _checkExtentsion(filename) {
    let ext = filename.split('.').pop();
    ext = ext.toString().toLowerCase();
    if (ext === "png" || ext === "jpg" || ext === "jpeg") {
      return true;
    }
    return false;
  }

  _submitInfo = async data => {
    const { company, redirect, updateCompany, notifyError } = this.props;
    const { description, file } = this.state;
    let payload = data;

    console.log("company.location",company.location)
    if (!company.location) {

      return notifyError("Please update your location.");
    }
    console.log("description",description)
    console.log("description.blocks.filter(block => block.text).length",description.blocks.filter(block => block.text).length)
    if (!description || description.blocks.filter(block => block.text).length < 1) {

      return notifyError("Please enter company description.");
    }

    if (file) {
      console.log(file)
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });


      const s3Bucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
      });
      let data_name = data.name;
      data_name = data_name.replace(/\s/g, '');
      data_name = data_name.replace(',', '');
      data_name = data_name.replace('.', '');
      const fileKey = `company-logos/${moment().valueOf()}${data_name}`;
      let s3Data = {
        Key: fileKey,
        Body: file,
        ContentType: file.type,
        ACL: "public-read"
      };

      await s3Bucket.putObject(s3Data, async (err, response) => {
        payload = {
          ...data,
          ...{
            description: JSON.stringify(description),
            logo: `${process.env.REACT_APP_IMAGE_BASE_URL}${fileKey}`
          }
        };

        await updateCompany(company.id, payload);
        return;
      });
    }
    payload = { ...data, ...{ description: JSON.stringify(description) } };
    await updateCompany(company.id, payload);
    return redirect(`/dashboard/company/${company.id}`);
  };

  _updateDescription = content => {
    this.setState({ description: content });
  };

  _addressChange = async geolocation => {
    const { updateCompany, company } = this.props;
    console.log("_addressChange this.props",this.props)
    console.log("_addressChange geolocation",geolocation)
    await updateCompany(company.id, { location: geolocation });
  };

  render() {
    const { browser, company, redirect, updateCompany, dispatch, systemSettings, featureFlags } = this.props;
    const { description, file, thePic, modalIsOpen, modalPage } = this.state;




    if (company) {
      return (
        <div style={{ height: "89vh", overflowY: "auto", overflowX: "hidden", paddingRight: "5px", paddingLeft: "2px", marginTop: "20px" }}>
          {/* <RouteLeavingGuard
          when={!company.description || !company.location}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if (this.blockNavigation) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
        }}
          /> */}
          <Flex flexDirection="column">
            {browser.lessThan.large ? (
              <Flex
                mb="32px"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <ImageTreatment
                  img={thePic || (file && file.preview) || company.logo || defaultCompany(company.id, company.name)}
                  blurRadius={"15px"}
                  imageInset={0}
                  borderRadius={0}
                  innerBorderRadius={0}
                  shadowOffset={0.1}
                  padding={8}
                  height={80}
                  width={80}
                />
                {/* <Box ml="37px" data-tip="Click here or drag and drop to upload">
                
                <ImageCrop dropZoneType='square_small' onCrop={this.onCrop} notifyError={this.props.notifyError} />
              </Box>
              <ReactTooltip /> */}
              </Flex>
            ) : (
              <Flex
                mb="53px"
                flexDirection="row"
                justifyContent="center"
                // alignItems="center"
                width="100%"
              >
                <ImageTreatment
                  img={thePic || (file && file.preview) || company.logo || defaultCompany(company.id, company.name)}
                  blurRadius={"15px"}
                  imageInset={0}
                  borderRadius={0}
                  innerBorderRadius={0}
                  shadowOffset={0.1}
                  padding={10}
                  height={120}
                  width={120}
                />
                <Box ml="62px" data-tip="Click here or drag and drop to upload">
                  {/* <SquareDropzoneLarge onDrop={this.onDrop} /> */}
                  <ImageCrop enableClick={true} dropZoneType='square_large' onCrop={this.onCrop} notifyError={this.props.notifyError} page="company_profile" />

                </Box>
                <ReactTooltip />
              </Flex>

            )}

            {
              browser.lessThan.large
                ?
                null
                :
                <Flex
                  mb="32px"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >

                  <DashboardSmallCTA
                    width="15%"
                    minWidth={["110px"]}
                    fontSize="12px"
                    innerHeight="30px"
                    ml="20px"
                    color="#FE4A49"
                    onClick={() => updateCompany(getUser().company_id, { logo: null }).then(company => this.setState({ thePic: company.payload.logo }))}

                  >
                    DELETE LOGO
                  </DashboardSmallCTA>
                </Flex>
            }




            <InfoForm
              browser={browser}
              company={company}
              description={company.description}
              onSubmit={this._submitInfo}
              initialValues={{ name: company && company.name }}
              updateDescription={this._updateDescription}
            />

            <NunitoSans
              color="#000000"
              style={{ marginBottom: '15px' }}
            >
              Tip: Your contact info can only be seen by your connections. Labels are required.
            </NunitoSans>



            <Box mb="64px">
              <Attributes
                label="Phone Contacts"
                labelPlaceholder="e.g. Mobile"
                maxlengths={["20", "20"]}
                formLabel="NUMBER"
                list={company.phone_numbers}
                placeholder="e.g. (415) 555-5555"
                createVar="Phone_number"
                fetchVar="Phone_numbers"
                refreshVar="fetchCompany"
                resourceId={company.id}
                extraParams={{ company: true, company_id: company.id }}
              />
            </Box>

            <Box mb="64px">
              <Attributes
                label="Websites"
                labelPlaceholder="e.g. Company"
                maxlengths={[null, "20"]}
                formLabel="URL"
                list={company.websites}
                placeholder="e.g. website.com"
                createVar="Website"
                fetchVar="Websites"
                refreshVar="fetchCompany"
                resourceId={company.id}
                extraParams={{ company: true, company_id: company.id }}
              />
            </Box>
            <Box mb="64px">
              <Attributes
                label="Email Addresses"
                labelPlaceholder="e.g. Support"
                maxlengths={[null, "20"]}
                formLabel="ADDRESS"
                list={company.email_addresses}
                placeholder="e.g. support@website.com"
                createVar="Email_address"
                fetchVar="Email_addresses"
                refreshVar="fetchCompany"
                resourceId={company.id}
                extraParams={{ company: true, company_id: company.id }}
              />
            </Box>

            {
            featureFlags.brands === true
            ?
            <Box mb="64px">
              <BrandAttributes
                maxlengths={["40", "100"]}
                list={company.brands}
                companyId={company.id}
                extraParams={{ company_id: company.id, user_id: getUser().id }}
              />
            </Box>
            :
            featureFlags.brands === "locked"
            ?
            <Box mb="64px">
              <LockedFeature
                text="Upgrade to Add Brands"
              />
            </Box>
            :
            null
            }

            {
            featureFlags.subdomains === true
            ?
            <>
              <NunitoSans
                color="#000000"

              >
                Tip: Teammates with a different email than the primary can only join KickPost account with an approved Subdomain.
              </NunitoSans>
              <Box mb="64px">
                <Attributes
                  label="Subdomains"
                  labelPlaceholder="e.g. Asia"
                  maxlengths={[null, "20"]}
                  formLabel="SUBDOMAIN"
                  list={company.subdomains}
                  placeholder="e.g. subdomain.com"
                  createVar="Subdomain"
                  fetchVar="Subdomains"
                  refreshVar="fetchCompany"
                  resourceId={company.id}
                  extraParams={{ company: true, company_id: company.id }}
                />
              </Box>
            </>
            :
            featureFlags.subdomains === "locked"
            ?
            <Box mb="64px">
              <LockedFeature
                text="Upgrade to Add Subdomains"
              />
            </Box>
            :
            null
            }




            {browser.lessThan.large ? (
              <Box mt="40px" mb={66 * 5 + 100 + "px"}>
                <MapComponent
                  placeName={
                    (company.location && company.location.place_name) ||
                    "Chicago, IL"
                  }
                  address={(company.location && company.location.address) || ""}
                  text={(company.location && company.location.text) || ""}
                  addressChange={this._addressChange}
                />
              </Box>
            ) : null}
          </Flex>
        </div>
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    browser: state.browser,
    company: state.resources.detail.company,
    user: state.resources.detail.user,
    loading: state.resources.detail.loading.user
  }),
  dispatch => ({
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    dispatch,
    actions: bindActionCreators(actions, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch)
  })
)(EditCompanyMain);

let EditCompanyNav = ({ dispatch }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        EDIT COMPANY
      </NunitoSans>
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-end"]}
        >
          {/* <OutlineFlex
            justifyContent="center"
            alignItems="center"
            height={["30px", "30px", "40px"]}
            width={["64px", "64px", "102px"]}
            onClick={() => dispatch(submit("company-info"))}
            borderRadius="8px"
            color="#00C594"
          >
            <NunitoSans
              pt={["2px", "4px"]}
              fontSize={["12px", "12px", "18px"]}
              fontWeight="bold"
              color="#00C594"
            >
              SAVE
            </NunitoSans>
          </OutlineFlex> */}
          <DashboardSmallCTA
            width="15%"
            onClick={() => dispatch(submit("company-info"))}
            minWidth={["80px", "80px", "122px", "122px"]}
            fontSize={["12px", "12px", "17px", "17px"]}
            innerHeight={["30px", "30px", "40px", "40px"]}
          // my="12px"
          // height="34px"
          >
            SAVE
          </DashboardSmallCTA>
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);

EditCompanyNav = connect(state => ({
  browser: state.browser,
}))(EditCompanyNav);

export { EditCompanyNav };


function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}