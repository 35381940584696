import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophyAlt, faUserPlus, faStar, faUsers, faBuilding, faChartNetwork, faUserCircle, faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { faSolarSystem } from '@fortawesome/pro-regular-svg-icons';

import { faStar as faStarLight, faMedal } from '@fortawesome/pro-light-svg-icons';


import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex, IconBox, Box } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";

import { shouldInfoModalOpen } from "../../utils/auth";
import {
  setInfoModalOpen
} from "../../actions/rule";

//INFOMODAL

export class InfoModal extends Component {
  state = {
    isModalOpen: false,
    // currentPage: 0
  };

  componentDidMount() {
    const { modalVersion, modalName, DoNotAutoTrigger } = this.props;

    if (!DoNotAutoTrigger && shouldInfoModalOpen(modalName, modalVersion)) {
      this.setState({ isModalOpen: true })
    }
  }

  _handleCloseRequest = () => {
    this.setState({ isModalOpen: false });
    this.props.setInfoModalOpen("")
  }

  // _goNextPage = ()=>{
  //   const {currentPage} = this.state;
  //   this.setState({currentPage: currentPage+1});
  // }

  // _goPreviousPage = ()=>{
  //   const {currentPage} = this.state;
  //   this.setState({currentPage: currentPage-1});
  // }


  render() {
    const { title, image, text, extraTexts, videoLink, modalName, infoModalOpen, browser, key } = this.props;
    const { isModalOpen } = this.state;

    const icons = {
      my_company_modal: faBuilding,
      coworkers_modal: faUsers,
      invites_modal: faUserPlus,
      my_profile_modal: faUserCircle,
      rewards_modal: faStar,
      search_modal: faSearch,
      connections_modal: faChartNetwork,
    };

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: browser.lessThan.large ? '50%' : "500px",
        minHeight: "342px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: '1px solid #707070',
        borderRadius: '10px'
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }

    };

    return (
      <Modal
        isOpen={isModalOpen || infoModalOpen === modalName}
        onRequestClose={() => this._handleCloseRequest()}
        style={customStyles}
        contentLabel={`${modalName} Modal`}
        ariaHideApp={false}
        key={0}
      >
        <Flex
          width='100%'
          flexDirection="column"
          justifyContent="center"
        >
          <Flex
            width='100%'
            style={{ float: 'right', display: 'block', margin: '0 auto' }}
            justifyContent="center"
          >
            <IconBox
              style={{ float: 'right', display: 'block', margin: '0 auto', cursor: "pointer" }}
              fontSize={[ "12px" ]}
              color="#C7C7C7"
              onClick={ () => this._handleCloseRequest() }
            >
              thinx
            </IconBox>
          </Flex>
          {
            icons[modalName]
              ?
              <FontAwesomeIcon
                icon={icons[modalName]}
                size='7x'
                color='#428BF4'
              />
              :
              <img 
                src={image} 
                alt={`${title} Modal`} 
                style={{ display: 'block',margin: '0 auto' }}
                width="80"></img>
          }

          <NunitoSansBold
            color='#000000'
            fontSize={['25px', '25px', '35px', '35px']}
            textAlign='center'
            style={{ marginTop: '30px', marginBottom: '17px' }}
          >
            {title}
          </NunitoSansBold>

          <NunitoSans
            color='#000000'
            fontSize={['16px', '16px', '18px', '18px']}
            lineHeight='22px'
            textAlign="center"
            key={0}
          >
            {text}
          </NunitoSans>
          {
            extraTexts &&
            extraTexts.length &&
            extraTexts.map(text => (
              <NunitoSans
                color='#000000'
                fontSize={['16px', '16px', '18px', '18px']}
                lineHeight='22px'
                textAlign="center"
                style={{ marginTop: '15px' }}
                key={0}
              >
                {text}
              </NunitoSans>
            ))
          }

          {
            videoLink
              ?
              <DashboardSmallCTA
                // width="450px"
                minWidth={["80px"]}
                fontSize="15px"
                innerHeight="40px"
                mt='30px'
                style={{ textAlign:'center',width:'40%',minWidth:'80px',display: 'block',margin: '30px auto' }}
                onClick={() => window.open(videoLink)}
                color="#428BF4"
              // my="12px"
              // height="34px"
              >
                WATCH VIDEO
              </DashboardSmallCTA>
              :
              null
          }
          <Box height="12px" />
        </Flex>
      </Modal>
    );

  }
}

export default connect(
  state => ({
    browser: state.browser,
    infoModalOpen: state.rule.infoModalOpen,
  }),
  dispatch => ({
    setInfoModalOpen: bindActionCreators(setInfoModalOpen, dispatch),
  })
)(InfoModal);