import React, { Component } from "react";
import { MapboxMap } from "../common/mapboxMap";
import MapboxAutoComplete from "../common/mapboxAutocomplete";

import { Box, Flex } from "../common/flexbox";

export class MapComponent extends Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    const { addressChange, address, text, placeName } = this.props;
    return (
      <Flex
        p={["10px", "17px"]}
        flexDirection="column"
        justifyContent="spaceBetween"
        alignItems="center"
        borderRadius="10px"
        bg="lightGray"
      >
        <Box width="100%" height="217px">
          <MapboxMap address={address} text={text} placeName={placeName} />
        </Box>
        <Box mt="24px" width="100%">
          <MapboxAutoComplete onSelect={addressChange} placeName={placeName} />
        </Box>
      </Flex>
    );
  }
}
