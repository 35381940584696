import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import styled from "styled-components";
import { space, width } from "styled-system";
import axios from "axios";
//utils
import { getUser, clearUser } from "../../utils/auth";
import { actions, clearDetail } from "../../actions/resources";

//components
import { Box, Flex } from "../common/flexbox";
import { H4 } from "../common/typography";
//images
import kickpostLogo from "../../images/icon.png";
import kickpostWhiteLogo from "../../images/kickpost_logo_w.png";

import InfoPage from "./info";

const OnboardingProgressUnderBar = styled.div`
  ${space} height:10px;
  background: #efefef;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const OnboardingProgressOverBar = styled.div`
  ${width} height:10px;
  background-image: linear-gradient(
    -90deg,
    #2FD371 0%,
    #2FD371 100%
    
  );
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
`;

const OnboardingProgress = ({ ...props }) => (
  <Flex
    pt={["5px", "10px", "10px", "10px"]}
    w={1}
    flexDirection="column"
    position="relative"
  >
    <Flex
      w={1}
      flexDirerestction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {Array(props.numberOfSteps).fill('a').map((k, index)=><H4 className={`${props.step === index+1 ? "active" : ""} onboarding-h4`}>{index+1}</H4>)}
      {/* <H4 className={`${props.step === 1 ? "active" : ""} onboarding-h4`}>1</H4>
      <H4 className={`${props.step === 2 ? "active" : ""} onboarding-h4`}>2</H4>
      <H4 className={`${props.step === 3 ? "active" : ""} onboarding-h4`}>3</H4> */}
      {/* <H4 className={`${props.step === 4 ? "active" : ""} onboarding-h4`}>4</H4> */}
    </Flex>
    <OnboardingProgressUnderBar
      mt={["7px", "10px", "10px", "10px"]}
      mb={["30px", "45px", "45px", "45px"]}
    >
      {/* <OnboardingProgressOverBar width={Math.min(1, props.step / 4)} /> */}
      <OnboardingProgressOverBar width={Math.min(1, props.step / props.numberOfSteps + 0.025)} />
    </OnboardingProgressUnderBar>
  </Flex>
);

const KickpostLogoImg = styled.img`
  background-size: containnter;
  width: 100%;
`;
const KickpostLogo = ({ ...props }) => <KickpostLogoImg src={kickpostLogo} />;

export class Auth extends Component {



  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {
  
    const _user = getUser();
    if(_user){
     
      
      axios.get(`${process.env.REACT_APP_API_URL}/user/${_user.email}`).then(res => {
        
          if(
            !res.data.id || 
            (res.data.status !== "active" && res.data.status !== "pending" && res.data.status !== "invited") ||
            (_user.company_id !== null && res.data.company_id !== _user.company_id)
            ){
  
            clearUser();
            this.props.redirect("/");
  
          }else{
            if (_user && _user.token) {
              this.props.redirect("/dashboard");
            }
          }
        
        

        // if (_user && getUser().token) {
        //   this.props.redirect("/dashboard");
        // }
      }).catch(err => console.log(err));
      
    }
    
  }

  render() {
    const { back, browser } = this.props;

    const {company_id, is_single_user_company}= getUser() || {};

    return (
      <div>
        <Flex
          w={1}
          justifyContent="center"
          // alignItems="center"
          // flexDirection="column"
        >
         {
           browser.lessThan.large
           ?
           null
           :
           <Flex
           width={[1, 1, 1, 1]}
          //  maxWidth={"960px"}
           alignItems="center"
           flexDirection="column"
           // id="auth-gutter-wrapper"
           style={{
             backgroundColor: "#10213A",
             height: '100vh'
         }}
         >
           <Box
            //  w={0.263}
             mt={["19px", "3vh", "5vh", "5vh"]}
             mb={["19px", "50px", "50px", "50px"]}
           >
             <img src={kickpostWhiteLogo} width={200} />
           </Box>
           <Box height="65px" />
            <Flex
            width="80%"
            >
              <InfoPage/>
            </Flex>
         </Flex>
         }
          <Flex
            width={[1, 1, 1, 1]}
            // maxWidth={"960px"}
            alignItems="center"
            flexDirection="column"
            id="auth-gutter-wrapper"
          >
            {/* <Box
              w={0.263}
              mt={["19px", "3vh", "5vh", "5vh"]}
              mb={["19px", "50px", "50px", "50px"]}
            >
              <KickpostLogo />
            </Box> */}
            {/* {window.location.pathname === "/" ||
            window.location.pathname === "/signup" ||
            window.location.pathname === "/login-verification" ||
            browser.lessThan.large ? (
              <Box my={["10px"]} />
            ) : (
              <OnboardingProgress numberOfSteps={company_id || is_single_user_company ? 2 : 3} back={back} step={this.props.step} />
            )} */}
            <Box height="125px" />

            <Box width={["90%", "90%", "80%", "80%"]}>
              {this.props.children}
            </Box>
          </Flex>
        </Flex>
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
  }),
  dispatch => ({
  redirect: bindActionCreators(push, dispatch),
  fetchUser: bindActionCreators(actions.fetchUser, dispatch),
  createSession: bindActionCreators(actions.createSession, dispatch),
}))(Auth);
