import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderDante } from "../../../common/forms";
import { Flex } from "../../../common/flexbox";
import { NunitoSans } from "../../../common/typography";

const validate = (values) => {
  const errors = {};
  console.log(values);

  if (!values.name) {
    errors.name = "REQUIRED";
  }

  return errors;
};

const DescriptionPointsLabel = () => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <div
      style={{
        fontSize: "14px",
        color: "black", 
        fontWeight: 700,
        marginBottom: "3px",
        marginTop: "3px",
      }}
    >
      DESCRIPTION
    </div>
  </div>
);

export class Asset extends PureComponent {
  state = {
    nameCharCount: 75,
  };

  render() {
    const {
      company,
      handleSubmit,
      descriptionIncoming,
      updateDescription
    } = this.props;

    let description = descriptionIncoming;

    if (
      typeof description === "undefined" &&
      typeof company.description === "undefined"
    ) {
      description = "description not setting properly";
    }

    const { nameCharCount } = this.state;

    return (
      <form onSubmit={handleSubmit} id="addasset-form">
        <Field
          label="TITLE"
          name="title"
          company={company.name}
          placeholder=""
          component={renderField}
          type="text"
          onChange={(e) =>
            this.setState({ nameCharCount: 75 - e.target.value.length })
          }
        />
        <Flex justifyContent="flex-end">
          <NunitoSans
            fontSize="14px"
            style={{ position: "relative", top: "-50px" }}
            color={nameCharCount < 0 ? "#FE4A49" : "#979797"}
          >
            {`${nameCharCount} characters remaining`}
          </NunitoSans>
        </Flex>
        <Field
          label={<DescriptionPointsLabel />}
          name="description"
          placeholder=" "
          component={renderDante}
          content={description}
          pageType="asset"
          updateContent={updateDescription}
        />
      </form>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  form: "addasset-form",
  validate,
})(Asset);
