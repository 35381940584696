import styled from "styled-components";

//opacity,
import {
  height,
  minHeight,
  minWidth,
  alignSelf,
  space,
  width,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  justifySelf,
  maxWidth,
  maxHeight,
  color,
  position,
  zIndex,
  borderRadius,
  colorStyle,
  order,
  fontSize,
  display,
  top,
  left,
  bottom,
  right,
  borders,
  backgroundImage,
  backgroundSize
} from "styled-system";

//  ${opacity}
export const Box = styled.div`
  ${borders}
  ${space}
  ${width}
  ${height}
  ${alignSelf}
  ${flex}
  ${justifySelf}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${colorStyle}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${order}
  ${display}
`;

//${colorStyleColor}
export const Flex = styled.div`
  ${top}
  ${bottom}
  ${right}
  ${left}
  ${space}
  ${width}
  ${height}
  ${minHeight}
  ${minWidth}
  ${alignItems}
  ${justifyContent}
  ${flexWrap}
  ${alignSelf}
  ${flex}
  ${justifySelf}
  ${flexDirection}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${order}
  ${display}
  ${backgroundImage}
  ${backgroundSize}
  display:flex;
  cursor:${props => (props.cursor ? props.cursor : "inherit")}
  overflow:${props => (props.overflow ? props.overflow : "visible")};
  &:hover {
    ${props => props.hover ? "background: "+props.hover: null}
  }
`;

export const FixedFlex = styled(Flex)``;

export const ClickCursorFlex = styled(Flex)`
  cursor:${props => (props.cursor ? props.cursor + " !important" : "pointer !important")};
  &:hover {
   text-decoration: underline;
   text-decoration-color: #428bf4;
  }
`;

export const ClickCursorFlexHover = styled(Flex)`
  cursor: pointer !important;
  
  &:hover {
    color:#ffffff;
    background:#2FD371;
  }
`;

export const FlexGrow = styled(Flex)`
  flex-grow: 100;
`;

export const IconBox = styled(Box)`
  ${color}
  ${fontSize}
  ${height}
  ${width}
  text-align:center;
  cursor:${props => (props.cursor ? props.cursor : "inherit")}
  font-display: swap; font-family: KickpostIcons;
  font-smooth: always;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const OverflowHiddenBox = styled(Box)`
  overflow: hidden;
`;
