import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Modal from 'react-modal';
import {
  position,
  top,
  bottom,
  right,
  left,
  space,
  colorStyle,
  width,
  fontSize,
  color,
  height,
  borderRadius,
  zIndex
} from "styled-system";
import { Field, reduxForm, submit, getFormValues } from "redux-form";
import StripeCheckout from "react-stripe-checkout";
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTooltip from 'react-tooltip';
//components
import AutoRotateImage from "../common/AutoRotateImage";
import {
  Box,
  Flex,
  IconBox,
  ClickCursorFlex,
  OverflowHiddenBox,
  FixedFlex
} from "../common/flexbox";
import { ProfileCompletionRow, GettingStartedRow, SystemNotificationsRowGroup } from "../common/rows";
import {
  H1CondensedSmallD0D0D0,
  H4,
  DINCondensed,
  NunitoSansBold,
  NunitoSansSemiBold
} from "../common/typography";

import StripeCheckoutButton from "../common/stripeCheckoutButton";

import {
  OutlineFlex,
  DashboardSmallCTA,
  renderSmallField,
  renderSmallTextAreaField
} from "../common/forms";
import { hexToRGB } from "../../utils/color";
// import { colors } from "../../utils/theme";
import { NunitoSans } from "../common/typography";
import { AbsoluteDiv } from "../common/absolute";
import thickDash from "../../images/thick-dash.png";
import thickDashD0D0D0 from "../../images/thick-dash-d0d0d0.png";
import oneSixSquareImage from "../../images/one-six-square.png";
import oneSixSquareImage979797 from "../../images/one-six-square-979797.png";

import fourFourSquare979797 from "../../images/four-four-square-979797.png";
import fourFourSquare979797Transparent from "../../images/four-four-square-979797-transparent.png";
import fourFourSquareD0D0D0 from "../../images/four-four-square-d0d0d0.png";
import fourFourSquareBottomRight979797 from "../../images/four-four-square-bottom-right-979797.png";
import fourFourSquareBottomRight979797Transparent from "../../images/four-four-square-bottom-right-979797-transparent.png";
import fourFourSquareBottomRightD0D0D0 from "../../images/four-four-square-bottom-right-d0d0d0.png";
import cornerRadiusImage8px from "../../images/8px-dotted-corner2828.png";
import cornerRadiusImageTransparent8px from "../../images/8px-dotted-corner-transparent.png";
import cornerRadiusImage8pxD0D0D0 from "../../images/8px-dotted-corner2828-d0d0d0.png";

import redDashCorner from "../../images/red-dash-corner.png";
import fourFourRedSquare from "../../images/four-four-red-square.png";
import fourFourRedSquareBottomRight from "../../images/four-four-red-square-bottom-right.png";

import greenDashCorner from "../../images/green-dash-corner.png";
import fourFourGreenSquare from "../../images/four-four-green-square.png";
import fourFourGreenSquareBottomRight from "../../images/four-four-green-square-bottom-right.png";

import yellowDashCorner from "../../images/yellow-dash-corner.png";
import fourFourYellowSquare from "../../images/four-four-yellow-square.png";
import fourFourYellowSquareBottomRight from "../../images/four-four-yellow-square-bottom-right.png";

import dashedCircleLargeImage from "../../images/dashed-circle-large.png";
import dashedSquareLargeImage from "../../images/dashed-square-large.png";

import vendorLogoImageShadowed from "../../images/vendor-logo-shadowed.png";
import departmentsLogoImageShadowed from "../../images/department-logo-shadowed.png";
import departmentsLogoImage from "../../images/department-logo.png";
import companyImage from "../../images/company-image.png";
import employeeLogoImageShadowed from "../../images/employee-logo-shadowed.png";
import changepaymentmethodLogoImage from "../../images/payment-logo-shadowed.png";
import locationPin from "../../images/location-pin.png";

import rewardStar from "../../images/rewards_star.png";
import createIncentives from "../../images/create_incentives_image.png";
import receiveIncentives from "../../images/receive_incentives_image.png";


import hashImage from "../../images/hash.png";
import audienceImage from "../../images/audience.png";

import Dropzone from "react-dropzone";

import { Link, UnderlineLink } from "../common/navigation";

import { CardElement, injectStripe } from 'react-stripe-elements';
// import {Elements, StripeProvider} from 'react-stripe-elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faMinusCircle, faCog, faCaretUp, faCaretDown, faUserPlus, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faSolarSystem } from '@fortawesome/pro-regular-svg-icons';
import { faStar, faMedal } from '@fortawesome/pro-light-svg-icons';

import BillingForm from "../dashboard/company/forms/billing";


import { defaultCompany } from "../../utils/defaultLogos";
import { onboardStripeConnectAccount } from "../../utils/stripe";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
    console.log("User clicked submit")
    window.alert("User clicked submit")

    //let {token} = await this.props.stripe.createToken({name: "Name"});

    //token={token => props.submitStripe(token, "monthly")}

    let response = await fetch("/charge", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: 'pk_test_2jOOmwAJl8GKbFo5puIhkEas',//token.id
      mode: 'no-cors'
    });

    if (response.ok) console.log("Purchase Complete!")
  }

  render() {
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>
        <CardElement />
        <button onClick={this.submit}>Send</button>
      </div>
    );
  }
}


//
// TODO why is this not working ???? bg='inputBackgroundGray'
export const Bubble = styled.div`
  ${color} ${colorStyle}
  border-radius:10px;
  width: 100%;
`;

export const BlurWrapper = styled(AbsoluteDiv)`
  ${position}
  filter:blur(${props => props.blur});
  opacity:${props => props.opacity};
  z-index:1;
`;

const StretchedImg = styled.img`
  overflow: ${props => (props.overflow ? props.overflow : "visible")};
  ${borderRadius} height:100%;
  width: 100%;
`;

const ImageBlur = ({ ...props }) => (
  <StretchedImg
    borderRadius={props.borderRadius}
    overflow={props.overflow}
    src={props.src}
  />
);

const ImgFill = styled.img`
  width: 100%;
`;

export const BGImgContain = styled.div`
  ${space}
  position:relative;
  z-index:10;
  ${width}
  ${height}
  background-image: url('${props => props.image}');
  background-position:center center;
  background-size:contain;
  background-repeat:no-repeat;
`;

export const BGImgCover = styled.div`
  ${space}
  position:relative;
  z-index:10;
  ${width}
  ${height}
  background-image: url('${props => props.image}');
  background-position:center center;
  background-size:cover;
  background-repeat:no-repeat;
`;

export const UnderlayShadow = styled(OverflowHiddenBox)`
  background: #000000;
  opacity: 1;
`;
export const OverlayShadow = styled(OverflowHiddenBox)`
  background: #ffffff;
  opacity: 1;
`;

export const ImageTreatment = ({ ...props }) => (
  <Box
    position="relative"
    width={`${props.width + 2 * props.padding}px`}
    height={`${props.height + 2 * props.padding}px`}
  >
    <Flex
      borderRadius={`${props.borderRadius}px`}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={`${props.padding}px`}
      alignItems="center"
      justifyContent="center"
      width={`${props.width}px`}
      height={`${props.height}px`}
    >
      {(props.img) ?
        <AbsoluteDiv
          borderRadius={props.innerBorderRadius}
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={5}
          m={`${props.padding}px`}
          top={0}
          bottom={0}
          right={0}
          left={0}
          bg="white"
        >
          {
            // console.log("imagetreatment props",props)
          }
          <AutoRotateImage 
            borderRadius={props.borderRadius} 
            src={props.img} 
            dataWidth={props.width} 
            dataHeight={props.height} 
            width="100%" 
          />
        </AbsoluteDiv>
        : null}
    </Flex>

    {(props.blurRadius === "0px") ? null :

      <BlurWrapper
        opacity={props.blurOpacity ? props.blurOpacity : 0.3}
        blur={props.blurRadius}
        top={props.padding + props.width * props.shadowOffset}
        left={props.padding}
        right={props.padding}
        bottom={-1 * (props.width * props.shadowOffset) + props.padding}
      >
        {props.regularShadow ? (
          <BlurWrapper
            className="blur-wrapper"
            width="100%"
            height="100%"
            blur="8px"
            top="5px"
            left="0px"
            opacity={0.2}
          >
            <UnderlayShadow
              borderRadius="10px"
              zIndex="1"
              width="100%"
              height="100%"
            />
          </BlurWrapper>
        ) : (
          <ImageBlur
            borderRadius={props.borderRadius}
            overflow="hidden"
            src={props.img}
          />
        )}
      </BlurWrapper>

    }

  </Box>
);

export const NewImageTreatment = ({ borderRadius = "initial", ...props }) => (
  <img src={props.img} width={props.width} height={props.height} style={{ border: '6px solid #D3D3D3', borderRadius: borderRadius }} />
);

export const BlurImageTreatment = ({ ...props }) => (
  <Box position="relative">
    <Flex
      borderRadius={props.borderRadius}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={props.p}
      alignItems="center"
      justifyContent="center"
      width={props.width}
      height={props.height}
    >
      <ImgFill src={props.img} />
    </Flex>
    <BlurWrapper>
      <ImageBlur src={props.img} />
    </BlurWrapper>
  </Box>
);

export const SquareImageTreatment = ({ ...props }) => (
  <Box position="relative">
    <Flex
      borderRadius={props.borderRadius}
      bg="imageBorder"
      position="relative"
      zIndex={100}
      p={props.padding ? props.padding : 10}
      alignItems="center"
      justifyContent="center"
      width={[
        props.widthSmall,
        props.widthLarge,
        props.widthLarge,
        props.widthLarge
      ]}
      height={[
        props.widthSmall,
        props.widthLarge,
        props.widthLarge,
        props.widthLarge
      ]}
    >
      <ImgFill src={props.img} />
    </Flex>
    <BlurWrapper>
      <ImageBlur />
    </BlurWrapper>
  </Box>
);

export const ThickPixelDash = styled.div`
  height: 2px;
  background-image: url(${thickDash});
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export const ThickPixelDashD0D0D0 = styled.div`
  height: 2px;
  background-image: url(${thickDashD0D0D0});
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export const OneSixDash = styled.div`
  ${height}
  ${width}
  ${position}
  ${space}
  ${top}
  ${bottom}
  ${left}
  ${right}
  background-image:url(${oneSixSquareImage});
`;

export const OneSixDash979797 = styled.div`
  ${height}
  ${width}
  ${position}
  ${space}
  ${top}
  ${bottom}
  ${left}
  ${right}
  background-image:url(${oneSixSquareImage979797});
`;


const CTAButtonText = styled.div`
  ${fontSize} 
  ${color}
  margin-top:4px;
  text-align: center;
  font-display: swap; font-family: NunitoSansExtraBold;
  font-weight: bold;
  &:hover {
    color: #fff;
  }
`;

export const ANoUnderline = styled.a`
  text-decoration: none;
  width: 100%;
  height: 100%;
`;

export const NoUnderline = styled.div`
  width: 100%;
  height: 100%;
`;

export const KickpostGreenHoverFlex = styled(Flex)`
  &:hover {
    background:#2FD371;
    color: #fff;
  }
`;
export const LightGrayCTA = ({ ...props }) => (
  <Link to={props.link} color="red">
    <KickpostGreenHoverFlex
      width="100%"
      height="100%"
      bg="#ebebe7"
      borderRadius="9px"
      alignItems="center"
      justifyContent="center"
      color={props.color}
    >
      <CTAButtonText
        fontSize={props.fontSize}
      >
        {props.children}
      </CTAButtonText>
    </KickpostGreenHoverFlex>
  </Link>
);



export const LightGrayHoverFlex = styled(Flex)`
  &:hover {
    background: #10213A;
    color: #fff;
  }
`;

export const KickPostGreenCTA = ({ ...props }) => (
  <Link to={props.link} color="red">
    <LightGrayHoverFlex
      width="100%"
      height="100%"
      bg="#2FD371"
      borderRadius="9px"
      alignItems="center"
      justifyContent="center"
      color={props.color}
    >
      <CTAButtonText
        fontSize={props.fontSize}
      >
        {props.children}
      </CTAButtonText>
    </LightGrayHoverFlex>
  </Link>
);

export const NavButtonTransparentHoverDiv = styled.div`
color: ${props => props.active || '#fff'};
span {
  color: white;
}
&:hover {
  color: #2FD371;

  span {
    color: white;
  }
}
`;

export const NavNotificationBubble = styled.span`
${top}
${bottom}
${left}
${right}
position: absolute;
font-size: 8px;
text-align: center;
border: 1px solid #FE4A49;
border-radius: 12px;
background-color: #FE4A49;
padding: 0px 4px;
padding-top: 1px;
font-color: white !important;
color: white;
`;


export const NavButtonTransparent = ({ ...props }) => {

  return (
    <Flex
      justifyContent={props.minimize ? "center" : 'flex-start'}
      alignItems='center'
      width="85%"
      data-for="navbar"
      data-tip={props.title}
      data-tip-disable={!props.minimize}
    >

      {props.link && !props.active && !props.lockedFeature ? (
        <Link to={props.link}>


          <NavButtonTransparentHoverDiv >
            <Flex
              alignItems="center"
            >

              {
                props.icon !== 'signout' || props.icon !== 'helpcenter' || props.icon !== 'settings'
                  ?
                  <div style={{ position: 'relative' }}>
                     <FontAwesomeIcon
                        icon={props.fontawesomeIcon}
                        style={{fontSize: '24px', width: '28px'}}
                      />

                    {
                      props.notificationCount
                        ?
                        <NavNotificationBubble
                          top="0px"
                          left={props.minimize ? "26px" : "16px"}
                        >

                          {props.notificationCount}
                        </NavNotificationBubble>
                        :
                        null
                    }
                  </div>
                  :
                  null
              }
              {
                props.minimize
                  ?
                  null
                  :
                  <NunitoSansSemiBold fontSize={["6px", "6px", "10px", "10px"]} style={{ marginLeft: props.icon !== 'signout' ? '12px' : '0px' }}>
                    {props.name.toUpperCase()}
                  </NunitoSansSemiBold>
              }
            </Flex>
          </NavButtonTransparentHoverDiv>


        </Link>
      ) :
        props.icon === "settings"
          ?
          <Link to={props.url}>
            <NavButtonTransparentHoverDiv  >
              <Flex
                alignItems="center"
              >





                <NunitoSansSemiBold fontSize={["6px", "6px", "10px", "10px"]} style={{ cursor: 'pointer' }}>
                  {props.name.toUpperCase()}
                </NunitoSansSemiBold>



              </Flex>
            </NavButtonTransparentHoverDiv>
          </Link>
          :
          props.icon === "signout"
            ?
            (

              <NavButtonTransparentHoverDiv {...props} >
                <Flex
                  alignItems="center"
                >





                  <NunitoSansSemiBold fontSize={["6px", "6px", "10px", "10px"]} style={{ cursor: 'pointer' }}>
                    {props.name.toUpperCase()}
                  </NunitoSansSemiBold>



                </Flex>
              </NavButtonTransparentHoverDiv>

            )
            :
            props.icon === 'helpcenter'
              ?
              (
                <NavButtonTransparentHoverDiv
                  onClick={() => {
                    props.action();
                  }}
                >
                  <Flex
                    alignItems="center"
                  >





                    <NunitoSansSemiBold fontSize={["6px", "6px", "10px", "10px"]} style={{ cursor: 'pointer' }}>
                      {props.name.toUpperCase()}
                    </NunitoSansSemiBold>



                  </Flex>
                </NavButtonTransparentHoverDiv>
              )
              :
              (
              
                  <NavButtonTransparentHoverDiv style={{cursor: "pointer"}} onClick={() => props.lockedFeature ? props.openUpgradeModal() : {}} active={props.lockedFeature ? "#fff" : "#2FD371"}>
                    <Flex
                      alignItems="center"
                    >

                      <div style={{ position: 'relative' }}>
                        <FontAwesomeIcon
                          icon={props.fontawesomeIcon}
                          style={{fontSize: '24px', width: '28px'}}
                        />
                        {
                          props.notificationCount
                            ?
                            <NavNotificationBubble
                              top="0px"
                              left={props.minimize ? "26px" : "16px"}
                            >

                              {props.notificationCount}
                            </NavNotificationBubble>
                            :
                            null
                        }
                      </div>

                      {
                        props.minimize
                          ?
                          null
                          :
                          <>
                          <NunitoSansSemiBold fontSize={["6px", "6px", "10px", "10px"]} style={{ marginLeft: '12px' }}>
                            {props.name.toUpperCase()}
                          </NunitoSansSemiBold>
                          {props.lockedFeature && <FontAwesomeIcon
                          icon={faCircleArrowUp}
                          size="sm"
                          style={{marginLeft:'5px'}}
                        />}
                          </>
                      }


                    </Flex>
                  </NavButtonTransparentHoverDiv>
              )}
      <ReactTooltip
        id="navbar"
        className="navBarTooltip"
        place="right"
        offset={{ 'right': 2 }}

      />
    </Flex>
  );
}


export const NavButton = ({ ...props }) => (
  <Flex>
    {props.link ? (
      <Link to={props.link} title={props.title}>
        <Flex
          {...props}
          borderRadius="8px"
          height={40}
          width={40}
          bg="lightGray"
        >
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <IconBox color="darkerGray" className="icon" fontSize={21}>
              {props.icon}
            </IconBox>
          </Flex>
        </Flex>
      </Link>
    ) : (
      <Flex {...props} borderRadius="8px" height={40} width={40} bg="lightGray">
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <IconBox color="darkerGray" className="icon" fontSize={20}>
            {props.icon}
          </IconBox>
        </Flex>
      </Flex>
    )}
  </Flex>
);

export const GradientOverflowDownward = styled(Box)`
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 98%
  );
  z-index: 1000;
  position: relative;
`;

export const GradientOverflowDownwardLaptop = styled(Box)`
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 17%,
    rgba(255, 255, 255, 0.53) 17%,
    rgba(255, 255, 255, 0.81) 17%,
    #ffffff 17%
  );
  z-index: 1000;
  position: relative;
`;

const WhiteGradientBox = styled(Box)`
  background-image: linear-gradient(
    ${props => props.gradientDegree},
    rgba(
        ${props => props.rgb[0]},
        ${props => props.rgb[1]},
        ${props => props.rgb[2]},
        0
      )
      12%,
    rgba(
        ${props => props.rgb[0]},
        ${props => props.rgb[1]},
        ${props => props.rgb[2]},
        1
      )
      88%
  );
`;

export const GradientUnderlayRightToLeft = ({ ...props }) => (
  <Flex width={props.gradientWidth + props.boxWidth} height="100%">
    <WhiteGradientBox
      gradientDegree="90deg"
      color={props.color}
      rgb={hexToRGB(props.color)}
      height="100%"
      width={props.gradientWidth}
    />
    <Box bg={props.color} height="100%" width={props.boxWidth} />
  </Flex>
);

export const GradientUnderlayLeftToRight = ({ ...props }) => (
  <Flex width={props.gradientWidth + props.boxWidth} height="100%">
    <Box bg={props.color} height="100%" width={props.boxWidth} />
    <WhiteGradientBox
      gradientDegree="270deg"
      color={props.color}
      rgb={hexToRGB(props.color)}
      height="100%"
      width={props.gradientWidth}
    />
  </Flex>
);

const CoverBG = styled(AbsoluteDiv)`
  ${colorStyle};
`;

const FourFourDottedCorner = styled(AbsoluteDiv)`
${zIndex}
  background-image: url(${cornerRadiusImage8px});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const FourFourDottedCornerTransparent = styled(AbsoluteDiv)`
  background-image: url(${cornerRadiusImageTransparent8px});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const FourFourDottedCornerGreen = styled(AbsoluteDiv)`
  background-image: url(${greenDashCorner});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const FourFourDottedCornerRed = styled(AbsoluteDiv)`
  background-image: url(${redDashCorner});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const FourFourDottedCornerYellow = styled(AbsoluteDiv)`
  background-image: url(${yellowDashCorner});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const FourFourDottedCornerD0D0D0 = styled(AbsoluteDiv)`
  background-image: url(${cornerRadiusImage8pxD0D0D0});
  background-size: contain;
  width: 8px;
  height: 8px;
  transform: rotate(${props => props.rotation});
`;

const DottedBGLeftTop = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquare979797});
`;

const DottedBGLeftTopTransparent = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquare979797Transparent});
`;

const DottedBGD0D0D0LeftTop = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquareD0D0D0});
`;

const DottedBGBottomRightTransparent = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquareBottomRight979797Transparent});
  background-position: bottom right;
`;

const DottedBGLeftTopGreen = styled(AbsoluteDiv)`
  background-image: url(${fourFourGreenSquare});
`;

const DottedBGBottomRightGreen = styled(AbsoluteDiv)`
  background-image: url(${fourFourGreenSquareBottomRight});
  background-position: bottom right;
`;

const DottedBGLeftTopYellow = styled(AbsoluteDiv)`
  background-image: url(${fourFourYellowSquare});
`;

const DottedBGBottomRightYellow = styled(AbsoluteDiv)`
  background-image: url(${fourFourYellowSquareBottomRight});
  background-position: bottom right;
`;

const DottedBGLeftTopRed = styled(AbsoluteDiv)`
  background-image: url(${fourFourRedSquare});
`;

const DottedBGBottomRightRed = styled(AbsoluteDiv)`
  background-image: url(${fourFourRedSquareBottomRight});
  background-position: bottom right;
`;

const DottedBGBottomRight = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquareBottomRight979797});
  background-position: bottom right;
`;

const DottedBGD0D0D0BottomRight = styled(AbsoluteDiv)`
  background-image: url(${fourFourSquareBottomRightD0D0D0});
  background-position: bottom right;
`;

export const FourFourDottedWith8pxCornerRadius = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
  >
    <Box zIndex="4" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCorner zIndex="2" top="-1px" right="-1px" rotation="0deg" />
    <FourFourDottedCorner
      zIndex="2"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCorner
      zIndex="2"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCorner zIndex="2" top="-1px" left="-1px" rotation="270deg" />
    <DottedBGLeftTop
      zIndex="0"
      top="-1px"
      bottom="0px"
      left="-1px"
      right="0px"
    />
    <DottedBGBottomRight
      zIndex="0"
      top="0px"
      bottom="-1px"
      left="0px"
      right="-1px"
    />
    <CoverBG
      bg={"#ffffff"}
      zIndex="1"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

export const FourFourDottedTransparentWith8pxCornerRadius = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
  >
    <Box zIndex="4" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCornerTransparent
      zIndex="3"
      top="-1px"
      right="-1px"
      rotation="0deg"
    />
    <FourFourDottedCornerTransparent
      zIndex="3"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCornerTransparent
      zIndex="3"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCornerTransparent
      zIndex="3"
      top="-1px"
      left="-1px"
      rotation="270deg"
    />
    <DottedBGLeftTopTransparent
      zIndex="1"
      top="7px"
      bottom="0px"
      left="7px"
      right="7px"
    />
    <DottedBGLeftTopTransparent
      zIndex="1"
      top="7px"
      bottom="7px"
      left="-1px"
      right="7px"
    />
    <DottedBGLeftTopTransparent
      zIndex="1"
      top="-1px"
      bottom="7px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightTransparent
      zIndex="1"
      top="7px"
      bottom="-1px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightTransparent
      zIndex="1"
      top="7px"
      bottom="7px"
      left="7px"
      right="-1px"
    />
    <CoverBG
      bg={props.colorStyle ? props.colorStyle : "#ffffff"}
      zIndex="2"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

export const FourFourGreenDashed8pxCornerRadius = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
  >
    <Box zIndex="4" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCornerGreen
      zIndex="3"
      top="-1px"
      right="-1px"
      rotation="0deg"
    />
    <FourFourDottedCornerGreen
      zIndex="3"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCornerGreen
      zIndex="3"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCornerGreen
      zIndex="3"
      top="-1px"
      left="-1px"
      rotation="270deg"
    />
    <DottedBGLeftTopGreen
      zIndex="1"
      top="7px"
      bottom="0px"
      left="7px"
      right="7px"
    />
    <DottedBGLeftTopGreen
      zIndex="1"
      top="7px"
      bottom="7px"
      left="-1px"
      right="7px"
    />
    <DottedBGLeftTopGreen
      zIndex="1"
      top="-1px"
      bottom="7px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightGreen
      zIndex="1"
      top="7px"
      bottom="-1px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightGreen
      zIndex="1"
      top="7px"
      bottom="7px"
      left="7px"
      right="-1px"
    />
    <CoverBG
      borderRadius="8px"
      bg={props.colorStyle ? props.colorStyle : "#ffffff"}
      zIndex="2"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

export const FourFourYellowDashed8pxCornerRadius = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
  >
    <Box zIndex="4" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCornerYellow
      zIndex="3"
      top="-1px"
      right="-1px"
      rotation="0deg"
    />
    <FourFourDottedCornerYellow
      zIndex="3"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCornerYellow
      zIndex="3"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCornerYellow
      zIndex="3"
      top="-1px"
      left="-1px"
      rotation="270deg"
    />
    <DottedBGLeftTopYellow
      zIndex="1"
      top="7px"
      bottom="0px"
      left="7px"
      right="7px"
    />
    <DottedBGLeftTopYellow
      zIndex="1"
      top="7px"
      bottom="7px"
      left="-1px"
      right="7px"
    />
    <DottedBGLeftTopYellow
      zIndex="1"
      top="-1px"
      bottom="7px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightYellow
      zIndex="1"
      top="7px"
      bottom="-1px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightYellow
      zIndex="1"
      top="7px"
      bottom="7px"
      left="7px"
      right="-1px"
    />
    <CoverBG
      borderRadius="8px"
      bg={props.colorStyle ? props.colorStyle : "#ffffff"}
      zIndex="2"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

export const FourFourRedDashed8pxCornerRadius = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
  >
    <Box zIndex="4" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCornerRed
      zIndex="3"
      top="-1px"
      right="-1px"
      rotation="0deg"
    />
    <FourFourDottedCornerRed
      zIndex="3"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCornerRed
      zIndex="3"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCornerRed
      zIndex="3"
      top="-1px"
      left="-1px"
      rotation="270deg"
    />
    <DottedBGLeftTopRed
      zIndex="1"
      top="7px"
      bottom="0px"
      left="7px"
      right="7px"
    />
    <DottedBGLeftTopRed
      zIndex="1"
      top="7px"
      bottom="7px"
      left="-1px"
      right="7px"
    />
    <DottedBGLeftTopRed
      zIndex="1"
      top="-1px"
      bottom="7px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightRed
      zIndex="1"
      top="7px"
      bottom="-1px"
      left="7px"
      right="7px"
    />
    <DottedBGBottomRightRed
      zIndex="1"
      top="7px"
      bottom="7px"
      left="7px"
      right="-1px"
    />
    <CoverBG
      borderRadius="8px"
      bg={props.colorStyle ? props.colorStyle : "#ffffff"}
      zIndex="2"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

export const FourFourDottedWith8pxCornerRadiusD0D0D0 = ({ ...props }) => (
  <Flex
    height={props.height ? props.height : "100%"}
    width="100%"
    justifyContent="center"
    position="relative"
    overflowY="scroll"
  >
    <Box
    overflowY="scroll" zIndex="3" position="relative" width={["100%"]}>
      {props.children}
    </Box>
    <FourFourDottedCornerD0D0D0
      zIndex="3"
      top="-1px"
      right="-1px"
      rotation="0deg"
    />
    <FourFourDottedCornerD0D0D0
      zIndex="3"
      bottom="-1px"
      right="-1px"
      rotation="90deg"
    />
    <FourFourDottedCornerD0D0D0
      zIndex="3"
      bottom="-1px"
      left="-1px"
      rotation="180deg"
    />
    <FourFourDottedCornerD0D0D0
      zIndex="3"
      top="-1px"
      left="-1px"
      rotation="270deg"
    />
    <DottedBGD0D0D0LeftTop
      zIndex="1"
      top="-1px"
      bottom="0px"
      left="-1px"
      right="0px"
    />
    <DottedBGD0D0D0BottomRight
      zIndex="1"
      top="0px"
      bottom="-1px"
      left="0px"
      right="-1px"
    />
    <CoverBG
      bg={props.colorStyle ? props.colorStyle : "#ffffff"}
      zIndex="2"
      top="0px"
      bottom="0px"
      left="0px"
      right="0px"
    />
  </Flex>
);

// export const DottedDropzone = ({ ...props }) => (
//   <FourFourDottedWith8pxCornerRadius>
//     <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
//       <Flex
//         height="100%"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//       >
//         <IconBox fontSize="22px" mb="4px" color="darkerGray">
//           upload
//         </IconBox>
//         <NunitoSans
//           mb="4px"
//           fontSize="10px"
//           fontWeight="bold"
//           color="darkerGray"
//           textAlign="center"
//         >
//           {props.text}<br />
//         </NunitoSans>
//       </Flex>
//     </AbsoluteDiv>
//     <Box
//       position="relative"
//       zIndex="10"
//       height="100%"
//       width="100%"
//       className="react-dropzone-wrapper"
//     >
//       <Dropzone disableClick
//         style={{
//           border: "0px solid transparent !important",
//           width: "100%",
//           height: "100%",
//           background: "transparent"
//         }}
//         onDrop={props.onDrop}
//       />
//     </Box>
//   </FourFourDottedWith8pxCornerRadius>
// );

export const DottedDropzone = ({ ...props }) => (
  <Flex
    style={{ border: "1px dashed #979797", borderRadius: '8px', height: '80px' }}
  >
    <Dropzone
      style={{
        border: "0px solid transparent !important",
        width: "100%",
        height: "100%",
        background: "transparent"
      }}
      onDrop={props.onDrop}
    >
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <IconBox fontSize="22px" mb="4px" color="darkerGray">
          upload
        </IconBox>
        <NunitoSans
          mb="4px"
          fontSize="10px"
          fontWeight="bold"
          color="darkerGray"
          justifyContent="center" alignItems="center"
        >
          {props.text}<br />
        </NunitoSans>
      </Flex>

    </Dropzone>
  </Flex>

);


export const NonDottedDownloadVendorTemplate = ({ ...props }) => (
  <RecommendedContentWrapper noLabel={true}>
    <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NunitoSans
          mb="4px"
          fontSize="10px"
          fontWeight="bold"
          color="darkerGray"
          justifyContent="center" alignItems="center"
        >
          {props.text}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <Box
      position="relative"
      zIndex="10"
      height="70px"
      width="100%"
      className="react-dropzone-wrapper"
    >
      <a target="_blank" href="https://kickpost-files.s3.amazonaws.com/vendor_invite.csv">
        <IconBox fontSize="22px" mb="4px" color="darkerGray">
          download
        </IconBox>
      </a>
    </Box>
  </RecommendedContentWrapper>
);


export const NonDottedInvitedCompanesTemplate = ({ ...props }) => (
  <RecommendedContentWrapper noLabel={true}>
    <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NunitoSans
          mb="4px"
          fontSize="10px"
          fontWeight="bold"
          color="darkerGray"
          justifyContent="center" alignItems="center"
        >
          {props.text}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <Box
      position="relative"
      zIndex="10"
      height="70px"
      width="100%"
      className="react-dropzone-wrapper"
    >
      <a target="_blank" >
        {/*<IconBox fontSize="22px" mb="4px" color="darkerGray">
          download
    </IconBox>*/}
      </a>
    </Box>
  </RecommendedContentWrapper>
);




export const NonDottedDownloadEmployeeTemplate = ({ ...props }) => (
  <RecommendedContentWrapper noLabel={true}>
    <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NunitoSans
          mb="4px"
          fontSize="10px"
          fontWeight="bold"
          color="darkerGray"
          justifyContent="center" alignItems="center"
        >
          {props.text}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <Box
      position="relative"
      zIndex="10"
      height="70px"
      width="100%"
      className="react-dropzone-wrapper"
    >
      <a target="_blank" href="https://kickpost-files.s3.amazonaws.com/employee_invite.csv">
        <IconBox fontSize="22px" mb="4px" color="darkerGray">
          download
        </IconBox>
      </a>
    </Box>
  </RecommendedContentWrapper>
);


export const NonDottedDropzone = ({ ...props }) => (
  <RecommendedContentWrapper noLabel={true}>
    <AbsoluteDiv zIndex="5" top="7px" left="0px" right="0px" bottom="3px">
      <Flex
        height="80%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {props.uploading ?
          <>
            <FontAwesomeIcon

              icon={faCog}
              color="#2FD371"
              size='2x'
              spin
            />

            <NunitoSans

              fontSize="11px"
              fontWeight="bold"
              color="#2FD371"
              justifyContent="center" alignItems="center"
            >
              LOADING
            </NunitoSans>
          </>
          :
          <>
            <IconBox fontSize="20px"
              // mb="4px" 
              color="darkerGray">
              upload
            </IconBox>
            <NunitoSans
              // mb="4px"
              fontSize="9px"
              fontWeight="bold"
              color="darkerGray"
              justifyContent="center" alignItems="center"
            >
              {props.text}

              {props.secondText ? <br /> : null}
              {props.secondText}
              {props.thirdText ? <br /> : null}
              {props.thirdText}



            </NunitoSans>
          </>
        }
      </Flex>
    </AbsoluteDiv>
    <Box
      position="relative"
      zIndex="10"
      height="70px"
      width="100%"
      className="react-dropzone-wrapper"
    >
      {props.uploading ? null :
        <Dropzone disableClick
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          accept=".csv, text/csv,application/vnd.ms-excel,application/csv,text/plain,text/x-csv,text/comma-separated-values,text/x-comma-separated-values,text/tab-separated-values"
          onDrop={props.onDrop}
        />
      }
    </Box>
  </RecommendedContentWrapper>
);

export const RepIdDropZone = props => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    style={{ border: '1px dashed #428bf4', borderRadius: '8px', height: '100px' }}
  >
    {props.uploading ? (
      <>
        <FontAwesomeIcon

          icon={faCog}
          color="#428bf4"
          size='2x'
          spin
        />

        <NunitoSans

          fontSize="11px"
          fontWeight="bold"
          color="#428bf4"
          justifyContent="center" alignItems="center"
        >
          LOADING
        </NunitoSans>
      </>
    ) :
      (

        <Dropzone disableClick
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          accept=".csv, text/csv,application/vnd.ms-excel,application/csv,text/plain,text/x-csv,text/comma-separated-values,text/x-comma-separated-values,text/tab-separated-values"
          onDrop={props.onDrop}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%', height: "100%" }}
          >
            <NunitoSans
              fontSize='12px'
            >
              Rep ID CSV Dropzone
            </NunitoSans>
            <NunitoSans
              fontSize='10px'
            >
              (email, rep id)
            </NunitoSans>

          </Flex>
        </Dropzone>
      )}
  </Flex>

)

const DashedSquareLarge = styled(Flex)`
  background-image: url(${dashedSquareLargeImage});
  background-size: contain;
  background-position: center center;
`;
export const SquareDropzoneLarge = ({ ...props }) => (
  <Flex width="134px" height="134px" position="relative">
    <DashedSquareLarge
      zIndex="10"
      width="134px"
      height="134px"
      position="relative"
    >
      <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconBox fontSize="34px" color="midGray">
            camera
          </IconBox>
        </Flex>
      </AbsoluteDiv>
      <ClickCursorFlex
        position="relative"
        zIndex="10"
        height="100%"
        width="100%"
        className="react-dropzone-wrapper"
      >
        <Dropzone
          disableClick={!props.enableClick}
          accept={["image/jpeg", "image/png"]}
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          onDrop={props.onDrop}
        />
      </ClickCursorFlex>
    </DashedSquareLarge>
    <BlurWrapper
      zIndex="5"
      opacity={0.3}
      blur={"12px"}
      top={"7px"}
      left={"2px"}
      right={"2px"}
      bottom={"-3px"}
    >
      <OverflowHiddenBox
        borderRadius="10px"
        width="100%"
        height="100%"
        bg="#9F9F9F"
      />
    </BlurWrapper>
  </Flex>
);

export const SquareDropzoneSmall = ({ ...props }) => (
  <Flex width="90px" height="90px" position="relative">
    <DashedSquareLarge
      zIndex="10"
      width="90px"
      height="90px"
      position="relative"
    >
      <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconBox fontSize="28px" color="midGray">
            camera
          </IconBox>
        </Flex>
      </AbsoluteDiv>
      <ClickCursorFlex
        position="relative"
        zIndex="10"
        height="100%"
        width="100%"
        className="react-dropzone-wrapper"
      >
        <Dropzone
          disableClick={!props.enableClick}
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          onDrop={props.onDrop}
        />
      </ClickCursorFlex>
    </DashedSquareLarge>
    <BlurWrapper
      zIndex="5"
      opacity={0.3}
      blur={"12px"}
      top={"7px"}
      left={"2px"}
      right={"2px"}
      bottom={"-3px"}
    >
      <OverflowHiddenBox
        borderRadius="8px"
        width="100%"
        height="100%"
        bg="#9F9F9F"
      />
    </BlurWrapper>
  </Flex>
);


export const SquareDropzoneExtraSmall = ({ ...props }) => (
  <Flex width="70px" height="70px" position="relative">
    <DashedSquareLarge
      zIndex="10"
      width="70px"
      height="70px"
      position="relative"
    >
      <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {
            props.thePic || (props.file && props.file.preview)
              ?
              <ImageTreatment
                img={props.thePic || (props.file && props.file.preview)}
                blurRadius={"5px"}
                imageInset={0}
                borderRadius={10}
                innerBorderRadius={0}
                shadowOffset={0.1}
                padding={5}
                height={70}
                width={70}
              />
              :
              <IconBox fontSize="28px" color="midGray">
                camera
              </IconBox>
          }

        </Flex>
      </AbsoluteDiv>
      <ClickCursorFlex
        position="relative"
        zIndex="10"
        height="100%"
        width="100%"
        className="react-dropzone-wrapper"
      >
        <Dropzone
          disableClick={!props.enableClick}
          accept={["image/jpeg", "image/png"]}
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          onDrop={props.onDrop}
        />
      </ClickCursorFlex>
    </DashedSquareLarge>
    <BlurWrapper
      zIndex="5"
      opacity={0.3}
      blur={"12px"}
      top={"7px"}
      left={"2px"}
      right={"2px"}
      bottom={"-3px"}
    >
      <OverflowHiddenBox
        borderRadius="8px"
        width="100%"
        height="100%"
        bg="#9F9F9F"
      />
    </BlurWrapper>
  </Flex>
);

const DashedCircleLarge = styled(Flex)`
  background-image: url(${dashedCircleLargeImage});
  background-size: contain;
  background-position: center center;
`;

export const CircleDropzoneLarge = ({ ...props }) => (
  <Flex width="134px" height="134px" position="relative">
    <DashedCircleLarge
      zIndex="10"
      width="134px"
      height="134px"
      position="relative"
    >
      <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconBox fontSize="34px" color="midGray">
            camera
          </IconBox>
        </Flex>
      </AbsoluteDiv>
      <ClickCursorFlex
        position="relative"
        zIndex="10"
        height="100%"
        width="100%"
        className="react-dropzone-wrapper"
      >
        <Dropzone
          disableClick={!props.enableClick}
          accept={["image/jpeg", "image/png"]}
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          onDrop={props.onDrop}
        />
      </ClickCursorFlex>
    </DashedCircleLarge>
    <BlurWrapper
      zIndex="5"
      opacity={0.3}
      blur={"12px"}
      top={"7px"}
      left={"2px"}
      right={"2px"}
      bottom={"-3px"}
    >
      <OverflowHiddenBox
        borderRadius="130px"
        width="100%"
        height="100%"
        bg="#9F9F9F"
      />
    </BlurWrapper>
  </Flex>
);

export const CircleDropzoneSmall = ({ ...props }) => (
  <Flex width="90px" height="90px" position="relative">
    <DashedCircleLarge
      zIndex="10"
      width="90px"
      height="90px"
      position="relative"
    >
      <AbsoluteDiv zIndex="5" top="0px" left="0px" right="0px" bottom="0px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconBox fontSize="28px" color="midGray">
            camera
          </IconBox>
        </Flex>
      </AbsoluteDiv>
      <ClickCursorFlex
        position="relative"
        zIndex="10"
        height="100%"
        width="100%"
        className="react-dropzone-wrapper"
      >
        <Dropzone disableClick
          style={{
            border: "0px solid transparent !important",
            width: "100%",
            height: "100%",
            background: "transparent"
          }}
          onDrop={props.onDrop}
        />
      </ClickCursorFlex>
    </DashedCircleLarge>
    <BlurWrapper
      zIndex="5"
      opacity={0.3}
      blur={"12px"}
      top={"7px"}
      left={"2px"}
      right={"2px"}
      bottom={"-3px"}
    >
      <OverflowHiddenBox
        borderRadius="130px"
        width="100%"
        height="100%"
        bg="#9F9F9F"
      />
    </BlurWrapper>
  </Flex>
);


export const Circle = styled.div`
  ${color}
  ${colorStyle}
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props => props.borderThickness}px solid
    ${props => props.borderColor};
  border-radius: ${props => props.size / 2 + props.borderThickness}px;
  overflow: hidden;

  &:hover {
  border: ${props => props.borderThickness}px solid
    ${props => (props.hoverColor ? props.hoverColor : props.borderColor)};
  }
`;

export const AccessoryButton = ({ ...props }) => (
  // <Circle
  //   size={props.size ? props.size : 34}
  //   borderThickness={2}
  //   borderColor={props.color}
  //   hoverColor={props.hoverColor}
  // >
  // </Circle>
  <ClickCursorFlex
    onClick={props.clickHandler}
    height="100%"
    width="100%"
    alignItems="center"
    justifyContent="center"
  >

    {
      props.iconName === "close" || props.iconName === "add" || props.iconName === "checkmark" ?
        <FontAwesomeIcon
          icon={props.iconName === "close" ? faMinusCircle : faPlusCircle}
          color={props.iconName === "close" ? '#FE4A49' : '#2FD371'}
          size='2x'
        />
        :
        <IconBox
          ml={props.iconMarginLeft ? props.iconMarginLeft : 0}
          mt={props.iconMarginTop ? props.iconMarginTop : 0}
          color={props.color}
          className="icon post-text"
          fontSize={props.iconSize}
        >
          {props.iconName}
        </IconBox>
    }



  </ClickCursorFlex>
);

export const AccessoryIndicator = ({ ...props }) => (
  <Circle
    size={props.size ? props.size : 34}
    borderThickness={2}
    borderColor={props.color}
    className="accessory-indicator-circle"
  >
    <Flex
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <IconBox
        ml={props.iconMarginLeft ? props.iconMarginLeft : 0}
        mt={props.iconMarginTop ? props.iconMarginTop : 0}
        color={props.iconColor ? props.iconColor : props.color}
        className="icon"
        fontSize={props.iconSize}
      >
        {props.iconName}
        {/* <FontAwesomeIcon 
        icon={faPlusCircle}
        style={{"font-size": "25px"}}
        /> */}

      </IconBox>


    </Flex>
  </Circle>
);

export const ProfileCompletionProgressUnderBar = styled.div`
  ${space} height:10px;
  background: #efefef;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const ProfileCompletionProgressOverBar = styled.div`
  ${width} height:10px;
  /* Rectangle: */
  background-image: linear-gradient(
    -90deg,
    #2FD371 0%,
    #2FD371 100%
  );
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
`;

export const ProfileCompletionProgress = ({ ...props }) => (
  <ProfileCompletionProgressUnderBar>
    <ProfileCompletionProgressOverBar
      width={Math.min(1, props.percent / 100) * 100 + "%"}
    />
  </ProfileCompletionProgressUnderBar>
);

export const RecommendedContentWrapper = ({ ...props }) => (
  <FourFourDottedWith8pxCornerRadiusD0D0D0 height="auto">
    <AbsoluteDiv top="-5px" left="0px" right="0px">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box bg="white" px="4px">
          {props.noLabel ? null : (
            <NunitoSans fontSize="9px" color="#979797">
              RECOMMENDED
            </NunitoSans>
          )}
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.children}
  </FourFourDottedWith8pxCornerRadiusD0D0D0>
);

export const SystemNotificationsContentWrapper = ({ ...props }) => (
  <FourFourDottedWith8pxCornerRadiusD0D0D0 height="98%"  overflowY="auto">
    <AbsoluteDiv top="-5px" left="0px" right="0px" overflowY="scroll">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box bg="white" px="4px">
          {props.noLabel ? null : (
            <NunitoSans fontSize="9px" color="#979797">
              NOTIFICATIONS
            </NunitoSans>
          )}
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.children}
  </FourFourDottedWith8pxCornerRadiusD0D0D0>
);

export const RewardsContentWrapper = ({ ...props }) => (
  <FourFourDottedWith8pxCornerRadiusD0D0D0 height="auto">
    <AbsoluteDiv top="-5px" left="0px" right="0px">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box bg="white" px="4px">
          {props.noLabel ? null : (
            <NunitoSans fontSize="9px" color="#979797">
              {props.label}
            </NunitoSans>
          )}
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.children}
  </FourFourDottedWith8pxCornerRadiusD0D0D0>
);

export const ReferralsContentWrapper = ({ ...props }) => (
  <FourFourDottedWith8pxCornerRadiusD0D0D0 height="auto">
    <AbsoluteDiv top="-5px" left="0px" right="0px">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box bg="white" px="4px">
            <NunitoSans fontSize="9px" color="#979797">
              REFERRALS
            </NunitoSans>
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.children}
  </FourFourDottedWith8pxCornerRadiusD0D0D0>
);

export const BillingContentWrapper = ({ ...props }) => (
  <FourFourDottedWith8pxCornerRadiusD0D0D0 height="100%">
    <AbsoluteDiv top="-5px" left="0px" right="0px">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box bg="white" px="4px">
          {props.noLabel ? null : (
            <NunitoSans fontSize="9px" color="#979797">
              BILLING
            </NunitoSans>
          )}
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.children}
  </FourFourDottedWith8pxCornerRadiusD0D0D0>
);


const SystemNotificationsRowGroupWrapperStyled = styled.div`
  position: relative;
  overflow: auto;
  overflowY: auto;
  overflow-y: auto;
  overflowX: hidden;
  max-height: 500px;
  margin-top: 5px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SystemNotifications = props => {

  return (
    <SystemNotificationsContentWrapper>
      <Flex flexDirection="column" pt="10px" px="8px" pb="10px" overflow="scroll" overflowY="scroll">
        <Flex px="1%" flexDirection="row" justifyContent="space-between">

          <NunitoSans
            pt={["4px"]}
            fontSize={["12px"]}
            fontWeight="bold"
            // color="#979797"
            color="#000000"
          >
            RECENT NOTIFICATIONS ({(props.message) ? props.message.length : 3})
          </NunitoSans>
          {/* <ClickCursorHoverFlex
            my="1px"
            justifyContent="center"
            alignItems="center"
            height={["24px"]}
            width="15%"
            minWidth={["75px"]}
            onClick={props.closeHandler ? props.closeHandler : () => {}}
            borderRadius="8px"
            bg="lightGray"
          >
            <NunitoSans
              pt={["2px"]}
              pb={["2px"]}
              fontSize={["12px"]}
              fontWeight="bold"
              color="#c3c3c3"
            >
              {props.show ? "HIDE" : "SHOW"}
            </NunitoSans>
          </ClickCursorHoverFlex> */}
          {/* <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="15%"
                onClick={props.closeHandler ? props.closeHandler : () => {}}
                minWidth={["75px"]}
                fontSize="12px"
                innerHeight="25px"
                color="#979797"
                // my="12px"
                // height="34px"
              >
                {props.show ? "HIDE" : "SHOW"}
              </DashboardSmallCTA>
            </Flex> */}

        </Flex>
        {/*<Flex pt={["20px"]} w={1} flexDirection="column" position="relative">
        <ProfileCompletionProgress percent={props.percentComplete} />
      </Flex>*/}
        <Flex flexDirection="column" px="1%" 
            style={{ overflowY: "scroll" }}
        >
          {
            props.show ? (
              props.isCompanyAdmin ? (
                <SystemNotificationsRowGroupWrapperStyled 
                  style={{ overflowY: "scroll" }}
                >
                  <SystemNotificationsRowGroup
                    admin={true}
                    style={{ overflowY: "scroll" }}
                    message={props.message || "A Co-worker needs to be Approved"}
                    showFirstDash={true}
                    showLastDash={false}
                    overflow="auto" 
                    overflow-y="scroll"
                    overflowY={"scroll"}
                    overflowX={"hidden"}
                    maxHeight={"272px"}
                    redirect={props.redirect}
                  />
                </SystemNotificationsRowGroupWrapperStyled>
              ) :
                <SystemNotificationsRowGroupWrapperStyled>
                  <SystemNotificationsRowGroup
                    admin={false}
                    overflow="auto" 
                    overflow-y="scroll" 
                    style={{ overflowY: "scroll" }}
                    message={props.message || "Kurt Russell joined your company"}
                    props={props}
                    showFirstDash={true}
                    showLastDash={true}
                    redirect={props.redirect}
                    overflowY={"scroll"}
                    overflowX={"hidden"}
                    maxHeight={"272px"}
                  />
                </SystemNotificationsRowGroupWrapperStyled>
            ) : null
            // }
            // {
          }
        </Flex>
      </Flex>
    </SystemNotificationsContentWrapper>
  );
};

const RewardsRowGroupWrapperStyled = styled.div`
  position: relative;
  margin-top: 15px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ReferralModal = props => {
  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "500px",
      maxHeight: "90vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '10px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  const ReferralList = () => {
    const referralObj = {};

    props.referrals.forEach(referral => {
      if(referralObj.hasOwnProperty(referral.referred_company_id)){
        if(referral.premium_upgrade){
          referralObj[referral.referred_company_id].premium_upgrade = true;
        }
      }else{
        referralObj[referral.referred_company_id] = referral;
      }
    });

    const theList = Object.values(referralObj);

    if(!theList.length) return null;

    return (
      <>
      {theList.map(referral => <NunitoSans key={referral.referred_company_id}>{referral.referred_company_name}</NunitoSans>)}
      </>
    );
  };

  return (
    <Modal
        isOpen={props.isOpen}
        onRequestClose={() => {
          props.handleModalClose();
        }}
        style={styles}
        contentLabel="Referrals Modal"
        ariaHideApp={false}
      >
        <Flex
          width='100%'
          flexDirection='column'
          alignItems="center"
        >
          <NunitoSansBold
          fontSize="24px"
          color="#000000"
          style={{textDecoration: "underline"}}
          >
            Referrals
          </NunitoSansBold>
          <ReferralList/>
          <DashboardSmallCTA
            width="15%"
            minWidth={["74px"]}
            fontSize="12px"
            innerHeight="30px"
            color="#FE4A49"
            onClick={() => {
              props.handleModalClose();
            }}

            mt="16px"
            height="34px"
          >
            CLOSE
          </DashboardSmallCTA>
        </Flex>
      </Modal>
  );
};

export const ReferralsAccessory = props => {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleStripeLogin = async () => {

    const stripe = await fetch(`${process.env.REACT_APP_API_URL}/stripelogin/${props.stripeConnectId}`).then(res => res.json());

    if (stripe && stripe.url) {
      window.open(stripe.url);
    }

  }

  const ActionButtonLink = () => {
    const hasStripeAccount = Boolean(props.stripeConnectId);
    const hasReferrals = Boolean(props.referrals.length);
    const hasOtherBonus = Boolean(props.otherBonuses.length);
    
    return (
      <Flex
        justifyContent="center"
        mt="30px"
        >
          {
          hasStripeAccount
          ?
          <>
            <NunitoSansSemiBold
            fontSize="12px"
            color="#2FD371"
            style={{textDecoration: "underline", cursor: "pointer", marginRight: "20px"}}
            onClick={() => handleStripeLogin()}
            >
             Stripe Account
            </NunitoSansSemiBold>
            <NunitoSansSemiBold
            fontSize="12px"
            color="#428BF4"
            style={{textDecoration: "underline", cursor: "pointer"}}
            onClick={() => setIsModalOpen(true)}
            >
              View Details
            </NunitoSansSemiBold>
          </>
          :
            <DashboardSmallCTA
            color="#428BF4"
            width="100%"
            onClick={() => onboardStripeConnectAccount()}
            disabled={!hasReferrals && !hasOtherBonus}
            >
              Add Reward Account
            </DashboardSmallCTA>
          }
        </Flex>
    );
  }

  return (
    <ReferralsContentWrapper>
      <Flex flexDirection="column" pt="10px" px="8px" pb="10px">
        <Flex
        justifyContent="space-between"
        alignItems="center"
        >
          <NunitoSans
          fontSize="12px"
          fontWeight="bold"
          color="#000000"
          >
            INVITE PARTNERS, EARN CASH!
          </NunitoSans>

          <UnderlineLink to={`/dashboard/company/${props.user.company_id}/invitations`}>
            <NunitoSans
            fontSize="12px"
            color="#428BF4"
            style={{textDecoration: "underline"}}
            >
              Send Invites
            </NunitoSans>
          </UnderlineLink>
        </Flex>
        <Flex
        justifyContent="space-between"
        alignItems="center"
        mt="20px"
        >
          <NunitoSans
          fontSize="12px"
          fontWeight="bold"
          color="#C3C3C3"
          >
            Referrals
          </NunitoSans>
          <NunitoSans
          fontSize="12px"
          color="#000000"
          >
            {currencyParser(props.referrals.filter(referral => !referral.premium_upgrade).reduce((a, b) => a + b.amount, 0))}
          </NunitoSans>
        </Flex>
        <Flex
        justifyContent="space-between"
        alignItems="center"
        >
          <NunitoSans
          fontSize="12px"
          fontWeight="bold"
          color="#C3C3C3"
          >
            Premium Referrals
          </NunitoSans>
          <NunitoSans
          fontSize="12px"
          color="#000000"
          >
            {currencyParser(props.referrals.filter(referral => referral.premium_upgrade).reduce((a, b) => a + b.amount, 0))}
          </NunitoSans>
        </Flex>
        <Flex
        justifyContent="space-between"
        alignItems="center"
        >
          <NunitoSans
          fontSize="12px"
          fontWeight="bold"
          color="#C3C3C3"
          >
            Other Bonuses
          </NunitoSans>
          <NunitoSans
          fontSize="12px"
          color="#000000"
          >
            {currencyParser(props.otherBonuses.reduce((a, b) => a + b.amount, 0))}
          </NunitoSans>
        </Flex>
        <ActionButtonLink/>
        
      </Flex>
      <ReferralModal
      isOpen={isModalOpen}
      handleModalClose={() => setIsModalOpen(false)}
      referrals={props.referrals}
      />
    </ReferralsContentWrapper>
  );
}

export const TeamRewardsAccessory = props => {

  const {user, systemSettings, companySettings, rewards} = props;
  const { company_admin } = user;
  const { currency, statement_dates } = systemSettings;
  const { receive_incentives, is_single_user_company} = companySettings;

  const companyTabEnabled = Boolean(company_admin && receive_incentives && !is_single_user_company);

  const [companySelected, setCompanySelected] = useState(companyTabEnabled);

  const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

  const TODAY = new Date();

  let statementDate = new Date();

  if (TODAY.getDate() < statement_dates[MONTHS[TODAY.getMonth()]]) {

    statementDate.setDate(statement_dates[MONTHS[TODAY.getMonth()]])

  } else {

    statementDate.setMonth(TODAY.getMonth() + 1);

    statementDate.setDate(statement_dates[MONTHS[statementDate.getMonth()]]);

  }

  const selectedRewards = companySelected ? rewards.company : rewards.personal;

  return (
    <Box pb="36px">
      <RewardsContentWrapper label="MY REWARDS">
        <Flex flexDirection="column" pt="10px" px="8px" pb="10px">
          <Flex
            px="1%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {companyTabEnabled ? (
              <Flex justifyContent="center" alignItems="center">
                <Flex alignItems="center">
                  <NunitoSans
                    onClick={() => setCompanySelected(true)}
                    color={companySelected ? "#000000" : "#6C7168"}
                    fontSize="12px"
                    fontWeight="bold"
                    style={{ cursor: "pointer", paddingRight: "5px", borderRight: '1px solid #E4E5E3' }}
                  >
                    Company
                  </NunitoSans>

                  <NunitoSans
                    onClick={() => setCompanySelected(false)}
                    color={companySelected ? "#c3c3c3" : "#000000"}
                    fontSize="12px"
                    fontWeight="bold"
                    style={{ cursor: "pointer", marginLeft: '5px' }}
                  >
                    Personal
                  </NunitoSans>
                </Flex>
              </Flex>
            ) :
              <NunitoSans
                color={"#000000"}
                fontSize="12px"
                fontWeight="bold"
                style={{ marginLeft: '5px' }}
              >
                Personal
              </NunitoSans>
            }
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <NunitoSans
                fontSize="10px"
                fontWeight="bold"
                color="#c3c3c3"
                justifyContent="center" alignItems="center"
              // style={{ marginBottom: "8px", marginTop: "8px" }}
              >
                {`as of ${moment().format("MM/DD/YY")}`}
              </NunitoSans>
            </Flex>
          </Flex>
          <Flex flexDirection="column" px="1%">
          <RewardsRowGroupWrapperStyled>
                <Flex justifyContent="space-between" alignItems="center">
                  <NunitoSans color="#000000" fontSize="12px" fontWeight="bold">
                    PENDING REWARDS
                  </NunitoSans>
                  <NunitoSans
                    color="#000000"
                    fontSize="18px"
                    fontWeight="bold"
                    style={{ marginLeft: "25px" }}
                  >
                    {currencyParser(selectedRewards.pendingTotal, currency)}
                  </NunitoSans>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <NunitoSans color="#c3c3c3" fontSize="12px" fontWeight="bold">
                    Paid Last Month
                  </NunitoSans>
                  <NunitoSans
                    color="#000000"
                    fontSize="12px"
                    // fontWeight="bold"
                    style={{ marginLeft: "43px" }}
                  >
                    {currencyParser(
                      selectedRewards.lastMonthPaidTotal,
                      currency
                    )}
                  </NunitoSans>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <NunitoSans color="#c3c3c3" fontSize="12px" fontWeight="bold">
                    Paid YTD
                  </NunitoSans>
                  <NunitoSans
                    color="#000000"
                    fontSize="12px"
                    // fontWeight="bold"
                    style={{ marginLeft: "43px" }}
                  >
                    {currencyParser(
                      selectedRewards.paidTotalYtd,
                      currency
                    )}
                  </NunitoSans>
                </Flex>

                <Flex justifyContent="center">
                  {
                    rewards && rewards.personal && rewards.personal.stripeAccountNeeded
                    ?
                    <DashboardSmallCTA
                    color="#428BF4"
                    width="100%"
                    mt="20px"
                    onClick={() => onboardStripeConnectAccount()}
                    >
                      Add Reward Account
                    </DashboardSmallCTA>
                    :
                    <NunitoSansSemiBold
                      fontSize='12px'
                      color='#428BF4'
                      justifyContent="center" alignItems="center"
                      style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '10px' }}
                      onClick={() =>
                        props.redirect(
                          `/dashboard/rewards?date=${moment(
                            statementDate.toJSON().slice(0, 10)
                          ).format("L")}`
                        )
                      }
                    >
                      View Details
                    </NunitoSansSemiBold>
                  }
                </Flex>
              </RewardsRowGroupWrapperStyled>
          </Flex>
        </Flex>
      </RewardsContentWrapper>
    </Box>
  );
};

export const IncentivesAccessory = props => {
  const [show, setShow] = useState(true);



  return (
    <RewardsContentWrapper
      label="INCENTIVES"
    >
      <Flex flexDirection="column" pt="10px" px="8px" pb="10px">
        <Flex px="1%" 
          flexDirection="row" 
          justifyContent="space-between" 
          alignItems="center"
        >

          <NunitoSans
            pt={["4px"]}
            fontSize={["12px"]}
            fontWeight="bold"
            // color="#428BF4"
            color="#000000"
          >
            RECENTLY ADDED
          </NunitoSans>

          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <DashboardSmallCTA
              width="15%"
              onClick={() => setShow(!show)}
              minWidth={["75px"]}
              fontSize="12px"
              innerHeight="25px"
              color="#428BF4"
            >
              {show ? "HIDE" : "SHOW"}
            </DashboardSmallCTA>
          </Flex>

        </Flex>

        <Flex flexDirection="column" px="1%">
          {
            show ? (

              <RewardsRowGroupWrapperStyled>


                <Flex
                  flexDirection="column"
                >
                  {
                    props.incentives.sort((a, b) => b.updated_at - a.updated_at).slice(0, 5).map((item, index) => <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      mb="10px"
                      onClick={() => props.redirect(`/dashboard/incentive/${item.id}`)}
                      style={{ cursor: 'pointer' }}
                      key={item.id}
                      className="incentive_accessory"
                    >
                      <Flex

                        alignItems="center"
                      >

                        <ImageTreatment
                          img={item.company.logo || defaultCompany(item.company.id, item.company.name)}
                          blurRadius={"0px"}
                          imageInset={0}
                          borderRadius={23}
                          innerBorderRadius={20}
                          shadowOffset={0}
                          padding={3}
                          height={40}
                          width={40}
                        />

                        <NunitoSans
                          color="#000000"
                          fontSize="12px"                        
                          fontWeight={400}
                          // color={props.active ? "darkText" : "#c3c3c3"}
                            // fontSize="14px"
                          // fontWeight="bold"
                          // style={{marginLeft:"10px"}}
                          style={{ overflow: "hidden", textOverflow: "ellipsis", width: "12vw", whiteSpace: "nowrap", marginLeft: "10px" }}
                          className="incentive_accessory_text"
                        >
                          {item.title}
                        </NunitoSans>
                      </Flex>

                      <NunitoSans
                        fontSize="12px"                        
                        fontWeight={400}
                        color={props.active ? "darkText" : "#c3c3c3"}
                        // fontWeight="bold"                        
                        width="70%"       
                        style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                        className="incentive_accessory_text"
                      >
                        {moment(item.created_at).utc().fromNow() || "0 hours ago"}

                      </NunitoSans>
                    </Flex>

                    )

                  }
                </Flex>

                {/* <Flex
                justifyContent="center"
                >
                <DashboardSmallCTA
                width="150px"
                // onClick={()=> setShow(!show)}
                minWidth={["75px"]}
                fontSize="12px"
                innerHeight="30px"
                mt="20px"
                // height="30px"
                color="#428BF4"
              >
                VIEW INCENTIVES
              </DashboardSmallCTA>
                </Flex> */}
              </RewardsRowGroupWrapperStyled>
            ) : null

          }
        </Flex>
      </Flex>
    </RewardsContentWrapper>
  );
};

export const SalesAccessory = props => {
  const [show, setShow] = useState(true);

  const CURRENCY = props.currency;

  const columns = [{
    Header: 'INV. DATE',
    accessor: 'invoice_date', // String-based value accessors!
    Cell: row => moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
    width: 64
  },
  {
    Header: 'INVOICE #',
    accessor: 'invoice_number' // String-based value accessors!
  },
  {
    Header: 'COMPANY',
    accessor: 'company_name' // String-based value accessors!
  },
  {
    Header: 'PART #',
    accessor: 'mnfg_pn' // String-based value accessors!
  },
  {
    Header: 'QTY',
    accessor: 'quantity',
    width: 64,
    Cell: props => numberParser(parseFloat(props.value))
  },
  {
    Header: '$',
    accessor: 'cost_per_unit', // String-based value accessors!
    width: 64,
    Cell: props => currencyParser(parseFloat(props.value), CURRENCY)
  }]


  return (
    <RewardsContentWrapper
      label="SALES"
    >
      <Flex flexDirection="column" pt="10px" px="8px" pb="10px">
        <Flex px="1%" flexDirection="row" justifyContent="space-between" alignItems="center">

          <NunitoSans
            pt={["4px"]}
            fontSize={["12px"]}
            fontWeight="bold"
            color="#428BF4"
          >
            SALES
          </NunitoSans>

          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <DashboardSmallCTA
              width="15%"
              onClick={() => setShow(!show)}
              minWidth={["75px"]}
              fontSize="12px"
              innerHeight="25px"
              color="#428BF4"
            >
              {show ? "HIDE" : "SHOW"}
            </DashboardSmallCTA>
          </Flex>

        </Flex>

        <Flex flexDirection="column" px="1%">
          {
            show ? (

              <RewardsRowGroupWrapperStyled>
                <ReactTable
                  className="-striped"
                  resizable={true}
                  data={props.sales}
                  columns={columns}
                  defaultPageSize={5}
                  showPagination={false}
                  style={{ fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none" }}
                  defaultSorted={[
                    {
                      id: "invoice_date",
                      desc: true
                    }
                  ]}
                />
                <Flex
                  justifyContent="center"
                  alignItems="center"

                >
                  <Flex
                    flexDirection="column"
                  >

                  </Flex>




                  <Flex
                    justifyContent="center"
                  >
                    <DashboardSmallCTA
                      width="150px"

                      minWidth={["75px"]}
                      fontSize="12px"
                      innerHeight="30px"
                      mt="20px"
                      // height="30px"
                      color="#428BF4"
                      onClick={() => props.redirect('/dashboard/rewards?target=sales')}
                    >
                      VIEW SALES
                    </DashboardSmallCTA>
                  </Flex>
                </Flex>
              </RewardsRowGroupWrapperStyled>
            ) : null

          }
        </Flex>
      </Flex>
    </RewardsContentWrapper>
  );
};

export const InvoicesAccessory = props => {
  const [show, setShow] = useState(true);

  const [mostRecent, setMostRecent] = useState(true);

  const CURRENCY = props.currency;

  const columns = [{
    Header: 'INV. DATE',
    accessor: 'invoice_date', // String-based value accessors!
    Cell: row => moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
    sortMethod: (a, b) => Date.parse(b) - Date.parse(a),
    width: 64
  },
  {
    Header: 'INVOICE #',
    accessor: 'invoice_number' // String-based value accessors!
  },
  {
    Header: 'DUE DATE',
    accessor: 'due_on', // String-based value accessors!
    Cell: row => moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
    sortMethod: (a, b) => Date.parse(b) - Date.parse(a)
  },
  {
    Header: '$',
    accessor: 'reward', // String-based value accessors!
    width: 64,
    Cell: props => currencyParser(parseFloat(props.value), CURRENCY)
  }]

  return (
    <RewardsContentWrapper
      label="INVOICES"
    >
      <Flex flexDirection="column" pt="10px" px="8px">
        <Flex px="1%" flexDirection="row" justifyContent="space-between" alignItems="center">

          <NunitoSans
            pt={["4px"]}
            fontSize={["12px"]}
            fontWeight="bold"
            color="#428BF4"
          >
            INVOICES
          </NunitoSans>

          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <DashboardSmallCTA
              width="15%"
              onClick={() => setShow(!show)}
              minWidth={["75px"]}
              fontSize="12px"
              innerHeight="25px"
              color="#428BF4"
            >
              {show ? "HIDE" : "SHOW"}
            </DashboardSmallCTA>
          </Flex>

        </Flex>

        <Flex flexDirection="column" px="1%">
          {
            show ? (

              <RewardsRowGroupWrapperStyled>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mb="10px"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <NunitoSans
                      onClick={() => setMostRecent(!mostRecent)}
                      color={mostRecent ? "#428BF4" : "#A3A99E"}
                      fontSize="17px"
                      fontWeight="bold"
                      style={{ cursor: "pointer", marginRight: "30px" }}
                    >
                      <u>Most Recent</u>
                    </NunitoSans>

                    <NunitoSans

                      onClick={() => setMostRecent(!mostRecent)}
                      color={mostRecent ? "#A3A99E" : "#428BF4"}
                      fontSize="17px"
                      fontWeight="bold"
                      style={{ cursor: "pointer" }}
                    >
                      <u>Pending</u>
                    </NunitoSans>

                  </Flex>

                </Flex>
                <ReactTable
                  className="-striped"
                  resizable={true}
                  data={mostRecent ? props.invoices : props.invoices.filter(item => !item.paid)}
                  columns={columns}
                  defaultPageSize={5}
                  showPagination={false}
                  style={{  fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none" }}
                />
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mb="10px"
                >
                  <Flex
                    flexDirection="column"
                  >

                  </Flex>




                  <Flex
                    justifyContent="center"
                  >
                    <DashboardSmallCTA
                      width="150px"

                      minWidth={["75px"]}
                      fontSize="12px"
                      innerHeight="30px"
                      mt="20px"
                      // height="30px"
                      color="#428BF4"
                      onClick={() => props.redirect('/dashboard/rewards?target=invoices')}
                    >
                      VIEW INVOICES
                    </DashboardSmallCTA>
                  </Flex>
                </Flex>
              </RewardsRowGroupWrapperStyled>
            ) : null

          }
        </Flex>
      </Flex>
    </RewardsContentWrapper>
  );
};

export const GettingStartedProcess = ({ user, redirect, receiveIncentives, invitesCheck = {}, type, handleDismiss, salesExist, userSales, isSingleUser = false, hasConnections = false }) => {
  const defaultCompanyLogo = 'https://kickpost-files.s3.amazonaws.com/company-logos/default_company.png';
  const { company } = user;

  if (
    (!isSingleUser && type === "companySetup" && company.name && company.logo && (!company.logo || company.logo !== defaultCompanyLogo) && company.description && company.location && invitesCheck.coworkers && invitesCheck.companyInvites)
    ||
    (isSingleUser && type === "companySetup" && invitesCheck.companyInvites && hasConnections)
    ||
    (!isSingleUser && type === "receiveIncentives" && invitesCheck.salesReps && salesExist)
    ||
    (isSingleUser && type === "receiveIncentives" && salesExist)
  ) {
    handleDismiss(type);

    //dismissing sales rep module if user has stripe connect account
  }

  return (
    <Flex
      flexDirection="column"
      // width="100%"
      style={{ border: '2px dashed #2FD371', borderRadius: '7px', padding: "0px 30px", marginBottom: "45px" }}
    >
      <Flex
        justifyContent="space-between"
        mt="30px"
      >
        <NunitoSansBold
          fontSize="28px"
          style={{ lineHeight: 1.3 }}
        >
          {(isSingleUser && type === 'companySetup') ? "Start building your Partner Network!" : (!isSingleUser && user.company_owner && type === 'companySetup') ? "You’re the Company Account Owner!" : (!isSingleUser && user.company_admin && type === 'companySetup') ? "You’re a Manager!" : (isSingleUser && type === "receiveIncentives") ? "Start earning your Rewards!" : (!isSingleUser && type === "receiveIncentives") ? "Your Company can now receive Rewards" : null}
          <ThickPixelDashD0D0D0 />
        </NunitoSansBold>
        {
          (type === "companySetup" && company.name && company.logo && company.logo !== defaultCompanyLogo && company.description && company.location && invitesCheck.coworkers && invitesCheck.companyInvites)
            ||
            (type === "receiveIncentives" && invitesCheck.salesReps && salesExist)
            ?
            <DashboardSmallCTA
              width="90px"
              minWidth={["75px"]}
              fontSize="10px"
              innerHeight="20px"
              color="#2FD371"
              onClick={() => handleDismiss(type)}
            >
              DISMISS
            </DashboardSmallCTA>
            :
            null
        }
      </Flex>

      <Flex
        flexDirection="column"
        mt="10px"
        mb="10px"
      >
        {
          (!isSingleUser && user.company_owner && type === 'companySetup')
            ?
            <NunitoSans
              fontSize="14px"
              color="#000000"
              style={{ marginBottom: "10px" }}
            >
              As an owner, you’ll manage the setup and settings for your company account. You can transfer account ownership to any other manager in the <span style={{ color: "#2FD371" }}>settings</span>.

            </NunitoSans>
            :
            null
        }
        <NunitoSans
          fontSize="14px"
          color="#000000"
        >
          { 
            (isSingleUser && type === 'companySetup') ?
              "Once you set up your profile, follow the steps below to get started with your new Partner Engagement Network." :
              (!isSingleUser && user.company_owner && type === 'companySetup') ?
                "To get started complete the list of tasks below." :
                (!isSingleUser && user.company_admin && type === 'companySetup') ?
                  "You’re now a manager of your company account! You'll be able to manage your company profile, coworkers and connections." :
                  (type === "receiveIncentives") ?
                    "You can now receive incentives from your industry partners. Start uploading your sales to qualify for rewards!" :
                      null
          }
        </NunitoSans>
      </Flex>

      {
        (user.company_owner || user.company_admin) && !isSingleUser && type === 'companySetup'
          ?
          <Flex
            flexDirection="column"
            mb="30px"
          >
            <GettingStartedRow
              message="1. Add Company Logo"
              fontSize="16px"
              showFirstDash={false}
              showLastDash={true}
              active={!user.company.logo || (user.company.logo && user.company.logo === defaultCompanyLogo)}
              clickHandler={() =>
                !user.company.logo || (user.company.logo && user.company.logo === defaultCompanyLogo)
                  ? redirect(`/dashboard/company/edit/${user.company.id}`)
                  : null
              }
            />
            <GettingStartedRow
              message="2. Add Company Description"
              fontSize="16px"
              showFirstDash={false}
              showLastDash={true}
              active={!user.company.description}
              clickHandler={() =>
                !user.company.description
                  ? redirect(`/dashboard/company/edit/${user.company.id}`)
                  : null
              }
            />
            <GettingStartedRow
              message="3. Add Company Location"
              fontSize="16px"
              showFirstDash={false}
              showLastDash={true}
              active={!user.company.location}
              clickHandler={() =>
                !user.company.location
                  ? redirect(`/dashboard/company/edit/${user.company.id}`)
                  : null
              }
            />
            <GettingStartedRow
              message="4. Invite Team"
              fontSize="16px"
              showFirstDash={false}
              showLastDash={true}
              active={!invitesCheck.coworkers || !invitesCheck.userInvitedCoworker}
              clickHandler={() => !invitesCheck.coworkers || !invitesCheck.userInvitedCoworker ? redirect(`/dashboard/company/${user.company.id}/employees`) : null}
            />
            <GettingStartedRow
              message="5. Invite Partners"
              fontSize="16px"
              showFirstDash={false}
              showLastDash={false}
              active={!invitesCheck.companyInvites || !invitesCheck.userInvitedCompany}
              clickHandler={() => !invitesCheck.companyInvites || !invitesCheck.userInvitedCompany ? redirect(`/dashboard/company/${user.company.id}/invitations`) : null}
            />

          </Flex>
          :
          (isSingleUser && type === 'companySetup')
            ?
            <Flex
              flexDirection="column"
              mb="30px"
            >
              <GettingStartedRow
                message="1. Connect with your Industry Partners"
                fontSize="16px"
                showFirstDash={false}
                showLastDash={true}
                active={!hasConnections}
                clickHandler={() => !hasConnections ? redirect(`/dashboard/company/${user.company.id}/vendors`) : null}
              />
              <GettingStartedRow
                message="2. Invite Industry Partners to join KickPost"
                fontSize="16px"
                showFirstDash={false}
                showLastDash={false}
                active={!invitesCheck.companyInvites || !invitesCheck.userInvitedCompany}
                clickHandler={() => !invitesCheck.companyInvites || !invitesCheck.userInvitedCompany ? redirect(`/dashboard/company/${user.company.id}/invitations`) : null}
              />
            </Flex>
            :
            type === "receiveIncentives"
              ?
              <Flex
                flexDirection="column"
                mb="30px"
              >
                <GettingStartedRow
                  message={isSingleUser ? "1. Upload Your Sales Data" : "1. Upload Sales"}
                  fontSize="16px"
                  showFirstDash={false}
                  showLastDash={false}
                  active={!salesExist || !userSales}
                  clickHandler={() => !salesExist || !userSales ? redirect(`/dashboard/rewards?target=sales`) : null}
                />
              </Flex>
              :
              null
      }

    </Flex>
  );
}

export const ProfileCompletion = props => (
  <RecommendedContentWrapper >
    <Flex flexDirection="column" pt="10px" px="8px">
      <Flex px="1%" flexDirection="row" justifyContent="space-between">
        <H1CondensedSmallD0D0D0>Profile Completion</H1CondensedSmallD0D0D0>
        <NunitoSans fontSize="24px" fontWeight={300}>
          {props.percentComplete}%
        </NunitoSans>
      </Flex>
      <Flex pt={["20px"]} w={1} flexDirection="column" position="relative">
        <ProfileCompletionProgress percent={props.percentComplete} />
      </Flex>
      <Flex flexDirection="column" px="1%">
        <ProfileCompletionRow
          message="Add Profile Image"
          showFirstDash={false}
          showLastDash={true}
          active={props.profilePictureActive}
          clickHandler={() =>
            props.profilePictureActive
              ? props.redirect(`/dashboard/profile/edit/${props.user.id}`)
              : null
          }
        />
        <ProfileCompletionRow
          message="Add Position"
          showFirstDash={false}
          showLastDash={true}
          active={props.profilePosition}
          clickHandler={() =>
            props.profilePosition
              ? props.redirect(`/dashboard/profile/edit/${props.user.id}`)
              : null
          }
        />
        <ProfileCompletionRow
          message="Add Phone Number"
          showFirstDash={false}
          showLastDash={false}
          active={props.profilePhoneNumberActive}
          clickHandler={() =>
            props.profilePhoneNumberActive
              ? props.redirect(`/dashboard/profile/edit/${props.user.id}`)
              : null
          }
        />


      </Flex>
      {/* <Flex justifyContent="center">

      <DashboardSmallCTA
            onClick={props.dismissHandler ? props.dismissHandler : () => {}}
            width="40%"
            minWidth={["90px"]}
            height="34px"
            color="#c3c3c3"
            my="10px"
          >
            DISMISS
      </DashboardSmallCTA>
      </Flex> */}
    </Flex>
  </RecommendedContentWrapper>
);

export const ClickCursorHoverFlex = styled(ClickCursorFlex)`
  transition: background 0.3s;
  &:hover {
    background: #efefef;
  }
`;

export const RecommendedContentCTA = props => (
  <RecommendedContentWrapper>
    <Flex flexDirection="column" pt="10px" px="8px">
      <Flex px="1%" flexDirection="row" justifyContent="space-between">
        <Box maxWidth="80%">
          <H1CondensedSmallD0D0D0>{props.title}</H1CondensedSmallD0D0D0>
        </Box>
      </Flex>
      <Flex flexDirection="column" px="1%">
        <Box py="19px">
          <NunitoSans fontSize="12px" lineHeight="18px" color="darkText">
            {props.message}
          </NunitoSans>
        </Box>
        {/* <OneSixDash height="1px" width="100%" /> */}
        <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {/* <ClickCursorHoverFlex
            my="10px"
            justifyContent="center"
            alignItems="center"
            height={["34px"]}
            width="40%"
            minWidth={["120px"]}
            onClick={props.dismissHandler ? props.dismissHandler : () => {}}
            borderRadius="8px"
            bg="lightGray"
          >
            <NunitoSans
              pt={["2px"]}
              fontSize={["12px"]}
              fontWeight="bold"
              color="#c3c3c3"
            >
              DISMISS
            </NunitoSans>
          </ClickCursorHoverFlex> */}
          <DashboardSmallCTA
            onClick={props.dismissHandler ? props.dismissHandler : () => { }}
            width="40%"
            minWidth={["90px"]}
            height="34px"
            color="#c3c3c3"
            my="10px"
          >
            DISMISS
          </DashboardSmallCTA>
          {/* <OneSixDash width="1px" height="36px" /> */}
          <DashboardSmallCTA
            onClick={() => props.redirect("/dashboard/posts/new")}
            width="40%"
            minWidth={["90px"]}
            height="34px"
          >
            ADD POST
          </DashboardSmallCTA>
        </Flex>
      </Flex>
    </Flex>
  </RecommendedContentWrapper>
);

export const PaymentEdit = props => (
  <Flex
    mb="54px"
    flexDirection={["column", "row"]}
    minHeight="85px"
    width="100%"
    justifyContent="spaceBetween"
    alignItems="center"
    borderRadius="10px"
    bg="lightGray"
  >
    <ChangePaymentMethod
      mt="8px"
      ml={"8px"}
      height="72px"
      width="72px"
      display={["none", "block"]}
    />
    <Box flex={"2 1 auto"} ml={[0, "10px"]} mt={["24px", "2px"]}>
      <NunitoSans fontWeight={300} fontSize="24px">
        Change Payment Method
      </NunitoSans>
    </Box>
    <DashboardSmallCTA

      mt={["10px", 0]}
      mb={["24px", 0]}
      mr={[0, "23px"]}
      width={["140px"]}
    >
      {((props.paid === true) || (props.paid === "true")) ? (
        <StripeCheckout
          allowRememberMe={false}
          name="KickPost"
          description="Change Payment Info"
          // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
          image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
          currency="USD"
          panelLabel="Update Card Info"
          stripeKey={
            process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
          }
          email={props.user.email}
          billingAddress={true}
          zipCode={true}
          token={token => props.submitStripe(token, "update")}
        >
          MANAGE


        </StripeCheckout>
      ) : null}

    </DashboardSmallCTA>
  </Flex>
);

export const BillingModal = ({ company, dispatch, updateBillingInfo, modalIsOpen, handleCloseRequest, initialCreateIncentives }) => {



  const companyOwner = (company && company.users && company.users.length && company.users.filter(user => user.company_owner)[0]) || { first_name: '', last_name: '' };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "60vw",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px"
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  const handleBillingInfo = data => {
    updateBillingInfo(data);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleCloseRequest()}
      style={customStyles}
      contentLabel="Billing Modal"
      ariaHideApp={false}
    >

      <Flex
        // justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >


        <NunitoSans
          alignText="center"
          fontSize="35px"
          fontWeight="bold"
        // width="100%"
        >
          {company && company.billing_address ? "Edit Billing Info" : "Add Billing Info"}

        </NunitoSans>
        <br />
        <NunitoSans>
          * Please note that incentive invoice notifications will be emailed to your billing contact.
        </NunitoSans>
        <br />
        <Box
          width="70%"
        >

          <BillingForm
            company={company}
            key={["BillingForm-UI"]}
            onSubmit={handleBillingInfo}
            initialValues={{
              billing_contact: company && company.billing_contact || `${companyOwner.first_name} ${companyOwner.last_name}`,
              ...company.billing_address
            }}
          // updateDescription={this._updateDescription}
          />
        </Box>



        <br />
        <DashboardSmallCTA
          onClick={() => dispatch(submit("billing-info"))}
          mt={["10px", 0]}
          mb={["24px", 0]}
          mr={[0, "23px"]}
          width={["140px"]}
          color="#428BF4"
        >

          {initialCreateIncentives ? 'ACTIVATE' : 'SAVE'}
        </DashboardSmallCTA>

      </Flex>

    </Modal>
  )

}

export const IncentivesActivationModal = ({ modalIsOpen, handleCloseRequest, handleConfirmRequest, modalType, hasNextPage, goNext, processingFee = 0, isSingleUser = false }) => {




  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "698px",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '43px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleCloseRequest()}
      style={customStyles}
      contentLabel="Incentives Modal"
      ariaHideApp={false}
    >

      <Flex
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
      >
        <NunitoSans
          alignSelf="center"
          fontSize="35px"
          fontWeight="bold"
        >
          {modalType === 'create_incentives' ? 'Create Sales Incentives' : modalType === 'create_vendor_incentives' ? 'Create Partner Incentives' : modalType === 'receive_vendor_incentives' ? 'Receive Partner Incentives' : 'Receive Sales Incentives'}
        </NunitoSans>
        <br />
        <Flex
          justifyContent="center"
          alignSelf="center"
          flexDirection="column"
        >
          <img src={modalType === 'create_incentives' || modalType === 'create_vendor_incentives' ? createIncentives : receiveIncentives} alt={modalType === 'create_incentives' || modalType === 'create_vendor_incentives' ? 'upward treding line' : 'dollar sign'} width="200"></img>
        </Flex>
        <br />
        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              `Drive sales performance with your partners by offering them incentives. We've streamlined the ${modalType === 'create_incentives' ? 'sales' : 'partner'} incentive process so you can focus on growing sales, instead of managing programs.`
              :
              isSingleUser
                ?
                "Allow your partner network to motivate you by offering you incentives. We’ve streamlined the sales incentive process so you can get paid faster, save time and focus on growing sales."
                :
                `Allow your partners to motivate your team by offering ${modalType === 'receive_vendor_incentives' ? 'your company partner incentives' : 'them incentives'}. We've streamlined the ${modalType === 'receive_vendor_incentives' ? 'partner incentive process so your company' : 'sales incentive process so your team'} can save time and focus on growing sales.`
          }
        </NunitoSans>
        <br />
        <NunitoSans>
          Here's how it works:
        </NunitoSans>
        <br />
        <NunitoSansBold>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              "Add Incentives"
              :
              "View and Track Incentives"
          }
        </NunitoSansBold>
        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              `Create ${modalType === 'create_incentives' ? 'sales' : 'partner'} incentive programs that match your business goals and choose who you want to participate in them.`
              :
              "You can view and track every incentive program offered by your partners."
          }
        </NunitoSans>
        <br />
        <NunitoSansBold>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              "Track Results"
              :
              "Upload Sales"
          }
        </NunitoSansBold>
        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              `Using verified sales data uploaded by your partners, you can track your program results and see which ${modalType === 'create_incentives' ? 'sales reps' : 'partners'} are earning rewards.`
              :
              "To receive accurate payouts you're required to upload your sales data prior to the monthly deadline."
          }
        </NunitoSans>
        <br />
        <NunitoSansBold>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              "Pay Rewards"
              :
              "Get Paid"
          }
        </NunitoSansBold>
        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              `At the end of each incentive program you'll receive a single invoice with backup reporting and once paid, rewards are paid directly to ${modalType === 'create_incentives' ? 'sales reps' : 'your partners'}.`
              :
              isSingleUser
                ?
                "Once your partners pay their invoices your reward money will be transferred to your reward account."
                :
                `Once your partners pay their invoices your${modalType === 'receive_vendor_incentives' ? '' : ' teams'} money will be transferred to ${modalType === 'receive_vendor_incentives' ? 'your reward account' : ' their reward accounts'}.`
          }
        </NunitoSans>
        <br />
        <NunitoSansBold>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              "Processing Fee"
              :
              null
          }
        </NunitoSansBold>
        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              `A ${processingFee}% processing fee will be applied to invoices based on the total rewards calculated.`
              :
              null
          }
        </NunitoSans>
        <br />

        <NunitoSans>
          {
            modalType === 'create_incentives'
              ||
              modalType === 'create_vendor_incentives'
              ?
              "You're always in control of your incentives, we've built in controls that allow you the flexibility to manage your incentives at every step. Our system is completely transparent and we're always here to assist you."
              :
              isSingleUser
                ?
                "The system is designed to make the incentive process simple and smooth for everyone. We invoice your partners and manage all the hard stuff. We even take care of taxes by issuing a single 1099 form to you. Our system is transparent and we're always here to assist you."
                :
                `The system is designed to make the ${modalType === 'receive_vendor_incentives' ? 'partner' : 'sales'} incentive process simple and smooth for everyone. We invoice your partners and manage all the hard stuff.${modalType === 'receive_vendor_incentives' ? '' : ' We even take care of taxes by issuing a single 1099 form to each participant.'} Our system is transparent and we're always here to assist you.`
          }
        </NunitoSans>
        <br />

        <Flex
          width="40%"
          justifyContent='space-between'
          alignItems='center'
          alignSelf="center"
        >
          <DashboardSmallCTA
            onClick={() => handleCloseRequest()}
            mt={["10px", 0]}
            mb={["24px", 0]}
            mr={[0, "23px"]}
            width={["140px"]}
            color="#FE4A49"

          >
            CANCEL
          </DashboardSmallCTA>
          <DashboardSmallCTA
            onClick={() => hasNextPage ? goNext() : handleConfirmRequest(modalType)}
            mt={["10px", 0]}
            mb={["24px", 0]}
            mr={[0, "23px"]}
            width={["140px"]}
            color="#428BF4"

          >
            {hasNextPage ? "CONTINUE" : "CONFIRM"}
          </DashboardSmallCTA>
        </Flex>



      </Flex>

    </Modal>
  );
}



export const SettingsRewards = ({ company, dispatch, deactivateRewards, rewardsActivation }) => {


  const [modalIsOpen, setModalIsOpen] = useState(false);



  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "698px",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '43px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  const handleRewards = () => {
    rewardsActivation();
    setModalIsOpen(false);

  }


  return (
    <>
      {
        company && company.rewards_active ?


          <DashboardSmallCTA
            onClick={() => {
              deactivateRewards();
              setModalIsOpen(false);
            }}
            minWidth={["122px"]}
            fontSize="14px"
            innerHeight="30px"
            width="130px"
            color="#428BF4"
          >
            DEACTIVATE
          </DashboardSmallCTA>

          :
          <DashboardSmallCTA
            onClick={() => !modalIsOpen ? setModalIsOpen(true) : {}}
            minWidth={["122px"]}
            fontSize="14px"
            innerHeight="30px"
            width="130px"
            color="#428BF4"
          >
            GET STARTED
          </DashboardSmallCTA>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);

        }}
        style={customStyles}
        contentLabel="Rewards Modal"
        ariaHideApp={false}
      >

        <Flex
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
        >
          <NunitoSans
            alignSelf="center"
            fontSize="35px"
            fontWeight="bold"
          >
            KickPost Rewards
          </NunitoSans>
          <br />
          <Flex
            justifyContent="center"
            alignSelf="center"
            flexDirection="column"
          >
            <img src={rewardStar} alt="Yellow Shoting Star" width="200" class="center"></img>
          </Flex>
          <br />
          <NunitoSans>
            Is an automated incentive management system that allows you and your connections to create and/or receive sales incentives. It automates the entire incentive cycle from start to finish:
          </NunitoSans>
          <br />

          <NunitoSansBold>
            Creation and Sharing
          </NunitoSansBold>
          <NunitoSans>
            Incentive programs are created on the system just like posts.
          </NunitoSans>
          <br />
          <NunitoSansBold>
            Sharing and Track
          </NunitoSansBold>
          <NunitoSans>
            Users can view incentive details and track their results at anytime.
          </NunitoSans>
          <br />
          <NunitoSansBold>
            Calculate Results
          </NunitoSansBold>
          <NunitoSans>
            Using company verified sales data uploaded by your partners we calculate rewards for all participants.
          </NunitoSans>
          <br />

          <NunitoSansBold>
            Payout Rewards
          </NunitoSansBold>
          <NunitoSans>
            Once partners pay their incentive invoices users receive rewards via their KickPost Reward accounts.
          </NunitoSans>
          <br />

          <NunitoSans>
            With KickPost Rewards you don’t sweat the details, we handle all the hard stuff and make the process simple. So you and your parnters can focus your time on driving sales.
          </NunitoSans>
          <br />


          <DashboardSmallCTA
            onClick={() => handleRewards()}
            mt={["10px", 0]}
            mb={["24px", 0]}
            mr={[0, "23px"]}
            width={["140px"]}
            color="#428BF4"
            alignSelf="center"
          >
            ACTIVATE
          </DashboardSmallCTA>

        </Flex>

      </Modal>
    </>
  );
}


export const SettingsPaymentEdit = props => (
  <DashboardSmallCTA
    minWidth={["122px"]}
    fontSize="14px"
    innerHeight="30px"
    width="130px"
    color="#428BF4"
  >

    <StripeCheckout
      allowRememberMe={false}
      name="KickPost"
      description="Change Payment Info"
      // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
      image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
      currency="USD"
      panelLabel="Update Card Info"
      stripeKey={
        process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
      }
      email={props.user.email}
      billingAddress={true}
      zipCode={true}
      token={token => props.submitStripe(token, "update")}
    >
      MANAGE


    </StripeCheckout>


  </DashboardSmallCTA>
);

export const SettingsBilling = ({ user, company, submitStripe, handleInvoiceInfo, dispatch, isSingleUser, customButtonText = '', customWidth }) => {

  const companyOwner = (company && company.users && company.users.length && company.users.filter(user => user.company_owner)[0]) || { first_name: '', last_name: '' };

  const [annualModalOpen, setAnnualModalOpen] = useState(false);

  const [billingModalOpen, setBillingModalOpen] = useState(false);

  const [processing, setProcessing] = useState(false);

  const handleBillingInfo = async data => {
    setProcessing(true);
    await handleInvoiceInfo(data);
    setProcessing(false);
    setBillingModalOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "300px",
      // maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '10px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  const billingStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "500px",
      maxHeight: "90vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '10px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  return (
    <Flex
      width="100%"
    >

      {/* <StripeCheckout
             allowRememberMe={false}
             name={isSingleUser ? "$67/mo" : "$250/mo + $50/active user"}
            description={isSingleUser ? "" : "First 5 active users on us!"}
             // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
             image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
             currency="USD"
             panelLabel="Subscribe"
             stripeKey={
               process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
             }
             email={user.email}
             billingAddress={true}
             zipCode={true}
             token={token => submitStripe(token, isSingleUser ? "individual" : "company")}
             
           >
             <DashboardSmallCTA
             mr='10px'
             minWidth={["122px"]}
            fontSize="14px"
            innerHeight="30px"
            width="130px"
            color="#428BF4"
           >
              UPGRADE
           </DashboardSmallCTA>
             
             
           </StripeCheckout> */}

      <Flex
        flexDirection="column"
        width="100%"
      >
        {/* <StripeCheckoutButton
          // mr='10px'
          minWidth={["122px"]}
          fontSize="16px"
          innerHeight="30px"
          width={customWidth || "130px"}
          color="#428BF4"
        >
          {'MONTHLY'}
        </StripeCheckoutButton> */}

        {/* <StripeCheckoutButton
          mt="10px"
          minWidth={["122px"]}
          fontSize="16px"
          innerHeight="30px"
          width={customWidth || "130px"}
          color="#428BF4"
          annual={true}
        >
          {'ANNUAL'}
        </StripeCheckoutButton> */}

      </Flex>


      {/* {
             true || isSingleUser
             ?
                <StripeCheckoutButton
                // mr='10px'
                minWidth={["122px"]}
                fontSize="16px"
                innerHeight="30px"
                width={customWidth || "130px"}
                color="#2FD371"
                >
                  {customButtonText || 'UPGRADE'}
                </StripeCheckoutButton>
             :

            <DashboardSmallCTA  
                minWidth={["122px"]}
                fontSize="14px"
                innerHeight="30px"
                width="130px"
                color="#428BF4"
                onClick={()=>setAnnualModalOpen(true)}
                >
              
              UPGRADE
              </DashboardSmallCTA>
           } */}





      <Modal
        isOpen={annualModalOpen}
        onRequestClose={() => {
          setAnnualModalOpen(false);
        }}
        style={customStyles}
        contentLabel="Annual Modal"
        ariaHideApp={false}
      >
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <NunitoSansBold>
            Payment Method
          </NunitoSansBold>
          <NunitoSans>
            How would you like to pay?
          </NunitoSans>
          <Flex
            alignItems='center'
            mt='15px'
          >

            {/* <StripeCheckout
                    allowRememberMe={false}
                    name="Annual Plan - $3,204/year"
                    description="Save $360"
                    ComponentClass="span"
                    // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
                    image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
                    currency="USD"
                    panelLabel="Subscribe"
                    stripeKey={
                      process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
                    }
                    email={user.email}
                    billingAddress={true}
                    zipCode={true}
                    token={token => submitStripe(token, "annual")}
                    >
                      <DashboardSmallCTA
                      minWidth={["122px"]}
                      fontSize="14px"
                      innerHeight="30px"
                      width="130px"
                      color="#428BF4"
                      onClick={()=>setAnnualModalOpen(false)}
                      >
                        CARD
                      </DashboardSmallCTA>
                        
                    </StripeCheckout> */}

            {/* <StripeCheckoutButton
              mr='10px'
              minWidth={["122px"]}
              fontSize="14px"
              innerHeight="30px"
              width="130px"
              color="#428BF4"
            >
              CARD
            </StripeCheckoutButton> */}

            <DashboardSmallCTA
              minWidth={["122px"]}
              fontSize="14px"
              innerHeight="30px"
              width="130px"
              color="#428BF4"
              ml='20px'

              onClick={() => {
                setAnnualModalOpen(false);
                setBillingModalOpen(true);
              }}
            >
              INVOICE
            </DashboardSmallCTA>
          </Flex>
        </Flex>


      </Modal>

      <Modal
        isOpen={billingModalOpen}
        onRequestClose={() => {
          setBillingModalOpen(false);
        }}
        style={billingStyles}
        contentLabel="Billing Modal"
        ariaHideApp={false}
      >
        <Flex
          width='100%'
          flexDirection='column'

        >

          <NunitoSansBold
            alignSelf='center'
            mb='15px'
          >
            Billing Info
          </NunitoSansBold>
          <BillingForm
            company={company}
            onSubmit={handleBillingInfo}
            showPO={true}
            initialValues={{
              billing_contact: company && company.billing_contact || `${companyOwner.first_name} ${companyOwner.last_name}`,
              ...company.billing_address
            }}
          // updateDescription={this._updateDescription}
          />
          <br />
          {
            processing
              ?
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                <FontAwesomeIcon
                  color='#428BF4'
                  icon={faCog}
                  size='lg'
                  spin
                />
                <NunitoSans
                  color='#428BF4'
                  fontSize='14px'
                >
                  Processing...
                </NunitoSans>
              </Flex>
              :
              <DashboardSmallCTA
                onClick={() => dispatch(submit("billing-info"))}
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px"]}
                color="#428BF4"
                alignSelf='center'
              >

                ACTIVATE
              </DashboardSmallCTA>
          }
        </Flex>
      </Modal>

    </Flex>
  )
}

export const BillingCTA = props => (
  <BillingContentWrapper>
    <Flex flexDirection="column" pt="16px" px="8px">
      <Flex px="1%" flexDirection="row" justifyContent="space-between">
        <Box maxWidth="80%">
          <H1CondensedSmallD0D0D0>{props.title || ""}</H1CondensedSmallD0D0D0>
        </Box>
      </Flex>
      <Flex flexDirection="column" px="1%">
        <Box py="19px">
          <NunitoSans fontSize="12px" lineHeight="18px" color="darkText">
            {props.message || ""}
          </NunitoSans>
          {/* <br/>
          <NunitoSans fontSize="12px" lineHeight="18px" color="darkText">
            Are you interested in <strong>Enterprise Edition</strong>? <a href="https://enterprise.kickpost.io" target="_blank" style={{color:"#2FD371"}} >Click Here</a> to learn more...
          </NunitoSans> */}
        </Box>
        <OneSixDash height="1px" width="100%" />
        {/* <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <ClickCursorHoverFlex
            my="10px"
            justifyContent="center"
            alignItems="center"
            height={["34px"]}
            width="40%"
            minWidth={["120px"]}
            onClick={props.dismissHandler ? props.dismissHandler : () => {}}
            borderRadius="8px"
            bg="lightGray"
          >
            <NunitoSans
              pt={["2px"]}
              fontSize={["12px"]}
              fontWeight="bold"
              color="#c3c3c3"
            >
              DISMISS
            </NunitoSans>
          </ClickCursorHoverFlex>
          <OneSixDash width="1px" height="36px" />
          <DashboardSmallCTA
            onClick={() => props.redirect("/dashboard/posts/new")}
            width="40%"
            minWidth={["90px"]}
            height="34px"
          >
            ADD POST
          </DashboardSmallCTA>
        </Flex> */}
        <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >

          {((props.paid === false) || (props.paid === "false")) ? (

            //   <ClickCursorHoverFlex
            //     my="10px"
            //     justifyContent="center"
            //     alignItems="center"
            //     height={["34px"]}
            //     width="40%"
            //     minWidth={["120px"]}
            //     borderRadius="8px"
            //     bg="lightGray"
            //   >
            //   <StripeCheckout
            //   allowRememberMe={false}
            //   name="Monthly Plan - $297/mo"
            //   description="Save $360 with Annual Plan"
            //   // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
            //   image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
            //   currency="USD"
            //   panelLabel="Subscribe"
            //   stripeKey={
            //     process.env.REACT_APP_STRIPE_KEY // cents
            //   }
            //   email={props.user.email}
            //   billingAddress={true}
            //   zipCode={true}
            //   token={token => props.submitStripe(token, "monthly")}
            // >

            //     <NunitoSans
            //       pt={["2px"]}
            //       fontSize={["12px"]}
            //       fontWeight="bold"
            //       color="#c3c3c3"
            //     >
            //       MONTHLY
            //     </NunitoSans>
            // </StripeCheckout>
            //   </ClickCursorHoverFlex>
            <DashboardSmallCTA

              width="40%"
              minWidth={["90px"]}
              height="34px"
              color="#c3c3c3"
              my="10px"
            >
              <StripeCheckout
                allowRememberMe={false}
                name="Monthly Plan - $297/mo"
                description="Save $360 with Annual Plan"
                // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
                image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
                currency="USD"
                panelLabel="Subscribe"
                stripeKey={
                  process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
                }
                email={props.user.email}
                billingAddress={true}
                zipCode={true}
                token={token => props.submitStripe(token, "monthly")}
              >
                MONTHLY
              </StripeCheckout>
            </DashboardSmallCTA>
          ) : null}

          <OneSixDash width="1px" height="36px" />

          {((props.paid === false) || (props.paid === "false")) ? (

            <DashboardSmallCTA
              width="40%"
              minWidth={["90px"]}
              height="34px"
            >
              <StripeCheckout
                allowRememberMe={false}
                name="Annual Plan - $3,204/year"
                description="Save $360"
                ComponentClass="span"
                // image="https://s3.amazonaws.com/kickpost-files/assets/kp-logo.png"
                image="https://files.stripe.com/files/f_live_lGd2qeL7TXQTyZT0bnFwRBxU"
                currency="USD"
                panelLabel="Subscribe"
                stripeKey={
                  process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY
                }
                email={props.user.email}
                billingAddress={true}
                zipCode={true}
                token={token => props.submitStripe(token, "annual")}
              >
                ANNUAL
              </StripeCheckout>
            </DashboardSmallCTA>

          ) : null}

        </Flex>
      </Flex>
    </Flex>
  </BillingContentWrapper>
);

export const RewardsCTA = props => (
  <BillingContentWrapper>
    <Flex flexDirection="column" pt="16px" px="8px">
      <Flex px="1%" flexDirection="row" justifyContent="space-between">
        <Box maxWidth="80%">
          <H1CondensedSmallD0D0D0>{props.title || ""}</H1CondensedSmallD0D0D0>
        </Box>
      </Flex>
      <Flex flexDirection="column" px="1%">
        <Box py="19px">
          <NunitoSans fontSize="12px" lineHeight="18px" color="darkText">
            {props.message || ""}
          </NunitoSans>

        </Box>
        <OneSixDash height="1px" width="100%" />

        <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >


          <DashboardSmallCTA
            onClick={props.dismissHandler ? props.dismissHandler : () => { }}
            width="40%"
            minWidth={["90px"]}
            height="34px"
            color="#c3c3c3"
            my="10px"
          >

            DISMISS

          </DashboardSmallCTA>


          <OneSixDash width="1px" height="36px" />



          <DashboardSmallCTA
            onClick={() => props.redirect(`/dashboard/company/edit/${props.user.company_id}?rewards`)}
            width="40%"
            minWidth={["90px"]}
            height="34px"
            color="#428BF4"
          >

            GET STARTED

          </DashboardSmallCTA>



        </Flex>
      </Flex>
    </Flex>
  </BillingContentWrapper>
);

class InviteEmployeeCTA extends React.Component {

  state = {
    placeHolderEmail: 'jimsmith@company.com',
    placeHolderFirstName: 'Jim',
    placeHolderLastName: 'Smith',
    charCount: 300 - this.props.initialValues.message.length
  }


  componentDidUpdate(prevProps) {
    if (prevProps.initialized && !this.props.initialized) {
      const message = "I added you as a user on our KickPost account. Sign in to get started.";
      this.props.initialize({
        message
      });

      this.setState({ charCount: 300 - message.length })
    }
  }

  render() {
    const { charCount } = this.state;
    const { handleSubmit, mobile, handleCsvUpload } = this.props;
    console.log(this.state,this.props);

    return (
      <RecommendedContentWrapper noLabel={true}>

        <Flex flexDirection="column" pt="10px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Add Teammate</H1CondensedSmallD0D0D0>
            </Box>

            {
              handleCsvUpload
                ?
                <DashboardSmallCTA
                  // width="50%"
                  onClick={() => handleCsvUpload()}
                  minWidth={["90px"]}
                  my="12px"
                  height="34px"
                  fontSize="11px"
                >
                  UPLOAD CSV
                </DashboardSmallCTA>
                :
                null
            }
          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">

            <NunitoSansSemiBold
              fontSize="16px"
              color="#000000"
            >
              Add your team member to your KickPost account.
            </NunitoSansSemiBold>

          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">
            <Box>
              <Field
                name="email"
                label="WORK EMAIL"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                mb="15px"
                placeholder={this.state.placeHolderEmail}
                onFocus={() => this.setState({ placeHolderEmail: "" })}
                onBlur={() => this.setState({ placeHolderEmail: 'jimsmith@company.com' })}
                component={renderSmallField}

              />
            </Box>
            <Box>
              <Field
                name="first_name"
                label="FIRST NAME"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                mb="0px"
                placeholder={this.state.placeHolderFirstName}
                onFocus={() => this.setState({ placeHolderFirstName: "" })}
                onBlur={() => this.setState({ placeHolderFirstName: 'Jim' })}
                component={renderSmallField}
              />
            </Box>
            <Box>
              <Field
                name="last_name"
                label="LAST NAME"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                mb="0px"
                placeholder={this.state.placeHolderLastName}
                onFocus={() => this.setState({ placeHolderLastName: "" })}
                onBlur={() => this.setState({ placeHolderLastName: 'Smith' })}
                component={renderSmallField}
              />
            </Box>

            <Box>
              <Field
                name="message"
                label="Invite Message"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                labelTextTransform="none"
                fontSize="18px"

                component={renderSmallTextAreaField}
                onChange={(e) => this.setState({ charCount: 300 - e.target.value.length })}
              // defaultValue={"Join our KickPost account so you can help us engage with our partner network."}

              />

              <Flex
                justifyContent="flex-end"
              >

                <NunitoSans
                  fontSize='12px'
                  style={{ position: 'relative', top: '-30px' }}
                  color={charCount < 0 ? '#FE4A49' : '#979797'}
                >
                  {`${charCount} characters remaining`}
                </NunitoSans>

              </Flex>
            </Box>

            <Flex flexDirection="row" justifyContent="center" alignItems="center">

              <DashboardSmallCTA
                onClick={() => handleSubmit()}
                width="50%"
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                ADD
              </DashboardSmallCTA>

            </Flex>
          </Flex>
        </Flex>

      </RecommendedContentWrapper>
    );
  }
};

InviteEmployeeCTA = reduxForm({
  form: "create-user",
  // enableReinitialize : true,
  // destroyOnUnmount: false
})(InviteEmployeeCTA);

class InviteVendorCTA extends React.Component {
  //console.log(handleSubmit)

  state = {
    placeHolderEmail: 'jimsmith@company.com',
    placeHolderFirstName: 'Jim',
    placeHolderLastName: 'Smith',
    charCount: 300 - this.props.initialValues.message.length
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialized && !this.props.initialized) {
      const message = "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales.";
      this.props.initialize({
        message
      });

      this.setState({ charCount: 300 - message.length })
    }

  }

  render() {
    const { charCount } = this.state;
    const { handleSubmit, mobile, handleCsvUpload } = this.props;

    return (
      <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="10px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Invite</H1CondensedSmallD0D0D0>
            </Box>

            {
              handleCsvUpload
                ?
                <DashboardSmallCTA

                  onClick={() => handleCsvUpload()}
                  minWidth={["110px"]}
                  my="12px"
                  height="34px"
                  fontSize="11px"
                  style={{ paddingRight: '5px', paddingLeft: '5px' }}
                >
                  UPLOAD INVITES
                </DashboardSmallCTA>
                :
                null
            }

          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">

            <NunitoSansSemiBold
              fontSize="16px"
              color="#000000"
            >
              Invite new members to join KickPost.
            </NunitoSansSemiBold>

          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">
            <Box>
              <Field
                name="email"
                label="WORK EMAIL"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                placeholder={this.state.placeHolderEmail}
                onFocus={() => this.setState({ placeHolderEmail: "" })}
                onBlur={() => this.setState({ placeHolderEmail: 'jimsmith@company.com' })}
                component={renderSmallField}
                mb="0px"
              />
            </Box>
            <Box>
              <Field
                name="first_name"
                label="FIRST NAME"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                placeholder={this.state.placeHolderFirstName}
                onFocus={() => this.setState({ placeHolderFirstName: "" })}
                onBlur={() => this.setState({ placeHolderFirstName: 'Jim' })}
                component={renderSmallField}
                mb="0px"
              />
            </Box>
            <Box>
              <Field
                name="last_name"
                label="LAST NAME"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                fontSize="18px"
                placeholder={this.state.placeHolderLastName}
                onFocus={() => this.setState({ placeHolderLastName: "" })}
                onBlur={() => this.setState({ placeHolderLastName: 'Smith' })}
                component={renderSmallField}
                mb="0px"
              />
            </Box>
            <Box>
              <Field
                name="message"
                label="Invite Message"
                type="text"
                labelFontSize="14px"
                labelFontWeight={600}
                labelTextTransform="none"
                fontSize="18px"
                component={renderSmallTextAreaField}
                onChange={(e) => this.setState({ charCount: 300 - e.target.value.length })}

              // defaultValue="Join our partner engagement network so we can work together more efficiently."
              />
              <Flex
                justifyContent="flex-end"
              >

                <NunitoSans
                  fontSize='12px'
                  style={{ position: 'relative', top: '-30px' }}
                  color={charCount < 0 ? '#FE4A49' : '#979797'}
                >
                  {`${charCount} characters remaining`}
                </NunitoSans>

              </Flex>
            </Box>
            {/* <OneSixDash height="1px" width="100%" /> */}
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="50%"
                onClick={() => handleSubmit()}
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                INVITE
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper>
    );
  }
};

let vendorValidate = values => {
  console.log(values)
};
InviteVendorCTA = reduxForm({
  form: "create-vendor",
  // enableReinitialize : true,
  // destroyOnUnmount: false
})(InviteVendorCTA);

let validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Department name required";
  }

  return errors;
};

class CreateDepartmentCTA extends React.Component {

  state = {
    placeHolder: "Channel Sales"
  }

  render() {

    const { handleSubmit } = this.props;


    return (
      <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="10px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Create Department</H1CondensedSmallD0D0D0>
            </Box>
          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">
            <Box>
              <Field
                mb="10px"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                name="name"
                label="DEPARTMENT NAME"
                type="text"
                placeholder={this.state.placeHolder}
                onFocus={() => {
                  this.setState({ placeHolder: '' });
                  this.props.handleFocus();
                }}
                onBlur={() => this.setState({ placeHolder: 'Channel Sales' })}
                component={renderSmallField}
              />
            </Box>
            <OneSixDash height="1px" width="100%" />
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                onClick={() => handleSubmit()}
                //              onClick={() => handleSubmit(this.handleSubmit)}
                width="30%"
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                CREATE
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper>
    );
  }
};

CreateDepartmentCTA = reduxForm({
  form: "create-department",
  validate
})(CreateDepartmentCTA);

let EditDepartmentCTA = ({ ...props }) => {
  const { handleSubmit, setDepartment } = props;

  return (
    <RecommendedContentWrapper noLabel={true}>
      <Flex flexDirection="column" pt="10px" px="8px">
        <Flex px="1%" flexDirection="row" justifyContent="space-between">
          <Box maxWidth="80%">
            <H1CondensedSmallD0D0D0>Edit Department</H1CondensedSmallD0D0D0>
          </Box>
        </Flex>
        <Flex pt="19px" flexDirection="column" px="1%">
          <Box>
            <Field
              mb="10px"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="off"
              name="name"
              label="DEPARTMENT NAME"
              type="text"
              placeholder="Channel Sales"
              component={renderSmallField}
            />
          </Box>
          <OneSixDash height="1px" width="100%" />
          <Flex
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <ClickCursorFlex
              my="10px"
              justifyContent="center"
              alignItems="center"
              height={["34px"]}
              width="30%"
              minWidth={["120px"]}
              borderRadius="8px"
              bg="lightGray"
              onClick={setDepartment}
            >
              <NunitoSans
                pt={["2px"]}
                fontSize={["12px"]}
                fontWeight="bold"
                color="#c3c3c3"
              >
                CANCEL
              </NunitoSans>
            </ClickCursorFlex>
            <OneSixDash width="1px" height="36px" />
            <DashboardSmallCTA
              onClick={() => handleSubmit()}
              //              onClick={() => handleSubmit(this.handleSubmit)}
              width="30%"
              minWidth={["90px"]}
              my="12px"
              height="34px"
            >
              SAVE
            </DashboardSmallCTA>
          </Flex>
        </Flex>
      </Flex>
    </RecommendedContentWrapper>
  );
};

EditDepartmentCTA = reduxForm({
  form: "edit-department",
  validate
})(EditDepartmentCTA);





class CreateGroupCTA extends React.Component {

  state = {
    placeHolder: "Gold Vendors"
  }

  render() {

    const { handleSubmit } = this.props;


    return (
      <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="10px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Create Group</H1CondensedSmallD0D0D0>
            </Box>
          </Flex>
          <Flex pt="19px" flexDirection="column" px="1%">
            <Box>
              <Field
                mb="10px"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                name="name"
                label="GROUP NAME"
                type="text"
                placeholder={this.state.placeHolder}
                onFocus={() => {
                  this.setState({ placeHolder: '' });
                  this.props.handleFocus();
                }}
                onBlur={() => this.setState({ placeHolder: 'Gold Vendors' })}
                component={renderSmallField}
              />
            </Box>
            <OneSixDash height="1px" width="100%" />
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                onClick={() => handleSubmit()}
                //              onClick={() => handleSubmit(this.handleSubmit)}
                width="30%"
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                CREATE
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper>
    );
  }
};

let validateGroup = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Group name required";
  }

  return errors;
};

CreateGroupCTA = reduxForm({
  form: "create-group",
  validateGroup
})(CreateGroupCTA);

let EditGroupCTA = ({ ...props }) => {
  const { handleSubmit, setGroup } = props;

  return (
    <RecommendedContentWrapper noLabel={true}>
      <Flex flexDirection="column" pt="10px" px="8px">
        <Flex px="1%" flexDirection="row" justifyContent="space-between">
          <Box maxWidth="80%">
            <H1CondensedSmallD0D0D0>Edit Group</H1CondensedSmallD0D0D0>
          </Box>
        </Flex>
        <Flex pt="19px" flexDirection="column" px="1%">
          <Box>
            <Field
              mb="10px"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="off"
              name="name"
              label="GROUP NAME"
              type="text"
              placeholder="Gold Vendor"
              component={renderSmallField}
            />
          </Box>
          <OneSixDash height="1px" width="100%" />
          <Flex
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <ClickCursorFlex
              my="10px"
              justifyContent="center"
              alignItems="center"
              height={["34px"]}
              width="30%"
              minWidth={["120px"]}
              borderRadius="8px"
              bg="lightGray"
              onClick={setGroup}
            >
              <NunitoSans
                pt={["2px"]}
                fontSize={["12px"]}
                fontWeight="bold"
                color="#c3c3c3"
              >
                CANCEL
              </NunitoSans>
            </ClickCursorFlex>
            <OneSixDash width="1px" height="36px" />
            <DashboardSmallCTA
              onClick={() => handleSubmit()}
              //              onClick={() => handleSubmit(this.handleSubmit)}
              width="30%"
              minWidth={["90px"]}
              my="12px"
              height="34px"
            >
              SAVE
            </DashboardSmallCTA>
          </Flex>
        </Flex>
      </Flex>
    </RecommendedContentWrapper>
  );
};

EditGroupCTA = reduxForm({
  form: "edit-group",
  validateGroup
})(EditGroupCTA);

export const GreenGradient = styled.div`
  ${height} ${width}
  background-size:200%;
  background-image: linear-gradient(
    -90deg,
    #51cc36 0%,
    #36cc75 26%,
    #33c297 57%,
    #36cc75 100%
  );
`;
export const SearchFilterFlag = ({ ...props }) => (
  <ClickCursorFlex
    onClick={props.clickHandler || null}
    className="searchFilterFlagBox"
    bg={["none", "none", "none", "none"]}
    borderRadius={["0px", "0px", "0px", "8px"]}
    position="relative"
    flexDirection="row"
    alignItems="center"
    px={["0px", "0px", "0px", "13px"]}
    py={["0px", "0px", "0px", "8px"]}
  >
    <Box mt="2px">
      <NunitoSansBold
        color={!props.active ? "#d0d0d0" : "#606060"}
        fontSize={["12px", "14px", "16px", "17px"]}
        fontWeight="normal"
      >
        {props.children}
      </NunitoSansBold>
      <AbsoluteDiv
        display={["block", "block", "block", "block"]}
        bottom="0px"
        left="0px"
        right="0px"
      >
        {props.active ? <GreenGradient height="1px" width="100%" /> : null}
        {props.active ? null : <Box bg="#c3c3c3" height="1px" width="100%" />}
      </AbsoluteDiv>
    </Box>
    <Box ml={["8px", "8px", "13px"]} display={["none", "none", "none", "none"]}>
      <AccessoryIndicator
        size={24}
        color={props.active ? "#2FD371" : "#efefef"}
        iconColor={props.active ? "#2FD371" : "#d8d8d8"}
        iconName="checkmark"
        iconMarginTop="2px"
        iconSize={11}
      />
    </Box>
  </ClickCursorFlex>
);

export const UnselectedBubble = ({ ...props }) => (
  <SmallFlexBubble
    cursor="pointer"
    bg="white"
    borderRadius="6px"
    alignItems="center"
    justifyContent="center"
    height="35px"
  >
    <NunitoSans
      pt="3px"
      px={["12px", "16px"]}
      fontWeight="bold"
      color="#979797"
      fontSize={["10px", "12px", "14px"]}
    >
      {props.children}
    </NunitoSans>
  </SmallFlexBubble>
);

export const SelectedButton = ({ ...props }) => (
  <OutlineFlex
    cursor="pointer"
    borderRadius="6px"
    alignItems="center"
    justifyContent="center"
    color="#1D2F34"
    borderThickness={"1px"}
    height="33px"
  >
    <NunitoSans
      px={["12px", "15px"]}
      pt="3px"
      fontWeight="bold"
      color="darkText"
      fontSize={["10px", "12px", "14px"]}
    >
      {props.children}
    </NunitoSans>
  </OutlineFlex>
);

export const VideoEmbedMobile = ({ ...props }) => (
  <FixedFlex
    position="fixed"
    justifyContent="center"
    alignItems="center"
    top="0px"
    left="0px"
    right="0px"
    bottom="0px"
    onClick={() => {
      props.clickHandler();
    }}
    zIndex={9999999}
    bg="rgba(0,0,0,0.8)"
  >
    <Flex position="relative" width="100%" pt="56.25%">
      {props.children}
    </Flex>
  </FixedFlex>
);

export const VideoEmbed = ({ ...props }) => (
  <FixedFlex
    position="fixed"
    justifyContent="center"
    alignItems="center"
    top="0px"
    left="0px"
    right="0px"
    bottom="0px"
    onClick={() => {
      props.clickHandler();
    }}
    zIndex={9999999}
    bg="rgba(0,0,0,0.8)"
  >
    {props.children}
  </FixedFlex>
);

export const MobileNavigationalBubble = ({ ...props }) => (
  <Flex position="relative">
    <FourFourDottedTransparentWith8pxCornerRadius bg={"#f4f4f4"}>
      <FlexBubble
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="72px"
      >
        <IconBox fontSize="23px" color="#979797" mb="5px">
          {props.icon}
        </IconBox>
        <NunitoSans fontSize="10px" fontWeight="bold" color="#979797">
          {props.text}
        </NunitoSans>
      </FlexBubble>
    </FourFourDottedTransparentWith8pxCornerRadius>
    <BlurWrapper
      className="blur-wrapper"
      width="100%"
      height="100%"
      blur="8px"
      top="5px"
      left="0px"
      opacity={0.08}
    >
      <UnderlayShadow
        borderRadius="8px"
        zIndex="1"
        width="100%"
        height="100%"
      />
    </BlurWrapper>
  </Flex>
);
export const MobileNavigationalNumberIcon = ({ ...props }) => (
  <Flex
    mt={["4px"]}
    height={["100%", "100%", "100%"]}
    alignItems={["center"]}
    justifyContent={["flex-end"]}
  >
    <Circle
      bg="rgba(255,255,255,0.8)"
      size={26}
      borderThickness={2}
      borderColor="#606060"
    >
      <Flex
        mt="1px"
        height="100%"
        width="100%"
        alignItems={["center"]}
        justifyContent={["center"]}
      >
        <NunitoSansSemiBold fontWeight={700} color="#606060" fontSize={16}>
          {props.number ? props.number : 8}
        </NunitoSansSemiBold>
      </Flex>
    </Circle>
  </Flex>
);

export const SmallFlexBubble = styled(Flex)`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

export const FlexBubble = styled(Flex)`
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: ${props => (props.cursor ? props.cursor : "inherit")}
`;

export const MegaFlexBubble = styled(Flex)`
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.03);
  overflow: hidden;
`;

export const VendorLogoImage = styled(Box)`
  background-image: url(${vendorLogoImageShadowed});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const DepartmentLogoImage = styled(Box)`
  background-image: url(${departmentsLogoImageShadowed});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ChangePaymentMethod = styled(Box)`
  background-image: url(${changepaymentmethodLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const EmployeeLogoImage = styled(Box)`
  background-image: url(${employeeLogoImageShadowed});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const LocationPinImage = styled(Box)`
  background-image: url(${locationPin});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const CompanyImage = styled(Box)`
  background-image: url(${companyImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const HashImage = styled(Box)`
  background-image: url(${hashImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const AudienceImage = styled(Box)`
  background-image: url(${audienceImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const DepartmentLogoNoShadowImage = styled(Box)`
  background-image: url(${departmentsLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
`;

export {
  CreateDepartmentCTA,
  EditDepartmentCTA,
  CreateGroupCTA,
  EditGroupCTA,
  InviteEmployeeCTA,
  InviteVendorCTA
};




function currencyParser(number, currency = "usd") {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(number)
}

function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}