import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
import moment from "moment";
//utils
//actions
//components 
import { H2, NunitoSansLight, NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold } from "../../common/typography";
// import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
// import { BackButton } from "../../common/backbutton";
// import { Link } from "../../common/navigation";
import styled from "styled-components";
import {Privacy} from "./privacy";
import {TermOfUse} from "./terms";

import { actions } from "../../../actions/resources";

 class LegalNav extends Component {
  state = { 
    isOpen: false, 
    isOpenPrivacy: true, 
    isOpenTOS: false,
    feeHistory: []
  };

  _fetchDependencies = async () => {
    const {systemSettings} = this.props;
    const history = await fetch(`${process.env.REACT_APP_API_URL}/systemfeehistory`).then(res=>res.json());
    
    const sortedHistory = history.length ? history.sort((a,b)=>moment(b.created_at).unix()-moment(a.created_at).unix()) : [];

    let feeHistory = sortedHistory.map((fee, index, arr)=>{

      return {
        date: (index===arr.length-1 ? 'Initial': moment(arr[index+1].created_at).format('L')) + ' - ' + moment(fee.created_at).format('L'),
        percent: fee.percent+ '%'
      }
    });

    feeHistory.unshift({
      date: sortedHistory.length ? `${moment(sortedHistory[0].created_at).format('L')} - Current` : 'Current',
      percent: systemSettings.system_incentive_fee + '%'
    });

    this.setState({feeHistory});

  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleModalTOS = () => {
    this.setState({
      isOpenPrivacy: false,      
      isOpenTOS: !this.state.isOpenTOS      
    });
    document.getElementById("IdTermOfUse").style.display = "block";
    document.getElementById("IdPrivacy").style.display = "none";
    document.getElementById("TOSBtn").style.color = "#000";         
    document.getElementById("PrivacyBtn").style.color = "#2FD371";     
   // console.log(this.state.isOpenTOS)
  }

  toggleModalPrivacy = () => {
    this.setState({
      isOpenTOS: false,   
      isOpenPrivacy: !this.state.isOpenPrivacy
    });
    document.getElementById("IdTermOfUse").style.display = "none";
    document.getElementById("IdPrivacy").style.display = "block";
    document.getElementById("TOSBtn").style.color = "#2FD371";         
    document.getElementById("PrivacyBtn").style.color = "#000";     
   // console.log(this.state.isOpenPrivacy)
  }

  componentDidUpdate(){
    document.getElementById('myModal').style.display = "none";
    document.getElementById('myModal').style.top = "-1000px";
  }

  componentDidMount(){
    this._fetchDependencies();
    
    document.getElementById("IdTermOfUse").style.display = "block";
    document.getElementById("IdPrivacy").style.display = "none";     
    document.getElementById("TOSBtn").style.color = "#000";         
    document.getElementById("PrivacyBtn").style.color = "#2FD371";   
    
    window.setTimeout(function(){
      
      
      document.getElementById('myModal').style.display = "none";
      document.getElementById('myModal').style.top = "-1000px";
      document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";
    var stateLCCColumn = document.getElementById("LCCColumn");
    //console.log(stateLCCColumn)
    if(( stateLCCColumn !== null )&&( stateLCCColumn !== "null" )){
    document.getElementById("LCCColumn").style.display = "inherit";
    document.getElementById("LACColumn").style.display = "inherit";
    document.getElementById("GODL").style.display = "block";
    document.getElementById("sdbr1").style.display = "block";
    document.getElementById("sdbr2").style.display = "block";
    document.getElementById("sdbr3").style.display = "none";
    document.getElementById("sdbr4").style.display = "none";


  
   } else {
    document.getElementById("MobileContentWrapper").style.visibility = "hidden";
    window.location.reload(true);

   }
      
         document.getElementById("LACColumn").style.display = "none";     
      document.getElementsByClassName("main-content")[0].style.display = "none";
    }, 500);
  }

  render() {
    const {systemSettings, companySettings} = this.props;

    console.log(this.props, 'LEGAL Settings')



    const LegalDisclaimerTitleDiv = styled.div`
    text-align: center;
    font-size: 40px;
    font-family: NunitoSansLight;
    margin-top:8px;
    color: #000;
  `;
  
  
  const LegalDisclaimerButton = styled.div`
    font-size: 40px;
    color: #000;
    font-family: NunitoSansLight;
    margin-top:8px;
    margin-bottom:8px;
  `;
  
  const LegalDisclaimerSectionHeader = styled.div`
    font-size: 12px;
    color: #000;
    text-align: center;
    font-family: NunitoSansBold;
    margin-top:20px;
    margin-bottom:20px;
  `;
  
  const LegalDisclaimerFirstLine = styled.div`
    font-size: 12px;
    color: #000;
    text-align: left;
    font-family: NunitoSans;
    margin-top:8px;
    margin-bottom:8px;
  `;

  
  const LegalDisclaimerParagraph = styled.div`
    font-size: 12px;
    color: #000;
    text-align: left;
    font-family: NunitoSans;
    text-indent: 37px;
    margin-top:8px;
    margin-bottom:8px;
  `;
    
  
  const LegalDisclaimerParagraphInner = styled.div`
    font-size: 12px;
    color: #000;
    text-align: left;
    font-family: NunitoSans;
    margin-left: 37px;
    margin-right: 37px;
    margin-top:8px;
    margin-bottom:8px;
  `;
  
  const LegalDisclaimerBodyText = styled.div`
    font-size: 12px;
    color: #000;
    text-align: left;
    font-family: NunitoSans;
    margin-top:8px;
    margin-bottom:8px;
  `;

  const H2Restyled = {
    "fontFamily":"NunitoSansLight"
  }

      return (
        <div
          height="100%">

          <H2 style={{"textAlign": "center","fontSize": "40px", "fontFamily": "NunitoSansLight", "marginBottom":"25px", "marginTop":"40px", "color": "#000"}}>LEGAL DISCLAIMERS</H2>
          <div style={{marginTop: "45px", marginBottom: '45px', textAlign: 'center'}} ><a href="#" id="TOSBtn" onClick={this.toggleModalTOS} style={{color:"#000",textDecoration:"underline","marginRight":"25px"}}>Terms of Service</a> | <a id="PrivacyBtn" href="#" onClick={this.toggleModalPrivacy} style={{color:"#2FD371",textDecoration:"underline","marginLeft":"25px"}}>Privacy Policy</a></div>
  

          <div style={{overflow: 'auto', height:'70vh'}} id="IdTermOfUse">
          <TermOfUse historyData={this.state.feeHistory} />
          </div>
          <div style={{overflow: 'auto', height:'70vh'}} id="IdPrivacy">
          <Privacy />
          </div>

  

      </div>
    );
  }
}

LegalNav = connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    fetchSystemSettings: bindActionCreators(actions.fetchSystemsetting, dispatch),
  })
)(LegalNav);

export {LegalNav};
