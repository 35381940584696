export const MAIN_SCROLL_TOP = 'MAIN_SCROLL_TOP';

export function mainContentScrollTop(scrollTop) {
  return {
    type: MAIN_SCROLL_TOP,
    payload: {
      scrollTop
    }
  }
}

