import React, {Component} from "react";
import Modal from "react-modal";

import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex, ClickCursorFlex, IconBox } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';



export default class ConfirmModal extends Component {
    state = {

    }


    render(){
        const {modalOpen, handleCloseRequest, message, message2, handleConfirm, mobile, processing=false, cancelButtonText="CANCEL", confirmButtonText="CONFIRM", customCancelHandle} = this.props;
        const {} = this.state;

        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)',
              width: mobile ? "80vw" : "40vw",
            //   maxHeight: "200px",
              zIndex: 16777271,
              overflow: "auto",
              padding: "5px 5px"
            },
        
            overlay: {
              zIndex: 16777270,
              background: "#A3A99E88"
            }
            
        };

        return (
            <Modal
            isOpen={modalOpen}
            onRequestClose={()=>handleCloseRequest()}
            style={customStyles}
            contentLabel="Confirm Modal"
            ariaHideApp={false}
            >
                <Flex
                flexDirection="column"
                alignItems="center"
                style={{width:'100%'}}
                >
                    <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size={mobile ? '2x' : '4x'}
                    color="#ffae42"
                    style={{marginTop:'15px'}}
                    />
                   
                    <NunitoSansBold
                    fontSize={mobile ? '11px' : '16px'}
                    textAlign='center'
                    style={{marginTop:'15px', width: '80%'}}
                    >
                        {message.toUpperCase()}
                    </NunitoSansBold>

                    {
                        message2
                        ?
                        <NunitoSansBold
                        fontSize={mobile ? '11px' : '16px'}
                        textAlign='center'
                        style={{width: '80%'}}
                        >
                            {message2.toUpperCase()}
                        </NunitoSansBold>
                        :
                        null
                    }

                   {
                       processing
                       ?
                       <NunitoSans
                       fontSize={mobile ? '11px' : '16px'}
                        style={{marginTop:'15px'}}
                       >
                           PROCESSING...
                       </NunitoSans>
                       :
                       null
                   }
                    <Flex
                    style={{width:'50%', marginTop: '15px', marginBottom: '15px'}}
                    >
                        
                        {
                            processing
                            ?
                            null
                            :
                            <>
                            <DashboardSmallCTA
                        onClick={()=>{
                            if(customCancelHandle){
                                customCancelHandle();
                            }

                            handleCloseRequest();
                        }}
                        fontSize="13px"
                        color="#FE4A49"
                        style={{width:"45%", marginRight:'10px'}}
                        >
                        {cancelButtonText}
                        </DashboardSmallCTA>
                        <DashboardSmallCTA
                        onClick={()=>{
                            handleConfirm();
                            if(this.props.processing===undefined){

                                handleCloseRequest();
                            }else{
                                setTimeout(()=>handleCloseRequest(),1000)
                            }
                        }}
                        fontSize="13px"
                        color="#428BF4"
                        style={{width:"45%"}}
                        >
                            {confirmButtonText}
                        </DashboardSmallCTA>
                            </>
                        }
                    </Flex>

                </Flex>

            </Modal>
        );
        




        return null;
    }
}