import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSwear } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <FontAwesomeIcon
            icon={faFaceSwear}
            size="10x"
            color="#428BF4"
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                   We really hate passwords.
                </span>
                {` Instead we use verification codes. It’s more secure. And you’ll never have to remember if you used password1234 or Password123!`}
            </NunitoSans>

        </Flex>
);