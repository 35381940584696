import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset, SubmissionError } from "redux-form";
import Modal from "react-modal";
import axios from "axios";
//utils
import { getUser } from "../../utils/auth";

import _ from "underscore";
//actions
import { actions } from "../../actions/resources";
import { success, error } from "../../actions/alerts";
//components
import StartForm from "./forms/start";


import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex, IconBox, Box } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";

import lockIcon from "../../images/lock_icon.svg";

// import {
//   history
// } from "store";

export class AuthStart extends Component {

  state = {
    isModalOpen: false,
    emailPayload: {},
    inviteSuccess: false,
    inviteInProgress: false,
    referralCode: "",
    initialValueSet: false
  }

  constructor(props) {
    super(props);
  }

  

  _throwError = async message => {
    throw new SubmissionError({ _error: message });
  };


  _handleSubmit = async (gotUser) => {  
    const { redirect } = this.props;
    
    console.log("handlesubmit",gotUser);
    // const isInitialLogin = false || !user.onboarding_completed;
    const isInitialLogin = false;
    if(isInitialLogin){
      // do nothing
    } else {
      redirect("/dashboard");
    }
  };


  render() {
    const { browser,props,user } = this.props;
    const { isModalOpen, inviteInProgress, inviteSuccess, referralCode } = this.state;

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: browser.lessThan.large ? '50%' : "443px",
        // minHeight: "312px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: '1px solid #707070',
        borderRadius: '10px'
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }

    };

    
    let gotUser = getUser();
    console.log(gotUser)

    return (
      <div className="auth-form">
        <StartForm 
        
        back={this.props.back || "/"}
        onSubmit={this._handleSubmit(gotUser)}  />
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    createUser: bindActionCreators(actions.createUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
    // ,referral_code: bindActionCreators({"referral_code":document.getElementById("register-form")[3].value}, dispatch)
  })
)(AuthStart);
