import React, {useState} from "react";
import { Field, reduxForm } from "redux-form";
import { Flex, Box } from "../../common/flexbox";
import { NunitoSans, NunitoSansBold } from "../../common/typography";
//import { handleSubmit } from "./handleSubmit";
import {
  renderMegaField,
  renderField,
  OnboardingCTA,
  BackButton,
  renderFieldCheckBox
} from "../../common/forms";
import { Bubble } from "../../common/ui";
import { H1, P } from "../../common/typography";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';

const validate = values => {
  const errors = {};
  // console.log(values)

  if (!values.email_verification_code) {
    errors.email_verification_code = "CHECK YOUR VERIFICATION CODE";
  }
  if (!values.privacy_and_tos) {
    errors.privacy_and_tos = "You need to click the checkbox above to accept and login";
  } else {
    delete errors.privacy_and_tos;
    //delete values.privacy_and_tos; 
  }
  // console.log(values)
  return errors;
};

const EmailVerificationBubble = ({ ...props }) => (
  <Flex
    width={[1, 1, 1, 1]}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Bubble bg="inputBackgroundGray">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={"24px"}
        pt={"20px"}
        pb={"10px"}
      >
        <Box width={0.8}>
          <Field
            label="Email Verification Code"
            name="email_verification_code"
            component={renderMegaField}
            type="text"
          />
          
        </Box>
      </Flex>
    </Bubble>
  </Flex>
);



/*

need to produce error message on bad sign in code
else {
      console.log(`Your email may in our system already. Already have an account? Login`);
      window.alert(`Your email may in our system already. Already have an account? Login`);
    }

*/
let EmailVerificationForm = props => {


  const [codeHelpOpen, setCodeHelpOpen] = useState(false);
  //const { back } = props;
  const { handleSubmit, back } = props;
  //handleSubmit = this.handleSubmit;//8659fe

  
  return (
    <Flex width={[1, 1, 1]} alignItems="center" flexDirection="column">
      <H1 alignSelf={"flex-start"}>{window.location.pathname === "/login-verification" ? "Hello, Welcome Back!" : "Verify Your Account"}</H1>
      <P alignSelf={"flex-start"}>
        {window.location.pathname === "/login-verification"
          ? `Please check your email for your verification code. It may take up to several minutes to arrive.`
          : `Confirm your email address. A code has been sent to your work email.`}
      </P>
      {/* <p style={{fontFamily: "NunitoSans", fontSize: "14px", alignSelf:"flex-start"}}>If you can't find the code in your inbox, check your spam folder. Still can't find it? Contact us at <a href="mailto:support@kickpost.io?subject=Login Code Help" style={{color:'#2FD371'}}>support@kickpost.io</a></p> */}
      <Flex
      alignItems='center'
      alignSelf="flex-start"
      >
        <Flex
        flexDirection='column'
        alignSelf='flex-start'
        mt='10px'
        >
        <NunitoSans
        fontSize='14px'
        >
          1. If you didn’t receive your code in your inbox, please check your spam folder.
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        >
          2. You might need to allow list our emails, add these addresses:
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        style={{marginLeft:'18px'}}
        >
          <span style={{color:'#2FD371'}}>info@kickpost.io</span> and <span style={{color:'#2FD371'}}>team@kickpost.io</span> to your email allow list.
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        >
          3. Still don’t have it? Email us at <a href="mailto:support@kickpost.io?subject=Login Code Help" style={{color:'#2FD371'}}>support@kickpost.io</a>
        </NunitoSans>
        </Flex>
        {/* <NunitoSansBold
        fontSize='16px'
        >
        Didn't get your code?
        </NunitoSansBold> 
        <FontAwesomeIcon
        icon={codeHelpOpen ? faArrowAltCircleDown : faArrowAltCircleRight}
        // size='lg'
        color='#2FD371'
        style={{marginLeft:'10px', fontSize:'16px', cursor: 'pointer'}}
        onClick={()=>setCodeHelpOpen(!codeHelpOpen)}
        /> */}
        
      </Flex>

      {
        codeHelpOpen
        ?
        <Flex
        flexDirection='column'
        alignSelf='flex-start'
        mt='10px'
        >
        <NunitoSans
        fontSize='14px'
        >
          1. If you didn’t receive your code in your inbox, please check your spam folder.
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        >
          2. You might need to allow list our emails, add these addresses:
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        style={{marginLeft:'18px'}}
        >
          <span style={{color:'#2FD371'}}>info@kickpost.io</span> and <span style={{color:'#2FD371'}}>team@kickpost.io</span> to your email allow list.
        </NunitoSans>
        <NunitoSans
        fontSize='14px'
        >
          3. Still don’t have it? Email us at <a href="mailto:support@kickpost.io?subject=Login Code Help" style={{color:'#2FD371'}}>support@kickpost.io</a>
        </NunitoSans>
        </Flex>
        :
        null
      }
      <form
        onSubmit={handleSubmit}
        id="create-company-form"
        style={{ width: "100%" }}
      >
        <Flex
          width={1}
          mt={["7px", "7px", "28px", "28px"]}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {
            props.isMobile
            ?
            <Box 
              width={[1, 1, 1, 1]}
              mt="20px">
            <Field
              label="Email Verification Code"
              name="email_verification_code"
              component={renderField}
              type="text"
            />
            
            </Box>
            :
            <EmailVerificationBubble />
          }

          <Flex
            justifyContent={["center", "flex-start"]}
            alignItems="center"
            width={[1, 1, 1, 1]}
          >
          
          
            <Box display={["none", "block"]} width={0.333}>
              <BackButton link={back} />
            </Box>
            <OnboardingCTA
              my={["2vh", "2vh", "9vh", "9vh"]}
              width={[0.75, 0.75, 0.375, 0.375]}
              type="submit"
            >
              {window.location.pathname === "/login-verification"
                ? `SIGN IN`
                : `SIGN IN`}
                
            </OnboardingCTA>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

export default reduxForm({
  form: "email-verification",
  validate
})(EmailVerificationForm);
