import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
//actions
import { actions } from "../../../../actions/resources";
import { error, success } from "../../../../actions/alerts";
//components
// import { OutlineFlex } from "../../../common/forms";
import { NunitoSans } from "../../../common/typography";
import { Flex, ClickCursorFlex } from "../../../common/flexbox";
import { AbsoluteDiv } from "../../../common/absolute";
import { BackButton } from "../../../common/backbutton";
// import ConfirmModal from "../../../common/confirmModal";

import QuestionModalTrigger from "../../../common/questionModalTrigger";

export class AddPostNav extends Component {
  state = {
    
    editMode: false
  }

  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {
    if(window.location.pathname.includes('edit')){
      this.setState({editMode: true})
    }
  }

  // _removePost = async () => {
  //   const { post, redirect, updatePost } = this.props;
  //   await updatePost(post.id, { status: "delete" });
  //   redirect(`/dashboard`);
  // };

  // _openRemoveModal = ()=> this.setState({removeModalOpen: true});
  // _closeRemoveModal = ()=> this.setState({removeModalOpen: false});
  // _handleRemoveConfirm = ()=>this._removePost();

  render() {
    const { editMode } = this.state;
    // const { match } = this.props;

    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {/* {match.params.id ? (
            <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
              <Flex
                height={["100%", "100%", "100%"]}
                alignItems={["center"]}
                justifyContent={["flex-end"]}
              >

                <ConfirmModal
                modalOpen={removeModalOpen}
                message="Are you sure you wish to delete this post?"
                handleCloseRequest={this._closeRemoveModal}
                handleConfirm={this._handleRemoveConfirm}
                />
                <OutlineFlex
                  justifyContent="center"
                  alignItems="center"
                  height={["30px", "30px", "40px"]}
                  width={["64px", "64px", "102px"]}
                  onClick={() => {
                    // if (
                    //   window.confirm(
                    //     "Are you sure you wish to delete this post?"
                    //   )
                    // ) {
                    //   return this._removePost();
                    // }
                    this._openRemoveModal();
                  }}
                  borderRadius="8px"
                  color="#FE4A49"
                >
                  <NunitoSans
                    pt={["2px", "4px"]}
                    fontSize={["12px", "12px", "18px"]}
                    fontWeight="bold"
                    color="#FE4A49"
                  >
                    DELETE
                  </NunitoSans>
                </OutlineFlex>
              </Flex>
            </AbsoluteDiv>
          ) : null} */}
          {editMode ? (
            <NunitoSans
              pt={["6px", "6px", "8px", "6px"]}
              fontSize={[14, 14, 18, 18]}
              fontWeight={["bold"]}
            >
              EDIT POST
            </NunitoSans>
          ) : (
            <NunitoSans
              pt={["6px", "6px", "8px", "6px"]}
              fontSize={[14, 14, 18, 18]}
              fontWeight={["bold"]}
            >
              ADD A POST
            </NunitoSans>
          )}
          <QuestionModalTrigger modalName='add_post' />
          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <ClickCursorFlex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
            >
              <BackButton />
            </ClickCursorFlex>
          </AbsoluteDiv>
        </Flex>
      </Flex>
    );
  }
}

export default connect(
  state => ({
    post: state.resources.detail.post
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    updatePost: bindActionCreators(actions.patchPost, dispatch)
  })
)(AddPostNav);
