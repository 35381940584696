import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import _ from "underscore";
import AWS from "aws-sdk";
import { uniq } from "lodash";
import { CSVLink } from "react-csv";
// import ReactTooltip from 'react-tooltip';


import moment from "moment";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
import ReactTable from "react-table";
import 'react-table/react-table.css';

import { parse } from "../../../../node_modules/csv-parse/dist/esm/sync";

//components

import { NunitoSans } from "../../common/typography";
import { Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA, SelectStyled } from "../../common/forms";
import { BackButton } from "../../common/backbutton";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

import { setRowSelected, setCsvDropOpen } from "../../../actions/incentives";


import CsvDropzoneModal from "../../common/csvDropzoneModal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import InfoModal from "../../common/InfoModal";

import salesLogo from "../../../images/sales_info_logo.png";

import QuestionModalTrigger from "../../common/questionModalTrigger";

import MapSalesModal from "./mapSalesModal";
import LoadingSpinner from "../../common/loading";
import { isValidEmail } from "../../../utils/helpers";


export class ManageSalesMain extends Component {
  
  state = {
    clickedRow: null,
    csvErrors: [],
    filteredData: null,
    uploading: false,
    salesUploadHistory: [],
    salesByDate: {},
    salesRaw: {},
    invoiceMonths: [],
    salesData: [],
    totalSales: 0,
    totalQuantity: 0,
    availableInvoiceDates: [],
    memoizedSalesData: {},
    filtered2: [],
    noDataTextCustom: "Loading...",
    isLoading: true
  };

  _fetchDependencies = async () => {
    
    const { fetchCompany, fetchUser, user } = this.props;

    if (!(getUser().company_admin || getUser().sales_id) || getUser().status !== 'active') {
      history.push('/dashboard')
    }

    if (!user || (user && user.id !== getUser().id)) {
      fetchUser(getUser().id);
    }

await this._fetchCsvUploads();

fetchCompany(getUser().company_id, { edit: true })

this._fetchSales();


setTimeout(() => this.setState({ noDataTextCustom: 'No Data' }), 3000);
}

componentDidMount() {
  this._fetchDependencies();
}

  _fetchCsvUploads = async(keepDropzoneOpen = false)=>{

    const { fetchCsvuploads } = this.props;

    return await fetchCsvuploads({ company_id: getUser().company_id })
      .then(res => {
        let { csvuploads } = this.props;
        
        this.props.setCsvDropOpen(keepDropzoneOpen);
        let salesUploadHistory;
        if (res !== undefined && res.payload) {
          csvuploads = res.payload;
          salesUploadHistory = csvuploads.filter(item => item.type === 'sale').filter((a, index) => index < 6);
          
        this.setState({ salesUploadHistory });
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/csvuploads?company_id=${getUser().company_id}`)
          .then((res) => res.json())
          .then((data) => {
            csvuploads = data;
            salesUploadHistory = csvuploads.filter(item => item.type === 'sale').filter((a, index) => index < 6);
            this.setState({ salesUploadHistory });
            })
        }

      }
      )
      .catch(err => console.error(err));

  }

  _fetchSales = async (params = {}) => {
    const {fetchSales} = this.props;

    this.setState({isLoading: true});

    if(params.hasOwnProperty('month') && params.year){
      const memoizedData = this.state.memoizedSalesData[`${params.month}-${params.year}`];
      if(memoizedData && memoizedData.salesData){
        this.setState({...memoizedData, isLoading: false});
        return;
      }
    }

    const payload = {
      ...params,
      isSalesPage: true,
      user_id: getUser().id
    };

    const firstRespond = await fetchSales({ ...payload, page: 1 }).then(res => res.payload).catch(err => {
      this.setState({isLoading: false});
    });

    const { data, lastPage, salesTotal, quantityTotal, availableDates } = firstRespond;

    let salesData = data || [];
    let totalSales = salesTotal || 0;
    let totalQuantity = quantityTotal || 0;
    const availableInvoiceDates = availableDates || [];

    if(lastPage > 1){
      const paginatedPromises = Array.from({length: lastPage-1}, (_, i) => i + 2).map(page => fetchSales({ ...payload, page }))

      const responds = await Promise.all(paginatedPromises).catch(err => this.setState({isLoading: false}));

      responds.forEach(respond => {
        const { data, salesTotal, quantityTotal } = respond.payload;
        salesData = [...salesData, ...data];
        totalSales += salesTotal;
        totalQuantity += quantityTotal;
      })
    }

    this.setState({isLoading: false})

    const memoizeKey = params.hasOwnProperty('month') && params.year ? `${params.month}-${params.year}` : `${availableInvoiceDates[0].month}-${availableInvoiceDates[0].year}`;
    const latestMemoizedSales = {...this.state.memoizedSalesData};
    latestMemoizedSales[memoizeKey] = {
      salesData,
      totalSales,
      totalQuantity
    };

    this.setState({salesData, totalSales, totalQuantity, availableInvoiceDates, memoizedSalesData: latestMemoizedSales, isLoading: false});
  }

  _changeTableFilterValue = (id, value) => {

    this.setState({ filtered2: [{ id, value }] })
  }

  componentDidUpdate(prevProps) {

    if (this.state.clickedRow && this.props.rowSelected && !Object.keys(this.props.rowSelected).length) {
      this.setState({ clickedRow: null })
    }
  }


  componentWillUnmount() {
    this.props.setRowSelected({});
  }

  _handleSalesUploadCompletion = () => {
    const {setCsvDropOpen, notifySuccess} = this.props;
   
    this.setState({csvErrors: [], uploading: false})
    setCsvDropOpen(false)
    notifySuccess(`We are processing uploaded sales data. You will receive an email with results when processing is completed. `)
  }

  _handleCsvUpload = (files) => {

    const { notifyError, user, systemSettings, companySettings} = this.props;
    const file = files[0];
    const results = [];
    const fileErrors = [];
    const companyId = getUser().company_id;
    const companyName = user.company.name;
    const userId = getUser().id;
    const reader = new FileReader();
    this.setState({csvErrors: [], uploading: true})
    if(typeof file === "undefined"){
      this.setState({uploading: false})
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });
    const lambda = new AWS.Lambda();



    reader.onload = async() => {
      const data = parse(reader.result);
         const keys=["invoice_number", "invoice_date", "mnfg_pn", "sku", "mfr_name", "cost_per_unit", "unit_sales_out", "quantity",  "customer_name", "dist_order_number",  "rep_id", "rep_name"].filter(key=>companySettings.is_single_user_company ?
          !['sku','rep_id', 'rep_name' ].includes(key)
          :
          !systemSettings.part_number_active
          ?
          key!=='sku'
          :
          true
          );
        for(let i=1; i < data.length; i++){
         
          //dynamic result formation
          let theResult = {
            'company_id': companyId,
            'company_name': companyName,
            'user_id': userId
          };
    
          for(let k=0; k<keys.length; k++){
            const theKey = keys[k];

            if(theKey==="quantity" || theKey==="cost_per_unit" || theKey === "unit_sales_out"){
              theResult[theKey] = data[i][k]?.replace(/[^\d.]/g, '') || ''
            }else if(theKey === 'rep_id'){
              theResult[theKey] = data[i][k]?.trim().toLowerCase() || ''
            }else{
              theResult[theKey] = data[i][k] || '';
            }
    
          }
    
          if(!theResult.rep_id){
            theResult.rep_id = getUser().email.toLowerCase();
            theResult.rep_name = getUser().first_name+" "+getUser().last_name;
          }
    
          theResult.sales_key = ["invoice_number", "invoice_date", "mnfg_pn", "sku", "mfr_name", "cost_per_unit", "unit_sales_out", "quantity",  "customer_name", "dist_order_number",  "rep_id", "rep_name", "company_id"].map(key=>theResult[key] || "").join("~");
    
          results.push(theResult);
    
        }
    
        
    
        results.forEach((item, index)=>{
          let errors= []
        if(!keys.every(key=>!item[key])){
    
          keys.forEach(key=>{
    
            if(!systemSettings.part_number_active && key==='sku') return;
    
            if(key!=="customer_name" && key!=="dist_order_number"){
              if(key === "unit_sales_out"){
                if(item[key] && isNaN(+item[key])){
                  errors.push(`Invalid Number at ${key}`)
                }
              }else if(key==="quantity" || key==="cost_per_unit"){
                if(!item[key] || isNaN(+item[key])){
                  errors.push(`Invalid Number at ${key}`)
                }
              }else if(key==="invoice_date"){
                if(!moment(item[key]).isValid()){
                  errors.push(`Invalid Date format at ${key} - Valid Format(MM/DD/YYYY)`)
                }else if(moment(item[key]).isBefore(new Date('01/01/2019')) ){
                  errors.push(`Date Error at ${key} - Can be backdated up to 01/01/2019`)
                }else if(moment(item[key]).isAfter(moment())){
                  errors.push(`Date Error at ${key} - Can not be any date later than today`)
                }
              }else if(key==='rep_id' && !isValidEmail(item[key])){
                errors.push(`Error at REP EMAIL - invalid email address`)
              }else if(!item[key]){
                errors.push(`INVALID ${key}`);
              }
            }
          })
        }
          if(errors.length!==0){
            fileErrors.push({
              line: index+1,
              errors: errors
            })
          }
        })
      
        if(fileErrors.length>0){
          this.setState({uploading: false, csvErrors: fileErrors})
        }else{
          
          const fileName = `${Date.now()}~${getUser().id}.csv`;

          const s3Bucket = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
          });
          const fileKey = `sales-csv-upload-${process.env.REACT_APP_NODE_ENV}/${fileName}`;
          let s3Data = {
            Key: fileKey,
            Body: file,
            ContentType: file.type,
            ACL: "public-read",
            Metadata: {
              "user":JSON.stringify({ ..._.pick(getUser(), ['id', 'company_id', 'email', 'first_name', 'last_name']), companyName}),
              "headers": JSON.stringify(keys)
             }
          };
          let start = performance.now();
          return s3Bucket.putObject(s3Data, (err, response) =>{
            console.log(err, response, "S3REPONSE")
            console.log("TIME UPLOAD: ", start - performance.now())
            this._handleSalesUploadCompletion();
          });

        }
    };

    reader.readAsText(file);
  }

  currencyParser = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.props.systemSettings.currency }).format(number)
  }

  _handleSalesDateChange = value => {
    const selectedDate = this.state.availableInvoiceDates[value];
    this._fetchSales({month: selectedDate.month, year: selectedDate.year});
  }

  _handleCloseModal = () => {
    const { setCsvDropOpen } = this.props;
    const { uploading } = this.state;

    if (uploading) return;

    this.setState({ csvErrors: [] })
    setCsvDropOpen(false)
  }

  _deleteSalesByUploadId = async(id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/csvuploads/${id}`,
      {
        method: 'DELETE'
      }
      ).then(res=>res.json());

    await this._fetchCsvUploads(true);

    this._fetchSales();
  }

  render() {


    const {sales, user, csvDropOpen, browser, systemSettings, companySettings } = this.props;
    const {
      clickedRow,
      csvErrors,
      filteredData,
      uploading,
      salesUploadHistory,
      salesByDate,
      invoiceMonths,
      availableInvoiceDates,
      salesData,
      totalQuantity,
      totalSales,
      isLoading
    } = this.state;

    if (!user) return null;
    const salesColumns = [

      {
        Header: 'VERIFIED',
        accessor: 'verified',
        width: 75,
        Cell: props => props.value ? <FontAwesomeIcon icon={faCheckCircle} color={props.index === clickedRow ? "#FFFFFF" : "#428BF4"} size="lg" /> : "",
        show: false
        // show: companySettings.sale_verification_active
      },
      {

        Header: 'INV. DATE',
        accessor: 'invoice_date',
        Cell: row => moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
        filterable: true,
        width: 80,
        sortMethod: (a, b) => Date.parse(b) - Date.parse(a),
        filterMethod: (filter, row) => row[filter.id] === moment(filter.value).subtract(7, 'hours').toISOString()

      },
      {
        Header: 'INVOICE',
        accessor: 'invoice_number',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
          Header: 'COMPANY',
          accessor: 'company_name',
          filterable: true,
          // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'REP NAME',
        accessor: 'rep_name',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'MFR NAME',
        accessor: 'mfr_name',
        width: 80,
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: systemSettings.part_number_name,
        accessor: 'sku',
        filterable: true,
        show: systemSettings.part_number_active
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'MFG PART#',
        accessor: 'mnfg_pn',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      // {
      //     Header: 'CUSTOMER NAME',
      //     accessor: 'customer_name',
      //     filterable: true,
      //     // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      // },
      {
        Header: 'QTY',
        accessor: 'quantity',
        width: 60,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => numberParser(parseFloat(props.value))
      },
      {
        Header: 'TOTAL COST VALUE',
        accessor: 'total_value',
        width: 100,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => this.currencyParser(parseFloat(props.value))
      }
    ];


    const columns = {
      sales: salesColumns
    }

    const csvHeaders = {
      sales: [
        { label: 'INVOICE NUMBER', key: 'invoice_number' },
        { label: 'INVOICE DATE', key: 'invoice_date' },
        { label: 'MFR NAME', key: 'mfr_name' },
        { label: systemSettings.part_number_name, key: 'sku' },
        { label: 'MNFG PART #', key: 'mnfg_pn' },
        { label: 'NAME', key: 'rep_name' },
        { label: 'REP COMPANY NAME', key: 'company_name' },
        { label: 'CUSTOMER NAME', key: 'customer_name' },
        { label: 'QTY', key: 'quantity' },
        { label: 'Sales Out Amount Per Item', key: 'unit_sales_out' },
        { label: 'Cost Per Item', key: 'cost_per_unit' },
        { label: 'TOTAL VALUE', key: 'total_value' }
      ]
    }

    const csvTemplateHeaders = {
      sales: [
        { label: 'INVOICE NUMBER', key: 'invoice_number' },
        { label: 'INVOICE DATE', key: 'invoice_date' },
        { label: 'MNF PART NUMBER', key: 'mnfg_pn' },
        { label: systemSettings.part_number_name.toUpperCase(), key: 'sku' },
        { label: 'MANUFACTURER NAME', key: 'mfr_name' },
        { label: 'COST PER UNIT', key: 'cost_per_unit' },
        { label: 'SALES OUT PER UNIT', key: 'unit_sales_out' },
        { label: 'QUANTITY SOLD', key: 'quantity' },
        { label: 'CUSTOMER NAME', key: 'customer_name' },
        { label: 'DISTRIBUTION ORDER NUMBER', key: 'dist_order_number' },
        { label: 'REP EMAIL', key: 'rep_id' },
        { label: 'REP NAME', key: 'rep_name' },
      ].filter(header => companySettings.is_single_user_company ?
        !['sku', 'rep_id', 'rep_name'].includes(header.key)
        :
        !systemSettings.part_number_active
          ?
          header.key !== 'sku'
          :
          true
      )
    }

    const data = {
      sales: salesData || []
    }

    return <div style={{ height: "89vh", overflowY: "auto", paddingRight: '25px' }}>
    <Flex
          flexDirection="column"
          style={{ marginTop: '25px' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems='flex-end'
            style={{ borderBottom: '1px solid #428BF4' }}
          >
            <NunitoSans key="nunito02"
              fontSize="14px"
              color="#428BF4"
              fontWeight="bold"
            >
              {`SALES SUMMARY`}
            </NunitoSans>
            {
              availableInvoiceDates?.length
              ?
              <SelectStyled
                key="SelectStyled01"
                id={`sales_dropdown`}
                style={{ padding: "10px 5px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000", marginLeft: '10px', marginBottom: '10px' }}
                fontSize={['12px', '12px', '16px', '16px']}
                onChange={e => this._handleSalesDateChange(e.target.value)}
                disabled={isLoading}
              >
                      {
                        availableInvoiceDates.map((date, index) => <option
                          style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                          value={index}
                          key={index}
                        >
                          {date.monthName + " " + date.year}
                        </option>)

                      }

              </SelectStyled>
              :
              null
            }
          </Flex>

          <>
            <Flex
              justifyContent="space-between"
              alignItems='center'
              style={{ backgroundColor: "#FAFAFA", paddingTop: "11px", paddingBottom: "11px" }}
            >
              <NunitoSans key="nunito04"
                fontSize="16px"
                color="#A3A99E"
                fontWeight="bold"
                style={{ paddingLeft: "35px" }}
              >
                Total Sales
              </NunitoSans>
              <NunitoSans key="nunito05"
                fontSize="16px"
                color="#000000"
                fontWeight="bold"
                textAlign="right"
                style={{ paddingRight: "35px" }}
                width="30%"
              >
                {this.currencyParser(totalSales || 0)}
              </NunitoSans>

            </Flex>

            <Flex
              justifyContent="space-between"
              alignItems='center'
              style={{ paddingTop: "11px", paddingBottom: "11px" }}
            >
              <NunitoSans key="nunito06"
                fontSize="16px"
                color="#A3A99E"
                fontWeight="bold"
                style={{ paddingLeft: "35px" }}
              >
                Quantity
              </NunitoSans>
              <NunitoSans key="nunito07"
                fontSize="16px"
                color="#000000"
                fontWeight="bold"
                textAlign="right"
                style={{ paddingRight: "35px" }}
                width="30%"
              >
                {numberParser(totalQuantity || 0)}
              </NunitoSans>

            </Flex>

          </>
        </Flex>
    <NunitoSans key="nunito08"
      fontSize="18px"
      fontWeight="bold"
      color="#000000"
      style={{ width: "100%", textAlign: "center", marginTop: "37px", marginBottom: "17px" }}
    >
      {`SALES DETAILS`}
    </NunitoSans>
    { !browser.greaterThan.medium ?
        <NunitoSans key="nunito09"
        fontSize="18px"
        fontWeight="bold"
        color="#6C7168"
        style={{ width: "100%", textAlign: "center", marginTop: "17px" }}
        >
          Details can be seen on the full screen version of the page.
        </NunitoSans>
      :
      isLoading ?
      <LoadingSpinner spinnerSize="5x" textSize="20px" style={{marginTop: '100px'}} />
      :
        <>
          <CsvDropzoneModal
            modalOpen={csvDropOpen}
            handleCloseRequest={this._handleCloseModal}
            handleCsvUpload={uploading ? () => { } : this._handleCsvUpload}
            csvName={'sales'}
            uploading={uploading}
            csvErrors={csvErrors}
            csvHeaders={csvTemplateHeaders.sales}
            uploadHistory={salesUploadHistory}
            handleHistoryDelete={this._deleteSalesByUploadId}
          />


          <ReactTable
            noDataText={"NO RESULTS"}
            noDataTextCustom={"NO RESULTS"}
            ref={(r) => {
              this.selectTable = r;
            }}
            onFilteredChange={(filtered2) => {
              this.setState({ filtered2, filteredData: this.selectTable.getResolvedState().sortedData.map(item => item._original) })
              // console.log(filtered2)
            }}
            filtered={this.state.filtered2}
            defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}

            className="-striped -highlight tablehover MyReactTableClass"
            columns={columns.sales}
            data={data.sales}
            defaultSorted={[
              {
                id: "invoice_date",
                desc: false
              }
            ]}

            // showPagination={false}
            defaultPageSize={20}
            resizable={true}
            style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none", marginTop: '35px' }}
            getTrProps={(state, rowInfo, column, instance) => {
              const { setRowSelected, rowSelected } = this.props;
              return {
                onClick: (e, handleOriginal) => {
                  console.log('A Td Element was clicked!')
                  // console.log('it produced this event:', e)
                  // console.log('It was in this column:', column)
                  // console.log('It was in this row:', rowInfo)
                  // console.log('It was in this table instance:', instance)

                  this.setState({ clickedRow: rowInfo.index })
                  rowInfo.original.type = 'sales';
                  setRowSelected(rowInfo.original)
                  // console.log(rowInfo.original)




                  // IMPORTANT! React-Table uses onClick internally to trigger
                  // events like expanding SubComponents and pivots.
                  // By default a custom 'onClick' handler will override this functionality.
                  // If you want to fire the original onClick handler, call the
                  // 'handleOriginal' function.
                  if (handleOriginal) {
                    handleOriginal()
                  }
                },
                style: {
                  background: rowInfo && this.state.clickedRow === rowInfo.index ? "#428BF4" : null,
                  color: rowInfo && this.state.clickedRow === rowInfo.index ? "#FFFFFF" : null
                }
              }
            }}
          />
          <Flex
            justifyContent="center"
          >

            <DashboardSmallCTA
              color="#428BF4"
              fontSize="15px"
              style={{ marginTop: '15px', width: '33%' }}
            >
              <CSVLink
                data={(filteredData ? filteredData : data.sales).map(item => _.omit(item, ['id', 'company_id', 'created_at', 'sales_key', 'user_id', 'history']))}
                headers={csvHeaders.sales}
                filename={`sales-data_${new Date().toJSON().slice(0, 10)}.csv`}
              >Export Data as CSV</CSVLink>
            </DashboardSmallCTA>
          </Flex>
        </>
    }


  </div>

  }
}

ManageSalesMain = reduxForm({
  // destroyOnUnmount: true,
  form: "salesPeriod-form",
  // validate,
  // enableReinitialize : true
})(ManageSalesMain);

export default connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    sales: state.resources.collection.sale || [],
    csvuploads: state.resources.collection.csvupload || [],
    user: state.resources.detail.user,
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen
  }),
  dispatch => ({
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch),
    fetchCsvuploads: bindActionCreators(actions.fetchCsvuploads, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch)
  })
)(ManageSalesMain);

class ManageSalesNav extends Component {
  constructor(props) {
    super(props);
  } 
  
  state ={
    mapSalesModalOpen: false
  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    
    this.props.setRowSelected({});
  }

  render() {

    const { formValues, setCsvDropOpen, companySettings, browser, featureFlags } = this.props;

    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <div>
              <InfoModal
                key={"sales_modal"}
                modalName='sales_modal'  //no spaces. Once set right do not change
                modalVersion="1.00" //set it to 1.00 initially then change it when needed
                image={salesLogo}
                title='Sales Data'
                text='Browse, track, and export details of sales data attributed to your account. '
              // videoLink='https://youtu.be/xFYRTlTuTHA'
              />
        </div>

        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >

          <NunitoSans
            pt={["6px", "6px", "8px", "6px"]}
            fontSize={[14, 14, 18, 18]}
            fontWeight={["bold"]}
          >
          SALES
          </NunitoSans>

          <QuestionModalTrigger modalName={`sales_modal`} />

          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
            >

              <BackButton />

            </Flex>
          </AbsoluteDiv>
          {
            browser.greaterThan.medium && companySettings && companySettings.receive_incentives && getUser().company_admin ?
              <AbsoluteDiv
                right={["0px", "0px", "0px", "0px", featureFlags.map_sales === true ? "10%" : "0%"]}
                bottom={"0px"}
                top={"0px"}
                width={["auto", "auto", "auto", "auto", "20%"]}
              >
                <Flex
                justifyContent="space-between"
                style={{width: featureFlags.map_sales === true ? '270px' : '130px'}}
                >
                  {
                    featureFlags.map_sales === true && (
                      <DashboardSmallCTA
                        onClick={() => this.setState({mapSalesModalOpen: true})}
                        fontSize="15px"
                        width="130px"
                        color="#428BF4"
                      >
                        MAP SALES
                      </DashboardSmallCTA>
                    )
                  }

                  <DashboardSmallCTA
                    onClick={() => setCsvDropOpen(true)}
                    fontSize="15px"
                    width="130px"
                    color="#428BF4"
                  >
                    UPLOAD
                  </DashboardSmallCTA>
                </Flex>
                <MapSalesModal
                  isModalOpen={this.state.mapSalesModalOpen}
                  closeModal={() => this.setState({mapSalesModalOpen: false})}
                />
              </AbsoluteDiv>

              :
              null
          }
          
        </Flex>

      </Flex>
    )
  }
};

ManageSalesNav = connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    user: state.resources.detail.user,
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen
  }),
  dispatch => ({
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch)
  })
)(ManageSalesNav);

export { ManageSalesNav };




function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}