import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, reduxForm, Field } from "redux-form";
import { createNumberMask } from 'redux-form-input-masks';
import _ from "underscore";
import moment from "moment";
import { isEqual, sortBy } from "lodash";
//utils
import { getUser } from "../../../utils/auth";
import { error } from "../../../actions/alerts";
//actions
import { actions } from "../../../actions/resources";
import { setRowSelected } from "../../../actions/incentives";
//components
import { NunitoSans } from "../../common/typography";
import currencies from "../../../constants/currency";
import { Flex, Box } from "../../common/flexbox";
import { DashboardSmallCTA } from "../../common/forms";
import { colors } from "../../../utils/theme";


export class ManageSalesAccessory extends Component {
  state = {
    editOpen: false,
    saleHistory: [],
    salesReps: [],
    categories: []
  };

  componentDidMount() {
    this._fetchDependencies();
  };


  _users = async (fetchUsers) => {
    return await fetchUsers({ company_id: getUser().company_id, status: 'active' });
  }

  _fetchDependencies = async () => {
    const { fetchUsers } = this.props;

    let users;
    this._users(fetchUsers)
      .then(() => {
        console.log("_fetchDependencies this.props", this.props);
        users = this.props.connections;
        console.log("_fetchDependencies users", users);//this is the raw connections returned from querying the PostgreSQL database connections table

        if (users) {
          let salesReps = sortBy(users.filter(user => user.sales_id), ['first_name', 'last_name']);
          this.setState({ salesReps });
        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });


    this._fetchCategories();

  }

  _fetchCategories = async () => {
    const categories = await fetch(`${process.env.REACT_APP_API_URL}/categories`).then(res => res.json());
    this.setState({ categories });
  }

  async componentDidUpdate(prevProps) {
    const { saleHistory } = this.state;

    let newFormValues = { ...this.props.rowSelected }

    if (!isEqual(prevProps.rowSelected, this.props.rowSelected)) {

      this.setState({ saleHistory: [] });
      if (this.props.rowSelected.type === 'sales' && (!saleHistory.length || (saleHistory.length && saleHistory[0].sales_id !== this.props.rowSelected.id))) {

        const historyPayload = await fetch(`${process.env.REACT_APP_API_URL}/saleshistory/${this.props.rowSelected.id}`);
        const history = await historyPayload.json();
        this.setState({ saleHistory: history })
      }

      if (this.props.rowSelected.type === 'sales') {
        const { rep_name, rep_id } = this.props.rowSelected;

        newFormValues.rep_name = `${rep_name}~${rep_id}`
      }


      this.props.initialize({ ...newFormValues });
    }
  }

  _updateSale = async () => {
    const { formValues, updateSale, fetchSales, setRowSelected, rowSelected, notifyError } = this.props;
    if (!formValues.rep_name) return notifyError("REP NAME cannot be blank");
    if (!formValues.mnfg_pn) return notifyError("MFG PART # cannot be blank");
    if (!formValues.quantity || isNaN(+formValues.quantity)) return notifyError("QUANTITY should be a number");
    if (!formValues.cost_per_unit || isNaN(+formValues.cost_per_unit)) return notifyError("$ should be a number");
    if (!formValues.edit_note) return notifyError("You should provide a reason of editing this sale");

    // rep_name need to be formatted before insertion because of dropdown formatting
    const repNameId = formValues.rep_name.split('~');

    const REP_NAME = repNameId[0];
    const REP_ID = repNameId[1];

    let newData = {
      rep_name: REP_NAME,
      rep_id: REP_ID,
      rep_company_name: formValues.rep_company_name,
      mnfg_pn: formValues.mnfg_pn,
      customer_name: formValues.customer_name,
      quantity: formValues.quantity,
      cost_per_unit: formValues.cost_per_unit,
      user_id: getUser().id,
      sales_key: (rowSelected.invoice_number + '~' + moment(rowSelected.invoice_date, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY") + '~' + formValues.mnfg_pn + '~' + (rowSelected.sku || "") + '~' + (rowSelected.mfr_name || "") + '~' + rowSelected.unit_sales_out + '~' + formValues.quantity + '~' + formValues.customer_name + '~' + (rowSelected.dist_order_number || "") + '~' + formValues.rep_id + '~' + formValues.rep_name + '~' + rowSelected.company_id).toLowerCase() //order from rewards-->index.js--> _handleCsvUpload --> line 463
    }
    let payload = {
      newData: newData,
      prevData: { ..._.omit(rowSelected, ["type", "id", "total_value", "created_at", "history"]), reason: formValues.edit_note || '', sales_id: rowSelected.id, edited_by: `${getUser().first_name} ${getUser().last_name}` },

    }

    await updateSale(rowSelected.id, payload);
    if (getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin) {
      fetchSales({});

    } else {
      fetchSales({ user_id: getUser().id, rewardsPage: true });

    }
    this.setState({ editOpen: false })
    setRowSelected({})

  }

  currencyMask = (decimal = 2, negative = false) => createNumberMask({
    prefix: currencies[this.props.systemSettings.currency].symbol,
    // suffix: ' per item',
    decimalPlaces: decimal,
    allowNegative: negative,
    locale: 'en-US',
  })

  numberMask = createNumberMask({
    // prefix: '$ ',
    // suffix: ' per item',
    // decimalPlaces: 2,
    locale: 'en-US',
  })

  currencyParser = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.props.systemSettings.currency }).format(number)
  }

  render() {

    const {
      rowSelected,
      systemSettings
    } = this.props;
    const { saleHistory, salesReps, categories } = this.state;



    const tableStructure = {
      sales: [
        {
          name: "INVOICE DATE",
          key: "invoice_date"
        },
        {
          name: "INVOICE #",
          key: "invoice_number"
        },
        {
          name: "COMPANY NAME",
          key: "company_name"
        },
        {
          name: "REP ID",
          key: "rep_id",
          editable: false
        },
        {
          name: "REP NAME",
          key: "rep_name",
          editable: true
        },
        {
          name: "MFG PART #",
          key: "mnfg_pn",
          editable: true
        },
        {
          name: "CUSTOMER NAME",
          key: "customer_name",
          editable: true
        },
        {
          name: "QUANTITY",
          key: "quantity",
          editable: true,
          type: "number"
        },
        {
          name: "COST PER UNIT",
          key: "cost_per_unit",
          editable: true,
          type: "currency"
        },
        {
          name: "TOTAL VALUE",
          key: "total_value",
          type: "currency"
        },
        {
          name: "DISTRIBUTION ORDER NUM.",
          key: "dist_order_number"
        },
        {
          name: "MAPPED SALE",
          key: "isMappedSale"
        }
      ]
    }

    return (
      <Flex flexDirection="column">


        {rowSelected && rowSelected.type
          ? <>
            <form id="rewardsAction-form">
              <Box height="40px" />
              <NunitoSans
                fontSize="22px"
                fontWeight="bold"
                color="#000000"
                style={{ width: "100%", textAlign: "center", marginBottom: "38px" }}
              >
                SALES DETAIL
              </NunitoSans>
              {tableStructure[rowSelected.type].map((item, index) => {
                if (item.key === 'invoice_age' && (rowSelected.paid || rowSelected.status === 'void')) return;
                if (item.key === 'note' && rowSelected.status !== 'Void') return;
                if (item.key === 'sales_rep_fee' && parseFloat(rowSelected[item.key]) === 0) return;

                if (!systemSettings.part_number_active && item.key === "sku") return;

                return <Flex
                  alignItems="center"
                  style={{ width: "100%", backgroundColor: index % 2 === 1 ? "transparent" : "#FAFAFA", paddingBottom: '7px', paddingTop: '7px' }}
                >
                  <NunitoSans
                    fontSize="14px"
                    fontWeight="bold"
                    color="#000000"
                    style={{ width: "50%", marginLeft: "35px" }}
                  >
                    {item.name}
                  </NunitoSans>
                  {(rowSelected.type === 'sales') && this.state.editOpen && item.editable ?
                    item.key === 'rep_name'
                      ?
                      <Field
                        name={item.key}
                        component="select"
                        style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: "#428BF4", border: "1px solid #428BF4", paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                      >
                        {
                          salesReps.length
                            ?
                            salesReps.map(user => <option key={user.id} value={`${user.first_name} ${user.last_name}~${user.sales_id}`}>{`${user.first_name} ${user.last_name}-${user.sales_id}`}</option>)
                            :
                            null
                        }
                        {
                          !rowSelected.rep_id || !salesReps.map(user => user.sales_id).includes(rowSelected.rep_id)
                            ?
                            <option key={rowSelected.id+index+item.key} value={`${rowSelected[item.key]}~${rowSelected.rep_id}`}>{`${rowSelected[item.key]}-${rowSelected.rep_id}`}</option>
                            :
                            null
                        }
                      </Field>
                      :
                      ["category_one", "category_two", "category_three"].includes(item.key)
                        ?
                        <Field
                          name={item.key}
                          component="select"
                          style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: colors.buttonCustom, border: "1px solid", borderColor: colors.buttonCustom, paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                        >
                          {
                            categories.map((category, i) => <option key={category + i} value={category}  >{category}</option>)
                          }
                        </Field>
                        :
                        <Field
                          name={item.key}
                          component="input"
                          style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: "#428BF4", border: "1px solid #428BF4", paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                          {...item.type === 'currency' ? this.currencyMask() : item.type === "number" ? this.numberMask : {}}
                        />
                    :
                    <NunitoSans
                      fontSize="14px"
                      fontWeight="bold"
                      color="#000000"
                      style={{ width: "50%" }}
                    >
                      {
                      item.key === "invoice_date" || (item.key === "date" && rowSelected[item.key] !== null) || item.key === "posted_date" || item.key === "due_on" || item.key === "created_at" ? 
                      moment(rowSelected[item.key], moment.ISO_8601).add(item.key === "created_at" ? 0 : 1, 'days').format("MM/DD/YYYY") : 
                      (item.type === 'number' && rowSelected[item.key] !== null) ? 
                      numberParser(parseFloat(rowSelected[item.key])) : 
                      (item.type === "currency" && rowSelected[item.key] !== null) ? 
                      this.currencyParser(parseFloat(rowSelected[item.key])) :
                      item.key === "isMappedSale" ?
                      (rowSelected.isMappedSale ? 'YES' : 'NO' ) : 
                      rowSelected[item.key]}
                    </NunitoSans>
                  }

                </Flex>
              })}

              {
                  rowSelected.type === "sales"  ?
                    <Flex
                      style={{ marginTop: "27px" }}
                    >
                      {this.state.editOpen ?
                        <Flex
                          flexDirection="column"
                          style={{ width: "100%", border: "1px solid #428BF4", borderRadius: "8px", boxShadow: "-4px 3px 6px #00000029", padding: "15px 20px" }}
                        >
                          <NunitoSans
                            fontSize="13px"
                            fontWeight="bolder"
                            color="#428BF4"
                            style={{ width: "100%", textAlign: "center", marginBottom: "15px" }}
                          >
                            {` EDIT SALES DATA`}
                          </NunitoSans>

                          <NunitoSans
                            fontSize="15px"
                            fontWeight="bold"
                            color="#000000"
                          >
                            {`Why are you editing this sales data?`} <span style={{ color: "#FE4A49" }}>*</span>
                          </NunitoSans>

                          <Field
                            name={"edit_note"}
                            component="textarea"
                            placeholder="Add a reason..."
                            rows="6"
                            style={{ padding: "2.5px 2.5px", border: "1px dashed #A3A99E", borderRadius: "6px", width: "100%" }}
                          />


                          <Flex
                            justifyContent="space-between"
                            style={{ marginTop: "20px" }}
                          >
                            <DashboardSmallCTA
                              onClick={() => this._updateSale()}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%" }}
                            >
                              SAVE
                            </DashboardSmallCTA>
                            <DashboardSmallCTA
                              onClick={() => this.setState({ editOpen: false })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%", marginRight: "-5px" }}
                            >
                              CANCEL
                            </DashboardSmallCTA>
                          </Flex>

                        </Flex>
                        :

                        !rowSelected.isMappedSale && getUser().company_admin && (getUser().company_id === rowSelected.company_id)
                          ?
                          <Flex
                            justifyContent="space-between"
                            width="100%"
                          >
                            <DashboardSmallCTA
                              onClick={() => this.setState({ editOpen: true })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%" }}

                            >
                              EDIT
                            </DashboardSmallCTA>
                            
                          </Flex>
                          :
                          null
                      }
                    </Flex>
                    :
                    null
              }
            </form>
            {((rowSelected.type === 'sales' && saleHistory.length > 0)) && !this.state.editOpen ?
              <>
                <NunitoSans
                  color="red"
                  style={{ width: "100%", textAlign: 'center', marginTop: '35px', marginBottom: '5px' }}
                >
                  CHANGES
                </NunitoSans>

                <Flex
                  style={{ width: '100%' }}
                  justifyContent="space-between"
                >
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    DATE
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    EDITED BY
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    REASON
                  </NunitoSans>
                </Flex>

                {saleHistory.map((item, index, arr) => <Flex
                  style={{ width: '100%', borderBottom: index !== arr.length - 1 ? '1px dashed gray' : "none", paddingBottom: '10px', marginTop: '10px' }}
                  justifyContent="space-between"
                >
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {moment(item.created_at).format('L')}
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {item.edited_by}
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {item.reason}
                  </NunitoSans>
                </Flex>
                )}

              </>
              :
              null
            }
          </>
          :
          null}
      </Flex>
    )
   
  }
}

ManageSalesAccessory = reduxForm({
  // destroyOnUnmount: true,
  form: "rewardsAction-form",
  // validate,
  // enableReinitialize : true
})(ManageSalesAccessory);


export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    formValues: getFormValues('rewardsAction-form')(state),
    connections: state.resources.collection.connection || [],
    user: state.resources.detail.user,
    rowSelected: state.incentives.rowSelected
  }),
  dispatch => ({
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    updateSale: bindActionCreators(actions.patchSale, dispatch),
    fetchSales: bindActionCreators(actions.fetchSales, dispatch)
  })
)(ManageSalesAccessory);




function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}