export const LinkedInBasicProfileFields = [
  "id",
  "email-address",
  "first-name",
  "last-name",
  "maiden-name",
  "formatted-name",
  "phonetic-first-name",
  "phonetic-last-name",
  "formatted-phonetic-name",
  "headline",
  "location",
  "industry",
  "current-share",
  "num-connections",
  "num-connections-capped",
  "summary",
  "specialties",
  "positions",
  "picture-url",
  "picture-urls::(original)",
  "site-standard-profile-request",
  "api-standard-profile-request",
  "public-profile-url"
];

export const EMAIL_REGEX =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

export const NON_BIZ_REGEX = "[a-zA-Z0-9_\\.-]+@((hotmail)|(aol)|(googlemail)|(outlook)|(yandex)|(protonmail)|(mail)|(zoho)|(tutanota)|(tutamail)|(tuta)|(keemail)|(icloud)|(yahoo)|(gmail))\\.[a-z]{2,4}";
export const URL_REGEX = /^(http|https):\/\/[^ "]+$/;
