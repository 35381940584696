import React, {useState} from "react";
import { history } from "../../store";
import ReactTooltip from 'react-tooltip';


import { Flex, Box, ClickCursorFlex, IconBox } from "../common/flexbox";
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../common/typography";
import { DashboardSmallCTA } from "../common/forms";
import {defaultCompany, defaultProfile} from "../../utils/defaultLogos";


export const InviteSuggestionBox = ({suggestion={}, sendInvite, handleDismiss, index})=>{
    const {id, first_name, last_name, email} = suggestion;
    const [inviteHoverOn, setInviteHoverOn]=useState(false);
    return (
        <Flex
        className={`suggestion-box invite-background-${(index%4)+1}`}
        flexDirection="column"
        // width="135px"
        alignItems="center"
        style={{padding: "13px 5px", border:"1px solid #A3AA9E", borderRadius: '10px'}}
        mb="8px"
        >
            <Flex
            justifyContent="flex-end"
            width="100%"
            mb="15px"
            
            >
                <IconBox
                className="close-btn"
                color="#A3A99E"
                fontSize="10px"
                data-tip="Dismiss"
                data-place="right"
                onClick={()=>handleDismiss(suggestion.id)}
                >
                    close
                </IconBox>
                <ReactTooltip />
            </Flex>

            <Flex
            flexDirection="column"
            alignItems="center"
            mb='10px'
            >
                <NunitoSansBold
                color="#000000"
                fontSize="17px"
                textAlign="center"
                >
                    {first_name}
                </NunitoSansBold>

                <NunitoSansBold
                color="#000000"
                fontSize="17px"
                textAlign="center"
                >
                    {last_name}
                </NunitoSansBold>

            </Flex>

            <NunitoSansSemiBold
            fontSize="12px"
            color="#000000"
            style={{overflow: "hidden",textOverflow: "ellipsis", maxWidth:"135px", whiteSpace: "nowrap"}}
            >
               {`@${email.split('@')[1]}`}
            </NunitoSansSemiBold>
            
            <Flex
            className="invite-btn"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt="15px"
            onMouseEnter={()=>setInviteHoverOn(true)}
            onMouseLeave={()=>setInviteHoverOn(false)}
            onClick={()=>sendInvite(suggestion)}
            style={{cursor: 'pointer'}}
            data-tip="Invite"
            data-place="right"
            style={{marginBottom: '-10px'}}
            >

                <IconBox
                fontSize="40px"
                color='#2FD371'
                >
                    {/* {inviteHoverOn ? "invite-btn-hover" : "invite-btn-resting"} */}
                    {inviteHoverOn ? "invite_button_alt_hover" : "invite_button_alt_resting"}

                </IconBox>
                <ReactTooltip/>
            </Flex>
    


        </Flex>
    );
}

export const ConnectionSuggestionBox = ({suggestion={}, handleConnect, handleDismiss})=>{
    const {id, name, logo, pseudoName, user_id} = suggestion;

    const theLink = pseudoName ? `/dashboard/profile/${user_id}` : `/dashboard/company/${id}`;

    return (
        <Flex
        className={`suggestion-box`}
        flexDirection="column"
        alignItems="center"
        style={{padding: "13px 5px", border:"1px solid #A3AA9E", borderRadius: '10px', cursor: 'pointer', minHeight: '171px'}}
        mb="10px"
        onClick={()=>history.push(theLink+"?companies")}
        >
            <Flex
            justifyContent="flex-end"
            width="100%"
            // mb="30px"
            
            >
                <IconBox
                className="close-btn"
                color="#A3A99E"
                fontSize="10px"
                data-tip="Dismiss"
                data-place="right"
                onClick={(e)=>{
                    e.stopPropagation();
                    return handleDismiss(id);
                }}
                >
                    close
                </IconBox>
                <ReactTooltip />
            </Flex>

            <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            // mb='15px'
            style={{height:'100%'}}
            >
                <img src={logo ? logo : pseudoName ? defaultProfile(id, name) : defaultCompany(id, name)} alt={`${name}`} width={50} height={50} style={{borderRadius: '50%'}} />
                
                <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt="10px"
                mb="15px"
                >
                    <NunitoSansBold
                    color="#000000"
                    fontSize="17px"
                    textAlign="center"
                    >
                        {name}
                    </NunitoSansBold>

                    {
                        pseudoName
                        ?
                        <NunitoSansSemiBold
                        color="#000000"
                        fontSize="12px"
                        textAlign="center"
                        >
                            {pseudoName}
                        </NunitoSansSemiBold>
                        :
                        null
                    }
                </Flex>

                <DashboardSmallCTA
                color="#2FD371"
                fontSize="15px"
                width="130px"
                onClick={(e)=>{
                    e.stopPropagation();
                    return handleConnect(id);
                }}
                >
                    CONNECT
                </DashboardSmallCTA>

            </Flex>

           
            


        </Flex>
    );
}