import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import AWS from "aws-sdk";
import moment from "moment";
//import { parse } from "csv";
import { parse } from "../../../../node_modules/csv-parse/dist/esm/sync";
import Axios from "axios";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
import { success, error } from "../../../actions/alerts";
//components
import { Flex, Box } from "../../common/flexbox";
import { DashboardSmallCTA } from "../../common/forms";
import { H1CondensedSmallD0D0D0, NunitoSansBold } from "../../common/typography";
import { RecommendedContentWrapper } from "../../common/ui";

import CsvDropzoneModal from "../../common/csvDropzoneModal";
import { LockedFeature } from "../../common/lockedfeature";




export class ManageEmployeesAccessory extends Component {
  state = {
    uploading: false,
    uploadingSalesId: false,
    subdomains: [],
    selectedCsv: null,
    csvDropOpen: false,
    inviteHistory: [],
    repIdHistory: [],
    splitsHistory: [],
    csvErrors: []
  }

  componentDidMount(){
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { company, fetchCompany, fetchCsvuploads } = this.props;

    if (!getUser()) return;

    if (!company) {
      await fetchCompany(getUser().company_id);
    }



    let subdomains = await Axios.get(`${process.env.REACT_APP_API_URL}/subdomains/${getUser().company_id}`)
    subdomains = subdomains.data;
    subdomains = subdomains.map(item => item.body);

    this.setState({ subdomains: subdomains });

    const CSVUploads =  fetchCsvuploads({ company_id: getUser().company_id, type: 'vendor_invite' });
    if (CSVUploads) {
      console.log("src/components/dashboard/employees/accessory.js  CSVUploads", CSVUploads);
      console.log("src/components/dashboard/employees/accessory.js  CSVUploads", JSON.stringify(CSVUploads));
      console.log("src/components/dashboard/employees/accessory.js  typeof CSVUploads", typeof CSVUploads);
      console.log("src/components/dashboard/employees/accessory.js  CSVUploads.length", CSVUploads.length);
      const entries = Object.entries(CSVUploads);
      console.log("src/components/dashboard/employees/accessory.js ",entries);
      if (CSVUploads.length > 0) {
        // const upHist = CSVUploads.filter((a, index) => index < 6);
        // this.setState({ uploadHistory: upHist });

        let inviteHistory = CSVUploads.filter(item => item.type === 'employee_invite').filter((a, i) => i < 6);
        let repIdHistory = CSVUploads.filter(item => item.type === 'rep_id').filter((a, i) => i < 6);
        let splitsHistory = CSVUploads.filter(item => item.type === 'split').filter((a, i) => i < 6);
        this.setState({ inviteHistory, repIdHistory, splitsHistory });
      }

    }

  }

  _onSubmit = async data => {
    const {
      createUser,
      resetForm,
      notifyError,
      notifySuccess
    } = this.props;

    let LeEmail = data.email;
    LeEmail = LeEmail.toString().trim().toLowerCase();
    data.email = LeEmail;

    let userPayload = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      ...{
        company_id: getUser().company_id,
        status: "invited",
        inviter_id: getUser().id,
        inviter_user_avatar: getUser().avatar,
        inviter_user_position: getUser().position,
        inviter_user_first_name: getUser().first_name,
        inviter_user_last_name: getUser().last_name
      }
    };

    let getUserEmail = getUser().email.split("@")[1];
    let userPayloadEmail = userPayload.email.split("@")[1];

      //check if the email is already associated with another user
      let activeEmail = null;
      try {
        activeEmail = await fetch(`${process.env.REACT_APP_API_URL}/emailcheck/${data.email}`).then(res => res.json());
      } catch(e){
        console.log(e);//user email is not in system
      }
      if (activeEmail && activeEmail.id) {
        return notifyError("This email is already associated with another user in the system.")
      }

      const user = await createUser({
        ...userPayload,
        doNotUpdateState: true
      });
      

      if (user && user.payload) {
        // console.log("src/components/dashboard/invitations/accessory.js ", user);

        notifySuccess(
          "An invitation email has been sent to " + userPayload.first_name + " " + userPayload.last_name + " at " + userPayload.email
        );
        this.props.fetchUsers({ company_id: getUser().company_id });
      }

    resetForm("create-user");
  };

/*
  _createUser = async data => {
    const { notifyError, company, createUser, fetchUsers, resetForm, notifySuccess } = this.props;
    const { subdomains } = this.state;

    let valuesEmail = data.email;
    let company_domain = company.domain;
    if (data.email) {
      data.email = valuesEmail;
      valuesEmail = valuesEmail.toString().trim().toLowerCase();
      company_domain = company_domain.toString().toLowerCase();
      if (!subdomains.includes(valuesEmail.split("@")[1])) {
        return;
      }
    }

    fetchUsers({ company_id: getUser().company_id }).then(users => {

      let invitedEmployees = users.payload.map(employee => employee.status === 'invited' ? employee.email : "");
      let activeEmployees = users.payload.map(employee => employee.status === 'active' ? employee.email : "");

      if (invitedEmployees.includes(valuesEmail)) {


        notifyError("You have already invited this person, to remind them click the RESEND button below.");

      } else if (activeEmployees.includes(valuesEmail)) {
        notifyError("You have already invited this coworker.");

      } else {
        console.log(getUser())
        let payload = {
          ...data,
          ...{
            company_id: getUser().company_id,
            status: "invited",
            inviter_id: getUser().id,
            inviter_user_avatar: getUser().avatar,
            inviter_user_position: getUser().position,
            inviter_user_first_name: getUser().first_name,
            inviter_user_last_name: getUser().last_name
          }
        };
        createUser(payload).then(result => {
          notifySuccess(result.payload.message);
          fetchUsers({ company_id: getUser().company_id });

        });


      }
    });

    resetForm("create-user");
  };
  */

  _uploadSalesIdCsv = async files => {
    const { updateUser, fetchUsers, createCsvupload, notifyError, notifySuccess, redirect } = this.props;
    const reader = new FileReader();
    let file = files[0];
    let fileErrors = [];
    let results = [];


    this.setState({ uploading: true });

    if (typeof file === "undefined") {
      this.setState({ uploading: false });
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    reader.onload = () => {
      parse(reader.result, async (err, data) => {
        console.log(data);

        const keys = ["email", "sales_id"];
        for (let i = 1; i < data.length; i++) {
          results.push({
            [keys[0]]: data[i][0].toLowerCase().trim() || '',
            [keys[1]]: data[i][1] || '',
          })

        }

        results = results.filter(item => item.email && item.sales_id)

        // console.log(results.filter(item=> !this.state.subdomains.includes(item.email.split('@')[1])), this.state.subdomains)

        let emails = results.map(item => item.email);
        let salesIds = results.map(item => item.sales_id);

        results.forEach((item, index) => {
          let errors = []
          if (!keys.every(key => !item[key])) {

            keys.forEach(key => {

              if (key === "email") {
                if (!this.state.subdomains.includes(item[key].split('@')[1])) {
                  errors.push(`Incorrect Domain at ${key}`)
                }
                if (emails.indexOf(item[key]) !== emails.lastIndexOf(item[key])) {
                  errors.push(`DUPLICATE ${key}`)
                }
              } else if (key === "sales_id") {
                if (salesIds.indexOf(item[key]) !== salesIds.lastIndexOf(item[key])) {
                  errors.push(`DUPLICATE ${key}`)
                }
              }

            })
          }
          if (errors.length !== 0) {
            fileErrors.push({
              line: index + 1,
              errors: errors
            })
          }
        })


        // if(results.filter(item=> !this.state.subdomains.includes(item.email.split('@')[1])).length>0){
        //   this.setState({uploading: false})
        //   return notifyError('Emails with incorrect domain found in uploaded CSV. Please fix and try again.')
        // }

        // if(results.map(item=>item.email).some((item, index, arr)=> index !== arr.lastIndexOf(item))){
        //   this.setState({uploading: false})
        //   return notifyError('Duplicate emails found in uploaded CSV. Please fix and try again.')
        // }

        // if(results.map(item=>item.sales_id).some((item, index, arr)=> index !== arr.lastIndexOf(item))){
        //   this.setState({uploading: false})
        //   return notifyError('Duplicate sales IDs found in uploaded CSV. Please fix and try again.')
        // }

        if (fileErrors.length > 0) {
          this.setState({ uploading: false, csvErrors: fileErrors })
        } else {

          const historyPayload = {
            company_id: getUser().company_id,
            user_id: getUser().id,
            user_name: getUser().first_name + " " + getUser().last_name,
            type: 'rep_id',
            records_amount: results.length
          }

          createCsvupload(historyPayload);

          const response = await updateUser(getUser().id, { rep_id_csv: JSON.stringify(results) });

          await fetchUsers({ company_id: getUser().company_id, offset: 0 });
          this.setState({ uploading: false, csvErrors: [] });
          redirect(window.location.pathname);
          notifySuccess(`Uploaded CSV is processed successfully.`);

        }


      }
      )
    }

    reader.readAsText(file);
  }


  _uploadEmployeeCsv = (files, inviteMessage) => {
    const { createBatchInvite, notifySuccess, notifyError } = this.props;
    let file = files[0];

    this.setState({ uploading: true });

    if (typeof file === "undefined") {
      this.setState({ uploading: false });
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    if (!inviteMessage || inviteMessage.trim().length === 0) {
      this.setState({ uploading: false });
      return notifyError('INVITE MESSAGE REQUIRED.');
    } else if (inviteMessage.trim().length > 300) {
      this.setState({ uploading: false });
      return notifyError('Invite message cannot be more than 300 characters.');
    }

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });
    
    const s3Bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });
    const fileKey = `csv-uploads/${moment().valueOf()}${getUser().company_id}`;
    let s3Data = {
      Key: fileKey,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    };

    return s3Bucket.putObject(s3Data, async (err, response) => {
      const bi = await createBatchInvite({
        user_id: getUser().id,
        company_id: getUser().company_id,
        url: fileKey,
        invite_type: "employee",
        status: "created",
        inviter_first_name: getUser().first_name,
        inviter_last_name: getUser().last_name,
        inviter_position: getUser().position || "position",
        inviter_company_name: "company.name",
        inviter_profile_pic: `<img  width="30%" height="30%" src=${getUser().avatar}>`,
        profile_pic: `<img  width="30%" height="30%" src=${getUser().avatar}>`,
        inviter_company_logo: `<img  width="30%" height="30%" `,
        company_logo: `<img  width="30%" height="30%" >`,
        inviteMessage
      });

      if (bi.payload.id) {
        this.setState({ uploading: false });
        notifySuccess(
          "We will email you when we have finished processing your upload."
        );
      }
    });
  };

  _uploadSplitsCsv = async files => {
    const { updateUser, fetchUsers, createCsvupload, notifyError, notifySuccess, redirect } = this.props;
    const reader = new FileReader();
    let file = files[0];
    let results = [];

    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };


    this.setState({ uploading: true });

    if (typeof file === "undefined") {
      this.setState({ uploading: false });
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    reader.onload = () => {
      parse(reader.result, async (err, data) => {
        console.log(data);

        const keys = ["giver_email", "getter_email", "percent"];
        for (let i = 1; i < data.length; i++) {
          results.push({
            [keys[0]]: data[i][0].toLowerCase().trim() || '',
            [keys[1]]: data[i][1].toLowerCase().trim() || '',
            [keys[2]]: parseInt(data[i][2].toLowerCase().trim()) || ''
          })

        }

        results = results.filter(item => item.giver_email && item.getter_email && item.percent)

        let splitsObject = groupBy(results, 'giver_email');







        if (results.filter(item => !this.state.subdomains.includes(item.giver_email.split('@')[1]) || !this.state.subdomains.includes(item.getter_email.split('@')[1])).length > 0) {
          this.setState({ uploading: false })
          return notifyError('Emails with incorrect domain found in uploaded CSV. Please fix and try again.')
        }

        if (results.some(item => item.percent < 1 || item.percent > 100)) {
          this.setState({ uploading: false })
          return notifyError('Percent Value cannot be less than 1 and larger than 100. Please fix and try again.')
        }

        for (const key in splitsObject) {

          const user_payload = await fetch(`${process.env.REACT_APP_API_URL}/user/${key}`);
          const giver_user = await user_payload.json();

          if (!giver_user.id) {
            this.setState({ uploading: false })
            return notifyError(`${key} is not associated with any of your employees. Please fix and try again.`);
          } else if (!giver_user.sales_id) {
            this.setState({ uploading: false })
            return notifyError(`${key} is not assigned a sales id (rep id). Please fix and try again.`);
          }

          if (splitsObject[key].map(item => item.getter_email).some((item, index, arr) => index !== arr.lastIndexOf(item))) {
            this.setState({ uploading: false })
            return notifyError(`Duplicate emails found in uploaded CSV for ${key}. Please fix and try again.`)
          }

          if (splitsObject[key].map(item => item.percent).reduce((a, b) => a + b, 0) > 100) {
            this.setState({ uploading: false })
            return notifyError(`Percentage Total for ${key} cannot be larger than 100. Please fix and try again.`);
          }
        }

        const historyPayload = {
          company_id: getUser().company_id,
          user_id: getUser().id,
          user_name: getUser().first_name + " " + getUser().last_name,
          type: 'split',
          records_amount: results.length
        }

        createCsvupload(historyPayload);


        await updateUser(getUser().id, { splits_csv: JSON.stringify(splitsObject) });

        await fetchUsers({ company_id: getUser().company_id, offset: 0 });
        redirect(window.location.pathname);
        notifySuccess(`Uploaded CSV is processed successfully.`)

        this.setState({ uploading: false });

      }
      )
    }

    reader.readAsText(file);
  }

  _handleCsvUpload = (files, inviteMessage = '') => {
    const { selectedCsv } = this.state;

    if (selectedCsv === 'invite') return this._uploadEmployeeCsv(files, inviteMessage);
    if (selectedCsv === 'rep id') return this._uploadSalesIdCsv(files);
    if (selectedCsv === 'splits') return this._uploadSplitsCsv(files);
  }

  _handleCloseModal = () => {

    this.setState({ selectedCsv: null, csvDropOpen: false, uploading: false, csvErrors: [] })
  };

  validate = values => {
    const { company } = this.props;
    const { subdomains } = this.state;
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    if (!values.message || !values.message.trim()) {
      errors.message = "Message required";
    } else if (values.message.trim().length > 300) {

      errors.message = "Message cannot be more than 300 characters.";

    }




    let valuesEmail = values.email;
    let company_domains = subdomains;
    if (!company_domains.includes(company.domain)) {
      company_domains.push(company.domain)
      this.setState({ subdomains: company_domains })
    }


    if (values.email) {
      valuesEmail = valuesEmail.toString().toLowerCase();
      // company_domain = company_domain.toString().toLowerCase(); 
      if (!company_domains.includes(valuesEmail.split("@")[1])) {
        errors.email = `Email domain must match ${company_domains.join(', ')}.`;
      }

    }


    return errors;
  };

  _handleInviteCsvUpload = () => {
    this.setState({ csvDropOpen: true, selectedCsv: 'invite' })
  };
  //below is co-workers page accessory side //
  render() {
    const { selectedCsv, csvDropOpen, uploading, csvErrors, inviteHistory, repIdHistory, splitsHistory } = this.state;
    const { company, redirect, systemSettings, companySettings, featureFlags } = this.props;

    const csvTemplateHeaders = {
      invite: [
        { label: 'WORK EMAIL', key: 'work_email' },
        { label: 'FIRST NAME', key: 'first_name' },
        { label: 'LAST NAME', key: 'last_name' }
      ],
      splits: [
        { label: 'GIVER EMAIL', key: 'giver_email' },
        { label: 'GETTER EMAIL', key: 'getter_email' },
        { label: 'PERCENT', key: 'percent' }
      ],
      repids: [
        { label: 'WORK EMAIL', key: 'work_email' },
        { label: 'REP ID', key: 'rep_id' }
      ]
    }

    if (company) {
      return (
        <Flex flexDirection="column">
          <Box height="76px" />
          <Flex flexDirection="column" style={{ height: "89vh", overflow: "auto", paddingLeft: "5px", paddingRight: "25px", paddingTop: "5px" }}>

            <Flex
              mt="0px"
              mb="54px"
              flexDirection={["column"]}
            >


              <RecommendedContentWrapper noLabel={true}>
                <Flex flexDirection="column" pt="14px" px="8px">
                  <Flex px="1%" flexDirection="row" justifyContent="space-between">
                    <Box maxWidth="80%">
                      <H1CondensedSmallD0D0D0>Manage</H1CondensedSmallD0D0D0>
                    </Box>
                  </Flex>

                  <Flex pt="19px" flexDirection="column" px="1%">

                    <Flex flexDirection="column" justifyContent="center" alignItems="center">

                      {
                        featureFlags.departments === true 
                        ?
                        <DashboardSmallCTA
                          width="100%"
                          onClick={() => {
                            redirect(
                              `/dashboard/company/${getUser().company_id}/departments`
                            )
                          }}
                          minWidth={["90px"]}
                          my="10px"
                          height="34px"
                        >
                          DEPARTMENTS
                        </DashboardSmallCTA>
                        :
                        featureFlags.departments === "locked"
                        ?
                        <LockedFeature 
                        text="Upgrade to Add Departments"
                        width="100%"
                        padding="10px"
                        fontSize="12px"
                        marginBottom="10px"
                        />
                        :
                        null
                      }
                     
                      {
                        (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && systemSettings.rewards_active && systemSettings.reward_splits && companySettings.splits_active ?
                          <DashboardSmallCTA
                            width="100%"
                            onClick={() => {
                              this.setState({ csvDropOpen: true, selectedCsv: 'splits' })
                            }}
                            minWidth={["90px"]}
                            my="10px"
                            height="34px"
                          >
                            UPLOAD SPLITS CSV
                          </DashboardSmallCTA>
                          :
                          null
                      }

                    </Flex>
                  </Flex>
                </Flex>
                <CsvDropzoneModal
                  modalOpen={csvDropOpen}
                  handleCloseRequest={this._handleCloseModal}
                  handleCsvUpload={uploading ? () => { } : this._handleCsvUpload}
                  csvName={selectedCsv || ''}
                  uploading={uploading}
                  csvErrors={csvErrors}
                  csvHeaders={csvTemplateHeaders[selectedCsv] || csvTemplateHeaders.repids}
                  uploadHistory={selectedCsv === 'splits' ? splitsHistory : selectedCsv === 'invite' ? inviteHistory : selectedCsv === 'rep id' ? repIdHistory : []}
                />
              </RecommendedContentWrapper>
              <Box height="40px" />
              {/* <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="14px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Departments</H1CondensedSmallD0D0D0>
            </Box>
            </Flex>
            
          <Flex pt="19px" flexDirection="column" px="1%">
          
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="50%"
                onClick={() => {
                  redirect(
                    `/dashboard/company/${getUser().company_id}/departments`
                  )
                }}
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                MANAGE
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper> */}
            </Flex>
          </Flex>
        </Flex>
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    company: state.resources.detail.company
  }),
  dispatch => ({
    createBatchInvite: bindActionCreators(actions.createBatch_invite, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    fetchCsvuploads: bindActionCreators(actions.fetchCsvuploads, dispatch),
    createCsvupload: bindActionCreators(actions.createCsvupload, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    redirect: bindActionCreators(push, dispatch)
  })
)(ManageEmployeesAccessory);
