import React from "react";

import {
  TwoPartFieldMarkupOnly,
  renderSmallField
} from "../../../../common/forms";

let ExistingProfileAttribute = props => {
  const { clickHandler, formLabel } = props;
  return (
    <TwoPartFieldMarkupOnly
      fieldOneProps={{
        disabled: true,
        label: formLabel,
        name: "body",
        placeholder: "(415) 555-5555",
        component: renderSmallField,
        type: "text",
        fieldValue: props.initialValues.body
      }}
      fieldTwoProps={{
        disabled: true,
        label: "LABEL",
        name: "label",
        placeholder: "Sales",
        component: renderSmallField,
        type: "text",
        fieldValue: props.initialValues.label
      }}
      icon="close"
      notActionable={props.initialValues.disabled}
      clickHandler={clickHandler}
    />
  );
};

export default ExistingProfileAttribute;
