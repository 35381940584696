import React, {Component, useState} from "react";
// import ReactTooltip from 'react-tooltip';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../store";
import InfiniteScroll from "react-infinite-scroller";

import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";

import { getUser, dismissInviteSuggestion, getDismissedInviteSuggestions, dismissSuggestionDuringSession, getDismissSuggestionDuringSession} from "../../../utils/auth";

import { Flex, Box, ClickCursorFlex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
import { BackButton } from "../../common/backbutton";
import {InviteSuggestionBox} from "../../common/suggestions";
import { InputStyled, DashboardSmallCTA, DashboardSmallToggleCTA } from "../../common/forms";

let timeout =null;

class InviteSuggestionsMain extends Component {

    state={
      dismissedInvites: [],
      search: '',
      inviteSearchSuggestions: [],
      searchLoaded: false,
      suggestionsInView: [],
      hasMore: true,
      currentPage: 1
    };

    componentDidMount(){
      const {fetchInviteSuggestions, inviteSuggestions, company, fetchCompany, companySettings } = this.props;

      if(!companySettings.invite_suggestion_active) return history.push('/dashboard');

      if(!inviteSuggestions || !inviteSuggestions.length){

        fetchInviteSuggestions({
          company_id: getUser().company_id,
          user_id: getUser().id
        }).then(res=>console.log(res.payload, "INVITE SUGGESTIONS"))
      }

      if(!company || company.id!==getUser().company_id){
        fetchCompany(getUser().company_id);
      }

      this.setState({dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]});
    }

    _handleInvite = async({id, first_name, last_name, email})=>{
      const {company, notifyError, notifySuccess, createUser, createConnection, fetchInviteSuggestions} = this.props;

      this._dismissDuringSession(id);

      let userPayload = {
        email,
        first_name,
        last_name,
        status: "invited_company",
        inviter: {
          ...getUser(),
          company_name: company.name
        }
      };
  

      const user = await createUser(userPayload);

      if (user && user.payload) {
        // console.log(user);
         let result = await createConnection({
           vendor_id: getUser().company_id,//maye hard-code this to KickPost
           company_id: getUser().company_id,//getUser().company_id,
           user_id: getUser().id,
           invitee_id: user.payload.invited_company_user.id,
           status: "invited",
           inviteMessage: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales."
         });
         
         if(typeof result !== "undefined" && result.payload.status==="invited"){
           notifySuccess(
             "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
           );
           fetchInviteSuggestions({
            company_id: getUser().company_id,
            user_id: getUser().id
          });
           
         } else {
           notifyError(
             "We could not invite this person. Please try again later."
           );
         }
       }else {
        notifyError(
          "We could not invite this person. Please try again later."
        );
      }

    };

    _dismissDuringSession = id =>{
      dismissSuggestionDuringSession(id);
      this.setState({
        dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]
      });
    }

    _handleDismiss = id =>{
      dismissInviteSuggestion(id);
      this.setState({
        dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]
      });
    }

    _updateSearch = (value)=>{
      this.setState({search: value});

      clearTimeout(timeout);

      if(!value || value.length===0) return this.setState({inviteSearchSuggestions: [], searchLoaded: false, suggestionsInView: [], hasMore: true, currentPage: 1});

      timeout = setTimeout(()=>this._fetchInvitesBySearch(value.trim().toLowerCase()), 1000);
    }

    _clearSearch = ()=>{
      this.setState({search: '', inviteSearchSuggestions: [], searchLoaded: false, suggestionsInView: [], hasMore: true, currentPage: 1});
    }

    _fetchInvitesBySearch = async(value='')=>{

      if(!value || value.length<2) return;

      
    
      const suggestions = await fetch(`${process.env.REACT_APP_API_URL}/searchinvitesuggestions?user_id=${getUser().id}&search=${value}`).then(res=>res.json()).catch(err=>console.log(err));
  
      this.setState({inviteSearchSuggestions: suggestions, searchLoaded: true})
    }

    _loadMoreSuggestions = page=>{
      const {dismissedInvites, search, searchLoaded, suggestionsInView, currentPage} = this.state;
      const {inviteSuggestions} = this.props;
      
      console.log(page, currentPage, "PAGEEE");
      if(search.length && searchLoaded) return;


      const PAGE_SIZE = 40;

     let filtered = inviteSuggestions.filter(invite=>!dismissedInvites.includes(invite.id));

     let newLoad = filtered.slice((currentPage-1)*PAGE_SIZE, currentPage*PAGE_SIZE);

     let newSuggestions = [...suggestionsInView, ...newLoad];

     this.setState({suggestionsInView: newSuggestions, hasMore: newSuggestions.length < filtered.length, currentPage: currentPage+1});

    }


    render(){
      const {dismissedInvites, search, inviteSearchSuggestions, searchLoaded, suggestionsInView, hasMore} = this.state;
      const {inviteSuggestions} = false;
      // const {inviteSuggestions} = this.props;

      
        if(!inviteSuggestions && !search.length) return null;
        
        let filteredSuggestions = inviteSuggestions.filter(invite=>!dismissedInvites.includes(invite.id));

        let filteredInviteSearchSuggestions = inviteSearchSuggestions.filter(invite=>!dismissedInvites.includes(invite.id));
        
        return(
          <Flex
          flexDirection="column"

          >
            <Flex
            mt="1px"
            mb="15px"
            mx={["0px"]}
            // height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <AbsoluteDiv
                display={["block"]}
                // left={["10px"]}
                // bottom={"0px"}
                top={["6px", "6px"]}
                width={["40px"]}
              >
                  <IconBox
                    // pl={["15px"]}
                    // pb={["15px"]}
                    // pt={["15px"]}
                    fontSize={["18px", "18px"]}
                    color="#c3c3c3"
                  >
                    search
                  </IconBox>
              
              </AbsoluteDiv>
              <InputStyled
                placeholder="Search by First Name, Last Name Or Domain/Company"
                onChange={e => this._updateSearch(e.target.value)}
                value={search}
                // innerRef={input => (this.searchInput = input)}
                style={{paddingLeft:'45px'}}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                // autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                // pt={["3px"]}
                px={["5px"]}
                mt={["0px"]}
                mb={["5px"]}
                height={[31, 40, 40, 40]}
                // fontWeight={400}
                fontSize={[14, 20, 20, 20]}
                lineHeight={1.5}
                color={"#000000"}
                borderBottom={[0]}
              />
              {
                search && search.length
                ?
                <AbsoluteDiv
                display={["block"]}
                right={["10px"]}
                top={["-8px"]}
                width={["40px"]}
              >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={this._clearSearch}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
                :
                null
              }

        

            </Flex>
          
          </Flex>

          

          {

            (search.length && searchLoaded && filteredInviteSearchSuggestions.length)
            ?
            <Flex
            className="suggestion-box-wrapper"
            >
              {filteredInviteSearchSuggestions.slice(0,40).map((suggestion, index)=><InviteSuggestionBox key={index} suggestion={suggestion} sendInvite={this._handleInvite} handleDismiss={this._handleDismiss} index={index} />)}
            </Flex>
            :
            (!(search.length && searchLoaded) && filteredSuggestions.length)
            ?
            <div style={{height: "79vh", overflow: "auto", overflowX: 'hidden'}}>
            {/* <Flex
            className="suggestion-box-wrapper"
             style={{height: "79vh", overflow: "auto"}}
             > */}
                <InfiniteScroll
                  key={[`IS` + `${Math.random().toString()}`]}
                  className="suggestion-box-wrapper"
                  pageStart = {0}
                  loadMore = {this._loadMoreSuggestions} 
                  hasMore={hasMore}
                  loader={<div className="loader"></div>}
                  useWindow = { false }
                  threshold = { 350 }
                  
                  >
                    {suggestionsInView.map((suggestion, index)=><InviteSuggestionBox key={index} suggestion={suggestion} sendInvite={this._handleInvite} handleDismiss={this._handleDismiss} index={index} />)}
                  </InfiniteScroll>
            
            </div>
            :
            search.length===0 || (search.length>1 && searchLoaded)
            ?
            <>
            <NunitoSansSemiBold
            fontSize='20px'
            color="#c3c3c3"
            textAlign='center'
            style={{marginTop:'60px'}}
            >
              {search.length>1 ? "NO RESULTS FOUND" : "NO SUGGESTIONS"}
            </NunitoSansSemiBold>

            {
            search.length && search.trim().split(' ').filter(Boolean).length>1
            ?
            <>
            <NunitoSans
            fontSize='16px'
            color="#c3c3c3"
            style={{marginTop:'20px'}}
            textAlign='center'
            >
              TRY SEARCHING ONE CATEGORY AT A TIME!
            </NunitoSans>

            <NunitoSans
            fontSize='16px'
            color="#c3c3c3"
            textAlign='center'
            >
              (FIRST NAME, LAST NAME <strong>OR</strong> DOMAIN/COMPANY NAME)
            </NunitoSans>
            </>
            :
            null
            }
            </>
            : 
            null
          }

           

            
          </Flex>
        );

    };

};


export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    inviteSuggestions: state.resources.collection.invitesuggestion,
    company: state.resources.detail.company,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchInviteSuggestions: bindActionCreators(actions.fetchInvitesuggestions, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
  })
)(InviteSuggestionsMain);


export const InviteSuggestionsNav = ({ ...props }) => (
    <Flex
      height={"100%"}
      width={"100%"}
      alignItems="flex-end"
      justifyContent="center"
    >
     
      <Flex
        height={["49px", "49px", "60px", "40px"]}
        width={"100%"}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <NunitoSans
          pt={["6px", "6px", "8px", "6px"]}
          fontSize={[14, 14, 18, 18]}
          fontWeight={["bold"]}
        >
          INVITE SUGGESTIONS
        </NunitoSans>
        {/* <QuestionModalTrigger/> */}
        <AbsoluteDiv
          left={["0px", "0px", "0px", "0px", "-20%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
          >
            <BackButton />
          </Flex>
        </AbsoluteDiv>
      </Flex>
    </Flex>
  );
