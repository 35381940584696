import { MAIN_SCROLL_TOP } from "../actions/dashboardPresentation"//'actions/dashboardPresentation'
//import dashboardPresentation from "./dashboardPresentation";

export const defaultState = {
  mainContentScrollTop:0
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case MAIN_SCROLL_TOP:
      newState.mainContentScrollTop = action.payload.scrollTop;
      return newState;
    default:
      return state;
  }
};
