const defaultState = {
  audienceName: null,
  selectedRules: []
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SET_AUDIENCE_NAME":
      newState.audienceName = action.payload;
      return newState;
    case "SET_SELECTED_RULES":
      newState.selectedRules = action.payload;
      return newState;
    default:
      return state;
  }
};
