import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
//utils
import { getUser, dismissSuggestionDuringSession } from "../../../utils/auth";
//actions
import { actions } from "../../../actions/resources";
//actions
import {
  searchProfilePosts,
  searchProfileIncentives
} from "../../../actions/search";
import { Flex, ClickCursorFlex, ClickCursorFlexHover } from "../../common/flexbox";
import {
  OutlineFlex,
  DashboardSmallCTA,
  renderSmallField
} from "../../common/forms";
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";
import { AbsoluteDiv } from "../../common/absolute";
import ProfileDetail from "./detail/index";
import { BackButton } from "../../common/backbutton";

import QuestionModalTrigger from "../../common/questionModalTrigger";


import InfoModal from "../../common/InfoModal";

import connectionsLogo from "../../../images/connections_info_logo.png";

export class ProfileDetailMain extends Component {

  state = {
    userCompany: {},
    isConnection: false,
    isRequestPending: false,
    theUser:{}
  }

  componentDidUpdate(nextProps) {
    const { posts, incentives, user, match, companySettings, systemSettings } = nextProps;
    console.log("componentDidUpdate");

    // if(match.params.id === user.id){
    //   if(match.params.id !== getUser().id){

    //    //FILTER POSTS
    //     let userDepartmentIds = getUser().departments ? getUser().departments.map(department => department.id) : []; //Grab current user's department ids

    //     let yesAllConnections = posts.filter(post => post.all_connections === 'yes'); // Get posts shared with all connections
    //     let noAllConnections = posts.filter(post => post.all_connections === 'no'); // Get posts that are not shared with all connections
    //     let companyPosts = noAllConnections.filter(post => post.company_ids.includes(getUser().company_id)); // Get the posts that are shared with viewer's company 
    //     let userPosts = noAllConnections.filter(post => post.user_ids.includes(getUser().id)); // Get the posts that are shared with viewer
    //     let departmentPosts = noAllConnections.filter(post => post.department_ids ? post.department_ids.some(r => userDepartmentIds.includes(r)): null); // Get the posts that are shared with viewer's departments

    //     let combinedAllPosts = yesAllConnections.concat(companyPosts).concat(userPosts).concat(departmentPosts); // Combine all posts 

    //     console.log(combinedAllPosts, "combinedAllPosts")

    //     let finalPosts = combinedAllPosts.filter(post => post.user_id === user.id && moment().unix() >= moment(post.start_date).unix() && moment().subtract(1, 'd').unix() <= moment(post.end_date).unix() ) // Only active posts from the user


    //     //FILTER INCENTIVES

    //     const CAN_RECEIVE_PARTNER_INCENTIVES = user && user.company_admin && systemSettings.rewards_active && systemSettings.vendor_incentives_active && (!systemSettings.vendor_companies_active || (systemSettings.vendor_companies_active && user.company.vendor_incentive_active)) && companySettings && companySettings.receive_vendor_incentives;

    //     const CAN_RECEIVE_SALES_INCENTIVES = user && systemSettings.rewards_active && systemSettings.sales_incentives_active && (!systemSettings.sales_companies_active || (systemSettings.sales_companies_active && user.company.sales_incentive_active)) && companySettings && companySettings.receive_incentives;

    //     let tempIncentives = [...incentives];

    //     if(!CAN_RECEIVE_PARTNER_INCENTIVES && !CAN_RECEIVE_SALES_INCENTIVES){
    //       //can't receive any incentives
    //       tempIncentives = [];

    //     }else if(CAN_RECEIVE_PARTNER_INCENTIVES && !CAN_RECEIVE_SALES_INCENTIVES){
    //       //only partner incentives

    //       tempIncentives = tempIncentives.filter(incentive=>incentive.is_partner_incentive);

    //     }else if(!CAN_RECEIVE_PARTNER_INCENTIVES && CAN_RECEIVE_SALES_INCENTIVES){
    //       // only sales incentives

    //       tempIncentives = tempIncentives.filter(incentive=>!incentive.is_partner_incentive);
    //     }

    //     let yesAllConnectionsIncentives = tempIncentives.filter(incentive => incentive.all_connections === 'yes'); // Get incentives shared with all connections
    //     let noAllConnectionsIncentives = tempIncentives.filter(incentive => incentive.all_connections === 'no'); // Get incentives that are not shared with all connections
    //     let companyIncentives = noAllConnectionsIncentives.filter(incentive => incentive.company_ids.includes(getUser().company_id)); // Get the incentives that are shared with viewer's company 
    //     let userIncentives = noAllConnectionsIncentives.filter(incentive => incentive.user_ids.includes(getUser().id)); // Get the incentives that are shared with viewer
    //     let departmentIncentives = noAllConnectionsIncentives.filter(incentive => incentive.department_ids ? incentive.department_ids.some(r => userDepartmentIds.includes(r)): null); // Get the incentives that are shared with viewer's departments

    //     let combinedAllIncentives = yesAllConnectionsIncentives.concat(companyIncentives).concat(userIncentives).concat(departmentIncentives); // Combine all incentives 

    //     let finalIncentives = combinedAllIncentives.filter(incentive => incentive.company_id === match.params.id && moment().unix() >= moment(incentive.start_date).unix() && moment().subtract(60, 'days').unix() <= moment(incentive.end_date).unix() ) // Only incentives within 60 days

    //     //update states

    //     this.setState({filteredPosts: finalPosts, filteredIncentives: finalIncentives});

    //      }else{

    //        this.setState({filteredPosts: posts});
    //      }
    // }
  }

  componentDidMount(){
    this._fetchDependencies();
    this._handleSearch('');
  }



  _fetchDependencies = async () => {
    let { DMG, theUser} = this.state;
    DMG+=' _fetchDependencies';

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${getUser().company_id}`);

    this.setState({ userCompany: response.data });
    console.log("componen-tWi-llMount userCompany", response.data);



    const { fetchPosts, fetchIncentives, user, fetchConnections, fetchUser, match } = this.props;

    console.log(DMG, " match  ", match);
    if (!user||user.id!=match.params.id) {
      // fetchUser(match.params.id, { edit: true });
      theUser = await fetchUser(match.params.id)
      .then((user)=>{
        console.log(DMG, " this.props", this.props);
        console.log(DMG, " new user",user)
        // setUserState(user);
        })
        .catch((e)=>{
          console.log(DMG, " still no user",e)
        });
    }
    // console.log(DMG,  " user  ", user);
    // console.log(DMG,  " theUser  ", theUser);

    let vendor_ids = [];

    if (!getUser()) return;
    const activeConnections = await fetchConnections({
      company_id: getUser().company_id,
      status: 'active'
    });

    if (activeConnections) {
      activeConnections.payload.forEach(reorder);

      function reorder(v, i, a) { //the results returned from the table are correct but not aligned properly
        if (v.company_id === getUser().company_id) { //this will extract the company_ids that a company is connected with
          vendor_ids.push(v.vendor_id);
        } else {
          vendor_ids.push(v.company_id);
        }
      }
    }

    let isConnection = vendor_ids.includes(match.params.id);

    if (!isConnection) {
      //check if a connection request pending

      const pendingConnections = await fetch(`${process.env.REACT_APP_API_URL}/connections?status=requested&company_id=${getUser().company_id}`).then(res => res.json());

      let pendingIds = [];

      pendingConnections.forEach(item => {
        if (item.company_id === getUser().company_id) {
          pendingIds.push(item.vendor_id);
        } else {
          pendingIds.push(item.company_id);
        }
      });

      this.setState({ isRequestPending: pendingIds.includes(getUser().company_id) })
      // this.setState({isRequestPending: pendingIds.includes(theUser.company.id)})


    }

    this.setState({ isConnection });

    if (isConnection) {
      this._handleSearch('');
    } 


  };

  _createConnection = async () => {
    const { createConnection, user, match, fetchConnectionSuggestions } = this.props;

    if (!user || user.id !== match.params.id) return;

    dismissSuggestionDuringSession(user.company_id);

    await createConnection({
      vendor_id: user.company_id,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchConnectionSuggestions({
      company_id: getUser().company_id
    });
    console.log("fetching dependencies again")
    this._handleSearch("");
  }

  _handleSearch = (input) => {
    const { searchProfilePosts, searchProfileIncentives, match, companySettings, systemSettings, user } = this.props;

    const { userCompany } = this.state;
    let { DMG } = this.state;

    DMG+=' _handleSearch';
    searchProfilePosts({
      input,
      user,
      userId: match.params.id
    });

    searchProfileIncentives({
      input,
      user,
      userId: match.params.id,
      companySettings,
      systemSettings
    });

  }

  render() {
    const { posts, user, match, incentives } = this.props;
    const { isConnection, isRequestPending, userCompany } = this.state;
    const DMG = "src/components/dashboard/profile/detail.js";


    if (posts && user) {


    }

    if (user && user.id === match.params.id) {
      return (
        <ProfileDetail
          key={[`mpd` + `${match.params.id}`]}
          emails={isConnection ? user.email_addresses : []}
          phoneNumbers={isConnection ? user.phone_numbers : []}
          posts={posts}
          incentives={incentives}
          user={user}
          companyDepartments={user.company_departments}
          websites={isConnection ? user.websites : []}
          isConnection={isConnection}
          isRequestPending={isRequestPending}
          createConnection={this._createConnection}
          handleSearch={this._handleSearch}
          userCompany={userCompany}
        />
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    posts: state.search.postSearchResults,
    incentives: state.search.incentiveSearchResults,
    user: state.resources.detail.user || [], //REMOVED ADDED
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    fetchPosts: bindActionCreators(actions.fetchPosts, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchIncentives: bindActionCreators(actions.fetchIncentives, dispatch),
    searchProfilePosts: bindActionCreators(searchProfilePosts, dispatch),
    searchProfileIncentives: bindActionCreators(searchProfileIncentives, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
  })
)(ProfileDetailMain);

export const ProfileDetailNav = props => {
  if (props.user) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >

          {
            getUser().id === props.match.params.id ?
              <>
                <InfoModal                  
                  key={0}
                  modalName='my_profile_modal'  //no spaces. Once set right do not change
                  modalVersion="1.00" //set it to 1.00 initially then change it when needed
                  image={connectionsLogo}
                  title='Get Noticed'
                  text='Complete your profile to make it easier for your Partner Network to recognize you! You can also sync your LinkedIn profile to quickly fill in details.'
                  extraTexts={["Need help? Watch our tutorial video."]}
                  videoLink='https://youtu.be/b7HOcaxPCTc'
                />
                <NunitoSans
                  key={1}
                  pt={["6px", "6px", "8px", "6px"]}
                  fontSize={[14, 14, 18, 18]}
                  fontWeight={["bold"]}
                  justifyContent="center"
                >
                  My Profile
                </NunitoSans>
                <QuestionModalTrigger modalName='my_profile_modal' />
              </>
              :
              null
          }
          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
            >
              <BackButton profile={true} />
            </Flex>
          </AbsoluteDiv>
          {getUser().id === props.user.id && getUser().id === props.match.params.id ? (
            <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
              <Flex
                height={["100%", "100%", "100%"]}
                alignItems={["center"]}
                justifyContent={["flex-end"]}
              >
                {/* <ClickCursorFlexHover 
                  justifyContent="center"
                  alignItems="center"
                  height={["30px", "30px", "40px"]}
                  width={["64px", "64px", "102px"]}
                  onClick={() =>
                    props.redirect(`/dashboard/profile/edit/${getUser().id}`)
                  }
                  borderRadius="9px"
                  bg="whiteGray"
                  color="gray">
                  <NunitoSansExtraBold
                    pt={["2px", "4px"]}
                    fontSize={["17px", "17px", "17px"]}
                    fontWeight="normal"
                  >
                    EDIT
                  </NunitoSansExtraBold>
                </ClickCursorFlexHover> */}
                <DashboardSmallCTA
                  width="15%"
                  onClick={() =>
                    props.redirect(`/dashboard/profile/edit/${getUser().id}`)}
                  minWidth={["80px", "80px", "122px", "122px"]}
                  fontSize={["12px", "12px", "17px", "17px"]}
                  innerHeight={["30px", "30px", "40px", "40px"]}
                // my="12px"
                // height="34px"
                >
                  EDIT
                </DashboardSmallCTA>
              </Flex>
            </AbsoluteDiv>
          ) : null}
        </Flex> 
      </Flex>
    );
  }
  return null;
};
