const defaultState = {
  location: {
    address: "",
    _geoloc: { lng: -87.6244, lat: 41.8756 },
    placeName: "Chicago, Illinois, United States",
    text: "Chicago",
    default: true
  },
  radius: 100,
  worldwide: true,
  departments: [],
  groups: [],
  exclusions: [],
  infoModalOpen: "",
  upgradeModalOpen: false
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SET_DEPARTMENTS":
      newState.departments = action.payload;
      return newState;
    case "SET_GROUPS":
      newState.groups = action.payload;
      return newState;
    case "SET_EXCLUSIONS":
      newState.exclusions = action.payload;
      return newState;
    case "SET_LOCATION":
      newState.location = action.payload;
      return newState;
    case "SET_LOCATION_RULE_TYPE":
      newState.worldwide = action.payload;
      return newState;
    case "SET_RADIUS":
      newState.radius = action.payload;
      return newState;
    case "SET_INFO_MODAL":
      newState.infoModalOpen = action.payload;
      return newState;
    case "SET_UPGRADE_MODAL":
      newState.upgradeModalOpen = action.payload;
      return newState;
    default:
      return state;
  }
};
