
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { Component } from "react";
// import { submit } from "redux-form";
// import { push } from "react-router-redux";
// import { history } from "store";

//utils
import { filenameCleaner, mimeToImage } from "../../../../utils/mimetypes";
//components
import { DottedDropzone } from "../../../common/ui";
import { Box, Flex } from "../../../common/flexbox";
import { Eyebrow } from "../../../common/forms";
import { FileAttachmentBubble } from "../../../common/post";
//actions
// import { actions } from "../../../../actions/resources";
// import { mainContentScrollTop } from "../../../../actions/dashboardPresentation";
import { error } from "../../../../actions/alerts";
// import { error, success } from "../../../../actions/alerts";

const laptopBubbleWidth = 47.3;

export class FileAttachments extends Component {
  constructor(props) {
    console.log(props);
  
    super(props);
  } 

  showErrorMessage(){
    const {  notifyError } = this.props;
    // const { notifySuccess, notifyError } = this.props;
    
    notifyError(
      "File size must be less than 50MB."
    );
  }

  render() {
    const { files, onDrop, removeFile } = this.props;
    // const { files, onDrop, removeFile, notifySuccess, notifyError, limit } = this.props;
    return (
      <Flex flexDirection="column">
        <Eyebrow ml="8px">FILE ATTACHMENT(S)</Eyebrow>
        <Flex
          mt="1px" 
          position="relative"
          justifyContent="space-between"
          flexWrap="wrap"
          pb={"22px"}
        >

        {
          // 25 is limit for attachments
          files.length<25 ?
          <Box
            height="78px"
            width={"100%"}
            mb="20px"
            style={{cursor: 'pointer'}}
          >
            <DottedDropzone onDrop={onDrop} text="Click to Upload File or Drag and Drop File to Upload" />
          </Box>
          :
          null
          
          }



          {files.map((file, i) => {
            console.log(file.size)
            console.log(file)
          
            if((typeof file.size === "undefined") || (typeof file.size === undefined)||file.size < 52428800){
              return (
                <Box key={i} width={["100%", laptopBubbleWidth + "%"]} mb="20px">
                  <FileAttachmentBubble
                    clickHandler={() => removeFile(file)}
                    filename={filenameCleaner(file.name)}
                    image={mimeToImage(file.type, file.name)}
                    key={i}
                    icon="close"
                  />
                </Box>
              );
            } else {

              //this.showErrorMessage();
              window.alert('File size must be less than 50MB');
              
              
             // return null;
            }
            return {}

          })}
          
        </Flex>
      </Flex>
    );
  }
}


export default connect(
  dispatch => ({
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch)
  })
)(FileAttachments);


