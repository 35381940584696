export const breakpoints_em = [ 32, 48, 64, 80, 96 ];
export const em = 16;

export const breakpoints_px = breakpoints_em.map(value =>{ 
   return value * em;
});

export const WIDTH_0 = breakpoints_px[0];
export const WIDTH_1 = breakpoints_px[1];
export const WIDTH_2 = breakpoints_px[2]-1;
export const WIDTH_3 = breakpoints_px[3];
export const WIDTH_4 = breakpoints_px[4];
