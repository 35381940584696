import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
// import {isEqual} from "lodash";
import _ from "underscore";
// import parse from "url-parse";
import moment from "moment";
import axios from "axios";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
import queryString from "query-string";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';


//components
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";
import { ClickCursorFlex, IconBox } from "../../common/flexbox";
import { Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA } from "../../common/forms";
// import Manage-Vendors from "components/dashboard/vendors/search";
import { BackButton } from "../../common/backbutton";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
import currencies from "../../../constants/currency";

import { setIncentiveHovered, setIncentiveSelected } from "../../../actions/incentives";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers,
  searchIncentives,
  searchUsers,
  searchProducts,
  searchMobile,
  searchProductGroups,
  searchProductCategories
} from "../../../actions/search";
// import { set } from "react-ga";

import RouteLeavingGuard from "../../common/routeGuardModal";
import ConfirmModal from "../../common/confirmModal";

// incentive forms
import QuickPay from "./types/quickpay";
import OneToOne from "./types/onetoone";
import Tier from "./types/tier";
import Increment from "./types/increment";
import Volume from "./types/volume";

import InfoModal from "../../common/InfoModal";

import addIcentive from "../../../images/incentive_info_logo.png";

import QuestionModalTrigger from "../../common/questionModalTrigger";

export class ManageIncentiveMain extends Component {
  state = {
    posting: false,
    audienceSearchInput: null,
    clicked: false,
    errors: {},
    files: [],
    videos: [],
    startDate: moment(),
    startDateError: null,
    endDate: moment().add(7, "days"),
    endDateError: null,
    postBody: null,
    videoAdded: false,
    quickpayTableData: [],
    title: '',
    tableData: null,
    rules: null,
    tiers: null,
    po_number: '',
    billing_contact: '',
    earn_amount: '',
    sell_goal: '',
    sell_unit: '',
    user: null,
    company: null,
    units_in: null,
    editMode: false,
    incentiveId: null,
    productSearchValue: '',
    allProductsToggle: false,
    is_partner_incentive: false,
    type: '',
    blockNavigation:true,
    submitted: false
  };

  componentDidMount(){
    this._fetchDependencies();
  }
  

  _fetchDependencies = async () => {
    if(!getUser().company_admin) history.push('/dashboard');
    
    const { fetchSettings, fetchCompany, fetchIncentive, fetchUser, match, user, setIncentiveSelected, companySettings, systemSettings } = this.props;

    let queryParams = queryString.parse(window.location.search);

    if(queryParams.type==="partner"){
      this.setState({is_partner_incentive: true});
    }

    fetchCompany(getUser().company_id, {edit: true}).then(res=>{
      if(systemSettings.product_active){
        this._productSearch('');
      }
    })



    if(!companySettings){

      fetchSettings(getUser().company_id).then(res=> res.payload.create_incentives ? null : history.push('/dashboard'));

    }else if(
      (!companySettings.create_incentives && !companySettings.create_vendor_incentives)
      ||
      (queryParams.type!=='sales' && queryParams.type!=='partner')
      ||
      (!companySettings.create_incentives && queryParams.type==='sales')
      ||
      (!companySettings.create_vendor_incentives && queryParams.type==='partner')
    ){
      history.push('/dashboard')
    }

    


    if (!user) {
      fetchUser(getUser().id);
    }

    

    if (match.params.id) {
      this.setState({incentiveId: match.params.id})
     if(window.location.pathname.includes('edit')){
       this.setState({editMode: true})
     }
      fetchIncentive(match.params.id).then(response => {
        let files = response.payload.files.map(file => {
          return {
            url: file.url,
            name: file.filename,
            type: file.filetype
          };
        });
        
       
        this.setState({
          endDate: moment(response.payload.end_date).utc(),
          postBody: response.payload.content,
          startDate: moment(response.payload.start_date).utc(),
          title: response.payload.title,
          tableData: response.payload.tableData,
          rules: response.payload.rules,
          tiers: response.payload.tiers,
          po_number: response.payload.po_number,
          billing_contact: response.payload.billing_contact,
          user: response.payload.user,
          company: response.payload.company,
          sell_goal: response.payload.sell_goal,
          sell_unit: response.payload.sell_unit,
          earn_amount: response.payload.earn_amount,
          units_in: response.payload.units_in,
          files: files,
          reward_type: response.payload.volume_reward_type,
          prize_description: response.payload.volume_prize_description,
          is_partner_incentive: response.payload.is_partner_incentive,
          type: response.payload.type
        });
        setIncentiveSelected(response.payload.type)
      });
    }

    
    //await fetchCompany(match.params.id);

    //this._fetchDependencies();
    
  }

  _productSearch = input =>{
    const {searchProducts, searchProductGroups, searchProductCategories, company} = this.props;
    const { allProductsToggle } = this.state;
    const payload = allProductsToggle ? [] : company.partner_ids || []
    console.log(payload)
    searchProducts(input, payload );
    searchProductGroups(input, payload);
    searchProductCategories(input);
    // ["COMP6","COMP7","COMP8"]
  }

  _updateProductSearch = input =>{
    this.setState({productSearchValue: input});
    this._productSearch(input);
  }

  _clearProductSearch = ()=> this._updateProductSearch("");

  _handleAllProductsToggle = ()=>{
    const {allProductsToggle, productSearchValue} = this.state;

    this.setState({allProductsToggle: !allProductsToggle});

    setTimeout(()=>this._productSearch(productSearchValue), 200);
  }

  
  _handleQuickPayData = payload => this.setState({quickpayTableData: payload})

  componentDidUpdate(prevProps, prevState){
    const keys = ['onetoone', 'increment', 'tier', 'volume', 'quickpay'];

    if(keys.includes(prevProps.incentiveSelected) && !keys.includes(this.props.incentiveSelected)){
      this.setState({startDate: moment(), endDate: moment().add(7, "days")})
    }

    if(this.state.type && this.state.type !== this.props.incentiveSelected){
      this.props.setIncentiveSelected(this.state.type)
    }

    
  }

  componentWillUnmount(){
    this.props.setIncentiveSelected('');
  }

 

  onDrop = async acceptedFiles => {
    this.setState({
      files: _.union(this.state.files, acceptedFiles)
    });
  };

  removeFile = file => {
    let fileIndex = this.state.files.indexOf(file);
    let fileArrayClone = this.state.files.slice(0);
    fileArrayClone.splice(fileIndex, 1);
    window.URL.revokeObjectURL(file.preview);
    this.setState({ files: fileArrayClone });
  };

  _updateIncentive = async data => {
    const { redirect, updateIncentive, post, user, incentiveSelected, systemSettings } = this.props;
    const { files, videos, startDate, endDate, postBody, incentiveId } = this.state;

    const errors = this._validate(data);

    this.setState({posting: true, submitted: true})
    
    

    if (!_.isEmpty(errors)) {
      this.setState({posting: false, submitted: false})
      return this.setState({ errors: errors });
    }


    let promises = files.map(async file => {
      if (file.url) {
        return {
          url: file.url,
          filename: file.name,
          filetype: file.type
        };
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/s3/signed-url`,
        {
          params: {
            content_type: true,
            filename: `posts/${user.company_id}/${file.name}`,
            filetype: file.type
          }
        }
      );

      let options = { headers: { "Content-Type": file.type } };
      const s3Response = await axios.put(
        response.data.signed_url,
        file,
        options
      );

      let payload = {
        url: s3Response.config.url,
        filename: file.name,
        filetype: file.type
      };

      return payload;
    });
 
    let newFiles = await Promise.all(promises);

    let payload = {
      // all_connections: incentiveSelected==="quickpay" ? "no" : connected,
      type: incentiveSelected || "",
      rules: data.rules || {},
      tiers: {...data.tiers} || {},
      tableData: {...data.tableData} || {},
      billing_contact: data.billing_contact || "",
      po_number: data.po_number || "",
      sell_goal: data.sell_goal || 0,
      sell_unit: data.sell_unit || '$',
      earn_amount: data.earn_amount || 0,
      units_in: data.units_in || "QTY",
      volume_reward_type: incentiveSelected==="volume" ? data.reward_type : "",
      volume_prize_description: incentiveSelected==="volume" ? data.prize_description || '' : '',
      // company_sharing: (clicked),//update sharing rule
      // company_ids: goodCompanies,
      // department_ids: JSON.stringify(_.pluck(selectedDepartments, "id")),
      // user_ids: JSON.stringify(_.pluck(selectedUsers, "id")),
      // company_id: getUser().company_id,
//      company_id: (clicked) ? "[]" : (getUser().company_id),//use clicked of this.state here to remove poster's company from receiving this
      content: JSON.stringify(postBody) || "",
      end_date: endDate
        ? endDate.format("YYYY-MM-DD")
        : moment()
            .add(7, "days")
            .format("YYYY-MM-DD"),
      files: JSON.stringify(newFiles),
      start_date: startDate
        ? startDate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      title: data.title,
      status: systemSettings.incentive_approval && (getUser().company_id!==process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID||(getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && systemSettings.incentive_approval_coworkers)) ? 'pending' : 'active',
      // user_id: getUser().id,
      // videos: JSON.stringify(videos)
    };

    const postResponse = await updateIncentive(incentiveId, payload);
    if (postResponse && postResponse.payload.id) {
      // Redirection to dashboard delayed for 2 secs to let Algolia to update
      setTimeout(()=>{
        this.state.blockNavigation = false;
        this.setState({
          files: [],
          videos: [],
          startDate: moment(),
          startDateError: null,
          endDate: moment().add(7, "days"),
          endDateError: null,
          postBody: null,
          post : null
        });
        
        
      this.setState({posting: false})
  
      redirect('/dashboard')
      }, 5000);
    }
  };

  _onSubmit = async data => {
    
    const { 
     // connections,
      createIncentive,
      createReward,
      redirect,
      selectedUsers,
      selectedDepartments,
      selectedCompanies,
      selectedGroups,
      fetchUser,
      user,
      connections,
      incentiveSelected,
      searchCompanies,
      searchDepartments,
      searchConnectionUsers, 
      post,
      resetForm,
      systemSettings,
      companySettings
    } = this.props;
    this.setState({posting: true, submitted: true})
    const { clicked, files, videos, startDate, endDate, postBody, is_partner_incentive } = this.state;
    

    const errors = this._validate(data);
    
   
    if (!_.isEmpty(errors)) {
      this.setState({posting: false, submitted: false})
      return this.setState({ errors: errors });
    }

    
    console.log("new.js",user, user.company,user.company.posting_active,systemSettings.posting_companies_active)
    if(user === undefined || user.company === undefined || user.company.posting_active !== true || systemSettings.posting_companies_active === true){
      this.setState({ posting: false, submitted: false })
      let refreshedErrors = {};
      //refreshedErrors["postBody"] = "Account posting is restricted. Please contact an administrator for an update.";
      console.log(refreshedErrors["postBody"]);
      //return this.setState({ errors: refreshedErrors });
    } else {
      this.setState({blockNavigation: false});
    }



    let promises = files.map(async file => {
      
      if (file.url) {
        return {
          url: file.url,
          filename: file.name,
          filetype: file.type
        };
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/s3/signed-url`,
        {
          params: {
            content_type: true,
            filename: `posts/${user.company_id}/${file.name}`,
            filetype: file.type
          }
        }
      );

      let options = { headers: { "Content-Type": file.type } };
      const s3Response = await axios.put(
        response.data.signed_url,
        file,
        options
      );

      let payload = {
        url: s3Response.config.url,
        filename: file.name,
        filetype: file.type
      };

      return payload;
    });

    let newFiles = await Promise.all(promises);    
    //console.log(selectedCompanies)
    let goodCompanies = "[]";
    let userObj = {"vendor_id":user.company_id}
    connections.push(userObj);

    if(typeof selectedCompanies === "undefined"||selectedCompanies === null||typeof selectedCompanies === null){
      //rejoice, we do nothing
    } else {

     goodCompanies = JSON.stringify(_.uniq(_.pluck(selectedCompanies, "id").concat(_.pluck(selectedGroups, "company_id"))));
    }

    
    let connected = "no";
    
   // if(clicked === false||clicked ==="false"){
      connected = ((selectedCompanies.length + selectedUsers.length + selectedDepartments.length + selectedGroups.length) === 0) ? "yes":"no";
   // }

   
    let payload = {
      incentive_id: await incentiveId(),
      is_partner_incentive,
      all_connections: incentiveSelected==="quickpay" ? "no" : connected,
      type: incentiveSelected || "",
      rules: data.rules || {},
      tiers: {...data.tiers} || {},
      tableData: {...data.tableData} || {},
      billing_contact: data.billing_contact || "",
      po_number: data.po_number || "",
      sell_goal: data.sell_goal || 0,
      sell_unit: data.sell_unit || '$',
      earn_amount: data.earn_amount || 0,
      units_in: data.units_in || "QTY",
      volume_reward_type: incentiveSelected==="volume" ? data.reward_type : "",
      volume_prize_description: incentiveSelected==="volume" ? data.prize_description || '' : '',
      // company_sharing: (clicked),//update sharing rule
      company_ids: goodCompanies,
      department_ids: JSON.stringify(_.pluck(selectedDepartments, "id")),
      user_ids: JSON.stringify(_.pluck(selectedUsers, "id")),
      group_ids: JSON.stringify(_.pluck(selectedGroups, "id")),
      company_id: getUser().company_id,
//      company_id: (clicked) ? "[]" : (getUser().company_id),//use clicked of this.state here to remove poster's company from receiving this
      content: JSON.stringify(postBody) || "",
      end_date: endDate
        ? endDate.format("YYYY-MM-DD")
        : moment()
            .add(7, "days")
            .format("YYYY-MM-DD"),
      files: JSON.stringify(newFiles),
      start_date: startDate
        ? startDate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      title: data.title,
      user_id: getUser().id,
      status: systemSettings.incentive_approval && (getUser().company_id!==process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID||(getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && systemSettings.incentive_approval_coworkers)) ? 'pending' : 'active',
      // videos: JSON.stringify(videos)
    };

   
    await createIncentive(payload);

    setTimeout(()=>{
      this.state.blockNavigation = false;
       // Redirection to dashboard delayed for 5 secs to let Algolia to update
      this.setState({
        files: [],
        videos: [],
        startDate: moment(),
        startDateError: null,
        endDate: moment().add(7, "days"),
        endDateError: null,
        postBody: null,
        post : null
      });
      
      
    this.setState({posting: false})
    this.setState({blockNavigation: false});
    redirect('/dashboard')
    }, 5000);

    async function incentiveId(){
      let count = await fetch(`${process.env.REACT_APP_API_URL}/incentivescount`);
      count = await count.text();
      count = parseInt(count) + 1;
      let total_count = '';
      if (count < 10) {
        total_count = "00000" + count;
      }
      else if (count > 9 && count < 100) {
        total_count = "0000" + count;
      }
      else if (count > 99 && count < 1000) {
        total_count = "000" + count;
      }else if (count > 999 && count < 10000) {
        total_count = "00" + count;
      }else if (count > 9999 && count < 100000) {
        total_count = "0" + count;
      }else{
        total_count = count;
      }

      return `KPI${total_count}`
    }
  };

  _updatePostBody = content => {
    this.setState({ postBody: content });
  };

  _onDateChange = (name, date) => {
    this.setState({ [name]: date });
  };

  _validate = (data) => {
    const { startDate, endDate, postBody } = this.state;
    const {incentiveSelected, systemSettings} = this.props;

    console.log(data, incentiveSelected)

    let refreshedErrors = {};

    if(incentiveSelected==="quickpay"){

      if(data.tableData.length ===0){
        refreshedErrors.errorNotify = "Select at least one person from audience filter"
      }else{
        data.tableData.forEach(row => {
          if(!parseFloat(row.reward)){
            refreshedErrors.errorNotify = 'Reward Number cannot be 0 or blank.';
          }else if(row.reward_type==='prize' && (!row.prize_description || row.prize_description.length < 3)){
            refreshedErrors.errorNotify = 'Prize can not be blank or too short if reward type is prize.';
          }
        })
      }
    }

    if(incentiveSelected !=="quickpay"){
      if(data.rules && data.rules.sales_selection && data.rules.sales_selection.inputValue === "part_numbers" && !data.tableData.length){
        refreshedErrors.errorNotify = "Add at least one product.";
        
       
      }else if(systemSettings.part_number_active && data.tableData.some(row => !row.sku)){
        refreshedErrors.errorNotify = `Enter ${systemSettings.part_number_name}`;
      }else if(data.tableData.some(row => !row.mnfg_part_number)){
        refreshedErrors.errorNotify = `Enter MFG Part Numbers`;
      }else if(!systemSettings.part_number_active && data.tableData.map(row=>row.mnfg_part_number).some((item, index, arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
        refreshedErrors.errorNotify = `There are Duplicate part numbers. Please fix and try again.`;
      }else if(systemSettings.part_number_active && data.tableData.map(row=>row.sku).some((item, index, arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
        refreshedErrors.errorNotify = `There are Duplicate ${systemSettings.part_number_name}. Please fix and try again.`;
      }else if(data.tableData.some(row => !(parseFloat(row.reward_value)>0)) && incentiveSelected === 'onetoone'){
        refreshedErrors.errorNotify = `Reward Value cannot be less than 0.1 or blank`;
      } else if(data.rules){

        Object.keys(data.rules).forEach(rule => {
         console.log(parseFloat(data.rules[rule].inputValue))
          if(data.rules[rule].inputBox && data.rules[rule].inputType !== "select" && data.rules[rule].selected && !(parseFloat(data.rules[rule].inputValue)>0)){
            
           refreshedErrors.errorNotify = `${data.rules[rule].name} Value cannot be less than 0.1 or blank`;
          }
   
          if(!data.rules[rule].inputBox && data.rules[rule].selected){
            if(data.tableData.some(row => !(parseFloat(row.cap_per_product)>0)) && rule==="reward_per_product_cap"){
              refreshedErrors.errorNotify = "CAP PER PRODUCT cannot be less than 0.1 or blank."
            }

            if(data.tableData.some(row => !(parseFloat(row.qty_per_product)>0)) && rule==="quantity_per_product_cap"){
              refreshedErrors.errorNotify = "QTY. PER PRODUCT CAP cannot be less than 0 or blank."
            }
          }
        })
      }

    }

    if(incentiveSelected==="increment" || incentiveSelected==="tier"){
      const lastTier = data.tiers[data.tiers.length-1];
      if(parseFloat(lastTier.units_to) <= 0 || parseFloat(lastTier.units_from) <= 0 || parseFloat(lastTier.reward) <= 0){

        refreshedErrors.errorNotify ="Values cannot be less than or equal to 0 or blank.";
     }
     else if(parseFloat(lastTier.units_to) <= parseFloat(lastTier.units_from)){
      refreshedErrors.errorNotify ="Second Number cannot be less than or equal to first number.";
     }else if(lastTier.reward_type==='prize' && lastTier.prize_description.length<3){
      refreshedErrors.errorNotify ="Prize Description cannot be blank or too short.";
   }else if(lastTier.reward_type==='percent' && (parseFloat(lastTier.reward)<0.1 || parseFloat(lastTier.reward)>100)){
    refreshedErrors.errorNotify ="Percent cannot be less than 0.1 and larger than 100.";
   }
    }

    if(incentiveSelected==="volume"){
      if(!data.sell_goal || parseFloat(data.sell_goal)<=0){
        refreshedErrors.errorNotify = "Sell Goal cannot be lower than or equal to 0.";
      }

      if(!data.earn_amount || parseFloat(data.earn_amount)<=0){
        refreshedErrors.errorNotify = "Earn Amount cannot be lower than or equal to 0.";
      }

      if(data.rules.rep_reward_cap.selected && parseFloat(data.rules.rep_reward_cap.inputValue) < parseFloat(data.earn_amount)){
        refreshedErrors.errorNotify = "Rep Reward Cap cannot be lower than Earn Amount.";
      }

      if(data.reward_type==='prize' && (!data.prize_description || data.prize_description.length<3)){
        refreshedErrors.errorNotify = "Prize Description cannot be blank or too short."
      }

      if(data.reward_type==='percent' && (parseFloat(data.earn_amount)<0.1 || parseFloat(data.earn_amount)>100)){
        refreshedErrors.errorNotify = "Percent cannot be less than 0.1 and larger than 100."
      }
    }

    

    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        refreshedErrors["startDate"] = "Start date cannot be after end date.";
      }

      
      if(endDate.diff(startDate, 'days') > 365){
        refreshedErrors["endDate"] = "End date cannot exceed 1 year.";
      }
    }
    
    if (incentiveSelected==="quickpay" && (!postBody || !postBody.blocks || !postBody.blocks.length || !postBody.blocks.map(block=>block.text || '').join('').trim().length)) {
      refreshedErrors["postBody"] = "Your post must have a kick.";
    }

    return refreshedErrors;
  };
  
  render() {
   
   


      const {company, loading, match, post, user, setIncentiveHovered, setIncentiveSelected, incentiveHovered, incentiveSelected, systemSettings, companySettings, productResults, productCategories, productGroups, browser } = this.props;
    const {
      posting,
      clicked,
      postBody,
      errors,
      files,
      startDate,
      startDateError,
      endDate,
      endDateError,
      videoUrl,
      videos,
      audienceSearchInput,
      videoAdded,
      title,
      tableData,
      rules,
      tiers,
      po_number,
      billing_contact,
      sell_goal,
      sell_unit,
      earn_amount,
      units_in,
      editMode,
      reward_type,
      prize_description,
      productSearchValue,
      allProductsToggle,
      is_partner_incentive,
      submitted
    } = this.state;



    const currencyMask = (decimal=2, negative=false, percentage=false)=>createNumberMask({
      prefix: percentage ? '': currencies[systemSettings.currency].symbol,
      suffix: percentage ? ' %' : '',
      decimalPlaces: decimal,
      allowNegative: negative,
      locale: 'en-US',
    })
    
    const numberMask = createNumberMask({
      // prefix: '$ ',
      // suffix: ' per item',
      // decimalPlaces: 2,
      locale: 'en-US',
    })


    const companyOwner = company && company.users && company.users.filter(user=>user.company_owner)[0];
   console.log({incentiveSelected, ID: match.params.id})
    if (match.params.id && !post && !!loading) return null;
    if (!user) return null;
    if (!companySettings.create_incentives) return null;

      const keys = ['onetoone', 'increment', 'tier', 'volume', 'quickpay'].filter(type=>systemSettings[is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'][type].isActive);    
      
    if(!keys.includes(incentiveSelected) && !match.params.id){
      return (
        <Flex 
          justifyContent="center"
          flexDirection="column" 
          mt="40px"
        >
           <InfoModal
      modalName='add_incentive'  //no spaces. Once set right do not change
      modalVersion="1.00" //set it to 1.00 initially then change it when needed
      image={addIcentive}
      title='Drive Sales Performance'
      text='Incentives are a great way to motivate your partner network and drive sales. For tips on how to create an incentive program, watch our tutorial video: '
      videoLink='https://youtu.be/ngbEasNfCq0'
      />
      {
        keys.map(key=>(
          <DashboardSmallCTA               
            // mt={["10px"]}
            mb={["20px"]}
            // mr={[0]}
            key={key.substring(0,4).trim().toLowerCase().replace(/\s/g, '')}
            width={["100%"]}
            color="#428BF4"
            fontSize="20px"
            innerHeight="50px"
            onMouseOver={()=> setIncentiveHovered(key)}
            onClick={()=> setIncentiveSelected(key)}
        >
            {key==='onetoone' ? "ONE TO ONE" : key==='quickpay' ? "QUICK PAY" : key.toUpperCase()}
        </DashboardSmallCTA>
        ))
      }
       
      </Flex>
      );
    }

    if(keys.includes(incentiveSelected)){

      
      if(incentiveSelected==='quickpay'){

        return (
          <>
          <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && submitted) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
          }}
          />
          <QuickPay
          company={company}
          initialValues={{ 
            billing_contact: billing_contact || (company&& company.billing_contact) || (companyOwner ? `${companyOwner.first_name} ${companyOwner.last_name}` : ''),
            title: title,
            po_number: po_number
          }}
          tableData={tableData}
          posting={posting}
            clicked={clicked}
            // clickToPrivate={this.clickToPrivate}
            // addVideo={this.addVideo}
            editMode={editMode}
            endDate={endDate}
            endDateError={endDateError}
            errors={errors}
            files={files}
            // initialValues={
            //   match.params.id
            //     ? {
            //         title: post && post.title
            //       }
            //     : null
            // }
            postBody={match.params.id ? (postBody) : null}
            // videos={videos}
            onDateChange={this._onDateChange}
            onDrop={this.onDrop}
            // updateVideoInput={this.updateVideoInput}
            onSubmit={editMode ? this._updateIncentive : this._onSubmit}
            removeFile={this.removeFile}
            // removeVideo={this.removeVideo}
            startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
            startDateError={startDateError}
            updateContent={this._updatePostBody}
            audienceSearchInput={audienceSearchInput}
            // videoUrl={videoUrl}
            // videoAdded={videoAdded}
            user={user}
            currencyMask={currencyMask}
            numberMask={numberMask}
            systemSettings={systemSettings}
          />
          </>
        );

        
      }

      if(incentiveSelected==='onetoone'){

        return (
          <>
          <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && submitted) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
          }}
          />
          <OneToOne
          searchValue={productSearchValue}
          search={this._productSearch}
          updateSearch={this._updateProductSearch}
          clearSearch={this._clearProductSearch}
          products={productResults}
          productGroups={productGroups}
          productCategories={productCategories}
          handleAllProductsToggle={this._handleAllProductsToggle}
          allProductsToggle={allProductsToggle}
          browser={browser}
          systemSettings={systemSettings}
          company={company}
          initialValues={{ 
            billing_contact: billing_contact || (company&& company.billing_contact) || (companyOwner ? `${companyOwner.first_name} ${companyOwner.last_name}` : ''),
            title: title,
            po_number: po_number
          }}
          tableData={tableData}
          rules={rules}
          posting={posting}
            clicked={clicked}
            // clickToPrivate={this.clickToPrivate}
            // addVideo={this.addVideo}
            editMode={editMode}
            endDate={endDate}
            
            errors={errors}
            files={files}
            // initialValues={
            //   match.params.id
            //     ? {
            //         title: post && post.title
            //       }
            //     : null
            // }
            // postBody={match.params.id ? (pos) : null}
            // videos={videos}
            onDateChange={this._onDateChange}
            onDrop={this.onDrop}
            // updateVideoInput={this.updateVideoInput}
            onSubmit={editMode ? this._updateIncentive : this._onSubmit}
            removeFile={this.removeFile}
            removeVideo={this.removeVideo}
            startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
            startDateError={startDate.isAfter() ? "This incentive will not be in newsfeed and search results until start date." : null}
            endDateError={endDate.isBefore() ? "This incentive will not be in newsfeed and search results.": null}
            updateContent={this._updatePostBody}
            audienceSearchInput={audienceSearchInput}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
            user={user}
            currencyMask={currencyMask}
            numberMask={numberMask}
            is_partner_incentive={is_partner_incentive}
          />
          </>
        );

        
      }

      if(incentiveSelected==='volume'){

        return (
          <>
          <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && submitted) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
          }}
          />
          <Volume
          searchValue={productSearchValue}
          search={this._productSearch}
          updateSearch={this._updateProductSearch}
          clearSearch={this._clearProductSearch}
          products={productResults}
          productGroups={productGroups}
          productCategories={productCategories}
          handleAllProductsToggle={this._handleAllProductsToggle}
          allProductsToggle={allProductsToggle}
          systemSettings={systemSettings}
          company={company}
          initialValues={{ 
            billing_contact: billing_contact || (company&& company.billing_contact) || (companyOwner ? `${companyOwner.first_name} ${companyOwner.last_name}` : ''),
            title: title,
            po_number: po_number,
            sell_goal: sell_goal,
            sell_unit: sell_unit,
            earn_amount: earn_amount,
            prize_description: prize_description,
            reward_type: reward_type || (systemSettings.reward_type === "prizes" ? 'prize' : 'cash')
          }}
          tableData={tableData}
          rules={rules}
          posting={posting}
            clicked={clicked}
            clickToPrivate={this.clickToPrivate}
            addVideo={this.addVideo}
            editMode={editMode}
            endDate={endDate}
            
            errors={errors}
            files={files}
            // initialValues={
            //   match.params.id
            //     ? {
            //         title: post && post.title
            //       }
            //     : null
            // }
            postBody={match.params.id ? (post && post.content) : null}
            videos={videos}
            onDateChange={this._onDateChange}
            onDrop={this.onDrop}
            // updateVideoInput={this.updateVideoInput}
            onSubmit={editMode ? this._updateIncentive : this._onSubmit}
            removeFile={this.removeFile}
            removeVideo={this.removeVideo}
            startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
            startDateError={startDate.isAfter() ? "This incentive will not be in newsfeed and search results until start date." : null}
            endDateError={endDate.isBefore() ? "This incentive will not be in newsfeed and search results.": null}
            updateContent={this._updatePostBody}
            audienceSearchInput={audienceSearchInput}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
            user={user}
            currencyMask={currencyMask}
            numberMask={numberMask}
            is_partner_incentive={is_partner_incentive}
          />
          </>
        );

        
      }

      if(incentiveSelected==='tier'){

        return (
          <>
          <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && submitted) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
          }}
          />
          <Tier
          searchValue={productSearchValue}
          search={this._productSearch}
          updateSearch={this._updateProductSearch}
          clearSearch={this._clearProductSearch}
          products={productResults}
          productGroups={productGroups}
          productCategories={productCategories}
          handleAllProductsToggle={this._handleAllProductsToggle}
          allProductsToggle={allProductsToggle}
          systemSettings={systemSettings}
          company={company}
          initialValues={{ 
            billing_contact: billing_contact || (company&& company.billing_contact) || (companyOwner ? `${companyOwner.first_name} ${companyOwner.last_name}` : ''),
            title: title,
            po_number: po_number,
            units_in: units_in,
            reward_type: systemSettings.reward_type === "prizes" ? 'prize' : 'cash'
          }}
          tableData={tableData}
          tiers={tiers}
          rules={rules}
          posting={posting}
            clicked={clicked}
            clickToPrivate={this.clickToPrivate}
            addVideo={this.addVideo}
            editMode={editMode}
            endDate={endDate}
            
            errors={errors}
            files={files}
            // initialValues={
            //   match.params.id
            //     ? {
            //         title: post && post.title
            //       }
            //     : null
            // }
            postBody={match.params.id ? (post && post.content) : null}
            videos={videos}
            onDateChange={this._onDateChange}
            onDrop={this.onDrop}
            // updateVideoInput={this.updateVideoInput}
            onSubmit={editMode ? this._updateIncentive : this._onSubmit}
            removeFile={this.removeFile}
            removeVideo={this.removeVideo}
            startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
            startDateError={startDate.isAfter() ? "This incentive will not be in newsfeed and search results until start date." : null}
            endDateError={endDate.isBefore() ? "This incentive will not be in newsfeed and search results.": null}
            updateContent={this._updatePostBody}
            audienceSearchInput={audienceSearchInput}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
            user={user}
            currencyMask={currencyMask}
            numberMask={numberMask}
            is_partner_incentive={is_partner_incentive}
          />
          </>
        );

        
      }

      if(incentiveSelected==='increment'){

        return (
          <>
          <RouteLeavingGuard
          when={Boolean(this.state.blockNavigation)}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            // This case it blocks the navigation when: 
            // 1. the login form is dirty, and 
            // 2. the user is going to 'sign-up' scene.
            //    (Just an example, in real case you might 
            //     need to block all location regarding this case)
            if(location.pathname.startsWith('/dashboard') && submitted) return false;
            if (Boolean(this.state.blockNavigation)) {
              if (location.pathname === 'signup') {
                return true
              }
            } 
            return true
          }}
          />
          <Increment
          searchValue={productSearchValue}
          search={this._productSearch}
          updateSearch={this._updateProductSearch}
          clearSearch={this._clearProductSearch}
          products={productResults}
          productGroups={productGroups}
          productCategories={productCategories}
          handleAllProductsToggle={this._handleAllProductsToggle}
          allProductsToggle={allProductsToggle}
          systemSettings={systemSettings}
          company={company}
          initialValues={{ 
            billing_contact: billing_contact || (company&& company.billing_contact) || (companyOwner ? `${companyOwner.first_name} ${companyOwner.last_name}` : ''),
            title: title,
            po_number: po_number,
            reward_type: systemSettings.reward_type === "prizes" ? 'prize' : 'cash'
            
          }}
          tableData={tableData}
          tiers={tiers}
          rules={rules}
          posting={posting}
            clicked={clicked}
            clickToPrivate={this.clickToPrivate}
            addVideo={this.addVideo}
            editMode={editMode}
            endDate={endDate}
            
            errors={errors}
            files={files}
            // initialValues={
            //   match.params.id
            //     ? {
            //         title: post && post.title
            //       }
            //     : null
            // }
            postBody={match.params.id ? (post && post.content) : null}
            videos={videos}
            onDateChange={this._onDateChange}
            onDrop={this.onDrop}
            // updateVideoInput={this.updateVideoInput}
            onSubmit={editMode ? this._updateIncentive : this._onSubmit}
            removeFile={this.removeFile}
            removeVideo={this.removeVideo}
            startDate={startDate || (post && moment(post.start_date).add(1, "days")) || moment()}
            startDateError={startDate.isAfter() ? "This incentive will not be in newsfeed and search results until start date." : null}
            endDateError={endDate.isBefore() ? "This incentive will not be in newsfeed and search results.": null}
            updateContent={this._updatePostBody}
            audienceSearchInput={audienceSearchInput}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
            user={user}
            currencyMask={currencyMask}
            numberMask={numberMask}
            is_partner_incentive={is_partner_incentive}
          />
          </>
        );

        
      }

    }
    return null;
  }
}

  export default connect(
    state => ({
      browser: state.browser,
      systemSettings: state.resources.detail.systemsetting,
      companySettings: state.resources.detail.companysetting,
      company: state.resources.detail.company,
      loading: state.resources.detail.loading.post,
      post: state.resources.detail.post,
      selectedUsers: state.post.selectedUsers,
      searchCompanies: state.search.searchCompanies,
      productResults: state.search.productSearchResults,
      productGroups: state.search.productGroupSearchResults,
      productCategories: state.search.productCategorySearchResults,
      //companyResults: state.search.companySearchResults || [],
      selectedCompanies: state.post.selectedCompanies,
      selectedDepartments: state.post.selectedDepartments,
      selectedGroups: state.post.selectedGroups,
      user: state.resources.detail.user,
      connections: state.resources.collection.connection || [],
      incentiveHovered: state.incentives.incentiveHovered,
      incentiveSelected: state.incentives.incentiveSelected
    }),
    dispatch => ({
      setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
      setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
      searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
      searchDepartments: bindActionCreators(
        searchConnectionDepartments,
        dispatch
      ),
      searchMobile: bindActionCreators(searchMobile, dispatch),
      searchProducts: bindActionCreators(searchProducts, dispatch),
      searchProductGroups: bindActionCreators(searchProductGroups, dispatch),
      searchProductCategories: bindActionCreators(searchProductCategories, dispatch),
      searchDepartments: bindActionCreators(
        searchConnectionDepartments,
        dispatch
      ),
      searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
      notifySuccess: bindActionCreators(
        success.bind(null, "api_success"),
        dispatch
      ),
      notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
      createIncentive: bindActionCreators(actions.createIncentive, dispatch),
      createReward: bindActionCreators(actions.createReward, dispatch),
      //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
      //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
      fetchPost: bindActionCreators(actions.fetchPost, dispatch),
      fetchIncentive: bindActionCreators(actions.fetchIncentive, dispatch),
      fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
      searchIncentives: bindActionCreators(searchIncentives, dispatch),
      fetchUser: bindActionCreators(actions.fetchUser, dispatch),
      //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
      fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
      notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
      redirect: bindActionCreators(push, dispatch),
      resetForm: bindActionCreators(reset, dispatch),
      updateIncentive: bindActionCreators(actions.patchIncentive, dispatch)
    })
  )(ManageIncentiveMain);






class ManageIncentiveNav extends Component {

  state ={
    modalOpen: false,
    is_partner_incentive:  queryString.parse(window.location.search).type==='partner'
  }

  handleConfirmNavigationClick = ()=>{
    const {setIncentiveSelected} = this.props;
    setIncentiveSelected('');
  }

  
  render(){

    const {incentiveSelected, setIncentiveSelected, match} = this.props;
    const {modalOpen, is_partner_incentive} = this.state;

    const incentiveTypes= {
      increment: {
          navTitle: `ADD AN INCREMENT ${is_partner_incentive ? "PARTNER" : "SALES"} INCENTIVE`
      },
      onetoone: {
        navTitle: `ADD A ONE TO ONE ${is_partner_incentive ? "PARTNER" : "SALES"} INCENTIVE`
      },
      tier: {
        navTitle: `ADD A TIER ${is_partner_incentive ? "PARTNER" : "SALES"} INCENTIVE`
      },
      volume: {
        navTitle: `ADD A VOLUME ${is_partner_incentive ? "PARTNER" : "SALES"} INCENTIVE`
      },
      quickpay: {
        navTitle: `ADD A ${is_partner_incentive ? "PARTNER" : "SALES"} QUICKPAY`
      }
  }

    return (
    <Flex
      height={"100%"}
      width={"100%"}
      alignItems="flex-end"
      justifyContent="center"
    >
      <Flex
        height={["49px", "49px", "60px", "40px"]}
        width={"100%"}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <NunitoSans
          pt={["6px", "6px", "8px", "6px"]}
          fontSize={[14, 14, 18, 18]}
          fontWeight={["bold"]}
        >

          {Object.keys(incentiveTypes).includes(incentiveSelected) ? 
          incentiveTypes[incentiveSelected].navTitle
          :
          `WHAT TYPE OF ${is_partner_incentive ? "PARTNER" : "SALES"} INCENTIVE?`
          }
          
        </NunitoSans>
        {Object.keys(incentiveTypes).includes(incentiveSelected) ? 
          null
          :
          <QuestionModalTrigger modalName='add_incentive'/>
          }
        <AbsoluteDiv
          left={["0px", "0px", "0px", "0px", "-20%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
          >
            {Object.keys(incentiveTypes).includes(incentiveSelected) && !match.params.id ? 
            
            <ClickCursorFlex onClick={()=> {
              // if(window.confirm("Leaving this page will discard unsaved changes. Click OK to leave.")){
              //   setIncentiveSelected('')
              // }

              this.setState({modalOpen:true})
              
            }}>
              <IconBox
              color="#606060"
              mt={["2px"]}
              fontSize={["20px", "20px", "24px"]}
              >
                leftcarret
              </IconBox>
            </ClickCursorFlex>
            :
            <BackButton />
          }
          </Flex>
        </AbsoluteDiv>
      </Flex>
          <ConfirmModal
          modalOpen={modalOpen}
          handleCloseRequest={()=>this.setState({modalOpen: false})}
          handleConfirm={this.handleConfirmNavigationClick}
          message="Leaving this page will discard unsaved changes."
          message2=" Are you sure you want to leave this page?"
        />
    </Flex>
  )
  }
};

ManageIncentiveNav = connect(
  state => ({
    incentiveHovered: state.incentives.incentiveHovered,
    incentiveSelected: state.incentives.incentiveSelected
  }),
  dispatch => ({
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
  //   createGroup: bindActionCreators(actions.createGroup, dispatch),
  //   fetchGroups: bindActionCreators(actions.fetchGroups, dispatch),
  //   notifySuccess: bindActionCreators(
  //     success.bind(null, "api_success"),
  //     dispatch
  //   ),
  //   notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
  //   resetForm: bindActionCreators(reset, dispatch),
  //   updateGroup: bindActionCreators(actions.patchGroup, dispatch)
  })
)(ManageIncentiveNav);

export { ManageIncentiveNav};

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

function calculateSplitReward(total_reward, percent){
  return roundTo2Decimal((total_reward * percent) / 100);
}

function roundTo2Decimal(num){
  return Math.round((num + Number.EPSILON) * 100) / 100;
}