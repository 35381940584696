import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset } from "redux-form";
//utils
import { setUser } from "../../utils/auth";
//actions
import { actions } from "../../actions/resources";
import { error, success } from "../../actions/alerts";
//components
import LoginForm from "./forms/login";
import MobileForm from "./forms/mobile";
// import SystemAlerts from "../../common/systemAlerts";
// import { AbsoluteDiv } from "../../common/absolute";
// import {
//   ErrorUnderbrow
// } from "../../common/forms";
import { SubmissionError } from 'redux-form';  // ES6

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export class AuthLogin extends Component {

  componentDidMount() {
    // localStorage.removeItem("referral_code");
    localStorage.removeItem("LeCompany");
    localStorage.removeItem("LeIdentity");
    localStorage.removeItem("LeEmail");
    localStorage.removeItem("LeDomain");
    localStorage.removeItem("user");
   const urlParams = new URLSearchParams(window.location.search);
   let referralCode = urlParams.get("referral_code") || localStorage.getItem("referral_code") || null;   
   console.log("referralCode",referralCode);
  }
  
  _handleSubmit = async data => {
    const {   
      createSession, 
      redirect, 
      resetForm 
    } = this.props;    

    let LeEmail = data.email;
    LeEmail = LeEmail.toString().trim().toLowerCase();    
    localStorage.setItem("LeEmail",LeEmail);
    data.email = LeEmail;

    let domain = LeEmail.split("@")[1];
    const companyPayload = await fetch(`${process.env.REACT_APP_API_URL}/companias/${domain}`);
    const company = await companyPayload.json();
    // console.log("companyPayload",companyPayload)
    // console.log("company",company)
    // console.log("company.id",company.id)
    localStorage.setItem("LeDomain",domain);

    const response = await createSession(data);

    let deet = new Date().toISOString();
    if(company.id===undefined){
      //if company domain does not exist then send user to sign up
      setUser({"payload":
          {
            "email":localStorage.getItem("LeEmail"),
            "payload": deet, 
            "name": "unknown unknown"
            }
        });
      redirect("/signup");
    } else if(response && response.payload && response.payload.id ==="no_such_user"){
      localStorage.setItem("LeCompany",JSON.stringify(company)); 
      localStorage.setItem("LeIdentity",JSON.stringify({LeEmail:LeEmail,company:JSON.stringify(company),domain:domain}));
      setUser({"payload":
          {
            "email":localStorage.getItem("LeEmail"),
            "payload": deet, 
            "name": "unknown unknown"
            }
        });
      redirect("/signup");
    } else {
      localStorage.setItem("LeCompany",JSON.stringify(company)); 
      localStorage.setItem("LeIdentity",JSON.stringify({LeEmail:LeEmail,company:JSON.stringify(company),domain:domain}));
      // console.log("login attempt",response);
  
      if(response && response.payload.status==='suspended'){
        return this._suspendedError();
      }      
      if(response && !response.payload.company_id){
        return this._throwError2();
      }
      if (response && response.payload.email) {
        setUser(response.payload);
        redirect("/login-verification");
      }  
    }    
  };

  _suspendedError = async data => {    
     throw new SubmissionError({ _error: 'Your account has been suspended. Please contact support.' });
  };

  _throwError = async data => {
    throw new SubmissionError({ _error: 'You may not be in our system yet. Sign up or check your email address and try again.' });
  };

  _throwError2 = async data => {
     throw new SubmissionError({ _error: 'You have an unfinished account. Please go back and complete your registration.' });

  };

  render() {
    const { browser } = this.props
    if (browser.lessThan.medium) {
      return (
        <div>
          <div className="auth-form">
            <MobileForm 
              onSubmit={this._handleSubmit}
              redirect={this.props.redirect}
            />
          </div> 
        </div>
      )
    } else {
        return (
          <div>
            <div className="auth-form">
              <LoginForm 
                onSubmit={this._handleSubmit}
                redirect={this.props.redirect}
              />
            </div>
          </div>
        )
    }
  }
}

export default connect(
  state => ({
    browser: state.browser,
  }),
  dispatch => ({
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createSession: bindActionCreators(actions.createSession, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
  })
)(AuthLogin);
