import Cookies from "universal-cookie";
import _ from "underscore";
import LogRocket from 'logrocket';

const cookie = new Cookies();

const MILISECONDS_IN_A_DAY = 86400000;

export const makeAuthHeader = () => {
  
  if (getUser() && getUser().token) {
    return {
      Authorization: getUser().token
    };
  }
  return null;
};

export const clearLinkedIn = () => cookie.remove("_kp.li");
export const clearUser = () =>
  cookie.remove("_kp.user", { path: "/", domain: process.env.DOMAIN });
export const getLinkedIn = () => cookie.get("_kp.li");
export const getUser = () =>
  cookie.get("_kp.user", { path: "/", domain: process.env.DOMAIN });
export const setLinkedIn = payload =>
  cookie.set("_kp.li", payload, { path: "/", domain: process.env.DOMAIN });
export const setUser = payload =>
  {
    console.log("setUser",payload);
    cookie.set(
      "_kp.user",
      _.pick(payload, [
        "id",
        "company_admin",
        "system_admin",
        "status",
        "avatar",
        "position",
        "departments",
        "company_id",
        "company_owner",
        "email",
        "first_name",
        "last_name",
        "token",
        "sales_id",
        "is_single_user_company",
        "client_session_id"
      ]),
      // 7776000000 miliseconds = 3 months
      { path: "/", domain: process.env.DOMAIN, expires : new Date(Date.now()+(90*MILISECONDS_IN_A_DAY)) }
    );


    LogRocket.init('resiqa/kickpost');
    // LogRocket.init('mvkmxo/kickpost-app');

    // if(payload && (payload.first_name===undefined) ){ payload.first_name = localStorage.getItem("LeEmail"); }
    // if(payload && (payload.last_name===undefined) ){ payload.last_name = localStorage.getItem("LeEmail"); }
    // if(payload && (payload.email===undefined)){ payload.email = localStorage.getItem("LeEmail"); }

      LogRocket.identify(payload.id, {
        name: payload.first_name + " " + payload.last_name ,
        email: localStorage.getItem("LeEmail"),
        subscriptionType: 'pro'
      });
      console.log("LR.v: "+LogRocket.version);
      let deet = new Date().toString();
      console.log(deet);
      if(localStorage.getItem("referral_code")){
        console.log("referral_code: "+localStorage.getItem("referral_code"));
      }
      

  }
  
export const clearAccessory = () =>
  cookie.remove("_kp.accessory", {
    path: "/",
    domain: process.env.DOMAIN
  });
export const getAccessory = () =>
  cookie.get("_kp.accessory", { path: "/", domain: process.env.DOMAIN });
export const setAccessory = payload =>
  cookie.set("_kp.accessory", payload, { path: "/" });

  export const checkVersion = () => {
    let kpVersion = localStorage.getItem("kpVersion");

    if(!kpVersion || kpVersion !==process.env.REACT_APP_VERSION){
      
      localStorage.setItem('kpVersion' ,process.env.REACT_APP_VERSION);
      clearUser();
      localStorage.removeItem('kpPolicy');
    }
  }

  export const forceLogout = () => {
    clearUser();
    localStorage.clear();
  }

  export const shouldInfoModalOpen = (name, currentVersion) => {
    const version = localStorage.getItem(name);

    if(!version || version !==currentVersion){
      localStorage.setItem(name ,currentVersion);
      return true;
    }

    return false;
  }

  export const getDismissedInviteSuggestions = (onlyIds=true)=>{

    let dismissedInvites = localStorage.getItem('dismissedInvites');

    dismissedInvites = dismissedInvites ? JSON.parse(dismissedInvites) : [];

    //remove dismissals after 120 days
    

    dismissedInvites.filter(invite=>{
      const difference = (Date.now()-invite.date)/MILISECONDS_IN_A_DAY;

      if(difference<120){
        return true;
      }

      return false;
    });

    localStorage.setItem('dismissedInvites', JSON.stringify(dismissedInvites));

    return onlyIds ? dismissedInvites.map(invite=>invite.id) : dismissedInvites;

  };

  export const dismissInviteSuggestion = (id)=>{

    let dismissedInvites = getDismissedInviteSuggestions(false);

    dismissedInvites.push({id, date: Date.now()});

    localStorage.setItem('dismissedInvites', JSON.stringify(dismissedInvites));

  }
 
  export const getDismissedConnectionSuggestions = (onlyIds=true)=>{

    let dismissedConnections = localStorage.getItem('dismissedConnections');

    dismissedConnections = dismissedConnections ? JSON.parse(dismissedConnections) : [];

    //remove dismissals after 120 days 

    dismissedConnections.filter(connection=>{
      const difference = (Date.now()-connection.date)/MILISECONDS_IN_A_DAY;

      if(difference<120){
        return true;
      }

      return false;
    });

    localStorage.setItem('dismissedConnections', JSON.stringify(dismissedConnections));

    return onlyIds ? dismissedConnections.map(connection=>connection.id) : dismissedConnections;

  };

  export const dismissConnectionSuggestion = (id)=>{

    let dismissedConnections = getDismissedConnectionSuggestions(false);

    dismissedConnections.push({id, date: Date.now()});

    localStorage.setItem('dismissedConnections', JSON.stringify(dismissedConnections));

  };

  export const getDismissSuggestionDuringSession = ()=>{

    const dismissedSuggestions = sessionStorage.getItem("dismissedSuggestions");

    return dismissedSuggestions ? JSON.parse(dismissedSuggestions) : [];
  }

  export const dismissSuggestionDuringSession = (id)=>{

    let dismissedSuggestions = getDismissSuggestionDuringSession();

    dismissedSuggestions.push(id);

    sessionStorage.setItem('dismissedSuggestions', JSON.stringify(dismissedSuggestions) ); 
  }