/* eslint camelcase: 0 */
const API_URL = process.env.REACT_APP_API_URL;

export const resources = {
  audience: {
    plural: "audiences"
  },
  batch_invite: {
    plural: "batch_invites"
  },
  company: {
    plural: "companies"
  },
  compania: {
    plural: "companias"
  },
  connection: {
    plural: "connections"
  },
  email_address: {
    plural: "email_addresses"
  },
  email_verification: {
    plural: "email_verifications"
  },
  department: {
    plural: "departments"
  },
  group: {
    plural: "groups"
  },
  phone_number: {
    plural: "phone_numbers"
  },
  post: {
    plural: "posts"
  },
  asset: {
    plural: "assets"
  },
  incentive: {
    plural: "incentives"
  },
  rule: {
    plural: "rules"
  },
  session: {
    plural: "sessions"
  },
  adminsession: {
    plural: "adminsessions"
  },
  tag: {
    plural: "tags"
  },
  user: {
    plural: "users"
  },
  notification: {
    plural: "notifications"
  },
  person: {
    plural: "persons"
  },
  website: {
    plural: "websites"
  },
  subdomain: {
    plural: "subdomains"
  },
  sale : {
    plural: "sales"
  },
  distsale : {
    plural: "distsales"
  },
  reward : {
    plural: "rewards"
  },
  salesupload : {
    plural: "salesuploads"
  },
  csvupload : {
    plural: "csvuploads"
  },
  invoice: {
    plural: "invoices"
  },
  systemsetting: {
    plural: "systemsettings"
  },
  companysetting: {
    plural: "companysettings"
  },
  featureflag: {
    plural: "featureflags"
  },
  product: {
    plural: "products"
  },
  partnerlabel: {
    plural: 'partnerlabels'
  },
  notificationcount: {
    plural: 'notificationcounts'
  },
  invitesuggestion: {
    plural: 'invitesuggestions'
  },
  searchinvitesuggestion: {
    plural: 'searchinvitesuggestions'
  },
  connectionsuggestion: {
    plural: 'connectionsuggestions'
  },
  brand: {
    plural: 'brands'
  },
};

Object.keys(resources).forEach(key => {
  let resource = resources[key];
  resource.url = `${API_URL}/${resource.plural}`;
});

export default resources;
