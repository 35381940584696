import React, {Component} from "react";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { CSVLink } from "react-csv";
import moment from "moment";

import { NunitoSans, NunitoSansBold } from "../common/typography";
import { Flex } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";
import LoadingSpinner from "../common/loading";
import { colors } from "../../utils/theme";

export default class CsvDropzoneModal extends Component {
    state = {
      message: "",
      charCount: 300,
      deletedHistoryId: '',
      file: null
    }

    componentDidMount(){

      const {csvName} = this.props;

     const defaultMessage = csvName==='invite' ? "I added you as a user on our KickPost account. Sign in to get started." : "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales.";

     this.setState({
       message: defaultMessage,
       charCount: 300-defaultMessage.length
     });

    }

    _handleDrop = data =>{
      const {handleCsvUpload, requireConfirmation} = this.props;

      let inviteMessage = this.state.message;

      requireConfirmation ? this._captureFile(data) : handleCsvUpload(data, inviteMessage);
    }

    _handleSubmit = () => {
      const {handleCsvUpload} = this.props;
      const { file, message } = this.state;
      handleCsvUpload(file, message);
    }

    _handleHistoryDelete = (id)=>{
      if(id === this.state.deletedHistoryId) return;
      this.setState({deletedHistoryId: id});
      this.props.handleHistoryDelete(id);
    }

    _captureFile = (files) => {
      const { setUploadingState, notifyError } = this.props;
      let file = files[0];
      setUploadingState(true);
      if (typeof file === "undefined") {
        setUploadingState(false);

        return notifyError(
          "This file doesn't work. It must be a .csv file."
        );
      }
      this.setState({file});
      setUploadingState(false);
    }

    _closeModal = () => {
      const { handleCloseRequest, csvName } = this.props;
      const defaultMessage = csvName==='invite' ? "I added you as a user on our KickPost account. Sign in to get started." : "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales.";
      this.setState({file: null, message: defaultMessage, charCount: 300-defaultMessage.length });
      handleCloseRequest();
    }

    render(){
        const {modalOpen, csvName, uploading, csvErrors, uploadHistory, csvHeaders, requireConfirmation} = this.props;
        const {message, charCount, deletedHistoryId, file} = this.state;

        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '40%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)',
              width: "50vw",
              maxHeight: "98vh",
              zIndex: 999998,
              overflow: "auto",
              padding: "0px",
              border: "4px dashed",
              borderColor: '#428BF4',
              borderRadius: "22px",
              backgroundColor: "#FFFFFFCB"
            },
          
            overlay: {
              zIndex: 999997,
              background: "#A3A99E88"
            }
            
          };
        
        const historyViewWidth = csvName === 'sales' ? '25%' : '33%';

        return (
            <Modal
            isOpen={modalOpen}
            onRequestClose={()=>this._closeModal()}
            style={customStyles}
            contentLabel={`${csvName} CSV Modal`}
            ariaHideApp={false}
          >
            
              <Dropzone 
              disableClick
          style={{
            border: "2px dashed !important",
            borderColor: colors.buttonCustom,
            width: "100%",
            height: "90vh",
            background: "transparent"
          }}
          accept=".csv, text/csv,application/vnd.ms-excel,application/csv,text/plain,text/x-csv,text/comma-separated-values,text/x-comma-separated-values,text/tab-separated-values"
          onDrop={uploading ? ()=>{} : this._handleDrop}
        >
          
          <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{width:"100%", height:"100%"}}
          >
            
            {!uploading && csvErrors.length>0 ?
            <>
            <NunitoSans
            fontSize="32px"
            color="#FE4A49"
            >
            ERROR PLEASE REUPLOAD
            </NunitoSans>
            <Flex
            flexDirection="column"
            style={{backgroundColor:"#FFFFFF", marginTop:"25px", marginBottom:"25px", padding:"5px", width:"70%", maxHeight:"80%", overflow:"auto"}}
            >
              <Flex
              style={{width:"100%"}}
              justifyContent="space-between"
              >
                <NunitoSans>
                  ERROR
                </NunitoSans>
                <NunitoSans>
                  LINE
                </NunitoSans>
              </Flex>
              {csvErrors.map((item, i)=>{
                return item.errors.map((error, k)=>{
                  return <Flex
                  style={{width:"100%"}}
                  justifyContent="space-between"
                  key={i+error+k}
                  >
                    <Flex
                    flexDirection="column"
                    >
  
                    <NunitoSans
                    
                    fontSize="10px"
                    >
                      {error}
                    </NunitoSans>
                    </Flex>
                    <Flex
                    flexDirection="column"
                    >
  
                    <NunitoSans
                    fontSize="10px"
                    >
                      {item.line}
                    </NunitoSans>
                    </Flex>
                  </Flex>
                })
              })}
            </Flex>
            </>
            :
            null
            }
  
          {uploading
          ?
          <LoadingSpinner
          textSize="20px"
          spinnerSize="6x"
          message='PROCESSING...' 
          />
          :
          <>
          <NunitoSans
          fontSize="32px"
          color="#6C7168"
          
          >
            DRAG & DROP
          </NunitoSans>
          <NunitoSans
          fontSize="32px"
          color="#6C7168"
          
          >
           {` ${csvName.toUpperCase()} DATA`}
          </NunitoSans>
          
          
            <DashboardSmallCTA
            color={colors.buttonCustom}
            fontSize="15px"
            style={{marginTop: '15px', width:'33%'}}
            >
            <CSVLink
            filename={`${csvName}-template.csv`} 
            data={[]}
            headers={csvHeaders}
            >Download CSV Template</CSVLink>
            </DashboardSmallCTA>

          {
            csvName==='invite' || csvName==="kickpost invite"
            ?
            <>
              <NunitoSansBold
              color='#6C7168'
              style={{marginTop:'15px'}}
              >
              INVITE  MESSAGE
              </NunitoSansBold>

              <textarea
              id="invite_message"
              value={message}
              onChange={e=>this.setState({message:e.target.value, charCount: 300-e.target.value.length})}
              style={{border: '1px solid black', fontFamily:'NunitoSans', fontSize: '18px', padding:'5px 5px', width: '400px', height:'70px'}}
              placeholder='Enter your message...'
              />
              <Flex
              justifyContent="flex-end"
              width="400px"
              >
              
                <NunitoSans
                fontSize='12px'
                // style={{position:'relative', top:'-30px'}}
                color={charCount<0 ? '#FE4A49' : '#979797'}
                >
                {`${charCount} characters remaining`}
                </NunitoSans>
            
              </Flex>
              {
                !uploading && requireConfirmation && file
                ?
                <>
                  <NunitoSans
                  color="#FE4A49"
                  style={{marginTop: '10px', marginBottom: '10px'}}
                  >
                    <i>Please review the invite message before submitting your file for processing.</i>
                  </NunitoSans>
                  <NunitoSans
                  // color="#FE4A49"
                  style={{marginTop: '10px', marginBottom: '10px'}}
                  >
                    {`File Captured: ${file.name}`}
                  </NunitoSans>
                  <DashboardSmallCTA
                  disabled={charCount < 0 || uploading}
                  onClick={()=>this._handleSubmit()}
                  >
                    SUBMIT
                  </DashboardSmallCTA>
                </>
                :
                null
              }
            </>
            :
            null
          }
           
          {uploadHistory.length > 0 ?
           <>
           <NunitoSans
           color="red"
           style={{width:"100%", textAlign:'center', marginTop:'35px', marginBottom:'5px'}}
           >
             RECENT UPLOADS
           </NunitoSans>
  
           <Flex
           style={{width:'90%'}}
           justifyContent="space-between"
           >
             <NunitoSans
             fontSize="12px"
             color='#FE4A49'
             style={{width: historyViewWidth, textAlign:'center', textDecoration:'underline'}}
             >
               DATE
             </NunitoSans>
             <NunitoSans
             fontSize="12px"
             color='#FE4A49'
             style={{width: historyViewWidth, textAlign:'center', textDecoration:'underline'}}
             >
               UPLOADED BY
             </NunitoSans>
             <NunitoSans
             fontSize="12px"
             color='#FE4A49'
             style={{width: historyViewWidth, textAlign:'center', textDecoration:'underline'}}
             >
               # OF RECORDS
             </NunitoSans>
             {
              csvName === 'sales' &&
              <NunitoSans
             fontSize="12px"
             color='#FE4A49'
             style={{width: historyViewWidth, textAlign:'center', textDecoration:'underline'}}
             >
               ACTION
             </NunitoSans>
             }
           </Flex>
           
           {uploadHistory.map((item, index, arr)=><Flex
           style={{width:'90%', borderBottom: index!==arr.length-1 ? '1px dashed gray': "none", paddingBottom:'10px', marginTop:'10px'}}
           justifyContent="space-between"
           key={index+item.user_name}
           >
             <NunitoSans
             fontSize="12px"
             style={{width: historyViewWidth, textAlign:'center'}}
             >
               {moment(item.created_at).format('LLL')}
             </NunitoSans>
             <NunitoSans
             fontSize="12px"
             style={{width: historyViewWidth, textAlign:'center'}}
             >
               {item.user_name}
             </NunitoSans>
             <NunitoSans
             fontSize="12px"
             style={{width: historyViewWidth, textAlign:'center'}}
             >
               {item.records_amount}
             </NunitoSans>
             {
              csvName === 'sales' &&
              <NunitoSans
              fontSize="12px"
              color= {deletedHistoryId === item.id ? '#000000' : '#FE4A49'}
              style={{width: historyViewWidth, textAlign:'center', cursor: deletedHistoryId === item.id ? 'auto' : 'pointer'}}
              onClick={() => this._handleHistoryDelete(item.id)}
              >
                {deletedHistoryId === item.id ? <i>Deleting...</i> : "Delete"}
              </NunitoSans>
             }
           </Flex>
           )}
           
           </>
           :
           null
          }
          </>
          }
  
          
          </Flex>
        </Dropzone>
            
          </Modal>
        );
        




        return null;
    }
}