import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector, getFormValues } from "redux-form";
import { Link } from "../../../common/navigation";
import _ from "underscore";
import ReactTable from "react-table";
import 'react-table/react-table.css';
// import { createNumberMask, createTextMask } from 'redux-form-input-masks';

//utils
import { getUser } from "../../../../utils/auth";
//actions
import { error, success } from "../../../../actions/alerts";
import { mainContentScrollTop } from "../../../../actions/dashboardPresentation";
import { companyTotal, selectRow } from "../../../../actions/post";
import { actions } from "../../../../actions/resources";
//components
// import { DateBubble } from "../../../common/forms";
import { renderDante, renderCondensedField, renderCondensedFieldSelect } from "../../../common/forms";
import { SingleDatePickerWrapper, DashboardCTA } from "../../../common/forms";
import { Eyebrow } from "../../../common/forms";
import { Flex, Box } from "../../../common/flexbox";
import FileAttachments from "../../posts/new/files";
// import VideoAttachments from "./videos";
// import PrivatePost from "./privatepost";
import { MobileNavigationalBubble } from "../../../common/ui";
import { AudienceRow } from "../../../common/rows";
import { AudienceMultiRowGroup } from "../../../common/rows";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faMinusCircle, faCog } from '@fortawesome/free-solid-svg-icons';
import { NunitoSans } from "../../../common/typography";

// import robSchniederImage from "images/rob-schnieder.png";
// import samsungImage from "images/samsung_square.png";
// import { type } from "os";

// const laptopBubbleWidth = 47.3;
const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = "Title cannot be blank.";
  }

  if (values.title&&values.title.length>75) {
    errors.title = "Title is too long.";
  }

  // if (!values.po_number) {
  //   errors.po_number = "PO NUMBER cannot be blank.";
  // }

  return errors;
};

class AddQuickPayMain extends PureComponent {
  state = {
    titleCharCount: 75,
    clicked: false,
    submitted: false,
    selectedAudienceFilters: [],
    searchModeActive: false,
    tableData: []
  };

  componentDidUpdate(prevProps, prevState){
    if(prevProps.selectedRows.length !== this.props.selectedRows.length){
      this.setState({tableData: this.props.selectedRows.map(item => {
        
        return {
          ...item.row,
          ...{
            formName: item.id,
            company_id: item.company_id,
            reward: 0
          }
        }
      })});

     
    }

    if(this.props.errors.errorNotify && this.state.submitted && prevState.tableData.length === this.state.tableData.length){
      this.props.notifyError(this.props.errors.errorNotify)
      this.props.errors.errorNotify = ''
      this.setState({submitted: false})
    }
  }

  componentDidMount(){
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user, tableData } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }
   // fetchConnections({ company_id: getUser().company_id, post: true });
   if(tableData){

     this.setState({tableData: tableData})
    //  tableData.forEach(item=>this.props.change(item.formName+'reward', item.reward))
     let values = {};
      tableData.forEach(item=>values[item.formName+'reward']=item.reward)
      this.props.initialize(values)
   }
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows } = this.props;
    let rows = companyRows.concat(departmentRows, userRows);
    return rows;
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;

    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers
    };
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };



  renderEditable= cellInfo => {
    const {tableData} = this.state;
    
    console.log(cellInfo.original.formName + cellInfo.column.id)
    return (
      

      <Field
      key={cellInfo.original.formName + cellInfo.column.id}
      name={cellInfo.original.formName + cellInfo.column.id}
      component="input"
      placeholder="Add Value"
      // type="number"
      style={{textAlign:"center"}}
      // errors={this.props.errors && this.props.errors[cellInfo.original.formName + cellInfo.column.id]}
      {...this.props.currencyMask(2,true)}
      />
    );
  }

 

  render() {
    const {
      company,
      formValues,
      posting,
      clicked,
      clickToPrivate,
      addVideo,
      browser,
      errors,
      files,
      handleSubmit,
      onDateChange,
      onDrop,
      updateVideoInput,
      postBody,
      removeFile,
      removeVideo,
      updateContent,
      videos,
      startDate,
      endDate,
      adjustScrollTop,
      allConnections,
      connections,
      selectedRows,
      videoUrl,
      user,
      videoAdded,
      initialValues
    } = this.props;

    const {tableData, titleCharCount} = this.state;

    const tableColumns = [{
      Header: 'NAME',
      accessor: 'name'
    },
    {
      Header: 'COMPANY',
      accessor: 'company'
    },
    {
      Header: 'REWARD VALUE',
      accessor: 'reward',
      Cell: this.renderEditable
    }];

    
    return (
      <Flex flexDirection="column">
        <div style={{height: "89vh", overflow: "auto", "paddingLeft": "2px", "paddingRight": "20px", marginTop: "20px"}}>
        <form onSubmit={handleSubmit} id="quickpay-form">
          
          <Field
            key={["QUP"]}
            label="TITLE"
            name="title"
            placeholder="Informative & Concise Incentive Title"
            component={renderCondensedField}
            onChange={e=>this.setState({titleCharCount: 75-e.target.value.length})}
            type="text"
            width="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          />

        <Flex
        justifyContent="flex-end"
        >
         
          <NunitoSans
          fontSize='14px'
          style={{position:'relative', top:'-50px'}}
          color={titleCharCount<0 ? '#FE4A49' : '#979797'}
          >
          {`${titleCharCount} characters remaining`}
          </NunitoSans>
       
        </Flex>
          
          <Field
            key={["QUPerrors"]}
            errors={errors}
            label="BODY"
            name="body"
            placeholder="Add your message and links here"
            component={renderDante}
            content={postBody}
            updateContent={updateContent}
          />
          <FileAttachments
          files={files}
          onDrop={onDrop}
          removeFile={removeFile}
          limit={1}
          />
          <ReactTable
          className="-striped -highlight"
          columns={tableColumns}
          data={tableData}
          sortable={false}
          resizable={true}
          showPagination={tableData.length>20}
          minRows={4}
          noDataText="Use the Audience Filter to the right to add names"
          name="tableData"
          style={{textAlign:"center", fontSize: "12px", fontWeight:"bold", fontFamily: "NunitoSans", color:"#6C7168", width: "100%"}}
          />
          
          <Flex
          justifyContent="space-between"
          mt="20px"
          >
          <Field
            key={["QUPbillcontact"]}
            label="BILLING CONTACT"
            name="billing_contact"
            // placeholder="Contact Name"
            component={renderCondensedFieldSelect}
            // type="text"
            width="45%"
            
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          >
            {company&&company.users&&company.users.filter(user => user.company_admin).map((user, index)=> <option 
        value={`${user.first_name} ${user.last_name}` } 
        key={index}
        
        >
        {`${user.first_name} ${user.last_name}`}
        </option>)}
          </Field>
          <Field
            key={["QUPponumber"]}
            label="PO NUMBER"
            name="po_number"
            placeholder="PO NUMBER"
            component={renderCondensedField}
            type="text"
            width="45%"
          
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          />
          </Flex>
          {/* <FileAttachments
            files={files}
            onDrop={onDrop}
            removeFile={removeFile}
          /> */}
          {/* <VideoAttachments
            addVideo={addVideo}
            updateVideoInput={updateVideoInput}
            removeVideo={removeVideo}
            videos={videos}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
          /> */}
          {browser.lessThan.large ? (
            <Flex mt="16px" pb="16px" flexDirection="column">
              <Eyebrow ml={"8px"}>AUDIENCE</Eyebrow>
              <Flex
                bg="inputBackgroundGray"
                px={"15px"}
                pb={"15px"}
                borderRadius="10px"
                flexDirection="column"
              >
                {selectedRows.length ? (
                  <Box position="relative" mt="0px">
                    <AudienceMultiRowGroup
                      color={"#f7f7f7"}
                      groupName="SEARCH RESULTS"
                      audiencesMulti={selectedRows}
                      selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                      onSelect={this._selectRow}
                      mobile={true}
                      hideHeader={true}
                      hideFirstLastDash={true}
                    />
                  </Box>
                ) : (
                  <Box position="relative">
                    <AudienceRow
                      submit={() => alert("submit")}
                      color={"#f7f7f7"}
                      key={0}
                      active={true}
                      firstRow={true}
                      lastRow={true}
                      hideFirstLastDash={true}
                      mobile={true}
                      removeAction={() => alert("remove")}
                      removeFromSelected={() => alert("remove from selected")}
                      hideAdd={false}
                      name="All Connections"
                      companyNumber={`${
                        allConnections
                          ? companyTotal(connections, user)
                          : companyTotal(connections, user)
                      } Companies`}
                    />
                  </Box>
                )}
                <Link to="/dashboard/posts/new/audience">
                  <MobileNavigationalBubble
                    icon="search"
                    text="Audience Filter"
                  />
                </Link>
              </Flex>
            </Flex>
          ) : null}
          {/* <Flex
            mt={["25px"]}
            justifyContent="space-between"
            flexDirection="row"
            flexWrap={["wrap", "nowrap"]}
          >
            <Box width={["100%", laptopBubbleWidth + "%"]} mb={["38px"]}>
              <DateBubble>
                <SingleDatePickerWrapper
                  date={startDate}
                  error={errors && errors["startDate"]}
                  name="startDate"
                  onDateChange={onDateChange}
                  onFocusChange={() => adjustScrollTop(500)}
                  label="START DATE"
                />
              </DateBubble>
            </Box>
            <Box width={["100%", laptopBubbleWidth + "%"]}>
              <DateBubble>
                <SingleDatePickerWrapper
                  date={endDate}
                  error={errors && errors["endDate"]}
                  name="endDate"
                  onDateChange={onDateChange}
                  onFocusChange={() => adjustScrollTop(500)}
                  label="END DATE"
                />
              </DateBubble>
            </Box>
            </Flex> */}
          
          <Flex
            width="100%"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mb="76px"
            onClick={posting ? ()=>{} : () => {
              //Code below sets table form values into tableData
              let theValues = {...formValues};
              let data = [...tableData];
             
              const keys = ["reward"];

              data.forEach(row => {
                keys.forEach(key=> row[key] = theValues[row.formName+key])
              })

             
              this.props.change("tableData", data);
             

              this.setState({ submitted: true });
            }}
          >
            {
              posting ? 
              <>
        <FontAwesomeIcon
        mb="10px"
        icon={faCog}
        color="#2FD371"
        size='2x'
        spin
        />
        
        <NunitoSans
         
          fontSize="13px"
          fontWeight="bold"
          color="#2FD371"
          textAlign="center"
        >
          {this.props.editMode ? "SAVING" : "POSTING"}
        </NunitoSans>
        </>
              :

//              loading={this.state.submitted && errors.length === 0}
            <DashboardCTA
              loading={JSON.stringify({"loading":(this.state.submitted && errors.length === 0)})}
              mt={["50px", "21px", "21px", "21px"]}
              width={[0.75, 0.75, 0.6, 0.6]}
              ctaText={this.props.editMode ? "SAVE" : "POST"}
              color="#428BF4"
            />
            }
          </Flex>
          

        </form>
        </div>
      </Flex>
    );
  }
}

AddQuickPayMain = reduxForm({
  destroyOnUnmount: true,
  form: "quickpay-form",
  validate,
  enableReinitialize : true
})(AddQuickPayMain);



AddQuickPayMain = connect(
  state => ({
    formValues: getFormValues('quickpay-form')(state),
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    //connections: state.resources.collection.connection,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    browser: state.browser,
    user: state.resources.detail.user
  }),
  dispatch => ({
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch)
  })
)(AddQuickPayMain);

export default AddQuickPayMain;
