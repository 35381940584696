import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { getFormValues } from "redux-form";
import { Flex, Box } from "../../common/flexbox";
import { getUser } from "../../../utils/auth";
import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

// searchConnectionCompanies,
// searchConnectionDepartments,
// searchConnectionUsers, 
// searchUsers,
import {
  searchPosts,
  searchIncentives,
  updatePostSearchResults,
  updateAssetSearchResults,
  toggleCategory
} from "../../../actions/search";
import {
  PostRowGroup,
  PeopleRowGroup,
  CompanyRowGroup,
  IncentiveRowGroup
} from "../../common/rows";

import { DashboardSmallCTA } from "../../common/forms";
// import { DashboardSmallCTA, DashboardSmallToggleCTA } from "../../common/forms";

export class SearchMain extends Component {

  state = {
    vendor_ids: null,
  }

  componentDidMount(){
    this._fetchDependencies();
  }


  _fetchDependencies = async () => {
    this._fetchDependencies();
  }

  componentWillUnmount() {
    //this.props.updatePostSearchResults([]);
  }

  _fetchDependencies = async () => {
    const { fetchUser, fetchConnections, postsCurrentPage, searchPosts, user } = this.props;
    // const user = await fetchUser(getUser().id, {
    //   edit: true
    // });
    // console.log("_fetchDependencies", user)
    // console.log(getUser())
    let connections;
    this._connections(fetchConnections)
      .then(() => {
        console.log("this.props", this.props);
        connections = this.props.connections;
        console.log("connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table

        let Vendor_ids = [];
        if (connections) {
          connections.forEach(reorder);
          // connections.payload.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              Vendor_ids.push(v.vendor_id);
            } else {
              Vendor_ids.push(v.company_id);
            }
          }
          this.setState({ vendor_ids: Vendor_ids });
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }

  _approvePost = async postId => {
    const { updatePost, searchP, vendorIds, user, searchInput, systemSettings, fetchNotificationCount } = this.props;
    const { vendor_ids } = this.state;
    const response = await updatePost(postId,
      {
        approver_info: {
          id: user.id,
          system_admin: user.system_admin
        }
      }).catch(err => console.log(err));
    setTimeout(() => {
      searchP(vendor_ids, user, '', 0, true, systemSettings)
      fetchNotificationCount({ user_id: getUser().id });
    }, 3000);
    // searchP(vendorIds, user, searchInput || " ", 0, "pending");

  }

  _declinePost = async (postId, reason) => {
    const { updatePost, searchP, vendorIds, user, searchInput, systemSettings, fetchNotificationCount } = this.props;
    const { vendor_ids } = this.state;
    await updatePost(postId, { status: "declined", reason: reason }).catch(err => console.log(err));
    setTimeout(() => {
      searchP(vendor_ids, user, '', 0, true, systemSettings);
      fetchNotificationCount({ user_id: getUser().id });
    }, 3000);

  };

  _approveIncentive = async incentiveId => {
    const { updateIncentive, searchI, vendorIds, user, searchInput, systemSettings, fetchNotificationCount } = this.props;
    const { vendor_ids } = this.state;
    const response = await updateIncentive(incentiveId, {
      approver_info: {
        id: user.id,
        system_admin: user.system_admin
      }
    }).catch(err => console.log(err));
    setTimeout(() => {
      searchI(vendor_ids, user, '', 0, {}, true, systemSettings);
      fetchNotificationCount({ user_id: getUser().id });
    }, 3000);
    // searchP(vendorIds, user, searchInput || " ", 0, "pending");

  }

  _declineIncentive = async (incentiveId, reason) => {
    const { updateIncentive, searchI, vendorIds, user, searchInput, systemSettings, fetchNotificationCount } = this.props;
    const { vendor_ids } = this.state;
    await updateIncentive(incentiveId, { status: "declined", reason: reason }).catch(err => console.log(err));
    setTimeout(() => {
      searchI(vendor_ids, user, '', 0, {}, true, systemSettings);
      fetchNotificationCount({ user_id: getUser().id });
    }, 3000);

  };

  _toggleCategory = category => {

    const { toggleCategory } = this.props;

    const filters = [
      "searchCompanies",
      "searchPosts",
      "searchIncentives",
      "searchUsers"
    ];


    filters.forEach(filter => {
      toggleCategory(filter, filter === category);
    })

  };




  render() {
    const {
      browser,
      companies,
      posts,
      incentives,
      redirect,
      searchCompanies,
      searchPosts,
      searchUsers,
      searchIncentives,
      user,
      users,
      systemSettings,
      companySettings,
      formValues,
      notifySuccess
    } = this.props;

    const allSelected = searchCompanies && searchUsers && searchPosts && searchIncentives;

    return (
      (posts) ? (
        <div style={{ height: "78vh", overflowY: "auto", overflowX: "hidden", padding: "25px", marginTop: browser.lessThan.small ? '100px' : '5px' }}>
          <Flex flexDirection="column">

            {(searchPosts && posts) ? (
              <Box mt="14px" mb="11px">
                <PostRowGroup
                  // hideHeader={!posts.length}
                  hideHeader={false}
                  posts={allSelected ? posts.slice(0, 3) : posts}
                  mobile={browser.lessThan.small}
                  hideFirstLastDash={true}
                  redirect={redirect}
                  approvalSwitchOn={formValues && formValues.search_switch}
                  approvePost={this._approvePost}
                  declinePost={this._declinePost}
                  notifySuccess={notifySuccess}
                />

                {
                  allSelected && posts.length > 3
                    ?
                    <DashboardSmallCTA
                      width="100%"
                      mt='20px'
                      fontSize="15px"
                      color="#A3A99E"
                      hoverColor="#2FD371"
                      innerHeight="40px"
                      onClick={() => this._toggleCategory("searchPosts")}
                    >
                      VIEW MORE POSTS
                    </DashboardSmallCTA>
                    :
                    null
                }

                {!posts.length ? <h5 style={{ marginLeft: "15px" }}>NO RESULTS FOUND</h5> : null}
              </Box>
            ) : null}
            {(systemSettings.rewards_active && searchIncentives && incentives && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) ? (
              <Box mt="14px" mb="11px">
                <IncentiveRowGroup
                  // hideHeader={!posts.length}
                  hideHeader={false}
                  posts={allSelected ? incentives.slice(0, 3) : incentives}
                  mobile={browser.lessThan.small}
                  hideFirstLastDash={true}
                  redirect={redirect}
                  approvalSwitchOn={formValues && formValues.search_switch}
                  approveIncentive={this._approveIncentive}
                  declineIncentive={this._declineIncentive}
                  notifySuccess={notifySuccess}
                  key={`IRGv`+incentives.id}
                />
                

                {
                  allSelected && incentives.length > 3
                    ?
                    <DashboardSmallCTA
                      width="100%"
                      mt='20px'
                      fontSize="15px"
                      color="#A3A99E"
                      hoverColor="#2FD371"
                      innerHeight="40px"
                      onClick={() => this._toggleCategory("searchIncentives")}

                    >
                      VIEW MORE INCENTIVES
                    </DashboardSmallCTA>
                    :
                    null
                }

                {!incentives.length ? <h5 style={{ marginLeft: "15px" }}>NO RESULTS FOUND</h5> : null}
              </Box>
            ) : null}
            {searchUsers && user && user.status !== "pending" ? (
              <Box mt="14px" mb="11px">
                <PeopleRowGroup
                  // hideHeader={!users.length}
                  hideHeader={false}
                  people={allSelected ? users.slice(0, 3) : users}
                  mobile={browser.lessThan.small}
                  hideFirstLastDash={true}
                  redirect={redirect}
                />

                {
                  allSelected && users.length > 3
                    ?
                    <DashboardSmallCTA
                      width="100%"
                      mt='20px'
                      fontSize="15px"
                      color="#A3A99E"
                      hoverColor="#2FD371"
                      innerHeight="40px"
                      onClick={() => this._toggleCategory("searchUsers")}
                    >
                      VIEW MORE PEOPLE
                    </DashboardSmallCTA>
                    :
                    null
                }

                {!users.length ? <h5 style={{ marginLeft: "15px" }}>NO RESULTS FOUND</h5> : null}
              </Box>
            ) : null}

            {searchCompanies && user && user.status !== "pending" ? (
              <Box mt="14px" mb="11px">
                <CompanyRowGroup
                  hideHeader={false}
                  companies={allSelected ? companies.slice(0, 3) : companies}
                  mobile={browser.lessThan.small}
                  hideFirstLastDash={true}
                  redirect={redirect}
                />

                {
                  allSelected && companies.length > 3
                    ?
                    <DashboardSmallCTA
                      width="100%"
                      mt='20px'
                      fontSize="15px"
                      color="#A3A99E"
                      hoverColor="#2FD371"
                      innerHeight="40px"
                      onClick={() => this._toggleCategory("searchCompanies")}
                    >
                      VIEW MORE COMPANIES
                    </DashboardSmallCTA>
                    :
                    null
                }



                {!companies.length ? <h5 style={{ marginLeft: "15px" }}>NO RESULTS FOUND</h5> : null}

              </Box>
            ) : null}



          </Flex>
        </div>
      ) : null
    );
  }
}

export default connect(
  state => ({
    formValues: getFormValues('pending-switch')(state),
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    companies: state.search.companySearchResults,
    connections: state.resources.collection.connection || [],
    posts: state.search.postSearchResults || state.search.searchPosts,
    incentives: state.search.incentiveSearchResults || state.search.searchIncentives,
    //posts: state.search.searchPosts,
    postsCurrentPage: state.search.postSearchResultsCurrentPage,
    postsTotalPages: state.search.postSearchResultsTotalPages,
    searchCompanies: state.search.searchCompanies,
    searchPosts: state.search.searchPosts,
    searchUsers: state.search.searchUsers,
    searchIncentives: state.search.searchIncentives,
    user: state.resources.detail.user,
    users: state.search.userSearchResults
  }),
  dispatch => ({
    //searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    updatePostSearchResults: bindActionCreators(
      updatePostSearchResults,
      dispatch
    ),
    updateAssetSearchResults: bindActionCreators(
      updateAssetSearchResults,
      dispatch
    ),
    updatePost: bindActionCreators(actions.patchPost, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch),
    searchP: bindActionCreators(searchPosts, dispatch),
    searchI: bindActionCreators(searchIncentives, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    //searchPosts: bindActionCreators(searchPosts, dispatch),
    redirect: bindActionCreators(push, dispatch),
    toggleCategory: bindActionCreators(toggleCategory, dispatch)
  })
)(SearchMain);
