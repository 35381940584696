import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit, reset } from "redux-form";
import _ from "underscore";
import axios from "axios";
//utils
import { getUser } from "../../../../utils/auth";
//actions
import { actions } from "../../../../actions/resources";
import { error } from "../../../../actions/alerts";
//components
import { Eyebrow } from "../../../common/forms";
import { Box } from "../../../common/flexbox";
import ExistingAttribute from "./forms/existing-attribute";
import Attribute from "./forms/attribute";

export class Attributes extends Component {
  _onSubmit = async data => {
    const {
      systemSettings,
      companySettings,
      actions,
      extraParams,
      createVar,
      fetchVar,
      notifyError,
      refreshVar,
      resetForm,
      resourceId,
      label,
      list
    } = this.props;
    
    if (createVar === "Email_address" && getUser().email === data.body) {
      resetForm(`profile-existing-attribute-${fetchVar}`);
      return notifyError("That is your primary email.");
    }

    

    if(label==='Subdomains'){
      let subdomains = [];
     const response =  await axios.get(`${process.env.REACT_APP_API_URL}/subdomains`).catch(err=>console.log(err));
     subdomains = response.data;
     if(subdomains.includes(data.body.toString().toLowerCase())){
       return notifyError("This domain is already in use.")
     }

    }

    
    
    function checkDuplicates(v, i, a) {
      console.log(v)
      console.log(data)
      if ((v.label.toString().toLowerCase() == data.label.toString().toLowerCase()) && v.status === "active") {
        notifyError("You're already using this label.");
        return v
      }
      if ((v.body.toString().toLowerCase() == data.body.toString().toLowerCase()) && v.status === "active") {
        if (label === "Phone Contacts" ) {
           notifyError("You're already using this number.");
           return v
        }
        if (label === "Websites") {
          notifyError("This website is already linked to this account.");
          return v
        }
        if (label === "Email Addresses") {
          notifyError("This email address has already been added.");
          return v
        }

      }
    }

    let result = list.filter(checkDuplicates)                    
    if(result.length > 0){
      return ;
    }

    let payload = {
      ...data,
      ...extraParams,
      ...{
        user_id: getUser().id
      }
    };

    await actions[`create${createVar}`](payload);
    resetForm(`profile-existing-attribute-${fetchVar}`);
    actions[refreshVar](resourceId, {
      edit: true
    });

  };

  _removeAttribute = async id => {
    const { actions, createVar, refreshVar, resourceId } = this.props;
    await actions[`patch${createVar}`](id, { status: "inactive" });
    actions[refreshVar](resourceId, { edit: true });
  };

  render() {
    const {
      formLabel,
      dispatch,
      fetchVar,
      label,
      labelPlaceholder,
      list,
      maxlengths,
      placeholder
    } = this.props;

    if (list && list.length) {
      let rows = _.filter(list, item => item.status !== "inactive").map(
        (profileAttribute, i) => {
          return (
            <Box mb="34px" key={i}>
              <ExistingAttribute
                formLabel={formLabel}
                label={label}
                form={`profile-existing-attribute-${fetchVar}-${i}`}
                initialValues={profileAttribute}
                clickHandler={() => this._removeAttribute(profileAttribute.id)}
              />
            </Box>
          );
        }
      );

      return (
        <div>
          <Eyebrow ml={["5px", "5px"]}>{label}</Eyebrow>
          {rows}
          <Attribute
            form={`profile-existing-attribute-${fetchVar}`}
            formLabel={formLabel}
            labelPlaceholder={labelPlaceholder}
            maxlengths={maxlengths}
            placeholder={placeholder}
            onSubmit={this._onSubmit}
            phone={fetchVar === "Phone_numbers" ? true : false}
            clickHandler={() =>
              dispatch(submit(`profile-existing-attribute-${fetchVar}`))
            }
          />
        </div>
      );
    }

    return (
      <div>
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
          <Eyebrow ml={["5px", "5px"]}>{label}</Eyebrow>
          
        </div>
        <Attribute
          form={`profile-existing-attribute-${fetchVar}`}
          formLabel={formLabel}
          labelPlaceholder={labelPlaceholder}
          maxlengths={maxlengths}
          placeholder={placeholder}
          onSubmit={this._onSubmit}
          phone={fetchVar === "Phone_numbers" ? true : false}
          clickHandler={() =>
            dispatch(submit(`profile-existing-attribute-${fetchVar}`))
          }
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting
  }),
  dispatch => ({
    dispatch,
    actions: bindActionCreators(actions, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    resetForm: bindActionCreators(reset, dispatch)
  })
)(Attributes);
