export const mapboxgl_accessToken =
  'pk.eyJ1IjoiaW5mb3JtYWwiLCJhIjoiY2pncGZ4M2N6MGd1dzMzbWd4NWRsOXozdiJ9.uXnyulQuZPJkJ1W9G1sl6Q';

export const VENDOR_NOTHING_STATE = 'nothing';
export const VENDOR_ACTIONREQUIRED_STATE = 'actionrequired';
export const VENDOR_REQUESTED_STATE = 'requested';
export const VENDOR_CONNECTED_STATE = 'connected';

export const extraPadding = 30;
export const extraPaddingMobile = 20;
