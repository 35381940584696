import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
import { reset, Field, reduxForm, getFormValues } from "redux-form";
import { push } from "react-router-redux";
import { history } from "../../../store";
// import InfiniteScroll from "react-infinite-scroller";
import Modal from "react-modal";
// import {isEqual} from 'lodash';
import Axios from "axios";
//utils
import { getUser } from "../../../utils/auth";
import { filterActiveDepartments } from "../../../utils/helpers";
//actions
import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";
import { searchEmployees, searchConnectionUsers } from "../../../actions/search";
//components
import { NunitoSans } from "../../common/typography";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { InviteEmployeeCTA, ImageTreatment } from "../../common/ui";
import { DashboardSmallCTA, InputStyled } from "../../common/forms";
import {EmployeeList} from "../../common/reactWindow";
import { BackButton } from "../../common/backbutton";

import QuestionModalTrigger from "../../common/questionModalTrigger";

import InfoModal from "../../common/InfoModal";

import coworkersLogo from "../../../images/coworkers_info_logo.png";
import defaultProfileImage from "../../../images/default_profile.jpg";


let timeout = null;

export class ManageEmployeesMain extends Component {
  state = { 
    search: null,
    subdomains: [],
    coworkers: [],
    employees: [],
    hasMore: true,
    page: 1,
    prevPage: 0,
    selectedRowId: 'hellofromkickpost',
    splitsModalOpen: false,
    splitsPerson: null,
    splitsPersonPercent: 100,
    searchForSplits: null,
    sortByValue: 'newest',
    filterByValue: 'all',
    DMG:"src/components/dashboard/employees/index.js"
   }

  constructor(props) {
    //console.log(props);
    super(props);    
  }

  componentDidMount(){
    this._fetchDependencies();
    this._search = _.debounce(this._search.bind(this), 400);
    try {
      if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
        setTimeout(()=>{
          if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
            document.getElementById("VendorRowGroupId").children[1].style.overflowX = "hidden";
          }}, 4000);
      } else {
        setTimeout(()=>{
          if(document.getElementById("VendorRowGroupId")!==null&&document.getElementById("VendorRowGroupId").children){
            document.getElementById("VendorRowGroupId").children[1].style.overflowX = "hidden";
          }}, 4000);
      }
    } catch(e){/*fail silently */}
  }

  _fetchDependencies = async () => {
    const {
      company,
      fetchCompany,
      fetchDepartments,
      fetchUsers,
      match,
      companySettings
    } = this.props;

    let localuser = getUser()||null;

    if (
      companySettings.is_single_user_company ||
      (localuser && localuser.company_admin === false) ||
      localuser.company_id !== match.params.id
    ) {
      history.push("/dashboard");
    }

    fetchDepartments({ company_id: localuser.company_id });
    console.log("_fetchDependencies this.props", this.props);

    if(true){
      fetchUsers({ company_id: localuser.company_id }).then(users => {      
      console.log("_fetchDependencies users", users);
      console.log("_fetchDependencies this.props", this.props);
      this.setState({coworkers: this.props.employees});
      // this.setState({coworkers: users.payload});
      });

    }

    if (!company) {
      fetchCompany(localuser.company_id, { detail: true });
    }

    let subdomains = await Axios.get(`${process.env.REACT_APP_API_URL}/subdomains/${localuser.company_id}`)
    subdomains = subdomains.data;
    subdomains = subdomains.map(item=>item.body);
    
    this.setState({subdomains: subdomains});

    let query = decodeURI(window.location.href.split("?")[1]);

    if(query !== undefined && query !== "undefined"){
      this._updateSearch(query);
    }

    this._searchForSplits('');
  }
  componentDidUpdate(nextProps) {
    let {DMG}=this.state;DMG+=" componentDidUpdate";
    // console.log(DMG,"nextProps.people",nextProps)
    // console.log(DMG,"nextProps.people",nextProps.people)
    // console.log(DMG, "this.props",this.props);
    // console.log(DMG, "this.props.people",this.props.people)

    if(nextProps.employees.length && this.props.employees.length && nextProps.employees.length > this.props.employees.length  && !this.state.search){
      this.props.fetchUsers({ company_id: getUser().company_id }).then(users => {
        this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
      });
    } else if (nextProps.userResults !== this.props.userResults) {
      //nextProps.userResults
      // return this._fetchUsers(nextProps.userResults);
      this.props.fetchUsers({
        company_id: getUser().company_id,
        ids: this.props.userResults
      }).then(users => {
        this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
      });

/*
      // await this._fetchUsers._fetchUsers(nextProps.userResults);
      // this.setState({coworkers: this.props.employees});
      console.log(DMG,"RESULTS CHANGED")
      let localemployees = this.props.employees;
      let found = [];
      for(let i=0;i<localemployees.length;i++){
        if(nextProps.userResults.includes(localemployees[i].id)){
          found.push(localemployees[i])
        }
      }
      console.log(DMG,found);
      if(found.length!==0){
        this.setState({coworkers: found});
      }
      console.log(DMG,"RESULTS CHANGED")
      */


    } else if (this.props.employees !== nextProps.employees) {
      this.setState({coworkers: this.props.employees});
    }
  }

  _createUser = async data => {
    const { notifyError, company, createUser, fetchUsers, resetForm, notifySuccess } = this.props;
    

    let valuesEmail = data.email;
    let company_domain = company.domain;
    if(data.email){
      data.email = valuesEmail;    
      valuesEmail = valuesEmail.toString().trim().toLowerCase(); 
      company_domain = company_domain.toString().toLowerCase(); 
      if (valuesEmail.split("@")[1] !== company_domain) {
        return;
      }
    }
    if(data.inviter_user_avatar){
      data.inviter_id = getUser().id;
      data.message = "test2";
    } else {
      data.inviter_user_avatar = getUser().avatar;
      data.inviter_id = getUser().id;
      data.message = "test3";
    }

    fetchUsers({ company_id: getUser().company_id }).then(users=> {
     
     let invitedEmployees = users.payload.map(employee => employee.status === 'invited' ? employee.email : "");
     
     if(invitedEmployees.includes(valuesEmail)){
       
      
 notifyError("You have already invited this person, to remind them click the RESEND button below.");

     }else{
      let payload = {
        ...data,
        ...{
          company_id: getUser().company_id,
          status: "invited",
          inviter_id: getUser().id,
          inviter_user_avatar: getUser().avatar,
          inviter_user_position: getUser().position,
          inviter_user_first_name: getUser().first_name,
          inviter_user_last_name: getUser().last_name
        }
      };

      createUser(payload).then(result => {
        notifySuccess(result.payload.message);
        fetchUsers({ company_id: getUser().company_id });
      } );
//      createUser(payload).then(result => notifySuccess("An login email was sent to "+ data.first_name +".") );
      
       
     }
    });

    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });

    resetForm("create-user");
  };

  _fetchUsers = async userResults => {
    const { fetchUsers } = this.props;
    fetchUsers({
      company_id: getUser().company_id,
      ids: userResults
    });
  };

  // _loadMore = pageNo => {
  //   const {fetchUsers} = this.props;
  //   const {coworkers, hasMore, page, prevPage} = this.state;

  //   this.setState({prevPage: page});

    

  //   fetchUsers({ company_id: getUser().company_id, offset: pageNo*15 }).then(users => {
  //     if(users.payload.length === 0){
  //       this.setState({hasMore: false})

  //     }else if(hasMore){
  //       this.setState({coworkers: coworkers.concat(users.payload)});
         

  //       if(users.payload.length < 15){
  //         this.setState({hasMore: false})
  //       }else{
  //         this.setState({page: page + 1})

  //       }
  //     }
      
  //   } )

  // }

  _remindUser = employee=> {
    const { createUser, fetchUsers, notifySuccess } = this.props;
    
    
    let payload = {
      ...employee,
      ...{
        reminder: true,
        inviteMessage: 'Just a reminder to join our KickPost account so you can help us engage with our partner network.',
        company_id: getUser().company_id,
        status: "invited",
        inviter_user_avatar: getUser().avatar,
        inviter_id: getUser().id,
        inviter_user_position: getUser().position,
        inviter_user_first_name: getUser().first_name,
        inviter_user_last_name: getUser().last_name
      }
    };
    createUser(payload)
    .then(result => 
      {
        console.log(result);
        if(true){
          notifySuccess("A reminder was sent to your coworker.");
        } else {

        }
      }
    );

    fetchUsers({ company_id: getUser().company_id });

    // console.log("EMPLOYEE", employee);

  }

  _removeUser = async employee => {
    const { fetchUsers, updateUser, fetchNotificationCount } = this.props;
    await updateUser(employee.id, {
      status: employee.status === "pending" ? "declined" : "deleted"
    });
    
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
    fetchNotificationCount({user_id:getUser().id});
  };

 
  _deleteUser = async employee => {
    const { fetchUsers, deleteUser } = this.props;
    console.log(employee)
    await deleteUser(employee.id, {
      status: employee.status === "pending" ? "declined" : "deleted",
      id: employee.id
    });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
  };

  _acceptUser = async employee => {
    const { fetchUsers, updateUser,fetchNotificationCount } = this.props;
    await updateUser(employee.id, { status: "active" });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
    fetchNotificationCount({user_id:getUser().id});
  };

  _makeSystemAdmin = async employee => {
    const { fetchUsers, updateUser } = this.props;
    await updateUser(employee.id, { system_admin: true });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
  };

  _removeSystemAdmin = async employee => {
    const { fetchUsers, updateUser } = this.props;
    await updateUser(employee.id, { system_admin: false });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
  };

  _makeAdmin = async employee => {
    const { fetchUsers, updateUser } = this.props;

    await updateUser(employee.id, { company_admin: true });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
  };

  _removeAdmin = async employee => {
    const { fetchUsers, updateUser } = this.props;
    await updateUser(employee.id, { company_admin: false, system_admin: false});
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
    });
  };

  _makeSalesRep = async employee => {
    const { fetchUsers, updateUser } = this.props;

    await updateUser(employee.id, { sales_rep: true, sales_id: employee.email });
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees});
    });
  };

  _removeSalesRep = async employee => {
    const { fetchUsers, updateUser } = this.props;
    await updateUser(employee.id, { sales_rep: false, sales_id: null});
    fetchUsers({ company_id: getUser().company_id }).then(users => {
      this.setState({coworkers: this.props.employees})
    });
  };


  _clearSearch = () => {
    //const { company } = this.props;

    
    this.setState({ search:"", filterByValue: 'all'});

   // this.setState({ search:"", searchInput: "", input: "",company:company});
    this._search("");
  };

  _search = async input => {
    const { fetchUsers, searchEmployees } = this.props;
    let {DMG}=this.state;DMG+="_search"
    // console.log(DMG,input)
    // console.log(DMG,"this.props",this.props)
    // console.log(DMG,"getUser().company_id, input",getUser().company_id, input)
    if (input.length === 0) {
      await fetchUsers({ company_id: getUser().company_id })
      .then(users => {
        this.setState({coworkers: this.props.employees});
    // this.setState({coworkers: users.payload});
      });
    } else {
      // console.log(DMG,"input",input)
      await searchEmployees(getUser().company_id, input);
    }
  };

  _updateSearch = input => {
    this.setState({ search: input });
    clearTimeout(timeout);
    timeout = setTimeout(()=>this._search(input), 1000)
  };

  _searchForSplits = async input => {
    const {
      searchCompanies,
      searchDepartments,
      searchGroups,
      searchConnectionUsers,
      user
    } = this.props;
   
    searchConnectionUsers(input, null, true, false, [], true);
  };

  _clearSearchForSplits = ()=> {
    this.setState({searchForSplits: ''});
    this._searchForSplits('');
  }

  _updateSearchForSplits = input => {
    this.setState({searchForSplits: input})
    this._searchForSplits(input);
  }


  validate = values => {
    const { company } = this.props;
    const { subdomains } = this.state;
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    if (!values.message || !values.message.trim()) {
      errors.message = "Message required";
    }else if(values.message.trim().length>300){

      errors.message = "Message cannot be more than 300 characters.";

    }

    

    
    let valuesEmail = values.email;
    let company_domains =  subdomains;
    if(!company_domains.includes(company.domain)){
      company_domains.push(company.domain)
      this.setState({subdomains: company_domains})
    }
    
    
    if(values.email){
      valuesEmail = valuesEmail.toString().toLowerCase(); 
      // company_domain = company_domain.toString().toLowerCase(); 
      if (!company_domains.includes(valuesEmail.split("@")[1])) {
        errors.email = `Email domain must match ${company_domains.join(', ')}`;
      }

    }


    return errors;
  };

  _selectRowId = id => this.setState({selectedRowId: id});

  _openSplitsModal = async employee => {
    // console.log(employee, "fromModal");
    const splitsPercent = 100 - (Object.values(employee.splits || {}).reduce((a,c)=>parseFloat(a)+parseFloat(c), 0));
    this.props.initialize(employee.splits || {});
    this.setState({splitsPerson: employee, splitsModalOpen: true, splitsPersonPercent: splitsPercent})

  }

  _saveSplits = async ()=>{
    const { fetchUsers, updateUser, formValues, reset, notifyError, userResults } = this.props;
    const { splitsPerson, search } = this.state;

    const percentTotal = Object.values(formValues).reduce((a,c)=>parseInt(a)+parseInt(c), 0);
   
    

    if(formValues && percentTotal<=100){
     
    await updateUser(splitsPerson.id, { splits: formValues });
    if(search && search.length){
      await this._fetchUsers(userResults)
    }else{
      await fetchUsers({ company_id: getUser().company_id}).then(res=>this.setState({coworkers: res.payload}));
    }

    }

    if(percentTotal>100){
      notifyError("Total percentages cannot be larger than 100. Please fix and try again.");
    }

    

    this.setState({splitsModalOpen: false, splitsPerson: null});
    reset('splits-form');
  }

 

  _filterBy = (employee, index) => {
    const {filterByValue} = this.state;

    if(filterByValue==='all'){
      return true;
    }else{
      return employee.status===filterByValue;
    }

  }

  _sortBy = (a, b) =>{
    const {sortByValue} = this.state;
    if(sortByValue==='newest') return Date.parse(b.created_at) - Date.parse(a.created_at);
    if(sortByValue==='oldest') return Date.parse(a.created_at) - Date.parse(b.created_at);
   
    if(sortByValue==='name') return (this._fullName(a)<this._fullName(b) ? -1 : this._fullName(a) > this._fullName(b) ? 1 : 0);
    if(sortByValue==='reverse-name') return (this._fullName(a)>this._fullName(b) ? -1 : this._fullName(a) < this._fullName(b) ? 1 : 0);
  }

  _fullName = person => person.first_name.trim()+' '+person.last_name.trim();


  render() {
    const {
      browser,
      company,
      departments,
      fetchUsers,
      redirect,
      searchEmployees,
      updateUser,
      delete2User,
      employees,
      userResults,
      systemSettings,
      companySettings,
      userRows,
      formValues,
      notificationCounts
    } = this.props;
    const { search, searchForSplits, coworkers, hasMore, page, prevPage, selectedRowId, splitsModalOpen, splitsPerson, splitsPersonPercent, sortByValue, filterByValue } = this.state;
    
    employees.forEach(employee => employee.status==='active' ? employee.link = `/dashboard/profile/${employee.id}?coworkers` : null);
    
    coworkers.forEach(employee => employee.status==='active' ? employee.link = `/dashboard/profile/${employee.id}?coworkers` : null);
    let {DMG}=this.state;DMG+=" render"
    // console.log(DMG,employees, coworkers);

    let localuser = getUser()||null;
    // console.log(DMG,localuser);

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: "400px",
        height: "80vh",
        zIndex: 999998,
        overflow: "auto"
      },
  
      overlay: {
        zIndex: 999997,
        
        background: "#A3A99E88"
      }
      
    };

    if (employees && departments && localuser && userResults) {
      return (
        
        <Flex flexDirection="column">
          <Modal
                   isOpen={splitsModalOpen}
                   onRequestClose={()=>{
                     this.setState({splitsModalOpen: false, splitsPerson: null});
                     this.props.reset('splits-form')
                    }}
                   style={customStyles}
                   contentLabel="Splits Modal"
                   ariaHideApp={false}
                  >
                    <Flex
                    flexDirection="column"
                    style={{width:"100%", height:'100%'}}
                    >
                    <Flex
                    position="relative"
                    justifyContent="center"
                    // alignItems="center"
                    style={{marginBottom:'25px'}}
                    >
                      <AbsoluteDiv
                      display={["block"]}
                      left={["0px"]}
                      bottom={"0px"}
                      top={["0px"]}
                      width={["100px"]}
                      >
                        <DashboardSmallCTA
                        fontSize="12px"
                        onClick={()=>this._saveSplits()}
                        >
                          SAVE
                        </DashboardSmallCTA>
                      </AbsoluteDiv>
                      <NunitoSans
                      fontSize="16px"
                      fontWeight="bold"
                      color="#10213A"
                      >
                      SET SPLITS
                      </NunitoSans>
                      <AbsoluteDiv
                      display={["block"]}
                      right={["20px"]}
                      bottom={"0px"}
                      top={["0px"]}
                      width={["17px"]}
                      >
                        <ClickCursorFlex
                          height={["100%", "100%", "100%"]}
                          alignItems={["center"]}
                          onClick={()=>{
                            this.setState({splitsModalOpen: false, splitsPerson: null});
                            this.props.reset('splits-form')
                           }}
                          justifyContent={[
                            "flex-start",
                            "flex-start",
                            "flex-start",
                            "center"
                          ]}
                        >
                          <IconBox
                            cursor="pointer"
                            pl={["15px"]}
                            pb={["15px"]}
                            pt={["15px"]}
                            fontSize={["20px"]}
                            color="#c3c3c3"
                          >
                            thinx
                          </IconBox>
                          </ClickCursorFlex>
                      </AbsoluteDiv>
                    </Flex>

                    {
                        splitsPerson
                        ?
                        <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        mb="20px"
                        >
                          <ImageTreatment
                              img={splitsPerson.avatar || defaultProfileImage}
                              blurRadius={"0px"}
                              imageInset={0}
                              borderRadius={52}
                              innerBorderRadius={50}
                              shadowOffset={0}
                              padding={4}
                              height={75}
                              width={75}
                          />
                          <NunitoSans
                          fontSize="22px"
                          fontWeight="bold"
                          color="#000000"
                          >
                            {splitsPerson.first_name+" "+splitsPerson.last_name}
                          </NunitoSans>
                          <NunitoSans
                          fontSize="22px"
                          fontWeight="bold"
                          color="#000000"
                          >
                            {splitsPersonPercent+" %"}
                          </NunitoSans>

                        </Flex>
                        :
                        null
                      }
                    <Flex 
                    position="relative" 
                    width={["100%"]} 
                    style={{marginBottom:'20px'}}
                    >
                      
              <InputStyled
                placeholder="Search Coworkers"
                onChange={e => this._updateSearchForSplits(e.target.value)}
                value={searchForSplits || "" }
                innerRef={input => (this.searchInput = input)}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                pt={["3px"]}
                px={["5px"]}
                mt={["0px"]}
                height={["49px"]}
                fontWeight={400}
                fontSize={[24]}
                lineHeight={1.5}
                color={"#979797"}
                borderBottom={[0]}
              />
              <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={this._clearSearchForSplits}
                    justifyContent={[
                      "flex-start",
                      "flex-start",
                      "flex-start",
                      "center"
                    ]}
                  >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>

              
                    </Flex>
                    <Flex
                    flexDirection="column"
                    style={{overflow:"auto"}}            
                    >
                      <form id="splits-form">
                      {
                        userRows&&splitsPerson&&userRows.filter(user=>splitsPerson.id!==user.id).sort((a,b)=>parseFloat(formValues[b.id]||0) - parseFloat(formValues[a.id]||0)).map((user, index)=>(
                          <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          className="settingsCompanyResults"
                          style={{paddingBottom:"10px", paddingTop: '10px', borderBottom: index+1===userRows.length ? 'none': '1px dashed #D3D3D3'}}
                          >
                            <Flex
                            alignItems="center"
                            >
                              <ImageTreatment
                              img={user.row.image || defaultProfileImage}
                              blurRadius={"0px"}
                              imageInset={0}
                              borderRadius={4}
                              innerBorderRadius={0}
                              shadowOffset={0}
                              padding={4}
                              height={36}
                              width={36}
                              />

                              <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                              style={{marginLeft:"20px"}}
                              >
                                <NunitoSans
                                fontSize="14px"
                                fontWeight={300}
                                color="darkText"
                                pt={"3px"}
                                lineHeight={1}
                                >
                                  {user.row.name}
                                </NunitoSans>
                                <NunitoSans
                                fontSize="8px" 
                                fontWeight={500} 
                                color="darkText"
                                >
                                  {user.row.company}
                                </NunitoSans>
                              </Flex>

                            </Flex>
                            {
                              splitsPerson && splitsPerson.sales_id && (splitsPersonPercent>0 || (formValues[user.id] && parseFloat(formValues[user.id])>0))
                              ?
                              
                              <Field
                              id={user.id}
                              name={user.id}
                              component="select"
                              onChange={(event, newValue, previousValue, name)=>this.setState({splitsPersonPercent: splitsPersonPercent - (parseFloat(newValue)-parseFloat(previousValue || 0))})}
                              style={{border: '1px solid', borderColor: '#428BF4', borderRadius: '8px', width: "60px", height:"25px", fontFamily:"NunitoSans", fontSize:"12px", marginRight: '10px'}}
                              >
                                {Array(((parseInt(formValues[user.id] || 0) + splitsPersonPercent)/5)+1).fill('1').map((a,index)=>(
                                  <option key={["PERC" + `${Math.random().toString()}`]} value={index*5} > {(index*5)+"%"} </option>
                                ))}
                              </Field>
                             
                              :
                              null
                            }
                          </Flex>
                          ))

                      }
                      </form>
                    </Flex>
                    </Flex>
          </Modal>
          <Flex
            // mt="1px"
            mb="15px"
            mx={["0px"]}
            // height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <AbsoluteDiv
                display={["block"]}
                // left={["10px"]}
                // bottom={"0px"}
                top={["6px", "6px"]}
                width={["40px"]}
              >
                  <IconBox
                    // pl={["15px"]}
                    // pb={["15px"]}
                    // pt={["15px"]}
                    fontSize={["18px", "18px"]}
                    color="#c3c3c3"
                  >
                    search
                  </IconBox>
              
              </AbsoluteDiv>
              <InputStyled
                placeholder="Search Team"
                onChange={e => this._updateSearch(e.target.value)}
                type="text"
                innerRef={input => (this.searchInput = input)}
                style={{paddingLeft:'45px'}}
                value={search || ""} //added the empty string to fix 'value' prop on 'input' should not be null.
                width="100%"
                name="dashboard-search"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                // pt={["3px"]}
                px={["5px", "5px"]}
                mt={["3px", "0px"]}
                height={[31, 40, 40, 40]}
                // fontWeight={400}
                fontSize={[14, 20, 20, 20]}
                lineHeight={1.5}
                color={"#000000"}
                borderBottom={[0]}
              />
              {
                search && search.length
                ?
                <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                <ClickCursorFlex
                  height={["100%", "100%", "100%"]}
                  alignItems={["center"]}
                  onClick={() => {
                    this._clearSearch();
                  }}
                  justifyContent={["center"]}
                >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
                :
                null
              }
            </Flex>
          </Flex>          
          {browser.lessThan.large ? (
            <div>
            <Flex pb="40px" flexDirection="column">
              <InviteEmployeeCTA
                onSubmit={this._createUser}
                validate={this.validate}
                initialValues={{message: "I added you as a user on our KickPost account. Sign in to get started."}}
              />
            </Flex>
            </div>
          ) : null}

        {/* <Flex
        justifyContent={browser.lessThan.large ? "center" : "flex-start"}
        mb="15px"
        >
          <Box
          mr="26px"
          onClick={() => this.setState({filterByValue:'active'})}
          >
            <SearchFilterFlag
            mobile={browser.lessThan.large}
            active={filterByValue==='active' || filterByValue==='all'}
            >
                    

             ACTIVE
                
            </SearchFilterFlag>
          </Box>

          <Box
          mr="26px"
          onClick={() => this.setState({filterByValue:'invited'})}
          >
            <SearchFilterFlag
            mobile={browser.lessThan.large}
            active={filterByValue==='invited' || filterByValue==='all'}
            >
                    

             INVITED
                
            </SearchFilterFlag>
          </Box>

          <Box
          mr="26px"
          onClick={() => this.setState({filterByValue:'pending'})}
          style={{position:'relative'}}
          >
            <SearchFilterFlag
            mobile={browser.lessThan.large}
            active={filterByValue==='pending' || filterByValue==='all'}
            >
                    

             PENDING
                
            </SearchFilterFlag>

            {
              notificationCounts.coworkers
              ?
              <NavNotificationBubble
               top="5px"
               right="0px"
              >
                {notificationCounts.coworkers}
              </NavNotificationBubble>
              :
              null
            }
          </Box>


            <Flex
            alignItems="center"
            style={{marginLeft: 'auto'}}
            >
            <NunitoSans
              fontSize='14px'
              fontWeight='bold'
              >
                Sort By:
              </NunitoSans>
              
              
              <select
              id='sortBy'
              onChange={(e)=>this.setState({sortByValue: e.target.value})}
              value={sortByValue}
              style={{border: '1px solid #707070', marginLeft: '5px'}}
              >
                <option key={1} value='newest'> Newest </option>
                <option key={2} value='oldest'> Oldest </option>
                <option key={3} value='name'> A-Z </option>
                <option key={4} value='reverse-name'> Z-A </option>
              </select>
            </Flex>
          </Flex> */}

        <Flex
          alignItems="center"
          style={{marginTop: '15px', marginBottom: "15px"}}
        
        >
          {/* <Flex flexWrap="wrap" >
            <Flex 
            // style={{paddingRight:'15px', borderRight: '1px solid #A3A99E'}}
            >
              <DashboardSmallToggleCTA
              selected={filterByValue==='all'}
              onClick={() => this.setState({filterByValue:'all'})}
              >
                ALL
              </DashboardSmallToggleCTA>
              <div style={{marginLeft: '15px' ,borderRight: '1px solid #A3A99E', maxHeight: '32px'}} />
            </Flex>

            <DashboardSmallToggleCTA
            style={{marginLeft:'15px', marginRight: '15px', marginBottom: '5px'}}
            selected={filterByValue==='active'}
            onClick={() => this.setState({filterByValue:'active'})}
            >
              ACTIVE
            </DashboardSmallToggleCTA>

            <DashboardSmallToggleCTA
            style={{marginRight: '15px', marginBottom: '5px'}}
            selected={filterByValue==='invited'}
            onClick={() => this.setState({filterByValue:'invited'})}
            >
              INVITED
            </DashboardSmallToggleCTA>

            <DashboardSmallToggleCTA
            style={{marginRight: '15px', marginBottom: '5px'}}
            selected={filterByValue==='pending'}
            onClick={() => this.setState({filterByValue:'pending'})}
            >
              PENDING
            </DashboardSmallToggleCTA>
            
          </Flex> */}

              
        </Flex>


          <EmployeeList 
                    id="flexemployee"
                    hideFirstLastDash={true}
                    employees={search ? employees.filter(this._filterBy).sort(this._sortBy) : coworkers.filter(this._filterBy).sort(this._sortBy)}
                    departments={filterActiveDepartments(departments)}
                    company={company}
                    mobile={browser.lessThan.small}
                    fetchUsers={fetchUsers}
                    delete2User={delete2User}
                    updateUser={updateUser}
                    resendClickHandler={this._remindUser}
                    makeAdminClickHandler={this._makeAdmin}
                    removeAdminClickHandler={this._removeAdmin}
                    makeSalesRep={this._makeSalesRep}
                    removeSalesRep={this._removeSalesRep}
                    makeSystemAdminClickHandler={this._makeSystemAdmin}
                    removeSystemAdminClickHandler={this._removeSystemAdmin}
                    removeClickHandler={this._removeUser}
                    deleteClickHandler={this._deleteUser}
                    acceptClickHandler={this._acceptUser}
                    selectedRowId={selectedRowId}
                    selectRowId={this._selectRowId}
                    search={search}
                    searchEmployees={searchEmployees}
                    user={localuser}
                    systemSettings={systemSettings}
                    companySettings={companySettings}
                    openSplitsModal={this._openSplitsModal}
                    />

          
          {/* {hasMore && !search && coworkers.length > 0 ? 
          <Flex width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
          >
            <Box>
          
          <DashboardSmallCTA
                onClick={() => this._loadMore(page)}
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px","400px"]}
              >
                LOAD MORE
          </DashboardSmallCTA>
          </Box>
          </Flex>
          
          : 
            null} */}


          
         
          {/* {browser.lessThan.large ? (
            <Flex
              mt="100px"
              mb="54px"
              flexDirection={["column", "row"]}
              minHeight="85px"
              width="100%"
              justifyContent="spaceBetween"
              alignItems="center"
              borderRadius="10px"
              bg="lightGray"
            >
              <DepartmentLogoImage
                mt="8px"
                ml={"8px"}
                height="71px"
                width="70px"
                display={["none", "block"]}
              />
              <Box flex={"2 1 auto"} ml={[0, "10px"]} mt={["24px", "2px"]}>
                <NunitoSans fontWeight={300} fontSize="24px">
                  {company.departments_total}
                  Departments
                </NunitoSans>
              </Box>
              <DashboardSmallCTA
                onClick={() =>
                  redirect(
                    `/dashboard/company/${getUser().company_id}/departments`
                  )
                }
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px"]}
              >
                MANAGE
              </DashboardSmallCTA>
            </Flex>
          ) : null} */}
         
        </Flex>
        
      );
    }
    return null;
  }
}

ManageEmployeesMain = reduxForm({
  form: "splits-form", 
  
})(ManageEmployeesMain);

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    formValues: getFormValues('splits-form')(state),
    browser: state.browser,
    company: state.resources.detail.company || [],
    employees: state.resources.collection.user || [],
    //searchedPeople: state.search.personSearchResults || [],
    departments: state.resources.collection.department || [],
    userResults: state.search.employeeSearchResults || [],
    userRows: state.search.userSearchRows,
    user: state.resources.detail.user || [],
    notificationCounts: state.resources.collection.notificationcount || {}
  }),
  dispatch => ({
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    //searchPeople: bindActionCreators(searchPeople, dispatch),
    searchEmployees: bindActionCreators(searchEmployees, dispatch),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    deleteUser: bindActionCreators(actions.deleteUser, dispatch),
    delete2User: bindActionCreators(actions.delete2User, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    redirect: bindActionCreators(push, dispatch)
  })
)(ManageEmployeesMain);

export const ManageEmployeesNav = () => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
     <InfoModal
      modalName='coworkers_modal'  //no spaces. Once set right do not change
      modalVersion="1.00" //set it to 1.00 initially then change it when needed
      image={coworkersLogo}
      title='Manage Your Team'
      text='Invite, manage, and organize your team. Create Departments to share information quickly with segments of your team. Managers can add new company domains under My Company.'
      extraTexts={["For more help, or a CSV walkthrough, watch our tutorial video:"]}
      videoLink='https://youtu.be/A164KokzzGU'
      />
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
       TEAM
      </NunitoSans>
      <QuestionModalTrigger modalName='coworkers_modal'/>
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);
