import React from "react";
import { Box, Flex } from "../../common/flexbox";
import {
  BlurWrapper,
  UnderlayShadow
} from "../../common/ui";
import { LinkedIn } from "react-linkedin-login-oauth2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

export const LinkedinSignin = ({ ...props }) => (
  <Flex
    className="linkedin-cta"
    position="relative"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <BlurWrapper
      className="blur-wrapper"
      width="100%"
      height="100%"
      blur="8px"
      top="5px"
      left="0px"
      opacity={0.2}
    >
      <UnderlayShadow
        borderRadius="3px"
        zIndex="1"
        width="100%"
        height="100%"
      />
    </BlurWrapper>
    {/*/<LinkedInImageButton
      className="linkedin-image"
      position="relative"
      borderRadius="3px"
      width="215px"
      height="40px"
      zIndex="2"
      onClick={props.clickHandler}
    />*/}
  </Flex>
);

export const LinkedInSignInBubble = ({ ...props }) => (
  <Flex
    flexDirection="row"
    height="85px"
    width="100%"
    justifyContent="spaceBetween"
    alignItems="center"
    borderRadius="10px"
    bg="transparent"
  >
  
  <LinkedIn
    clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
    redirectUri={`${window.location.origin}/dashboard/linkedin`}
    scope="r_basicprofile r_emailaddress w_member_social"
    state="20190501"
    className="kp-linkedin-btn"
    onFailure={props.handleFailure}
    onSuccess={props.handleSuccess}
    {...props}
  >
    <FontAwesomeIcon
    icon={faLinkedin}
    size="lg"
    style={{
      marginRight: "10px"
    }}
    />
    Complete Profile with LinkedIn
  </LinkedIn>
    {/* <LinkedInImageButton
      className="linkedin-image"
      position="relative"
      borderRadius="3px"
      width="230px"
      height="40px"
      zIndex="2"
      pl="20px"
    >
      <LinkedinSDK
        clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
        callBack={props.clickHandler}
        fields={`:(${LinkedInBasicProfileFields.toString()})`}
        loginButtonText={"Sync with Linkedin"}
        textButton={"Sync with Linkedin"}
        buttonType={"button"}
        getOAuthToken
      />
    </LinkedInImageButton> */}
    <Box flex="2 1 auto" />
    {/* {props.active ? (
      <Box mr={["10px", "22px"]}>
        <AccessoryIndicator
          size={30}
          color="#0077B5"
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Box>
    ) : (
      <Box mr={["10px", "22px"]}>
        <AccessoryIndicator
          size={30}
          color="#c3c3c3"
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Box>
    )} */}
  </Flex>
);