import React, {PureComponent, Component} from 'react';
// import {  shouldComponentUpdate  } from 'react-window';
import { FixedSizeList as List, shouldComponentUpdate  } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';
// import 'react-virtualized/styles.css'; // only needs to be imported once


import { EmployeeRow, VendorRow, RowGroupHeader, CompanyCheckmarkableRow, DepartmentCheckmarkableRow, GroupCheckmarkableRow, PersonCheckmarkableRow, ConnectedCompanyRow, MappedNameRow} from "../common/rows";
import { Flex } from "../common/flexbox";
// import { IconBox, Box, Flex, ClickCursorFlex } from "../common/flexbox";

const Row = ({ index, style }) => (
  <div className={index % 2 ? "ListItemOdd" : "ListItemEven"} style={style}>
    Row {index}
  </div>
);

export class ConnectedCompanyList extends PureComponent {
  HEIGHT = 400;
  render() {
    // Pass items array to the item renderer component as itemData:
    return (
        <List
          height={this.props.currentHeight || this.HEIGHT}
          width="100%"
          itemSize={50}
          itemCount={this.props.companies.length}
          itemKey = {itemKey}
          itemData={[...this.props.companies, this.props]}        
      >
        {ConnectedCompanyRenderer}
      </List>
    );
  }
};

export class MappedNameList extends PureComponent {
  HEIGHT = 400;
  render() {
    // Pass items array to the item renderer component as itemData:
    return (
        <List
          height={this.props.currentHeight || this.HEIGHT}
          width="100%"
          itemSize={50}
          itemCount={this.props.mappings.length}
          itemKey = {itemKey}
          itemData={[...this.props.mappings, this.props]}        
      >
        {MappedNameRenderer}
      </List>
    );
  }
};

export  class EmployeeList extends PureComponent {
    HEIGHT = 700;
    render() {
      // Pass items array to the item renderer component as itemData:
      console.log(this.props.employees[2], "ComponentThatRendersAListOfItems", "XYZ")
      let height="100%";
      let width=920;
      return (
          <List
            height={this.props.currentHeight || this.HEIGHT}
            width="100%"
            itemSize={68}
            itemCount={this.props.employees.length}
            itemKey = {itemKey}
            itemData={[...this.props.employees, this.props]}        
        >
           {EmployeeRenderer}
           </List>
      );
    }
};

export  class VendorList extends PureComponent {
  HEIGHT = 700;
  
  render() {
    // Pass items array to the item renderer component as itemData:

    let vendors = [];

    this.props.vendors && this.props.vendors.forEach(vendor=>{
      if(vendor && vendor.connectionStatus && vendor.connectionStatus==="actionRequired"){
        vendors.unshift(vendor);
      }else{
        vendors.push(vendor);
      }
    })
    
    return (
      <List
      height={this.props.currentHeight || this.HEIGHT}
      width="100%"
      itemSize={70}
      itemCount={vendors.length}
      itemKey = {itemKey}
      itemData={[...vendors, this.props]}      
      >
        {VendorRenderer}
      </List>
    );
  }
};

export  class AudienceList extends PureComponent {
  HEIGHT = this.props.height || 500;
  
  render() {
    // Pass items array to the item renderer component as itemData:
    
    return (
    <Flex flexDirection="column" > 
    {this.props.hideHeader ? null : (
      <RowGroupHeader
        groupName={this.props.groupName ? this.props.groupName : "TAGS"}
        fontSize={this.props.headerFontSize}
        justifyContent={this.props.headerjustify} 
      />
    )}
      <Flex flexDirection="column" >
            <List
            height={this.HEIGHT}
            itemSize={68}
            itemCount={this.props.audiencesMulti.length}
            itemKey = {itemKey}
            itemData={[...this.props.audiencesMulti, this.props]}          
            >
              {AudienceRenderer}
            </List>
      </Flex>
    </Flex>
    );
  }
};


class AudienceRenderer extends Component {
  shouldComponentUpdate = shouldComponentUpdate.bind(this);

  render() {
      
    // Access the items array using the "data" prop:
    const audience = this.props.data[this.props.index];
  //   console.log(employee)
   const PROPS = this.props.data[this.props.data.length-1];
   
   let type = audience.type;
   let row = audience.row;
   
    return (
      <div style={this.props.style}>
        {
          type === "company" && !PROPS.onlyPeople
          ?
          <CompanyCheckmarkableRow
              color={PROPS.color ? PROPS.color : "#ffffff"}
              noSelect={PROPS.noSelect}
              selected={
                PROPS.selectedAudienceMultiIds &&
                PROPS.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={PROPS.mobile}
              // key={i}
              firstRow={this.props.index === 0}
              lastRow={this.props.index === this.props.data.length - 2}
              hideFirstLastDash={PROPS.hideFirstLastDash}
              isSingleUserCompany={row.is_single_user_company}
              image={row.image}
              primaryText={row.name}
              removeAction={PROPS.removeAction}
              removeFromSelected={() => PROPS.removeFromSelected(audience.id)}
              secondaryText={row.is_single_user_company ? row.pseudoName : row.people}
              selectRow={PROPS.onSelect}
              id={audience.id}
              type={audience.type}
              companyId={audience.company_id}
            />
          :
          type === "department"&& !PROPS.onlyPeople
          ?
          <DepartmentCheckmarkableRow
              color={PROPS.color ? PROPS.color : "#ffffff"}
              noSelect={PROPS.noSelect}
              selected={
                PROPS.selectedAudienceMultiIds &&
                PROPS.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={PROPS.mobile}
              // key={i}
              firstRow={this.props.index === 0}
              lastRow={this.props.index === this.props.data.length - 2}
              hideFirstLastDash={PROPS.hideFirstLastDash}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}              
              removeAction={PROPS.removeAction}
              removeFromSelected={() => PROPS.removeFromSelected(audience.id)}
              ancillaryText={row.people}
              selectRow={PROPS.onSelect}
              id={audience.id}
              type={audience.type}
              companyId={audience.company_id}
            />
          :
          type === "group"&& !PROPS.onlyPeople
          ?
          <GroupCheckmarkableRow
              color={PROPS.color ? PROPS.color : "#ffffff"}
              noSelect={PROPS.noSelect}
              selected={
                PROPS.selectedAudienceMultiIds &&
                PROPS.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={PROPS.mobile}
              // key={i}
              firstRow={this.props.index === 0}
              lastRow={this.props.index === this.props.data.length - 2}
              hideFirstLastDash={PROPS.hideFirstLastDash}
              isManaged={row.isManaged}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}              
              removeAction={PROPS.removeAction}
              removeFromSelected={() => PROPS.removeFromSelected(audience.id)}
              ancillaryText={row.isManaged ? row.people : row.companies}
              selectRow={PROPS.onSelect}
              id={audience.id}
              type={audience.type}
              companyId={audience.company_id}
              companyIds={audience.row.companyIds}
            />
          :
          type === "person"
          ?
          <PersonCheckmarkableRow
              color={PROPS.color ? PROPS.color : "#ffffff"}
              noSelect={PROPS.noSelect}
              selected={
                PROPS.selectedAudienceMultiIds &&
                PROPS.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={true}
              // key={i}
              firstRow={this.props.index === 0}
              lastRow={this.props.index === this.props.data.length - 2}
              hideFirstLastDash={PROPS.hideFirstLastDash}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}
              removeAction={PROPS.removeAction}
              removeFromSelected={() => PROPS.removeFromSelected(audience.id)}
              selectRow={PROPS.onSelect}
              id={audience.id}
              type={audience.type}
              companyId={audience.company_id}
              userId={row.id}
            />
          :
          null


        }
      </div>
    );
  }
}


class EmployeeRenderer extends Component {
    shouldComponentUpdate = shouldComponentUpdate.bind(this);

    render() {
        
      // Access the items array using the "data" prop:
      const employee = this.props.data[this.props.index];
    //   console.log(employee)
     const PROPS = this.props.data[this.props.data.length-1];
    //  console.log(PROPS)
      return (
        <div style={this.props.style}>
          <EmployeeRow
            // key={employee.id.toString()}
            // onClick={employee.link ? ()=> history.push(employee.link) : null}
            selectedRowId={PROPS.selectedRowId}
            selectRowId={PROPS.selectRowId}
            userDepartments={employee.departments || []}
            companyDepartments={PROPS.departments || []}
            company={PROPS.company}
            firstRow={this.props.index === 0}
            lastRow={this.props.index ===this.props.data.length - 2}
            hideFirstLastDash={PROPS.hideFirstLastDash}
            mobile={PROPS.mobile}
            employee={employee}
            fetchUsers={PROPS.fetchUsers}
            updateUser={PROPS.updateUser}
            resendClickHandler={PROPS.resendClickHandler}
            deleteClickHandler={PROPS.deleteClickHandler}
            removeClickHandler={PROPS.removeClickHandler}
            acceptClickHandler={PROPS.acceptClickHandler}
            makeAdminClickHandler={PROPS.makeAdminClickHandler}
            removeAdminClickHandler={PROPS.removeAdminClickHandler}
            makeSalesRep={PROPS.makeSalesRep}
            removeSalesRep={PROPS.removeSalesRep}
            makeSystemAdminClickHandler={PROPS.makeSystemAdminClickHandler}
            removeSystemAdminClickHandler={PROPS.removeSystemAdminClickHandler}
            search={PROPS.search}
            searchEmployees={PROPS.searchEmployees}
            user={PROPS.user}
            systemSettings={PROPS.systemSettings}
            companySettings={PROPS.companySettings}
            openSplitsModal={PROPS.openSplitsModal}
          />
        </div>
      );
    }
  }

  class ConnectedCompanyRenderer extends Component {
    shouldComponentUpdate = shouldComponentUpdate.bind(this);

    render() {
    const company = this.props.data[this.props.index];
     const PROPS = this.props.data[this.props.data.length-1];

     return (
      <div style={this.props.style}>
        <ConnectedCompanyRow
          onSelect={PROPS.onSelect}
          company={company}
          firstRow={this.props.index === 0}
          lastRow={this.props.index ===this.props.data.length - 2}
        />
      </div>
     );
    }
  }

  class MappedNameRenderer extends Component {
    shouldComponentUpdate = shouldComponentUpdate.bind(this);

    render() {
    const mapping = this.props.data[this.props.index];
     const PROPS = this.props.data[this.props.data.length-1];

     return (
      <div style={this.props.style}>
        <MappedNameRow
          mapping={mapping}
          onSelect={PROPS.onSelect}
          firstRow={this.props.index === 0}
          lastRow={this.props.index ===this.props.data.length - 2}
          selected={PROPS.selected}
        />
      </div>
     );
    }
  }


  class VendorRenderer extends Component {
    shouldComponentUpdate = shouldComponentUpdate.bind(this);

    render() {
      // console.log("VendorRenderer this.props",this.props)
      // Access the items array using the "data" prop:
      const vendor = this.props.data[this.props.index];
    //   console.log(employee)
     const PROPS = this.props.data[this.props.data.length-1];
    //  console.log(PROPS)
      return (
        <div style={this.props.style}>
          <VendorRow
            fetchNotificationCount={PROPS.fetchNotificationCount}
            updateSearch={PROPS.updateSearch}
            vendorGroups={vendor.groups || []}
            companyGroups={PROPS.groups || []}
            company={PROPS.company}
            connections={PROPS.connections}
            handlePartnerModal={PROPS.handlePartnerModal}
            createConnection={PROPS.createConnection}
            fetchConnections={PROPS.fetchConnections}
            fetchConnectionSuggestions={PROPS.fetchConnectionSuggestions}
            // fetchCompanies={}
            updateConnection={PROPS.updateConnection}
            updateCompany={PROPS.updateCompany}
            vendor={vendor}
            firstRow={this.props.index === 0}
            lastRow={this.props.index ===this.props.data.length - 2}
            hideFirstLastDash={PROPS.hideFirstLastDash}
            mobile={PROPS.mobile}
            redirect={PROPS.redirect}
            settings={PROPS.settings}
            notifySuccess={PROPS.notifySuccess}
          />
        </div>
      );
    }
  }

function itemKey(index, data) {
    // Find the item at the specified index.
    // In this case "data" is an Array that was passed to List as "itemData".
    const item = data[index];
   
    // Return a value that uniquely identifies this item.
    // Typically this will be a UID of some sort.
    return item.id;
}