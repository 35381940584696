import React, {useState} from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField, OnboardingCTA } from "../../common/forms";
import { Flex, Box } from "../../common/flexbox";
import { H1, P, NunitoSans } from "../../common/typography";
import { EMAIL_REGEX, NON_BIZ_REGEX } from "../../../constants/app";
// import { SubmissionError } from 'redux-form';  // ES6

import Legal from "../legal";

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email required";
  } else {
    values.email = values.email.toString().trim();
  }

  if (!values.first_name) {
    errors.first_name = "First name required";
  }

  if (!values.last_name) {
    errors.last_name = "Last name required";
  }

  // if(!values.terms){
  //   errors.terms = "You need to accept terms and conditions."
  // }

  let re = new RegExp(EMAIL_REGEX, "i");
  
  if(localStorage.getItem("LeEmail")&&localStorage.getItem("LeEmail")!==""){
    values["email"] = localStorage.getItem("LeEmail");
  } 
  if (!re.test(values["email"])) {
    errors["email"] = "Not a valid email";
  }

  let re2 = new RegExp(NON_BIZ_REGEX, "i");

  if (re2.test(values["email"])) {
    errors["email"] = "Registration requires work email addresses for security purposes";
  }

  const alphaNumericRegex = new RegExp(/[^a-z0-9]/gi);

  if(values.referral_code && values.referral_code.trim().length && alphaNumericRegex.test(values.referral_code.trim())){
    errors["referral_code"] = "Only letters and numbers are allowed.";
  } 
  return errors;
};

let RegisterForm = props => {
  const { handleSubmit,submit, error, pristine, reset, submitting } = props;
//  const { handleSubmit,submit, error, pristine, reset, submitting, referralCode } = props;
console.log(props);
  // if(referralCode){
    // console.log(referralCode);
    // props.change('referral_code', referralCode);
  // }
  let referral_code = localStorage.getItem("referral_code");
  console.log("referral_code",referral_code);
  if(!referral_code){
    referral_code = "";
  }


  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  return (
    <Flex
      width={[1, 1, 1]}
      alignItems="center"
      flexDirection="column"
      id="register-form-flex"
    >
      <Legal
      termsOpen={termsOpen}
      privacyOpen={privacyOpen}
      setPrivacyOpen={setPrivacyOpen}
      setTermsOpen={setTermsOpen}
      />
      <H1 alignSelf={"flex-start"}>Your Info.</H1>
      <P alignSelf={"flex-start"}>
      Enter your first and last name so we know who you are.
      </P>
      <form
        onSubmit={handleSubmit}
        id="register-form"
        style={{ width: "100%" }}
      >
        <Flex
          w={1}
          mt={["7px", "28px", "28px", "28px"]}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
         
            <Box width={[1,1,1]}>
              <Field
                label="First name"
                name="first_name"
                component={renderField}
                type="text"
                mb="0px"
              />
            </Box>
            <Box width={[1,1,1]}>
              <Field
                label="Last name"
                name="last_name"
                component={renderField}
                type="text"
                mb="0px"
              />
            </Box>
         
          <Box width={[1,1,1]}>
            <Field
              label="Company Name"
              name="company"
              disabled={false}
              defaultValue={""}
              component={renderField}
              type="text"
              mb="0px"
            />
           
          </Box>

          <Box width={[1,1,1]}>
            <Field
              label="Referral Code"
              name="referral_code"
              disabled={true}
              component={renderField}
              type="text"
            />
           
          </Box>

          
          
          <div  style={{display:"none"}} >
            <Box width={[1,1,1]}>
              <Field
                label="Work Email"
                name="email"
                value=""
                editable={false}
                component={renderField}
                type="text"
                mb="0px"
              />
            
            </Box>
           </div>
          
          <Flex
          flexDirection="column"
          alignSelf="center"
          style={{marginTop:"-25px"}}
          >
          <label>
            <Flex
            
            >
            {/* <Field name="terms" component="input" type="checkbox" value="accepted"/>  */}
            {/* <NunitoSans
            fontSize={['12px', '12px', '14px', '14px']}
            style={{marginLeft:'20px'}}
            >
            By signing up, you are agreeing to KickPost <span 
            onClick={(e)=>{
              e.preventDefault();
              setTermsOpen(true)
            }} 
            style={{color:"#2FD371", textDecoration:"underline", cursor: "pointer"}}
            >
              Terms of Service
              </span> and  <span 
              onClick={(e)=>{
                e.preventDefault();
                setPrivacyOpen(true);
              }} 
              style={{color:"#2FD371", textDecoration:"underline", cursor: "pointer"}}
              >
                Privacy Policy
              </span>
              .
            </NunitoSans> */}
            
            </Flex>
          </label>
          {error && <strong style={{marginTop:'10px', color: 'red', textAlign:'center'}}>{error}</strong>}
          </Flex>
          
          <OnboardingCTA
            mt={["21px", "21px", "21px", "21px"]}
            width={[0.75, 0.75, 0.375, 0.375]}
            type="submit"
          >
            NEXT
          </OnboardingCTA>
          {/* <Flex justifyContent="center">
            <Box 
              width={[1, 1, 1, 1]}
              my={2}>
              <Link to="/">
                <P>Already have an account? <span style={{color: '#2FD371', fontWeight: "bold"}}> <i>Log In</i></span> </P>
              </Link>
            </Box>
          </Flex> */}
        </Flex>
      </form>
    </Flex>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  form: "register",
  validate
})(RegisterForm);
