import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import moment from "moment";
import AWS from "aws-sdk";
// import ReactTooltip from 'react-tooltip';
// import Dropzone from "react-dropzone";
// import Modal from "react-modal";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { setInvitationId } from "../../../actions/invitations";
//components
import { Flex, Box } from "../../common/flexbox";
import { InviteVendorCTA, RecommendedContentWrapper, CreateDepartmentCTA, EditDepartmentCTA } from "../../common/ui";
import { NunitoSans, NunitoSansBold, H1CondensedSmallD0D0D0 } from "../../common/typography";
// import { DashboardSmallCTA, renderSmallField } from "../../common/forms";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';

import { EMAIL_REGEX, NON_BIZ_REGEX } from "../../../constants/app";

import CsvDropzoneModal from "../../common/csvDropzoneModal";
// import { CSVLink, CSVDownload } from "react-csv";


// import LoadingSpinner from "../../common/loading";


export class ManageInvitationsAccessory extends Component {
  //constructor(props) {
  //  super(props);
  //    this.state = { search: null };
  // this._search = _.debounce(this._search.bind(this), 400);
  //}
  state = {
    uploading: false,
    csvDropOpen: false,
    uploadHistory: [],
    clipboardSuccess: false,
    clipboardSuccessBiz: false
  }

  componentDidMount(){
    this._fetchDependencies();
  }

  _fetchDependencies =  () => {
    const { fetchCsvuploads, fetchCompany, company, fetchUser, user } = this.props;

    if (!user || (user.id !== getUser().id)) {
      fetchUser(getUser().id);      
      fetchCompany(getUser().company_id);
    } else {
      fetchCompany(getUser().company_id);    
    }

    const CSVUploads = fetchCsvuploads({ company_id: getUser().company_id, type: 'vendor_invite' });
    if (CSVUploads) {
      console.log("src/components/dashboard/invitations/accessory.js ", "CSVUploads", CSVUploads);
      console.log("src/components/dashboard/invitations/accessory.js ", "CSVUploads", JSON.stringify(CSVUploads));
      console.log("src/components/dashboard/invitations/accessory.js ", "typeof CSVUploads", typeof CSVUploads);
      console.log("src/components/dashboard/invitations/accessory.js ", "CSVUploads.length", CSVUploads.length);
      const entries = Object.entries(CSVUploads);
      console.log("src/components/dashboard/invitations/accessory.js ", entries);
      if (CSVUploads.length > 0) {
        const upHist = CSVUploads.filter((a, index) => index < 6);
        this.setState({ uploadHistory: upHist });
      }

    }

  }


  _onSubmit = async data => {
    const {
      people,
      searchedPeople,
      fetchPersons,
      createConnection,
      createUser,
      resetForm,
      notifyError,
      notifySuccess,
      company,
      companySettings
    } = this.props;

    let inviteeEmails = people.map(item => item.person.email);


    let LeEmail = data.email;
    LeEmail = LeEmail.toString().trim().toLowerCase();
    data.email = LeEmail;

    let userPayload = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      status: "invited_company",
      inviter: {
        ...getUser(),
        company_name: company.name
      }
    };

    let getUserEmail = getUser().email.split("@")[1];
    let userPayloadEmail = userPayload.email.split("@")[1];

    if (inviteeEmails.includes(userPayload.email.toString().toLowerCase())) {
      notifyError(
        "You have already invited this person, to remind them click the RESEND button below."
      );
      resetForm("create-vendor");
    } else if (
      (!companySettings.is_single_user_company && userPayloadEmail != undefined)
      ||
      (companySettings.is_single_user_company && getUser().email !== userPayload.email && userPayloadEmail != undefined)
    ) {
      //check if the email is already associated with another user
      const activeEmail = await fetch(`${process.env.REACT_APP_API_URL}/emailcheck/${data.email}`).then(res => res.json());

      if (activeEmail && activeEmail.id) {
        return notifyError("This email is already associated with another user in the system.")
      }

      const user = await createUser({
        ...userPayload,
        doNotUpdateState: true
      });


      if (user && user.payload) {
        // console.log("src/components/dashboard/invitations/accessory.js ", user);
        let result = await createConnection({
          vendor_id: getUser().company_id,//maye hard-code this to KickPost
          company_id: getUser().company_id,//getUser().company_id,
          user_id: getUser().id,
          invitee_id: user.payload.invited_company_user.id,
          status: "invited",
          inviteMessage: data.message
        });

        if (typeof result !== "undefined" && result.payload.status === "invited") {
          notifySuccess(
            "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
          );
          fetchPersons({ "id": getUser().id });
        } else {
          notifyError(
            "We could not invite this person. Please try again later."
          );
        }
      }


    }


    resetForm("create-vendor");
  };

  _uploadVendorCsv = async (file, inviteMessage = '') => {
    const { createBatchInvite, notifySuccess, company } = this.props;

    this.setState({ uploading: true });

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });

    const s3Bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });
    const fileKey = `csv-vendor-uploads/${moment().valueOf()}${getUser().company_id
      }`;
    let s3Data = {
      Key: fileKey,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    };

    
    const uploadRes =  s3Bucket.putObject(s3Data, async (err, response) => {
      
      return "big time";
    });


      if(uploadRes.response.error===null){

        const bi = await createBatchInvite({
          user_id: getUser().id,
          company_id: getUser().company_id,
          profile_pic: `<img  width="30%" height="30%" src=${getUser().avatar}>`,
          url: fileKey,
          invite_type: "vendor",
          status: "created",
          inviter: {
            ...getUser(),
            company_name: company.name
          },
          inviteMessage
        });
  
  
        if (bi && bi.payload && bi.payload.id) {
          this.setState({ uploading: false, csvDropOpen: false });
  
          notifySuccess(
            "We will email you when we have finished processing your upload."
          );
        }
      }
    return uploadRes;
  };

  validate = values => {
    const errors = {};
    if (!values.company_name) {
      //  errors.company_name = "Company name required";
    }

    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    let re = new RegExp(EMAIL_REGEX, "i");

    if (!re.test(values["email"])) {
      errors["email"] = "Not a valid email";
    }

    let re2 = new RegExp(NON_BIZ_REGEX, "i");

    if (re2.test(values["email"])) {
      errors["email"] = "Only Work Emails";
    }

    if (!values.message || !values.message.trim()) {
      errors.message = "Message required";
    } else if (values.message.trim().length > 300) {
      errors.message = "Message cannot be more than 300 characters.";
    }

    return errors;
  };

  _handleCloseModal = () => this.setState({ csvDropOpen: false, uploading: false })

  _handleCsvUpload = () => { this.setState({ csvDropOpen: true }) };

  _copyToClipboard = async (referralCode,user=false) => {

    try {
      const text = `${window.origin}/signup/?referral_code=${referralCode}`;
      await navigator.clipboard.writeText(text);
      if(user===true){
        this.setState({ clipboardSuccess: true });
        setTimeout(() => this.setState({ clipboardSuccess: false }), 1000);
      }
      if(user===false){
        this.setState({ clipboardSuccessBiz: true });
        setTimeout(() => this.setState({ clipboardSuccessBiz: false }), 1000);
      }

    } catch (error) {
      console.log("src/components/dashboard/invitations/accessory.js ", error)
    }
  }
  _launchMailer = async (e,referralCode) => {

    try {
      e.preventDefault();
      var formattedBody = `I want to invite you to join us on KickPost, a platform that lets us make more sales together! \n\nIt takes 2 min to join and it's free! \n\nHere's my referral link: \n\n${window.origin}/signup/?referral_code=${referralCode}`;
      var mailToLink = "mailto:?subject=Join me on KickPost and lets make our partnership, click!&bcc=team@kickpost.io&body=" + encodeURIComponent(formattedBody);
      // window.location.href = mailToLink;
      window.open(mailToLink, '_blank');
    } catch (error) {
      console.log("src/components/dashboard/invitations/accessory.js mailing", error)
    }
  }

  render() {
    const { departments,
      departmentId,
      setInvitationId,
      people,
      searchedPeople,
      company,
      user
    } = this.props;

    const { uploading, csvDropOpen, uploadHistory, clipboardSuccess,clipboardSuccessBiz } = this.state;

    const headers = [
      { label: 'WORK EMAIL', key: 'work_email' },
      { label: 'FIRST NAME', key: 'first_name' },
      { label: 'LAST NAME', key: 'last_name' }
    ];
    
    console.log("company",company);
    console.log("user",user);
    if(!company){
      // fetchCompany(user.company_id);
    }


    /*if (departmentId) {
      return (
        <Flex flexDirection="column">
          <Box height="103px" />
          <EditDepartmentCTA
            initialValues={{
              name: _.findWhere(departments, { id: departmentId }).name
            }}
            setInvitationId={() => setInvitationId(null)}
            onSubmit={this._updateDepartment}
          />
        </Flex>
      );
    }*/
    //below is for the inner page after clicking 'manage invites' in connections page//
    return (
      <Flex flexDirection="column">
        <Box height="76px" width={["100%"]} />
        {
          user && user.referral_code
            ?
            <>
              <RecommendedContentWrapper noLabel={true}>
                <Flex flexDirection="column" pt="14px" px="8px">
                  <Flex px="1%" flexDirection="row" justifyContent="space-between">
                    <Box maxWidth="80%">
                      <H1CondensedSmallD0D0D0>Referral Link</H1CondensedSmallD0D0D0>
                    </Box>
                  </Flex>

                  <Flex pt="19px" pb="19px" flexDirection="column" px="1%">

                  <Flex flexDirection="row" justifyContent="space-between" alignItems="center" py="5px" style={{ backgroundColor: "#f8f8f8" }}>
                      <NunitoSansBold
                        fontSize="12px"
                        color="#428bf4"
                        style={{ marginLeft: '10px' }}
                      >
                        <FontAwesomeIcon
                          icon={faHandshake}
                          size="lg"
                          color="#428bf4"
                          style={{ cursor: 'pointer', marginRight: '10px' }}
                        /> {`${window.origin}/signup/?referral_code=${user.referral_code}`}
                      </NunitoSansBold>

                      {
                        navigator && navigator.clipboard
                          ?
                          clipboardSuccess
                            ?
                            <NunitoSans
                              fontSize="14px"
                              color="#428bf4"
                              style={{ marginRight: '10px' }}
                            >
                              Copied!
                            </NunitoSans>
                            :
                            <div>
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                size="lg"
                                color="#428bf4"
                                // onClick={() => console.log("src/components/dashboard/invitations/accessory.js ", user.referral_code)}
                                onClick={(e) => 
                                  this._launchMailer(e,user.referral_code)
                                }
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                              />
                              <FontAwesomeIcon
                                icon={faCopy}
                                size="lg"
                                color="#428bf4"
                                // onClick={() => console.log("src/components/dashboard/invitations/accessory.js ", user.referral_code)}
                                onClick={() => this._copyToClipboard(user.referral_code,true)}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                              />
                            </div>
                          // <DashboardSmallCTA
                          //   width="15%"
                          //   minWidth={["60px"]}
                          //   fontSize="10px"
                          //   innerHeight="20px"
                          //   onClick={() => this._copyToClipboard(user.referral_code)}
                          //   color="#428bf4"
                          // >
                          //   COPY
                          // </DashboardSmallCTA>
                          :
                          null
                      }
                  </Flex>
                  <Flex flexDirection="row" justifyContent="space-between" alignItems="center" py="5px" style={{ backgroundColor: "#f8f8f8" }}>
                    
                    {(company&&user&&(user.company_admin===true))? 
                    <NunitoSansBold
                      fontSize="12px"
                      color="#428bf4"
                      style={{ marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon
                              icon={faBuilding}
                              size="lg"
                              color="#428bf4"
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                      /> 
                      
                      {(company&&user&&(user.company_admin===true))? `${window.origin}/signup/?referral_code=${company.referral_code}`: ""}
                    </NunitoSansBold>
                    : null}


                    {
                      navigator && navigator.clipboard && company&&user&&(user.company_admin===true)
                        ?
                        clipboardSuccessBiz
                          ?
                          <NunitoSans
                            fontSize="14px"
                            color="#428bf4"
                            style={{ marginRight: '10px' }}
                          >
                            Copied!
                          </NunitoSans>
                          :
                          <div>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              size="lg"
                              color="#428bf4"
                              // onClick={() => console.log("src/components/dashboard/invitations/accessory.js ", user.referral_code)}
                              onClick={(e) => 
                                this._launchMailer(e,company.referral_code)
                              }
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <FontAwesomeIcon
                              icon={faCopy}
                              size="lg"
                              color="#428bf4"
                              // onClick={() => console.log("src/components/dashboard/invitations/accessory.js ", user.referral_code)}
                              onClick={() => this._copyToClipboard(company.referral_code,false)}
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                          </div>
                        // <DashboardSmallCTA
                        //   width="15%"
                        //   minWidth={["60px"]}
                        //   fontSize="10px"
                        //   innerHeight="20px"
                        //   onClick={() => this._copyToClipboard(user.referral_code)}
                        //   color="#428bf4"
                        // >
                        //   COPY
                        // </DashboardSmallCTA>
                        :
                        null
                    }
                  </Flex>
                  </Flex>
                </Flex>
              </RecommendedContentWrapper>
              <Box height="40px" />
            </>
            :
            null
        }
        <InviteVendorCTA onSubmit={this._onSubmit} validate={this.validate} initialValues={{ message: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales." }} handleCsvUpload={this._handleCsvUpload} />
        <Box height="40px" />
        {/* <Box height="78px" width={["100%"]} data-tip="Drag and Drop File Here">
          <NonDottedDropzone
          uploading={this.state.uploading}         
            text="Drag and Drop CSV file here"
            secondText="(work email, first name, last name)"
            thirdText="Maximum 5,000 invites per upload."
            type="csv"
            onDrop={this._uploadVendorCsv}
          />
        </Box>
        {
          this.state.uploading ? null : <ReactTooltip />
        }
        
        <Box height="40px" />
        <Box height="78px" width={["100%"]}>
          <NonDottedDownloadVendorTemplate 
            text="Download CSV Template"
          />
        </Box> */}
        {/* <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="14px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Uploads</H1CondensedSmallD0D0D0>
            </Box>
            </Flex>
            
          <Flex pt="19px" flexDirection="column" px="1%">
          
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="50%"
                onClick={() => {
                  this.setState({ csvDropOpen: true})
                }}
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                UPLOAD INVITES
              </DashboardSmallCTA>
              
              
            </Flex>
          </Flex>
        </Flex>
        
      </RecommendedContentWrapper> */}

        <CsvDropzoneModal
          modalOpen={csvDropOpen}
          handleCloseRequest={this._handleCloseModal}
          handleCsvUpload={uploading ? () => { } : this._uploadVendorCsv}
          setUploadingState={(uploading)=>this.setState({uploading})}
          csvName={"kickpost invite"}
          uploading={uploading}
          csvErrors={[]}
          csvHeaders={headers}
          uploadHistory={uploadHistory}
          requireConfirmation={true}
          notifyError={this.props.notifyError}
        />
        <Box height="40px" />

      </Flex>
    );
  }
}

export default connect(
  state => ({
    user: state.resources.detail.user,
    departments: state.resources.collection.department,
    departmentId: state.departments.departmentId,
    people: state.resources.collection.person || [],
    searchedPeople: state.search.personSearchResults || [],
    companySettings: state.resources.detail.companysetting,
    company: state.resources.detail.company,
  }),
  dispatch => ({
    setInvitationId: bindActionCreators(setInvitationId, dispatch),
    createDepartment: bindActionCreators(actions.createDepartment, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    fetchCsvuploads: bindActionCreators(actions.fetchCsvuploads, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createBatchInvite: bindActionCreators(actions.createBatch_invite, dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchPersons: bindActionCreators(actions.fetchPersons, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)//,
    //updateDepartment: bindActionCreators(actions.patchDepartment, dispatch)
  })
)(ManageInvitationsAccessory);
