import React, { Component } from "react";
import { connect } from "react-redux";

import { NunitoSans } from "../../../../common/typography";
import { Flex } from "../../../../common/flexbox";

import { AbsoluteDiv } from "../../../../common/absolute";

import clone from "lodash/clone";

// Tweening
import { Easing, Tween, autoPlay } from "es6-tween";

class CompanyInfographic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      window1Dashoffset: 1000,
      window2Dashoffset: 1000,
      window3Dashoffset: 1000,
      window4Dashoffset: 1000,
      window5Dashoffset: 1000,
      roofDashoffset: 1000,
      companiesActive: props.companiesActive,
      companiesActiveForRender: 0,
      companiesTotal: props.companiesTotal,
      companiesTotalForRender: 0
    };
    this.Tween = Tween;
    this.Easing = Easing;
    this.autoPlay = autoPlay;
  }
  componentDidMount() {
    const delay = 500;
    this.underlayRoofPath = this.refs.underlayRoofPath;
    this.underlayWindowPath = this.refs.underlayWindowPath;
    this.updateGraphStateForPercent(
      this.state.companiesActive / this.state.companiesTotal
    );
    /*
    setTimeout(() => {
    }, delay);
    */
    this.tweenStateNumbers(delay);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.companiesTotal !== this.props.companiesTotal ||
      prevProps.companiesActive !== this.props.companiesActive
    ) {
      this.setState({
        companiesActive: this.props.companiesActive,
        companiesTotal: this.props.companiesTotal
      });
    }
    if (
      prevState.companiesTotal !== this.state.companiesTotal ||
      prevState.companiesActive !== this.state.companiesActive
    ) {
      this.updateGraphStateForPercent(
        this.state.companiesActive / this.state.companiesTotal
      );
      this.tweenStateNumbers(0);
    }
  }
  tweenStateNumbers = delay => {
    let updateFunc = function() {
      this.autoPlay(true);
      let from = {
        active: clone(this.state.companiesActiveForRender),
        total: clone(this.state.companiesTotalForRender)
      };

      let to = {
        active: clone(this.state.companiesActive),
        total: clone(this.state.companiesTotal)
      };

      let scrollTween = new this.Tween(from) // eslint-disable-line no-unused-vars
        .to(to, 500)
        .easing(this.Easing.Quadratic.Out)
        .on(
          "update",
          function(transitioning) {
            // Called after tween.js updates 'coords'.
            this.setState({
              companiesActiveForRender: transitioning.active,
              companiesTotalForRender: transitioning.total
            });
          }.bind(this)
        )
        .on(
          "complete",
          function() {
            // Called after tween.js updates 'coords'.
            this.setState({
              companiesActiveForRender: to.active,
              companiesTotalForRender: to.total
            });
          }.bind(this)
        )
        .delay(delay)
        .start(); // Start the tween immediately.
    }.bind(this);

    window.requestAnimationFrame(function() {
      updateFunc();
    });

    /*
    var that = this;
    var tween = new TWEEN.Tween(from) // Create a new tween that modifies 'coords'.
      .to(to, 1000) // Move to (300, 200) in 1 second.
      .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
      .onUpdate(function() {
        that.setState({
          companiesActive: from.active,
          companiesTota: from.total,
        });
      })
      .start(); // Start the tween immediately.
      */
  };
  updateGraphStateForPercent(percent) {
    const roofLength = this.underlayRoofPath.getTotalLength();
    console.log("percent")
    console.log(isNaN(percent))
    console.log(typeof percent)
    if (isNaN(percent)) {
      percent = 0;
    }

    let roofDashoffset;
    roofDashoffset = 1000 - roofLength * percent;
//    let roofDashoffset = 1000 - roofLength * percent; //REMOVED

    const underlayWindowLength = this.underlayWindowPath.getTotalLength();
    const windowMin = 1000 - underlayWindowLength * 0.03;
    let window1Dashoffset = 1000;
    let window2Dashoffset = 1000;
    let window3Dashoffset = 1000;
    let window4Dashoffset = 1000;
    let window5Dashoffset = 1000;

    const leftSideCrawlEnd = 45 / 400;
    const rightSideCrawlStart = (400 - 45) / 400;
    if (percent > 0 && percent <= leftSideCrawlEnd) {
      if (percent > 0 && percent <= leftSideCrawlEnd / 5 * 1) {
        window1Dashoffset = windowMin;
      } else if (percent > 0 && percent <= leftSideCrawlEnd / 5 * 2) {
        window1Dashoffset = windowMin;
        window2Dashoffset = windowMin;
      } else if (percent > 0 && percent <= leftSideCrawlEnd / 5 * 3) {
        window1Dashoffset = windowMin;
        window2Dashoffset = windowMin;
        window3Dashoffset = windowMin;
      } else if (percent > 0 && percent <= leftSideCrawlEnd / 5 * 4) {
        window1Dashoffset = windowMin;
        window2Dashoffset = windowMin;
        window3Dashoffset = windowMin;
        window4Dashoffset = windowMin;
      } else if (percent > 0 && percent <= leftSideCrawlEnd / 5 * 5) {
        window1Dashoffset = windowMin;
        window2Dashoffset = windowMin;
        window3Dashoffset = windowMin;
        window4Dashoffset = windowMin;
        window5Dashoffset = windowMin;
      }
    }
    if (percent > leftSideCrawlEnd) {
      const flatPart = percent - leftSideCrawlEnd;
      const flatPercent = flatPart / (rightSideCrawlStart - leftSideCrawlEnd);

      const maxWindowDashoffset = 1000 - underlayWindowLength * flatPercent;
      window5Dashoffset = maxWindowDashoffset;
      window4Dashoffset = 1000 - underlayWindowLength * (flatPercent * 9 / 10);
      window3Dashoffset = 1000 - underlayWindowLength * (flatPercent * 2 / 3);
      window2Dashoffset = 1000 - underlayWindowLength * (flatPercent * 1 / 2);
      window1Dashoffset = 1000 - underlayWindowLength * (flatPercent * 1 / 3);
    }
    if (percent > 0.5) {
      const flatPart = percent - leftSideCrawlEnd;
      const flatPercent = flatPart / (rightSideCrawlStart - leftSideCrawlEnd);

      const maxWindowDashoffset = 1000 - underlayWindowLength * flatPercent;
      window5Dashoffset = maxWindowDashoffset;
      window4Dashoffset = maxWindowDashoffset;
      window3Dashoffset = 1000 - underlayWindowLength * (flatPercent * 9 / 10);
      window2Dashoffset = 1000 - underlayWindowLength * (flatPercent * 3 / 4);
      window1Dashoffset = 1000 - underlayWindowLength * (flatPercent * 2 / 3);
    }
    if (percent > rightSideCrawlStart) {
      const crawlLeft = percent - rightSideCrawlStart;
      const crawlTotal = 1 - rightSideCrawlStart;
      const fullWindowDashOffset = 1000 - underlayWindowLength;

      if (crawlLeft <= crawlTotal / 5 * 1) {
        window5Dashoffset = fullWindowDashOffset;
        window4Dashoffset = fullWindowDashOffset;
      } else if (crawlLeft <= crawlTotal / 5 * 2) {
        window5Dashoffset = fullWindowDashOffset;
        window4Dashoffset = fullWindowDashOffset;
      } else if (crawlLeft <= crawlTotal / 5 * 3) {
        window5Dashoffset = fullWindowDashOffset;
        window4Dashoffset = fullWindowDashOffset;
        window3Dashoffset = fullWindowDashOffset;
      } else if (crawlLeft <= crawlTotal / 5 * 4) {
        window5Dashoffset = fullWindowDashOffset;
        window4Dashoffset = fullWindowDashOffset;
        window3Dashoffset = fullWindowDashOffset;
        window2Dashoffset = fullWindowDashOffset;
      } else {
        window5Dashoffset = fullWindowDashOffset;
        window4Dashoffset = fullWindowDashOffset;
        window3Dashoffset = fullWindowDashOffset;
        window2Dashoffset = fullWindowDashOffset;
        window1Dashoffset = fullWindowDashOffset;
      }
    }

    this.setState({
      roofDashoffset: roofDashoffset,
      window1Dashoffset: window1Dashoffset,
      window2Dashoffset: window2Dashoffset,
      window3Dashoffset: window3Dashoffset,
      window4Dashoffset: window4Dashoffset,
      window5Dashoffset: window5Dashoffset
    });
  }
  render() {
    return (
      <Flex
        onClick={() => {
          /*
          this.setState({
            companiesActive: Math.random() * this.state.companiesTotal
          });
          */
        }}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <NunitoSans
          display={["block", "none", "none", "block"]}
          textAlign="center"
          pl="2px"
          fontSize={["8px"]}
          fontWeight={700}
          color="darkText"
        >
          COMPANIES
          </NunitoSans>
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection={["column", "row", "row", "column"]}
        >
          <Flex
            display={["flex !important"]}
            mt={["0px", "3px", "3px", "7px"]}
            mb={["0px", "3px", "3px", "7px"]}
            pb={["14.1%"]}
            width={["100%"]}
            position="relative"
          >
            <AbsoluteDiv
              display={["none", "block", "block", "none"]}
              top={["-15px", "-13px", "-15px"]}
              left="0px"
              right="0px"
            >
              <NunitoSans
                textAlign="center"
                pl="2px"
                fontSize={["8px", "9px", "10px", "11px"]}
                fontWeight={700}
                color="darkText"
              >
                COMPANIES
              </NunitoSans>
            </AbsoluteDiv>
            <AbsoluteDiv
              zIndex={5}
              top="0px"
              left="0px"
              width="100%"
              height="100%"
            >
              <svg
                width="100%"
                height="100%"
                id="company-underlay"
                x="0px"
                y="0px"
                viewBox="0 0 310 45"
              >
                <path id="Line" className="st0" d="M17,17h277" />
                <path id="Line_1_" className="st0" d="M17,23h277" />
                <path id="Line_2_" className="st0" d="M17,29h277" />
                <path id="Line_3_" className="st0" d="M17,35h277" />
                <path
                  ref="underlayWindowPath"
                  id="Line_4"
                  className="st0"
                  d="M17,41h277"
                />
                <path
                  ref="underlayRoofPath"
                  id="Rectangle-11"
                  className="st1"
                  d="M5,39.5c0-4.1,0-6.7,0-7.8C5,28,5,22.4,5,15C5,9.5,9.5,5,15,5h280c5.5,0,10,4.5,10,10v25"
                />
              </svg>
            </AbsoluteDiv>
            <AbsoluteDiv
              zIndex={10}
              top="0px"
              left="0px"
              width="100%"
              height="100%"
            >
              <svg
                width="100%"
                height="100%"
                id="company-gradient"
                x="0px"
                y="0px"
                viewBox="0 0 310 45"
              >
                <linearGradient gradientUnits="userSpaceOnUse" id="grad">
                  <stop stopColor="#009EFD" />
                  <stop offset="100%" stopColor="#74EBD5" />
                </linearGradient>
                <path
                  style={{ strokeDashoffset: this.state.window5Dashoffset }}
                  ref="window5"
                  className="st0-color"
                  d="M17,17h277"
                />
                <path
                  style={{ strokeDashoffset: this.state.window4Dashoffset }}
                  ref="window4"
                  className="st0-color"
                  d="M17,23h277"
                />
                <path
                  style={{ strokeDashoffset: this.state.window3Dashoffset }}
                  ref="window3"
                  className="st0-color"
                  d="M17,29h277"
                />
                <path
                  style={{ strokeDashoffset: this.state.window2Dashoffset }}
                  ref="window2"
                  className="st0-color"
                  d="M17,35h277"
                />
                <path
                  style={{ strokeDashoffset: this.state.window1Dashoffset }}
                  ref="window1"
                  className="st0-color"
                  d="M17,41h277"
                />
                <path
                  style={{ strokeDashoffset: this.state.roofDashoffset }}
                  className="st1-color"
                  d="M5,39.5c0-4.1,0-6.7,0-7.8C5,28,5,22.4,5,15C5,9.5,9.5,5,15,5h280c5.5,0,10,4.5,10,10v25"
                />
              </svg>
            </AbsoluteDiv>
          </Flex>
          <Flex
            pt={["5px", "5px", "0px"]}
            pl={["0px", "15px", "15px", "0px"]}
            alignItems={["center", "flex-start", "flex-start", "center"]}
            justifyContent="space-between"
            flexDirection="column"
            minWidth={["80px", "80px", "100px"]}
          >
            <Flex
              alignItems={["center", "flex-start", "flex-start", "center"]}
              display={["flex"]}
              position="relative"
            >
              <NunitoSans
                lineHeight={1}
                fontSize={["14px", "24px"]}
                fontWeight={700}
                color="darkText"
              >
                {this.state.companiesActiveForRender.toFixed(0)}
              </NunitoSans>
              <AbsoluteDiv width="200%" minWidth="50px" top="0px" left="100%">
                <NunitoSans
                  pl="2px"
                  fontSize={["6px", "7px"]}
                  fontWeight={700}
                  color="#C3C3C3"
                >
                  {"/ "}
                  {this.state.companiesTotalForRender.toFixed(0)}
                </NunitoSans>
              </AbsoluteDiv>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default (CompanyInfographic = connect(
  state => ({
    browser: state.browser
  }),
  null
)(CompanyInfographic));
