import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarUsers } from "@fortawesome/pro-solid-svg-icons";

import { NunitoSans, NunitoSansSemiBold } from "../common/typography";
import { Flex } from "../common/flexbox";
import { DashboardSmallCTA } from "../common/forms";
import { setUpgradeModalOpen } from "../../actions/rule";

export class ContactUsModal extends Component {
  _handleCloseRequest = () => {
    this.props.setUpgradeModalOpen(false);
  };

  _handleBooking = () => {
    window.open(
      "https://meetings.hubspot.com/bill-portin/unlock-the-full-potential-of-kickpost-",
      "_blank"
    );
    this._handleCloseRequest();
  };

  render() {
    const { upgradeModalOpen, browser } = this.props;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: browser.lessThan.large ? "50%" : "800px",
        minHeight: "342px",
        zIndex: 16777271,
        overflow: "auto",
        paddingLeft: "40px",
        paddingRight: "40px",
        border: "1px solid #707070",
        borderRadius: "10px",
        background: "#F8F8F8",
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88",
      },
    };

    return (
      <Modal
        isOpen={upgradeModalOpen}
        onRequestClose={() => this._handleCloseRequest()}
        style={customStyles}
        contentLabel={`Contact us Modal`}
        ariaHideApp={false}
      >
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <NunitoSansSemiBold
            fontSize="14px"
            color="#428BF4"
            textAlign="center"
            style={{ marginTop: "20px", marginBottom: "25px" }}
          >
            READY TO UPGRADE?
          </NunitoSansSemiBold>

          <Flex
            width="500px"
            flexDirection="column"
            justifyContent="center"
            style={{
              padding: "40px",
              border: "1px solid #979797",
              background: "white",
              marginBottom: "20px",
            }}
          >
            <FontAwesomeIcon icon={faCalendarUsers} color="#428BF4" size="4x" />

            <NunitoSansSemiBold
              fontSize="24px"
              color="#428BF4"
              textAlign="center"
              style={{ marginTop: "20px" }}
            >
              Maximize KickPost's Potential!
            </NunitoSansSemiBold>

            <NunitoSansSemiBold
              fontSize="18px"
              color="#000000"
              textAlign="center"
              style={{ marginTop: "30px", marginBottom: "10px" }}
            >
              Schedule a personalized session to unlock the full range of
              benefits.
            </NunitoSansSemiBold>

            <NunitoSansSemiBold
              fontSize="18px"
              color="#000000"
              textAlign="center"
              style={{ marginBottom: "30px" }}
            >
              Book your slot now and let's embark on this exciting journey
              together!
            </NunitoSansSemiBold>

            <DashboardSmallCTA
              width="100%"
              fontSize="16px"
              color="#428BF4"
              mt="30px"
              onClick={this._handleBooking}
              reversed={true}
            >
              Book Now!
            </DashboardSmallCTA>
          </Flex>
        </Flex>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    browser: state.browser,
    upgradeModalOpen: state.rule.upgradeModalOpen,
  }),
  (dispatch) => ({
    setUpgradeModalOpen: bindActionCreators(setUpgradeModalOpen, dispatch),
  })
)(ContactUsModal);
