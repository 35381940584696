import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import { history } from "../../../store";
//utils
import { getUser } from "../../../utils/auth";
import { filterActiveDepartments } from "../../../utils/helpers";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { setDepartment, setDepartmentMembers } from "../../../actions/departments";
import { searchDepartments, searchConnectionUsers } from "../../../actions/search";
//components
import { InputStyled,  DashboardSmallCTA } from "../../common/forms";
import { NunitoSans } from "../../common/typography";
import { Flex, Box, ClickCursorFlex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { CreateDepartmentCTA, EditDepartmentCTA } from "../../common/ui";
import { DepartmentRowGroup } from "../../common/rows";
import { BackButton } from "../../common/backbutton";
import InfiniteScroll from "react-infinite-scroller";



export class ManageDepartmentsMain extends Component {
  state = { 
    search: null,
    hasMore: true,
    loadedDepartments: [],
    page: 1,
    editDepartmentMode: false,
    DMG:"src/components/dashboard/departments/index.js"
   };

componentDidMount(){
  this._fetchDependencies();
}


_fetchDependencies = async () => {
    const { fetchDepartments, departments, match } = this.props;
    if (
      (getUser() && getUser().company_admin === false) ||
      getUser().company_id !== match.params.id
    ) {
      history.push("/dashboard");
    }

    
      fetchDepartments({
        company_id: getUser().company_id,
        status: "active",
        offset: 0
      }).then(res => {
        // console.log("src/components/dashboard/departments/index.js ",this.props)
        let depts = this.props.departments;
        this.setState({loadedDepartments: depts, hasMore: depts.length < 15 ? false : true});
      });
    
  }

  // componentWillUnmount(){
  //   this.props.setDepartment(null);
    
  // }

  // componentDidUpdate(prevProps){
  //   if(!_.isEqual(prevProps.userRows, this.props.userRows)){
  //     console.log(this.props.userRows, "USERS UPDATED")
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {DMG} = this.state;

    if(nextProps.departments.length && this.props.departments.length && nextProps.departments.length > this.props.departments.length  && !this.state.search){
      this.props.fetchDepartments({ company_id: getUser().company_id, status: 'active', offset: 0 })
      .then(res => {
      
      
        console.log(DMG,"nextProps",nextProps)
        console.log(DMG,"this.state",this.state)
        console.log(DMG,"this.props",this.props)
        console.log(DMG,"res",res)

        let depts = filterActiveDepartments(this.props.departments);      
        this.setState({loadedDepartments: depts, hasMore: depts.length < 15 ? false : true, page: 1});
      
        // this.setState({loadedDepartments: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
      });
    }

    if (nextProps.departmentResults !== this.props.departmentResults) {
      return this._fetchDepartments(nextProps.departmentResults);
    }
  }

  _removeDepartment = async id => {
    const { fetchDepartments, updateDepartment } = this.props;
    await updateDepartment(id, { status: "inactive"});
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active",
      offset: 0
    }).then(res => {
      let depts = filterActiveDepartments(this.props.departments);      
      this.setState({loadedDepartments: depts, hasMore: depts.length < 15 ? false : true, page: 1});
//      this.setState({loadedDepartments: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
    });
  };

  _createDepartment = async data => {
    const { createDepartment, setDepartment, fetchDepartments, resetForm, selectedRows, notifyError, updateUser } = this.props;
    
    console.log("this.state.departmentResults ", this.state.departmentResults) 

    if(selectedRows.length < 2) return notifyError("Minimum 2 people required to create a department.");

    const departments = await fetchDepartments({
      company_id: getUser().company_id,
      status: "active"
    });

    if(departments.payload.map(dept=>dept.name.toLowerCase().trim()).includes(data.name.toLowerCase().trim())) return notifyError("A department with this name already exists.");
    
    const respond = await createDepartment({
      ...data,
      ...{
        company_id: getUser().company_id,
        user_id: getUser().id
      }
    });

    const newDepartment = respond.payload;
    

    const memberIds = selectedRows.map(user=>user.id);

   

    for(let i =0; i<memberIds.length; i++){
      
        await updateUser(memberIds[i], {
          departments:  _.omit(newDepartment, ['status', 'user_id'])
        }).catch(err=>console.log(err))
    }

    setDepartment(null);
    resetForm("create-department");
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active",
      offset: 0
    }).then(res => {
      this.setState({loadedDepartments: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
    });

    
  };

  _editDepartment = async department => {
    const { setDepartment } = this.props;
    this.setState({editDepartmentMode: true})
    setDepartment(department);
  };

  _fetchDepartments = departmentResults => {
    const { fetchDepartments } = this.props;
    
    fetchDepartments({
      company_id: getUser().company_id,
      ids: departmentResults,
      searchResultsEmpty: Boolean(this.state.search && (!departmentResults || !departmentResults.length)),
      status: "active"
    });
  };

  _updateDepartment = async data => {
    const {
      departments,
      theDepartment,
      departmentMembers,
      setDepartmentMembers,
      selectedRows,
      fetchDepartments,
      resetForm,
      setDepartment,
      updateDepartment,
      updateUser
    } = this.props;

    console.log(departmentMembers, selectedRows, "KOOL");

    let newMemberIds = selectedRows.filter(user=>!departmentMembers.map(member=>member.id).includes(user.id)).map(user=>user.id);
    let oldMemberIds = departmentMembers.filter(member=>!selectedRows.map(user=>user.id).includes(member.id)).map(user=>user.id);

    const newAndOldIds = newMemberIds.concat(oldMemberIds);

    console.log(newAndOldIds, theDepartment, "IDS")

    if(newAndOldIds && newAndOldIds.length){
      for(let i=0; i<newAndOldIds.length; i++){
        await updateUser(newAndOldIds[i], {
          departments: theDepartment
        })
      }
      
    }

    

    

    

    await updateDepartment(_.findWhere(departments, { id: theDepartment.id }).id, {
      name: data.name
    });
    setDepartment(null);
    resetForm("edit-department");
    fetchDepartments({
      company_id: getUser().company_id,
      status: "active",
      offset: 0
    }).then(res => {
      this.setState({editDepartmentMode: false, loadedDepartments: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
    });
    //it takes Algolia a while to update, so we wait for a second to get updated results from Algolia
    
    this.props.searchConnectionUsers('', null, true, false, [], true)
   
  };

  _clearSearch = () => {
    

    this.setState({ search:""});

    this._search("");
  };

  _loadMore = pageNo => {
    const {fetchDepartments} = this.props;
    const {loadedDepartments, hasMore, page, DMG} = this.state;    

    fetchDepartments({ company_id: getUser().company_id, status: 'active', offset: pageNo*15 })
    .then(res => {
      console.log(DMG,"this.state",this.state)
      console.log(DMG,"this.props",this.props)
      console.log(DMG,"res",res)
      console.log(DMG,"hasMore",hasMore)
      console.log(DMG,"page",page)
      console.log(DMG,"loadedDepartments",loadedDepartments)
      if(res && res.payload && res.payload.length === 0){
        this.setState({hasMore: false})

      }else if(res && res.payload && hasMore===true){
        this.setState({loadedDepartments: loadedDepartments.concat(res.payload)});        

        if(res.payload.length < 15){
          this.setState({hasMore: false})
        }else{
          this.setState({page: page + 1})

        }
      } else {
        this.setState({hasMore: false});
        console.log(DMG,"hasMore===false or empty response from the server");
      }
      
    } )

  }

  _search = async input => {
    const { fetchDepartments, searchDepartments } = this.props;
    console.log(getUser().company_id )

    if (input.length === 0) {
      fetchDepartments({
        company_id: getUser().company_id,
        status: "active",
        offset: 0
      }).then(res => {
        this.setState({loadedDepartments: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
      });
    } else {
      searchDepartments(getUser().company_id, input);
    }
  };

  _updateSearch = input => {
    this.setState({ search: input });
    this._search(input);
  };

  _handleFocus = () =>{
    const { theDepartment, setDepartment } = this.props;
    if (theDepartment) return;

    setDepartment({
      id: 'new_department',
      total_employee_count: 0
    })

  }

  render() {
    const { browser, departments, theDepartment, setDepartment } = this.props;
    const { search, hasMore, page, loadedDepartments, editDepartmentMode } = this.state;

   
    return (
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          
          <Flex
            mt="1px"
            mb="25px"
            mx={["0px"]}
            height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <InputStyled
                placeholder="Search Departments"
                onChange={e => this._updateSearch(e.target.value)}
                value={search || ""}
                innerRef={input => (this.searchInput = input)}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"11px"}
                pt={["3px"]}
                px={["5px"]}
                mt={["0px"]}
                height={["49px"]}
                fontWeight={400}
                fontSize={[24]}
                lineHeight={1.5}
                color={"#979797"}
                borderBottom={[0]}
              />
              <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                <ClickCursorFlex
                  height={["100%", "100%", "100%"]}
                  alignItems={["center"]}
                  onClick={() => {this._clearSearch();}}
                  justifyContent={["center"]}
                >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
            </Flex>
          </Flex>
          {browser.lessThan.large || true ? (
            <Flex pb="40px" flexDirection="column">
              {theDepartment && editDepartmentMode ? (
                <EditDepartmentCTA
                  setDepartment={() => {
                    this.setState({editDepartmentMode: false})
                    return setDepartment(null);
                  }}
                  initialValues={{
                    name: _.findWhere(departments, { id: theDepartment.id }).name
                  }}
                  onSubmit={this._updateDepartment}
                />
              ) : (
                <CreateDepartmentCTA handleFocus={this._handleFocus} onSubmit={this._createDepartment} />
              )}
            </Flex>
          ) : null}
          {(search && search.length &&  _.filter(departments, d => d.status === "active").length) || (!search && loadedDepartments.length) ? (
            <div style={{height: "79vh", overflowY: "auto", overflowX: "hidden", "paddingRight": "20px"}}>
             <InfiniteScroll 
                pageStart = { 0 }
                loadMore = { a => {
                  if(a<=page){
                    return this._loadMore(page);
                  }
                }   } 
                hasMore={hasMore && !search && loadedDepartments.length > 0}
                // hasMore={false}
                loader={<div className="loader"></div>}
                useWindow = { false }
                threshold = { 150 } 
                >

            <DepartmentRowGroup
              hideFirstLastDash={true}
              departments={filterActiveDepartments(search ? departments : loadedDepartments)}
              mobile={browser.lessThan.small}
              editClickHandler={this._editDepartment}
              removeClickHandler={this._removeDepartment}
              editDepartmentMode={editDepartmentMode}
            />
            </InfiniteScroll>
            {/* {hasMore && !search && loadedDepartments.length > 0 ? 
          <Flex width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
          >
            <Box>
              <DashboardSmallCTA                    
                    onClick={() => this._loadMore(page)}
                    mt={["10px", 0]}
                    mb={["24px", 0]}
                    mr={[0, "23px"]}
                    width={["140px","400px"]}
                    
                  >
                    LOAD MORE
              </DashboardSmallCTA>
              </Box>
              </Flex>
              
              : 
                null} */}
                </div>
          ) : (
            <Flex justifyContent="center" alignItems="center" height={175}>
              <Box>
                <NunitoSans
                  pt={["3px", "4px", "7px", "5px"]}
                  fontSize={22}
                  fontStyle="italic"
                  color="darkGray"
                >
                  {search ? "No search results" : "There have been no departments added."}
                </NunitoSans>
              </Box>
            </Flex>
          )}
        </Flex>
        
      </Flex>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
    theDepartment: state.departments.department,
    departmentMembers: state.departments.departmentMembers,
    selectedRows: state.post.selectedRows,
    userRows: state.search.userSearchRows,
    departments: state.resources.collection.department || [],
    departmentResults: state.search.departmentSearchResults || []
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createDepartment: bindActionCreators(actions.createDepartment, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchDepartments: bindActionCreators(searchDepartments, dispatch),
    setDepartment: bindActionCreators(setDepartment, dispatch),
    setDepartmentMembers: bindActionCreators(setDepartmentMembers, dispatch),
    updateDepartment: bindActionCreators(actions.patchDepartment, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    redirect: bindActionCreators(push, dispatch),
  })
)(ManageDepartmentsMain);

export const ManageDepartmentsNav = ({ ...props }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        DEPARTMENTS
      </NunitoSans>
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);
