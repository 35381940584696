import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, reduxForm, Field } from "redux-form";
import { createNumberMask } from 'redux-form-input-masks';
import { push } from "react-router-redux";
import _ from "underscore";
import moment from "moment";
import { isEqual, sortBy, uniq } from "lodash";
import { CSVLink } from "react-csv";
//utils
import { getUser } from "../../../utils/auth";
import { success, error } from "../../../actions/alerts";
//actions
import { actions } from "../../../actions/resources";
import { setRowSelected } from "../../../actions/incentives";

//components
import { NunitoSans } from "../../common/typography";
import ConfirmModal from "../../common/confirmModal";
import currencies from "../../../constants/currency";
import { Flex, Box } from "../../common/flexbox";
import { DashboardSmallCTA } from "../../common/forms";
import { colors } from "../../../utils/theme";


export class ManageRewardsAccessory extends Component {
  state = {
    invoiceModalOpen: false,
    voidOpen: false,
    editOpen: false,
    voidInvOpen: false,
    distsaleHistory: [],
    invoiceRewardsDetail: null,
    invoiceSalesBackup: null,
    salesReps: [],
    processingInvoice: false,
    categories: [],
    deleteOpen: false
  };

  componentDidMount() {
    this._fetchDependencies();
  };


  _users = async (fetchUsers) => {
    return await fetchUsers({ company_id: getUser().company_id, status: 'active' });
  }

  _fetchDependencies = async () => {
    const { fetchUsers } = this.props;

    let users;
    this._users(fetchUsers)
      .then(() => {
        console.log("_fetchDependencies this.props", this.props);
        users = this.props.connections;
        console.log("_fetchDependencies users", users);//this is the raw connections returned from querying the PostgreSQL database connections table

        if (users) {
          let salesReps = sortBy(users.filter(user => user.sales_id), ['first_name', 'last_name']);
          this.setState({ salesReps });
        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });


    this._fetchCategories();

  }

  _fetchCategories = async () => {
    const categories = await fetch(`${process.env.REACT_APP_API_URL}/categories`).then(res => res.json());
    this.setState({ categories });
  }

  async componentDidUpdate(prevProps) {
    const { distsaleHistory } = this.state;

    let newFormValues = { ...this.props.rowSelected }

    if (!isEqual(prevProps.rowSelected, this.props.rowSelected)) {

      this.setState({ distsaleHistory: [], invoiceRewardsDetail: null, invoiceSalesBackup: null });
      
      if (this.props.rowSelected.type === 'distsales' && (!distsaleHistory.length || (distsaleHistory.length && distsaleHistory[0].sales_id !== this.props.rowSelected.id))) {

        const historyPayload = await fetch(`${process.env.REACT_APP_API_URL}/distsaleshistory/${this.props.rowSelected.id}`);
        const history = await historyPayload.json();
        this.setState({ distsaleHistory: history })
      }

      if (this.props.rowSelected.type === 'invoices') {
        this._getRewardDetail(this.props.rowSelected.incentive_id);
        this._getSalesBackup(this.props.rowSelected.incentive_id);
      }

      this.props.initialize({ ...newFormValues });
    }
  }

  _paidInvoice = async (id, incentive_id) => {
    const { updateInvoice, fetchInvoices, fetchRewards, setRowSelected, notifyError, notifySuccess, fetchNotificationCount } = this.props;

    this.setState({ processingInvoice: true });

    const invoice = await updateInvoice(id, { paid: true, status: 'closed', incentive_id });



    if (invoice.payload.id) {
      notifySuccess('Invoice processed successfully.');
    } else if (invoice.payload.payout_total) {
      notifyError(invoice.payload.error + `. ${(invoice.payload.payout_total - invoice.payload.balance) / 100} dollars short.`)
    } else {
      notifyError('An error occured. Please try again later.');
    }

    this.setState({ processingInvoice: false, invoiceModalOpen: false });

    let payload = getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin ? {} : { user_id: getUser().id, rewardsPage: true };
    fetchInvoices(payload);
    fetchRewards(payload);
    setRowSelected({});
    fetchNotificationCount({ user_id: getUser().id });
  }

  _voidInvoice = async (id, incentive_id) => {
    const { updateInvoice, fetchInvoices, fetchRewards, formValues, notifyError, setRowSelected, fetchNotificationCount } = this.props;
    if (!formValues.void_inv_note) return notifyError("REASON IS REQUIRED");
    await updateInvoice(id, { note: formValues.void_inv_note, status: 'void', voider_user_id: getUser().id, incentive_id });
    let payload = getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin ? {} : { user_id: getUser().id, rewardsPage: true };
    fetchInvoices(payload);
    fetchRewards(payload);
    this.setState({ voidInvOpen: false });
    setRowSelected({});
    fetchNotificationCount({ user_id: getUser().id })
  }

  _voidReward = async reward_id => {
    const { updateReward, fetchRewards, formValues, notifyError, setRowSelected } = this.props;
    if (!formValues.void_reward_note) return notifyError("REASON IS REQUIRED");
    if (formValues.void_reward_note.trim().length < 5) return notifyError("REASON IS TOO SHORT");
    await updateReward(reward_id, { status: "Void", note: formValues.void_reward_note, voider_user_id: getUser().id });

    let payload = getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin ? {} : { user_id: getUser().id, rewardsPage: true };
    fetchRewards(payload);
    this.setState({ voidOpen: false });
    setRowSelected({});
  }

  _getSalesBackup = async (incentive_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sales?incentive_id=${incentive_id}&user_id=${getUser().id}&invoiceSales=true`);
    let sales = await response.json();
    sales = sales.message ? [] : sales.map(sale => _.omit(sale, ["id", "company_id", "user_id"]));
    sales.length && sales.forEach(sale => {
      sale.created_at = moment(sale.created_at).utc().format("L");
      sale.invoice_date = moment(sale.invoice_date).utc().format("L");
      sale.total_value = parseFloat(sale.quantity) * parseFloat(sale.unit_sales_out);
    })
    this.setState({ invoiceSalesBackup: sales });

  }

  _getRewardDetail = async (incentive_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/rewards?incentive_id=${incentive_id}`);
    let rewards = await response.json();
    console.log(rewards)

    rewards = rewards.map(reward => _.omit(reward, ["id", "user_id", "sales_incentive_id", "vendor_company_id", "reward_key", "sponsor_company_id"]));
    rewards.forEach(reward => {
      reward.posted_date = reward.posted_date ? moment(reward.posted_date).utc().format("L") : '';
      reward.invoice_date = reward.invoice_date ? moment(reward.invoice_date).utc().format("L") : '';
      reward.reward_tier = (reward.type === 'onetoone' || reward.type === 'quickpay' || reward.value <= 0) ?
        '' :
        (reward.type === 'tier' || reward.type === 'increment') ?
          `Sell ${reward.reward_tier.units_from} - ${reward.reward_tier.units_to} AND Earn ${reward.reward_tier.reward}` :
          `SELL ${reward.reward_tier.sell_goal} and EARN ${reward.reward_tier.earn_amount}`;
    })
    this.setState({ invoiceRewardsDetail: rewards });

  }

  _updateDistsale = async () => {
    const { formValues, updateDistsale, fetchDistsales, setRowSelected, rowSelected, notifyError } = this.props;
    if (!formValues.order_number) return notifyError("ORDER NUMBER cannot be blank");
    if (!formValues.quantity || isNaN(+formValues.quantity)) return notifyError("QUANTITY should be a number");
    if (formValues.sales_amount && isNaN(+formValues.sales_amount)) return notifyError("$ should be a number");
    if (!formValues.edit_note) return notifyError("You should provide a reason of editing this distribution sale");





    let newData = {
      mnfr_pn: formValues.mnfr_pn,
      mnfr_name: formValues.mnfr_name,
      customer_name: formValues.customer_name,
      quantity: formValues.quantity,
      sales_amount: formValues.sales_amount,
      user_id: getUser().id
    }
    let payload = {
      newData: newData,
      prevData: { ..._.omit(rowSelected, ["type", "id", "created_at", "updated_at", "history"]), reason: formValues.edit_note || '', sales_id: rowSelected.id, edited_by: `${getUser().first_name} ${getUser().last_name}` },

    }

    await updateDistsale(rowSelected.id, payload);
    // console.log(updatedSale, rowSelected)
    // if(getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin){
    //   fetchSales({});

    // }else{
    //   fetchSales({user_id:getUser().id, rewardsPage: true});

    // }

    fetchDistsales({ company_id: getUser().company_id });
    this.setState({ editOpen: false });
    setRowSelected({});

  }

  _updateProduct = async () => {
    const { formValues, updateProduct, fetchProducts, setRowSelected, rowSelected, notifyError, systemSettings } = this.props;
    if (!formValues.description || !formValues.description.trim()) return notifyError("Description cannot be blank");
    if (!formValues.part_number || !formValues.part_number.trim()) return notifyError("Part Number cannot be blank");
    if (systemSettings.part_number_active && (!formValues.sku || !formValues.sku.trim())) return notifyError(`${systemSettings.part_number_name.toUpperCase()} can not be blank`);
    if (!formValues.edit_note || !formValues.edit_note.trim()) return notifyError("You should provide a reason of editing this product");

    let categories = [];

    formValues.category_one.trim() && categories.push(formValues.category_one.trim().toLowerCase());
    formValues.category_two.trim() && categories.push(formValues.category_two.trim().toLowerCase());
    formValues.category_three.trim() && categories.push(formValues.category_three.trim().toLowerCase());


    if (categories.length > 1 && uniq(categories).length !== categories.length) return notifyError("There are duplicate category names.");



    console.log(categories)

    let newData = {
      sku: formValues.sku.trim(),
      part_number: formValues.part_number.trim(),
      description: formValues.description.trim(),
      category_one: formValues.category_one.trim(),
      category_two: formValues.category_two.trim(),
      category_three: formValues.category_three.trim(),

    }
    // let payload = {
    //   newData: newData

    // }

    let updatedProduct = await updateProduct(rowSelected.id, newData);
    // console.log(updatedSale, rowSelected)
    // if(getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin){
    //   fetchProducts({});

    // }else{
    //   fetchProducts({user_id:getUser().id, rewardsPage: true});

    // }
    fetchProducts({});
    this.setState({ editOpen: false })
    setRowSelected({})

  }

  _deleteProduct = async () => {
    const { formValues, updateProduct, fetchProducts, setRowSelected, rowSelected, notifyError, systemSettings } = this.props;

    if (!formValues.delete_note || !formValues.delete_note.trim()) return notifyError("You should provide a reason of deleting this product");


    await updateProduct(rowSelected.id, { status: 'delete' });

    fetchProducts({});
    this.setState({ deleteOpen: false })
    setRowSelected({})
  }

  downloadInvoice = async filename => {
    // filename='kickpost.pdf';
    return window.open(filename, "_blank");
    // let response = await fetch(`${process.env.REACT_APP_API_URL}/s3/signed-url?filename=${filename}&filetype=application/pdf&content_type=invoice`);
    // response = await response.json();
    // if (response.signed_url) {
    //   win.location.href = response.signed_url;
    //   win.focus();
    // }
  }

  _handleViewInvoice = incentive_id => {
    const { redirect } = this.props;
    redirect(`/dashboard/rewards?target=invoices&incentiveId=${incentive_id}`)
  }

  currencyMask = (decimal = 2, negative = false) => createNumberMask({
    prefix: currencies[this.props.systemSettings.currency].symbol,
    // suffix: ' per item',
    decimalPlaces: decimal,
    allowNegative: negative,
    locale: 'en-US',
  })

  numberMask = createNumberMask({
    // prefix: '$ ',
    // suffix: ' per item',
    // decimalPlaces: 2,
    locale: 'en-US',
  })

  currencyParser = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.props.systemSettings.currency }).format(number)
  }

  _invoiceCloseRequest = () => {
    const { processingInvoice } = this.state;

    if (processingInvoice) return;

    this.setState({ processingInvoice: false, invoiceModalOpen: false })
  }

  render() {

    const {
      rowSelected,
      systemSettings
    } = this.props;
    const { saleHistory, distsaleHistory, invoiceRewardsDetail, invoiceSalesBackup, salesReps, processingInvoice, invoiceModalOpen, categories, deleteOpen } = this.state;

    const tableStructure = {
      invoices: [
        {
          name: "INVOICE #",
          key: "invoice_number"
        },
        {
          name: "INV. DATE",
          key: "invoice_date"
        },
        {
          name: "DUE DATE",
          key: "due_on"
        },
        {
          name: "INVOICE STATUS",
          key: "invoice_status"
        },
        {
          name: "INVOICE AGE",
          key: "invoice_age"
        },
        {
          name: "INCENTIVE TITLE",
          key: "incentive_title"
        },
        {
          name: "INCENTIVE ID",
          key: "incentive_id"
        },
        {
          name: "BILL TO",
          key: "bill_to"
        },
        {
          name: "INVOICE TOTAL",
          key: "reward",
          type: "currency"
        }
      ],

      distsales: [
        {
          name: "ORDER NUMBER",
          key: "order_number"
        },
        {
          name: "DATE",
          key: "date"
        },
        {
          name: "MFR PART NUMBER",
          key: "mnfr_pn",
          editable: false
        },
        {
          name: "MFR NAME",
          key: "mnfr_name",
          editable: true
        },
        {
          name: "RESELLER NAME",
          key: "reseller_name",
          editable: true,
        },
        {
          name: "QUANTITY",
          key: "quantity",
          editable: true,
          type: "number"
        },
        {
          name: "SALES AMOUNT",
          key: "sales_amount",
          editable: true,
          type: "currency"
        },
        {
          name: "CUSTOMER NAME",
          key: "customer_name",
          editable: true
        }
      ],

      rewards: [
        {
          name: "REWARD ID",
          key: "reward_id"
        },
        {
          name: "STATUS",
          key: "status"
        },
        {
          name: "DATE",
          key: "posted_date"
        },
        {
          name: "INCENTIVE ID",
          key: "incentive_id"
        },
        {
          name: "INCENTIVE TITLE",
          key: "incentive_title"
        },
        {
          name: "SPONSOR NAME",
          key: "sponsor_company_name"
        },
        {
          name: "VENDOR NAME",
          key: "vendor_company_name"
        },
        {
          name: "REP NAME",
          key: "rep_name"
        },
        {
          name: "APPLIED REP FEE (%)",
          key: "sales_rep_fee"
        },
        {
          name: "REWARD VALUE",
          key: "total_value",
          type: "currency"
        },
        {
          name: "SPLIT PERCENT",
          key: "split_percent"
        },
        {
          name: "EARNED",
          key: "value",
          type: "currency"
        },
        {
          name: "NOTE",
          key: "note"
        }

      ],

      products: [
        {
          name: "PARTNER ID",
          key: "partner_id"
        },
        {
          name: systemSettings.part_number_name,
          key: "sku",
          editable: true
        },
        {
          name: "DESCRIPTION",
          key: "description",
          editable: true
        },
        {
          name: "PART NUMBER",
          key: "part_number",
          editable: true
        },
        {
          name: "CATEGORY ONE",
          key: "category_one",
          editable: true
        },
        {
          name: "CATEGORY TWO",
          key: "category_two",
          editable: true
        },
        {
          name: "CATEGORY THREE",
          key: "category_three",
          editable: true
        },
        {
          name: "DATE",
          key: "created_at"
        }
      ]
    }

    return (
      <Flex flexDirection="column">


        {rowSelected && rowSelected.type
          ? <>
            <form id="rewardsAction-form">
              <Box height="40px" />
              <NunitoSans
                fontSize="22px"
                fontWeight="bold"
                color="#000000"
                style={{ width: "100%", textAlign: "center", marginBottom: "38px" }}
              >

                {rowSelected.type === "invoices" ? "INVOICE DETAIL" : rowSelected.type === "distsales" ? "DISTY SALES DETAIL" :
                  rowSelected.type === "products" ? "PRODUCT DETAIL" : "REWARDS DETAIL"}
              </NunitoSans>
              {tableStructure[rowSelected.type].map((item, index) => {
                if (item.key === 'invoice_age' && (rowSelected.paid || rowSelected.status === 'void')) return;
                if (item.key === 'note' && rowSelected.status !== 'Void') return;
                if (item.key === 'sales_rep_fee' && parseFloat(rowSelected[item.key]) === 0) return;

                if (!systemSettings.part_number_active && item.key === "sku") return;

                return <Flex
                  alignItems="center"
                  style={{ width: "100%", backgroundColor: index % 2 === 1 ? "transparent" : "#FAFAFA", paddingBottom: '7px', paddingTop: '7px' }}
                >
                  <NunitoSans
                    fontSize="14px"
                    fontWeight="bold"
                    color="#000000"
                    style={{ width: "50%", marginLeft: "35px" }}
                  >
                    {item.name}
                  </NunitoSans>
                  {(rowSelected.type === 'distsales' || rowSelected.type === 'products') && this.state.editOpen && item.editable ?
                    item.key === 'rep_name'
                      ?
                      <Field
                        name={item.key}
                        component="select"
                        style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: "#428BF4", border: "1px solid #428BF4", paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                      >
                        {
                          salesReps.length
                            ?
                            salesReps.map(user => <option key={user.id} value={`${user.first_name} ${user.last_name}~${user.sales_id}`}>{`${user.first_name} ${user.last_name}-${user.sales_id}`}</option>)
                            :
                            null
                        }
                        {
                          !rowSelected.rep_id || !salesReps.map(user => user.sales_id).includes(rowSelected.rep_id)
                            ?
                            <option key={rowSelected.id+index+item.key} value={`${rowSelected[item.key]}~${rowSelected.rep_id}`}>{`${rowSelected[item.key]}-${rowSelected.rep_id}`}</option>
                            :
                            null
                        }
                      </Field>
                      :
                      ["category_one", "category_two", "category_three"].includes(item.key)
                        ?
                        <Field
                          name={item.key}
                          component="select"
                          style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: colors.buttonCustom, border: "1px solid", borderColor: colors.buttonCustom, paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                        >
                          {
                            categories.map((category, i) => <option key={category + i} value={category}  >{category}</option>)
                          }
                        </Field>
                        :
                        <Field
                          name={item.key}
                          component="input"
                          style={{ width: "50%", fontSize: "14px", fontFamily: "NunitoSans", fontWeight: "bold", color: "#428BF4", border: "1px solid #428BF4", paddingLeft: "3px", paddingTop: "3px", paddingBottom: "3px" }}
                          {...item.type === 'currency' ? this.currencyMask() : item.type === "number" ? this.numberMask : {}}
                        />
                    :
                    <NunitoSans
                      fontSize="14px"
                      fontWeight="bold"
                      color="#000000"
                      style={{ width: "50%" }}
                    >
                      {item.key === "invoice_date" || item.key === "due_on" ? moment.unix(rowSelected[item.key]).format("MM/DD/YYYY") : (item.key === "date" && rowSelected[item.key] !== null) || item.key === "posted_date" || item.key === "created_at" ? moment(rowSelected[item.key], moment.ISO_8601).add(item.key === "created_at" ? 0 : 1, 'days').format("MM/DD/YYYY") : (item.type === 'number' && rowSelected[item.key] !== null) ? numberParser(parseFloat(rowSelected[item.key])) : (item.type === "currency" && rowSelected[item.key] !== null) ? this.currencyParser(parseFloat(rowSelected[item.key])) : rowSelected[item.key]}
                    </NunitoSans>
                  }

                </Flex>
              })}

              {rowSelected.type === "invoices" && rowSelected.status !== 'void' ?
                <>
                  <Flex
                    style={{ marginTop: "20px" }}
                  >
                    {
                      this.state.voidInvOpen ?
                        <Flex
                          flexDirection="column"
                          style={{ width: "100%", border: "1px solid #FE4A49", borderRadius: "8px", boxShadow: "-4px 3px 6px #00000029", padding: "15px 20px" }}
                        >
                          <NunitoSans
                            fontSize="13px"
                            fontWeight="bolder"
                            color="#FE4A49"
                            style={{ width: "100%", textAlign: "center", marginBottom: "15px" }}
                          >
                            VOID INVOICE
                          </NunitoSans>

                          <NunitoSans
                            fontSize="15px"
                            fontWeight="bold"
                            color="#000000"
                          >
                            Why are you voiding this invoice? <span style={{ color: "#FE4A49" }}>*</span>
                          </NunitoSans>

                          <Field
                            name="void_inv_note"
                            component="textarea"
                            placeholder="Add a description..."
                            rows="6"
                            style={{ padding: "2.5px 2.5px", border: "1px dashed #A3A99E", borderRadius: "6px", width: "100%" }}
                          />


                          <Flex
                            justifyContent="space-between"
                            style={{ marginTop: "20px" }}
                          >
                            <DashboardSmallCTA
                              onClick={() => this._voidInvoice(rowSelected.id, rowSelected.incentive_id)}
                              fontSize="13px"
                              color="#FE4A49"
                              style={{ width: "45%" }}
                            >
                              CONFIRM CHANGE (VOID INVOICE)
                            </DashboardSmallCTA>
                            <DashboardSmallCTA
                              onClick={() => this.setState({ voidInvOpen: false })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%", marginRight: "-5px" }}
                            >
                              CANCEL
                            </DashboardSmallCTA>
                          </Flex>

                        </Flex>

                        :
                        null
                    }
                    <ConfirmModal
                      modalOpen={invoiceModalOpen}
                      processing={processingInvoice}
                      message="Are you sure you wish to process this invoice?"
                      handleCloseRequest={this._invoiceCloseRequest}
                      handleConfirm={() => this._paidInvoice(rowSelected.id, rowSelected.incentive_id)}
                    />
                  </Flex>
                  {invoiceRewardsDetail && invoiceSalesBackup
                    ?
                    <Flex>
                      <DashboardSmallCTA
                        mt="15px"
                        fontSize="13px"
                        color="#428BF4"
                        width="45%"
                      >


                        <CSVLink
                          filename={`${rowSelected.invoice_number}_rewards_detail.csv`}
                          data={invoiceRewardsDetail}

                          headers={[{ label: 'REWARD ID', key: 'reward_id' }, { label: "REWARD STATUS", key: 'status' }, { label: 'INCENTIVE TYPE', key: 'type' }, { label: 'POSTED DATE', key: 'posted_date' }, { label: 'INCENTIVE ID', key: 'incentive_id' }, { label: 'INCENTIVE TITLE', key: 'incentive_title' }, { label: 'INVOICE DATE', key: 'invoice_date' }, { label: 'INVOICE NUMBER', key: 'invoice_number' }, { label: 'PART NUMBER', key: 'part_number' }, { label: 'QUANTITY', key: 'quantity' }, { label: 'SALES OUT', key: 'sales_out' }, { label: 'REWARD VALUE', key: 'value' }, { label: 'REP NAME', key: 'rep_name' }, { label: 'REP COMPANY NAME', key: 'vendor_company_name' }, { label: 'REWARD TIER', key: 'reward_tier' }, { label: 'CAPPED', key: 'capped' }]}
                        >
                          REWARD DETAIL
                        </CSVLink>



                      </DashboardSmallCTA>
                      <DashboardSmallCTA
                        mt="15px"
                        fontSize="13px"
                        color="#428BF4"
                        width="45%"
                        style={{ marginLeft: "47px" }}
                        justifyContent="space-between"

                      >
                        <CSVLink
                          filename={`${rowSelected.invoice_number}_sales_backup.csv`}
                          data={invoiceSalesBackup}

                          headers={[{ label: 'SALES KEY', key: 'sales_key' }, { label: "CREATED AT", key: 'created_at' }, { label: 'INVOICE DATE', key: 'invoice_date' }, { label: 'INVOICE NUMBER', key: 'invoice_number' }, { label: 'MFR NAME', key: 'mfr_name' }, { label: 'MFG NO', key: 'mnfg_pn' }, { label: 'QUANTITY', key: 'quantity' }, { label: 'CUSTOMER NAME', key: 'customer_name' }, { label: 'REP NAME', key: 'rep_name' }, { label: 'REP ID', key: 'rep_id' }, { label: 'REP COMPANY NAME', key: 'company_name' }, { label: 'COST PER UNIT', key: 'cost_per_unit' }, { label: 'UNIT SALES OUT', key: 'unit_sales_out' }, { label: 'TOTAL VALUE', key: 'total_value' }]}
                        >
                          SALES BACKUP
                        </CSVLink>
                      </DashboardSmallCTA>
                    </Flex>
                    :
                    null
                  }
                  <Flex>
                    <DashboardSmallCTA
                      mt="15px"
                      fontSize="13px"
                      color="#428BF4"
                      width="45%"
                      onClick={() => this.downloadInvoice(rowSelected.invoice_pdf)}
                    >
                      INVOICE (pdf)
                    </DashboardSmallCTA>
                    <DashboardSmallCTA
                      mt="15px"
                      fontSize="13px"
                      color="#428BF4"
                      width="45%"
                      style={{ marginLeft: "47px" }}
                      onClick={() => window.open(rowSelected.hosted_invoice_url, "_blank")}
                    >
                      PAY INVOICE
                    </DashboardSmallCTA>
                    {/* <DashboardSmallCTA
           mt="15px"
           fontSize="13px"
           color="#428BF4"
           width="45%"
           style={{marginLeft:"47px"}}
           justifyContent="space-between"
           
           >
             REWARD SUMMARY
           </DashboardSmallCTA> */}
                  </Flex>

                </>
                :
                rowSelected.type === "rewards" ?
                  <>
                    {/* <Flex
         justifyContent="space-between"
         style={{marginTop:"20px"}}
         >
           <DashboardSmallCTA
           fontSize="13px"
           color="#428BF4"
           width="45%"
           >
             VIEW INVOICE
           </DashboardSmallCTA>
           <DashboardSmallCTA
           fontSize="13px"
           color="#428BF4"
           width="45%"
           style={{marginLeft:"28px"}}
           >
             BACKUP SALES DATA
           </DashboardSmallCTA>
         </Flex> */}
                    <Flex
                      style={{ marginTop: "18px" }}
                    >
                      {this.state.voidOpen ?
                        <Flex
                          flexDirection="column"
                          style={{ width: "100%", border: "1px solid #FE4A49", borderRadius: "8px", boxShadow: "-4px 3px 6px #00000029", padding: "15px 20px" }}
                        >
                          <NunitoSans
                            fontSize="13px"
                            fontWeight="bolder"
                            color="#FE4A49"
                            style={{ width: "100%", textAlign: "center", marginBottom: "15px" }}
                          >
                            VOID REWARD
                          </NunitoSans>

                          <NunitoSans
                            fontSize="15px"
                            fontWeight="bold"
                            color="#000000"
                          >
                            Why are you voiding this reward? <span style={{ color: "#FE4A49" }}>*</span>
                          </NunitoSans>

                          <Field
                            name="void_reward_note"
                            component="textarea"
                            placeholder="Add a description..."
                            rows="6"
                            style={{ padding: "2.5px 2.5px", border: "1px dashed #A3A99E", borderRadius: "6px", width: "100%" }}
                          />


                          <Flex
                            justifyContent="space-between"
                            style={{ marginTop: "20px" }}
                          >
                            <DashboardSmallCTA
                              onClick={() => this._voidReward(rowSelected.id)}
                              fontSize="13px"
                              color="#FE4A49"
                              style={{ width: "45%" }}
                            >
                              CONFIRM CHANGE (VOID REWARD)
                            </DashboardSmallCTA>
                            <DashboardSmallCTA
                              onClick={() => this.setState({ voidOpen: false })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%", marginRight: "-5px" }}
                            >
                              CANCEL
                            </DashboardSmallCTA>
                          </Flex>

                        </Flex>
                        :
                        getUser().company_admin
                          ?
                          <Flex
                            justifyContent="space-between"
                            style={{ marginTop: "20px", width: '100%' }}
                          >
                            {getUser().company_admin && (rowSelected.status === "Pending" || rowSelected.status === "Paid")
                              ?
                              <DashboardSmallCTA
                                fontSize="13px"
                                color="#428BF4"
                                width="45%"
                                onClick={() => this._handleViewInvoice(rowSelected.incentive_id)}
                              >
                                VIEW INVOICE
                              </DashboardSmallCTA>
                              :
                              null
                            }
                            {rowSelected.status === 'Projected' && getUser().company_admin && (getUser().company_id === rowSelected.sponsor_company_id || getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)
                              ?
                              <DashboardSmallCTA
                                onClick={() => this.setState({ voidOpen: true })}
                                fontSize="13px"
                                color="#FE4A49"
                                width="45%"
                              >
                                VOID REWARD
                              </DashboardSmallCTA>
                              :
                              null
                            }
                          </Flex>
                          :
                          null
                      }

                    </Flex>
                  </>
                  :
                  rowSelected.type === "distsales" || rowSelected.type === "products" ?
                    <Flex
                      style={{ marginTop: "27px" }}
                    >
                      {this.state.editOpen || deleteOpen ?
                        <Flex
                          flexDirection="column"
                          style={{ width: "100%", border: "1px solid #428BF4", borderRadius: "8px", boxShadow: "-4px 3px 6px #00000029", padding: "15px 20px" }}
                        >
                          <NunitoSans
                            fontSize="13px"
                            fontWeight="bolder"
                            color="#428BF4"
                            style={{ width: "100%", textAlign: "center", marginBottom: "15px" }}
                          >
                            {` ${deleteOpen ? 'DELETE' : 'EDIT'} ${(rowSelected.type === 'distsales' ? "disty sales" : rowSelected.type).toUpperCase()} DATA`}
                          </NunitoSans>

                          <NunitoSans
                            fontSize="15px"
                            fontWeight="bold"
                            color="#000000"
                          >
                            {`Why are you ${deleteOpen ? 'deleting' : 'editing'} this ${rowSelected.type === 'distsales' ? "disty sales" : rowSelected.type} data?`} <span style={{ color: "#FE4A49" }}>*</span>
                          </NunitoSans>

                          <Field
                            name={deleteOpen ? "delete_note" : "edit_note"}
                            component="textarea"
                            placeholder="Add a reason..."
                            rows="6"
                            style={{ padding: "2.5px 2.5px", border: "1px dashed #A3A99E", borderRadius: "6px", width: "100%" }}
                          />


                          <Flex
                            justifyContent="space-between"
                            style={{ marginTop: "20px" }}
                          >
                            <DashboardSmallCTA
                              onClick={() => rowSelected.type === "distsales" ? this._updateDistsale() : deleteOpen ? this._deleteProduct() : this._updateProduct()}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%" }}
                            >
                              {deleteOpen ? "DELETE" : "SAVE"}
                            </DashboardSmallCTA>
                            <DashboardSmallCTA
                              onClick={() => deleteOpen ? this.setState({ deleteOpen: false }) : this.setState({ editOpen: false })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%", marginRight: "-5px" }}
                            >
                              CANCEL
                            </DashboardSmallCTA>
                          </Flex>

                        </Flex>
                        :

                        !rowSelected.isMappedSale && getUser().company_admin && (rowSelected.type === "products" || getUser().company_id === rowSelected.company_id)
                          ?
                          <Flex
                            justifyContent="space-between"
                            width="100%"
                          >
                            <DashboardSmallCTA
                              onClick={() => this.setState({ editOpen: true })}
                              fontSize="13px"
                              color="#428BF4"
                              style={{ width: "45%" }}

                            >
                              EDIT
                            </DashboardSmallCTA>
                            {rowSelected.type === "products"
                              ?

                              <DashboardSmallCTA
                                onClick={() => this.setState({ deleteOpen: true })}
                                fontSize="13px"
                                ccolor="#428BF4"
                                style={{ width: "45%" }}

                              >
                                DELETE PRODUCT
                              </DashboardSmallCTA>
                              :
                              null

                            }
                          </Flex>
                          :
                          null
                      }
                    </Flex>
                    :
                    null
              }
            </form>
            {((rowSelected.type === 'distsales' && distsaleHistory.length > 0)) && !this.state.editOpen ?
              <>
                <NunitoSans
                  color="red"
                  style={{ width: "100%", textAlign: 'center', marginTop: '35px', marginBottom: '5px' }}
                >
                  CHANGES
                </NunitoSans>

                <Flex
                  style={{ width: '100%' }}
                  justifyContent="space-between"
                >
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    DATE
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    EDITED BY
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    color='#FE4A49'
                    style={{ width: "33%", textAlign: 'center', textDecoration: 'underline' }}
                  >
                    REASON
                  </NunitoSans>
                </Flex>

                {distsaleHistory.map((item, index, arr) => <Flex
                  style={{ width: '100%', borderBottom: index !== arr.length - 1 ? '1px dashed gray' : "none", paddingBottom: '10px', marginTop: '10px' }}
                  justifyContent="space-between"
                >
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {moment(item.created_at).format('L')}
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {item.edited_by}
                  </NunitoSans>
                  <NunitoSans
                    fontSize="12px"
                    style={{ width: "33%", textAlign: 'center' }}
                  >
                    {item.reason}
                  </NunitoSans>
                </Flex>
                )}

              </>
              :
              null
            }
          </>
          :
          null}
      </Flex>
    )
    
  }
}

ManageRewardsAccessory = reduxForm({
  // destroyOnUnmount: true,
  form: "rewardsAction-form",
  // validate,
  // enableReinitialize : true
})(ManageRewardsAccessory);


export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    formValues: getFormValues('rewardsAction-form')(state),
    connections: state.resources.collection.connection || [],
    user: state.resources.detail.user,
    rowSelected: state.incentives.rowSelected
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    updateInvoice: bindActionCreators(actions.patchInvoice, dispatch),
    fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
    updateReward: bindActionCreators(actions.patchReward, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    updateProduct: bindActionCreators(actions.patchProduct, dispatch),
    fetchProducts: bindActionCreators(actions.fetchProducts, dispatch),
    updateDistsale: bindActionCreators(actions.patchDistsale, dispatch),
    fetchDistsales: bindActionCreators(actions.fetchDistsales, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    redirect: bindActionCreators(push, dispatch),
  })
)(ManageRewardsAccessory);

function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}