import React from "react";
import { reduxForm } from "redux-form";

import { BrandTwoPartFieldDashed, renderSmallField } from "../../../common/forms";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.body = "Required";
  }

  if (!values.url) {
    errors.url = "Required";
  }else if(!validURL(values.url)){
    errors.url = "Not valid"
  }

  return errors;
};

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}


let BrandAttribute = props => {
  const {
    maxlengths,
    clickHandler,
    handleSubmit,
    onCrop,
    notifyError,
    file,
    thePic
  } = props;


  return (
    <form onSubmit={handleSubmit} id="brand-form">
      <BrandTwoPartFieldDashed
        file={file}
        thePic={thePic}
        notifyError={notifyError}
        onCrop={onCrop}
        fieldOneProps={{
          label: "NAME",
          name: "name",
          placeholder: 'e.g. Brand Name',
          component: renderSmallField,
          type: "text",
          maxLength: maxlengths[0]
        }}
        fieldTwoProps={{
          label: "URL",
          name: "url",
          placeholder: 'e.g. Website',
          component: renderSmallField,
          type: "text",
          maxLength: maxlengths[1]
        }}
        icon="checkmark"
        clickHandler={clickHandler}
      />
    </form>
  );
};

export default reduxForm({
  validate
})(BrandAttribute);