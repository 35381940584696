import React from "react";
import { Field, reduxForm } from "redux-form";
import {
  renderField,
  OnboardingCTA,
  BackButton
} from "../../common/forms";
import { Flex, Box } from "../../common/flexbox";
import { H1, P, NunitoSans } from "../../common/typography";
import { EMAIL_REGEX } from "../../../constants/app";

const validate = values => {
  const errors = {};

  let re = new RegExp(EMAIL_REGEX, "i");

  if (!re.test(values["email"])) {
    errors["email"] = "You may not be in our system yet. Sign up or check your email address and try again.";
  }

  
  if (!values.email) {
    errors.email = "Email is required";
  }


  return errors;
};


let LoginForm = props => {
  const { handleSubmit, error, pristine, reset, submitting, redirect } = props;
  
  return (
    <Flex width={[1, 1, 1]} alignItems="center" flexDirection="column" mt={4}>
      <H1 alignSelf={"flex-start"}>Let's KickPost</H1>
      <P alignSelf={"flex-start"}>
      Add your work email below.
      </P>
      <form
        onSubmit={handleSubmit}
        id="create-company-form"
        style={{ width: "100%" }}
      >
        <Flex
          width={1}
          mt={["7px", "7px", "28px", "28px"]}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex width={[1, 1, 1, 1]}>
            <Box width={[1, 1, 1, 1]}>
              <Field
                label="Work Email"
                name="email"
                mb={0}
                component={renderField}
                type="email"
                inputMode="email"
              />
              {error && <strong>{error}</strong>}
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            // justifyContent={["center", "center"]}
            alignItems="center"
            width={[1, 1, 1, 1]}
            my={["2vh", "2vh", "2vh", "2vh"]}
          >
            {/* <Box display={["none", "block"]} width={0.333}>
              <BackButton link="/" />
            </Box> */}
            
            <NunitoSans
            fontSize="14px"
            color="#000000"
            style={{marginTop: "23px",marginBottom: "23px", textAlign:"center"}}
            >
            By joining you confirm that you've accepted our <a href="/legal?legal=tos" target="_blank" style={{color:"#2FD371"}}>terms of service</a> and <a href="/legal#PrivacyBtn" target="_blank" style={{color:"#2FD371"}}>privacy policy</a>
            </NunitoSans>



{/* 

<NunitoSans
            fontSize={['12px', '12px', '14px', '14px']}
            style={{marginLeft:'20px'}}
            >
            By signing up, you are agreeing to KickPost <span 
            onClick={(e)=>{
              e.preventDefault();
              setTermsOpen(true)
            }} 
            style={{color:"#2FD371", textDecoration:"underline", cursor: "pointer"}}
            >
              Terms of Service
              </span> and  <span 
              onClick={(e)=>{
                e.preventDefault();
                setPrivacyOpen(true);
              }} 
              style={{color:"#2FD371", textDecoration:"underline", cursor: "pointer"}}
              >
                Privacy Policy
              </span>
              .
            </NunitoSans>
*/}

            <OnboardingCTA
             
              width={[1,1,1,1]}
              type="submit"
            >
              NEXT
            </OnboardingCTA> 
            {/* <NunitoSans
            fontSize="22px"
            color="#2FD371"
            style={{cursor: "pointer", textDecoration: "underline", lineHeight: 2}}
            onClick={()=>redirect("/signup")}
            >
            Sign Up or Request Access Here
            </NunitoSans> */}
          </Flex>
          
        </Flex>
      </form>
    </Flex>
  );
};

export default reduxForm({
  form: "login",
  validate
})(LoginForm);
