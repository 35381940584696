import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//actions
import { actions } from "../../../../actions/resources";
import { Flex, Box } from "../../../common/flexbox";

// import {
//   MegaFlexBubble, BillingCTA
// } from "../../common/ui";
// import { MapboxMap } from "../../common/mapboxMap";
//utils
import { getUser } from "../../../../utils/auth";
// import { getAccessory, getUser, setAccessory } from "../../../../utils/auth";
import {
  WebsiteBubble,
  EmailBubble,  
  PhoneBubble,
  BrandBubble
} from "../../../common/rows";


// NunitoSans,
// NunitoSansSemiBold
import {
  NunitoSansBold,
} from "../../../common/typography";
import { CardElement } from 'react-stripe-elements';
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons";
// import { CardElement, injectStripe } from 'react-stripe-elements';
// import { Elements, StripeProvider } from 'react-stripe-elements';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    let response = await fetch("/charge", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: token.id,
      mode: 'no-cors'
    });

    if (response.ok) console.log("Purchase Complete!")
  }

  render() {
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>
        <CardElement />
        <button onClick={this.submit}>Send</button>
      </div>
    );
  }
}

export class CompanyDetailAccessory extends Component {
  state = {
    filteredIncentives: [],
    filteredPosts: [],
    isConnection: false,
    isRequestPending: false,
    companies: []
  }
  constructor(props) {
    super(props);
    // this.state = {
    //   isConnection: false
    // }
    // this.submit = this.submit.bind(this);
  }
  // async submit(ev) {
  //   // User clicked submit
  //   console.log("User clicked submit")
  //   //window.alert("User clicked submit")
  //   console.log(ev)
  // }




  componentDidMount() {
    this._fetchDependencies();
  }

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }
  _fetchDependencies = async () => {
    const { fetchUser, fetchCompany, fetchConnections, match } = this.props;
    let { isConnection } = this.state;
    //console.log("_fetchDependencies isConnection src/components/dashboard/company/detail/accessory.js", isConnection);
    
    let user = await fetchUser(getUser().id, {
      edit: true
    });


    let connections;
    // await this._connections(fetchConnections)
    
    fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${getUser().company_id}&status=active`)
    .then((res) => res.json())
    .then((connections) => {
      
      //console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table
      
      if(user === undefined){
        user = this.props.user;
      } 
      if(connections === undefined){
        connections = [];
      } else if(connections.length===0){
        connections = this.props.connections;
      }  
      
      //console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table
      
      let Vendor_ids = [];
      if (connections) {
        connections.forEach(reorder);
        function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
          if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
            Vendor_ids.push(v.vendor_id);
          } else {
            Vendor_ids.push(v.company_id);
          }
        }

        //console.log("_fetchDependencies Vendor_ids", Vendor_ids);
        // this.setState({ vendor_ids: Vendor_ids });

        const isConnection = Vendor_ids.includes(match.params.id);
        // const isConnection = Vendor_ids.includes(user.company_id);
        //console.log("_fetchDependencies isConnection", isConnection);
        this.setState({ isConnection });
        //const isConnection = vendor_ids.includes(match.params.id);

        if (false && !isConnection) {
          //check if a connection request pending

          const pendingConnections =  fetch(`${process.env.REACT_APP_API_URL}/connections?status=requested&company_id=${getUser().company_id}`)
          .then(res=>{
            // res => res.json()
            let response = res
            let pendingIds = [];
            //console.log("this.props", this.props);
            console.log(response)
            

            response.forEach(item => {
              if (item.company_id === getUser().company_id) {
                pendingIds.push(item.vendor_id);
              } else {
                pendingIds.push(item.company_id);
              }
            });

            this.setState({ isRequestPending: pendingIds.includes(match.params.id) })

          });

          let pendingIds = [];

          pendingConnections.forEach(item => {
            if (item.company_id === getUser().company_id) {
              pendingIds.push(item.vendor_id);
            } else {
              pendingIds.push(item.company_id);
            }
          });

          this.setState({ isRequestPending: pendingIds.includes(match.params.id) })


        }

        //console.log("_fetchDependencies isConnection src/components/dashboard/company/detail/detail.js", isConnection);
  
        this.setState({ isConnection });
        //console.log("_fetchDependencies isConnection", isConnection);
        fetchCompany(match.params.id, { detail: true });

        // if(isConnection){
        //   fetchPosts({ company_id: match.params.id, status: "active" });

        //   // who can receive incentives
        //   fetchIncentives({company_id: match.params.id})

        // }

        if (isConnection) {
          // this._handleSearch('');
        }


      }
    })
    .catch((e) => {
      console.log("_fetchDependencies e", e);
    });


    if(false){
    let vendor_ids = [];


    let activeConnections = await fetchConnections({
      company_id: getUser().company_id,
      status: 'active'
    });
    

    console.log("_fetchDependencies activeConnections src/components/dashboard/company/detail/accessory.js", activeConnections)
    console.log("_fetchDependenciesthis.props src/components/dashboard/company/detail/accessory.js", this.props)
  // activeConnections.payload.forEach(reorder);
    if(activeConnections === undefined || activeConnections.length===0){
      activeConnections = this.props.connections;
    }

    if (activeConnections) {
      activeConnections.forEach(reorder);
    }

    function reorder(v, i, a) { //the results returned from the table are correct but not aligned properly
      if (v.company_id === getUser().company_id) { //this will extract the company_ids that a company is connected with
        vendor_ids.push(v.vendor_id);
      } else {
        vendor_ids.push(v.company_id);
      }
    }
     isConnection = vendor_ids.includes(match.params.id);
    fetchCompany(match.params.id, { detail: true });
    this.setState({ isConnection });



    }

  };


  render() {
    const { company,match, user } = this.props;

    const { isConnection } = this.state;

    // user. = user.email;
    
    //console.log("isConnectionisConnection",isConnection)
    //console.log("isConnectionisConnection",match)
    if(!isConnection){
      return (<div></div>);
    }

    if (

      company &&
      company.websites &&
      company.phone_numbers &&
      company.email_addresses && user
    ) {


      return (
        <Flex
          flexDirection="column"
          width="100%"
          position="relative"
          mt={"91px"}
          style={{ height: "89vh", overflowY: "auto", overflowX: "hidden", paddingLeft: "5px", paddingRight: "25px", paddingTop: "5px" }}
        >
          {
            company && (company.websites.length || company.phone_numbers.length || company.email_addresses.length)
              ?
              <NunitoSansBold
                textAlign="center"
                mb='10px'
              >
                CONTACT
              </NunitoSansBold>
              :
              null
          }

          <Box>
            {company &&
              company.websites.map((website, i) => {
                return <WebsiteBubble key={i} {...website} />;
              })}
          </Box>
          <Box>
            {company &&
              company.email_addresses.map((email, i) => {
                return <EmailBubble key={i} {...email} />;
              })}
          </Box>
          <Box>
            {company &&
              company.phone_numbers.map((phone, i) => {
                return <PhoneBubble key={i} {...phone} />;
              })}
          </Box>
          {
            company && company.brands.length
              ?
              <NunitoSansBold
                textAlign="center"
                mb='10px'
              >
                BRANDS
              </NunitoSansBold>
              :
              null
          }
          <Box>
            {company &&
              company.brands.map((brand, i) => {
                return <BrandBubble key={i} {...brand} />;
              })}
          </Box>
          {/* {company.location ? (
            <div>
              <MegaFlexBubble
                position="relative"
                bg="white"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                p="16px"
                mb="24px"
              >
                <Box width="100%" height="217px">
                  <MapboxMap
                    address={
                      (company.location && company.location.address) || ""
                    }
                    placeName={
                      (company.location && company.location.place_name) || null
                    }
                    text={(company.location && company.location.text) || ""}
                    showFullAddress={true}
                  />
                </Box>
              </MegaFlexBubble>
              <Box height="160px" />
            </div>
          ) : null} */}


        </Flex>
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    company: state.resources.detail.company,
    user: state.resources.detail.user
  }),
  dispatch => ({
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    updateU: bindActionCreators(actions.patchCompany, dispatch)
  })
)(CompanyDetailAccessory);
