import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmilingHands } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <FontAwesomeIcon
            icon={faFaceSmilingHands}
            size="10x"
            color="#428BF4"
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    KickPost is a social network and incentive platform for industry partners.
                </span>
                {` It’s a simple, modern way to work with your industry.`}
            </NunitoSans>

        </Flex>
);