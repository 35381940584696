import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  NunitoSans,
  NunitoSansBold,
  NunitoSansSemiBold,
} from "../../common/typography";
import { Flex } from "../../common/flexbox";
import { DashboardSmallCTA } from "../../common/forms";
import { EMAIL_REGEX } from "../../../constants/app";

const BonusModal = ({ isOpen, handleModalClose, createBonus }) => {
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [domain, setDomain] = useState("");
  const [premium, setPremium] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (error.length) {
      setError("");
    }
  }, [email, description, domain, amount]);

  const styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      maxHeight: "90vh",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: "1px solid #707070",
      borderRadius: "10px",
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88",
    },
  };

  const clearForm = () => {
    setEmail("");
    setDescription("");
    setAmount("");
    setDomain("");
    setPremium(false);
    setError("");
  };

  const handleSubmit = () => {
    const emailReg = new RegExp(EMAIL_REGEX, "i");
    const domainRegex = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
    );
    const theDescription = description.trim();

    if (!emailReg.test(email)) return setError("Not a valid email!");


    if (domain.length && !domainRegex.test(domain))
      return setError("Invalid domain name!");

    if (!amount.length || parseInt(amount) < 1)
      return setError("Amount cannot be lower than $1.");

    if (!theDescription.length) return setError("Description is required!");

    if (theDescription.length < 10) return setError("Description is too short.");

    createBonus({
      email,
      description: theDescription,
      amount: parseFloat(amount),
      referred_company_domain: domain,
      is_premium_upgrade: premium,
    });

    clearForm();
    handleModalClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        clearForm();
        handleModalClose();
      }}
      style={styles}
      contentLabel="Bonus Modal"
      ariaHideApp={false}
    >
      <Flex width="100%" flexDirection="column" alignItems="center">
        <NunitoSansBold color="#000000" fontSize="20px">
          Create a KickPost Bonus
        </NunitoSansBold>
        <Flex justifyContent="space-between" width="100%" mt="20px">
          <Flex flexDirection="column" alignItems="center" width="50%">
            <NunitoSansSemiBold>Email *</NunitoSansSemiBold>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              required
              style={{
                fontSize: "14px",
                fontFamily: "NunitoSans",
                padding: "3px 5px",
                border: "1px solid #707070",
                color: "#000000",
                marginLeft: "5px",
              }}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" width="50%">
            <NunitoSansSemiBold>Amount($) *</NunitoSansSemiBold>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value.trim())}
              required
              style={{
                fontSize: "14px",
                fontFamily: "NunitoSans",
                padding: "3px 5px",
                border: "1px solid #707070",
                color: "#000000",
                marginLeft: "5px",
                textAlign: "center",
              }}
            />
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" width="100%" mt="20px">
          <Flex flexDirection="column" alignItems="center" width="50%">
            <NunitoSansSemiBold>Referred Company Domain</NunitoSansSemiBold>
            <input
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value.trim())}
              style={{
                fontSize: "14px",
                fontFamily: "NunitoSans",
                padding: "3px 5px",
                border: "1px solid #707070",
                color: "#000000",
                marginLeft: "5px",
              }}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" width="50%">
            <NunitoSansSemiBold>Premium Upgrade?</NunitoSansSemiBold>
            <input
              type="checkbox"
              value={premium}
              disabled={!domain.length}
              onChange={() => setPremium(!premium)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer"
              }}
            />
          </Flex>
        </Flex>

        <Flex flexDirection="column" alignItems="center" width="100%" my="20px">
          <NunitoSansSemiBold>Description *</NunitoSansSemiBold>
          <textarea
            rows="6"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: "NunitoSans",
              padding: "3px 5px",
              border: "1px solid #707070",
              color: "#000000",
            }}
          />
        </Flex>
        {error && (
          <NunitoSans color="red" fontSize="14px" mb="10px">
            {error}
          </NunitoSans>
        )}
        <DashboardSmallCTA
          width="15%"
          minWidth={["74px"]}
          fontSize="12px"
          innerHeight="30px"
          color="#428BF4"
          onClick={() => handleSubmit()}
          height="34px"
          disabled={
            error || !email.length || !amount.length || !description.length
          }
        >
          SUBMIT
        </DashboardSmallCTA>
      </Flex>
    </Modal>
  );
};

export default BonusModal;
