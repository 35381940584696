import React, {useState, Component, useEffect} from "react";
import { connect } from "react-redux";
//components
import CompanyTypeForm from "./forms/company-type";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset } from "redux-form";
//utils
import { getUser } from "../../utils/auth";
//actions
import { actions } from "../../actions/resources";
import { error, success } from "../../actions/alerts";

export class CompanyType extends Component {
  state = {
    company: {"company":JSON.parse(localStorage.getItem("LeCompany")).name,"name":JSON.parse(localStorage.getItem("LeCompany")).name},
    user:null,
    companyFound: false,
    file: null,
    singleUserAccount: false
  };

  
  componentDidMount(){
    this.setState({ user: this._getCook("_kp.user")});
    if(document.cookie.search("sales_id")>0){
      setTimeout((user) => {
        if(localStorage.getItem("LeCompany")&&localStorage.getItem("LeCompany")!==""){
          this._handleSubmit(user);
        } else {
          // redirect(company.is_single_user_company ? "/individual" : "/accsount-type");
        }
      }, 1000);
    }

  }

  _getCook(cookiename) {
    // Get name followed by anything except a semicolon
    var cookiestring=RegExp(cookiename+"=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
  }

  _handleSubmit = async data => {
    const { createCompany, redirect, notifySuccess, notifyError } = this.props;
    const { user,company } = this.state;

    let payload = {};
    try {
      payload = {
        // ...data,
        ...{
          name: company.name,
          first_name:JSON.parse(user).first_name,
          last_name:JSON.parse(user).last_name,
          domain: getUser().email.split("@")[1],
          user_id: getUser().id,
          is_single_user_company: this.state.singleUserAccount
          // ,"goal":"share_info"
        }
      };
      console.log('company.js',payload);

      const response = await createCompany(payload);
      console.log('company.js',response);      
      if (response && response.payload.id) {
        this.setState({ company: response.payload});
        console.log("let's take a break")
      }
    } catch(e){
      console.log("email address err",e);
      notifyError(
        "This email is already associated to a KickPost account."
      );
      window.alert("This email is already associated to a KickPost account.");
      window.history.back();window.history.back();
    }    
  };

  render() {
    let { company } = this.state;
    console.log("company-type.js ",company)
    const {user} = this.props;
    console.log("company-type.js this.props",this.props)
    return (
      <div className="App">
        <CompanyTypeForm 
        user={user}        
        back={this.props.back || "/"}
        company={this.props.company} />
      </div>
    );
  }
}
export default connect(
  state => ({
    company: state.resources.detail.company
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
  })
)(CompanyType); 
