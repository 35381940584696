import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import dashboardPresentation from "./dashboardPresentation";
import alerts from "./alerts";
import audiences from "./audiences";
import auth from "./auth";
import departments from "./departments";
import groups from "./groups";
import incentives from "./incentives";
import post from "./post";
import resources from "./resources";
import rule from "./rule";
import search from "./search";
import * as breakpoints from "../utils/breakpoints";

import { createResponsiveStateReducer } from "redux-responsive";

export default combineReducers({
  alerts,
  audiences,
  auth,
  dashboardPresentation,
  departments,
  groups,
  incentives,
  post,
  rule,
  search,
  form: formReducer,
  resources,
  browser: createResponsiveStateReducer({
    extraSmall: breakpoints.WIDTH_0,
    small: breakpoints.WIDTH_1,
    medium: breakpoints.WIDTH_2,
    large: breakpoints.WIDTH_3,
    extraLarge: breakpoints.WIDTH_4
  }),
  router: routerReducer
});
