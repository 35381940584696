import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getUser } from "../../utils/auth";

import { AbsoluteDiv } from "./absolute";
import { Flex } from "./flexbox";
import { NunitoSans } from "./typography";

let TrialAlert = ({user, history}) => {
    const dismissedKey = "trial_alert_dismissed";
    const [isDismissed, setIsDismissed] = useState(!!sessionStorage.getItem(dismissedKey));
    const [data, setData] = useState(null);

    const handleDismiss = () => {
        sessionStorage.setItem(dismissedKey, "true");
        setIsDismissed(true);
    };

    const fetchTrialStatus = async() => {
        if(!user || !user.id || !getUser().id || !getUser().company_admin || user.id !== getUser().id) return setData(null);
        const { status, days, link } = await fetch(`${process.env.REACT_APP_API_URL}/freetrials/${getUser().id}`).then(res=>res.json()).catch(err => console.log(err));

        if(status && status !== "not_found"){
            let alertData;

            if(status === "active"){
               alertData = {
                text: `${days} days left in your trial.`,
                linkText: "Add Payment Method",
                link
               };
            }else if(status === "pending"){
                alertData = {
                    text: `You have ${days} days of free trial.`,
                    linkText: "Activate now",
                    link
                };
            }

            if(alertData){
                setData(alertData);
            }
        }
    };

    useEffect(() => {
        fetchTrialStatus();
    }, [user]);

    useEffect(()=>{
        history.listen((location)=>{
            if(!location.pathname.includes('dashboard')){
                setData(null);
            }
        })
    }, [])

    if(!data || isDismissed) return null;
    const { text, linkText, link } = data;
    return (
        <Flex
                justifyContent="center"
                alignItems="center"
                style={{
                    backgroundColor: "#2FD371",
                    width: "100%",
                    height: '30px',
                    paddingTop: '5px',
                    paddingBottom: '5px'
                }}
            >
                <NunitoSans
                color="white"
                textAlign="center"
                >
                    {text}
                </NunitoSans>

                {
                    link && 
                    <NunitoSans
                    color="white"
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginLeft: '5px'
                    }}
                    onClick={() => window.location.assign(link)}
                    >
                        {linkText}
                    </NunitoSans>
                }

                <AbsoluteDiv
                color="white"
                fontSize="20px"
                right={20}
                style={{cursor: "pointer"}}
                onClick={handleDismiss}
                >
                    x
                </AbsoluteDiv>
            </Flex>
    );
};

TrialAlert = connect(
    state => ({
      user: state.resources.detail.user
    })
  )(TrialAlert);

export default TrialAlert;