import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import Modal from "react-modal";
import ReactPlayer from "react-player";
//utils
import { filenameCleaner, mimeToImage } from "../../../../utils/mimetypes";
import { getUser } from "../../../../utils/auth";
//components
import { Box, Flex } from "../../../common/flexbox";
import { H1Condensed, NunitoSans, NunitoSansSemiBold } from "../../../common/typography";
// import DanteComponent from "../../common/dante";
import DraftComponent from "../../../common/reactDraft";
import { VideoEmbedMobile } from "../../../common/ui";
// import { AudienceMultiRowGroup } from "../../common/rows";
import { AudienceList } from "../../../common/reactWindow";
import {
  VideoAttachmentBubble,
  FileAttachmentBubble
} from "../../../common/post";
import { OneSixDash, HashImage, ImageTreatment } from "../../../common/ui";
import {
  CompressedBody,
  SquareImageTreatmentLarge
} from "../../../common/post";
import { Link, UnderlineLink } from "../../../common/navigation";

import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers,
  searchUsers
} from "../../../../actions/search";

import { companyTotal, peopleTotal } from "../../../../actions/post";


import { actions } from "../../../../actions/resources";

export class Attachments extends Component {
  render() {
    const {
      fileAttachments,
      playVideo,
      processFileDownload,
      videoAttachments
    } = this.props;

    return (
      <Flex position="relative" flexDirection="column" width="100%">
        {fileAttachments &&
          fileAttachments.map((file, i) => {
            return (
              <Box key={i} width="100%" my="10px" onClick={() => processFileDownload(file)}>
                <FileAttachmentBubble
                  clickHandler={() => processFileDownload(file)}
                  downloadable={true}
                  icon="rightarrow"
                  link={file.url}
                  filename={filenameCleaner(file.filename)}
                  image={mimeToImage(file.filetype, file.filename)}
                  key={i}
                />
              </Box>
            );
          })}
        {videoAttachments &&
          videoAttachments.map((video, i) => {
            return (
              <Box key={i} width="100%" my="10px" onClick={() => playVideo(video)}>
                <VideoAttachmentBubble
                  clickHandler={() => playVideo(video)}
                  playable={true}
                  iconMarginLeft="3px"
                  icon="play"
                  link={video.link}
                  title={video.title}
                  image={video.image}
                  minutes={video.duration}
                  key={i}
                />
              </Box>
            );
          })}
      </Flex>
    );
  }
}

let viewTimer = null;

export class PostDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showVideo: null,
      modalOpen: false,
      postedByUser: null,
      componentMounted: 0
    }
  }

  async fetchHelper() {
    var DMG = "fetchHelper";
    const {
      companyId,
      userId,
      fetchConnections,
      fetchUser,
      allConnections,
      userIds,
      companyIds,
      departmentIds,
      searchCompanies,
      searchDepartments,
      searchConnectionUsers,
      user
    } = this.props;
    const gotUser = getUser();

    if ((allConnections === 'yes' && (gotUser.company_id === companyId || gotUser.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) && gotUser.company_admin)) {
      // console.log(DMG,"gotUser",gotUser);
      //console.log(DMG, "user", user);
      if (false) {
        //const user = await fetchUser(person.id);
      }
      //console.log(DMG, "companyId", companyId);
      //console.log(DMG, "gotUser.id", gotUser.id);


      if (!user) {
        await fetchUser(getUser().id);
      }

      /*
      //was this necessary?
      await fetchUser(userId).then(res => {
        console.log(DMG, "res", res);
        this.setState({ postedByUser: res.payload });
      })
      */

      // fetchUser(gotUser.id);
      fetchConnections({ company_id: companyId, status: 'active', from: "newPost" });
      //console.log(DMG, "userId", userId);

      // this.setState({ postedByUser: user });
      this.setState({ postedByUser: user }, () => {
        // console.log(DMG, this.state.postedByUser, 'postedByUser');
      });

      axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`).then(res => searchCompanies('', res.data.paid, false, companyId, [], true))

    }

    if ((allConnections === 'no' && (gotUser.company_id === companyId || gotUser.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) && gotUser.company_admin)) {
      // fetchConnections({ company_id: response.payload.company.id, status: 'active', from:"newPost" });

      axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`).then(res => {

        if (companyIds.length) {
          searchCompanies('', res.data.paid, false, companyId, companyIds);
        }

        if (departmentIds.length) {
          searchDepartments('', res.data.paid, false, departmentIds);
        }

        if (userIds.length) {
          searchConnectionUsers('', res.data.paid, false, companyId, userIds);
        }
      })

    }


    let links = document.getElementsByClassName("rdw-link-decorator-wrapper");    
    links.forEach(element => {
      element.style.color = "#00F";
        element.style.textDecoration = "underline";
    });

  }

  componentDidMount() {
    viewTimer = setTimeout(this.sendViewRecord, 5000);
    this.fetchHelper();

  }

  componentWillUnmount() {
    clearTimeout(viewTimer);
  }


  sendViewRecord = async () => {

    const viewPayload = {
      user_id: getUser().id,
      company_id: getUser().company_id,
      content_type: "post",
      content_id: this.props.postId
    };

    await axios.post(`${process.env.REACT_APP_API_URL}/viewrecord`, viewPayload);
  }

  _playVideo = async video => {
    this.setState({
      showVideo: video
    });
  };

  _processFileDownload = async file => {
    let win = window.open(window.location, "_blank");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/s3/signed-url`,
      {
        params: {
          filename: file.filename,
          filetype: file.filetype
        }
      }
    );

    if (response.data.signed_url) {
      win.location.href = response.data.signed_url;
      win.focus();
    }
  };

  _getAudienceList = () => {

    const { allConnections, userIds, companyIds, departmentIds, companyRows, departmentRows, userRows } = this.props;

    if (allConnections === "yes") {
      return companyRows;
    } else {
      let rows = [];

      if (companyIds.length) {
        rows = rows.concat(companyRows);
      }

      if (departmentIds.length) {
        rows = rows.concat(departmentRows);
      }

      if (userIds.length) {
        rows = rows.concat(userRows);
      }

      return rows;
    }
  }

  _getAudienceCount = (notion = -1) => {

    const { allConnections, connections, userIds, companyIds, departmentIds, companyRows, departmentRows, userRows } = this.props;
    const { postedByUser } = this.state;

    if (allConnections === "yes") {
      console.log("allConnections", allConnections, postedByUser, notion)
      return peopleTotal(connections, postedByUser);
    } else {
      let count = 0;


      if (companyIds.length) {
        count += companyRows.reduce((a, b) => a + b.row.total, 0);
      }

      if (departmentIds.length) {
        count += departmentRows.reduce((a, b) => a + b.row.total, 0);
      }

      if (userIds.length) {
        count += userRows.length;
      }

      return count;

    }

  }

  render() {
    const {
      body,
      browser,
      companyId,
      companyLogo,
      companyName,
      brandName,
      fileAttachments,
      hashTags,
      postedBy,
      postedByImage,
      postDate,
      userId,
      title,
      videoAttachments,
      allConnections,
      companyRows,
      departmentRows,
      userRows,
      companies,
      isSingleUserCompany,
      connections
    } = this.props;
    const { showVideo, modalOpen, postedByUser } = this.state;



    const customStyles = {
      content: {
        top: '50%',
        left: '40%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "677px",
        maxHeight: "80vh",
        zIndex: 16777271,
        overflow: "auto",
        borderRadius: "59px"
      },

      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }

    };

    let audienceList = this._getAudienceList();



    return (
      <Flex flexDirection="column" pb={40} mt="20px">
        {
          brandName
            ?
            <NunitoSansSemiBold
              fontSize="10px"
              color="#000000"
              style={{
                backgroundColor: "#ACEDC6",
                padding: "1px 5px 0 5px",
                width: "fit-content"
              }}
            >
              {`On Behalf Of: ${brandName}`}
            </NunitoSansSemiBold>
            :
            null
        }
        <Flex
          justifyContent="space-between"
          flexDirection="row"
          position="relative"
          width={[1]}
        >
          <Box mt={[0, "10px"]} width={[0.757, 0.72]}>
            <H1Condensed>{title}</H1Condensed>
            {
              isSingleUserCompany
                ?
                <NunitoSans
                  fontSize={["10px", "16px"]}
                  fontWeight="medium"
                  pt={["5px", "12px"]}
                  color="darkerGray"
                >
                  {companyName}
                </NunitoSans>
                :
                <UnderlineLink to={`/dashboard/company/${companyId}`}>
                  <NunitoSans
                    fontSize={["10px", "16px"]}
                    fontWeight="medium"
                    pt={["5px", "12px"]}
                    color="darkerGray"
                  >
                    {companyName}
                  </NunitoSans>
                </UnderlineLink>

            }
          </Box>
          <Link to={`/dashboard/${isSingleUserCompany ? 'profile' : 'company'}/${isSingleUserCompany ? userId : companyId}`}>
            {browser.lessThan.small ? (
              <Box mr="-2px">
                <SquareImageTreatmentLarge
                  borderRadius={5}
                  size={44}
                  img={companyLogo}
                />
              </Box>
            ) : (
              <Box mr="0px">
                <SquareImageTreatmentLarge
                  borderRadius={10}
                  size={85}
                  img={companyLogo}
                />
              </Box>
            )}
          </Link>
        </Flex>
        {!browser.lessThan.large && (getUser().company_id === companyId || getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) && getUser().company_admin ?
          <>
            <NunitoSans
              fontSize="16px"
              fontWeight="bolder"
              color="#2FD371"
              width="100%"
              style={{ borderBottom: "#D3D3D3 solid 1px", marginTop: "31px" }}
            >
              AUDIENCE
            </NunitoSans>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              style={{ paddingTop: "11px", paddingBottom: "11px" }}
            >
              <NunitoSans
                fontSize="16px"
                fontWeight="bold"
                color="#000000"
                style={{ paddingLeft: "35px" }}
              >
                {allConnections === 'yes' ? 'ALL' : 'FILTERED'}
              </NunitoSans>
              <Flex
                alignItems="center"
              >
                <NunitoSans
                  className="postDetails"
                  fontSize="12px"
                  fontWeight="bold"
                  color="#A3AA9E"
                  style={{ marginRight: "25px" }}
                  onClick={() => this.setState({ modalOpen: true })}
                >
                  View Details
                </NunitoSans>

                <NunitoSans
                  fontSize="16px"
                  fontWeight="bold"
                  color="#000000"
                  style={{ paddingRight: "15px" }}
                >
                  {/* {companies.reduce((a,b)=>a+b.total_employee_count, 0)} */}
                  {/* {allConnections==='yes' ? `${peopleTotal(connections, postedByUser)} PEOPLE` : `${userRows.length + companyRows.reduce((a,b)=>a+b.row.total, 0) + departmentRows.reduce((a,b)=>a+b.row.total, 0)} PEOPLE`} */}
                  {`${this._getAudienceCount({ postedByUser })} PEOPLE`}
                </NunitoSans>
              </Flex>
            </Flex>
          </>
          :
          null
        }
        <Flex position="relative" flexDirection="column">
          <CompressedBody pb={["30px"]} lineHeight={"30px"} fontSize={[12, 14]}>
            {/* <DanteComponent config={{ read_only: true }} content={body} /> */}
            <DraftComponent readOnly={true} initialContent={body} />
            {browser.lessThan.medium ? null : (
              <OneSixDash
                position="absolute"
                bottom="-1px"
                left="0px"
                right="0px"
                height="1px"
              />
            )}
          </CompressedBody>
          {browser.lessThan.small ? (
            <Flex
              position="relative"
              pb="13px"
              flexDirection="column"
              width="100%"
            >
              <Attachments
                fileAttachments={fileAttachments}
                videoAttachments={videoAttachments}
                playVideo={this._playVideo}
                processFileDownload={this._processFileDownload}
              />
              {showVideo ? (
                <VideoEmbedMobile
                  clickHandler={() => {
                    this.setState({ showVideo: false });
                  }}
                >
                  <ReactPlayer
                    className="react-player-mobile"
                    width="100%"
                    height="100%"
                    url={showVideo.url}
                  />
                </VideoEmbedMobile>
              ) : null}
              <OneSixDash
                position="absolute"
                bottom="-1px"
                left="0px"
                right="0px"
                height="1px"
              />
            </Flex>
          ) : null}
          {browser.lessThan.large && browser.greaterThan.extraSmall ? (
            <Flex
              position="relative"
              pb="13px"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
            >
              {fileAttachments.map((file, i) => {
                return (
                  <Box key={i} width="45%" my="10px" onClick={() => this._processFileDownload(file)}>
                    <FileAttachmentBubble
                      clickHandler={() => this._processFileDownload(file)}
                      downloadable={true}
                      icon="rightarrow"
                      link={file.url}
                      filename={filenameCleaner(file.filename)}
                      image={mimeToImage(file.filetype, file.filename)}
                      key={i}
                    />
                  </Box>
                );
              })}
              {videoAttachments.map((video, i) => {
                return (
                  <Box key={i} width="45%" my="10px" onClick={() => this._playVideo(video)}>
                    <VideoAttachmentBubble
                      clickHandler={() => this._playVideo(video)}
                      playable={true}
                      iconMarginLeft="3px"
                      icon="play"
                      link={video.link}
                      title={video.title}
                      image={video.image}
                      minutes={video.duration}
                      key={i}
                    />
                  </Box>
                );
              })}
              {showVideo ? (
                <VideoEmbedMobile
                  clickHandler={() => {
                    this.setState({ showVideo: false });
                  }}
                >
                  <ReactPlayer
                    className="react-player-mobile"
                    width="100%"
                    height="100%"
                    url={showVideo.url}
                  />
                </VideoEmbedMobile>
              ) : null}
              <OneSixDash
                position="absolute"
                bottom="-1px"
                left="0px"
                right="0px"
                height="1px"
              />
            </Flex>
          ) : null}
          {hashTags.length > 0 ? (
            <Flex
              width="100%"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              py="6px"
              position="relative"
            >
              {hashTags.map((tag, i) => {
                return (
                  <Flex
                    key={i}
                    py="6px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <HashImage ml={"2px"} height="20px" width="22px" />
                    <NunitoSans
                      fontSize="10px"
                      pt="2px"
                      fontWeight={300}
                      color="darkGray"
                      pr={["15px", "26px"]}
                    >
                      {tag}
                    </NunitoSans>
                  </Flex>
                );
              })}
              <OneSixDash
                position="absolute"
                bottom="-1px"
                left="0px"
                right="0px"
                height="1px"
              />
            </Flex>
          ) : null}
          <Flex
            width="100%"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            py="20px"
          >
            <Link to={`/dashboard/profile/${userId}`}>
              {browser.lessThan.small ? (
                <ImageTreatment
                  img={postedByImage}
                  blurRadius={"0px"}
                  imageInset={0}
                  borderRadius={18}
                  innerBorderRadius={15}
                  shadowOffset={0}
                  padding={3}
                  height={30}
                  width={30}
                />
              ) : (
                <ImageTreatment
                  img={postedByImage}
                  blurRadius={"0px"}
                  imageInset={0}
                  borderRadius={23}
                  innerBorderRadius={20}
                  shadowOffset={0}
                  padding={3}
                  height={40}
                  width={40}
                />
              )}
            </Link>
            <NunitoSans
              pl={["10px", "14px"]}
              fontSize={["10px", "14px"]}
              pt={["2px", "4px"]}
              color="darkGray"
              fontWeight={300}
            >
              Posted by&nbsp;
              <UnderlineLink
                fontWeight={postedBy === "You" ? 700 : 300}
                to={`/dashboard/profile/${userId}`}
              >
                {postedBy}
              </UnderlineLink>
              &nbsp;on {postDate}
            </NunitoSans>
            <OneSixDash
              position="absolute"
              bottom="-1px"
              left="0px"
              right="0px"
              height="1px"
            />
          </Flex>
        </Flex>


        <Modal
          isOpen={modalOpen}
          onRequestClose={() => this.setState({ modalOpen: false })}
          style={customStyles}
          contentLabel="Post Audience Modal"
          ariaHideApp={false}
        >
          {/* <AudienceMultiRowGroup
          audiencesMulti={allConnections==='yes' ? companyRows : [].concat(companyRows, departmentRows, userRows)}
          groupName="AUDIENCE"
          headerFontSize="35px"
          headerjustifyContent="center"
          mobile={true}
          hideFirstLastDash={true}
          noSelect={true}
          /> */}

          <AudienceList
            // audiencesMulti={allConnections==='yes' ? companyRows : [].concat(companyRows, departmentRows, userRows)}
            audiencesMulti={audienceList}
            groupName="AUDIENCE"
            headerFontSize="35px"
            headerjustifyContent="center"
            mobile={true}
            hideFirstLastDash={true}
            noSelect={true}
          />

        </Modal>
      </Flex>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows || [],
    departmentRows: state.search.departmentSearchRows || [],
    userRows: state.search.userSearchRows || [],
    connections: state.resources.collection.connection || [],
    user: state.resources.detail.user,
  }),
  dispatch => ({
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
  })
)(PostDetail);
