import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import _ from "underscore";
import { history } from "../../../store";
//utils
import { getUser } from "../../../utils/auth";
import { filterActiveGroups } from "../../../utils/helpers";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { setGroup } from "../../../actions/groups";
import { searchGroups, searchConnectionCompanies } from "../../../actions/search";
//components
import { InputStyled,  DashboardSmallCTA } from "../../common/forms";
import { NunitoSans } from "../../common/typography";
import { Flex, Box, ClickCursorFlex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { CreateGroupCTA, EditGroupCTA } from "../../common/ui";
import { GroupRowGroup } from "../../common/rows";
import { BackButton } from "../../common/backbutton";
import InfiniteScroll from "react-infinite-scroller";


export class ManageGroupsMain extends Component {
  state = { 
    search: null,
    hasMore: true,
    loadedGroups: [],
    page: 1,
    editGroupMode: false,
    DMG :"src/components/dashboard/groups/index.js"
   };

  componentDidMount(){
    this._fetchDependencies();
  }

  

_fetchDependencies = async () => {

    const { fetchGroups, fetchCompany, groups, match,company } = this.props;
    const {DMG}  = this.state;      

    await fetchCompany(match.params.id);
    if (
      (getUser() && getUser().company_admin === false) ||
      getUser().company_id !== match.params.id ||
      (company && !company.paid)
      ) {
        history.push("/dashboard");
      }
    
    fetchGroups({
      company_id: getUser().company_id,
      isManaged: false,
      status: "active",
      offset: 0
    }).then(res => {
      this.setState({loadedGroups: this.props.groups, hasMore: this.props.groups.length < 15 ? false : true});
    });     
      
  }

  componentWillUnmount(){
    const {setGroup}=this.props;
    setGroup(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.groups.length && this.props.groups.length && nextProps.groups.length > this.props.groups.length  && !this.state.search){
      this.props.fetchGroups({ company_id: getUser().company_id, status: 'active', isManaged: false, offset: 0 }).then(res => {        
        const { groups } = this.props;
        console.log(this.state.DMG+" _search",groups)
        console.log(this.state.DMG+" this.props",this.props);                
        this.setState({loadedGroups: groups, hasMore: groups.length < 15 ? false : true, page: 1});
        
        // this.setState({loadedGroups: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
      });
    }

    if (nextProps.groupResults !== this.props.groupResults) {
      return this._fetchGroups(nextProps.groupResults);
    }
  }

  _removeGroup = async id => {
    const { fetchGroups, updateGroup } = this.props;
    await updateGroup(id, { status: "inactive"});
    fetchGroups({
      company_id: getUser().company_id,
      status: "active",
      isManaged: false,
      offset: 0
    }).then(res => {
      const { groups } = this.props;              
      this.setState({loadedGroups: groups, hasMore: groups.length < 15 ? false : true, page: 1});  

      //this.setState({loadedGroups: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
    });
  };

  _createGroup = async data => {
    const { createGroup, setGroup, fetchGroups, resetForm, selectedRows, notifyError, updateCompany } = this.props;

    if(!data.name) return notifyError("Group name cannot be blank.");

    if(selectedRows.length<2) return notifyError('Minimum 2 companies needed to create a group.');

    await fetchGroups({
      company_id: getUser().company_id,
      status: "active"
    }).then(() => {
      const { groups } = this.props;     
      if(groups.map(group=>group.name.toLowerCase().trim()).includes(data.name.toLowerCase().trim())) {
        notifyError("A group with this name already exists.");
        return;
      } 
      
      const respond = createGroup({
        ...data,
        ...{
          company_id: getUser().company_id,
          user_id: getUser().id
        }
      });
  
      const theGroup = respond.payload;
  
      const newMembers = selectedRows;
  
      
  
      for(let i=0; i<newMembers.length; i++){
        let newGroups=newMembers[i].groups || [];
  
        newGroups.push(_.omit(theGroup, ["status", "user_id"]));
  
         updateCompany(newMembers[i].id, {
          groups: JSON.stringify(newGroups),
          doNotUpdateState: true
        })
      }
  
      setGroup(null);
      resetForm("create-group");
  
      fetchGroups({
        company_id: getUser().company_id,
        status: "active",
        isManaged: false,
        offset: 0
      }).then(res => {      
        const { groups } = this.props;              
        this.setState({loadedGroups: groups, hasMore: groups.length < 15 ? false : true, page: 1});
        // this.setState({loadedGroups: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
      });

    });
  };

  _editGroup = async group => {
    const { setGroup } = this.props;
    this.setState({editGroupMode: true})
    setGroup(group);
  };

  _fetchGroups = groupResults => {
    const { fetchGroups } = this.props;
    const { search,DMG } = this.state;
    
    console.log(DMG,groupResults,search)
    let searchResultsEmpty = ((this.state.search === "") && (!groupResults || !groupResults.length));
    console.log(DMG,searchResultsEmpty)
    fetchGroups({
      "company_id": getUser().company_id,
      "ids": groupResults,
      "searchResultsEmpty": Boolean(searchResultsEmpty) ,
      "isManaged": false,
      "status": "active"
    });
  };

  _updateGroup = async data => {
    const {
      groups,
      theGroup,
      groupMembers,
      selectedRows,
      fetchGroups,
      resetForm,
      setGroup,
      updateGroup,
      updateCompany,
      fetchCompany
    } = this.props;

    let newMembers = selectedRows.filter(company=>!groupMembers.map(member=>member.id).includes(company.id))
    let oldMembers = groupMembers.filter(member=>!selectedRows.map(company=>company.id).includes(member.id))

    console.log(newMembers, oldMembers, "MEMBERS")

    if(newMembers && newMembers.length){

      for(let i=0; i<newMembers.length; i++){
        let newGroups=newMembers[i].groups || [];

        newGroups.push(_.omit(theGroup, ["status", "user_id"]));

        await updateCompany(newMembers[i].id, {
          groups: JSON.stringify(newGroups),
          doNotUpdateState: true
        })
      }

    }

    if(oldMembers && oldMembers.length){
      for(let i=0; i<oldMembers.length; i++){
        let newGroups=oldMembers[i].groups.filter(oldGroups=>oldGroups.id!==theGroup.id);        

        await updateCompany(oldMembers[i].id, {
          groups: JSON.stringify(newGroups),
          doNotUpdateState: true
        })
      }
    }

    await fetchCompany(getUser().company_id);
    
    // console.log(DMG,"this.props",this.props);
    // console.log(DMG,"loadedGroups",loadedGroups);
    // console.log(DMG,"groups",groups);
    // console.log(DMG,"theGroup",theGroup);
    // console.log(DMG,"data",data);

    await updateGroup(_.findWhere(groups, { id: theGroup.id }).id, {
      name: data.name
    });
    setGroup(null);
    resetForm("edit-group");
    fetchGroups({
      company_id: getUser().company_id,
      status: "active",
      isManaged: false,
      offset: 0
    }).then(res => {           
      const { groups } = this.props;              
      this.setState({editGroupMode: false, loadedGroups: groups, hasMore: groups.length < 15 ? false : true, page: 1});    
      //this.setState({editGroupMode: false, loadedGroups: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1});
    });
    //it takes Algolia a while to update, so we wait for a second to get updated results from Algolia
    setTimeout( ()=> this.props.searchCompanies('') , 1000);
  };

  _clearSearch = () => {  
    this.setState({ search:""});
    this._search(" ");
  };

  _loadMore = pageNo => {
    const {fetchGroups} = this.props;
    const {loadedGroups, hasMore, page} = this.state;    

    fetchGroups({ company_id: getUser().company_id, status: 'active', isManaged: false, offset: pageNo*15 }).then(res => {
      const { groups } = this.props;

      console.log(this.state.DMG+" _search",groups)
      console.log(this.state.DMG+" this.props",this.props);  
      console.log(this.state.DMG+" res",res);  

      if(res.payload.length === 0){
        this.setState({hasMore: false})
      }else if(hasMore){
        this.setState({loadedGroups: loadedGroups.concat(res.payload)});
         

        if(res.payload.length < 15){
          this.setState({hasMore: false})
        }else{
          this.setState({page: page + 1})

        }
      }
      
    } )

  }

  _updateCompany = async(company_id, payload)=>{

    const company = await fetch(`${process.env.REACT_APP_API_URL}/companies/${company_id}`,
    {
      method: 'PATCH',
      body: JSON.stringify(payload)
    }).then(res=>res.json());

    return company;
  }

  _search = async input => {
    const { fetchGroups, searchGroups } = this.props;
    console.log(getUser().company_id )

    if (input.length === 0) {
      fetchGroups({
        company_id: getUser().company_id,
        status: "active",
        isManaged: false,
        offset: 0
      }).then(res => {
        const { groups } = this.props;
        console.log(this.state.DMG+" _search",groups)
        console.log(this.state.DMG+" this.props",this.props);                
        this.setState({loadedGroups: groups, hasMore: groups.length < 15 ? false : true, page: 1});
      });
    } else {
      searchGroups(getUser().company_id, input);
    }
  };

  _updateSearch = input => {
    this.setState({ search: input });
    this._search(input);
  };

  _handleFocus = () =>{
    const { theGroup, setGroup } = this.props;
    if (theGroup) return;

    setGroup({
      id: 'new_group',
      total_company_count: 0
    })
  }

  render() {
    const { browser, groups, theGroup, setGroup,company, groupResults } = this.props;
    const { search, hasMore, page, loadedGroups, editGroupMode, DMG } = this.state;

    console.log(DMG,"this.props",this.props);
    console.log(DMG,"loadedGroups",loadedGroups);
    console.log(DMG,"groups",groups);
    console.log(DMG,"theGroup",theGroup);
    let localLoadedGroups = groups;
    if(loadedGroups&&loadedGroups.length!==0){
      localLoadedGroups = loadedGroups;
    }


    return company && company.paid ? (
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Flex
            mt="1px"
            mb="25px"
            mx={["0px"]}
            height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <InputStyled
                placeholder="Search Groups"
                onChange={e => this._updateSearch(e.target.value)}
                value={search || ""}
                innerRef={input => (this.searchInput = input)}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                pt={["3px"]}
                px={["5px"]}
                mt={["0px"]}
                height={["49px"]}
                fontWeight={400}
                fontSize={[24]}
                lineHeight={1.5}
                color={"#979797"}
                borderBottom={[0]}
              />
              <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                <ClickCursorFlex
                  height={["100%", "100%", "100%"]}
                  alignItems={["center"]}
                  onClick={() => {this._clearSearch();}}
                  justifyContent={["center"]}
                >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
            </Flex>
          </Flex>

          <Flex pb="40px" flexDirection="column">
{ /*
    console.log(DMG,"this.props",this.props),
    console.log(DMG,"loadedGroups",loadedGroups),
    console.log(DMG,"groups",groups),
    console.log(DMG,"theGroup",theGroup),*/
    }

              {theGroup && editGroupMode ? (
                <EditGroupCTA
                  setGroup={() => {
                    this.setState({editGroupMode: false});
                    return setGroup(null);
                  }}
                  initialValues={{
                    name: _.findWhere(localLoadedGroups, { id: theGroup.id }).name
                  }}
                  onSubmit={this._updateGroup}
                />
              ) : (
                <CreateGroupCTA handleFocus={this._handleFocus} onSubmit={this._createGroup} />
              )}
            </Flex>
          {(search && search.length && groupResults.length) || (!search && localLoadedGroups.length) ? (
            <div style={{height: "79vh", overflowY: "auto", overflowX: "hidden", "paddingRight": "20px"}}>
             <InfiniteScroll 
                key={["MGM01" + `${Math.random().toString()}`]}
                pageStart = { 0 }
                loadMore = { a => {
                  if(a<=page){
                    return this._loadMore(page);
                  }
                }   } 
                hasMore={hasMore && !search && localLoadedGroups.length > 0}
                // hasMore={false}
                loader={<div className="loader"></div>}
                useWindow = { false }
                threshold = { 150 } 
                >

            <GroupRowGroup
              key={["MGMGRG01" + `${Math.random().toString()}`]}
              hideFirstLastDash={true}
              groups={filterActiveGroups(localLoadedGroups)}
              mobile={browser.lessThan.small}
              editClickHandler={this._editGroup}
              removeClickHandler={this._removeGroup}
              editGroupMode={editGroupMode}
            />
            </InfiniteScroll>
            {/* {hasMore && !search && loadedDepartments.length > 0 ? 
          <Flex width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
          >
            <Box>
              <DashboardSmallCTA                    
                    onClick={() => this._loadMore(page)}
                    mt={["10px", 0]}
                    mb={["24px", 0]}
                    mr={[0, "23px"]}
                    width={["140px","400px"]}
                    
                  >
                    LOAD MORE
              </DashboardSmallCTA>
              </Box>
              </Flex>
              
              : 
                null} */}
                </div>
          ) : (
            <Flex justifyContent="center" alignItems="center" height={175}>
              <Box>
                <NunitoSans
                  pt={["3px", "4px", "7px", "5px"]}
                  fontSize={22}
                  fontStyle="italic"
                  color="darkGray"
                >
                  {search ? "No search results" : "There have been no groups added."}
                </NunitoSans>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    ): <div></div>;
  }
}

export default connect(
  state => ({
    browser: state.browser,
    company: state.resources.detail.company,
    theGroup: state.groups.group,
    groupMembers: state.groups.groupMembers,
    selectedRows: state.post.selectedRows,
    groups: state.resources.collection.group || [],
    groupResults: state.search.groupSearchResults || []
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createGroup: bindActionCreators(actions.createGroup, dispatch),
    fetchGroups: bindActionCreators(actions.fetchGroups, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    searchGroups: bindActionCreators(searchGroups, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    setGroup: bindActionCreators(setGroup, dispatch),
    updateGroup: bindActionCreators(actions.patchGroup, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch)
  })
)(ManageGroupsMain);

export const ManageGroupsNav = ({ ...props }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        GROUPS
      </NunitoSans>
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);
