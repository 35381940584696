import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { reset } from "redux-form";
// import AWS from "aws-sdk";
// import moment from "moment";
//utils
import { getUser, setUser } from "../../utils/auth";
//actions
import { actions } from "../../actions/resources";
import { error, success } from "../../actions/alerts";
//components
import CompanyForm from "./forms/company";
import ConfirmCompany from "./forms/confirm-company";


export class Company extends Component {
  state = {
    company: {"company":JSON.parse(localStorage.getItem("LeCompany")).name,"name":JSON.parse(localStorage.getItem("LeCompany")).name},
    companyFound: false,
    file: null,
    singleUserAccount: false
  };

  componentDidMount(){
    this.setState({ singleUserAccount: Boolean(window.location.pathname.split('/')[1]==='individual') });
    this._fetchDependencies();

    
    setTimeout(() => {

      if(localStorage.getItem("LeCompany")&&localStorage.getItem("LeCompany")!==""&&JSON.parse(localStorage.getItem("LeCompany")).name){
        document.getElementsByTagName("input")[0].value = JSON.parse(localStorage.getItem("LeCompany")).name;
      } else {
        // redirect(company.is_single_user_company ? "/individual" : "/accsount-type");

      }
    }, 1000)
  }


  _fetchDependencies = async () => {
    console.log("AAAAABBBBBBBAAAAAAAAAAAA", this.state, this.props)
    let gotUser = getUser();
    const {
      redirect
    } = this.props;
    if (!getUser()) {
      redirect("/");
    } else if (gotUser.is_single_user_company && window.location.pathname.split('/')[1] !== 'individual') {
      redirect("/")
    } else if (gotUser.hasOwnProperty('is_single_user_company') && !gotUser.is_single_user_company && window.location.pathname.split('/')[1] !== 'company') {
      redirect("/")
    } else {
  
      let leDomain = gotUser.email;
      leDomain = leDomain.toString().toLowerCase();
      leDomain = leDomain.split("@")[1];
  
      fetch(`${process.env.REACT_APP_API_URL}/companias/${leDomain}`)
        .then(response => response.json())
        .then(data => {
          console.log("companias fetchdependencies AUGUST 2022", data, this.props);
  
          if (data === undefined) {
            data = this.props.company;
          }
  
          this.setState({
            company: data
          });
          if (data.id || data.is_single_user_company) {
            this.setState({
              companyFound: true
            });
          }
        });
  
    }
  
  
  
  
  
  
    if (gotUser && gotUser.company_id) {
  
      this._fetchDependencies();
  
  
      this.setState({
        companyFound: true
      });
    }
  
  }
  

  _fetchDependencies = async () => {
    
    console.log("BBBBBBBBBBAAAAAAA",this.state,this.props)
    const { fetchCompany } = this.props;
    fetchCompany(getUser().company_id);    
  };

  _handleConfirmSubmit = async data => {
    const { updateUser, redirect, notifySuccess, notifyError, resetForm } = this.props;

    let payload = { 
      first_name: data.first_name,
      last_name: data.last_name
    };

    const user = await updateUser(getUser().id, payload).then(res=>res.payload);

    if(user&&user.id){
      let _user = getUser();
      _user.first_name = user.first_name;
      _user.last_name = user.last_name;

      setUser(_user);
      localStorage.setItem("user",user.email);

      redirect("/email-verification");


    }else{
      notifyError('There was an error.')
    }
  }

  _handleSubmit = async data => {
    const { createCompany, redirect, notifySuccess, notifyError, resetForm } = this.props;
   
    let payload = {};
    try {
      payload = {
        ...data,
        ...{
          domain: getUser().email.split("@")[1],
          user_id: getUser().id,
          is_single_user_company: this.state.singleUserAccount
        }
      };
      console.log('company.js',payload);

      const response = await createCompany(payload);
      console.log('company.js',response);
      
      if (response && response.payload.id) {
        redirect("/email-verification");
        //redirect("/goal");
        // redirect("/email-verification");
      }
    } catch(e){
        window.alert("This email is already associated to a KickPost account.");
        notifyError(
            "This email is already associated to a KickPost account."
          );
    }
    
  };

  onDrop = files => {
    this.setState({ file: files[0] });
  };

  render() {
    const { back, redirect } = this.props;
    let { companyFound, file, company, singleUserAccount } = this.state;
    console.log("company.js company",company)
    console.log("company.js companyFound",companyFound)
    console.log("company.js this.props",this.props)

    if (companyFound || (this.props.company && this.props.company.id)) {
        company = this.props.company;
        return <ConfirmCompany 
          company={company} 
          redirect={redirect} 
          onSubmit={company.is_single_user_company ? this._handleSubmit : this._handleConfirmSubmit} 
          singleUserAccount={singleUserAccount} />;
      
    }
    //PIPPY117QK

    return (
      <CompanyForm
        initialValues={{name: company.name}} 
        name={company.name} 
        back={back}
        redirect={redirect}
        dropzoneOnDrop={this.onDrop}
        dropzonePreview={(file && file.preview) || null}
        onSubmit={this._handleSubmit}
        singleUserAccount={singleUserAccount}
      />
    );
  }
}

export default connect(
  state => ({
    company: state.resources.detail.company
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    redirect: bindActionCreators(push, dispatch),
    resetForm: bindActionCreators(reset, dispatch)
  })
)(Company);