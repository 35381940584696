import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P} from '../../common/typography';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
import ReactTooltip from 'react-tooltip';
import {  NunitoSansBold,  NunitoSans,  NunitoSansSemiBold } from "../../common/typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";

let StartForm = props => {
  const {handleSubmit,  back, redirect, isMobile} = props;
  
  document.getElementById("kickpost-root").style.background = "rgba(255,255,255,1)";
  
  return (
    <Flex width={[1, 1, 1]} alignItems="left" flexDirection="column">
      <H1 alignSelf={'flex-start'}>Get Started!</H1>
      <NunitoSansSemiBold
        fontSize={["16px","16px","18px","18px"]}
        color="#000000"
        textAlign="left"
        style={{marginBottom: isMobile ? '5px' : '5px'}}
      >
          Explore the features of your account.
      </NunitoSansSemiBold>

                  
                  <NunitoSansBold
                    fontSize={["22px","22px","20px","20px"]}
                    color="#000000"
                    style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                  >
                        CONNECT WITH YOUR PARTNERS
                  </NunitoSansBold>
                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '5px' : '5px'}}
                  >
                     Join all your industry partners in a single place.
                  </NunitoSansSemiBold>


                  <NunitoSansBold
                    fontSize={["22px","22px","20px","20px"]}
                    color="#000000"
                    style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                  >
                        STAY UP TO DATE
                  </NunitoSansBold>
                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '5px' : '5px'}}
                  >
                     Browse the latest info in your newsfeed.
                  </NunitoSansSemiBold>


                  <NunitoSansBold
                    fontSize={["22px","22px","20px","20px"]}
                    color="#000000"
                    style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                  >
                        ACCESS INFO INSTANTLY
                  </NunitoSansBold>
                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '5px' : '5px'}}
                  >
                     Search info from all your partners in one place.
                  </NunitoSansSemiBold>


                  <NunitoSansBold
                    fontSize={["22px","22px","20px","20px"]}
                    color="#000000"
                    style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                  >
                        EARN REWARDS
                  </NunitoSansBold>
                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '5px' : '5px'}}
                  >
                     Earn SPIFFs and prizes engaging with your partners.
                  </NunitoSansSemiBold>


                  <NunitoSansBold
                    fontSize={["22px","22px","20px","20px"]}
                    color="#000000"
                    style={{marginBottom:'1px', marginTop: '5px', textAlign:"left"}}                    
                  >
                        JUST GETTING STARTED
                  </NunitoSansBold>
                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="left"
                    style={{marginBottom: isMobile ? '5px' : '5px'}}
                  >
                     We're adding new features all the time.
                  </NunitoSansSemiBold>
                  <form
                    onSubmit={handleSubmit}
                    id="create-company-form"
                    style={{ width: "100%" }}
                  >
                  <OnboardingCTA 
                  redirect={redirect} 
                    my={['2vh', '2vh', '9vh', '9vh']}
                    width={[1,1,1,1]}          
                    // onClick={() => _handleSubmit(props)}                
                    type="submit">
                    START KICKPOSTING
                  </OnboardingCTA>
                  </form>
    </Flex>
  );
};

function validate(){}

export default reduxForm({
  form: 'company',
  validate,
},
dispatch => ({
  redirect: bindActionCreators(push, dispatch)
})
)(StartForm);
