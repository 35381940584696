import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux"
import { submit, reset, reduxForm, Field, getFormValues } from "redux-form";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth"
import { actions } from "../../../actions/resources"
import { mainContentScrollTop } from "../../../actions/dashboardPresentation"
import { error, success } from "../../../actions/alerts"
import AssetForm from "./forms/assetInfo"
import FileAttachments from "../company/forms/files"
import VideoAttachments from "../company/forms/videos"
import { NunitoSans, NunitoSansBold } from "../../common/typography"
import { DashboardSmallCTA, OutlineFlex } from "../../common/forms"
import { Box, Flex } from "../../common/flexbox"
import { AbsoluteDiv } from "../../common/absolute"
import { BackButton } from "../../common/backbutton"
import ReactTooltip from "react-tooltip"
import InfoModal from "../../common/InfoModal"
import addAssetIcon from "../../../images/add_asset_modal_icon.png"
import QuestionModalTrigger from "../../common/questionModalTrigger"
import parse from "url-parse"
import moment from "moment"
import _ from "underscore"
import RouteLeavingGuard from "../../common/routeGuardModal"
import axios from "axios"
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const validate = (values) => {
  return
}

export class AssetCompanyMain extends PureComponent {
  state = {
    description: [],
    files: [],
    videoAdded: false,
    videos: [],
    videoAdded: false,
    submitted: false,
    blockNavigation: true,
    endDate: moment().add(7, "days"),
    startDate: moment(),
    posting: false
  }

  componentDidMount(){
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { redirect } = this.props
  }

  onDrop = async (acceptedFiles) => {
    this.setState({
      files: _.union(this.state.files, acceptedFiles),
    });
  };

  _checkExtentsion(filename) {
    let ext = filename.split(".").pop();
    ext = ext.toString().toLowerCase();
    console.log("SHOULD THERE BE MORE FILES TYPES ADDED FOR UPLOADING?")
    if (ext === "png" || ext === "jpg" || ext === "jpeg") {
      return true;
    }
    return false;
  }

  removeFile = (fileToRemove) => {
    this.setState((prevState) => ({
      files: prevState.files.filter((file) => file !== fileToRemove),
    }));
  };

  _updateDescription = (content) => {
    this.setState({ description: content });
  };

  addVideo = async () => {
    const { notifyError } = this.props;
    const { videoUrl, videos } = this.state;
    const parsedUrl = parse(videoUrl);

    let videoMeta = null;
    if (parsedUrl.hostname === "vimeo.com") {
      const response = await fetch(
        `${process.env.REACT_APP_VIMEO_URL}${parsedUrl.pathname}?access_token=${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        { mode: "no-cors" }
      ).then((response) => response.json());
      if (response && response.name && response.pictures) {
        videoMeta = {
          title: response.name,
          image: response.pictures.sizes[3].link,
          duration: parseInt(response.duration / 60, 10),
          url: videoUrl,
        };
      }
    }
    //    https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&key=AIzaSyDNyDP1f_mQUTkQWOd1VEtYuGRNC6_mIOc&id=
    if (parsedUrl.hostname === "youtu.be") {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&key=${
          process.env.REACT_APP_YOUTUBE_DATA_KEY
        }&id=${parsedUrl.pathname.replace(
          //`${process.env.REACT_APP_YOUTUBE_URL}${parsedUrl.pathname.replace(
          /^\/+/g,
          "",
          { mode: "no-cors" }
        )}`
      ).then((response) => response.json());

      //youtube-api-for-kp-uploads

      if (response.items) {
        const item = response.items[0];

        const duration = moment
          .duration(item.contentDetails.duration)
          .asMinutes();

        if (item && item.snippet.thumbnails && item.snippet.title) {
          const selectedThumbnail =
            item.snippet.thumbnails.standard ||
            item.snippet.thumbnails.high ||
            item.snippet.thumbnails.medium ||
            item.snippet.thumbnails.default;
          videoMeta = {
            title: item.snippet.title,
            image: selectedThumbnail.url,
            duration: parseInt(duration, 10),
            url: videoUrl,
          };
        }
      }
    }

    if (videoMeta) {
      if (videos.map((video) => video.url).includes(videoMeta.url)) {
        return notifyError("This video has been already attached.");
      }
      this.setState({ videoAdded: true });
      return this.setState({
        videos: _.union(this.state.videos, [videoMeta]),
        videoAdded: false,
      });
    }

    return notifyError("That is an invalid url.");
  };

  updateVideoInput = (e) => {
    this.setState({
      videoUrl: e.target.value,
    })
  }
  
create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

  removeVideo = (video) => {
    const { videos } = this.state;
    let videoIndex = videos.indexOf(video);
    let videoArrayClone = videos.slice(0);
    videoArrayClone.splice(videoIndex, 1);
    this.setState({ videos: videoArrayClone }); 
  }

  _onSubmit = async data => {
    const { files, videos, description, endDate, startDate } = this.state
    const { user, formValues, createAsset, redirect } = this.props
    this.setState({posting: true, blockNavigation: false})

    let promises = files.map(async file => {
      if (file.url) {
        return {
          url: file.url,
          filename: file.name,
          filetype: file.type
        };
      }

      const response = await axios.get( // ask s3 to create signed url
        `${process.env.REACT_APP_API_URL}/s3/signed-url`,
        {
          params: {
            content_type: true,
            filename: `posts/${user.company_id}/${file.name}`,
            filetype: file.type
          }
        }
      );

      let options = { headers: { "Content-Type": file.type } };
      const s3Response = await axios.put(
        response.data.signed_url,
        file,
        options
      )

      let payload = {
        url: s3Response.config.url,
        filename: file.name,
        filetype: file.type
      }

      return payload;
    })

    let newFiles = await Promise.all(promises)

    let payload = {
      user_id: user.id,
      company_id: user.company_id,
      videos: JSON.stringify(videos),
      title: formValues.title, 
      content: JSON.stringify(description),
      files: JSON.stringify(newFiles),
      created_at: startDate
        ? startDate.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      company_sharing: false, //update sharing rule
      status: 'active',
      brand_id: this.create_UUID(),
      end_date: "2099-07-01",
      start_date: startDate
      ? startDate.format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
      all_connections: "yes"
    }

    const assetResponse = await createAsset(payload)

    setTimeout(() => {
      redirect(`/dashboard/company/${user.company_id}`)
    }, 5000)
  }

  render() {
    const { browser, company, dispatch } = this.props;
    const {
      description,
      files,
      thePic,
      modalIsOpen,
      modalPage,
      videos,
      videoUrl,
      videoAdded,
      submitted,
      posting
    } = this.state;

    if (company) {
      return (
        <>
          <RouteLeavingGuard
            when={Boolean(this.state.blockNavigation)}
            // whener={this.state.blockNavigation && !(systemSettings.posting_companies_active && user.company && !user.company.posting_active)}
            navigate={path => history.push(path)}
            shouldBlockNavigation={location => {
              // This case it blocks the navigation when: 
              // 1. the login form is dirty, and 
              // 2. the user is going to 'sign-up' scene.
              //    (Just an example, in real case you might 
              //     need to block all location regarding this case)
              if(location.pathname.startsWith('/dashboard') && (submitted || !getUser().company_admin)) return false;
              if (Boolean(this.state.blockNavigation)) {
                if (location.pathname === 'signup') {
                  return true
                }
              }
              return true
            }}
          />
          <div
            style={{
              height: "89vh",
              overflowY: "auto",
              overflowX: "hidden",
              paddingRight: "20px",
              paddingLeft: "2px",
              marginTop: "0px",
            }}
          >
            <Flex flexDirection="column">
              {browser.lessThan.large ? (
                <Flex
                  mb="32px"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  {}
                </Flex>
              ) : (
                <Flex
                  mb="32px"
                  flexDirection="row"
                  justifyContent="center"
                  // alignItems="center"
                  width="100%"
                >
                  <ReactTooltip />
                </Flex>
              )}
                <form id='addasset-form' name='addasset-form'>
                  <AssetForm
                    browser={browser}
                    company={company}
                    description={company.description}
                    onSubmit={() => console.log('hello addasset-form')}
                    updateDescription={this._updateDescription}
                  />
                  {files.length > 0 || videos.length > 0 ? (
                    videos.length > 0 ? (
                      <VideoAttachments
                        addVideo={this.addVideo}
                        updateVideoInput={this.updateVideoInput || {}}
                        videoUrl={videoUrl}
                        removeVideo={this.removeVideo}
                        videos={videos}
                        videoAdded={videoAdded}
                      />
                    ) : (
                      <FileAttachments
                        files={files}
                        onDrop={this.onDrop}
                        removeFile={this.removeFile}
                      />
                    )
                  ) : (
                    <>
                      <FileAttachments
                        files={files}
                        onDrop={this.onDrop}
                        removeFile={this.removeFile}
                      />
                      <VideoAttachments
                        addVideo={this.addVideo}
                        updateVideoInput={this.updateVideoInput || {}}
                        videoUrl={videoUrl}
                        removeVideo={this.removeVideo}
                        videos={videos}
                        videoAdded={videoAdded}
                      />
                    </>
                  )}
                  
                <Flex
                  mt="10px"
                  justifyContent="center"
                >
                  {
                    posting ?
                      <Flex flexDirection='column'>
                        <FontAwesomeIcon
                          mb="10px"
                          icon={faCog}
                          color="#2FD371"
                          size='2x'
                          spin
                        />
                        <NunitoSans
                          fontSize="13px"
                          fontWeight="bold"
                          color="#2FD371"
                          textAlign="center"
                        >
                          SAVING
                        </NunitoSans>
                      </Flex>
                    :
                      <DashboardSmallCTA
                        // onClick={() => dispatch(submit("addasset-form"))}
                        minWidth="122px"
                        fontSize="17px"
                        innerHeight="40px"
                        onClick={() => {this._onSubmit()}}
                        type='submit'
                      >
                        SAVE
                      </DashboardSmallCTA>
                  }
                </Flex>
              </form>
            </Flex>
          </div>
        </>
      );
    }
    return null;
  }
}

AssetCompanyMain = reduxForm({
  destroyOnUnmount: true,
  form: "addasset-form",
  validate,
  enableReinitialize: true,
})(AssetCompanyMain);

export default connect(
  (state) => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    browser: state.browser,
    company: state.resources.detail.company,
    user: state.resources.detail.user,
    loading: state.resources.detail.loading.user,
    formValues: getFormValues('addasset-form')(state)
  }),
  (dispatch) => ({
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    dispatch,
    actions: bindActionCreators(actions, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateCompany: bindActionCreators(actions.patchCompany, dispatch),
    createAsset: bindActionCreators(actions.createAsset, dispatch),
    // fetchPost: bindActionCreators(actions.fetchPost, dispatch),
    // updatePost: bindActionCreators(actions.patchPost, dispatch)
  })
)(AssetCompanyMain);

let AssetCompanyNav = ({ dispatch }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        ADD ASSET
      </NunitoSans>
      <QuestionModalTrigger
        modalName="add_asset_modal" /* this is the question mark thing next to add asset */
      />
      <InfoModal
        modalName="add_asset_modal" // connects this to QuestionModalTrigger component above
        modalVersion="1.00"
        image={addAssetIcon}
        title="Got Information?"
        text="Add files and/or videos to your company profile to provide easily accessible information to your Partner Network!  Assets have no expiration date."
        extraTexts={["Want to add more Assets?  Upgrade!"]}
      />
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-end"]}
        ></Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);

AssetCompanyNav = connect((state) => ({
  browser: state.browser,
}))(AssetCompanyNav);

export { AssetCompanyNav }
