import React, { Component } from "react";
import _ from "underscore";

import styled from "styled-components";


import { Flex } from "../../common/flexbox";


export class Privacy extends Component {
  
      
  render() {

  const LegalDisclaimerSectionHeader = styled.div`
    font-size: 16px;
    color: #000;
    text-align: justify;
    font-family: NunitoSansBold;
    font-weight: 600;
    margin-top:20px;
    margin-bottom:20px;
    width:80%;
  `;
  
  
  const FirstLegalDisclaimerFirstLine = styled.div`
    font-size: 22px;
    color: #000;
    text-align: justify;
    font-family: NunitoSansBold;
    margin-top:8px;
    margin-bottom:8px;
    width:80%;
  `;

    
  const LegalDisclaimerParagraph = styled.div`
  font-size: 16px;
  color: #000;
  text-align: justify;
  font-family: NunitoSans;
  text-indent: 37px;
  margin-top:8px;
  margin-bottom:8px;
  width:80%;
  `;
  
  
  const LegalDisclaimerParagraphInner = styled.div`
    font-size: 16px;
    color: #000;
    text-align: justify;
    font-family: NunitoSans;
    margin-top:8px;
    margin-bottom:8px;
    margin-left:3%;
    width:80%;
  `;

   
      return (
    <Flex
    flexDirection="column"
    
    >
      <div
      style={{paddingRight: '20px',marginLeft: '10%'}}
      >
<FirstLegalDisclaimerFirstLine><strong>Introduction. Privacy Policy for the KickPost.io website, web and mobile applications</strong></FirstLegalDisclaimerFirstLine>

<LegalDisclaimerSectionHeader><strong>Welcome to the KickPost Corp Privacy Policy.</strong></LegalDisclaimerSectionHeader>

<LegalDisclaimerParagraph>
KickPost Corp (the “Company”) is committed to maintaining robust privacy protections for its users.  Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.  
</LegalDisclaimerParagraph>
<LegalDisclaimerParagraph>
or purposes of this Agreement, “Site” refers to the Company’s website and web and mobile application which can be accessed at www.kickpost.io and app.kickpost.io or via the google play or app store. “Service” refers to the Company’s services accessed via the Site, in which users can create user and company profiles, connect with other companies and share posts with their internal and external connections. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service. By accessing our Site or our Service, you accept our Privacy Policy and Terms of Use, and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy.
</LegalDisclaimerParagraph>
<LegalDisclaimerSectionHeader><strong>I. INFORMATION KICKPOST COLLECTS</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. Personal Information includes your email addresses, phone numbers, website addresses, company descriptions, logos and images, which you submit to us through the registration process at the Site.
</LegalDisclaimerParagraph>
<LegalDisclaimerParagraphInner>
1.   Information collected via Technology. To activate the Service you do not need to submit any Personal Information other than your first and last name email address. To use the Service thereafter, you do need to submit further Personal Information regarding location, employer details, points of contacts including email addresses, phone numbers and website urls.  However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to collect the following information:
·  Pages visited, content preferences, historical patterns for system usage including time being spent on the system, connections made on the system, etc. 

The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.  For example, we store a persistent cookie to keep users logged into their accounts for accessibility purposes, avoiding the need to login each time they use the service.
</LegalDisclaimerParagraphInner>
<LegalDisclaimerParagraphInner>
2.   Information you provide us by registering for an account. In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and generating an access code. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.
</LegalDisclaimerParagraphInner>
<LegalDisclaimerParagraphInner>
3. Children’s Privacy. The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly collect or solicit information from anyone under the age of 13, or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 13 without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at info@kickpost.io. 
</LegalDisclaimerParagraphInner>
<LegalDisclaimerSectionHeader><strong>II. HOW WE USE, SHARE, AND RETAIN INFORMATION</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
Personal Information: Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.
</LegalDisclaimerParagraph>
<LegalDisclaimerParagraph>
In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers.

We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law. 

Non-Personal Information: In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. 
</LegalDisclaimerParagraph>

<LegalDisclaimerParagraph>
Automated processing of information. We use the information and data that you provide and that we have about Members to make recommendations for invitations, connections, content and features that may be useful to you. For example, we use data and information about you to recommend invites to you and your network. Keeping your profile(s) accurate and up to date helps us to make these recommendations more accurate and relevant.
</LegalDisclaimerParagraph>

<LegalDisclaimerParagraph>
This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion. In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.
</LegalDisclaimerParagraph>

<LegalDisclaimerParagraph>
We may employ third-party companies and individuals due to the following reasons:
</LegalDisclaimerParagraph>
<LegalDisclaimerParagraphInner>
  -  To facilitate our Service; 
</LegalDisclaimerParagraphInner>
<LegalDisclaimerParagraphInner>
-  To provide the Service on our behalf; 
</LegalDisclaimerParagraphInner>
<LegalDisclaimerParagraphInner>
-  To perform Service-related services; or 
</LegalDisclaimerParagraphInner>
<LegalDisclaimerParagraphInner>
-  To assist us in analyzing how our Service is used. 
</LegalDisclaimerParagraphInner>

<LegalDisclaimerParagraph>
We want to inform users of the Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
</LegalDisclaimerParagraph>

<LegalDisclaimerParagraph>
We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
</LegalDisclaimerParagraph>

<LegalDisclaimerParagraph>
This Site may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
</LegalDisclaimerParagraph>




<LegalDisclaimerSectionHeader><strong>III. KEEPING YOUR INFORMATION SECURE</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
We implement security measures designed to protect your information from unauthorized access. Your account is protected by your username and system generated security codes and we urge you to take steps to keep your personal information safe by not disclosing access to your registered email address, or the system generated security codes that are requested from our service. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.
</LegalDisclaimerParagraph>


<LegalDisclaimerParagraph><strong>DELETING YOUR INFORMATION</strong></LegalDisclaimerParagraph>
<LegalDisclaimerParagraph>
Deleting your account and information is easy. You can request your account be deleted by logging into your account and going to settings &gt; delete account or you can send us an email at info@kickpost.io.
</LegalDisclaimerParagraph>

<LegalDisclaimerSectionHeader><strong>IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
You have the right at any time to prevent us from contacting you for marketing purposes.  When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional email. You can also indicate that you do not wish to receive marketing communications from us in the [list location of opt-out page, i.e. “Settings” section] of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in the [location of opt-out page] of the Site, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy.
</LegalDisclaimerParagraph>

<LegalDisclaimerSectionHeader><strong>V. LINKS TO OTHER WEBSITES</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.
</LegalDisclaimerParagraph>

<LegalDisclaimerSectionHeader><strong>VI. CHANGES TO OUR PRIVACY POLICY</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
The Company reserves the right to change this policy and our Terms of Service at any time.  We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates.
</LegalDisclaimerParagraph>

<LegalDisclaimerSectionHeader><strong>VII. CONTACT US</strong></LegalDisclaimerSectionHeader>
<LegalDisclaimerParagraph>
If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to info@kickpost.io
Last Updated: This Privacy Policy was last updated on August 10, 2023.
</LegalDisclaimerParagraph>

          
      </div>
      </Flex>
    );
  }
}

export default Privacy;
