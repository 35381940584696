import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
import _ from "underscore";

import axios from "axios";

//utils
import { getUser,setUser, clearUser } from "../../../../utils/auth";
// import axios from 'axios';
import Modal from 'react-modal';
import {
  searchPosts,
  searchIncentives,
  updatePostSearchResults,
  updateAssetSearchResults,
  toggleCategory
} from "../../../../actions/search";
import { actions } from "../../../../actions/resources";
import { push } from "react-router-redux";
import { addressBottomPart } from "../../../../utils/helpers";
import { H2, NunitoSans, UnderlinedNunitoSans, NunitoSansBold, NunitoSansExtraBold } from "../../../common/typography";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../../common/flexbox";
import { ImageTreatment, NewImageTreatment } from "../../../common/ui";
import { OutlineFlex, DashboardSmallCTA, VendorRequest, InputStyled, DashboardSmallToggleCTA } from "../../../common/forms";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

import { AbsoluteDiv } from "../../../common/absolute";

import {
  RegularDepartmentRowGroup,
  PostRowGroup,
  IncentiveRowGroup,
  PhoneRowSmall,
  EmailRowSmall,
  WebsiteRowSmall,
  MapRowSmall

} from "../../../common/rows";
//images
import { defaultProfile } from "../../../../utils/defaultLogos";

let timeout = null;

const UserTypesModal = (props) => {


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: props.isMobile ? '50%' : "600px",
      // minHeight: "312px",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '10px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  return (
    <Modal
      isOpen={props.modalOpen}
      onRequestClose={() => props.handleCloseRequest()}
      style={customStyles}
      contentLabel={`User Types Modal`}
      ariaHideApp={false}
    >
      <Flex
        justifyContent="center"
        // alignItems="center"
        flexDirection="column"
      >
        <Flex
          width="100%"
          justifyContent="flex-end"
        >
          <IconBox
            cursor="pointer"
            fontSize={["12px"]}
            color="#C7C7C7"
            onClick={() => props.handleCloseRequest()}
          >
            thinx
          </IconBox>
        </Flex>

        <NunitoSansExtraBold
          fontSize="16px"
          color="#428BF4"
          textAlign="center"
        >
          ACCOUNT ROLES
        </NunitoSansExtraBold>

        <NunitoSans
          fontSize="18px"
          color="#000000"
          textAlign="center"
          style={{ marginTop: "10px", marginBottom: '25px' }}
        >
          To update your role please contact the account owner or a manager.
        </NunitoSans>

        <NunitoSansBold
          fontSize="18px"
          color="#000000"
        >
          ACCOUNT OWNER
        </NunitoSansBold>
        <NunitoSans
          fontSize="18px"
          color="#000000"
        >
          Everything a manager can do plus:
        </NunitoSans>
        <NunitoSans
          fontSize="18px"
          color="#000000"
        >
          + Manage account settings, features and billing for the account
        </NunitoSans>

        <NunitoSansBold
          fontSize="18px"
          color="#000000"
          style={{ marginTop: '20px' }}
        >
          MANAGER
        </NunitoSansBold>
        <NunitoSans
          fontSize="18px"
          color="#000000"
        >
          Everything a user can do plus:
        </NunitoSans>
        <NunitoSans
          fontSize="18px"
          color="#000000"
        >
          + Manage the team and network for the account
        </NunitoSans>

        <NunitoSansBold
          fontSize="18px"
          color="#000000"
          style={{ marginTop: '20px' }}
        >
          USER
        </NunitoSansBold>
        <NunitoSans
          fontSize="18px"
          color="#000000"
          style={{ marginBottom: '10px' }}
        >
          Can only view information
        </NunitoSans>
      </Flex>
    </Modal>
  );
};

export class ProfileDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTypesModalOpen: false,
      searchValue: '',
      selectedCategory: 'Posts',
      isConnection: false,
      userCompany: {},
      company: {}
    
  }
}
  //you are connected to yourself, let's see your posts and incentives 
   componentDidMount() {
    this._search(null);
    const { userCompany, user } = this.props;
    const { fetchConnections, match } = this.props;
    let company =  axios.get(`${process.env.REACT_APP_API_URL}/companies/${getUser().company_id}`)
      .then(respond => {
          this.setState({ company: respond.data });
          
        let vendor_ids = [];

        if (!getUser()) return;
        const activeConnections = fetchConnections({
          company_id: respond.data.id,
          status: 'active'
        }).then((res)=>{

          if(res){
            res.payload.forEach(reorder);

            function reorder(v, i, a) { //the results returned from the table are correct but not aligned properly
              if (v.company_id === getUser().company_id ) { //this will extract the company_ids that a company is connected with
                vendor_ids.push(v.vendor_id);
              } else {
                vendor_ids.push(v.company_id);
              }
            }
            let isConnection = vendor_ids.includes(user.company_id);          
            this.setState({ isConnection });
          }
          
        });


      });
    
  }

  companyDidUpdate() {
    this._search = _.debounce(this._search.bind(this), 400);
  }

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }

  _openLoginAs = async data => {
    const {   
      createSession, 
      createAdminsession, 
      redirect, 
      user,
      fetchUser
    } = this.props;
    
    const response = await createAdminsession({"email":user.email,"admin":getUser().id});
      if (response && response.payload.email&& response.payload.id) {
        let LeEmail = user.email;
        localStorage.setItem("LeEmail",LeEmail);
        let domain = LeEmail.split("@")[1];
        localStorage.setItem("LeDomain",domain);
        localStorage.setItem("token",response.payload.email_verification_code);
    
         setUser(response.payload);
         return redirect( `/login-verification?token=${response.payload.email_verification_code}`);
      }  
    }   

  _updateSearch = input => {
    //console.log("_updateSearch", this.state.searchValue, input)
    if(this.state.searchValue && this.state.searchValue === input){
      return {};
    } else if(this.state.searchValue===input){
      return {}
    }
    else  {
      clearTimeout(timeout);
      this.setState({ searchValue: input });
      timeout = setTimeout(() => this._search(input), 1000);
    }

  };

  _search = input => {

//console.log("cons src/components/dashboard/profile/detail/index.js")
    const { handleSearch } = this.props;
    if(!input){
      input = "";
    }

    if (input.length === 0 || input.length > 2) {
      handleSearch(input);
    }
  }

  _toggleCategory = category => {
    const {
      browser,
      emails,
      phoneNumbers,
      posts,
      incentives,
      websites,
      user,
      searchPosts,
      searchIncentives,
      redirect,
      companyDepartments,
      systemSettings,
      companySettings,
      // isConnection,
      isRequestPending,
      createConnection,
      
      userCompany
    } = this.props;
    const { searchValue, isConnection } = this.state;
    this.setState({ selectedCategory: category });
    this._search(searchValue);
    
    
  }


  render() {
    const { searchValue, selectedCategory, userTypesModalOpen } = this.state;

    const {
      browser,
      emails,
      phoneNumbers,
      posts,
      incentives,
      websites,
      user,
      searchPosts,
      searchIncentives,
      redirect,
      companyDepartments,
      systemSettings,
      companySettings,
      // isConnection,
      isRequestPending,
      createConnection,
      
      userCompany
    } = this.props;
    
    const { isConnection } = this.state;

    //console.log("src\components\dashboard\profile\detail\index.js render userCompany", userCompany);
    console.log("src\components\dashboard\profile\detail\index.js render isConnection", isConnection);
    
    
    const gotUser = getUser(); 

    
    if (companyDepartments && companyDepartments.length > 0 && user.departments) {
      let compDeptIds = companyDepartments.map(dept => dept.id);
      user.departments = user.departments.filter(dept => compDeptIds.includes(dept.id));
    }

    const {company} = this.state;

    return (
      <div style={{ height: "89vh", overflow: "auto", paddingRight: "25px", marginTop: "25px" }}>
        <Flex
          alignItems="center"
        >
          {browser.lessThan.small ? (
            <ImageTreatment
              key={[`IT` + `${user.first_name}${user.last_name}`]}
              img={(user && user.avatar) || (defaultProfile(user.id, `${user.first_name} ${user.last_name}`))}
              blurRadius={"12px"}
              imageInset={0}
              borderRadius={48}
              innerBorderRadius={40}
              shadowOffset={0.1375}
              padding={8}
              height={80}
              width={80}
              blurOpacity={0.5}
            />
          ) : (
            // <ImageTreatment
            //   img={(user && user.avatar) || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
            //   blurRadius={"15px"}
            //   imageInset={0}
            //   borderRadius={70}
            //   innerBorderRadius={60}
            //   shadowOffset={0.1175}
            //   padding={10}
            //   height={120}
            //   width={120}
            //   blurOpacity={0.5}
            // />
            <NewImageTreatment
              key={[`NIT` + `${user.first_name}${user.last_name}`]}
              img={(user && user.avatar) || (defaultProfile(user.id, `${user.first_name} ${user.last_name}`))}
              height={150}
              width={150}
              borderRadius="50%"
            />

          )}

          <Flex
            flexDirection="column"
            // alignItems="center"
            ml="60px"
          >
            <NunitoSansBold
              fontSize="30px"
              color="#000000"
            >
              {user ? `${user.first_name} ${user.last_name}` : null}
            </NunitoSansBold>
            <NunitoSans
              fontSize="18px"
              color="#000000"
            >
              {user.position}
              {/* <span
                onClick = {()=> user.company.pseudoName ? null : redirect(`/dashboard/company/${user.company.id}`)}
                style={ user.company.pseudoName ? {fontWeight: 'bold', textDecoration: 'underline'} : {cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline'}}
                >{user.company.pseudoName || user.company.name}</span> */}

            </NunitoSans>
            {
              user.location && user.location.place_name
                ?
                <NunitoSans
                  fontSize="16px"
                  color="#000000"
                >
                  {addressBottomPart(user.location && user.location.place_name)}
                </NunitoSans>
                :
                null
            }

            <NunitoSansBold
              fontSize="18px"
              style={user && user.company && user.company.pseudoName ? { textDecoration: 'underline' } : { cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => user.company.pseudoName ? null : redirect(`/dashboard/company/${user.company.id}`)}
            >
              {user.company.pseudoName || user.company.name}
            </NunitoSansBold>


            {(user && user.company && user.company.pseudoName)  ?  
            <NunitoSansBold
              fontSize="18px"
              style={ { textDecoration: 'underline' } }
              onClick={() => null}
            >
              {/* {user.company.pseudoName } */}
            </NunitoSansBold>
             : 
            <NunitoSansBold
              fontSize="18px"
              style={ { cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => redirect(`/dashboard/company/${user.company.id}`)}
            >
              {/* {user.company.name} */}
            </NunitoSansBold> }

            {gotUser.company_admin===true && gotUser.company_id === "d6212069-224f-435d-a999-bfb7beb487ae"   ?  
            
            <NunitoSansBold
              fontSize="18px"
              style={user && user.company && user.company.pseudoName ? { textDecoration: 'underline' } : { cursor: 'pointer', textDecoration: 'underline' }}
              onClick={this._openLoginAs}
            >
              Login As {user.first_name || user.last_name}
            </NunitoSansBold>
             : 
            <NunitoSansBold
              fontSize="18px"
              style={ { cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => redirect(`/dashboard/company/${user.company.id}`)}
            >
              {/* {user.company.name} */}
            </NunitoSansBold> }








            <UserTypesModal
              modalOpen={userTypesModalOpen}
              handleCloseRequest={() => this.setState({ userTypesModalOpen: false })}
              isMobile={browser.lessThan.large}
            />



            {
              user.company_id === getUser().company_id &&
                user.company.settings &&
                !user.company.settings.is_single_user_company
                ?
                <Flex
                  alignItems='center'
                >
                  <FontAwesomeIcon
                    icon={user.company.settings.company_goal === 'share_info' ? faPaperPlane : faNewspaper}
                    color="#CACCC6"
                    size="1x"
                    style={{ marginRight: '10px' }}
                    data-tip={user.company.settings.company_goal === 'share_info' ? "Goal: Share Info" : "Goal: Receive Info"}
                  />

                  <ReactTooltip id='rtt' key={`rtt`} />
                  <p
                    style={{
                      padding: "0px 5px",
                      backgroundColor: "#CACCC6",
                      fontSize: '12px',
                      color: "#FFFFFF",
                      fontWeight: 600,
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => this.setState({ userTypesModalOpen: true })}
                  >
                    {
                      user.company_owner
                        ?
                        'Account Owner'
                        :
                        user.company_admin
                          ?
                          "Manager"
                          :
                          "User"
                    }
                  </p>
                </Flex>
                :
                null

            }

            {
              user && user.company &&user.company.settings &&
                user.company.settings.is_single_user_company &&
                user.company.settings.industry_categories &&
                user.company.settings.industry_categories.length
                ?
                <Flex>
                  <FontAwesomeIcon
                    icon={user.company.settings.company_goal === 'share_info' ? faPaperPlane : faNewspaper}
                    color="#CACCC6"
                    size="1x"
                    style={{ marginRight: '10px' }}
                  />
                  {
                    user.company.settings.industry_categories.map((category, index) => (
                      <p
                        style={{
                          padding: "0px 5px",
                          backgroundColor: "#428BF4",
                          marginLeft: index !== 0 ? "10px" : '0px',
                          fontSize: '12px',
                          color: "#FFFFFF",
                          fontWeight: 600,
                          textAlign: 'center'
                        }}
                      >
                        {category.name}
                      </p>
                    ))

                  }
                </Flex>
                :
                null
            }

            <Flex>

              {
                user.company.pseudoName && !isConnection
                  ?
                  <DashboardSmallCTA
                    key={`DSTCA3`}
                    color="#2FD371"
                    reversed={true}
                    mt="15px"
                    minWidth={["160px"]}
                    disabled={isRequestPending}
                    onClick={() => isRequestPending ? {} : createConnection()}
                  >
                    {/* {isRequestPending ? "PENDING" : "CONNECT"} */}
                  </DashboardSmallCTA>
                  :
                  null
              }

            </Flex>

          </Flex>

        </Flex>
        <Flex
          flexDirection="column"
          pb={40}
          alignItems="center"
          justifyContent="center"
        >

          {/* <Box pb={["25px", "35px"]} ml="20px">
          {browser.lessThan.small ? (
            <ImageTreatment
              img={(user && user.avatar) || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
              blurRadius={"12px"}
              imageInset={0}
              borderRadius={48}
              innerBorderRadius={40}
              shadowOffset={0.1375}
              padding={8}
              height={80}
              width={80}
              blurOpacity={0.5}
            />
          ) : (
            <ImageTreatment
              img={(user && user.avatar) || defaultProfile(user.id, `${user.first_name} ${user.last_name}`)}
              blurRadius={"15px"}
              imageInset={0}
              borderRadius={70}
              innerBorderRadius={60}
              shadowOffset={0.1175}
              padding={10}
              height={120}
              width={120}
              blurOpacity={0.5}
            />
            
          )}
          
        </Box> */}
          {/* <H2 style={{"margin-left": "20px", textAlign: 'center'}}>{user ? `${user.first_name} ${user.last_name}` : null}</H2> */}
          {/* <NunitoSansBold
          pt={[0, "3px"]}
          fontSize={["16px", "16px"]}
          fontWeight="normal"
          color="black"
          style={{"margin-left": "20px"}}
        >
          {user.position}
        </NunitoSansBold>
        <NunitoSansBold
          pt={[0, "6px"]}
          fontSize={["16px", "16px"]}
          fontWeight="normal"
          color="gray"
          onClick = {()=> user.company.pseudoName ? null : redirect(`/dashboard/company/${user.company.id}`)}
          style={ user.company.pseudoName ? {} : {cursor: 'pointer'}}
        >
         {user.company.pseudoName || user.company.name}
        </NunitoSansBold > */}
          <Box height={["35px"]} />
          {browser.lessThan.large && !(systemSettings.privacy_active && companySettings.company_private && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) ? (
            <Box width="100%">
              {(typeof websites.map === "function") ?
                websites.map((website, i) => {
                  return <WebsiteRowSmall key={i} website={website} />;
                }) : null
              }
            </Box>
          ) : null}
          {browser.lessThan.large ? (
            <Box width="100%">
              {typeof phoneNumbers.map === "function" ? phoneNumbers.filter(num => num.status === 'active').map((phoneNumber, i) => {
                return <PhoneRowSmall key={i} phoneNumber={phoneNumber} />;
              }) : null}
            </Box>
          ) : null}
          {browser.lessThan.large ? (
            <Box width="100%">
              <EmailRowSmall
                key={0}
                email={{ body: user.email, label: "Primary" }}
              />
              {typeof emails.map === "function" ? emails.map((email, i) => {
                return <EmailRowSmall key={i + 1} email={email} />;
              }) : null}
            </Box>
          ) : null}
          {browser.lessThan.large && user.location ? (
            <Box width="100%">
              {user.location ? (
                <MapRowSmall
                  address={(user.location && user.location.address) || ""}
                  placeName={(user.location && user.location.place_name) || ""}
                  text={(user.location && user.location.text) || ""}
                />
              ) : null}
            </Box>
          ) : null}

          {
            (isConnection && (isConnection === false))
              ?
              <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
                mt="60px"
                style={{ backgroundColor: "#F2F2F2", height: "60vh", borderRadius: '17px', borderShadow: '0px 0px 6px #00000029' }}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt='100px'
                  style={{ width: '94px', height: '94px', border: '1px solid #A3A99E', borderRadius: '50%', backgroundColor: 'white' }}
                >
                  <IconBox
                    color="#D3D3D3"
                    fontSize="94px"
                  >
                    lock_icon
                  </IconBox>

                </Flex>

                <NunitoSansExtraBold
                  color="#A3A99E"
                  fontSize="15px"
                  style={{ marginTop: '20px' }}
                >
                  CONNECT TO SEE DETAILS
                </NunitoSansExtraBold>

              </Flex>
              :
              null
          }
{ /* isConnection && (userCompany && userCompany.paid && userCompany.paid === true && user.company.paid === true) || user.company.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || user.company.id === getUser().company_id || user.company.paid === true */  }
          {(isConnection && (isConnection === true))
            
              ?
              <Flex mt="55px" position="relative" width={["100%"]}>
                <AbsoluteDiv
                  display={["block"]}
                  // left={["10px"]}
                  // bottom={"0px"}
                  top={["4px", "6px"]}
                  width={["40px"]}
                >
                  <IconBox
                    // pl={["15px"]}
                    // pb={["15px"]}
                    // pt={["15px"]}
                    fontSize={["18px", "18px"]}
                    color="#c3c3c3"
                  >
                    search
                  </IconBox>

                </AbsoluteDiv>
                <InputStyled
                  key={[`dashboard-search` + `${user.first_name}${user.last_name}`]}
                  placeholder={`Search ${selectedCategory} from ${user.first_name} ${user.last_name}`}
                  onChange={e => this._updateSearch(e.target.value)}
                  value={this.state.searchValue||""}
                  innerRef={input => (this.searchInput = input)}
                  style={{ paddingLeft: '45px' }}
                  width="100%"
                  name="dashboard-search"
                  type="text"
                  border={0}
                  autoFocus={true}
                  bg={["lightGray"]}
                  borderRadius={"11px"}
                  // pt={["3px"]}
                  px={["5px"]}
                  mt={["0px"]}
                  mb={["5px"]}
                  height={[31, 40, 40, 40]}
                  // fontWeight={400}
                  fontSize={[14, 20, 20, 20]}
                  lineHeight={1.5}
                  color={"#000000"}
                  borderBottom={[0]}
                />
                {
                  searchValue.length
                    ?
                    <AbsoluteDiv
                      display={["block"]}
                      right={["10px"]}
                      top={["4px", "6px"]}
                      width={["40px"]}
                    >
                      <ClickCursorFlex
                        height={["100%", "100%", "100%"]}
                        alignItems={["center"]}
                        onClick={() => this._updateSearch('')}
                        justifyContent={[
                          "flex-start",
                          "flex-start",
                          "flex-start",
                          "center"
                        ]}
                      >
                        <IconBox
                          cursor="pointer"
                          // pl={["15px"]}
                          // pb={["15px"]}
                          // pt={["15px"]}
                          fontSize={["17px"]}
                          color="#c3c3c3"
                        >
                          thinx
                        </IconBox>
                      </ClickCursorFlex>
                    </AbsoluteDiv>
                    :
                    null
                }



              </Flex>
              :
              null
          }

          {
            isConnection
              ?
              <Flex
                width="100%"
                mt="25px"
              >
                <Flex>
                  {
                    ((user.company.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)  || ( isConnection) )
                      ?
                      <DashboardSmallToggleCTA
                        key={`DSTCA1`}
                        id={`DSTCA1`}
                        // style={{marginLeft:'15px'}}
                        selected={selectedCategory === 'Posts'}
                        onClick={() => this._toggleCategory('Posts')}
                        fontSize={["12px", "12px", "17px", "17px"]}
                      >
                        POSTS
                      </DashboardSmallToggleCTA>
                      :
                      null
                  }

                  {
                    // ((userCompany && userCompany.paid && userCompany.paid === true && user.company.paid === true) && (systemSettings.rewards_active &&  companySettings && (companySettings.receive_incentives || companySettings.receive_vendor_incentives )) && (user.company.settings && (user.company.settings.create_incentives || user.company.settings.create_vendor_incentives)))
                    ((gotUser.company_id ===
                      process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) || ((gotUser.sales_id!==null || gotUser.company_admin===true) && isConnection && (systemSettings.rewards_active &&  incentives && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) ))
                      ?
                      <DashboardSmallToggleCTA
                        id={`DSTCA`}
                        key={`DSTCA`}
                        style={{ marginLeft: '15px' }}
                        selected={selectedCategory === 'Incentives'}
                        onClick={() => this._toggleCategory('Incentives')}
                        fontSize={["12px", "12px", "17px", "17px"]}
                      >
                        INCENTIVES

                      </DashboardSmallToggleCTA>
                      :
                      null
                  }


                </Flex>

              </Flex>
              :
              null
          }
          {((user.company.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID)  || ( isConnection) )
            ?
            <>
              <Box height={["60px", "40px"]} />
              {(this.state.selectedCategory==="Posts")? <PostRowGroup
                postOwner={user.id === getUser().id}
                hideHeader={true}
                posts={posts}
                hideFirstLastDash={true}
                redirect={redirect}
              />:null}
              

              {(this.state.selectedCategory==="Posts"&&!posts.length) ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5> : null}
            </>
            : 
            null

          }
          {
            ((gotUser.company_id ===
              process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) || ((gotUser.sales_id!==null || gotUser.company_admin===true) && isConnection && (systemSettings.rewards_active &&  incentives && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) ))
            ?
            <>
              <Box height={["60px", "40px"]} />
              {(this.state.selectedCategory==="Incentives")? <IncentiveRowGroup
                incentiveOwner={user.id === getUser().id}
                posts={incentives}
                hideHeader={true}
                hideFirstLastDash={true}
                redirect={redirect}
                key={`IRGv`+incentives.id}
              />: null}
              
              

              {(!incentives.length && this.state.selectedCategory==="Incentives") ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5> : null}
            </>
            :
            null

          }

        </Flex>
      </div>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    redirect: bindActionCreators(push, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    searchP: bindActionCreators(searchPosts, dispatch),
    searchI: bindActionCreators(searchIncentives, dispatch),
    createSession: bindActionCreators(actions.createSession, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    createAdminsession: bindActionCreators(actions.createAdminsession, dispatch),
    fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch)
  })
)(ProfileDetail);
/*
for admin abilities might need to replace
user.company with
getUser().company

*/