import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "../../../common/navigation";
import _ from "underscore";
import {groupBy} from 'lodash';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
//import { parse } from "csv";
import { parse } from "../../../../../node_modules/csv-parse/dist/esm/sync";
import { CSVLink, CSVDownload } from "react-csv";


//utils
import { getUser } from "../../../../utils/auth";
//actions
import { error, success } from "../../../../actions/alerts";
import { mainContentScrollTop } from "../../../../actions/dashboardPresentation";
import { companyTotal, selectRow } from "../../../../actions/post";
import { actions } from "../../../../actions/resources";
//components
import { DateBubble, InputStyled, Eyebrow,SingleDatePickerWrapper, DashboardCTA,DashboardSmallCTA } from "../../../common/forms";
import { renderDante, renderCondensedField, renderCondensedFieldSelect, renderSmallField } from "../../../common/forms";
import { Flex, Box,IconBox, ClickCursorFlex } from "../../../common/flexbox";
import { AbsoluteDiv } from "../../../common/absolute";
import FileAttachments from "../../posts/new/files";
// import VideoAttachments from "./videos";
// import PrivatePost from "./privatepost";
import { MobileNavigationalBubble, NonDottedDropzone } from "../../../common/ui";
import { AudienceRow } from "../../../common/rows";
import { AudienceMultiRowGroup } from "../../../common/rows";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faMinusCircle, faCog, faCheckCircle, faCaretDown, faCaretUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NunitoSans } from "../../../common/typography";
import currencies from "../../../../constants/currency";
import CsvDropzoneModal from "../../../common/csvDropzoneModal";

// import robSchniederImage from "images/rob-schnieder.png";
// import samsungImage from "images/samsung_square.png";
// import { type } from "os";

const laptopBubbleWidth = 47.3;
const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = "Title cannot be blank.";
  }

  if (values.title&&values.title.length>75) {
    errors.title = "Title is too long.";
  }

  // if (!values.po_number) {
  //   errors.po_number = "PO NUMBER cannot be blank.";
  // }

  return errors;
};



class AddOneToOneMain extends PureComponent {
  state = {
    csvDropOpen: false,
    titleCharCount: 75,
    productDropdownOpen: false,
    processing: false,
    addedGroups: [],
    uploading: false,
    clicked: false,
    submitted: false,
    selectedAudienceFilters: [],
    searchModeActive: false,
    tableData: [],
    rulesClicked: true,
    duplicates: [],
    rules: {
      program_reward_cap: {
        name: 'PROGRAM REWARD CAP',
        inputBox: true,
        inputValue: 0,
        selected: false,
        lastRule: false,
        placeholder: `${currencies[this.props.systemSettings.currency].symbol} Amount`,
        inputType: 'currency',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.program_reward_cap
      },
      program_quantity_cap: {
        name: 'PROGRAM QUANTITY CAP',
        inputBox: true,
        inputValue: 0,
        selected: false,
        lastRule: false,
        placeholder: 'Enter Qty.',
        inputType: 'number',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.program_quantity_cap
      },
      rep_reward_cap: {
        name: 'REP REWARD CAP',
        inputBox: true,
        inputValue: 0,
        selected: false,
        lastRule: false,
        placeholder: `${currencies[this.props.systemSettings.currency].symbol} Amount`,
        inputType: 'currency',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.rep_reward_cap
      },
      rep_quantity_product_cap: {
        name: 'REP QUANTITY PRODUCT CAP',
        inputBox: true,
        inputValue: 0,
        selected: false,
        lastRule: false,
        placeholder: 'Enter Qty.',
        inputType: 'number',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.rep_quantity_product_cap
      },
      reward_per_product_cap: {
        name: 'REWARD PER PRODUCT CAP (PER REP)',
        inputBox: false,
        inputValue: 0,
        selected: false,
        lastRule: false,
        placeholder: `${currencies[this.props.systemSettings.currency].symbol} Amount`,
        inputType: 'currency',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.reward_per_product_cap
      },
      quantity_per_product_cap: {
        name: 'QUANTITY PER PRODUCT CAP (PER REP)',
        inputBox: false,
        inputValue: 0,
        selected: false,
        lastRule: true,
        placeholder: 'Enter Qty.',
        inputType: 'number',
        show: this.props.systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone.quantity_per_product_cap
        
      }
    }
  };

  componentDidUpdate(prevProps, prevState){
    if(this.props.errors.errorNotify && this.state.submitted){
      this.props.notifyError(this.props.errors.errorNotify)
      this.props.errors.errorNotify = ''
      this.setState({submitted: false})
    }
  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user, rules, tableData, initialValues } = this.props;

    let values = {...initialValues};

    if (!user) {
      await fetchUser(getUser().id);
    }
    if (rules){
      this.setState({rules: rules})
      Object.keys(rules).forEach(rule=> rules[rule].inputBox ? values[rule+'_value']=rules[rule].inputValue : null)
    }
    if(tableData){
      this.setState({tableData: tableData})
      console.log(tableData)
      const keys=["mnfg_part_number", "description", "sku", "reward_value", "cap_per_product", "qty_per_product"];
      
      tableData.forEach(data=>keys.forEach(key=>values[data.formName+key]=data[key]))
    }
    this.props.initialize(values)
    // fetchConnections({ company_id: getUser().company_id, post: true });
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows } = this.props;
    let rows = companyRows.concat(departmentRows, userRows);
    return rows;
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;

    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers
    };
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };


  renderEditable= cellInfo => {
    const {formValues} = this.props;
    console.log(cellInfo.original.formName + cellInfo.column.id)
    let formatter = {};
    if(cellInfo.column.id === 'reward_value' ||cellInfo.column.id === 'cap_per_product'){
      formatter = this.props.currencyMask(cellInfo.column.id === 'cap_per_product'? 0 : 2);
    }

    if(cellInfo.column.id === 'qty_per_product'){
      formatter = this.props.numberMask;
    }
    let fieldStyle = {
      textAlign: "center"
    }
    if(cellInfo.column.id === 'reward_value' && formValues && !formValues[cellInfo.original.formName + cellInfo.column.id]){
      fieldStyle.color = 'red'
    }

    

    if((cellInfo.column.id === 'mnfg_part_number' || cellInfo.column.id === 'sku') && formValues && this.state.duplicates.includes(formValues[cellInfo.original.formName + cellInfo.column.id])){
      fieldStyle.color='#FE4A49'
    }

    return (
      

      <Field
      name={cellInfo.original.formName + cellInfo.column.id}
      component="input"
      placeholder={cellInfo.column.id==='description' ? 'No product description':"Add Value"}
      type="text"
      style={fieldStyle}
      {...formatter}
      />
    );
  }

  renderDelete= cellInfo => {
    const {tableData} = this.state;
    const {formValues, systemSettings} = this.props;
    console.log(cellInfo)
    return (
      <div
        style={{ color: '#FE4A49', cursor:"pointer" }}
        
        onClick={e => {
          delete formValues[cellInfo.original.formName+"mnfg_part_number"]
          this.props.initialize(formValues)
          
          let partNumbersKeys = Object.keys(formValues).filter(item=>item.includes('mnfg'));
          let skuKeys = Object.keys(formValues).filter(item=>item.includes('sku'));
          let partNumbers=[];
          let skuNumbers = [];
          partNumbersKeys.forEach(key=>partNumbers.push(formValues[key]));
          skuKeys.forEach(key=>skuNumbers.push(formValues[key]));
          let dupIds = [];

          if(systemSettings.part_number_active){
            dupIds = _.uniq(skuNumbers.filter(item=>skuNumbers.indexOf(item)!==skuNumbers.lastIndexOf(item)))
          }else{
            dupIds = _.uniq(partNumbers.filter(item=>partNumbers.indexOf(item)!==partNumbers.lastIndexOf(item)))
          }
          
          
          this.setState({duplicates: dupIds})
        
          this.setState({tableData: tableData.filter((_,i)=> i !==cellInfo.index)})

        }
      }
        
      >X</div>
    );
  }

  _processCSVData = (data) => {
    const {notifyError, notifySuccess, formValues, systemSettings} = this.props;
    const skuActive = systemSettings.part_number_active;
    let newData = [...this.state.tableData];

    data = data.filter(item=>item[0])
    data.shift();
    // if(data[0][0].trim().includes(' ')){
    //   data.shift()
    // }
    
    let duplicates = [];

    if(skuActive){

      duplicates = data.map(item=>item[5]).filter((item, index, arr)=> arr.indexOf(item) !== arr.lastIndexOf(item));
      if(duplicates.length){
        this.setState({uploading: false});
        return notifyError(
          `${duplicates.length} duplicate ${systemSettings.part_number_name} found out of ${data.length} rows. Please fix and try again. `
        );
      }

    }else{
      
      duplicates = data.map(item=>item[0]).filter((item, index, arr)=> arr.indexOf(item) !== arr.lastIndexOf(item));
      if(duplicates.length){
        this.setState({uploading: false});
        return notifyError(
          `${duplicates.length} duplicate product ids found out of ${data.length} rows. Please fix and try again. `
        );
      }
    }
    
    
    const keys=["mnfg_part_number", "description", "reward_value", "cap_per_product", "qty_per_product"];
    skuActive && keys.push("sku");
    let newValues = {...formValues};

    for(let i=0; i < data.length; i++){

      const rowData = {
        [keys[0]]: data[i][0] || '',
        [keys[1]]: data[i][1] || '',
        [keys[2]]: parseFloat(data[i][2]?.replace(/[^\d.-]/g, '') || 0),
        [keys[3]]: parseInt(data[i][3]?.replace(/[^\d.-]/g, '') || '0') || '',
        [keys[4]]: parseInt(data[i][4]?.replace(/[^\d.-]/g, '') || '0') || '',
      };

      if(skuActive) {
        rowData["sku"] = data[i][5] || '';
      }

      const row = {
        formName: i,
        delete_btn: 'X',
        status:'',
        ...rowData
      };

      newData.push(row);

      
      keys.forEach((key)=> newValues[i+key] = rowData[key]);


    }

    this.props.initialize(newValues);
    
    let rewardValues = newData.filter(item=> !item.reward_value || isNaN(item.reward_value))
    this.setState({tableData: newData})
    notifySuccess(`${newData.length} products uploaded successfully. ${rewardValues.length ? rewardValues.length+' products with no reward value' : ''}`)
    this.setState({csvDropOpen: false ,uploading: false})

  }

  _uploadCSV = files =>{
    const {notifyError} = this.props;
    const file = files[0];

    if(typeof file === "undefined"){
      this.setState({uploading: false});
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }
    
    const reader = new FileReader();

    this.setState({uploading: true})
    
    reader.onload = () => {
      this._processCSVData(parse(reader.result));
    }

    reader.readAsText(file);    
  }

  

  _assignSameValue = () =>{
    const {formValues, notifyError} = this.props;
    const {tableData} = this.state;
    //  console.log(formValues , tableData);

    if(tableData.length<1) return notifyError("There is no table data.");

    if(formValues && formValues.same_value && formValues.same_value > 0){
      const theValue = formValues.same_value;

      let newValues = {...formValues};

      tableData.forEach(data=> newValues[data.formName+'reward_value']=theValue);

      this.props.initialize(newValues);

    }else{
      return notifyError('The Value should be larger than 0.');
    }
  }

  _removeTableData = ()=>{
    const {formValues, notifyError} = this.props;
    console.log(formValues)
    const newValues = _.pick(formValues, ["billing_contact", "title", "po_number", "program_quantity_cap_value", "program_reward_cap_value", "rep_quantity_product_cap_value", "rep_reward_cap_value"]);
    this.props.initialize(newValues);
    this.setState({tableData: [], addedGroups: []});
  }

  _addTableData = async (queryInfo, categories = false)=>{
    const {formValues, notifyError, systemSettings} = this.props;
    const { tableData, addedGroups} = this.state;

    this.setState({processing: true});

    


    let data = [...tableData];
    let partNumbersKeys = Object.keys(formValues).filter(item=>item.includes('mnfg'));
    let skuKeys = Object.keys(formValues).filter(item=>item.includes('sku'));
    let partNumbers=[];
    let skuNumbers = [];
    partNumbersKeys.forEach(key=>partNumbers.push(formValues[key]));
    skuKeys.forEach(key=>skuNumbers.push(formValues[key]));
    let dupIds = [];

    if(systemSettings.part_number_active){
      dupIds = _.uniq(skuNumbers.filter(item=>skuNumbers.indexOf(item)!==skuNumbers.lastIndexOf(item)))
    }else{
      dupIds = _.uniq(partNumbers.filter(item=>partNumbers.indexOf(item)!==partNumbers.lastIndexOf(item)))
    }

    this.setState({duplicates: dupIds})

    if(!systemSettings.part_number_active && partNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
      return notifyError("Duplicate part number. Please fix and try again.")
    }else if(systemSettings.part_number_active && skuNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
      return notifyError(`Duplicate ${systemSettings.part_number_name}. Please fix and try again.`)
    }
    let newValues = {...formValues};
    
    let newData = [];
    
    const query = categories ? `category=${queryInfo}` : `partner_id=${queryInfo}`;

    const products = await fetch(`${process.env.REACT_APP_API_URL}/products?${query}`).then(res=>res.json());

    
    products.forEach(product=>{
      newData.push({
        formName: product.id,
        delete_btn: 'X',
        status:'',
        mnfg_part_number: product.part_number,
        sku: product.sku,
        description: product.description,
        reward_value:'',
        cap_per_product:'',
        qty_per_product: ''
      });

      newValues[product.id+"mnfg_part_number"] = product.part_number;
      newValues[product.id+"description"] = product.description;
      newValues[product.id+"sku"] = product.sku; 

    })
    
    data = [...newData, ...data];

    this.props.initialize(newValues);

    let newAddedGroups = [...addedGroups];
    newAddedGroups.push(queryInfo);

    
    this.setState({tableData: data, processing: false, addedGroups: newAddedGroups})
  }

  _getCSVHeaders = () => {
    const { systemSettings } = this.props;
    const { rules } = this.state;
    const headers = [{label: 'MFG # (required)', key:'mnfg_part_number'}, {label: "Description", key:'description'}, {label: 'Reward (required)', key: 'reward_value'}, {label: `Cap Per Product ${rules.reward_per_product_cap.selected ? '(required)': ''}`, key:'cap_per_product'},{label: `Qty Per Product ${rules.quantity_per_product_cap.selected ? '(required)': ''}`, key: 'qty_per_product'}];

    systemSettings.part_number_active && headers.push({label: `${systemSettings.part_number_name} ${systemSettings.part_number_active ? '(required)': ''}`, key: 'sku'});

    return headers;

  }


  render() {
    const {
      company,
      posting,
      clicked,
      clickToPrivate,
      addVideo,
      browser,
      errors,
      files,
      handleSubmit,
      onDateChange,
      onDrop,
      updateVideoInput,
      postBody,
      removeFile,
      removeVideo,
      updateContent,
      videos,
      startDate,
      endDate,
      adjustScrollTop,
      allConnections,
      connections,
      selectedRows,
      videoUrl,
      user,
      videoAdded,
      initialValues,
      formValues,
      systemSettings,
      startDateError,
      endDateError,
      search,
      updateSearch,
      clearSearch,
      searchValue,
      products,
      productGroups,
      productCategories,
      handleAllProductsToggle,
      allProductsToggle,
      is_partner_incentive
    } = this.props;

    const {rules, rulesClicked,tableData, uploading, titleCharCount, productDropdownOpen, processing, addedGroups, csvDropOpen} = this.state;
   
    const tableColumns = [
      {
        Header: "",
        accessor: 'delete_btn',
        width: 20,
        style: {border: "none"},
        Cell: this.renderDelete
      },
      {
      Header: 'MFG PART #',
      accessor: 'mnfg_part_number',
      Cell: this.renderEditable,
      filterable: tableData.length>5
    },
    {
      Header: systemSettings.part_number_name,
      accessor: 'sku',
      Cell: this.renderEditable,
      filterable: tableData.length > 5,
      show: systemSettings.part_number_active
    },
    {
    Header: 'DESCRIPTION',
    accessor: 'description',
    Cell: this.renderEditable,
    filterable: tableData.length>5
  },
    {
      Header: `REWARD VALUE (${currencies[systemSettings.currency].symbol})`,
      accessor: 'reward_value',
      Cell: this.renderEditable,
    },
    {
      Header: `${currencies[systemSettings.currency].symbol} CAP PER PRODUCT`,
      accessor: 'cap_per_product',
      Cell: this.renderEditable,
      show: rules.reward_per_product_cap.selected
    },
    {
      Header: 'QTY. PER PRODUCT CAP',
      accessor: 'qty_per_product',
      Cell: this.renderEditable,
      show: rules.quantity_per_product_cap.selected
    }];


    let filteredProducts = tableData.length ? products.filter(prod=>!tableData.map(data=>data.formName).includes(prod.id)) : products;
    

    return (
      <Flex flexDirection="column">
        <div style={{height: "89vh", overflow: "auto", "paddingLeft": "2px", "paddingRight": "20px", marginTop: "20px"}}>
        <form onSubmit={handleSubmit} id="onetoone-form">
          <Field
            key={["O2O-onetoone-form"]}
            label="TITLE"
            name="title"
            placeholder="Informative & Concise Incentive Title"
            component={renderCondensedField}
            onChange={e=>this.setState({titleCharCount: 75-e.target.value.length})}
            type="text"
            width="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          />
           <Flex
        justifyContent="flex-end"
        >
         
          <NunitoSans
          fontSize='14px'
          style={{position:'relative', top:'-50px'}}
          color={titleCharCount<0 ? '#FE4A49' : '#979797'}
          >
          {`${titleCharCount} characters remaining`}
          </NunitoSans>
       
        </Flex>
          <FileAttachments
          files={files}
          onDrop={onDrop}
          removeFile={removeFile}
          limit={1}
          />
         

           <Flex
            mt={["25px"]}
            justifyContent="space-between"
            flexDirection="row"
            flexWrap={["wrap", "nowrap"]}
          >
            <Box width={["100%", laptopBubbleWidth + "%"]} mb={["38px"]}>
              <DateBubble>
                <SingleDatePickerWrapper
                  date={startDate}
                  error={errors && errors["startDate"]}
                  name="startDate"
                  onDateChange={onDateChange}
                  onFocusChange={() => adjustScrollTop(500)}
                  label="START DATE"
                  pastMonths={parseInt(systemSettings.backdate_in_months)}
                />
              </DateBubble>
            </Box>
            <Box width={["100%", laptopBubbleWidth + "%"]}>
              <DateBubble>
                <SingleDatePickerWrapper
                  date={endDate}
                  error={errors && errors["endDate"]}
                  name="endDate"
                  onDateChange={onDateChange}
                  onFocusChange={() => adjustScrollTop(500)}
                  label="END DATE"
                  afterStartDate={startDate}
                />
              </DateBubble>
            </Box>
            </Flex>
            <NunitoSans
            fonstSize="12px"
            color="#FE4A49"
            style={{marginTop:'-30px', marginBottom:'35px'}}
            >
              {startDateError || endDateError || ''}
            </NunitoSans>
            <Flex
            flexDirection="column"
            width="100%"
            style={{marginBottom:"20px"}}
            >
              {
                Object.keys(rules).filter(rule=>systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone[rule]).length > 0
                ?
                <Flex
              width="100%"
              style={{backgroundColor:"#EBEBE7", height:"51px", paddingRight:"4px", cursor:"pointer"}}
              alignItems="center"
              onClick={()=>this.setState({rulesClicked: !rulesClicked})}
              >
                <NunitoSans
                fontSize="20px"
                fontWeight="bold"
                style={{marginLeft:"16px"}}
                color="#000000"
                >
                  RULES
                </NunitoSans>
                <FontAwesomeIcon
                icon={rulesClicked ? faCaretUp : faCaretDown}
                size="lg"
                color="#000000"
                style={{marginLeft:"5px"}}
                />
              </Flex>
                :
                null
              }
              
              { rulesClicked ? 
              
              <Flex
              flexDirection="column"
              width="100%"
              style={{ border: "2px solid #EBEBE7"}}
              >
                {Object.keys(rules).filter(rule=>systemSettings[this.props.is_partner_incentive ? 'vendor_incentives_config' : 'sales_incentives_config'].onetoone[rule]).map(rule => 
                
                <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                style={{ height:"51px", borderBottom: !rules[rule].lastRule ? "2px solid #EBEBE7": null}}
                >
                  <Flex
                  style={{marginLeft:"16px"}}
                  alignItems="center"
                  >
                    <FontAwesomeIcon
                    icon={rules[rule].selected ? faCheckCircle : faPlusCircle}
                    size="lg"
                    color={rules[rule].selected ? "#2FD371":"#428BF4"}
                    onClick={()=>{
                      let currentRules = JSON.parse(JSON.stringify(rules));
                      currentRules[rule].selected = !currentRules[rule].selected;
                      this.setState({rules: currentRules});
                    }}
                    style={{cursor:"pointer"}}
                    />
                    <NunitoSans
                    fontSize="16px"
                    fontWeight="bold"
                    color={rules[rule].selected ? "#000000" : "#6C7168"}
                    style={{marginLeft:"14px"}}
                    >
                     {is_partner_incentive ? rules[rule].name.replaceAll('REP', 'PARTNER') : rules[rule].name}
                    </NunitoSans>
                  </Flex>
                  {rules[rule].inputBox ?
                  <Flex
                  alignItems="center"
                  >
                    
                    <Field
                   
                    // label="BILLING CONTACT"
                    name={rule+"_value"}
                    placeholder={rules[rule].placeholder}
                    component={"input"}
                    
                    // type={rules[rule].inputType}
                    // min="0.01"
                    
                    width="20%"
                    height="37px"
                    props={{ disabled: !rules[rule].selected }}
                    style={{width: "168px", height:"37px", marginRight:"26px", background: "#EBEBE7 0% 0% no-repeat padding-box", fontFamily:"NunitoSansSemiBold", fontSize:"14px" , fontWeight: 400, textAlign:"center", fontDisplay:"swap"}}
                    // {...rules[rule].inputType==='currency' ? this.props.currencyMask() : this.props.numberMask}
                    {...rules[rule].inputType==='currency' ? this.props.currencyMask() : this.props.numberMask}
                    
                
                  />
                  </Flex> 
                    :
                    <NunitoSans
                    color="#A3A99E"
                    fontSize="14px"
                    style={{width:"168px", textAlign:"center", marginRight:"26px"}}
                    >
                      Add Below
                    </NunitoSans>
                    }

                </Flex>
                  
                )}

              </Flex>
              :
              null
              }

            </Flex>
          {/* <Flex
          flexDirection="column"
          width="100%"
          >
            <Flex
            width="100%"
            justifyContent="space-between"
            >
              <NunitoSans
              fontSize="15px"
              fontWeight="bold"
              color="#000000"
              >
              UPLOAD MFG PART #
              </NunitoSans>

              <NunitoSans
              fontSize="15px"
              fontWeight="bold"
              color="#A3A99E"
              style={{textDecoration:"underline"}}
              >
               <CSVLink 
          data={[]}
          headers={[{label: 'MFG # (required)', key:'mnfg_part_number'}, {label: "Description", key:'description'}, {label: 'Reward (required)', key: 'reward_value'}, {label: `Cap Per Product ${rules.reward_per_product_cap.selected ? '(required)': ''}`, key:'cap_per_product'},{label: `Qty Per Product ${rules.quantity_per_product_cap.selected ? '(required)': ''}`, key: 'qty_per_product'}]}
          
          >DOWNLOAD CSV TEMPLATE</CSVLink>
              </NunitoSans>
            </Flex>

          </Flex>

          <NonDottedDropzone
          text="Drag and Drop File to Upload"
          type="csv"
          onDrop={this._uploadCSV}
          uploading={uploading}
          type="csv"
          /> */}

          {
            systemSettings.product_active
            ?
            <Flex
            flexDirection="column"
            
            mt="20px"
            style={{border: '1px solid black', borderRadius:'6px'}}
            >
            <Flex position="relative"width={["100%", "83%", "83%", "100%"]} style={{borderBottom: productDropdownOpen ? "1px solid black":""}}>
            <InputStyled
              onFocus={()=>this.setState({productDropdownOpen: true})}
              
              placeholder="Search Products"
              // innerRef={input => (this.searchInput = input)}
              width="100%"
              name="dashboard-search"
              type="text"
              border={0}
              // autoFocus={true}
              // bg={["lightGray", "lightGray"]}
              borderRadius={browser.lessThan.small ? "6px" : "6px"}
              pt={["3px", "3px", 0]}
              px={["5px", "5px"]}
              mt={["3px", "0px"]}
              ml="10px"
              height={[31, 49, 49, 49]}
              fontWeight={browser.lessThan.small ? 400 : 400}
              fontSize={[14, 24, 24, 24]}
              lineHeight={1.5}
              color={browser.lessThan.small ? "#000000" : "#000000"}
              borderBottom={[0, 0]}
              onChange={e => updateSearch(e.target.value)}
              value={searchValue ||""}
              
            />
            <AbsoluteDiv
              display={["none", "block"]}
              right={["10px"]}
              bottom={"0px"}
              top={["0px", "6px", "0px"]}
              width={["40px"]}
            >
              <ClickCursorFlex
                height={["100%", "100%", "100%"]}
                alignItems={["center"]}
                onClick={()=>{
                  clearSearch();
                  this.setState({productDropdownOpen: false})
                }}
                justifyContent={[
                  "flex-start",
                  "flex-start",
                  "flex-start",
                  "center"
                ]}
              >
                <IconBox
                  pl={["15px"]}
                  pb={["15px"]}
                  pt={["15px"]}
                  fontSize={["20px", "17px", "17px"]}
                  color="#000000"
                >
                  thinx
                </IconBox>
              </ClickCursorFlex>
            </AbsoluteDiv>
           
          </Flex>
          {
            productDropdownOpen
            ?
            <Flex
            flexDirection="column"
            style={{maxHeight:'300px', overflow:'auto'}}
            >
               <Flex 
          flexDirection="column"
          style={{width: '100%', paddingBottom:'10px'}}
          >

            <Flex
            justifyContent='space-between'
            style={{borderBottom:'1px solid black', margin:'5px 10px'}}
            >

            <NunitoSans
            fontWeight="bold"
            >
              GROUPS
            </NunitoSans>

            <NunitoSans
            fontWeight="bold"
            fontSize="12px"
            color={"#428BF4"}
            style={{cursor:'pointer', textDecoration:'underline'}}
            onClick={()=>handleAllProductsToggle()}
            >
              {allProductsToggle ? "SHOW MY PRODUCTS" : "SHOW ALL PRODUCTS"}
            </NunitoSans>
            </Flex>
            
            {
              productGroups.length || productCategories.length ?
              <>
              {
              productGroups.filter(group=>!addedGroups.includes(group.partner_id)).map((group, index)=>(
                <Flex
                justifyContent="space-between"
                alignItems="center"
                key={index+group.partner_id}
                style={{marginLeft:'15px', marginRight:"15px", maxHeight: '100px', overflow:'auto', borderBottom:'1px solid #c3c3c3' }}
                >
                  <NunitoSans
                  fontSize="14px"
                  style={{width: '25%'}}
                  >
                    {`${group.partner_label || 'No Label'} - (${group.partner_id})`}
                  </NunitoSans>

                  <NunitoSans
                  fontSize="14px"
                  style={{width: '20%'}}
                  >
                    {`${group.number_of_products} ${group.number_of_products>1 ? "PRODUCTS" : "PRODUCT"}`}
                  </NunitoSans>

                 

                  { 
                 processing
                 ?
                 <NunitoSans
                 fontSize='12px'
                 >
                    Processing...
                  </NunitoSans>
                 :
                 <FontAwesomeIcon
                  // fontSize="12px"
                  icon={faPlusCircle}
                  style={{cursor:"pointer"}}
                  color={"#428BF4"}
                  onClick={()=> this._addTableData(group.partner_id)}
                  />
                  
                  
                  }
                    

                </Flex>
              )
              )
              }

              {
              productCategories.filter(group=>!addedGroups.includes(group.category)).map((item, index)=>(
                <Flex
                justifyContent="space-between"
                alignItems="center"
                key={index+item.category}
                style={{marginLeft:'15px', marginRight:"15px", maxHeight: '100px', overflow:'auto', borderBottom:'1px solid #c3c3c3' }}
                >
                  <NunitoSans
                  fontSize="14px"
                  style={{width: '25%'}}
                  >
                    {item.category}
                  </NunitoSans>

                  <NunitoSans
                  fontSize="14px"
                  style={{width: '20%'}}
                  >
                    {`${item.number_of_products} ${item.number_of_products>1 ? "PRODUCTS" : "PRODUCT"}`}
                  </NunitoSans>

                 { 
                 processing
                 ?
                 <NunitoSans
                 fontSize='12px'
                 >
                    Processing...
                  </NunitoSans>
                 :
                 <FontAwesomeIcon
                  // fontSize="12px"
                  icon={faPlusCircle}
                  style={{cursor:"pointer"}}
                  color={"#428BF4"}
                  onClick={()=> this._addTableData(item.category, true)}
                  />
                  
                  
                  }
                    

                </Flex>
              )
              )
              }

              </>
              
              :
              <NunitoSans
              fontSize="14px"
              style={{marginLeft:"15px"}}
              >
                NO RESULTS
              </NunitoSans>
            }
            

          </Flex>
               <Flex
          flexDirection="column"
          style={{width: '100%', paddingBottom:'10px'}}
          >
            
            <NunitoSans
            fontWeight="bold"
            style={{borderBottom:'1px solid black', margin:'5px 10px'}}
            >
              AVAILABLE PRODUCTS
            </NunitoSans>
            {
              filteredProducts && filteredProducts.length ?
              [{part_number:'MNFG PART #', sku: "SKU", description:"Description"}, ...filteredProducts].map((product, index)=>(
                <Flex
                justifyContent="space-between"
                alignItems="center"
                key={index+product.part_number}
                style={{marginLeft:'15px', marginRight:"15px", maxHeight: '100px', overflow:'auto', borderBottom:`1px solid ${index===0? 'black': "#c3c3c3"}` }}
                > 
                  
                  <NunitoSans
                  fontSize="14px"
                  style={{width: '15%'}}
                  fontWeight={index===0? 'bold' : 'normal'}
                  >
                    {product.part_number}
                  </NunitoSans>

                  <NunitoSans
                  fontSize="14px"
                  fontWeight={index===0? 'bold' : 'normal'}
                  style={{width: '15%'}}
                  >
                    {product.sku}
                  </NunitoSans>

                  <NunitoSans
                  fontSize="14px"
                  fontWeight={index===0? 'bold' : 'normal'}
                  style={{width: '35%'}}
                  >
                    {product.description || 'No Description'}
                  </NunitoSans>

                  <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{cursor: index!==0? "pointer" : "default", opacity: index===0? 0 : 1}}
                  color={"#428BF4"}
                  onClick={()=>{
                    if (index===0) return;
                    let data = [...tableData];
                    let partNumbersKeys = Object.keys(formValues).filter(item=>item.includes('mnfg'));
                    let skuKeys = Object.keys(formValues).filter(item=>item.includes('sku'));
                    let partNumbers=[];
                    let skuNumbers = [];
                    partNumbersKeys.forEach(key=>partNumbers.push(formValues[key]));
                    skuKeys.forEach(key=>skuNumbers.push(formValues[key]));
                    let dupIds = [];

                    if(systemSettings.part_number_active){
                      dupIds = _.uniq(skuNumbers.filter(item=>skuNumbers.indexOf(item)!==skuNumbers.lastIndexOf(item)))
                    }else{
                      dupIds = _.uniq(partNumbers.filter(item=>partNumbers.indexOf(item)!==partNumbers.lastIndexOf(item)))
                    }

                    this.setState({duplicates: dupIds})

                    if(!systemSettings.part_number_active && partNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
                      return this.props.notifyError("Duplicate part number. Please fix and try again.")
                    }else if(systemSettings.part_number_active && skuNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
                      return this.props.notifyError(`Duplicate ${systemSettings.part_number_name}. Please fix and try again.`)
                    }
                    let newValues = {...formValues};
                    // const date = Date.now();
                    data.unshift( {
                      formName: product.id,
                      delete_btn: 'X',
                      status:'',
                      mnfg_part_number: product.part_number,
                      sku: product.sku,
                      description: product.description,
                      reward_value:'',
                      cap_per_product:'',
                      qty_per_product: ''
                    });

                    newValues[product.id+"mnfg_part_number"] = product.part_number;
                    newValues[product.id+"description"] = product.description;
                    newValues[product.id+"sku"] = product.sku;

                    this.props.initialize(newValues);
        
                    
                    this.setState({tableData: data})
                  }}
                  />
                    

                </Flex>
              )
              )
              :
              <NunitoSans
              fontSize="14px"
              style={{marginLeft:"15px"}}
              >
                NO RESULTS
              </NunitoSans>
            }
            

          </Flex>
         

            </Flex>
           
            :
            null
          }
          
          </Flex>
            :
            null
          }

          <Flex
          justifyContent='space-between'
          alignItems='center'
          mt='28px'
          >
            <NunitoSans
            fontSize="14px"
            fontWeight="bold"
            style={{textDecoration:'underline', cursor: 'pointer'}}
            onClick={()=>this._removeTableData()}
            >
              Remove all table data
            </NunitoSans>
            <Flex
            flexDirection='column'
            alignItems='center'
            >
              <NunitoSans
              fontSize="12px"
              >
                Assign Same Value
              </NunitoSans>
              <Flex
              justifyContent='center'
              alignItems='center'
              width='50%'
              >
                <Field
                name="same_value"
                component="input"
                style={{border:'1px solid black', padding: '2px 5px', width: "50%", marginRight:'5px'}}
                {...this.props.currencyMask()}
                />
                <FontAwesomeIcon
                icon={faCheckCircle}
                color={'#428BF4'}
                style={{cursor:'pointer'}}
                onClick={()=>this._assignSameValue()}
                />
              </Flex>

            </Flex>
          </Flex>

          <ReactTable
          className="-striped -highlight"
          columns={tableColumns}
          data={tableData}
          sortable={false}
          resizable={true}
          showPagination={tableData.length>20}
          defaultFilterMethod={(filter,row)=>formValues[row._original.formName+filter.id]&&formValues[row._original.formName+filter.id].toLowerCase().includes(filter.value.toLowerCase())}
          // defaultPageSize={tableData.length}
          minRows={0}
          noDataText=''
          NoDataComponent={props=><span {...props}></span>}
          
          name="tableData"
          style={{textAlign:"center", fontSize: "14px", fontWeight:"bold", fontFamily: "NunitoSans", color:"#6C7168", width: "100%", marginTop:"28px"}}
          />
          <Flex
          alignItems="center"
          justifyContent="center"
          mt="5px"
          style={{cursor:"pointer"}}
          onClick={()=>{
            let data = [...tableData];
            let partNumbersKeys = Object.keys(formValues).filter(item=>item.includes('mnfg'));
            let skuKeys = Object.keys(formValues).filter(item=>item.includes('sku'));
            let partNumbers=[];
            let skuNumbers = [];
            partNumbersKeys.forEach(key=>partNumbers.push(formValues[key]));
            skuKeys.forEach(key=>skuNumbers.push(formValues[key]));
            let dupIds = [];

            if(systemSettings.part_number_active){
                dupIds = _.uniq(skuNumbers.filter(item=>skuNumbers.indexOf(item)!==skuNumbers.lastIndexOf(item)))
            }else{
                  dupIds = _.uniq(partNumbers.filter(item=>partNumbers.indexOf(item)!==partNumbers.lastIndexOf(item)))
            }

            this.setState({duplicates: dupIds})

           if(!systemSettings.part_number_active && partNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
                return this.props.notifyError("Duplicate part number. Please fix and try again.")
           }else if(systemSettings.part_number_active && skuNumbers.some((item,index,arr)=>arr.indexOf(item) !== arr.lastIndexOf(item))){
                 return this.props.notifyError(`Duplicate ${systemSettings.part_number_name}. Please fix and try again.`)
            }
            
            data.unshift( {
              formName: Date.now(),
              delete_btn: 'X',
              status:'',
              mnfg_part_number: '',
              sku:'',
              description: '',
              reward_value:'',
              cap_per_product:'',
              qty_per_product: ''
            });

            
            this.setState({tableData: data})
          }}
          >
              <FontAwesomeIcon
              icon={faPlus}
              size="sm"
              color="#428BF4"
              />

          <NunitoSans
          fontSize="14px"
          fontWeight="bold"
          color="#428BF4"
          style={{marginLeft:"5px"}}
         
          >
            ADD PRODUCT MANUALLY
          </NunitoSans>
          </Flex>

          <Flex
          flexDirection="column"
          width="100%"
          >
            <Flex
            width="100%"
            justifyContent="center"
            >
            
              <DashboardSmallCTA
              width="50%"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ csvDropOpen: true})
              }}
              minWidth={["90px"]}
              my="12px"
              height="34px"
              >
                UPLOAD PRODUCT DATA
                </DashboardSmallCTA>
            </Flex> 

          </Flex>
          <CsvDropzoneModal
          modalOpen={csvDropOpen}
          handleCloseRequest={()=>this.setState({csvDropOpen: false, uploading: false})}
          handleCsvUpload={uploading ? ()=>{} : this._uploadCSV}
          csvName={'One to One Product'}
          uploading={uploading}
          csvErrors={[]}
          csvHeaders={this._getCSVHeaders()}
          uploadHistory={[]}
          />
          <Flex
          justifyContent="space-between"
          mt="20px"
          >
          <Field
            label="BILLING CONTACT"
            name="billing_contact"
            // placeholder="Contact Name"
            component={renderCondensedFieldSelect}
            // type="text"
            width="45%"
            
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          >
            {company&&company.users&&company.users.filter(user => user.company_admin).map((user, index)=> <option 
        value={`${user.first_name} ${user.last_name}` } 
        key={index}
        
        >
        {`${user.first_name} ${user.last_name}`}
        </option>)}
          </Field>
          <Field
            label="PO NUMBER"
            name="po_number"
            placeholder="PO NUMBER"
            component={renderCondensedField}
            type="text"
            width="45%"
          
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{"padding-left": "12px", "padding-right": "12px"}}
        px={["12px"]}
        pt={["16px"]}
        pb={["6px"]}
          />
          </Flex>
          {/* <FileAttachments
            files={files}
            onDrop={onDrop}
            removeFile={removeFile}
          /> */}
          {/* <VideoAttachments
            addVideo={addVideo}
            updateVideoInput={updateVideoInput}
            removeVideo={removeVideo}
            videos={videos}
            videoUrl={videoUrl}
            videoAdded={videoAdded}
          /> */}
          {browser.lessThan.large ? (
            <Flex mt="16px" pb="16px" flexDirection="column">
              <Eyebrow ml={"8px"}>AUDIENCE</Eyebrow>
              <Flex
                bg="inputBackgroundGray"
                px={"15px"}
                pb={"15px"}
                borderRadius="10px"
                flexDirection="column"
              >
                {selectedRows.length ? (
                  <Box position="relative" mt="0px">
                    <AudienceMultiRowGroup
                      color={"#f7f7f7"}
                      groupName="SEARCH RESULTS"
                      audiencesMulti={selectedRows}
                      selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                      onSelect={this._selectRow}
                      mobile={true}
                      hideHeader={true}
                      hideFirstLastDash={true}
                    />
                  </Box>
                ) : (
                  <Box position="relative">
                    <AudienceRow
                      submit={() => alert("submit")}
                      color={"#f7f7f7"}
                      key={0}
                      active={true}
                      firstRow={true}
                      lastRow={true}
                      hideFirstLastDash={true}
                      mobile={true}
                      removeAction={() => alert("remove")}
                      removeFromSelected={() => alert("remove from selected")}
                      hideAdd={false}
                      name="All Connections"
                      companyNumber={`${
                        allConnections
                          ? companyTotal(connections, user)
                          : companyTotal(connections, user)
                      } Companies`}
                    />
                  </Box>
                )}
                <Link to="/dashboard/posts/new/audience">
                  <MobileNavigationalBubble
                    icon="search"
                    text="Audience Filter"
                  />
                </Link>
              </Flex>
            </Flex>
          ) : null}
         
          
          <Flex
            width="100%"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mb="76px"
            onClick={posting ? ()=>{} : () => {
              //Code below sets table form values into tableData
              let theValues = {...formValues};
              let data = [...tableData];
              let theRules= JSON.parse(JSON.stringify(rules));
              const keys = ["mnfg_part_number", "description" ,"reward_value","cap_per_product","qty_per_product","sku"];

              data.forEach(row => {
                keys.forEach(key=> row[key] = theValues[row.formName+key])
              })

              Object.keys(theRules).forEach(rule =>{
                theRules[rule].inputValue = formValues[rule+"_value"] || "0"
              })

              let partNumbers=data.map(item=>item.mnfg_part_number);
              let skuNumbers = data.map(item=>item.sku);
              let dupIds= [];
              if(systemSettings.part_number_active){
                dupIds = skuNumbers.filter(item=> skuNumbers.indexOf(item) !== skuNumbers.lastIndexOf(item));
              }else{
                dupIds = partNumbers.filter(item=> partNumbers.indexOf(item) !== partNumbers.lastIndexOf(item));
              }
              this.setState({duplicates:dupIds})

              this.props.change("tableData", data);
              this.props.change("rules", theRules);

              this.setState({ submitted: true });
            }}
          >
            {
              posting ? 
              <>
        <FontAwesomeIcon
        mb="10px"
        icon={faCog}
        color="#2FD371"
        size='2x'
        spin
        />
        
        <NunitoSans
         
          fontSize="13px"
          fontWeight="bold"
          color="#2FD371"
          textAlign="center"
        >
          {this.props.editMode ? "SAVING" : "POSTING"}
        </NunitoSans>
        </>
              :

            <DashboardCTA
              loading={JSON.stringify({"loading":(this.state.submitted && errors.length === 0)})}
              mt={["50px", "21px", "21px", "21px"]}
              width={[0.75, 0.75, 0.6, 0.6]}
              ctaText={this.props.editMode ? "SAVE" : "POST"}
              color="#428BF4"
            />
            }
          </Flex>
          

        </form>
        </div>
      </Flex>
    );
  }
}

AddOneToOneMain = reduxForm({
  destroyOnUnmount: true,
  form: "onetoone-form",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize : true
})(AddOneToOneMain);



AddOneToOneMain = connect(
  state => ({
    formValues: getFormValues('onetoone-form')(state),
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    //connections: state.resources.collection.connection,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    browser: state.browser,
    user: state.resources.detail.user
  }),
  dispatch => ({
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    )
  })
)(AddOneToOneMain);

export default AddOneToOneMain;
