import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
//utils
import { getUser } from "../../../../utils/auth";
//actions
import { error, success } from "../../../../actions/alerts";
import { actions } from "../../../../actions/resources";
import { companyTotal, peopleTotal, selectRow, clearRows } from "../../../../actions/post";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionGroups,
  searchConnectionUsers,
  searchUsers
} from "../../../../actions/search";
//components
import { NunitoSans, H1CondensedSmallD0D0D0 } from "../../../common/typography";
import CompanyInfographic from "./audiences/companyInfographic";
import PeopleInfographic from "./audiences/peopleInfographic";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../../common/flexbox";
import { MegaFlexBubble, BillingCTA, OneSixDash, FourFourDottedWith8pxCornerRadius, RecommendedContentWrapper } from "../../../common/ui";
import { AudienceRow } from "../../../common/rows";
import { AudienceMultiRowGroup } from "../../../common/rows";
import {AudienceList} from "../../../common/reactWindow";
import { InputStyled, DashboardSmallCTA } from "../../../common/forms";
import { AbsoluteDiv } from "../../../common/absolute";
import { ClickCursorHoverFlex} from "../../../common/ui"
import { LockedFeature } from "../../../common/lockedfeature";

export class AddPostAccessory extends Component {
  state = {
    audienceSearchInput: null,
    searchModeActive: false
  };

  componentDidMount(){
    this._fetchDependencies();
  }

  componentWillUnmount(){
    this._removeAudience();
  }

 


  componentDidUpdate(prevProps, prevState) {
    const { audienceSearchInput, searchModeActive } = this.state;
    if (searchModeActive === true && prevState.searchModeActive === false) {
      this._search(audienceSearchInput || "");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      searchUsers,
      selectedCompanies,
      selectedDepartments,
      selectedUsers,
      selectedGroups
    } = this.props;

    if (
      selectedCompanies !== nextProps.selectedCompanies ||
      selectedDepartments !== nextProps.selectedDepartments ||
      selectedUsers !== nextProps.selectedUsers ||
      selectedGroups !== nextProps.selectedGroups
    ) {
      console.log(nextProps.selectedCompanies, nextProps.selectedGroups)
      searchUsers(
        _.pluck(nextProps.selectedCompanies, "id").concat(_.pluck(nextProps.selectedGroups, "company_id")),
        _.pluck(nextProps.selectedDepartments, "id"),
        _.pluck(nextProps.selectedUsers, "id")
      );
    }
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }
    fetchConnections({ company_id: getUser().company_id, status: 'active', from:"newPost" });//only share with your active connections
//    fetchConnections({ company_id: getUser().company_id, post: true });
  };

  _search = async input => {
    const {
      searchCompanies,
      searchDepartments,
      searchGroups,
      searchConnectionUsers,
      user
    } = this.props;

    if(!user || !user.company) return null;
    
    searchCompanies(input, user.company.paid);
    searchDepartments(input, user.company.paid);
    searchGroups(input, user.company.paid);
    searchConnectionUsers(input, null, user.company.paid);
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows, groupRows, selectedRows } = this.props;

    
    let rows = userRows.concat(departmentRows, companyRows , groupRows );

    const selectedRowIds = selectedRows.map(slrow=>slrow.id);
    
    // we sort to push selected rows to the bottom
    rows = rows.sort(row=>{
      if(selectedRowIds.includes(row.id)) return 1;
      return -1;
    });

    
    
    return rows;
  };
//selectedAudienceMultiIds

	
_removeAudience = () => {
    
  const {
    selectedRows,
    clearRows
  } = this.props;
  
  //console.log(selectedRows);
  clearRows();
  this._updateSearch("");
}
  _updateSearch = input => {

   
  //console.log(AudienceMultiRowGroup);


    this.setState({ audienceSearchInput: input });
    //HELLO
    if (input.length < 2){
      if(input.length != 0){
        return;
      }    
    }
    this._search(input);
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectedGroups,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;
    
    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers,
      group: selectedGroups
    };
    
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };

  _addAllIndividuals = async (individuals)=>{

    for(let i=0; i<individuals.length; i++){

      const individual = individuals[i];

      if(i>0){
        await promiseTimeout(10);
      }

      this._selectRow(individual.id, individual.type, individual.company_id)

    }

  }

//below accessory side of ADD A POST page //
  render() {
    const {
      allConnections,
      audienceSearchResults,
      companies,
      connections, 
      selectedRows,
      featureFlags,
      user } = this.props;
    const { audienceSearchInput, searchModeActive } = this.state;


    const allAudience = this._searchAudienceFilters();

    const selectedRowIds = selectedRows.map(slrow=>slrow.id);

    const unselectedRows = allAudience.filter(row=>!selectedRowIds.includes(row.id));

    let addAllRows = [];

    if(
      unselectedRows.every(audience=>audience.type==="person" && audience.row.is_single_user_company && audience.row.company_domain===unselectedRows[0].row.company_domain)
      && unselectedRows.length > 1
    ){
      addAllRows =  unselectedRows;
    }else{
     addAllRows = [];
    }

    if (user && connections) {
      return (
        <Flex justifyContent="center" flexDirection="column" width="100%">

      

          <Box mt="35px" width="100%">
            <NunitoSans textAlign="center" fontWeight={700} fontSize="18px">
              AUDIENCE
            </NunitoSans>
          </Box>
          <Flex mt="26px" mb="40px" width="100%" flexDirection="column">
            <MegaFlexBubble
              bg="white"
              borderRadius="10px"
              pl={["5px", "12px", "15px"]}
              pt={["5px", "12px", "15px"]}
              pr={["5px", "12px", "15px"]}
              pb={"0px"}
              flexDirection="column"
            >
              {companies && connections ? (
                <Flex
                  alignItems="center"
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap={["no-wrap"]}
                >
                  <Box width={["48%", "48%", "45%"]}>
                    <CompanyInfographic
                      companiesActive={
                        allConnections
                          ? companyTotal(connections, user)
                          : audienceSearchResults &&
                            audienceSearchResults.companies
                      }
                      companiesTotal={companyTotal(connections, user) || 0}
                    />
                  </Box>
                  <Box width={["48%", "48%", "45%"]}>
                    <PeopleInfographic
                      peopleActive={
                        allConnections
                          ? peopleTotal(connections, user)
                          : audienceSearchResults &&
                            audienceSearchResults.people
                      }
                      peopleTotal={peopleTotal(connections, user)||0}
                    />
                  </Box>
                </Flex>
              ) : null}
              {!searchModeActive ? (
                <Box position="relative" mt="23px">
                  <OneSixDash
                    position="absolute"
                    top="0px"
                    left="0px"
                    right="0px"
                    height="1px"
                  />
                  <AudienceRow
                    color={"#ffffff"}
                    key={0}
                    active={true}
                    firstRow={true}
                    lastRow={true}
                    hideFirstLastDash={true}
                    mobile={true}
                    hideAdd={true}
                    name="All Connections"
                    companyNumber={`${
                      allConnections
                        ? companyTotal(connections, user)
                        : companyTotal(connections, user)
                    } Companies`}
                  />
                </Box>
              ) : (
               
                <Box position="relative" mt="23px">
                  {/* <OneSixDash
                    position="absolute"
                    top="0px"
                    left="0px"
                    right="0px"
                    height="1px"
                  /> */}
                  {(selectedRows.length) ? (
                    <Flex
                    maxHeight="35vh"
                    flexDirection="column"
                    // justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    style={{overflow:"auto"}}
                  >
                  {/* <ClickCursorHoverFlex
                  my="10px"
                  justifyContent="center"
                  alignItems="center"
                  height={["34px"]}
                  width="40%"
                  minWidth={["120px"]}
                  onClick={() => this._removeAudience()}
                  borderRadius="8px"
                  bg="lightGray"
                  > 
                    
           
                      <NunitoSans
                       pt={["2px"]}
                       fontSize={["12px"]}
                       fontWeight="bold"
                       color="#c3c3c3"
                       >                     
                      CLEAR ALL
                      </NunitoSans>
                    
                    </ClickCursorHoverFlex> */}
                    <DashboardSmallCTA
                width="40%"
                onClick={() => this._removeAudience()}
                minWidth={["120px"]}
                fontSize="17px"
                innerHeight="40px"
                // my="12px"
                // height="34px"
              >
                CLEAR ALL
              </DashboardSmallCTA>
                                            
                      <AudienceMultiRowGroup
                        audiencesMulti={selectedRows}
                        selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                        mobile={true}
                        hideHeader={true}
                        hideFirstLastDash={true}
                        onSelect={this._selectRow}
                      />
                    </Flex>
                  ) : (
                    <AudienceRow
                      color={"#ffffff"}
                      key={0}
                      active={true}
                      firstRow={true}
                      lastRow={true}
                      hideFirstLastDash={true}
                      mobile={true}
                      hideAdd={true}
                      name="All Connections"
                      companyNumber={`${
                        allConnections
                          ? companyTotal(connections, user)
                          : companyTotal(connections, user)
                      } Companies`}
                    />
                  )}
                </Box> 
              )}
            </MegaFlexBubble>
          </Flex>
          { ((user.company.paid===true)||(user.company.paid==="true")) || true  ? (
          <div>
          {searchModeActive ? (
            <DashboardSmallCTA
            color='#FE4A49'
            style={{marginBottom:'10px'}}
            innerHeight="50px"
            fontSize="16px"
            fontWeight="bold"
            onClick={()=>this.setState({ searchModeActive: false })}
            >
              Close Audience Filter
            </DashboardSmallCTA>
          ) 
          :
          featureFlags.audience_filtering === true ?
          (
          
            <Flex
            height="50px"
            // flexDirection="column"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={() => this.setState({ searchModeActive: true })}
            style={{border: '1px dashed#2FD371', borderRadius:'8px'}}
            className="audienceFilterPost"
          >
            <IconBox className="audienceTextPost" fontSize="22px" mb="4px" color="#2FD371" mr="10px">
              search
            </IconBox>
            <NunitoSans
            className="audienceTextPost"
              mb="4px"
              fontSize="16px"
              fontWeight="bold"
              color="#2FD371"
              textAlign="center"
            >
              Audience Filter 
            </NunitoSans>
          </Flex>
            /* </FourFourDottedWith8pxCornerRadius> */
          ) :
          featureFlags.audience_filtering === "locked" ?
          <LockedFeature
            text="Upgrade to Add Audience Filters"
            fontSize="14px"
          />
          : null}
          {searchModeActive ? (
            <Flex
              mt="1px"
              mb="25px"
              mx={["0px"]}
              height={["49px"]}
              width={"100%"}
              position="relative"
              alignItems="center"
              justifyContent="center"

            >
              <Flex position="relative" width={["100%"]}>
                <InputStyled
                  placeholder="Search Audience"
                  onChange={e => this._updateSearch(e.target.value)}
                  innerRef={input => (this.searchInput = input)}
                  value={audienceSearchInput || ""}
                  width="100%"
                  name="post-new-search"
                  type="text"
                  border={0}
                  autoFocus={true}
                  bg={["lightGray"]}
                  borderRadius={"6px"}
                  pt={["3px"]}
                  px={["16px"]}
                  mt={["0px"]}
                  height={["46px"]}
                  fontWeight={300}
                  fontSize={[18]}
                  lineHeight={1.3}
                  color={"#979797"}
                  borderBottom={[0]}
                />
                <AbsoluteDiv
                  display={["block"]}
                  right={["20px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["17px"]}
                >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={() => this._updateSearch("")}
                    justifyContent={["center"]}
                  >
                    <IconBox
                      cursor="pointer"
                      pl={["15px"]}
                      pb={["15px"]}
                      pt={["15px"]}
                      fontSize={["17px"]}
                      color="#c3c3c3"
                    >
                      thinx
                    </IconBox>
                  </ClickCursorFlex>
                </AbsoluteDiv>
              </Flex>
              
            </Flex>
          ) : null}
          {
               searchModeActive && addAllRows.length
                ?
                <DashboardSmallCTA
                onClick={()=>this._addAllIndividuals(addAllRows)}
                >
                  {`ADD ALL ${addAllRows.length} INDIVIDUALS`}
                </DashboardSmallCTA>
                :
                null
              }
            {searchModeActive ? (
            <Box mb="5px">
            
              <AudienceList
              audiencesMulti={allAudience}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              />
            </Box>
          ) : null}
          </div>
          ) : null}
        </Flex>
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length &&
      !state.post.selectedGroups.length,
    audienceSearchResults: state.search.audienceSearchResults,
    featureFlags: state.resources.detail.featureflag,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    connections: state.resources.collection.connection || [],
    departmentRows: state.search.departmentSearchRows,
    groupRows: state.search.groupSearchRows,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    user: state.resources.detail.user,
    userRows: state.search.userSearchRows
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchGroups: bindActionCreators(
      searchConnectionGroups,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    clearRows: bindActionCreators(clearRows, dispatch)
  })
)(AddPostAccessory);


function promiseTimeout (time) {
  return new Promise(function(resolve,reject){
    setTimeout(function(){resolve(time);},time);
  });
};