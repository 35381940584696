import React from "react";
import {
    Flex
} from "../../common/flexbox";


import SignUp from "./signup";
import LogIn from "./login";
import CompanyName from "./companyname";
import CompanyType from "./companytype";
import AccountType from "./accounttype";
import Verification from "./verification";
// /home/georgemck/Documents/htdocs/ws-kickpost/kp-web/src/components/auth/info/verification.js
///home/georgemck/Documents/htdocs/ws-kickpost/kp-web/src/components/auth/info/signup.js
import Start from "./start";
import Connection from "./connection";
import Industry from "./industry";
import Goal from "./goal.js";

export default () => {
     let Page = <></>;
     switch (window.location.pathname) {
        case '/':
            Page = LogIn;
            break;

        case '/signup':
            Page = SignUp;
            break;

        case '/signup/':
            Page = SignUp;
            break;

        case '/login-verification':
            Page = Verification;
            break;

        case '/email-verification':
            Page = Verification;
            break;

        case '/goal':
            Page = Goal;
            break;

        case '/industry':
            Page = Industry;
            break;

        case '/connection':
            Page = Connection;
            break;

        case '/start':
            Page = Start;
            break;

        case '/account-type':
            Page = AccountType;
            break;

        case '/company-type':
            Page = CompanyType;
            break;

        case '/individual':
            Page = CompanyName;
            break;

        case '/company':
            Page = CompanyName;
            break;

        default:
            break;
    }

    return <Page/> ;
};