import React from "react";
import { NunitoSans } from "./common/typography";
import { Flex } from "./common/flexbox";

import under_maintenance from "../images/maintenenceIllo.png";


export class UnderMaintenance extends React.Component {

  
  render(){
    

  return (
   <Flex 
   flexDirection="column" 
  //  maxWidth="248px"
   alignItems="center"
   justifyContent="flex-end"
   style={{marginTop: "366px"}}
   >

     
<Flex
flexDirection="column"
maxWidth="248px"
alignItems="center"
>

     <img src={under_maintenance} alt="under maintenance" width="249px" height="236px"></img>
   
     <NunitoSans 
     fontSize="35px"
     fontWeight={700}
     textAlign='center'
     >
       We’ll be back!
     </NunitoSans>
   
     <NunitoSans 
     fontSize="14px"
     textAlign='center'
     >
       We’re currently working on improving the site. Please come back later.
     </NunitoSans>
     
</Flex>
     
   
   </Flex>
   );
}
} 

export default UnderMaintenance;