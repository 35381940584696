import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import _ from "underscore";
import AWS from "aws-sdk";
import { sortBy, isEqual, uniq, uniqBy } from "lodash";
import { CSVLink } from "react-csv";


import moment from "moment";
import { history } from "../../../store";
import { getUser } from "../../../utils/auth";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from "react-modal";

import { parse } from "../../../../node_modules/csv-parse/dist/esm/sync";

//components
import { NavNotificationBubble } from "../../common/ui";
import { NunitoSans, NunitoSansBold } from "../../common/typography";
import { Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA, SelectStyled } from "../../common/forms";
import { BackButton } from "../../common/backbutton";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

import { setRowSelected, setCsvDropOpen } from "../../../actions/incentives";

import CsvDropzoneModal from "../../common/csvDropzoneModal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import InfoModal from "../../common/InfoModal";

import rewardsLogo from "../../../images/rewards_info_logo.png";
import invoicesLogo from "../../../images/invoices_info_logo.png";

import QuestionModalTrigger from "../../common/questionModalTrigger";
import axios from "axios";
import { onboardStripeConnectAccount } from "../../../utils/stripe";


export class ManageRewardsMain extends Component {
  constructor() {
    super();
  }
  state = {
    quickpayTableData: [],
    clickedRow: null,
    csvErrors: [],
    filteredData: null,
    pendingTotal: 0,
    projectedTotal: 0,
    paidTotal: 0,
    uploading: false,
    distSalesUploadHistory: [],
    productsUploadHistory: [],
    rewardsByDate: [],
    invoices: [],
    invoicesByStatus: [],
    filtered: [],
    filtered2: [],
    theLabels: {},
    noDataTextCustom: "Loading...",
    accountSelected: 'all'
  };

  _fetchDependencies = async () => {
  // async _fetchDependencies()  {
    
    const { fetchCompany, fetchDistsales, fetchInvoices, fetchProducts, updateUser, fetchRewards, fetchUser, user, notifyError, notifySuccess, systemSettings, companySettings } = this.props;

    if (!(getUser().company_admin || getUser().sales_id) || !systemSettings.rewards_active) {
      history.push('/dashboard')
    }

    if (!(companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) {
      history.push('/dashboard')
    }



    if (!user || (user && user.id !== getUser().id)) {
      fetchUser(getUser().id);
    }

///////////////////////////PAYLOAD INITIALIZED

    // RETRIEVE SALES AND REWARDS
    let payload = (getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin) ? 
      {} : 
      { user_id: getUser().id, rewardsPage: true };

////////////////////////////////////////// REWARDS START


fetchRewards(payload)
.then((res) => {
  const rewards = (res && res.payload) || [];

  const projectedTotal = rewards.filter(rew => rew.status === 'Projected').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
  const pendingTotal = rewards.filter(rew => rew.status === 'Pending').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
  const paidTotal = rewards.filter(rew => rew.status === 'Paid').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);

  rewards.forEach(reward => {
    reward.value_type = reward.partner_fee > 0 ? "Partner Fee" : reward.sales_rep_fee > 0 ? "Sales Rep Fee" : "Reward";
  });
  this.setState({ projectedTotal, pendingTotal, paidTotal });

  //check for data in url
  const urlParams = new URLSearchParams(window.location.search);
  const urlDate = urlParams.get('date');
  const defaultDate = urlDate ? new Date(urlDate) : new Date();

  //Pre-select current month as statement date
  let theDate = uniq(rewards.map(rew => rew.statement_date)).filter(date => defaultDate.getMonth() === new Date(date).getMonth())[0];
  if (!theDate) {
    const today = new Date();
    today.setDate(14);
    theDate = today.toJSON().slice(0, 10)

  }

  this._handleStatementChange(theDate, rewards);
  if (document.getElementById("statement_dropdown")) {

    document.getElementById("statement_dropdown").value = theDate;
  }
});


//////////////////////////////////////////REWARDS END 

//////////////////////////////////////////CSVUPLOADS START


// const CSVUploads =  

await this._fetchCsvUploads();
 

//////////////////////////////////////////CSVUPLOADS END

//////////////////////////////////////////STRIPE SECTION START

    //stripe connect token check
    const urlParams = new URLSearchParams(window.location.search);
    const stateStripe = urlParams.get('state');
    const code = urlParams.get('code');
    const error = urlParams.get('error');
    const target = urlParams.get('target');
    const incentiveId = urlParams.get('incentiveId');

    if (stateStripe && (code || error)) {
      history.replace(window.location.pathname);
    }

    // console.log("src/components/dashboard/rewards/index.js _fetchDependencies Main urlParams", urlParams)
    // console.log("src/components/dashboard/rewards/index.js _fetchDependencies Main target", target)
    // console.log("src/components/dashboard/rewards/index.js _fetchDependencies Main stateStripe", stateStripe)

    if (stateStripe === getUser().id && code && !error) {
      const response = updateUser(getUser().id, { stripe_connect_code: code });
      // console.log(response.payload, 'ZTRIPE');
      fetchUser(getUser().id)
      if (response.payload.stripe_connect_id) {
        notifySuccess(`You can now redeem rewards!`)
      }
      if (response.payload.stripeStatus) {
        notifyError(response.payload.stripeStatus.message)
      }
    } else if (error) {
      notifyError(`${error} There was a problem with Stripe Connect. Please try again.`)
    }

    if (target === 'invoices' && incentiveId) {
      this._changeTableFilterValue('incentive_id', incentiveId);
      console.log("IT IS CHANGED")
    }


///////////////////////////////////////////STRIPE SECTION END


    fetchCompany(getUser().company_id, { edit: true })

    fetchDistsales({ company_id: getUser().company_id });
    this._fetchInvoices();
    // fetchInvoices(payload)
    //   .then(res => {
    //     let { invoices } = this.props;
    //     if (res !== undefined && res.payload) {
    //       invoices = res.payload;
    //     }
    //     this.setState({ invoicesByStatus: invoices });
    //   });

    fetchProducts({});

    this._fetchProductLabels();


    setTimeout(() => this.setState({ noDataTextCustom: 'No Data' }), 3000)

  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchInvoices = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/stripeinvoices/${getUser().company_id}`);
      this.setState({
        invoices: response.data,
        invoicesByStatus: response.data
      })
    } catch (error) {
      console.log(error);
    }
  }

  _fetchCsvUploads = async(keepDropzoneOpen = false)=>{

    const { fetchCsvuploads } = this.props;

    return await fetchCsvuploads({ company_id: getUser().company_id })
      .then(res => {
        let { csvuploads } = this.props;
        
        this.props.setCsvDropOpen(keepDropzoneOpen);
        let distSalesUploadHistory, productsUploadHistory ;
        if (res !== undefined && res.payload) {
          csvuploads = res.payload;
          distSalesUploadHistory = csvuploads.filter(item => item.type === 'distsale').filter((a, index) => index < 6);
          productsUploadHistory = csvuploads.filter(item => item.type === 'product').filter((a, index) => index < 6);
          
        this.setState({ productsUploadHistory, distSalesUploadHistory });
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/csvuploads?company_id=${getUser().company_id}`)
          .then((res) => res.json())
          .then((data) => {
            csvuploads = data;
            distSalesUploadHistory = csvuploads.filter(item => item.type === 'distsale').filter((a, index) => index < 6);
            productsUploadHistory = csvuploads.filter(item => item.type === 'product').filter((a, index) => index < 6);
            
        this.setState({ productsUploadHistory, distSalesUploadHistory });
            })
        }

      }
      )
      .catch(err => console.error(err));

  }

  _fetchProductLabels = async () => {
    const labels = await fetch(`${process.env.REACT_APP_API_URL}/partnerlabels`).then(res => res.json());
    console.log(labels, "LABELS")
    let theLabels = {};

    labels && labels.forEach(lb => theLabels[lb.partner_id] = lb.label);

    this.setState({ theLabels });
  }

  _changeTableFilterValue = (id, value) => {

    this.setState({ filtered2: [{ id, value }] })
  }

  componentDidUpdate(prevProps) {

    if (!isEqual(prevProps.rewards, this.props.rewards)) {
      const rewards = this.props.rewards;
      const projectedTotal = rewards.filter(rew => rew.status === 'Projected').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
      const pendingTotal = rewards.filter(rew => rew.status === 'Pending').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
      const paidTotal = rewards.filter(rew => rew.status === 'Paid').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
      this.setState({ projectedTotal, pendingTotal, paidTotal });
    }

    if (prevProps && prevProps.formValues && prevProps.formValues.rewardsnav !== this.props.formValues.rewardsnav) {
      this.setState({ filteredData: null, noDataTextCustom: 'Loading...' })

      setTimeout(() => this.setState({ noDataTextCustom: 'No Data' }), 3000)

    }

    if (this.state.clickedRow && this.props.rowSelected && !Object.keys(this.props.rowSelected).length) {
      this.setState({ clickedRow: null })
    }
  }


  componentWillUnmount() {
    this.props.setRowSelected({});
  }

  _handleQuickPayData = payload => this.setState({ quickpayTableData: payload })

  _handleCsvUpload = (files) => {

    const {formValues, setCsvDropOpen, notifyError, notifySuccess, user, systemSettings, companySettings} = this.props;
    const file = files[0];
    const results = [];
    const fileErrors = [];
    const companyId = getUser().company_id;
    const companyName = user.company.name;
    const userId = getUser().id;
    const reader = new FileReader();
    this.setState({csvErrors: [], uploading: true})
    if(typeof file === "undefined"){
      this.setState({uploading: false})
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      region: "us-east-1"
    });
    const lambda = new AWS.Lambda();



    reader.onload = async() => {
      
        if (formValues && formValues.rewardsnav === "products") {
          console.log("products--THIS CODE IS NOT SUPPOSED TO RUN. IF IT DOES IT MUST BE FIXED");
          parse(reader.result, async (err, data) => {
            const keys = ["partner_id", "description", "part_number", "category_one", "category_two", "category_three", "sku", "status"];
            for (let x = 0; x < keys.length; x++) {
              if (data[0][x] === undefined) {
                this.setState({ uploading: false });
                return notifyError(`${keys[x].toUpperCase()} is missing. Please fix and try again`);
              }
            }
            for (let i = 1; i < data.length; i++) {

              let item = {
                [keys[0]]: data[i][0].trim() || '',
                [keys[1]]: data[i][1].trim() || '',
                [keys[2]]: data[i][2].trim() || '',
                [keys[3]]: data[i][3].trim() || '',
                [keys[4]]: data[i][4].trim() || '',
                [keys[5]]: data[i][5].trim() || '',
                [keys[6]]: data[i][6].trim() || '',
                [keys[7]]: data[i][7].trim() || 'active'
              };

              item.product_key = item.part_number + item.sku;


              results.push(item)

            }

            let optionalKeys = ["category_one", "category_two", "category_three"];

            !systemSettings.part_number_active && optionalKeys.push("sku");

            // if(systemSettings.part_number_active && results.some(item=> results.map(i=>i.part_number+i.sku).indexOf(item.part_number+item.sku) !== results.map(i=>i.part_number+i.sku).lastIndexOf(item.part_number+item.sku))){
            //   this.setState({uploading: false})
            //   return notifyError(
            //     `There are duplicate combination of part number and ${systemSettings.part_number_name}. Please fix and try again.`
            //   );
            // }
            const partNumbers = results.map(i => i.part_number);
            const patNumbersAndSku = results.map(i => i.part_number + i.sku);

            results.forEach((item, index) => {
              let errors = []
              if (!keys.every(key => !item[key])) {

                keys.forEach(key => {
                  if (!optionalKeys.includes(key)) {
                    // if(key==="part_number"){
                    //   if(!item[key] || isNaN(+item[key])){
                    //     errors.push(`Invalid Number at ${key}`)
                    //   }
                    // }else{
                    //   if(!item[key]){
                    //     errors.push(`INVALID ${key}`)
                    //   }
                    // }

                    if (!item[key]) {
                      errors.push(`INVALID ${key !== "sku" ? key : systemSettings.part_number_name}`)
                    } else if (!systemSettings.part_number_active && key === "part_number" && partNumbers.indexOf(item[key]) !== partNumbers.lastIndexOf(item[key])) {
                      errors.push(`DUPLICATE ${key}`)
                    } else if (systemSettings.part_number_active && key === "part_number" && patNumbersAndSku.indexOf(item.part_number + item.sku) !== patNumbersAndSku.lastIndexOf(item.part_number + item.sku)) {
                      errors.push(`DUPLICATE part number and sku combination`)
                    }
                  }
                })
              }
              if (errors.length !== 0) {
                fileErrors.push({
                  line: index + 1,
                  errors: errors
                })
              }
            }
            )

            // this.setState({csvData: results})
            // results.forEach(payload=>this.props.createSale(payload))
            if (fileErrors.length > 0) {
              this.setState({ uploading: false, csvErrors: fileErrors })
            } else {


              let params = {
                FunctionName: process.env.REACT_APP_AWS_PRODUCTS_UPLOAD /* required */,
                Payload: JSON.stringify({
                  unique_key: getUser().id + "~" + Date.now(),
                  data: results,
                  user: getUser()
                })
              };

              lambda.invoke(params, (err, data) => {
                if (err) console.log(err, err.stack);
                else console.log(data, "Lambda_data");
              });

              this.setState({ csvErrors: [], uploading: false })
              setCsvDropOpen(false)
              notifySuccess('We are processing your products upload. We will email you when it is done.')
            }
          }
          );
        } else if (formValues && formValues.rewardsnav === "distsales") {
          // console.log("distsales");
          // const keys = ["order_number", "reseller_name", "date", "mnfr_pn", "mnfr_name", "quantity", "sales_amount", "customer_name"];

          // ///////////////VVVVVVVVVVV//////////////////

          // for (var ii = 1; ii < datasplit.length; ii++) {

          //   let data = datasplit[ii];

          //   let solodata = data.replace(/[\n\r]/g, '').split(","); //replace new lines and carriage returns, then create an array based on commas

          //   for (let x = 0; x < keys.length; x++) {
          //     if (solodata[x] === undefined) {
          //       this.setState({ uploading: false });
          //       return notifyError(`${keys[x].toUpperCase()} is missing. Please fix and try again`);
          //     }
          //   }
            
          //   for (let i = 1; i < solodata.length; i++) {

          //     let item = {
          //       [keys[0]]: solodata[0].trim() || '',
          //       [keys[1]]: solodata[1].trim() || '',
          //       [keys[2]]: solodata[2].trim() || null,
          //       [keys[3]]: solodata[3].trim() || '',
          //       [keys[4]]: solodata[4].trim() || '',
          //       [keys[5]]: solodata[5].trim() || null,
          //       [keys[6]]: solodata[6].trim() || null,
          //       [keys[7]]: solodata[7].trim() || ''
          //     };

          //     item.company_id = companyId;
          //     item.user_id = userId;

          //     results.push(item);

          //   }
          // }

          //   ///////////////AAAAAAAAAAAA////////////////

          //   results.forEach((item, index) => {
          //     let errors = []
          //     if (!keys.every(key => !item[key])) {

          //       keys.forEach(key => {

          //         if (key !== 'order_number' && !item[key]) return;

          //         if (key === "sales_amount" || key === "quantity") {
          //           if (!item[key] || isNaN(+item[key])) {
          //             errors.push(`Invalid Number at ${key}`)
          //           }
          //         } else if (key === "date") {
          //           if (!moment(item[key]).isValid()) {
          //             errors.push(`Invalid Date format at ${key} - Valid Format(MM/DD/YYYY)`)
          //           } else if (moment(item[key]).isBefore(new Date('01/01/2019'))) {
          //             errors.push(`Date Error at ${key} - Can be backdated up to 01/01/2019`)
          //           } else if (moment(item[key]).isAfter(moment())) {
          //             errors.push(`Date Error at ${key} - Can not be any date later than today`)
          //           }
          //         } else {
          //           if (!item[key]) {
          //             errors.push(`INVALID ${key}`)
          //           }
          //         }
          //       })
          //     }
          //     if (errors.length !== 0) {
          //       fileErrors.push({
          //         line: index + 1,
          //         errors: errors
          //       })
          //     }
          //   })

          //   console.log(fileErrors, "fileErrors");
          //   console.log(" results  ", results);
          //   // this.setState({csvData: results})
          //   // results.forEach(payload=>this.props.createSale(payload))
          //   if (fileErrors.length > 0) {
          //     this.setState({ uploading: false, csvErrors: fileErrors })
          //   } else {


          //     let params = {
          //       FunctionName: process.env.REACT_APP_AWS_DISTSALES_UPLOAD /* required */,
          //       Payload: JSON.stringify({
          //         unique_key: getUser().id + "~" + Date.now(),
          //         data: results,
          //         user: getUser()
          //       })
          //     };

          //     lambda.invoke(params, (err, data) => {
          //       if (err) console.log(err, err.stack);
          //       else console.log(data, "Lambda_data");
          //     });

          //     this.setState({ csvErrors: [], uploading: false })
          //     setCsvDropOpen(false)
          //     notifySuccess('We are processing your distribution sales upload. We will email you when it is done.')
          //   }
        
        }
      

    };

    reader.readAsText(file);
  }

  currencyParser = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.props.systemSettings.currency }).format(number)
  }

  _handleInvoiceStatusChange = value => {
    const { invoices } = this.state;
    let invoicesByStatus = [];

    if (value === 'all') {
      invoicesByStatus = invoices;
    } else if (value === 'paid' || value === 'open') {
      invoicesByStatus = invoices.filter(invoice => invoice.status === value)
    } else {

      invoicesByStatus = invoices.filter(invoice => {
        let pastDays = moment().diff(moment.unix(invoice.due_on), 'days');

        if (value === '90+ days' && pastDays >= 90) return true
        else if (value === '60-90 days' && pastDays >= 60) return true
        else if (value === '30-60 days' && pastDays >= 30) return true
        else if (value === '0-30 days' && pastDays > 0) return true
        else return false
      })


    }

    this.setState({ invoicesByStatus });
  }

  _handleStatementChange = (value, rewardsToUse = null) => {
    const rewards = rewardsToUse || this.props.rewards;
    const selectedAccount = document.getElementById('account_dropdown') && document.getElementById('account_dropdown').value;
    let theRewards = [];
    if (selectedAccount && selectedAccount !== 'all') {
      theRewards = rewards.filter(reward => reward.statement_date === value && reward.sales_id === selectedAccount);
    } else {
      theRewards = rewards.filter(reward => reward.statement_date === value);
    }
    this.setState({ rewardsByDate: theRewards.filter(reward => parseInt(reward.value) !== 0) })
  }

  _handleAccountChange = value => {
    this.setState({accountSelected: value});
    this._handleStatementChange(document.getElementById('statement_dropdown').value);
  }

  _handleCloseModal = () => {
    const { setCsvDropOpen } = this.props;
    const { uploading } = this.state;

    if (uploading) return;

    this.setState({ csvErrors: [] })
    setCsvDropOpen(false)
  }

  _getStatementDates = () => {

    const { rewards } = this.props;
    const DATE = new Date();
    let addCurrentMonth = true;
    let statement_dates = uniq(rewards.filter(rew => rew.statement_date).map(reward => reward.statement_date));
    statement_dates.forEach(date => {
      let dateR = new Date(date);
      if (dateR.getMonth() === DATE.getMonth() && dateR.getFullYear() === DATE.getFullYear()) {
        addCurrentMonth = false;
      }
    });

    if (addCurrentMonth) {
      DATE.setDate(14);
      statement_dates.push(DATE.toJSON().slice(0, 10))
    }

    return statement_dates;
  }

  render() {


    const { distsales, rewards, products, user, formValues, csvDropOpen, browser, systemSettings, companySettings, notificationCounts } = this.props;
    const {
      clickedRow,
      csvErrors,
      filteredData,
      projectedTotal,
      pendingTotal,
      paidTotal,
      uploading,
      distSalesUploadHistory,
      productsUploadHistory,
      rewardsByDate,
      theLabels,
      invoicesByStatus,
      invoices
    } = this.state;

    if (!user) return null;
    if (!(companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) return null;

    const invoicesColumns = [
      {
        Header: 'PAID',
        accessor: 'paid',
        width: 50,
        Cell: props => props.value ? <FontAwesomeIcon icon={faCheckCircle} color={props.index === clickedRow ? "#FFFFFF" : "#428BF4"} size="lg" /> : ""
      },
      {
        Header: 'INV. DATE',
        accessor: 'invoice_date',
        filterable: true,
        width: 100,
        Cell: row => moment.unix(row.value).format("MM/DD/YYYY"),
        sortMethod: (a, b) => b - a


      },
      //   {
      //     Header: 'DUE DATE',
      //     accessor: 'due_on',
      //     filterable: true,
      //     width: 100,
      //     Cell: row => moment(row.value,  moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
      //     sortMethod: (a,b)=> Date.parse(b) - Date.parse(a)


      // },
      {
        Header: 'INVOICE',
        accessor: 'invoice_number',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'INCENTIVE TITLE',
        accessor: 'incentive_title',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'BILL TO',
        accessor: 'bill_to',
        filterable: true
      },
      {
        Header: 'INCENTIVE ID',
        accessor: 'incentive_id',
        filterable: true,
        filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
      },
      {
        Header: '$',
        accessor: 'reward',
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => this.currencyParser(parseFloat(props.value))
      }
    ];

    const distSalesColumns = [
      {
        Header: 'ORDER NUMBER',
        accessor: 'order_number',
        filterable: true
      },
      {
        Header: 'RESELLER NAME',
        accessor: 'reseller_name',
        filterable: true
      },
      {

        Header: 'DATE',
        accessor: 'date',
        Cell: row => row.value ? moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY") : '',
        filterable: true,
        width: 80,
        sortMethod: (a, b) => Date.parse(b) - Date.parse(a),
        filterMethod: (filter, row) => row[filter.id] === moment(filter.value).subtract(7, 'hours').toISOString()

      },
      {
        Header: 'MFR PART#',
        accessor: 'mnfr_pn',
        filterable: true,
      },
      {
        Header: 'MFR NAME',
        accessor: 'mnfr_name',
        width: 80,
        filterable: true,
      },
      {
        Header: 'QTY',
        accessor: 'quantity',
        width: 60,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => props.value ? numberParser(parseFloat(props.value)) : ''
      },
      {
        Header: 'SALES AMOUNT',
        accessor: 'sales_amount',
        width: 70,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => props.value ? this.currencyParser(parseFloat(props.value)) : ''
      }
    ];

    const productsColumns = [

      {

        Header: 'Partner ID',
        accessor: 'partner_id',
        // Cell: row => moment(row.value,  moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
        filterable: true,
        // width: 100,
        // sortMethod: (a,b)=> Date.parse(b) - Date.parse(a),
        // filterMethod: (filter, row)=> row[filter.id] === moment(filter.value).subtract(7, 'hours').toISOString()

      },
      {

        Header: 'Partner Label',
        accessor: 'partner_label',
        // Cell: row => moment(row.value,  moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
        filterable: true,
        // width: 100,
        // sortMethod: (a,b)=> Date.parse(b) - Date.parse(a),
        // filterMethod: (filter, row)=> row[filter.id] === moment(filter.value).subtract(7, 'hours').toISOString()

      },
      {
        Header: systemSettings.part_number_name,
        accessor: 'sku',
        filterable: true,
        show: systemSettings.part_number_active
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'Description',
        accessor: 'description',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'Part Number',
        accessor: 'part_number',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'Cat. 1',
        accessor: 'category_one',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'Cat. 2',
        accessor: 'category_two',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'Cat. 3',
        accessor: 'category_three',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'DATE',
        accessor: 'created_at',
        filterable: true,
        Cell: row => moment(row.value, moment.ISO_8601).format("MM/DD/YYYY"),
        sortMethod: (a, b) => Date.parse(b) - Date.parse(a),
        filterMethod: (filter, row) => row[filter.id] === moment(filter.value).subtract(7, 'hours').toISOString()
      }
    ];

    const rewardsColumns = [
      {
        Header: 'STATUS',
        accessor: 'status',
        filterable: true,
        width: 60,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id].toLowerCase() === filter.value;
        },
        Filter: ({ filter, onChange }) => {
          const { filtered } = this.state;
          // console.log(filter, "FILTER")
          // console.log(filtered, "FILTERED")
          // if(filtered && filtered.value){
          //   onChange(filtered.value)
          // }
          return <select
            onChange={event => onChange(event.target.value)}
            // onChange={event => console.log(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="projected">Projected</option>
            <option value="pending">Pending</option>
            <option value="paid">Paid</option>
            <option value="void">Void</option>
          </select>
        }


      },
      {
        Header: 'DATE',
        Cell: row => moment(row.value, moment.ISO_8601).add(1, 'days').format("MM/DD/YYYY"),
        width: 80,
        accessor: 'posted_date',
        filterable: true,
        sortMethod: (a, b) => Date.parse(b) - Date.parse(a)
      },
      {
        Header: 'INCENTIVE ID',
        accessor: 'incentive_id',
        width: 90,
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)

      },
      {
        Header: 'SPONSOR NAME',
        accessor: 'sponsor_company_name',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)

      },
      // {
      //     Header: 'INCENTIVE TITLE',
      //     accessor: 'incentive_title',
      //     filterable: true,
      //     // filterMethod: (filter,row)=>row[filter.id].toLowerCase().includes(filter.value.toLowerCase())

      // },
      {
        Header: 'COMPANY NAME',
        accessor: 'vendor_company_name',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].toLowerCase().includes(filter.value.toLowerCase())

      },
      {
        Header: 'REP NAME',
        accessor: 'rep_name',
        filterable: true,
        // filterMethod: (filter,row)=>row[filter.id].includes(filter.value)
      },
      {
        Header: 'TYPE',
        accessor: 'value_type',
        show: getUser().company_admin
      },
      {
        Header: 'REWARD VALUE',
        accessor: 'total_value',
        width: 100,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => this.currencyParser(parseFloat(props.value))
      },
      {
        Header: 'EARNED',
        accessor: 'value',
        width: 100,
        sortMethod: (a, b) => parseFloat(b) - parseFloat(a),
        Cell: props => this.currencyParser(parseFloat(props.value))
      },
      {
        Header: 'REWARD ID',
        accessor: 'reward_id',
        show: false
      }
    ];



    invoices.forEach(item => {
      if (item.paid) {
        item.invoice_status = 'CLOSED'
      } else if (item.status !== 'open') {
        item.invoice_status = item.status.toUpperCase();
      } else {
        let pastDays = moment().diff(moment.unix(item.due_on), 'days');
        
        item.invoice_status = pastDays > 0 ? `${pastDays >= 90 ? 'Past Due (90+)' : pastDays >= 60 ? 'Past Due (60+)' : pastDays >= 30 ? 'Past Due (30+)' : 'Past Due (0+)'}` : "OPEN";
        item.invoice_age = ` ${moment().diff(moment.unix(item.invoice_date), 'days')} days`;
      }
      item.company_name = 'KickPost'
    })

    products.forEach(prod => prod.partner_label = theLabels[prod.partner_id] || "No Label");

    rewards.forEach(rew => {
      if (isNaN(parseFloat(rew.total_value))) {
        rew.total_value = parseFloat(rew.value)
      }
    })

    const columns = {
      rewards: rewardsColumns,
      distsales: distSalesColumns,
      invoices: invoicesColumns,
      products: productsColumns
    }

    const csvHeaders = {
      distsales: [
        { label: 'ORDER NUMBER', key: 'order_number' },
        { label: 'RESELLER NAME', key: 'reseller_name' },
        { label: 'DATE', key: 'date' },
        { label: 'MFR PART #', key: 'mnfr_pn' },
        { label: 'MFR NAME', key: 'mnfr_name' },
        { label: 'QTY', key: 'quantity' },
        { label: 'SALES AMOUNT', key: 'sales_amount' },
        { label: 'CUSTOMER NAME', key: 'customer_name' }
      ],

      invoices: [
        { label: 'Paid', key: 'paid' },
        { label: 'Invoice Date', key: 'date' },
        { label: 'Invoice Status', key: 'invoice_status' },
        { label: 'INVOICE #', key: 'invoice_number' },
        { label: 'INCENTIVE ID', key: 'incentive_id' },
        { label: 'INCENTIVE TITLE', key: 'incentive_title' },
        { label: 'COMPANY NAME', key: 'company_name' },
        { label: 'BILL TO', key: 'bill_to' },
        { label: 'AMOUNT', key: 'reward' }
      ],
      rewards: [
        { label: 'STATUS', key: 'status' },
        { label: 'REWARD ID', key: 'reward_id' },
        { label: 'REWARD DATE', key: 'posted_date' },
        { label: 'INCENTIVE ID', key: 'incentive_id' },
        { label: 'VENDOR COMPANY NAME', key: 'vendor_company_name' },
        { label: 'INCENTIVE TITLE', key: 'incentive_title' },
        { label: 'NAME', key: 'rep_name' },
        { label: 'REWARD VALUE', key: 'value' }
      ],
      products: [
        { label: 'PARTNER ID', key: 'partner_id' },
        { label: 'PARTNER LABEL', key: 'partner_label' },
        { label: systemSettings.part_number_name.toUpperCase(), key: 'sku' },
        { label: 'DESCRIPTION', key: 'description' },
        { label: 'PART NUMBER', key: 'part_number' },
        { label: 'CATEGORY ONE', key: 'category_one' },
        { label: 'CATEGORY TWO', key: 'category_two' },
        { label: 'CATEGORY THREE', key: 'category_three' },
        { label: 'DATE', key: 'created_at' }
      ]
    }

    const csvTemplateHeaders = {
      distsales: [
        { label: 'ORDER NUMBER', key: 'order_number' },
        { label: 'RESELLER NAME', key: 'reseller_name' },
        { label: 'DATE', key: 'date' },
        { label: 'MFR PART #', key: 'mnfr_pn' },
        { label: 'MFR NAME', key: 'mnfr_name' },
        { label: 'QTY', key: 'quantity' },
        { label: 'SALES AMOUNT', key: 'sales_amount' },
        { label: 'CUSTOMER NAME', key: 'customer_name' }
      ],
      products: [
        { label: 'PARTNER ID', key: 'partner_id' },
        { label: 'DESCRIPTION', key: 'description' },
        { label: 'PART NUMBER', key: 'part_number' },
        { label: 'CATEGORY ONE', key: 'category_one' },
        { label: 'CATEGORY TWO', key: 'category_two' },
        { label: 'CATEGORY THREE', key: 'category_three' },
        { label: systemSettings.part_number_name.toUpperCase(), key: 'sku' },
        { label: "STATUS", key: 'status' }
      ]
    }

    const data = {
      rewards: rewards.filter(reward => parseFloat(reward.value) !== 0),
      distsales,
      invoices: invoicesByStatus,
      products
    }

    const prevValues = rewardsByDate.filter(rew => rew.status === 'Projected').map(item => parseFloat(item.prev_value));

    const totalProjected = rewardsByDate.filter(rew => rew.status === 'Projected').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
    const totalPrevProjected = rewardsByDate.filter(rew => rew.status === 'Projected').map(item => parseFloat(item.prev_value)).reduce((a, b) => a + b, 0);
    const changePercent = totalPrevProjected && ((totalProjected - totalPrevProjected) / totalPrevProjected * 100);
    const totalPending = rewardsByDate.filter(rew => rew.status === 'Pending').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);
    const totalPaid = rewardsByDate.filter(rew => rew.status === 'Paid').map(item => parseFloat(item.value)).reduce((a, b) => a + b, 0);

    if (formValues && (formValues.rewardsnav === 'invoices' || formValues.rewardsnav === 'products')) {
      return <div style={{ height: "89vh", overflowY: "auto", paddingRight: '25px' }}>
        <Flex
          flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
          justifyContent='center'
          alignItems='center'
          style={{ width: '100%', marginTop: '35px' }}
        >
          {
            formValues.rewardsnav !== 'products'
              ?
              <Flex
                alignItems='center'
                style={{ marginBottom: '10px' }}
                flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
                position="relative"
              >
                <NunitoSans key="nunito39"
                  fontWeight='bold'
                >
                  STATUS
                </NunitoSans>

                <SelectStyled
                  key="SelectStyled01"
                  id={`${formValues.rewardsnav}_dropdown`}
                  style={{ padding: "10px 10px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000", marginLeft: '10px' }}
                  fontSize={['12px', '12px', '16px', '16px']}
                  onChange={e => this._handleInvoiceStatusChange(e.target.value)}
                >
                   <>
                        <option
                          style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                          value={'all'}
                          key={'all_invoices'}

                        >
                          ALL
                        </option>
                        {
                          ['open', '0-30 days', '30-60 days', '60-90 days', '90+ days', 'closed'].map(status => <option
                            style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                            value={status}
                            key={status}
                          >
                            {status.toUpperCase()}
                          </option>)
                        }
                      </>

                </SelectStyled>

                {
                  browser.greaterThan.medium && formValues.rewardsnav === 'invoices' && notificationCounts.rewards
                    ?
                    <NavNotificationBubble
                      top="-5px"
                      left="195px"
                    >
                      {notificationCounts.rewards}
                    </NavNotificationBubble>
                    :
                    null
                }

              </Flex>
              :
              null
          }
        </Flex>

        {
          formValues.rewardsnav !== 'products'
            ?
            <Flex
              flexDirection="column"
              style={{ marginTop: '25px' }}
            >
              <Flex
                justifyContent="space-between"
                alignItems='center'
                style={{ borderBottom: '1px solid #428BF4' }}
              >
                <NunitoSans key="nunito02"
                  fontSize="14px"
                  color="#428BF4"
                  fontWeight="bold"
                >
                  {`${formValues.rewardsnav.toUpperCase()} SUMMARY`}
                </NunitoSans>
                
              </Flex>

              <>
                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ backgroundColor: "#FAFAFA", paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito04"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    Invoice Total
                  </NunitoSans>
                  <NunitoSans key="nunito05"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {this.currencyParser(invoicesByStatus.map(invoice => parseFloat(invoice.reward)).reduce((a, b) => a + b, 0))}
                  </NunitoSans>

                </Flex>

                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito06"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    Invoice Count
                  </NunitoSans>
                  <NunitoSans key="nunito07"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {numberParser(invoicesByStatus.length)}
                  </NunitoSans>

                </Flex>

              </>





            </Flex>
            :
            null
        }
        <NunitoSans key="nunito08"
          fontSize="18px"
          fontWeight="bold"
          color="#000000"
          style={{ width: "100%", textAlign: "center", marginTop: "37px", marginBottom: "17px" }}
        >
          {`${formValues.rewardsnav.toUpperCase()} DETAILS`}
        </NunitoSans>
        {
          browser.greaterThan.medium
            ?
            <>
              <CsvDropzoneModal
                modalOpen={csvDropOpen}
                handleCloseRequest={this._handleCloseModal}
                handleCsvUpload={uploading ? () => { } : this._handleCsvUpload}
                csvName={formValues.rewardsnav}
                onload={(data, fileInfo, originalFile) => console.dir(data, fileInfo, originalFile)}
                uploading={uploading}
                csvErrors={csvErrors}
                csvHeaders={csvTemplateHeaders[formValues.rewardsnav]}
                uploadHistory={formValues.rewardsnav === 'products' ? productsUploadHistory : []}
              />

              <ReactTable
                noDataText={"NO RESULTS"}
                noDataTextCustom={"NO RESULTS"}
                ref={(r) => {
                  this.selectTable = r;
                }}
                onFilteredChange={(filtered2) => {
                  this.setState({ filtered2, filteredData: this.selectTable.getResolvedState().sortedData.map(item => item._original) })
                 
                }}
                filtered={this.state.filtered2}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}

                className="-striped -highlight tablehover MyReactTableClass"
                columns={columns[formValues.rewardsnav]}
                data={data[formValues.rewardsnav]}
                defaultSorted={[
                  {
                    id: "invoice_date",
                    desc: false
                  }
                ]}

                defaultPageSize={20}
                resizable={true}
                style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none", marginTop: '35px' }}
                getTrProps={(state, rowInfo, column, instance) => {
                  const { setRowSelected, rowSelected } = this.props;
                  return {
                    onClick: (e, handleOriginal) => {
                      console.log('A Td Element was clicked!')
                      // console.log('it produced this event:', e)
                      // console.log('It was in this column:', column)
                      // console.log('It was in this row:', rowInfo)
                      // console.log('It was in this table instance:', instance)

                      this.setState({ clickedRow: rowInfo.index })
                      rowInfo.original.type = formValues.rewardsnav;
                      setRowSelected(rowInfo.original)
                      // console.log(rowInfo.original)




                      // IMPORTANT! React-Table uses onClick internally to trigger
                      // events like expanding SubComponents and pivots.
                      // By default a custom 'onClick' handler will override this functionality.
                      // If you want to fire the original onClick handler, call the
                      // 'handleOriginal' function.
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowInfo && this.state.clickedRow === rowInfo.index ? "#428BF4" : null,
                      color: rowInfo && this.state.clickedRow === rowInfo.index ? "#FFFFFF" : null
                    }
                  }
                }}
              />
              <Flex
                justifyContent="center"
              >

                <DashboardSmallCTA
                  color="#428BF4"
                  fontSize="15px"
                  style={{ marginTop: '15px', width: '33%' }}
                >
                  <CSVLink
                    data={(filteredData ? filteredData : data[formValues.rewardsnav])}
                    headers={csvHeaders[formValues.rewardsnav]}
                    filename={`${formValues.rewardsnav}-data_${new Date().toJSON().slice(0, 10)}.csv`}
                  >Export Data as CSV</CSVLink>
                </DashboardSmallCTA>
              </Flex>
            </>
            :
            <NunitoSans key="nunito09"
              fontSize="18px"
              fontWeight="bold"
              color="#6C7168"
              style={{ width: "100%", textAlign: "center", marginTop: "17px" }}
            >
              Details can be seen on the full screen version of the page.
            </NunitoSans>
        }


      </div>

    } else if (formValues && formValues.rewardsnav === 'distsales') {

      return <div style={{ height: "89vh", overflowY: "auto", paddingRight: '25px' }}>
        <NunitoSans key="nunito10"
          fontSize="18px"
          fontWeight="bold"
          color="#000000"
          style={{ width: "100%", textAlign: "center", marginTop: "37px", marginBottom: "17px" }}
        >
          {`DISTRIBUTION SALES DETAILS`}
        </NunitoSans>
        {
          browser.greaterThan.medium
            ?
            <>
              <CsvDropzoneModal
                modalOpen={csvDropOpen}
                handleCloseRequest={this._handleCloseModal}
                handleCsvUpload={uploading ? () => { } : this._handleCsvUpload}
                csvName={formValues.rewardsnav}
                uploading={uploading}
                csvErrors={csvErrors}
                csvHeaders={csvTemplateHeaders[formValues.rewardsnav]}
                uploadHistory={formValues.rewardsnav === 'distsales' ? distSalesUploadHistory : formValues.rewardsnav === 'products' ? productsUploadHistory : []}
              />

              <ReactTable
                noDataText={"NO RESULTS"}
                noDataTextCustom={"NO RESULTS"}
                ref={(r) => {
                  this.selectTable = r;
                }}
                onFilteredChange={(filtered2) => {
                  this.setState({ filtered2, filteredData: this.selectTable.getResolvedState().sortedData.map(item => item._original) })
                  
                }}
                filtered={this.state.filtered2}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}

                className="-striped -highlight tablehover MyReactTableClass"
                columns={columns[formValues.rewardsnav]}
                data={data[formValues.rewardsnav]}
                defaultSorted={[
                  {
                    id: "invoice_date",
                    desc: false
                  }
                ]}

                // showPagination={false}
                defaultPageSize={20}
                resizable={true}
                style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none", marginTop: '35px' }}
                getTrProps={(state, rowInfo, column, instance) => {
                  const { setRowSelected, rowSelected } = this.props;
                  return {
                    onClick: (e, handleOriginal) => {
                      console.log('A Td Element was clicked!')
                      // console.log('it produced this event:', e)
                      // console.log('It was in this column:', column)
                      // console.log('It was in this row:', rowInfo)
                      // console.log('It was in this table instance:', instance)

                      this.setState({ clickedRow: rowInfo.index })
                      rowInfo.original.type = formValues.rewardsnav;
                      setRowSelected(rowInfo.original)
                      // console.log(rowInfo.original)




                      // IMPORTANT! React-Table uses onClick internally to trigger
                      // events like expanding SubComponents and pivots.
                      // By default a custom 'onClick' handler will override this functionality.
                      // If you want to fire the original onClick handler, call the
                      // 'handleOriginal' function.
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowInfo && this.state.clickedRow === rowInfo.index ? "#428BF4" : null,
                      color: rowInfo && this.state.clickedRow === rowInfo.index ? "#FFFFFF" : null
                    }
                  }
                }}
              />
              <Flex
                justifyContent="center"
              >

                <DashboardSmallCTA
                  color="#428BF4"
                  fontSize="15px"
                  style={{ marginTop: '15px', width: '33%' }}
                >
                  <CSVLink
                    data={(filteredData ? filteredData : data[formValues.rewardsnav]).map(item => _.omit(item, formValues.rewardsnav === 'distsales' ? ['id', 'company_id', 'created_at', 'sales_key', 'user_id', 'history'] : []))}
                    headers={csvHeaders[formValues.rewardsnav]}
                    filename={`${formValues.rewardsnav}-data_${new Date().toJSON().slice(0, 10)}.csv`}
                  >Export Data as CSV</CSVLink>
                </DashboardSmallCTA>
              </Flex>
            </>
            :
            <NunitoSans key="nunito11"
              fontSize="18px"
              fontWeight="bold"
              color="#6C7168"
              style={{ width: "100%", textAlign: "center", marginTop: "17px" }}
            >
              Details can be seen on the full screen version of the page.
            </NunitoSans>
        }


      </div>

    } else {
      

      return <div style={{ height: "89vh", overflowY: "auto", overflowx: "hidden", paddingRight: '25px' }}>

        <Flex
          flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
          justifyContent='center'
          alignItems='center'
          style={{ width: '100%', marginTop: '35px' }}
        >
          <Flex
            alignItems='center'
            style={{ marginBottom: '10px' }}
            flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
          >
            <NunitoSans key="nunito12"
              fontWeight='bold'
            >
              STATEMENT
            </NunitoSans>

            <SelectStyled
              key="SelectStyled02"
              id="statement_dropdown"
              style={{ padding: "10px 10px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000", marginLeft: '10px' }}
              fontSize={['12px', '12px', '16px', '16px']}
              onChange={e => this._handleStatementChange(e.target.value)}
            >

              {this._getStatementDates()
                .sort((dt1, dt2) => {
                  if (moment(dt1).isAfter(dt2)) {
                    return -1;
                  } else if (moment(dt1).isBefore(dt2)) {
                    return 1;
                  }

                  return 0;
                })
                .map(date => <option
                  style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                  value={date}
                  key={date}
                >
                  {moment(date).add(1, 'day').format('MMMM') + ' ' + moment(date).add(1, 'day').format('YYYY')}
                </option>
                )}
            </SelectStyled>

          </Flex>
        </Flex>
        <Flex
          flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
          justifyContent='center'
          alignItems='center'
          style={{ width: '100%', marginTop: '25px' }}
        >

          {
            uniqBy(rewards, 'user_id').length > 1
              ?
              <Flex
                alignItems='center'
                justifyContent='space-between'
                style={{ marginBottom: '10px' }}
                flexDirection={browser.greaterThan.medium ? 'row' : 'column'}
              >
                <NunitoSans key="nunito13"
                  fontWeight='bold'
                >
                  ACCOUNT
                </NunitoSans>


                <SelectStyled
                  key="SelectStyled03"
                  id="account_dropdown"
                  style={{ padding: "10px 10px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000", marginLeft: '10px' }}
                  value={this.state.accountSelected}
                  fontSize={['12px', '12px', '16px', '16px']}
                  onChange={e => this._handleAccountChange(e.target.value)}
                >

                  <option
                    style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                    value={'all'}
                    key={'all'}

                  >
                    ALL
                  </option>

                  {sortBy(uniqBy(rewards, 'user_id'), 'rep_name').map(rew =>
                    <option
                      style={{ textAlign: "center", width: "100%", fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                      value={rew.sales_id}
                      key={[ `${rew.rep_name}-${rew.sales_id}-${Math.random()}`]} >
                      {`${rew.rep_name.length > 16 ? rew.rep_name.slice(0, 16).toUpperCase() + '...' : rew.rep_name.toUpperCase()} ${rew.rep_name.split(' ')[1] === "House" ? '' : '(' + rew.sales_id + ')'}`}
                    </option>
                  )}
                </SelectStyled>

              </Flex>
              :
              null
          }
        </Flex>
        <Flex
          flexDirection="column"
          style={{ marginTop: '25px' }}
        >
          <Flex
            justifyContent="space-between"
            alignItems='center'
            style={{ borderBottom: '1px solid #428BF4' }}
          >
            <NunitoSans key="nunito14"
              fontSize="14px"
              color="#428BF4"
              fontWeight="bold"
            >
              REWARD SUMMARY
            </NunitoSans>
            {
              totalProjected
                ?
                <NunitoSans key="nunito15"
                  fontSize="12px"
                  color="#000000"
                  fontWeight="bold"
                >
                  {`Results as of ${moment().format('L')}`}
                </NunitoSans>
                :
                null
            }


          </Flex>

          <Flex
            justifyContent="space-between"
            alignItems='center'
            style={{ backgroundColor: "#FAFAFA", paddingTop: "11px", paddingBottom: "11px" }}
          >
            <NunitoSans key="nunito01"
              fontSize="16px"
              color="#A3A99E"
              fontWeight="bold"
              style={{ paddingLeft: "35px" }}
            >
              {totalProjected !== 0 ? "Projected" : "Total"}
            </NunitoSans>
            <NunitoSans key="nunito16"
              fontSize="16px"
              color="#000000"
              fontWeight="bold"
              textAlign="right"
              style={{ paddingRight: "35px" }}
              width="30%"
            >
              {this.currencyParser(totalProjected || (totalPending + totalPaid))}
            </NunitoSans>

          </Flex>
          {
            totalProjected
              ?
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito17"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    +/-
                  </NunitoSans>
                  <NunitoSans key="nunito18"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {this.currencyParser(prevValues.length === 0 || prevValues.every(num => num === 0) ? 0 : totalProjected - totalPrevProjected)}
                  </NunitoSans>

                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ backgroundColor: "#FAFAFA", paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito19"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    % Change
                  </NunitoSans>
                  <NunitoSans key="nunito20"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {(totalPrevProjected !== 0 ? numberParser(changePercent) : 0) + " %"}
                  </NunitoSans>

                </Flex>
              </>
              :
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito21"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    Pending
                  </NunitoSans>
                  <NunitoSans key="nunito22"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {this.currencyParser(totalPending)}
                  </NunitoSans>

                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems='center'
                  style={{ backgroundColor: "#FAFAFA", paddingTop: "11px", paddingBottom: "11px" }}
                >
                  <NunitoSans key="nunito23"
                    fontSize="16px"
                    color="#A3A99E"
                    fontWeight="bold"
                    style={{ paddingLeft: "35px" }}
                  >
                    Paid
                  </NunitoSans>
                  <NunitoSans key="nunito24"
                    fontSize="16px"
                    color="#000000"
                    fontWeight="bold"
                    textAlign="right"
                    style={{ paddingRight: "35px" }}
                    width="30%"
                  >
                    {this.currencyParser(totalPaid)}
                  </NunitoSans>

                </Flex>

              </>

          }

          <Flex
            justifyContent="space-between"
            alignItems='center'
            style={{ paddingTop: "11px", paddingBottom: "11px" }}
          >
            <NunitoSans key="nunito25"
              fontSize="16px"
              color="#A3A99E"
              fontWeight="bold"
              style={{ paddingLeft: "35px" }}
            >
              Statement Date
            </NunitoSans>
            <NunitoSans key="nunito26"
              fontSize="16px"
              color="#000000"
              fontWeight="bold"
              textAlign="right"
              style={{ paddingRight: "35px" }}
              width="30%"
            >
              {document.getElementById('statement_dropdown') ? moment(document.getElementById('statement_dropdown').value).add(1, 'd').format('L') : ''}
            </NunitoSans>

          </Flex>

        </Flex>
        <NunitoSans key="nunito27"
          fontSize="18px"
          fontWeight="bold"
          color="#000000"
          style={{ width: "100%", textAlign: "center", marginTop: "37px", marginBottom: "17px" }}
        >
          REWARDS DETAILS
        </NunitoSans>
        {
          browser.greaterThan.medium
            ?
            <>
              <ReactTable
                noDataText={"NO RESULTS"}
                noDataTextCustom={"NO RESULTS"}
                ref={(r) => {
                  this.selectTable = r;
                }}
                onFilteredChange={(filtered) => {
                  this.setState({ filtered, filteredData: this.selectTable.getResolvedState().sortedData.map(item => item._original) })
                }}

                className="-striped -highlight tablehover"
                columns={rewardsColumns}
                data={rewardsByDate}
                resizable={true}
                filtered={this.state.filtered}
                // onFilteredChange={filtered => this.setState({ filtered })}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                // showPagination={false}
                defaultPageSize={20}
                style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", fontFamily: "NunitoSans", color: "#6C7168", width: "100%", border: "none", marginTop: '35px' }}
                getTrProps={(state, rowInfo, column, instance) => {
                  const { setRowSelected } = this.props;
                  return {
                    onClick: (e, handleOriginal) => {
                      console.log('A Td Element was clicked!')
                      // console.log('it produced this event:', e)
                      // console.log('It was in this column:', column)
                      // console.log('It was in this row:', rowInfo)
                      // console.log('It was in this table instance:', instance)

                      this.setState({ clickedRow: rowInfo.index })
                      rowInfo.original.type = "rewards";
                      setRowSelected(rowInfo.original)




                      // IMPORTANT! React-Table uses onClick internally to trigger
                      // events like expanding SubComponents and pivots.
                      // By default a custom 'onClick' handler will override this functionality.
                      // If you want to fire the original onClick handler, call the
                      // 'handleOriginal' function.
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowInfo && this.state.clickedRow === rowInfo.index ? "#428BF4" : null,
                      color: rowInfo && this.state.clickedRow === rowInfo.index ? "#FFFFFF" : null
                    }
                  }
                }}
              />
              <Flex
                justifyContent="center"
              >

                <DashboardSmallCTA
                  color="#428BF4"
                  fontSize="15px"
                  style={{ marginTop: '15px', width: '33%' }}
                >
                  <CSVLink
                    data={(filteredData ? filteredData : rewardsByDate).map(item => _.omit(item, ['id', 'user_id', 'type', 'note']))}
                    headers={csvHeaders.rewards}
                    filename={`rewards-data_${new Date().toJSON().slice(0, 10)}.csv`}
                  >Export Data as CSV</CSVLink>
                </DashboardSmallCTA>
              </Flex>
            </>
            :
            <NunitoSans key="nunito28"
              fontSize="18px"
              fontWeight="bold"
              color="#6C7168"
              style={{ width: "100%", textAlign: "center", marginTop: "17px" }}
            >
              Details can be seen on the full screen version of the page.
            </NunitoSans>
        }

      </div>
    }




  }
}

ManageRewardsMain = reduxForm({
  // destroyOnUnmount: true,
  form: "rewardsPeriod-form",
  // validate,
  // enableReinitialize : true
})(ManageRewardsMain);

export default connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    formValues: getFormValues('rewardsNav-form')(state),
    distsales: state.resources.collection.distsale || [],
    rewards: state.resources.collection.reward || [],
    invoices: state.resources.collection.invoice || [],
    products: state.resources.collection.product || [],
    csvuploads: state.resources.collection.csvupload || [],
    user: state.resources.detail.user,
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchDistsales: bindActionCreators(actions.fetchDistsales, dispatch),
    fetchCsvuploads: bindActionCreators(actions.fetchCsvuploads, dispatch),
    fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
    fetchProducts: bindActionCreators(actions.fetchProducts, dispatch),
    fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
  })
)(ManageRewardsMain);






class ManageRewardsNav extends Component {
  constructor() {
    super();
  }
  
  state = {
    stripeModalOpen: false
  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const target = searchParams.get('target');

    if (target === 'invoices') {

      this.props.initialize({ rewardsnav: target })

    } else {
      this.props.initialize({ rewardsnav: 'rewards' })
    }
    this.props.setRowSelected({});
   
  }

  _handleStripeButton = async () => {
    const { user } = this.props;
    if (!user) return;

    let url;

    if (user && user.stripe_connect_id) {
      let stripeUrl = await fetch(`${process.env.REACT_APP_API_URL}/stripelogin/${user.stripe_connect_id}`);
      stripeUrl = await stripeUrl.json();
      url = stripeUrl.url;
      window.open(url);

    } else {

      this.setState({ stripeModalOpen: true })

    }

  }

  render() {

    const { stripeModalOpen } = this.state;

    const { formValues, setCsvDropOpen, companySettings, systemSettings, browser, notificationCounts } = this.props;



    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "600px",
        maxHeight: "600px",
        zIndex: 999998,
        overflow: "auto",
        padding: "40px",
        marginTop: "20px",
        marginBottom: "20px"
      },

      overlay: {
        zIndex: 999997,

        background: "#A3A99E88"
      }

    };



    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        {
          formValues && formValues.rewardsnav === 'invoices'
            ?
            <InfoModal
              key={"invoices_modal"}
              modalName='invoices_modal'  //no spaces. Once set right do not change
              modalVersion="1.00" //set it to 1.00 initially then change it when needed
              image={invoicesLogo}
              title='Invoices'
              text='View, download and track invoices associated to your account. '
            // videoLink='https://youtu.be/xFYRTlTuTHA'
            />
            :
            null
        }

        {
          !formValues || (formValues && formValues.rewardsnav !== 'invoices')
            ?
            <InfoModal
              key={"rewards_modal"}
              modalName='rewards_modal'  //no spaces. Once set right do not change
              modalVersion="1.00" //set it to 1.00 initially then change it when needed
              image={rewardsLogo}
              title='Manage Rewards'
              text='Track data, view details, stay up-to-date, and export records of your rewards. '
            // videoLink='https://youtu.be/xFYRTlTuTHA'
            />
            :
            null
        }

        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >

          <form id="rewardsNav-form">
            <Field
              key={"rewardsnav"}
              name="rewardsnav"
              className="dropdown"
              component="select"
              style={{ padding: "10px 25px", border: '1px solid #428BF4', borderRadius: "9px", textAlign: "center", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#000000" }}
              onChange={() => this.props.setRowSelected({})}
            >

              <option
                style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                value="rewards"
              >
                REWARDS
              </option>

              {
                companySettings.sale_verification_active
                  ?
                  <option
                    style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                    value="distsales"
                  >
                    DISTY SALES
                  </option>
                  :
                  null
              }
              {
                getUser().company_admin && companySettings.create_incentives
                  ?
                  <option

                    style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                    value="invoices"
                  // selected={query === 'invoices'}
                  >
                    {`INVOICES${notificationCounts.rewards ? ' (' + notificationCounts.rewards + ')' : ''}`}
                  </option>
                  :
                  null
              }
              {
                systemSettings.product_active && getUser().company_admin
                  ?
                  <option

                    style={{ textAlign: "center", width: "100%", fontSize: '20px', fontWeight: "bold", fontFamily: "NunitoSans", color: "#A3A99E" }}
                    value="products"

                  >
                    PRODUCTS
                  </option>
                  :
                  null
              }
            </Field>
          </form>

          <QuestionModalTrigger modalName={formValues && `${formValues.rewardsnav}_modal`} />

          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
            >

              <BackButton />

            </Flex>
          </AbsoluteDiv>
          {
            browser.greaterThan.medium && formValues && companySettings && ((formValues.rewardsnav === "products" && companySettings.create_incentives) || formValues.rewardsnav === "distsales") && getUser().company_admin ?
              <AbsoluteDiv
                right={["0px", "0px", "0px", "0px", "-5%"]}
                bottom={"0px"}
                top={"0px"}
                width={["auto", "auto", "auto", "auto", "20%"]}
              >
                <DashboardSmallCTA
                  onClick={() => setCsvDropOpen(true)}
                  fontSize="15px"
                  width="130px"
                  color="#428BF4"
                >
                  UPLOAD
                </DashboardSmallCTA>

              </AbsoluteDiv>

              :
              null
          }
          {
            browser.greaterThan.medium && formValues && formValues.rewardsnav === "rewards" && companySettings && companySettings.receive_incentives ?
              <AbsoluteDiv
                // right={["0px", "0px", "0px", "0px", "0%"]}
                right={"-25px"}
                bottom={"0px"}
                top={"0px"}
                width={["auto", "auto", "auto", "auto"]}
              >
                <DashboardSmallCTA
                  onClick={() => this._handleStripeButton()}
                  fontSize={['10px', '10px', "16px", '16px']}
                  width="15%"
                  minWidth={["70px", "70px", "122px", "122px"]}
                  innerHeight="40px"
                  color="#428BF4"
                >
                  REDEEM
                </DashboardSmallCTA>

              </AbsoluteDiv>

              :
              null
          }
        </Flex>

        <Modal
          isOpen={stripeModalOpen}
          onRequestClose={() => this.setState({ stripeModalOpen: false })}
          style={customStyles}
          contentLabel="Stripe Modal"
          ariaHideApp={false}
        >
          <Flex
            flexDirection="column"
            alignItems='flex-start'
            justifyContent='space-between'
            height="100%"
          >
            <NunitoSansBold key="nunito29" alignSelf='center' fontsSize='18px'>
              REDEEM REWARDS
            </NunitoSansBold>
            <br />
            <NunitoSans key="nunito30" >
              We've made it easy for you to receive the rewards you earn from all your partners. Here's how it works:
            </NunitoSans>
            <br />
            <NunitoSansBold key="nunito31" >Step 1:</NunitoSansBold>
            <NunitoSans key="nunito32" >
              Earn rewards by selling qualified products and services.
            </NunitoSans>
            <br />
            <NunitoSansBold key="nunito33" >Step 2:</NunitoSansBold>
            <NunitoSans key="nunito34" >
              We calculate your rewards and your partners pay KickPost.
            </NunitoSans>
            <br />
            <NunitoSansBold key="nunito35" >Step 3:</NunitoSansBold>
            <NunitoSans key="nunito36" >
              We pay you.
            </NunitoSans>
            <br />
            <NunitoSans key="nunito37" >
              To redeem your rewards, you're required to activate a stripe account. This account is secure and simple to use. Once your rewards are paid out, you can transfer your reward dollars directly to your linked bank account.

            </NunitoSans>
            <br />
            <NunitoSans key="nunito38" >

              It's that simple!

            </NunitoSans>
            <br />
            <DashboardSmallCTA

              onClick={() => onboardStripeConnectAccount()}
              minWidth={["122px"]}
              fontSize="14px"
              innerHeight="35px"
              width="225px"
              color="#428BF4"
              alignSelf="center"
            >
              CREATE STRIPE ACCOUNT
            </DashboardSmallCTA>
          </Flex>

        </Modal>

      </Flex>
    )
  }
};

ManageRewardsNav = reduxForm({
  destroyOnUnmount: false,
  form: "rewardsNav-form",
  // initialValues: {
  //   rewardsnav: 'sales'
  // },
  // validate,
  enableReinitialize: true
})(ManageRewardsNav);

ManageRewardsNav = connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    user: state.resources.detail.user,
    formValues: getFormValues('rewardsNav-form')(state),
    rowSelected: state.incentives.rowSelected,
    csvDropOpen: state.incentives.csvDropOpen,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
    setRowSelected: bindActionCreators(setRowSelected, dispatch),
    setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch)
  })
)(ManageRewardsNav);

export { ManageRewardsNav };

function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}