import _ from "underscore";

import { getUser } from "../utils/auth";

export const updateSelectedUsers = payload => ({
  type: "SET_SELECTED_USERS",
  payload
});

export const updateSelectedCompanies = payload => ({
  type: "SET_SELECTED_COMPANIES",
  payload
});

export const clearSelectedDepartments = payload => ({
  type: "CLEAR_SELECTED_DEPARTMENTS",
  payload
});

export const clearSelectedGroups = payload => ({
  type: "CLEAR_SELECTED_GROUPS",
  payload
});

export const clearSelectedUsers = payload => ({
  type: "CLEAR_SELECTED_USERS",
  payload
});

export const clearSelectedCompanies = payload => ({
  type: "CLEAR_SELECTED_COMPANIES",
  payload
});

export const updateSelectedDepartments = payload => ({
  type: "SET_SELECTED_DEPARTMENTS",
  payload
});

export const updateSelectedGroups = payload => ({
  type: "SET_SELECTED_GROUPS",
  payload
});

export const updateSelectedRows = payload => ({
  type: "SET_SELECTED_ROWS",
  payload
});

export const clearSelectedRows = payload => ({
  type: "CLEAR_SELECTED_ROWS",
  payload
});

export const selectRow = (
  itemId,
  selectedItems,
  itemType,
  selectedRows,
  companyId,
  item
) => {
  return dispatch => {
    let selectedItemsCopy = selectedItems.slice();
    let selectedRowsCopy = selectedRows.slice();
    let existingItem = _.indexOf(_.pluck(selectedItems, "id"), itemId);
    let existingRow = _.indexOf(_.pluck(selectedRows, "id"), itemId);

    console.log(itemId,
      selectedItems,
      itemType,
      selectedRows,
      companyId,
      item, 'INSIDE', "selectROW")

    if (itemType === 'group') {
      companyId.forEach(companyId => {

        if (existingItem === -1) {
          selectedItemsCopy.push({
            id: itemId,
            company_id: companyId
          });
        } else {
          selectedItemsCopy.splice(existingItem, 1);
        }
      })

      if (companyId.length === 0) {
        if (existingItem === -1) {
          selectedItemsCopy.push({
            id: itemId,
            company_id: ''
          });
        } else {
          selectedItemsCopy.splice(existingItem, 1);
        }
      }

    } else if (existingItem === -1) {
      selectedItemsCopy.push({
        id: itemId,
        company_id: companyId
      });
    } else {
      selectedItemsCopy.splice(existingItem, 1);
    }


    if (existingRow === -1) {
      selectedRowsCopy.push(item);
    } else {
      selectedRowsCopy.splice(existingItem, 1);
    }

    const updateMethods = {
      company: updateSelectedCompanies,
      person: updateSelectedUsers,
      department: updateSelectedDepartments,
      group: updateSelectedGroups
    };

    console.log(selectedItems)

    dispatch(updateSelectedRows(selectedRowsCopy));
    return dispatch(updateMethods[itemType](selectedItemsCopy));
  };
};

export const preSelectRows = (
  payload,
  itemType
) => {
  return dispatch => {

    const updateMethods = {
      company: updateSelectedCompanies,
      person: updateSelectedUsers,
      department: updateSelectedDepartments,
      group: updateSelectedGroups
    };



    dispatch(updateSelectedRows(payload));
    return dispatch(updateMethods[itemType](payload));
  };
};

export const clearRows = () => {
  return dispatch => {

    dispatch(clearSelectedRows());
    dispatch(clearSelectedUsers());
    dispatch(clearSelectedDepartments());
    dispatch(clearSelectedGroups());
    dispatch(clearSelectedCompanies());

    return [];
  }
}

export const companyTotal = (connections, user) => {
  // let companies = _
  //   .chain(connections)
  //   .pluck("company_id")
  //   .value();
  //   console.log(companies)

  if (!user) return 0;

  // let companies = connections.map(item => item.company_id).concat(connections.map(item => item.vendor_id));
  let companies = connections.map(connection => connection.company && connection.company.id);
  companies = _.uniq(companies);




  let companiez = companies.filter(company => typeof company !== "undefined");
  let companias = companiez;
  //console.log(companias)

  companiez = companiez.filter(company => (typeof company !== "undefined") && (company !== undefined) && (company.length > 0));
  //console.log(companiez)

  //FOLLOWING THREE LINES COMMENTED OUT ON SEPT15_2023 TO SHOW STANDARD ACCOUNTS THEIR AUDIENCE REACH
  // if ((user.company.paid === "false") || (user.company.paid === false)) {
  //   companias = _.filter(companiez, company => company === user.company_id);
  // }

  return companias.length;
  if (companias.length === 0) {
    return companias.length + 1;
  } else {
    return companias.length;
  }
};





export const peopleTotal = (connections, user) => {
  let DMG = "const peopleTotal";
  console.log(DMG, "user", user);
  
  if (!user || !connections || !connections.length) return 0;
  //console.log(connections)
  let companies = _
    .chain(connections)
    .pluck("company")
    .value();

  let companiez = companies.filter(company => typeof company !== "undefined");
  //console.log(companiez)
  let companias = companiez;
  //FOLLOWING THREE LINES COMMENTED OUT ON SEPT15_2023 TO SHOW STANDARD ACCOUNTS THEIR AUDIENCE REACH
  // if ((user.company.paid === "false") || (user.company.paid === false)) {
  //   companias = _.filter(companiez, company => company.id === user.company_id);
  // }

  let sum;

  try {
    if (companias !== "") {
      sum = companias.map(company => company.total_employee_count || 0).reduce((a, b) => a + b, 0);
    }
  } catch (err) {

    sum = 0;
  }

  return sum;
};