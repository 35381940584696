import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import titleize from "underscore.string/titleize";
import ReactPlayer from "react-player";
import axios from "axios";
//utils
import { getUser } from "../../../utils/auth";
//actions
import { actions, clearDetail } from "../../../actions/resources";
//components
import { ClickCursorFlex, Flex, ClickCursorFlexHover, Box } from "../../common/flexbox";
import { NunitoSans, NunitoSansExtraBold } from "../../common/typography";
import { AbsoluteDiv } from "../../common/absolute";
import { BackButton } from "../../common/backbutton";
import PostDetail, {
  Attachments
} from "./detail/index";
import {
  OutlineFlex,
  DashboardSmallCTA,
  renderSmallField
} from "../../common/forms";
import { VideoEmbed } from "../../common/ui";
import ConfirmModal from "../../common/confirmModal";
import {defaultProfile, defaultCompany} from "../../../utils/defaultLogos";


export class PostsDetailMain extends PureComponent {
  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { fetchPost, match } = this.props;
    fetchPost(match.params.id);
  };

  render() {
    const { loading, post } = this.props;

    if (loading) return <div />;

    console.log(post, "NEWPOST")

    if (post && post.company) {
      return (
          <PostDetail
            postId={post.id}
            companyId={post.company.id}
            companyLogo={
              post.brand && post.brand.name
              ?
              (post.brand.logo || defaultCompany(post.brand.id, post.brand.name))
              :
              post.company.is_single_user_company 
              ? 
              defaultCompany(post.company.id, post.company.name) 
              : 
              (post.company.logo || defaultCompany(post.company.id, post.company.name))
            }
            companyName={post.company.name}
            brandName={post.brand && post.brand.name}
            title={post.title}
            body={post.content}
            postDate={moment(post.start_date)
              .utc()
              .format("MMMM Do YYYY")}
            postedBy={
              getUser().id === post.user_id
                ? `You`
                : titleize(`${post.user.first_name} ${post.user.last_name}`)
            }
            userId={post.user.id}
            hashTags={_.pluck(post.tags, "name")}
            postedByImage={post.user.avatar || defaultProfile(post.user_id, `${post.user.first_name} ${post.user.last_name}`)}
            fileAttachments={post.files}
            videoAttachments={post.videos}
            allConnections={post.all_connections}
            companyIds={post.company_ids}
            departmentIds={post.department_ids}
            userIds={post.user_ids}
            isSingleUserCompany={post.company.is_single_user_company}
          />      
      );
    }

    return null;
  }
}

export default connect(
  state => ({
    post: state.resources.detail.post,
    loading: state.resources.detail.loading.post
  }),
  dispatch => ({
    clearDetail: bindActionCreators(clearDetail, dispatch),
    fetchPost: bindActionCreators(actions.fetchPost, dispatch)
  })
)(PostsDetailMain);

export class PostDetailAccessory extends PureComponent {
  state = {
    showVideo: null,
    removeModalOpen: false
  };

  _playVideo = async video => {
    this.setState({
      showVideo: video
    });
  };

  _removePost = async () => {
    const { post, redirect, updatePost } = this.props;
    await updatePost(post.id, { status: "delete" });
    redirect(`/dashboard`);
  };

  _openRemoveModal = ()=> this.setState({removeModalOpen: true});
  _closeRemoveModal = ()=> this.setState({removeModalOpen: false});
  _handleRemoveConfirm = ()=>this._removePost();

  _processFileDownload = async file => {
    const { post } = this.props;
    let win = window.open(window.location, "_blank");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/s3/signed-url`,
      {
        params: {
          filename: `posts/${post.company_id}/${file.filename}`,
          filetype: file.filetype
        }
      }
    );

    if (response.data.signed_url) {
      win.location.href = response.data.signed_url;
      win.focus();
    }
  };

  render() {
    const { post, redirect, user, systemSettings } = this.props;
    const { showVideo, removeModalOpen } = this.state;
    

    if (post) {

      let adminDeactivation = systemSettings.post_admin_deactivation && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().system_admin;

      return (
        <Flex
        flexDirection='column'
        // width='100%'
        >
          {
            getUser().id===post.user_id || adminDeactivation
            ?
            <>
            <ConfirmModal
           modalOpen={removeModalOpen}
           message="Are you sure you wish to delete this post?"
           handleCloseRequest={this._closeRemoveModal}
           handleConfirm={this._handleRemoveConfirm}
           />
            <Box height="40px" />
          <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          >
            <NunitoSans
            fontSize="18px"
            fontWeight='bold'
            color="#000000"
            >
              ACTIONS
            </NunitoSans>
          </Flex>
          <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          style={{border: "1px dashed #A3AA9E", borderRadius: "12px", marginTop: "25px"}}
          >
           
           
            <Flex
            width="80%"
            justifyContent="space-evenly"
            style={{marginTop: "22px"}}
            >
            {
              getUser().id===post.user_id && systemSettings.posts_active && (!systemSettings.posting_companies_active || (systemSettings.posting_companies_active && user && user.company.posting_active))
              ?
              <DashboardSmallCTA
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px"]}
                color="#428BF4"
                fontSize="13px"
                onClick={()=>redirect(`/dashboard/posts/duplicate/${post.id}`)}
              >
                DUPLICATE
              </DashboardSmallCTA>
              :
              null
            }
              {
                getUser().id===post.user_id
                ?
                <DashboardSmallCTA
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "0"]}
                width={["140px"]}
                color="#428BF4"
                fontSize="13px"
                onClick={()=>redirect(`/dashboard/posts/edit/${post.id}`)}
              >
                EDIT
              </DashboardSmallCTA>
              :
              null
              }
            </Flex>
            {
              getUser().id===post.user_id || adminDeactivation
              ?
              <DashboardSmallCTA
                mt={["10px", "10px",]}
                mb={["24px", "24px",]}
                mr={[0, "0"]}
                width={["140px"]}
                color="#FE4A49"
                fontSize="13px"
                onClick={()=>this._openRemoveModal()}
                
              >
                DELETE
              </DashboardSmallCTA>
              :
              null
            }

           
            

          </Flex>
            </>
            :
            null
          }
          

          <Flex position="relative" mt={"103px"}>
            
            <Attachments
              fileAttachments={post.files}
              playVideo={this._playVideo}
              processFileDownload={this._processFileDownload}
              videoAttachments={post.videos}
            />
            {showVideo ? (
              <VideoEmbed
                clickHandler={() => {
                  this.setState({ showVideo: false });
                }}
              >
                <ReactPlayer url={showVideo.url} />
              </VideoEmbed>
            ) : null}
          </Flex>
        </Flex>
      );
    }
    return null;
  }
}

PostDetailAccessory = connect(
  state => ({
    browser: state.browser,
    systemSettings: state.resources.detail.systemsetting,
  }),
  dispatch => ({
    
    updatePost: bindActionCreators(actions.patchPost, dispatch)
  })
)(PostDetailAccessory);

let PostDetailNav = props => {
  if (props.post && props.post.start_date && getUser()) {
    // console.log(moment(props.post.end_date).add(1, 'd'), 'EOD', moment())
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {props.post.status === "deleted" && moment(props.post.end_date).add(1, 'd').unix() > moment().unix() ? (
            <NunitoSans
              pt={["3px", "4px", "7px", "5px"]}
              fontSize={[10, 11, 12, 13]}
              fontStyle="italic"
              color="#FE4A49"
            >
              DELETED ON{" "}
              {moment(props.post.updated_at)
                .utc()
                .format("MMMM Do YYYY")}
            </NunitoSans>
          ) : (
            <div>
              {moment(props.post.end_date).unix() > moment().unix() ? (
                <NunitoSans
                  pt={["3px", "4px", "7px", "5px"]}
                  fontSize={[10, 11, 12, 13]}
                  fontStyle="italic"
                  color="#FE4A49"
                >
                  ENDS{" "}
                  {moment(props.post.end_date)
                    .utc()
                    .format("MMMM Do YYYY")}
                </NunitoSans>
              ) : null}
            </div>
          )}
          {
            moment(props.post.end_date).add(1, 'd').unix() <= moment().unix() ? 
            <NunitoSans
              pt={["3px", "4px", "7px", "5px"]}
              fontSize={[10, 11, 12, 13]}
              fontStyle="italic"
              color="#FE4A49"
            >
              EXPIRED ON{" "}
              {moment(props.post.end_date)
                .utc()
                .format("MMMM Do YYYY")}
            </NunitoSans>
            : null
          }
          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "center"
              ]}
            >
              <BackButton
              postId={props.post.id}
              />
                
            </Flex>
          </AbsoluteDiv>
          {!props.browser.lessThan.large && getUser().id === props.post.user_id ? (
            <AbsoluteDiv right={["0px"]} bottom={"0px"} top={"0px"}>
              <Flex
                height={["100%", "100%", "100%"]}
                alignItems={["center"]}
                justifyContent={["flex-end"]}
              >
                {/* <ClickCursorFlexHover 
                  justifyContent="center"
                  alignItems="center"
                  height={["30px", "30px", "40px"]}
                  width={["64px", "64px", "102px"]}
                  onClick={() =>
                    props.redirect(`/dashboard/posts/edit/${props.post.id}`)
                  }
                  borderRadius="9px"
                  bg="whiteGray"
                  color="gray">
                  <NunitoSansExtraBold
                    pt={["2px", "4px"]}
                    fontSize={["17px", "17px", "17px"]}
                    fontWeight="normal"
                  >
                    EDIT
                  </NunitoSansExtraBold>
                </ClickCursorFlexHover> */}

              {/* <DashboardSmallCTA
                width="15%"
                onClick={() =>
                  props.redirect(`/dashboard/posts/edit/${props.post.id}`)}
                  minWidth={["70px","70px","122px","122px"]}
                  fontSize={["12px","12px","17px","17px"]}
                innerHeight="40px"
                // my="12px"
                // height="34px"
              >
                EDIT
              </DashboardSmallCTA> */}


              </Flex>
            </AbsoluteDiv>
          ) : null}
        </Flex>
      </Flex>
    );
  }
  return null;
};


PostDetailNav = connect(
  state => ({
    browser: state.browser,
    
  }),
  null
)(PostDetailNav);

export { PostDetailNav};