import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import {sortBy} from "lodash";
//utils
import { getUser } from "../../../utils/auth";
import { success, error } from "../../../actions/alerts";
//actions
import { actions } from "../../../actions/resources";
import { setGroup, setGroupMembers } from "../../../actions/groups";
//components
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { CreateGroupCTA, EditGroupCTA } from "../../common/ui";

import { companyTotal, peopleTotal, selectRow, clearRows, preSelectRows } from "../../../actions/post";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionGroups,
  searchConnectionUsers,
  searchUsers
} from "../../../actions/search";
//components
import { H2, NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold,H1CondensedSmallD0D0D0 } from "../../common/typography";
import CompanyInfographic from "./audiences/companyInfographic";
import PeopleInfographic from "./audiences/peopleInfographic";

import { MegaFlexBubble, BillingCTA, OneSixDash, FourFourDottedWith8pxCornerRadius,RecommendedContentWrapper } from "../../common/ui";
import { AudienceRow } from "../../common/rows";
import { AudienceMultiRowGroup } from "../../common/rows";
import {AudienceList} from "../../common/reactWindow";
import { InputStyled, DashboardSmallCTA } from "../../common/forms";
import { AbsoluteDiv } from "../../common/absolute";

export class ManageGroupsAccessory extends Component {
state = {
    audienceSearchInput: null,
    searchModeActive: true,
    connectedCompanies: null,
    allAudience:[],
    isMounted:false,
    _searchAudienceFilters : {},
    DMG: "src/components/dashboard/groups/accessory.js"
  }


  async componentDidMount(){
    this._fetchDependencies();
    
    await promiseTimeout(500);
    this.setState({"isMounted": true,"_searchAudienceFilters":this._searchAudienceFilters});
}


// _fetchDependencies = async () => {


  componentWillUnmount(){
    this._removeAudience();
  }



  componentDidUpdate(prevProps, prevState) {
    const { DMG, audienceSearchInput, searchModeActive, connectedCompanies } = this.state;
    
    console.log(DMG,"componentDidUpdate this.props",this.props)
    console.log(DMG,"componentDidUpdate this.state",this.state)
    if(prevProps.theGroup !== this.props.theGroup){
      this._removeAudience();
      this.props.setGroupMembers(null);
      this.setState({connectedCompanies: null});
    }

    if(!prevState.connectedCompanies && connectedCompanies && this.props.theGroup){
      // console.log(connectedCompanies, 'COMPANIES')
      //that's how we preselect members of the group

      
      let groupMembers = connectedCompanies.filter(company=> company.groups && company.groups.map(group=>group.id).includes(this.props.theGroup.id))
      
      this.props.preSelectRows(groupMembers, "group")
      this.props.setGroupMembers(groupMembers); 
    }
  }

  

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user, incentive, fetchIncentive, match } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }

    // let allAudience = this._searchAudienceFilters();
    // this.setState({"allAudience": allAudience});   
    
    this._search('')

   
    fetchConnections({ company_id: getUser().company_id, status: 'active', from:"newPost" });
  };

  _search = async input => {
    const {
      searchCompanies,
      searchDepartments,
      searchGroups,
      searchConnectionUsers,
      user
    } = this.props;
  
    searchCompanies(input, undefined, undefined, undefined, undefined, true );
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows, groupRows, selectedRows, theGroup } = this.props;
    
    let rows = companyRows;
    if(!this.state.audienceSearchInput && companyRows && !this.state.connectedCompanies){
      if(theGroup){
        let groupMembers = companyRows.filter(company=> company.groups && company.groups.map(group=>group.id).includes(this.props.theGroup.id));
        console.log(theGroup, groupMembers, 'THEGORUP')
        if(parseInt(theGroup.total_company_count)===groupMembers.length){
          this.setState({connectedCompanies: companyRows})        
        }else{
          this._search('')
        }
      }
      
    }
    let rowsSelected = [];
    let rowsNotSelected = [];

   sortBy(rows, [function(item){return item.row.name}]).forEach(row=>{
      if(selectedRows.map(slrow=>slrow.id).includes(row.id)){
        rowsSelected.push(row);
      }else{
        rowsNotSelected.push(row)
      }
    })

    return [...rowsNotSelected, ...rowsSelected];
    // return rows.sort(row=>{
    //   if(selectedRows.map(slrow=>slrow.id).includes(row.id)) return 1;
    //   return -1;
    // });
  };
//selectedAudienceMultiIds

	
_removeAudience = () => {
    
  const {
    selectedRows,
    clearRows
  } = this.props;
  
  //console.log(selectedRows);
  clearRows();
  this._updateSearch("");
}

_updateSearch = input => {

   
  //console.log(AudienceMultiRowGroup);


    this.setState({ audienceSearchInput: input });
    //HELLO
    if (input.length < 2){
      if(input.length != 0){
        return;
      }    
    }
    this._search(input);
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectedGroups,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;

    console.log(itemId, itemType, companyId, 'selectROW')
    
    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers,
      group: selectedGroups
    };
   
    
    selectRow(
      itemId,
      // rowTypes[itemType],
      selectedRows,
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };



  _createGroup = async data => {
    const { createGroup, fetchGroups, resetForm, notifyError, notifySuccess } = this.props;
    fetchGroups({
      company_id: getUser().company_id,
      status: "active"
    }).then(groups => {
      let groupNames = groups.payload.map(item => item.name);
      if(groupNames.includes(data.name)){

        notifyError("A group with this name already exists.");

      }else if(!data.name){
        notifyError("Group name can not be blank.");
      }else{

      createGroup({
          ...data,
          ...{ company_id: getUser().company_id, user_id: getUser().id }
        }).then((result)=>{
          notifySuccess("Group has been created.");

          fetchGroups({
            company_id: getUser().company_id,
            status: "active"
          });
        });
      }
    });
    
    resetForm("create-group");
  };

  _updateGroup = async data => {
    const {
      groups,
      groupId,
      fetchGroups,
      resetForm,
      setGroupId,
      updateGroup
    } = this.props;

    await updateGroup(_.findWhere(groups, { id: groupId }).id, {
      name: data.name
    });
    setGroupId(null);
    resetForm("edit-group");
    fetchGroups({
      company_id: getUser().company_id,
      status: "active"
    });
  };



  _addAllIndividuals = async (individuals)=>{

    for(let i=0; i<individuals.length; i++){

      const individual = individuals[i];

      if(i>0){
        await promiseTimeout(10);
      }

      this._selectRow(individual.id, individual.type, individual.company_id)

    }

  }
// below is accessory side when editing a department in the DEPARTMENTS page //
  render() {
    const { groups, theGroup, setGroup, selectedRows } = this.props;
    const { audienceSearchInput, searchModeActive,  DMG, isMounted, _searchAudienceFilters } = this.state;
    let addAllRows = [];

    
    let allAudience = [];
    if(isMounted===true){
      try {
        allAudience = _searchAudienceFilters();
      } catch(e){ }
    }
    console.log(DMG,"allAudience",allAudience);

    const selectedRowIds = selectedRows.map(slrow=>slrow.id);
    const unselectedRows = allAudience.filter(row=>!selectedRowIds.includes(row.id));

    if(
      unselectedRows.every(audience=>audience.type==="company" && audience.row.is_single_user_company && audience.row.domain===unselectedRows[0].row.domain)
      && unselectedRows.length > 1
    ){
      addAllRows =  unselectedRows;
    }else{
     addAllRows = [];
    }

    if (!theGroup) return null;
    return (
      <Flex justifyContent="center" flexDirection="column" width="100%">
   

        <Box mt="35px" width="100%">
          <NunitoSans textAlign="center" fontWeight={700} fontSize="18px">
            CONNECTIONS
          </NunitoSans>
        </Box>
        <Flex mt="26px" mb="40px" width="100%" flexDirection="column">
          <MegaFlexBubble
            bg="white"
            borderRadius="10px"
            pl={["5px", "12px", "15px"]}
            pt={["5px", "12px", "15px"]}
            pr={["5px", "12px", "15px"]}
            pb={"0px"}
            flexDirection="column"
          >
              <Box position="relative" mt="23px">
                
                {(selectedRows.length) ? (
                  <Flex
                  maxHeight="30vh"
                  flexDirection="column"
                  // justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  style={{overflowY:"auto",overflowX:"hidden"}}
                >             
                    <AudienceMultiRowGroup
                      audiencesMulti={selectedRows}
                      selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                      mobile={true}
                      hideHeader={true}
                      hideFirstLastDash={true}
                      onSelect={this._selectRow}
                    />
                  </Flex>
                ) : 
                null
                }
              </Box> 
            
          </MegaFlexBubble>
        </Flex>        
        <div>              
          <Flex
            mt="1px"
            mb="25px"
            mx={["0px"]}
            height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <InputStyled
                placeholder="Search Connections"
                onChange={e => this._updateSearch(e.target.value)}
                innerRef={input => (this.searchInput = input)}
                value={audienceSearchInput || ""}
                width="100%"
                name="post-new-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                pt={["3px"]}
                px={["16px"]}
                mt={["0px"]}
                height={["46px"]}
                fontWeight={300}
                fontSize={[18]}
                lineHeight={1.3}
                color={"#979797"}
                borderBottom={[0]}
              />
              <AbsoluteDiv
                display={["block"]}
                right={["20px"]}
                bottom={"0px"}
                top={["0px"]}
                width={["17px"]}
              >
                <ClickCursorFlex
                  height={["100%", "100%", "100%"]}
                  alignItems={["center"]}
                  onClick={() => this._updateSearch("")}
                  justifyContent={["center"]}
                >
                  <IconBox
                    cursor="pointer"
                    pl={["15px"]}
                    pb={["15px"]}
                    pt={["15px"]}
                    fontSize={["17px"]}
                    color="#c3c3c3"
                  >
                    thinx
                  </IconBox>
                </ClickCursorFlex>
              </AbsoluteDiv>
            </Flex>
          </Flex>

          {
            (addAllRows && addAllRows.length!==0)
                ?
                <DashboardSmallCTA
                onClick={()=>this._addAllIndividuals(addAllRows)}
                >
                  {`ADD ALL ${addAllRows.length} COMPANIES`}
                </DashboardSmallCTA>
                :
                null
              }
        
          
          <Box 
          mb="5px"
          // style={{height:"45vh", overflow:"auto"}}
          >
            {/* <AudienceMultiRowGroup
              audiencesMulti={this._searchAudienceFilters()}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              // onlyPeople={true}
            /> */}

             <AudienceList
              audiencesMulti={allAudience}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              />
          </Box>
        
        </div>
        
      </Flex>
    );
    /*
    if (groupId) {
      return (
        <Flex flexDirection="column">
          <Box height="76px" />
          <EditGroupCTA
            initialValues={{
              name: _.findWhere(groups, { id: groupId }).name
            }}
            setGroupId={() => setGroupId(null)}
            onSubmit={this._updateGroup}
          />
        </Flex>
      );
    }
// below is the accessory side of the DEPARTMENTS page //
    return (
      <Flex flexDirection="column">
        <Box height="76px" />
        <CreateGroupCTA onSubmit={this._createGroup} />
      </Flex>
    );
    */
  }
}

export default connect(
  state => ({
    groups: state.resources.collection.group,
    theGroup: state.groups.group,
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length &&
      !state.post.selectedGroups.length,
    audienceSearchResults: state.search.audienceSearchResults,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    connections: state.resources.collection.connection || [],
    departmentRows: state.search.departmentSearchRows,
    groupRows: state.search.groupSearchRows,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    user: state.resources.detail.user,
    userRows: state.search.userSearchRows,
  }),
  dispatch => ({
    setGroup: bindActionCreators(setGroup, dispatch),
    setGroupMembers: bindActionCreators(setGroupMembers, dispatch),
    createGroup: bindActionCreators(actions.createGroup, dispatch),
    fetchGroups: bindActionCreators(actions.fetchGroups, dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    updateGroup: bindActionCreators(actions.patchGroup, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    // setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    // setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchGroups: bindActionCreators(
      searchConnectionGroups,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    preSelectRows: bindActionCreators(preSelectRows, dispatch),
    clearRows: bindActionCreators(clearRows, dispatch),
    redirect: bindActionCreators(push, dispatch),
  })
)(ManageGroupsAccessory);



function promiseTimeout (time) {
  return new Promise(function(resolve,reject){
    setTimeout(function(){resolve(time);},time);
  });
};