import React, {Component} from 'react';
import {Box, Flex} from '../common/flexbox';
import {AbsoluteDiv} from '../common/absolute';
import {NunitoSans} from '../common/typography';

import {mapboxgl_accessToken} from "../../utils/constants";

import ReactTimeout from 'react-timeout';

import MapboxClient from 'mapbox';
import {debounce} from 'lodash';
import {SmallEyebrow, InputStyled} from '../common/forms';

import {
  LocationPinImage,
  OneSixDash,
  AccessoryIndicator,
  GradientUnderlayRightToLeft,
} from '../common/ui';

class MapboxAutoComplete extends Component {
  state = {
    focus: false, 
    address: '', 
    rows: []
  }
  constructor(props) {
    super(props);
    this.inputChange = debounce(this.inputChange.bind(this), 600);
    this.focusIn = this.focusIn.bind(this);
    this.focusOut = this.focusOut.bind(this);
    this.setFocusIn = this.setFocusIn.bind(this);
    this.setFocusOut = this.setFocusOut.bind(this);
    this.rowClick = this.rowClick.bind(this);
  }
  componentDidMount() {
    this.client = new MapboxClient(mapboxgl_accessToken);
    this.setState({address: this.props.placeName});
    //this.refs.input.value = this.props.address
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.placeName !== this.props.placeName) {
      
      // this.searchInput.value = this.props.placeName;
    }
  }
  inputChange() {
    let inputValue = document.getElementById("mapinput").value;
    if(this.searchInput && this.searchInput.value){
      inputValue = this.searchInput.value;
    };
    let that = this;
    this.client
      .geocodeForward(inputValue)
      .then(function(res) {
        // res is the http response, including: status, headers and entity properties
        let data = res.entity; // data is the geocoding result as parsed JSON
        let features = data.features || [];
        that.setState({rows: features});
      })
      .catch(function(err) {
        console.log('geocode error ' + err);
        // handle errors
      });
  }
  setFocusIn() {
    this.setState({focus: true});
  }
  setFocusOut() {
    this.setState({focus: false});
  }
  focusIn() {
    this.setFocusIn();
  }
  focusOut() {
    this.props.setTimeout(this.setFocusOut, 200);
  }
  rowClick(row) {
    document.getElementById("mapinput").value = row.place_name;
    this.props.onSelect(row);
  }
  render() {
    const {placeName} = this.props;
    const {rows} = this.state;
    // filtering returned data to only display place, region, country rather than exact addresses
    // other countries may have different definitions about what constitutes a place/region so some countries may display something more specific than a city
    let rowsFiltered = rows.filter((row) => (row.place_type[0] == 'place' || row.place_type[0] == 'region' || row.place_type[0] == 'country'))
    return (
      <Flex
        flexDirection="column"
        className="renderField-input-wrapper"
        position="relative">
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
        <SmallEyebrow>LOCATION</SmallEyebrow>
        </div>
        <InputStyled
          id="mapinput"
          border={0}
          fontWeight={'light'}
          fontSize={[24, 24, 24, 24]}
          height={[30]}
          mt={'8px'}
          lineHeight={[1.3]}
          color={'darkText'}
          defaultValue={placeName}
          borderBottom={[1]}
          onChange={this.inputChange}
          onFocus={this.focusIn}
          onBlur={this.focusOut}
          label="location"
          type="text"
          width="100%"
          placeholder="Scottsdale, Arizona, United States"
          innerRef={div => (this.searchInput = div)}
        />
        <AbsoluteDiv
          zIndex={500}
          top="100%"
          overflow="hidden"
          borderRadius="10px"
          left={['-10px', '-17px']}
          right={['-10px', '-17px']}
          display={this.state.focus ? 'block' : 'none'}>
          <Flex flexDirection="column" width="100%">
            {rowsFiltered.map((row, i) => {
              return (
                <Flex
                  onClick={() => this.rowClick(row)}
                  className="search-row-flex"
                  position="relative"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  height="65px"
                  bg="lightGray"
                  width="100%"
                  key={i}>
                  <LocationPinImage ml="18px" height="38px" width="28px" />
                  <Flex
                    position="absolute"
                    left="15%"
                    right="-40%"
                    zIndex="3"
                    ml="5%"
                    flex="2 1 auto"
                    flexDirection="column"
                    justifyContent="flex-start">
                    <NunitoSans fontSize="14px" fontWeight={300}>
                      {row.address} {row.text}
                    </NunitoSans>
                    <NunitoSans fontSize="8px" fontWeight={500}>
                      {row.place_name.substring(
                        row.place_name.indexOf(',') + 2,
                      )}
                    </NunitoSans>
                  </Flex>
                  <Box position="relative" zIndex="5" mr="21px">
                    <AccessoryIndicator
                      onClick={() => alert('derp')}
                      size={30}
                      color="#efefef"
                      iconColor="#d8d8d8"
                      iconSize="12px"
                      iconMarginTop="2px"
                      iconName="checkmark"
                    />
                  </Box>
                  <AbsoluteDiv zIndex="4" top="1px" right="0px" bottom="1px">
                    <GradientUnderlayRightToLeft
                      color="#f7f7f7"
                      boxWidth="49px"
                      gradientWidth="43px"
                    />
                  </AbsoluteDiv>
                  <AbsoluteDiv
                    display={i >= rows.length - 1 ? 'none' : 'block'}
                    height="1px"
                    bottom="0px"
                    left="18px"
                    right="18px">
                    <OneSixDash width="100%" height="1px" />
                  </AbsoluteDiv>
                </Flex>
              );
            })}
          </Flex>
        </AbsoluteDiv>
      </Flex>
    );
  }
}

export default ReactTimeout(MapboxAutoComplete);
