const defaultState = {
  linkedin: {}
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "AUTH_LINKEDIN_RESPONSE":
      return (newState.linkedin = action.payload);
    default:
      return state;
  }
};
