import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MapComponent } from "../../../common/mapComponent";
import { Box, Flex } from "../../../common/flexbox";
// import { DashboardSmallCTA } from "../../../common/forms";
// import { LinkedInSignInBubble } from "components/dashboard/profile/linkedin";
// import stripeButton from "images/stripe_2x.png";
//utils
import { getUser } from "../../../../utils/auth";
//actions
import { actions } from "../../../../actions/resources";
import axios from 'axios';

export class EditProfileAccessory extends Component {
  _isMounted = false;

  state = {
    placeName: "San Francisco, California, 94117, US",
    // address: "San Francisco",
    // text: "",
    // code: '',
    // errorMessage: '',
    liProfile: {}
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    
      if (this._isMounted) {
        this.setState({
          placeName: "San Francisco, California, 94117, US",
          address: "San Francisco",
          text: ""
        });
      }
  
  }

  _handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: '',
    });
    axios.get(`${process.env.REACT_APP_API_URL}/liprofile?code=${data.code}&origin=${window.location.origin}`)
    .then(res => {
      //console.log(res.data)
      this.setState({
        liProfile: res.data,
      });
      this._linkedinSync(res.data)
    }).catch(err => console.log('ERROR',err));
  }

  _handleFailure = (error) => {
    this.setState({
      code: '',
      errorMessage: error.errorMessage,
    });    
  }

  _addressChange = geolocation => {
    const { updateUser } = this.props;
    updateUser(getUser().id, { location: geolocation });
  };

  _linkedinSync = async data => {
    const {
      createEmailAddress,
      createWebsite,
      fetchUser,
      updateUser
    } = this.props;
        
    const userId = getUser().id;
    
    let position = data.headline;
    if (data.positions && data.positions.values) {
      if (data.positions.values[0].title) {
        position = data.positions.values[0].title;
      }
    }

    let userPayload = {
      avatar: data.pictureUrl,
      first_name: data.firstName,
      last_name: data.lastName,
      linkedin: JSON.stringify({
        id: data.id,
        url: data.publicProfileUrl
      }),
      position: position
    };

    let emailPayload = {
      body: data.emailAddress,
      label: "LinkedIn",
      user_id: userId
    };

    let websitePayload = {
      body: data.publicProfileUrl,
      label: "LinkedIn",
      user_id: userId
    };

    await updateUser(userId, userPayload);
    await createEmailAddress(emailPayload);
    await createWebsite(websitePayload);
    fetchUser(userId, { edit: true });
    if (this._isMounted) {
      window.location.reload();
    }
  };

// below accessory in EDIT mode of MY PROFILE page //
  render() {
    const { user, systemSettings, companySettings } = this.props;
    const { placeName, address, text } = this.state;
    if (user) {
      return (
        <Flex flexDirection="column">
          
          <Box mt="91px" />
          <MapComponent
            placeName={(user.location && user.location.place_name) || placeName}
            address={
              user.location
                ? `${user.location.address ? user.location.address : ""}`
                : address
            }
            text={(user.location && user.location.text) || text}
            addressChange={this._addressChange}
          />
          
          <Box mt={66 * 5 + 600 + "px"} />
        </Flex>
      );
    }
    return null;
  }
}

export default connect(
  state => ({
    liProfile: state.liProfile,
    user: state.resources.detail.user,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    createEmailAddress: bindActionCreators(
      actions.createEmail_address,
      dispatch
    ),
    createWebsite: bindActionCreators(actions.createWebsite, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch)
  })
)(EditProfileAccessory);

function numberParser(number){
  return new Intl.NumberFormat('en-US').format(number)
}