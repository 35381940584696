import React from "react";
import { NunitoSans } from "./common/typography";
import { Flex } from "./common/flexbox";

import cookiesRocket from "../images/cookies_page.png";


export class UnderMaintenance extends React.Component {

  
  render(){
    

  return (
   <Flex 
   flexDirection="column" 
  //  maxWidth="248px"
   alignItems="center"
   justifyContent="flex-end"
   style={{marginTop: "366px"}}
   >

     
<Flex
flexDirection="column"
maxWidth="248px"
alignItems="center"
>

     <img src={cookiesRocket} alt="" width="249px" height="236px"></img>
   
     <NunitoSans 
     fontSize="35px"
     fontWeight={700}
     textAlign='center'
     >
       We Run on Cookies
     </NunitoSans>
   
     <NunitoSans 
     fontSize="14px"
     textAlign='center'
     >
      {`Looks like you have cookies disabled! Please enable cookies for ${window.location.origin} to ensure system works properly.`}
     </NunitoSans>
     
</Flex>
     
   
   </Flex>
   );
}
} 

export default UnderMaintenance;