import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import axios from "axios";
// import { H2 } from "../../../common/typography";
import { NunitoSans, NunitoSansBold, NunitoSansExtraBold } from "../../../common/typography";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../../common/flexbox";
import { ImageTreatment } from "../../../common/ui";
// import { CompressedBody } from "../../../common/post";
// import DanteComponent from "../../../common/dante";
import DraftComponent from "../../../common/reactDraft";
// import { OneSixDash } from "../../../common/ui";
import { DashboardSmallCTA, InputStyled, DashboardSmallToggleCTA } from "../../../common/forms";
import { AbsoluteDiv } from "../../../common/absolute";
import { getUser } from "../../../../utils/auth";
import { addressBottomPart } from "../../../../utils/helpers";

// import { RegularDepartmentRowGroup } from "../../../common/rows";

import {
  PostRowGroup,
  AssetRowGroup,
  PeopleRowGroup,
  IncentiveRowGroup,
  PhoneRowSmall,
  EmailRowSmall,
  WebsiteRowSmall,
  MapRowSmall
} from "../../../common/rows";
// import { filterActiveDepartments, filterActiveEmployees } from "../../../../utils/helpers";
//images
import { defaultCompany } from "../../../../utils/defaultLogos";

import { colors } from "../../../../utils/theme";

let timeout = null;

class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnection: false,
      userCompany: {},
      searchContent: '',
      searchValue: {},
      selectedCategory: 'People',
      viewMoreActive: false,
      descriptionHeight: 0,
      user:{}
    }
  }



  componentDidUpdate() {

    if (document.getElementById('company-description') && document.getElementById('company-description').clientHeight > 0 && this.state.descriptionHeight === 0) {
      this.setState({ descriptionHeight: document.getElementById('company-description').clientHeight })
    }
  }

  componentDidMount() {   
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
 
    let {  match } = this.props;
    let company_id = getUser().company_id;
    let user = getUser();

    let company = axios.get(`${process.env.REACT_APP_API_URL}/companies/${company_id}`).then(respond => {
      // console.log("more soup company",company)
      this.setState({ userCompany: respond.data });
    });
    // console.log("more soupie company",company)
    // this.setState({ userCompany: company.data });
    this.setState({ user: user });

    fetch(`${process.env.REACT_APP_API_URL}/connections?company_id=${company_id}&status=active`)
    .then((res) => res.json())
    .then((connections) => {
      
      // console.log("_fetchDependencies connections", connections);
      // console.log("_fetchDependencies match", match);
      // console.log("_fetchDependencies this.state", this.state);

      if(connections === undefined){
        connections = [];
      } else if(connections.length===0){
        connections = this.props.connections;
      }  
      
      // console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table
      
      let Vendor_ids = [];
      if (connections) {
        connections.forEach(reorder);
        function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
          if (v.company_id === company_id) {//this will extract the company_ids that a company is connected with
            Vendor_ids.push(v.vendor_id);
          } else {
            Vendor_ids.push(v.company_id);
          }
        }

        let winner = window.location.href;
        let page_company_id = winner.substring(winner.search("/company/")+9,winner.search("companies")-winner.search("/company/"));
        page_company_id = winner.substring(winner.search("/company/")+9,winner.search("/company/")+9+36);



        // console.log("_fetchDependencies Vendor_ids", Vendor_ids);
        let isConnection = Vendor_ids.includes(page_company_id);
        this.setState({ isConnection });
        // console.log("_fetchDependencies isConnection", isConnection);

      }
    })
    .catch((e) => {
      console.log("_fetchDependencies e", e);
    });
}




  companyDescriptionExists() {
    const company = this.props.company;
    if (!company) {
      return false;
    }
    if (!company.description) {
      return false;
    }
    if (company.description.blocks.length === 0) {
      return false;
    }
    if (
      company.description.blocks.length === 1 &&
      company.description.blocks[0].text === ""
    ) {
      return false;
    }
    return true;
  }

  _updateSearch = input => {
    console.log(input,"input333")
    const { selectedCategory } = this.state;
    this.setState({ searchContent: input});

    this.setState({ searchValue: {"content":input,"category":selectedCategory} });
    clearTimeout(timeout);

    timeout = setTimeout(() => this._search( {"content":input,"category":selectedCategory} ), 1000);
  };

  _search = input => {
    console.log("00000 BBBBBB",input);
    const { handleSearch } = this.props;
    const { selectedCategory } = this.state;

    console.log(input,"selectedCategory");
    console.log(selectedCategory,"selectedCategory");
    if (input.content.length === 0 || input.content.length > 2) {
      handleSearch(input.content,selectedCategory);
    }
  }



  _toggleCategory = category => {
    
    console.log("00000 AAAAAAAAAAAAAA");
    const { searchValue,searchContent } = this.state;
    this.setState({ selectedCategory: category });
    const { handleSearch } = this.props;

    console.log(category,"category");
    console.log(searchValue,"searchValue");

    // this._search({"content":searchContent,"category":category});
    
    handleSearch({"content":searchContent,"category":category});
  }

  render() {
    const { userCompany, user, searchValue, searchContent, isConnection, selectedCategory, viewMoreActive, descriptionHeight } = this.state;
    // console.log(userCompany, searchValue, selectedCategory, viewMoreActive, descriptionHeight)

    //      departments,
    const {
      browser,
      company,
      phoneNumbers,
      emails,
      redirect,
      websites,
      posts,
      incentives,
      assets,
      users,
      systemSettings,
      companySettings,
      
      isRequestPending,
      createConnection
    } = this.props;
    

// if(company && user && user.company && (company.id === user.company.id)){
//   // isConnection = true;
//   // this.setState({ isConnection });
// }






    return (
      <div style={{ height: "89vh", overflowY: "auto", overflowX: "hidden", paddingRight: "25px", marginTop: "25px" }}>

        <Flex alignItems="center">
          {browser.lessThan.small ? (
            <ImageTreatment img={company.logo || defaultCompany(company.id, company.name)} blurRadius={"12px"}
              imageInset={0} borderRadius={0} innerBorderRadius={0} shadowOffset={0.1375} padding={8} height={80}
              width={80} blurOpacity={0.4} key={60} />
          ) : (
            <ImageTreatment img={company.logo || defaultCompany(company.id, company.name)} blurRadius={"15px"}
              imageInset={0} borderRadius={0} innerBorderRadius={0} shadowOffset={0.1175} padding={10} height={120}
              width={120} blurOpacity={0.4} key={60} />
          )}

          <Flex
            flexDirection="column"
            // alignItems="center" 
            ml="40px"
            key={1}
          >
            <Flex>
              <NunitoSansBold fontSize="30px" color="#000000" key={2}>
                {company.name}
              </NunitoSansBold>
              {
                company && company.paid && company.paid === false
                  ?
                  <IconBox style={{ marginLeft: "5px" }} color={colors.kickpostPlus}>
                    kickpost_plus
                  </IconBox>
                  :
                  null
              }
            </Flex>

            {
              company && company.location
                ?
                <NunitoSans fontSize="16px" color="#000000" key={31}>
                  {addressBottomPart(company.location.place_name)}
                </NunitoSans>
                :
                null
            }



            {
              company && company.users_total
                ?
                <Flex
                // mb='5px'
                >
                  <NunitoSans fontSize="14px" color="#000000" key={32}>
                    {`${company.users_total} ${company.users_total > 1 ? "People" : "Person"}`}
                  </NunitoSans>
                </Flex>
                :
                null
            }

            {
              company.settings &&
                !company.settings.is_single_user_company &&
                company.settings.industry_categories &&
                company.settings.industry_categories.length
                ?
                <Flex>
                  {
                    company.settings.industry_categories.map((category, index) => (
                      <p style={{
                        padding: "0px 5px",
                        backgroundColor: "#428BF4",
                        marginLeft: index !== 0 ? "10px" : '0px',
                        fontSize: '12px',
                        color: "#FFFFFF",
                        fontWeight: 600,
                        textAlign: 'center'
                      }}
                        key={category.id}>
                        {category.name}
                      </p>
                    ))

                  }
                </Flex>
                :
                null
            }


            <Flex>

              {
                (false && !isConnection) 
                  ?
                  <DashboardSmallCTA display={"none"} color={colors.kickpostGreen} reversed={true} mr="15px" minWidth={["160px"]}
                    disabled={isRequestPending} onClick={() => isRequestPending ? {} : createConnection()}
                  >
                     {isRequestPending ? "PENDING" : "CONNECT"} 
                  </DashboardSmallCTA>
                  :
                  null
              }

              <DashboardSmallCTA color={colors.kickpostGreen} minWidth={["160px"]} style={{ display: 'none' }}
                onClick={() => window.open(`https://kickpost.io`)}
              // onClick={()=>window.open(`http://${company.domain}`)}
              >

                VISIT WEBSITE
              </DashboardSmallCTA>

            </Flex>

          </Flex>

        </Flex>

        <Flex flexDirection="column" pb={40} alignItems="center" justifyContent="center">
          {/* <Box pb={["25px", "35px" ]} ml="20px">
                {browser.lessThan.small ? (
                <ImageTreatment img={company.logo || defaultCompany(company.id, company.name)} blurRadius={"12px"}
                    imageInset={0} borderRadius={8} innerBorderRadius={0} shadowOffset={0.1375} padding={8} height={80}
                    width={80} blurOpacity={0.4} />
                ) : (
                <ImageTreatment img={company.logo || defaultCompany(company.id, company.name)} blurRadius={"15px"}
                    imageInset={0} borderRadius={10} innerBorderRadius={0} shadowOffset={0.1175} padding={10}
                    height={120} width={120} blurOpacity={0.4} />
                )}
            </Box> */}
          {/* <Flex>
                <H2 style={{marginLeft: "20px", textAlign: 'center'}}>{company.name}</H2>
                {
                company.paid
                ?
                <IconBox style={{marginLeft: "5px"}} color={colors.kickpostPlus}>
                    kickpost_plus
                </IconBox>
                :
                null
                }
            </Flex> */}
          {/* <NunitoSans pt={[0, "3px" ]} fontSize={["12px", "18px" ]} fontWeight={500} color="darkText"
                style={{"margin-left": "20px"}}>
                <a href={`http://${company.domain}`} target='_blank'>{company.domain}</a>

            </NunitoSans> */}
          {this.companyDescriptionExists() ? (
            <Flex flexDirection="column" width="100%" mb='20px'>
              <Box height={["60px", "40px"]} />
              <NunitoSansBold color="#000000" fontSize="16px"
                style={{ borderBottom: "1px solid #000000", marginBottom: '10px' }} key={40}>
                ABOUT
              </NunitoSansBold>
              <Flex id="company-description" style={descriptionHeight > 75 && !viewMoreActive ? {
                height: '75px',
                overflow: 'hidden'
              } : {}}>
                {/* <CompressedBody width="100%" // pb={["30px"]} mb={['-60px']} // lineHeight={"30px"}
                        fontSize={[12, 14]}> */}

                <DraftComponent readOnly={true} initialContent={company.description}
                  extraStyles={{ overflow: 'hidden' }} />
                {/* </CompressedBody> */}
              </Flex>
              {/* <DraftComponent readOnly={true} initialContent={company.description} extraStyles={
                    (descriptionHeight> 75) && !viewMoreActive ? {height: '75px', overflow: 'hidden'} : {}}/> */}
              {
                descriptionHeight > 91
                  ?
                  <NunitoSansBold color={colors.kickpostGreen} textAlign="right" mt='10px'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.setState({
                      viewMoreActive: !viewMoreActive
                    })}
                    key={41}
                  >
                    {viewMoreActive ? "View Less" : "View More"}
                  </NunitoSansBold>
                  :
                  null
              }
            </Flex>
          ) : null}
          {/*
            <Box mt={["35px"]} /> */}
          {browser.lessThan.small || browser.lessThan.medium ? (
            <Box width="100%;">
              {websites && isConnection ? websites.map((website, i) => {
                return
                <WebsiteRowSmall website={website} />;
              }) : null

              }
            </Box>
          ) : null}
          {browser.lessThan.small || browser.lessThan.medium ? (
            <Box width="100%;">
              {
                phoneNumbers && isConnection ? phoneNumbers.map((phoneNumber, i) => {
                  return
                  <PhoneRowSmall phoneNumber={phoneNumber} />;
                }) : null




              }
            </Box>
          ) : null}
          {browser.lessThan.small || browser.lessThan.medium ? (
            <Box width="100%;">
              {
                emails && isConnection ? emails.map((email, i) => {
                  return
                  <EmailRowSmall email={email} />;
                }) : null





              }
            </Box>
          ) : null}
          {browser.lessThan.small && company.location ? (
            <Box width="100%;">
              {company.location && isConnection ? (
                <MapRowSmall address={(company.location && company.location.address) || ""} placeName={
                  (company.location && company.location.place_name) || null} text={(company.location &&
                    company.location.text) || ""} />
              ) : null}
            </Box>
          ) : null}
          {
            !(isConnection)
              ?
              <Flex flexDirection="column" alignItems="center" width="100%" mt="60px"
                style={{ backgroundColor: "#F2F2F2", height: "60vh", borderRadius: '17px', borderShadow: '0px 0px 6px #00000029' }}>
                <Flex flexDirection="column" alignItems="center" justifyContent="center" mt='100px'
                  style={{ width: '94px', height: '94px', border: '1px solid #A3A99E', borderRadius: '50%', backgroundColor: 'white' }}>
                  <IconBox color="#D3D3D3" fontSize="94px">
                    lock_icon
                  </IconBox>

                </Flex>

                <NunitoSansExtraBold color="#A3A99E" fontSize="15px" style={{ marginTop: '20px' }} key={50}>
                  CONNECT TO SEE DETAILS
                </NunitoSansExtraBold>

              </Flex>
              :
              null
          }

          {
            (isConnection)
              ?
              <Flex mt="55px" position="relative" width={["100%"]}>
                <AbsoluteDiv
                  display={["block"]} // left={["10px"]} // bottom={"0px"} top={["4px", "6px" ]}
                  top={["6px", "6px"]}
                  width={["40px"]}>
                  <IconBox
                    fontSize={["18px", "18px" ]}
                    color="#c3c3c3">
                    search
                  </IconBox>

                </AbsoluteDiv>
                <InputStyled 
                  placeholder={`Search ${selectedCategory} from ${company.name}`} onChange={e =>
                  this._updateSearch(e.target.value)}
                  value={searchContent}
                  innerRef={input => (this.searchInput = input)}
                  style={{ paddingLeft: '45px' }}
                  width="100%"
                  name="dashboard-search"
                  type="text"
                  border={0}
                  autoFocus={true}
                  bg={["lightGray"]}
                  borderRadius={"11px"}
                  // pt={["3px"]}
                  px={["5px"]}
                  mt={["0px"]}
                  mb={["5px"]}
                  height={[31, 40, 40, 40]}
                  // fontWeight={400}
                  fontSize={[14, 20, 20, 20]}
                  lineHeight={1.5}
                  color={"#000000"}
                  borderBottom={[0]}
                />
                {
                  searchContent.length
                    ?
                    <AbsoluteDiv display={["block"]} right={["10px"]} top={["4px", "6px"]} width={["40px"]}>
                      <ClickCursorFlex height={["100%", "100%", "100%"]} alignItems={["center"]} onClick={() =>
                        this._updateSearch('')}
                        justifyContent={[
                          "flex-start",
                          "flex-start",
                          "flex-start",
                          "center"
                        ]}
                      >
                        <IconBox cursor="pointer" // pl={["15px"]} // pb={["15px"]} // pt={["15px"]}
                          fontSize={["17px"]} color="#c3c3c3">
                          thinx
                        </IconBox>
                      </ClickCursorFlex>
                    </AbsoluteDiv>
                    :
                    null
                }



              </Flex>
              :
              null
          }

          {
            (isConnection)
              ?
              <Flex width="100%" mt="25px">
                <Flex>
                  <DashboardSmallToggleCTA 
                    selected={selectedCategory==='Posts' }
                    onClick={() => this._toggleCategory('Posts')}
                    fontSize={["12px", "12px", "17px", "17px"]}
                  >
                    POSTS
                  </DashboardSmallToggleCTA>
                  <DashboardSmallToggleCTA 
                    style={{ marginLeft: '15px' }} 
                    selected={selectedCategory === 'People'}
                    onClick={() => this._toggleCategory('People')}
                    fontSize={["12px", "12px", "17px", "17px"]}
                  >
                    PEOPLE
                  </DashboardSmallToggleCTA>

                  <DashboardSmallToggleCTA 
                    style={{ marginLeft: '15px' }} 
                    selected={selectedCategory === 'Assets'}
                    onClick={() => this._toggleCategory('Assets')}
                    fontSize={["12px", "12px", "17px", "17px"]}
                  >
                    ASSETS
                  </DashboardSmallToggleCTA>

{/* ((companySettings && (companySettings.receive_incentives ||
                      companySettings.receive_vendor_incentives)) && (company.settings &&
                        (company.settings.create_incentives || company.settings.create_vendor_incentives)) && incentives.length) */}

                  {
                    ((getUser().sales_id || getUser().company_admin)&&(companySettings && (companySettings.create_vendor_incentives ||companySettings.receive_incentives ||
                      companySettings.receive_vendor_incentives) && user.company_admin) 
                      && (company.settings &&
                        (company.settings.create_incentives || company.settings.create_vendor_incentives)) 
                      && (incentives.length>0))
                      ?
                      <DashboardSmallToggleCTA 
                      id={`DSTCA2`}
                      key={`DSTCA2`}
                        style={{ marginLeft: '15px' }} 
                        selected={selectedCategory === 'Incentives'}
                        onClick={() => this._toggleCategory('Incentives')}
                        fontSize={["12px", "12px", "17px", "17px"]}
                      >
                        INCENTIVES

                      </DashboardSmallToggleCTA>
                      :
                      null
                  }


                </Flex>

              </Flex>
              :
              null
          }



          {((userCompany && userCompany.paid && userCompany.paid === true && company.paid === true) || company.id ===
            process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || company.id === getUser().company_id || company.paid ===
            true) && selectedCategory === 'Posts' && isConnection
            ?
            <>
              <Box height={["60px", "40px"]} />

              <PostRowGroup posts={posts} hideHeader={true} hideFirstLastDash={true} redirect={redirect} />

              {!posts.length ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5> :
                null}
            </>
            :
            null

          }
          { ((isConnection && (systemSettings.rewards_active &&  incentives && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)) || (userCompany && userCompany.company_id ===
                process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) || company.id === getUser().company_id) && incentives && (selectedCategory
                  === 'Incentives' && selectedCategory !== 'People') )
            ?
            <>
              <Box height={["60px", "40px"]} />

              <IncentiveRowGroup 
                posts={incentives} 
                hideHeader={true} 
                hideFirstLastDash={true} 
                redirect={redirect} 
                key={`IRGv`+incentives.id}
              />
              
              
              {!incentives.length ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5>
                : null}
            </>
            :            
            null
          }        

          {selectedCategory === 'People' && isConnection
            ?
            <>
              <Box height={["60px", "40px"]} />

              <PeopleRowGroup people={users} hideHeader={true} hideFirstLastDash={true} redirect={redirect} />
              {!users.length ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5> :
                null}
            </>
            :
            null

          }

          {selectedCategory === 'Assets' && isConnection
            ?
            <>
              <Box height={["60px", "40px"]} />

              <AssetRowGroup assets={assets} hideHeader={true} hideFirstLastDash={true} redirect={redirect} />

              {!assets.length ? <h5 style={{ marginLeft: "15px", alignSelf: "flex-start" }}>NO RESULTS FOUND</h5> :
                null}
            </>
            :
            null

          }

        </Flex>
      </div>
    );
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    browser: state.browser
  }),
  dispatch => ({
    redirect: bindActionCreators(push, dispatch),

  })
)(CompanyDetail);
