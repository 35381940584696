import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudCheck } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        >
            <FontAwesomeIcon
            icon={faCloudCheck}
            size="10x"
            color="#428BF4"
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px",textAlign:"center"}}
            >
                <span 
                style={{fontWeight:900}}
                >
                    Your Company Profile
                </span><br />
                {` Make sure your industry partners can find you.`}
            </NunitoSans>

        </Flex>
);