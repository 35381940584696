import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faMinusCircle, faCog } from '@fortawesome/free-solid-svg-icons';

import { NunitoSans } from "../common/typography";

import { Flex, Box } from "../common/flexbox";


export const LoadingSpinner = props =>(

        <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={props.style || {}}
        >
            <>
        <FontAwesomeIcon
        
        icon={faCog}
        color={props.color || "#2FD371"}
        size={props.spinnerSize || '2x'}
        spin
        />

        
        <NunitoSans
         
          fontSize={props.textSize || "13px"}
          fontWeight="bold"
          color="#2FD371"
          textAlign="center"
        >
          {props.message ? props.message : 'LOADING'}
        </NunitoSans>
        </>
        </Flex>
);

export default LoadingSpinner;