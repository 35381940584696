import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, reset, getFormValues } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import AWS from "aws-sdk";
import moment from "moment";
import Modal from "react-modal";
import { history } from "../../../store";
// import { difference, differenceWith, isEqual } from "lodash";

//utils
import { getUser, dismissConnectionSuggestion, getDismissedConnectionSuggestions, dismissSuggestionDuringSession, getDismissSuggestionDuringSession } from "../../../utils/auth";
import {defaultCompany, defaultProfile} from "../../../utils/defaultLogos";
//actions
import { actions } from "../../../actions/resources";
import { success, error } from "../../../actions/alerts";
//components
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { LockedFeature } from "../../common/lockedfeature";

import { DepartmentLogoImage, RecommendedContentWrapper, OneSixDash } from "../../common/ui";
import { InputStyled,DashboardSmallCTA } from "../../common/forms";
import { NunitoSans, NunitoSansSemiBold, NunitoSansBold, H1CondensedSmallD0D0D0 } from "../../common/typography";




export class ManageVendorsAccessory extends Component {

  state ={
    labelModalOpen: false,
    partnerIds: null,
    filteredPartnerIds: null,
    initialPartnerIds: {},
    dismissedConnections: []
  }

  _fetchDependencies = async () => {
    const {fetchCompany}=this.props;
    fetchCompany(getUser().company_id);
    this._fetchPartnerIds();
    this._initializeLabelValues();
    
  }

  componentDidMount(){
    this._fetchDependencies();
    let dismissedConnections = getDismissSuggestionDuringSession();
    
    this.setState({dismissedConnections})
  }

  _fetchPartnerIds = async ()=>{

    const {systemSettings} = this.props;

    if (systemSettings.rewards_active &&  systemSettings.product_active && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin){
      const partnerIds = await fetch(`${process.env.REACT_APP_API_URL}/partnerids`).then(res=>res.json());
     

      if(!partnerIds || partnerIds.length === 0) return;
     
      let filteredPartnerIds = partnerIds;
      this.setState({partnerIds, filteredPartnerIds});
      // console.log(partnerIds, "PROD")
    }

  }

  //CHECK THIS ON DEMO AND APP - SEPT 2022 --gmk
  //sometimes the fetch was failing
  _initializeLabelValues = async ()=>{
    let labels = [];
    
    labels = await fetch(`${process.env.REACT_APP_API_URL}/partnerlabels`).then(
      (res)=>{
        if(res){
          return res.json();
        } else {
          return [];
        }
      }
    );
      
    let theLabels = {};

    labels && labels.forEach(lb=> theLabels[lb.partner_id] = lb.label);
    this.setState({initialPartnerIds: theLabels})
    this.props.initialize(theLabels);
  }

  _handleSuggestionConnect = async(id)=>{
    const { createConnection, fetchConnections, fetchNotificationCount, fetchConnectionSuggestions} = this.props;

    this._dismissForSession(id);
    
    await createConnection({
      vendor_id: id,
      company_id: getUser().company_id,
      user_id: getUser().id,
      status: "requested"
    });
   
    fetchConnectionSuggestions({
      company_id: getUser().company_id
    });

    await fetchConnections({ company_id: getUser().company_id });
    fetchNotificationCount();
  };

  _dismissForSession = id =>{
    dismissSuggestionDuringSession(id);
    this.setState({
      dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
    });
  }

  _handleConnectionSuggestionDismiss = id =>{

    dismissConnectionSuggestion(id);
      this.setState({
        dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
      });
  }

  _onSubmit = async data => {
    const {
      retrieveCompania,
      createConnection,
      createUser,
      fetchConnections,
      patchUser, 
      notifySuccess,
      resetForm
    } = this.props;
    
    let userPayload = {
      email: data.email, 
      first_name: data.first_name,
      last_name: data.last_name,
      status: "invited_company"
    };

    //window.alert("stop");
    let company = await retrieveCompania(data.email.split("@")[1]);//if a company exists with this domain name, get it

    let getUserEmail = getUser().email.split("@")[1];
    let userPayloadEmail = userPayload.email.split("@")[1];
    
    if (userPayloadEmail != undefined) {

      if (!company.payload.id) { //if there is no company, then make it and get it
    
        userPayload = {
          ...userPayload,
          ...{
            companyExists: "false"
          }
        };
    
        const user = await createUser(userPayload);
        if (user.payload.id) {
          notifySuccess(
            "There was a problem sending email."
          );
        } else {
          notifySuccess(
            "An email invitation will be sent to " + userPayload.first_name
          );
    
        }
    
      } else {
    
        const user = await createUser(userPayload);
         if (user.payload.id) {
          notifySuccess(
            "An email invitation will be sent to " + userPayload.first_name + " of " +
            data.company_name );
        } else {
          notifySuccess(
            "There was a problem sending email."
          );
    
        }
    
        if (user && user.payload) {
          let companyPayload = {
            domain: data.email.split("@")[1],
            name: data.company_name,
            status: "invited",
            user_id: user.payload.id
          };
    
    
          if (company && company.payload) {
    
            await createConnection({
              vendor_id: company.payload.id,
              company_id: getUser().company_id,
              user_id: getUser().id,
              invitee_id: user.payload.id,
              status: "invited"
            });
    
            if (user.payload && company.payload) {
              fetchConnections({
                company_id: getUser().company_id
              });
            }
          } else {
    
            await createConnection({
              vendor_id: company.payload.id,
              company_id: getUser().company_id,
              user_id: getUser().id,
              invitee_id: user.payload.id,
              status: "invited"
            });
    
            if (user.payload && company.payload) {
              fetchConnections({
                company_id: getUser().company_id
              });
            }
    
          }
    
          await patchUser(user.payload.id, {
            ...user.payload,
            ...{
              company_id: company.payload.id
            }
          });
    
    
        }
      }
    
    }

   resetForm("create-vendor");
  };

  _openInvitedCompaniesModal = async event => {
    console.log("Show Invited Companies");

  };

  _uploadVendorCsv = files => {
    const { createBatchInvite, notifySuccess, notifyError } = this.props;


    let file = files[0];

    if(typeof file === "undefined"){
      return notifyError(
        "This file doesn't work. It must be a .csv file."
      );
    }


      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });
    const s3Bucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
    });
    const fileKey = `csv-vendor-uploads/${moment().valueOf()}${
      getUser().company_id
    }`;
    let s3Data = {
      Key: fileKey,
      Body: file,
      ContentType: file.type,
      ACL: "public-read"
    };

    return s3Bucket.putObject(s3Data, async (err, response) => {
      const bi = await createBatchInvite({
        user_id: getUser().id,
        company_id: getUser().company_id,
        url: fileKey,
        invite_type: "vendor",
        status: "created"
      });

      if (bi.payload.id) {
        console.log(bi.payload.id)
        console.log(bi.payload)
        notifySuccess(
          "We will email you when we have finished processing your upload."
        );
      }
    });
  };

  validate = values => {
    const errors = {};
    if (!values.company_name) {
     // errors.company_name = "Company name required";
    }

    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = re.test(String(values.email).toLowerCase());
    if (!values.email) {
      errors.email = "Email required";
    } else if (validEmail === false) {
      errors.email = `Email address is not valid`;
    } 

    return errors;
  };
// below accesory side CONNECTIONS page //

_filterPartners = input=>{
  const { partnerIds, filteredPartnerIds} = this.state;
  const { formValues } = this.props;

  input = input.toLowerCase().trim();

  if(!input) return this.setState({filteredPartnerIds: partnerIds});

  const ids = [...partnerIds];

  const keys = formValues && Object.keys(formValues);

  let filtered = ids.filter(id=>id.toLowerCase().includes(input) || filterLabels(id));

  this.setState({filteredPartnerIds: filtered})

  function filterLabels (key){

    if ( !formValues || !Object.keys(formValues).includes(key)) return false;

    if (formValues[key].toLowerCase().includes(input)) return true;
    else return false;

  }

}

_saveLabels = async()=>{
  const {formValues, notifyError, notifySuccess, createLabel} = this.props;

  const labels = formValues && Object.values(formValues);

  if(!labels) return this.setState({labelModalOpen:false});
  
  // Check for duplicate labels
  labels.map(l=>l.toLowerCase().trim()).forEach((label,i,arr)=>{
    if(arr.indexOf(label) !== arr.lastIndexOf(label)){
      return notifyError(`${label} is used more than once as a label. Please fix and try again.`);
    }
  });

  // data is formatted for insertion to labels database table
 
 
  for(let [partner_id, label] of Object.entries(formValues)){
    label = label.trim();

    if(label!==this.state.initialPartnerIds[partner_id]){
      
      await createLabel({
        partner_id,
        label
      }).catch(err=>console.log(err, 'labelError'));
    }
   

  }

  notifySuccess("Labels are saved successfully.");
  this.setState({labelModalOpen: false});
  this._initializeLabelValues();

}

  render() {
    const { company, redirect, connections, systemSettings, companySettings, featureFlags, connectionSuggestions } = this.props;
    const { labelModalOpen, partnerIds, filteredPartnerIds, dismissedConnections } = this.state;

    const filteredConnectionSuggestions = connectionSuggestions ? connectionSuggestions.filter(suggestion=>!dismissedConnections.includes(suggestion.id)): null;
    const showConnectionSuggestions = companySettings.connection_suggestion_active && filteredConnectionSuggestions && filteredConnectionSuggestions.length;

    

    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: "670px",
        height: "80vh",
        zIndex: 999998,
        overflow: "auto"
      },
  
      overlay: {
        zIndex: 999997,
        
        background: "#A3A99E88"
      }
      
    };
    

    return (
      <Flex flexDirection="column">
        <Modal
        isOpen={labelModalOpen}
        onRequestClose={()=>this.setState({labelModalOpen: false})}
        style={customStyles}
        contentLabel="Partner Label Modal"
        ariaHideApp={false}
        >
          <Flex
           flexDirection="column"
           style={{width:"100%", height:'100%'}}
           >
            <Flex
                    position="relative"
                    justifyContent="center"
                    // alignItems="center"
                    style={{marginBottom:'25px'}}
                    >
                      <AbsoluteDiv
                      display={["block"]}
                      left={["0px"]}
                      bottom={"0px"}
                      top={["-5px"]}
                      width={["100px"]}
                      >
                        <DashboardSmallCTA
                        fontSize="12px"
                        onClick={()=>this._saveLabels()}
                        // color={colors.buttonCustom}
                        >
                          SAVE
                        </DashboardSmallCTA>
                      </AbsoluteDiv>
                      <NunitoSansBold
                      fontSize="16px"
                      fontWeight="bold"
                      color="#10213A"
                      >
                      Partner ID Labels
                      </NunitoSansBold>
                      <AbsoluteDiv
                      display={["block"]}
                      right={["20px"]}
                      bottom={"0px"}
                      top={["0px"]}
                      width={["17px"]}
                      >
                        <ClickCursorFlex
                          height={["100%", "100%", "100%"]}
                          alignItems={["center"]}
                          onClick={()=>this.setState({labelModalOpen: false})}
                          justifyContent={[
                            "flex-start",
                            "flex-start",
                            "flex-start",
                            "center"
                          ]}
                        >
                          <IconBox
                            cursor="pointer"
                            pl={["15px"]}
                            pb={["15px"]}
                            pt={["15px"]}
                            fontSize={["20px"]}
                            color="#c3c3c3"
                          >
                            thinx
                          </IconBox>
                          </ClickCursorFlex>
                      </AbsoluteDiv>
                    </Flex>
              <Flex
              flexDirection='column'
              alignItems="center"
              mb="15px"
              
              >
                {/* <NunitoSans>
                  Search by IDs or labels
                </NunitoSans>
                <input
                type="text"
                placeholder="Type an ID or label..."
                onChange={e=>this._filterPartners(e.target.value)}
                style={{border:'1px solid black', padding: '3px 5px', fontFamily:'NunitoSans'}}
                /> */}
<InputStyled
                placeholder="Search by IDs or Labels"
                onChange={e => this._filterPartners(e.target.value)}
                innerRef={input => (this.searchInput = input)}
                // value={audienceSearchInput || ""}
                width="80%"
                name="post-new-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"6px"}
                pt={["3px"]}
                px={["16px"]}
                mt={["0px"]}
                height={["46px"]}
                fontWeight={300}
                fontSize={[18]}
                lineHeight={1.3}
                color={"#979797"}
                borderBottom={[0]}
              />
                          
              </Flex>
              <Flex
              flexDirection="column"
              width="85%"
              style={{margin:'0 auto'}}
              
              >
                <Flex
                justifyContent="space-between"
                alignItems="center"
                style={{borderBottom:'2px solid #D3D3D3', marginTop: '10px', paddingBottom: '10px'}}
            >             
                
                  <NunitoSans
                  fontSize='14px'
                  fontWeight="bold"
                  // color= {colors.buttonCustom}
                  style={{width:"45%", textAlign:'center'}}
                  >
                    PARTNER ID
                  </NunitoSans>

                  <NunitoSans
                   fontSize='14px'
                   fontWeight="bold"
                  //  color= {colors.buttonCustom}
                   style={{width:"45%", textAlign:'center'}}
                   
            >             
                  
                    LABEL
                  </NunitoSans>
                  

                </Flex>
                <form id="partner-labels" style={{overflow:'auto'}}>
                  {
                    filteredPartnerIds&&filteredPartnerIds.map((id,index)=>(
                      <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      style={{backgroundColor: index % 2===0 ?  '#FAFAFA': 'white', paddingBottom: '10px', paddingTop: '10px'}}
            >                     
                      
                        <NunitoSans
                        fontSize='12px'
                        fontWeight="bold"
                        style={{width:"45%", textAlign:'center'}}
                        >
                          {id}
                        </NunitoSans>

                        <Flex
                        justifyContent="center"
                        alignItems="center"
                        style={{width:"45%"}}
                        >
                          <Field
                          name={id}
                          type="text"
                          component="input"
                          placeholder="Add a Label"                          
                          style={{backgroundColor:"#EBEBE7", textAlign:"center", color:"#979797", padding:'3px 5px', fontSize:'12px', fontFamily:'NunitoSans'}}
                          key={"mamageiser"}
                         />
                        </Flex>
                        
                      </Flex>
                    ))
                  }
                </form>

              </Flex>
          </Flex>
        </Modal>
        <Box height="76px" />

        {
          showConnectionSuggestions
          ?
          <>
          <RecommendedContentWrapper noLabel={true}>
          <Flex flexDirection="column" pt="14px" px="8px">
            <Flex px="1%" flexDirection="row" justifyContent="space-between">
              <Box maxWidth="80%">
                <H1CondensedSmallD0D0D0>Recommended Connections</H1CondensedSmallD0D0D0>
              </Box>
            </Flex>
            <Flex pt="19px" flexDirection="column" px="1%">
           

           <Flex
           flexDirection="column"
           mt="16px"
           >
             {
               filteredConnectionSuggestions.slice(0,3).map((suggestion, index)=><ConnectionSuggestionRow key={index} index={index}  suggestion={suggestion} handleConnect={this._handleSuggestionConnect} handleDismiss={this._handleConnectionSuggestionDismiss} />)
             }

           </Flex>

           {
             filteredConnectionSuggestions && filteredConnectionSuggestions.length > 3
             ?
             
            
             <Flex flexDirection="row" justifyContent="center" alignItems="center">
             <DashboardSmallCTA
               width="50%"
               onClick={() => {
                 redirect(
                   `/dashboard/company/${getUser().company_id}/connectionsuggestions`
                 )
               }}
               minWidth={["90px"]}
               my="12px"
               height="34px"
             >
               VIEW ALL
             </DashboardSmallCTA>
             </Flex>
           
             :
             null
           }
           </Flex>
          </Flex>
        </RecommendedContentWrapper>
        <Box height="40px" />
        </>
          :
          null
        }
         
         <Flex
              mb="54px"
              flexDirection={["column"]}
            >
             
          <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="14px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Groups</H1CondensedSmallD0D0D0>
            </Box>
            </Flex>
            
          <Flex pt="19px" flexDirection="column" px="1%">
          
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              {
                featureFlags.groups === true
                ?
                  <DashboardSmallCTA
                  width="100%"
                  onClick={() => {
                    redirect(
                      `/dashboard/company/${getUser().company_id}/groups`
                    )
                  }}
                  minWidth={["90px"]}
                  my="12px"
                  height="34px"
                >
                  MANAGE
                </DashboardSmallCTA>
                :
                <LockedFeature 
                text="Upgrade to Add Groups"
                width="100%"
                minWidth={["90px"]}                
                my="12px"
                height="34px"
                padding="10px"
                fontSize="14px"
                marginBottom="10px"
                />
                
              }
              
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper>
    </Flex>
      
{partnerIds&&partnerIds.length&&systemSettings.rewards_active &&  systemSettings.product_active && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin ? 
            <Flex
              mb="54px"
              flexDirection={["column"]}
            >
             
          <RecommendedContentWrapper noLabel={true}>
        <Flex flexDirection="column" pt="14px" px="8px">
          <Flex px="1%" flexDirection="row" justifyContent="space-between">
            <Box maxWidth="80%">
              <H1CondensedSmallD0D0D0>Partner Labels</H1CondensedSmallD0D0D0>
            </Box>
            </Flex>
            
          <Flex pt="19px" flexDirection="column" px="1%">
          
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DashboardSmallCTA
                width="100%"
                onClick={() => this.setState({labelModalOpen: true})}
                minWidth={["90px"]}
                my="12px"
                height="34px"
              >
                MANAGE
              </DashboardSmallCTA>
            </Flex>
          </Flex>
        </Flex>
      </RecommendedContentWrapper>
            </Flex>
      : 
      null}
      </Flex>

    ); 
  }
}

ManageVendorsAccessory = reduxForm({
  form: "partner-labels",
  enableReinitialize : true,
  keepDirtyOnReinitialize : true
  
})(ManageVendorsAccessory);

ManageVendorsAccessory = connect(
  state => ({
    formValues: getFormValues('partner-labels')(state),
    connections: state.resources.collection.connection,
    company: state.resources.detail.company,
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    connectionSuggestions: state.resources.collection.connectionsuggestion || [],
  }),
  dispatch => ({
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    createBatchInvite: bindActionCreators(actions.createBatch_invite, dispatch),
    createLabel: bindActionCreators(actions.createPartnerlabel, dispatch),
    retrieveCompania: bindActionCreators(actions.retrieveCompania, dispatch),
    createCompany: bindActionCreators(actions.createCompany, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    patchUser: bindActionCreators(actions.patchUser, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    resetForm: bindActionCreators(reset, dispatch),
    redirect: bindActionCreators(push, dispatch)
  })
)(ManageVendorsAccessory);

export default ManageVendorsAccessory;



const ConnectionSuggestionRow = ({suggestion={}, handleConnect, handleDismiss, index})=>{

  const {id, name, logo, pseudoName, user_id} = suggestion;

    const theLink = pseudoName ? `/dashboard/profile/${user_id}` : `/dashboard/company/${id}`;

  return (
    <Flex
    justifyContent="space-between"
    alignItems="center"
    style={{paddingBottom: '15px', paddingTop: '15px', cursor: 'pointer' }}
    onClick={()=>history.push(theLink+"?companies")}
    position="relative"
    >
      {
        index!==0
        ?
        <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
        :
        null
      }
      <Flex
      alignItems="center"
      >
      <img src={logo ? logo : pseudoName ? defaultProfile(id, name) : defaultCompany(id, name)} alt={`${name}`} width={35} height={35} style={{borderRadius: '50%'}} />

      <Flex
      flexDirection="column"
      ml="20px"
      >

        <NunitoSansBold
        fontSize="16px"
        >
          {name}
        </NunitoSansBold>
        {
          pseudoName
          ?
          <NunitoSans
          fontSize="12px"
          >
            {pseudoName}
          </NunitoSans>
          :
          null
        }

      </Flex>

      </Flex>

      <DashboardSmallCTA
      width="15%"
      minWidth={["60px"]}
      fontSize="10px"
      innerHeight="20px"
      ml="10px"
      onClick={(e)=>{
        e.stopPropagation();
        return handleConnect(id);
        }}
      >
         Connect
      </DashboardSmallCTA>

    </Flex>
  );
}