import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link, OriginalLink } from "../../../common/navigation";
import _ from "underscore";
import { createNumberMask } from 'redux-form-input-masks';
//utils
import { getUser } from "../../../../utils/auth";
//actions
import { mainContentScrollTop } from "../../../../actions/dashboardPresentation";
import { companyTotal, selectRow } from "../../../../actions/post";
import { actions } from "../../../../actions/resources";
//components
import { DateBubble } from "../../../common/forms";
import { renderDante, renderCondensedField, DashboardSmallCTA, SingleDatePickerWrapper, DashboardCTA, Eyebrow } from "../../../common/forms";

import { Flex, Box } from "../../../common/flexbox";
// import { AbsoluteDiv } from "../../../common/absolute";
import FileAttachments from "./files";
import VideoAttachments from "./videos";
// import PrivatePost from "./privatepost";
import { MobileNavigationalBubble } from "../../../common/ui";
import { AudienceRow } from "../../../common/rows";
import { AudienceMultiRowGroup } from "../../../common/rows";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus, faMinusCircle, faCog } from '@fortawesome/free-solid-svg-icons';
// import { faLockAlt } from "@fortawesome/pro-solid-svg-icons";
import { NunitoSans, NunitoSansSemiBold, NunitoSansBold } from "../../../common/typography";
import BrandsDropdown from "./branddropdown";

import robSchniederImage from "../../../../images/rob-schnieder.png";
import samsungImage from "../../../../images/samsung_square.png";

const laptopBubbleWidth = 47.3;
const validate = values => {
  const errors = {};


  if (!values.title) {
    errors.title = "Title cannot be blank.";
  }

  if (values.title && values.title.length > 75) {
    errors.title = "Title is too long.";
  }

  return errors;
};

class AddPostMain extends PureComponent {
  state = {
    titleCharCount: 75,
    clicked: false,
    submitted: false,
    selectedAudienceFilters: [],
    searchModeActive: false,
    searchAudienceFilters: [
      {
        type: "person",
        row: {
          id: 1,
          name: "Rob Schnieder",
          image: robSchniederImage,
          company: "Samsung Inc."
        }
      },
      {
        type: "department",
        row: {
          id: 2,
          image: samsungImage,
          name: "Sales",
          company: "Samsung Inc.",
          people: "10 People"
        }
      },
      {
        type: "company",
        row: {
          id: 3,
          image: samsungImage,
          name: "Samsung Inc.",
          people: "10 People"
        }
      }
    ]
  };

  componentDidMount(){
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }
    // fetchConnections({ company_id: getUser().company_id, post: true });
  };
  _submitForm = async () => {
    console.log("_submitForm", this.state.submitted);
    this.setState({ submitted: true });
    console.log("_submitForm", this.state.submitted);
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows } = this.props;
    let rows = companyRows.concat(departmentRows, userRows);
    return rows;
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;

    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers
    };
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };

  render() {
    const {
      posting,
      clicked,
      clickToPrivate,
      addVideo,
      browser,
      errors,
      files,
      handleSubmit,
      onDateChange,
      onDrop,
      updateVideoInput,
      postBody,
      removeFile,
      removeVideo,
      updateContent,
      videos,
      startDate,
      endDate,
      adjustScrollTop,
      allConnections,
      connections,
      selectedRows,
      videoUrl,
      user,
      videoAdded,
      brandOptions,
      selectedBrand,
      handleBrandSelection,
      initialValues,
      systemSettings,
      companySettings,
      formValues
    } = this.props;

    const { titleCharCount } = this.state;

    /*const numberMask = createNumberMask({
      // prefix: '$ ',
      // suffix: ' per item',
      allowNegative: false,
      locale: 'en-US',
    });*/

    return (
      <Flex flexDirection="column">
        <div style={{ height: "89vh", overflowY: "auto", overflowX: "hidden", paddingLeft: "2px", paddingRight: "20px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} id="addpost-form">

            
            <Field
              key={"POST TITLE"}
              label="POST TITLE"
              name="title"
              placeholder="Informative & Concise Post Title"
              component={renderCondensedField}
              onChange={e => this.setState({ titleCharCount: 75 - e.target.value.length })}
              type="text"
              width="100%"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{ "padding-left": "12px", "padding-right": "12px" }}
              px={["12px"]}
              pt={["16px"]}
              pb={["6px"]}
            />

            <Flex
              justifyContent="flex-end"
            >

              <NunitoSans
                fontSize='14px'
                style={{ position: 'relative', top: '-50px' }}
                color={titleCharCount < 0 ? '#FE4A49' : '#979797'}
              >
                {`${titleCharCount} characters remaining`}
              </NunitoSans>

            </Flex>

            {
              brandOptions && brandOptions.length > 1
                ?
                <Box mb="30px">
                  <Eyebrow>POST AS</Eyebrow>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      backgroundColor: '#ebebe7',
                      borderRadius: '4px',
                      padding: '10px'
                    }}
                  >
                    <NunitoSansSemiBold
                      color="#000000"
                      fontSize="14px"
                    >
                      Choose the brand you'd like to post on behalf of.
                    </NunitoSansSemiBold>

                    <BrandsDropdown
                      brandOptions={brandOptions}
                      selectedBrand={selectedBrand}
                      handleBrandSelection={handleBrandSelection}
                    />

                  </Flex>
                </Box>
                :
                null
            }

            <Field
              errors={errors}
              label="POST BODY"
              name="body"
              placeholder="Add your message and links here"
              component={renderDante}
              content={postBody}
              updateContent={updateContent}
            />
            <FileAttachments
              files={files}
              onDrop={onDrop}
              removeFile={removeFile}
            />
            <VideoAttachments
              addVideo={addVideo}
              updateVideoInput={updateVideoInput || {}}
              removeVideo={removeVideo}
              videos={videos}
              videoUrl={videoUrl}
              videoAdded={videoAdded}
            /> 
            {browser.lessThan.large ? (
              <Flex mt="16px" pb="16px" flexDirection="column">
                <Eyebrow ml={"8px"}>AUDIENCE</Eyebrow>
                <Flex
                  bg="inputBackgroundGray"
                  px={"15px"}
                  pb={"15px"}
                  borderRadius="10px"
                  flexDirection="column"
                >
                  {selectedRows.length ? (
                    <Box position="relative" mt="0px">
                      <AudienceMultiRowGroup                        
                        color={"#f7f7f7"}
                        key={["FORM-addpostmain"]}
                        groupName="SEARCH RESULTS"
                        audiencesMulti={selectedRows}
                        selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                        onSelect={this._selectRow}
                        mobile={true}
                        hideHeader={true}
                        hideFirstLastDash={true}
                      />
                    </Box>
                  ) : (
                    <Box position="relative">
                      <AudienceRow
                        submit={() => alert("submit")}
                        color={"#f7f7f7"}
                        key={0}
                        active={true}
                        firstRow={true}
                        lastRow={true}
                        hideFirstLastDash={true}
                        mobile={true}
                        removeAction={() => alert("remove")}
                        removeFromSelected={() => alert("remove from selected")}
                        hideAdd={false}
                        name="All Connections"
                        companyNumber={`${allConnections
                            ? companyTotal(connections, user)
                            : companyTotal(connections, user)
                          } Companies`}
                      />
                    </Box>
                  )}
                  <Link to="/dashboard/posts/new/audience">
                    <MobileNavigationalBubble
                      icon="search"
                      text="Audience Filter"
                    />
                  </Link>
                </Flex>
              </Flex>
            ) : null}
            <Flex
              mt={["25px"]}
              justifyContent="space-between"
              flexDirection="row"
              flexWrap={["wrap", "nowrap"]}
            >
              <Box width={["100%", laptopBubbleWidth + "%"]} mb={["38px"]}>
                <DateBubble>
                  <SingleDatePickerWrapper
                  bg="inputBackgroundGray"
                  key={"startDate"}
                    date={startDate}
                    error={errors && errors["startDate"]}
                    name="startDate"
                    onDateChange={onDateChange}
                    onFocusChange={() => adjustScrollTop(500)}
                    label="START DATE"
                  />
                </DateBubble>
              </Box>
              <Box width={["100%", laptopBubbleWidth + "%"]}>
                <DateBubble>
                  <SingleDatePickerWrapper
                    key={"endDate"}
                    date={endDate}
                    error={errors && errors["endDate"]}
                    name="endDate"
                    onDateChange={onDateChange}
                    onFocusChange={() => adjustScrollTop(500)}
                    label="END DATE"
                  />
                </DateBubble>
              </Box>

            </Flex>

            <Flex
              mb="10px">
            </Flex>

            <Flex
              width="100%"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              mb="76px"
              onClick={this._submitForm}
              // onClick={posting ? () => { } : () => this.setState({ submitted: true })}
            >
              {
                posting ?
                  <>
                    <FontAwesomeIcon
                      mb="10px"
                      icon={faCog}
                      color="#2FD371"
                      size='2x'
                      spin
                    />

                    <NunitoSans

                      fontSize="13px"
                      fontWeight="bold"
                      color="#2FD371"
                      textAlign="center"
                    >
                      {this.props.editMode ? "SAVING" : "POSTING"}
                    </NunitoSans>
                  </>
                  :
                  <DashboardCTA 
                  loading={JSON.stringify({"loading":(this.state.submitted && errors.length === 0)})}
                    mt={["50px", "21px", "21px", "21px"]}
                    width={[0.75, 0.75, 0.6, 0.6]}
                    ctaText={this.props.editMode ? "SAVE" : "POST"}
                    color="#2FD371"
                  />
              }
            </Flex>
          </form>
        </div>
      </Flex>
    );
  }
}

AddPostMain = reduxForm({
  destroyOnUnmount: true,
  form: "addpost-form",
  validate,
  enableReinitialize: true
})(AddPostMain);

AddPostMain = connect(
  state => ({
    formValues: getFormValues('addpost-form')(state),
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    //connections: state.resources.collection.connection,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    browser: state.browser,
    user: state.resources.detail.user
  }),
  dispatch => ({
    //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    adjustScrollTop: bindActionCreators(mainContentScrollTop, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch)
  })
)(AddPostMain);

export default AddPostMain;
