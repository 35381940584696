import React from "react";

import styled from "styled-components";
import {
  textAlign,
  space,
  width,
  fontSize,
  color,
  height,
  borders,
  fontWeight,
  lineHeight,
  alignSelf,
  display
} from "styled-system";
import { ThickPixelDash, ThickPixelDashD0D0D0 } from "../common/ui";

const H1Styled = styled.h1`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSans;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const H1 = ({ ...props }) => (
  <H1Styled
    {...props}
    border={0}
    fontWeight={"bold"}
    fontSize={[30, 40]}
    lineHeight={1.3}
    color={"darkText"}
  >
    {props.children}
    <ThickPixelDash />
  </H1Styled>
);
export const H1Small = ({ ...props }) => (
  <H1Styled
    {...props}
    border={0}
    fontWeight={"bold"}
    fontSize={[30]}
    lineHeight={1.3}
    color={"darkText"}
  >
    {props.children}
    <ThickPixelDash />
  </H1Styled>
);

const NunitoSansSemiBoldItalicStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  ${textAlign}
  font-display: swap; font-family:NunitoSansSemiBoldItalic;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const NunitoSansSemiBoldItalic = ({ ...props }) => (
  <NunitoSansSemiBoldItalicStyled {...props}>{props.children}</NunitoSansSemiBoldItalicStyled>
);



const NunitoSansExtraBoldStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  ${textAlign}
  font-display: swap; font-family:NunitoSansExtraBold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const NunitoSansExtraBold = ({ ...props }) => (
  <NunitoSansExtraBoldStyled {...props}>{props.children}</NunitoSansExtraBoldStyled>
);
const NunitoSansBoldStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  ${textAlign}
  font-display: swap; font-family:NunitoSansBold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const NunitoSansBold = ({ ...props }) => (
  <NunitoSansBoldStyled {...props}>{props.children}</NunitoSansBoldStyled>
);

const H1CondensedStyled = styled.h1`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSansBold;
  font-weight:normal;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;
export const H1Condensed = ({ ...props }) => (
  <H1CondensedStyled
    {...props}
    fontSize={[20, 35]}
    lineHeight={1.3}
    color={"darkText"}
    pb={["1px", "3px"]}
  >
    {props.children}
    <ThickPixelDashD0D0D0 />
  </H1CondensedStyled>
);

export const H1CondensedSmall = ({ ...props }) => (
  <H1CondensedStyled
    {...props}
    fontSize={[24]}
    lineHeight={1.3}
    color={"darkText"}
    pb={["1px"]}
  >
    {props.children}
    <ThickPixelDashD0D0D0 />
  </H1CondensedStyled>
);

export const H1CondensedSmallD0D0D0 = ({ ...props }) => (
  <H1CondensedStyled
    {...props}
    fontSize={[28]}
    lineHeight={1.3}
    color={"black"}
    pb={["1px"]}
  >
    {props.children}
    <ThickPixelDashD0D0D0 />
  </H1CondensedStyled>
);

const H2Styled = styled.h2`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSansSemiBold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const H2 = ({ ...props }) => (
  <H2Styled
    {...props}
    border={0}
    fontWeight={"light"}
    fontSize={[42, 42]}
    lineHeight={1.3}
    color={"black"}
  >
    {props.children}
  </H2Styled>
);

const H3CondensedStyled = styled.h3`
  ${space}
  ${width}
  ${height}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSans;
  font-weight:normal;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;
export const H3Condensed = ({ ...props }) => (
  <H3CondensedStyled
    {...props}
    fontSize={15}
    lineHeight={1}
    color={"darkGray"}
  >
    {props.children}
  </H3CondensedStyled>
);

const H4Styled = styled.h4`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSans;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const H4 = ({ ...props }) => (
  <H4Styled
    {...props}
    border={0}
    fontWeight={"bold"}
    fontSize={[12, 16]}
    lineHeight={1.3}
    color={props.color ? props.color : "darkGray"}
  >
    {props.children}
  </H4Styled>
);

export const H4Small = ({ ...props }) => (
  <H4Styled
    {...props}
    fontWeight={"bold"}
    fontSize={12}
    lineHeight={1.3}
    color={props.color ? props.color : "darkGray"}
  >
    {props.children}
  </H4Styled>
);

const H5Styled = styled.h5`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSans;
  font-weight:bold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const H5 = ({ ...props }) => (
  <H5Styled
    {...props}
    border={0}
    fontSize={[10, 12]}
    lineHeight={1.3}
    color={props.color ? props.color : "darkGray"}
  >
    {props.children}
  </H5Styled>
);

export const NunitoSansStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${lineHeight}
  ${alignSelf}
  ${fontWeight}
  ${textAlign}
  ${display}
  font-style: ${props => (props.fontStyle ? props.fontStyle : "normal")}
  font-display: swap; font-family:NunitoSans;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const NunitoSans = ({ ...props }) => (
  <NunitoSansStyled {...props}>{props.children}</NunitoSansStyled>
);


export const NunitoSansSemiBoldStyled = styled.div`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${lineHeight}
  ${alignSelf}
  ${fontWeight}
  ${textAlign}
  ${display}
  font-style: ${props => (props.fontStyle ? props.fontStyle : "normal")}
  font-display: swap; font-family:NunitoSansSemiBold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const NunitoSansSemiBold = ({ ...props }) => (
  <NunitoSansSemiBoldStyled {...props}>{props.children}</NunitoSansSemiBoldStyled>
);

const H6Styled = styled.h6`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${lineHeight}
  ${alignSelf}
  ${textAlign}
  font-display: swap; font-family:NunitoSans;
  font-weight:bold;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const H6 = ({ ...props }) => (
  <H6Styled
    {...props}
    border={0}
    fontSize={["8px", "10px"]}
    lineHeight={1.3}
    color={props.color ? props.color : "darkGray"}
  >
    {props.children}
  </H6Styled>
);

const PStyled = styled.p`
  ${space}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${alignSelf}
  font-display: swap; font-family:NunitoSans;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const P = ({ ...props }) => (
  <PStyled
    py={["16px", "22px", "22px", "22px"]}
    border={0}
    fontWeight={"normal"}
    fontSize={[14, 20]}
    lineHeight={1.7}
    color={"darkText"}
    {...props}
  >
    {props.children}
  </PStyled>
);

export const UnderlinedNunitoSans = styled(NunitoSans)`
  text-decoration: underline;
`;


export const UnderlinedNunitoSansSemiBold = styled(NunitoSansSemiBold)`
  text-decoration: underline;
`;


