import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
//actions
import { actions } from "../../../../actions/resources";
import { Flex, Box, IconBox } from "../../../common/flexbox";
// import { MegaFlexBubble } from "../../common/ui";
import { NunitoSansExtraBold, NunitoSansBold } from "../../../common/typography";
// import { MapboxMap } from "../../common/mapboxMap";
import {
  WebsiteBubble,
  EmailBubble,
  PhoneBubble
} from "../../../common/rows";
import { getUser } from "../../../../utils/auth";
import { LockedFeature } from "../../../common/lockedfeature";


export class ProfileDetailAccessory extends Component {
  state = {
    _isMounted: false,
    user: {},
    connections: {},
    isConnection: false
  }
  constructor(props) {
    super(props);
  }



  componentDidMount() {
    this.state._isMounted = true;
    this._fetchDependencies();
  }

  _connections = async (fetchConnections) => {
    return await fetchConnections({ company_id: getUser().company_id, status: 'active' });
  }

  _fetchDependencies = async () => {
    const { fetchUser, fetchConnections, companySettings, user, match } = this.props;

    // 
    if((user &&match)&&(match.params)&&(match.params.id===user.id) ){
      // console.log(user,"fat user")
      // console.log(match,"fat user")
      await fetchUser(getUser().id, { edit: true });
    }

    let connections;
    this._connections(fetchConnections)
      .then(() => {
        connections = this.props.connections;
        // console.log("_fetchDependencies connections", connections);//this is the raw connections returned from querying the PostgreSQL database connections table
        let Vendor_ids = [];
        if (connections) {
          connections.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              Vendor_ids.push(v.vendor_id);
            } else {
              Vendor_ids.push(v.company_id);
            }
          }

          // console.log("_fetchDependencies Vendor_ids", Vendor_ids);
          // this.setState({ vendor_ids: Vendor_ids });

          const isConnection = Vendor_ids.includes(user.company_id);
          // console.log("_fetchDependencies isConnection", isConnection);
          this.setState({ isConnection });
        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });
  };

  componentDidUpdate(prevProps) {

    const { user, match } = this.props;

    if (prevProps.user !== user || (prevProps.user && user && prevProps.user.company_id !== user.company_id)) {
      this.getConnectionStatus()
    }

  }

  getConnectionStatus = async () => {


    const { fetchUser, fetchConnections, companySettings, match, user } = this.props;

    if (user.id !== match.params.id) return;

    let activeConnections;
    this._connections(fetchConnections)
      .then(() => {
        activeConnections = this.props.connections;
        // console.log("_fetchDependencies activeConnections", activeConnections);//this is the raw connections returned from querying the PostgreSQL database connections table
        let Vendor_ids = [];
        if (activeConnections) {
          activeConnections.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              Vendor_ids.push(v.vendor_id);
            } else {
              Vendor_ids.push(v.company_id);
            }
          }

          // console.log("_fetchDependencies Vendor_ids", Vendor_ids);
          // this.setState({ vendor_ids: Vendor_ids });

          const isConnection = Vendor_ids.includes(user.company_id);
          // console.log("_fetchDependencies isConnection", isConnection);
          this.setState({ isConnection });
        }
      })
      .catch((e) => {
        console.log("_fetchDependencies e", e);
      });


  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  // below accessory in MY PROFILE page //
  render() {
    const { user, match, systemSettings, companySettings, featureFlags } = this.props;
    const { isConnection } = this.state;

    if (true || (user && (user.id === match.params.id))) {
      if (systemSettings.privacy_active && companySettings.company_private && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) return null;
      if (systemSettings.privacy_active && companySettings.users_private && user.profile_private && user.company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) return null;
      if (isConnection === null) return null;

      if (!isConnection) return (<Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt="60px"
        style={{ backgroundColor: "#F2F2F2", height: "50vh", borderRadius: '17px', borderShadow: '0px 0px 6px #00000029' }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt='100px'
          style={{ width: '94px', height: '94px', border: '1px solid #A3A99E', borderRadius: '50%', backgroundColor: 'white' }}
        >
          <IconBox
            color="#D3D3D3"
            fontSize="94px"
          >
            lock_icon
          </IconBox>

        </Flex>

        <NunitoSansExtraBold
          color="#A3A99E"
          fontSize="15px"
          style={{ marginTop: '20px' }}
        >
          CONNECT TO SEE DETAILS
        </NunitoSansExtraBold>

      </Flex>);

      return (
        <Flex
          flexDirection="column"
          width="100%"
          position="relative"
          mt={"91px"}
          style={{ height: "89vh", overflow: "auto", paddingLeft: "5px", paddingRight: "25px", paddingTop: "5px" }}
        >
          <NunitoSansBold
            textAlign="center"
            mb='10px'
          >
            CONTACT
          </NunitoSansBold>
          {
            featureFlags.view_user_contact === true || match.params.id === getUser().id || user.company_id === getUser().company_id
            ?
            <>
            <Box>
            {user &&
              _.filter(
                user.websites,
                website => website.status === "active"
              ).map((website, i) => {
                return <WebsiteBubble key={i} {...website} />;
              })}
          </Box>
          <Box>
            <EmailBubble key={0} body={user.email} label={"Primary"} />
            {user &&
              _.filter(
                user.email_addresses,
                email_address => email_address.status === "active"
              ).map((email, i) => {
                return <EmailBubble key={i + 1} {...email} />;
              })}
          </Box>
          <Box>
            {user &&
              _.filter(
                user.phone_numbers,
                phone_number => phone_number.status === "active"
              ).map((phone, i) => {
                return <PhoneBubble key={i} {...phone} />;
              })}
          </Box>
            </>
            :
            featureFlags.view_user_contact === "locked"
            ?
            <Box>
              <LockedFeature
                text="Upgrade to View Contact Info"
              />
            </Box>
            :
            null
          }
          
        </Flex>
      );
    }
  }
}

export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    connections: state.resources.collection.connection || [],
    companySettings: state.resources.detail.companysetting,
    featureFlags: state.resources.detail.featureflag,
    user: state.resources.detail.user,
  }),
  dispatch => ({
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch)
  })
)(ProfileDetailAccessory);
