import React, {
  Component
} from "react";
import NotFound from '../notfound';
import axios from "axios";

export class CompanyRedirect extends Component {
  state = {
    "c": ""
  }

  componentDidMount() {
    this._fetchDependencies();
  }

  _fetchDependencies = async () => {
    const pathname = window.location.pathname;
    
      //https://kp-production.herokuapp.com/c/apple
      //  axios.get(`${process.env.REACT_APP_API_URL}/maintenance_mode`).then(respond => {

        //USAGE http://localhost:3000/c/benq

    let c = pathname.toString().substring(3).toLowerCase();

    await axios.get(`https://kp-production.herokuapp.com/c/${c}`).then(respond => {
      console.log(respond);
      if(respond && respond.data && respond.data.company_id){
        this.setState({
          "c": "dashboard/company/"+ respond.data.company_id
        });
      } else {
        this.setState({
          "c": c
        });
      }
    });

  };

  render() {
      let redirect_to = false;
      let DMG = "merge22/src/components/dashboard/company/redirect.js";
      let c = this.state.c;
      console.log(DMG + " c render ", c);

      //create a Lambda function URL (https://docs.aws.amazon.com/lambda/latest/dg/lambda-urls.html) that looks up a text string in an Algolia index and returns the ID of its company as a pathname
      // c = "dashboard/company/46be09e4-ec9e-4987-86be-f4556297e32a"
      // will redirect to 
      //  http://localhost:3000/dashboard/company/46be09e4-ec9e-4987-86be-f4556297e32a

      if (redirect_to !== false || c === "opensesame" || c.search("dashboard/company") !== -1) {
        // window.location.href = "https://app.kickpost.io/"+c
        if (c !== "opensesame") {
        window.location.href = "/" + c;
        // window.location.href = "/dashboard/company/46be09e4-ec9e-4987-86be-f4556297e32a"  
        return ( <div > </div>  );
        } else {
        return ( <div > Hi {  c  } </div>
        );
        }
      } else {
          if (c == '') {
            return (<div></div>)
          } else {
            return (<NotFound />)
          }
      }
    }
  }