import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import _ from "underscore";
import moment from "moment";
import queryString from "query-string";
//utils
import { getUser } from "../../../utils/auth";
import { success, error } from "../../../actions/alerts";
//actions
import { actions } from "../../../actions/resources";
import { setIncentiveHovered, setIncentiveSelected } from "../../../actions/incentives";
import { companyTotal, peopleTotal, selectRow, clearRows } from "../../../actions/post";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionGroups,
  searchConnectionUsers,
  searchUsers
} from "../../../actions/search";
//components
import { H2, NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold,H1CondensedSmallD0D0D0 } from "../../common/typography";
import CompanyInfographic from "./audiences/companyInfographic";
import PeopleInfographic from "./audiences/peopleInfographic";
import { Flex, Box, IconBox, ClickCursorFlex } from "../../common/flexbox";
import { MegaFlexBubble, BillingCTA, OneSixDash, FourFourDottedWith8pxCornerRadius,RecommendedContentWrapper } from "../../common/ui";
import { AudienceRow } from "../../common/rows";
import { AudienceMultiRowGroup } from "../../common/rows";
import {AudienceList} from "../../common/reactWindow";
import { InputStyled, DashboardSmallCTA } from "../../common/forms";
import { AbsoluteDiv } from "../../common/absolute";
// Timport { ClickCursorHoverFlex} from "../../common/ui"

export class ManageIncentiveAccessory extends Component {
  state = {
    audienceSearchInput: null,
    searchModeActive: false,
    is_partner_incentive: queryString.parse(window.location.search).type==='partner'
  };

  componentDidMount(){
    this._fetchDependencies();
  }

  componentWillUnmount(){
    this._removeAudience();
  }

  componentDidUpdate(prevProps, prevState) {
    const { audienceSearchInput, searchModeActive } = this.state;
    if (searchModeActive === true && prevState.searchModeActive === false) {
      this._search(audienceSearchInput || "");
    }

    if(prevProps.incentiveSelected !== this.props.incentiveSelected){
      this._removeAudience();
      this.setState({audienceSearchInput: null, searchModeActive: false});
      console.log("REMOVED")
    }

    if(prevProps.incentive && this.props.incentive && prevProps.incentive.id!==this.props.incentive.id){
      this.setState({is_partner_incentive: this.props.incentive.is_partner_incentive})
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      searchUsers,
      selectedCompanies,
      selectedDepartments,
      selectedUsers,
      selectedGroups
    } = this.props;

    if (
      selectedCompanies !== nextProps.selectedCompanies ||
      selectedDepartments !== nextProps.selectedDepartments ||
      selectedUsers !== nextProps.selectedUsers ||
      selectedGroups !== nextProps.selectedGroups
    ) {
      console.log(nextProps.selectedCompanies, nextProps.selectedGroups)
      searchUsers(
        _.pluck(nextProps.selectedCompanies, "id").concat(_.pluck(nextProps.selectedGroups, "company_id")),
        _.pluck(nextProps.selectedDepartments, "id"),
        _.pluck(nextProps.selectedUsers, "id")
      );
    }
  }

  _fetchDependencies = async () => {
    const { fetchConnections, fetchUser, user, incentive, fetchIncentive, match } = this.props;

    if (!user) {
      await fetchUser(getUser().id);
    }

    // if(incentive&&match.params.id && match.params.id !==incentive.id){
    //   await fetchIncentive(match.params.id)
    // }
    fetchConnections({ company_id: getUser().company_id, status: 'active', from:"newPost" });//only share with your active connections
//    fetchConnections({ company_id: getUser().company_id, post: true });
    // if(match.params.id && incentive){
    //   console.log(this._searchAudienceFilters())
    //   console.log(incentive, "theIncentive")
    //   const audience = this._searchAudienceFilters();
    //   console.log(audience)
    //   let users = audience.filter(item=>item.type==='person'&&incentive.user_ids.includes(item.id))
    //   console.log(users, "Users")
    //   // users.forEach(user=>this._selectRow(user.id, 'person', user.company_id))
    // }
  };

  _search = async input => {
    const {
      searchCompanies,
      searchDepartments,
      searchGroups,
      searchConnectionUsers,
      user
    } = this.props;

    const {is_partner_incentive} = this.state;

    if (!user) return;

    searchCompanies(input, user.company.paid, true, null, [], false, is_partner_incentive);

    if(!is_partner_incentive){

      searchDepartments(input, user.company.paid, true);
      searchGroups(input, user.company.paid, true);
      searchConnectionUsers(input, null, user.company.paid, true);
    }
  };

  _searchAudienceFilters = () => {
    const { companyRows, departmentRows, userRows, groupRows, selectedRows } = this.props;

    
    let rows = userRows.concat(departmentRows, companyRows , groupRows );

    const selectedRowIds = selectedRows.map(slrow=>slrow.id);
    
    // we sort to push selected rows to the bottom
    rows = rows.sort(row=>{
      if(selectedRowIds.includes(row.id)) return 1;
      return -1;
    });

    
    
    return rows;
  };
//selectedAudienceMultiIds

	
_removeAudience = () => {
    
  const {
    selectedRows,
    clearRows
  } = this.props;
  
  //console.log(selectedRows);
  clearRows();
  this._updateSearch("");
}
  _updateSearch = input => {

   
  //console.log(AudienceMultiRowGroup);


    this.setState({ audienceSearchInput: input });
    //HELLO
    if (input.length < 2){
      if(input.length != 0){
        return;
      }    
    }
    this._search(input);
  };

  _selectRow = (itemId, itemType, companyId) => {
    const {
      selectedCompanies,
      selectedDepartments,
      selectedGroups,
      selectRow,
      selectedRows,
      selectedUsers
    } = this.props;
    
    const rowTypes = {
      company: selectedCompanies,
      department: selectedDepartments,
      person: selectedUsers,
      group: selectedGroups
    };
    console.log(itemId, itemType, companyId)
    
    selectRow(
      itemId,
      rowTypes[itemType],
      itemType,
      selectedRows,
      companyId,
      _.findWhere(this._searchAudienceFilters(), { id: itemId })
    );
  };

  _addAllIndividuals = async (individuals)=>{

    for(let i=0; i<individuals.length; i++){

      const individual = individuals[i];

      if(i>0){
        await promiseTimeout(10);
      }

      this._selectRow(individual.id, individual.type, individual.company_id)

    }

  }
  

  

  render() {
    
    const {
      allConnections,
      audienceSearchResults,
      companies,
      connections, 
      selectedRows,
      selectedCompanies, 
      selectedDepartments, 
      selectedGroups, 
      selectedUsers,
      company,
      user,
      incentive,
      incentiveHovered,
    incentiveSelected } = this.props;

    const { audienceSearchInput, searchModeActive, is_partner_incentive } = this.state;
    const rewardConnections = connections ? connections.filter(connection=> connection.company && connection.company[is_partner_incentive ? 'can_receive_vendor_incentives' : 'can_receive_incentives']) : [];

    
   
    const allAudience = this._searchAudienceFilters();

    const selectedRowIds = selectedRows.map(slrow=>slrow.id);

    const unselectedRows = allAudience.filter(row=>!selectedRowIds.includes(row.id));

    let addAllRows = [];

    if(
      unselectedRows.every(audience=>audience.type==="person" && audience.row.is_single_user_company && audience.row.company_domain===unselectedRows[0].row.company_domain)
      && unselectedRows.length > 1
    ){
      addAllRows =  unselectedRows;
    }else{
     addAllRows = [];
    }
    
    const incentiveTypes= {
        increment: {
            title: "INCREMENT",
            definition: `${is_partner_incentive ? "Partner" : "Salesperson"} earns one reward for every unit sold, but the per unit reward fluctuates based on the total units sold.`
        },
        onetoone: {
            title: "ONE TO ONE",
            definition: `${is_partner_incentive ? "Partner" : "Salesperson"} earns one reward for every unit sold.`
        },
        tier: {
            title: "TIER",
            definition: `${is_partner_incentive ? "Partner" : "Salesperson"} earns rewards for the tier matching their total sales achieved.`
        },
        volume: {
            title: "VOLUME",
            definition: `${is_partner_incentive ? "Partner" : "Salesperson"} earns rewards based on the sales volume they achieve.`
        },
        quickpay: {
            title: "QUICK PAY",
            definition: `Directly reward a ${is_partner_incentive ? "partner" : "salesperson"} for an activity that doesn’t fit in the available sales incentive options.`
        }
    }
   

    
    if(Object.keys(incentiveTypes).includes(incentiveHovered) && !Object.keys(incentiveTypes).includes(incentiveSelected)){

      return (
        <Flex flexDirection="column">
          <Box height="40px" />
            
          <Flex
          justifyContent="center"
          >
              <NunitoSans
              fontSize="22px"
              fontWeight="bolder"
              color="#428BF4"
              >
              {incentiveTypes[incentiveHovered].title}
              </NunitoSans>
          </Flex>
          <IconBox
          fontSize="75px"
          color="#428BF4"
          pb={["15px"]}
          pt={["25px"]}
          >
            {incentiveHovered}
          </IconBox>
          <Flex
          mt="20px"
          >
              <NunitoSans
              color="#000000"
              fontSize="16px"
              >
             {incentiveTypes[incentiveHovered].definition}
              </NunitoSans>
          </Flex>
          
        </Flex>
      );
    }

    if(Object.keys(incentiveTypes).includes(incentiveSelected)){
      return (
        <Flex justifyContent="center" flexDirection="column" width="100%">
     

          <Box mt="35px" width="100%">
            <NunitoSans textAlign="center" fontWeight={700} fontSize="18px">
              {incentiveSelected==='quickpay' ? "ADD PARTICIPANTS":"AUDIENCE"}
            </NunitoSans>
          </Box>
          <Flex mt="26px" mb="40px" width="100%" flexDirection="column">
            <MegaFlexBubble
              bg="white"
              borderRadius="10px"
              pl={["5px", "12px", "15px"]}
              pt={["5px", "12px", "15px"]}
              pr={["5px", "12px", "15px"]}
              pb={"0px"}
              flexDirection="column"
            >
              {companies && connections ? (
                <Flex
                  alignItems="center"
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  flexWrap={["no-wrap"]}
                >
                  <Box width={["48%", "48%", "45%"]}>
                    <CompanyInfographic
                      companiesActive={
                        allConnections
                          ? incentiveSelected==='quickpay' && !selectedRows.length ? 0 : (companyTotal(rewardConnections, user))
                          : audienceSearchResults &&
                            audienceSearchResults.companies
                      }
                      companiesTotal={companyTotal(rewardConnections, user) || 0}
                    />
                  </Box>
                  <Box width={["48%", "48%", "45%"]}>
                    <PeopleInfographic
                      peopleActive={
                        allConnections
                          ? incentiveSelected==='quickpay' && !selectedRows.length ? 0 : peopleTotal(rewardConnections, user)
                          : audienceSearchResults &&
                            audienceSearchResults.people
                      }
                      peopleTotal={peopleTotal(rewardConnections, user)||0}
                    />
                  </Box>
                </Flex>
              ) : null}
              {!searchModeActive ? (
                <Box position="relative" mt="23px">
                  <OneSixDash
                    position="absolute"
                    top="0px"
                    left="0px"
                    right="0px"
                    height="1px"
                  />
                  <AudienceRow
                    color={"#ffffff"}
                    key={0}
                    active={true}
                    firstRow={true}
                    lastRow={true}
                    hideFirstLastDash={true}
                    mobile={true}
                    hideAdd={true}
                    name="All Connections"
                    companyNumber={`${
                      allConnections
                        ? companyTotal(rewardConnections, user)
                        : companyTotal(rewardConnections, user)
                    } Companies`}
                  />
                </Box>
              ) : (
               
                <Box position="relative" mt="23px">
                  
                  {(selectedRows.length) ? (
                    <Flex
                    maxHeight="35vh"
                    flexDirection="column"
                    // justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    style={{overflow:"auto"}}
                  >
                  
                    <DashboardSmallCTA
                width="40%"
                onClick={() => this._removeAudience()}
                minWidth={["120px"]}
                fontSize="17px"
                innerHeight="40px"
                // my="12px"
                // height="34px"
              >
                CLEAR ALL
              </DashboardSmallCTA>
                                            
                      <AudienceMultiRowGroup
                        audiencesMulti={selectedRows}
                        selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                        mobile={true}
                        hideHeader={true}
                        hideFirstLastDash={true}
                        onSelect={this._selectRow}
                      />
                    </Flex>
                  ) : (
                    <AudienceRow
                      color={"#ffffff"}
                      key={0}
                      active={true}
                      firstRow={true}
                      lastRow={true}
                      hideFirstLastDash={true}
                      mobile={true}
                      hideAdd={true}
                      name="All Connections"
                      companyNumber={`${
                        allConnections
                          ? companyTotal(rewardConnections, user)
                          : companyTotal(rewardConnections, user)
                      } Companies`}
                    />
                  )}
                </Box> 
              )}
            </MegaFlexBubble>
          </Flex>
          { ((user.company.paid===true)||(user.company.paid==="true")) || true  ? (
          <div>
          {searchModeActive ? (
            <DashboardSmallCTA
            color='#FE4A49'
            style={{marginBottom:'10px'}}
            innerHeight="50px"
            fontSize="16px"
            fontWeight="bold"
            onClick={()=>this.setState({ searchModeActive: false })}
            >
              Close Audience Filter
            </DashboardSmallCTA>
          ) : (
          
              <Flex
                height="50px"
                // flexDirection="column"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => this.setState({ searchModeActive: true })}
                style={{border: '1px dashed #428BF4', borderRadius:'8px'}}
                className="audienceFilter"
              >
                <IconBox className="audienceText" fontSize="22px" mb="4px" color="#428BF4" mr="10px">
                  search
                </IconBox>
                <NunitoSans
                className="audienceText"
                  mb="4px"
                  fontSize="16px"
                  fontWeight="bold"
                  color="#428BF4"
                  textAlign="center"
                >
                  Audience Filter 
                </NunitoSans>
              </Flex>
            // </FourFourDottedWith8pxCornerRadius>
          )}
          {searchModeActive ? (
            <Flex
              mt="1px"
              mb="25px"
              mx={["0px"]}
              height={["49px"]}
              width={"100%"}
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Flex position="relative" width={["100%"]}>
                <InputStyled
                  placeholder="Search Audience"
                  onChange={e => this._updateSearch(e.target.value)}
                  innerRef={input => (this.searchInput = input)}
                  value={audienceSearchInput || ""}
                  width="100%"
                  name="post-new-search"
                  type="text"
                  border={0}
                  autoFocus={true}
                  bg={["lightGray"]}
                  borderRadius={"6px"}
                  pt={["3px"]}
                  px={["16px"]}
                  mt={["0px"]}
                  height={["46px"]}
                  fontWeight={300}
                  fontSize={[18]}
                  lineHeight={1.3}
                  color={"#979797"}
                  borderBottom={[0]}
                />
                <AbsoluteDiv
                  display={["block"]}
                  right={["20px"]}
                  bottom={"0px"}
                  top={["0px"]}
                  width={["17px"]}
                >
                  <ClickCursorFlex
                    height={["100%", "100%", "100%"]}
                    alignItems={["center"]}
                    onClick={() => this._updateSearch("")}
                    justifyContent={["center"]}
                  >
                    <IconBox
                      cursor="pointer"
                      pl={["15px"]}
                      pb={["15px"]}
                      pt={["15px"]}
                      fontSize={["17px"]}
                      color="#c3c3c3"
                    >
                      thinx
                    </IconBox>
                  </ClickCursorFlex>
                </AbsoluteDiv>
              </Flex>
            </Flex>
          ) : null}

            {
               searchModeActive && addAllRows.length
                ?
                <DashboardSmallCTA
                onClick={()=>this._addAllIndividuals(addAllRows)}
                >
                  {`ADD ALL ${addAllRows.length} INDIVIDUALS`}
                </DashboardSmallCTA>
                :
                null
              }
            {searchModeActive ? (
            <Box mb="5px">
              {/* <AudienceMultiRowGroup
                audiencesMulti={this._searchAudienceFilters()}
                selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
                groupName="SEARCH RESULTS"
                mobile={true}
                hideHeader={true}
                hideFirstLastDash={true}
                onSelect={this._selectRow}
                onlyPeople={incentiveSelected==='quickpay'}
              /> */}

               <AudienceList
              audiencesMulti={is_partner_incentive ? allAudience.filter(audience=>audience.type==='company') : incentiveSelected==='quickpay' ? allAudience.filter(audience=>audience.type==='person')  : allAudience}
              selectedAudienceMultiIds={_.pluck(selectedRows, "id")}
              groupName="SEARCH RESULTS"
              mobile={true}
              hideHeader={true}
              hideFirstLastDash={true}
              onSelect={this._selectRow}
              onlyPeople={incentiveSelected==='quickpay' && !is_partner_incentive}
              />
            </Box>
          ) : null}
          </div>
          ) : null}
        </Flex>
      );
    }



    return null;
    
  }
}


export default connect(
  state => ({
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length &&
      !state.post.selectedGroups.length,
    audienceSearchResults: state.search.audienceSearchResults,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    connections: state.resources.collection.connection || [],
    departmentRows: state.search.departmentSearchRows,
    groupRows: state.search.groupSearchRows,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    user: state.resources.detail.user,
    incentive: state.resources.detail.incentive,
    userRows: state.search.userSearchRows,
    incentiveHovered: state.incentives.incentiveHovered ,
    incentiveSelected: state.incentives.incentiveSelected
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchIncentive: bindActionCreators(actions.fetchIncentive, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchGroups: bindActionCreators(
      searchConnectionGroups,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    clearRows: bindActionCreators(clearRows, dispatch)
  })
)(ManageIncentiveAccessory);




class IncentiveDetailAccessory extends Component {
  state = {
    audienceSearchInput: null,
    searchModeActive: false,
    cancelIncentiveOpen: false
  };

  getFinalUploadDate = (endDate)=>{
    const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

    
    const dueDate = moment(endDate);
    const month = dueDate.month();
    const date = dueDate.date();
    const getStatementMonth = (nextMonth = false) => {
      return parseInt(this.props.systemSettings.statement_dates[MONTHS[(month + nextMonth ? 1 : 0)%12]])
    }

    if(date < getStatementMonth()){
      return moment(dueDate).date(getStatementMonth()).subtract(1, 'days').format('L');
    }else{
      return moment(dueDate).add(1, "months").date(getStatementMonth(true)).subtract(1, 'days').format('L');
    }
  }

  _cancelIncentive = async incentive_id => {
    const { notifyError, notifySuccess, updateIncentive, redirect} = this.props;

    const incentiveNote = document.querySelector('#incentive_note').value;


    if(!incentiveNote) return notifyError('A REASON FOR CANCELLATION IS REQUIRED');

    if(incentiveNote.trim().length<5) return notifyError("REASON OF CANCELLATION SHOULD HAVE AT LEAST 5 CHARACTERS.");

    const response = await updateIncentive(incentive_id, {status:'cancelled', note: incentiveNote.trim(), voider_user_id: getUser().id});

    

    if(response.payload && response.payload.id){
      this.setState({cancelIncentiveOpen: false});
      notifySuccess("INCENTIVE IS CANCELLED.");
      return redirect('/dashboard');
    }


  }

  
  render() {
    
    const {
      allConnections,
      audienceSearchResults,
      companies,
      connections, 
      selectedRows,
      selectedCompanies, 
      selectedDepartments, 
      selectedGroups, 
      selectedUsers,
      company,
      user,
      incentiveHovered,
    incentiveSelected,
  redirect,
match,
incentive,
updateIncentive,
systemSettings
 } = this.props;
    const { audienceSearchInput, searchModeActive,cancelIncentiveOpen } = this.state;

    // console.log( incentive ? new Date(incentive.end_date).getDate() : "")
    
    if(!incentive) return null;

    return(
      <Flex flexDirection="column">
          <Box height="40px" />
          <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          >
            <NunitoSans
            fontSize="18px"
            fontWeight='bold'
            color="#000000"
            >
              ACTIONS
            </NunitoSans>
          </Flex>
          <Box height="28px" />
          <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          style={{border: "1px dashed #A3AA9E", borderRadius: "12px"}}
          >
            <NunitoSans
            fontSize="14px"
            fontWeight="bold"
            color="#000000"
            textAlign="center"
            style={{marginBottom: "16px", marginTop: "13px"}}
            >
            Results current as of {<><span style={{color:'#428BF4'}}>{moment().format("L")}</span><br/></>} Rewards are non-final
            </NunitoSans>
            {incentive&&incentive.company_id===getUser().company_id&&getUser().company_admin ? 
            <NunitoSans
            fontSize="14px"
            fontWeight="bold"
            color="#000000"
            textAlign="center"
            style={{marginBottom: "13px"}}
            >
            Deadline Date for upload: {<span style={{color:'#FE4A49'}}>{this.getFinalUploadDate(incentive.end_date)}</span>}
            </NunitoSans>
            : null}

          </Flex>
          {(incentive&& incentive.user_id === getUser().id) || (incentive && systemSettings.incentive_admin_deactivation && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().system_admin && incentive.status!=='closed') ?
          <>
          <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          style={{border: "1px dashed #A3AA9E", borderRadius: "12px", marginTop: "25px"}}
          >
            {incentive&&incentive.type!=='quickpay' && incentive.user_id === getUser().id ?
            <Flex
            width="80%"
            justifyContent="space-evenly"
            style={{marginTop: "22px"}}
            >
            <DashboardSmallCTA
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px"]}
                color="#428BF4"
                fontSize="13px"
                onClick={()=>redirect(`/dashboard/incentives/duplicate/${match.params.id}?type=${incentive.is_partner_incentive ? 'partner' : 'sales'}`)}
              >
                DUPLICATE
              </DashboardSmallCTA>
             {
               incentive.status!=='closed'
               ?
               <DashboardSmallCTA
               mt={["10px", 0]}
               mb={["24px", 0]}
               mr={[0, "0"]}
               width={["140px"]}
               color="#428BF4"
               fontSize="13px"
               onClick={()=>redirect(`/dashboard/incentives/edit/${match.params.id}?type=${incentive.is_partner_incentive ? 'partner' : 'sales'}`)}
             >
               EDIT
             </DashboardSmallCTA>
               :
               null
             }
            </Flex>
            :
            null}

            {
              cancelIncentiveOpen 
              ?
              <Flex
           flexDirection="column"
           style={{width:"80%", border: "1px solid #FE4A49", borderRadius:"8px", boxShadow:"-4px 3px 6px #00000029", padding:"15px 20px", marginTop:'15px', marginBottom: '10px'}}
           >
             <NunitoSans
             fontSize="13px"
             fontWeight="bolder"
             color="#FE4A49"
             style={{width:"100%", textAlign:"center", marginBottom:"15px"}}
             >
             CANCEL INCENTIVE
             </NunitoSans>

             <NunitoSans
             fontSize="15px"
             fontWeight="bold"
             color="#000000"
             >
               Why are you cancelling this incentive? <span style={{color:"#FE4A49"}}>*</span>
             </NunitoSans>
            
               {/* <Field
               name="void_inv_note"
               component="textarea"
               placeholder="Add a description..."
               rows="6"
               style={{ padding:"2.5px 2.5px", border:"1px dashed #A3A99E", borderRadius:"6px", width:"100%"}}
               /> */}
               <textarea
               id="incentive_note"
               placeholder="Add a description"
               rows="6"
               style={{ padding:"2.5px 2.5px", border:"1px dashed #A3A99E", borderRadius:"6px", width:"100%"}}
               />

             
             <Flex
             justifyContent="space-between"
             style={{marginTop:"20px"}}
             >
               <DashboardSmallCTA
               onClick={()=>this._cancelIncentive(match.params.id)}
               fontSize="13px"
               color="#FE4A49"
               style={{width:"45%"}}
               >
               CONFIRM
               </DashboardSmallCTA>
               <DashboardSmallCTA
               onClick={()=>this.setState({cancelIncentiveOpen: false})}
               fontSize="13px"
               color="#428BF4"
               style={{width:"45%",marginRight:"-5px"}}
               >
               CANCEL
               </DashboardSmallCTA>
             </Flex>

           </Flex>
              :
              <Flex
            width="80%"
            justifyContent="space-evenly"
            style={{marginBottom: "22px", marginTop: "22px"}}
            
            >
              {
                incentive.status === 'closed' && incentive.user_id === getUser().id
                ?
                <DashboardSmallCTA
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px"]}
                color="#428BF4"
                fontSize="13px"
                onClick={()=> redirect(`/dashboard/rewards?target=invoices&incentiveId=${incentive.incentive_id}`)}
              >
                VIEW INVOICE
              </DashboardSmallCTA>
                :
                null
              }
              
               {
                 incentive.status==='active'
                 ?
                 <DashboardSmallCTA
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "0"]}
                width={["140px"]}
                color="#FE4A49"
                fontSize="13px"
                onClick={()=>this.setState({cancelIncentiveOpen: true})}
                // onClick={()=>updateIncentive(match.params.id, {status:'cancelled'})}
              >
                CANCEL
              </DashboardSmallCTA>
                 :
                 null
               }
              
            </Flex>

            }
            

          </Flex>
          </>
          :
          null}
          <Box height="28px" />
          
      </Flex>
    );
  }
}


IncentiveDetailAccessory = connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    allConnections:
      !state.post.selectedCompanies.length &&
      !state.post.selectedDepartments.length &&
      !state.post.selectedUsers.length &&
      !state.post.selectedGroups.length,
    audienceSearchResults: state.search.audienceSearchResults,
    companies: state.search.companySearchResults,
    companyRows: state.search.companySearchRows,
    connections: state.resources.collection.connection || [],
    departmentRows: state.search.departmentSearchRows,
    groupRows: state.search.groupSearchRows,
    selectedCompanies: state.post.selectedCompanies,
    selectedDepartments: state.post.selectedDepartments,
    selectedGroups: state.post.selectedGroups,
    selectedRows: state.post.selectedRows,
    selectedUsers: state.post.selectedUsers,
    user: state.resources.detail.user,
    userRows: state.search.userSearchRows,
    incentive: state.resources.detail.incentive,
    incentiveHovered: state.incentives.incentiveHovered ,
    incentiveSelected: state.incentives.incentiveSelected
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
    setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
    setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
    searchDepartments: bindActionCreators(
      searchConnectionDepartments,
      dispatch
    ),
    searchGroups: bindActionCreators(
      searchConnectionGroups,
      dispatch
    ),
    searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
    searchUsers: bindActionCreators(searchUsers, dispatch),
    selectRow: bindActionCreators(selectRow, dispatch),
    clearRows: bindActionCreators(clearRows, dispatch),
    redirect: bindActionCreators(push, dispatch),
    updateIncentive: bindActionCreators(actions.patchIncentive, dispatch)
  })
)(IncentiveDetailAccessory);

export {IncentiveDetailAccessory}



function promiseTimeout (time) {
  return new Promise(function(resolve,reject){
    setTimeout(function(){resolve(time);},time);
  });
};