import React, {Component} from "react";

import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


const QuestionTooltip = (props)=>{

    return (
        <>
        <ReactTooltip
        
        />
        <FontAwesomeIcon
         icon={faQuestionCircle}
         size={props.size || 'sm'}
         color="#428BF4"
         data-tip={props.tooltipText}
         data-type="info"
         data-multiline={true}
         style={{marginLeft:'5px'}}
        />
        </>
    );

}

export default QuestionTooltip;