import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reset  } from "redux-form";
import moment from "moment";
import AWS from "aws-sdk";
import { CSVLink } from "react-csv";
import { push } from "react-router-redux";
import { history } from "../../../store";
import { getUser, makeAuthHeader } from "../../../utils/auth";
import { EMAIL_REGEX } from "../../../constants/app";
//components
import { NunitoSans } from "../../common/typography";
import { Flex } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { DashboardSmallCTA } from "../../common/forms";
import { BackButton } from "../../common/backbutton";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

import { setIncentiveHovered, setIncentiveSelected, setRowSelected, setCsvDropOpen } from "../../../actions/incentives";
import {
  searchConnectionCompanies,
  searchConnectionDepartments,
  searchConnectionUsers
} from "../../../actions/search";

import {
  
  NavNotificationBubble
} from "../../common/ui";
import RouteLeavingGuard from "../../common/routeGuardModal";
import CreateBonusModal from "./createBonusModal";




export class ManageSystemReportsMain extends Component {
  blockNavigation = true;

    state ={
      lastRewardRun: null,
      upgradeCompanyValue : "",
      assignOwnershipValue: "",
      setTypeValue: "Reseller",
      setTypeDomainValue: "",
      freeTrialDomainValue: "",
      freeTrialDaysValue: 30,
      isBonusModalOpen: false
    }

  componentDidMount(){
      this._fetchDependencies();
  }
  
  
  _fetchDependencies = async () => {
      const {systemSettings, fetchSettings} =this.props;
        if(getUser().company_id!==process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID || !getUser().system_admin) return history.push('/dashboard');

        if(!systemSettings){
          fetchSettings(process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID);
        }

        this._fetchLambdaRewardLogs();

        
    }

    _fetchLambdaRewardLogs = ()=>{
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: "us-east-1"
      });
      
     const cloudWatchLogs = new AWS.CloudWatchLogs();

     const yesterday = moment().subtract(1, 'days');

     cloudWatchLogs.filterLogEvents({
      logGroupName: `/aws/lambda/${process.env.REACT_APP_NODE_ENV}-reward-calc-controller`,
      startTime: yesterday.valueOf()
     }, (err, data)=>{
        if (err) console.log(err, err.stack, 'AWS DATA'); // an error occurred
        else{
          // successful response
          
          let lastEvent = data.events.sort((a,b)=>b.timestamp-a.timestamp)[0]; 
          const lastRewardRun = moment(lastEvent.timestamp).fromNow();
          this.setState({lastRewardRun});
        }    
      })
    }

    _fetchRequestedReport = async (reportName)=>{

      const {notifyError} = this.props;

      this.setState({[reportName+"Data"]: "fetching"});

      try{
        var moment2 = require('moment-timezone');
        moment2.tz.setDefault("America/Denver");

        const data = await fetch(`${process.env.REACT_APP_API_URL}/${reportName}`).then(res=>res.json());

        let theData = [...data];

        if(reportName==="company_invitee_report"){
          theData.forEach(invitee=>{
            if(invitee.joined_at){     
              invitee.joined_atHour=moment2(invitee.joined_at).format('h:mma z');
              invitee.joined_at=moment2(invitee.joined_at).format("YYYY-MM-DD");
              console.log("invitee.joined_at",invitee.joined_at)
              // invitee.joined_at=moment(invitee.joined_at).format("dddd, MMMM Do YYYY, h:mm:ss a");
            }
            invitee.created_atHour=moment2(invitee.created_at).format('h:mma z');
            invitee.created_at=moment2(invitee.created_at).format("YYYY-MM-DD");  
            // invitee.created_at=moment(invitee.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a");
            invitee.full_name=invitee.first_name+' '+invitee.last_name;
          });
        }

        if(reportName==="connections_report"){
          theData.forEach(conn=>{
            conn.created_atHour=moment2(conn.created_at).format('h:mma z');
            conn.created_at=moment2(conn.created_at).format("YYYY-MM-DD");            
            // conn.created_at=moment(conn.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a");
            conn.inviter_full_name=conn.inviter_first_name+' '+conn.inviter_last_name;
            conn.status = conn.status === 'active' ? "accepted" : 'pending';
          });
        } 

        if(reportName==="users_report"){
          theData.forEach(conn=>{
            conn.created_atHour=moment2(conn.created_at).format('h:mma z');
            conn.created_at=moment2(conn.created_at).format("YYYY-MM-DD");
          });
        } 

        console.log("theData",theData)
        setTimeout(()=>{
          moment2.tz.setDefault();
          this.setState({[reportName+"Data"]: theData});
          setTimeout(()=>{
            const button =  document.getElementById(reportName);
          button.click();
          },100)
        }, 1000);



      }catch(err){
        this.setState({[reportName+"Data"]: null});
        notifyError("Something went wrong. Please try again later.");
        moment2.tz.setDefault();
      }

      


    }

    _runCalc = async()=>{

      const {notifySuccess}=this.props;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/manualrewardcalc`,
        {
          method: 'POST',
          headers: makeAuthHeader()
        }
        ).then(res=>res.json());

        if(response.status){
          notifySuccess("Manual reward calculation is initiated.")
        }

    }

    _upgradeToCompany = async()=>{
      const {notifySuccess, notifyError}=this.props;

      let domain = this.state.upgradeCompanyValue.trim().toLowerCase();

      this.setState({upgradeCompanyValue:""});
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mergecompanies`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            domain
        })
        }
        ).then(res=>res.json());

        if(response.id){
         
          return notifySuccess(`Merge process has started. It may take a few minutes for changes to take place.`);
        }

        if(response.error){
          notifyError(response.error);
        }
    }

    _assignOwnership = async()=>{
      
      const {notifySuccess, notifyError}=this.props;
      
      let email = this.state.assignOwnershipValue.trim();
      
      let re = new RegExp(EMAIL_REGEX, "i");
      
      if (!re.test(email)) {
        return notifyError("Not a valid email");
      }
      
      this.setState({assignOwnershipValue:""});
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/assignownership`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            email
        })
        }
        ).then(res=>res.json());

        if(response.status){
         
          return notifySuccess(`Ownership is assigned to ${email}`);
        }

        if(response.error){
          notifyError(response.error);
        }
    }

    _setType = async()=>{
      const {notifySuccess, notifyError}=this.props;

      let type = this.state.setTypeValue.trim();
      let domain = this.state.setTypeDomainValue.trim().toLowerCase();
      
      this.setState({setTypeDomainValue:"", setTypeValue:"Reseller"});

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/settype`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            domain,
            type
        })
        }
        ).then(res=>res.json());

        if(response.status){
         
          return notifySuccess(`Company type is set to ${type}`);
        }

        if(response.error){
          notifyError(response.error);
        }
    }

    _setFreeTrial = async()=>{
      const { freeTrialDomainValue, freeTrialDaysValue } = this.state;
      const {notifySuccess, notifyError}=this.props;

      const domainRegex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);

      if(!domainRegex.test(freeTrialDomainValue)){
        return notifyError("Invalid domain name");
      }

      if(freeTrialDaysValue < 1){
        return notifyError("Free trial days cannot be lower than 1.");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/freetrials`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            domain: freeTrialDomainValue,
            days: freeTrialDaysValue,
            given_by: getUser().id
        })
        }
        ).then(res=>res.json());

        if(response.id){
          this.setState({freeTrialDaysValue: 30, freeTrialDomainValue: ""});
          return notifySuccess(`${freeTrialDaysValue} days of Free Trial added for ${freeTrialDomainValue}`);
        }

        if(response.error){
          return notifyError(response.error);
        }
    }

    _handleCreateBonus = async(payload) => {
      const {notifySuccess, notifyError}=this.props;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/createbonus`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...makeAuthHeader()
          },
        body: JSON.stringify({
            ...payload,
            requester_id: getUser().id
        })
        }
        ).then(res=>res.json());

        if(response && response.success){
          return notifySuccess("KickPost Bonus created successfully.");
        }else if(response && response.error){
          return notifyError(response.error);
        }else {
          return notifyError("Something went wrong. Please try again another time.");
        }
    };
 
    render(){

      const {lastRewardRun, isBonusModalOpen} = this.state;

      const FUNCTIONS = [
        {
          name: "manualCalc",
          title: 'Manual Rewards Calculation',
          description: 'Triggers rewards calculation',
          method: this._runCalc
        },
        {
          name: "upgradeCompany",
          title: 'Upgrade To Company',
          description: 'Upgrades individual accounts to a company account by domain.',
          inputType: "text",
          placeholderText: "Ex. abcde.com",
          method: this._upgradeToCompany
        },
        {
          name: "assignOwnership",
          title: 'Assign Ownership',
          description: 'Assigns ownership by email',
          inputType: "email",
          placeholderText: "Ex. xyz@abcde.com",
          method: this._assignOwnership
        },
        {
          name: "setType",
          title: 'Set Company Type',
          description: 'Set company type by domain',
          inputType: "select",
          method: this._setType,
          options: [
            {
              value: "Reseller",
              text: "RESELLER"
            },
            {
              value: "Vendor",
              text: "VENDOR"
            }
          ]
        },
        {
          name: "freeTrial",
          title: 'Set Free Trial',
          description: 'Set free trial by domain',
          inputType: "custom",
          method: this._setFreeTrial
        },
        {
          name: "createBonus",
          title: 'KickPost Bonus',
          description: 'Create a KickPost Bonus',
          inputType: "custom",
          method: () => this.setState({isBonusModalOpen: true})
        }
      ];
      
     
      const REPORTS = [
        
        {
          title:'INVITEE REPORT',
          reportName: 'company_invitee_report',
          description: 'Get Invitee report',
          csvHeaders: [
            {
              label: 'Invitee Name',
              key: 'full_name'
            },
            {
              label: 'Invitee Email',
              key: 'email'
            },
            {
              label: 'Inviter Name',
              key: 'inviter_user_name'
            },
            {
              label: 'Inviter Company',
              key: 'inviter_company_name'
            },
            {
              label: 'Invite Date',
              key: 'created_at'
            },
            {
              label: 'Invite Time',
              key: 'created_atHour'
            },
            {
              label: 'Joined Date',
              key: 'joined_at'
            },
            {
              label: 'Joined Time',
              key: 'joined_atHour'
            }
          ]
        },
        {
          title:'CONNECTIONS REPORT',
          reportName: 'connections_report',
          description: `Get Connections report`,
          csvHeaders: [
            {
              label: 'Requester Name',
              key: 'inviter_full_name'
            },
            {
              label: 'Requester Company Name',
              key: 'inviter_company_name'
            },
            {
              label: 'Requester Email',
              key: 'inviter_email'
            },
            {
              label: 'Requestee Company Name',
              key: 'invited_company_name'
            },
            {
              label: 'Request Date',
              key: 'created_at'
            },
            {
              label: 'Request Time',
              key: 'created_atHour'
            },
            {
              label: 'Request Status',
              key: 'status'
            }
          ]
        },
        {
          title:'USERS REPORT',
          reportName: 'users_report',
          description: `Get active users report`,
          csvHeaders: [
            {
              label: 'Company Name',
              key: 'company_name'
            },
            {
              label: 'First Name',
              key: 'first_name'
            },
            {
              label: 'Last Name',
              key: 'last_name'
            },
            {
              label: 'Email',
              key: 'email'
            },
            {
              label: 'Role',
              key: 'role'
            },
            {
              label: 'Referrer ID',
              key: 'referrer_id'
            },
            {
              label: 'Referrer Name',
              key: 'referrer_name'
            },
            {
              label: 'Referrer Email',
              key: 'referrer_email'
            },
            {
              label: 'Date',
              key: 'created_at'
            },
            {
              label: 'Date Time',
              key: 'created_atHour'
            }
          ]
        },
        {
          title:'COMPANIES REPORT',
          reportName: 'companies_report',
          description: `Get active companies report`,
          csvHeaders: [
            {
              label: 'Domain',
              key: 'domain'
            },
            {
              label: 'Name',
              key: 'name'
            },
            {
              label: 'Paid',
              key: 'paid'
            },
            {
              label: 'Account Type',
              key: 'company_type'
            },
            {
              label: 'Company Goal',
              key: 'company_goal'
            },
            {
              label: 'Industry 1',
              key: 'industry_1'
            },
            {
              label: 'Industry 2',
              key: 'industry_2'
            },
            {
              label: 'Industry 3',
              key: 'industry_3'
            }
          ]
        },
        {
          title:'POSTS REPORT',
          reportName: 'posts_report',
          description: `Get active posts report`,
          csvHeaders: [
            {
              label: 'Title',
              key: 'title'
            },
            {
              label: 'Start Date',
              key: 'start_date'
            },
            {
              label: 'End Date',
              key: 'end_date'
            },
            {
              label: 'Company Name',
              key: 'company_name'
            },
            {
              label: 'User First Name',
              key: 'first_name'
            },
            {
              label: 'User Last Name',
              key: 'last_name'
            },
            {
              label: 'User Email',
              key: 'email'
            }
          ]
        },
        {
          title:'INCENTIVES REPORT',
          reportName: 'incentives_report',
          description: `Get active incentives report`,
          csvHeaders: [
            {
              label: 'Type',
              key: 'type'
            },
            {
              label: 'Title',
              key: 'title'
            },
            {
              label: 'Start Date',
              key: 'start_date'
            },
            {
              label: 'End Date',
              key: 'end_date'
            },
            {
              label: 'Company Name',
              key: 'company_name'
            },
            {
              label: 'User First Name',
              key: 'first_name'
            },
            {
              label: 'User Last Name',
              key: 'last_name'
            },
            {
              label: 'User Email',
              key: 'email'
            }
          ]
        }
      ];

       

        if(getUser().company_id!==process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID) return null;

        
        return <>
        <RouteLeavingGuard
            when={this.blockNavigation}
            navigate={path => history.push(path)}
            shouldBlockNavigation={location => {
              // This case it blocks the navigation when: 
              // 1. the login form is dirty, and 
              // 2. the user is going to 'sign-up' scene.
              //    (Just an example, in real case you might 
              //     need to block all location regarding this case)
              if (this.blockNavigation) {
                if (location.pathname === 'signup') {
                  return true
                }
              } 
              return true
          }}
            />

        <Flex
        flexDirection="column"
        style={{height: "82vh", overflowY: "auto", overflowX: 'hidden', paddingRight:'25px',  marginTop:'55px'}}
        >
         
            <Flex
            style={{borderBottom:'5px solid #D3D3D3', marginTop: '47px'}}
            >
              <NunitoSans
              fontSize="16px"
              color="#6C7168"
              fontWeight="bolder"
              >
                REPORTS
              </NunitoSans>
            </Flex>

            {
              REPORTS.map((report, index)=>{


                return (
                  <Flex
                alignItems='center'
                justifyContent='space-between'
                style={{borderBottom:'1px solid #D3D3D3', borderTop: index===0 ? '1px solid #D3D3D3' : "none"}}
                >
                  <Flex
                  flexDirection="column"
                  style={{marginTop:'13px', marginBottom:'13px'}}
                  >
                    <NunitoSans
                    fontSize='14px'
                    fontWeight='bold'
                    color='#10213A'
                    >
                      {report.title.toUpperCase()}
                    </NunitoSans>
                    <NunitoSans
                    fontSize='14px'
                    color='#000000'
                    >
                      {report.description}
                    </NunitoSans>
  
                  </Flex>
  
                  <Flex
                  flexDirection="column"
                  style={{marginTop:'13px', marginBottom:'13px'}}
                  >
                    <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    >

                      {
                       this.state[report.reportName+"Data"] && this.state[report.reportName+"Data"]!=='fetching' && this.state[report.reportName+"Data"].length>=0
                        ?
                        <DashboardSmallCTA
                        mr="10px"
                        minWidth={["122px"]}
                        fontSize="14px"
                        innerHeight="30px"
                        width="130px"
                        color="#428BF4"
                        >
                          <CSVLink
                          
                          id={report.reportName} 
                          filename={`${report.reportName}.csv`}
                          data={this.state[report.reportName+"Data"]}
                          headers={report.csvHeaders}
                    
                          >
                          DOWNLOAD
                          </CSVLink>
                        </DashboardSmallCTA>
                        // <CSVDownload
                        // filename={`${report.reportName}.csv`}
                        // data={this.state[report.reportName+"Data"]}
                        // headers={report.csvHeaders}
                        // target="_blank"
                        // />
                        :
                        <DashboardSmallCTA
                        mr="10px"
                        minWidth={["122px"]}
                        fontSize="14px"
                        innerHeight="30px"
                        width="130px"
                        onClick={()=> this._fetchRequestedReport(report.reportName)}
                        color="#428BF4"
                        disabled={this.state[report.reportName+"Data"]==='fetching'}
                        >
                          {this.state[report.reportName+"Data"]==='fetching' ? "FETCHING..." : "GET"}
                        </DashboardSmallCTA>

                      }
                      
                     
  
                    </Flex>
                    
  
                  </Flex>
  
                </Flex>
                );
              })
            }

            <Flex
            style={{borderBottom:'5px solid #D3D3D3', marginTop: '47px'}}
            >
              <NunitoSans
              fontSize="16px"
              color="#6C7168"
              fontWeight="bolder"
              >
                FUNCTIONS
              </NunitoSans>
            </Flex>

            <CreateBonusModal
            isOpen={isBonusModalOpen}
            handleModalClose={() => this.setState({isBonusModalOpen: false})}
            createBonus={this._handleCreateBonus}
            />

            {
              FUNCTIONS.map((func, index)=>{


                return (
                  <Flex
                alignItems='center'
                justifyContent='space-between'
                style={{borderBottom:'1px solid #D3D3D3', borderTop: index===0 ? '1px solid #D3D3D3' : "none"}}
                >
                  <Flex
                  flexDirection="column"
                  style={{marginTop:'13px', marginBottom:'13px'}}
                  >
                    <NunitoSans
                    fontSize='14px'
                    fontWeight='bold'
                    color='#10213A'
                    >
                      {func.title.toUpperCase()}
                    </NunitoSans>
                    <NunitoSans
                    fontSize='14px'
                    color='#000000'
                    >
                      {func.description}
                    </NunitoSans>
  
                  </Flex>


                  <Flex
                  alignItems="center"
                  >
                    {
                      func.inputType === "text" || func.inputType === "email"
                      ?
                      <input
                      id={func.name}
                      type={func.inputType}
                      placeholder={func.placeholderText || "Enter Value"}
                      onChange={(e)=>this.setState({[func.name+"Value"] : e.target.value})}
                      value={this.state[func.name+"Value"]}
                      required
                      style={{
                        fontSize:"14px",
                        fontFamily: "NunitoSans",
                        padding: "3px 5px",
                        border: '1px solid #707070',
                        color: '#000000'
                      }}
                      />
                      :
                      func.inputType === "select" && func.name ===  "setType"
                      ?
                      <Flex
                      alignItems="center"
                      >
                        <input
                        type={"text"}
                        placeholder={"Ex. abcde.com"}
                        onChange={(e)=>this.setState({setTypeDomainValue  : e.target.value})}
                        value={this.state.setTypeDomainValue}
                        required
                        style={{
                          fontSize:"14px",
                          fontFamily: "NunitoSans",
                          padding: "3px 5px",
                          border: '1px solid #707070',
                          color: '#000000',
                          marginRight: "5px"
                        }}
                        />
                        <select
                        id={func.name}
                        onChange={(e)=>this.setState({setTypeValue : e.target.value})}
                        value={this.state.setTypeValue}
                        style={{
                          fontSize:"14px",
                          fontFamily: "NunitoSans",
                          padding: "3px 5px",
                          border: '1px solid #707070',
                          color: '#000000'
                        }}
                        >
                          {
                            func.options.map(item => <option value={item.value} >{item.text}</option>)
                          }
                        </select>
                      </Flex>
                      :
                      func.name==="freeTrial"
                      ?
                      <Flex
                      alignItems="center"
                      >
                        <input
                        type={"text"}
                        placeholder={"Ex. abcde.com"}
                        onChange={(e)=>this.setState({freeTrialDomainValue : e.target.value.toLowerCase().trim()})}
                        value={this.state.freeTrialDomainValue}
                        required
                        style={{
                          fontSize:"14px",
                          fontFamily: "NunitoSans",
                          padding: "3px 5px",
                          border: '1px solid #707070',
                          color: '#000000',
                          marginRight: "5px"
                        }}
                        />
                        <NunitoSans>for</NunitoSans>
                        <Flex>
                          <input
                          type={"number"}
                          placeholder={"Number of free trial days"}
                          onChange={(e)=>this.setState({freeTrialDaysValue : parseInt(e.target.value)})}
                          value={this.state.freeTrialDaysValue}
                          required
                          style={{
                            fontSize:"14px",
                            fontFamily: "NunitoSans",
                            padding: "3px 5px",
                            border: '1px solid #707070',
                            color: '#000000',
                            marginLeft: "5px",
                            marginRight: "5px",
                            textAlign: "center",
                            width: "50px"
                          }}
                          />
                          <NunitoSans>days</NunitoSans>

                        </Flex>
                      </Flex>
                      :
                      null
                    }

                    <Flex
                    flexDirection="column"
                    style={{marginTop:'13px', marginBottom:'13px', marginLeft: '10px'}}
                    >
                      <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      >

                          <DashboardSmallCTA
                          mr="10px"
                          minWidth={["122px"]}
                          fontSize="14px"
                          innerHeight="30px"
                          width="130px"
                          onClick={()=> func.method()}
                          color="#428BF4"
                          disabled={
                            (func.inputType && func.inputType!=="custom" && !this.state[func.name+"Value"].trim()) || 
                            (func.name==="setType" && !this.state.setTypeDomainValue) ||
                            (func.name==="freeTrial" && (!this.state.freeTrialDaysValue || !this.state.freeTrialDomainValue))
                          }
                          >
                            {func.name === "createBonus" ? "CREATE" : func.inputType ? "SUBMIT" : "RUN"}
                          </DashboardSmallCTA>
                      
    
                      </Flex>
                    {
                      func.name==='manualCalc'
                      ?
                      <NunitoSans
                      fontSize='14px'
                      color='#000000'
                      >
                        {`Last Run: ${lastRewardRun}`}
                      </NunitoSans>
                      :
                      null
                    }
    
                    </Flex>
                  </Flex>
  
  
                </Flex>
                );
              })
            }

          
        </Flex>
        </>
    }
}



  export default connect(
    state => ({
      browser: state.browser,
      
      company: state.resources.detail.company,
      loading: state.resources.detail.loading.post,
      post: state.resources.detail.post,
      sales: state.resources.collection.sale || [],
      rewards: state.resources.collection.reward || [],
      invoices: state.resources.collection.invoice || [],
      systemSettings: state.resources.detail.systemsetting,
      selectedUsers: state.post.selectedUsers,
      companyResults: state.search.companySearchResults,
      //companyResults: state.search.companySearchResults || [],
      selectedCompanies: state.post.selectedCompanies,
      selectedDepartments: state.post.selectedDepartments,
      selectedGroups: state.post.selectedGroups,
      user: state.resources.detail.user,
      connections: state.resources.collection.connection || [],
      incentiveHovered: state.incentives.incentiveHovered,
      incentiveSelected: state.incentives.incentiveSelected,
      rowSelected: state.incentives.rowSelected,
      csvDropOpen: state.incentives.csvDropOpen
    }),
    dispatch => ({
      setIncentiveHovered: bindActionCreators(setIncentiveHovered, dispatch),
      setIncentiveSelected: bindActionCreators(setIncentiveSelected, dispatch),
      setRowSelected: bindActionCreators(setRowSelected, dispatch),
      setCsvDropOpen: bindActionCreators(setCsvDropOpen, dispatch),
      searchCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
      searchDepartments: bindActionCreators(
        searchConnectionDepartments,
        dispatch
      ),
      searchConnectionUsers: bindActionCreators(searchConnectionUsers, dispatch),
      notifySuccess: bindActionCreators(
        success.bind(null, "api_success"),
        dispatch
      ),
      notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
      createSale: bindActionCreators(actions.createSale, dispatch),
      createSalesupload: bindActionCreators(actions.createSalesupload, dispatch),
      //searchCC: bindActionCreators(searchConnectedCompanies, dispatch),fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
      //searchConnectionCompanies: bindActionCreators(searchConnectionCompanies, dispatch),
      fetchSettings: bindActionCreators(actions.fetchSystemsetting, dispatch),
      fetchSales: bindActionCreators(actions.fetchSales, dispatch),
      fetchSalesuploads: bindActionCreators(actions.fetchSalesuploads, dispatch),
      fetchRewards: bindActionCreators(actions.fetchRewards, dispatch),
      fetchInvoices: bindActionCreators(actions.fetchInvoices, dispatch),
      fetchUser: bindActionCreators(actions.fetchUser, dispatch),
      fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
      //fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
      fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
      redirect: bindActionCreators(push, dispatch),
      resetForm: bindActionCreators(reset, dispatch),
      updateIncentive: bindActionCreators(actions.patchIncentive, dispatch),
      updateSale: bindActionCreators(actions.patchSale, dispatch),
      updateUser: bindActionCreators(actions.patchUser, dispatch),
      updateSettings: bindActionCreators(actions.patchSystemsetting, dispatch),
      fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    })
  )(ManageSystemReportsMain);




class ManageSystemReportsNav extends Component {
    state = {
      verificationModalOpen: false
    }

   

   
   
    render(){

      const {notificationCounts} = this.props;
     
        return <Flex
        height={"100%"}
        width={"100%"}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Flex
          height={["49px", "49px", "60px", "40px"]}
          width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          {
            getUser().system_admin && getUser().company_id===process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID
            ?
            <div style={{position:'relative'}}>
            <select
            id="system_settings_nav_dropdown"
            style={{padding:"10px 25px",border: '1px solid #428BF4',borderRadius: "9px", textAlign:"center", fontSize: '20px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#000000"}}
            onChange={e=>history.push(`/dashboard/${e.target.value}`)}
            >
              <option
               style={{textAlign:"center", width:"100%", fontSize: '20px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#A3A99E"}}
               value="systemreports"
              >
              SYSTEM REPORTS
              </option>
              <option
               style={{textAlign:"center", width:"100%", fontSize: '20px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#A3A99E"}}
               value="systemsettings"
              >
              {`SYSTEM SETTINGS${notificationCounts.settings ? ' ('+notificationCounts.settings+')': ''}`}
              </option>
              <option
               style={{textAlign:"center", width:"100%", fontSize: '20px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#A3A99E"}}
               value="settings"
              >
              ACCOUNT SETTINGS
              </option>

            </select>
            {
           notificationCounts.settings
            ?
            <NavNotificationBubble
            top="-5px"
            right="-5px"
            >
              {notificationCounts.settings || 0}
            </NavNotificationBubble>
            :
            null
          }
            </div>
            :
            <NunitoSans
            pt={["6px", "6px", "8px", "6px"]}
            fontSize={[14, 14, 18, 18]}
            fontWeight={["bold"]}
          >
            SYSTEM REPORTS
          </NunitoSans>

          }
          <AbsoluteDiv
            left={["0px", "0px", "0px", "0px", "-20%"]}
            bottom={"0px"}
            top={"0px"}
            width={["auto", "auto", "auto", "auto", "20%"]}
          >
            <Flex
              height={["100%", "100%", "100%"]}
              alignItems={["center"]}
              justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
            >
              <BackButton />
            </Flex>
          </AbsoluteDiv>
          
        </Flex>
        
      </Flex>
    }
}



ManageSystemReportsNav = connect(
  state => ({
    
    systemSettings: state.resources.detail.systemsetting,
    notificationCounts: state.resources.collection.notificationcount || {},
  }),
  dispatch => ({
   
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
  })
)(ManageSystemReportsNav);

export {ManageSystemReportsNav};
