import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmilingHands } from '@fortawesome/pro-duotone-svg-icons';


import { Flex } from "../../common/flexbox";
import { NunitoSans } from "../../common/typography";

export default ()=>(
        <Flex
        flexDirection="column"
        width="100%"
        style={{textAlign:"center"}}
        >
            <FontAwesomeIcon
            icon={faFaceSmilingHands}
            size="10x"
            color="#428BF4"
            style={{textAlign:"center"}}
            />

            <NunitoSans
            fontSize="24px"
            color="#FFFFFF"
            style={{marginTop: "50px"}}
            >
                <span 
                style={{fontWeight:900,}}
                >
                    Sign In
                </span><br />
                {` Enter your work email address to get going.`}
            </NunitoSans>

        </Flex>
);