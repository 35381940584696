import React from "react";
import { Field, reduxForm } from "redux-form";

import { renderField, SwitchInput } from "../../../../common/forms";
import { Flex, Box } from "../../../../common/flexbox";
import { NunitoSans, NunitoSansExtraBold } from "../../../../common/typography";
import {
  FourFourDottedWith8pxCornerRadius,
  
} from "../../../../common/ui";

const validate = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "First name required";
  }

  if (!values.last_name) {
    errors.last_name = "Last name required";
  }

  if (!values.position) {
    errors.position = "Position required";
  }

  return errors;
};

let Info = props => {
  const { handleSubmit, privacyToolOn } = props;
  return (
    <form onSubmit={handleSubmit} id="profile-form">
      <Field
        label="FIRST NAME"
        name="first_name"
        placeholder="Rob"
        component={renderField}
        type="text"
      />
      <Field
        label="LAST NAME"
        name="last_name"
        placeholder="Johnson"
        component={renderField}
        type="text"
      />
      <Field
        label="POSITION"
        name="position"
        placeholder="Sales"
        component={renderField}
        type="text"
      />

     
      {
        privacyToolOn ?
        <Flex
      flexDirection="column"
      style={{marginBottom: '20px'}}
      >
        <NunitoSans
        fontSize="14px"
        fontWeight="bold"
        color="#000000"
        >
          CONTACT PRIVACY
        </NunitoSans>
        <FourFourDottedWith8pxCornerRadius>
        <Flex
        justifyContent="space-between"
        alignItems="center"
        style={{padding:'8px 10px'}}
        >
          <NunitoSans
          fontSize="14px"
          >
            This will block other users from viewing your contact information (email, phone, and location).
          </NunitoSans>
          <Field
          name="profile_private"
          type="checkmark"
          component={SwitchInput}
          onColor='#2FD371'
          offColor='#D3D3D3'
        />

        </Flex>
        </FourFourDottedWith8pxCornerRadius>
      </Flex>
        :
        null
      }

      <NunitoSans
      color="#000000"
      style={{marginBottom: '20px'}}
      >
      Your contact information is hidden by default from users you are not connected to. Labels are required.
      </NunitoSans>
      
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  form: "profile-info",
  validate
})(Info);
