import React, {Component} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faQuestionCircle} from '@fortawesome/free-regular-svg-icons';

import {
    setInfoModalOpen
  } from "../../actions/rule";


let QuestionModalTrigger = (props)=>{

    return (
        
        <FontAwesomeIcon
         icon={faQuestionCircle}
        //  size={props.size || 'lg'}
         color="#428BF4"
         onClick={()=>props.setInfoModalOpen(props.modalName || "")}
         style={{marginLeft: props.marginLeft || '15px', cursor:'pointer', fontSize: props.size || '20px'}}
         
        />
        
    );

};

QuestionModalTrigger = connect(
    state => ({
      browser: state.browser,
     
    }),
    dispatch => ({
        setInfoModalOpen: bindActionCreators(setInfoModalOpen, dispatch),
    })
  )(QuestionModalTrigger);



export default QuestionModalTrigger;

