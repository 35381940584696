import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import _ from "lodash";

import { actions } from "../../../actions/resources";
import { error, success } from "../../../actions/alerts";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faShareAlt, faUserPlus, faNewspaper as faNewspaperLight, faPaperPlane as faPaperPlaneLight } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';
import { faSolarSystem } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faTimesCircle } from'@fortawesome/free-regular-svg-icons';

import {
 
  ImageTreatment
  
} from "../../common/ui";
import {defaultProfile, defaultCompany} from "../../../utils/defaultLogos";

import {
    
    Flex,
    IconBox
} from "../../common/flexbox";


import {
    NunitoSansBold,
    NunitoSans,
    NunitoSansSemiBold
} from "../../common/typography";

import {
    DashboardSmallCTA
} from "../../common/forms";


import KickpostRocket from "../../../images/kickpost_rocket.png";


let InitialLoginModal = props=>{

    const {user, isSingleUser, isMobile, pendingConnections, sponsoredBrands, industryCategories, updateConnection, createConnection, finalAction,  notifyError,  updateSettings, updateUser, companyGoal } = props;

    
    const [modalOpen, setModalOpen] = useState(false);
    // const [onboardingComplete, setOnboardingComplete] = useState(false);
    const [page, setPage] = useState(1);
    const [stripeOpen, setStripeOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(user&&user.company&&user.company.settings&&user.company.settings.industry_categories || []);
    const [selectedGoal, setSelectedGoal] = useState(user&&user.company&&user.company.settings&&user.company.settings.company_goal || '');
    const [errorMessage, setErrorMessage] = useState('');
  
    if(user && user.id && !modalOpen){

    // const isInitialLogin = !localStorage.getItem(`_kp_initial_login_${user.id}`);
    const isInitialLogin = !user.onboarding_completed;
    const termsApproved = Boolean(localStorage.getItem("kpPolicy"));

    // if(user.company_owner && isInitialLogin && termsApproved ) setModalOpen(true);

    }


    const handleModalDismissal = async (dismiss=false)=>{
        if(!dismiss) return;
        // localStorage.setItem(`_kp_initial_login_${user.id}`, 'complete');
        await updateUser(user.id, {onboarding_completed: true});
        setModalOpen(false);
        sessionStorage.setItem(`_kp_pending_connections_${user.id}`, 'complete');
        // if(selectedCategories.length && selectedGoal.length){
        //   updateSettings(user.company_id, {
        //     industry_categories: JSON.stringify(selectedCategories),
        //     company_goal: selectedGoal
        //   });
        // }

        finalAction();
    }

    const firstPageNextHandle = ()=>{

      if((selectedGoal && selectedGoal.length) && (selectedCategories && selectedCategories.length)){
        //both are selected
        setPage(pendingConnections.filter(conn=>conn.status==='requested').length ? 4 : sponsoredBrands && sponsoredBrands.length && companyGoal==="receive_info" ? 5 : 6);
      }else if(!(selectedGoal && selectedGoal.length)){
        setPage(2);
      }else{
        setPage(3);
      }
    };

    const handleGoal = async()=>{

      if(selectedGoal && selectedGoal.length){
        await updateSettings(user.company_id,{
          company_goal: selectedGoal
        });

      }

      setPage(!(selectedCategories && selectedCategories.length) ? 3 : pendingConnections.filter(conn=>conn.status==='requested').length ? 4 : sponsoredBrands && sponsoredBrands.length && companyGoal==="receive_info" ? 5 : 6);
    }

    const handleCategory = async()=>{

      if(selectedCategories && selectedCategories.length){
        await updateSettings(user.company_id,{
          industry_categories: JSON.stringify(selectedCategories)
        });
      }

      setPage(pendingConnections.filter(conn=>conn.status==='requested').length ? 4 : sponsoredBrands && sponsoredBrands.length && companyGoal==="receive_info" ? 5 : 6);

    }

    const toggleCategory = (category)=>{

      setErrorMessage('');
      
      let newSelection = [...selectedCategories];

     const isSelected = _.findIndex(newSelection, function(o) { return o.id === category.id; }) > -1;
     
     if(isSelected){
       // remove category
        setSelectedCategories(newSelection.filter(cat=>cat.id!==category.id));
     }else{
       if(newSelection.length === 3){
        setErrorMessage("You can choose up to 3 industry categories.");
         return setTimeout(()=>setErrorMessage(''), 5000);
       }
      //add category
      newSelection.push(category);
      setSelectedCategories(newSelection);
     }

    };

  // console.log({sponsoredBrands});

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          width: isMobile ? '70%' : "575px",
          height: isMobile ? "80vh" : "550px",
          zIndex: 16777271,
          overflow: "auto",
          padding: isMobile ? "25px 30px" : "50px 60px",
          border: '1px solid #707070',
          borderRadius: '44px',
          opacity: stripeOpen ? 0 : 1
        },
    
        overlay: {
          zIndex: 16777270,
          background: "#A3A99E88"
        }
        
      };

      const features = {
            share_info: [
              {
                  icon: faPaperPlaneLight,
                  title: 'Share Info',
                  text: 'Share unlimited posts with your network.',
                  // tag: "Upgrade Required",
                  show: true
              },
              {
                icon: faShareAlt,
                title: 'Get Connected',
                text: 'Connect to people and companies from your industry already on the KickPost.',
                show: true
            },
            {
                icon: faUserPlus,
                title: 'Invite Your Partners',
                text: 'Send invites to your industry contacts to join your network.',
                show: true
            }
          ],

          receive_info: [
            {
                icon: faNewspaperLight,
                title: 'Browse Your Newsfeed',
                text: "See the latest posts and incentives from your network.",
                show: true
            },
            {
              icon: faSearch,
              title:'Find Info Fast',
              text: 'Search for posts, incentives, people or companies in your network.',
              show: true
          },
          {
              icon: faShareAlt,
              title: 'Build Your Network',
              text: 'Connect to people and brands in your industry.',
              show: true
          }
        ]
      }


      return (
          <Modal
          isOpen={modalOpen}
          onRequestClose={() => handleModalDismissal(false)}
          style={customStyles}
          contentLabel={`Initial Login Modal`}
          ariaHideApp={false}
          >
            {
                page===1
                ?
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                    <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                    >
                        {isSingleUser ? "INDIVIDUAL ACCOUNT" : "COMPANY ACCOUNT"}
                    </NunitoSansBold>
                    <img src={KickpostRocket} width={isMobile ? "172" : "284"} />
                    <NunitoSansBold
                    fontSize={["25px","25px","35px","35px"]}
                    color="#000000"
                    style={{marginBottom:"20px"}}
                    textAlign="center"
                    >
                    Welcome to KickPost!
                    </NunitoSansBold>
                    <NunitoSans
                    fontSize="18px"
                    color="#000000"
                    textAlign="center"
                    >
                    Thanks for joining! We’re excited you're ready to simplify how you engage with your industry partners.
                    </NunitoSans>
                    <DashboardSmallCTA
                    width="100%"
                    color="#428BF4"
                    innerHeight="35px"
                    fontSize="16px"
                    style={{marginTop:'50px'}}
                    // onClick={()=>setPage(pendingConnections.filter(conn=>conn.status==='requested').length ? 2 : 3)}
                    onClick={()=>firstPageNextHandle()}
                    >
                        CONTINUE
                    </DashboardSmallCTA>
                </Flex>
                :
                page===2
                ?
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    YOUR GOAL
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize="28px"
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                        Choose Your Objective
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize="18px"
                    color="#000000"
                    textAlign="center"
                    style={{marginBottom: isMobile ? '30px' : '70px'}}
                  >
                     Select your primary goal for joining KickPost.
                  </NunitoSansSemiBold>

                  <Flex
                  width="100%"
                  justifyContent='space-between'
                  flexDirection={isMobile ? "column" : "row"}
                  >
                     <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    style={{
                      border: `1px solid #428BF4`,
                      backgroundColor: selectedGoal==='receive_info' ? '#428BF4': 'transparent',
                      cursor: 'pointer'
                    }}
                    onClick={()=>setSelectedGoal('receive_info')}
                    >

                      <FontAwesomeIcon
                      icon={faNewspaper}
                      color={selectedGoal==='receive_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginTop: isMobile ? '20px' : '50px', marginBottom: isMobile ? '15px' : '27px', fontSize: isMobile ? '35px' : '45px'}}
                      />

                      <NunitoSansBold
                      
                      color={selectedGoal==='receive_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginBottom: isMobile ? '20px' :'30px', textAlign: 'center', fontSize:isMobile ? '18px' : "22px", width: '150px'}}
                      >
                        Receive Info from my Network
                      </NunitoSansBold>

                    </Flex>

                    <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="250px"
                    style={{
                      border: `1px solid #428BF4`,
                      backgroundColor: selectedGoal==='share_info' ? '#428BF4': 'transparent',
                      cursor: 'pointer',
                      marginTop: isMobile ? '20px' : 0
                    }}
                    onClick={()=>setSelectedGoal('share_info')}
                    >

                      <FontAwesomeIcon
                      icon={faPaperPlane}
                      color={selectedGoal==='share_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginTop: isMobile ? '20px' : '50px', marginBottom: isMobile ? '15px' : '27px', fontSize: isMobile ? '35px' : '45px'}}
                      />

                      <NunitoSansBold
                      
                      color={selectedGoal==='share_info' ? '#FFFFFF' : '#428BF4'}
                      style={{marginBottom: isMobile ? '20px' :'30px', textAlign: 'center', fontSize:isMobile ? '18px' : "22px", width: '150px'}}
                      >
                        Share Info with my Network
                      </NunitoSansBold>

                    </Flex>

                  </Flex>

                  

                  <DashboardSmallCTA
                    width="100%"
                    minWidth="130px"
                    innerHeight="35px"
                    fontSize="16px"
                    disabled={!selectedGoal}
                    onClick={() => handleGoal()}
                    style={{marginTop: '45px'}}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
                :
                page===3
                ?
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    YOUR INDUSTRY
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                        Choose Your Industry
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="center"
                    style={{marginBottom: isMobile ? '10px' : '70px'}}
                  >
                     Select at least one option from the list below.
                  </NunitoSansSemiBold>

                  <IndustryCategories
                  isMobile={isMobile}
                  industryCategories={industryCategories}
                  selectedCategories={selectedCategories}
                  toggleCategory={toggleCategory}
                  />

                    {
                      errorMessage
                      ?
                      <p
                      style={{
                        color: '#FE4A49',
                        fontSize:'14px'
                      }}
                      >
                        {errorMessage}
                      </p>
                      :
                      null
                    }
                  <DashboardSmallCTA
                    width="100%"
                    minWidth="130px"
                    innerHeight="35px"
                    fontSize="16px"
                    disabled={selectedCategories.length<1}
                    onClick={() => handleCategory()}
                    style={{marginTop: errorMessage ? '21px' : '70px'}}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
                :
                page===4
                ?
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    GET CONNECTED
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                      Pending Connection Requests
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="center"
                  >
                    Approve your connection requests to immediately start engaging your industry partners.
                  </NunitoSansSemiBold>

                  <PendingConnections
                  isMobile={isMobile}
                  pendingConnections={props.pendingConnections}
                  updateConnection={updateConnection}
                  />

                  <DashboardSmallCTA
                    minWidth="130px"
                    width='100%'
                    innerHeight="35px"
                    fontSize="16px"
                    onClick={() => setPage(sponsoredBrands && sponsoredBrands.length && companyGoal==="receive_info" ? 5 : 6)}
                  >
                    NEXT
                  </DashboardSmallCTA>
                </Flex>
                :
                page===5
                ?
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                  <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                  >
                    SPOTLIGHTED BRANDS
                  </NunitoSansBold>

                  <NunitoSansBold
                    fontSize={["20px","20px","28px","28px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                    
                  >
                      Top brands in your industry.
                  </NunitoSansBold>

                  <NunitoSansSemiBold
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="center"
                  >
                    Get connected to some of the top brands in your industry that are already on Kickpost.
                  </NunitoSansSemiBold>

                    <SponsoredBrands
                    sponsoredBrands={sponsoredBrands}
                    createConnection={createConnection}
                    isMobile={isMobile}
                    />

                  <DashboardSmallCTA
                    minWidth="130px"
                    width='100%'
                    innerHeight="35px"
                    fontSize="16px"
                    onClick={() => setPage(6)}
                  >
                    NEXT
                  </DashboardSmallCTA>



                </Flex>
                :
                
                <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"          
                >
                    <NunitoSansBold
                    fontSize="16px"
                    color="#428BF4"
                    
                    >
                        GET STARTED
                    </NunitoSansBold>
    
                    <NunitoSansBold
                    fontSize={["25px","25px","35px","35px"]}
                    color="#000000"
                    style={{marginBottom:'20px', marginTop: '10px', textAlign:"center"}}
                    
                    >
                        Start KickPosting!
                    </NunitoSansBold>
    
                    <NunitoSans
                    fontSize={["16px","16px","18px","18px"]}
                    color="#000000"
                    textAlign="center"
                    >
                      It’s time to explore the benefits of your new account.
                    </NunitoSans>

                    <Flex
                    flexDirection="column"
                    style={{marginBottom: isMobile ? '20px' : '35px', marginTop: isMobile ? '20px' : '35px'}}
                    >

                    {
                        features[selectedGoal].filter(feature=>feature.show).map((feature, index)=>(
                            <Flex
                            flexDirection={["column", "column", "row", "row"]}
                            alignItems="center"
                            style={{marginTop: index>0 ? '25px' : '0px'}}
                            >
                               
                                {
                                  isMobile
                                  ?
                                  <FontAwesomeIcon
                                    icon={feature.icon}
                                    color="#428BF4"
                                    style={{fontSize: '30px', marginBottom: '10px'}}
                                    />
                                  :
                                  <div
                                  style={{width: '50px'}}
                                  >
                                    <FontAwesomeIcon
                                    icon={feature.icon}
                                    color="#428BF4"
                                    style={{fontSize: '35px'}}
                                    />

                                  </div>
                                }

                                <Flex
                                flexDirection="column"
                                style={{marginLeft: isMobile ? 0 : "19px"}}
                                >
                                  <NunitoSansBold
                                    fontSize="18px"
                                    color="#000000"
                                    textAlign={["center","center","left","left"]}
                                    >
                                        {feature.title}
                                    </NunitoSansBold>

                                  <NunitoSansBold
                                  fontSize="14px"
                                  color="#000000"
                                  textAlign={["center","center","left","left"]}
                                  >
                                      {feature.text}
                                  </NunitoSansBold>

                                </Flex>

                            </Flex>
                        ))
                    }
                    </Flex>
                    <DashboardSmallCTA
                    width="100%"
                    color="#2FD371"
                    innerHeight="35px"
                    fontSize="16px"
                    onClick={()=>handleModalDismissal(true)}
                    // style={{marginTop: selectedGoal==='receive_info' || user.company.paid ? '70px' : '0px' }}
                    >
                        START KICKPOSTING
                    </DashboardSmallCTA>
{/*     
                    {
                      selectedGoal==='share_info' && !user.company.paid
                      ?
                      <>
                        <StripeCheckoutButton
                        width="100%"
                        color="#428BF4"
                        innerHeight="35px"
                        fontSize="16px"
                        style={{marginTop:'25px', marginBottom: '15px'}}
                        onSuccess={handleModalDismissal}
                        onError={notifyError}
                        reversed={true}
                        >
                          Add-On Share Posts
                        </StripeCheckoutButton>

                        <NunitoSans
                        fontSize="14px"
                        color="#000000"
                        >
                          <span onClick={()=>window.open("https://www.kickpost.io/#Plans")} style={{color:"#428BF4", cursor: "pointer", textDecoration:"underline", lineHeight:2}}>Click here</span> to learn more.
                        </NunitoSans>
                      </>
                      :
                      null
                    } */}
    
                </Flex>
            
            }

          </Modal>
      );




};

InitialLoginModal = connect(
    state => ({
      company: state.resources.detail.company,
    }),
    dispatch => ({
      fetchSettings: bindActionCreators(actions.fetchCompanysetting, dispatch),
      updateSettings: bindActionCreators(actions.patchCompanysetting, dispatch),
      fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
      fetchUser: bindActionCreators(actions.fetchUser, dispatch),
      updateUser: bindActionCreators(actions.patchUser, dispatch),
      updateCompany: bindActionCreators(actions.patchCompany, dispatch),
      notifySuccess: bindActionCreators(
        success.bind(null, "api_success"),
        dispatch
      ),
      notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
      
    })
  )(InitialLoginModal);

  
export let PendingConnectionsModal = props=>{

  const {user, isSingleUser, isMobile, pendingConnections, updateConnection, finalAction, initialPendingCount} = props;

  

  const [modalOpen, setModalOpen] = useState(false);
  const [didMount, setDidMount] = useState(false);
  

  useEffect(()=>{
    if(initialPendingCount>0 && modalOpen){
      if(!didMount){
       setDidMount(true);
      }else if(pendingConnections.length===0){

        handleModalDismissal(true);
      }
    }
  }, [pendingConnections])

if(user && user.id && !modalOpen){

  const InitialLoginDone = !user.company_owner || user.company.paid ? true : Boolean(localStorage.getItem(`_kp_initial_login_${user.id}`));
  const termsApproved = Boolean(localStorage.getItem("kpPolicy"));
  const newSession = !sessionStorage.getItem(`_kp_pending_connections_${user.id}`);

  // if(initialPendingCount > 2 && user.company_admin && InitialLoginDone && termsApproved && newSession) setModalOpen(true);

}

  const handleModalDismissal = (dismiss=false)=>{
      if(!dismiss) return;
      sessionStorage.setItem(`_kp_pending_connections_${user.id}`, 'complete');
      finalAction();
      setModalOpen(false);
  }



  const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: isMobile ? '50%' : "575px",
        height: "550px",
        zIndex: 16777271,
        overflow: "auto",
        padding: "50px 60px",
        border: '1px solid #707070',
        borderRadius: '44px'
      },
  
      overlay: {
        zIndex: 16777270,
        background: "#A3A99E88"
      }
      
    };

    return (
        <Modal
        isOpen={modalOpen}
        onRequestClose={() => handleModalDismissal(false)}
        style={customStyles}
        contentLabel={`Pending Connections Modal`}
        ariaHideApp={false}
        >
          <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"          
              >
                <NunitoSansBold
                  fontSize="16px"
                  color="#428BF4"
                  
                >
                  NEW CONNECTION REQUEST(S)
                </NunitoSansBold>

                <NunitoSansBold
                  fontSize="28px"
                  color="#000000"
                  style={{marginBottom:'20px', marginTop: '20px', textAlign:"center"}}
                  
                >
                      What Are You Waiting For?
                </NunitoSansBold>

                {/* <NunitoSansSemiBold
                  fontSize="18px"
                  color="#000000"
                  textAlign="center"
                >
                   You have some pending connection requests! Make connections with your industry partners to view and share information immediately.
                </NunitoSansSemiBold> */}

                <PendingConnections
                pendingConnections={pendingConnections}
                updateConnection={updateConnection}
                height="400px"
                />

                <DashboardSmallCTA
                  minWidth="130px"
                  fontSize="15px"
                  innerHeight="30px"
                  onClick={() => handleModalDismissal(true)}
                >
                  SKIP
                </DashboardSmallCTA>
              </Flex>

        </Modal>
    );




};

  const IndustryCategories = ({industryCategories=[], selectedCategories, toggleCategory, isMobile})=>{

    const theFirstHalf = Math.ceil(industryCategories.length/2);

    const firstColumn = industryCategories.slice(0, theFirstHalf);
    const secondColumn = industryCategories.slice(theFirstHalf);

    return (
      <Flex
      width="100%"
      justifyContent="space-between"
      flexDirection={isMobile ? "column" : "row"}
      >
        <Flex
        flexDirection="column"
        >
          {
            firstColumn.map((category, index)=><CategoryBox
              key={category+index.toString()}
              isMobile={isMobile}
              category={category}
              selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
              isFirst={index===0}
              toggleCategory={toggleCategory}
              />
            )
          }

        </Flex>

        <Flex
        flexDirection="column"
        >
          {
            secondColumn.map((category, index)=><CategoryBox
              key={theFirstHalf + index.toString()}
              isMobile={isMobile}
              category={category}
              selected={selectedCategories.map(cat=>cat.id).includes(category.id)}
              isFirst={index===0}
              toggleCategory={toggleCategory}
              />
            )
          }

        </Flex>
  
      </Flex>
    );
  }

  const CategoryBox = (props) => (
    <Flex
    alignItems="center"
    width="250px"
    style={{
      border: `1px solid ${props.selected ? "#428BF4" : "#C3C3C3"}`, 
      backgroundColor: props.selected ? "#428BF4" : "transparent", 
      marginTop: props.isMobile ? '15px' : !props.isFirst ? '35px' : 'none',
      paddingBottom:  props.isMobile ? '5px' : '10px',
      paddingTop: props.isMobile ? '5px' : '10px',
      cursor: 'pointer'
    }}
    onClick={()=>props.toggleCategory(props.category)}
    >

      <FontAwesomeIcon
      icon={props.selected ? faMinus : faPlus}
      color={props.selected ? "#FFFFFF" : "#C3C3C3"}
      style={{marginLeft: '20px', fontSize: props.isMobile ? '18px' : '22px'}}
      />

      <NunitoSans
      color={props.selected ? "#FFFFFF" : "#C3C3C3"}
      fontSize={["18px","18px","22px","22px"]}
      style={{marginLeft: '20px'}}
      >
        {props.category.name}
      </NunitoSans>

    </Flex>
  );

  const SponsoredBrands = (props)=>(
    <Flex
    width="100%"
    flexDirection={["column", "row"]}
    justifyContent="space-between"
    alignItems="center"
    style={{marginTop: '30px', marginBottom: '30px'}}
    >

      {props.sponsoredBrands.map((brand, index)=>(
        <SponsoredBrandRow
        key={brand+index.toString()}
        brand={brand}
        createConnection={props.createConnection}
        isMobile={props.isMobile}
        />
      ))}

    </Flex>
  );

  const SponsoredBrandRow = ({brand, createConnection, isMobile})=>{

    const [processing, setProcessing] = useState(false);

    const _createConnection = async(vendorId)=>{

      setProcessing(true);

      await createConnection(vendorId);

      setProcessing(false);

    }

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        style={{padding: isMobile ? "15px 15px" : "20px 20px", border: '1px solid #C3C3C3', borderRadius: '5px', marginBottom: isMobile ? '10px' : 0}}
        >

          <img src={brand.logo || defaultCompany(brand.id, brand.name)} width={120} alt={brand.name} />

          <NunitoSansBold
          fontSize="17px"
          color="#000000"
          textAlign="center"
          style={{marginTop: '10px', marginBottom: '10px'}}
          >
            {brand.name}
          </NunitoSansBold>

          {
            processing
            ?
            <NunitoSans
            fontSize="12px"
            color="#2FD371"
            textAlign="center"
            >
              Processing...

            </NunitoSans>
            :
            !brand.connectionStatus
            ?
            <DashboardSmallCTA
            width="100%"
            minWidth={["74px"]}
            fontSize="12px"
            innerHeight="30px"
            onClick={() => _createConnection(brand.id)}
            >
              CONNECT
            </DashboardSmallCTA>
            :
            <NunitoSans
            fontSize="12px"
            color="#2FD371"
            textAlign="center"
            >
              {brand.connectionStatus==='active' ? "CONNECTED" : "PENDING"}

            </NunitoSans>
          }

        </Flex>
    );
  };


  const PendingConnections = (props)=>(
    <Flex
    flexDirection="column"
    width="100%"
    style={{height: props.height || '350px', overflowX: 'hidden', overflowY: 'auto', paddingRight: '10px'}}
    >
      {
        props.pendingConnections.map((connection, index)=>(
          <PendingConnectionRow
          key={connection+ index.toString()}
          updateConnection={props.updateConnection}
          connection={connection}
          index={index}
          isMobile={props.isMobile}
          />
        ))
      }

    </Flex>
  );

  const PendingConnectionRow = ({connection, index, updateConnection, isMobile})=>{

    const [processing, setProcessing] = useState(false);

    const _updateConnection = async(conn, status)=>{

      setProcessing(true);

      await updateConnection(conn, status);

      setProcessing(false);

    }

    return (
      <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      style={{borderTop: index!==0 ? '1px dashed #A3A99E' : 'none', paddingTop: isMobile ? '10px' : '20px', paddingBottom: isMobile ? '10px' : '20px'}}
      >


          <Flex
          alignItems="center"
          >
            <ImageTreatment
                img={connection.company.logo ||(connection.company.is_single_user_company ? defaultProfile(connection.company.id, connection.company.name) : defaultCompany(connection.company.id, connection.company.name))}
                blurRadius={"0px"}
                imageInset={0}
                borderRadius={connection.company.is_single_user_company ? 22 : 4}
                innerBorderRadius={connection.company.is_single_user_company ? 20 : 0}
                shadowOffset={0}
                padding={4}
                height={isMobile ? 30 : 40}
                width={isMobile ? 30 : 40}
                
            />

            <Flex
            flexDirection="column"
            style={{marginLeft: '15px'}}
            >
              <Flex
              alignItems="center"
              >
                <NunitoSansBold
                fontSize={["14px","14px","17px","17px"]}
                color="#000000"
                >
                  {connection.company.name}
                </NunitoSansBold>

              </Flex>
              <NunitoSans
              fontSize={["10px","10px","12px","12px"]}
              color="#000000"
              >
                {connection.company.is_single_user_company ? connection.company.pseudoName : `${connection.company.total_employee_count} People`}
              </NunitoSans>
            </Flex>

          </Flex>

          {
            processing
            ?
            <NunitoSans
            fontSize={["12px","12px","15px","15px"]}
            textAlign="center"
            >
              Processing...
            </NunitoSans>
            :
            connection.status==="requested"
            ?
            <Flex>
              {
                isMobile
                ?
                <FontAwesomeIcon
                icon={faTimesCircle}
                color="#A3AA9E"
                size="2x"
                onClick={() => _updateConnection(connection, 'declined')}
                />
                :
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["74px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  color="#FE4A49"
                  onClick={() => _updateConnection(connection, 'declined')}
                >
                  IGNORE
                </DashboardSmallCTA>
              }

              {
                isMobile
                ?
                <FontAwesomeIcon
                icon={faCheckCircle}
                color="#428BF4"
                size="2x"
                onClick={() => _updateConnection(connection, 'active')}
                style={{marginLeft: '15px'}}
                />
                :
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["74px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  onClick={() => _updateConnection(connection, 'active')}
                >
                  ACCEPT
                </DashboardSmallCTA>
              }

            </Flex>
            :
            <NunitoSansBold
            color='#C7C7C7'
            fontSize={["12px","12px","15px","15px"]}
            textAlign="center"
            >
              CONNECTED
            </NunitoSansBold>
          }


      </Flex>
    );
  };

  export {InitialLoginModal};


  function numberParser(number){
    return new Intl.NumberFormat('en-US').format(number)
  }