import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField, OnboardingCTA, BackButton} from '../../common/forms';
import {Flex, Box} from '../../common/flexbox';
import {Bubble} from '../../common/ui';
import {H1, P} from '../../common/typography';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import companyLogoUploadImage from '../../../images/company-logo-upload.jpg';
import ReactTooltip from 'react-tooltip';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Company name is required';
  }
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
 
  return errors;
};
const BlurWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 10px;
  right: 10px;
  bottom: -18px;
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
`;
const DefaultBlurrable = styled.div`
  width: 100%;
  height: 100%;
  background: #9f9f9f;
`;
const PreviewImg = styled.img`
  height: 100%;
  width: 100%;
`;
const PreviewImageBlurrable = ({src}) => <PreviewImg src={src} />;

const DropzoneKickpost = ({...props}) => (
  <Box position="relative" >
    <Flex
      borderRadius={10}
      bg="imageBorder"
      position="relative"
      zIndex={100} 
      justifyContent="center"
      p={'10px'}
      alignItems="center"
      width={[83, 110, 110, 110]}
      height={[83, 110, 110, 110]}>
      <Dropzone disableClick
        onDrop={props.dropzoneOnDrop}
        style={{
          backgroundColor: '#fff',
          backgroundImage: props.dropzonePreview
            ? `url(${props.dropzonePreview})`
            : `url(${companyLogoUploadImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          cursor: 'pointer',
          width: '100%',
          paddingTop: '100%',
        }}
        multiple={false}
      />
    </Flex>
    {props.dropzonePreview ? (
      <BlurWrapper>
        <PreviewImageBlurrable src={props.dropzonePreview} />
      </BlurWrapper>
    ) : (
      <BlurWrapper>
        <DefaultBlurrable />
      </BlurWrapper>
    )}
  </Box>
);
const CompanyNameBubble = ({...props}) => (
  <Flex
    w={[1, 1, 1, 1]}
    alignContent="center"
    justifyContent="space-between"
    flexDirection={['column', 'column', 'row', 'row']}>
    <Bubble
      bg={['white', 'white', 'inputBackgroundGray', 'inputBackgroundGray']}>
      <Flex
        flexDirection={['column', 'column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
        pt={['0px', '0px', '36px', '36px']}
        pb={['0px', '0px', '39px', '39px']}>
        <Box
          flex="1 1 auto"
          order={[1, 1, 0, 0]}
          width={[1, 1, 0.605, 0.655]}
          ml={['0px', '0px', '44px', '44px']}>
          <Field
            label="Company Name"
            name="name"
            mb={0}
            component={renderField}
            type="text"
          />
        </Box>
        <Box data-tip="Drag and Drop Logo Here"
          mb={[39, 39, 0, 0]}
          mr={[0, 0, 49, 49]}
          ml={[0, 0, 49, 49]}
          order={[0, 0, 1, 1]}
          width={[103, 130, 130, 130]}>
            <ReactTooltip />
          <DropzoneKickpost {...props} />
        </Box>
      </Flex>
    </Bubble>
  </Flex>
);

let CompanyForm = props => {
  const {handleSubmit, dropzoneOnDrop, dropzonePreview, back, singleUserAccount, redirect} = props;
  return (
    <Flex width={[1, 1, 1]} alignItems="center" flexDirection="column">
      <H1 alignSelf={'flex-start'}>You’re in!</H1>
      <P alignSelf={'flex-start'}>
      Make sure to accurately enter your Company Name to get started.
      </P>
      <form
        onSubmit={handleSubmit}
        id="create-company-form"
        style={{width: '100%'}}>
        <Flex
          width={[1, 1, 1, 1]}
          mt={['7px', '7px', '28px', '28px']}
          alignItems="center"
          justifyContent="center"
          flexDirection="column">

          <Box 
            width={[1, 1, 1, 1]}
          >
            <Field
              label={singleUserAccount ? "Company Display Name" : "Company Name"}
              name="name"
              component={renderField}
              type="text"
            />
            
          </Box>
          {/* <Flex
            w={[1, 1, 1, 1]}
            alignContent="center"
            justifyContent="space-between"
            flexDirection={["column", "column", "row", "row"]}
          >
            <Box w={[1, 1, 0.4823, 0.4823]}>
              <Field
                label="First name"
                name="first_name"
                component={renderField}
                type="text"
              />
            </Box>
            <Box w={[1, 1, 0.4823, 0.4823]}>
              <Field
                label="Last name"
                name="last_name"
                component={renderField}
                type="text"
              />
            </Box>
          </Flex> */}
          
          <Flex
              justifyContent={['center', 'flex-start']}
              alignItems="center"
              width={[1, 1, 1, 1]}
            >
            <Box display={['none', 'block']} width={0.333}>
              <BackButton link={back} />
            </Box>
            <OnboardingCTA
              my={['2vh', '2vh', '9vh', '9vh']}
              width={[0.75, 0.75, 0.375, 0.375]}
              type="submit">
              NEXT
            </OnboardingCTA>
          </Flex>
          <OnboardingCTA
            display={["block", "none"]}
            my={["1vh", "1vh", "9vh", "9vh"]}
            width={[0.75, 0.75, 0.375, 0.375]}
            color="#A3A99E"
            onClick={() => redirect(back)}
          >
            BACK
          </OnboardingCTA>
        </Flex>
      </form>
    </Flex>
  );
};

export default reduxForm({
  form: 'company',
  validate,
})(CompanyForm);
