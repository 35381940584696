import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { reset } from "redux-form";
import _ from "underscore";
import { history } from "../../../store";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";


//utils
import { getUser, dismissInviteSuggestion, getDismissedInviteSuggestions, dismissSuggestionDuringSession, getDismissSuggestionDuringSession, makeAuthHeader } from "../../../utils/auth";
// import { filterActiveDepartments } from "../../utils/helpers";
//actions
import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";
import { setInvitationId } from "../../../actions/invitations";
import {
  //searchDepartments, 
  searchEmployees,
  searchPeople
} from "../../../actions/search";

import { EMAIL_REGEX, NON_BIZ_REGEX } from "../../../constants/app";
//components
import { InputStyled, DashboardSmallCTA } from "../../common/forms";
import { NunitoSans, NunitoSansBold } from "../../common/typography";
import { Flex, Box, ClickCursorFlex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import {
  //InviteVendorCTA, 
  //CreateDepartmentCTA, 
  //EditDepartmentCTA,
  InviteVendorCTA
} from "../../common/ui";
import {
  //DepartmentRowGroup,
  VendorInvitedRowGroup,
  CompanyInvitedRowGroup,
  //VendorRowGroup,
  //I_nvitationRowGroup,
  RowGroupHeader,
  CompanyRowGroup
} from "../../common/rows";
import { BackButton } from "../../common/backbutton";


import InfoModal from "../../common/InfoModal";

import inviteLogo from "../../../images/invites_info_logo.png";

import QuestionModalTrigger from "../../common/questionModalTrigger";
// import { InviteSuggestionBox } from "../../common/suggestions";

export class ManageInvitationsMain extends Component {
  state = {
    search: false,
    hasMore: true,
    invites: [],
    page: 1,
    prevPage: 0,
    sortByValue: 'newest',
    dismissedInvites: [],
    invitePromo: null,
    DMG:"src/components/dashboard/invitations/index.js"
  };
  constructor() {
    super();
  }

  _fetchDependencies = async () => {
    let {DMG}= this.state;DMG+=" _fetchDependencies"
    console.log(DMG,"");

    const {
      //fetchDepartments, departments, 
      fetchUsers,
      fetchPersons,
      fetchCompany,
      people,
      match } = this.props;

    fetchCompany(getUser().company_id);
    
    console.log(DMG,"this.props", this.props);
    
    console.log(DMG,"people", people);

    if (
      (getUser()) &&
      getUser().company_id !== match.params.id
    ) {
      history.push("/dashboard");
    }

    let peopled = await this._fetchPersons(fetchPersons)
      .then((results) => {
        console.log(DMG,"results", results);
        console.log(DMG,"compon-entWillMount this.props", this.props);
        let people = this.props.people;
        if (people) {
          console.log(DMG,"compone-ntWillMount people", people);//this is the raw people returned from querying the PostgreSQL database people table
          people.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              // Vendor_ids.push(v.vendor_id);
            } else {
              // Vendor_ids.push(v.company_id);
            }
          }
          this.setState({ invites: people, hasMore: people.length < 15 ? false : true });
        }
      })
      .catch((e) => {
        console.log(DMG,"compo-nentWillMount e", e);
      })
      .finally((res) => {
        console.log(DMG,"res", res);
      });

    // console.log(DMG,"componentW-illMount peopled", peopled);

    /*
    fetchPersons({ "id": getUser().id, "offset": 0 }).then(res => {
      console.log(DMG,res);
      this.setState({ invites: res.payload, hasMore: res.payload.length < 15 ? false : true });
      //      this.setState({invites: res.payload, hasMore: res.payload.length < 15 ? false : true});
    });
    */

    this.setState({
      dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]
    });
  }

  componentDidUpdate(nextProps) {
    let {DMG}=this.state;DMG+=" componentDidUpdate";
    // console.log(DMG,"nextProps.people",nextProps.people)
    // console.log(DMG, "this.props.people",this.props.people)
    // console.log(DMG,"this.state.search",this.state.search)
    // console.log(DMG,(!this.state.search && nextProps.people.length === 1 && !this.props.people.length) )
    // console.log(DMG,nextProps.people, this.props.people)


    if (this.props.people !== nextProps.people) {
      this.setState({ invites: this.props.people });
    }


    if (!this.state.search && nextProps.people.length === 1 && !this.props.people.length) {
      this.props.fetchPersons({ "id": getUser().id, "offset": 0 })
      .then(res => {
        this.setState({ invites: res.payload, hasMore: res.payload.length < 15 ? false : true, page: 1, prevPage: 0 });
        // console.log(DMG,'A');
      });
    }
    
    console.log(DMG,(nextProps.people.length && this.props.people.length && nextProps.people.length >= this.props.people.length && !this.state.search))
    if (nextProps.people.length && this.props.people.length && nextProps.people.length >= this.props.people.length && !this.state.search) {
      if (nextProps.people[0].person.email !== this.props.people[0].person.email) {
        if (moment().unix() - moment(nextProps.people[0].created_at).unix() < 2000) {
          let updatedInvites = this.state.invites;
          updatedInvites.unshift(nextProps.people[0]);
          this.setState({ invites: updatedInvites });
        }

      } 
    }
    /*
    if (nextProps.departmentResults !== this.props.departmentResults) {
      return this._fetchDepartments(nextProps.departmentResults);
    }
    */
  }

  async componentDidMount() {
    await this._fetchDependencies();
    const { companySettings } = this.props;

    let inviteActionable =  fetch(`${process.env.REACT_APP_API_URL}/point_actionables?type=send_invites&status=active&audience=all`, { headers: makeAuthHeader() }).then(res => res.json());

    if (inviteActionable && inviteActionable.length) {
      this.setState({
        invitePromo: {
          value: parseInt(inviteActionable[0].point_value),
          cap: parseInt(inviteActionable[0].cap_value)
        }
      })
    } else {
      inviteActionable =  fetch(`${process.env.REACT_APP_API_URL}/point_actionables?type=send_invites&status=active&audience=${companySettings.company_goal}`, { headers: makeAuthHeader() }).then(res => res.json());

      if (inviteActionable && inviteActionable.length) {
        this.setState({
          invitePromo: {
            value: parseInt(inviteActionable[0].point_value),
            cap: parseInt(inviteActionable[0].cap_value)
          }
        })
      }
    }


  }

  _fetchPersons = async (input = "") => {
    let DMG = "_fetchPersons ";
    const { companyResults, fetchPersons } = this.props;
    console.log(DMG, input)
    if (input === fetchPersons) {
      console.log(DMG, "still got ya")
      return await fetchPersons({ "id": getUser().id });
      // return await fetchPersons({ "id": getUser().id, "input": input });
    }
    if (input !== "" && input.offset) {
      // request.offset = input.offset;
      // console.log(DMG, "input.offset", parseInt(input.offset))
      return  fetchPersons({ id: getUser().id, offset: parseInt(input.offset) * 1 });
    }
    // else {
    //   console.log(DMG, "bird is the word")
    // }

    // let request = {};
    // if (input.offset) {
    //   request.offset = input.offset;
    //   return await fetchPersons({ "id": getUser().id, request });
    // }

    // if (request.offset) {
    //   return await fetchPersons({ "id": getUser().id, request });
    // } else {
    //   return await fetchPersons({ "id": getUser().id, "input": input });
    // }

    /*fetchPersons({
      //id: "2c8d158e-4fe7-456f-a1b8-6f0984227202"
      //, ids: _.pluck(companyResults, "id")
      person
      //: "2c8d158e-4fe7-456f-a1b8-6f0984227202"
      //,ids: _.pluck(companyResults, "id")
    });*/
    /*fetchPersons({
      company_id: "682bed84-0185-4f7c-8fc2-7bbecaf8252c",
      ids: _.pluck(companyResults, "id")
    });*/
  };

  _fetchConnections = () => {
    const { companyResults, fetchConnections } = this.props;
    fetchConnections({
      company_id: getUser().company_id,
      ids: _.pluck(companyResults, "id")
    });
  };



  _clearSearch = () => {
    this.setState({ search: "" });
    this._search("");
  };

  _search = async input => {
    const { fetchPersons, fetchDepartments, searchDepartments, searchPeople } = this.props;

    if (input.length === 0) {
      //console.log("fetchPersons");
      fetchPersons({ "id": getUser().id, "offset": 0 }).then(res => {
        // const DMG ="src/components/dashboard/invitations/index.js";
        // console.log(DMG+" this.props",this.props);
        // console.log(DMG+" this.state",this.state);
        
        this.setState({ invites: this.props.people, hasMore: this.props.people.length < 15 ? false : true, page: 1, prevPage: 0 });
      });
    } else {
      //console.log("searchPeople", input);
      searchPeople(getUser().id, input);
    }

  };

  _updateSearch = input => {
    this.setState({ search: input });
    this._search(input);
    //this._fetchPersons(input);

    //this._fetchConnections();
  };

  _removeInvitation = async data => {
    console.log("_removeInvitation data")
    console.log(data)
    const {
      updateConnection,
      notifyError,
      notifySuccess
    } = this.props;
    let result = await updateConnection(data.id, {
      status: "delete", "vendor_id": "no_vendor_id", "invitee_id": data.invitee_id,
      connection_id: data.id, index: "personsIndex", company_id: getUser().company_id
    });

    console.log("resultresult")
    console.log(result)
    if (result.payload === 1) {
      notifySuccess(
        "Invite for " + data.person.first_name + " " + data.person.last_name + " has been removed "
      );
      this._clearSearch();
    } else {
      notifyError(
        "Could not remove connection. Please try again later."
      );
    }
  };

  _loadMore = async pageNo => {
    const { fetchPersons } = this.props;
    const { invites, hasMore, page, prevPage } = this.state;

    this.setState({ prevPage: page });

    console.log('hellooooooo', page, pageNo)

    // fetchPersons().then(res => {    })

    let people;
    this._fetchPersons({ offset: pageNo * 15 })
      .then(() => {
        console.log("componen-tWillMount this.props", this.props);
        people = this.props.people;
        if (people) {
          console.log("componentW-illMount people", people);//this is the raw people returned from querying the PostgreSQL database people table
          people.forEach(reorder);
          function reorder(v, i, a) {//the results returned from the table are correct but not aligned properly
            if (v.company_id === getUser().company_id) {//this will extract the company_ids that a company is connected with
              // Vendor_ids.push(v.vendor_id);
            } else {
              // Vendor_ids.push(v.company_id);
            }
          }
          if (people.length === 0) {

            this.setState({ hasMore: false });
            console.log(hasMore)
          } else if (hasMore) {
            this.setState({ invites: invites.concat(people) });


            if (people.length < 15) {
              this.setState({ hasMore: false })
            } else {
              this.setState({ page: page + 1 })

            }
          }
        }
      })
      .catch((e) => {
        console.log("compone-ntWillMount e", e);
      });
  }


  _resendInvitation = async data => {
    const {
      createConnection,
      notifyError,
      notifySuccess
    } = this.props;
    //console.log(data)

    let userPayload = {
      resend: "true",
      //email: data.person.email,
      //first_name: data.person.first_name,
      //last_name: data.person.last_name,
      inviteMessage: 'Just a reminder to join our partner engagement network so our companies can work together more efficiently.',
      status: "invited_company",
      invitee_id: data.person.id,
      user_id: data.user_id,
      company_id: data.company_id,
      vendor_id: data.vendor_id,
      id: data.id

    };
    //console.log(userPayload)
    let result = await createConnection(userPayload);
    //console.log(result)
    if (typeof result !== "undefined" && result.payload.message === "success") {
      notifySuccess(
        "An email has been resent to " + data.person.first_name + " " + data.person.last_name + " at " + data.person.email
      );
    } else {
      notifyError(
        "An email could not be sent. Please try again later. "
      );
    }
  };

  _sortBy = (a, b) => {
    const { sortByValue } = this.state;
    if (sortByValue === 'newest') return Date.parse(b.created_at) - Date.parse(a.created_at);
    if (sortByValue === 'oldest') return Date.parse(a.created_at) - Date.parse(b.created_at);

    if (sortByValue === 'name') return (this._fullName(a.person) < this._fullName(b.person) ? -1 : this._fullName(a.person) > this._fullName(b.person) ? 1 : 0);
    if (sortByValue === 'reverse-name') return (this._fullName(a.person) > this._fullName(b.person) ? -1 : this._fullName(a.person) < this._fullName(b.person) ? 1 : 0);
  }

  _fullName = person => person.first_name.trim() + ' ' + person.last_name.trim();

  _onSubmit = async data => {
    const {
      people,
      searchedPeople,
      fetchPersons,
      createConnection,
      createUser,
      resetForm,
      notifyError,
      notifySuccess,
      company,
      companySettings
    } = this.props;

    let inviteeEmails = people.map(item => item.person.email);


    let LeEmail = data.email;
    LeEmail = LeEmail.toString().trim().toLowerCase();
    data.email = LeEmail;

    let userPayload = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      status: "invited_company",
      inviter: {
        ...getUser(),
        company_name: company.name
      }
    };

    let getUserEmail = getUser().email.split("@")[1];
    let userPayloadEmail = userPayload.email.split("@")[1];


    if (inviteeEmails.includes(userPayload.email.toString().toLowerCase())) {
      notifyError(
        "You have already invited this person, to remind them click the RESEND button below."
      );
      resetForm("create-vendor");
    } else if (
      (!companySettings.is_single_user_company && userPayloadEmail != undefined)
      ||
      (companySettings.is_single_user_company && getUser().email !== userPayload.email && userPayloadEmail != undefined)
    ) {
      const user = await createUser(userPayload);

      if (user && user.payload) {
        // console.log(user);
        let result = await createConnection({
          vendor_id: getUser().company_id,//maye hard-code this to KickPost
          company_id: getUser().company_id,//getUser().company_id,
          user_id: getUser().id,
          invitee_id: user.payload.invited_company_user.id,
          status: "invited",
          inviteMessage: data.message
        });
        //console.log(result)
        if (typeof result !== "undefined" && result.payload.status === "invited") {
          notifySuccess(
            "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
          );
          fetchPersons({ "id": getUser().id });
        } else {
          notifyError(
            "We could not invite this person. Please try again later."
          );
        }
      }


    }


    resetForm("create-vendor");
  };


  _handleInvite = async ({ id, first_name, last_name, email }) => {
    const { company, notifyError, notifySuccess, createUser, createConnection, fetchInviteSuggestions, fetchPersons } = this.props;

    this._dismissDuringSession(id);

    let userPayload = {
      email,
      first_name,
      last_name,
      status: "invited_company",
      inviter: {
        ...getUser(),
        company_name: company.name
      }
    };


    const user = await createUser(userPayload);

    if (user && user.payload) {
      // console.log(user);
      let result = await createConnection({
        vendor_id: getUser().company_id,//maye hard-code this to KickPost
        company_id: getUser().company_id,//getUser().company_id,
        user_id: getUser().id,
        invitee_id: user.payload.invited_company_user.id,
        status: "invited",
        inviteMessage: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales."
      });

      if (typeof result !== "undefined" && result.payload.status === "invited") {
        notifySuccess(
          "An invitation email has been sent to " + user.payload.invited_company_user.first_name + " " + user.payload.invited_company_user.last_name + " at " + user.payload.invited_company_user.email
        );
        fetchInviteSuggestions({
          company_id: getUser().company_id,
          user_id: getUser().id
        });
        fetchPersons({ "id": getUser().id });
      } else {
        notifyError(
          "We could not invite this person. Please try again later."
        );
      }
    } else {
      notifyError(
        "We could not invite this person. Please try again later."
      );
    }

  };

  validate = values => {
    const errors = {};
    if (!values.company_name) {
      //  errors.company_name = "Company name required";
    }

    if (!values.first_name) {
      errors.first_name = "First name required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    let re = new RegExp(EMAIL_REGEX, "i");

    if (!re.test(values["email"])) {
      errors["email"] = "Not a valid email";
    }

    let re2 = new RegExp(NON_BIZ_REGEX, "i");

    if (re2.test(values["email"])) {
      errors["email"] = "Only Business Emails";
    }

    if (!values.message || !values.message.trim()) {
      errors.message = "Message required";
    } else if (values.message.trim().length > 300) {
      errors.message = "Message cannot be more than 300 characters.";
    }

    return errors;
  };

  _dismissDuringSession = id => {
    dismissSuggestionDuringSession(id);
    this.setState({
      dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]
    });

  }

  _handleInviteSuggestionDismiss = id => {
    dismissInviteSuggestion(id);
    this.setState({
      dismissedInvites: [...getDismissedInviteSuggestions(), ...getDismissSuggestionDuringSession()]
    });
  }


  render() {
    const {
      //fetchUsers,
      updateUser,
      company,
      companyResults,
      connections,
      inviteSuggestions,
      browser,
      people,
      searchedPeople,
      //      departments, 
      createConnection,
      updateConnection,
      fetchPersons,
      fetchConnections,
      redirect,
      invitationId,
      //    departmentId, 
      setInvitationId,
      employees,
      userResults,
      companySettings
    } = this.props;
    const { search, invites, hasMore, page, prevPage, sortByValue, dismissedInvites, invitePromo } = this.state;
    const isMobile = browser.lessThan.small;



    //KEEP THIS CODE FOR LATER
    //const filteredInviteSuggestions = inviteSuggestions.filter(invite => !dismissedInvites.includes(invite.id));


    //console.log(people);
    //console.log(connections);
    //console.log(companyResults);
    //console.log(employees);

    let invitedConnections = connections.filter(checkForInvitedConnections);
    let filteredCompanyResults = [];

    //console.log("fetchUsers({ company_id: getUser().company_id })");
    //    this._fetchPersons({"id": "2c8d158e-4fe7-456f-a1b8-6f0984227202"});
    //let foundPersons = fetchPersons({"id": getUser().id});//this._fetchPersons({"id": "2c8d158e-4fe7-456f-a1b8-6f0984227202"});
    //console.log(getUser())

    //console.log(invitedConnections);
    //console.log(foundPersons);

    //console.log(this.state);



    invitedConnections.forEach(conn => {
      companyResults.find(retru);
      function retru(element, index, array) {
        if (conn.vendor_id.toString() === array[index].id.toString()) {
          // alert(element.id)
          filteredCompanyResults.push(array[index]);
          return array[index];
        }
        return false;
      }
    });

    function checkForInvitedConnections(connection) {
      if (connection.status === "invited") {
        return connection;
      }
      return false;
    }



    return (
      <Flex flexDirection="column">
        <Flex flexDirection="column">

          <Flex
            mt="1px"
            mb="15px"
            mx={["0px"]}
            // height={["49px"]}
            width={"100%"}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Flex position="relative" width={["100%"]}>
              <AbsoluteDiv
                display={["block"]}
                // left={["10px"]}
                // bottom={"0px"}
                top={["6px", "6px"]}
                width={["40px"]}
              >
                <IconBox
                  // pl={["15px"]}
                  // pb={["15px"]}
                  // pt={["15px"]}
                  fontSize={["18px", "18px"]}
                  color="#c3c3c3"
                >
                  search
                </IconBox>

              </AbsoluteDiv>
              <InputStyled
                placeholder="Search Invites"
                onChange={e => this._updateSearch(e.target.value)}
                value={search || ""}
                innerRef={input => (this.searchInput = input)}
                style={{ paddingLeft: '45px' }}
                width="100%"
                name="dashboard-search"
                type="text"
                border={0}
                autoFocus={true}
                bg={["lightGray"]}
                borderRadius={"11px"}
                // pt={["3px"]}
                px={["5px", "5px"]}
                mt={["3px", "0px"]}
                height={[31, 40, 40, 40]}
                // fontWeight={400}
                fontSize={[14, 20, 20, 20]}
                lineHeight={1.5}
                color={"#000000"}
                borderBottom={[0]}
              />
              {
                search && search.length
                  ?
                  <AbsoluteDiv
                    display={["block"]}
                    right={["20px"]}
                    bottom={"0px"}
                    top={["0px"]}
                    width={["17px"]}
                  >
                    <ClickCursorFlex
                      height={["100%", "100%", "100%"]}
                      alignItems={["center"]}
                      onClick={this._clearSearch}
                      justifyContent={["center"]}
                    >
                      <IconBox
                        cursor="pointer"
                        pl={["15px"]}
                        pb={["15px"]}
                        pt={["15px"]}
                        fontSize={["17px"]}
                        color="#c3c3c3"
                      >
                        thinx
                      </IconBox>
                    </ClickCursorFlex>
                  </AbsoluteDiv>
                  :
                  null
              }
            </Flex>
          </Flex>

          {/* {
            browser.lessThan.large
            ?
            null
            :
            <Flex
            flexDirection="column"
          alignItems="center"
          style={{marginLeft: 'auto'}}
          >
            <NunitoSans
            fontSize='14px'
            fontWeight='bold'
            >
              Sort By:
            </NunitoSans>
           
            
            <select
            id='sortBy'
            onChange={(e)=>this.setState({sortByValue: e.target.value})}
            value={sortByValue}
            style={{padding:"5px 10px", border: '1px solid #2FD371', borderRadius: "5px", textAlign:"center", fontSize: '12px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#6C7168"}}
            >
              <option style={{textAlign:"center", width:"100%", fontSize: '12px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#6C7168"}} key={1} value='newest'> NEWEST </option>
              <option style={{textAlign:"center", width:"100%", fontSize: '12px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#6C7168"}} key={2} value='oldest'> OLDEST </option>
              <option style={{textAlign:"center", width:"100%", fontSize: '12px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#6C7168"}} key={3} value='name'> A-Z </option>
              <option style={{textAlign:"center", width:"100%", fontSize: '12px', fontWeight:"bold", fontFamily:"NunitoSans", color:"#6C7168"}} key={4} value='reverse-name'> Z-A </option>
            </select>
            
          </Flex>
          } */}

          {
            browser.lessThan.large
              ?
              <div style={{ marginBottom: '15px' }}>
                <InviteVendorCTA
                  onSubmit={this._onSubmit}
                  validate={this.validate}
                  initialValues={{ message: "Join me on KickPost, a platform that allows industry partners to engage like never before. It simplifies how we can work together to drive sales." }} />
              </div>
              :
              null
          }

          {
            invites && invites.length
              ?
              <div style={{ height: "79vh", overflowY: "auto", overflowX: "hidden", "paddingRight": "20px" }}>
                <InfiniteScroll
                  key={["IVI" + `${Math.random().toString()}`]}
                  pageStart={0}
                  loadMore={a => {

                    console.log(prevPage, page);

                    if (prevPage < page) {
                      return this._loadMore(page);
                    }
                  }
                  }
                  hasMore={hasMore && !search && invites.length > 0}
                  // hasMore={false}
                  loader={<div className="loader"></div>}
                  useWindow={false}
                  threshold={150}
                >



                  {
                    companySettings.invite_suggestion_active && !(search && search.length) && inviteSuggestions && inviteSuggestions.length
                      ?
                      <Flex
                        flexDirection="column"
                        mb='10px'
                      >
                        <Flex
                          mb='20px'
                        >
                          {/*<NunitoSansBold
                            fontSize="14px"
                            color="#000000"
                            style={{ marginRight: '20px' }}
                          >
                            RECOMMENDED INVITES
                          </NunitoSansBold> */}

                          {/*<NunitoSansBold
                            fontSize="14px"
                            color="#2FD371"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => redirect(`/dashboard/company/${getUser().company_id}/invitesuggestions`)}
                          >
                            VIEW ALL3
                          </NunitoSansBold>*/}
                        </Flex>
                        
                        {/*<Flex
                          className="suggestion-box-wrapper singleRow"
                        >
                          {filteredInviteSuggestions.slice(0, 6).map((suggestion, index) => <InviteSuggestionBox key={index} suggestion={suggestion} sendInvite={this._handleInvite} handleDismiss={this._handleInviteSuggestionDismiss} index={index} />)}
                        </Flex>  */}

                      </Flex>
                      :
                      null
                  }


                  {search && (!searchedPeople || !searchedPeople.hits || searchedPeople.hits.length === 0) ?

                    <Flex justifyContent="center" alignItems="center" height={175}>
                      <Box>
                        <NunitoSans
                          key={["NUNa" + `${Math.random().toString()}`]}
                          pt={["3px", "4px", "7px", "5px"]}
                          fontSize={22}
                          fontStyle="italic"
                          color="darkGray"
                        >
                          No Results Found
                        </NunitoSans>
                      </Box>
                    </Flex>

                    :
                    <>
                      <NunitoSansBold
                        key={["NSB" + `${Math.random().toString()}`]}
                        style={{ marginTop: '25px' }}
                      >
                        INVITES
                      </NunitoSansBold>
                      <CompanyInvitedRowGroup                      
                        key={["CIRG" + `${Math.random().toString()}`]}
                        // showHeader={true || search&&search.length}
                        mobile={browser.lessThan.large}
                        hideFirstLastDash={true}
                        resendInvitation={this._resendInvitation}
                        removeInvitation={this._removeInvitation}
                        //invited={_.where(connections, { status: "invited" })}
                        invited={(search && searchedPeople && searchedPeople.hits && searchedPeople.hits.length > 0 ? searchedPeople.hits : invites).sort(this._sortBy)}
                      />
                    </>
                  }

                </InfiniteScroll>
              </div>
              :
              <div>
                {
                  companySettings.invite_suggestion_active && !(search && search.length) && inviteSuggestions && inviteSuggestions.length
                    ?
                    <Flex
                      flexDirection="column"
                    >
                      <Flex
                        mb='20px'
                      >
                        {/*<NunitoSansBold
                          fontSize="14px"
                          color="#000000"
                          style={{ marginRight: '20px' }}
                        >
                          RECOMMENDED INVITES
                        </NunitoSansBold>*/}
                        {/*<NunitoSansBold
                          fontSize="14px"
                          color="#2FD371"
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => redirect(`/dashboard/company/${getUser().company_id}/invitesuggestions`)}
                        >
                          VIEW ALL
                        </NunitoSansBold>*/}
                      </Flex>

                      {/*<Flex
                        className="suggestion-box-wrapper singleRow"
                      >
                        {filteredInviteSuggestions.slice(0, 6).map((suggestion, index) => <InviteSuggestionBox key={index} suggestion={suggestion} sendInvite={this._handleInvite} handleDismiss={this._handleInviteSuggestionDismiss} index={index} />)}
                      </Flex>*/}

                    </Flex>
                    :
                    null
                }
                <Flex justifyContent="center" alignItems="center" height={175}>

                  <Box>
                    <NunitoSans
                      pt={["3px", "4px", "7px", "5px"]}
                      fontSize={22}
                      fontStyle="italic"
                      color="darkGray"
                    >
                      There have been no invitations.
                    </NunitoSans>
                  </Box>
                </Flex>
              </div>

          }


          {/* {hasMore && !search && invites.length > 0 ? 
          <Flex width={"100%"}
          position="relative"
          alignItems="center"
          justifyContent="center"
          >
            <Box>
          <DashboardSmallCTA
                onClick={() => this._loadMore(page)}
                mt={["10px", 0]}
                mb={["24px", 0]}
                mr={[0, "23px"]}
                width={["140px","400px"]}
              >
                LOAD MORE
          </DashboardSmallCTA>
          </Box>
          </Flex>
              } */}


        </Flex>
      </Flex>
    );

    return null;
  }

}

export default connect(
  state => ({
    browser: state.browser,
    setinvitationId: null,//state.invitations.invitationId,
    // departments: state.resources.collection.department || [],
    //departmentResults: state.search.departmentSearchResults || [],
    inviteSuggestions: state.resources.collection.invitesuggestion || [],
    company: state.resources.detail.company,
    employees: state.resources.collection.user || [],
    people: state.resources.collection.person || [],
    searchedPeople: state.search.personSearchResults || [],
    companyResults: state.search.companySearchResults || [],
    connections: state.resources.collection.connection || [],
    companySettings: state.resources.detail.companysetting,
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    searchPeople: bindActionCreators(searchPeople, dispatch),
    searchEmployees: bindActionCreators(searchEmployees, dispatch),
    fetchUsers: null,//bindActionCreators(actions.fetchUsers, dispatch),
    updateUser: bindActionCreators(actions.patchUser, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    updateConnection: bindActionCreators(actions.patchConnection, dispatch),
    redirect: bindActionCreators(push, dispatch),
    fetchPersons: bindActionCreators(actions.fetchPersons, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchInviteSuggestions: bindActionCreators(actions.fetchInvitesuggestions, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    //fetchDepartments: bindActionCreators(actions.fetchDepartments, dispatch),
    resetForm: bindActionCreators(reset, dispatch),
    // searchDepartments: bindActionCreators(searchDepartments, dispatch),
    //updateDepartment: bindActionCreators(actions.patchDepartment, dispatch),
    setInvitationId: bindActionCreators(setInvitationId, dispatch)
  })
)(ManageInvitationsMain);


export const ManageInvitationsNav = ({ ...props }) => (
  <Flex
    height={"100%"}
    width={"100%"}
    alignItems="flex-end"
    justifyContent="center"
  >
    <InfoModal
      modalName='invites_modal'  //no spaces. Once set right do not change
      modalVersion="1.00" //set it to 1.00 initially then change it when needed
      image={inviteLogo}
      title='Your Invites'
      text='Build your network by inviting single contacts, uploading a .CSV file for group invites, or sharing your referral link. For tips on how to build your partner network, watch our tutorial video:'
      videoLink='https://youtu.be/bLizN1BdS7Y'
    />
    <Flex
      height={["49px", "49px", "60px", "40px"]}
      width={"100%"}
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <NunitoSans
        pt={["6px", "6px", "8px", "6px"]}
        fontSize={[14, 14, 18, 18]}
        fontWeight={["bold"]}
      >
        INVITES
      </NunitoSans>
      <QuestionModalTrigger modalName='invites_modal' />
      <AbsoluteDiv
        left={["0px", "0px", "0px", "0px", "-20%"]}
        bottom={"0px"}
        top={"0px"}
        width={["auto", "auto", "auto", "auto", "20%"]}
      >
        <Flex
          height={["100%", "100%", "100%"]}
          alignItems={["center"]}
          justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
        >
          <BackButton />
        </Flex>
      </AbsoluteDiv>
    </Flex>
  </Flex>
);


function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}