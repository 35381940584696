import React, { Component, useState } from "react";
import _ from "underscore";
// import {capitalize} from "lodash";
import titleize from "underscore.string/titleize";
import moment from "moment";
import { history } from "../../store";
import axios from "axios";
import Modal from "react-modal";

//utils
import { getUser, dismissSuggestionDuringSession } from "../../utils/auth";
import { IconBox, Box, Flex, ClickCursorFlex } from "./flexbox";
import { OutlineFlex, DashboardSmallCTA, VendorRequest } from "./forms";
import { NunitoSansSemiBold, NunitoSansSemiBoldItalic, NunitoSans, NunitoSansBold, UnderlinedNunitoSans, UnderlinedNunitoSansSemiBold } from "./typography";
import { AbsoluteDiv } from "./absolute";
import ConfirmModal from "./confirmModal";
import PostDetail, {
  Attachments
} from "../dashboard/posts/detail/index";
import IncentiveDetail, {
  IncentiveDetailNav
} from "../dashboard/incentives/details";
import {
  OneSixDash,
  DepartmentLogoNoShadowImage,
  GradientUnderlayRightToLeft,
  GradientUnderlayLeftToRight,
  ImageTreatment,
  HashImage,
  MegaFlexBubble,
  AccessoryIndicator,
  LocationPinImage,
  AudienceImage
} from "./ui";
import { addressBottomPart } from "../../utils/helpers";

import { defaultProfile, defaultCompany } from "../../utils/defaultLogos";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faCheckCircle as solidCheckCircle, faCog } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-regular-svg-icons';
// import { colorStyle } from "styled-system";
import { colors } from "../../utils/theme";
// import { BrowserRouter } from "react-router-dom";

export const SeeAllButton = ({ ...props }) => (
  <ClickCursorFlex
    bg="#f4f4f4"
    height="18px"
    width="54px"
    justifyContent="center"
    alignItems="center"
    borderRadius="11.5px"
    onClick={props.onClickHandler}
  >
    <NunitoSans color="darkerGray" fontSize="8px" fontWeight={700} pt="2px">
      SEE ALL
    </NunitoSans>
  </ClickCursorFlex>
);

export const RowGroupHeader = ({ ...props }) => (
  <Flex flexDirection="row" justifyContent={props.justify || "space-between"} alignItems="center">
    <NunitoSansBold
      py="6px"
      pl="7px"
      fontSize={props.fontSize || "14px"}
      fontWeight="bold"
      color={props.color || "#000000"}
    >
      {props.groupName}
    </NunitoSansBold>
    {/*props.seeAllOnClick ? (
      <SeeAllButton onClickHandler={props.seeAllOnClick} />
    ) : null*/}
  </Flex>
);

// Department Rows & Groups
//
// TODO: DELETE ALL THESE SMALL & LARGE ROWS AND GROUPS.
// now all accomplished below in a combined group
// component and row component one with a mobile flag
export const DepartmentRowSmall = ({ ...props }) => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <DepartmentLogoNoShadowImage width={"40px"} height={"40px"} ml={"7px"} />
    <AbsoluteDiv
      overflow="hidden"
      left="70px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"5px"}
          lineHeight={1}
        >
          {props.name}
        </NunitoSans>
        {/* <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
          {props.employeeNumber}{" "}
          {props.employeeNumber === 1 ? "Employee" : "Employees"}
        </NunitoSans> */}
        <NunitoSans
          fontSize="8px"
          fontWeight={400}
          color="#7B7B7B"
          lineHeight={1.3}
        >
          {props.companyName}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <IconBox fontSize="16px" pr="6px" color="#cecece">
          rightcarret
        </IconBox>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const DepartmentRowLarge = ({ ...props }) => {
  return (
    <ClickCursorFlex
      height="68px"
      position="relative"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {props.firstRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
      <DepartmentLogoNoShadowImage width={"46px"} height={"46px"} ml={"9px"} />
      <AbsoluteDiv
        overflow="hidden"
        left="85px"
        top="1px"
        bottom="1px"
        right="0px"
      >
        <Flex
          // width="1000%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <NunitoSans
            fontSize="14px"
            fontWeight={300}
            color="darkText"
            pt={"5px"}
            lineHeight={1}
          >
            {props.name}
          </NunitoSans>
          {/* <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
          {props.employeeNumber}{" "}
          {props.employeeNumber === 1 ? "Employee" : "Employees"}
        </NunitoSans> */}
          <NunitoSans
            fontSize="8px"
            fontWeight={400}
            color="#7B7B7B"
            lineHeight={1.3}
          >
            {props.companyName}
          </NunitoSans>
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <GradientUnderlayRightToLeft
          color="#FFFFFF"
          boxWidth="41px"
          gradientWidth="41px"
        />
      </AbsoluteDiv>
      {props.lastRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          bottom="-1px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
    </ClickCursorFlex>
  );
};

export const DepartmentRowGroupSmall = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.departments && props.departments.length ? (
      <div>
        <RowGroupHeader groupName="DEPARTMENT(S)" />
        <Flex flexDirection="column" width="100%">
          {props.departments.map((department, i) => {
            return (
              <DepartmentRowSmall
                key={i}
                firstRow={i === 0}
                lastRow={
                  i === props.departments && props.departments.length - 1
                }
                hideFirstLastDash={props.hideFirstLastDash}
                companyName={props.companyName}
                {...department}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);

export const DepartmentRowGroupLarge = ({ ...props }) => {
  return (
    <Flex flexDirection="column" width="100%">
      {props.departments && props.departments.length ? (
        <div>
          <RowGroupHeader groupName="DEPARTMENT(S)" />
          <Flex flexDirection="column" width="100%">
            {props.departments.map((department, i) => {
              return (
                <DepartmentRowLarge
                  key={i}
                  firstRow={i === 0}
                  lastRow={
                    i === props.departments && props.departments.length - 1
                  }
                  hideFirstLastDash={props.hideFirstLastDash}
                  companyName={props.companyName}
                  {...department}
                />
              );
            })}
          </Flex>
        </div>
      ) : null}
    </Flex>
  );
};

// NEW NEW
export const RegularDepartmentRow = ({ ...props }) => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <DepartmentLogoNoShadowImage
      width={"46px"}
      height={"46px"}
      ml={props.mobile ? "7px" : "9px"}
    />
    <AbsoluteDiv
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSansSemiBold
          fontSize="18px"
          fontWeight="normal"
          color="black"
          pt={"5px"}
          lineHeight={1}
        >
          {props.name}
        </NunitoSansSemiBold>
        {true ? null : (
          <NunitoSans fontSize="12px" fontWeight="normal" color="black">
            {props.employeeNumber}{" "}
            {props.employeeNumber === 1 ? "Employee" : "Employees"}
          </NunitoSans>
        )}
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    {true ? null : (
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <IconBox fontSize="16px" pr="6px" color="#cecece">
            rightcarret
          </IconBox>
        </Flex>
      </AbsoluteDiv>
    )}
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const RegularDepartmentRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.hideHeader ? null : (
      <RowGroupHeader
        groupName="DEPARTMENTS"

      />
    )}
    <Flex flexDirection="column" width="100%">
      {props.departments.map((department, i) => {
        return (
          <RegularDepartmentRow
            mobile={props.mobile}
            key={i}
            firstRow={i === 0}
            lastRow={i === props.departments && props.departments.length - 1}
            hideFirstLastDash={props.hideFirstLastDash}
            {...department}
          />
        );
      })}
    </Flex>
  </Flex>
);

// Tag Row & Group
//

export const TagRow = props => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    className={`tag-row-flex ${props.active ? "active" : "not-active"}`}
    alignItems="center"
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <HashImage
      width={"46px"}
      height={"46px"}
      ml={props.mobile ? "7px" : "9px"}
    />
    <AbsoluteDiv
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"5px"}
          lineHeight={1}
        >
          {props.name}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color={props.color}
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
        onClick={
          props.form
            ? () => (props.name && props.name.length ? props.submit() : null)
            : props.removeAction
              ? props.removeFromSelected
              : props.submit
        }
      >
        <AccessoryIndicator
          size={30}
          color={props.active ? "#2FD371" : "#efefef"}
          iconName="checkmark"
          iconSize={"14px"}
          iconMarginTop="2px"
          iconColor={props.active ? "#2FD371" : "#d8d8d8"}
        />
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const TagRowGroup = props => (
  <Flex flexDirection="column" width="100%">
    {props.hideHeader ? null : (
      <RowGroupHeader
        groupName={props.groupName ? props.groupName : "TAGS"}

      />
    )}
    <Flex flexDirection="column" width="100%">
      {props.tags &&
        props.tags.map((tag, i) => {
          return (
            <TagRow
              submit={() => props.addToSelected(tag)}
              form={false}
              color={props.color ? props.color : "#ffffff"}
              key={i}
              active={props.selected && props.selected.includes(tag.id)}
              firstRow={i === 0}
              lastRow={i === props.tags.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              removeAction={props.removeAction}
              removeFromSelected={() => props.removeFromSelected(tag.id)}
              {...tag}
            />
          );
        })}
    </Flex>
  </Flex>
);

// Audience Row & Group
//

export const AudienceRow = props => {
  return (
    <Flex
      height="68px"
      position="relative"
      flexDirection="row"
      justifyContent="flex-start"
      className={`tag-row-flex ${props.active ? "active" : "not-active"}`}
      alignItems="center"
    >
      {props.firstRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
      <AudienceImage
        width={"37px"}
        height={"37px"}
        ml={props.mobile ? "3px" : "9px"}
      />
      <AbsoluteDiv
        overflow="hidden"
        left={props.mobile ? "70px" : "85px"}
        top="1px"
        bottom="1px"
        right="0px"
      >
        <Flex
          // width="1000%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <NunitoSans
            fontSize="14px"
            fontWeight={300}
            color="darkText"
            pt={props.companyNumber ? "2px" : "5px"}
            lineHeight={1}
          >
            {props.name}
          </NunitoSans>
          {props.companyNumber ? (
            <NunitoSans
              fontSize="8px"
              fontWeight={500}
              color="darkText"
              lineHeight={1}
            >
              {props.companyNumber}
            </NunitoSans>
          ) : null}
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <GradientUnderlayRightToLeft
          color={props.color}
          boxWidth="26px"
          gradientWidth="26px"
        />
      </AbsoluteDiv>
      {props.hideAdd ? null : (
        <AbsoluteDiv top="1px" right="0px" bottom="1px">
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
            onClick={
              props.removeAction ? props.removeFromSelected : props.submit
            }
          >
            <AccessoryIndicator
              size={30}
              color={props.active ? "#2FD371" : "#efefef"}
              iconName="checkmark"
              iconSize={"14px"}
              iconMarginTop="2px"
              iconColor={props.active ? "#2FD371" : "#d8d8d8"}
            />
          </Flex>
        </AbsoluteDiv>
      )}
      {props.lastRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          bottom="-1px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
    </Flex>
  );
};

export const AudienceRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.hideHeader ? null : (
      <RowGroupHeader
        groupName={props.groupName ? props.groupName : "TAGS"}

      />
    )}
    <Flex flexDirection="column" width="100%">
      {props.audiences.map((audience, i) => {
        return (
          <AudienceRow
            submit={() => props.addToSelected(audience)}
            color={props.color ? props.color : "#ffffff"}
            key={i}
            active={props.selected && props.selected.includes(audience.id)}
            firstRow={i === 0}
            lastRow={i === props.audiences.length - 1}
            hideFirstLastDash={props.hideFirstLastDash}
            removeAction={props.removeAction}
            removeFromSelected={() => props.removeFromSelected(audience.id)}
            hideAdd={props.hideAdd}
            {...audience}
          />
        );
      })}
    </Flex>
  </Flex>
);

export class CompanyCheckmarkableRow extends React.Component {
  state = {
    iconColor: '#efefef',
    iconName: faPlusCircle
  }

  render() {

    const { isSingleUserCompany } = this.props;

    return (

      <ClickCursorFlex
        height="68px"
        position="relative"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        cursor={this.props.noSelect ? "auto" : "pointer"}
        className={`multi-row-flex ${this.props.selected ? "active" : "not-active"}`}
        onClick={() => this.props.noSelect ? null : this.props.selectRow(this.props.id, this.props.type, this.props.companyId)}
      >
        {this.props.firstRow && this.props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml={this.props.mobile ? "7px" : "9px"}>
          <ImageTreatment
            img={this.props.image || (isSingleUserCompany ? defaultProfile(this.props.companyId, this.props.primaryText) : defaultCompany(this.props.companyId, this.props.primaryText))}
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={isSingleUserCompany ? 22 : 4}
            innerBorderRadius={isSingleUserCompany ? 20 : 0}
            shadowOffset={0}
            padding={4}
            height={36}
            width={36}
          />
        </Box>
        <AbsoluteDiv
          cursor={this.props.noSelect ? "auto" : "pointer"}
          overflow="hidden"
          left={this.props.mobile ? "70px" : "85px"}
          top="1px"
          bottom="1px"
          right="0px"
        >
          <Flex
            // // width="1000%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <NunitoSans
              fontSize="14px"
              fontWeight={300}
              color="darkText"
              pt={"3px"}
              lineHeight={1}
            >
              {this.props.primaryText}
            </NunitoSans>
            {this.props.secondaryText ? (
              <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
                {this.props.secondaryText}
              </NunitoSans>
            ) : null}
            {this.props.ancillaryText ? (
              <NunitoSans fontSize="8px" fontWeight={400} color="#7B7B7B">
                {this.props.ancillaryText}
              </NunitoSans>
            ) : null}
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv top="1px" right="0px" bottom="1px">
          <GradientUnderlayRightToLeft
            color={this.props.color}
            boxWidth="26px"
            gradientWidth="26px"
          />
        </AbsoluteDiv>
        {this.props.noSelect ?
          null
          :
          <AbsoluteDiv top="1px" right="5px" bottom="1px">
            <Flex
              height="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              {/* <AccessoryIndicator
          size={30}
          color="#efefef"
          iconName="checkmark"
          iconSize={"13px"}
          iconMarginTop="2px"
        /> */}
              <FontAwesomeIcon
                onMouseOver={() => this.setState({ iconColor: '#2FD371' })}
                onMouseOut={() => this.setState({ iconColor: '#efefef' })}
                icon={this.props.selected ? faMinusCircle : faPlusCircle}
                color={this.props.selected ? "#FE4A49" : "#2FD371"}
                size='2x'

              />
            </Flex>
          </AbsoluteDiv>
        }
        {this.props.lastRow && this.props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            bottom="-1px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
      </ClickCursorFlex>
    );
  }
};

export const DepartmentCheckmarkableRow = props => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    cursor={props.noSelect ? "auto" : "pointer"}
    className={`multi-row-flex ${props.selected ? "active" : "not-active"}`}
    onClick={() => props.noSelect ? null : props.selectRow(props.id, props.type, props.companyId)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    {/* {props.image ? (
      <AbsoluteDiv top="15px" left="11px">
        <Box width="18px" height="18px" borderRadius="4px" overflow="hidden">
          <img
            style={{ width: "18px", height: "18px" }}
            src={props.image}
            alt="cool"
          />
        </Box>
      </AbsoluteDiv>
    ) : null} */}
    {/* <DepartmentLogoNoShadowImage
      width={"46px"}
      height={"46px"}
      ml={props.mobile ? "7px" : "9px"}
    /> */}
    <IconBox pl='11px' fontSize="35px" color="#6C7168">
      departments_icon
    </IconBox>
    <AbsoluteDiv
      cursor={props.noSelect ? "auto" : "pointer"}
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"3px"}
          lineHeight={1}
        >
          {`${props.secondaryText} - ${props.primaryText}`}
        </NunitoSans>
        {props.secondaryText ? (
          <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
            {'Department'}
          </NunitoSans>
        ) : null}
        {props.ancillaryText ? (
          <NunitoSans fontSize="8px" fontWeight={400} color="#7B7B7B">
            {props.ancillaryText}
          </NunitoSans>
        ) : null}
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color={props.color}
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    {props.noSelect ?
      null
      :
      <AbsoluteDiv top="1px" right="5px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          {/* <AccessoryIndicator
          size={30}
          color="#efefef"
          iconName="checkmark"
          iconSize={"13px"}
          iconMarginTop="2px"
        /> */}
          <FontAwesomeIcon

            icon={props.selected ? faMinusCircle : faPlusCircle}
            color={props.selected ? "#FE4A49" : "#2FD371"}
            size='2x'

          />
        </Flex>
      </AbsoluteDiv>
    }
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const GroupCheckmarkableRow = props => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    cursor={props.noSelect ? "auto" : "pointer"}
    className={`multi-row-flex ${props.selected ? "active" : "not-active"}`}
    onClick={() => props.noSelect ? null : props.selectRow(props.id, props.type, props.companyIds)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    {/* {props.image ? (
      <AbsoluteDiv top="15px" left="11px">
        <Box width="18px" height="18px" borderRadius="4px" overflow="hidden">
          <img
            style={{ width: "18px", height: "18px" }}
            src={props.image}
            alt="cool"
          />
        </Box>
      </AbsoluteDiv>
    ) : null}
    <DepartmentLogoNoShadowImage
      width={"46px"}
      height={"46px"}
      ml={props.mobile ? "7px" : "9px"}
    /> */}

    {
      props.isManaged
        ?
        <FontAwesomeIcon
          icon={faUsersClass}
          color="#6C7168"
          style={{ paddingLeft: '11px', fontSize: '35px' }}
        />
        :
        <IconBox pl='11px' fontSize="35px" color="#6C7168">
          groups_icon
        </IconBox>
    }
    <AbsoluteDiv
      cursor={props.noSelect ? "auto" : "pointer"}
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"3px"}
          lineHeight={1}
        >
          {`${props.primaryText}`}
        </NunitoSans>
        {props.secondaryText ? (
          <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
            {props.isManaged ? 'Smart Group' : 'Group'}
          </NunitoSans>
        ) : null}
        {props.ancillaryText ? (
          <NunitoSans fontSize="8px" fontWeight={400} color="#7B7B7B">
            {props.ancillaryText}
          </NunitoSans>
        ) : null}
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color={props.color}
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    {props.noSelect ?
      null
      :
      <AbsoluteDiv top="1px" right="5px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          {/* <AccessoryIndicator
          size={30}
          color="#efefef"
          iconName="checkmark"
          iconSize={"13px"}
          iconMarginTop="2px"
        /> */}
          <FontAwesomeIcon

            icon={props.selected ? faMinusCircle : faPlusCircle}
            color={props.selected ? "#FE4A49" : "#2FD371"}
            size='2x'

          />
        </Flex>
      </AbsoluteDiv>
    }
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);



export const PersonCheckmarkableRow = props => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    cursor={props.noSelect ? "auto" : "pointer"}
    className={`multi-row-flex ${props.selected ? "active" : "not-active"}`}
    onClick={() => props.noSelect ? null : props.selectRow(props.id, props.type, props.companyId)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml={props.mobile ? "5px" : "7px"}>
      <ImageTreatment
        img={props.image || defaultProfile(props.userId, props.primaryText)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={22}
        innerBorderRadius={20}
        shadowOffset={0}
        padding={4}
        height={40}
        width={40}
      />
    </Box>
    <AbsoluteDiv
      cursor={props.noSelect ? "auto" : "pointer"}
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"3px"}
          lineHeight={1}
        >
          {props.primaryText}
        </NunitoSans>
        {props.secondaryText ? (
          <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
            {props.secondaryText}
          </NunitoSans>
        ) : null}
        {props.ancillaryText ? (
          <NunitoSans fontSize="8px" fontWeight={400} color="#7B7B7B">
            {props.ancillaryText}
          </NunitoSans>
        ) : null}
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color={props.color}
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    {props.noSelect ?
      null
      :
      <AbsoluteDiv top="1px" right="5px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          {/* <AccessoryIndicator
          size={30}
          color="#efefef"
          iconName="checkmark"
          iconSize={"13px"}
          iconMarginTop="2px"
        /> */}
          <FontAwesomeIcon

            icon={props.selected ? faMinusCircle : faPlusCircle}
            color={props.selected ? "#FE4A49" : "#2FD371"}
            size='2x'

          />
        </Flex>
      </AbsoluteDiv>
    }
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const AudienceMultiRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.hideHeader ? null : (
      <RowGroupHeader
        groupName={props.groupName ? props.groupName : "TAGS"}
        fontSize={props.headerFontSize}
        justifyContent={props.headerjustify}
      />
    )}
    <Flex flexDirection="column" width="100%">
      {props.audiencesMulti.map((audienceMulti, i) => {
        let type = audienceMulti.type;
        let row = audienceMulti.row;
        if (type === "company" && !props.onlyPeople) {
          return (
            <CompanyCheckmarkableRow
              color={props.color ? props.color : "#ffffff"}
              noSelect={props.noSelect}
              selected={
                props.selectedAudienceMultiIds &&
                props.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.audiencesMulti.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              image={row.image}
              primaryText={row.name}
              removeAction={props.removeAction}
              removeFromSelected={() => props.removeFromSelected(audienceMulti.id)}
              secondaryText={row.people}
              selectRow={props.onSelect}
              id={audienceMulti.id}
              type={audienceMulti.type}
              companyId={audienceMulti.company_id}
            />
          );
        }
        if (type === "department" && !props.onlyPeople) {
          return (
            <DepartmentCheckmarkableRow
              color={props.color ? props.color : "#ffffff"}
              noSelect={props.noSelect}
              selected={
                props.selectedAudienceMultiIds &&
                props.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.audiencesMulti.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}
              removeAction={props.removeAction}
              removeFromSelected={() => props.removeFromSelected(audienceMulti.id)}
              ancillaryText={row.people}
              selectRow={props.onSelect}
              id={audienceMulti.id}
              type={audienceMulti.type}
              companyId={audienceMulti.company_id}
            />
          );
        }
        if (type === "group" && !props.onlyPeople) {
          return (
            <GroupCheckmarkableRow
              color={props.color ? props.color : "#ffffff"}
              noSelect={props.noSelect}
              selected={
                props.selectedAudienceMultiIds &&
                props.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.audiencesMulti.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              isManaged={row.isManaged}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}
              removeAction={props.removeAction}
              removeFromSelected={() => props.removeFromSelected(audienceMulti.id)}
              ancillaryText={row.isManaged ? row.people : row.companies}
              selectRow={props.onSelect}
              id={audienceMulti.id}
              type={audienceMulti.type}
              companyId={audienceMulti.company_id}
              companyIds={audienceMulti.row.companyIds}
            />
          );
        }
        if (type === "person") {
          return (
            <PersonCheckmarkableRow
              color={props.color ? props.color : "#ffffff"}
              noSelect={props.noSelect}
              selected={
                props.selectedAudienceMultiIds &&
                props.selectedAudienceMultiIds.includes(row.id)
              }
              mobile={true}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.audiencesMulti.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              image={row.image}
              primaryText={row.name}
              secondaryText={row.company}
              removeAction={props.removeAction}
              removeFromSelected={() => props.removeFromSelected(audienceMulti.id)}
              selectRow={props.onSelect}
              id={audienceMulti.id}
              type={audienceMulti.type}
              companyId={audienceMulti.company_id}
            />
          );
        }

        return null;
      })}
    </Flex>
  </Flex>
);
export const AssetRow = props => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [declineClicked, setDeclineClicked] = useState(false);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "50vw",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto"
    },

    overlay: {
      zIndex: 16777270
    }

  };

  return (
    <Flex
      height="68px"
      position="relative"
      width="100%"
    // className="row"
    >
      <ClickCursorFlex
        className="row-click-cursor row"
        width="100%"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => !modalIsOpen && props.approvalSwitchOn ? setModalIsOpen(true) : props.redirect(`/dashboard/post/${props.post.id}?search`)}
      >
        {props.firstRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml={props.mobile ? "7px" : "9px"}>
          <ImageTreatment
            img={
              props.asset.brand && props.asset.brand.name
                ?
                (props.asset.brand.logo || defaultCompany(props.asset.brand.id, props.asset.brand.name))
                :
                props.asset.company.is_single_user_company
                  ?
                  defaultCompany(props.asset.company.id, props.asset.company.name)
                  :
                  (props.asset.company.logo || defaultCompany(props.asset.company.id, props.asset.company.name||''))
            }
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={4}
            innerBorderRadius={0}
            shadowOffset={0}
            padding={4}
            height={36}
            width={36}
          />
        </Box>
        <AbsoluteDiv
          overflow="hidden"
          left={props.mobile ? "70px" : "85px"}
          top="1px"
          bottom="1px"
          right="0px"
        >
          <Flex
            // width="1000%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <NunitoSansSemiBold
              fontSize={["14px", "14px", "18px", "18px"]}
              fontWeight="normal"
              color="black"
              pt={"5px"}
              lineHeight={1}
              style={{ overflow: "hidden", textOverflow: "ellipsis", width: "35vw", whiteSpace: "nowrap" }}
            >
              {props.asset.title}
            </NunitoSansSemiBold>
            <NunitoSans
              fontSize={["10px", "10px", "12px", "12px"]}
              fontWeight="normal"
              color="black"
            >
              {moment(props.asset.updated_at || props.asset.created_at)
                .utc()
                .fromNow()}
            </NunitoSans>
            <Flex
              alignItems="center"
            >
              <NunitoSans
                fontSize={["10px", "10px", "12px", "12px"]}
                fontWeight="normal"
                color="gray"
                lineHeight={1.3}
              >
                {props.asset.company.name}
              </NunitoSans>
              {
                props.asset.brand && props.asset.brand.name
                  ?
                  <NunitoSansSemiBold
                    fontSize="9px"
                    color="#000000"
                    style={{
                      backgroundColor: "#ACEDC6",
                      padding: "1px 5px 0 5px",
                      marginLeft: "10px",
                      width: "fit-content"
                    }}
                  >
                    {`On Behalf Of: ${props.asset.brand.name}`}
                  </NunitoSansSemiBold>
                  :
                  null
              }
              
            </Flex>
          </Flex>
        </AbsoluteDiv>

        <AbsoluteDiv top="1px" right="0px" bottom="1px">
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <IconBox fontSize="16px" pr="6px" color="#cecece">
              rightcarret
            </IconBox>
          </Flex>
        </AbsoluteDiv>
        {props.lastRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            bottom="-1px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
      </ClickCursorFlex>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Asset Modal"
        ariaHideApp={false}
      >
        <Flex

          justifyContent="space-between"
        >

          <DashboardSmallCTA
            width="15%"
            minWidth={["74px"]}
            fontSize="12px"
            innerHeight="30px"
            // ml="20px"
            color="#FE4A49"
            onClick={() => {
              setModalIsOpen(false);

            }}

            my="12px"
            height="34px"
          >
            CLOSE
          </DashboardSmallCTA>

          {props.asset.status === "pending"
            ?

            <Flex
              flexDirection="row"
            >
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    let reason = document.getElementById('reasonText').value;
                    if (reason && reason.length > 0) {
                      props.declinePost(props.asset.id, reason);
                      document.getElementById('reasonError').innerText = "";
                      setTimeout(() => {
                        props.notifySuccess('asset is declined')
                        setDeclineClicked(false);
                        setModalIsOpen(false);
                      }, 4000)
                    } else {
                      document.getElementById('reasonError').innerText = "Enter an explanation";
                    }

                  } else {
                    return setDeclineClicked(true);

                  }
                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? 'FINISH' : "DECLINE"}
              </DashboardSmallCTA>
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                // color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    setDeclineClicked(false);
                    document.getElementById('reasonError').innerText = "";
                  } else {
                    props.approvePost(props.asset.id);

                    setTimeout(() => {
                      props.notifySuccess("The asset is approved.")
                      setDeclineClicked(false);
                      setModalIsOpen(false)

                    }, 4000)


                  }

                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? "BACK" : "APPROVE"}
              </DashboardSmallCTA>
            </Flex>
            :
            null
          }
          {
            props.asset.user_id === getUser().id
              ?

              <Flex
                flexDirection="row"
              >
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["74px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  // color="#FE4A49"
                  onClick={() => props.redirect(`/dashboard/posts/edit/${props.asset.id}`)}

                  my="12px"
                  height="34px"
                >
                  EDIT
                </DashboardSmallCTA>
              </Flex>
              :
              null
          }
        </Flex>
        {declineClicked
          ?
          <div>
            <p
              id="reasonLabel"
              style={{
                margin: "10px 0px 10px 0px"
              }}
            >
              Enter an explanation for declining this asset:
            </p>
            <p
              id="reasonError"
              style={{
                color: "red",
                marginBottom: "10px"
              }}
            ></p>
            <textarea
              id="reasonText"
              rows="4"
              style={{
                width: "90%",
                border: "2px solid gray",
                height: "100%",
                padding: "5px 5px 5px 5px"

              }}
              autoFocus
              required
            />

          </div>
          :
          null

        }
        {
          props.asset.user ?
            // <AssetDetail
            //   postId={props.post.id}
            //   companyId={props.post.company.id}
            //   companyLogo={props.post.company.is_single_user_company ? defaultCompany(props.post.company.id, props.post.company.name) : (props.post.company.logo || defaultCompany(props.post.company.id, props.post.company.name))}
            //   companyName={props.post.company.name}
            //   title={props.post.title}
            //   body={props.post.content}
            //   postDate={moment(props.post.start_date)
            //     .utc()
            //     .format("MMMM Do YYYY")}
            //   postedBy={
            //     getUser().id === props.post.user_id
            //       ? `You`
            //       : titleize(`${props.post.user.first_name} ${props.post.user.last_name}`)
            //   }
            //   userId={props.post.user.id}
            //   hashTags={_.pluck(props.post.tags, "name")}
            //   postedByImage={props.post.user.avatar || defaultProfile(props.post.user.id, `${props.post.user.first_name} ${props.post.user.last_name}`)}
            //   fileAttachments={props.post.files}
            //   videoAttachments={props.post.videos}
            //   allConnections={props.post.all_connections}
            //   companyIds={props.post.company_ids}
            //   departmentIds={props.post.department_ids}
            //   userIds={props.post.user_ids}
            // />
            <div></div>
            :
            null
        }
      </Modal>
    </Flex>
  );
}
// Posts Rows & Groups
//
// TODO: DELETE ALL THESE SMALL & LARGE ROWS AND GROUPS.
// now all accomplished below in a combined group
// component and row component one with a mobile flag
export const PostRow = props => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [declineClicked, setDeclineClicked] = useState(false);


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "50vw",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto"
    },

    overlay: {
      zIndex: 16777270
    }

  };

  return (
    <Flex
      height="68px"
      position="relative"
      width="100%"
    // className="row"
    >
      <ClickCursorFlex
        className="row-click-cursor row"
        width="100%"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => !modalIsOpen && props.approvalSwitchOn ? setModalIsOpen(true) : props.redirect(`/dashboard/post/${props.post.id}?search`)}
      >
        {props.firstRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml={props.mobile ? "7px" : "9px"}>
          <ImageTreatment
            img={
              props.post.brand && props.post.brand.name
                ?
                (props.post.brand.logo || defaultCompany(props.post.brand.id, props.post.brand.name))
                :
                props.post.company.is_single_user_company
                  ?
                  defaultCompany(props.post.company.id, props.post.company.name)
                  :
                  (props.post.company.logo || defaultCompany(props.post.company.id, props.post.company.name))
            }
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={4}
            innerBorderRadius={0}
            shadowOffset={0}
            padding={4}
            height={36}
            width={36}
          />
        </Box>
        <AbsoluteDiv
          overflow="hidden"
          left={props.mobile ? "70px" : "85px"}
          top="1px"
          bottom="1px"
          right="0px"
        >
          <Flex
            // width="1000%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <NunitoSansSemiBold
              fontSize={["14px", "14px", "18px", "18px"]}
              fontWeight="normal"
              color="black"
              pt={"5px"}
              lineHeight={1}
              style={{ overflow: "hidden", textOverflow: "ellipsis", width: "35vw", whiteSpace: "nowrap" }}
            >
              {props.post.title}
            </NunitoSansSemiBold>
            <NunitoSans
              fontSize={["10px", "10px", "12px", "12px"]}
              fontWeight="normal"
              color="black"
            >
              {moment(props.post.updated_at || props.post.created_at)
                .utc()
                .fromNow()}
            </NunitoSans>
            <Flex
              alignItems="center"
            >
              <NunitoSans
                fontSize={["10px", "10px", "12px", "12px"]}
                fontWeight="normal"
                color="gray"
                lineHeight={1.3}
              >
                {props.post.company.name}
              </NunitoSans>
              {
                props.post.brand && props.post.brand.name
                  ?
                  <NunitoSansSemiBold
                    fontSize="9px"
                    color="#000000"
                    style={{
                      backgroundColor: "#ACEDC6",
                      padding: "1px 5px 0 5px",
                      marginLeft: "10px",
                      width: "fit-content"
                    }}
                  >
                    {`On Behalf Of: ${props.post.brand.name}`}
                  </NunitoSansSemiBold>
                  :
                  null
              }
              
            </Flex>
          </Flex>
        </AbsoluteDiv>

        <AbsoluteDiv top="1px" right="0px" bottom="1px">
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <IconBox fontSize="16px" pr="6px" color="#cecece">
              rightcarret
            </IconBox>
          </Flex>
        </AbsoluteDiv>
        {props.lastRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            bottom="-1px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
      </ClickCursorFlex>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Post Modal"
        ariaHideApp={false}
      >
        <Flex

          justifyContent="space-between"
        >

          <DashboardSmallCTA
            width="15%"
            minWidth={["74px"]}
            fontSize="12px"
            innerHeight="30px"
            // ml="20px"
            color="#FE4A49"
            onClick={() => {
              setModalIsOpen(false);

            }}

            my="12px"
            height="34px"
          >
            CLOSE
          </DashboardSmallCTA>

          {props.post.status === "pending"
            ?

            <Flex
              flexDirection="row"
            >
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    let reason = document.getElementById('reasonText').value;
                    if (reason && reason.length > 0) {
                      props.declinePost(props.post.id, reason);
                      document.getElementById('reasonError').innerText = "";
                      setTimeout(() => {
                        props.notifySuccess('Post is declined')
                        setDeclineClicked(false);
                        setModalIsOpen(false);
                      }, 4000)
                    } else {
                      document.getElementById('reasonError').innerText = "Enter an explanation";
                    }

                  } else {
                    return setDeclineClicked(true);

                  }
                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? 'FINISH' : "DECLINE"}
              </DashboardSmallCTA>
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                // color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    setDeclineClicked(false);
                    document.getElementById('reasonError').innerText = "";
                  } else {
                    props.approvePost(props.post.id);

                    setTimeout(() => {
                      props.notifySuccess("The post is approved.")
                      setDeclineClicked(false);
                      setModalIsOpen(false)

                    }, 4000)


                  }

                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? "BACK" : "APPROVE"}
              </DashboardSmallCTA>
            </Flex>
            :
            null
          }
          {
            props.post.user_id === getUser().id
              ?

              <Flex
                flexDirection="row"
              >
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["74px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  // color="#FE4A49"
                  onClick={() => props.redirect(`/dashboard/posts/edit/${props.post.id}`)}

                  my="12px"
                  height="34px"
                >
                  EDIT
                </DashboardSmallCTA>
              </Flex>
              :
              null
          }
        </Flex>
        {declineClicked
          ?
          <div>
            <p
              id="reasonLabel"
              style={{
                margin: "10px 0px 10px 0px"
              }}
            >
              Enter an explanation for declining this post:
            </p>
            <p
              id="reasonError"
              style={{
                color: "red",
                marginBottom: "10px"
              }}
            ></p>
            <textarea
              id="reasonText"
              rows="4"
              style={{
                width: "90%",
                border: "2px solid gray",
                height: "100%",
                padding: "5px 5px 5px 5px"

              }}
              autoFocus
              required
            />

          </div>
          :
          null

        }
        {
          props.post.user ?
            <PostDetail
              postId={props.post.id}
              companyId={props.post.company.id}
              companyLogo={props.post.company.is_single_user_company ? defaultCompany(props.post.company.id, props.post.company.name) : (props.post.company.logo || defaultCompany(props.post.company.id, props.post.company.name))}
              companyName={props.post.company.name}
              title={props.post.title}
              body={props.post.content}
              postDate={moment(props.post.start_date)
                .utc()
                .format("MMMM Do YYYY")}
              postedBy={
                getUser().id === props.post.user_id
                  ? `You`
                  : titleize(`${props.post.user.first_name} ${props.post.user.last_name}`)
              }
              userId={props.post.user.id}
              hashTags={_.pluck(props.post.tags, "name")}
              postedByImage={props.post.user.avatar || defaultProfile(props.post.user.id, `${props.post.user.first_name} ${props.post.user.last_name}`)}
              fileAttachments={props.post.files}
              videoAttachments={props.post.videos}
              allConnections={props.post.all_connections}
              companyIds={props.post.company_ids}
              departmentIds={props.post.department_ids}
              userIds={props.post.user_ids}
            />
            :
            null
        }
      </Modal>
    </Flex>
  );
}

export const IncentiveRow = props => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [declineClicked, setDeclineClicked] = useState(false);

  // const systemSettings = await fetch(`${process.env.REACT_APP_API_URL}/systemsettings/${process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID}`).then(res=>res.json());
    

  const { systemSettings, companySettings } = props;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "50vw",
      maxHeight: "98vh",
      zIndex: 16777271,
      overflow: "auto"
    },

    overlay: {
      zIndex: 16777270
    }

  };

  return (
    <Flex
      height="68px"
      position="relative"
      width="100%"
    // className="row"
    >
      <ClickCursorFlex
        className="row-click-cursor row"
        // style={{borderLeft:'6px solid #428BF4'}}
        width="100%"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => !modalIsOpen && props.approvalSwitchOn ? setModalIsOpen(true) : props.redirect(`/dashboard/incentive/${props.post.id}?search`)}
      >
        {props.firstRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml={props.mobile ? "7px" : "9px"}>
          <ImageTreatment
            img={props.post.company.logo || defaultCompany(props.post.company.id, props.post.company.name)}
            blurRadius={"0px"}
            imageInset={0}
            borderRadius={4}
            innerBorderRadius={0}
            shadowOffset={0}
            padding={4}
            height={36}
            width={36}
          />
        </Box>
        <AbsoluteDiv
          overflow="hidden"
          left={props.mobile ? "70px" : "85px"}
          top="1px"
          bottom="1px"
          right="0px"
        >
          <Flex
            width="90%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Flex
              width="100%"
              justifyContent="space-between"
            >

              <NunitoSansSemiBold
                fontSize={["14px", "14px", "18px", "18px"]}
                fontWeight="normal"
                color="black"
                pt={"5px"}
                lineHeight={1}
                style={{ overflow: "hidden", textOverflow: "ellipsis", width: props.mobile ? '30vw' : "22vw", whiteSpace: "nowrap" }}
              >
                {props.post.title}

              </NunitoSansSemiBold>
              <NunitoSansSemiBold
                fontSize={["10px", "10px", "14px", "14px"]}
                fontWeight="normal"
                color={props.post.is_partner_incentive ? "#D5A800" : "#428BF4"}
                pt={"5px"}
                lineHeight={1}
                style={{ overflow: "hidden", textOverflow: "ellipsis", width: props.mobile ? '20vw' : "10vw", whiteSpace: "nowrap" }}
              >
                {`${props.post.is_partner_incentive ? "PARTNER - " : ""}${props.post.type === 'onetoone' ? 'ONE TO ONE' : props.post.type.toUpperCase()}`}
              </NunitoSansSemiBold>
            </Flex>
            <NunitoSans
              fontSize={["10px", "10px", "12px", "12px"]}
              fontWeight="normal"
              color="black">
              {moment(props.post.updated_at).utc().fromNow() + (props.incentiveOwner ? ` - (${moment(props.post.start_date).unix() > moment().unix() ? 'scheduled' : props.post.status})` : props.post.status === 'closed' ? '-(Closed)' : '')}
            </NunitoSans>
            <NunitoSans
              fontSize={["10px", "10px", "12px", "12px"]}
              fontWeight="normal"
              color="gray"
              lineHeight={1.3}
            >
              {props.post.company.name}
            </NunitoSans>
          </Flex>
        </AbsoluteDiv>

        <AbsoluteDiv top="1px" right="0px" bottom="1px">
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <IconBox fontSize="16px" pr="6px" color="#cecece">
              rightcarret
            </IconBox>
          </Flex>
        </AbsoluteDiv>
        {props.lastRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            bottom="-1px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
      </ClickCursorFlex>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Incentive Modal"
        ariaHideApp={false}
      >
        <Flex

          justifyContent="space-between"
        >

          <DashboardSmallCTA
            width="15%"
            minWidth={["74px"]}
            fontSize="12px"
            innerHeight="30px"
            // ml="20px"
            color="#FE4A49"
            onClick={() => {
              setModalIsOpen(false);

            }}

            my="12px"
            height="34px"
          >
            CLOSE
          </DashboardSmallCTA>

          {props.post.status === "pending"
            ?

            <Flex
              flexDirection="row"
            >
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    let reason = document.getElementById('reasonText').value;
                    if (reason && reason.length > 0) {
                      props.declineIncentive(props.post.id, reason);
                      document.getElementById('reasonError').innerText = "";
                      setTimeout(() => {
                        props.notifySuccess("Incentive is declined.")
                        setDeclineClicked(false);
                        setModalIsOpen(false);
                      }, 4000)
                    } else {
                      document.getElementById('reasonError').innerText = "Enter an explanation";
                    }

                  } else {
                    return setDeclineClicked(true);

                  }
                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? 'FINISH' : "DECLINE"}
              </DashboardSmallCTA>
              <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                // color="#FE4A49"
                onClick={() => {
                  if (declineClicked) {
                    setDeclineClicked(false);
                    document.getElementById('reasonError').innerText = "";
                  } else {
                    props.approveIncentive(props.post.id);

                    setTimeout(() => {
                      props.notifySuccess("Incentive is approved.")
                      setDeclineClicked(false);
                      setModalIsOpen(false)
                    }, 4000)

                  }

                }}

                my="12px"
                height="34px"
              >
                {declineClicked ? "BACK" : "APPROVE"}
              </DashboardSmallCTA>
            </Flex>
            :
            null
          }
          {
            props.post.user_id === getUser().id
              ?

              <Flex
                flexDirection="row"
              >
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["74px"]}
                  fontSize="12px"
                  innerHeight="30px"
                  ml="20px"
                  // color="#FE4A49"
                  onClick={() => props.redirect(`/dashboard/incentives/edit/${props.post.id}`)}

                  my="12px"
                  height="34px"
                >
                  EDIT
                </DashboardSmallCTA>
              </Flex>
              :
              null
          }
        </Flex>
        {declineClicked
          ?
          <div>
            <p
              id="reasonLabel"
              style={{
                margin: "10px 0px 10px 0px"
              }}
            >
              Enter an explanation for declining this incentive:
            </p>
            <p
              id="reasonError"
              style={{
                color: "red",
                marginBottom: "10px"
              }}
            ></p>
            <textarea
              id="reasonText"
              rows="4"
              style={{
                width: "90%",
                border: "2px solid gray",
                height: "100%",
                padding: "5px 5px 5px 5px"

              }}
              autoFocus
              required
            />

          </div>
          :
          null

        }
        {systemSettings && systemSettings.rewards_active && companySettings && (companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives) && (getUser().sales_id || getUser().company_admin)?
             <IncentiveDetail
             key={props.post.id}
           // modalIncentiveId={props.post.id}
           match={
             {
               params: {
                 id: props.post.id
               }
             }
           }
         />           
           :
           null}
        

      </Modal>
    </Flex>
  );
}

export const PostRowGroup = props => {
  return (
    <Flex flexDirection="column" width="100%">
      {props.hideHeader ? null : (
        <RowGroupHeader groupName="POSTS" />
      )}
      <Flex flexDirection="column" width="100%">
        {props.posts.map((post, i) => {
          return (
            <PostRow
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.posts.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              post={post}
              redirect={props.redirect}
              approvalSwitchOn={props.approvalSwitchOn}
              approvePost={props.approvePost}
              declinePost={props.declinePost}
              notifySuccess={props.notifySuccess}
              postOwner={props.postOwner}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};


export const AssetRowGroup = props => {
  return (
    <Flex flexDirection="column" width="100%">
      {props.hideHeader ? null : (
        <RowGroupHeader groupName="ASSETS" />
      )}
      <Flex flexDirection="column" width="100%">
        {props.assets.map((asset, i) => {
          return (
            <AssetRow
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.assets.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              asset={asset}
              redirect={props.redirect}
              approvePost={props.approvePost}
              declinePost={props.declinePost}
              approvalSwitchOn={props.approvalSwitchOn}
              notifySuccess={props.notifySuccess}
              assetOwner={props.assetOwner}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export const IncentiveRowGroup = props => {
  return (
    <Flex flexDirection="column" width="100%">
      {props.hideHeader ? null : (
        <RowGroupHeader groupName="INCENTIVES" />
      )}
      <Flex flexDirection="column" width="100%">
        {props.posts.map((post, i) => {
          return (
            <IncentiveRow
              mobile={props.mobile}
              key={i}
              firstRow={i === 0}
              lastRow={i === props.posts.length - 1}
              hideFirstLastDash={props.hideFirstLastDash}
              post={post}
              redirect={props.redirect}
              approvalSwitchOn={props.approvalSwitchOn}
              approveIncentive={props.approveIncentive}
              declineIncentive={props.declineIncentive}
              notifySuccess={props.notifySuccess}
              systemSettings={props.systemSettings}
              companySettings={props.companySettings}
              incentiveOwner={props.incentiveOwner}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
// Company Row
//
export const CompanyRow = props => (
  <ClickCursorFlex
    className="row-click-cursor row"
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    onClick={() => props.redirect(`/dashboard/company/${props.company.id}?search`)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml={props.mobile ? "7px" : "9px"}>
      <ImageTreatment
        img={props.company.logo || defaultCompany(props.company.id, props.company.name)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={4}
        innerBorderRadius={0}
        shadowOffset={0}
        padding={4}
        height={36}
        width={36}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Flex>
          <NunitoSansSemiBold
            fontSize="18px"
            fontWeight="normal"
            color="black"
            pt={"3px"}
            lineHeight={1}
          >
            {props.company.name}
          </NunitoSansSemiBold>
          {/* {
          props.company.paid
          ?
          <IconBox
          fontSize="12px"
          color={colors.kickpostPlus}
          style={{marginLeft:'5px'}}
          >
            kickpost_plus
          </IconBox>
          :
          null
        } */}
        </Flex>
        {
          props.company.location && props.company.location.place_name
            ?
            <NunitoSans
              lineHeight={1.3}
              fontSize="12px"
              fontWeight="normal"
              color="black"
              style={{ "overflow": "hidden", "textOverflow": "ellipsis", width: "35vw", "whiteSpace": "nowrap" }}>
                {addressBottomPart(props.company.location && props.company.location.place_name)}
            </NunitoSans>
            :
            null
        }
        <NunitoSans lineHeight={1.3} fontSize="12px" fontWeight="normal" color="black">
          {`${props.company.total_employee_count} ${props.company.total_employee_count > 1 ? "people" : "person"}`}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    {/* <AbsoluteDiv top="1px" right="-52px" bottom="1px">
      <GradientUnderlayLeftToRight
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" left="0px" bottom="1px">
      <GradientUnderlayLeftToRight
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" left="-52px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv> */}
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <IconBox fontSize="16px" pr="6px" color="#cecece">
          rightcarret
        </IconBox>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const ConnectedCompanyRow = props => (
  <Flex
    height="50px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    {props.firstRow ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml={props.mobile ? "7px" : "9px"}>
      <ImageTreatment
        img={props.company.logo || defaultCompany(props.company.id, props.company.name)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={4}
        innerBorderRadius={0}
        shadowOffset={0}
        padding={4}
        height={28}
        width={28}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Flex>
          <NunitoSansSemiBold
            fontSize="14px"
            fontWeight="normal"
            color="black"
            pt={"3px"}
            lineHeight={1}
          >
            {props.company.name}
          </NunitoSansSemiBold>
        </Flex>
        <NunitoSans lineHeight={1.3} fontSize="10px" fontWeight="normal" color="black">
          {`${props.company.mapped_names?.length} ${props.company.mapped_names?.length !== 1 ? "maps" : "map"}`}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <DashboardSmallCTA
        minWidth={["74px"]}
        fontSize="10px"
        color="#428bf4"
        innerHeight="20px"
        onClick={() => props.onSelect(props.company)}
        style={{ marginRight: '5px'}}
        >
          {props.selected ? "CHANGE" : "SELECT"}
        </DashboardSmallCTA>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </Flex>
);

export const MappedNameRow = props => (
  <Flex
    height="50px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    {props.firstRow ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <AbsoluteDiv
      overflow="hidden"
      top="1px"
      bottom="1px"
      left="0px"
    >
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Flex>
          <NunitoSansSemiBold
            fontSize="16px"
            fontWeight="normal"
            color="black"
            pt={"3px"}
            lineHeight={1}
          >
            {props.mapping}
          </NunitoSansSemiBold>
        </Flex>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <FontAwesomeIcon
        icon={props.selected ? faMinusCircle : faPlusCircle}
        color={props.selected ? "#FE4A49" : "#2FD371"}
        size='lg'
        style={{cursor: 'pointer', marginRight: '5px'}}
        onClick={()=>props.onSelect(props.mapping)}
        />
      </Flex>
    </AbsoluteDiv>
    {props.lastRow ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </Flex>
);

export const CompanyRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.hideHeader ? null : (
      <RowGroupHeader
        groupName="COMPANIES"

      />
    )}
    <Flex flexDirection="column" width="100%">
      {props.companies.map((company, i) => {
        return (
          <CompanyRow
            mobile={props.mobile}
            key={i}
            firstRow={i === 0}
            lastRow={i === props.companies.length - 1}
            hideFirstLastDash={props.hideFirstLastDash}
            company={company}
            redirect={props.redirect}
          />
        );
      })}
    </Flex>
  </Flex>
);

// Person Row
//
// TODO: DELETE ALL THESE SMALL & LARGE ROWS AND GROUPS.
// now all accomplished below in a combined group
// component and row component one with a mobile flag

export const PeopleRowSmall = ({ ...props }) => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    onClick={() => props.redirect(`/dashboard/profile/${props.id}`)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml="5px">
      <ImageTreatment
        img={props.avatar || defaultProfile(props.id, props.name)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={22}
        innerBorderRadius={20}
        shadowOffset={0}
        padding={4}
        height={40}
        width={40}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="70px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"5px"}
          lineHeight={1}
        >
          {props.name}
        </NunitoSans>
        <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
          {props.position}
        </NunitoSans>
        <NunitoSans
          fontSize="8px"
          fontWeight={400}
          color="#7B7B7B"
          lineHeight={1.3}
        >
          {props.companyName}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <IconBox fontSize="16px" pr="6px" color="#cecece">
          rightcarret
        </IconBox>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const PeopleRowLarge = ({ ...props }) => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    onClick={() => props.redirect(`/dashboard/profile/${props.id}`)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml="7px">
      <ImageTreatment
        img={props.avatar || defaultProfile(props.id, `${props.first_name} ${props.last_name}`)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={22}
        innerBorderRadius={20}
        shadowOffset={0}
        padding={4}
        height={40}
        width={40}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="85px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"5px"}
          lineHeight={1}
        >
          {props.first_name} {props.last_name} {props.email}
        </NunitoSans>
        <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
          {props.position}
        </NunitoSans>
        <NunitoSans
          fontSize="8px"
          fontWeight={400}
          color="#7B7B7B"
          lineHeight={1.3}
        >
          {props.companyName}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="41px"
        gradientWidth="41px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <IconBox fontSize="16px" pr="6px" color="#cecece">
          rightcarret
        </IconBox>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const PeopleRowGroupSmall = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.employees && props.employees.length ? (
      <div>
        <RowGroupHeader groupName="PEOPLE" />
        <Flex flexDirection="column" width="100%">
          {props.employees.map((employee, i) => {
            return (
              <PeopleRowSmall
                key={i}
                companyName={props.companyName}
                firstRow={i === 0}
                lastRow={i === props.employees.length - 1}
                hideFirstLastDash={props.hideFirstLastDash}
                redirect={props.redirect}
                {...employee}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);

export const PeopleRowGroupLarge = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.employees && props.employees.length ? (
      <div>
        <RowGroupHeader groupName="PEOPLE" />
        <Flex flexDirection="column" width="100%">
          {props.employees.map((employee, i) => {
            return (
              <PeopleRowLarge
                key={i}
                companyName={props.companyName}
                firstRow={i === 0}
                lastRow={i === props.employees.length - 1}
                hideFirstLastDash={props.hideFirstLastDash}
                redirect={props.redirect}
                {...employee}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);

export const PeopleRow = ({ ...props }) => (

  <ClickCursorFlex
    className="row-click-cursor row"
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    onClick={() => props.redirect(`/dashboard/profile/${props.id}?search`)}
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml={props.mobile ? "5px" : "7px"}>
      <ImageTreatment
        img={props.avatar || defaultProfile(props.id, `${props.first_name} ${props.last_name}`)}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={22}
        innerBorderRadius={20}
        shadowOffset={0}
        padding={4}
        height={40}
        width={40}
      />
    </Box>
    <AbsoluteDiv
      className="row"
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSansSemiBold
          fontSize="18px"
          fontWeight="normal"
          color="black"
          pt={"5px"}
          lineHeight={1}
        >
          {props.first_name} {props.last_name}
        </NunitoSansSemiBold>
        <NunitoSans fontSize="12px" fontWeight="normal" color="black" style={{ "overflow": "hidden", "textOverflow": "ellipsis", width: "35vw", "whiteSpace": "nowrap" }}>
          {props.companyName} {props.position ? "- " + props.position : ""}
        </NunitoSans>
        {
          props.location && props.location.place_name
            ?
            <NunitoSans
              lineHeight={1.3}
              fontSize="12px"
              fontWeight="normal"
              color="black"
              style={{ "overflow": "hidden", "textOverflow": "ellipsis", width: "35vw", "whiteSpace": "nowrap" }}>
                {addressBottomPart(props.location && props.location.place_name)}
            </NunitoSans>
            :
            null
        }
        {/* {props.companyName ? (
          <NunitoSans
            fontSize="12px"
            fontWeight="normal"
            color="gray"
            lineHeight={1.3}
          >
            {props.companyName}
          </NunitoSans>
        ) : null} */}
      </Flex>
    </AbsoluteDiv>
    {/* <AbsoluteDiv top="1px" right="-52px" bottom="1px">
      <GradientUnderlayLeftToRight
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" left="0px" bottom="1px">
      <GradientUnderlayLeftToRight
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" left="-52px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="26px"
        gradientWidth="26px"
      />
    </AbsoluteDiv> */}
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <IconBox fontSize="16px" pr="6px" color="#cecece">
          rightcarret
        </IconBox>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>

);

export const PeopleRowGroup = props => {
  return (
    <Flex flexDirection="column" width="100%">
      {props.hideHeader ? null : <RowGroupHeader groupName="PEOPLE" />}
      <Flex flexDirection="column" width="100%">
        {props.people &&
          props.people.map((person, i) => {
            // console.log(person)
            return (
              <PeopleRow
                mobile={props.mobile}
                key={i}
                firstRow={i === 0}
                lastRow={i === props.people.length - 1}
                hideFirstLastDash={props.hideFirstLastDash}
                companyName={person.company.name}
                redirect={props.redirect}
                {...person}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

// Bubbles
//
export const BrandBubble = ({ ...props }) => (
  <MegaFlexBubble
    position="relative"
    bg="white"
    borderRadius="8px"
    alignItems="center"
    justifyContent="flex-start"
    width={1}
    mb="24px"
    height={68}
  >
    <Box mx="20px">
      <ImageTreatment
        img={props.logo || defaultCompany(props.id, props.name)}
        blurRadius={"5px"}
        imageInset={0}
        borderRadius={10}
        innerBorderRadius={0}
        shadowOffset={0.1}
        padding={2}
        height={36}
        width={36}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="80px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        height="100%"
        width="300%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <UnderlinedNunitoSansSemiBold
          lineHeight={1.1}
          fontSize="18px"
          fontWeight="normal"
          color="black"
        >
          <a href={`${props.url.substring(0, 4) != 'http' ? 'http://' : ''}${props.url}`} target='_blank'>{props.name}</a>

        </UnderlinedNunitoSansSemiBold>
        {/* <NunitoSans
          fontSize="12px"
          fontWeight="normal"
          color="black">
          {props.label}
        </NunitoSans> */}
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="25px"
        gradientWidth="25px"
      />
    </AbsoluteDiv>
  </MegaFlexBubble>
);

export const WebsiteBubble = ({ ...props }) => (
  <MegaFlexBubble
    position="relative"
    bg="white"
    borderRadius="8px"
    alignItems="center"
    justifyContent="flex-start"
    width={1}
    mb="24px"
    height={68}
  >
    <Box mx="20px">
      <AccessoryIndicator
        size={36}
        color="#A80053"
        iconName="link"
        iconSize={"14px"}
        iconMarginTop="2px"
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="80px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        height="100%"
        width="300%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <UnderlinedNunitoSansSemiBold
          lineHeight={1.1}
          fontSize="18px"
          fontWeight="normal"
          color="black"
        >
          <a href={`${props.body.substring(0, 4) != 'http' ? 'http://' : ''}${props.body}`} target='_blank'>{props.body}</a>

        </UnderlinedNunitoSansSemiBold>
        <NunitoSans
          fontSize="12px"
          fontWeight="normal"
          color="black">
          {props.label}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="25px"
        gradientWidth="25px"
      />
    </AbsoluteDiv>
  </MegaFlexBubble>
);

export const EmailBubble = ({ ...props }) => (
  <MegaFlexBubble
    position="relative"
    bg="white"
    borderRadius="8px"
    alignItems="center"
    justifyContent="flex-start"
    width={1}
    mb="24px"
    height={68}
  >
    <Box mx="20px">
      <AccessoryIndicator
        size={36}
        color="#0E4FE4"
        iconName="mail"
        iconSize={"14px"}
        iconMarginTop="2px"
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="80px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        height="100%"
        width="300%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <UnderlinedNunitoSansSemiBold
          lineHeight={1.1}
          fontSize="18px"
          fontWeight="normal"
          color="black"
        >
          <a href={`mailto:${props.body}`}>{props.body}</a>
        </UnderlinedNunitoSansSemiBold>
        <NunitoSans
          fontSize="12px"
          fontWeight="normal"
          color="black">
          {props.label}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="25px"
        gradientWidth="25px"
      />
    </AbsoluteDiv>
  </MegaFlexBubble>
);

export const PhoneBubble = ({ ...props }) => (
  <MegaFlexBubble
    position="relative"
    bg="white"
    borderRadius="8px"
    alignItems="center"
    justifyContent="flex-start"
    width={1}
    mb="24px"
    height={68}
  >
    <Box mx="20px">
      <AccessoryIndicator
        size={36}
        color="#00CF2F"
        iconName="phone"
        iconSize={"14px"}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left="80px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        height="100%"
        width="300%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSansSemiBold
          lineHeight={1.1}
          fontSize="18px"
          fontWeight="normal"
          color="black"
        >
          <a href={`tel:${props.body}`}><u>{props.body}</u></a>

        </NunitoSansSemiBold>
        <NunitoSans
          fontSize="12px"
          fontWeight="normal"
          color="black">
          {props.label}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="25px"
        gradientWidth="25px"
      />
    </AbsoluteDiv>
  </MegaFlexBubble>
);

export const PhoneRowSmall = props => (
  <ClickCursorFlex
    minHeight="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box py="24px">
        <UnderlinedNunitoSans
          fontSize="18px"

          color="black"
          pt={"5px"}
          lineHeight={1}
        >
          <a href={`tel:${props.phoneNumber.body}`}>{props.phoneNumber.body}</a>
          {/* {props.phoneNumber.body} */}
        </UnderlinedNunitoSans>
        <NunitoSans
          width="100%"
          textalign="center"
          fontSize="12px"
          // fontWeight={500}
          color="black"
        >
          {props.phoneNumber.label}
        </NunitoSans>
      </Box>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box mr="3px">
          <AccessoryIndicator
            size={30}
            color="#00CF2F"
            iconName="phone"
            iconSize={"13px"}
            iconMarginTop="0px"
          />
        </Box>
      </Flex>
    </AbsoluteDiv>
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </ClickCursorFlex>
);

export const WebsiteRowSmall = props => (
  <ClickCursorFlex
    minHeight="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box py="24px">
        <UnderlinedNunitoSans
          fontSize="18px"
          // fontWeight="normal"
          color="black"
          pt={"5px"}
          lineHeight={1}
        >
          <a href={`${props.website.body.substring(0, 3) != 'http' ? 'http://' : ''}${props.website.body}`} target='_blank'>{props.website.body}</a>
          {/* {props.website.body} */}
        </UnderlinedNunitoSans>
        <NunitoSans
          width="100%"
          textalign="center"
          fontSize="12px"
          // fontWeight="normal"
          color="black"
        >
          {props.website.label}
        </NunitoSans>
      </Box>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box mr="3px">
          <AccessoryIndicator
            size={30}
            color="#A80053"
            iconName="link"
            iconSize={"13px"}
          />
        </Box>
      </Flex>
    </AbsoluteDiv>
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </ClickCursorFlex>
);

export const EmailRowSmall = props => (
  <ClickCursorFlex
    minHeight="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box py="24px">
        <UnderlinedNunitoSans
          fontSize="18px"
          fontWeight="normal"
          color="black"
          pt={"5px"}
          lineHeight={1}
        >
          <a href={`mailto:${props.email.body}`}>{props.email.body}</a>
          {/* {props.email.body} */}
        </UnderlinedNunitoSans>
        <NunitoSans
          width="100%"
          textalign="center"
          fontSize="12px"
          fontWeight="normal"
          color="black"
        >
          {props.email.label}
        </NunitoSans>
      </Box>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box mr="3px">
          <AccessoryIndicator
            size={30}
            color="#0E4FE4"
            iconName="mail"
            iconSize={"11px"}
            iconMarginTop="2px"
          />
        </Box>
      </Flex>
    </AbsoluteDiv>
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </ClickCursorFlex>
);

export const MapRowSmall = ({ ...props }) => (
  <ClickCursorFlex
    minHeight="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    <OneSixDash
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      height="1px"
    />
    <AbsoluteDiv zIndex={9} top="1px" left="0px" bottom="1px">
      <GradientUnderlayLeftToRight
        color="#FFFFFF"
        boxWidth="36px"
        gradientWidth="30px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={10} top="1px" left="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <LocationPinImage ml="13px" height="36px" width="28px" />
      </Flex>
    </AbsoluteDiv>
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box py="24px">
        {/* <NunitoSans
          fontSize="14px"
          fontWeight={300}
          fontStyle="italic"
          color="darkText"
          pt={"5px"}
          textAlign="center"
          lineHeight={1}
        >
          {props.address} {props.text}
        </NunitoSans> */}
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          fontStyle="italic"
          color="darkText"
          pt={"5px"}
          textalign="center"
          lineHeight={1}
        >
          {addressBottomPart(props.placeName)}
        </NunitoSans>
      </Box>
    </Flex>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      />
    </AbsoluteDiv>
    {/* <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box mr="3px">
          <AccessoryIndicator
            size={30}
            color="#FF6236"
            iconName="rightarrow"
            iconSize={"14px"}
            iconMarginTop="2px"
          />
        </Box>
      </Flex>
    </AbsoluteDiv> */}
    <OneSixDash
      position="absolute"
      bottom="-1px"
      left="0px"
      right="0px"
      height="1px"
    />
  </ClickCursorFlex>
);

// System Notifications Row
//
export const SystemNotificationsRow = ({ ...props }) => (

  <ClickCursorFlex
    minHeight="61px"
    position="relative"
    flexDirection="row"
    justifyContent="space-between"
    onClick={props.clickHandler}
    alignItems="center"
  >
    {/* {props.showFirstDash ? (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    ) : null} */}

    {/* <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      /> */}
    {/* </AbsoluteDiv> */}
    {/* <AbsoluteDiv top="1px" right="0px" bottom="1px"> */}
    {/* <Flex
        height="100%"
        width="100%"
        // flexDirection="column"
        justifyContent="space_between"
        alignItems="center"
      > */}
    <NunitoSans
      fontSize="14px"
      fontWeight={400}
      color="#000000"
      // color={props.active ? "darkText" : "#c3c3c3"}
      pt={"3px"}
      lineHeight={1}
      width="70%"
    >
      {/* {props.message.variable || "Kick"}<br />
        {props.message.action || "Post"} */}

      {notificationMessage(props.message)}
    </NunitoSans>
    {/* <Box> */}
    <NunitoSans
      pt={["2px"]}
      fontSize={["12px"]}
      fontWeight="bold"
      color="#c3c3c3"
      width="25%"
    >
      {moment(props.message.timestamped || props.message.created_at).utc().fromNow() || "0 hours ago"}
    </NunitoSans>
    {/* </Box> */}
    {/* </Flex> */}
    {/* </AbsoluteDiv> */}
    {/* {props.showLastDash ? (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    ) : null} */}
  </ClickCursorFlex>
);

function notificationMessage(message) {

  const MESSAGES = {
    rewards_activation_on: `${message.name} has activated Rewards`,
    rewards_activation_off: `${message.name} has deactivated Rewards`,
    create_incentives_on: `${message.name} can now create sales incentives`,
    receive_incentives_on: `${message.name} can now receive sales incentives`,
    create_incentives_off: `${message.name} can no longer create sales incentives`,
    receive_incentives_off: `${message.name} can no longer receive sales incentives`,
    create_partner_incentives_on: `${message.name} can now create partner incentives`,
    receive_partner_incentives_on: `${message.name} can now receive partner incentives`,
    create_partner_incentives_off: `${message.name} can no longer create partner incentives`,
    receive_partner_incentives_off: `${message.name} can no longer receive partner incentives`,
    new_invoice: `Invoice ${message.name} has been issued to your account`,
    upcoming_sale_deadline: `Deadline for uploading sales for next statement date is ${message.name}`,
    connection_request_accept: `${message.name} is now a connection`,
    connection_request_decline: `${message.name} has declined connection request`,
    connection_removed: `${message.name} is no longer a connection`,
    new_company: `${message.name} joined KickPost`,
    new_connection_request: `${message.name} sent a connection request`,
    new_user: `${message.name} has joined ${getUser().company_id === message.company_id ? 'your company' : message.secondary_name}`,
    user_pending: `${message.name} is waiting for approval`
  };

  return MESSAGES[message.action];
}


// Profile Completion Row
//
export const ProfileCompletionRow = ({ ...props }) => (
  <ClickCursorFlex
    minHeight="61px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    onClick={props.clickHandler}
    alignItems="center"
  >
    {props.showFirstDash ? (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    ) : null}
    <NunitoSans
      fontSize={props.fontSize || "14px"}
      fontWeight={(props.active && props.fontWeight) || 400}
      color={props.active ? "darkText" : "#c3c3c3"}
      pt={"3px"}
      lineHeight={1}
    >
      {props.message}
    </NunitoSans>
    
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="38px"
        gradientWidth="35px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box>
          {props.active ? (
            <AccessoryIndicator
              size={30}
              color="#2FD371"
              iconName="rightarrow"
              iconSize={"13px"}
              iconMarginTop="0px"
            />
          ) : (
            <AccessoryIndicator
              size={30}
              color="#efefef"
              iconName="checkmark"
              iconSize={"13px"}
              iconMarginTop="2px"
            />
          )}
        </Box>
      </Flex>
    </AbsoluteDiv>
    {props.showLastDash ? (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    ) : null}
  </ClickCursorFlex>
);

export const GettingStartedRow = ({ ...props }) => {

  const [hoverOn, setHoverOn] = useState(false);

  return (
    <ClickCursorFlex
      minHeight="61px"
      position="relative"
      flexDirection="row"
      justifyContent="flex-start"
      onClick={props.clickHandler}
      alignItems="center"
      onMouseOver={() => setHoverOn(true)}
      onMouseOut={() => setHoverOn(false)}
    >
      {props.showFirstDash ? (
        <OneSixDash
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          height="1px"
        />
      ) : null}
      <NunitoSansSemiBold
        fontSize={props.fontSize || "14px"}
        // fontWeight={(props.active && props.fontWeight) || 400}
        color={props.active && hoverOn ? "#2FD371" : props.active ? "darkText" : "#c3c3c3"}
        pt={"3px"}
        lineHeight={1}
        style={{ textDecoration: props.active && hoverOn ? 'underline' : 'none' }}
      >
        {props.message}
      </NunitoSansSemiBold>
      
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <GradientUnderlayRightToLeft
          color="#FFFFFF"
          boxWidth="38px"
          gradientWidth="35px"
        />
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Box>
            {
              props.active && hoverOn
                ?
                <IconBox
                  fontSize="35px"
                  color="#2FD371"
                >
                  onboarding-button-hover
                </IconBox>
                :
                props.active
                  ?
                  <IconBox
                    fontSize="35px"
                    color="#2FD371"
                  >
                    onboarding-button-resting
                  </IconBox>

                  :
                  (
                    <AccessoryIndicator
                      size={30}
                      color="#efefef"
                      iconName="checkmark"
                      iconSize={"13px"}
                      iconMarginTop="2px"
                    />
                  )}
          </Box>
        </Flex>
      </AbsoluteDiv>
      {props.showLastDash ? (
        <OneSixDash
          position="absolute"
          bottom="-1px"
          left="0px"
          right="0px"
          height="1px"
        />
      ) : null}
    </ClickCursorFlex>
  );
};

// Employee Invite Sent Rows & Groups
//

export const EmployeeInvitedRow = ({ ...props }) => (
  <ClickCursorFlex
    height="68px"
    position="relative"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
  >
    {props.firstRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        top="0px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
    <Box ml="7px">
      <ImageTreatment
        img={props.profileImage}
        blurRadius={"0px"}
        imageInset={0}
        borderRadius={22}
        innerBorderRadius={20}
        shadowOffset={0}
        padding={4}
        height={40}
        width={40}
      />
    </Box>
    <AbsoluteDiv
      overflow="hidden"
      left={props.mobile ? "70px" : "85px"}
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <NunitoSans
          fontSize="14px"
          fontWeight={300}
          color="darkText"
          pt={"5px"}
          lineHeight={1}
        >
          {props.name}
        </NunitoSans>
        <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
          {props.email}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="62px"
        gradientWidth="41px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv top="1px" right="0px" bottom="1px">
      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <NunitoSans
          pr="4px"
          fontSize={props.mobile ? "10px" : "12px"}
          fontStyle="italic"
          color="#FE4A49"
        >
          PENDING
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    {props.lastRow && props.hideFirstLastDash ? null : (
      <OneSixDash
        position="absolute"
        bottom="-1px"
        left="0px"
        right="0px"
        height="1px"
      />
    )}
  </ClickCursorFlex>
);

export const EmployeeInvitedRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    <RowGroupHeader groupName="INVITED" />
    <Flex flexDirection="column" width="100%">
      {props.invited.map((person, i) => {
        return (
          <EmployeeInvitedRow
            mobile={props.mobile}
            key={i}
            firstRow={i === 0}
            lastRow={i === props.invited.length - 1}
            hideFirstLastDash={props.hideFirstLastDash}
            {...person}
          />
        );
      })}
    </Flex>
  </Flex>
);

// Regular Employee Invite Sent Rows & Groups
//

export const DepartmentNotSpecifiedBubble = ({ ...props }) => (
  <OutlineFlex
    borderRadius="8px"
    borderThickness="1px"
    color={props.active ? "#1D2F34" : "#979797"}
    height="54px"
    justifyContent="space-between"
    position="relative"
    alignItems="center"
    minWidth="120px"
    px="13px"
  >
    <AbsoluteDiv
      overflow="hidden"
      left="13px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        justifyContent="center"
        flexDirection="column"
      >
        <NunitoSans
          lineHeight="1.2"
          fontSize="14px"
          fontWeight={400}
          color={props.active ? "#FE4A49" : "#979797"}
        >
          Not Specified
        </NunitoSans>
        <NunitoSans
          fontSize="8px"
          fontWeight={500}
          color={props.active ? "darkText" : "#979797"}
        >
          {props.employeeNumber}{" "}
          {props.employeeNumber === 1 ? "Employee" : "Employees"}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={9} top="10px" right="0px" bottom="10px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="36px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={9} top="1px" right="0px" bottom="1px">
      <Flex
        pr={"14px"}
        height="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={10}
        position="relative"
      >
        <AccessoryIndicator
          size={24}
          color={props.active ? "#2FD371" : "#d0d0d0"}
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Flex>
    </AbsoluteDiv>
  </OutlineFlex>
);

export const GroupNotSpecifiedBubble = ({ ...props }) => (
  <OutlineFlex
    borderRadius="8px"
    borderThickness="1px"
    color={props.active ? "#1D2F34" : "#979797"}
    height="54px"
    justifyContent="space-between"
    position="relative"
    alignItems="center"
    minWidth="120px"
    px="13px"
  >
    <AbsoluteDiv
      overflow="hidden"
      left="13px"
      top="1px"
      bottom="1px"
      right="0px"
    >
      <Flex
        // width="1000%"
        height="100%"
        justifyContent="center"
        flexDirection="column"
      >
        <NunitoSans
          lineHeight="1.2"
          fontSize="14px"
          fontWeight={400}
          color={props.active ? "#FE4A49" : "#979797"}
        >
          Not Specified
        </NunitoSans>
        <NunitoSans
          fontSize="8px"
          fontWeight={500}
          color={props.active ? "darkText" : "#979797"}
        >
          {props.employeeNumber}{" "}
          {props.employeeNumber === 1 ? "Employee" : "Employees"}
        </NunitoSans>
      </Flex>
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={9} top="10px" right="0px" bottom="10px">
      <GradientUnderlayRightToLeft
        color="#FFFFFF"
        boxWidth="36px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={9} top="1px" right="0px" bottom="1px">
      <Flex
        pr={"14px"}
        height="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={10}
        position="relative"
      >
        <AccessoryIndicator
          size={24}
          color={props.active ? "#2FD371" : "#d0d0d0"}
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Flex>
    </AbsoluteDiv>
  </OutlineFlex>
);

export const DepartmentBubble = props => (
  <OutlineFlex
    borderRadius="8px"
    borderThickness="1px"
    color={props.active ? "#1D2F34" : "#979797"}
    height="54px"
    justifyContent="space-between"
    alignItems="center"
    minWidth="120px"
    position="relative"
    px="13px"
  >
    <Flex flexDirection="column">
      <NunitoSans
        lineHeight="1.2"
        fontSize="14px"
        fontWeight={300}
        color={props.active ? "darkText" : "#979797"}
      >
        {props.name}
      </NunitoSans>
      <NunitoSans
        fontSize="8px"
        fontWeight={500}
        color={props.active ? "darkText" : "#979797"}
      >
        {props.employee_count}{" "}
        {props.employee_count === 1 ? "Employee" : "Employees"}
      </NunitoSans>
    </Flex>
    <AbsoluteDiv zIndex={9} top="10px" right="0px" bottom="10px">
      <GradientUnderlayRightToLeft
        color={props.gradientColor ? props.gradientColor : "#FFFFFF"}
        boxWidth="36px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={10} top="1px" right="0px" bottom="1px">
      <Flex
        pr={"14px"}
        height="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={10}
        position="relative"
      >
        <AccessoryIndicator
          size={24}
          color={props.active ? "#2FD371" : "#d0d0d0"}
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Flex>
    </AbsoluteDiv>
  </OutlineFlex>
);

export const GroupBubble = props => (
  <OutlineFlex
    borderRadius="8px"
    borderThickness="1px"
    color={props.active ? "#1D2F34" : "#979797"}
    height="54px"
    justifyContent="space-between"
    alignItems="center"
    minWidth="120px"
    position="relative"
    px="13px"
  >
    <Flex flexDirection="column">
      <NunitoSans
        lineHeight="1.2"
        fontSize="14px"
        fontWeight={300}
        color={props.active ? "darkText" : "#979797"}
      >
        {props.name}
      </NunitoSans>
      <NunitoSans
        fontSize="8px"
        fontWeight={500}
        color={props.active ? "darkText" : "#979797"}
      >
        {props.employee_count}{" "}
        {props.employee_count === 1 ? "Employee" : "Employees"}
      </NunitoSans>
    </Flex>
    <AbsoluteDiv zIndex={9} top="10px" right="0px" bottom="10px">
      <GradientUnderlayRightToLeft
        color={props.gradientColor ? props.gradientColor : "#FFFFFF"}
        boxWidth="36px"
        gradientWidth="26px"
      />
    </AbsoluteDiv>
    <AbsoluteDiv zIndex={10} top="1px" right="0px" bottom="1px">
      <Flex
        pr={"14px"}
        height="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={10}
        position="relative"
      >
        <AccessoryIndicator
          size={24}
          color={props.active ? "#2FD371" : "#d0d0d0"}
          iconSize="11px"
          iconMarginTop="2px"
          iconName="checkmark"
        />
      </Flex>
    </AbsoluteDiv>
  </OutlineFlex>
);

export class EmployeeRow extends Component {
  state = {
    showDepartments: false,
    updatedDepartments: [],
    editSalesId: false,
    salesIdInput: '',
    checkmarkHover: false,
    removeModalOpen: false,
    deleteModalOpen: false
  }
  constructor(props) {
    super(props);
  }

  departmentClick = async (department, employee) => {
    const { search, searchEmployees, updateUser } = this.props;
    const { updatedDepartments } = this.state;
    let departmentsCopy = updatedDepartments.slice();
    // console.log("departmentClick")

    let existing = _.findIndex(updatedDepartments, { id: department.id });
    if (existing !== -1) {
      departmentsCopy.splice(existing, 1);
    } else {
      departmentsCopy.push({
        id: department.id,
        company_id: department.company_id,
        name: department.name
      });
    }

    await updateUser(employee.id, {
      departments: JSON.stringify(departmentsCopy)
    }).then(user => {
      this.setState({
        updatedDepartments: user.payload.departments
      });
    });

    searchEmployees(department.company_id, search);
  };

  _openRemoveModal = () => this.setState({ removeModalOpen: true });

  _closeRemoveModal = () => this.setState({ removeModalOpen: false });

  _handleRemoveConfirm = () => {
    const { employee, removeClickHandler } = this.props;
    removeClickHandler(employee);
  }

  _openDeleteModal = () => this.setState({ deleteModalOpen: true });
  _closeDeleteModal = () => this.setState({ deleteModalOpen: false });
  _handleDeleteConfirm = () => {
    const { employee, deleteClickHandler } = this.props;
    deleteClickHandler(employee);
  }


  render() {
    const {
      employee,
      hideFirstLastDash,
      firstRow,
      lastRow,
      mobile,
      makeSalesRep,
      removeSalesRep,
      makeAdminClickHandler,
      removeAdminClickHandler,
      makeSystemAdminClickHandler,
      removeSystemAdminClickHandler,
      resendClickHandler,
      removeClickHandler,
      acceptClickHandler,
      user,
      selectedRowId,
      selectRowId,
      systemSettings,
      companySettings,
      openSplitsModal
    } = this.props;
    const { updatedDepartments, editSalesId, salesIdInput, checkmarkHover, removeModalOpen, deleteModalOpen } = this.state;

    return (
       <Flex position="relative" flexDirection="column" width="95%"> 
        <ClickCursorFlex
          height={["68px", "68px"]}
          position="relative"
          flexDirection={["column", "row"]}
          justifyContent="flex-start"
          alignItems={["flex-start", "center"]}
        >
          {firstRow && hideFirstLastDash ? null : (
            <OneSixDash
              position="absolute"
              top="0px"
              left="0px"
              right="0px"
              height="1px"
            />
          )}
          <Box
            onClick={employee.link ? () => history.push(employee.link) : null}
          >
            <Box ml="7px" pt={["13px", 0]}>
              <ImageTreatment
                img={employee.avatar || defaultProfile(employee.id, `${employee.first_name} ${employee.last_name}`)}
                blurRadius={"0px"}
                imageInset={0}
                borderRadius={22}
                innerBorderRadius={20}
                shadowOffset={0}
                padding={4}
                height={40}
                width={40}
              />
            </Box>
            <AbsoluteDiv
              overflow="hidden"
              left={mobile ? "70px" : "85px"}
              top="1px"
              height="68px"
              right="0px"
            >
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <NunitoSans
                  fontSize="14px"
                  fontWeight={300}
                  color="darkText"
                  pt={"5px"}
                  lineHeight={1}
                >
                  {employee.first_name} {employee.last_name}
                </NunitoSans>
                <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
                  {employee.email}
                </NunitoSans>
              </Flex>
            </AbsoluteDiv>
          </Box>
          <AbsoluteDiv top="1px" right="0px" height="68px">
            <GradientUnderlayRightToLeft
              color="#FFFFFF"
              boxWidth={mobile ? "25px" : "230px"}
              gradientWidth="30px"
            />
          </AbsoluteDiv>
          
          {mobile === false && (employee.status === "active" || employee.status === "invited") ? (
            <AbsoluteDiv top="1px" right="0px" bottom="1px">
              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <ConfirmModal
                  modalOpen={removeModalOpen}
                  message="Are you sure you wish to remove this employee?"
                  handleCloseRequest={this._closeRemoveModal}
                  handleConfirm={this._handleRemoveConfirm}
                />
                {employee.company_admin ||
                  user.id === employee.id ? null : (

                  <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"
                    onClick={() => {

                      this._openRemoveModal();
                    }}
                    color="#FE4A49"
                  // my="12px"
                  // height="34px"
                  >
                    Remove
                  </DashboardSmallCTA>
                )}
                {
                  employee.sales_id && systemSettings.reward_splits && companySettings.splits_active && companySettings.receive_incentives
                    ?
                    <DashboardSmallCTA
                      width="15%"
                      minWidth={["60px"]}
                      fontSize="10px"
                      innerHeight="20px"
                      ml="10px"
                      color={colors.buttonCustom}
                      onClick={() => openSplitsModal(employee)}

                    >
                      {Object.values(employee.splits || {}).reduce((a, c) => parseInt(a) + parseInt(c), 0) === 0 ? "Add Split" : "Edit Split"}
                    </DashboardSmallCTA>
                    :
                    null
                }
                {getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_owner && employee.company_admin && !employee.company_owner ?
                  employee.system_admin ? (



                    <DashboardSmallCTA
                      width="15%"
                      minWidth={["60px"]}
                      fontSize="10px"
                      innerHeight="20px"
                      ml="10px"
                      onClick={() => removeSystemAdminClickHandler(employee)}
                      reversed={true}
                    // my="12px"
                    // height="34px"
                    >
                      Admin
                    </DashboardSmallCTA>


                  ) : (

                    <DashboardSmallCTA
                      width="15%"
                      minWidth={["60px"]}
                      fontSize="10px"
                      innerHeight="20px"
                      ml="10px"
                      onClick={() => makeSystemAdminClickHandler(employee)}
                      color="#979797"

                    // my="12px"
                    // height="34px"
                    >
                      Admin
                    </DashboardSmallCTA>
                  )
                  :
                  null
                }
                {employee.company_admin ? (

                  employee.id === user.id || employee.company_owner
                    ?

                    <NunitoSans
                      pr="4px"
                      fontSize={mobile ? "10px" : "12px"}
                      fontStyle="italic"
                      color="#2FD371"
                    >
                      {employee.company_owner ? 'Owner' : 'Manager'}
                    </NunitoSans>

                    : (

                      <DashboardSmallCTA
                        width="15%"
                        minWidth={["60px"]}
                        fontSize="10px"
                        innerHeight="20px"
                        ml="10px"
                        onClick={() => removeAdminClickHandler(employee)}
                        reversed={true}
                      // my="12px"
                      // height="34px"
                      >
                        Manager
                      </DashboardSmallCTA>
                    )


                ) : (

                  <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"
                    onClick={() => makeAdminClickHandler(employee)}
                    color="#979797"
                  // my="12px"
                  // height="34px"
                  >
                    Manager
                  </DashboardSmallCTA>
                )}

                {
                   systemSettings.rewards_active && companySettings.receive_incentives && user.id !== employee.id ?
                   (
                    employee.sales_rep
                    ?
                    <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"
                    onClick={() => removeSalesRep(employee)}
                    reversed={true}
                  >
                    Sales Rep
                  </DashboardSmallCTA>
                    :
                    <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"
                    onClick={() => makeSalesRep(employee)}
                    color="#979797"
                  >
                    Sales Rep
                  </DashboardSmallCTA>
                   )
                   :
                   null
                }

              </Flex>
            </AbsoluteDiv>
          )
            :
            mobile === false && employee.status === "suspended"
              ?
              (
                <AbsoluteDiv top="1px" right="0px" bottom="1px">
                  <Flex
                    height="100%"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ConfirmModal
                      modalOpen={removeModalOpen}
                      message="Are you sure you wish to remove this employee?"
                      handleCloseRequest={this._closeRemoveModal}
                      handleConfirm={this._handleRemoveConfirm}
                    />
                    {
                      user.id === employee.id ? null : (

                        <DashboardSmallCTA
                          width="15%"
                          minWidth={["60px"]}
                          fontSize="10px"
                          innerHeight="20px"
                          ml="10px"
                          onClick={() => {

                            this._openRemoveModal();
                          }}
                          color="#FE4A49"
                        // my="12px"
                        // height="34px"
                        >
                          Remove
                        </DashboardSmallCTA>
                      )}
                  </Flex>
                </AbsoluteDiv>
              )
              :
              null
          }



{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}



          {
            mobile === true && (employee.id === user.id || employee.company_owner)
              ?
              <AbsoluteDiv top="1px" right="0px" bottom="1px">
                <Flex
                  height="100%"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NunitoSans
                    pr="4px"
                    fontSize={mobile ? "10px" : "12px"}
                    fontStyle="italic"
                    color="#2FD371"
                  >
                    {employee.company_owner ? 'Owner' : 'Manager'}
                  </NunitoSans>
                </Flex>

              </AbsoluteDiv>
              :
              null

          }



{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}


          {mobile === false && employee.status === "pending" ? (
            <AbsoluteDiv top="1px" right="0px" bottom="1px">
              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="flexEnd"
                pr={["7px", 0]}
                py={["20px", 0]}
                alignItems="center"
              >
                <Flex flexDirection="row">
                  {/* <OutlineFlex
                    justifyContent="center"
                    borderRadius="8px"
                    borderThickness="1px"
                    alignItems="center"
                    color="#FE4A49"
                    onClick={() => removeClickHandler(employee)}
                  >
                    <Box px="12px" py="4px">
                      <NunitoSans
                        pt="3px"
                        fontSize="12px"
                        fontWeight={500}
                        color="#FE4A49"
                      >
                        DECLINE
                      </NunitoSans>
                    </Box>
                  </OutlineFlex> */}
                  <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"
                    color="#FE4A49"
                    onClick={() => removeClickHandler(employee)}

                  // my="12px"
                  // height="34px"
                  >
                    Decline
                  </DashboardSmallCTA>
                  {/* <OutlineFlex
                    ml="20px"
                    justifyContent="center"
                    borderRadius="8px"
                    alignItems="center"
                    color="#2FD371"
                    borderThickness="1px"
                    bg="white"
                    onClick={() => acceptClickHandler(employee)}
                  >
                    <Box px="12px" py="4px">
                      <NunitoSans
                        pt="3px"
                        fontSize="12px"
                        fontWeight={700}
                        color="#2FD371"
                      >
                        ACCEPT
                      </NunitoSans>
                    </Box>
                    
                  </OutlineFlex> */}
                  <DashboardSmallCTA
                    width="15%"
                    minWidth={["60px"]}
                    fontSize="10px"
                    innerHeight="20px"
                    ml="10px"

                    onClick={() => acceptClickHandler(employee)}

                  // my="12px"
                  // height="34px"
                  >
                    Accept
                  </DashboardSmallCTA>
                </Flex>
              </Flex>
            </AbsoluteDiv>
          ) : null}



{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}

          {/* {mobile === true && employee.status === "pending" ? (
            <AbsoluteDiv top="1px" right="0px" bottom="1px">
            <Flex
              height="100%"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Flex>
                <OutlineFlex
                  justifyContent="center"
                  borderRadius="8px"
                  borderThickness="1px"
                  alignItems="center"
                  onClick={() => removeClickHandler(employee)}
                  color="#FE4A49"
                >
                  <Box px="12px" py="4px">
                    <NunitoSans
                      pt="3px"
                      fontSize="12px"
                      fontWeight={700}
                      color="#FE4A49"
                    >
                      DECLINE
                    </NunitoSans>
                  </Box>
                </OutlineFlex>
                <OutlineFlex
                  ml="20px"
                  justifyContent="center"
                  borderRadius="8px"
                  alignItems="center"
                  color="#2FD371"
                  borderThickness="1px"
                  bg="white"
                  onClick={() => acceptClickHandler(employee)}
                >
                  <Box px="12px" py="4px">
                    <NunitoSans
                      pt="3px"
                      fontSize="12px"
                      fontWeight={700}
                      color="#blue"
                    >
                      ACCEPT
                    </NunitoSans>
                  </Box>
                </OutlineFlex>
              </Flex>
            </Flex>
            </AbsoluteDiv>
          ) : null} */}



{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}

          {mobile === false &&
            (employee.status === "declined" ||
              employee.status === "deleted") ? (
            <AbsoluteDiv top="1px" right="0px" bottom="1px">
              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="flexEnd"
                pr={["7px", 0]}
                py={["20px", 0]}
                alignItems="center"
              >
                <ConfirmModal
                  modalOpen={deleteModalOpen}
                  message="Are you sure you wish to delete this user?"
                  handleCloseRequest={this._closeDeleteModal}
                  handleConfirm={this._handleDeleteConfirm}
                />

                <DashboardSmallCTA
                  width="15%"
                  minWidth={["60px"]}
                  fontSize="10px"
                  innerHeight="20px"
                  ml="10px"
                  onClick={() => {
                    // if (
                    //   window.confirm(
                    //     "Are you sure you wish to delete this user?"
                    //   )
                    // ) {
                    //   return deleteClickHandler(employee);
                    // }

                    this._openDeleteModal();
                  }}
                  color="#FE4A49"

                // my="12px"
                // height="34px"
                >
                  Delete
                </DashboardSmallCTA>

                {employee.status != "invited" ? <Flex
                  ml="20px"
                  height="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <NunitoSans
                    pr="4px"
                    fontSize={mobile ? "10px" : "12px"}
                    fontStyle="italic"
                    color="#FE4A49"
                  >
                    Removed
                    {/* {employee.status.toUpperCase()} */}
                  </NunitoSans>
                </Flex> : null}






                {employee.status === "invited" ?
                  <Flex flexDirection="row">



                    <DashboardSmallCTA
                      width="15%"
                      minWidth={["60px"]}
                      fontSize="10px"
                      innerHeight="20px"
                      ml="10px"
                      onClick={() => resendClickHandler(employee)}

                    // my="12px"
                    // height="34px"
                    >
                      Resend
                    </DashboardSmallCTA>
                  </Flex>


                  : null}








              </Flex>
            </AbsoluteDiv>
          ) : null}




{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}

          {/* {mobile === true &&
          (employee.status === "declined" ||
            employee.status === "deleted" ||
            employee.status === "invited") ? (
              <AbsoluteDiv top="1px" right="0px" bottom="1px">
              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
              <Flex
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-end"
              >
                <NunitoSans
                  pr="4px"
                  fontSize={mobile ? "10px" : "12px"}
                  fontStyle="italic"
                  color="#FE4A49"
                >
                  REMOVED
                  
                </NunitoSans>
              </Flex>
            </Flex>
            </AbsoluteDiv>
          ) : null} */}



{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}
{/*                                                                    */}



        </ClickCursorFlex>

        {lastRow && hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            bottom="-1px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        </Flex>
    );
  }
}

export const SystemNotificationsRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%" overflow="scroll">


    {(typeof props.message.map === typeof function () { }) ? (props.message.map((message, i) => {
      return (
        (message.url) ? (<Box key={i + "1"}
          onClick={() => props.redirect(message.url)}
        ><SystemNotificationsRow
            key={i}
            message={message}
            props={props}
          />
        </Box>) : (<Box key={i + "2"}

        ><SystemNotificationsRow
            key={i}
            message={message}
            props={props}
          />
        </Box>)

      );
    })) : null}


  </Flex>
);



// Company Invite Sent Rows & Groups
//

export const CompanyInvitedRow = props => {

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [resendModalOpen, setResendModalOpen] = useState(false);

  const _openRemoveModal = () => setRemoveModalOpen(true);
  const _closeRemoveModal = () => setRemoveModalOpen(false);
  const _handleRemoveConfirm = () => {
    const { removeInvitation } = props;
    removeInvitation(props);
  }

  const _openResendModal = () => setResendModalOpen(true);
  const _closeResendModal = () => setResendModalOpen(false);
  const _handleResendConfirm = () => {
    const { resendInvitation } = props;
    resendInvitation(props);
  }

  return (
    <ClickCursorFlex
      height="68px"
      position="relative"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {props.firstRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
      {/*<Box ml="7px">
        <ImageTreatment
          img={props.company.logo || defaultCompany}
          blurRadius={"0px"}
          imageInset={0}
          borderRadius={4}
          innerBorderRadius={0}
          shadowOffset={0}
          padding={4}
          height={0}
          width={0}
        />
      </Box>*/
        //{props.status === "invited" ? (
      }
      <AbsoluteDiv
        overflow="hidden"
        left="0px"
        top="1px"
        bottom="1px"
        right="0px"
      >
        <Flex
          // width="1000%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <NunitoSans
            fontSize={["12px", "12px", "14px", "14px"]}
            fontWeight={300}
            color="darkText"
            pt={"5px"}
            lineHeight={1}
          >
            <strong>{props.person.first_name}</strong> <strong>{props.person.last_name}</strong>
            {/* {props.person.first_name + ' ' + props.person.last_name + ' ' + props.person.email } */}
          </NunitoSans>

          <NunitoSans
            fontSize={["12px", "12px", "14px", "14px"]}
            fontWeight={300}
            color="darkText"
            pt={"5px"}
            lineHeight={1}
          >
            {props.person.email}
            {/* {props.person.first_name + ' ' + props.person.last_name + ' ' + props.person.email } */}
          </NunitoSans>
          {/*<NunitoSans fontSize="8px" fontWeight={500} color="darkText">
            {props.company.location ? props.company.location.place_name : "N/A"}
          </NunitoSans>*/}
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <GradientUnderlayRightToLeft
          color="#FFFFFF"
          boxWidth="62px"
          gradientWidth="41px"
        />
      </AbsoluteDiv>
      <AbsoluteDiv right="0px" >
        <Flex flexDirection="row">
          <ConfirmModal
            modalOpen={removeModalOpen}
            message="Are you sure you want to remove this invitation?"
            handleCloseRequest={_closeRemoveModal}
            handleConfirm={_handleRemoveConfirm}
            mobile={props.mobile}
          />

          <ConfirmModal
            modalOpen={resendModalOpen}
            message="Are you sure you wish to resend this invitation?"
            handleCloseRequest={_closeResendModal}
            handleConfirm={_handleResendConfirm}
            mobile={props.mobile}
          />

          <DashboardSmallCTA
            width="15%"
            minWidth={["60px"]}
            fontSize="10px"
            innerHeight="20px"
            ml="10px"
            color="#FE4A49"
            onClick={
              () => {
                // console.log(props)
                // if (
                //   window.confirm("Are you sure you want to remove this invitation?")
                // ) {
                //   return props.removeInvitation(props);
                // }
                _openRemoveModal();
              }
            }

          // my="12px"
          // height="34px"
          >
            Remove
          </DashboardSmallCTA>

          <DashboardSmallCTA
            width="15%"
            minWidth={["60px"]}
            fontSize="10px"
            innerHeight="20px"
            ml="10px"
            onClick={() => _openResendModal()}
            disabled={props.person.email_bounced}
          >
            {props.person.email_bounced ? "Undelivered" : 'Resend'}
          </DashboardSmallCTA>

        </Flex>


      </AbsoluteDiv>
      {props.lastRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          bottom="-1px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
    </ClickCursorFlex>
  );
}

export const CompanyInvitedRowGroup = props => (

  <Flex overflow-x="hidden" flexDirection="column" width="100%">
    <div>

      {props.showHeader ? <RowGroupHeader groupName="INVITES" /> : null}



      {

        props.invited.length ? (
          <Flex flexDirection="column" width="100%">
            {props.invited.map((company, i) => {
              return (
                <CompanyInvitedRow
                  mobile={props.mobile}
                  key={i}
                  resendInvitation={props.resendInvitation}
                  removeInvitation={props.removeInvitation}
                  firstRow={i === 0}
                  lastRow={i === props.invited.length - 1}
                  hideFirstLastDash={props.hideFirstLastDash}
                  {...company}
                />
              );
            })}
          </Flex>
        ) : null

      }
    </div>



  </Flex>
);


/* DELETE ME
export class CompanyRow2 extends Component {
  _connectionStatus = () => {
    const { company2, connections, company } = this.props;

    let incomingRequest = _.findWhere(connections, {
      company_id: company.id,
      company_id: company.id
    });
    let outgoingRequest = _.findWhere(connections, {
      company_id: company.id,
      company_id: company.id
    });

    if (outgoingRequest && outgoingRequest.company_id === company.id) {
      return outgoingRequest.status;
    }

    const incomingStatuses = {
      requested: "actionRequired",
      active: "active",
      removed: "removed",
      declined: "declined"
    };

    if (incomingRequest && incomingRequest.company_id === company.id) {
      return incomingStatuses[incomingRequest.status];
    }

    return null;
  };

  _createConnection = async () => {
    const { createConnection, fetchConnections, company2, company } = this.props;
    await createConnection({
      company_id: company.id,
      company_id: company.id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchConnections({ company_id: company.id });
  };

  
  _updateConnection = async (status, companyId) => {
    const {
      connections,
      updateConnection,
      fetchConnections,
      company
    } = this.props;

    let filteredConnections = _.filter(
      connections,
      connection => connection.company_id !== connection.company_id
    );
    let incomingRequest = _.findWhere(filteredConnections, {
      company_id: companyId
    });
    let outgoingRequest = _.findWhere(filteredConnections, {
      company_id: companyId
    });

    let id;
    if (incomingRequest) {
      id = incomingRequest.id;
    } else {
      id = outgoingRequest.id;
    }

    await updateConnection(id, { status: status });

    fetchConnections({ company_id: company.id });
  };

  _redirect = id => {
    const { redirect } = this.props;

    return redirect(`/dashboard/company/${id}`);
  };

  render() {
    const { company, firstRow, hideFirstLastDash, mobile } = this.props;
    const connectionStatus = this._connectionStatus();
    
    let resultShifter = true;// to start off, show nothing at all
    if(typeof company.status ==="undefined"){
      resultShifter = false;//if there is no status set, show everything
    }
    if((company.status==="searchNotConnectedCompanies") &&(connectionStatus === null)){
      resultShifter = false;//show only the not connected companies
    }
    if((company.status==="searchPendingCompanies") &&(connectionStatus !== null)){
      resultShifter = false;//show only the pending companies
    }

    if(resultShifter === false){
      return (
        <Flex position="relative" flexDirection="column">
          <ClickCursorFlex
            height={["140px", "68px"]}
            minHeight={"68px"}
            position="relative"
            flexDirection={["column", "row"]}
            justifyContent="flex-start"
            alignItems={["flex-start", "center"]}
          >
            {firstRow && hideFirstLastDash ? null : (
              <OneSixDash
                position="absolute"
                top="0px"
                left="0px"
                right="0px"
                height="1px"
              />
            )}
            <Box ml="7px" pt={["25px", 0]}>
              { <ImageTreatment
                img={company.logo || defaultCompany}
                blurRadius={"0px"}
                imageInset={0}
                borderRadius={4}
                innerBorderRadius={0}
                shadowOffset={0}
                padding={4}
                height={40}
                width={40}
              /> }
            </Box>
            <AbsoluteDiv
              overflow="hidden"
              left={mobile ? "70px" : "85px"}
              top={["13px", "1px"]}
              height="68px"
              right="0px"
            >
              <Flex
                // width="1000%"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <NunitoSansSemiBold
                  fontSize="18px"
                  fontWeight="normal"
                  color="black"
                  pt={"5px"}
                  lineHeight={1}
                >
                  {company.name}
                </NunitoSansSemiBold>
                <NunitoSans fontSize="12px" fontWeight="normal" color="darkText">
                  {company.location ? company.location.place_name : null}
                </NunitoSans>
              </Flex>
            </AbsoluteDiv>
            <AbsoluteDiv top="1px" right="0px" height="68px">
              {connectionStatus === "active" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "106px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "requested" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "40px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "actionRequired" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "144px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
            </AbsoluteDiv>
            <AbsoluteDiv
              top={["68px", "1px"]}
              right={["auto", "0px"]}
              left={["7px", "auto"]}
              height="70px"
            >
              {this.state.companyID === company.id ? null : (
                <CompanyRowActions
                  createConnection={this._createConnection}
                  connectionStatus={connectionStatus}
                  updateConnection={this._updateConnection}
                  redirect={this._redirect}
                  company={company}
                />
              )}
            </AbsoluteDiv>
          </ClickCursorFlex>
        </Flex>
      );
    } else {
    return null;
  } 

    
  }
}*/

// const CompanyRowActions = props => {
//   return (
//     <Flex
//       height="100%"
//       flexDirection="row"
//       justifyContent="center"
//       alignItems="center"
//     >
//       {props.connectionStatus === "active" ? (
//         <Flex flexDirection="row">
//         {props.company.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID ? null : 
//         <OutlineFlex
//             justifyContent="center"
//             borderRadius="8px"
//             borderThickness="1px"
//             alignItems="center"
//             onClick={() => {
//               // if (
//               //   window.confirm("Are you sure you wish to remove this company?")
//               // ) {
//               //   return props.updateConnection("removed", props.company.id);
//               // }
//             }}
//             color="#FE4A49"
//           >

//              <Box px="12px" py="4px">
//             <NunitoSans
//               pt="3px"
//               fontSize="12px"
//               fontWeight={500}
//               color="#FE4A49"
//             >
//               REMOVE
//             </NunitoSans>
//           </Box>


//           </OutlineFlex>
//         }

//           <OutlineFlex
//             ml="20px"
//             justifyContent="center"
//             borderRadius="8px"
//             alignItems="center"
//             color="#979797"
//             borderThickness="1px"
//             onClick={() => props.redirect(props.company.id)}
//             bg="white"
//           >
//             <Box px="12px" py="4px">
//               <NunitoSans
//                 pt="3px"
//                 fontSize="12px"
//                 fontWeight={700}
//                 color="#979797"
//               >
//                 VIEW
//               </NunitoSans>
//             </Box>
//           </OutlineFlex>
//         </Flex>
//       ) : null}
//       {props.connectionStatus === "requested" ? (
//         <NunitoSansSemiBoldItalic pr="4px" fontSize="14px" fontStyle="normal" color="#2FD371">
//           PENDING
//         </NunitoSansSemiBoldItalic>
//       ) : null}
//       {/* {props.connectionStatus === "removed" ||
//       props.connectionStatus === "declined" ? (
//         <NunitoSans pr="4px" fontSize="14px" fontStyle="italic" color="#a3aa9e" fontWeight="600">
//           INACTIVE
//         </NunitoSans>
//       ) : null} */}
//       {props.connectionStatus === "actionRequired" ? (
//         <Flex flexDirection="row">
//           {/* <OutlineFlex
//             justifyContent="center"
//             borderRadius="8px"
//             borderThickness="1px"
//             alignItems="center"
//             color="#FE4A49"
//             onClick={() => props.updateConnection("declined", props.company.id)}
//           >
//             <Box px="12px" py="4px">
//               <NunitoSans
//                 pt="3px"
//                 fontSize="12px"
//                 fontWeight={500}
//                 color="#FE4A49"
//               >
//                 DECLINE
//               </NunitoSans>
//             </Box>
//           </OutlineFlex> */}
//           <DashboardSmallCTA
//                 width="15%"
//                 minWidth={["74px"]}
//                 fontSize="12px"
//                 innerHeight="30px"
//                 ml="20px"
//                 color="#FE4A49"
//                 onClick={() => props.updateConnection("declined", props.company.id)}

//                 // my="12px"
//                 // height="34px"
//               >
//                 DECLINE
//               </DashboardSmallCTA>

//           {/* <OutlineFlex
//             ml="20px"
//             justifyContent="center"
//             borderRadius="8px"
//             alignItems="center"
//             color="#2FD371"
//             borderThickness="1px"
//             bg="white"
//             onClick={() => props.updateConnection("active", props.company.id)}
//           >
//             <Box px="12px" py="4px">
//               <NunitoSans
//                 pt="3px"
//                 fontSize="12px"
//                 fontWeight={700}
//                 color="#2FD371"
//               >
//                 ACCEPT
//               </NunitoSans>
//             </Box>
//           </OutlineFlex> */}
//           <DashboardSmallCTA
//                 width="15%"
//                 minWidth={["74px"]}
//                 fontSize="12px"
//                 innerHeight="30px"
//                 ml="20px"
//                 // color="#FE4A49"
//                 onClick={() => props.updateConnection("active", props.company.id)}

//                 // my="12px"
//                 // height="34px"
//               >
//                 ACCEPT
//               </DashboardSmallCTA>
//         </Flex>
//       ) : null}
//       {!props.connectionStatus || props.connectionStatus === "removed" ||
//       props.connectionStatus === "declined" ? (
//         <Flex flexDirection="row" justifyContent="center" alignItems="center">
//           <VendorRequest
//             onClick={props.createConnection}
//             width="120px"
//             minWidth={["90px"]}
//             my="12px"
//             height="34px"
//           >
//             CONNECT
//           </VendorRequest>{/* CompanyRequest*/}
//         </Flex>
//       ) : null}
//     </Flex>
//   );
// };

export const CompanyRowGroup2 = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    <CompanyRow
      connections={props.connections}
      createConnection={props.createConnection}
      fetchConnections={props.fetchConnections}
      updateConnection={props.updateConnection}
      company={props.company}
      firstRow={props.firstRow}
      lastRow={props.lastRow}
      hideFirstLastDash={props.hideFirstLastDash}
      mobile={props.mobile}
      redirect={props.redirect}
    />
  </Flex>
);


// Vendor Invite Sent Rows & Groups
//

export const VendorInvitedRow = props => (

  (!props.vendor) ? null : (
    <ClickCursorFlex
      height="68px"
      position="relative"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {props.firstRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
      {/* {console.log(props)} */}
      <Box ml="7px">
        <ImageTreatment
          img={props.vendor.logo || defaultCompany(props.vendor.id, props.vendor.name)}
          blurRadius={"0px"}
          imageInset={0}
          borderRadius={4}
          innerBorderRadius={0}
          shadowOffset={0}
          padding={4}
          height={40}
          width={40}
        />
      </Box>
      <AbsoluteDiv
        overflow="hidden"
        left="85px"
        top="1px"
        bottom="1px"
        right="0px"
      >
        <Flex
          // width="1000%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <NunitoSans
            fontSize="14px"
            fontWeight={300}
            color="darkText"
            pt={"5px"}
            lineHeight={1}
          >
            {props.vendor.name || ""}
          </NunitoSans>
          <NunitoSans fontSize="8px" fontWeight={500} color="darkText">
            {props.vendor.location ? props.vendor.location.place_name : "N/A"}
          </NunitoSans>
        </Flex>
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <GradientUnderlayRightToLeft
          color="#FFFFFF"
          boxWidth="62px"
          gradientWidth="41px"
        />
      </AbsoluteDiv>
      <AbsoluteDiv top="1px" right="0px" bottom="1px">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <NunitoSans
            pr="4px"
            fontSize={props.mobile ? "10px" : "12px"}
            fontStyle="italic"
            color="#FE4A49"
          >
            PENDING
          </NunitoSans>
        </Flex>
      </AbsoluteDiv>
      {props.lastRow && props.hideFirstLastDash ? null : (
        <OneSixDash
          position="absolute"
          bottom="-1px"
          left="0px"
          right="0px"
          height="1px"
        />
      )}
    </ClickCursorFlex>
  )
);

export const VendorInvitedRowGroup = props => (
  <Flex flexDirection="column" width="100%">
    {props.invited.length ? (
      <div>
        <RowGroupHeader groupName="INVITED" />
        <Flex flexDirection="column" width="100%">
          {props.invited.map((company, i) => {
            return (
              <VendorInvitedRow
                mobile={props.mobile}
                key={i}
                firstRow={i === 0}
                lastRow={i === props.invited.length - 1}
                hideFirstLastDash={props.hideFirstLastDash}
                {...company}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);

export class VendorRow extends Component {
  state = {
    showGroups: false,
    updatedGroups: this.props.vendorGroups,
    companyID: -1,
    processing: false
  }

  componentDidMount() {
    this.setState({
      companyID: getUser().company_id
    });

  }
  // UN-SAFE_compon--entWillMount(){
  //   this.setState({connectionStatus: })
  // }


  groupClick = async (group, vendor) => {
    const { search, fetchConnections, updateCompany, company } = this.props;
    const { updatedGroups, showGroups } = this.state;
    let groupsCopy = updatedGroups.slice();
    // console.log("groupClick")

    let existing = _.findIndex(updatedGroups, { id: group.id });
    if (existing !== -1) {
      groupsCopy.splice(existing, 1);
    } else {
      groupsCopy.push({
        id: group.id,
        company_id: group.company_id,
        name: group.name
      });
    }

    await updateCompany(vendor.id, {
      groups: JSON.stringify(groupsCopy)
    }).then(vendor => {
      this.setState({
        updatedGroups: vendor.payload.groups
      });
    });

    // this.setState({showGroups: !showGroups})

    // history.push(`/dashboard/company/${getUser().company_id}/vendors`)

    // fetchConnections({ company_id: company.id });
  };
  _connectionStatus = () => {
    const { company, connections, vendor } = this.props;

    let incomingRequest = _.findWhere(connections, {
      vendor_id: getUser().company_id,
      company_id: vendor.id
    });
    let outgoingRequest = _.findWhere(connections, {
      company_id: getUser().company_id,
      vendor_id: vendor.id
    });


    if (outgoingRequest && outgoingRequest.vendor_id === vendor.id) {
      return outgoingRequest.status;
    }

    const incomingStatuses = {
      requested: "actionRequired",
      active: "active",
      removed: "removed",
      declined: "declined"
    };

    if (incomingRequest && incomingRequest.company_id === vendor.id) {
      return incomingStatuses[incomingRequest.status];
    }

    return null;
  };

  _createConnection = async () => {
    const { createConnection, fetchConnections, fetchNotificationCount, updateConnection, vendor, company, notifySuccess, fetchConnectionSuggestions } = this.props;
    this.setState({ processing: true });
    const result = await createConnection({
      vendor_id: vendor.id,
      company_id: company.id,
      user_id: getUser().id,
      status: "requested"
    }).then(res => res.payload);

    dismissSuggestionDuringSession(vendor.id);

    fetchConnectionSuggestions({ company_id: getUser().company_id });

    if (result && result.presentConnection && result.presentConnection.id) {
      if (result.presentConnection.status === 'requested') {
        const vendorId = result.presentConnection.vendor_id === getUser().company_id ? result.presentConnection.company_id : result.presentConnection.vendor_id;
        await updateConnection(result.presentConnection.id, { status: 'active', index: "companyIndex", vendor_id: vendorId, company_id: getUser().company_id });
      } else {
        //active connection
        notifySuccess("Connection is already active.")
      }
    }

    await fetchConnections({ company_id: company.id });
    fetchNotificationCount();
    this.setState({ processing: false });
  };



  _showGroupsHandler = () => {
    this.setState({ showGroups: !this.state.showGroups })
  }

  _updateConnection = async (status, vendorId) => {
    const {
      connections,
      updateConnection,
      fetchConnections,
      company,
      fetchNotificationCount
    } = this.props;

    this.setState({ processing: true });

    let filteredConnections = _.filter(
      connections,
      connection => connection.company_id !== connection.vendor_id
    );
    let incomingRequest = _.findWhere(filteredConnections, {
      vendor_id: vendorId
    });
    let outgoingRequest = _.findWhere(filteredConnections, {
      company_id: vendorId
    });

    let id;
    if (incomingRequest) {
      id = incomingRequest.id;
    } else {
      id = outgoingRequest.id;
    }

    await updateConnection(id, { status: status, index: "companyIndex", vendor_id: vendorId, company_id: getUser().company_id });

    await fetchConnections({ company_id: company.id });

    fetchNotificationCount();

    this.setState({ processing: false });
  };

  _redirect = vendor => {
    const { redirect } = this.props;

    return redirect(`/dashboard/${vendor.is_single_user_company ? 'profile' : 'company'}/${vendor.is_single_user_company ? vendor.user_id : vendor.id}?companies`);
  };

  _remindConnectionRequest = vendorId => {
    const {
      connections,
      notifySuccess
    } = this.props;



    let filteredConnections = _.filter(
      connections,
      connection => connection.company_id !== connection.vendor_id
    );
    let incomingRequest = _.findWhere(filteredConnections, {
      vendor_id: vendorId
    });
    let outgoingRequest = _.findWhere(filteredConnections, {
      company_id: vendorId
    });

    let id;
    if (incomingRequest) {
      id = incomingRequest.id;
    } else {
      id = outgoingRequest.id;
    }

    fetch(`${process.env.REACT_APP_API_URL}/sendconnectionrequest?connection_id=${id}`);
    return notifySuccess('CONNECTION REQUEST REMINDER HAS BEEN SENT.');
  }

  render() {
    const { vendor, firstRow, hideFirstLastDash, mobile, companyGroups, company, settings } = this.props;
    const { updatedGroups, showGroups, processing } = this.state;
    const connectionStatus = vendor.connectionStatus;

    let resultShifter = true;// to start off, show nothing at all
    if (typeof vendor.status === "undefined") {
      resultShifter = false;//if there is no status set, show everything
    }
    if ((vendor.status === "searchNotConnectedCompanies") && (connectionStatus === null)) {
      resultShifter = false;//show only the not connected companies
    }
    if ((vendor.status === "searchPendingCompanies") && (connectionStatus !== null)) {
      resultShifter = false;//show only the pending companies
    }


    if (resultShifter === false) {
      return (
        <Flex position="relative" flexDirection="column" width="95%">
          <Flex
            height={["140px", "68px"]}
            minHeight={"68px"}
            position="relative"
            flexDirection={["column", "row"]}
            justifyContent="flex-start"
            alignItems={["flex-start", "center"]}>
            {firstRow && hideFirstLastDash ? null : (
              <OneSixDash
                position="absolute"
                top="0px"
                left="0px"
                right="0px"
                height="1px"
              />
            )}
            <Box>
              <Box ml="7px" pt={["25px", 0]} onClick={() => this._redirect(vendor)} style={{ cursor: 'pointer' }} >
                <ImageTreatment
                  img={vendor.logo || (vendor.is_single_user_company ? defaultProfile(vendor.id, vendor.name) : defaultCompany(vendor.id, vendor.name))}
                  blurRadius={"0px"}
                  imageInset={0}
                  borderRadius={vendor.is_single_user_company ? 22 : 4}
                  innerBorderRadius={vendor.is_single_user_company ? 20 : 0}
                  shadowOffset={0}
                  padding={4}
                  height={40}
                  width={40}

                />
              </Box>
              <AbsoluteDiv
                overflow="hidden"
                left={mobile ? "70px" : "85px"}
                top={["13px", "1px"]}
                height="68px"
                right="0px">
                <Flex
                  // width="1000%"
                  height="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Flex
                    alignItems="center"
                  >
                    <NunitoSansSemiBold
                      fontSize="18px"
                      fontWeight="normal"
                      color="black"
                      pt={"5px"}
                      lineHeight={1}
                      onClick={() => this._redirect(vendor)}
                      style={{ cursor: 'pointer' }}
                    >
                      {vendor.name}
                    </NunitoSansSemiBold>
                    {/* {
                      vendor.paid
                      ?
                      <IconBox
                      fontSize="12px"
                      color={colors.kickpostPlus}
                      style={{marginLeft:'5px'}}
                      >
                      kickpost_plus
                    </IconBox>
                    :
                    null
                    } */}

                  
                  </Flex>
                  <NunitoSans fontSize="12px" fontWeight="normal" color="darkText" style={{ "overflow": "hidden", "textOverflow": "ellipsis", width: "20vw", "whiteSpace": "nowrap" }}>
                    {vendor.is_single_user_company ? vendor.pseudoName : vendor.location ? vendor.location.place_name : null}
                  </NunitoSans>
                  {
                    vendor.is_single_user_company
                      ?
                      null
                      :
                      <NunitoSans fontSize="12px" fontWeight="normal" color="darkText">
                        {`${vendor.total_employee_count} ${vendor.total_employee_count > 1 ? "people" : "person"}`}
                      </NunitoSans>
                  }
                </Flex>
              </AbsoluteDiv>
            </Box>
            <AbsoluteDiv top="1px" right="0px" height="68px">
              {connectionStatus === "active" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "106px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "requested" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "40px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "actionRequired" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "144px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
            </AbsoluteDiv>
            <AbsoluteDiv
              top={["68px", "1px"]}
              right={["auto", "0px"]}
              left={["7px", "auto"]}
              height="70px">
              {getUser().company_id === vendor.id ? null : (
                <VendorRowActions
                  sendReminder={this._remindConnectionRequest}
                  createConnection={this._createConnection}
                  connectionStatus={connectionStatus}
                  updateConnection={this._updateConnection}
                  showGroupsHandler={this._showGroupsHandler}
                  handlePartnerModal={this.props.handlePartnerModal}
                  redirect={this._redirect}
                  vendor={vendor}
                  company={company}
                  settings={settings}
                  processing={processing}
                />
              )}
            </AbsoluteDiv>
          </Flex>
          {/* {true && showGroups && connectionStatus === 'active' ? (
            <Flex
              mb="20px"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {updatedGroups.length ? null : (
                <Box width={["100%", "45%", "30%"]} my="10px" mr="0px" key={0}>
                  <GroupNotSpecifiedBubble
                    active={updatedGroups.length === 0}
                  />
                </Box>
              )}
              {companyGroups.map((group, i) => {
                return (
                  <Box
                    key={i + 1}
                    onClick={() => {
                      this.groupClick(group, vendor);
                    }}
                    width={["100%", "45%", "30%"]}
                    mr="0px"
                    my="10px"
                  >
                    <GroupBubble
                      {...group}
                      active={
                        updatedGroups &&
                        _.pluck(updatedGroups, "id").includes(group.id)
                      }
                    />
                  </Box>
                );
              })}
            </Flex>
          ) : null} */}
        </Flex>
      );
    } else {
      return null;
    }


  }
}

/*export class VendorRowConnectionStatus extends Component {
  _connectionStatus = () => {
    const { company, connections, vendor, people } = this.props;

    
    console.log(connections);
    console.log(people);
    console.log(vendor);

    let incomingRequest = _.findWhere(connections, {
      vendor_id: company.id,
      company_id: vendor.id
    });
    let outgoingRequest = _.findWhere(connections, {
      company_id: company.id,
      vendor_id: vendor.id
    });

    if (outgoingRequest && outgoingRequest.vendor_id === vendor.id) {
      return outgoingRequest.status;
    }

    const incomingStatuses = {
      invited: "invited",
      requested: "actionRequired",
      active: "active",
      removed: "removed",
      declined: "declined"
    };

    if (incomingRequest && incomingRequest.company_id === vendor.id) {
      return incomingStatuses[incomingRequest.status];
    }

    return null;
  };

  _createConnection = async () => {
    const { createConnection, fetchConnections, vendor, company } = this.props;
    await createConnection({
      vendor_id: vendor.id,
      company_id: company.id,
      user_id: getUser().id,
      status: "requested"
    });

    fetchConnections({ company_id: company.id });
  };

  _updateConnection = async (status, vendorId) => {
    const {
      connections,
      updateConnection,
      fetchConnections,
      company
    } = this.props;

    let filteredConnections = _.filter(
      connections,
      connection => connection.company_id !== connection.vendor_id
    );
    let incomingRequest = _.findWhere(filteredConnections, {
      vendor_id: vendorId
    });
    let outgoingRequest = _.findWhere(filteredConnections, {
      company_id: vendorId
    });

    let id;
    if (incomingRequest) {
      id = incomingRequest.id;
    } else {
      id = outgoingRequest.id;
    }

    await updateConnection(id, { status: status });

    fetchConnections({ company_id: company.id });
  };

  _redirect = id => {
    const { redirect } = this.props;

    return redirect(`/dashboard/company/${id}`);
  };

  render() {
    const { vendor, firstRow, hideFirstLastDash, mobile } = this.props;
    console.log(vendor)
    const connectionStatus = this._connectionStatus();
    
    let resultShifter = true;// to start off, show nothing at all
    if(typeof vendor.status ==="undefined"){
      resultShifter = false;//if there is no status set, show everything
    }
    if((vendor.status==="searchNotConnectedCompanies") &&(connectionStatus === null)){
      resultShifter = false;//show only the not connected companies
    }
    if((vendor.status==="searchPendingCompanies") &&(connectionStatus !== null)){
      resultShifter = false;//show only the pending companies
    }

    if(resultShifter === false){
      return (
        <Flex position="relative" flexDirection="column">
          <ClickCursorFlex
            height={["140px", "68px"]}
            minHeight={"68px"}
            position="relative"
            flexDirection={["column", "row"]}
            justifyContent="flex-start"
            alignItems={["flex-start", "center"]}
          >
            {firstRow && hideFirstLastDash ? null : (
              <OneSixDash
                position="absolute"
                top="0px"
                left="0px"
                right="0px"
                height="1px"
              />
            )}
            <AbsoluteDiv
              overflow="hidden"
              left={mobile ? "70px" : "25px"}
              top={["13px", "1px"]}
              height="68px"
              right="0px"
            >
              <Flex
                // width="1000%"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <NunitoSansSemiBold
                  fontSize="18px"
                  fontWeight="normal"
                  color="black"
                  pt={"5px"}
                  lineHeight={1}
                >
                  {vendor.name}
                </NunitoSansSemiBold>
                <NunitoSans fontSize="12px" fontWeight="normal" color="darkText">
                  {vendor.location ? vendor.location.place_name : null}
                </NunitoSans>
              </Flex>
            </AbsoluteDiv>
            <AbsoluteDiv top="1px" right="0px" height="68px">
              {connectionStatus === "active" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "106px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "requested" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "40px"}
                  gradientWidth="30px"
                />
              ) : null}
              {connectionStatus === "actionRequired" ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "144px"}
                  gradientWidth="30px"
                />
              ) : null}
              {!connectionStatus ? (
                <GradientUnderlayRightToLeft
                  color="#FFFFFF"
                  boxWidth={this.props.mobile ? "25px" : "160px"}
                  gradientWidth="30px"
                />
              ) : null}
            </AbsoluteDiv>
            <AbsoluteDiv
              top={["68px", "1px"]}
              right={["auto", "0px"]}
              left={["7px", "auto"]}
              height="70px"
            >
              {getUser().company_id === vendor.id ? null : (
                <VendorRowActions2
                  createConnection={this._createConnection}
                  connectionStatus={connectionStatus}
                  updateConnection={this._updateConnection}
                  redirect={this._redirect}
                  vendor={vendor}
                />
              )}
            </AbsoluteDiv>
          </ClickCursorFlex>
        </Flex>
      );
    } else {
    return null;
  } 

    
  }
}*/



const VendorRowActions = props => {

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [removeModalMessage, setRemoveModalMessage] = useState("Are you sure you want to disconnect?");

  const _openRemoveModal = (customMessage = "Are you sure you want to disconnect?") => {
    setRemoveModalMessage(customMessage);
    setRemoveModalOpen(true);
  };
  const _closeRemoveModal = () => setRemoveModalOpen(false);
  const _handleRemoveConfirm = () => props.updateConnection("delete", props.vendor.id);


  if (props.processing) {
    return (
      <Flex
        height="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <FontAwesomeIcon

          icon={faCog}
          color={"#2FD371"}
          style={{ fontSize: '25px', marginRight: '5px' }}
          spin
        />

        <NunitoSans
          fontSize="14px"
        >
          Processing your request...
        </NunitoSans>
      </Flex>
    );
  }

  return (
    <Flex
      height="100%"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <ConfirmModal
        modalOpen={removeModalOpen}
        message={removeModalMessage}
        handleCloseRequest={_closeRemoveModal}
        handleConfirm={_handleRemoveConfirm}
      />
      {

        props.connectionStatus === "active" ? (
          <Flex flexDirection="row">
            {
              getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin && props.settings.rewards_active && props.settings.product_active
                ?
                <DashboardSmallCTA
                  width="15%"
                  minWidth={["60px"]}
                  fontSize="10px"
                  innerHeight="20px"
                  ml="10px"
                  color={colors.buttonCustom}
                  onClick={() => props.handlePartnerModal(props.vendor)}
                >
                  {props.vendor.partner_ids.length ? "Edit Ids" : "Add Ids"}
                </DashboardSmallCTA>
                :
                null
            }
            {props.vendor.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID ? null :

              <DashboardSmallCTA
                width="15%"
                minWidth={["60px"]}
                fontSize="10px"
                innerHeight="20px"
                ml="10px"
                color="#FE4A49"
                onClick={() => {
                  // if (
                  //   window.confirm("Are you sure you wish to remove this vendor?")
                  // ) {
                  //   return props.updateConnection("delete", props.vendor.id);
                  // }

                  _openRemoveModal();
                }}

              // my="12px"
              // height="34px"
              >
                Remove
              </DashboardSmallCTA>
            }

            {/* <OutlineFlex
            ml="20px"
            justifyContent="center"
            borderRadius="8px"
            alignItems="center"
            color="#979797"
            borderThickness="1px"
            onClick={() => props.redirect(props.vendor.id+"?companies")}
            bg="white"
          >
            <Box px="12px" py="4px">
              <NunitoSans
                pt="3px"
                fontSize="12px"
                fontWeight={700}
                color="#979797"
              >
                VIEW
              </NunitoSans>
            </Box>
          </OutlineFlex> */}
            {/* <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#979797"
                onClick={() => props.redirect(props.vendor)}
              
              >
                VIEW
              </DashboardSmallCTA> */}
            {/* {props.company.paid ? 
              <DashboardSmallCTA
                width="15%"
                minWidth={["110px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                onClick={() => props.showGroupsHandler()}
                color="#979797"    
                // my="12px"
                // height="34px"
              >
                GROUPS
              </DashboardSmallCTA>
              :
              null} */}
          </Flex>
        ) : null}
      {props.connectionStatus === "requested" ? (
        <>
          <NunitoSansSemiBoldItalic pr="4px" fontSize="14px" fontStyle="normal" color="#2FD371">
            PENDING
          </NunitoSansSemiBoldItalic>

          {/* <DashboardSmallCTA
        width="15%"
        minWidth={["74px"]}
        fontSize="12px"
        innerHeight="30px"
        ml="20px"
        color="#2FD371"
        onClick={() => props.sendReminder(props.vendor.id)}
        
        >
        REMIND
        </DashboardSmallCTA> */}

          {/* <DashboardSmallCTA
         width="15%"
         minWidth={["74px"]}
         fontSize="12px"
         innerHeight="30px"
         ml="20px"
         color="#979797"
         onClick={() => props.redirect(props.vendor)}
         
        >
           VIEW
        </DashboardSmallCTA> */}

          {props.vendor.id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID ? null :

            <DashboardSmallCTA
              width="15%"
              minWidth={["60px"]}
              fontSize="10px"
              innerHeight="20px"
              ml="10px"
              color="#FE4A49"
              onClick={() => {
                // if (
                //   window.confirm("Are you sure you wish to remove this vendor?")
                // ) {
                //   return props.updateConnection("delete", props.vendor.id);
                // }

                _openRemoveModal("Are you sure to remove this connection request?");
              }}

            // my="12px"
            // height="34px"
            >
              Remove
            </DashboardSmallCTA>
          }
        </>
      ) : null}
      {/* {props.connectionStatus === "removed" ||
      props.connectionStatus === "declined" ? (
        <NunitoSans pr="4px" fontSize="14px" fontStyle="italic" color="#a3aa9e" fontWeight="600">
          INACTIVE
        </NunitoSans>
      ) : null} */}
      {props.connectionStatus === "actionRequired" ? (
        <Flex flexDirection="row">
          {/* <OutlineFlex
            justifyContent="center"
            borderRadius="8px"
            borderThickness="1px"
            alignItems="center"
            color="#FE4A49"
            onClick={() => props.updateConnection("declined", props.vendor.id)}
          >
            <Box px="12px" py="4px">
              <NunitoSans
                pt="3px"
                fontSize="12px"
                fontWeight={500}
                color="#FE4A49"
              >
                DECLINE
              </NunitoSans>
            </Box>
          </OutlineFlex> */}
          <DashboardSmallCTA
            width="15%"
            minWidth={["60px"]}
            fontSize="10px"
            innerHeight="20px"
            ml="10px"
            color="#FE4A49"
            onClick={() => props.updateConnection("declined", props.vendor.id)}

          // my="12px"
          // height="34px"
          >
            Ignore
          </DashboardSmallCTA>
          {/* <OutlineFlex
            ml="20px"
            justifyContent="center"
            borderRadius="8px"
            alignItems="center"
            color="#2FD371"
            borderThickness="1px"
            bg="white"
            onClick={() => props.updateConnection("active", props.vendor.id)}
          >
            <Box px="12px" py="4px">
              <NunitoSans
                pt="3px"
                fontSize="12px"
                fontWeight={700}
                color="#2FD371"
              >
                ACCEPT
              </NunitoSans>
            </Box>
          </OutlineFlex> */}
          <DashboardSmallCTA
            width="15%"
            minWidth={["60px"]}
            fontSize="10px"
            innerHeight="20px"
            ml="10px"
            // color="#FE4A49"
            onClick={() => props.updateConnection("active", props.vendor.id)}

          // my="12px"
          // height="34px"
          >
            Accept
          </DashboardSmallCTA>
        </Flex>
      ) : null}
      {((!props.connectionStatus || props.connectionStatus === "removed" ||
        props.connectionStatus === "declined" ) && (props.vendor.id !== "53faf5ba-91a5-4667-b260-bda72f6b783d"))? (
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <DashboardSmallCTA
            onClick={props.createConnection}
            width="15%"
            minWidth={["60px"]}
            fontSize="10px"
            innerHeight="20px"
            ml="10px"
            color="#2FD371"
            disabled={props.connectionStatus && props.connectionStatus === "declined"}
            title={props.connectionStatus && props.connectionStatus === "declined" ? `Ignored for ${props.vendor.declinedFor} days` : "Connect"}
          >
            Connect
          </DashboardSmallCTA>
          {/* <DashboardSmallCTA
                width="15%"
                minWidth={["74px"]}
                fontSize="12px"
                innerHeight="30px"
                ml="20px"
                color="#979797"
                onClick={() => props.redirect(props.vendor)}
                
              >
                VIEW
              </DashboardSmallCTA> */}
        </Flex>
      ) : null}
      {props.vendor.id !== process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_id === process.env.REACT_APP_SYSTEM_OWNER_COMPANY_ID && getUser().company_admin ?
        <DashboardSmallCTA
          width="15%"
          minWidth={["60px"]}
          fontSize="10px"
          innerHeight="20px"
          ml="10px"
          color="#FE4A49"
          onClick={() => {
            const keyword = window.prompt("Enter the keyword to continue with permanent deletion of this company.")
            if (
              keyword === process.env.REACT_APP_DELETE_COMPANY_KEYWORD
            ) {
              const payload = {
                keyword: keyword
              }
              axios.put(`${process.env.REACT_APP_API_URL}/companies/${props.vendor.id}`, payload).then(res => console.log(res)).catch(err => console.log(err));
            } else {
              alert("Incorrect Keyword!");
            }
          }}

        // my="12px"
        // height="34px"
        >
          Delete
        </DashboardSmallCTA>
        :
        null
      }
    </Flex>
  );
};
export const VendorRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    <VendorRow
      updateSearch={props.updateSearch}
      vendorGroups={props.vendor.groups || []}
      companyGroups={props.groups || []}
      company={props.company}
      connections={props.connections}
      createConnection={props.createConnection}
      fetchConnections={props.fetchConnections}
      // fetchCompanies={}
      updateConnection={props.updateConnection}
      updateCompany={props.updateCompany}
      vendor={props.vendor}
      firstRow={props.firstRow}
      lastRow={props.lastRow}
      hideFirstLastDash={props.hideFirstLastDash}
      mobile={props.mobile}
      redirect={props.redirect}
      notifySuccess={props.notifySuccess}
    />
  </Flex>
);

/*
export const InvitationRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
  {console.log(props)}
    <VendorRowConnectionStatus
      people={props.people || {}}
      company={props.company}
      connections={props.connections}
      createConnection={props.createConnection}
      fetchConnections={props.fetchConnections}
      updateConnection={props.updateConnection}
      removeInvitation={props.removeInvitation}
      resendInvitation={props.resendInvitation}
      vendor={props.vendor}
      firstRow={props.firstRow}
      lastRow={props.lastRow}
      hideFirstLastDash={props.hideFirstLastDash}
      mobile={props.mobile}
      redirect={props.redirect}
    />
  </Flex>
);*/

//DepartmentLogoNoShadowImage

export const DepartmentRow = ({ ...props }) => {

  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const _openRemoveModal = () => setRemoveModalOpen(true);
  const _closeRemoveModal = () => setRemoveModalOpen(false);
  const _handleRemoveConfirm = () => props.removeClickHandler();


  return (
    <Flex position="relative" flexDirection="column">
      <Flex
        height={["auto", "68px"]}
        minHeight={"68px"}
        position="relative"
        flexDirection={["column", "row"]}
        justifyContent="flex-start"
        alignItems={["flex-start", "center"]}
      >
        {props.firstRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml="7px" pt={["13px", 0]}>
          <DepartmentLogoNoShadowImage height={40} width={40} />
        </Box>
        <AbsoluteDiv
          overflow="hidden"
          left={props.mobile ? "70px" : "85px"}
          top="1px"
          height="68px"
          zIndex={8}
          right="0px"
        >
          <Flex
            // width="1000%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <NunitoSansSemiBold
              fontSize={["14px", "14px", "18px", "18px"]}
              fontWeight="normal"
              color="black"
              pt={"5px"}
              lineHeight={1}
            >
              {props.name}
            </NunitoSansSemiBold>
            {props.total_employee_count ? (
              <NunitoSans
                fontSize={["10px", "10px", "12px", "12px"]}
                fontWeight="normal"
                color="black"
              >
                {props.total_employee_count}{" "}
                {parseInt(props.total_employee_count) === 1 ? "Person" : "People"}
              </NunitoSans>

            ) : null}
            {
              props.created_by
                ?
                <NunitoSans
                  fontSize={["10px", "10px", "12px", "12px"]}
                  fontWeight="normal"
                  color="gray"
                  lineHeight={1.3}
                >
                  {props.created_by.first_name + ' ' + props.created_by.last_name}
                </NunitoSans>
                :
                null
            }
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv zIndex={9} top="1px" right="0px" height="68px">
          <GradientUnderlayRightToLeft
            color="#FFFFFF"
            boxWidth={props.mobile ? "86px" : "86px"}
            gradientWidth="30px"
          />
        </AbsoluteDiv>
        <AbsoluteDiv zIndex={10} top="1px" right="0px" bottom="1px">
          {!props.editDepartmentMode
            ?
            (

              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Flex flexDirection="row">
                  <ConfirmModal
                    modalOpen={removeModalOpen}
                    message="Are you sure you wish to remove this department?"
                    handleCloseRequest={_closeRemoveModal}
                    handleConfirm={_handleRemoveConfirm}
                  />

                  <DashboardSmallCTA
                    width="15%"
                    onClick={() => {
                      if (props.removeClickHandler) {
                        // if (
                        //   window.confirm(
                        //     "Are you sure you wish to remove this department?"
                        //   )
                        // ) {
                        //   return props.removeClickHandler();
                        // }
                        return _openRemoveModal();
                      }
                    }}
                    color="#FE4A49"
                    minWidth={["74px"]}
                    fontSize="12px"
                    innerHeight="30px"
                    ml="20px"
                  // my="12px"
                  // height="34px"
                  >
                    REMOVE
                  </DashboardSmallCTA>

                  <DashboardSmallCTA
                    width="15%"
                    onClick={() => props.editClickHandler()}
                    minWidth={["74px"]}
                    fontSize="12px"
                    innerHeight="30px"
                    ml="20px"
                  // my="12px"
                  // height="34px"
                  >
                    EDIT
                  </DashboardSmallCTA>
                </Flex>
              </Flex>
            )
            :
            null
          }
        </AbsoluteDiv>
      </Flex>
    </Flex>
  );
}

export const GroupRow = ({ ...props }) => {

  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const _openRemoveModal = () => setRemoveModalOpen(true);
  const _closeRemoveModal = () => setRemoveModalOpen(false);
  const _handleRemoveConfirm = () => props.removeClickHandler();


  return (
    <Flex position="relative" flexDirection="column">
      <Flex
        height={["auto", "68px"]}
        minHeight={"68px"}
        position="relative"
        flexDirection={["column", "row"]}
        justifyContent="flex-start"
        alignItems={["flex-start", "center"]}
      >
        {props.firstRow && props.hideFirstLastDash ? null : (
          <OneSixDash
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="1px"
          />
        )}
        <Box ml="7px" pt={["13px", 0]}>
          <DepartmentLogoNoShadowImage height={40} width={40} />
        </Box>
        <AbsoluteDiv
          overflow="hidden"
          left={props.mobile ? "70px" : "85px"}
          top="1px"
          height="68px"
          zIndex={8}
          right="0px"
        >
          <Flex
            // width="1000%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <NunitoSansSemiBold
              fontSize={["14px", "14px", "18px", "18px"]}
              fontWeight="normal"
              color="black"
              pt={"5px"}
              lineHeight={1}
            >
              {props.name}
            </NunitoSansSemiBold>
            {props.total_company_count ? (
              <NunitoSans
                fontSize={["10px", "10px", "12px", "12px"]}
                fontWeight="normal"
                color="black"
              >
                {props.total_company_count}{" "}
                {`${parseInt(props.total_company_count) === 1 ? "Company" : "Companies"} - ${props.total_employee_count} People`}
              </NunitoSans>
            ) : null}
            {
              props.created_by
                ?
                <NunitoSans
                  fontSize={["10px", "10px", "12px", "12px"]}
                  fontWeight="normal"
                  color="gray"
                  lineHeight={1.3}
                >
                  {props.created_by.first_name + ' ' + props.created_by.last_name}
                </NunitoSans>
                :
                null
            }
          </Flex>
        </AbsoluteDiv>
        <AbsoluteDiv zIndex={9} top="1px" right="0px" height="68px">
          <GradientUnderlayRightToLeft
            color="#FFFFFF"
            boxWidth={props.mobile ? "86px" : "86px"}
            gradientWidth="30px"
          />
        </AbsoluteDiv>
        <AbsoluteDiv zIndex={10} top="1px" right="0px" bottom="1px">
          {!props.editGroupMode
            ?
            (

              <Flex
                height="100%"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Flex flexDirection="row">

                  <ConfirmModal
                    modalOpen={removeModalOpen}
                    message="Are you sure you wish to remove this group?"
                    handleCloseRequest={_closeRemoveModal}
                    handleConfirm={_handleRemoveConfirm}
                  />

                  <DashboardSmallCTA
                    width="15%"
                    onClick={() => {
                      if (props.removeClickHandler) {
                        // if (
                        //   window.confirm(
                        //     "Are you sure you wish to remove this group?"
                        //   )
                        // ) {
                        //   return props.removeClickHandler();
                        // }
                        return _openRemoveModal();
                      }
                    }}
                    color="#FE4A49"
                    minWidth={["74px"]}
                    fontSize="12px"
                    innerHeight="30px"
                    ml="20px"
                  // my="12px"
                  // height="34px"
                  >
                    REMOVE
                  </DashboardSmallCTA>

                  <DashboardSmallCTA
                    width="15%"
                    onClick={() => props.editClickHandler()}
                    minWidth={["74px"]}
                    fontSize="12px"
                    innerHeight="30px"
                    ml="20px"
                  // my="12px"
                  // height="34px"
                  >
                    EDIT
                  </DashboardSmallCTA>
                </Flex>
              </Flex>
            )
            :
            null
          }
        </AbsoluteDiv>
      </Flex>
    </Flex>
  );
}

export const DepartmentRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.departments && props.departments.length ? (
      <div>
        <RowGroupHeader
          groupName="DEPARTMENTS"

        />
        <Flex flexDirection="column" width="100%">
          {props.departments.map((department, i) => {
            return (
              <DepartmentRow
                key={i}
                firstRow={i === 0}
                lastRow={
                  i === props.departments && props.departments.length - 1
                }
                editClickHandler={() => props.editClickHandler(department)}
                hideFirstLastDash={props.hideFirstLastDash}
                mobile={props.mobile}
                removeClickHandler={() =>
                  props.removeClickHandler(department.id)
                }
                editDepartmentMode={props.editDepartmentMode}
                {...department}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);


export const GroupRowGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    {props.groups && props.groups.length ? (
      <div>
        <RowGroupHeader
          groupName="GROUPS"

        />
        <Flex flexDirection="column" width="100%">
          {props.groups.map((group, i) => {
            return (
              <GroupRow
                key={i}
                firstRow={i === 0}
                lastRow={
                  i === props.groups && props.groups.length - 1
                }
                editClickHandler={() => props.editClickHandler(group)}
                hideFirstLastDash={props.hideFirstLastDash}
                mobile={props.mobile}
                removeClickHandler={() =>
                  props.removeClickHandler(group.id)
                }
                editGroupMode={props.editGroupMode}
                {...group}
              />
            );
          })}
        </Flex>
      </div>
    ) : null}
  </Flex>
);

export const CompanyDepartmentGroup = ({ ...props }) => (
  <Flex flexDirection="column" width="100%">
    <RowGroupHeader groupName="CO-WORKERS" />
    <Flex flexDirection="column" width="100%">
      {props.employees.map((person, i) => {
        return (
          <EmployeeRow
            key={i}
            companyDepartments={props.companyDepartments}
            firstRow={i === 0}
            lastRow={i === props.employees.length - 1}
            hideFirstLastDash={props.hideFirstLastDash}
            mobile={props.mobile}
            {...person}
          />
        );
      })}
    </Flex>
  </Flex>
);


function numberParser(number) {
  return new Intl.NumberFormat('en-US').format(number)
}