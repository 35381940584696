import React, {Component, useState} from "react";
// import ReactTooltip from 'react-tooltip';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../../../store";

import { error, success } from "../../../actions/alerts";
import { actions } from "../../../actions/resources";

import { getUser, dismissConnectionSuggestion, getDismissedConnectionSuggestions, dismissSuggestionDuringSession, getDismissSuggestionDuringSession} from "../../../utils/auth";

import { Flex, Box, ClickCursorFlex, IconBox } from "../../common/flexbox";
import { AbsoluteDiv } from "../../common/absolute";
import { NunitoSans, NunitoSansBold, NunitoSansSemiBold } from "../../common/typography";
import { BackButton } from "../../common/backbutton";
import {ConnectionSuggestionBox} from "../../common/suggestions";
// import { get } from "styled-system";

class ConnectionSuggestionsMain extends Component {

    state={
      dismissedConnections: []
    };

    componentDidMount(){
      const {fetchConnectionSuggestions, connectionSuggestions, company, fetchCompany, companySettings } = this.props;

      if(!companySettings.connection_suggestion_active) return history.push('/dashboard');

      if(!connectionSuggestions || !connectionSuggestions.length){

        fetchConnectionSuggestions({
          company_id: getUser().company_id
        });
      }

      if(!company || company.id!==getUser().company_id){
        fetchCompany(getUser().company_id);
      }

      this.setState({dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]});
    }

    _handleConnect = async(id)=>{
      const { createConnection, fetchConnections, fetchNotificationCount, fetchConnectionSuggestions} = this.props;

      this._dismissForSession(id);
      
      await createConnection({
        vendor_id: id,
        company_id: getUser().company_id,
        user_id: getUser().id,
        status: "requested"
      });
     
      fetchConnectionSuggestions({
        company_id: getUser().company_id
      });

      await fetchConnections({ company_id: getUser().company_id });
      fetchNotificationCount();
    };

    _dismissForSession = id =>{
      dismissSuggestionDuringSession(id);
      this.setState({
        dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
      });
    }

    _handleDismiss = id =>{
      dismissConnectionSuggestion(id);
      this.setState({
        dismissedConnections: [...getDismissedConnectionSuggestions(), ...getDismissSuggestionDuringSession()]
      });
    }


    render(){
      const {dismissedConnections} = this.state;
      const {connectionSuggestions} = this.props;
        if(!connectionSuggestions) return null;
        
        let filteredSuggestions = connectionSuggestions.filter(connection=>!dismissedConnections.includes(connection.id));
        return(
            <Flex
            className="suggestion-box-wrapper"
            >
            {filteredSuggestions.map((suggestion, index)=><ConnectionSuggestionBox key={index} suggestion={suggestion} handleDismiss={this._handleDismiss} handleConnect={this._handleConnect} />)}
            </Flex>
        );

    };

};


export default connect(
  state => ({
    systemSettings: state.resources.detail.systemsetting,
    companySettings: state.resources.detail.companysetting,
    connectionSuggestions: state.resources.collection.connectionsuggestion || [],
    company: state.resources.detail.company,
  }),
  dispatch => ({
    notifySuccess: bindActionCreators(
      success.bind(null, "api_success"),
      dispatch
    ),
    notifyError: bindActionCreators(error.bind(null, "api_error"), dispatch),
    fetchConnectionSuggestions: bindActionCreators(actions.fetchConnectionsuggestions, dispatch),
    createConnection: bindActionCreators(actions.createConnection, dispatch),
    createUser: bindActionCreators(actions.createUser, dispatch),
    fetchCompany: bindActionCreators(actions.fetchCompany, dispatch),
    fetchConnections: bindActionCreators(actions.fetchConnections, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
  })
)(ConnectionSuggestionsMain);


export const ConnectionSuggestionsNav = ({ ...props }) => (
    <Flex
      height={"100%"}
      width={"100%"}
      alignItems="flex-end"
      justifyContent="center"
    >
      
      <Flex
        height={["49px", "49px", "60px", "40px"]}
        width={"100%"}
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <NunitoSans
          pt={["6px", "6px", "8px", "6px"]}
          fontSize={[14, 14, 18, 18]}
          fontWeight={["bold"]}
        >
          RECOMMENDED CONNECTIONS
        </NunitoSans>
        <AbsoluteDiv
          left={["0px", "0px", "0px", "0px", "-20%"]}
          bottom={"0px"}
          top={"0px"}
          width={["auto", "auto", "auto", "auto", "20%"]}
        >
          <Flex
            height={["100%", "100%", "100%"]}
            alignItems={["center"]}
            justifyContent={["flex-start", "flex-start", "flex-start", "center"]}
          >
            <BackButton />
          </Flex>
        </AbsoluteDiv>
      </Flex>
    </Flex>
  );
