import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderDante } from "../../../common/forms";
// import { Eyebrow, renderFieldPlan, renderField, renderDante } from "../../../common/forms";

const validate = values => {
  const errors = {};
  console.log(values)

  if (!values.name) {
    errors.name = "Company name required";
  }

  return errors;
};

const DescriptionPointsLabel = (props) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
    <div style={{ fontSize: '14px', color: 'black', fontWeight: 700, marginBottom: '3px', marginTop: '3px' }}>COMPANY DESCRIPTION</div>
    
  </div>
);


let Info = props => {
  const { company, handleSubmit, description, updateDescription } = props;
  let planType = {"plan":"KickPost","grade":"upgrade"};
  if(company.paid === true){
    planType = {"plan":"KickPost Plus","grade":"downgrade"};
  }
  company.planType = planType;
  if((typeof description==="undefined")&&(typeof company.description==="undefined")){
    description = "description not setting properly";
  }

  return (
    <form onSubmit={handleSubmit} id="profile-form">
    
      <Field
        label="COMPANY NAME"
        name="name"
        company={company.name}
        placeholder="ACME Inc."
        component={renderField}
        type="text"
      />
      <Field
        // label="COMPANY DESCRIPTION"
        label={<DescriptionPointsLabel/>}
        name="description"
        placeholder="Company description"
        component={renderDante}
        content={description}
        pageType='company'
        updateContent={updateDescription}
      />
      <h5>
        {"COMPANY PLAN TYPE: " + planType.plan}
      </h5>
      
      
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  form: "company-info",
  validate
})(Info);
