import React, { Component } from "react";
import _ from "underscore";
import moment from "moment";

import { H2 } from "../../../common/typography";
import { Flex } from "../../../common/flexbox";
import TermOfUse from "../../../auth/legal/terms";
import Privacy from "../../../auth/legal/privacy";

export class LegalNav extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, isOpenPrivacy: true, isOpenTOS: false,
      feeHistory: [] ,historyData: []};
  }

  
  _fetchDependencies = async () => {
    const {systemSettings} = this.props;
    const history = await fetch(`${process.env.REACT_APP_API_URL}/systemfeehistory`).then(res=>res.json());
    
    const sortedHistory = history.length ? history.sort((a,b)=>moment(b.created_at).unix()-moment(a.created_at).unix()) : [];

    let feeHistory = sortedHistory.map((fee, index, arr)=>{

      return {
        date: (index===arr.length-1 ? 'Initial': moment(arr[index+1].created_at).format('L')) + ' - ' + moment(fee.created_at).format('L'),
        percent: fee.percent+ '%'
      }
    });

    feeHistory.unshift({
      date: sortedHistory.length ? `${moment(sortedHistory[0].created_at).format('L')} - Current` : 'Current',
      percent: systemSettings.system_incentive_fee + '%'
    });

    this.setState({feeHistory});

  }

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let legal = urlParams.get("legal") || null;   
    console.log("legal",legal);
    if(legal==="tos"){
      this.setState({
        isOpenPrivacy: false,      
        isOpenTOS: true      
      });
      document.getElementById("LegalDisclaimers").innerText = "Terms of Use";    
    } else {
      this.setState({
        isOpenTOS: false,   
        isOpenPrivacy: true
      });
      document.getElementById("LegalDisclaimers").innerText = "Privacy Policy"; 
    }
  }


  toggleModalTOS = () => {
    this.setState({
      isOpenPrivacy: false,      
      isOpenTOS: true      
    });
    
    document.getElementById("LegalDisclaimers").innerText = "Terms of Use";    
    document.getElementById("TOSBtn").style.color = "#2FD371";
    document.getElementById("PrivacyBtn").style.color = "#000";
  }

  toggleModalPrivacy = () => {
    this.setState({
      isOpenTOS: false,   
      isOpenPrivacy: true
    });
    document.getElementById("LegalDisclaimers").innerText = "Privacy Policy";  
    document.getElementById("PrivacyBtn").style.color = "#2FD371";    
    document.getElementById("TOSBtn").style.color = "#000";      
  }


  render() {

    const { isOpenTOS, isOpenPrivacy } = this.state;

   
  return (
    <Flex
    flexDirection="column"
    >

        <div
          height="100%">

          <H2 id="LegalDisclaimers" style={{"textAlign": "center","fontSize": "40px", "fontFamily": "NunitoSansLight", "marginBottom":"25px", "marginTop":"40px", "color": "#000"}}>LEGAL DISCLAIMERS</H2>
          <div style={{marginTop: "45px", marginBottom: '45px', textAlign: 'center'}} >
            <a href="#TOSBtn" id="TOSBtn" onClick={this.toggleModalTOS} style={{color:"#000",textDecoration:"underline","marginRight":"25px"}}><strong>Terms of Service</strong></a> | 
            <a id="PrivacyBtn" href="#PrivacyBtn" onClick={this.toggleModalPrivacy} style={{color:"#2FD371",textDecoration:"underline","marginLeft":"25px"}}><strong>Privacy Policy</strong></a>
          </div>
        </div>
      
      { isOpenPrivacy && <Privacy /> }
      { isOpenTOS && <TermOfUse /> }
    </Flex>
    );
  }
}


export default LegalNav;

