import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import { faChartSimple, faHouse, faMagnifyingGlass, faUser, faBuilding, faUsers, faEnvelopeCircleCheck, faStar, faCircleNodes } from "@fortawesome/free-solid-svg-icons";
//actions
import { actions } from "../../actions/resources";
import {
  setUpgradeModalOpen
} from "../../actions/rule";
import { Flex, Box } from "../common/flexbox";
import { getUser } from "../../utils/auth";
import { NavButtonTransparent } from "../common/ui";
import { DashboardSmallCTA } from "../common/forms";

import { NunitoSansSemiBold, NunitoSans, NunitoSansBold } from "../common/typography";
import ContactUsModal from "../common/contactUsModal";

class HomeCrumbForSidebar extends Component {
  state = {
    isHelpModalOpen: false
  }

  componentDidMount(){
    // console.log("componentDidMount")
    this._fetchdependencies();
  }
  
  _fetchdependencies() {
    // console.log("_fetchdependencies")
    const { fetchNotificationCount } = this.props;
    this._setUser();
    fetchNotificationCount({ user_id: getUser().id });
  }

  componentDidUpdate(nextProps) {
    if (this.props.path !== nextProps.path) {
      this._setUser(); 
    }
  }

  _setUser = async () => {
    let DMG = "src/components/dashboard/homecrumb.js";
    let {user} = this.props;
    // console.log(DMG, " _setUser")
    const { fetchUser } = this.props;
    if(!user){
      user = await fetchUser(getUser().id)
      .then((user)=>{
        // console.log(DMG, " this.props", this.props);
        console.log(DMG, " new user",user)
        // setUserState(user);
        })
        .catch((e)=>{
          console.log(DMG, " still no user",e)
        });
    } else {
      console.log(DMG, " normal user",user)
      // console.log(DMG, " this.props", this.props);
      // setUserState(user);
   } 

    // function setUserState(usr){
    //   // this.setState({ "user": usr});
    //   console.log(DMG, " hello setUserState" );
    // }

  }


  _closeHelpModal = () => this.setState({ isHelpModalOpen: false });

  _openHelpModal = () => this.setState({ isHelpModalOpen: true });

  render() {
    const { user, path, systemSettings, companySettings, featureFlags, browser, notificationCounts } = this.props;
    const {  isHelpModalOpen } = this.state;
    const DMG = "src/components/dashboard/homecrumb.js" ;
    // console.log(DMG,"this.props", this.props)
    console.log(DMG,"user", user)

    return (
      <Flex
        position="relative"
        zIndex={100000}
        flexDirection="column"
        alignItems="center"
      >
        <Box mt={["15px", "10px", "10px", "10px", "14px"]} />
        <NunitoSansSemiBold
          fontSize="8px"
          color="#6C7168"
          style={{ borderTop: '1px solid #6C7168', paddingTop: '5px', width: '85%', textAlign: browser.lessThan.infinity ? "center" : null }}

        >
          {browser.lessThan.infinity ? "NAV" : "NAVIGATION"}
        </NunitoSansSemiBold>

        <Box key={0} mt={["30px", "20px", "20px", "20px", "20px"]} />
        <NavButtonTransparent link="/dashboard" fontawesomeIcon={faHouse} title="Dashboard" name="home" minimize={browser.lessThan.infinity} active={path === "/dashboard" ? true : false} />



        <Box key={1} mt={["30px", "20px", "20px", "20px", "20px"]} />
        <NavButtonTransparent link={`/dashboard/search`} fontawesomeIcon={faMagnifyingGlass} title="Search" name="search" minimize={browser.lessThan.infinity} active={path === "/dashboard/search" ? true : false} notificationCount={notificationCounts.search} />


        <Box key={2} mt={["30px", "20px", "20px", "20px", "20px"]} />
        <NavButtonTransparent link={`/dashboard/profile/${getUser().id}`} fontawesomeIcon={faUser} title={"Profile " +`${getUser().first_name} ${getUser().last_name}`} name="my profile" minimize={browser.lessThan.infinity} active={path === `/dashboard/profile/${getUser().id}` ? true : false} />

        {
          companySettings.is_single_user_company
            ?
            null
            :
            <>
              <Box key={3} mt={["30px", "20px", "20px", "20px", "20px"]} />
              <NavButtonTransparent link={`/dashboard/company/${getUser().company_id}`} fontawesomeIcon={faBuilding} title="My Company" name="my company" minimize={browser.lessThan.infinity} active={path === `/dashboard/company/${getUser().company_id}` ? true : false} />
            </>
        }

        <>
          <Box key={4} mt={["30px", "20px", "20px", "20px", "20px"]} />
          <NavButtonTransparent link={`/dashboard/company/${getUser().company_id}/invitations`} fontawesomeIcon={faEnvelopeCircleCheck} title="Invites" name="invites" minimize={browser.lessThan.infinity} active={path === `/dashboard/company/${getUser().company_id}/invitations` ? true : false} />
        </>

        
        

       { ((systemSettings.rewards_active) && ((getUser().status !== "pending")) &&(companySettings.receive_incentives || companySettings.create_incentives || companySettings.receive_vendor_incentives || companySettings.create_vendor_incentives)  && (getUser().company_admin || getUser().sales_id))?
          <>
            <Box key={5} mt={["30px", "20px", "20px", "20px", "20px"]} />
            <NavButtonTransparent link={`/dashboard/rewards`} fontawesomeIcon={faStar} title="Rewards" name="rewards" minimize={browser.lessThan.infinity} active={path === "/dashboard/rewards" ? true : false} notificationCount={notificationCounts.rewards} lockedFeature={featureFlags.rewards === "locked"} openUpgradeModal={() => this.props.setUpgradeModalOpen(true)} />
          </>
          :
          null

        }

        { (getUser().status === "active" && (getUser().company_admin || getUser().sales_id))?
          <>
            <Box key={6} mt={["30px", "20px", "20px", "20px", "20px"]} />
            <NavButtonTransparent link={featureFlags && (featureFlags.sales === "locked") ? path : `/dashboard/sales`} fontawesomeIcon={faChartSimple} title="Sales" name="sales" minimize={browser.lessThan.infinity} active={path === "/dashboard/sales" ? true : false} notificationCount={0} lockedFeature={featureFlags && (featureFlags.sales === "locked")} openUpgradeModal={() => this.props.setUpgradeModalOpen(true)} />
          </>
          :
          null

        }
        {getUser().company_admin ? (
          <>
            <Box mt={["30px", "20px", "20px", "20px", "28px"]} />
            <NunitoSansSemiBold
              fontSize="8px"
              color="#6C7168"
              style={{ borderTop: '1px solid #6C7168', paddingTop: '5px', width: '85%', textAlign: browser.lessThan.infinity ? "center" : null }}
            >
              MANAGE
            </NunitoSansSemiBold>
            
            {
              companySettings.is_single_user_company
              ?
              null
              :
              <>
                <Box key={7} mt={["30px", "20px", "20px", "20px", "20px"]} />
                <NavButtonTransparent link={`/dashboard/company/${getUser().company_id}/employees`} fontawesomeIcon={faUsers} title="My Team" name="my team" minimize={browser.lessThan.infinity} active={path === `/dashboard/company/${getUser().company_id}/employees` ? true : false} notificationCount={notificationCounts.coworkers} />
              </>
            }


            <Box key={8} mt={["30px", "20px", "20px", "20px", "20px"]} />
            <NavButtonTransparent link={`/dashboard/company/${getUser().company_id}/vendors`} fontawesomeIcon={faCircleNodes} title="Connections" name="connections" minimize={browser.lessThan.infinity} active={path === `/dashboard/company/${getUser().company_id}/vendors` ? true : false} notificationCount={notificationCounts.connections} />


          </>

        ) : null}

        <Box key={9} mt={["30px", "20px", "20px", "20px", "20px"]} style={{ cursor: "pointer" }} />
        <div style={{ borderTop: '1px solid #6C7168', paddingTop: '5px', width: '85%' }}></div>

        {
          getUser().company_admin
            ?
            <NavButtonTransparent url={`/dashboard/settings`} icon="settings" title="Settings" name="settings" minimize={browser.lessThan.infinity} active={path === `/dashboard/settings` ? true : false} notificationCount={notificationCounts.settings} />
            :
            null
        }
        <NavButtonTransparent action={this._openHelpModal} icon="helpcenter" title="Help" name={browser.lessThan.infinity ? 'help' : 'help'} minimize={browser.lessThan.infinity} />

        <NavButtonTransparent icon="signout" title="Sign Out" name="sign out" minimize={browser.lessThan.infinity} onClick={this.props.logout} />

        <HelpModal
          isModalOpen={isHelpModalOpen}
          handleCloseRequest={this._closeHelpModal}
          isMobile={browser.lessThan.large}
        />
        <ContactUsModal key={999}/>

      </Flex>
    );
  }
}

HomeCrumbForSidebar = connect(state => ({
  browser: state.browser,
  history: state.history,
  routes: state.routes,
  router: state.router,
  systemSettings: state.resources.detail.systemsetting,
  companySettings: state.resources.detail.companysetting,
  featureFlags: state.resources.detail.featureflag,
  notificationCounts: state.resources.collection.notificationcount || {},
}),
  dispatch => ({

    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
    fetchNotificationCount: bindActionCreators(actions.fetchNotificationcounts, dispatch),
    setUpgradeModalOpen: bindActionCreators(setUpgradeModalOpen, dispatch),
  })
)(HomeCrumbForSidebar);

export { HomeCrumbForSidebar };

const HelpModal = ({ isModalOpen, handleCloseRequest, isMobile }) => {

  const [submitted, setSubmitted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {

    if (!text) {
      return setError("Please type your question.");
    }

    const message = text.trim();

    if (!message) {
      setText("");
      return setError("Please type your question.");
    }

    if (message.length < 20) {
      return setError("Please give us more details.");
    }

    setProcessing(true);

    await fetch(`${process.env.REACT_APP_API_URL}/helpquestion`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: getUser().id,
        message
      })
    }).then(res => res.json());

    setSubmitted(true);
    setProcessing(false);

  }

  const closeModal = () => {
    setSubmitted(false);
    setProcessing(false);
    setText("");
    setError("");

    handleCloseRequest();
  }

  const handleTextChange = (value) => {
    setText(value);

    if (error) {
      setError("");
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '50%' : "500px",
      // minHeight: "312px",
      zIndex: 16777271,
      overflow: "auto",
      paddingLeft: "40px",
      paddingRight: "40px",
      border: '1px solid #707070',
      borderRadius: '10px'
    },

    overlay: {
      zIndex: 16777270,
      background: "#A3A99E88"
    }

  };

  return (

    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel={`Help Modal`}
      ariaHideApp={false}
    >

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >

        <NunitoSansBold
          color='#000000'
          fontSize={['25px', '25px', '35px', '35px']}
          textAlign='center'
          style={{ marginTop: '30px', marginBottom: '17px' }}
        >
          {submitted ? "Submitted" : "Need Help?"}
        </NunitoSansBold>

        <NunitoSans
          color='#000000'
          fontSize={['16px', '16px', '18px', '18px']}
          lineHeight='22px'
          textAlign="center"
          style={{ marginBottom: '20px' }}
        >
          {
            submitted
              ?
              "Your question has been sent to the KickPost support team, we'll get back to you asap via email."
              :
              "Let us know what we can help you with below and we'll get back to you asap."
          }
        </NunitoSans>

        {
          submitted
            ?
            null
            :
            <textarea
              value={text}
              onChange={(e) => handleTextChange(e.target.value)}
              autoFocus
              style={{
                width: '100%',
                border: '1px solid #000000',
                padding: '5px 5px',
                minHeight: '200px',
                fontFamily: 'NunitoSans',
                fontSize: '14px'
              }}
            />
        }

        {
          submitted
            ?
            <DashboardSmallCTA
              minWidth={["122px"]}
              fontSize="16px"
              innerHeight="30px"
              width="70%"
              color="#428BF4"
              onClick={() => closeModal()}
              style={{ marginTop: '20px' }}
            >
              DONE
            </DashboardSmallCTA>
            :
            <DashboardSmallCTA
              minWidth={["122px"]}
              fontSize="16px"
              innerHeight="30px"
              width="70%"
              color="#428BF4"
              disabled={processing}
              onClick={() => handleSubmit()}
              style={{ marginTop: '20px' }}
            >
              {processing ? "Processing..." : "SUBMIT"}
            </DashboardSmallCTA>
        }


        {error && <p style={{ color: 'red' }} >{error}</p>}

      </Flex>

    </Modal>

  );
}
