const defaultState = {
  department: null,
  departmentMembers: null
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SET_DEPARTMENT":
      newState.department = action.payload;
      return newState;
    case "SET_DEPARTMENT_MEMBERS":
      newState.departmentMembers = action.payload;
      return newState;
    default:
      return state;
  }
};
